import {apiClient} from "./apiclient";
import keyBy from "lodash.keyby";
import {POCKETSUITE_USER_ID} from "../utils/constants";
import {replace} from "connected-react-router";
import {processError} from "./error";
import { Signature } from "../models/Signature";
import {Subscription} from "../models/Subscription";
import { Package } from "../models/Package";
import { TaxcodeInstance } from "../models/Taxcode";
import { GiftCertificate } from "../models/GiftCertificate";
import { Payment } from "../models/Payment";

export type TransactionsState = {
    isSending: boolean,
    completedIntent?: string,
    failedIntent?: string,
    pendingIntent?: string,
}

const initialState: TransactionsState = {
    isSending: false,
}

export default function transactionsReducer(state = initialState, action: any) {
    switch (action.type) {
        case 'transactions/REQUEST':
            return {
                ...state,
                isSending: action.sending,
            }
        case 'transactions/SET_PENDING_INTENT':
            return {
                ...state,
                completedIntent: undefined,
                failedIntent: undefined,
                pendingIntent: action.pendingIntent,
            }
        case 'transactions/SET_AUTHORIZED_INTENT':
            return {
                ...state,
                completedIntent: action.completedIntent,
                failedIntent: undefined,
                pendingIntent: undefined,
            }
        case 'transactions/SET_FAILED_INTENT':
            return {
                ...state,
                completedIntent: undefined,
                failedIntent: action.failedIntent,
                pendingIntent: undefined,
            }
        default:
            return state
    }
}

const transactionsRequest = (sending: boolean) => {
    return { type: 'transactions/REQUEST', sending }
}

export const setCompletedIntent = (intent?: string) => {
    return { type: 'transactions/SET_AUTHORIZED_INTENT', completedIntent: intent }
}

export const setFailedIntent = (intent?: string) => {
    return { type: 'transactions/SET_FAILED_INTENT', failedIntent: intent }
}

export const setPendingIntent = (intent?: string) => {
    return { type: 'transactions/SET_PENDING_INTENT', pendingIntent: intent }
}

export const fetchPros = (callback?: () => void) => {
    return (dispatch: any) => {
        return apiClient.post('/payee/sync')
            .then(resp => resp.data)
            .then(json => {
                if (json.payees) {
                    // Ignore PS user
                    const payees = json.payees.filter((p: any) => p.person !== POCKETSUITE_USER_ID)
                    const payeeMap = keyBy(payees, 'person')
                    dispatch({type: 'entities/UPDATE', name: 'payees', map: payeeMap})
                }

                if (json.contacts) {
                    const userMap = keyBy(json.contacts, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'users', map: userMap})
                }

                if (callback) callback()
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

type AvailabilitySearchParams = {
    duration: number;
    start: Date,
    itemID?: string;
}

export const fetchProAvailability = ({duration, start, itemID}: AvailabilitySearchParams) => {
    return (dispatch: any) => {
        const params: any = {
            staff: 'all',
            strict: true,
            duration,
            start: start.toISOString(),
        }
        if (itemID) params.item = itemID

        return apiClient.post('/availability/vendor', params)
            .then(resp => resp.data)
            .then(json => {
                return json.results
            })
    }
}

type CancelBookingRequest = {
    id: string,
    doNotNotify?: boolean,
    cancelAll?: boolean,
    cancellationType?: string,
    cancellationMemo?: string,
    enforce?: boolean
}

export const cancelBooking = ({id, doNotNotify, cancelAll, cancellationType, cancellationMemo, enforce}: CancelBookingRequest) =>
    (dispatch: any) => {
        let postData: any = { }
        if (cancelAll)
            postData['cancel_all'] = true
        if (doNotNotify)
            postData['dont_notify'] = true
        if (enforce)
            postData['enforce'] = true
        if (!!cancellationType)
            postData['cancellation_type'] = cancellationType
        if (!!cancellationMemo)
            postData['cancellation_memo'] = cancellationMemo

        return apiClient.post(`/booking/${id}/cancel`, postData)
            .then(resp => resp.data)
            .then(json => {
                if (json.booking) {
                    const bookingMap: any = { }
                    bookingMap[json.booking.id] = json.booking
                    dispatch({type: 'entities/UPDATE', name: 'bookings', map: bookingMap})
                }
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }

export const cancelInvoice = (id: string, cancelAll: boolean) =>
    (dispatch: any) => {
        let postData: any = { }
        if (cancelAll)
            postData['cancel_all'] = true

        return apiClient.post(`/invoice/${id}/cancel`, postData)
            .then(resp => resp.data)
            .then(json => {
                if (json.invoice) {
                    const invoiceMap: any = { }
                    invoiceMap[json.invoice.id] = json.invoice
                    dispatch({type: 'entities/UPDATE', name: 'invoices', map: invoiceMap})
                }
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }

export const cancelGift = (id: string) =>
    (dispatch: any) =>
        apiClient.post(`/gift/${id}/cancel`, { })
            .then(resp => resp.data)
            .then(json => {
                if (json.gift) {
                    const giftMap: any = { }
                    giftMap[json.gift.id] = json.gift
                    dispatch({type: 'entities/UPDATE', name: 'gifts', map: giftMap})
                }
                if (json.message) {
                    const messageMap: any = {}
                    messageMap[json.message.id] = json.message
                    dispatch({type: 'entities/UPDATE', name: 'messages', map: messageMap})
                }
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })

export const cancelEstimate = (id: string) =>
    (dispatch: any) =>
        apiClient.post(`/estimate/${id}/cancel`, { })
            .then(resp => resp.data)
            .then(json => {
                if (json.estimate) {
                    const estimateMap: any = { }
                    estimateMap[json.estimate.id] = json.estimate
                    dispatch({type: 'entities/UPDATE', name: 'estimates', map: estimateMap})
                }
                if (json.message) {
                    const messageMap: any = {}
                    messageMap[json.message.id] = json.message
                    dispatch({type: 'entities/UPDATE', name: 'messages', map: messageMap})
                }
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })

export const confirmEstimate = (record: any) =>
    (dispatch: any) => {
        dispatch(transactionsRequest(true))

        const postData: any = {
            source: record.source ?? "",
            gratuity: record.gratuity,
            gratuity_type: record.gratuity_type
        }
        if (!!record.is_pos) {
            postData['is_pos'] = true
        }
        if (!!record.intent) {
            postData['intent'] = record.intent
        }

        return apiClient.post(`/estimate/${record.id}/confirm`, postData)
            .then(resp => resp.data)
            .then(json => {
                if (json.estimate) {
                    const estimateMap: any = {}
                    estimateMap[json.estimate.id] = json.estimate
                    dispatch({type: 'entities/UPDATE', name: 'estimates', map: estimateMap})
                }
                if (json.client) {
                    const personMap: any = {}
                    personMap[json.client.id] = json.client
                    dispatch({type: 'entities/UPDATE', name: 'clients', map: personMap})
                }
                if (json.payee) {
                    const payeeMap: any = {}
                    payeeMap[json.payee.id] = json.payee
                    dispatch({type: 'entities/UPDATE', name: 'payees', map: payeeMap})
                }
                return json
            })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => dispatch(transactionsRequest(false)))
    }

export const sendReaderReceipt = (paymentId: string, name?: string, email?: string, phone?: string) =>
    (dispatch: any) => {
        dispatch(transactionsRequest(true))

        const postData: any = {
            name,
            email,
            phone
        }

        return apiClient.post(`/checkout/${paymentId}/receipt`, postData)
            .then(resp => resp.data)
            .then(json => {
                if (json.client) {
                    const clientMap = {[json.client.id]: json.client}
                    dispatch({type: 'entities/UPDATE', name: 'clients', map: clientMap})
                }
                if (json.user) {
                    const userMap = {[json.user.id]: json.user}
                    dispatch({type: 'entities/UPDATE', name: 'users', map: userMap})
                }
                return json
            })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => dispatch(transactionsRequest(false)))
    }

export const createIntent = (record: Payment, serialNumber: string, stripeReaderId: string) =>
    (dispatch: any) => {
        dispatch(transactionsRequest(true))

        const postData: any = {
            ...record,
            reader: serialNumber,
            stripe_reader_id: stripeReaderId,
            send_reader: true
        }
        if (record.type === "confirm") {
            postData.type = "deposit"
        }

        return apiClient.post(`/checkout/${postData.owner}/intent`, postData)
            .then(resp => resp.data)
            .then(json => {
                return json
            })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => dispatch(transactionsRequest(false)))
    }

export const sendBooking = (bookingData: any) => {
    return (dispatch: any) => {
        const url = bookingData.id ? `/booking/${bookingData.id}` : '/booking'
        return apiClient.post(url, bookingData)
            .then(resp => resp.data)
            .then(json => {
                // Update entities to make sure they are available after the push
                if (json.client) {
                    const clientMap = {[json.client.id]: json.client}
                    dispatch({type: 'entities/UPDATE', name: 'clients', map: clientMap})
                }
                if (json.user) {
                    const userMap = {[json.user.id]: json.user}
                    dispatch({type: 'entities/UPDATE', name: 'users', map: userMap})
                }

                // Replace so the user can't use the browser's back button to view
                // the confirmation screen again.
                dispatch(replace('/schedule/success', {
                    booking: json.booking,
                    notify: !bookingData.dont_notify,
                }))
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
        }
}

export const sendClass = (lessonId: string, classData: any) => {
    return (dispatch: any) => {
        return apiClient.post(`/class/${lessonId}/schedule`, classData)
            .then(resp => resp.data)
            .then(json => {
                // Update entities to make sure they are available after the push
                if (json.client) {
                    const clientMap = {[json.client.id]: json.client}
                    dispatch({type: 'entities/UPDATE', name: 'clients', map: clientMap})
                }
                if (json.user) {
                    const userMap = {[json.user.id]: json.user}
                    dispatch({type: 'entities/UPDATE', name: 'users', map: userMap})
                }

                // Replace so the user can't use the browser's back button to view
                // the confirmation screen again.
                dispatch(replace('/schedule/success', {
                    booking: json.booking,
                    notify: !classData.dont_notify,
                }))
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const sendInvoice = (invoice: any, chargeAfterSend?: boolean) => {
    return (dispatch: any) => {
        const url = invoice.id ? `/invoice/${invoice.id}` : '/invoice'

        return apiClient.post(url, invoice)
            .then(resp => resp.data)
            .then(json => {
                if (json.invoice) {
                    const invoiceMap: any = { }
                    invoiceMap[json.invoice.id] = json.invoice
                    dispatch({type: 'entities/UPDATE', name: 'invoices', map: invoiceMap})
                }
                if (chargeAfterSend) {
                    // Replace so the user can't use the browser's back button to view
                    // the confirmation screen again.
                    dispatch(replace(`/charge/invoice/${json.invoice.id}`, {invoice: json.invoice}))
                }
                else {
                    // Replace so the user can't use the browser's back button to view
                    // the confirmation screen again.
                    dispatch(replace('/invoice/success', {invoice: json.invoice}))
                }

            })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const sendSignature = (signatureData: Signature) => {
    return (dispatch: any) => {
        const data = signatureData.toObject()
        const url = signatureData.id ? `/signature/${signatureData.id}` : '/signature'

        dispatch(transactionsRequest(true))
        return apiClient.post(url, data)
            .then(resp => resp.data)
            .then(json => {
                if (json.message) {
                    const messageMap: any = { }
                    messageMap[json.message.id] = json.message
                    dispatch({type: 'entities/UPDATE', name: 'messages', map: messageMap})
                }

                if (json.signature) {
                    const signatureMap: any = { }
                    signatureMap[json.signature.id] = json.signature
                    dispatch({type: 'entities/UPDATE', name: 'signatures', map: signatureMap})
                    return json.signature
                }
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => dispatch(transactionsRequest(false)))
    }
}

export const signSignature = (signatureData: Signature) => {
    return (dispatch: any) => {
        return apiClient.post(`/signature/${signatureData.id}/sign`, signatureData)
            .then(resp => resp.data)
            .then(json => {
                if (json.messages) {
                    const messageMap = keyBy(json.messages, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'messages', map: messageMap})
                }

                if (json.signature) {
                    const signatureMap: any = { }
                    signatureMap[json.signature.id] = json.signature
                    dispatch({type: 'entities/UPDATE', name: 'signatures', map: signatureMap})
                    return json.signature
                }
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const deleteSignature = (id: string | undefined, doNotNotify: boolean) => {
    return (dispatch: any) => {
        return apiClient.post(`/signature/${id}/delete`, { dont_notify: doNotNotify })
            .then(resp => resp.data)
            .then(json => {
                if (json.message) {
                    const messageMap: any = { }
                    messageMap[json.message.id] = json.message
                    dispatch({type: 'entities/UPDATE', name: 'messages', map: messageMap})
                }

                if (json.signature) {
                    const signatureMap: any = { }
                    signatureMap[json.signature.id] = json.signature
                    dispatch({type: 'entities/UPDATE', name: 'signatures', map: signatureMap})
                    return json.signature
                }
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const sendEstimate = (estimate: any, doNotNotify?: boolean) => {
    if (doNotNotify) estimate.dont_notify = true
    return (dispatch: any) => {
        const url = estimate.id ? `/estimate/${estimate.id}` : '/estimate'
        return apiClient.post(url, estimate)
            .then(resp => resp.data)
            .then(json => {
                if (json.estimate) {
                    const estimateMap: any = { }
                    estimateMap[json.estimate.id] = json.estimate
                    dispatch({type: 'entities/UPDATE', name: 'estimates', map: estimateMap})
                }

                // Replace so the user can't use the browser's back button to view
                // the confirmation screen again.
                dispatch(replace('/estimate/success', {estimate: json.estimate}))
            })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const saveSubscription = (subscription: Partial<Subscription>) => submitSubscription(subscription, true)
export const sendSubscription = (subscription: Partial<Subscription>) => submitSubscription(subscription, false)

const submitSubscription = (subscription: Partial<Subscription>, doNotNotify: boolean) =>
    (dispatch: any) => {
        const url = subscription.id ? `/subscription/${subscription.id}` : '/subscription'
        return apiClient.post(url, {
                ...subscription,
                dont_notify: doNotNotify
            })
            .then(resp => resp.data)
            .then(json =>json?.subscription ? json.subscription as Partial<Subscription> : undefined)
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }

export const fetchSubscription = (id: string) =>
    (dispatch: any) =>
        apiClient.get(`/subscription/${id}`)
            .then(resp => resp.data)
            .then(json => new Subscription(json as Partial<Subscription>))
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })

export const cancelSubscription = (id: string, enforce: boolean) =>
    (dispatch: any) => {
        let postData: any = { enforce }
        return apiClient.post(`/subscription/${id}/cancel`, postData)
            .then(resp => resp.data)
            .then(json => {
                if (json.subscription) {
                    const subscriptionMap: any = { }
                    subscriptionMap[json.subscription.id] = json.subscription
                    dispatch({type: 'entities/UPDATE', name: 'subscription', map: subscriptionMap})
                    return json.subscription
                }
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }

export const savePackage = (bundle: Partial<Package>) => submitPackage(bundle, true)
export const sendPackage = (bundle: Partial<Package>) => submitPackage(bundle, false)

const submitPackage = (bundle: Partial<Package>, doNotNotify: boolean) =>
    (dispatch: any) => {
        const url = bundle.id ? `/package/${bundle.id}` : '/package'
        return apiClient.post(url, {
                ...bundle,
                dont_notify: doNotNotify
            })
            .then(resp => resp.data)
            .then(json =>json?.package ? json.package as Partial<Package> : undefined)
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }

export const fetchPackage = (id: string) =>
    (dispatch: any) =>
        apiClient.post(`/package/sync`, {id})
            .then(resp => resp.data)
            .then((json: any) => {
                if (json.package) return json.package as Partial<Package>;
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })

export const saveGift = (gift: Partial<GiftCertificate>) => submitGift(gift, true)
export const sendGift = (gift: Partial<GiftCertificate>) => submitGift(gift, false)

const submitGift = (gift: Partial<GiftCertificate>, doNotNotify: boolean) =>
    (dispatch: any) => {
        const url = gift.id ? `/gift/${gift.id}` : '/gift'
        return apiClient.post(url, {
                ...gift,
                dont_notify: doNotNotify
            })
            .then(resp => resp.data)
            .then(json =>json?.gift ? json.gift as Partial<GiftCertificate> : undefined)
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }

export const fetchGiftCertificate = (id: string) =>
    (dispatch: any) =>
        apiClient.post(`/gift/sync`, {id})
            .then(resp => resp.data)
            .then((json: any) => {
                if (json.gift) return json.gift as Partial<GiftCertificate>;
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })

export const fetchTaxCodeByLocation = (location?: string, callback?: (taxCode: TaxcodeInstance) => void) =>
    fetchTaxCode(location, undefined, callback)

export const fetchTaxCodeById = (taxCodeId?: string, callback?: (taxCode: TaxcodeInstance) => void) =>
    fetchTaxCode(undefined, taxCodeId, callback)

const fetchTaxCode = (location?: string, taxCodeId?: string, callback?: (taxCode: TaxcodeInstance) => void) =>
    (dispatch: any) =>
        apiClient.post(`/taxcode`, {location, taxcode: taxCodeId})
            .then(resp => resp.data)
            .then(json => {
                if (json && callback) callback(json)
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })

export const sendForm = (recordParams: any) =>
    (dispatch: any) => {
        dispatch(transactionsRequest(true))
        return apiClient.post(!recordParams.id ? '/record' : `/record/${recordParams.id}`, recordParams)
            .then(resp => resp.data)
            .catch((error) => {
                dispatch(processError(error, "Error saving form"))
                return Promise.reject()
            })
            .finally(() => dispatch(transactionsRequest(false)))
    }

export const cancelForm = (formId: string, doNotNotify: boolean) =>
    (dispatch: any) => {
        dispatch(transactionsRequest(true))
        return apiClient.post(`/record/${formId}/delete`, { dont_notify: doNotNotify })
            .then(resp => resp.data)
            .then(json => {
                if (json.record) {
                    const recordMap: any = {}
                    recordMap[json.record.id] = json.record
                    dispatch({type: 'entities/UPDATE', name: 'records', map: recordMap})
                }
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => dispatch(transactionsRequest(false)))
    }

export const markBookingPaid = (record: any) =>
    (dispatch: any) => {
        dispatch(transactionsRequest(true))

        const postData: any = {
            rate: record.rate ?? "0",
            type: "mark",
            is_pos: false,
            lines: record.lines,
            taxcode: record.taxcode ?? "",
            tax: record.tax ?? "",
            nexus: record.nexus ?? ""
        }

        if (!!record.paymeth) {
            postData['paymeth'] = record.paymeth
        }
        if (!!record.refnum) {
            postData['refnum'] = record.refnum
        }
        if (record.send_receipt) {
            postData['send_receipt'] = true
        }
        if (!!record.gratuity && !!record.gratuity_type) {
            postData['gratuity'] = record.gratuity
            postData['gratuity_type'] = record.gratuity_type
        }
        if (!!record.mark_paid_date) {
            postData['mark_paid_date'] = record.mark_paid_date
        }

        return apiClient.post(`/booking/${record.id}/complete`, postData)
            .then(resp => resp.data)
            .then(json => {
                if (json.booking) {
                    const bookingMap: any = {}
                    bookingMap[json.booking.id] = json.booking
                    dispatch({type: 'entities/UPDATE', name: 'bookings', map: bookingMap})
                }
                if (json.client) {
                    const personMap: any = {}
                    personMap[json.client.id] = json.client
                    dispatch({type: 'entities/UPDATE', name: 'clients', map: personMap})
                }
                if (json.payee) {
                    const payeeMap: any = {}
                    payeeMap[json.payee.id] = json.payee
                    dispatch({type: 'entities/UPDATE', name: 'payees', map: payeeMap})
                }
                return json
            })
            .catch(error => {
                dispatch(processError(error, "Error confirming booking"))
                return Promise.reject()
            })
            .finally(() => dispatch(transactionsRequest(false)))
    }

export const markInvoicePaid = (record: any) =>
    (dispatch: any) => {
        dispatch(transactionsRequest(true))

        const postData: any = { }
        if (!!record.paymeth) {
            postData['paymeth'] = record.paymeth
        }
        if (!!record.refnum) {
            postData['refnum'] = record.refnum
        }
        if (record.send_receipt) {
            postData['send_receipt'] = true
        }
        if (!!record.gratuity && !!record.gratuity_type) {
            postData['gratuity'] = record.gratuity
            postData['gratuity_type'] = record.gratuity_type
        }
        if (!!record.mark_paid_date) {
            postData['mark_paid_date'] = record.mark_paid_date
        }

        return apiClient.post(`/invoice/${record.id}/mark`, postData)
            .then(resp => resp.data)
            .then(json => {
                if (json.estimate) {
                    const estimateMap: any = {}
                    estimateMap[json.estimate.id] = json.estimate
                    dispatch({type: 'entities/UPDATE', name: 'estimates', map: estimateMap})
                }
                if (json.message) {
                    const messageMap: any = {}
                    messageMap[json.message.id] = json.message
                    dispatch({type: 'entities/UPDATE', name: 'messages', map: messageMap})
                }
                if (json.booking) {
                    const bookingMap: any = {}
                    bookingMap[json.booking.id] = json.booking
                    dispatch({type: 'entities/UPDATE', name: 'bookings', map: bookingMap})
                }
                return json
            })
            .catch(error => {
                dispatch(processError(error, "Error marking invoice paid"))
                return Promise.reject()
            })
            .finally(() => dispatch(transactionsRequest(false)))
    }

export const markEstimatePaid = (record: any) =>
    (dispatch: any) => {
        dispatch(transactionsRequest(true))

        const postData: any = { }
        if (!!record.paymeth) {
            postData['paymeth'] = record.paymeth
        }
        if (!!record.refnum) {
            postData['refnum'] = record.refnum
        }
        if (record.send_receipt) {
            postData['send_receipt'] = true
        }
        if (!!record.gratuity && !!record.gratuity_type) {
            postData['gratuity'] = record.gratuity
            postData['gratuity_type'] = record.gratuity_type
        }
        if (!!record.mark_paid_date) {
            postData['mark_paid_date'] = record.mark_paid_date
        }

        return apiClient.post(`/estimate/${record.id}/mark`, postData)
            .then(resp => resp.data)
            .then(json => {
                if (json.message) {
                    const messageMap: any = {}
                    messageMap[json.message.id] = json.message
                    dispatch({type: 'entities/UPDATE', name: 'messages', map: messageMap})
                }
                if (json.estimate) {
                    const estimateMap: any = {}
                    estimateMap[json.estimate.id] = json.estimate
                    dispatch({type: 'entities/UPDATE', name: 'estimates', map: estimateMap})
                }
            })
            .catch(error => {
                dispatch(processError(error, "Error marking estimate paid"))
                return Promise.reject()
            })
            .finally(() => dispatch(transactionsRequest(false)))
    }

export const markPackagePaid = (record: any) =>
    (dispatch: any) => {
        dispatch(transactionsRequest(true))

        const postData: any = { }
        if (!!record.paymeth) {
            postData['paymeth'] = record.paymeth
        }
        if (!!record.refnum) {
            postData['refnum'] = record.refnum
        }
        if (!!record.gratuity && !!record.gratuity_type) {
            postData['gratuity'] = record.gratuity
            postData['gratuity_type'] = record.gratuity_type
        }
        if (!!record.mark_paid_date) {
            postData['mark_paid_date'] = record.mark_paid_date
        }
        if (record.send_receipt) {
            postData['send_receipt'] = true
        }

        return apiClient.post(`/package/${record.id}/complete`, postData)
            .then(resp => resp.data)
            .then(json => {
                if (json.message) {
                    const messageMap: any = {}
                    messageMap[json.message.id] = json.message
                    dispatch({type: 'entities/UPDATE', name: 'messages', map: messageMap})
                }
                if (json.bookings) {
                    const bookingMap = keyBy(json.bookings, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'bookings', map: bookingMap})
                }
                return json
            })
            .catch(error => {
                dispatch(processError(error, "Error marking package paid"))
                return Promise.reject()
            })
            .finally(() => dispatch(transactionsRequest(false)))
    }

export const markPaid = (paymentData: any) =>
    (dispatch: any) => {
        dispatch(transactionsRequest(true))

        paymentData['dont_notify'] = !paymentData.send_receipt
        return apiClient.post('/mark', paymentData)
            .then(resp => resp.data)
            .then(json => {
                if (json.message) {
                    const messageMap: any = {}
                    messageMap[json.message.id] = json.message
                    dispatch({type: 'entities/UPDATE', name: 'messages', map: messageMap})
                }
                return json
            })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => dispatch(transactionsRequest(false)))
    }

export const markGiftPaid = (record: any) =>
    (dispatch: any) => {
        dispatch(transactionsRequest(true))

        const postData: any = { }
        if (!!record.paymeth) {
            postData['paymeth'] = record.paymeth
        }
        if (!!record.refnum) {
            postData['refnum'] = record.refnum
        }
        if (record.send_receipt) {
            postData['send_receipt'] = true
        }
        if (!!record.gratuity && !!record.gratuity_type) {
            postData['gratuity'] = record.gratuity
            postData['gratuity_type'] = record.gratuity_type
        }
        if (!!record.mark_paid_date) {
            postData['mark_paid_date'] = record.mark_paid_date
        }

        return apiClient.post(`/gift/${record.id}/complete`, postData)
            .then(resp => resp.data)
            .then(json => {
                if (json.message) {
                    const messageMap: any = {}
                    messageMap[json.message.id] = json.message
                    dispatch({type: 'entities/UPDATE', name: 'messages', map: messageMap})
                }
                return json
            })
            .catch(error => {
                dispatch(processError(error, "Error marking gift paid"))
                return Promise.reject()
            })
            .finally(() => dispatch(transactionsRequest(false)))
    }

export const applyPackage = (packageId: string, bookingId: string) =>
    (dispatch: any) => {
        dispatch(transactionsRequest(true))

        return apiClient.post(`/package/${packageId}/apply`, {booking: bookingId})
            .then(resp => resp.data)
            .then((json: any) => {
                if (json) {
                    const bookingMap: any = { }
                    bookingMap[json.id] = json
                    dispatch({type: 'entities/UPDATE', name: 'bookings', map: bookingMap})
                }
            })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => dispatch(transactionsRequest(false)))
    }

export const changeBookingSource = (bookingId: string, sourceId: string) =>
    (dispatch: any) => {
        dispatch(transactionsRequest(true))

        return apiClient.post(`/booking/${bookingId}/source`, {source: sourceId})
            .then(resp => resp.data)
            .then((json: any[]) => {
                if (json.length) {
                    const bookingMap = keyBy(json, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'bookings', map: bookingMap})
                }
            })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => dispatch(transactionsRequest(false)))
    }

export const changeSubscriptionSource = (subscriptionId: string, sourceId: string) =>
    (dispatch: any) => {
        dispatch(transactionsRequest(true))

        return apiClient.post(`/subscription/${subscriptionId}/source`, {source: sourceId})
            .then(resp => resp.data)
            .then((json: any) => {
                if (json.subscription) {
                    const subscriptionMap: any = { }
                    subscriptionMap[json.subscription.id] = json.subscription
                    dispatch({type: 'entities/UPDATE', name: 'subscription', map: subscriptionMap})
                }
            })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => dispatch(transactionsRequest(false)))
    }

export const rollbackTransaction = (recordId: string, recordType: string) =>
    (dispatch: any) => {
        dispatch(transactionsRequest(true))

        return apiClient.post(`/${recordType.toLowerCase()}/${recordId}/rollback`)
            .then(resp => resp.data)
            .then((json: any) => {
                if (!json) return

                if (json.bookings?.length) {
                    const bookingMap = keyBy(json.bookings, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'bookings', map: bookingMap})
                }
                if (json.booking) {
                    const bookingMap: any = { }
                    bookingMap[json.booking.id] = json.booking
                    dispatch({type: 'entities/UPDATE', name: 'bookings', map: bookingMap})
                }
                if (json.invoices?.length) {
                    const invoiceMap = keyBy(json.invoices, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'invoices', map: invoiceMap})
                }
                if (json.invoice) {
                    const invoiceMap: any = { }
                    invoiceMap[json.invoice.id] = json.invoice
                    dispatch({type: 'entities/UPDATE', name: 'invoices', map: invoiceMap})
                }
            })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => dispatch(transactionsRequest(false)))
    }

export const sendClientReceipt = (recordId: string, recordType: string) =>
    (dispatch: any) => {
        dispatch(transactionsRequest(true))

        return apiClient.post(`/${recordType.toLowerCase()}/${recordId}/receipt`)
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => dispatch(transactionsRequest(false)))
    }

export const confirmBooking = (record: any) =>
    (dispatch: any) => {
        dispatch(transactionsRequest(true))

        const postData: any = {
            gratuity: record.gratuity,
            gratuity_type: record.gratuity_type,
            source: record.source
        }
        if (!!record.is_pos) {
            postData['is_pos'] = true
        }
        if (!!record.confirm_all) {
            postData['confirm_all'] = true
        }
        if (!!record.intent) {
            postData['intent'] = record.intent
        }

        return apiClient.post(`/booking/${record.id}/confirm`, postData)
            .then(resp => resp.data)
            .then(json => {
                if (json.booking) {
                    const bookingMap: any = {}
                    bookingMap[json.booking.id] = json.booking
                    dispatch({type: 'entities/UPDATE', name: 'bookings', map: bookingMap})
                }
                if (json.client) {
                    const personMap: any = {}
                    personMap[json.client.id] = json.client
                    dispatch({type: 'entities/UPDATE', name: 'clients', map: personMap})
                }
                if (json.payee) {
                    const payeeMap: any = {}
                    payeeMap[json.payee.id] = json.payee
                    dispatch({type: 'entities/UPDATE', name: 'payees', map: payeeMap})
                }
                return json
            })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => dispatch(transactionsRequest(false)))
    }

export const chargeBooking = (record: any) =>
    (dispatch: any) => {
        dispatch(transactionsRequest(true))

        const postData: any = {
            rate: record?.rate ?? 0,
            type: "pay",
            is_pos: true,
            allow_surcharge: record.allow_surcharge,
            taxcode: !!record.nexus ? record.taxcode ?? "" : "",
            tax: !!record.nexus ? record.tax ?? "" : "",
            nexus: !!record.nexus ? record.nexus : "",
            location: !!record.nexus ? record.location ?? "" : "",
            discount: Number(record.discount) > 0 ? record.discount : "",
            discount_type: Number(record.discount) > 0 ? record.discount_type : "",
        }
        if (record.lines) {
            postData['lines'] = record.lines
        }
        if (!!record.source) {
            postData['source'] = record.source
        }
        if (!!record.gratuity) {
            postData['gratuity'] = record.gratuity
        }
        if (!!record.gratuity_type) {
            postData['gratuity_type'] = record.gratuity_type
        }
        if (!!record.intent) {
            postData['intent'] = record.intent
        }

        return apiClient.post(`/booking/${record.id}/complete`, postData)
            .then(resp => resp.data)
            .then(json => {
                if (json.booking) {
                    const bookingMap: any = {}
                    bookingMap[json.booking.id] = json.booking
                    dispatch({type: 'entities/UPDATE', name: 'bookings', map: bookingMap})
                }
                if (json.client) {
                    const personMap: any = {}
                    personMap[json.client.id] = json.client
                    dispatch({type: 'entities/UPDATE', name: 'person', map: personMap})
                }
                if (json.payee) {
                    const payeeMap: any = {}
                    payeeMap[json.payee.id] = json.payee
                    dispatch({type: 'entities/UPDATE', name: 'payees', map: payeeMap})
                }
                return json
            })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => dispatch(transactionsRequest(false)))
    }

export const chargeEstimate = (record: any) =>
    (dispatch: any) => {
        dispatch(transactionsRequest(true))

        const postData: any = {
            rate: record?.rate ?? 0,
            type: "pay",
            is_pos: true,
            allow_surcharge: record.allow_surcharge,
            taxcode: !!record.nexus ? record.taxcode ?? "" : "",
            tax: !!record.nexus ? record.tax ?? "" : "",
            nexus: !!record.nexus ? record.nexus : "",
            location: !!record.nexus ? record.location ?? "" : "",
            discount: Number(record.discount) > 0 ? record.discount : "",
            discount_type: Number(record.discount) > 0 ? record.discount_type : "",
        }
        if (record.lines) {
            postData['lines'] = record.lines
        }
        if (!!record.source) {
            postData['source'] = record.source
        }
        if (!!record.intent) {
            postData['intent'] = record.intent
        }
        if (!!record.gratuity) {
            postData['gratuity'] = record.gratuity
        }
        if (!!record.gratuity_type) {
            postData['gratuity_type'] = record.gratuity_type
        }

        return apiClient.post(`/estimate/${record.id}/charge`, postData)
            .then(resp => resp.data)
            .then(json => {
                if (json.estimate) {
                    const estimateMap: any = {}
                    estimateMap[json.estimate.id] = json.estimate
                    dispatch({type: 'entities/UPDATE', name: 'estimates', map: estimateMap})
                }
                if (json.client) {
                    const personMap: any = {}
                    personMap[json.client.id] = json.client
                    dispatch({type: 'entities/UPDATE', name: 'person', map: personMap})
                }
                if (json.payee) {
                    const payeeMap: any = {}
                    payeeMap[json.payee.id] = json.payee
                    dispatch({type: 'entities/UPDATE', name: 'payees', map: payeeMap})
                }
                return json
            })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => dispatch(transactionsRequest(false)))
    }

export const skipNextRenewal = (subscriptionId: string) =>
    (dispatch: any) => {
        dispatch(transactionsRequest(true))

        return apiClient.post(`/subscription/${subscriptionId}/skip`)
            .then(resp => resp.data)
            .then(json => {
                if (json.subscription) {
                    const subscriptionMap: any = {}
                    subscriptionMap[json.subscription.id] = json.subscription
                    dispatch({type: 'entities/UPDATE', name: 'subscriptions', map: subscriptionMap})
                }
                return json.subscription
            })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => dispatch(transactionsRequest(false)))
    }

type ConfirmSubscriptionProps = {
    subscriptionId: string,
    sourceId?: string,
    pos: boolean,
    gratuity?: string,
    gratuityType?: string
}

export const confirmSubscription = ({subscriptionId, sourceId, pos, gratuity, gratuityType}: ConfirmSubscriptionProps) =>
    (dispatch: any) => {
        dispatch(transactionsRequest(true))
        return apiClient.post(`/subscription/${subscriptionId}/confirm`, {
                is_pos: pos,
                source: sourceId ?? "",
                gratuity,
                gratuity_type: gratuityType
            })
            .then(resp => resp.data)
            .then(json =>json?.subscription ? json.subscription as Partial<Subscription> : undefined)
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => dispatch(transactionsRequest(false)))
    }

export const sendReceipt = (id: string) => {
    return (dispatch: any) => apiClient.post(`/payment/${id}/receipt`)
        .then(resp => resp.data)
        .then(message => {
            if (message) {
                const messageMap: any = { }
                messageMap[message.id] = message
                dispatch({type: 'entities/UPDATE', name: 'messages', map: messageMap})
                return message
            }
        })
        .catch((error) => {
            dispatch(processError(error))
            return Promise.reject()
        })
}

export const sendMarkPaidReceipt = (id: string) => {
    return (dispatch: any) => apiClient.post(`/charge/${id}/receipt`)
        .then(resp => resp.data)
        .then(message => {
            if (message) {
                const messageMap: any = { }
                messageMap[message.id] = message
                dispatch({type: 'entities/UPDATE', name: 'messages', map: messageMap})
                return message
            }
        })
        .catch((error) => {
            dispatch(processError(error))
            return Promise.reject()
        })
}

type RefundPayload = {
    amount: number,
    reason: string,
    details?: string,
}

export const submitRefund = (id: string, payload: RefundPayload) => {
    return (dispatch: any) => apiClient.post(`/payment/${id}/refund`, payload)
        .then(resp => resp.data)
        .then(json => {
            if (json.payment) {
                const paymentMap: any = { }
                paymentMap[json.payment.id] = json.payment
                dispatch({type: 'entities/UPDATE', name: 'payments', map: paymentMap})
                return json.payment
            }
        })
        .catch((error) => {
            dispatch(processError(error))
            return Promise.reject()
        })
}

export const submitChargeRefund = (id: string, payload: RefundPayload) => {
    return (dispatch: any) => apiClient.post(`/charge/${id}/refund`, payload)
        .then(resp => resp.data)
        .then(json => {
            if (json.payment) {
                const chargeMap: any = { }
                chargeMap[json.charge.id] = json.charge
                dispatch({type: 'entities/UPDATE', name: 'charges', map: chargeMap})
                return json.charge
            }
        })
        .catch((error) => {
            dispatch(processError(error))
            return Promise.reject()
        })
}

export const cancelPackage = (id: string) =>
    (dispatch: any) => {
        dispatch(transactionsRequest(true))
        return apiClient.post(`/package/${id}/cancel`)
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => dispatch(transactionsRequest(false)))
    }

export const fetchBalances = () =>
    (dispatch: any) =>
        apiClient.get('/balances')
            .then(resp => resp.data)
            .then(json => {
                if (json.balances) {
                    const balanceMap = keyBy(json.balances, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'balances', map: balanceMap})
                }

                if (json.payments) {
                    const paymentMap = keyBy(json.payments, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'payments', map: paymentMap})
                }

                if (json.deposits) {
                    const depositMap = keyBy(json.deposits, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'deposits', map: depositMap})
                }

                return json
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })

export const cancelEvent = (id: string) =>
    (dispatch: any) => {
        return apiClient.post('/events/cancel', {event_id: id})
            .then(resp => resp.data)
            .then(json => {
                if (json) {
                    const eventMap: any = {}
                    eventMap[json.id] = json
                    dispatch({type: 'entities/UPDATE', name: 'events', map: eventMap})
                }
                return json
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }