import React from "react";
import {AppHeader} from "../app/AppHeader";
import { CheckingEdit } from "../settings/payment-methods/CheckingEdit";

export const GoLiveAddChecking = () => {
    return (
        <AppHeader middleWidget={null}
                   showBackButton
                   title="Grow Your Business">
            <div className="servicesSettings onboarding launchBusiness">
                <CheckingEdit />
            </div>
        </AppHeader>
    )
}