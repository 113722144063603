import React, {useEffect, useState} from 'react'
import {RootState} from '../../store'
import {push} from 'connected-react-router'
import {useDispatch, useSelector} from "react-redux"
import {Spinner} from '@zendeskgarden/react-loaders'
import debounce from 'lodash.debounce'
import {ContactAvatar} from './ContactAvatar'
import {
    clearSearch,
    searchContacts,
    syncContacts
} from '../../modules/contacts'
import {generateCancelToken} from '../../modules/apiclient'
import {e164ToPhone} from '../../utils/phone'
import {AppHeader} from '../app/AppHeader'
import {TypeAheadSearchBar} from '../app/TypeAheadSearchBar'
import './contacts.css'
import {PSButtonPrimary} from '../app/PSButton'

export const ContactView = () => {
    const dispatch = useDispatch()
    const state = useSelector((state: RootState) => state.contacts)

    // Initial page load
    useEffect(() => {
        const cancelToken = generateCancelToken()
        if (state.contacts.length === 0) {
            dispatch(syncContacts('', cancelToken.token))
        }

        return () => {
           cancelToken.cancel()
        }
    }, [dispatch, state.contacts.length])

    const [listHeight, setListHeight] = useState(window.innerHeight-82)
    useEffect(() => {
        const handleResize = () => {
            setListHeight(window.innerHeight-82)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    // Automatically load more contacts when the scroll bar is near the bottom
    useEffect(() => {
        const loadMore = (e?: any) => {
            if (e) {
                e.preventDefault()
            }

            const { hasMore, lastKey } = state.pagination

            if (hasMore) {
                dispatch(syncContacts(lastKey))
            }
        }

        const handleScroll = debounce(() => {
            const contactList = document.getElementById('contactList')
            if (!contactList) return
            const percentToBottom = contactList.scrollTop / (contactList.scrollHeight - contactList.offsetHeight)
            if (percentToBottom > 0.65 && !state.isSending) {
                console.log('Load more')
                loadMore()
            }
        }, 200)

        const contactList = document.getElementById('contactList')
        contactList!.addEventListener('scroll', handleScroll)

        return function cleanup () {
            contactList!.removeEventListener('scroll', handleScroll)
        }
    }, [dispatch, state.isSending, state.pagination])

    let loadingIndicator
    if (state.isSending || state.isSearching) {
        loadingIndicator = (
            <div style={{textAlign: 'center', padding: '128px 0'}}>
                <Spinner size={'128'} color="#314A68" />
            </div>
        )
    }

    const clientSearch = (
        <div style={{display: 'flex', margin: 'auto', borderRadius: '10px', flex: '1', justifyContent: 'center', alignContent: 'center', height: '40px', minWidth: '33%'}}>
            <TypeAheadSearchBar stateField="contacts"
                                clearSearch={clearSearch}
                                search={searchContacts}
                                searchBarPlaceholder="Search clients by name or number"
            />
        </div>
    )

    let contactList = []
    if (state.didSearch && !state.isSearching)
        contactList = state.searchResults
    else if (!state.didSearch && !state.isSearching)
        contactList = state.contacts

    const title = (
    <div style={{display: 'flex'}}>
        <div style={{flex: '1', padding: '8px 62px 0 0'}}>
            <p>Clients</p>
        </div>
            <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                        onClick={() => {
                                            dispatch(push('/client/edit/'))
                                            }}>
                Add client
            </PSButtonPrimary>

    </div>)
    return (
        <AppHeader title={title} middleWidget={clientSearch}>
            <div id="contactList" style={{height: listHeight}}>
                <table className='contactListTable'>
                    <thead>
                        <tr>
                            <th className="nameColumn">Name</th>
                            <th>Contact Info</th>
                            <th>Street Address</th>
                            <th>City, State, Zip</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.didSearch && !state.isSearching && state.searchResults.length === 0 && (
                            <tr className="noResults">
                                <td colSpan={4}>No matching clients found</td>
                            </tr>
                        )}
                        {contactList.map((c: any) => {
                            const nameParts = c.name.split(' ')
                            let firstNamePart, lastNamePart
                            if (nameParts.length > 1) {
                                lastNamePart = nameParts[nameParts.length - 1]
                                firstNamePart = nameParts.splice(0, nameParts.length - 1).join(' ')
                            }
                            else {
                                firstNamePart = c.name
                            }

                            const contactInfo = <span
                                style={{fontSize: '13px'}}><div>{c.email}</div><div>{e164ToPhone(c.e164)}</div></span>

                            const streetAddress = <>
                                <div>{c.line1}</div>
                                <div>{c.line2}</div>
                            </>

                            let cityState = c.city
                            if (c.state || c.postal_code) {
                                cityState += `, ${c.state} ${c.postal_code}`
                            }

                            return (
                                <tr onClick={e => dispatch(push(`/record/client/${c.id}`))}
                                    key={`contact-${c.id}`}
                                >
                                    <td className="nameColumn">
                                        <ContactAvatar user={c} />
                                        <span style={{
                                            marginLeft: '16px',
                                            verticalAlign: 'middle',
                                            color: '#000000'
                                        }}>
                                            {firstNamePart}&nbsp;
                                            <span
                                                style={{fontWeight: 700}}>{lastNamePart}</span>
                                        </span>
                                    </td>
                                    <td>{contactInfo}</td>
                                    <td>{streetAddress}</td>
                                    <td>{cityState}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {loadingIndicator}
            </div>
        </AppHeader>
    )
}