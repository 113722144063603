import React, {useCallback, useEffect, useMemo, useState} from "react";
import {AppHeader} from "../../app/AppHeader";
import {ReactComponent as ClientIcon} from "../../../icons/ps_general_person_nobg.svg";
import {useAppDispatch, usePSOwner, usePSUser} from "../../../hooks";
import {UserInstance} from "../../../models/User";
import {apiClient} from "../../../modules/apiclient";
import {SelectModal} from "../SelectModal";
import {LineItem, LineItemInstance} from "../../../models/LineItem";
import {push} from "connected-react-router";
import {Payment} from "../../../models/Payment";
import moment from "moment";
import {useLocation} from "react-router";
import keyBy from "lodash.keyby";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {Spinner} from "@zendeskgarden/react-loaders";
import { NumberPad } from "./NumberPad";
import {ReactComponent as CalculatorIcon} from "../../../icons/ps_calculator.svg";
import {ReactComponent as FourSquareIcon} from "../../../icons/ps_four_boxes.svg";
import {ReactComponent as ListIcon} from "../../../icons/ps_list_icon.svg";
import {ReactComponent as ItemsIcon} from "../../../icons/ps_items_board.svg";
import {ReactComponent as ProductIcon} from "../../../icons/ps_items_products.svg";
import {ReactComponent as ClassIcon} from "../../../icons/ps_items_classes.svg";
import {ReactComponent as ServiceIcon} from "../../../icons/ps_items_services.svg";
import {ReactComponent as CategoryIcon} from "../../../icons/ps_items_categories.svg";
import { ReactComponent as ChevronIcon } from "@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg";
import { fetchItems, SettingsState } from "../../../modules/settings";
import { Result } from "../../../utils";
import { PSButton, PSButtonPrimary } from "../../app/PSButton";
import { Button, ButtonGroup } from "@zendeskgarden/react-buttons";
import { ChargeItemCard } from "./ChargeItemCard";
import { currencyFormat } from "../../../utils/numbers";
import { ChargeSummaryItemCard } from "./ChargeSummaryItemCard";
import { Dropdown, Item, Menu, Trigger } from "@zendeskgarden/react-dropdowns";
import { Item as PSItem } from "../../../models/Item";
import { Accordion } from "@zendeskgarden/react-accordions";
import { ChargeEditItemModal } from "./ChargeEditItemModal";
import { ContactAvatar } from "../../contacts/ContactAvatar";

export const ChargeNew = () => {
    const dispatch = useAppDispatch()
    const state: {entities: any, settings: SettingsState} = useSelector((state: RootState) => ({
        entities: state.entities,
        settings: state.settings
    }))

    const routerLocation = useLocation()
    const locationState: any = routerLocation.state
    const paymentData = locationState?.payment

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        // Start fetching data to render what exists
        const promises = []
        if (Object.keys(state.entities.items).length === 0) {
            const p1 = new Promise<void>((resolve, reject) => {
                apiClient.post('/item/sync')
                    .then(resp => resp.data)
                    .then(json => {
                        const itemMap = keyBy(json.items, 'id')
                        dispatch({type: 'entities/UPDATE', name: 'items', map: itemMap})
                        resolve()
                    })
                    .catch(reject)
            })
            promises.push(p1)
        }

        if (Object.keys(state.entities.categories).length === 0) {
            const p2 = new Promise<void>((resolve, reject) => {
                apiClient.post('/category/sync')
                    .then(resp => resp.data)
                    .then(json => {
                        const categoryMap = keyBy(json.categories, 'id')
                        dispatch({type: 'entities/UPDATE', name: 'categories', map: categoryMap})
                        resolve()
                    })
                    .catch(reject)
            })
            promises.push(p2)
        }

        if (paymentData?.client && !state.entities.users[paymentData.client]) {
            const p3 = new Promise<void>((resolve, reject) => {
                apiClient.post('/client/sync', {person: paymentData.client})
                    .then(resp => resp.data)
                    .then(json => {
                        if (json.contacts?.length > 0) {
                            const user = json.contacts[0]
                            const userMap = {
                                [user.id]: user
                            }
                            dispatch({type: 'entities/UPDATE', name: 'users', map: userMap})
                        }
                        resolve()
                    })
                    .catch(reject)
            })
            promises.push(p3)
        }

        promises.push(Result(dispatch(fetchItems())))

        Promise.all(promises).then(() => setLoading(false))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, paymentData]) // ignore state.entities

    // Load existing data that we are editing
    useEffect(() => {
        if (!paymentData || loading) {
            return
        }

        setClient(state.entities.users[paymentData.client])
        setLineItems(paymentData.lines)
    }, [paymentData, loading, state.entities.users])

    const serviceItems = useMemo(() => state.settings.items.filter(item => item.type === 'service'), [state.settings.items])
    const productItems = useMemo(() => state.settings.items.filter(item => item.type === 'product'), [state.settings.items])
    const classItems = useMemo(() => state.settings.items.filter(item => item.type === 'class'), [state.settings.items])
    const [filterText, setFilterText] = useState('')
    const [filteredItems, _setFilteredItems] = useState<PSItem[]>([])
    const setFilterItems = useCallback((items: PSItem[]) => {
        const subSet = items.filter(i => !['package', 'subscription', 'gift'].includes(i.type))
        _setFilteredItems(!filterText ? subSet : subSet.filter(
            item => item.name.toLowerCase().indexOf(filterText) > -1))
    }, [filterText])

    useEffect(() => {
        setFilterItems(state.settings.items)
    }, [state.settings.items, setFilterItems])

    // Form state
    const [client, setClient] = useState<UserInstance | undefined>(locationState?.client)
    const [keypadTotal, setKeypadTotal] = useState(0)
    const [lineItems, setLineItems] = useState<LineItemInstance[]>([])
    const [nextEnabled, setNextEnabled] = useState(false)

    const [selectModalType, setSelectModalType] = useState<'clients' | 'items' | undefined>()

    const user = usePSUser()
    const owner = usePSOwner()

    const onSelected = (item: any[]) => {
        setClient(item[0])
        setSelectModalType(undefined)
    }

    const itemsTotal = useCallback(() => {
        const payment = new Payment({
            lines: lineItems,
            taxcode: user?.taxcode || '',
            allow_surcharge: owner?.allow_surcharge || false,
            date: moment().toISOString(),
        })

        return payment.calculateTotal()
    }, [lineItems, owner?.allow_surcharge, user?.taxcode])

    const addKeypadItem = useCallback((keypadTotal: number) => {
        setLineItems((currentVal) => {
            const lineItem = new LineItem({})
            lineItem.total = keypadTotal
            lineItem.rate = keypadTotal
            lineItem.quantity = Number(1)
            lineItem.taxable = owner?.allow_salestax ?? false
            lineItem.discountable = owner?.allow_discount ?? false
            lineItem.type = 'custom'
            lineItem.date = moment().format('YYYY-MM-DD')
            lineItem.itemType = 'service'

            currentVal.push(lineItem)
            return [ ...currentVal ]
        })
    }, [owner?.allow_discount, owner?.allow_salestax])

    const totalCharge = useCallback(() => {
        const payment = new Payment({
            total: keypadTotal + itemsTotal(),
            lines: lineItems,
            taxcode: owner?.taxcode || '',
            allow_surcharge: owner?.allow_surcharge || false,
            date: moment().toISOString(),
        })

        return payment.calculateTotal()
    }, [keypadTotal, itemsTotal, lineItems, owner?.taxcode, owner?.allow_surcharge])

    useEffect(() => {
        setNextEnabled((!!client || !!owner?.allow_mark) && totalCharge() > 0)
    }, [client, totalCharge, owner?.allow_mark])

    const onNextHandler = () => {
        if (keypadTotal) {
            addKeypadItem(keypadTotal)
        }

        const payment = new Payment({
            owner: user?.id || '',
            vendor: user?.employer || user?.id || '',
            client: client?.id || '',
            total: itemsTotal(),
            lines: lineItems,
            taxcode: user?.taxcode || '',
            allow_surcharge: owner?.allow_surcharge || false,
            date: moment().toISOString(),
        })

        dispatch(push('/charge/new/confirm', {payment}))
    }

    const [showEditItem, setShowEditItem] = useState(false)
    const [selectedView, setSelectedView] = useState<'keypad'|'items'|'categories'>('keypad');
    const [dropdownSelection, setDropdownSelection] = useState<'items'|'services'|'products'|'classes'|'categories'>('items');
    const [selectedItemsView, setSelectedItemsView] = useState<'widget'|'list'>('widget');
    const [dropdownRotate, setDropdownRotated] = useState(false)
    const [editItemIndex, setEditItemIndex] = useState(0)

    const getItemCount = useCallback(() => {
        if (selectedView === 'items') {
            return filteredItems.length
        }
        else if (selectedView === 'categories') {
            const categories: any[] = Object.values(state.entities.categories).sort((c :any) => c.seqnum)
            return categories.map((category) => {
                const itemIds = category.listings.filter((l: string) => filteredItems.map(z => z.id)?.includes(l))
                return (itemIds.length ?? 0)
            }).reduce((a, b) => a + b)
        }
        return 0
    }, [filteredItems, selectedView, state.entities.categories])

    const onSelect = (item: 'items'|'services'|'products'|'classes'|'categories') => {
        setDropdownSelection(item)
        setDropdownRotated(false)
        if (['items', 'services', 'products', 'classes'].includes(item)) {
            setSelectedView('items')
        }
        else if (item === 'categories') {
            setSelectedView('categories')
        }
    }

    const [itemsDropdownLabel, setItemsDropdownLabel] = useState<JSX.Element>()
    const onKeypadButton = () => {
        setFilterText('')
        setSelectedView('keypad')
    }

    useEffect(() => {
        if (dropdownSelection === 'items') {
            setItemsDropdownLabel(<>
                <ItemsIcon height={22} width={22} /><span className="text">All Items ({state.settings.items.length})</span>
            </>)
            setFilterItems(state.settings.items)
        }
        else if (dropdownSelection === 'services') {
            setItemsDropdownLabel(<>
                <ServiceIcon height={22} width={22} /><span className="text">Services ({serviceItems.length})</span>
            </>)
            setFilterItems(serviceItems)
        }
        else if (dropdownSelection === 'products') {
            setItemsDropdownLabel(<>
                <ProductIcon height={22} width={22} /><span className="text">Products ({productItems.length})</span>
            </>)
            setFilterItems(productItems)
        }
        else if (dropdownSelection === 'classes') {
            setItemsDropdownLabel(<>
                <ClassIcon height={22} width={22} /><span className="text">Classes ({classItems.length})</span>
            </>)
            setFilterItems(classItems)
        }
        else if (dropdownSelection === 'categories') {
            setItemsDropdownLabel(<>
                <CategoryIcon height={22} width={22} /><span className="text">Categories ({Object.keys(state.entities.categories).length})</span>
            </>)
            setFilterItems(state.settings.items)
        }
    }, [classItems, dropdownSelection, productItems, serviceItems, setFilterItems, state.entities.categories, state.settings.items])

    const getMiddleWidget = () => {
        return selectedView !== 'keypad' ?
            <div style={{display: 'flex', margin: 'auto', borderRadius: '10px', flex: '1', justifyContent: 'center', alignContent: 'center', height: '40px', minWidth: '33%'}}>
                <div className="searchBar">
                    <input type="text"
                            placeholder={`Search`}
                            onChange={e => setFilterText(e.target.value.toLowerCase())}
                    />
                </div>
            </div> : null
    }

    const renderItemGroup = useCallback((items: PSItem[], categoryId?: string) => {
        if (!items.length) {
            return (
                <div style={{margin: '220px 0', textAlign: 'center'}}>
                    <img alt="card reader" src="/icons/empty-box.png" />
                    <div className="gray">Nothing here yet. You got this!</div>
                </div>
            )
        }
        return <div className={`itemWrapper ${selectedItemsView}`}>
            {items.map((item) => {
                return (
                    <ChargeItemCard
                        key={`item-${item.id}${categoryId}`}
                        view={selectedItemsView}
                        item={item}
                        selected={!!lineItems?.find(l => l.item === item.id)}
                        onItemSelected={(item) => {
                            setLineItems((currentVal) => {
                                const lineItem = new LineItem({})
                                lineItem.total = Number(item.rate)
                                lineItem.rate = Number(item.rate)
                                lineItem.item = item.id
                                lineItem.quantity = Number(1)
                                lineItem.memo = item.name
                                lineItem.taxable = item.allow_salestax
                                lineItem.discountable = owner?.allow_discount ?? Number(item.discount) > 0
                                lineItem.type = 'item'
                                lineItem.date = moment().format('YYYY-MM-DD')
                                lineItem.itemType = item.type

                                currentVal.push(lineItem)
                                return [ ...currentVal ]
                            })
                        }}
                        onItemDeselected={(item) => {
                            setLineItems(currentVal => {
                                const ix = currentVal.findIndex(line => line.item === item.id)
                                if (ix > -1) currentVal.splice(ix, 1)
                                return [ ...currentVal ]
                            })
                        }}
                    />
                )
            })}
        </div>
    }, [lineItems, selectedItemsView, owner?.allow_discount])

    const renderCategories = useCallback(() => {
        if (!getItemCount()) {
            return renderItemGroup(filteredItems)
        }
        const categories = Object.values(state.entities.categories).sort((c :any) => c.seqnum)
        return categories.map((category: any) => {
            const itemIds = category.listings.filter((l: string) => filteredItems.map(z => z.id)?.includes(l))
            if (itemIds.length) {
                return <Accordion className="category-wrap" key={`category-${category.id}`} level={2} style={{ marginTop: '30px'}}>
                    <Accordion.Section style={{ padding: '0' }}>
                        <Accordion.Header>
                            <Accordion.Label style={{ padding: 0 }}>
                                {category.name} ({itemIds.length})
                            </Accordion.Label>
                        </Accordion.Header>
                        <div className="separator"></div>
                        <Accordion.Panel style={{ padding: '0' }}>
                            {renderItemGroup(filteredItems.filter(i => itemIds.includes(i.id)), category.id)}
                        </Accordion.Panel>
                    </Accordion.Section>
                </Accordion>
            }
            else {
                return <></>
            }
        })
    }, [filteredItems, getItemCount, renderItemGroup, state.entities.categories])

    const [top, setTop] = useState(0)
    useEffect(() => {
        const headerHeight = 77
        const viewHeight = window.innerHeight - headerHeight
        const lineItemsHeight = 93 * lineItems?.length // 93px height per row
        const chargeSummaryHeight = 230 + lineItemsHeight // 230px height no items

        setTop(chargeSummaryHeight > viewHeight ? viewHeight - chargeSummaryHeight : 0)
    }, [lineItems?.length])

    return (
        <AppHeader title="Charge" showBackButton middleWidget={getMiddleWidget()}>
            <div id="charge-screen" style={{overflowY: 'auto'}}>
                {loading && (
                    <div className="loadingOverlay">
                        <div style={{textAlign: 'center', padding: '128px 0'}}>
                            <Spinner size="128" color="#314A68" />
                            <h1>Loading data...</h1>
                        </div>
                    </div>
                )}
                <div className="chargeClient transaction servicesSettings" style={{ display: 'flex', maxWidth: '1920px' }}>
                    <div className="left">
                        <div className="header">
                            <h1>Items</h1>
                            <span style={{marginLeft: 'auto'}}>{`(${lineItems?.length ?? 0} selected)`}</span>
                        </div>

                        <div className="separator" />

                        <div className="controls">
                            <div className={`viewButtons ${selectedView}`}>
                                <PSButton className='keypadButton' style={{height: '48px'}} onClick={onKeypadButton}><CalculatorIcon height={22} width={22} /><span className="text">Keypad</span></PSButton>
                                <Dropdown
                                    onSelect={onSelect}
                                    onStateChange={options => options.hasOwnProperty('isOpen') && setDropdownRotated(options.isOpen ?? false)}
                                >
                                    <Trigger>
                                        <PSButton className='itemsButton' style={{height: '48px'}}>
                                            {itemsDropdownLabel}
                                            <Button.EndIcon isRotated={dropdownRotate}>
                                                <ChevronIcon />
                                            </Button.EndIcon>
                                        </PSButton>
                                    </Trigger>
                                    <Menu className="menu">
                                        <Item value="items"><ItemsIcon height={22} width={22} /><span className="text">All Items ({state.settings.items.length})</span></Item>
                                        {!!Object.keys(state.entities.categories).length && <Item value="categories"><CategoryIcon height={22} width={22} /><span className="text">Categories ({Object.keys(state.entities.categories).length})</span></Item>}
                                        {owner?.allow_services && <Item value="services"><ServiceIcon height={22} width={22} /><span className="text">Services ({serviceItems.length})</span></Item>}
                                        {owner?.allow_product && <Item value="products"><ProductIcon height={22} width={22} /><span className="text">Products ({productItems.length})</span></Item>}
                                        {owner?.allow_class && <Item value="classes"><ClassIcon height={22} width={22} /><span className="text">Classes ({classItems.length})</span></Item>}
                                    </Menu>
                                </Dropdown>
                            </div>
                            {['items', 'categories'].includes(selectedView) && (
                                <ButtonGroup className="buttonGroup" selectedItem={selectedItemsView} onSelect={setSelectedItemsView}>
                                    <Button value='list' className={selectedItemsView ===  'list' ? 'selected' : ''}>
                                        <ListIcon height={25} width={25} />
                                    </Button>
                                    <Button value='widget' className={selectedItemsView ===  'widget' ? 'selected' : ''}>
                                        <FourSquareIcon height={25} width={25} />
                                    </Button>
                                </ButtonGroup>
                            )}
                        </div>

                        {selectedView === 'keypad' &&
                            <NumberPad
                                value={keypadTotal}
                                onInputChanged={(data: string) => {
                                    setKeypadTotal(Number(data))
                                    return true
                                }}
                                onComplete={(data: number) => {
                                    addKeypadItem(data)
                                    setKeypadTotal(0)
                                }}
                            />
                        }
                        {['items', 'categories'].includes(selectedView) && !!filterText && <div className="searchTerm" style={{ marginTop: '30px' }}>
                            {`Showing ${getItemCount()} results for "${filterText}"`}
                        </div>}
                        {selectedView === 'items' && <>
                            <div style={{ marginTop: '30px' }}>
                                {renderItemGroup(filteredItems)}
                            </div>
                        </>}
                        {selectedView === 'categories' && (
                            renderCategories()
                        )}
                    </div>
                    <div className="right">
                        <div className="form">
                            <div className="formFieldSelection">
                                <div className="display">
                                    <div className="icon">{client ? <ContactAvatar user={client} /> : <ClientIcon />}</div>
                                    <div className="labels">
                                        <div className="label">{!owner?.allow_mark ? "Client (required)" : "Client"}</div>
                                        {client && (
                                            <div className="selected" style={{width: '510px'}}>{client.name}</div>
                                        )}
                                        {!client&& (
                                            <div className="helper">Select a client</div>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="buttonLink"
                                            onClick={() => setSelectModalType('clients')}
                                    >
                                        {"+ Add client"}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="chargeSummary" style={{position: 'sticky', top: `${top}px`}}>
                            <div className="title">Charge Summary</div>
                            <div className="separator" />
                            <div className="itemSummary">
                                {lineItems.map((line: any, index: number) => {
                                        return <div key={`summary-${line.item}`} >
                                            <ChargeSummaryItemCard
                                                line={line}
                                                index={index}
                                                onDeleted={(index) => {
                                                    setLineItems(currentVal => {
                                                        currentVal.splice(index, 1)
                                                        return [ ...currentVal ]
                                                    })

                                                }}
                                                onQuantityChanged={(index, quantity) => {
                                                    setLineItems(currentVal => {
                                                        currentVal[index].quantity = quantity
                                                        currentVal[index].total = currentVal[index].rate * quantity
                                                        return [ ...currentVal ]
                                                    })
                                                }}
                                                onEdit={(index) => {
                                                    setEditItemIndex(index)
                                                    setShowEditItem(true)
                                                }}
                                            />
                                            <div className="separator" />
                                        </div>
                                    })}
                                    <div className="total" >
                                        <span className="text">Subtotal</span>
                                        <span className="amount">{currencyFormat(totalCharge())}</span>
                                    </div>
                                    <PSButtonPrimary style={{height: '40px', fontSize: '15px'}} onClick={onNextHandler} disabled={!nextEnabled}>
                                        Charge
                                    </PSButtonPrimary>
                            </div>
                        </div>
                    </div>
                </div>
                {selectModalType && (
                        <SelectModal type={selectModalType}
                                    multiSelect={false}
                                    currentSelections={client ? [client] : []}
                                    onSelect={onSelected}
                                    onCancel={() => setSelectModalType(undefined) }
                        />
                    )}

                {showEditItem && (
                        <ChargeEditItemModal item={lineItems[editItemIndex]}
                                            onDone={(item) =>  {
                                                setLineItems((currentVal) => {
                                                    currentVal[editItemIndex] = item
                                                    return [ ...currentVal ]
                                                })
                                                setShowEditItem(false)
                                            }}
                                            onCancel={() => setShowEditItem(false) }
                        />
                    )}
            </div>
        </AppHeader>
    )
}