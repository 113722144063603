import React from "react";

type Props = {
    created?: boolean;
    reviewed?: boolean;
    sent?: boolean;
    firstStep?: string;
    secondStep?: string;
    thirdStep?: string;
}

export const TransactionProgress = ({created, reviewed, sent, firstStep, secondStep, thirdStep}: Props) => {
    return (
        <div className="transactionProgress">
            <div className="step">
                <img src={`/check-${created ? 'on' : 'off'}.svg`} width="16" height="16" alt={created ? "Completed" : "Uncompleted"}/>
                <div className={created ? 'completed' : undefined}>{firstStep ?? 'Create'}</div>
            </div>
            <div className="separator" />
            <div className="step">
                <img src={`/check-${reviewed ? 'on' : 'off'}.svg`} width="16" height="16"  alt={reviewed ? "Completed" : "Uncompleted"}/>
                <div className={reviewed ? 'completed' : undefined}>{secondStep ?? 'Review'}</div>
            </div>
            <div className="separator" />
            <div className="step">
                <img src={`/check-${sent ? 'on' : 'off'}.svg`} width="16" height="16"  alt={sent ? "Completed" : "Uncompleted"}/>
                <div className={sent ? 'completed' : undefined}>{thirdStep ?? 'Send'}</div>
            </div>
        </div>
    )
}