import React, {useCallback, useEffect, useState} from "react";
import {useAppDispatch} from "../../../hooks";
import {
    fetchSources,
    saveUser,
    SettingsState
} from "../../../modules/settings";
import {
    Checkbox,
    Field,
    Hint,
    Label,
} from "@zendeskgarden/react-forms";
import {RootState} from "../../../store";
import {useSelector} from "react-redux";
import {Prompt} from "react-router";
import {PSButtonPrimary} from "../../app/PSButton";
import {Dots} from "@zendeskgarden/react-loaders";
import localforage from 'localforage'
import {isEmpty, capitalizeFirstLetters, determineDefaultSource, SourceType} from '../../../utils';
import { UserInstance } from "../../../models/User";

export const PayoutEdit = () => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)
    const [saveEnabled, setSaveEnabled] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)

    const [checkingAccountNumber, setCheckingAccountNumber] = useState('')
    const [instantPayoutSource, setInstancePayoutSource] = useState('')

    const [areInstancePayoutsEnabled, setAreInstancePayoutEnabled] = useState(false)
    const toggleOnExplainer = <><br/><br/>Automated regular payouts are run at 12pm Pacific on weekdays (including holidays), and funds are deposited in your bank account the next business day (not including weekends or bank holidays).<br/><br/>Want automated payouts to be deposited faster? If you have an instant transfer eligible debit card, toggle on Daily instant payouts above. An automated payout will be run everyday (including weekends) starting at 12pm Pacific as usual, but the funds will be available in your account within minutes for an additional 1% (minimum $1 fee).<br/><br/>With an eligible debit card, you can also manually initiate an instant payout at any time using the cash out option in the Pending payouts dashboard.</>
    const toggleOffExplainer = <><br/><br/>Your weekday deposit will be delivered to you within 15 minutes of being sent for 1% (min $1). You can still cash out immediately for any eligible payments. If you have an instant eligible debit card added we will send deposits there. Otherwise we will send deposits to your bank account.</>

    const [user, setUser] = useState<UserInstance | undefined>()

    const getDefaultCreditSource = useCallback(() =>
            determineDefaultSource(SourceType.Credit, state.sources)
        , [state.sources]);
    const getInstantPayoutSource = useCallback(() =>
            state.sources
                .sort(source => source.created_date)
                .find(source => source.instant === true)
        , [state.sources])

    useEffect(() => {
        dispatch(fetchSources())
    }, [dispatch])


    useEffect(() => {
        const instantSource = getInstantPayoutSource()
        const defaultCreditSource = getDefaultCreditSource()

        if (!isEmpty(instantSource)) {
            setInstancePayoutSource(`${capitalizeFirstLetters(instantSource.card_brand)} - ${instantSource.card_number.substring(instantSource.card_number.length - 4)}`)
        }
        if (!isEmpty(defaultCreditSource)) {
            setCheckingAccountNumber(defaultCreditSource.account_number.substring(defaultCreditSource.account_number.length - 4))
        }

    }, [getInstantPayoutSource, getDefaultCreditSource])

    const onSave = () => {
        if (state.isSending) return

        const userParams: any = {
            id: user?.id,
            instant_payout: areInstancePayoutsEnabled
        }

        // @ts-ignore
        dispatch(saveUser(userParams)).then(() => localforage.getItem('user')
            .then((user: any) => setUser(user)))
    }

    useEffect(() => setSaveEnabled(hasEdits), [hasEdits])

    useEffect(() => {
        if (!user) return

        setHasEdits(areInstancePayoutsEnabled !== user?.instant_payout)
    }, [user, areInstancePayoutsEnabled])

    useEffect(() => {
        if (!user) return

        setAreInstancePayoutEnabled(user?.instant_payout)
    }, [user])


    useEffect(() => {
        localforage.getItem('user').then((user: any) => setUser(user))
    }, [])

    let description = checkingAccountNumber ?
        <>Your checking account for payout is Checking - {checkingAccountNumber}.</> :
        <>You have not yet setup a checking account for payout.</>

    description = instantPayoutSource ?
        <>{description} Your debit card for instant payout is {instantPayoutSource}</> :
        <>{description} You have not yet setup a debit card for instant payout.</>

    description = areInstancePayoutsEnabled ? <>{description} {toggleOffExplainer}</> : <>{description} {toggleOnExplainer}</>

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Payouts</h1>
                <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!saveEnabled || state.isSending}>
                    {state.isSending ? <Dots /> : "Save"}
                </PSButtonPrimary>
            </div>
            <div className="form">

                <Field className="field">
                    <Checkbox checked={areInstancePayoutsEnabled}
                              disabled={state.isSending}
                              onChange={(e) => { setAreInstancePayoutEnabled(e.target.checked) }}>
                        <Label className="withHint">Daily instant payouts</Label>
                        <Hint>{state.loadingSources ? <Dots /> : description}</Hint>
                    </Checkbox>
                </Field>

            </div>

            <Prompt
                when={hasEdits && !state.isSending}
                message="Are you sure you'd like to leave this page without saving your changes?" />

        </div>
    )
}