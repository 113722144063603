import React, {useState} from "react";
import {Field as PSField} from "../../models/Field";
import {Checkbox, Field, Input, Label} from "@zendeskgarden/react-forms";
import {PSTextarea} from "../app/PSTextarea";
import {
    filterDecimalInput, filterMonetaryInput,
    filterNumericInput
} from "../../utils/numbers";
import InputMask from "react-input-mask";
import {Datepicker} from "@zendeskgarden/react-datepickers";
import {Item} from "@zendeskgarden/react-dropdowns";
import {PSMultiselect} from "../app/PSMultiselect";
import {PSDropdown} from "../app/PSDropdown";
import moment from "moment";

type Props = {
    field: PSField,
    currentValue: any,
    onChange: (value: any) => void,
}

export const CustomFieldEntry = ({field, currentValue, onChange}: Props) => {
    const [checked, setChecked] = useState(currentValue || false)
    const [value, setValue] = useState<any>(currentValue || '')
    const [selectedItems, setSelectedItems] = useState<any[]>(currentValue || [])

    const updateChecked = (newValue: boolean) => {
        setChecked(newValue)
        onChange(newValue)
    }
    const updateValue = (newValue: string) => {
        setValue(newValue)
        onChange(newValue)
    }
    const setItems =  (newItems: any[]) => {
        setSelectedItems(newItems)
        onChange(newItems)
    }

    let inputField = <div />
    switch (field.type) {
        case "checkbox":
            inputField = (
                <Field className="field">
                    <Checkbox checked={checked}
                              onChange={e => updateChecked(e.target.checked)}
                    >
                        <Label>{field.label}{field.is_required ? ' (required)' : ''}</Label>
                    </Checkbox>
                </Field>
            )
            break;
        case "currency":
            inputField = (
                <Field className="field">
                    <Label>{field.label}{field.is_required ? ' (required)' : ''}</Label>
                    <Input value={value}
                       onChange={e => filterMonetaryInput(e.target.value, updateValue)}
                       placeholder="Enter amount"
                       inputMode="numeric"
                    />
                </Field>
            )
            break;
        case "email":
            inputField = (
                <Field className="field">
                    <Label>{field.label}{field.is_required ? ' (required)' : ''}</Label>
                    <Input value={value}
                           onChange={e => updateValue(e.target.value)}
                           type="email"
                           placeholder="Enter email"
                    />
                </Field>
            )
            break;
        case "float":
            inputField = (
                <Field className="field">
                    <Label>{field.label}{field.is_required ? ' (required)' : ''}</Label>
                    <Input value={value}
                           onChange={e => filterDecimalInput(e.target.value, updateValue)}
                           placeholder="Enter number"
                           inputMode="decimal"
                    />
                </Field>
            )
            break;
        case "integer":
            inputField = (
                <Field className="field">
                    <Label>{field.label}{field.is_required ? ' (required)' : ''}</Label>
                    <Input value={value}
                           onChange={e => filterNumericInput(e.target.value, updateValue)}
                           placeholder="Enter number"
                           inputMode="numeric"
                    />
                </Field>
            )
            break;
        case "longtext":
            inputField = (
                <Field className="field">
                    <Label>{field.label}{field.is_required ? ' (required)' : ''}</Label>
                    <div style={{marginTop: '16px'}}>
                        <PSTextarea placeholder="Enter text"
                                    className=""
                                    minRows={3}
                                    maxLength={900}
                                    value={value}
                                    onChange={value => updateValue(value)}
                        />
                    </div>
                </Field>
            )
            break;
        case "phone":
            inputField = (
                <Field className="field">
                    <Label>{field.label}{field.is_required ? ' (required)' : ''}</Label>
                    <InputMask mask="999-999-9999"
                               value={value}
                               alwaysShowMask={false}
                               maskChar={''}
                               onChange={(e: any) => updateValue(e.target.value)}>
                        {(inputProps: any) => <Input {...inputProps}
                                                     className="mobileNumberInput"
                                                     placeholder="Enter mobile number" />
                        }
                    </InputMask>
                </Field>
            )
            break;
        case "date":
            inputField = (
                <Field className="field">
                    <Label>{field.label}{field.is_required ? ' (required)' : ''}</Label>
                    <Datepicker value={value ? moment(value).toDate() : new Date()}
                                onChange={date => updateValue(moment(date).format('YYYY-MM-DD'))}>
                        <Input placeholder="Select date" />
                    </Datepicker>
                </Field>
            )
            break;
        case "multiselect":
            inputField = (
                <PSMultiselect selected={selectedItems}
                               nameProperty="label"
                               onSelect={(selection) => setItems(selection.filter((s: any) => !!s.value))}
                               placeholder="Select one or more options"
                               label={field.label + (field.is_required ? ' (required)' : '')}
                >
                    {field.options.map(option => (
                        <Item key={option.value} value={option}>
                            <span>{option.label}</span>
                        </Item>
                    ))}
                </PSMultiselect>
            )
            break;
        case "select":
            inputField = (
                <PSDropdown selected={value}
                            nameProperty="label"
                            onSelect={(selection) => updateValue(selection)}
                            label={field.label + (field.is_required ? ' (required)' : '')}
                >
                    {field.options.map(option => (
                        <Item key={option.value} value={option}>
                            <span>{option.label}</span>
                        </Item>
                    ))}
                </PSDropdown>
            )
            break;
        case "text":
        case "":
        default:
            inputField = (
                <Field className="field">
                    <Label>{field.label}{field.is_required ? ' (required)' : ''}</Label>
                    <Input value={value}
                           onChange={e => updateValue(e.target.value)}
                           placeholder="Enter text"
                    />
                </Field>
            )
            break;
    }

    return inputField
}