import React from "react";
import {Dropdown, Item, Menu, Select, Field} from "@zendeskgarden/react-dropdowns";
import {Hint, Label} from "@zendeskgarden/react-forms";

type Props = {
    selected: any,
    disabled?: boolean,
    nameProperty: string,
    downshiftProperty?: string,
    onSelect: (s: any) => void,
    label?: string,
    hint?: string,
    placeholder?: React.ReactNode,
    disablePlaceholderClick?: boolean,
    onLearnMore?: () => void,
    children: React.ReactNode,
    selectWidth?: string,
    selectHeight?: string,
    marginTop?: string,
    paddingTop?: string,
}

export const PSDropdown = (props: Props) => {
    return (
        <Dropdown selectedItem={props.selected}
                  onSelect={(selection) => props.onSelect(selection)}
                  downshiftProps={{ itemToString: (item: any) => item && item[props.downshiftProperty ?? props.nameProperty]}}>
            <Field className="field">
                {props.label && (<Label className="label"
                       style={{paddingBottom: props.hint ? undefined : '14px'}}
                >
                    {props.label}
                </Label>)}
                {props.hint && (
                    <Hint className="hint">{props.hint}</Hint>
                )}
                <Select className="input"
                        disabled={props.disabled}
                        style={{width: props.selectWidth || undefined, height: props.selectHeight || undefined, marginTop: props.marginTop || undefined, paddingTop: props.paddingTop || undefined}}
                >
                    {props.selected && props.selected[props.nameProperty]}
                    {!props.selected && props.placeholder}
                </Select>
                {props.onLearnMore && (
                    <div className="buttonLink brightBlue"
                         style={{display: 'inline-block', paddingTop: 8}}
                         onClick={props.onLearnMore}>
                        Learn more &gt;
                    </div>
                )}
            </Field>
            <Menu>
                {props.placeholder && (
                    props.disablePlaceholderClick ?
                        <Item value="" disabled>{props.placeholder}</Item> :
                        <Item value="">{props.placeholder}</Item>
                )}
                {props.children}
            </Menu>
        </Dropdown>
    )
}