import React, {createRef, RefObject, useEffect, useState} from "react";
import {
    LearnMoreContractsBody,
    LearnMoreFormsBody,
    LearnMoreLeadTimeBody, LearnMorePackagesBody, LearnMoreSubscriptionsBody
} from "./LearnMore";
import {EnabledServiceSelection} from "./packages/SelectServicesModal";

type PhotoUploadProps = {
    disabled: boolean,
    setPhoto: (f: File) => void,
}

type PhotoUploadReturnType = [RefObject<HTMLInputElement>, () => void, () => void]

export const usePhotoUpload = ({disabled, setPhoto}: PhotoUploadProps): PhotoUploadReturnType => {
    const addPhotoRef = createRef<HTMLInputElement>()

    const selectPhoto = () => {
        if (disabled) return

        if (addPhotoRef.current) {
            addPhotoRef.current.value = ''
            addPhotoRef.current.click()
        }
    }

    const selectedPhoto = () => {
        if (!addPhotoRef.current) return
        if (addPhotoRef.current.files && addPhotoRef.current.files.length > 0) {
            setPhoto(addPhotoRef.current.files[0])
        }
    }

    return [addPhotoRef, selectPhoto, selectedPhoto]
}

type LearnMoreReturnType = [boolean, string, React.ReactNode, (f: string, v: boolean) => void, () => void]
export const useLearnMore = (): LearnMoreReturnType => {
    const [learnMoreVisible, setLearnMoreVisible] = useState(false)
    const [learnMoreTitle, setLearnMoreTitle] = useState('')
    const [learnMoreBody, setLearnMoreBody] = useState<React.ReactNode>()

    const closeLearnMore = () => showLearnMore('', false)
    const showLearnMore = (form: string, visible: boolean) => {
        switch (form) {
            case 'subscriptions':
                setLearnMoreTitle('Subscriptions')
                setLearnMoreBody(LearnMoreSubscriptionsBody)
                break
            case 'packages':
                setLearnMoreTitle('Packages')
                setLearnMoreBody(LearnMorePackagesBody)
                break
            case 'leadtime':
                setLearnMoreTitle('Lead time')
                setLearnMoreBody(LearnMoreLeadTimeBody)
                break
            case 'contracts':
                setLearnMoreTitle('Contracts')
                setLearnMoreBody(LearnMoreContractsBody)
                break
            case 'forms':
                setLearnMoreTitle('Forms')
                setLearnMoreBody(LearnMoreFormsBody)
                break
        }
        setLearnMoreVisible(visible)
    }

    return [
        learnMoreVisible,
        learnMoreTitle,
        learnMoreBody,
        showLearnMore,
        closeLearnMore
    ]
}

type PackageComponentReturnType = [
    string
]

// Update limited service description when it changes
export const usePackageComponent = (limitBy: string, limitedServices: EnabledServiceSelection[]): PackageComponentReturnType => {
    const [limitedServicesDescription, setLimitedServicesDescription] = useState('')

    useEffect(() => {
        let result: string[] = []
        limitedServices.forEach((limitedService) => {
            let countDesc
            if (limitedService.count === 0)
                countDesc = 'Same as package'
            else {
                countDesc = `${limitedService.count} ${limitBy === 'sessions' ? 'session' : 'hour'}`
                if (limitedService.count > 1)
                    countDesc += 's'
            }
            const desc = `${limitedService.name} (${countDesc})`
            result.push(desc)
        })
        setLimitedServicesDescription(result.join(', '))
    }, [limitBy, limitedServices])

    return [limitedServicesDescription]
}
