import React from 'react'
import { ReactComponent as CloseIcon } from '../../icons/close.svg'
import PropTypes from 'prop-types'

export const Modal = (props) => {
    const handleCloseClick = (e) => {
        if (e.target.className === 'modal') {
            props.onClose()
        }
    }
    return (
        <div className="modal"
             style={{display: props.visible ? 'block' : 'none'}}
             onClick={handleCloseClick}
        >
            <div className="main">
                <div className="close" onClick={e => props.onClose()}>
                    <CloseIcon />
                </div>
                <div className="body">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

Modal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.object,
}
