import React, { useCallback, useEffect, useState } from "react";
import { TutorialVideoModal } from "../tutorials/TutorialVideoModal";
import { FeatureCard } from "./FeatureCard";
import { checkPartnerFeature, decorateFeatures, Feature, featureName, featureRequiredPlan } from "../../../models/Feature";
import { useAppDispatch, usePSUser } from "../../../hooks";
import { Industry } from "../../../models/Industry";
import { ACTION_BUTTON, OPTIONAL_BUTTON, WarningModal } from "../../app/WarningModal";
import { fetchEdition, fetchIndustry } from "../../../modules/settings";
import { Edition } from "../../../models/Edition";
import { Result } from "../../../utils";
import { Modal, Header, Body, Close, Footer, FooterItem } from "@zendeskgarden/react-modals";
import { PSButtonPrimary } from "../../app/PSButton";
import { push } from "connected-react-router";
import { SelectPlan, FEATURE_UPGRADE_CONTEXT, HandleUpgradeOpts } from "../SelectPlan";

type Props = {
    features: Feature[],
    onChange: (feature: Feature) => void,
    onUpgrade: (opts?: HandleUpgradeOpts) => void,
}

export const FeaturesList = ({ features: storedFeatures, onChange, onUpgrade }: Props) => {
    const user = usePSUser()
    const dispatch = useAppDispatch()
    const [selectedFeature, setSelectedFeature] = useState<Feature>()
    const [features, setFeatures] = useState<Feature[]>([])
    const [edition, setEdition] = useState<Edition>()
    const [industry, setIndustry] = useState<Industry>()
    const [videoTitle, setVideoTitle] = useState('Online booking')
    const [showVideoModal, setShowVideoModal] = useState(false)
    const [dependentChildren, setDependentChildren] = useState<Feature[]>([])
    const [showDependentChildrenModal, setShowDependentChildrenModal] = useState(false)
    const [showUnlockModal, setShowUnlockModal] = useState(false)
    const [showUnlockFlow, setShowUnlockFlow] = useState(false)
    const [showFeatures, setShowFeatures] = useState(true)

    useEffect(() => {
        if (!user?.edition) return

        let isSubscribed = true
        Result(dispatch(fetchEdition(user.edition)))
            .then((found: Edition) => isSubscribed ? setEdition(found) : null)

        return () => {
            isSubscribed = false
        }
    }, [user?.edition, dispatch])

    useEffect(() => {
        if (!user?.industry) return

        let isSubscribed = true
        Result(dispatch(fetchIndustry(user.industry)))
            .then((found: Industry) => isSubscribed ? setIndustry(found) : null)

        return () => {
            isSubscribed = false
        }
    }, [user?.industry, dispatch])

    useEffect(() => {
        if (!user || !industry) return
        const { recommended, available } = decorateFeatures(storedFeatures, user, industry, edition)
        setFeatures([...recommended, ...available])
    }, [user, industry, storedFeatures, edition])

    const onView = (feature: Feature) => {
        setVideoTitle(featureName(feature))
        setShowVideoModal(true)
        setSelectedFeature(feature)
    }

    const onToggle = useCallback((feature: Feature, isEnabled: boolean) => {
        if (!user || features.length < 1) return

        setSelectedFeature({ ...feature, isEnabled })
        setFeatures(features.map(f => f.id === feature.id ? { ...f, isEnabled } : f))

        if (isEnabled) {
            const parent = features.find(f => f.id === feature.parent)

            if (feature.isLocked && !checkPartnerFeature(feature, user, edition)) {
                setShowUnlockModal(true)
            } else if (parent && !parent.isEnabled) {
                setFeatures(lastFeatures =>
                    lastFeatures.map(f => f.id === parent.id ? { ...f, isEnabled: true } : f)
                )
                onChange({ ...parent, isEnabled: true })
            }
        } else {
            const enabledChildren = features.filter(f => f.isEnabled && f.parent === feature.id)
            if (enabledChildren.length > 0) {
                setDependentChildren(enabledChildren)
                setShowDependentChildrenModal(true)
            }
        }

        onChange({ ...feature, isEnabled })
    }, [features, user, edition, onChange])

    const toggleOffChildren = useCallback(() => {
        if (showDependentChildrenModal) setShowDependentChildrenModal(false)
        const childrenIds = dependentChildren.map(c => c.id)
        setFeatures(
            features.map(f => childrenIds.includes(f.id) ? { ...f, isEnabled: false } : f)
        )
        dependentChildren.forEach(child => onChange({ ...child, isEnabled: false }))
    }, [showDependentChildrenModal, dependentChildren, features, onChange])

    const cancelToggle = useCallback(() => {
        if (!selectedFeature) return
        if (showDependentChildrenModal) setShowDependentChildrenModal(false)
        if (showUnlockModal) setShowUnlockModal(false)

        setFeatures(
            features.map(f => f.id === selectedFeature.id ? { ...f, isEnabled: !f.isEnabled } : f)
        )
        onChange({ ...selectedFeature, isEnabled: !selectedFeature.isEnabled })
        setSelectedFeature(undefined)
    }, [selectedFeature, showDependentChildrenModal, showUnlockModal, features, onChange])

    const handleUnlock = () => {
        setShowUnlockModal(false)
        setShowUnlockFlow(true)
    }

    const handleUpgrade = useCallback((opts?: HandleUpgradeOpts) => {
        if (opts?.cancel) {
            setShowUnlockFlow(false)
            cancelToggle()
        }
        setShowFeatures(opts?.cancel || false)
        onUpgrade(opts)
    }, [cancelToggle, onUpgrade])

    return (
        <>
            {showFeatures && (
                features.map((feature: Feature) => {
                    return <FeatureCard
                        key={`feature-${feature.id}`}
                        feature={feature}
                        onView={() => onView(feature)}
                        onToggle={(checked: boolean) => onToggle(feature, checked)}
                    />
                })
            )}
            {showVideoModal && (
                <TutorialVideoModal
                    onClose={() => setShowVideoModal(false)}
                    featureID={selectedFeature?.id || ''}
                    title={videoTitle}
                    text={selectedFeature?.pitch}
                />
            )}
            {showDependentChildrenModal && (
                <WarningModal
                    title="Warning"
                    message={
                        `Disabling ${selectedFeature?.name ?? 'this feature'} will also disable:
                        ${dependentChildren.map(c => c.name).join(', ')}`
                    }
                    buttonText="Ok"
                    extraButtonText="Cancel"
                    onClose={() => cancelToggle()}
                    onButtonClick={(buttonIx) => {
                        if (buttonIx === ACTION_BUTTON) toggleOffChildren()
                        else if (buttonIx === OPTIONAL_BUTTON) cancelToggle()
                    }}
                />
            )}
            {showUnlockModal && (
                <Modal onClose={() => cancelToggle()}>
                    <Header>{selectedFeature?.requires_plus ? 'Team feature' : selectedFeature?.requires_premium ? 'Premium feature' : 'Unlock feature'}</Header>
                    <Body>{selectedFeature?.pitch}</Body>
                    <Close aria-label="Close modal" />
                    <Footer>
                        <FooterItem>
                            <PSButtonPrimary onClick={handleUnlock}>Unlock Feature</PSButtonPrimary>
                        </FooterItem>
                    </Footer>
                </Modal>
            )}
            {showUnlockFlow && (
                <SelectPlan context={FEATURE_UPGRADE_CONTEXT}
                            onNext={() => dispatch(push('/'))}
                            handleUpgrade={opts => handleUpgrade(opts)}
                            planId={featureRequiredPlan(selectedFeature)} />
            )}
        </>
    )
}