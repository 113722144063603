import { ItemOption, Weekday } from "../components/settings/AvailableHoursModal"
import moment from "moment";
import {SelectOption} from "../components/settings/Options";

export type ServiceComponent = {
    component: string;
    capacity: string | null;
}

export type Lesson = {
    employee: string;
    duration: string;
    capacity: number;
    location: string;
    date: string;
    schedule: {
        duration: string;
        dow: number;
        interval: string | number;
        frequency: string;
        date: string;
    }
}

export class Item {
    id?: string;
    type: string;
    name: string;
    image?: string;
    image_uri?: string;
    rate: string;
    deposit: string;
    discount: string;
    discount_type?: string;
    discount_instances?: number;
    memo: string;
    allow_salestax: boolean;
    duration: number;
    leadtime: string;
    is_public: boolean;
    capacity: string;
    availability?: Weekday[];
    schedule: number;
    mon_start_hour: string;
    tue_start_hour: string;
    wed_start_hour: string;
    thu_start_hour: string;
    fri_start_hour: string;
    sat_start_hour: string;
    sun_start_hour: string;
    mon_end_hour: string;
    tue_end_hour: string;
    wed_end_hour: string;
    thu_end_hour: string;
    fri_end_hour: string;
    sat_end_hour: string;
    sun_end_hour: string;
    form: string;
    contract: string;
    allow_conference: boolean;
    conference_type?: string;
    is_virtual: boolean;
    client_selects_location: boolean;
    location: string;
    quantity: string;
    cost: string;
    allow_inventory: boolean;
    expires: string;
    start_date: string | null;
    end_date: string | null;
    seqnum: number;
    package_all: boolean;
    capacity_type: string;
    interval?: number;
    instances?: number;
    frequency: string;
    components?: ServiceComponent[];
    package?: string;
    allow_waitlist: boolean;
    class_type: string;
    class_count: string;
    employee: string;
    lessons?: Lesson[];
    edition?: string;
    edition_item?: string;
    onboarding_form_id?: string;
    onboarding_contract_id?: string;

    constructor(type: string) {
        this.type = type
        this.name = ''
        this.rate = ''
        this.deposit = ''
        this.discount = ''
        this.memo = ''
        this.allow_salestax = false
        this.duration = 0;
        this.leadtime = '';
        this.is_public = false;
        this.capacity = '';
        this.schedule = 0;
        this.mon_start_hour = ''
        this.tue_start_hour = ''
        this.wed_start_hour = ''
        this.thu_start_hour = ''
        this.fri_start_hour = ''
        this.sat_start_hour = ''
        this.sun_start_hour = ''
        this.mon_end_hour = ''
        this.tue_end_hour = ''
        this.wed_end_hour = ''
        this.thu_end_hour = ''
        this.fri_end_hour = ''
        this.sat_end_hour = ''
        this.sun_end_hour = ''
        this.form = ''
        this.contract = ''
        this.allow_conference = false
        this.is_virtual = false
        this.client_selects_location = false;
        this.location = ''
        this.quantity = ''
        this.cost = ''
        this.allow_inventory = false
        this.expires = ''
        this.start_date = null;
        this.end_date = null;
        this.seqnum = 0;
        this.package_all = false;
        this.capacity_type = '';
        this.frequency = '';
        this.allow_waitlist = false;
        this.class_type = '';
        this.class_count = '';
        this.employee = '';
    }

    setData(data: any) {
        this.id = data.id
        this.allow_conference = !!data.allow_conference
        this.allow_salestax = !!data.allow_salestax
        this.capacity = data.capacity
        this.contract = data.contract || ''
        this.deposit = data.deposit || ''
        this.discount = data.discount || ''
        this.discount_instances = data.discount_instances
        this.duration = data.duration || ''
        this.form = data.form || ''
        this.location = data.location || ''
        this.memo = data.description || ''
        this.schedule = data.availability ? scheduleValue(data.availability) : data.schedule
        this.is_virtual = data.is_virtual
        this.client_selects_location = data.client_selects_location
        this.name = data.name
        this.rate = data.price || ''
        this.image = data.image
        this.image_uri = data.image_uri
        this.allow_inventory = data.allow_inventory
        this.expires = data.expires || ''
        this.start_date = data.start_date || null
        this.end_date = data.end_date || null
        this.seqnum = data.seqnum
        this.package_all = !!data.package_all
        this.capacity_type = data.capacity_type || ''
        this.frequency = data.frequency || ''
        this.interval = data.interval
        this.instances = data.instances
        this.components = data.components
        this.package = data.package
        this.allow_waitlist = data.allow_waitlist
        this.class_type = data.class_type
        this.class_count = data.class_count
        this.employee = data.employee
        this.conference_type = data.conference_type

        if (data.availability?.length > 0) {
            if (data.availability[0].enabled) {
                this.mon_start_hour = data.availability[0].start.value
                this.mon_end_hour = data.availability[0].end.value
            }
            if (data.availability[1].enabled) {
                this.tue_start_hour = data.availability[1].start.value
                this.tue_end_hour = data.availability[1].end.value
            }
            if (data.availability[2].enabled) {
                this.wed_start_hour = data.availability[2].start.value
                this.wed_end_hour = data.availability[2].end.value
            }
            if (data.availability[3].enabled) {
                this.thu_start_hour = data.availability[3].start.value
                this.thu_end_hour = data.availability[3].end.value
            }
            if (data.availability[4].enabled) {
                this.fri_start_hour = data.availability[4].start.value
                this.fri_end_hour = data.availability[4].end.value
            }
            if (data.availability[5].enabled) {
                this.sat_start_hour = data.availability[5].start.value
                this.sat_end_hour = data.availability[5].end.value
            }
            if (data.availability[6].enabled) {
                this.sun_start_hour = data.availability[6].start.value
                this.sun_end_hour = data.availability[6].end.value
            }
        }

        this.edition = data.edition
        this.edition_item = data.edition_item
        this.onboarding_form_id = data.onboarding_form_id
        this.onboarding_contract_id = data.onboarding_contract_id
    }

    toObject(): any {
        const obj: any = {}

        obj.id = this.id
        obj.type = this.type
        obj.name = this.name
        obj.rate = this.rate || ''
        obj.deposit = this.deposit || ''
        obj.discount = this.discount || ''
        obj.discount_instances = this.discount_instances
        obj.memo = this.memo || ''
        obj.allow_salestax = this.allow_salestax || false
        obj.duration = this.duration || ''
        obj.leadtime = this.leadtime || ''
        obj.is_public = this.is_public || false
        obj.capacity = this.capacity
        obj.schedule = this.availability ? scheduleValue(this.availability) : 0
        obj.form = this.form || ''
        obj.contract = this.contract || ''
        obj.location = this.location || ''
        obj.allow_conference = this.allow_conference
        obj.is_virtual = this.is_virtual
        obj.client_selects_location = this.client_selects_location
        obj.location = this.location
        obj.quantity = this.quantity || ''
        obj.cost = this.cost || ''
        obj.allow_inventory = this.allow_inventory
        obj.expires = this.expires || ''
        obj.start_date = this.start_date || null
        obj.end_date = this.end_date || null
        obj.image = this.image || ''
        if (this.image_uri)
            obj.image_uri = this.image_uri
        obj.package_all = this.package_all
        obj.capacity_type = this.capacity_type || ''
        obj.frequency = this.frequency || ''
        obj.interval = this.interval
        obj.instances = this.instances
        obj.components = this.components
        obj.package = this.package
        obj.allow_waitlist = this.allow_waitlist
        obj.class_type = this.class_type
        obj.class_count = this.class_count
        obj.employee = this.employee
        obj.conference_type = this.conference_type

        if (this.lessons) {
            obj.lessons = []
            this.lessons.forEach((lesson) => {
                obj.lessons.push(lesson)
            })
        }

        if (this.availability && this.availability.length === 7) {
            if (this.availability[0].enabled) {
                obj.mon_start_hour = (this.availability[0].start as ItemOption).value
                obj.mon_end_hour = (this.availability[0].end as ItemOption).value
            }
            else {
                obj.mon_start_hour = ''
                obj.mon_end_hour = ''
            }
            if (this.availability[1].enabled) {
                obj.tue_start_hour = (this.availability[1].start as ItemOption).value
                obj.tue_end_hour = (this.availability[1].end as ItemOption).value
            }
            else {
                obj.tue_start_hour = ''
                obj.tue_end_hour = ''
            }
            if (this.availability[2].enabled) {
                obj.wed_start_hour = (this.availability[2].start as ItemOption).value
                obj.wed_end_hour = (this.availability[2].end as ItemOption).value
            }
            else {
                obj.wed_start_hour = ''
                obj.wed_end_hour = ''
            }
            if (this.availability[3].enabled) {
                obj.thu_start_hour = (this.availability[3].start as ItemOption).value
                obj.thu_end_hour = (this.availability[3].end as ItemOption).value
            }
            else {
                obj.thu_start_hour = ''
                obj.thu_end_hour = ''
            }
            if (this.availability[4].enabled) {
                obj.fri_start_hour = (this.availability[4].start as ItemOption).value
                obj.fri_end_hour = (this.availability[4].end as ItemOption).value
            }
            else {
                obj.fri_start_hour = ''
                obj.fri_end_hour = ''
            }
            if (this.availability[5].enabled) {
                obj.sat_start_hour = (this.availability[5].start as ItemOption).value
                obj.sat_end_hour = (this.availability[5].end as ItemOption).value
            }
            else {
                obj.sat_start_hour = ''
                obj.sat_end_hour = ''
            }
            if (this.availability[6].enabled) {
                obj.sun_start_hour = (this.availability[6].start as ItemOption).value
                obj.sun_end_hour = (this.availability[6].end as ItemOption).value
            }
            else {
                obj.sun_start_hour = ''
                obj.sun_end_hour = ''
            }
        }
        else if (this.type === 'reservation') {
            obj.sun_start_hour = this.sun_start_hour ?? ''
            obj.sun_end_hour = this.sun_end_hour ?? ''
            obj.mon_start_hour = this.mon_start_hour ?? ''
            obj.mon_end_hour = this.mon_end_hour ?? ''
            obj.tue_start_hour = this.tue_start_hour ?? ''
            obj.tue_end_hour = this.tue_end_hour ?? ''
            obj.wed_start_hour = this.wed_start_hour ?? ''
            obj.wed_end_hour = this.wed_end_hour ?? ''
            obj.thu_start_hour = this.thu_start_hour ?? ''
            obj.thu_end_hour = this.thu_end_hour ?? ''
            obj.fri_start_hour = this.fri_start_hour ?? ''
            obj.fri_end_hour = this.fri_end_hour ?? ''
            obj.sat_start_hour = this.sat_start_hour ?? ''
            obj.sat_end_hour = this.sat_end_hour ?? ''
        }

        if (this.edition)
            obj.edition = this.edition
        if (this.edition_item)
            obj.edition_item = this.edition_item

        return obj
    }

    setReservationTimes(startDate: Date, checkIn: string, checkOut: string, duration: string) {
        let startMoment = moment(startDate)
        if (checkIn) {
            const numCheckIn = Number(checkIn)
            startMoment = startMoment.hour(Math.floor(numCheckIn))
            if (numCheckIn % 1 !== 0)
                startMoment = startMoment.minute(30)
        }
        this.start_date = startMoment.toISOString()

        switch (startMoment.day()) {
            case 0: this.sun_start_hour = checkIn; break;
            case 1: this.mon_start_hour = checkIn; break;
            case 2: this.tue_start_hour = checkIn; break;
            case 3: this.wed_start_hour = checkIn; break;
            case 4: this.thu_start_hour = checkIn; break;
            case 5: this.fri_start_hour = checkIn; break;
            case 6: this.sat_start_hour = checkIn; break;
        }

        if (duration) {
            let endMoment = startMoment.clone().add(Number(duration)/1440, 'days')
            if (checkOut) {
                const numCheckOut = Number(checkOut)
                endMoment = endMoment.hour(Math.floor(numCheckOut))
                if (numCheckOut % 1 !== 0)
                    endMoment = endMoment.minute(30)
            }
            this.end_date = endMoment.toISOString()

            switch (endMoment.day()) {
                case 0: this.sun_end_hour = checkOut; break;
                case 1: this.mon_end_hour = checkOut; break;
                case 2: this.tue_end_hour = checkOut; break;
                case 3: this.wed_end_hour = checkOut; break;
                case 4: this.thu_end_hour = checkOut; break;
                case 5: this.fri_end_hour = checkOut; break;
                case 6: this.sat_end_hour = checkOut; break;
            }
        }
    }

    // set the {dow}_(start|end)_hour for each day in the availability
    setStartEndHoursForDay = (day: number,
                              checkIn: '' | SelectOption,
                              checkOut: '' | SelectOption) => {
        if (day & 1) {
            this.mon_start_hour = checkIn ? checkIn.value : ''
            this.tue_end_hour = checkOut ? checkOut.value : ''
        }
        else if (day & 2) {
            this.tue_start_hour = checkIn ? checkIn.value : ''
            this.wed_end_hour = checkOut ? checkOut.value : ''
        }
        else if (day & 4) {
            this.wed_start_hour = checkIn ? checkIn.value : ''
            this.thu_end_hour = checkOut ? checkOut.value : ''
        }
        else if (day & 8) {
            this.thu_start_hour = checkIn ? checkIn.value : ''
            this.fri_end_hour = checkOut ? checkOut.value : ''
        }
        else if (day & 16) {
            this.fri_start_hour = checkIn ? checkIn.value : ''
            this.sat_end_hour = checkOut ? checkOut.value : ''
        }
        else if (day & 32) {
            this.sat_start_hour = checkIn ? checkIn.value : ''
            this.sun_end_hour = checkOut ? checkOut.value : ''
        }
        else if (day & 64) {
            this.sun_start_hour = checkIn ? checkIn.value : ''
            this.mon_end_hour = checkOut ? checkOut.value : ''
        }
    }
}

export const areEqual = (first: Weekday[] | undefined, second: Weekday[] | undefined): boolean => {
    if (!first || !second) {
        const defined = first ?? second
        return !defined?.some(day => day.enabled)
    }

    if (first?.length !== second?.length) {
        return false
    }

    for (let i = 0; i < first.length; i++) {
        const other = second.find(day => day.name === first[i].name)
        if (first[i].enabled !== other?.enabled) {
            return false;
        }

        if (first[i].enabled && (
            JSON.stringify(first[i].start) !== JSON.stringify(other.start) || JSON.stringify(first[i].end) !== JSON.stringify(other.end)
        )) {
            return false;
        }
    }
    return true;
}

export const createAvailabilityArray = (data: any): Weekday[] => {
    const itemHours: ItemOption[] = []
    for (let i = 0; i < 24; i++) {
        itemHours.push({value: i, name: moment().hour(i).minute(0).format('h:mma') })
        itemHours.push({value: i+0.5, name: moment().hour(i).minute(30).format('h:mma') })
    }

    itemHours.push({value: 24, name: moment().hour(0).minute(0).format('h:mma') })
    const weekdays: Weekday[] = []
    const dayKeys = [
        {start: 'mon_start_hour', end: 'mon_end_hour'},
        {start: 'tue_start_hour', end: 'tue_end_hour'},
        {start: 'wed_start_hour', end: 'wed_end_hour'},
        {start: 'thu_start_hour', end: 'thu_end_hour'},
        {start: 'fri_start_hour', end: 'fri_end_hour'},
        {start: 'sat_start_hour', end: 'sat_end_hour'},
        {start: 'sun_start_hour', end: 'sun_end_hour'},
    ]

    for (let i = 1; i <= 7; i++) {
        const keys = dayKeys[i - 1]
        const start = itemHours.find(i => i.value.toFixed(1) === parseFloat(data[keys.start]).toFixed(1)) || ''
        const end = itemHours.find(i => i.value.toFixed(1) === parseFloat(data[keys.end]).toFixed(1)) || ''
        const enabled = start !== '' && (data.schedule & (1 << i-1)) !== 0

        weekdays.push({
            name: moment().day(i).format('ddd'),
            enabled,
            start,
            end,
            endHours: [],
        })
    }

    return weekdays
}

export const createAvailabilitySummary = (weekdays: Weekday[], fallback?: string): string => {
    const days: string[] = []
    weekdays.forEach((weekday) => {
        if (weekday.enabled) {
            days.push(weekday.name)
        }
    })

    if (days.length === 0) {
        return fallback ?? 'Same as business'
    }

    return days.join(', ')
}

export const scheduleValue = (weekdays: Weekday[]): number => {
    let schedule = 0
    weekdays?.forEach((weekday, index) => {
        if (weekday.enabled) {
            schedule |= 1 << index
        }
    })

    return schedule
}

export const bookingNextDate = () => {
    /*
+ (NSDate *)nextDate:(NSDate *)date vendor:(User *)vendor forVendor: (BOOL)forVendor {
    NSInteger selectedHour = [[[NSCalendar currentCalendar] components:NSCalendarUnitHour fromDate:date] hour];
    double dow = [[[NSCalendar currentCalendar] components:NSCalendarUnitWeekday fromDate:date] weekday];
    dow = dow == 1 ? pow(2,6) : pow(2,dow-2);
    NSInteger nextAvailableHour = 8;
    if (forVendor)
        nextAvailableHour = selectedHour;
    else {
        NSArray *days = [Schedule dayStrings];
        NSString *dowString = [NSString stringWithFormat:@"%@", @(dow)];
        Option *option = days[[days indexOfObjectPassingTest:^BOOL(Option *obj, NSUInteger idx, BOOL *stop) {
            return [obj.id isEqualToString:dowString];
        }]];
        if (option && vendor) {
            NSNumber *startHour = [[vendor lazyData] valueForKey:[NSString stringWithFormat:@"%@_start_hour", [option.label substringWithRange:NSMakeRange(0, 3)].lowercaseString]];
            NSNumber *endHour = [[vendor lazyData] valueForKey:[NSString stringWithFormat:@"%@_end_hour", [option.label substringWithRange:NSMakeRange(0, 3)].lowercaseString]];
            if (startHour && endHour && selectedHour <= endHour.integerValue && selectedHour >= startHour.integerValue)
                nextAvailableHour = selectedHour;
        }
    }
    date = [date dateByMovingToBeginningOfDay];
    date = [[NSDate alloc] initWithTimeInterval:60 * 60 * nextAvailableHour sinceDate:date];
    return date;
}
     */
}