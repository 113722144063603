import React from 'react'
import PropTypes from 'prop-types'

export const RecordStatusBar = (props) => {
    const { statuses } = props

    return (
        <div className="progressChecks">
            {statuses.map((status, index) => {
                const hasSpacer = index+1 < statuses.length
                return (
                    <React.Fragment key={`progress-${index}`}>
                        <div className={`step ${status.completed ? 'completed' : ''}`}
                             style={{left: `${12+index*87}px`}}
                        >
                            <img
                                src={`/check-${status.completed ? 'on' : 'off'}.svg`}
                                alt="Completed" width="24" height="24" />
                            {hasSpacer && (
                                <div className={status.completed ? 'spacerCompleted' : 'spacer'} />
                            )}
                         </div>
                    </React.Fragment>
                )
            })}
            {statuses.map((status, index) => (
                <div key={`record-status-${status.label}`} className="label" style={{left: `${index*87}px`}}>{status.label}</div>
            ))}
        </div>
    )
}

RecordStatusBar.propTypes = {
    statuses: PropTypes.array.isRequired
}
