import React, { useMemo } from "react";
import {AppHeader} from "../../app/AppHeader";
import {ReactComponent as CheckmarkImage} from "../../../icons/big_checkmark.svg";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {e164ToPhone} from "../../../utils/phone";
import {Payment} from "../../../models/Payment";
import { GiftCertificate } from "../../../models/GiftCertificate";
import { Package } from "../../../models/Package";
import { Invoice } from "../../../models/Invoice";
import { Estimate } from "../../../models/Estimate";
import { Booking } from "../../../models/Booking";
import { Charge } from "../../../models/Charge";
import { SettingsState } from "../../../modules/settings";

type LocationState = {
    payment?: Payment,
    charge?: Charge,
    booking?: Booking,
    estimate?: Estimate,
    invoice?: Invoice,
    aPackage?: Package,
    gift?: GiftCertificate
}

export const ChargeSuccess = () => {
    const state: {entities: any, settings: SettingsState} = useSelector((state: RootState) => {
        return {
            entities: state.entities,
            settings: state.settings
        }
    })

    const readers = useMemo(() => state.settings.readers.filter(r => r.is_internet), [state.settings.readers])

    const location = useLocation()
    const locationState: any = location.state
    const { payment, charge, booking, estimate, invoice, aPackage, gift } = locationState as LocationState

    const client = payment?.client || booking?.client || estimate?.client || invoice?.client || aPackage?.client || gift?.client
    const user = client ? state.entities.users[client] || {} : {}
    let successMessage = `A receipt was sent to ${user?.name || 'your client'}`
    if (user.e164)
        successMessage += ` at ${e164ToPhone(user.e164)}`

    return (
        <AppHeader title="Charge"
                   middleWidget={null}
        >
            <div className="transactionSuccess">
                <CheckmarkImage/>
                <h1>Success</h1>
                <div className="details">{successMessage}</div>

                <div className="links">
                    <div className="link">
                        <Link to="/">Dashboard</Link>
                        <div>View your PocketSuite home screen</div>
                    </div>
                    {!charge && (
                        <div className="link">
                            <Link to="/calendar">Calendar</Link>
                            <div>View your calendar</div>
                        </div>
                    )}
                    <div className="link">
                        <Link to="/messages">Inbox</Link>
                        <div>View your inbox</div>
                    </div>
                    {charge?.id && (
                        <div className="link">
                            <Link to={`/record/charge/${charge.id}`}>
                                View charge
                            </Link>
                            <div>View this charge record</div>
                        </div>
                    )}
                    {payment?.id && (
                        <div className="link">
                            <Link to={`/record/payment/${payment.id}`}>
                                View payment
                            </Link>
                            <div>View this payment record</div>
                        </div>
                    )}
                    {!readers?.length && (
                        <div className="link">
                            <Link to={`/settings/reader`}>
                                Order a card reader
                            </Link>
                            <div>Swipe, tap magnetic stripe, or read chip cards.</div>
                        </div>
                    )}
                    {booking?.id && (
                        <div className="link">
                            <Link to={`/record/booking/${booking.id}`}>
                                View appointment
                            </Link>
                            <div>View this appointment record</div>
                        </div>
                    )}
                    {estimate?.id && (
                        <div className="link">
                            <Link to={`/record/estimate/${estimate.id}`}>
                                View estimate
                            </Link>
                            <div>View this estimate record</div>
                        </div>
                    )}
                    {gift?.id && (
                        <div className="link">
                            <Link to={`/record/gift/${gift.id}`}>
                                View gift
                            </Link>
                            <div>View this gift record</div>
                        </div>
                    )}
                    {invoice?.id && (
                        <div className="link">
                            <Link to={`/record/invoice/${invoice.id}`}>
                                View invoice
                            </Link>
                            <div>View this invoice record</div>
                        </div>
                    )}
                    {aPackage?.id && (
                        <div className="link">
                            <Link to={`/record/package/${aPackage.id}`}>
                                View package
                            </Link>
                            <div>View this package record</div>
                        </div>
                    )}
                    <div className="link">
                        <Link to="/charge/new">Create another</Link>
                        <div>Charge another client</div>
                    </div>
                </div>
            </div>
        </AppHeader>
    )
}
