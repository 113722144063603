import { Item } from "@zendeskgarden/react-dropdowns";
import React from "react";
import { Field } from "../../../models/Field";
import { Option } from "../../../models/Option"
import { PSDropdown } from "../../app/PSDropdown";

type FieldProps = {
    fieldValue?: string,
    label: string,
    field: Field,
    onChange: (changed: string) => void
}

export const SelectInput = ({fieldValue, label, field, onChange}: FieldProps) => {
    const selected = field.options.find(option => option.value === fieldValue)
    return (
        <PSDropdown selected={selected}
            nameProperty={"label"}
            label={label}
            onSelect={(option: Option) => onChange(option.value)}>
        <>
            {field.options.map(option => (
                <Item key={`${field.name}-${option.value}`} value={option}>
                    {option.label}
                </Item>
            ))}
        </>
        </PSDropdown>
    )
}