import React, { useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { PSButton, PSButtonPrimary } from "../../../app/PSButton";
import { ReactComponent as LeftArrow } from "../../../../icons/left-arrow.svg";
import { Field, Label } from "@zendeskgarden/react-forms";
import { PSTextarea } from "../../../app/PSTextarea";
import { useAppDispatch, usePSUser } from "../../../../hooks";
import { replace } from "connected-react-router";
import { cancelPlan } from "../../../../modules/settings";
import { Modal, Header, Body, Close, Footer, FooterItem } from "@zendeskgarden/react-modals";
import { Result } from "../../../../utils";
import { SelectOption } from "../../Options";

export const CancelPlanConfirm = () => {
    const me = usePSUser()
    const history = useHistory()
    const dispatch = useAppDispatch()
    const { reason } = useLocation<{ reason: SelectOption }>().state

    const [details, setDetails] = useState('')
    const [showDoneModal, setShowDoneModal] = useState(false)

    const handleDone = useCallback(() => {
        if (!me) return

        Result(dispatch(cancelPlan(me.id, { reason: reason.value, details })))
            .then(() => setShowDoneModal(true))
    }, [me, dispatch, reason, details])

    return (
        <>
            <div className="supportSettings">
                <div className="header">
                    <PSButton className="left" onClick={() => history.goBack()}>
                        <LeftArrow />
                    </PSButton>
                    <h1 className="title">Please tell us more!</h1>
                    <div className="right">
                        <PSButtonPrimary disabled={!reason || !details} onClick={handleDone}>
                            Done
                        </PSButtonPrimary>
                    </div>
                </div>
                <div className="separator" />
                <div className="form">
                    <Field className="field">
                        <Label className="label">We love feedback!</Label>
                        <PSTextarea placeholder="Please enter"
                                    className="input"
                                    minRows={5}
                                    maxLength={999}
                                    value={details}
                                    onChange={value => setDetails(value)}/>
                    </Field>
                </div>
            </div>
            {showDoneModal && (
                <Modal onClose={() => dispatch(replace('/login'))}>
                    <Header>All done!</Header>
                    <Body>
                        <p>Your subscription was successfully canceled. We're sorry to see you go. If you change your mind in the next 30 days, we might be able to recover your premium number.</p>
                    </Body>
                    <Footer>
                        <FooterItem>
                            <PSButtonPrimary onClick={() => dispatch(replace('/login'))}>
                                Done
                            </PSButtonPrimary>
                        </FooterItem>
                    </Footer>
                    <Close aria-label="Close modal" />
                </Modal>
            )}
        </>
    )
}
