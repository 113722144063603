import React from "react"
import moment from 'moment'
import {currencyFormat} from "./numbers"
import { Link } from 'react-router-dom'
import { ReactComponent as ChevronIcon } from '../icons/chevron.svg'

export const displayBooking = (booking: any) => {
    const subtitle = []
    if (booking.item_display)
        subtitle.push(booking.item_display)

    subtitle.push(booking.amount ? currencyFormat(booking.amount) : 'TBD')

    return (
        <div style={{marginBottom: '12px'}} key={`booking-${booking.id}`}>
            <div className="grey">{subtitle.join(' · ')}</div>
        </div>
    )
}

export const displayJob = (items: any, booking: any) => {
    const item = items[booking.item] || {}
    const subtitle = []
    if (item.name)
        subtitle.push(item.name)

    subtitle.push(booking.amount ? currencyFormat(booking.amount) : 'TBD')

    return (
        <div style={{marginBottom: '12px'}} key={`booking-${booking.id}`}>
            <Link to={`/record/booking/${booking.id}`}>
                {moment(booking.date).format('ddd, MMMM Do')}
                <ChevronIcon className="chevron" />
            </Link>
            <div className="grey">{subtitle.join(' · ')}</div>
        </div>
    )
}

export const displayExpense = (expense: any) => {
    const subtitle = []
    if (expense.memo)
        subtitle.push(expense.memo)

    subtitle.push(currencyFormat(expense.amount))

    return (
        <div style={{marginBottom: '12px'}} key={`expense-${expense.id}`}>
            <div className="capitalize">{expense.category}</div>
            <div className="grey">{subtitle.join(' · ')}</div>
        </div>
    )
}

export const displayLineItem = (lineItem: any, showQuantity: boolean) => {
    const subtitle = []
    if (lineItem.rate)
        subtitle.push(`${currencyFormat(lineItem.rate)} rate`)
    else if (lineItem.memo)
        subtitle.push(lineItem.memo)

    subtitle.push(currencyFormat(lineItem.amount))

    let title = lineItem.item_display
    if (showQuantity)
        title += ` x ${lineItem.quantity}`

    return (
        <div style={{marginBottom: '12px'}} key={`lineitem-${lineItem.id}`}>
            <div>{title}</div>
            <div className="grey">{subtitle.join(' · ')}</div>
        </div>
    )
}

