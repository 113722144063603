import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { currencyFormat } from '../../utils/numbers'
import { push } from 'connected-react-router'
import moment from 'moment'
import { ReactComponent as InboundChevronIcon } from '../../icons/chevron.svg'
import { ReactComponent as OutboundChevronIcon } from '../../icons/white_chevron.svg'
import { ReactComponent as InboundPaymentIcon } from '../../icons/msg_inbound_payment.svg'
import { ReactComponent as OutboundPaymentIcon } from '../../icons/msg_outbound_payment.svg'
import { ReactComponent as InboundSubscriptionIcon } from '../../icons/msg_inbound_subscription.svg'
import { ReactComponent as OutboundSubscriptionIcon } from '../../icons/msg_outbound_subscription.svg'
import { ReactComponent as InboundSignatureIcon } from '../../icons/msg_inbound_signature.svg'
import { ReactComponent as OutboundSignatureIcon } from '../../icons/msg_outbound_signature.svg'
import { ReactComponent as InboundBookingIcon } from '../../icons/msg_inbound_booking.svg'
import { ReactComponent as OutboundBookingIcon } from '../../icons/msg_outbound_booking.svg'
import { ReactComponent as InboundInvoiceIcon } from '../../icons/msg_inbound_invoice.svg'
import { ReactComponent as OutboundInvoiceIcon } from '../../icons/msg_outbound_invoice.svg'
import { ReactComponent as InboundFormIcon } from '../../icons/msg_inbound_form.svg'
import { ReactComponent as OutboundFormIcon } from '../../icons/msg_outbound_form.svg'
import { ReactComponent as InboundPackageIcon } from '../../icons/msg_inbound_package.svg'
import { ReactComponent as OutboundPackageIcon } from '../../icons/msg_outbound_package.svg'
import { ReactComponent as InboundConferenceIcon } from '../../icons/msg_inbound_conference.svg'
import { ReactComponent as OutboundConferenceIcon } from '../../icons/msg_outbound_conference.svg'
import { ReactComponent as InboundGiftIcon } from '../../icons/msg_inbound_gift.svg'
import { ReactComponent as OutboundGiftIcon } from '../../icons/msg_outbound_gift.svg'
import { ReactComponent as InboundClassIcon } from '../../icons/msg_inbound_class.svg'
import { ReactComponent as OutboundClassIcon } from '../../icons/msg_outbound_class.svg'
import { ReactComponent as InboundPersonIcon } from '../../icons/msg_inbound_person.svg'
import { ReactComponent as OutboundPersonIcon } from '../../icons/msg_outbound_person.svg'
import { ReactComponent as InboundPhoneIcon } from '../../icons/msg_inbound_phone.svg'
import { ReactComponent as OutboundPhoneIcon } from '../../icons/msg_outbound_phone.svg'
import { ReactComponent as InboundTaskIcon } from '../../icons/msg_inbound_task.svg'
import { ReactComponent as OutboundTaskIcon } from '../../icons/msg_outbound_task.svg'
import Moment from 'react-moment'
import {markPaidPaymentOptions} from '../../components/settings/Options'

export const MediaMessageBubble = (props) => {
    const dispatch = useDispatch()
    const state = useSelector(state => {
        return {
            entities: state.entities,
            login: state.login,
        }
    })

    const { isOutbound, message } = props

    const getMessageText = (message) => {
        switch (message.type) {
            case 'payment':
                const payment = state.entities.payments[message.payment] || {}
                if (payment.id)
                    return {
                        title: `${currencyFormat(payment.amount)} payment`,
                        subtitle: payment.refnum,
                        icon: isOutbound ? <OutboundPaymentIcon /> : <InboundPaymentIcon />
                    }
                break
            case 'charge':
                const charge = state.entities.charges[message.charge] || {}
                if (charge.id) {
                    const paymeth = markPaidPaymentOptions.filter(o => o.value === charge.paymeth)?.shift().label
                    return {
                        title: `${currencyFormat(charge.charge_amount)} Charge`,
                        subtitle: `Marked paid`,
                        icon: isOutbound ? <OutboundPaymentIcon /> : <InboundPaymentIcon />
                    }
                }
                break
            case 'subscription':
                const subscription = state.entities.subscriptions[message.subscription] || {}
                if (subscription.id)
                    return {
                        title: `${moment(subscription.date).format('M/D')} subscription`,
                        subtitle: message.message,
                        icon: isOutbound ? <OutboundSubscriptionIcon /> : <InboundSubscriptionIcon />
                    }
                break
            case 'signature':
                const signature = state.entities.signatures[message.signature] || {}
                if (signature.id) {
                    const contract = state.entities.contracts[signature.contract] || {}
                    return {
                        title: contract.name || 'Contract',
                        subtitle: message.message,
                        icon: isOutbound ? <OutboundSignatureIcon /> : <InboundSignatureIcon />
                    }
                }
                break
            case 'record':
                const record = state.entities.records[message.record] || {}
                if (record.id) {
                    const data = state.entities.types[record.type] || {}
                    return {
                        title: data.name || 'Form',
                        subtitle: message.message,
                        icon: isOutbound ? <OutboundFormIcon /> : <InboundFormIcon />
                    }
                }
                break
            case 'booking':
                const booking = state.entities.bookings[message.booking] || {}
                if (booking.id)
                    return {
                        title: moment(booking.date).format('M/D'),
                        subtitle: message.message,
                        icon: isOutbound ? <OutboundBookingIcon /> : <InboundBookingIcon />
                    }
                break
            case 'signup':
                const contact = state.entities.contacts[message.person] || {}
                if (contact.id)
                    return {
                        title: contact.name,
                        subtitle: message.message,
                        icon: isOutbound ? <OutboundPersonIcon /> : <InboundPersonIcon />
                    }
                break
            case 'estimate':
                const estimate = state.entities.estimates[message.estimate] || {}
                if (estimate.id)
                    return {
                        title: `Estimate #${estimate.number}`,
                        subtitle: message.message,
                        icon: isOutbound ? <OutboundInvoiceIcon /> : <InboundInvoiceIcon />
                    }
                break
            case 'invoice':
                const invoice = state.entities.invoices[message.invoice] || {}
                if (invoice.id)
                    return {
                        title: `Invoice #${invoice.number}`,
                        subtitle: message.message,
                        icon: isOutbound ? <OutboundInvoiceIcon /> : <InboundInvoiceIcon />
                    }
                break
            case 'task':
                const task = state.entities.tasks[message.task] || {}
                if (task.id)
                    return {
                        title: task.title,
                        subtitle: message.message,
                        icon: isOutbound ? <OutboundTaskIcon /> : <InboundTaskIcon />
                    }
                break
            case 'gift':
                const gift = state.entities.gifts[message.gift]
                if (gift.id)
                    return {
                        title: gift.display_name,
                        subtitle: message.message,
                        icon: isOutbound ? <OutboundGiftIcon /> : <InboundGiftIcon />
                    }
                break
            case 'package':
                const pack = state.entities.packages[message.package]
                if (pack.id)
                    return  {
                        title: `${moment(pack.created_date).format('M/D')} package`,
                        subtitle: message.message,
                        icon: isOutbound ? <OutboundPackageIcon /> : <InboundPackageIcon />
                    }
                break
            case 'lesson':
                const lesson = state.entities.classes[message.lesson]
                if (lesson.id)
                    return {
                        title: `${moment(lesson.date).format('M/D')} class`,
                        subtitle: message.message,
                        icon: isOutbound ? <OutboundClassIcon /> : <InboundClassIcon />
                    }
                break
            case 'conference':
                return {
                    title: 'Video conference',
                    subtitle: message.message,
                    icon: isOutbound ? <OutboundConferenceIcon /> : <InboundConferenceIcon />
                }
            case 'call':
                return {
                    title: 'Phone call',
                    subtitle: message.message,
                    icon: isOutbound ? <OutboundPhoneIcon /> : <InboundPhoneIcon />
                }
            default:
                return {title: message.message}
        }

        return {title: message.message}
    }

    const getId = (message) => {
        switch (message.type) {
            case 'payment':
                return message.payment
            case 'charge':
                return message.charge
            case 'booking':
                return message.booking
            case 'signature':
                return message.signature
            case 'signup':
                return message.person
            case 'subscription':
                return message.subscription
            case 'estimate':
                return message.estimate
            case 'invoice':
                return message.invoice
            case 'record':
                return message.record
            case 'task':
                return message.task
            case 'lesson':
                return message.lesson
            case 'gift':
                return message.gift
            case 'package':
                return message.package
            default:
                return null
        }
    }

    const viewMedia = (message) => {
        const id = getId(message)
        if (!id) {
            console.log(`Unlinked media type=${message.type}`)
            return
        }
        dispatch(push(`/record/${message.type}/${id}`))
    }

    const id = getId(message)
    const isLink = message.type && message.type !== 'person'

    let chevronImage
    if (id) {
        chevronImage = isOutbound ?
            <OutboundChevronIcon className="chevron" /> :
            <InboundChevronIcon className="chevron" />
    }

    const { title, subtitle, icon } = getMessageText(message)

    const calendarStrings = {
        // sameDay: 'LT',
        // lastDay: 'dddd LT',
        // lastWeek: 'dddd LT',
        sameDay: 'ddd, MMM Do YYYY h:mm a',
        lastDay: 'ddd, MMM Do YYYY h:mm a',
        lastWeek: 'ddd, MMM Do YYYY h:mm a',
        sameElse: 'ddd, MMM Do YYYY h:mm a',
    }

    return (
        <div className={isOutbound ? 'outboundMessage' : 'inboundMessage'}>
            <div className="message"
                 onClick={e => viewMedia(message)}
                 style={{cursor: isLink && id ? 'pointer' : 'default'}}
            >
                <div className="messageMedia">
                    <div className="icon">
                        {icon ? icon : isOutbound ? <OutboundBookingIcon /> : <InboundBookingIcon />}
                    </div>
                    <div className="text">
                        <div className="title">{title}</div>
                        <div className="subtitle">{subtitle}</div>
                    </div>
                    {chevronImage}
                </div>
            </div>
            <Moment calendar={calendarStrings}>{props.message.created_date}</Moment>
        </div>
    )
}