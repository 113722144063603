import moment from "moment";
import { formatHourMinute } from "../../utils";

export type SelectOption = {
    label: string,
    value: string,
}

export const findSelection = (options: SelectOption[], value: string|number|undefined): SelectOption | undefined =>
    options.find(option => option.value === value?.toString())

export const findSubscriptionFrequencySelection = (interval: Number, frequency: string) =>
    findFrequencySelection(subscriptionFrequencyOptions, interval, frequency)

const findFrequencySelection = (frequencyOptions: SelectOption[], interval: Number, frequency: string) => {
    const value = `${interval}${frequency === 'weekly' ? 'w' : frequency === 'daily' ? 'd' : 'm'}`
    return findSelection(frequencyOptions, value)
}

export const discountOptions: SelectOption[] = [
    { label: 'None', value: '0' },
    { label: '5%', value: '5' },
    { label: '10%', value: '10' },
    { label: '14%', value: '14' },
    { label: '15%', value: '15' },
    { label: '20%', value: '20' },
    { label: '22%', value: '22' },
    { label: '25%', value: '25' },
    { label: '30%', value: '30' },
    { label: '35%', value: '35' },
    { label: '40%', value: '40' },
    { label: '45%', value: '45' },
    { label: '50%', value: '50' },
    { label: '55%', value: '55' },
    { label: '60%', value: '60' },
    { label: '65%', value: '65' },
    { label: '70%', value: '70' },
    { label: '75%', value: '75' },
    { label: '80%', value: '80' },
    { label: '90%', value: '90' },
]

export const durationDayOptions: SelectOption[] = [
    { label: 'TBD', value: '' },
    { value: '1440', label: '1 day' },
    { value: '2880', label: '2 days' },
    { value: '4320', label: '3 days' },
    { value: '5760', label: '4 days' },
    { value: '7200', label: '5 days' },
    { value: '8640', label: '6 days' },
    { value: '10080', label: '1 week' },
    { value: '11520', label: '8 days' },
    { value: '12960', label: '9 days' },
    { value: '14400', label: '10 days' },
    { value: '15840', label: '11 days' },
    { value: '17280', label: '12 days' },
    { value: '18720', label: '13 days' },
    { value: '20160', label: '2 weeks' },
    { value: '21600', label: '15 days' },
    { value: '23040', label: '16 days' },
    { value: '24480', label: '17 days' },
    { value: '25920', label: '18 days' },
    { value: '27360', label: '19 days' },
    { value: '28800', label: '20 days' },
    { value: '30240', label: '3 weeks' },
    { value: '31680', label: '22 days' },
    { value: '33120', label: '23 days' },
    { value: '34560', label: '24 days' },
    { value: '36000', label: '25 days' },
    { value: '37440', label: '26 days' },
    { value: '38880', label: '27 days' },
    { value: '40320', label: '4 weeks' },
    { value: '41760', label: '29 days' },
    { value: '43200', label: '30 days' },
    { value: '50400', label: '5 weeks' },
    { value: '60480', label: '6 weeks' },
    { value: '70560', label: '7 weeks' },
    { value: '80640', label: '8 weeks' },
    { value: '90720', label: '9 weeks' },
    { value: '100800', label: '10 weeks' },
]
const durationDayOptionsNoTBD = durationDayOptions.slice(1)

// 5 minute increments starting at 10 minutes up to 14 hours
const fiveMinuteIncrementOptions: SelectOption[] = []
for (let i = 10; i <= 14 * 60; i += 5) {
    const numberOfHours = Math.floor(i / 60)
    const numberOfMinutes = i % 60
    const hourLabel = numberOfHours > 1 ? 'hours' : 'hour'

    const label = i < 60 ? `${i} minutes` :
        numberOfMinutes ? `${numberOfHours} ${hourLabel}, ${numberOfMinutes} minutes` : `${numberOfHours} ${hourLabel}`
        fiveMinuteIncrementOptions.push({value: i.toString(), label})
}

export const blockDurationOptions: SelectOption[] = [
    ...fiveMinuteIncrementOptions,
    ...durationDayOptionsNoTBD
]

export const durationOptions: SelectOption[] = [
    { label: 'TBD', value: '' },
    ...fiveMinuteIncrementOptions
]

export const durationOptionsNoTBD: SelectOption[] = durationOptions.slice(1)

export const leadtimeOptions: SelectOption[] = [
    { label: 'No lead time', value: '0' },
    { label: '15 minutes', value: '15m' },
    { label: '20 minutes', value: '20m' },
    { label: '30 minutes', value: '30m' },
    { label: '45 minutes', value: '45m' },
    { label: '1 hour', value: '1' },
    { label: '2 hours', value: '2' },
    { label: '4 hours', value: '4' },
    { label: '6 hours', value: '6' },
    { label: '8 hours', value: '8' },
    { label: '12 hours', value: '12' },
    { label: 'Next day', value: 'nextDay' },
    { label: '24 hours', value: '24' },
    { label: '36 hours', value: '36' },
    { label: '2 days', value: '48' },
    { label: '3 days', value: '72' },
    { label: '4 days', value: '96' },
    { label: 'One week', value: '168'},
    { label: 'Two weeks', value: '336'},
    { label: 'Three weeks', value: '504'},
    { label: 'Four weeks', value: '672'},
    { label: 'Six weeks', value: '1008'},
    { label: 'Eight weeks', value: '1344'},
    { label: 'Twelve weeks', value: '2016'},
]

export const expireOptions: SelectOption[] = [
    { label: 'No expiration', value: '' },
    { label: '1 year', value: '360' },
    { label: '2 years', value: '720' },
    { label: '5 years', value: '1800' },
]

export const capacityOptions: SelectOption[] = [];
for (let i = 1; i <= 40; i++) {
    capacityOptions.push({label: i.toString(), value: i.toString()})
}

export const daysOfTheWeekOptions: SelectOption[] = [
    { label: 'Monday', value: '1' },
    { label: 'Tuesday', value: '2' },
    { label: 'Wednesday', value: '4' },
    { label: 'Thursday', value: '8' },
    { label: 'Friday', value: '16' },
    { label: 'Saturday', value: '32' },
    { label: 'Sunday', value: '64' },
]

export const bookingDOWMonthlyOptions: SelectOption[][] = []
for (let i = 0; i < 7; i++) {
    const day = moment().day(i+1).format('dddd')
    bookingDOWMonthlyOptions[i] = [
        { value: '1', label: `First ${day}` },
        { value: '2', label: `Second ${day}` },
        { value: '3', label: `Third ${day}` },
        { value: '4', label: `Fourth ${day}` },
        { value: '-1', label: `Last ${day}` },
        { value: '-2', label: `Second to last ${day}` },
    ]
}

export const timeOptions: SelectOption[] = [];
for (let i = 0; i < 24; i++) {
    timeOptions.push({
        label: moment().hour(i).minute(0).format('h:mma'),
        value: i.toString(),
    })
    timeOptions.push({
        label: moment().hour(i).minute(30).format('h:mma'),
        value: (i+.5).toString(),
    })
}

export const sessionExpireOptions: SelectOption[] = [
    { value: '7', label: '1 week' },
    { value: '10', label: '10 days' },
    { value: '14', label: '2 weeks' },
    { value: '21', label: '3 weeks' },
    { value: '28', label: '4 weeks' },
    { value: '30', label: '1 month' },
    { value: '35', label: '5 weeks' },
    { value: '40', label: '40 days' },
    { value: '42', label: '6 weeks' },
    { value: '45', label: '45 days' },
    { value: '56', label: '8 weeks' },
    { value: '60', label: '2 months' },
    { value: '70', label: '10 weeks' },
    { value: '90', label: '3 months' },
    { value: '105', label: '15 weeks' },
    { value: '119', label: '17 weeks' },
    { value: '120', label: '4 months' },
    { value: '150', label: '5 months' },
    { value: '180', label: '6 months' },
    { value: '210', label: '7 months' },
    { value: '240', label: '8 months' },
    { value: '270', label: '9 months' },
    { value: '360', label: '12 months' },
    { value: '540', label: '18 months' },
    { value: '720', label: '24 months' },
    { value: '1080', label: '36 months' },
]

export const sessionHourOptions: SelectOption[] = []
for (let i = 30; i < 60 * 10; i=i+30) {
    const label = formatHourMinute(i)
    sessionHourOptions.push({
        label, value: i.toString()
    })
}
for (let i = 10; i <= 100; i++) {
    const label = formatHourMinute(i * 60)
    sessionHourOptions.push({
        label, value: (i * 60).toString()
    })
}


export const sessionCountOptions: SelectOption[] = []
for (let i = 0; i <= 100; i++) {
    const label = i.toString()
    sessionCountOptions.push({
        label, value: label
    })
}

export const subscriptionFrequencyOptions: SelectOption[] = [
    { value: '1w', label: 'Every week' },
    { value: '2w', label: 'Every 2 weeks' },
    { value: '3w', label: 'Every 3 weeks' },
    { value: '4w', label: 'Every 4 weeks' },
    { value: '1m', label: 'Every month' },
    { value: '5w', label: 'Every 5 weeks' },
    { value: '6w', label: 'Every 6 weeks' },
    { value: '7w', label: 'Every 7 weeks' },
    { value: '8w', label: 'Every 8 weeks' },
    { value: '2m', label: 'Every 2 months' },
    { value: '9w', label: 'Every 9 weeks' },
    { value: '10w', label: 'Every 10 weeks' },
    { value: '11w', label: 'Every 11 weeks' },
    { value: '12w', label: 'Every 12 weeks' },
    { value: '3m', label: 'Every 3 months' },
    { value: '4m', label: 'Every 4 months' },
    { value: '6m', label: 'Every 6 months' },
    { value: '12m', label: 'Every 12 months' },
    { value: '1d', label: 'Every day' },
    { value: '2d', label: 'Every 2 days' },
    { value: '3d', label: 'Every 3 days' },
    { value: '4d', label: 'Every 4 days' },
    { value: '5d', label: 'Every 5 days' },
]

export const subscriptionReminderOptions: SelectOption[] = [
    { value: '', label: "Don't remind" },
    { value: '24', label: '24 hours' },
    { value: '48', label: '2 days' },
    { value: '72', label: '3 days' },
    { value: '120', label: '5 days' },
    { value: '144', label: '7 days' },
]

export const subscriptionSendReminderOptions = (isCreate: boolean): SelectOption[] => [
    { value: '', label: "Before every payment" },
    { value: '1', label: isCreate ? 'Before first payment' : 'Before next payment' }
]

export const packageReminderOptions: SelectOption[] = [
    { value: '1', label: 'Every day' },
    { value: '3', label: 'Every 3 days' },
    { value: '7', label: 'Once a week' },
    { value: '14', label: 'Every 2 weeks' },
    { value: '30', label: 'Every 30 days' },
]

export const giftReminderOptions: SelectOption[] = [
    { value: '1', label: 'Every day' },
    { value: '3', label: 'Every 3 days' },
    { value: '7', label: 'Once a week' },
    { value: '14', label: 'Every 2 weeks' },
    { value: '30', label: 'Every 30 days' },
]

export const lessonFrequencyOptions: SelectOption[] = [
    { value: '1', label: 'Every week' },
    { value: '2', label: 'Every 2 weeks' },
    { value: '3', label: 'Every 3 weeks' },
    { value: '4', label: 'Every 4 weeks' },
    { value: '1m', label: 'Every month' },
    { value: '5', label: 'Every 5 weeks' },
    { value: '6', label: 'Every 6 weeks' },
    { value: '7', label: 'Every 7 weeks' },
    { value: '8', label: 'Every 8 weeks' },
    { value: '2m', label: 'Every 2 months' },
    { value: '9', label: 'Every 9 weeks' },
    { value: '10', label: 'Every 10 weeks' },
    { value: '11', label: 'Every 11 weeks' },
    { value: '12', label: 'Every 12 weeks' },
    { value: '3m', label: 'Every 3 months' },
]

export const recurringScheduleFrequencyOptions: SelectOption[] = [
        { value: '1', label: 'Every week' },
        { value: '2', label: 'Every 2 weeks' },
        { value: '3', label: 'Every 3 weeks' },
        { value: '4', label: 'Every 4 weeks' },
        { value: '1m', label: 'Every month' },
        { value: '5', label: 'Every 5 weeks' },
        { value: '6', label: 'Every 6 weeks' },
        { value: '7', label: 'Every 7 weeks' },
        { value: '8', label: 'Every 8 weeks' },
        { value: '2m', label: 'Every 2 months' },
        { value: '9', label: 'Every 9 weeks' },
        { value: '10', label: 'Every 10 weeks' },
        { value: '11', label: 'Every 11 weeks' },
        { value: '12', label: 'Every 12 weeks' },
        { value: '3m', label: 'Every 3 months' },
        { value: '6m', label: 'Every 6 months' },
        { value: '8m', label: 'Every 8 months' },
        { value: '10m', label: 'Every 10 months' },
        { value: '12m', label: 'Every 12 months' },
]

export const instanceOptions: SelectOption[] = [
    { value: '', label: 'Continuous'},
]
for (let i = 1; i <= 52; i++) {
    instanceOptions.push({
        label: i.toString(), value: i.toString(),
    })
}

export const classTypeOptions: SelectOption[] = [
    { value: 'dropin', label: 'Single drop-in' },
    { value: 'open', label: 'Open enrollment' },
    { value: 'series', label: 'Series' },
]

export const classSizeOptions: SelectOption[] = []
for (let i = 1; i <= 100; i++) {
    classSizeOptions.push({
        label: i.toString(), value: i.toString(),
    })
}

export const classCountOptions: SelectOption[] = []
for (let i = 1; i <= 30; i++) {
    classCountOptions.push({
        label: i.toString(), value: i.toString(),
    })
}
export const timeOfDayOptions: SelectOption[] = []
for (let i = 0; i < 24; i++) {
    timeOfDayOptions.push({value: i.toString(), label: moment().hour(i).minute(0).format('h:mma') })
    timeOfDayOptions.push({value: (i+0.25).toString(), label: moment().hour(i).minute(15).format('h:mma') })
    timeOfDayOptions.push({value: (i+0.5).toString(), label: moment().hour(i).minute(30).format('h:mma') })
    timeOfDayOptions.push({value: (i+0.75).toString(), label: moment().hour(i).minute(45).format('h:mma') })
}
timeOfDayOptions.push({value: '24', label: moment().hour(0).minute(0).format('h:mma') })


export const blockCountOptions: SelectOption[] = [
    { value: '', label: 'Repeat forever' },
]
for (let i = 2; i <= 24; i++) {
    blockCountOptions.push({
        label: i.toString(), value: i.toString(),
    })
}
for (let i = 30; i <= 60; i += 10) {
    blockCountOptions.push({
        label: i.toString(), value: i.toString(),
    })
}

export const lessonCountOptions: SelectOption[] = [
    { value: '', label: 'Repeat forever' },
]
for (let i = 2; i <= 24; i++) {
    lessonCountOptions.push({
        label: i.toString(), value: i.toString(),
    })
}
for (let i = 30; i <= 200; i += 10) {
    lessonCountOptions.push({
        label: i.toString(), value: i.toString(),
    })
}

export const fieldQuestionTypeOptions : SelectOption[] = [
    { value: 'text', label: 'Text' },
    { value: 'integer', label: 'Integer' },
    { value: 'select', label: 'Single choice' },
    { value: 'multiselect', label: 'Multiple choice' },
    { value: 'checkbox', label: 'Checkbox' },
    { value: 'date', label: 'Date' },
    { value: 'float', label: 'Decimal number' },
    { value: 'currency', label: 'Currency' },
    { value: 'longtext', label: 'Long text' },
    { value: 'email', label: 'Email' },
    { value: 'phone', label: 'Phone' },
    { value: 'image', label: 'Image' },
    { value: 'document', label: 'File upload' },
]

export const dashboardDateFilterOptionsWithQuarter: SelectOption[] = [
    { value: 'wtd', label: 'This week' },
    { value: 'mtd', label: 'This month' },
    { value: 'qtd', label: 'This quarter' },
    { value: 'ytd', label: 'This year' },
    { value: 'all', label: 'Total' },
]

export const incomeDateFilterOptions: SelectOption[] = [
    { value: 'wtd', label: 'Week to date' },
    { value: 'mtd', label: 'Month to date' },
    { value: 'qtd', label: 'Quarter to date' },
    { value: 'ytd', label: 'Year to date' },
    { value: 'lw', label: 'Last week' },
    { value: 'lm', label: 'Last month' },
    { value: 'lq', label: 'Last quarter' },
    { value: 'ly', label: 'Last year' },
    { value: 'lyq', label: 'Last year (quarter)' },
    { value: 'all', label: 'Total (all)' },
]

export const futureIncomeDateFilterOptions: SelectOption[] = [
    { value: 'tw', label: 'This week' },
    { value: 'tm', label: 'This month' },
    { value: 'tq', label: 'This quarter' },
    { value: 'ty', label: 'This year' },
    { value: 'nw', label: 'Next week' },
    { value: 'nm', label: 'Next month' },
    { value: 'nq', label: 'Next quarter' },
    { value: 'all', label: 'Total (all)' },
]

export const dataExportDateFilterOptions: SelectOption[] = [
    { value: '1d', label: 'Last 24 hours' },
    { value: '1w', label: 'Last 7 days' },
    { value: 'lw', label: 'Last week' },
    { value: '1m', label: 'Last 30 days' },
    { value: 'lm', label: 'Last month' },
    { value: '3m', label: 'Last 90 days' },
    { value: 'lq', label: 'Last quarter' },
    { value: '12m', label: 'Last 12 months' },
    { value: 'ly', label: 'Last year' },
    { value: 'td', label: 'Today' },
    { value: 'tw', label: 'This week' },
    { value: 'tm', label: 'This month' },
    { value: 'tq', label: 'This quarter' },
    { value: 'ty', label: 'This year' },
    { value: 'nd', label: 'Next day' },
    { value: 'nw', label: 'Next 7 days' },
    { value: 'n30d', label: 'Next 30 days' },
    { value: 'nm', label: 'Next month' },
    { value: 'n90d', label: 'Next 90 days' },
    { value: 'nq', label: 'Next quarter' },
    { value: '2015', label: 'FY 2015' },
    { value: '2016', label: 'FY 2016' },
    { value: '2017', label: 'FY 2017' },
    { value: '2018', label: 'FY 2018' },
    { value: '2019', label: 'FY 2019' },
    { value: '2020', label: 'FY 2020' },
    { value: '2021', label: 'FY 2021' },
    { value: '2022', label: 'FY 2022' },
    { value: 'wtd', label: 'This week to date' },
    { value: 'mtd', label: 'This month to date' },
    { value: 'qtd', label: 'This quarter to date' },
    { value: 'ytd', label: 'This year to date' },
    { value: 'lwtd', label: 'Last week to date' },
    { value: 'lmtd', label: 'Last month to date' },
    { value: 'lqtd', label: 'Last quarter to date' },
    { value: 'lytd', label: 'Last year to date' },
    { value: 'all', label: 'All' },
]

export const dashboardDateFilterOptions: SelectOption[] = [
    { value: 'tw', label: 'This week' },
    { value: 'tm', label: 'This month' },
    { value: 'tq', label: 'This quarter' },
    { value: 'ty', label: 'This year' },
    { value: 'nw', label: 'Next week' },
    { value: 'nm', label: 'Next month' },
    { value: 'nq', label: 'Next quarter' },
    { value: 'ny', label: 'Next year' },
    { value: 'lw', label: 'Last week' },
    { value: 'lm', label: 'Last month' },
    { value: 'lq', label: 'Last quarter' },
    { value: 'ly', label: 'Last year' },
    { value: 'wtd', label: 'Week to date' },
    { value: 'mtd', label: 'Month to date' },
    { value: 'qtd', label: 'Quarter to date' },
    { value: 'ytd', label: 'Year to date' },
    { value: 'week', label: 'Next 7 days' },
    { value: 'month', label: 'Next 30 days' },
    { value: 'quarter', label: 'Next 90 days' },
    { value: 'year', label: 'Next 365 days' },
    { value: 'pw', label: 'Last 7 days' },
    { value: 'pm', label: 'Last 30 days' },
    { value: 'pq', label: 'Last 90 days' },
    { value: 'py', label: 'Last 365 days' },
    { value: 'all', label: 'Total (all)' },
]

export const inventoryDateFilterOptions: SelectOption[] = [
    { value: 'week', label: 'Next 7 days' },
    { value: 'month', label: 'Next 30 days' },
    { value: 'quarter', label: 'Next 90 days' },
    { value: 'year', label: 'Next 365 days' },
    { value: 'all', label: 'Total (all)' },
]

export const bookingStatusOptions: SelectOption[] = [
    { value: '', label: 'Already confirmed' },
    { value: 'confirmOnly', label: 'Requires confirmation' },
    { value: 'confirmPayment', label: 'Requires credit card' },
]

export const bookingSendViaOptions: SelectOption[] = [
    { value: '', label: "Don't notify" },
    { value: 'sms', label: 'Text message' },
    { value: 'combo', label: 'Text and email' },
    { value: 'email', label: 'Email' },
]

export const bookingSendViaOptionsNoDont: SelectOption[] = bookingSendViaOptions.slice(1)

export const invoiceReminderOptions: SelectOption[] = [
    {value:"1", label:"Every day"},
    {value:"3", label:"Every 3 days"},
    {value:"7", label:"Once a week"},
    {value:"14", label:"Every 2 weeks"},
    {value:"30", label:"Every 30 days"},
]

export const estimateReminderOptions: SelectOption[] = [
    {value:"1", label:"Every day"},
    {value:"3", label:"Every 3 days"},
    {value:"7", label:"Once a week"}
]

export const timeZoneOptions: SelectOption[] = [
    { label: 'Eastern Time' , value: 'America/New_York' },
    { label: 'Pacific Time', value: 'America/Los_Angeles' },
    { label: 'Central Time', value: 'America/Chicago' },
    { label: 'Mountain Time', value: 'America/Denver' },
    { label: 'Arizona Time', value: 'America/Phoenix' },
    { label: 'Alaska Time', value: 'America/Anchorage' },
    { label: 'Hawaii Time', value: 'Pacific/Honolulu' }
]

export const requireCreditCardOptions: SelectOption[] = [
    { label: 'Don\'t Require' , value: 'never' },
    { label: 'Only if priced', value: 'ifPriced' },
    { label: 'Always', value: 'always' }
]

export const confirmationStatusOptions: SelectOption[] = [
    { label: 'Already Confirmed' , value: 'confirmed' },
    { label: 'Requires confirmation', value: 'confirmOnly' },
    { label: 'Requires credit card', value: 'confirmPayment' }
]

export const maxAvailableRangeOptions: SelectOption[] = [
    { label: 'This Week' , value: 'tw' },
    { label: 'This Month', value: 'tm' },
    { label: '1 day', value: '1d' },
    { label: '2 days', value: '2d' },
    { label: '3 days', value: '3d' },
    { label: '4 days', value: '4d' },
    { label: '5 days', value: '5d' },
    { label: '6 days', value: '6d' },
    { label: '1 week', value: '1w' },
    { label: '2 weeks', value: '2w' },
    { label: '3 weeks', value: '3w' },
    { label: '4 weeks', value: '4w' },
    { label: '5 weeks', value: '5w' },
    { label: '6 weeks', value: '6w' },
    { label: '1 month', value: '1m' },
    { label: '2 months', value: '2m' },
    { label: '3 months', value: '3m' },
    { label: '4 months', value: '4m' },
    { label: '5 months', value: '5m' },
    { label: '6 months', value: '6m' }
]

export const bufferTimeOptions = (forPayee: boolean) : SelectOption[] => {
    const options: SelectOption[] = []
    if (forPayee) {
        options.push({ label: 'Same as business', value: ''})
        options.push({ label: 'None', value: '0'})
    }
    else
        options.push({ label: 'None', value: ''})
    options.push({ label: '5 minutes', value: '5' })
    options.push({ label: '10 minutes', value: '10' })
    options.push({ label: '15 minutes', value: '15' })
    options.push({ label: '20 minutes', value: '20' })
    options.push({ label: '25 minutes', value: '25' })
    options.push({ label: '30 minutes', value: '30' })
    options.push({ label: '45 minutes', value: '45' })
    options.push({ label: '1 hour', value: '60' })
    options.push({ label: '90 minutes', value: '90' })
    options.push({ label: '2 hours', value: '120' })
    return options
}

export const flexStartOptions: SelectOption[] = [
    { label: 'No Flex', value: '' },
    { label: 'No Flex + buffer', value: 'buffer' },
    { label: 'Flex 10 minutes', value: '10' },
    { label: 'Flex 15 minutes', value: '15' },
    { label: 'Flex 20 minutes', value: '20' },
    { label: 'Flex 30 minutes', value: '30' },
    { label: 'Flex 60 minutes', value: '60' }
]

export const discountPercentOptions: SelectOption[] = [
    { label: "None", value: "" },
    { label: "10%", value: "10" },
    { label: "12%", value: "12" },
    { label: "14%", value: "14" },
    { label: "15%", value: "15" },
    { label: "18%", value: "18" },
    { label: "20%", value: "20" },
    { label: "22%", value: "22" },
    { label: "25%", value: "25" },
    { label: "30%", value: "30" },
    { label: "35%", value: "35" },
    { label: "40%", value: "40" },
    { label: "45%", value: "45" },
    { label: "50%", value: "50" },
    { label: "55%", value: "55" },
    { label: "60%", value: "60" },
    { label: "65%", value: "65" },
    { label: "70%", value: "70" },
    { label: "75%", value: "75" },
    { label: "80%", value: "80" },
    { label: "90%", value: "90" }
]

export const gratuityPercentOptions: SelectOption[] = [
    { label: "None", value: "" },
    { label: "10%", value: "10" },
    { label: "15%", value: "15" },
    { label: "20%", value: "20" },
    { label: "25%", value: "25" },
    { label: "30%", value: "30" },
    { label: "35%", value: "35" },
    { label: "40%", value: "40" },
    { label: "45%", value: "45" },
    { label: "50%", value: "50" },
    { label: "55%", value: "55" },
    { label: "60%", value: "60" },
    { label: "65%", value: "65" },
    { label: "70%", value: "70" },
    { label: "75%", value: "75" },
    { label: "80%", value: "80" },
    { label: "90%", value: "90" },
    { label: "100%", value: "100" }
]

export const deadlineOptions: SelectOption[] = [
    { label: "1 hour", value: "1" },
    { label: "2 hours", value: "2" },
    { label: "6 hours", value: "6" },
    { label: "12 hours", value: "12" },
    { label: "24 hours", value: "24" },
    { label: "48 hours", value: "48" },
    { label: "72 hours", value: "72" },
    { label: "One week", value: "168" },
    { label: "Two weeks", value: "336" },
    { label: "Four weeks", value: "672" }
]

export const packagePolicyOptions: SelectOption[] = [
    { label: "Charge fee", value: "charge" },
    { label: "Lose session", value: "session" },
    { label: "Both", value: "both" }
]

export const gratuityOptions: SelectOption[] = [
    { label: 'At booking and final checkout', value: 'always' },
    { label: 'Only at final checkout', value: 'charge' }
]


export const readerTypeOptions = (includeMobileReaders?: boolean) => {
    let options: SelectOption[] = [
        { label: 'Wise POSe', value: 'bbpos_wisee' }
    ]
    if (includeMobileReaders) {
        options = [
            { label: 'Stripe M2', value: 'stripe_m2' },
            { label: 'BBPOS Chipper 2x', value: 'bbpos_chipper2x' },
            ...options,
        ]
    }
    return options
}

export const bookingReminderOptions: SelectOption[] = [
    { value: '', label: "Don't remind" },
    { value: '2', label: '2 hours' },
    { value: '4', label: '4 hours' },
    { value: '6', label: '6 hours' },
    { value: '8', label: '8 hours' },
    { value: '12', label: '12 hours' },
    { value: '24', label: '24 hours' },
    { value: '36', label: '36 hours' },
    { value: '48', label: '2 days' },
    { value: '72', label: '3 days' },
]

export const bookingReminderOptionsNoDont: SelectOption[] = bookingReminderOptions.slice(1)


export const bookingRebookOptions: SelectOption[] = [
    { value: '', label: 'None' },
    { value: '7d', label: '7 days' },
    { value: '14d', label: '14 days' },
    { value: '28d', label: '4 weeks' },
    { value: '42d', label: '6 weeks' },
    { value: '1m', label:  '1 month' },
    { value: '2m', label:  '2 months' },
    { value: '3m', label:  '3 months' },
    { value: '6m', label:  '6 months' },
    { value: '12m', label: '1 year' },
    { value: '24m', label: '2 years' },
]

export const timeHourOptions: SelectOption[] = [
    { value: '00', label: '12 am' },
    { value: '01', label: '1 am' },
    { value: '02', label: '2 am' },
    { value: '03', label: '3 am' },
    { value: '04', label: '4 am' },
    { value: '05', label: '5 am' },
    { value: '06', label: '6 am' },
    { value: '07', label: '7 am' },
    { value: '08', label: '8 am' },
    { value: '09', label: '9 am' },
    { value: '10', label: '10 am' },
    { value: '11', label: '11 am' },
    { value: '12', label: '12 pm' },
    { value: '13', label: '1 pm' },
    { value: '14', label: '2 pm' },
    { value: '15', label: '3 pm' },
    { value: '16', label: '4 pm' },
    { value: '17', label: '5 pm' },
    { value: '18', label: '6 pm' },
    { value: '19', label: '7 pm' },
    { value: '20', label: '8 pm' },
    { value: '21', label: '9 pm' },
    { value: '22', label: '10 pm' },
    { value: '23', label: '11 pm' },
]

export const timeMinuteIntervalFiveOptions: SelectOption[] = []
for (let i = 0; i < 10; i += 5) {
    timeMinuteIntervalFiveOptions.push({
        value: i.toString(),
        label: `0${i}`,
    })
}
for (let i = 10; i < 60; i += 5)
    timeMinuteIntervalFiveOptions.push({value: i.toString(), label: i.toString()})

export const taxCodeOptions: SelectOption[] = [
    { value: '', label: "Don't collect" },
    { value: 'pro', label: "At business location" },
    { value: 'transaction', label: "At transaction location" },
]

export const invoicePaymentOptions: SelectOption[] = [
    { value: '', label: 'Single payment' },
    { value: '2_10', label: '2 payments (10% min)' },
    { value: '2_15', label: '2 payments (15% min)' },
    { value: '2_20', label: '2 payments (20% min)' },
    { value: '2_25', label: '2 payments (25% min)' },
    { value: '2_30', label: '2 payments (30% min)' },
    { value: '2_40', label: '2 payments (40% min)' },
    { value: '2_50', label: '2 payments (50% min)' },
    { value: '4_25', label: '4 payments (25% min)' },
]

export const invoiceCategoryOptions: SelectOption[] = [
    { value: 'supplies', label: 'Supplies' },
    { value: 'labor', label: 'Labor' },
    { value: 'mileage', label: 'Gas & mileage' },
    { value: 'meals', label: 'Meals & entertainment' },
    { value: 'travel', label: 'Travel' },
    { value: 'fees', label: 'Fees' },
    { value: 'adjustments', label: 'Adjustments' },
]

export const yearsInBusinessOptions: SelectOption[] = [
    { value: '0', label: '< 1 year old' },
    { value: '1', label: '1 year old'},
    { value: '2', label: '2 years old'},
    { value: '3', label: '3 years old'},
    { value: '4', label: '4 years old'},
    { value: '5', label: '5 years old'},
    { value: '6', label: '6 years old'},
    { value: '7', label: '7 years old'},
    { value: '8', label: '8 years old'},
    { value: '9', label: '9 years old'},
    { value: '10', label: '10+ years old'}
]

export const loginTTLOptions: SelectOption[] = [
    { value: '7', label: '7 days' },
    { value: '14', label: '14 days' },
    { value: '30', label: '30 days' },
    { value: '90', label: '90 days' },
]

export const colorThemeOptions: SelectOption[] = [
    { value: '#294b6b', label: 'Suite blue' },
    { value: '#5cb85c', label: 'Suite green' },
    { value: '#c0392c', label: 'Brick' },
    { value: '#af473c', label: 'Chilli' },
    { value: '#f5413b', label: 'Red' },
    { value: '#984b43', label: 'Rusty red' },
    { value: '#b63033', label: 'Moderate red' },
    { value: '#996633', label: 'Brown' },
    { value: '#76323f', label: 'Oxblood' },
    { value: '#dcb239', label: 'Lemon' },
    { value: '#f39c12', label: 'Squash' },
    { value: '#e9b000', label: 'Mustard' },
    { value: '#f1c40e', label: 'Golden rod' },
    { value: '#2ecd70', label: 'Greenish teal' },
    { value: '#27ae5f', label: 'Jade green' },
    { value: '#1e392a', label: 'Forest' },
    { value: '#0e7036', label: 'Spruce' },
    { value: '#1a2930', label: 'Denim' },
    { value: '#3398dc', label: 'Dark sky blue' },
    { value: '#38629b', label: 'Muted blue' },
    { value: '#31465a', label: 'Dark grey blue' },
    { value: '#18121e', label: 'Navy blue' },
    { value: '#373737', label: 'Charcoal' },
    { value: '#07889b', label: 'Teal' },
    { value: '#009999', label: 'Dark cyan' },
    { value: '#1bb6bc', label: 'Topaz' },
    { value: '#233237', label: 'Gunmetal' },
    { value: '#bdc3c7', label: 'Silver' },
    { value: '#94a5a6', label: 'Cool grey' },
    { value: '#3d3d3d', label: 'Very dark grey' },
    { value: '#565656', label: 'Blackboard' },
    { value: '#945d60', label: 'Terracotta' },
    { value: '#813772', label: 'Posy' },
    { value: '#94618e', label: 'Grape' },
    { value: '#49274a', label: 'Eggplant' },
    { value: '#a239ca', label: 'Fuchsia' },
    { value: '#6d7993', label: 'Lavender' },
    { value: '#9b59b6', label: 'Deep lavender' },
    { value: '#450045', label: 'Very dark magenta' },
    { value: '#ff38b2', label: 'Barbie pink' },
    { value: '#ffaacd', label: 'Powder pink' },
    { value: '#1d2731', label: 'Ivory black' },
    { value: '#1c1c1c', label: 'Black' }
]

const continuousOption = {value: '', label: 'Continuous'} as SelectOption
export const numberOptions = (countTo: number, includeContinuous?: boolean) => {
    let numbers: SelectOption[] = []
    if (includeContinuous) {
        numbers.push(continuousOption)
    }
    for (let i = 1; i <= countTo; i++) {
        numbers.push({value: i.toString(), label: i.toString()})
    }
    return numbers
}

export const availabilityHours: SelectOption[] = []
for (let i = 0; i < 24; i++) {
    availabilityHours.push({value: i.toString(), label: moment().hour(i).minute(0).format('h:mma') })
    availabilityHours.push({value: (i+0.5).toString(), label: moment().hour(i).minute(30).format('h:mma') })
}
availabilityHours.push({value: '24', label: moment().hour(0).minute(0).format('h:mma') })

export const videoConferenceOptions = (zoomConnected: boolean) => {
    const options: SelectOption[] = []
    if (zoomConnected) {
        options.push({ label: 'Attach Zoom link', value: 'zoom'})
    }
    options.push({ label: 'Attach PocketSuite link', value: 'pocketsuite'})
    return options
}

export const markPaidPaymentOptions: SelectOption[] = [
    { value: 'cash', label: 'Cash' },
    { value: 'check', label: 'Check'},
    { value: 'square', label: 'Square'},
    { value: 'paypal', label: 'PayPal'},
    { value: 'venmo', label: 'Venmo'},
    { value: 'cashapp', label: 'Cash App'},
    { value: 'zelle', label: 'Zelle'},
    { value: 'applepay', label: 'Apple Pay'},
    { value: 'googlepay', label: 'Google Pay'},
    { value: 'other', label: 'Other'}
]

export const cancellationTypeOptions: SelectOption[] = [
    { value: 'late_cancellation', label: 'Late cancellation' },
    { value: 'no_show', label: 'No show'},
    { value: 'courtesy', label: 'Courtesy cancellation'},
    { value: 'reschedule', label: 'Rescheduled'},
]

export const notifyTypeOptions: SelectOption[] = [
    { value: 'no_notify', label: 'Do not Notify' },
    { value: 'notify', label: 'Notify'}
]

export const silenceTransactionOptions: SelectOption[] = [
    { value: 'silence', label: 'Silence only'},
    { value: 'mark', label: 'Silence & mark read'}
]

export const refundAmountOptions: SelectOption[] = [
    { value: 'full', label: 'Full refund (with fees)' },
    { value: 'net', label: 'Full refund (minus fees)' },
    { value: 'partial', label: 'Partial refund' },
]

export const markRefundAmountOptions: SelectOption[] = [
    { value: 'full', label: 'Full refund' },
    { value: 'partial', label: 'Partial refund' },
]

export const refundReasonOptions: SelectOption[] = [
    { value: 'cancel', label: 'Services not rendered' },
    { value: 'overcharge', label: 'Overcharged' },
    { value: 'courtesy', label: 'Courtesy refund' },
    { value: 'paid', label: 'Client already paid' },
]

export const cancellationReasonOptions: SelectOption[] = [
    { value: 'price', label: 'Pricing' },
    { value: 'covid19', label: 'Impacted by Covid-19' },
    { value: 'bugs', label: 'Bugs, problems' },
    { value: 'feature', label: 'Missing features' },
    { value: 'desktop', label: 'Desktop access' },
    { value: 'payments', label: 'Can\'t process payments' },
    { value: 'break', label: 'Taking a break from the business' },
    { value: 'closed', label: 'Closing the business' },
    { value: 'other', label: 'Other' },
]