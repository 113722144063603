import React, { useCallback, useEffect, useRef, useState } from "react";
import {useAppDispatch, useConfirmModal, usePSUser} from "../../../hooks";
import { Item } from "@zendeskgarden/react-dropdowns"
import { Field, IInputProps, Input, Label, Message } from "@zendeskgarden/react-forms"
import {PSButton, PSButtonPrimary} from "../../app/PSButton";
import { PSDropdown } from "../../app/PSDropdown";
import { VALIDATION } from "@zendeskgarden/react-forms/dist/typings/utils/validation";
import { orderReader, fetchSources, SettingsState } from "../../../modules/settings";
import {ReactComponent as LeftArrow} from "../../../icons/left-arrow.svg";
import {readerTypeOptions, SelectOption} from "../Options";
import InputMask from 'react-input-mask'
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { apiClient } from "../../../modules/apiclient";
import debounce from "lodash.debounce";
import { currencyFormat, filterNumericInput } from "../../../utils/numbers";
import { useHistory } from "react-router";
import { SourceInstance } from "../../../models/Source";
import { CardEdit } from "./CardEdit";
import { isEmpty, isValid, isValidPostalCode, Result } from "../../../utils";
import { isProductTaxable, isShippingTaxable } from "../../../models/Taxcode";

const useDebouncedCallback = (callback: any, delay: number) => {
    const callbackRef = useRef<any>(callback)
    callbackRef.current = callback;
    return useCallback(debounce(
        (...args) => callbackRef.current!(...args),
        delay,
    ), []);
};

export const NewReaderCheckout = () => {
    const READER_COST = 250;
    const SHIPPING_COST = 13;
    const dispatch = useAppDispatch()
    const history = useHistory()
    const settingsState: SettingsState = useSelector((state: RootState) => state.settings)

    const user = usePSUser()
    const [street, setStreet] = useState('')
    const [unit, setUnit] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] =useState('')
    const [costSummary, setCostSummary] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [postalCodeValidation, setPostalCodeValidation] =  useState<VALIDATION | undefined>()
    const [streetValidation, setStreetValidation] = useState<VALIDATION | undefined>()
    const [chargeEnabled, setChargeEnabled] = useState(false)
    const [currentSource, setCurrentSource] = useState<SourceInstance | ''>('')
    const [showCardEdit, setShowCardEdit] = useState(false)

    const [quantity, setQuantity] = useState(1)
    const [readerType, setReaderType] = useState<SelectOption>(readerTypeOptions()[0])

    useEffect(() => {
        setPostalCodeValidation(postalCode?.match(/\d{5}/) ? undefined : 'error')
    }, [postalCode, user])

    useEffect(() => {
        setStreetValidation(street?.replace(/\s/g, '').length ? undefined : 'error')
    }, [street, user])

    useEffect(() => {
        setChargeEnabled(isValid(postalCodeValidation) && isValid(streetValidation) && !!currentSource && currentSource.id !== 'new')
    }, [postalCodeValidation, streetValidation, currentSource])

    const [setModal, setModalLoading, showModal, closeModal, modalComponent] = useConfirmModal()


    useEffect(() => {
        if (!user) return

        setStreet(user.line1)
        setUnit(user.line2)
        setCity(user.city)
        setPostalCode(user.postal_code)
    }, [user])

    const [taxRate, setTaxRate] = useState(0)
    const [taxCode, setTaxCode] = useState('')
    const handleApiCall = useDebouncedCallback(() => {
        apiClient.post(`/taxcode`, {'location': `${street} ${unit}, ${city} ${postalCode}`})
            .then(resp => resp.data)
            .then(json => {                
                setTaxRate(Number(json?.rate) || 0)
                setTaxCode(json?.id || '')
                setState(json?.state || '')
            })
    }, 200)

    useEffect(() => {
        if (!readerType)
            return

        const rate = taxRate / 100;
        const lineTotal = READER_COST * quantity;
        let tax = (isProductTaxable(state) ? lineTotal : 0) * rate;
        tax += (isShippingTaxable(state) ? SHIPPING_COST : 0) * rate;
        const totalStr = currencyFormat(lineTotal + tax + SHIPPING_COST)
        let extraStr = ''
        if (tax > 0) {
            extraStr = `, ${currencyFormat(tax)} tax`
        }
        setCostSummary(`${totalStr} (incl. ${currencyFormat(SHIPPING_COST)} shipping${extraStr})`)
    }, [readerType, taxRate, quantity, state])

    useEffect(() => {
        dispatch(fetchSources())
    }, [dispatch])

    const onPurchase = useCallback(() => {
        setModal({
            title: 'Order reader',
            body: `You're about to order a card reader for a total amount of ${costSummary}. Do you want to confirm this order?`,
            button: 'Yes, place order',
            optionalButton: 'Cancel',
            onClick: (optionalButton) => {
                if (!optionalButton && typeof currentSource !== 'string') {
                    setModalLoading(true)
                    Result(dispatch(orderReader(quantity, currentSource.id!, taxCode, street, unit, city, postalCode)))
                        .then(() => history.goBack())
                        .finally(() => {
                            setModalLoading(false)
                            closeModal()
                        })
                }
                else if (optionalButton) {
                    closeModal()
                }
            }
        })
        showModal()
    }, [city, closeModal, costSummary, currentSource, dispatch, history, postalCode, quantity, setModal, setModalLoading, showModal, street, taxCode, unit])

    useEffect(() => {
        if (!street || !isValidPostalCode(postalCode)) return

        handleApiCall()
    }, [street, postalCode, handleApiCall])

    useEffect(() => {
        if (!isEmpty(settingsState.source) && settingsState.sources.includes(settingsState.source)) {
            setCurrentSource(settingsState.source)
        }
        else {
            setCurrentSource('')
        }
    }, [settingsState.source, settingsState.sources])

    const orderDetails = <div className="orderDetails">
        <PSDropdown selected={readerType}
                    nameProperty={"label"}
                    onSelect={(selected) => setReaderType(selected)}
                    label={"Choose a card reader (required)"}>
            <>
                {readerTypeOptions().map(option => (
                    <Item key={`reader-type-${option.value}`} value={option}>
                        {option.label}
                    </Item>
                ))}
            </>
        </PSDropdown>
        <Field className="field">
            <Label className="label">Quantity</Label>
            <Input placeholder="Set a quantity"
                    value={quantity}
                    disabled={settingsState.isSending}
                    onChange={e => filterNumericInput(e.target.value, setQuantity)} />
        </Field>
        <Field className="field">
            <Label className="label">Cost $</Label>
            <Input disabled
                    value={costSummary}
                    onChange={e => { }} />
        </Field>
        <Field className="field">
            <PSDropdown selected={currentSource}
                        nameProperty="display_name"
                        downshiftProperty="id"
                        label="Select payment method (required)"
                        placeholder={<div className="grey">Select a card</div>}
                        onSelect={(selection) => selection?.id !== 'new' ? setCurrentSource(selection) : setShowCardEdit(true)}
            >
                {settingsState.sources.filter((source: any) => source.type !== "checking").map((source: any) => (
                    <Item key={`source-${source.id}`} value={source}>
                        {source.display_name}
                    </Item>
                ))}
                <Item key={`source-new`} value={{id: 'new'}}>
                    Add New Card
                </Item>
            </PSDropdown>
        </Field>
    </div>
    const shippingDetails = <div className='shippingDetails'>
        <Field className="field">
            <Label className="label">Street (required)</Label>
            <Input placeholder="Add street"
                    value={street}
                    validation={streetValidation}
                    disabled={settingsState.isSending}
                    onChange={e => setStreet(e.target.value)} />
        </Field>
        {!!streetValidation && (
            <Message validation={streetValidation}>
                A street address is required
            </Message>
        )}
        <Field className="field">
            <Label className="label">Unit</Label>
            <Input placeholder="Add unit number"
                    value={unit}
                    disabled={settingsState.isSending}
                    onChange={e => setUnit(e.target.value)} />
        </Field>
        <Field className="field">
            <Label className="label">City</Label>
            <Input placeholder="Add city"
                    value={city}
                    disabled={settingsState.isSending}
                    onChange={e => setCity(e.target.value)} />
        </Field>
        <Field className="field">
            <Label className="label">Postal code (required)</Label>
            <InputMask mask="99999"
                        value={postalCode}
                        alwaysShowMask={false}
                        maskChar={''}
                        disabled={settingsState.isSending}
                        onChange={e => setPostalCode(e.target.value)}>
                        {(inputProps: IInputProps) => <Input {...inputProps}
                            className="mobileNumberInput"
                            placeholder="Add zipcode"
                            disabled={settingsState.isSending}
                            validation={postalCodeValidation}
                            />}
            </InputMask>
            {!!postalCodeValidation && (
                <Message validation={postalCodeValidation}>
                    That does not appear to be a valid zipcode.
                </Message>
            )}
        </Field>
    </div>

    if (showCardEdit)
        return <CardEdit onBack={() => setShowCardEdit(false)} />
    return (
        <div className="servicesSettings newReaderCheckout">
            <div>
                <div className="row header">
                    <PSButton style={{marginLeft: 0, marginRight: 16}} onClick={() => history.goBack()}>
                        <LeftArrow />
                    </PSButton>
                    <h1>Order a card reader</h1>
                    <PSButtonPrimary style={{height: '40px', marginRight: '8px', marginLeft: 'auto'}}
                                     disabled={!chargeEnabled}
                                     onClick={() => onPurchase()}>
                        Purchase
                    </PSButtonPrimary>
                </div>
                <div className="separator" />
                {orderDetails}
                <div style={{fontSize: '22px', fontWeight: 700}}>Shipping details</div>
                <div className="separator" />
                {shippingDetails}
            </div>
            {modalComponent}
        </div>
    )
}


