import React from "react";
import {
    Body, Close,
    Footer,
    FooterItem,
    Header,
    Modal
} from "@zendeskgarden/react-modals";
import {ReactComponent as ErrorIcon} from "../../icons/alert_red_circle.svg";
import {Button} from "@zendeskgarden/react-buttons";

type Props = {
    title: string,
    message: string | React.ReactNode,
    buttonText: string,
    onClose: () => void,
    onButtonClick: () => void,
}

export const ErrorModal = ({title, message, buttonText, onClose, onButtonClick}: Props) => {
    return (
        <Modal onClose={onClose}>
            <Header style={{display: 'flex', color: '#EB5757'}}>
                <ErrorIcon />
                <span style={{fontWeight: 700, paddingLeft: 8}}>{title}</span>
            </Header>
            <Body>
                {message}
            </Body>
            <Footer>
                <FooterItem>
                    <Button onClick={onButtonClick}
                            style={{
                                backgroundColor: '#EB5757',
                                border: '1px solid #EB5757',
                                color: '#ffffff'
                            }}
                    >
                        {buttonText}
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )
}
