import React, {useCallback, useEffect, useState} from "react";
import {PSButtonDanger, PSButtonPrimary} from "../../app/PSButton";
import {ReactComponent as PlusIcon} from "../../../icons/plus_ps_blue.svg";
import {
    Checkbox,
    Field,
    Hint,
    Input,
    Label,
} from "@zendeskgarden/react-forms";
import { Item } from "@zendeskgarden/react-dropdowns";
import {Prompt, useHistory, useParams} from "react-router";
import {IconButton} from "@zendeskgarden/react-buttons";
import {GuardrailModal} from "../GuardrailModal";
import {AvailableHoursModal, Weekday} from "../AvailableHoursModal";
import {useSelector} from "react-redux";
import {
    clearItem, deleteItem,
    fetchAttachments,
    fetchCredentials,
    fetchItem, saveItem,
    SettingsState
} from "../../../modules/settings";
import {useAppDispatch, usePSOwner} from "../../../hooks";
import {RootState} from "../../../store";
import {Dots, Spinner} from "@zendeskgarden/react-loaders";
import {
    createAvailabilitySummary, createAvailabilityArray,
    Item as PSItem, areEqual,
} from "../../../models/Item";
import {PSDropdown} from "../../app/PSDropdown";
import {currencyFloat, filterMonetaryInput} from "../../../utils/numbers";
import {LocationModal} from "../LocationModal";
import {Header, Modal, Body, Close} from "@zendeskgarden/react-modals";
import {
    LearnMoreContractsBody,
    LearnMoreFormsBody,
    LearnMoreLeadTimeBody
} from "../LearnMore";
import {usePhotoUpload} from "../hooks";
import {
    discountOptions,
    durationOptions,
    leadtimeOptions,
    SelectOption,
    videoConferenceOptions
} from "../Options";
import {SettingsPhotoField} from "../SettingsPhotoField";
import {PSTextarea} from "../../app/PSTextarea";
import {updateItem} from "../../../modules/onboarding";

type Props = {
    forOnboarding?: boolean,
    refreshData?: boolean,
}

export const ServiceEdit = ({forOnboarding, refreshData}: Props) => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const history = useHistory()

    const [photo, setPhoto] = useState<undefined | string | File>('')
    const [image, setImage] = useState<undefined | string>('') // For editing
    const [name, setName] = useState('')
    const [price, setPrice] = useState('')
    const [deposit, setDeposit] = useState('')
    const [discount, setDiscount] = useState<any>('')
    const [salesTax, setSalesTax] = useState(false)
    const [description, setDescription] = useState('')
    const [duration, setDuration] = useState<string | SelectOption>('')
    const [leadtime, setLeadtime] = useState<string | SelectOption>( '')
    const [online, setOnline] = useState(false)
    const [singleCapacity, setSingleCapacity] = useState(false)
    const [availability, setAvailability] = useState<Weekday[]>([])
    const [availabilitySummary, setAvailabilitySummary] = useState('Same as business')
    const [contract, setContract] = useState<any>('')
    const [form, setForm] = useState<any>('')
    const [videoConference, setVideoConference] = useState(false)
    const [videoConferenceType, setVideoConferenceType] = useState<SelectOption>()
    const [isVirtual, setIsVirtual] = useState(false)
    const [clientSelectsLocation, setClientSelectsLocation] = useState(false)
    const [saveEnabled, setSaveEnabled] = useState(false)
    const [formSending, setFormSending] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)
    const [zoomConnected, setZoomConnected] = useState(false)

    const [addPhotoRef, selectPhoto, selectedPhoto] = usePhotoUpload({disabled: state.isSending, setPhoto})
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    const [salesTaxEnabled, setSalesTaxEnabled] = useState(false)
    const [onlineBookingEnabled, setOnlineBookingEnabled] = useState(false)
    const [discountEnabled, setDiscountEnabled] = useState(false)
    const [formsEnabled, setFormsEnabled] = useState(false)
    const [contractsEnabled, setContractsEnabled] = useState(false)
    const [scheduleHere, setScheduleHere] = useState(false)
    const owner = usePSOwner()
    useEffect(() => {
        if (!owner) return
        setSalesTaxEnabled(owner.allow_salestax)
        setOnlineBookingEnabled(owner.allow_widget)
        setDiscountEnabled(owner.allow_discount)
        setFormsEnabled(owner.allow_record)
        setContractsEnabled(owner.allow_contract)
        setScheduleHere(owner.location)
    }, [owner])

    useEffect(() => {
        dispatch(fetchCredentials())
    }, [dispatch])

    useEffect(() => {
        if (state.credentials.find(credential => credential.provider === 'zoom')) {
            setZoomConnected(true)
        }
    }, [state.credentials])

    // Enforce that price must be greater or equal to deposit
    const priceValidator = () => {
        if (Number(deposit) > Number(price))
            setPrice(deposit)
    }

    // Available hours modal
    const [showAvailableHours, setShowAvailableHours] = useState(false)

    // Location selection handling. When 'Another location' is selected, we show
    // the LocationModal to get the user to input the address.
    const [locationDetails, setLocationDetails] = useState('')
    const [showLocationModal, setShowLocationModal] = useState(false)
    const locationOptions = [
        { label: 'No location', value: 'none' },
    ]
    if (scheduleHere)
        locationOptions.push({ label: 'Business location', value: 'business' })
    locationOptions.push({ label: 'Client selects location', value: 'client' })
    if (locationDetails)
        locationOptions.push({ label: locationDetails, value: 'another' })
    else
        locationOptions.push({ label: 'Another location', value: 'another' })
    const [location, setLocation] = useState(locationOptions[1])

    const selectLocation = (selection: SelectOption) => {
        if (selection.value === 'another')
            setShowLocationModal(true)
        else
            setLocation(selection)
        setIsVirtual(selection.value === 'none')
        setClientSelectsLocation(selection.value === 'client')
    }

    const locationModalCompleted = (location: string) => {
        setLocationDetails(location)
        setLocation({label: location, value: 'another'})
        setShowLocationModal(false)
    }

    // Learn more
    const [learnMoreVisible, setLearnMoreVisible] = useState(false)
    const [learnMoreTitle, setLearnMoreTitle] = useState('')
    const [learnMoreBody, setLearnMoreBody] = useState<React.ReactNode | null>()
    const showLearnMore = (form: string, visible: boolean) => {
        switch (form) {
            case 'leadtime':
                setLearnMoreTitle('Lead time')
                setLearnMoreBody(LearnMoreLeadTimeBody)
                break
            case 'contracts':
                setLearnMoreTitle('Contracts')
                setLearnMoreBody(LearnMoreContractsBody)
                break
            case 'forms':
                setLearnMoreTitle('Forms')
                setLearnMoreBody(LearnMoreFormsBody)
                break
        }
        setLearnMoreVisible(visible)
    }

    // Fetch the user's contracts and forms
    useEffect(() => {
        dispatch(fetchAttachments())
    }, [dispatch])

    // Load the service if this is an edit, otherwise treat it as a create
    const params: any = useParams()
    useEffect(() => {
        if (forOnboarding) return

        if (!params.serviceID) {
            dispatch(clearItem())
            return
        }
        dispatch(fetchItem('service', params.serviceID))
    }, [dispatch, params.serviceID, forOnboarding])

    // Update the form's values after the service is loaded into state
    useEffect(() => {
        if (params.action !== 'copy')
            setName(state.item.name || '')
        setPhoto(state.item.image_uri || '')
        setImage(state.item.image || '')
        setPrice(state.item.rate ? currencyFloat(state.item.rate).toFixed(2) : '')
        setDeposit(state.item.deposit ? currencyFloat(state.item.deposit).toFixed(2) : '')

        // Get discount
        const selectedDiscount = discountOptions.find((option) => option.value === state.item.discount)
        setDiscount(selectedDiscount || '')

        setSalesTax(state.item.allow_salestax)
        setDescription(state.item.memo || '')

        // Get duration from durationOptions
        const selectedDuration = durationOptions.find((option) => option.value === state.item.duration?.toString())
        setDuration(selectedDuration || '')

        // Get leadtime from leadtimeOptions
        const selectedLeadTime = leadtimeOptions.find((option) => option.value === state.item.leadtime)
        setLeadtime(selectedLeadTime || '')

        setOnline(state.item.is_public)
        setSingleCapacity(Number(state.item.capacity) === 1)

        // Schedule of business hours
        const availableDays = createAvailabilityArray(state.item)
        setAvailability(availableDays)
        setAvailabilitySummary(createAvailabilitySummary(availableDays))

        // Get contract
        const selectedContract = state.contracts.find((c) => c.id === state.item.contract)
        setContract(selectedContract || '')

        // Get form
        const selectedForm = state.forms.find((f) => f.id === state.item.form)
        setForm(selectedForm || '')

        setVideoConference(state.item.allow_conference)
        setVideoConferenceType(!!state.item.conference_type ? videoConferenceOptions(zoomConnected).find(option => option.value === state.item.conference_type) : undefined)

        setIsVirtual(state.item.is_virtual)
        setClientSelectsLocation(state.item.client_selects_location)

        // Set location
        if (state.item.is_virtual)
            setLocation({ label: 'No location', value: 'none' })
        else if (state.item.location) {
            setLocation({label: state.item.location, value: 'another'})
            setLocationDetails(state.item.location)
        }
        else if (state.item.client_selects_location)
            setLocation({label: 'Client selects location', value: 'client'})
        else if (scheduleHere)
            setLocation({label: 'Business location', value: 'business'})
        else
            setLocation({label: 'Client selects location', value: 'client'})
    }, [state.item, state.contracts, state.forms, scheduleHere, zoomConnected])

    useEffect(() => {
        setSaveEnabled(name !== '' && (hasEdits || !!forOnboarding))
    }, [name, hasEdits, forOnboarding])

    useEffect(() => {
        if (forOnboarding) {
            setHasEdits(false)
            return
        }

        let changed = 0
        if ((state.item.name || name) && name !== state.item.name)
            changed = 1
        else if ((state.item.rate || price) && Number(price) !== Number(state.item.rate))
            changed = 2
        else if ((state.item.deposit || deposit) && deposit !== state.item.deposit)
            changed = 3
        else if ((state.item.memo || description) && description !== state.item.memo)
            changed = 4
        else if ((state.item.allow_salestax || salesTax) && salesTax !== state.item.allow_salestax)
            changed = 5
        else if ((state.item.discount || discount) && discount?.value !== state.item.discount)
            changed = 6
        else if ((state.item.duration || duration) && Number((duration as SelectOption).value) !== state.item.duration)
            changed = 9
        else if ((state.item.leadtime || leadtime) && (leadtime as SelectOption).value !== state.item.leadtime)
            changed = 10
        else if ((state.item.is_public || online) && online !== state.item.is_public)
            changed = 11
        else if ((state.item.capacity || singleCapacity) && (singleCapacity ? '1' : '' ) !== state.item.capacity.toString())
            changed = 12
        else if ((state.item.form || form) && form.id !== state.item.form)
            changed = 7
        else if ((state.item.contract || contract) && contract.id !== state.item.contract)
            changed = 8
        else if ((state.item.allow_conference || videoConference) && videoConference !== state.item.allow_conference)
            changed = 14
        else if (typeof photo === 'object')
            changed = 13
        else if ((state.item.is_virtual || isVirtual) && isVirtual !== state.item.is_virtual)
            changed = 16
        else if ((state.item.location || location.value) && location.value !== 'none' && location.value !== state.item.location)
            changed = 17
        else if ((availability.length || state.item.availability) && !areEqual(availability, state.item.availability ?? createAvailabilityArray(state.item)))
            changed = 15
        else if ((videoConferenceType || state.item.conference_type) && videoConferenceType !== state.item.conference_type)
            changed = 16
        else if ((state.item.client_selects_location || clientSelectsLocation) && clientSelectsLocation !== state.item.client_selects_location)
            changed = 18

        setHasEdits(changed > 0)
    }, [
        hasEdits, state.item, name, price, deposit, description, salesTax,
        discount, duration, leadtime, online, singleCapacity, videoConference, videoConferenceType,
        form, contract, photo, availability, isVirtual, location, forOnboarding, clientSelectsLocation])

    const changeSingleCapacity = (on: boolean) => {
        if (on) {
            setGuardrailTitle('Single capacity')
            setGuardrailBody('By toggling single capacity on this service can only be booked once. Multiple clients will not be able to book this service in the same time slot.')
            setGuardrailButton('Continue')
            setGuardrailType('singleCapacity')
            setShowGuardrail(true)
        }
        else {
            setSingleCapacity(false)
        }
    }

    const changeAvailability = () => {
        // If the availability is same as business hours, show a guardrail
        if (availabilitySummary === 'Same as business') {
            setGuardrailTitle('Updating service hours')
            setGuardrailBody('No need to set specific availability for your services unless they are different from your overall business hours.')
            setGuardrailButton('Continue')
            setGuardrailType('availability')
            setShowGuardrail(true)
        }
        else {
            setShowAvailableHours(true)
        }
    }

    const onAvailableHoursDone = (weekdays: Weekday[]) => {
        setShowAvailableHours(false)
        setAvailability(weekdays)
        setAvailabilitySummary(createAvailabilitySummary(weekdays))
    }

    const onSave = (skipPriceCheck: boolean) => {
        if (formSending) return

        // Validation
        if (!name) return

        if (!skipPriceCheck && !price) {
            setGuardrailTitle('Would you like to continue without adding a price?')
            setGuardrailBody(
                <>
                    <p>If you don’t add a price this service will be shown as a
                        price of “TBD” and allow you to edit the price on a
                        service by service basis.</p>
                    <br/>
                    <p>This is great if you don’t necessarily know the price of
                        the service before booking it.</p>
                </>
            )
            setGuardrailButton('Continue')
            setGuardrailType('price')
            setShowGuardrail(true)
            return
        }

        const service = new PSItem('service')
        if (forOnboarding)
            service.setData(state.item)
        service.name = name
        service.image = image
        service.rate = price
        service.deposit = deposit
        service.memo = description
        service.allow_salestax = salesTax
        service.availability = availability
        service.is_public = online
        service.allow_conference = videoConference
        service.conference_type = videoConferenceType?.value ?? ''
        service.capacity = singleCapacity ? '1' : ''
        service.is_virtual = isVirtual
        service.client_selects_location = clientSelectsLocation
        if (discount)
            service.discount = (discount as SelectOption).value
        if (duration)
            service.duration = Number((duration as SelectOption).value)
        if (leadtime)
            service.leadtime = (leadtime as SelectOption).value
        if (form)
            service.form = form.id
        if (contract)
            service.contract = contract.id
        if (location.value === 'another')
            service.location = locationDetails
        if (params.serviceID && params.action !== 'copy')
            service.id = params.serviceID

        if (forOnboarding) {
            service.id = state.item.id
            service.image = undefined
            dispatch(updateItem(service.toObject(), !!refreshData))
            history.go(-2)
            return
        }

        setFormSending(true)

        if (typeof photo === 'string') {
            dispatch(saveItem(service))
        }
        else {
            dispatch(saveItem(service, photo as File))
        }
    }

    // Guardrail states
    const [showGuardrail, setShowGuardrail] = useState(false)
    const [guardrailTitle, setGuardrailTitle] = useState('')
    const [guardrailBody, setGuardrailBody] = useState<string | React.ReactNode>('')
    const [guardrailButton, setGuardrailButton] = useState('')
    const [guardrailType, setGuardrailType] = useState('')
    const guardrailAction = useCallback(() => {
        // TODO Add unsaved form guardrail
        switch (guardrailType) {
            case 'availability':
                setShowAvailableHours(true)
                break
            case 'singleCapacity':
                setSingleCapacity(true)
                break
            case 'price':
                onSave(true)
                break
            default:
                console.log(`Unknown guardrail type: ${guardrailType}`)
        }
        setShowGuardrail(false)
    }, [guardrailType])

    const showAttachments = (contractsEnabled || formsEnabled) &&
        (state.loadingAttachments || state.contracts.length > 0 || state.forms.length > 0)

    const showDeleteButton = params.serviceID && params.action !== 'copy'

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Service</h1>
                {showDeleteButton && (
                    <PSButtonDanger style={{height: '40px'}}
                                    onClick={() => setShowDeleteConfirmation(true)}>
                        Delete service
                    </PSButtonDanger>
                )}
                <PSButtonPrimary style={{height: '40px', marginLeft: showDeleteButton ? '16px' : 'auto'}}
                                 onClick={() => onSave(false)}
                                 disabled={!saveEnabled || formSending}>
                    {formSending && <Dots />}
                    {!formSending && "Save"}
                </PSButtonPrimary>
            </div>
            <div className="form">
                {!forOnboarding && (
                    <SettingsPhotoField photo={photo}
                                        selectPhoto={selectPhoto}
                                        selectedPhoto={selectedPhoto}
                                        addPhotoRef={addPhotoRef}
                                        title="Service" />
                )}

                <Field className="field">
                    <Label className="label">Name (required)</Label>
                    <Input placeholder="Service name"
                           value={name}
                           disabled={state.isSending}
                           onChange={e => setName(e.target.value)} />
                </Field>

               <Field className="field">
                    <Label className="label">Price · $</Label>
                    <Input placeholder="Service price"
                           value={price}
                           disabled={state.isSending}
                           onBlur={priceValidator}
                           onChange={e => filterMonetaryInput(e.target.value, setPrice)} />
                </Field>

                <Field className="field">
                    <Label className="label">Deposit · $</Label>
                    <Input placeholder="Service deposit"
                           value={deposit}
                           disabled={state.isSending}
                           onBlur={priceValidator}
                           onChange={e => filterMonetaryInput(e.target.value, setDeposit)} />
                </Field>

                {discountEnabled && (
                    <PSDropdown selected={discount}
                                nameProperty="label"
                                disabled={state.isSending}
                                onSelect={(selection) => setDiscount(selection)}
                                label="Discount rate"
                                placeholder={<div className="grey">Select discount rate</div>}>
                        <>
                            {discountOptions.map(option => (
                                <Item key={`discount-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                )}

                {salesTaxEnabled && (
                    <Field className="field">
                        <Checkbox checked={salesTax}
                                  disabled={state.isSending}
                                  onChange={e => setSalesTax(e.target.checked)}>
                            <Label className="withHint">Allow sales tax</Label>
                            <Hint>Automatically calculate & apply sales tax</Hint>
                        </Checkbox>
                    </Field>
                )}

                <Field className="field">
                    <Label className="label">Description</Label>
                    <PSTextarea placeholder="Service description"
                                className="input"
                                minRows={8}
                                maxLength={1500}
                                disabled={state.isSending}
                                value={description}
                                onChange={value => setDescription(value)} />
                </Field>

                <h2>Scheduling</h2>

                <PSDropdown selected={duration}
                            onSelect={(selection) => setDuration(selection)}
                            disabled={state.isSending}
                            nameProperty="label"
                            label="Duration"
                            placeholder={<div className="grey">Select duration</div>}>
                    {durationOptions.map(option => (
                        <Item key={`duration-${option.value}`} value={option}>
                            {option.label}
                        </Item>
                    ))}
                </PSDropdown>

                <PSDropdown selected={leadtime}
                            onSelect={(selection) => setLeadtime(selection)}
                            disabled={state.isSending}
                            nameProperty="label"
                            label="Lead time"
                            hint="Minimum amount of time needed to prep for this service"
                            onLearnMore={() => showLearnMore('leadtime', true)}
                            placeholder={<div className="grey">Select lead time</div>}>
                    {leadtimeOptions.map(option => (
                        <Item key={`leadtime-${option.value}`} value={option}>
                            {option.label}
                        </Item>
                    ))}
                </PSDropdown>

                {onlineBookingEnabled && (
                    <Field className="field">
                        <Checkbox checked={online}
                                  disabled={state.isSending}
                                  onChange={(e) => { setOnline(e.target.checked)}}>
                            <Label className="withHint">Show online</Label>
                            <Hint>Allow clients to purchase this service online</Hint>
                        </Checkbox>
                    </Field>
                )}

                <Field className="field">
                    <Checkbox checked={singleCapacity}
                              disabled={state.isSending}
                              onChange={(e) => { changeSingleCapacity(e.target.checked)}}>
                        <Label className="withHint">Single capacity</Label>
                        <Hint>Only one client can book this equipment / room at a time</Hint>
                    </Checkbox>
                </Field>

                <Field className="field">
                    <div style={{display: 'flex'}}>
                        <Label>Availability</Label>
                        <IconButton style={{marginLeft: 'auto'}}
                                    disabled={state.isSending}
                                    onClick={() => changeAvailability()}>
                            <PlusIcon />
                        </IconButton>
                    </div>
                    <Hint style={{marginTop: -10}}>{availabilitySummary}</Hint>
                </Field>

                {showAttachments && <h2>Attachments</h2>}

                {state.loadingAttachments && (
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                )}
                {contractsEnabled && !state.loadingAttachments && state.contracts.length > 0 && (
                    <PSDropdown selected={contract}
                                disabled={state.isSending}
                                nameProperty="name"
                                onSelect={(selection) => setContract(selection)}
                                label="Attach a contract"
                                hint="This contract will be signed when a client books the service"
                                onLearnMore={() => showLearnMore('contracts', true)}
                                placeholder={<div className="grey">Select contract</div>}>
                        <>
                            {state.contracts.map(option => (
                                <Item key={`contract-${option.id}`} value={option}>
                                    {option.name}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                )}
                {formsEnabled && !state.loadingAttachments && state.forms.length > 0 && (
                    <PSDropdown selected={form}
                                disabled={state.isSending}
                                nameProperty="name"
                                onSelect={(selection) => setForm(selection)}
                                label="Attach a form"
                                hint="This form will be completed when a client books the service"
                                onLearnMore={() => showLearnMore('forms', true)}
                                placeholder={<div className="grey">Select form</div>}>
                        <>
                            {state.forms.map(option => (
                                <Item key={`form-${option.id}`} value={option}>
                                    {option.name}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                )}

                <h2>Location</h2>

                <PSDropdown selected={videoConferenceType}
                            disabled={state.isSending}
                            nameProperty="label"
                            onSelect={(selection) => {
                                setVideoConferenceType(selection || undefined)
                                setVideoConference(!!selection.value)
                            }}
                            label="Add video conference"
                            hint="Add a video conference link to this appointment"
                            placeholder={<div className="grey">No Video Link</div>}>
                    <>
                        {videoConferenceOptions(zoomConnected).map(option => (
                            <Item key={`conference-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>

                <PSDropdown selected={location}
                            disabled={state.isSending}
                            nameProperty="label"
                            onSelect={(selection) => selectLocation(selection)}
                            label="Location">
                    <>
                        {locationOptions.map(option => (
                            <Item key={`location-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>

                <div style={{paddingBottom: 48}} />

                {showGuardrail && (
                    <GuardrailModal title={guardrailTitle}
                                    body={guardrailBody}
                                    buttonText={guardrailButton}
                                    onClose={() => setShowGuardrail(false)}
                                    onAction={guardrailAction} />
                )}
                {showAvailableHours && (
                    <AvailableHoursModal title={'Setup service hours'}
                                         onClose={() => setShowAvailableHours(false)}
                                         onDone={onAvailableHoursDone}
                                         currentAvailability={availability} />
                )}
                {showLocationModal && (
                    <LocationModal onClose={() => setShowLocationModal(false)}
                                   onDone={locationModalCompleted}
                                   currentLocation={locationDetails} />
                )}
                {learnMoreVisible && (
                    <Modal onClose={() => showLearnMore('', false)}>
                        <Header>{learnMoreTitle}</Header>
                        <Body>{learnMoreBody}</Body>
                        <Close aria-label="Close modal" />
                    </Modal>
                )}
                {showDeleteConfirmation && (
                    <GuardrailModal title="Are you sure?"
                                    body="Are you sure you'd like to delete this item?"
                                    buttonText="Delete"
                                    onClose={() => setShowDeleteConfirmation(false)}
                                    onAction={() => dispatch(deleteItem(params.serviceID, 'services'))}
                    />
                )}
                <Prompt
                    when={hasEdits && !formSending}
                    message="Are you sure you'd like to leave this page without saving your changes?" />
            </div>
        </div>
    )
}
