export class Deposit {
    id?: string
    status?: string
    memo?: string
    refnum?: string
    source?: string
    amount?: number
    owner?: string
    created_date?: string
    cancelled_date?: string
    sent_date?: string
    paid_date?: string
    last_modified_date?: string

    constructor(data: Deposit) {
        Object.assign(this, data)
        this.sent_date = this.paid_date ? this.paid_date : this.status === 'paid' ? this.last_modified_date : ''
    }

    statusString(): string {
        if (this.cancelled_date)
            return 'Canceled'
        if (this.status === 'paid')
            return 'Received by bank'
        if (this.status === 'in_transit')
            return 'Sent to bank'

        return 'Fully processed'
    }
}