import { Note } from '../models/note'
import { addImage, Result } from '../utils'
import { apiClient } from './apiclient'
import { processError } from './error'

export type NoteState = {
    notes: Note[],
    loading: boolean,
}

const initialState: NoteState = {
    notes: [],
    loading: false
}

export default function notesReducer(state = initialState, action: any) {
    switch (action.type) {
        case 'note/SET_NOTES':
            return {
                ...state,
                notes: action.notes
            }
        case 'note/SET_LOADING':
            return {
                ...state,
                loading: action.loading
            }
        case 'note/REMOVE_NOTE':
            const notes = state.notes
            const ix = notes.findIndex((note) => note.id === action.noteId)
            if (ix > -1) {
                notes.splice(ix, 1)
            }
            return {
                ...state,
                notes
            }
        default:
            return state
    }
}

const setLoading = (loading: boolean) => {
    return { type: 'note/SET_LOADING', loading }
}

const setNotes = (notes: Note[]) => {
    return { type: 'note/SET_NOTES', notes }
}

const deleteNote = (noteId: string) => {
    return { type: 'note/REMOVE_NOTE', noteId }
}

export const fetchUserNotes = (userId: string) => (dispatch: any): any =>
    Result(dispatch(fetchNotes({userId})))
        .then(notes => dispatch(setNotes(notes)))

export const fetchBookingNotes = (bookingId: string) => (dispatch: any): any =>
    Result(dispatch(fetchNotes({bookingId})))
        .then(notes => dispatch(setNotes(notes)))

export const fetchEstimateNotes = (estimateId: string) => (dispatch: any): any =>
    Result(dispatch(fetchNotes({estimateId})))
        .then(notes => dispatch(setNotes(notes)))

type FetchNoteParams = {
    userId?: string,
    bookingId?: string,
    estimateId?: string
}

export const fetchNote = (noteId: string) => (dispatch: any): any =>
    Result(dispatch(fetchNotes({})))
        .then((notes: Note[]) => notes.find(note => note.id === noteId))


const fetchNotes = ({userId, bookingId, estimateId}: FetchNoteParams) => {
    return (dispatch: any): any => {
        dispatch(setLoading(true))
        return apiClient.post('/notes', {person: userId, booking: bookingId, estimate: estimateId})
            .then(resp => resp.data)
            .then(data => data.filter((note: Note) => !note.cancelled_date))
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => dispatch(setLoading(false)))
    }
}

export const removeNote = (noteId: string) => {
    return (dispatch: any): any => {
        dispatch(setLoading(true))
        return apiClient.post(`/note/${noteId}/delete`)
            .then(resp => resp.data)
            .then(() => dispatch(deleteNote(noteId)))
            .catch(error => dispatch(processError(error)))
            .finally(() => dispatch(setLoading(false)))
    }
}

export const saveNote = (note: any, image?: File) => {
    return (dispatch: any): any => {
        const url = !!note.id  ? `/note/${note.id}` : `/note`
        dispatch(setLoading(true))
        return apiClient.post(url, addImage(note, image))
            .then(resp => resp.data)
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => dispatch(setLoading(false)))
    }
}