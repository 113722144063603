const colors = [
    {bg: '#fdeef1', fg: '#fa3c69'},
    {bg: '#fff1e9', fg: '#e57c45'},
    {bg: '#efecff', fg: '#412fb6'},
    {bg: '#ecf7ee', fg: '#60ab6b'},
    {bg: '#e9f5ff', fg: '#3e9de9'},
    {bg: '#fdf9ec', fg: '#c8a022'},
]

export const userColors = (id) => {
    let hash = 0
    for (let i = 0; i < id.length; i++) {
        hash = ((hash<<5) - hash) + id.charCodeAt(i)
    }
    return colors[Math.abs(hash) % colors.length]
}

const calColors = {
    'block':       {bg: '#f5f5f5', fg: '#999999'},
    'task':        {bg: '#e9f5ff', fg: '#3e9de9'},
    'booking':     {bg: '#e6eff8', fg: '#005cb0'},
    'class':       {bg: '#eafbfa', fg: '#1ba097'},
    'reservation': {bg: '#f2fae8', fg: '#508f4b'},
    'event':       {bg: '#f5eff8', fg: '#9b49b6'},
    'default':     {bg: '#fff1e9', fg: '#e57c45'},
}

export const calendarColors = (type) => {
    const color = calColors[type]
    if (!color) return calColors['default']
    return color
}

export const dashboardTagColors = {
    red:    { bg: '#FDEEF1', fg: '#FA3C69' },
    yellow: { bg: '#FDF9EC', fg: '#C8A022' },
    blue:   { bg: '#E9F5FF', fg: '#3E9DE9' },
    green:  { bg: '#EFFAF5', fg: '#62CE9A' },
    grey:   { bg: '#F5F5F5', fg: '#999999' }
}

export const intToColorString = (color) => {
    return "#" + color.toString(16).padStart(6, '0');
}