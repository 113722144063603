import React from "react";
import {PSButtonPrimary} from "../../app/PSButton";
import {Spinner} from "@zendeskgarden/react-loaders";
import {SourceCard} from "./SourceCard";

type Props = {
    sources: Array<any>,
    loading: boolean,
    selected?: string,
    addChecking?: () => void,
    addPaymentCard: () => void,
    onViewItemClick?: (source: any) => void,
    onSelectItemClick?: (source: any) => void,
    title?: string,
    subtitle?: string
}

export const PaymentMethodsListComponent = ({sources, loading, selected, title, subtitle, addChecking, addPaymentCard, onViewItemClick, onSelectItemClick}: Props) => {
    const header = title ?? 'Payment methods'
    if (!sources?.length) {
        return (
            <div className="servicesSettings">
                <div className="header">
                    <h1>{header}</h1>
                    {loading && (<Spinner size="128" color="#314A68" />)}
                </div>
                {!loading && (
                    <div className="empty">
                        <div>Add a payment method to make or receive payments.</div>
                        <div className="promo">For instant payouts, add a debit card and toggle on “Accept instant payouts”</div>
                        <PSButtonPrimary style={{height: '40px', marginRight: '8px'}}
                                    onClick={() => addPaymentCard()}>
                        Add a card
                    </PSButtonPrimary>
                    {addChecking && (
                        <PSButtonPrimary style={{height: '40px', marginLeft: '8px'}}
                                        onClick={() => addChecking()}>
                            Add a checking account
                        </PSButtonPrimary>
                    )}
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>{header}</h1>
                <div className="right">
                <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                 onClick={() => addPaymentCard()}>
                    Add a card
                </PSButtonPrimary>
                {addChecking && (
                    <PSButtonPrimary style={{height: '40px', marginLeft: '16px'}}
                                    onClick={() => addChecking()}>
                        Add a checking account
                    </PSButtonPrimary>
                )}
                </div>
            </div>
            <div className="separator" />
            {subtitle && <p style={{marginBottom: '16px', fontSize: '17px', lineHeight: '22px'}}>{subtitle}</p>}
            <div>
                {sources
                    .filter(source => !addChecking ? source.type !== 'checking' : true)
                    .map((source: any) => {
                        return <SourceCard key={`source-${source.id}`}
                                           source={source}
                                           selected={selected === undefined ? undefined : selected === source.id}
                                           onView={() => { if (onViewItemClick) onViewItemClick(source) }}
                                           onSelect={() => { if (onSelectItemClick) onSelectItemClick(source) }} />
                })}
            </div>
        </div>
    )
}
