import React, { createRef, useEffect, useState } from "react";
import {useSelector} from "react-redux";
import {useAppDispatch, usePSUser} from "../../../hooks";
import {RootState} from "../../../store";
import {AppHeader} from "../../app/AppHeader";
import {PSButton, PSButtonPrimary} from "../../app/PSButton";
import {PSFormFieldSelection} from "../../app/PSFormFieldSelection";
import {ReactComponent as ClientIcon} from "../../../icons/ps_general_person_nobg.svg";
import {SelectModal} from "../SelectModal";
import {PSDropdown} from "../../app/PSDropdown";
import {Item} from "@zendeskgarden/react-dropdowns";
import {SelectOption, bookingSendViaOptionsNoDont} from "../../../components/settings/Options"
import {fetchContracts} from "../../../modules/settings";
import {
    Body, Close,
    Footer,
    FooterItem,
    Header,
    Modal
} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import {Dots, Spinner} from "@zendeskgarden/react-loaders";
import {b64EncodeUnicode} from "../../../utils/strings";
import {API_BASE, apiClient} from "../../../modules/apiclient";
import { Signature } from "../../../models/Signature";
import { sendSignature } from "../../../modules/transactions";
import { useDashboardLocation } from "../../../models/Dashboard";
import { replace } from "connected-react-router";
import { Result } from "../../../utils";


export const ContractSend = () => {
    const dispatch = useAppDispatch()
    const user = usePSUser()

    const state = useSelector((state: RootState) => {
        return {
            settings: state.settings,
            transactions: state.transactions,
        }
    })

    const locationState = useDashboardLocation()
    const [selectedContract, setSelectedContract] = useState<any>()
    const [client, setClient] = useState(locationState?.client)
    const [channel, setChannel] = useState<SelectOption>({value: '', label: ''})

    const [selectModalType, setSelectModalType] = useState<'clients' | undefined>()
    const [options, setOptions] = useState<any[]>([])

    const [isPreviewLoading, setIsPreviewLoading] = useState(true)
    const [pdfPreviewData, setPDFPreviewData] = useState('')
    const [showPreviewModal, setShowPreviewModal] = useState(false)
    const formPreviewRef = createRef<HTMLFormElement>()

    const [showSendModal, setShowSendModal] = useState(false)

    const onClientSelected = (item: any[]) => {
        setClient(item[0])
        setSelectModalType(undefined)

        if (!item[0]) return
        if (!item[0]?.phone && channel.value === 'sms') {
            const selectedChannel = bookingSendViaOptionsNoDont?.find( option => option.value === 'email' )
            setChannel(selectedChannel || channel)
        }
        else if (!item[0]?.email && channel.value === 'email') {
            const selectedChannel = bookingSendViaOptionsNoDont?.find( option => option.value === 'sms' )
            setChannel(selectedChannel || channel)
        }
    }

    useEffect( () => {
        const selectedChannel = bookingSendViaOptionsNoDont?.find( option => option.value === 'sms' )
        setChannel( c => selectedChannel || c)
    }, [])

    useEffect( () => {
        dispatch(fetchContracts())
    }, [dispatch])

    useEffect( () => {
        setOptions(state.settings.contracts)
    }, [state.settings.contracts])

    const getPreview = () => {
        setShowPreviewModal(true)
        setIsPreviewLoading(true)

        apiClient.get(`/contract/${selectedContract.id}/print`)
            .then(resp => {
                setPDFPreviewData(resp.data)
                setIsPreviewLoading(false)
            })
    }

    const showPreview = () => {
        if (formPreviewRef.current)
            formPreviewRef.current.submit()
    }

    const sendContract = () => {
        const signatureData = new Signature()
        signatureData.contract = selectedContract.id
        signatureData.employee = user?.id
        signatureData.person = client?.id
        signatureData.channel = channel.value

        Result(dispatch(sendSignature(signatureData)))
            .then(() => dispatch(replace(`/messages/${signatureData.person}`)))
    }

    if (state.settings.isSending || !user) {
        return (
            <div className="servicesSettings">
                <div className="header">
                    <h1>Send Contract</h1>
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <AppHeader middleWidget={null}
                   showBackButton
                   title="Contract"
        >
            <div className="contractSend servicesSettings">
                <div className="header">
                    <h1>Send contract</h1>
                    <div className="right">
                        <PSButton style={{height: '40px', marginLeft: 'auto'}}
                                  onClick={() => getPreview()}
                                  disabled={!selectedContract}
                        >
                            Preview
                        </PSButton>
                        <PSButtonPrimary style={{height: '40px', marginLeft: '16px'}}
                                         onClick={() => setShowSendModal(true)}
                                         disabled={!selectedContract || !client || showSendModal || state.transactions.isSending}
                        >
                            {!state.transactions.isSending && (
                                "Send"
                            )}
                            {state.transactions.isSending && (
                                <Dots/>
                            )}
                        </PSButtonPrimary>
                    </div>
                </div>
                <div className="separator" />
                <div className="form">
                    <PSFormFieldSelection label="Client (required)"
                                          helperLabel="Select a client"
                                          icon={<ClientIcon />}
                                          selection={client}
                                          onClick={() => setSelectModalType('clients')}
                    />
                    <PSDropdown selected={selectedContract}
                                onSelect={(selection) => setSelectedContract(selection)}
                                nameProperty={"name"}
                                label="Contract (required)"
                                placeholder={<div className="grey">Select a contract</div>}
                    >
                        <>
                            {options.map(option => (
                                <Item key={`option-${option.id}`} value={option}>
                                    {option.name}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                    <PSDropdown selected={channel}
                                nameProperty="label"
                                hint="Specify how you want this contract to be sent"
                                onSelect={(selection) => setChannel(selection)}
                                label="Send via">
                            <>
                                {bookingSendViaOptionsNoDont && bookingSendViaOptionsNoDont!.map((option: SelectOption) => (
                                        <Item key={`channel-${option.value}`} value={option}>
                                            {option.label}
                                        </Item>
                                ))}
                            </>
                    </PSDropdown>
                </div>

                {selectModalType && (
                    <SelectModal type={selectModalType}
                                 multiSelect={false}
                                 currentSelections={client ? [client] : []}
                                 onSelect={onClientSelected}
                                 onCancel={() => setSelectModalType(undefined) }
                    />
                )}
                {showPreviewModal && (
                    <Modal className="signatureModal" onClose={() => setShowPreviewModal(false)}>
                        <Header>{selectedContract.name} - Preview </Header>
                        <Body>
                            {isPreviewLoading && (
                                <div style={{width: '100%', height: '420px'}}>
                                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                                        <Spinner size="128" color="#314A68" />
                                    </div>
                                </div>
                            )}
                            {!isPreviewLoading && pdfPreviewData && (
                                <iframe style={{width: '100%', height: '420px'}}
                                        src={`data:application/pdf;base64,${b64EncodeUnicode(pdfPreviewData)}`}
                                        title={'Preview'}>
                                </iframe>
                            )}
                        </Body>
                        <Footer style={{paddingBottom: 32}}>
                            <FooterItem>
                                <form method="POST"
                                    ref={formPreviewRef}
                                    action={`${API_BASE}/contract/${selectedContract.id}/print`}
                                    target="pspreview">
                                </form>
                                <Button onClick={() => showPreview()}
                                        isPrimary>
                                    Open in browser
                                </Button>
                            </FooterItem>
                        </Footer>
                        <Close aria-label="Close modal" />
                    </Modal>
                )}
                {showSendModal && (
                    <Modal className="contractSendModal" onClose={() => setShowSendModal(false)}>
                        <Header>Send contract</Header>
                        <Body>
                        Please confirm that you’d like this contract to be sent to {client?.name}. Once sent the client will receive a notification and be able to sign this contract from their mobile device or computer.
                        </Body>
                        <Footer>
                            <PSButtonPrimary
                                onClick={() => sendContract()}
                                disabled={state.transactions.isSending}
                            >
                                {state.transactions.isSending && (
                                    <Dots/>
                                )}
                                {!state.transactions.isSending && (
                                    "Send contract"
                                )}
                            </PSButtonPrimary>
                        </Footer>
                        <Close aria-label="Close modal" />
                    </Modal>
                )}
            </div>
        </AppHeader>
    )
}