import React, {useCallback, useEffect, useState} from 'react'
import {PSButtonPrimary} from "../app/PSButton";
import {useAppDispatch, usePSOwner} from "../../hooks";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {push} from "connected-react-router";
import {
    fetchContracts,
    fetchForms,
    fetchItems
} from "../../modules/settings";
import {Spinner} from "@zendeskgarden/react-loaders";
import { Item as PSItem } from '../../models/Item';
import {ItemCard} from "./ItemCard";

import './settings.css'
import copy from "copy-to-clipboard";
import {Body, Close, Header, Modal} from "@zendeskgarden/react-modals";
import {ReactComponent as SuccessIcon} from "../../icons/success.svg";
import {BOOK_HOST} from "../../utils/constants";
import {Dropdown, Item, Menu, Trigger} from "@zendeskgarden/react-dropdowns";
import {Button} from "@zendeskgarden/react-buttons";
import { ReactComponent as ChevronIcon } from "@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg";

type Props = {
    type: string,
    typePlural?: string,
    name: string,
    plural?: string,
    nameFilter?: string,
}

type StringLookup = {
    [index: string]: string,
}

export const SettingsItemList = ({type, typePlural, name, plural, nameFilter}: Props) => {
    const dispatch = useAppDispatch()
    const state = useSelector((state: RootState) => state.settings)

    const [pageLoading, setPageLoading] = useState(true)
    const [itemList, setItemList] = useState<any[]>([])
    const [dropdownRotate, setDropdownRotated] = useState(false)

    const emptyStatePromo: StringLookup = {
        'service': 'Offer services that your clients can book in-app or online 😁',
        'reservation': 'Create a reservation and offer multi-day bookings and overnight appointments 📆',
        'product': 'Offer products that your clients can pick up on their next appointments 📦',
        'package': 'Sell bundles of items and collect payment upfront 💸',
        'subscription': 'Subscriptions are a great way to get clients on recurring payment plans 📈',
        'gift': 'Create a gift certificate and sell it online or in person 🎁',
        'class': 'Setup classes, add them to the schedule and track attendance 📋',
        'contract': 'Send and sign contracts via text 📝',
        'form': 'Send and fill out forms via text 📝',
    }

    if (!typePlural)
        typePlural = `${type}s`

    if (!plural)
        plural = `${name}s`

    useEffect(() => {
        setPageLoading(true)
        if (type === 'contract') {
            // @ts-ignore
            dispatch(fetchContracts()).then(() => setPageLoading(false))
        }
        else if (type === 'form') {
            // @ts-ignore
            dispatch(fetchForms()).then(() => setPageLoading(false))
        }
        else {
            // @ts-ignore
            dispatch(fetchItems(type)).then(() => setPageLoading(false))
        }
    }, [dispatch, type])

    useEffect(() => {
        if (type === 'contract')
            setItemList(state.contracts)
        else if (type === 'form')
            setItemList(state.forms)
        else
            setItemList(state.items)
    }, [type, state.items, state.contracts, state.forms])

    const owner = usePSOwner()
    // Make sure employer.allow_widget is true to add the share booking link to the kabob menu
    const [showShareBookingModal, setShowShareBookingModal] = useState(false)
    const [isBookingEnabled, setIsBookingEnabled] = useState(false)
    useEffect(() => {
        if (type === 'product' || !owner) return

        setIsBookingEnabled(owner.allow_widget)
    }, [owner, type])

    const onAddButton = useCallback((selected: string) => {
        if (selected === 'new')
            dispatch(push(`/settings/${typePlural}/new`))
        else
            dispatch(push(`/settings/${typePlural}/defaults`, {initialStep: typePlural}))
    }, [dispatch, typePlural])

    const onCopyItem = (item: any) => {
        dispatch(push(`/settings/${typePlural}/${item.id}/copy`))
    }

    const onShareLink = (item: any) => {
        if (type === 'form') {
            const formURL = encodeURI(`${BOOK_HOST}/form/${item.key}`)
            copy(formURL)
            setShowShareBookingModal(true)
            return
        }

        if (!owner)
            return
        const username = owner.username || owner.key
        const itemURL = item.name.replaceAll('-', '')
            .replaceAll(' ', '-')
            .replaceAll('&', 'and')
            .toLowerCase()
        const bookingURL = encodeURI(`${BOOK_HOST}/book/${username}/item/`) + encodeURIComponent(itemURL)
        copy(bookingURL)
        setShowShareBookingModal(true)
    }

    const onEditSchedule = (item: PSItem) => {
        dispatch(push(`/settings/${typePlural}/${item.id}/lessons`))
    }

    if (pageLoading || state.isSending) {
        return (
            <div className="servicesSettings">
                <div className="header">
                    <h1>{plural}</h1>
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                </div>
            </div>
        )
    }

    if (itemList.length === 0) {
        return (
            <div className="servicesSettings">
                <div className="header">
                    <h1>{plural}</h1>
                </div>
                <div className="empty">
                    <div>You don't have any {plural.toLowerCase()} yet</div>
                    <div className="promo">{emptyStatePromo[type]}</div>
                    <Dropdown
                        onSelect={item => onAddButton(item)}
                        onStateChange={options => options.hasOwnProperty('isOpen') && setDropdownRotated(options.isOpen ?? false)}
                    >
                        <Trigger>
                            <PSButtonPrimary style={{height: '40px'}}
                                             onClick={() => {}}>
                                Add a {name.toLowerCase()}
                                <Button.EndIcon isRotated={dropdownRotate}>
                                    <ChevronIcon />
                                </Button.EndIcon>
                            </PSButtonPrimary>
                        </Trigger>
                        <Menu>
                            <Item value="default">Add {name.toLowerCase()} from defaults</Item>
                            <Item value="new">Create a {name.toLowerCase()}</Item>
                        </Menu>
                    </Dropdown>
                </div>
            </div>
        )
    }

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>{plural}</h1>
                <Dropdown
                    onSelect={item => onAddButton(item)}
                    onStateChange={options => options.hasOwnProperty('isOpen') && setDropdownRotated(options.isOpen ?? false)}
                >
                    <Trigger>
                        <PSButtonPrimary style={{height: '40px'}}
                                         onClick={() => {}}>
                            Add a {name.toLowerCase()}
                            <Button.EndIcon isRotated={dropdownRotate}>
                                <ChevronIcon />
                            </Button.EndIcon>
                        </PSButtonPrimary>
                    </Trigger>
                    <Menu>
                        <Item value="default">Add {name.toLowerCase()} from defaults</Item>
                        <Item value="new">Create a {name.toLowerCase()}</Item>
                    </Menu>
                </Dropdown>
            </div>
            <div className="separator" />
            <div>
                {itemList
                    .filter((item: PSItem) => {
                        if (!nameFilter) return true
                        return item.name.indexOf(nameFilter) > -1
                    })
                    .map((item: PSItem) => {
                    return <ItemCard key={`item-${item.id}`}
                                     item={item}
                                     onCopyItem={() => onCopyItem(item)}
                                     onShareLink={isBookingEnabled || type === 'form' ? () => onShareLink(item) : undefined}
                                     onEditSchedule={type === 'class' ? () => onEditSchedule(item) : undefined}
                                     pluralName={typePlural}
                                     type={type} />
                })}
            </div>
            {showShareBookingModal && (
                <Modal onClose={() => setShowShareBookingModal(false)}>
                    <Header style={{display: 'flex', color: '#219653'}}>
                        <SuccessIcon />
                        <span style={{fontWeight: 700, paddingLeft: 8}}>Booking link copied!</span>
                    </Header>
                    <Body>Your booking link has been copied to your clipboard! Paste it to share with a client or on social media.</Body>
                    <Close aria-label="Close modal" />
                </Modal>
            )}
        </div>
    )
}
