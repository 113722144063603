export class Option {
    value: string;
    label: string;
    seqnum: number;

    constructor() {
        this.value = ''
        this.label = ''
        this.seqnum = 0
    }

    setData(data: any) {
        this.value = data.value
        this.label = data.label
        this.seqnum = data.seqnum
    }
}