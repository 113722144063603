import React from "react";
import {LineItemInstance} from "../../models/LineItem";
import {Job} from "../../models/Job";
import {Header, Modal, Body, Close} from "@zendeskgarden/react-modals";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {currencyFormat} from "../../utils/numbers";
import {ContactAvatar} from "../contacts/ContactAvatar";
import {invoiceCategoryOptions} from "../settings/Options";
import moment from "moment";

type Props = {
    title: string,
    lineItems?: LineItemInstance[],
    expenses?: LineItemInstance[],
    appointments?: LineItemInstance[],
    jobs?: Job[],
    onCancel: () => void,
}

export const ItemSummaryModal = ({title, lineItems, expenses, appointments, jobs, onCancel}: Props) => {
    const state: any = useSelector((state: RootState) => state.entities)

    return (
        <Modal className="scheduleItemsModal modal"
               onClose={() => onCancel()}
        >
            <Header className="header">
                <div className="title">{title}</div>
            </Header>
            <Body className="body">
                {lineItems?.filter((lineItem: any) => lineItem.itemType === 'service').map(l => {
                    return (
                        <div className="item" key={`service-${l.id}`}>
                            <div className="details">
                                <div className="name">{state.items[l.item]?.name}</div>
                                <div>{currencyFormat(l.rate || l.total)} rate</div>
                            </div>
                            <div className="total">{currencyFormat(l.rate || l.total)}</div>
                        </div>
                    )
                })}
                {lineItems?.filter((lineItem: any) => lineItem.itemType === 'product').map(l => {
                    return (
                        <div className="item" key={`product-${l.id}`}>
                            <div className="details">
                                <div className="name">{state.items[l.item]?.name} x {l.quantity}</div>
                                <div>{currencyFormat(l.rate || l.total)} rate</div>
                            </div>
                            <div className="total">{currencyFormat((l.rate * l.quantity) || l.total)}</div>
                        </div>
                    )
                })}
                {jobs?.map(job => {
                    const user = state.users[job.person]
                    if (!user) return null
                    return (
                        <div className="item" key={`user-${job.person}`}>
                        <div style={{paddingRight: '8px', paddingBottom: '8px'}}>
                                <ContactAvatar user={user} />
                            </div>
                            <div className="details">
                                <div className="name">{user.name}</div>
                            </div>
                        </div>
                    )
                })}
                {expenses?.map(expenseLineItem => {
                    const categoryOption = invoiceCategoryOptions.find(o => o.value === expenseLineItem.category)

                    return (
                        <div className="item" key={`expense-${expenseLineItem.category}`}>
                            <div className="details">
                                <div className="name">{categoryOption?.label}</div>
                                <div>{currencyFormat(expenseLineItem.total)}</div>
                            </div>
                            <div className="total">{currencyFormat(expenseLineItem.total)}</div>
                        </div>
                    )
                })}
                {appointments?.map(lineItem => {
                    const item = state.items[lineItem.bookingItemID || '']

                    return (
                        <div className="item" key={`appointment-${lineItem.booking}`}>
                            <div className="details">
                                <div className="name">{moment(item.date).format('ddd, MMMM Do')}</div>
                                <div>{item?.name ?? 'Booking'}</div>
                            </div>
                            <div className="total">{currencyFormat(lineItem.total)}</div>
                        </div>
                    )
                })}
            </Body>
            <Close aria-label="Close modal" />
        </Modal>
    )
}