import { apiClient } from './apiclient'
import keyBy from 'lodash.keyby'
import moment from 'moment'
import { processError } from './error'

const initialState = {
    isSending: false,
    recordType: '',
    id: '',
    data: {},
    apiResponse: {},
    sources: [],
    fetchingNotes: false,
    notes: [],
    fetchingExtra: false,
    bookings: [],
}

export default function recordsReducer(state = initialState, action) {
    switch (action.type) {
        case 'record/REQUEST':
            return {
                ...state,
                isSending: action.sending,
                notes: action.sending ? [] : state.notes,
            }
        case 'record/SET_DATA':
            return {
                ...state,
                recordType: action.recordType,
                id: action.id,
                data: action.data,
                sources: action.sources,
                apiResponse: action.apiResponse,
            }
        case 'record/CLEAR_DATA':
            return {
                ...state,
                recordType: '',
                id: '',
                data: {},
                apiResponse: {},
            }
        case 'record/REQUEST_NOTES':
            return {
                ...state,
                fetchingNotes: action.sending,
            }
        case 'record/SET_NOTES':
            return {
                ...state,
                notes: action.notes,
            }
        case 'record/REQUEST_EXTRA':
            return {
                ...state,
                fetchingExtra: action.sending,
                bookings: action.sending ? [] : state.bookings,
            }
        case 'record/SET_BOOKINGS':
            return {
                ...state,
                bookings: action.bookings,
            }
        default:
            return state
    }
}

function recordRequest(sending) {
    return { type: 'record/REQUEST', sending }
}

function recordSetData(recordType, id, data, sources, apiResponse) {
    return { type: 'record/SET_DATA', recordType, id, data, sources, apiResponse }
}

export const clearRecordData = () => {
    return { type: 'record/CLEAR_DATA' }
}

export const getRecord = (recordType, id) => {
    return dispatch => {
        dispatch(recordRequest(true))

        let axioResponsePromise
        if (['payee', 'signup', 'client'].includes(recordType))
            axioResponsePromise = apiClient.post('/user/sync', {id})
        else if (recordType === 'booking')
            axioResponsePromise = apiClient.post('/booking/sync', {id, type: 'view', for_vendor: true})
        else if (recordType === 'event')
            axioResponsePromise = apiClient.post('/events/sync', {id, type: 'view'})
        else if (recordType === 'lesson' || recordType === 'class')
            axioResponsePromise = apiClient.post('/class/sync', {id})
        else
            axioResponsePromise = apiClient.post(`/${recordType}/sync`, {id})

        axioResponsePromise
            .then(resp => resp.data)
            .then(json => {
                if (json.clients) {
                    const clientMap = keyBy(json.clients, 'person')
                    dispatch({type: 'entities/UPDATE', name: 'clients', map: clientMap})
                }
                if (json.client && ['payee', 'signup', 'client', 'user'].includes(recordType)) {
                    const clientMap = {}
                    clientMap[json.client.person] = json.client
                    dispatch({type: 'entities/UPDATE', name: 'clients', map: clientMap})
                }
                else if (json.client) {
                    const clientMap = {}
                    clientMap[json.client.id] = json.client
                    dispatch({type: 'entities/UPDATE', name: 'contacts', map: clientMap})
                    dispatch({type: 'entities/UPDATE', name: 'users', map: clientMap})
                }
                if (json.owner) {
                    const ownerMap = {}
                    ownerMap[json.owner.id] = json.owner
                    dispatch({type: 'entities/UPDATE', name: 'contacts', map: ownerMap})
                    dispatch({type: 'entities/UPDATE', name: 'users', map: ownerMap})
                }
                if (json.customer) {
                    const customerMap = {}
                    customerMap[json.customer.id] = json.customer
                    dispatch({type: 'entities/UPDATE', name: 'contacts', map: customerMap})
                    dispatch({type: 'entities/UPDATE', name: 'users', map: customerMap})
                }
                if (json.contacts) {
                    const contactMap = keyBy(json.contacts, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'contacts', map: contactMap})
                    dispatch({type: 'entities/UPDATE', name: 'users', map: contactMap})
                }
                if (json.person) {
                    const contactMap = {}
                    contactMap[json.person.id] = json.person
                    dispatch({type: 'entities/UPDATE', name: 'contacts', map: contactMap})
                    dispatch({type: 'entities/UPDATE', name: 'users', map: contactMap})
                }
                if (json.user) {
                    const userMap = {}
                    userMap[json.user.id] = json.user
                    dispatch({type: 'entities/UPDATE', name: 'contacts', map: userMap})
                    dispatch({type: 'entities/UPDATE', name: 'users', map: userMap})
                }
                if (json.referer) {
                    const userMap = {}
                    userMap[json.referer.id] = json.referer
                    dispatch({type: 'entities/UPDATE', name: 'users', map: userMap})
                }
                if (json.channel) {
                    const channelMap = {}
                    channelMap[json.channel.id] = json.channel
                    dispatch({type: 'entities/UPDATE', name: 'channels', map: channelMap})
                }
                if (json.channels) {
                    const channelMap = keyBy(json.channels, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'channels', map: channelMap})
                }
                if (json.employer) {
                    const userMap = {}
                    userMap[json.employer.id] = json.employer
                    dispatch({type: 'entities/UPDATE', name: 'contacts', map: userMap})
                    dispatch({type: 'entities/UPDATE', name: 'users', map: userMap})
                }
                if (json.plan) {
                    // SubscriptionSyncer API returns item as a plan
                    const planMap = {}
                    planMap[json.plan.id] = json.plan
                    dispatch({type: 'entities/UPDATE', name: 'items', map: planMap})
                }

                if (json.invoices) {
                    const invoiceMap = keyBy(json.invoices, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'invoices', map: invoiceMap})
                }

                if (json.invoice) {
                    const invoiceMap = {}
                    invoiceMap[json.invoice.id] = json.invoice
                    dispatch({type: 'entities/UPDATE', name: 'invoices', map: invoiceMap})
                }

                if (json.item) {
                    const itemMap = {}
                    itemMap[json.item.id] = json.item
                    dispatch({type: 'entities/UPDATE', name: 'items', map: itemMap})
                }

                if (json.items) {
                    const itemMap = keyBy(json.items, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'items', map: itemMap})
                }

                if (json.fields) {
                    const fieldMap = keyBy(json.fields, 'name')
                    dispatch({type: 'entities/UPDATE', name: 'fields', map: fieldMap})
                }

                if (json.employee) {
                    const employeeMap = {}
                    employeeMap[json.employee.id] = json.employee
                    dispatch({type: 'entities/UPDATE', name: 'contacts', map: employeeMap})
                    dispatch({type: 'entities/UPDATE', name: 'users', map: employeeMap})
                }

                if (json.employees) {
                    const employeeMap = keyBy(json.employees, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'employees', map: employeeMap})
                    dispatch({type: 'entities/UPDATE', name: 'contacts', map: employeeMap})
                }

                if (json.contracts) {
                    const contractMap = keyBy(json.contracts, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'contracts', map: contractMap})
                }

                if (json.contract) {
                    const contractMap = {}
                    contractMap[json.contract.id] = json.contract
                    dispatch({type: 'entities/UPDATE', name: 'contracts', map: contractMap})
                }

                if (json.payee) {
                    const payeeMap = {}
                    payeeMap[json.payee.id] = json.payee
                    dispatch({type: 'entities/UPDATE', name: 'payees', map: payeeMap})
                }

                if (json.payees) {
                    const payeeMap = keyBy(json.payees, 'person')
                    dispatch({type: 'entities/UPDATE', name: 'payees', map: payeeMap})
                }

                if (json.types) {
                    const typeMap = keyBy(json.types, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'types', map: typeMap})
                }

                if (json.forms) {
                    const typeMap = keyBy(json.forms, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'types', map: typeMap})
                }

                if (json.type) {
                    const typeMap = {}
                    typeMap[json.type.id] = json.type
                    dispatch({type: 'entities/UPDATE', name: 'types', map: typeMap})
                }

                if (json.bookings) {
                    const bookingMap = keyBy(json.bookings, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'bookings', map: bookingMap})
                }

                if (json.task) {
                    const taskMap = {}
                    taskMap[json.task.id] = json.task
                    dispatch({type: 'entities/UPDATE', name: 'tasks', map: taskMap})
                }

                if (json.event) {
                    const eventMap = {}
                    eventMap[json.event.id] = json.event
                    dispatch({type: 'entities/UPDATE', name: 'events', map: eventMap})
                }

                if (json.block) {
                    const blockMap = {}
                    blockMap[json.block.id] = json.block
                    dispatch({type: 'entities/UPDATE', name: 'blocks', map: blockMap})
                }

                if (json.entity) {
                    const entityMap = {}
                    entityMap[id] = json.entity
                    dispatch({type: 'entities/UPDATE', name: recordType, map: entityMap})
                }

                if (json.gifts) {
                    const giftMap = keyBy(json.gifts, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'gifts', map: giftMap})
                }

                if (json.payments) {
                    const paymentMap = keyBy(json.payments, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'payments', map: paymentMap})
                }

                if (json.payment) {
                    const paymentMap = {}
                    paymentMap[json.payment.id] = json.payment
                    dispatch({type: 'entities/UPDATE', name: 'payments', map: paymentMap})
                }

                if (json.charge) {
                    const chargeMap = {}
                    chargeMap[json.charge.id] = json.charge
                    dispatch({type: 'entities/UPDATE', name: 'charges', map: chargeMap})
                }

                if (json.deposit) {
                    const depositMap = {}
                    depositMap[json.deposit.id] = json.deposit
                    dispatch({type: 'entities/UPDATE', name: 'deposits', map: depositMap})
                }

                if (json.source) {
                    const sourceMap = {}
                    sourceMap[json.source.id] = json.source
                    dispatch({type: 'entities/UPDATE', name: 'sources', map: sourceMap})
                }

                if (json.sources) {
                    const sourceMap = keyBy(json.sources, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'sources', map: sourceMap})
                }

                let entity = json.entity
                if (['payee', 'signup', 'client'].includes(recordType))
                    entity = json.user
                else if (recordType === 'lesson' || recordType === 'class')
                    entity = json.lesson
                else if (json[recordType])
                    entity = json[recordType]

                dispatch(recordSetData(recordType, id, entity, json.sources, json))
            })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => {
                dispatch(recordRequest(false))
            })
    }
}

const fetchingNotes = (sending) => {
    return { type: 'record/REQUEST_NOTES', sending }
}

const setNotes = (notes) => {
    return { type: 'record/SET_NOTES', notes }
}

export const getNotes = (type, recordId) => {
    return dispatch => {
        dispatch(fetchingNotes(true))

        const data = {}
        if (type === 'client')
            data.person = recordId
        else if (type === 'booking')
            data.booking = recordId

        apiClient.post('/notes', data)
            .then(resp => resp.data)
            .then(json => {
                const notes = json.sort((a, b) => moment(a.last_modified_date).isBefore(b.last_modified_date))
                dispatch(setNotes(notes))
            })
            .catch(error => dispatch(processError(error)))
            .finally(() => {
                dispatch(fetchingNotes(false))
            })
    }
}

const fetchingExtra = (sending) => {
    return { type: 'record/REQUEST_EXTRA', sending }
}

const setBookings = (bookings) => {
    return { type: 'record/SET_BOOKINGS', bookings }
}

export const checkinBooking = (bookingId) => {
    return dispatch => {
        dispatch(recordRequest(true))

        return apiClient.post(`/booking/${bookingId}/checkin`)
            .then(resp => resp.data)
            .then(json => {
                if (json.booking) {
                    const bookingMap = {}
                    bookingMap[json.booking.id] = json.booking
                    dispatch({type: 'entities/UPDATE', name: 'bookings', map: bookingMap})
                }
            })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => {
                dispatch(recordRequest(false))
            })
    }
}

export const promoteBooking = (bookingId) => {
    return dispatch => {
        dispatch(recordRequest(true))

        return apiClient.post(`/booking/${bookingId}/promote`)
            .then(resp => resp.data)
            .then(json => {
                if (json.booking) {
                    const bookingMap = {}
                    bookingMap[json.booking.id] = json.booking
                    dispatch({type: 'entities/UPDATE', name: 'bookings', map: bookingMap})
                }
            })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => {
                dispatch(recordRequest(false))
            })
    }
}

export const getBookings = (scheduleId) => {
    return dispatch => {
        dispatch(fetchingExtra(true))

        const data = { schedule: scheduleId }

        return apiClient.post('/bookings', data)
            .then(resp => resp.data)
            .then(json => {
                const bookings = json.sort((a, b) => moment(a.date).isBefore(b.date))
                dispatch(setBookings(bookings))
            })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => {
                dispatch(fetchingExtra(false))
            })
    }
}