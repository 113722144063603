import { readerTypeOptions } from "../components/settings/Options";

export class Reader {
    id?: string;
    owner?: string;
    ip_addr?: string;
    version?: string;
    serial_number?: string;
    type?: 'stripe_m2' | 'bbpos_chipper2x' | 'bbpos_wisee';
    label?: string;
    reader?: string;
    created_date?: string;
    cancelled_date?: string;
    is_updated?: boolean;
    is_internet?: boolean;
    stripe_reader?: any;
    registered_date?: string;
    image?: string;
    image_uri?: string;
    line1?: string;
    line2?: string;
    city?: string;
    state?: string;
    postal_code?: string;
}

export const isInternetReader = (readerType?: string) => readerType === 'bbpos_wisee'
export const readerDisabled = (r: Reader) => (r.is_internet || isInternetReader(r.type)) && r.stripe_reader?.status !== 'online'
export const readerStatus = (r: Reader) => {
    if (r.is_internet && !r.registered_date) {
        return 'Registration required'
    }
    return `${r.is_updated ? 'Up to date' : 'Update required'}`
}

export const readerTitle = (r: Reader) => {
    const label = !!r.label ? r.label : readerTypeOptions(true).find(o => o.value === r.type)?.value
    return `${label ? label+ '; SN ' : ''}${r.serial_number}`
}
