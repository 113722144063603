import React, {useCallback, useEffect} from "react";
import {useHistory, useParams} from "react-router";
import {RootState} from "../../store";
import {AppHeader} from "../app/AppHeader";
import {isEmpty} from "../../utils"
import {useSelector} from "react-redux"
import {ContactsState, syncClient, setSource} from "../../modules/contacts";
import {useAppDispatch} from "../../hooks";
import {deleteSource} from "../../modules/settings";
import {SourceViewComponent} from "../settings/payment-methods/SourceViewComponent";

export const ContactSourceView = () => {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const params = useParams<{id: string, sourceId: string}>()
    const state: ContactsState = useSelector((state: RootState) => state.contacts)

    const loadClientSources = useCallback(() => {
        if (isEmpty(state.source) && params?.id && params.id !== state.client.id) {
            dispatch(syncClient(params.id))
        }
    }, [params.id, state.source, state.client.id, dispatch])

    useEffect(() => {
        if (isEmpty(state.source) && state.sources.length) {
            const card = state.sources.find((card: any) => card.id === params?.sourceId)
            if (card) dispatch(setSource(card))
        }
    }, [state.client, state.source, params?.sourceId, dispatch, state.sources])

    useEffect(() => {
        loadClientSources()
    }, [loadClientSources])

    const doDelete = () => {
        // @ts-ignore
        dispatch(deleteSource(state.source)).then(
            history.goBack()
        )
    }

    return (
        <AppHeader middleWidget={null}
                   showBackButton
                   title="Client"
        >
            <div className="pageWrapper">
                <SourceViewComponent
                    doDelete={doDelete}
                    source={state.source} />
            </div>
        </AppHeader>
    )
}