import React, {useEffect, useState} from "react";
import {Modal, Body, Close, Footer, FooterItem, Header} from "@zendeskgarden/react-modals";
import {Field, Input, Label} from "@zendeskgarden/react-forms";
import {PSButtonPrimary} from "../../app/PSButton";

type Props = {
    onClose: () => void,
    onVerify: (firstAmount: number, secondAmount: number) => void,
}

export const VerifyAccountModal = ({onClose, onVerify}: Props) => {
    const [firstAmountStr, setFirstAmountStr] = useState('')
    const [secondAmountStr, setSecondAmountStr] = useState('')

    const [firstAmount, setFirstAmount] = useState<number>(0)
    const [secondAmount, setSecondAmount] = useState<number>(0)

    useEffect(() => {
        setFirstAmount(parseFloat(firstAmountStr))
    }, [firstAmountStr])

    useEffect(() => {
        setSecondAmount(parseFloat(secondAmountStr))
    }, [secondAmountStr])

    const onVerifyClicked = () => {
        onVerify(firstAmount, secondAmount)
    }

    return (
        <Modal className="addNumberModal" onClose={onClose}>
            <Header>
                Verify account
            </Header>
            <Body style={{paddingLeft: 0, paddingRight: 0}}>
                <div className="servicesSettings" style={{maxWidth: 536}}>
                <div className="form">
                <Field className="field">
                    <Label className="label">First amount</Label>
                    <Input value={firstAmountStr}
                           onChange={e => setFirstAmountStr(e.target.value)}
                    />
                </Field>
                <Field className="field">
                    <Label className="label">Second amount</Label>
                    <Input value={secondAmountStr}
                           onChange={e => setSecondAmountStr(e.target.value)}
                    />
                </Field>
                </div>
                </div>
            </Body>
            <Footer>
                <FooterItem>
                    <PSButtonPrimary isLink
                            onClick={() => onVerifyClicked()}
                            disabled={!firstAmount || !secondAmount}>
                    Verify account
                    </PSButtonPrimary>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )
}