import React, {createRef, useEffect, useState} from 'react'
import {Route, Switch} from 'react-router-dom'
import {AppHeader} from "../app/AppHeader";
import {SchedulingEdit} from './scheduling/SchedulingEdit';
import {CancellationEdit} from './scheduling/CancellationEdit';
import {SettingsMenu} from "./SettingsMenu";
import {ServiceEdit} from "./services/ServiceEdit";
import {ProductEdit} from "./products/ProductEdit";
import {GiftEdit} from "./gifts/GiftEdit";
import {SettingsItemList} from './SettingsItemList';
import {ReservationEdit} from "./reservations/ReservationEdit";
import {SettingsSort} from "./SettingsSort";
import {SettingsCategoriesList} from "./categories/SettingsCategoriesList";
import {CategoryEdit} from "./categories/CategoryEdit";
import {CategorySort} from "./categories/CategorySort";
import {PackageEdit} from "./packages/PackageEdit";
import {SubscriptionEdit} from "./subscriptions/SubscriptionEdit";
import {useHistory, useLocation} from "react-router";
import {ContractEdit} from "./marketing/ContractEdit";
import {ClassesEdit} from "./classes/ClassesEdit";
import {ClassesSchedule} from "./classes/ClassesSchedule";
import {ClassesLessonView} from "./classes/ClassesLessonView";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import {FormEdit} from "./marketing/forms/FormEdit";
import {FormQuestionList} from "./marketing/forms/FormQuestionList";
import {FormQuestionBuilder} from "./marketing/forms/FormQuestionBuilder";
import {BusinessEdit} from './business/BusinessEdit';
import {DefaultMemos} from './memos/DefaultMemos';
import {ProfileEdit} from './profile/ProfileEdit';
import {LocationEdit} from './location/LocationEdit';
import {NotificationsEdit} from './notifications/NotificationsEdit';
import {NumberEdit} from './numbers/NumberEdit';
import {Numbers} from './numbers/Numbers'
import {SalesTaxEdit} from './payments/SalesTaxEdit';
import {GratuityEdit} from './payments/GratuityEdit';
import {PaymentMethodList} from './payment-methods/PaymentMethodsList'
import {CardEdit} from './payment-methods/CardEdit';
import {CheckingEdit} from './payment-methods/CheckingEdit';
import {SourceView} from './payment-methods/SourceView';
import {PayoutEdit} from './payments/PayoutEdit';
import {TutorialsList} from './tutorials/TutorialsList';
import {CalendarSyncList} from './calendar-sync/CalendarSyncList';
import {CalendarSyncToPicker} from './calendar-sync/CalendarSyncToPicker';
import {CalendarSyncFromPicker} from './calendar-sync/CalendarSyncFromPicker';
import {
    ExternalCalendarSettings
} from './calendar-sync/ExternalCalendarSettings';
import {DataExportView} from './data-export/DataExportView';
import {CHANGE_PLAN_CONTEXT, TEAM_PLAN_CONTEXT, AFFILIATE_PLAN_CONTEXT, SelectPlan} from './SelectPlan';
import {LoginEdit} from './support/LoginEdit';
import {usePSUser} from "../../hooks";
import { VideoConferencing } from './VideoConferencing';
import { push } from 'connected-react-router';
import { CategoryFromDefaults } from './categories/CategoryFromDefaults';
import { ItemFromDefaults } from './ItemFromDefaults';
import { FormFromDefaults } from './marketing/forms/FormFromDefaults';
import { ContractFromDefaults } from './marketing/ContractFromDefaults';
import { PrivacyAndTerms } from './support/PrivacyAndTerms';
import { CardReaderList } from './payment-methods/CardReaderList';
import { AddCardReader } from './payment-methods/AddCardReader';
import { NewReaderCheckout } from './payment-methods/NewReaderCheckout';
import { FeatureEdit } from './features/FeatureEdit';
import { CancelPlan } from './support/change-plan/CancelPlan';
import { CancelPlanFeatures } from './support/change-plan/CancelPlanFeatures';
import { CancelPlanUpsellLite } from './support/change-plan/CancelPlanUpsellLite';
import { CancelPlanFeedback } from './support/change-plan/CancelPlanFeedback';
import { CancelPlanUpsellDiscount } from './support/change-plan/CancelPlanUpsellDiscount';
import { CancelPlanConfirm } from './support/change-plan/CancelPlanConfirm';
import {LeadsEdit} from "./marketing/leads/LeadsEdit";
import {LeadsFieldEdit} from "./marketing/leads/LeadsFieldEdit";
import {SetupOnlineChat} from "./marketing/SetupOnlineChat";
import {SetupOnlinePayment} from "./marketing/SetupOnlinePayment";
import {SetupSuiteLinkComponent} from "./marketing/suitelink/SetupSuiteLinkComponent";
import {LinkEdit} from "./marketing/suitelink/LinkEdit";
import {RequestCallComponent} from "../dashboard/RequestCallComponent";
import { SendTaxForm } from './data-export/SendTaxForm';

export const SettingsView = () => {
    const dispatch = useDispatch()
    const state = useSelector((state: RootState) => state.settings)
    const innerScrollRef = createRef<HTMLDivElement>()

    const history = useHistory()
    useEffect(() => {
        const deregister = history.listen((location, action)=> {
            if (action === 'PUSH' && innerScrollRef.current) {
                innerScrollRef.current.scrollTo(0, 0)
            }
        })

        return () => {
            deregister()
        }
    }, [history, innerScrollRef])


    // Show the search bar if we are on a list of items that has more than 10
    const [showSearchBar, setShowSearchBar] = useState(false)
    const [searchName, setSearchName] = useState('')
    const [filterText, setFilterText] = useState('')
    const searchBar = (
        <div style={{display: 'flex', margin: 'auto', borderRadius: '10px', flex: '1', justifyContent: 'center', alignContent: 'center', height: '40px', minWidth: '33%'}}>
            <div className="searchBar">
                <input type="text"
                       placeholder={`Search${searchName.length > 0 ? ` ${searchName}` : ''}`}
                       onChange={e => setFilterText(e.target.value)}
                />
            </div>
        </div>
    )
    const location = useLocation()
    useEffect(() => {
        let show = false
        let title = ''
        let itemList = state.items
        const acceptedItems = [
            'services', 'classes', 'products', 'gifts', 'reservations',
            'packages', 'categories', 'contracts', 'forms', 'subscriptions',
        ]

        const pathParts = location.pathname.split('/')
        if (pathParts.length === 3 && acceptedItems.indexOf(pathParts[2]) > -1) {
            show = true
            title = pathParts[2]
        }

        switch (title) {
            case 'contracts':
                itemList = state.contracts
                break
            case 'forms':
                itemList = state.forms
                break
        }


        setShowSearchBar(show && itemList.length > 10)
        setSearchName(title)
    }, [location.pathname, state.items, state.contracts, state.forms])

    const user = usePSUser()

    return (
        <div style={{height: '100%'}}>
            <AppHeader title="Settings" middleWidget={showSearchBar ? searchBar : null}>
                <div className="settingsWrapper">
                    <SettingsMenu />
                    <div className="innerScroll" ref={innerScrollRef}>
                        <div className="pageWrapper">
                            <Switch>
                                <Route path="/settings/services"
                                       exact>
                                    <SettingsItemList
                                        type="service"
                                        name="Service"
                                        nameFilter={filterText}
                                    />
                                </Route>
                                <Route path="/settings/categories/defaults"
                                       exact
                                       component={CategoryFromDefaults} />
                                <Route path="/settings/forms/defaults"
                                       exact
                                       component={FormFromDefaults} />
                                <Route path="/settings/contracts/defaults"
                                       exact
                                       component={ContractFromDefaults} />
                                <Route path="/settings/:itemType/defaults"
                                       component={ItemFromDefaults} />
                                <Route path="/settings/account/features"
                                       exact
                                       component={FeatureEdit} />
                                <Route path="/settings/account/profile"
                                       exact
                                       component={ProfileEdit} />
                                <Route path="/settings/account/business"
                                       exact
                                       component={BusinessEdit} />
                                <Route path="/settings/account/location"
                                       exact
                                       component={LocationEdit} />
                                <Route path="/settings/account/memos"
                                       exact
                                       component={DefaultMemos} />
                                <Route path="/settings/account/notifications"
                                       exact
                                       component={NotificationsEdit} />
                                <Route path="/settings/account/number/edit"
                                       exact
                                       component={NumberEdit} />
                                <Route path="/settings/account/number/:phoneNumberID/edit"
                                       component={NumberEdit} />
                                <Route path="/settings/account/number/:teamMemberID/new"
                                       component={NumberEdit} />
                                <Route path="/settings/account/numbers"
                                       exact
                                       component={Numbers} />
                                <Route path="/settings/services/new"
                                       exact
                                       component={ServiceEdit} />
                                <Route path="/settings/services/:serviceID/:action"
                                       component={ServiceEdit} />
                                <Route path="/settings/products"
                                       exact>
                                    <SettingsItemList
                                        type="product"
                                        name="Product"
                                        nameFilter={filterText}
                                    />
                                </Route>
                                <Route path="/settings/products/new"
                                       exact
                                       component={ProductEdit} />
                                <Route path="/settings/products/:productID/:action"
                                       component={ProductEdit} />
                                <Route path="/settings/gifts"
                                       exact>
                                       <SettingsItemList
                                           type="gift"
                                           name="Gift Certificate"
                                           nameFilter={filterText}
                                       />
                                </Route>
                                <Route path="/settings/gifts/new"
                                       exact
                                       component={GiftEdit} />
                                <Route path="/settings/gifts/:giftID/:action"
                                       component={GiftEdit} />
                                <Route path="/settings/classes"
                                       exact>
                                    <SettingsItemList type="class" typePlural="classes"
                                                      name="Class" plural="Classes" />
                                </Route>
                                <Route path="/settings/classes/new"
                                       component={ClassesEdit} />
                                <Route path="/settings/classes/:classID/schedule"
                                       exact
                                       component={ClassesSchedule} />
                                <Route path="/settings/classes/:classID/lessons/:lessonID?"
                                       component={ClassesLessonView} />
                                <Route path="/settings/classes/:classID/:action"
                                       component={ClassesEdit} />
                                <Route path="/settings/reservations"
                                       exact>
                                    <SettingsItemList
                                        type="reservation"
                                        name="Reservation"
                                        nameFilter={filterText}
                                    />
                                </Route>
                                <Route path="/settings/reservations/new"
                                       component={ReservationEdit} />
                                <Route path="/settings/reservations/:reservationID/:action"
                                       component={ReservationEdit} />
                                <Route path="/settings/packages"
                                       exact>
                                    <SettingsItemList
                                        type="package"
                                        name="Package"
                                        nameFilter={filterText}
                                    />
                                </Route>
                                <Route path="/settings/packages/new"
                                       exact
                                       component={PackageEdit} />
                                <Route path="/settings/packages/:packageID/:action"
                                       component={PackageEdit} />
                                <Route path="/settings/subscriptions"
                                       exact>
                                    <SettingsItemList
                                        type="subscription"
                                        name="Subscription"
                                        nameFilter={filterText}
                                    />
                                </Route>
                                <Route path="/settings/subscriptions/new"
                                       exact
                                       component={SubscriptionEdit} />
                                <Route path="/settings/subscriptions/:subscriptionID/:action"
                                       component={SubscriptionEdit} />
                                <Route path="/settings/sort"
                                       exact
                                       component={SettingsSort} />
                                <Route path="/settings/categories"
                                       exact
                                       component={SettingsCategoriesList} />
                                <Route path="/settings/categories/new"
                                       exact
                                       component={CategoryEdit} />
                                <Route path="/settings/categories/:categoryID/edit"
                                       component={CategoryEdit} />
                                <Route path="/settings/categories/:categoryID/sort"
                                       component={CategorySort} />
                                <Route path="/settings/leads"
                                       exact
                                       component={LeadsEdit} />
                                <Route path="/settings/leads/questions"
                                       exact>
                                    <FormQuestionList forClient={true} />
                                </Route>
                                <Route path="/settings/leads/questions/new"
                                       exact
                                       component={LeadsFieldEdit} />
                                <Route path="/settings/leads/questions/:fieldID"
                                       component={LeadsFieldEdit} />
                                <Route path="/settings/chat"
                                       exact
                                       component={SetupOnlineChat} />
                                <Route path="/settings/payments"
                                       exact
                                       component={SetupOnlinePayment} />
                                <Route path="/settings/link"
                                       exact
                                       component={SetupSuiteLinkComponent} />
                                <Route path="/settings/link/:linkID/edit"
                                       component={LinkEdit} />
                                <Route path="/settings/link/new"
                                       exact
                                       component={LinkEdit} />
                                <Route path="/settings/contracts"
                                       exact>
                                    <SettingsItemList
                                        type="contract"
                                        name="Contract"
                                        nameFilter={filterText}
                                    />
                                </Route>
                                <Route path="/settings/contracts/new"
                                       exact
                                       component={ContractEdit} />
                                <Route path="/settings/contracts/:contractID/:action"
                                       component={ContractEdit} />
                                <Route path="/settings/forms"
                                       exact>
                                    <SettingsItemList type="form" name="Form" />
                                </Route>
                                <Route path="/settings/forms/new"
                                       exact
                                       component={FormEdit} />
                                <Route path="/settings/forms/:formID?/questions/new"
                                       component={FormQuestionBuilder} />
                                <Route path="/settings/forms/:formID?/questions/:fieldID?/edit"
                                       component={FormQuestionBuilder} />
                                <Route path="/settings/forms/:formID?/questions"
                                       component={FormQuestionList} />
                                <Route path="/settings/forms/:formID/:action"
                                       component={FormEdit} />
                                <Route path="/settings/scheduling"
                                       component={SchedulingEdit} />
                                <Route path="/settings/cancellations"
                                       component={CancellationEdit} />
                                <Route path="/settings/calendar/sync"
                                       component={CalendarSyncList} />
                                <Route path="/settings/calendar/setup/:credentialID"
                                       component={ExternalCalendarSettings} />
                                <Route path="/settings/calendar/sync_to/:credentialID/"
                                       component={CalendarSyncToPicker} />
                                <Route path="/settings/calendar/sync_from/:credentialID/"
                                       component={CalendarSyncFromPicker} />
                                <Route path="/settings/payments/sales-tax"
                                       component={SalesTaxEdit} />
                                <Route path="/settings/payments/gratuity"
                                       component={GratuityEdit} />
                                <Route path="/settings/payments/methods"
                                       exact
                                       component={PaymentMethodList} />
                                <Route path="/settings/payments/methods/card/new"
                                       exact
                                       children={<CardEdit onBack={() => history.goBack()} />} />
                                <Route path="/settings/payments/methods/checking/new"
                                       component={CheckingEdit} />
                                <Route path="/settings/payments/methods/:sourceID/view"
                                       component={SourceView} />
                                <Route path="/settings/payments/payout"
                                       component={PayoutEdit} />
                                <Route path="/settings/tutorials"
                                       exact
                                       component={TutorialsList} />
                                <Route path="/settings/reader/add"
                                       exact
                                       component={AddCardReader} />
                                <Route path="/settings/reader/:readerID/edit"
                                       component={AddCardReader} />
                                <Route path="/settings/reader/:readerID/activate"
                                       component={AddCardReader} />
                                <Route path="/settings/reader/checkout"
                                       component={NewReaderCheckout} />
                                <Route path="/settings/reader"
                                       exact
                                       component={CardReaderList} />                                       
                                <Route path="/settings/tax-form"
                                       component={SendTaxForm} />                                
                                <Route path="/settings/data-export"
                                       component={DataExportView} />
                                <Route path="/settings/request-call"
                                       exact
                                       children={<RequestCallComponent onClose={() => history.goBack()} forDashboard={false} />} />
                                <Route path="/settings/login-security"
                                       component={LoginEdit} />
                                <Route path="/settings/change-plan"
                                       exact
                                       children={<SelectPlan context={CHANGE_PLAN_CONTEXT} onNext={() => dispatch(push('/'))} />} />
                                <Route path="/settings/change-plan/cancel"
                                       exact
                                       component={CancelPlan} />
                                <Route path="/settings/change-plan/cancel/features"
                                       exact
                                       component={CancelPlanFeatures} />
                                <Route path="/settings/change-plan/cancel/lite"
                                       exact
                                       component={CancelPlanUpsellLite} />
                                <Route path="/settings/change-plan/cancel/feedback"
                                       exact
                                       component={CancelPlanFeedback} />
                                <Route path="/settings/change-plan/cancel/confirm"
                                       exact
                                       component={CancelPlanConfirm} />
                                <Route path="/settings/change-plan/cancel/discount"
                                       exact
                                       component={CancelPlanUpsellDiscount} />
                                <Route path="/settings/upgrade-team-plan"
                                       exact
                                       children={<SelectPlan context={TEAM_PLAN_CONTEXT} onNext={() => dispatch(push('/'))} />} />
                                <Route path="/settings/upgrade-affiliate-plan"
                                       exact
                                       children={<SelectPlan context={AFFILIATE_PLAN_CONTEXT} onNext={() => dispatch(push('/'))} />} />
                                {user?.plan &&
                                    <Route path="/settings/videoconferencing"
                                           exact
                                           component={VideoConferencing} />
                                }
                                <Route path="/settings/privacy-terms"
                                       exact
                                       component={PrivacyAndTerms} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </AppHeader>
        </div>
    )
}