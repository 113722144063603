import React, {useCallback, useEffect, useState} from "react";
import {
    Body,
    Close,
    Footer,
    FooterItem,
    Header,
    Modal
} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import {Field, Input, Label} from "@zendeskgarden/react-forms";
import {apiClient} from "../../modules/apiclient";
import debounce from "lodash.debounce";

type Props = {
    onClose: () => void,
    onDone: (location: string) => void,
    currentLocation: string,
    filterAddresses?: boolean
}

export const LocationModal = ({onClose, onDone, currentLocation, filterAddresses}: Props) => {
    const [address, setAddress] = useState(currentLocation)
    const [selectedAddress, setSelectedAddress] = useState('')
    const [saveEnabled, setSaveEnabled] = useState(false)
    const [addressOptions, setAddressOptions] = useState([])

    useEffect(() => {
        setSaveEnabled(!!address)
    }, [address])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchAddress = useCallback(debounce((address: string) => {
        setSelectedAddress('')

        if (!address) return

        apiClient.post(`/location/autocomplete?q=${address}&filter_address=${filterAddresses ?? false}`)
            .then(resp => resp.data)
            .then((json) => {
                const addressList = json.predictions.map((p: any) => p.description)
                console.log(addressList)
                setAddressOptions(addressList)
            })
            // @ts-ignore
            .catch(error => window.Rollbar.error('locationAutocomplete error', error))
        }, 250, {trailing: true})
    , [])

    useEffect(() => {
        searchAddress(address)
    }, [address, searchAddress])

    const selectAddress = (address: string) => {
        setSelectedAddress(address)
        setAddressOptions([])
    }

    return (
        <Modal className="locationModal" onClose={onClose}>
            <Header>Location</Header>
            <Body>
                <Field className="field">
                    <Label className="label">Add location</Label>
                    <Input placeholder="Address"
                        value={selectedAddress || address}
                        onChange={e => setAddress(e.target.value)} />
                </Field>
                {addressOptions.length > 0 && selectedAddress === '' && (
                    <div className="searchResults">
                        {addressOptions.map((address: string) => (
                            <div className="entry"
                                 key={address}
                                 onClick={() => selectAddress(address)}
                            >
                                {address}
                            </div>
                        ))}
                    </div>
                )}
            </Body>
            <Footer style={{paddingBottom: 32}}>
                <FooterItem>
                    <Button onClick={() => onDone(selectedAddress || address)}
                            disabled={!saveEnabled}
                            isPrimary>
                        Save
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )
}
