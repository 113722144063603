import moment from 'moment'

const shouldShowBanner = () =>  {
    let isBannerVisible = true
    const hideTime = localStorage.getItem('hide-notification-banner')
    if (hideTime) {
        const hiddenAt = moment(parseInt(hideTime, 10))
        if (hiddenAt) {
            isBannerVisible = moment().isAfter(hiddenAt.add(2, 'weeks'))
        }
    }

    return window.Notification && window.Notification.permission === "default" && isBannerVisible
}

export interface AppState {
    bannerVisibility: boolean,
}

const initialState: AppState = {
    bannerVisibility: shouldShowBanner(),
}

export default function appReducer(state = initialState, action: any) {
    switch (action.type) {
        case 'app/SET_BANNER_VISIBILITY':
            return {
                ...state,
                bannerVisibility: action.bannerVisibility,
            }
        default:
            return state
    }
}

export const setBannerVisibility = (bannerVisibility: boolean) => {
    return { type: 'app/SET_BANNER_VISIBILITY', bannerVisibility }
}
