import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { PSButton, PSButtonPrimary } from "../../../app/PSButton"
import { ReactComponent as LeftArrow } from "../../../../icons/left-arrow.svg";
import { useAppDispatch, usePSUser } from "../../../../hooks";
import { fetchActiveSubscriptions, fetchBillingFeatures, SettingsState } from "../../../../modules/settings";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { Spinner } from "@zendeskgarden/react-loaders";
import { push, replace } from "connected-react-router";
import { PREMIUM_ID, PREMIUM_LITE_PRICE } from "../../../../utils/constants";

export const CancelPlanFeatures = () => {
    const user = usePSUser()
    const history = useHistory()
    const dispatch = useAppDispatch()
    const { activePSSubscription, billingFeatures }: SettingsState = useSelector((state: RootState) => state.settings)

    const [isLoading, setIsLoading] = useState(true)
    const [offerLite, setOfferLite] = useState(false)

    useEffect(() => {
        if (!user?.id || activePSSubscription.id) return
        dispatch(fetchActiveSubscriptions(user.id))
    }, [user?.id, activePSSubscription.id, dispatch])

    useEffect(() => {
        if (!user || !activePSSubscription.id ) return

        const { plan, interval, frequency, amount, source } = activePSSubscription
        const shouldOfferLite = !(
            user.edition_allow_basic_plan ||
            Number(user.featurecap) > 0 ||
            plan !== PREMIUM_ID ||
            interval !== 1 ||
            frequency !== 'monthly' ||
            amount <= PREMIUM_LITE_PRICE ||
            !source
        )

        setOfferLite(shouldOfferLite)
    }, [user, activePSSubscription])

    useEffect(() => {
        if (billingFeatures?.length > 0) setIsLoading(false)
    }, [billingFeatures])

    useEffect(() => {
        if (!user || billingFeatures.length > 0) return
        dispatch(fetchBillingFeatures(user.id))
    }, [dispatch, user, billingFeatures])

    return (
        <div className="supportSettings">
            <div className="header">
                <PSButton className="left" onClick={() => history.goBack()}>
                    <LeftArrow />
                </PSButton>
                <h1 className="title">Are you sure?</h1>
                <div className="right">
                    <PSButton onClick={() => offerLite ? dispatch(push('lite')) : dispatch(push('feedback'))}>Continue cancellation</PSButton>
                    <PSButtonPrimary onClick={() => dispatch(replace('/'))}>Never mind</PSButtonPrimary>
                </div>
            </div>
            <div className="separator" />
            <p className="details">
                If you cancel your plan you'll lose out on these benefits.
            </p>
            {isLoading && (
                <div style={{textAlign: 'center', padding: '128px 0'}}>
                    <Spinner size="128" color="#314A68" />
                </div>
            )}
            {!isLoading && billingFeatures.map(({ id, title, details }) => (
                <div className="supportCard column" key={`billing-feature-${id}`}>
                    <div className="title">{title}</div>
                    <div className="details">{details}</div>
                </div>
            ))}
        </div>
    )
}
