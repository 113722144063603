import React, {createRef, useState} from "react";
import {
    Body, Close,
    Footer,
    FooterItem,
    Header,
    Modal
} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import ReactSignatureCanvas from "react-signature-canvas";
import { Dots } from "@zendeskgarden/react-loaders";

type Props = {
    isSigning?: boolean,
    onClose: () => void,
    onDone: (signatureData: string) => void,
}

export const SignatureModal = ({onClose, onDone, isSigning = false}: Props) => {
    const [saveEnabled, setSaveEnabled] = useState(false)

    const padRef = createRef<ReactSignatureCanvas>()

    const onEnd = () => {
        if (!padRef.current) return

        setSaveEnabled(!padRef.current.isEmpty())
    }

    const clearSignature = () => {
        if (padRef.current) {
            padRef.current.clear()
            setSaveEnabled(false)
        }
    }

    const onSaveClicked = () => {
        if (!padRef.current) return
        padRef.current.off()

        const dataParts = padRef.current.toDataURL('image/png').split(',')
        const signatureData = dataParts.pop() as string
        onDone(signatureData)
    }

    return (
        <Modal className="signatureModal" onClose={onClose}>
            <Header>Add signature</Header>
            <Body>
                <div className="contents">
                    <div className="signBelow">
                        Sign in the below box
                    </div>
                    <div className="box">
                        <ReactSignatureCanvas canvasProps={{width: 420, height: 300 }}
                                              onEnd={onEnd}
                                              ref={padRef}
                                              />
                    </div>
                    <div className="clearSignature"
                         onClick={() => clearSignature()}>Clear signature</div>
                </div>
            </Body>
            <Footer style={{paddingBottom: 32}}>
                <FooterItem>
                    <Button onClick={onSaveClicked}
                            disabled={!saveEnabled}
                            isPrimary>
                        {isSigning ? <Dots /> : "Save"}
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )
}