import React, { useEffect, useState } from 'react'
import { e164ToPhone } from '../../../utils/phone'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { RecordBody } from '../RecordBody'
import { RecordDetail } from '../RecordDetail'
import { currencyFormat } from '../../../utils/numbers'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { ReactComponent as ChevronIcon } from '../../../icons/chevron.svg'
import { GoogleMapsAPIKey } from '../../../utils/constants'
import { getNotes } from '../../../modules/records'
import { Modal } from '../../app/Modal'
import { PSButtonPrimary, PSButton } from '../../app/PSButton'
import { usePSUser } from "../../../hooks";
import { DisplayCustomField } from '../DisplayCustomField'

export const RecordClient = (props) => {
    const { record, sources } = props
    const dispatch = useDispatch()
    const state = useSelector(state => (
        {
            records: state.records,
            entities: state.entities,
        }
    ))

    useEffect(() => {
        dispatch(getNotes('client', record.id))
    }, [dispatch, record.id])

    const [showModal, setShowModal] = useState(false)
    const [modalContents, setModalContents] = useState()

    const apiResponse = state.records.apiResponse
    const income = apiResponse.income ? currencyFormat(apiResponse.income) : '-'
    const lastDate = apiResponse.last_date ? moment(apiResponse.last_date).format('MMM Do') : null // null so we can fall through to nextDate
    const nextDate = apiResponse.next_date ? moment(apiResponse.next_date).format('MMM Do') : '-'
    const balance = apiResponse.balance ? currencyFormat(apiResponse.balance) : '-'

    const client = state.entities.clients[record.id] || {}
    const employer = state.entities.contacts[record.employer] || {}
    const teamMember = state.entities.contacts[client.employee] || {}
    const customFieldList = (client.field_values ? Object.keys(client.field_values) : []).sort((a, b) => {
        const aData = state.entities.fields[a]
        const bData = state.entities.fields[b]
        return aData && bData ? aData.seqnum - bData.seqnum : aData ? 1 : -1
    })

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GoogleMapsAPIKey,
    })

    const address = [
        record.line1, record.line2, (record.line1 || record.line2) ? ',' : '',
        (record.city && record.state) ? `${record.city},` : record.city,
        record.state
    ].join(' ')

    const recordDetails = []
    const me = usePSUser()
    const owner = me?.employer || me?.id;
    const forClient = me?.type === "client"
    const doRestrict = client?.id && me?.role === "contractor"
    const enforcePrivacy = employer?.id && !(owner === employer?.id)
    if (record.phone && !doRestrict && (!enforcePrivacy || record?.sms || employer?.sms)) {
        recordDetails.push(
            <RecordDetail header="Phone"
                          details={e164ToPhone((forClient || enforcePrivacy) && record.sms ? record.sms : (enforcePrivacy && !record.sms) ? employer.sms : record.e164)}
            />
        )
    }

    if (record.email && !doRestrict && !enforcePrivacy) {
        recordDetails.push(
            <RecordDetail header="Email"
                          details={<a href={`mailto:${record.email}`}>{record.email}</a>}
            />
        )
    }

    if (client.channel && state.entities.channels[client.channel]) {
        const channel = state.entities.channels[client.channel]
        recordDetails.push(
            <RecordDetail header="Lead source"
                          details={channel.name}
            />
        )
        if (client.cost)
            recordDetails.push(
                <RecordDetail header="Cost"
                            details={currencyFormat(client.cost)}
                />
            )
    }
    else if (client.referer && state.entities.users[client.referer]) {
        const referer = state.entities.users[client.referer]
        recordDetails.push(
            <RecordDetail header="Lead source"
                          details={referer.name}
            />
        )
    }

    if (address.trim().length !== 0) {
        const latLng = {
            lat: parseFloat(record.latitude),
            lng: parseFloat(record.longitude)
        }
        recordDetails.push(
            <RecordDetail header="Address" details={address}>
                {isLoaded && record.latitude && record.longitude && (
                    <div style={{marginTop: '12px'}}>
                        <GoogleMap
                            center={latLng}
                            zoom={15}
                            clickableIcons={false}
                            mapContainerStyle={{width: '100%', height: '320px'}}
                            options={{disableDefaultUI: true}}
                        >
                            <Marker position={latLng} />
                        </GoogleMap>
                    </div>
                )}
            </RecordDetail>
        )
    }

    if (teamMember.id) {
        const details = (
            <Link to={`/record/payee/${teamMember.id}`}>
                {teamMember.name}
                <ChevronIcon className="chevron" />
            </Link>
        )
        recordDetails.push(
            <RecordDetail header="Team member" details={details} />
        )
    }

    const filteredSources = sources && sources.filter(s => !s.cancelled_date)
    if (filteredSources.length > 0) {
        const details = filteredSources.map((source, index) => (
            <div key={`source-${index}`} className="details">
                {source.display_name}
            </div>
        ))
        recordDetails.push(
            <RecordDetail header="Cards on file"
                          details={details}
            />
        )
    }

    const showNote = (note) => {
        const noteBody = (
            <div className="bodyWrapper">
                {note.title && (
                    <h1>{note.title}</h1>
                )}
                <div className="scroll">
                    <p className="text">
                        {note.note}
                    </p>
                    {note.image_uri && (
                        <img className="userImage"
                             src={note.image_uri}
                             alt={note.title} />
                    )}
                </div>
            </div>
        )
        setModalContents(noteBody)
        setShowModal(true)
    }

    if (state.records.notes.length > 0) {
        const notes = state.records.notes.map((note, index) => (
            <div key={`notes-${note.id}`}>
                <div className="buttonLink" onClick={e => showNote(note)}>
                    {note.title || moment(note.created_date).format('MMM D, YYYY')}
                </div>
                <div style={{lineHeight: '16px', paddingBottom: '6px', color: '#999999', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre'}}>
                    {note.note.replace(/\n/g, ' ')}
                </div>
            </div>
        ))

        recordDetails.push(
            <RecordDetail header="Notes"
                          isText
                          details={notes} />
        )
    }

    if (customFieldList.length > 0) {
        customFieldList.map((field, index) => {
            const fieldData = state.entities.fields[field] || {}
            if (!fieldData.type) return null

            recordDetails.push(
                <RecordDetail header={fieldData.label}
                              details={<DisplayCustomField name={field} field={fieldData} instance={client} />} />
            )

            return null
        })
    }

    return (
        <div className="recordView">
            <div className="recordHeaderButtons">
                <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                onClick={() => dispatch(push(`/client/edit/${record.id}`))}>
                    Edit
                </PSButtonPrimary>
                <PSButton style={{height: '40px', marginLeft: '10px'}}
                                onClick={() => dispatch(push(`/dashboard/user/${record.id}`))}>
                    History
                </PSButton>
                <PSButton style={{height: '40px', marginLeft: '10px'}}
                                onClick={() => dispatch(push(`/messages/${record.id}`))}>
                    Chat
                </PSButton>
                <PSButton style={{height: '40px', marginLeft: '10px'}}
                                onClick={() => dispatch(push(`/notes/client/${record.id}`))}>
                    Notes
                </PSButton>
            </div>
            <div className="recordHeader">
                <div className="detailsWrapper">
                    <div className="details">
                        <div className="box">
                            <div className="name">Income</div>
                            <div className="value">{income}</div>
                        </div>
                        <div className="box">
                            <div className="name">{lastDate ? 'Last' : 'Next'} appt</div>
                            <div className="value">{lastDate || nextDate}</div>
                        </div>
                        <div className="box">
                            <div className="name">Balance</div>
                            <div className="value">{balance}</div>
                        </div>
                    </div>
                </div>
                <h2>{record.name}</h2>
            </div>
            <RecordBody recordDetails={recordDetails} />
            <Modal visible={showModal}
                   onClose={() => setShowModal(false)}
            >
                {modalContents}
            </Modal>
        </div>
    )
}
