import React, {useEffect, useState} from "react";
import {Textarea} from "@zendeskgarden/react-forms";

type Props = {
    placeholder: string,
    className: string,
    minRows: number,
    disabled?: boolean,
    value: string,
    onChange: (value: string) => void,
    onLearnMore?: () => void,
    maxLength?: number,
}

export const PSTextarea = ({placeholder, className, minRows, disabled, value, maxLength=900, onChange, onLearnMore}: Props) => {
    const [characterCount, setCharacterCount] = useState(maxLength)

    const onTextChange = (value: string) => {
        const remainingCount = maxLength - value.length
        setCharacterCount(remainingCount)
        onChange(value)
    }

    useEffect(() => {
        setCharacterCount(maxLength - value.length)
    }, [maxLength, value])

    return (
        <div className="pstextarea">
            <Textarea placeholder={placeholder}
                      className={className}
                      minRows={minRows}
                      disabled={disabled}
                      value={value}
                      maxLength={maxLength}
                      onChange={e => onTextChange(e.target.value)} />
            <div className="count"
                 style={{color: characterCount < 10 ? '#eb5757' : '#999999'}}>
                {characterCount}
            </div>
            {onLearnMore && (
                <div className="buttonLink brightBlue"
                        style={{display: 'inline-block', paddingTop: 8}}
                        onClick={onLearnMore}>
                    Learn more &gt;
                </div>
            )}
        </div>
    )
}