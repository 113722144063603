import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../hooks";
import {
    saveUser, 
    SettingsState
} from "../../../modules/settings";
import {RootState} from "../../../store";
import {useSelector} from "react-redux";
import {Field, Hint, Input, IInputProps, Checkbox, Label, Message} from "@zendeskgarden/react-forms";
import {Prompt} from "react-router";
import {PSButtonPrimary} from "../../app/PSButton";
import {Dots} from "@zendeskgarden/react-loaders";
import localforage from 'localforage'
import { VALIDATION } from "@zendeskgarden/react-forms/dist/typings/utils/validation";
import InputMask from 'react-input-mask'

type Props = {
    forOnboarding?: boolean,
    forceVendorLocation?: boolean,
    onComplete?: () => void,
}

export const LocationEdit = ({forOnboarding, forceVendorLocation, onComplete}: Props) => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const [saveEnabled, setSaveEnabled] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)

    const [user, setUser] = useState<any>({})

    const [superAdmin, setSuperAdmin] = useState<boolean>(false)

    const [street, setStreet] = useState('')
    const [unit, setUnit] = useState('')
    const [city, setCity] = useState('')
    const [zipcode, setZipcode] = useState('')
    const [scheduleHere, setScheduleHere] = useState(forceVendorLocation ?? false)

    const [streetRequired, setStreetRequired] = useState(false)

    const [zipcodeValidation, setZipcodeValidation] =  useState<VALIDATION | undefined>(undefined)
    const [streetValidation, setStreetValidation] = useState<VALIDATION | undefined>(undefined)

    useEffect(() => {
        if (!user) return
        setStreet(user.line1)
        setUnit(user.line2)
        setCity(user.city)
        setZipcode(user.postal_code)
        setScheduleHere(forceVendorLocation ?? user.location)
        setSuperAdmin(user.role === 'owner')
        setStreetRequired(user.type !== 'client' && !user.verified_date)
    }, [user])

    useEffect(() => {
        setZipcodeValidation( zipcode?.match(/\d{5}/) ? undefined : 'error')
    }, [zipcode])

    useEffect(() => {
        setStreetValidation( !streetRequired || street?.replace(/\s/g, '').length ? undefined : 'error')
    }, [street, streetRequired])

    useEffect(() => {
        let edits = false
        if (!user) return

        if (user.line1 !== street)
            edits = true
        else if (user.line2 !== unit)
            edits = true
        else if (user.city !== city)
            edits = true
        else if (user.postal_code !== zipcode)
            edits = true
        else if ((forceVendorLocation ?? user.location) !== scheduleHere)
            edits = true

        setHasEdits(edits)
    }, [user, street, unit, city, zipcode, scheduleHere])

    useEffect(() => {
        setSaveEnabled(hasEdits && !zipcodeValidation  && !streetValidation)
    }, [hasEdits, zipcodeValidation, streetValidation])

    useEffect(() => {
        localforage.getItem('user').then((user: any) => setUser(user))
    }, [])

    const onSave = () => {
        if (state.isSending) return

        const userParams: any = {}
        userParams.id = user.id
        userParams.line1 = street
        userParams.line2 = unit
        userParams.city = city
        userParams.postal_code = zipcode
        userParams.location = scheduleHere

        // @ts-ignore
        dispatch(saveUser(userParams)).then(() => localforage.getItem('user').then((user: any) => setUser(user)))

        if (onComplete)
            onComplete()
    }    

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Location</h1>
                <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!saveEnabled || state.isSending}>
                    {state.isSending && <Dots />}
                    {!state.isSending && "Save"}
                </PSButtonPrimary>
            </div>
            <div className="form">
               <Field className="field">
                    {!streetRequired && (
                        <Label className="label">Street</Label>
                    )}
                    {streetRequired && (
                        <Label className="label">Street (required)</Label>
                    )}
                    <Input placeholder="Add street"
                           value={street}
                           validation={streetValidation}
                           disabled={state.isSending}
                           onChange={e => setStreet(e.target.value)} />
                </Field>
                {!!streetValidation && (
                    <Message validation={streetValidation}>
                        A street address is required
                    </Message>
                )}
               <Field className="field">
                    <Label className="label">Unit</Label>
                    <Input placeholder="Add unit number"
                           value={unit}
                           disabled={state.isSending}
                           onChange={e => setUnit(e.target.value)} />
                </Field>
                <Field className="field">
                    <Label className="label">City</Label>
                    <Input placeholder="Add city"
                           value={city}
                           disabled={state.isSending}
                           onChange={e => setCity(e.target.value)} />
                </Field>
                <Field className="field">
                <Label className="label">Zipcode (required)</Label>
                    <InputMask mask="99999"
                               value={zipcode}
                               alwaysShowMask={false}
                               maskChar={''}
                               disabled={state.isSending}
                               onChange={e => setZipcode(e.target.value)}>
                               {(inputProps: IInputProps) => <Input {...inputProps}
                                   className="mobileNumberInput"
                                   placeholder="Add zipcode" 
                                   disabled={state.isSending}
                                   validation={zipcodeValidation}
                                   />}
                    </InputMask>
                    {!!zipcodeValidation && (
                        <Message validation={zipcodeValidation}>
                            That does not appear to be a valid zipcode.
                        </Message>
                    )}
                </Field>
                {superAdmin && (
                    <Field className="field">
                        <Checkbox checked={scheduleHere}
                                  disabled={state.isSending}
                                  onChange={(e) => {
                                      setScheduleHere(e.target.checked)
                                  }}>
                            <Label className="withHint">Schedule clients here</Label>
                            <Hint>Enable this if you perform services at a fixed location</Hint>
                        </Checkbox>
                    </Field>
                )}                
            </div>
            <Prompt when={hasEdits && !state.isSending}
                    message="Are you sure you'd like to leave this page without saving your changes?" />
        </div>
    )    
}
