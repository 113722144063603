import { Button } from "@zendeskgarden/react-buttons"
import { Item } from "@zendeskgarden/react-dropdowns"
import { Field, Input, Label } from "@zendeskgarden/react-forms"
import { Spinner } from "@zendeskgarden/react-loaders"
import { Modal, Header, Body, Close, Footer, FooterItem } from "@zendeskgarden/react-modals"
import { replace } from "connected-react-router"
import React, { useState, useEffect, useCallback } from "react"
import { useSelector } from "react-redux"
import { useLocation, useParams } from "react-router"
import { useAppDispatch, useConfirmModal } from "../../../hooks"
import { getRecord } from "../../../modules/records"
import { submitChargeRefund, submitRefund } from "../../../modules/transactions"
import { RootState } from "../../../store"
import { Result } from "../../../utils"
import { currencyFormat, filterMonetaryInput, shiftThirdDecimal } from "../../../utils/numbers"
import { AppHeader } from "../../app/AppHeader"
import { ErrorModal } from "../../app/ErrorModal"
import { PSButtonPrimary } from "../../app/PSButton"
import { PSDropdown } from "../../app/PSDropdown"
import { PSLearnMore } from "../../app/PSLearnMore"
import { PSTextarea } from "../../app/PSTextarea"
import { LearnMoreRefunds } from "../../settings/LearnMore"
import { markRefundAmountOptions, refundAmountOptions, refundReasonOptions, SelectOption } from "../../settings/Options"

const getPartialAmountLabel = (value: string) => {
    return value ? ` · ${currencyFormat(value)}` : ' (enter amount)'
}

export const RecordRefund = () => {
    const dispatch = useAppDispatch()
    const location: any = useLocation().state
    const params = useParams<{recordType: string, recordID: string}>()
    const state = useSelector((state: RootState) => (
        {
            entities: state.entities,
            records: state.records
        }
    ))

    const [record, setRecord] = useState(location?.record)

    useEffect(() => {
        if (record?.id) return
        dispatch(getRecord(params.recordType, params.recordID))
    }, [record, params.recordType, params.recordID, dispatch])

    useEffect(() => setRecord(state.records.data), [state.records.data])

    const recordAmount = {
        'payment': record?.amount - record?.refunded_amount,
        'charge': record.charge_amount
    }[params.recordType]

    const [amount, setAmount] = useState(recordAmount)
    const [partialAmount, setPartialAmount] = useState('')
    const [partialAmountInput, setPartialAmountInput] = useState('')
    const [selectedAmount, setSelectedAmount] = useState<SelectOption>()
    const [reason, setReason] = useState<SelectOption>()
    const [details, setDetails] = useState('')

    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => setIsLoading(!record?.id), [record])

    const [sendEnabled, setSendEnabled] = useState(false)
    useEffect(() => setSendEnabled(!isLoading && !!amount && !!reason), [isLoading, amount, reason])

    const refundOptions = {
        'payment': refundAmountOptions,
        'charge': markRefundAmountOptions
    }[params.recordType]

    const [amountOptions, setAmountOptions] = useState<SelectOption[]>(refundOptions!)
    useEffect(() => {
        if (!record?.id) return

        const options = refundOptions!.map(opt => {
            if (opt.value === 'full') return { ...opt, 'label': opt.label + ` · ${currencyFormat(recordAmount)}` }
            if (opt.value === 'net') return { ...opt, 'label': opt.label + ` · ${currencyFormat(record.adjusted_payout - record.refunded_amount)} ` }
            if (opt.value === 'partial') return { ...opt, 'label': opt.label + `${getPartialAmountLabel(partialAmount)}` }
            return opt
        })
        setAmountOptions(options)
    }, [record?.id, record.amount, record.refunded_amount, record.adjusted_payout, partialAmount, refundOptions, record.charge_amount, params.recordType, recordAmount])

    const [showPartialRefundModal, setShowPartialRefundModal] = useState(false)
    const handleAmount = useCallback((selection: SelectOption) => {
        if (selection.value === 'partial')
            setShowPartialRefundModal(true)
        else {
            if (params.recordType === 'payment') {
                if (selection.value === 'full') setAmount(record.amount)
                else if (selection.value === 'net') setAmount(record.adjusted_payout)
            }
            else if (params.recordType === 'charge') {
                if (selection.value === 'full') setAmount(record.charge_amount)
            }
            setSelectedAmount(selection)
        }
    }, [params.recordType, record.amount, record.adjusted_payout, record.charge_amount])

    const [showErrorModal, setShowErrorModal] = useState(false)
    const handlePartialAmountInput = useCallback(() => {
        const partialOption = refundAmountOptions.filter(opt => opt.value === 'partial')[0]

        if (parseFloat(partialAmountInput) > amount) {
            setShowErrorModal(true)
        } else {
            setSelectedAmount({ ...partialOption, 'label': partialOption.label + `${getPartialAmountLabel(partialAmountInput)}` })
            setAmount(parseFloat(partialAmountInput))
            setPartialAmount(partialAmountInput)
            setShowPartialRefundModal(false)
        }
    }, [amount, partialAmountInput])

    const cancelPartialAmountInput = useCallback(() => {
        setPartialAmountInput(partialAmount)
        setShowPartialRefundModal(false)
    }, [partialAmount])

    const [setCtaModalInfo, setCtaModalLoading, showCtaModal, closeCtaModal, ctaComponent] = useConfirmModal()

    const sendRefund = useCallback(() => {
        if (!record?.id || !amount || !reason) return
        setCtaModalInfo({
            title: `Refund ${currencyFormat(amount)}`,
            body: "Funds will be withdrawn from your PocketSuite balance, incoming payments and/or bank account",
            button: "Send refund",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(submitRefund(record.id, {
                    amount,
                    reason: reason.value,
                    details
                })))
                .then(() => {
                    closeCtaModal()
                    dispatch(replace(`/record/${params.recordType}/${record.id}`, { refundSuccess: true }))
                })
            }
        })
        showCtaModal()
    }, [amount, closeCtaModal, details, dispatch, params.recordType, reason, record.id, setCtaModalInfo, setCtaModalLoading, showCtaModal]);

    const sendMarkPaidRefund = useCallback(() => {
        if (!record?.id || !amount || !reason) return
        setCtaModalInfo({
            title: `Refund ${currencyFormat(amount)}`,
            body: "This refund will be recorded for reporting purposes only. No funds will be transferred as the payment was not processed through PocketSuite.",
            button: "Send refund",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(submitChargeRefund(record.id, {
                    amount,
                    reason: reason.value,
                    details
                })))
                .then(() => {
                    closeCtaModal()
                    dispatch(replace(`/record/${params.recordType}/${record.id}`, { refundSuccess: true }))
                })
            }
        })
        showCtaModal()
    }, [amount, closeCtaModal, details, dispatch, params.recordType, reason, record.id, setCtaModalInfo, setCtaModalLoading, showCtaModal]);


    const onSend = useCallback(() => {
        if (params.recordType === 'charge') {
            sendMarkPaidRefund()
        }
        else if (params.recordType === 'payment') {
            sendRefund()
        }
    }, [params.recordType, sendMarkPaidRefund, sendRefund])

    const [learnMoreComponent, showLearnMore] = PSLearnMore([
        { key: 'refunds', title: 'Refunds', body: LearnMoreRefunds },
    ]);

    return (
        <AppHeader showBackButton title="Payment" middleWidget={null}>
            <div className="servicesSettings" style={{width: '680px'}}>
                <div className="header">
                    <h1>Refund</h1>
                    <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                     onClick={onSend}
                                     disabled={!sendEnabled}
                    >
                        Send
                    </PSButtonPrimary>
                </div>
                <div className="separator" />
                <div className="form">
                    {isLoading && (
                        <Spinner size='128' color="#314A68" />
                    )}
                    {!isLoading && (
                        <>
                            <Field className="field">
                                <PSDropdown selected={selectedAmount}
                                            nameProperty="label"
                                            onSelect={(selection) => handleAmount(selection)}
                                            label="Refund Amount"
                                            hint="Select a full refund or enter a partial refund amount"
                                            placeholder={<div className="grey">Select amount</div>}
                                            onLearnMore={() => showLearnMore('refunds')}>
                                    <>
                                        {amountOptions.map(option => (
                                            <Item key={`refund-amount-${option.value}`} value={option}>
                                                {option.label}
                                            </Item>
                                        ))}
                                    </>
                                </PSDropdown>
                            </Field>
                            <Field className="field">
                                <PSDropdown selected={reason}
                                            nameProperty="label"
                                            onSelect={(selection) => setReason(selection)}
                                            label="Reason for refund"
                                            hint="Select a reason for refunding the payment"
                                            placeholder={<div className="grey">Select reason</div>}>
                                    <>
                                        {refundReasonOptions.map(option => (
                                            <Item key={`refund-reason-${option.value}`} value={option}>
                                                {option.label}
                                            </Item>
                                        ))}
                                    </>
                                </PSDropdown>
                            </Field>
                            <Field className="field">
                                <Label className="label">More details</Label>
                                <PSTextarea placeholder="Add more details about the refund and this will be added to the receipt."
                                            className="input"
                                            minRows={5}
                                            maxLength={999}
                                            value={details}
                                            onChange={value => setDetails(value)}/>
                            </Field>
                        </>
                    )}
                </div>
            </div>
            {ctaComponent}
            {showPartialRefundModal && (
                <Modal onClose={cancelPartialAmountInput}>
                    <Header className="header">Refund amount</Header>
                    <Body className="form">
                        <Field className="field">
                            <Label className="label">Partial refund</Label>
                            <Input placeholder="Enter amount"
                                value={partialAmountInput}
                                onChange={e => filterMonetaryInput(shiftThirdDecimal(e.target.value), setPartialAmountInput)} />
                        </Field>
                    </Body>
                    <Footer className="footer">
                        <FooterItem>
                            <Button isPrimary
                                    onClick={handlePartialAmountInput}
                                    disabled={parseFloat(partialAmountInput) < 1 || parseFloat(partialAmountInput) > amount}
                            >
                                Done
                            </Button>
                        </FooterItem>
                    </Footer>
                    <Close aria-label="Close modal" />
                </Modal>
            )}
            {showErrorModal &&
                <ErrorModal title="Exceeded limit"
                            message={`Refund amount cannot exceed ${currencyFormat(amount)}`}
                            buttonText="OK"
                            onClose={() => setShowErrorModal(false)}
                            onButtonClick={() => setShowErrorModal(false)}
                />
            }
            {learnMoreComponent}
        </AppHeader>
    )
}