import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { PSButton, PSButtonPrimary } from "../../../app/PSButton";
import { ReactComponent as LeftArrow } from "../../../../icons/left-arrow.svg";
import { useAppDispatch, usePSUser } from "../../../../hooks";
import { fetchBillingHistory, SettingsState } from "../../../../modules/settings"
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { currencyFormat } from "../../../../utils/numbers";
import { Spinner } from "@zendeskgarden/react-loaders";
import { push, replace } from "connected-react-router";

export const CancelPlan = () => {
    const user = usePSUser()
    const history = useHistory()
    const dispatch = useAppDispatch()
    const { billingHistory }: SettingsState = useSelector((state: RootState) => state.settings)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (billingHistory.data) setIsLoading(false)
    }, [billingHistory.data])

    useEffect(() => {
        if (!user || billingHistory.data) return
        dispatch(fetchBillingHistory(user.id))
    }, [dispatch, user, billingHistory.data])

    return (
        <div className="supportSettings">
            <div className="header">
                <PSButton className="left" onClick={() => history.goBack()}>
                    <LeftArrow />
                </PSButton>
                <h1 className="title">Cancel your plan?</h1>
                <div className="right">
                    <PSButton onClick={() => dispatch(push('cancel/features'))}>Cancel plan</PSButton>
                    <PSButtonPrimary onClick={() => dispatch(replace('/'))}>Never mind</PSButtonPrimary>
                </div>
            </div>
            <div className="separator" />
            <p className="details">
                Cancelling permanently removes your content. To keep your content you can pause your plan.
            </p>
            {isLoading && (
                <div style={{textAlign: 'center', padding: '128px 0'}}>
                    <Spinner size="128" color="#314A68" />
                </div>
            )}
            {!isLoading && (
                <>
                    <p className="title details center">{billingHistory?.header_title}</p>

                    {billingHistory.data?.map(({ id, value, details }) => (
                        <div className="supportCard" key={`billing-history-data-${id}`}>
                            <div className="title">
                                {id === 'income' ? currencyFormat(value) : value.toLocaleString()}
                            </div>
                            <div className="details">&nbsp;{details}</div>
                        </div>
                    ))}
                </>
            )}
        </div>
    )
}
