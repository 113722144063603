import React, {useEffect, useState} from "react";
import {Modal, Body, Footer, FooterItem, Header} from "@zendeskgarden/react-modals";
import {Field, Input, Label} from "@zendeskgarden/react-forms";
import {Datepicker} from "@zendeskgarden/react-datepickers";
import moment from "moment";
import {PSDropdown} from "../../app/PSDropdown";
import {Item} from "@zendeskgarden/react-dropdowns";
import {
    durationOptions,
    timeHourOptions,
    timeMinuteIntervalFiveOptions
} from "../../settings/Options";
import { PSButtonPrimary } from "../../app/PSButton";
import { apiClient } from "../../../modules/apiclient";
import { Schedule } from "../../../models/Schedule";
import { Spinner } from "@zendeskgarden/react-loaders";

type Props = {
    onClose: () => void,
    schedule: any,
    lesson: any
}

export const ScheduleDateViewModal = ({onClose, schedule, lesson}: Props) => {
    const date = moment(schedule.date).toDate()

    const dateHourValue = moment(schedule.date).format('hh')
    const selectedHour = timeHourOptions.find(option => option.value === dateHourValue.toString())

    const dateMinuteValue = moment(schedule.date).minute()
    const selectedMinute = timeMinuteIntervalFiveOptions.find(option => option.value === dateMinuteValue.toString())

    const selectedDuration = durationOptions.find(option => option.value === schedule.duration.toString())

    const [lessons, setLessons] = useState<Schedule[]>([])
    const [bookingCounts, setBookingCounts] = useState<any>({})
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        const data = {
            start: moment(schedule.date).add(-1, 'weeks').toISOString(),
            end: moment(schedule.date).add(6, 'months').toISOString(),
            item: lesson.item,
        }

        setIsLoading(true)
        apiClient.post('/calendar/sync', data)
            .then(resp => resp.data)
            .then(json => {
                // Have a count of bookings per lesson, so we can calculate spots remaining
                const bookingCounts: any = {}
                json.bookings.forEach((booking: any) => {
                    if (bookingCounts[booking.lesson])
                        bookingCounts[booking.lesson] += 1
                    else
                        bookingCounts[booking.lesson] = 1
                })
                setBookingCounts(bookingCounts)

                // Filter out the multiple lessons with the specified schedule, if we have a schedule
                const lessons = json.classes
                    .map((c: any) => {
                        const s = new Schedule()
                        s.setData(c)
                        return s
                    })
                setLessons(lessons)
            })
            .catch(error => {
                // @ts-ignore
                window.Rollbar.error('classLesson calendar sync error', error)
                return Promise.reject()
            })
            .finally(() => setIsLoading(false))
    }, [lesson.item, schedule.date])

    return (
        <Modal className="selectModal scheduleDateModal" onClose={onClose}>
            <Header className="header">Class Schedule</Header>
            <Body className="body">
            <div className="fields">
                <div className="fieldGroup">
                    <Field className="fieldWrapper">
                        <Label className="label">Date</Label>
                        <Datepicker value={date}
                                    minValue={moment().toDate()}>
                            <Input disabled />
                        </Datepicker>
                    </Field>
                    <Field className="fieldWrapper">
                        <Label className="label">Duration</Label>
                        <PSDropdown selected={selectedDuration} disabled
                                    nameProperty="label"
                                    onSelect={(selected) => {}}
                                    label="">
                            {durationOptions.map(o => (
                                <Item key={`duration-${o.value}`} value={o}>
                                    {o.label}
                                </Item>
                            ))}
                        </PSDropdown>
                    </Field>
                </div>
                <div className="fieldGroup" style={{paddingTop: '8px', marginBottom: '16px'}}>
                    <Field className="fieldWrapper">
                        <Label className="label">Time (hour)</Label>
                        <PSDropdown selected={selectedHour} disabled
                                    nameProperty="label"
                                    onSelect={(selected) => { }}
                                    label="">
                            {timeHourOptions.map(o => (
                                <Item key={`time-hour-${o.value}`} value={o}>
                                    {o.label}
                                </Item>
                            ))}
                        </PSDropdown>
                    </Field>
                    <Field className="fieldWrapper">
                        <Label className="label">Time (minutes)</Label>
                        <PSDropdown selected={selectedMinute} disabled
                                    nameProperty="label"
                                    onSelect={(selected) => { }}
                                    label="">
                            {timeMinuteIntervalFiveOptions.map(o => (
                                <Item key={`time-minute-${o.value}`} value={o}>
                                    {o.label}
                                </Item>
                            ))}
                        </PSDropdown>
                    </Field>
                </div>

                {isLoading && <Spinner size="64" color="#314A68" />}
                {lessons.map((schedule) => {
                    let remaining = schedule.capacity
                    if (bookingCounts && schedule.id && schedule.id in bookingCounts)
                        remaining = Math.max(schedule.capacity - bookingCounts[schedule.id], 0)
                    const remainingDesc = remaining ? `${remaining} spot${remaining !== 1 ? 's' : ''} left` : ''

                    return (
                        <div key={`lesson-${schedule.id}`} className="serviceCard">
                            <div className="details">
                                <div className="name">{schedule.prettyDescription('dddd MMMM D, h:mm A', true)}</div>
                                <div>{remainingDesc}</div>
                            </div>
                        </div>
                )})}
            </div>
            </Body>
            <Footer className="footer">
                <FooterItem>
                    <PSButtonPrimary onClick={onClose}>Done</PSButtonPrimary>
                </FooterItem>
            </Footer>
        </Modal>
    )
}