import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../hooks";
import {
    SettingsState,
    cancelCredential,
    fetchCredentials
} from "../../modules/settings";
import {RootState} from "../../store";
import {useSelector} from "react-redux";
import {Spinner} from "@zendeskgarden/react-loaders";
import {apiClient} from "../../modules/apiclient";
import {processError} from "../../modules/error";
import {CredentialCard} from "./calendar-sync/CredentialCard";
import { Credential } from "../../models/Credential";
import {ReactComponent as ZoomIcon} from "../../icons/zoom.svg"
import {ReactComponent as PocketSuiteIcon} from "../../icons/pocketsuite-icon-blue.svg"

export const VideoConferencing = () => {
    const dispatch = useAppDispatch()
    const settingsState: SettingsState = useSelector((state: RootState) => state.settings)
    const credential = settingsState.credentials.find(f => f.provider === 'zoom')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        dispatch(fetchCredentials())
    }, [dispatch])

    const startZoomAuth = () =>
        apiClient.get('/auth/zoom')
            .then(resp => resp.data )
            .then((json: any) => {
                window.location.href = json.url
            })
            .catch(error => dispatch(processError(error, "Error starting auth process")))

    const disconnectAuth = (credential: Credential) => {
        setLoading(true)
        dispatch(cancelCredential(credential))
            // @ts-ignore
            .then(() => dispatch(fetchCredentials()))
            .then(() => setLoading(false))
    }

    const getCredentialState = (credential: Credential) =>
        credential.failing_date? 'disconnected' : 'connected'

    if (settingsState.isSending || loading) {
        return (
            <div className="servicesSettings">
                <div className="header">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Video Conferencing</h1>
            </div>
            <div className="separator" />
            {credential && <CredentialCard key={`credential-${credential.id}`}
                                                    state={getCredentialState(credential)}
                                                    credential={credential}
                                                    onDisconnect={() => disconnectAuth(credential)}
                                                    icon={(<ZoomIcon />)}
                                    />}
            {!credential && (
                <div className="calendarCard">
                    <ZoomIcon/>
                    <div className="details">
                        <div className="name">Zoom</div>
                            <div className="rate">
                                <span style={{color: "#999999"}}>
                                    Connect your Zoom account to add Zoom links to appointments
                                </span>
                        </div>

                    </div>
                    <div className="edit">
                        <div className="view">
                            <div onClick={() => startZoomAuth()} className="view buttonLink brightBlue">
                                Connect
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="calendarCard">
                <PocketSuiteIcon style={{maxWidth: "36px", maxHeight: "36px"}} />
                <div className="details">
                    <div className="name">PocketSuite</div>
                        <div className="rate">
                            <span style={{color: "#20BC52"}}>
                                Connected
                            </span>
                    </div>
                </div>
            </div>
        </div>
    )
}