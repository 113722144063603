import React, {useEffect, useState} from "react"
import {AppHeader} from "../app/AppHeader";
import {useAppDispatch} from "../../hooks";
import animation from "../../icons/confetti_desktop.json"
import Lottie from "lottie-react";
import { push } from "connected-react-router";
import { RequestCallComponent } from "../dashboard/RequestCallComponent";
import { setGoLiveState } from "../../modules/onboarding";
import { fetchDashboards } from "../../modules/dashboard";

export const GoLiveSuccess = () => {
    const dispatch = useAppDispatch()

    const [confettiVisible, setConfettiVisible] = useState(true)
    const [showSetupCall, setShowSetupCall] = useState(false)

    useEffect(() => {
        setGoLiveState('gl_complete')
            .then(dispatch(fetchDashboards("main")))
    }, [dispatch])

    return (
        <AppHeader title="Grow your business" middleWidget={null}>
            {confettiVisible &&
                <Lottie style={{position: 'absolute', width: '100%'}} animationData={animation}
                    loop={false}
                    onClick={() => setConfettiVisible(false)}
                    onComplete={() => setConfettiVisible(false)}
                />
            }
            <div className="scheduleClient servicesSettings onboarding launchSuccess">
                <div className="wrapper">
                <div className="header">
                    <div style={{width:'100%'}}>
                        <h5>You're all setup</h5>
                        <h1>Let's get you booked!</h1>
                    </div>
                </div>

                <div className="form">
                    <div className="text">You're all setup and ready to go! Want to connect with a member of our Customer Success Team? Schedule a call below!</div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img alt="edition logo" src="/icons/edition-blue-logo-no-hat.png" width="315" height="203" />
                    </div>
                    <div className="serviceCard onboardingCard">
                        <div className="details">
                            <div className="name">Have questions before going live?</div>
                            <div className="rate">Schedule an optional 15-minute call with our Customer Success Team.</div>
                        </div>
                        <div className="edit">
                            <div className={`buttonLink brightBlue`}
                                    onClick={() => { setShowSetupCall(true) }}
                            >
                                Select
                            </div>
                        </div>
                    </div>
                    <div className="serviceCard onboardingCard">
                        <div className="details">
                            <div className="name">Dashboard</div>
                        <div className="rate">View your PocketSuite homescreen</div>
                        </div>
                        <div className="edit">
                            <div className={`buttonLink brightBlue`}
                                    onClick={() => dispatch(push('/'))}
                            >
                                Select
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            {showSetupCall && (
                <RequestCallComponent forDashboard={true} onClose={() => setShowSetupCall(false)} />)}
        </AppHeader>
    )
}
