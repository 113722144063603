import React, {useEffect, useState} from "react";
import { useAppDispatch } from "../../../hooks";
import {apiClient} from "../../../modules/apiclient"
import {processError} from "../../../modules/error";
import {featureTutorial} from "../../../models/Feature";
import {PSVideoModal} from "../../app/PSVideoModal";

type Props = {
    featureID: string,
    title: string,
    text?: string,
    onClose: () => void,
}

export const TutorialVideoModal = ({onClose, featureID, title, text}: Props) => {
    const dispatch = useAppDispatch()

    const [isLoading, setIsLoading] = useState(true)
    const [videoURL, setVideoURL] = useState('')

    useEffect(() => {
        if (!featureID) return

        apiClient.get(`/tutorial/${featureTutorial(featureID)}`)
            .then((resp: any) => resp.data)
            .then((json: any) => {
                setVideoURL(`https://player.vimeo.com/video/${json.video_id}?autoplay=1`)
            })
            .catch(error => dispatch(processError(error)))
            .finally(() => {
                setIsLoading(false)
            })
    }, [dispatch, featureID])

    return <PSVideoModal title={title}
                         text={text}
                         videoURL={videoURL}
                         isLoading={isLoading}
                         onClose={onClose} />
}