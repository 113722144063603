import React, { useEffect, useState } from 'react'
import { AppHeader } from '../app/AppHeader'
import { CalendarHeaderBar } from './CalendarHeaderBar'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from '@zendeskgarden/react-loaders'
import {
    clearSearch,
    getCalendar,
    searchCalendar
} from '../../modules/calendar'
import { CalendarDay } from './CalendarDay'
import { CalendarWeek } from './CalendarWeek'
import { CalendarMonth } from './CalendarMonth'
import { CalendarSchedule } from './CalendarSchedule'
import { generateCancelToken } from '../../modules/apiclient'
import { TypeAheadSearchBar } from '../app/TypeAheadSearchBar'
import { CalendarSearch } from './CalendarSearch'

export const CalendarIndex = () => {
    const dispatch = useDispatch()
    const state = useSelector(state => state.calendar)

    useEffect(() => {
        const cancelToken = generateCancelToken()
        dispatch(getCalendar(state.currentDate, state.currentView, cancelToken.token))
        return _ => {
            if (cancelToken) {
                cancelToken.cancel('index cancel')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let loadingIndicator
    if (state.isSending) {
        loadingIndicator = (
            <div style={{textAlign: 'center', padding: '128px 0'}}>
                <Spinner size={128} color="#314A68" />
            </div>
        )
    }

    const [calendarHeight, setCalendarHeight] = useState(window.innerHeight-214)
    useEffect(() => {
        const handleResize = () => {
            setCalendarHeight(window.innerHeight-214)
        }
        window.addEventListener('resize', handleResize)

        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    })

    let selectedCalendarView
    if (!state.isSending) {
        switch (state.currentView) {
            case 'day':
                selectedCalendarView = <CalendarDay height={calendarHeight} />
                break
            case 'month':
                selectedCalendarView = <CalendarMonth height={calendarHeight} />
                break
            case 'week':
                selectedCalendarView = <CalendarWeek height={calendarHeight} />
                break
            case 'schedule':
            default:
                selectedCalendarView = <CalendarSchedule height={calendarHeight} />
                break
        }
    }

    // If we are searching, then switch to the schedule view
    if (state.isSearching || state.didSearch)
        selectedCalendarView = <CalendarSearch height={calendarHeight} />

    const calendarSearch = (
        <div style={{display: 'flex', margin: 'auto', borderRadius: '10px', flex: '1', justifyContent: 'center', alignContent: 'center', height: '40px', minWidth: '33%'}}>
            <TypeAheadSearchBar type="text"
                                clearSearch={clearSearch}
                                search={searchCalendar}
                                stateField="calendar"
                                className="calendarSearch"
                                searchBarPlaceholder="Search" />
        </div>
    )

    return (
        <AppHeader title="Calendar" middleWidget={calendarSearch}>
            <div style={{margin: '32px 32px 0 32px'}}>
                <CalendarHeaderBar />
                {loadingIndicator}
                {selectedCalendarView}
            </div>
        </AppHeader>
    )
}