import React, { useState } from "react";
import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import { PSButtonPrimary } from "./PSButton";

type PSLearnMoreReturnType = [React.ReactNode, (key: string) => void]
export const PSLearnMore = (modals: Array<any>): PSLearnMoreReturnType => {
    const [learnMoreVisible, setLearnMoreVisible] = useState(false)
    const [learnMoreTitle, setLearnMoreTitle] = useState('')
    const [learnMoreBody, setLearnMoreBody] = useState<React.ReactNode>()

    const closeLearnMore = () => setLearnMoreVisible(false)
    const showLearnMore = (key: string) => {
        const modal = modals.find((z: any) => z.key === key);
        setLearnMoreTitle(modal.title)
        setLearnMoreBody(modal.body)
        setLearnMoreVisible(true)
    }

    return [
        (learnMoreVisible ?
            <Modal onClose={closeLearnMore}>
                <Header>{learnMoreTitle}</Header>
                <Body>{learnMoreBody}</Body>
                <Close aria-label="Close modal" />
                <Footer>
                    <FooterItem>
                        <PSButtonPrimary onClick={closeLearnMore}>Done</PSButtonPrimary>
                    </FooterItem>
                </Footer>
            </Modal> :
            undefined
        ),
        showLearnMore
    ]
}