import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../hooks";
import {
    SettingsState,
    submitLoginSettings
} from "../../../modules/settings";
import {RootState} from "../../../store";
import {useSelector} from "react-redux";
import {PSDropdown} from "../../app/PSDropdown";
import {Prompt} from "react-router";
import {PSButtonPrimary} from "../../app/PSButton";
import {Dots} from "@zendeskgarden/react-loaders";
import localforage from 'localforage'
import {findSelection, SelectOption} from "../Options";
import {loginTTLOptions} from "../Options";
import {Item} from "@zendeskgarden/react-dropdowns";
import {isEmpty, Result} from '../../../utils';
import {Field, Hint, Input, Label} from "@zendeskgarden/react-forms";
import {e164ToPhone} from "../../../utils/phone";
import {dateFormat} from "../../../utils/dates";


export const LoginEdit = () => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const findTTL = (device: any) => findSelection(loginTTLOptions, device?.ttl)

    const [hasEdits, setHasEdits] = useState(false)
    const [user, setUser] = useState<any>({})
    const [device, setDevice] = useState<any>({})
    const [phone, setPhone] = useState('')
    const [loginDate, setLoginDate] = useState('')
    const [password, setPassword] = useState<string>('')
    const [ttl, setTTL] = useState<SelectOption>(findTTL(device) ?? loginTTLOptions[0])

    useEffect(() => {
        Promise.all([
            localforage.getItem('user'),
            localforage.getItem('device')
        ]).then(values => {
            setUser(values[0])
            setDevice(values[1])
        })
    }, [])

    useEffect(() => {
        if (isEmpty(user)) return

        setPhone(e164ToPhone(user.e164))
        setPassword(user.password)
    }, [user])

    useEffect(() => {
        if (isEmpty(user)) return

        const edits = user.password !== password && (!password.length || password.length == 4)
        setHasEdits(edits)
    }, [user, password])

    useEffect(() => {
        if (isEmpty(device)) return

        setLoginDate(device.lastLoginDate)
        setTTL(findTTL(device) ?? loginTTLOptions[0])
    }, [device])

    useEffect(() => {
        if (isEmpty(device)) return

        const edits = device.ttl?.toString() !== ttl.value
        setHasEdits(edits)
    }, [device, ttl])

    const onSave = () => {
        if (state.isSending) return

        const settingParams: any = {}
        settingParams.id = device.id;
        settingParams.ttl = ttl.value;
        settingParams.password = password;

        Result(dispatch(submitLoginSettings(settingParams))).then(() => {
            Promise.all([
                localforage.getItem('user'),
                localforage.getItem('device')
            ]).then(values => {
                setUser(values[0])
                setDevice(values[1])
            })
        });
    }

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Login & Security</h1>
                <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!hasEdits || state.isSending}>
                    {state.isSending ? <Dots /> : "Save"}
                </PSButtonPrimary>
            </div>

            <div className="form">
                <Field className="field">
                    <Label className="label">Login</Label>
                    <Hint>Your last login with this device was on {dateFormat(loginDate, 'ddd[.] MMM Do YYYY')} at {dateFormat(loginDate, 'h:mm a')}.</Hint>
                    <Input value={phone}
                           disabled={true} />
                </Field>

                <Field className="field">
                    <Label className="label">Password</Label>
                    <Hint>{user?.password?.length ? "Your login is via password. Delete your password to switch to text message." : "Your login is via text message. Enter a 4 digit pin to switch to password."}</Hint>
                    <Input type="password"
                           value={password}
                           onChange={event => setPassword(event.target.value)}
                           maxLength={4}
                           onKeyPress={(event) => {
                               if (!/[0-9]/.test(event.key)) {
                                   event.preventDefault();
                               }
                           }}
                    />
                </Field>

                <PSDropdown selected={ttl}
                            nameProperty={"label"}
                            onSelect={(selected) => setTTL(selected)}
                            label={"Require login every"}>
                    <>
                        {loginTTLOptions.map(option => (
                            <Item key={`ttl-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>
            </div>

            <Prompt
                when={hasEdits && !state.isSending}
                message="Are you sure you'd like to leave this page without saving your changes?" />

        </div>
    )
}