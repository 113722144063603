import React from "react";
import {Category} from "../../models/Category";
import {useAppDispatch} from "../../hooks";
import {
    ReactComponent as CategoryIcon
} from "../../icons/ps_settings_categories_nobg.svg";
import {push} from "connected-react-router";
import { Field, Label, Toggle } from "@zendeskgarden/react-forms";

type Props = {
    category: Category,
    isChecked: boolean,
    editURL: string,
    onToggleCategory: (b: boolean) => void,
}
export const OnboardingCategory = ({category, isChecked, editURL, onToggleCategory}: Props) => {
    const dispatch = useAppDispatch()

    let categoryImage = <CategoryIcon />
    if (category.image_uri)
        categoryImage = <img alt={category.name} src={category.image_uri} />

    return (
        <div className={`serviceCard onboardingCard ${isChecked ? 'selected' : ''}`}>
            {categoryImage}
            <div className="details">
                <div className="name">{category.name}</div>
                <div className="rate">{category.listings.length} services</div>
            </div>
            <div className="edit">
                {isChecked && (
                    <div className="buttonLink brightBlue"
                         onClick={() => {
                             dispatch(push(`/onboarding/category/edit`, {category}))
                         }}
                     >
                        Edit
                    </div>
                )}
            </div>
            <div className="toggle">
                <Field>
                    <Toggle checked={isChecked}
                            onChange={e => {
                                onToggleCategory(e.target.checked)
                            }}
                    >
                        <Label>&nbsp;</Label>
                    </Toggle>
                </Field>
            </div>
        </div>
    )
}
