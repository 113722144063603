import React, {useCallback, useEffect, useState} from "react";
import {useAppDispatch, usePSOwner} from "../../../hooks";
import {
    saveSalesTax,
    SettingsState
} from "../../../modules/settings";
import {
    Checkbox,
    Field,
    Hint,
    Label,
} from "@zendeskgarden/react-forms";
import {IconButton} from "@zendeskgarden/react-buttons";
import {RootState} from "../../../store";
import {useSelector} from "react-redux";
import {Prompt} from "react-router";
import {PSButtonPrimary} from "../../app/PSButton";
import {Dots} from "@zendeskgarden/react-loaders";
import localforage from 'localforage'
import {PSLearnMore} from "../../app/PSLearnMore";
import {LearnMoreSalesTax} from "../LearnMore";
import {isEmpty, reportError, Result, stringEqual} from '../../../utils';
import {apiClient} from "../../../modules/apiclient";
import {debounce} from "lodash";
import {LocationModal} from "../LocationModal";
import {DangerModal} from "../DangerModal";

type Props = {
    headerTitle?: string,
    buttonTitle?: string,
    onComplete?: () => void,
}

export const SalesTaxEdit = ({headerTitle, buttonTitle, onComplete}: Props) => {
    const [learnMoreComponent, showLearnMore] = PSLearnMore([
        { key: 'sales-tax', title: 'Sales Tax', body: LearnMoreSalesTax }
    ]);

    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const [saveEnabled, setSaveEnabled] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)
    const [showBusinessEdit, setShowBusinessEdit] = useState(false)

    const [shouldCollectTax, setShouldCollectTax] = useState<boolean | undefined>()
    const [salesTax, setSalesTax] = useState<any>({})
    const [taxPercentage, setTaxPercentage] = useState(0)
    const [showInvalidAddress, setShowInvalidAddress] = useState(false)
    const [fetchingTaxCode, setFetchingTaxCode] = useState(false)
    const [address, setAddress] = useState<string | undefined>()
    const [taxCode, setTaxCode] = useState<string | undefined>()

    const [user, setUser] = useState<any>({})
    const owner = usePSOwner()

    const getTaxInfo =
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useCallback(debounce((userId: string) => {
            if (fetchingTaxCode) return

            setFetchingTaxCode(true)
            return apiClient.get(`/user/${userId}/taxcode`)
                .then(response => response.data)
                .then(json => {
                    setSalesTax(json)
                })
                .catch(error => {
                    reportError('getTaxInfo', { error, taxAddress: owner?.tax_address })

                    if (!owner?.taxcode && owner?.tax_address) {
                        return findTaxCode(owner.tax_address)
                    }
                    else {
                        setTaxCode("")
                        setTaxPercentage(0)
                    }
                })
                .finally(() => setFetchingTaxCode(false))
            }
        , 250), [user])

    const findTaxCode =
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useCallback(debounce((location: string) => {
            setFetchingTaxCode(true)
            return apiClient.post(`taxcode`, { location })
                .then(response => response.data)
                .then(json => {
                    if (isEmpty(json)) {
                        setShowInvalidAddress(true)
                        return
                    }

                    setAddress([json.street.trim(), json.city, json.state, json.postal_code].join(', ').trim())
                    setTaxPercentage(parseFloat(json.rate))
                    setTaxCode(json.id)
                    setShowBusinessEdit(false)
                })
                .catch(error => reportError('findtaxcode', { error, address: location}))
                .finally(() => setFetchingTaxCode(false))
            }
        , 250), [])

    const onSave = () => {
        if (state.isSending) return

        setShowBusinessEdit(false)
        Result(dispatch(saveSalesTax(user.id, address ?? '', taxCode ?? '', shouldCollectTax ?? false)))
            .then(() => localforage.getItem('user')
            .then((user: any) => {
                setUser(user)
                if (onComplete) {
                    onComplete()
                }
            }))
    }

    const onLocationModalDone = (location: string) => {
        setTaxCode(undefined)
        findTaxCode(location)
    }

    useEffect(() => {
        if (isEmpty(owner)) return

        const edits =
            (owner!.always_collect_tax || false) !== shouldCollectTax ||
            !stringEqual(owner!.tax_address, address) ||
            !stringEqual(owner!.taxcode, taxCode)

        setHasEdits(edits)
    }, [owner, shouldCollectTax, address, taxCode])

    useEffect(() => setSaveEnabled(!fetchingTaxCode && hasEdits), [hasEdits, fetchingTaxCode])

    useEffect(() => {
        if (isEmpty(salesTax)) return

        setTaxPercentage(parseFloat(salesTax.rate))
        setTaxCode(salesTax.id)

    }, [salesTax])

    useEffect(() => {
        if (isEmpty(owner)) return

        if (shouldCollectTax === undefined) {
            setShouldCollectTax(owner!.always_collect_tax || false)
        }
        if (address === undefined) setAddress(owner!.tax_address ?? '')
        if (taxCode === undefined) getTaxInfo(owner!.id)

    }, [owner, shouldCollectTax, setShouldCollectTax, address, setAddress, taxCode, getTaxInfo])

    useEffect(() => {
        localforage.getItem('user').then((user: any) => setUser(user))
    }, [])

    let taxLabel = <Label>{`Your business location`}</Label>
    if (taxPercentage) {
        taxLabel = <Label>{`Your business location • ${taxPercentage}%`}</Label>
    }
    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>{headerTitle ?? 'Setup sales tax'}</h1>
                <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!saveEnabled || state.isSending}>
                    {state.isSending ? <Dots /> : (buttonTitle ?? "Save")}
                </PSButtonPrimary>
            </div>
            <div className="form">
                <Field className="field">
                    <div style={{display: 'flex'}}>
                        {taxLabel}
                        <IconButton style={{marginLeft: 'auto'}}
                                    onClick={() => setShowBusinessEdit(true)}>
                            <p style={{height: '36px', marginLeft: '-7px'}}>Edit</p>
                        </IconButton>
                    </div>
                    <Hint>{address}</Hint>
                </Field>

                <Field className="field">
                    <Checkbox checked={shouldCollectTax}
                              disabled={state.isSending}
                              onChange={(e) => setShouldCollectTax(e.target.checked)}>
                        <Label className="withHint">Always collect sales tax</Label>
                        <Hint>Collect sales tax on every transaction</Hint>
                    </Checkbox>
                    <div className="buttonLink brightBlue"
                         style={{display: 'inline-block', paddingTop: 8}}
                         onClick={() => showLearnMore('sales-tax')}>
                        Learn more &gt;
                    </div>
                </Field>

            </div>

            {learnMoreComponent}

            {showBusinessEdit && (
                    <LocationModal
                        filterAddresses={true}
                        onClose={() => setShowBusinessEdit(false)}
                        onDone={onLocationModalDone}
                        currentLocation={address ?? ""} />
                )}

            {showInvalidAddress && (
                <DangerModal title="Please try again"
                                body="Enter a valid address with zipcode so that PocketSuite can calculate your sales tax rate. "
                                buttonText="Ok"
                                disableDismiss={true}
                                onClose={() => setShowInvalidAddress(false)}
                                onAction={() => setShowInvalidAddress(false)}
                />
            )}

            <Prompt
                when={hasEdits && !state.isSending}
                message="Are you sure you'd like to leave this page without saving your changes?" />

        </div>
    )
}