import React, {useCallback, useEffect, useState} from "react";
import {
    PSButtonDanger,
    PSButtonPrimary,
    PSToggleButton
} from "../../app/PSButton";
import {Dots, Spinner} from "@zendeskgarden/react-loaders";
import {useAppDispatch, usePSOwner} from "../../../hooks";
import {
    clearItem, deleteItem,
    fetchAttachments, fetchItem, fetchItems,
    saveItem,
    SettingsState
} from "../../../modules/settings";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {useLearnMore, usePackageComponent, usePhotoUpload} from "../hooks";
import {
    Checkbox,
    Field,
    Hint,
    Input,
    Label, Radio,
} from "@zendeskgarden/react-forms";
import {currencyFloat, filterMonetaryInput} from "../../../utils/numbers";
import {PSDropdown} from "../../app/PSDropdown";
import {
    discountOptions, SelectOption,
    sessionCountOptions,
    sessionExpireOptions,
    sessionHourOptions
} from "../Options";
import {Dropdown, Item, Menu, Select, Field as DropdownField} from "@zendeskgarden/react-dropdowns";
import {Body, Close, Header, Modal} from "@zendeskgarden/react-modals";
import {GuardrailModal} from "../GuardrailModal";
import {
    EnabledServiceSelection,
    SelectServicesModal
} from "./SelectServicesModal";
import {Item as PSItem, ServiceComponent} from "../../../models/Item";
import {Prompt, useHistory, useParams} from "react-router";
import findIndex from "lodash.findindex";
import {SettingsPhotoField} from "../SettingsPhotoField";
import {PSTextarea} from "../../app/PSTextarea";
import {updateItem} from "../../../modules/onboarding";

type Props = {
    forOnboarding?: boolean,
    refreshData?: boolean,
}

export const PackageEdit = ({forOnboarding, refreshData}: Props) => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const history = useHistory()

    const [photo, setPhoto] = useState<undefined | string | File>('')
    const [image, setImage] = useState<undefined | string>('') // For editing
    const [name, setName] = useState('')
    const [price, setPrice] = useState('')
    const [discount, setDiscount] = useState<any>('')
    const [salesTax, setSalesTax] = useState(false)
    const [description, setDescription] = useState('')
    const [sessionCount, setSessionCount] = useState<SelectOption>()
    const [limitBy, setLimitBy] = useState('sessions')
    const [servicesSelection, setServicesSelection] = useState('')
    const [limitedServices, setLimitedServices] = useState<EnabledServiceSelection[]>([])
    const [expiresAfter, setExpiresAfter] = useState<any>('')
    const [online, setOnline] = useState(true)
    const [contract, setContract] = useState<any>('')
    const [form, setForm] = useState<any>('')
    const [saveEnabled, setSaveEnabled] = useState(false)
    const [formSending, setFormSending] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)

    const [addPhotoRef, selectPhoto, selectedPhoto] = usePhotoUpload({disabled: state.isSending, setPhoto})
    const [learnMoreVisible, learnMoreTitle, learnMoreBody, showLearnMore] = useLearnMore()
    const [showSelectServicesModal, setShowSelectServicesModal] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    const [salesTaxEnabled, setSalesTaxEnabled] = useState(false)
    const [onlineBookingEnabled, setOnlineBookingEnabled] = useState(false)
    const [discountEnabled, setDiscountEnabled] = useState(false)
    const [formsEnabled, setFormsEnabled] = useState(false)
    const [contractsEnabled, setContractsEnabled] = useState(false)
    const owner = usePSOwner()
    useEffect(() => {
        if (!owner) return
        setSalesTaxEnabled(owner.allow_salestax)
        setOnlineBookingEnabled(owner.allow_widget)
        setDiscountEnabled(owner.allow_discount)
        setFormsEnabled(owner.allow_record)
        setContractsEnabled(owner.allow_contract)
    }, [owner])

    useEffect(() => {
        setSaveEnabled(!!name && !!sessionCount && !!limitBy && !!servicesSelection && !!expiresAfter)
    }, [name, sessionCount, limitBy, servicesSelection, expiresAfter])

    useEffect(() => {
        setSessionCount(currentVal => {
            if (!currentVal) return currentVal

            if (limitBy !== 'duration') {
                const count = Math.floor(Number(currentVal.value) / 60)
                return sessionCountOptions.find(option => option.value === count.toString())
            }
            else {
                const count = Number(currentVal.value) * 60
                return sessionHourOptions.find(option => option.value === count.toString())
            }
        })
    }, [limitBy])

    useEffect(() => {
        if (forOnboarding) {
            setHasEdits(false)
            return
        }

        let changed = 0
        if ((state.item.name || name) && name !== state.item.name)
            changed = 1
        else if ((state.item.rate || Number(price)) && Number(price) !== Number(state.item.rate))
            changed = 2
        else if ((state.item.memo || description) && description !== state.item.memo)
            changed = 3
        else if ((state.item.allow_salestax || salesTax) && salesTax !== state.item.allow_salestax)
            changed = 5
        else if ((state.item.discount || discount) && discount?.value !== state.item.discount)
            changed = 6
        else if ((state.item.contract || contract) && contract.id !== state.item.contract)
            changed = 9
        else if ((state.item.form || form) && form.id !== state.item.form)
            changed = 10
        else if ((state.item.is_public || online) && online !== state.item.is_public)
            changed = 11
        else if ((state.item.expires || expiresAfter) && expiresAfter?.value !== state.item.expires.toString())
            changed = 7
        else if (limitBy === 'session' && (state.item.capacity || sessionCount) && Number(sessionCount?.value) !== Number(state.item.capacity))
            changed = 12
        else if (limitBy === 'duration' && (state.item.capacity || sessionCount) && Number(sessionCount?.value) !== Number(state.item.capacity))
            changed = 12
        else if ((state.item.capacity_type || limitBy) && limitBy !== state.item.capacity_type)
            changed = 8
        else if (typeof photo === 'object')
            changed = 13
        // TODO serviceSelection
        setHasEdits(changed > 0)
    }, [
        state.item, name, price, description, discount, salesTax, online,
        contract, form, expiresAfter, limitBy, sessionCount, photo, forOnboarding
    ])

    // Fetch the user's contracts and forms
    useEffect(() => {
        dispatch(fetchAttachments())
        dispatch(fetchItems())
    }, [dispatch])

    // Load the package if this is an edit, otherwise treat it as a create
    const params: any = useParams()
    useEffect(() => {
        if (forOnboarding) return

        if (!params.packageID) {
            dispatch(clearItem())
            return
        }
        dispatch(fetchItem('package', params.packageID))
    }, [dispatch, params.packageID, forOnboarding])

    // Update state of the component when a package is loaded
    useEffect(() => {
        if (params.action !== 'copy')
            setName(state.item.name || '')

        setPhoto(state.item.image_uri || '')
        setImage(state.item.image || '')
        setPrice(state.item.rate ? currencyFloat(state.item.rate).toFixed(2) : '')
        setDescription(state.item.memo || '')
        setOnline(state.item.is_public)
        if (state.item.package_all)
            setServicesSelection( 'all')
        else if (state.item.id) // Only set it if there is an item loaded and package_all is not true
            setServicesSelection( 'some')
        else
            setServicesSelection( '')
        if (!state.item.package_all && state.item.components) {
            const enabledServices: EnabledServiceSelection[] = []
            state.item.components.forEach((component: ServiceComponent) => {
                const idx = findIndex(state.items, (i: PSItem) => i.id === component.component)
                if (idx > -1) {
                    const item = state.items[idx]
                    enabledServices.push({
                        name: item.name,
                        id: item.id as string,
                        count: Number(component.capacity || 0),
                    })
                }
            })
            setLimitedServices(enabledServices)
        }
        else {
            setLimitedServices([])
        }

        if (state.item.expires) {
            const selectedExpiry = sessionExpireOptions.find(option => option.value === state.item.expires.toString())
            setExpiresAfter(selectedExpiry || '')
        }
        else {
            setExpiresAfter('')
        }

        if (state.item.capacity_type === 'session') {
            setLimitBy('session')
            const selectedCapacity = sessionCountOptions.find(option => option.value === state.item.capacity.toString())
            setSessionCount(selectedCapacity)
        }
        else if (state.item.capacity_type === 'duration') {
            setLimitBy('duration')
            const selectedCapacity = sessionHourOptions.find(option => option.value === state.item.capacity.toString())
            setSessionCount(selectedCapacity)
        }
        else {
            setLimitBy('session')
            setSessionCount(undefined)
        }

        const selectedDiscount = discountOptions.find(option => option.value === state.item.discount)
        setDiscount(selectedDiscount || '')
        setSalesTax(state.item.allow_salestax)

        const selectedContract = state.contracts.find((c) => c.id === state.item.contract)
        setContract(selectedContract || '')

        const selectedForm = state.forms.find((f) => f.id === state.item.form)
        setForm(selectedForm || '')
    }, [state.item, state.items, state.contracts, state.forms, params.action])

    // Guardrail states
    const [showGuardrail, setShowGuardrail] = useState(false)
    const [guardrailTitle, setGuardrailTitle] = useState('')
    const [guardrailBody, setGuardrailBody] = useState<string | React.ReactNode>('')
    const [guardrailButton, setGuardrailButton] = useState('')
    const [guardrailType, setGuardrailType] = useState('')
    const guardrailAction = useCallback(() => {
        // TODO Add unsaved form guardrail
        switch (guardrailType) {
            case 'servicesSelection':
                setServicesSelection('all')
                setLimitedServices([])
                break
            default:
                console.log(`Unknown guardrail type: ${guardrailType}`)
        }
        setShowGuardrail(false)
    }, [guardrailType])

    const changeServicesSelection = (selected: string) => {
        if (servicesSelection !== 'all' && selected === 'all') {
            setGuardrailTitle('Apply package to all services')
            setGuardrailBody('If a client has this package (and the package is active) they will be able to book any of your services and the price of that service will be covered by this package.')
            setGuardrailButton('Continue')
            setGuardrailType('servicesSelection')
            setShowGuardrail(true)
            return
        }
        else if (selected === 'some') {
            setShowSelectServicesModal(true)
        }

        setServicesSelection(selected)
    }

    const servicesSelectionDone = (enabledServices: EnabledServiceSelection[]) => {
        if (enabledServices.length === 0) {
            setServicesSelection('')
        }
        setLimitedServices(enabledServices)
        setShowSelectServicesModal(false)
    }

    const onSave = () => {
        if (formSending) return

        if (!name || !sessionCount || !limitBy || !servicesSelection || !expiresAfter) return

        const item = new PSItem('package')
        if (forOnboarding)
            item.setData(state.item)
        item.name = name
        item.image = image
        item.rate = price
        item.deposit = price
        item.memo = description
        item.allow_salestax = salesTax
        item.is_public = online
        if (limitBy === 'session') {
            item.capacity_type = 'session'
            item.capacity = sessionCount.value
        }
        else if (limitBy === 'duration') {
            item.capacity_type = 'duration'
            item.capacity = sessionCount.value
        }
        item.package_all = (servicesSelection === 'all')
        if (servicesSelection === 'some' && !forOnboarding) {
            item.components = limitedServices.map((serviceSelection) => (
                {
                    component: serviceSelection.id,
                    capacity: Number(serviceSelection.count) > 0 ? serviceSelection.count.toString() : '',
                }
            ))
        }
        if (expiresAfter)
            item.expires = (expiresAfter as SelectOption).value
        if (discount)
            item.discount = (discount as SelectOption).value
        if (form)
            item.form = form.id
        if (contract)
            item.contract = contract.id
        if (params.packageID && params.action !== 'copy')
            item.id = params.packageID

        if (forOnboarding) {
            item.id = state.item.id
            dispatch(updateItem(item.toObject(), !!refreshData))
            history.go(-2)
            return
        }

        setFormSending(true)

        if (typeof photo === 'string') {
            dispatch(saveItem(item))
        }
        else {
            dispatch(saveItem(item, photo as File))
        }
    }

    const showAttachments = (contractsEnabled || formsEnabled) &&
        (state.loadingAttachments || state.contracts.length > 0 || state.forms.length > 0)

    // Update limited service description when it changes
    const [limitedServicesDescription] = usePackageComponent(limitBy, limitedServices)

    const showDeleteButton = params.packageID && params.action !== 'copy'

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Package</h1>
                {showDeleteButton && !forOnboarding && (
                    <PSButtonDanger style={{height: '40px'}}
                                    onClick={() => setShowDeleteConfirmation(true)}>
                        Delete package
                    </PSButtonDanger>
                )}
                <PSButtonPrimary style={{height: '40px', marginLeft: showDeleteButton ? '16px' : 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!saveEnabled || formSending}>
                    {formSending && <Dots />}
                    {!formSending && "Save"}
                </PSButtonPrimary>
            </div>

            <div className="form">
                {!forOnboarding && (
                    <SettingsPhotoField photo={photo}
                                        selectPhoto={selectPhoto}
                                        selectedPhoto={selectedPhoto}
                                        addPhotoRef={addPhotoRef}
                                        title="Package" />
                )}

                <Field className="field">
                    <Label className="label">Name (required)</Label>
                    <Input placeholder="Package name"
                           value={name}
                           disabled={state.isSending}
                           onChange={e => setName(e.target.value)} />
                </Field>

                <Field className="field">
                    <Label className="label">Price · $</Label>
                    <Input placeholder="Package price"
                           value={price}
                           disabled={state.isSending}
                           onChange={e => filterMonetaryInput(e.target.value, setPrice)} />
                </Field>

                {discountEnabled && (
                    <PSDropdown selected={discount}
                                nameProperty="label"
                                disabled={state.isSending}
                                onSelect={(selection) => setDiscount(selection)}
                                label="Discount rate"
                                placeholder={<div className="grey">Select discount rate</div>}>
                        <>
                            {discountOptions.map(option => (
                                <Item key={`discount-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                )}

                {salesTaxEnabled && (
                    <Field className="field">
                        <Checkbox checked={salesTax}
                                  disabled={state.isSending}
                                  onChange={e => setSalesTax(e.target.checked)}>
                            <Label className="withHint">Allow sales tax</Label>
                            <Hint>Automatically calculate & apply sales tax</Hint>
                        </Checkbox>
                    </Field>
                )}

                <Field className="field">
                    <Label className="label">Description</Label>
                    <PSTextarea placeholder="Package description"
                                className="input"
                                minRows={8}
                                maxLength={1500}
                                disabled={state.isSending}
                                value={description}
                                onChange={value => setDescription(value)}/>
                </Field>

                <h2>Limit usage</h2>

                <Dropdown selectedItem={sessionCount}
                          onSelect={(selection) => setSessionCount(selection)}
                          downshiftProps={{ itemToString: (item: any) => item && item['label']}}>
                    <DropdownField className="field">
                        <Label className="label">Sessions (required)</Label>
                        <Hint className="hint">Limit the number of sessions or hours covered by this subscription</Hint>
                        <div style={{display: 'flex'}}>
                            <Select style={{width: 292}} className="input" disabled={state.isSending}>
                                {sessionCount && sessionCount.label}
                                {!sessionCount && (<div className="grey">Select number</div>)}
                            </Select>
                            <PSToggleButton isPressed={limitBy === 'session'}
                                            onClick={() => setLimitBy('session')}
                                            disabled={state.isSending}>
                                Sessions
                            </PSToggleButton>
                            <PSToggleButton isPressed={limitBy === 'duration'}
                                            onClick={() => setLimitBy('duration')}
                                            disabled={state.isSending}>
                                Hours
                            </PSToggleButton>
                        </div>
                        <div className="buttonLink brightBlue"
                             style={{display: 'inline-block', paddingTop: 8}}
                             onClick={() => showLearnMore('packages', true)}>
                            Learn more &gt;
                        </div>
                    </DropdownField>
                    <Menu>
                        <Item value="">Select number</Item>
                        {limitBy !== 'duration' && sessionCountOptions.map((option) => (
                            <Item key={`sessioncount-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                        {limitBy === 'duration' && sessionHourOptions.map((option) => (
                            <Item key={`sessionduration-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </Menu>
                </Dropdown>

                {!forOnboarding && (
                    <>
                        <Field className="field">
                            <Label className="label">Services (required)</Label>
                            <Hint className="hint">Select the items this package will cover</Hint>
                        </Field>
                        <Field className="field" style={{marginTop: 12}}>
                            <Radio name="services-selection"
                                   value="all"
                                   checked={servicesSelection === 'all'}
                                   onChange={event => {
                                       changeServicesSelection(event.target.value)
                                   }}>
                                <Label style={{color: '#000000', fontWeight: 400}}>All services</Label>
                            </Radio>
                        </Field>
                        <Field className="field" style={{marginTop: 8}}>
                            <Radio name="services-selection"
                                   value="some"
                                   checked={servicesSelection === 'some'}
                                   onChange={event => changeServicesSelection(event.target.value)}>
                                <Label style={{color: '#000000', fontWeight: 400}}>Some services</Label>
                            </Radio>
                        </Field>
                        {limitedServices.length > 0 && (
                            <div style={{paddingLeft: 20, paddingTop: 4}}>
                                <div style={{color: '#c4c4c4', fontSize: '13px', lineHeight: '16px', paddingTop: 4}}>
                                    {limitedServicesDescription}
                                </div>
                                <div className="buttonLink"
                                     style={{display: 'inline-block', paddingTop: 8, color: '#314A68'}}
                                     onClick={() => setShowSelectServicesModal(true)}>
                                    Edit services &gt;
                                </div>
                            </div>
                        )}
                    </>
                )}
                <h2>Details</h2>

                <PSDropdown selected={expiresAfter}
                            nameProperty="label"
                            disabled={state.isSending}
                            onSelect={(selection) => setExpiresAfter(selection)}
                            label="Expires after (required)"
                            placeholder={<div className="grey">Select expiration</div>}>
                    <>
                        {sessionExpireOptions.map(option => (
                            <Item key={`expires-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>

                {onlineBookingEnabled && (
                    <Field className="field">
                        <Checkbox checked={online}
                                  disabled={state.isSending}
                                  onChange={(e) => { setOnline(e.target.checked)}}>
                            <Label className="withHint">Show online</Label>
                            <Hint>Allow clients to purchase this package online</Hint>
                        </Checkbox>
                    </Field>
                )}

                {showAttachments && <h2>Attachments</h2>}

                {state.loadingAttachments && (
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                )}
                {contractsEnabled && !state.loadingAttachments && state.contracts.length > 0 && (
                    <PSDropdown selected={contract}
                                disabled={state.isSending}
                                nameProperty="name"
                                onSelect={(selection) => setContract(selection)}
                                label="Attach a contract"
                                hint="This contract will be signed when a client purchases this package"
                                onLearnMore={() => showLearnMore('contracts', true)}
                                placeholder={<div className="grey">Select contract</div>}>
                        <>
                            {state.contracts.map(option => (
                                <Item key={`contract-${option.id}`} value={option}>
                                    {option.name}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                )}
                {formsEnabled && !state.loadingAttachments && state.forms.length > 0 && (
                    <PSDropdown selected={form}
                                disabled={state.isSending}
                                nameProperty="name"
                                onSelect={(selection) => setForm(selection)}
                                label="Attach a form"
                                hint="This form will be completed when a client purchases this package"
                                onLearnMore={() => showLearnMore('forms', true)}
                                placeholder={<div className="grey">Select form</div>}>
                        <>
                            {state.forms.map(option => (
                                <Item key={`form-${option.id}`} value={option}>
                                    {option.name}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                )}
            </div>
            {showGuardrail && (
                <GuardrailModal title={guardrailTitle}
                                body={guardrailBody}
                                buttonText={guardrailButton}
                                onClose={() => setShowGuardrail(false)}
                                onAction={guardrailAction} />
            )}
            {learnMoreVisible && (
                <Modal onClose={() => showLearnMore('', false)}>
                    <Header>{learnMoreTitle}</Header>
                    <Body>{learnMoreBody}</Body>
                    <Close aria-label="Close modal" />
                </Modal>
            )}
            {showSelectServicesModal && (
                <SelectServicesModal onClose={() => servicesSelectionDone(limitedServices)}
                                     onDone={servicesSelectionDone}
                                     currentServices={limitedServices} />
            )}
            {showDeleteConfirmation && (
                <GuardrailModal title="Are you sure?"
                                body="Are you sure you'd like to delete this item?"
                                buttonText="Delete"
                                onClose={() => setShowDeleteConfirmation(false)}
                                onAction={() => dispatch(deleteItem(params.packageID, 'packages'))}
                />
            )}
            <Prompt
                when={hasEdits && !formSending}
                message="Are you sure you'd like to leave this page without saving your changes?" />
        </div>
    )
}