import React, {useEffect, useState} from "react";
import {useAppDispatch, usePSOwner} from "../../../hooks";
import {
    clearCategory,
    deleteCategory,
    fetchCategory,
    fetchItems,
    saveCategory,
    SettingsState
} from "../../../modules/settings";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {SelectOption} from "../Options";
import {PSButtonDanger, PSButtonPrimary} from "../../app/PSButton";
import {Dots} from "@zendeskgarden/react-loaders";
import {usePhotoUpload} from "../hooks";
import {Checkbox, Field, Hint, Input, Label,} from "@zendeskgarden/react-forms";
import {PSMultiselect} from "../../app/PSMultiselect";
import {Item} from "@zendeskgarden/react-dropdowns";
import {Category} from "../../../models/Category";
import {Prompt, useHistory, useParams} from "react-router";
import findIndex from "lodash.findindex";
import {SettingsPhotoField} from "../SettingsPhotoField";
import {GuardrailModal} from "../GuardrailModal";
import {PSTextarea} from "../../app/PSTextarea";
import {updateCategory} from "../../../modules/onboarding";

type Props = {
    forOnboarding?: boolean,
}

export const CategoryEdit = ({forOnboarding}: Props) => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const history = useHistory()

    const [photo, setPhoto] = useState<undefined | string | File>('')
    const [image, setImage] = useState<undefined | string>('') // For editing
    const [name, setName] = useState('')
    const [items, setItems] = useState<SelectOption[] | never[]>([])
    const [description, setDescription] = useState('')
    const [online, setOnline] = useState(true)
    const [saveEnabled, setSaveEnabled] = useState(false)
    const [formSending, setFormSending] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)

    const [addPhotoRef, selectPhoto, selectedPhoto] = usePhotoUpload({disabled: state.isSending, setPhoto})
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    const [onlineBookingEnabled, setOnlineBookingEnabled] = useState(false)
    const owner = usePSOwner()
    useEffect(() => {
        if (!owner) return
        setOnlineBookingEnabled(owner.allow_widget)
    }, [owner])

    // Load items for the multiselect dropdown
    useEffect(() => {
        if (forOnboarding) return
        dispatch(fetchItems())
    }, [dispatch, forOnboarding])

    // Convert the item list into SelectOptions, that can be selected
    const [selectItems, setSelectItems] = useState<SelectOption[]>([])
    useEffect(() => {
        // Create selection array from items
        const itemSelect: SelectOption[] = []
        state.items.forEach((item) => {
            if (!item.id) return
            itemSelect.push({ label: item.name, value: item.id })
        })
        setSelectItems(itemSelect)
    }, [state.items])

    // Load the category if this is an edit, otherwise treat it as a create
    const params: {categoryID?: string} = useParams()
    useEffect(() => {
        if (forOnboarding) return

        if (!params.categoryID) {
            dispatch(clearCategory())
            return
        }
        dispatch(fetchCategory(params.categoryID))
    }, [dispatch, params.categoryID, forOnboarding])

    // Set the fields if we have a category loaded
    useEffect(() =>{
        if (!state.category.id) return

        setName(state.category.name)
        setPhoto(state.category.image_uri)
        setImage(state.category.image)
        setDescription(state.category.memo)
        setOnline(state.category.is_public)

        const items =  state.category.listings.map((itemId) => {
            const index = findIndex(selectItems, (i: SelectOption) => i.value === itemId)
            if (index > -1) {
                return selectItems[index]
            }
            return { value: '', label: ''}
        }).filter(i => i.value)
        setItems(items)
    }, [state.category, selectItems])

    useEffect(() => {
        setSaveEnabled(!!name)
    }, [name])

    useEffect(() => {
        if (forOnboarding) {
            setHasEdits(false)
            return
        }

        let changed = 0
        if ((state.item.name || name) && name !== state.item.name)
            changed = 1
        else if ((state.item.memo || description) && description !== state.item.memo)
            changed = 3
        else if (typeof photo === 'object')
            changed = 13
        // TODO items, online
        setHasEdits(changed > 0)
    }, [
        state.item, name, description, online, photo, forOnboarding
    ])

    const updateItems = (selection: any[]) => {
        setItems(selection)
    }

    const onSave = () => {
        if (formSending) return

        if (!name) return

        const category = new Category(forOnboarding ? state.category : {})
        category.type = "service"
        category.name = name
        category.image = image
        category.memo = description
        category.is_public = online
        if (!forOnboarding) {
            category.listings = items.map((i: SelectOption) => i.value)
        }

        if (params.categoryID) {
            category.id = params.categoryID
        }

        if (forOnboarding) {
            category.id = state.category.id
            category.image = undefined
            dispatch(updateCategory(category, false))
            history.go(-1)
            return
        }

        setFormSending(true)

        if (typeof photo === 'string') {
            dispatch(saveCategory(category))
        }
        else {
            dispatch(saveCategory(category, photo as File))
        }
    }

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Category</h1>
                {params.categoryID && (
                    <PSButtonDanger style={{height: '40px'}}
                                    onClick={() => setShowDeleteConfirmation(true)}>
                        Delete category
                    </PSButtonDanger>
                )}
                <PSButtonPrimary style={{height: '40px', marginLeft: params.categoryID ? '16px' : 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!saveEnabled || formSending}>
                    {formSending && <Dots />}
                    {!formSending && "Save"}
                </PSButtonPrimary>
            </div>

            <div className="form">
                {!forOnboarding && (
                    <SettingsPhotoField photo={photo}
                                        selectPhoto={selectPhoto}
                                        selectedPhoto={selectedPhoto}
                                        addPhotoRef={addPhotoRef}
                                        title="Category" />
                )}

                <Field className="field">
                    <Label className="label">Name (required)</Label>
                    <Input placeholder="Category name"
                           value={name}
                           disabled={state.isSending}
                           onChange={e => setName(e.target.value)} />
                </Field>

                {!forOnboarding && (
                    <PSMultiselect selected={items}
                                   nameProperty="label"
                                   disabled={state.isSending}
                                   onSelect={(selection) => updateItems(selection)}
                                   label="Items"
                                   placeholder="Select items">
                        <>
                            {selectItems.map((option: SelectOption) => (
                                <Item key={`dow-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSMultiselect>
                )}

                <Field className="field">
                    <Label className="label">Description</Label>
                    <PSTextarea placeholder="Category description"
                                className="input"
                                minRows={8}
                                maxLength={999}
                                disabled={state.isSending}
                                value={description}
                                onChange={value => setDescription(value)}/>
                </Field>

                {onlineBookingEnabled && (
                    <Field className="field">
                        <Checkbox checked={online}
                                  disabled={state.isSending}
                                  onChange={(e) => { setOnline(e.target.checked)}}>
                            <Label className="withHint">Show online</Label>
                            <Hint>Allow clients to purchase this service online</Hint>
                        </Checkbox>
                    </Field>
                )}
            </div>
            {showDeleteConfirmation && (
                <GuardrailModal title="Are you sure?"
                                body="Are you sure you'd like to delete this category?"
                                buttonText="Delete"
                                onClose={() => setShowDeleteConfirmation(false)}
                                onAction={() => dispatch(deleteCategory(params.categoryID!))}
                />
            )}
            <Prompt
                when={hasEdits && !formSending}
                message="Are you sure you'd like to leave this page without saving your changes?" />
        </div>
    )
}