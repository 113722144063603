import React, { useState } from "react";
import {Dropdown, Menu, Trigger} from "@zendeskgarden/react-dropdowns";
import { PSButtonPrimary } from "./PSButton";
import { Button } from "@zendeskgarden/react-buttons";
import { ReactComponent as ChevronIcon } from "@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg";

type Props = {
    onSelect: (s: any) => void,
    label?: string | JSX.Element,
    children: React.ReactNode,
    primary: boolean,
    disabled?: boolean,
}

export const PSButtonDropdown = ({ children, primary, disabled, label, onSelect }: Props) => {
    const [dropdownRotate, setDropdownRotated] = useState(false)

    return (
        <Dropdown
            onSelect={item => onSelect(item)}
            onStateChange={options => options.hasOwnProperty('isOpen') && setDropdownRotated(options.isOpen ?? false)}
        >
            <Trigger>
                <PSButtonPrimary style={{height: '40px'}} onClick={() => {}} disabled={disabled}>
                    {label}
                    <Button.EndIcon isRotated={dropdownRotate}>
                        <ChevronIcon />
                    </Button.EndIcon>
                </PSButtonPrimary>
            </Trigger>
            <Menu className="menu">
                {children}
            </Menu>
        </Dropdown>
    )
}