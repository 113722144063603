import React, {useState} from "react";
import {AppHeader} from "../app/AppHeader";
import {Spinner} from "@zendeskgarden/react-loaders";
import {useAppDispatch, usePSUser} from "../../hooks";
import {RootState} from "../../store";
import {useSelector} from "react-redux";
import {push} from "connected-react-router";
import {setOnboardingState} from "../../modules/onboarding";

export const OnboardingLocation = () => {
    const dispatch = useAppDispatch()

    const [isSending, setIsSending] = useState(false)

    const skipLocation = () => {
        setIsSending(true)
        // @ts-ignore
        dispatch(setOnboardingState('ob_location')).then(() => setIsSending(false))
    }

    return (
        <AppHeader title="Setup" showBackButton middleWidget={null}>
            {isSending && (
                <div className="loadingOverlay">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                        <h1>Saving...</h1>
                    </div>
                </div>
            )}
            <div className="scheduleClient servicesSettings onboardingItems">
                <div className="header">
                    <h1>Where do you schedule clients?</h1>
                </div>

                <div className="separator" />

                <div className="form">
                    <div className="serviceCard onboardingCard">
                        <div className="details">
                            <div className="name">At my location</div>
                            <div className="rate">Clients will be shown my location when booking</div>
                        </div>
                        <div className="edit">
                            <div className="buttonLink brightBlue"
                                 onClick={() => {
                                     dispatch(push('/onboarding/location/edit', {vendorLocation: true}))
                                 }}
                            >
                                Select
                            </div>
                        </div>
                    </div>

                    <div className="serviceCard onboardingCard">
                        <div className="details">
                            <div className="name">Client selects the location</div>
                            <div className="rate">Clients will be asked to supply a location when booking</div>
                        </div>
                        <div className="edit">
                            <div className="buttonLink brightBlue"
                                 onClick={() => {
                                     dispatch(push('/onboarding/location/edit', {vendorLocation: false}))
                                 }}
                            >
                                Select
                            </div>
                        </div>
                    </div>

                    <div className="serviceCard onboardingCard">
                        <div className="details">
                            <div className="name">Virtually</div>
                            <div className="rate">Add a PocketSuite video conference link to appointments</div>
                        </div>
                        <div className="edit">
                            <div className="buttonLink brightBlue"
                                 onClick={() => skipLocation()}
                            >
                                Select
                            </div>
                        </div>
                    </div>

                    <div className="serviceCard onboardingCard">
                        <div className="details">
                            <div className="name">It's a mix</div>
                            <div className="rate">My appointment locations vary</div>
                        </div>
                        <div className="edit">
                            <div className="buttonLink brightBlue"
                                 onClick={() => skipLocation()}
                            >
                                Select
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppHeader>
    )
}
