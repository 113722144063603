import { apiClient, generateCancelToken } from './apiclient'
import { getConversation } from './conversation'
import keyBy from 'lodash.keyby'
import moment from 'moment'
import debounce from 'lodash.debounce'
import axios from 'axios'
import { processError } from './error'

let cancelToken

const initialState = {
    isSending: true,
    isMessageSending: false,
    inbox: [],
    error: null,
    unreadCount: 0,
    // Inbox search
    searchText: '', // the actual query, it sets the InboxSearch value
    isSearching: false, // search is running
    didSearch: false, // set to true when we debounce the input and send it to the server
    searchResults: [],
    // Inbox pagination
    pagination: {
        isScrolling: false,
        hasMore: true,
        timestamp: '',
    }
}

export default function inboxReducer(state = initialState, action) {
    switch (action.type) {
        case 'messages/REQUEST':
            return {
                ...state,
                isSending: action.sending,
                pagination: {
                    ...state.pagination,
                    isScrolling: action.scrolling,
                }
            }
        case 'messages/INBOX_LOADED':
            let lastMessageTimestamp
            if (action.inbox.length) {
                lastMessageTimestamp = action.inbox[action.inbox.length-1].thread_last_modified_date
            }
            const hasMore = action.inbox.length > 0
            return {
                ...state,
                inbox: state.inbox.concat(action.inbox),
                pagination: {
                    timestamp: lastMessageTimestamp,
                    hasMore: hasMore,
                    isScrolling: false
                },
            }
        case 'messages/INBOX_UPDATE':
        case 'conversation/NEW_MESSAGE':
            let newInbox = [ ...state.inbox ]
            const inboxIndex = newInbox.findIndex((i) => {
                if (action.isGroupMessage)
                    return i.team === action.message.team
                else
                    return i.person === action.message.person
            })
            if (inboxIndex === -1) {
                newInbox.unshift(action.message)
            }
            else {
                // Remove this item
                newInbox.splice(inboxIndex, 1)
                // Add it to the front, with the new message, since it was most recently updated
                newInbox.unshift(action.message)
            }
            // Sort by created_date
            newInbox = newInbox.sort((a, b) => moment(a.created_date).isBefore(moment(b.created_date)))
            return {
                ...state,
                inbox: newInbox,
            }
        case 'messages/SET_UNREAD_COUNT':
            return {
                ...state,
                unreadCount: Math.min(99, action.count),
            }
        case 'inbox/SET_SEARCHING':
            return {
                ...state,
                searchText: action.query || state.searchText,
                isSearching: action.searching,
            }
        case 'inbox/CLEAR_SEARCH_RESULT':
            return {
                ...state,
                isSearching: false,
                searchText: '',
                didSearch: false,
                searchResults: [],
            }
        case 'inbox/SET_SEARCH_RESULT':
            return {
                ...state,
                didSearch: true,
                searchResults: action.userResults,
            }
        default:
            return state
    }
}

function messagesRequest(sending, scrolling = false) {
    return { type: 'messages/REQUEST', sending, scrolling }
}

function inboxLoaded(inbox, pagination) {
    return { type: 'messages/INBOX_LOADED', inbox, pagination }
}

export const inboxLastMessageUpdate = (userId, message, isGroupMessage = false) => {
    return { type: 'messages/INBOX_UPDATE', userId, message, isGroupMessage }
}

export const getInbox = (timestamp = '', userId, cancelToken) => {
    return (dispatch, getState) => {
        dispatch(messagesRequest(true))

        const data = {
            ts: timestamp || undefined,
            limit: 50,
        }

        return apiClient.post('/message/inbox', data, {cancelToken})
            .then(resp => resp.data)
            .then((json) => {
                // Make sure contacts are up to date
                const contactsMap = keyBy(json.contacts, 'id')
                dispatch({type: 'entities/UPDATE', name: 'contacts', map: contactsMap})

                const teamsMap = keyBy(json.teams, 'id')
                dispatch({type: 'entities/UPDATE', name: 'teams', map: teamsMap})

                const { conversation } = getState()
                if (userId && conversation.currentThread !== userId) {
                    dispatch(getConversation(userId))
                }
                dispatch(inboxLoaded(json.messages, json.pagination))
            })
            .catch(error => {
                if (!axios.isCancel(error)) {
                    dispatch(processError(error))
                    return Promise.reject()
                }
            })
            .finally(() => {
                dispatch(messagesRequest(false))
            })
    }
}

export const setUnreadCount = (count) => {
    return { type: 'messages/SET_UNREAD_COUNT', count }
}

export const getUnreadCount = () => {
    return dispatch => {
        // If the user is not logged in, don't mess with this
        if (localStorage.getItem('loggedIn') !== '1') return

        return apiClient.get('/inbox/sync/unread')
            .then(resp => resp.data)
            .then(json => {
                dispatch(setUnreadCount(json.count))
            })
            .catch(error => dispatch(processError(error)))
    }
}

const setSearching = (searching, query) => {
    return { type: 'inbox/SET_SEARCHING', searching, query }
}

const setSearchResult = (userResults) => {
    return { type: 'inbox/SET_SEARCH_RESULT', userResults }
}

export const clearSearch = () => {
    if (cancelToken) {
        performSearchDebounced.cancel()
        cancelToken.cancel('clearSearch')
        cancelToken = null
    }

    return { type: 'inbox/CLEAR_SEARCH_RESULT' }
}

const performSearchDebounced = debounce((dispatch, query) => {
    dispatch(setSearching(true))

    if (cancelToken) {
        cancelToken.cancel('stop messageSearch')
        cancelToken = null
    }

    cancelToken = generateCancelToken()

    const data = {keywords: query}
    apiClient.post('/message/search', data, {cancelToken: cancelToken.token})
        .then(resp => resp.data)
        .then(json => {
            const indexOfUsers = []
            for (let i = 0; i < json.types.length; i++) {
                if (json.types[i] === 'user') {
                    indexOfUsers.push(i)
                }
            }

            const users = []
            for (let i = 0; i < indexOfUsers.length; i++) {
                users.push(json.results[indexOfUsers[i]])
            }

            // Update the entities map so if they click the search result, we have
            // the user entity ready to be queried
            const userMap = keyBy(users, 'id')
            dispatch({type: 'entities/UPDATE', name: 'contacts', map: userMap})

            dispatch(setSearchResult(users))
            dispatch(setSearching(false))
        })
        .catch(error => {
            if (!axios.isCancel(error)) {
                dispatch(processError(error))
                dispatch(setSearching(false))
                return Promise.reject()
            }
        })
}, 250, {trailing: true})

export const searchInbox = (query) => {
    return dispatch => {
        dispatch(setSearching(true, query))

        performSearchDebounced(dispatch, query)
    }
}
