import React, {useEffect, useState} from "react";
import { useAppDispatch } from "../../../hooks";
import { fetchFeatures, SettingsState } from "../../../modules/settings";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import localforage from "localforage";
import {ReactComponent as ClassesIcon} from "../../../icons/ps_settings_classes.svg";
import {ReactComponent as GraphIcon} from "../../../icons/ps_graph_upward_trend.svg";
import {ReactComponent as WandIcon} from "../../../icons/ps_general_magic_wand.svg";
import {Spinner} from "@zendeskgarden/react-loaders";
import {TutorialVideoModal} from "./TutorialVideoModal";
import {BOOK_HOST} from "../../../utils/constants";
import {TutorialCard} from "./TutorialCard";
import {Feature, featureEnabled, featureName} from "../../../models/Feature";
import {UserInstance} from "../../../models/User";
import { GuidedSetupCard, GuidedSetupRowType } from "./GuidedSetupCard";
import { PSButton } from "../../app/PSButton";
import { ButtonGroup } from "@zendeskgarden/react-buttons";
import { apiClient } from "../../../modules/apiclient";
import { processError } from "../../../modules/error";

export const TutorialsList = () => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const [user, setUser] = useState<UserInstance>()
    const [industries, setIndustries] = useState<any[]>()
    const [industry, setIndustry] = useState<any>()

    const [showVideoModal, setShowVideoModal] = useState(false)
    const [selectedFeatureID, setSelectedFeatureID] = useState('')

    const [videoTitle, setVideoTitle] = useState('Overview')

    const [view, setView] = useState<'guided_setup' | 'tutorial'>('guided_setup')
    const [tutorialsList, setTutorialsList] = useState<any[]>()
    const [guidedSetupList, setGuidedSetupList] = useState<GuidedSetupRowType[]>()
    const [loading, setLoading] = useState(false)

    // return a callback that opens the guided setup
    const openGuidedSetup = (id: string) => {
        return () => {
            setLoading(true)
            apiClient.post(`/tutorial/${id}`)
                .then(resp => resp.data)
                .then(json => {
                    setLoading(false)

                    window.open(json.tutorial_uri, "_blank")
                })
                .catch((error) => {
                    setLoading(false)

                    dispatch(processError(error))
                    return Promise.reject()
                })
        }
    }

    const changeTopList = (v: 'guided_setup' | 'tutorial') => {
        setView(v)
    }

    useEffect(() => {
        if (!dispatch) return
        localforage.getItem('user').then((user: any) => setUser(user))
        localforage.getItem('industries')
          .then((industries: any) => setIndustries(industries))
        dispatch(fetchFeatures())
    }, [dispatch])

    useEffect(() => {
        if (!user) return
        if (!industries) return

        industries.forEach((industry) => {
            if (industry.id === user.industry) {
                setIndustry(industry)
            }
        })
    }, [user, industries])

    useEffect(() => {
        if (!state.features || !state.features.length) return
        if (!user) return
        if (!industry) return

        const top: Partial<Feature>[] = []
        const features: Partial<Feature>[] = []
        const defaultEnabledFeatures: string[] = industry.features.split(',')

        top.push({id: 'overview'}, {id:'setup'}, {id: 'conference'})

        for (let i = 0; i < state.features.length; i++) {
            const feature = state.features[i]
            const enabled = featureEnabled(feature.id, user, industry)
            if (enabled) {
                if (user.type === 'payee' && (feature.id === 'benchmark' || feature.id === 'staffing' || feature.id === 'trigger')) {
                    continue;
                }
                else if (industryDefaultOnFeature(feature.id, defaultEnabledFeatures)) {
                    top.push(feature)
                    if (feature.id === 'contract')
                        top.push({id: 'imports'})
                }
                else {
                    features.push(feature)
                    if (feature.id === 'contract')
                        features.push({id: 'imports'})
                }
            }
            else if (industryDefaultOnFeature(feature.id, defaultEnabledFeatures)) {
                features.push(feature)
            }
        }

        setTutorialsList([...top, ...features])

    }, [state.features, user, industry])

    useEffect(() => {
        const setupList = []
        setupList.push({
            key: "1",
            icon: <ClassesIcon />,
            title: "Welcome to PocketSuite",
            subtitle: "Learn where to find powerful PocketSuite features, tutorial videos & how-to articles, and live support.",
            onClick: openGuidedSetup('guided_setup_welcome')
        } as GuidedSetupRowType)
        setupList.push({
            key: "2",
            icon: <WandIcon />,
            title: "PocketSuite Foundations",
            subtitle: "Settings, scheduling, online booking, client fields, forms, contracts, cancellations, refunds & more!",
            onClick: openGuidedSetup('guided_setup_foundations')
        } as GuidedSetupRowType)
        setupList.push({
            key: "3",
            icon: <GraphIcon />,
            title: "PocketSuite growth lab",
            subtitle: "Packages, subscriptions, gift certificates, smart campaigns, suite link & more!",
            onClick: openGuidedSetup('guided_setup_growthlab')
        } as GuidedSetupRowType)
        setGuidedSetupList(setupList)
    }, [])

    const industryDefaultOnFeature = (feature: string, defaultEnabledFeatures: string[]) => {
        return defaultEnabledFeatures.some((enabledFeature) => {
            return feature === enabledFeature
        })
    }

    const onView = (feature: Feature) => {
        setVideoTitle(featureName(feature))
        setShowVideoModal(true)
        setSelectedFeatureID(feature.id)
    }

    if (!tutorialsList || !guidedSetupList || loading)
    {
        return (
            <div className="servicesSettings">
                <div className="header">
                    <h1>Tutorials</h1>
                </div>
                <div style={{textAlign: 'center', padding: '128px 0'}}>
                    <Spinner size="128" color="#314A68" />
                </div>
            </div>
        )
    }

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Tutorials</h1>
                <div className="right">
                    <ButtonGroup className="groupButtonGroup"
                                    onSelect={changeTopList}
                                    selectedItem={view}>
                            <PSButton size="small" value="guided_setup">Guided Setup</PSButton>
                            <PSButton size="small" value="tutorial" style={{marginLeft: '-1px'}}>Tutorials</PSButton>
                        </ButtonGroup>
                </div>
            </div>
            <div className="separator" />
            <div>
                {view === "guided_setup" && guidedSetupList
                    .map((item: GuidedSetupRowType) =>
                        <GuidedSetupCard
                            key={`guided-setup-${item.key}`}
                            item={item}/>
                )}
                {view === "tutorial" && tutorialsList
                    .map((feature: Feature) =>
                        <TutorialCard
                            key={`feature-${feature.id}`}
                            feature={feature}
                            onView={() => {onView(feature)}} />
                )}
            </div>
            {showVideoModal && (
                <TutorialVideoModal
                    onClose={() => setShowVideoModal(false)}
                    featureID={selectedFeatureID}
                    title={videoTitle}
                />
            )}
        </div>
    )
}