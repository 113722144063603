import React, {useCallback, useEffect, useState} from "react";
import {useAppDispatch, usePSOwner} from "../../hooks";
import {fetchItems, moveItem, SettingsState} from "../../modules/settings";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {Item} from "../../models/Item";
import {SortableItemCard} from "./SortableItemCard";
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult
} from "react-beautiful-dnd";

export const SettingsSort = () => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    useEffect(() => {
        dispatch(fetchItems(null, false, true))
    }, [dispatch])

    const [sortedItems, setSortedItems] = useState<Item[]>([])
    useEffect(() => {
        setSortedItems(
            state.items.sort((a, b) => a.seqnum < b.seqnum ? -1 : 1)
        )
    }, [state.items])

    const onDragEnd = useCallback((result: DropResult) => {
        if (!result.destination) return

        const target = sortedItems[result.destination.index]
        const [removed] = sortedItems.splice(result.source.index, 1)

        // Send to the server
        dispatch(moveItem(removed, target.seqnum))

        sortedItems.splice(result.destination.index, 0, removed)
        setSortedItems(sortedItems)
    }, [dispatch, sortedItems])

    const owner = usePSOwner()

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>{owner?.allow_product ? 'Sort services & products' : 'Sort services'}</h1>
            </div>
            <div className="separator" />
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(droppableProps: any) => {
                        return (
                            <div ref={droppableProps.innerRef}>
                                {sortedItems.map((item: Item, index) => (
                                    <Draggable key={item.id}
                                               draggableId={item.id as string}
                                               index={index}>
                                        {(provided: any, snapshot: any) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={provided.draggableProps.style}
                                            >
                                                <SortableItemCard key={`item-${item.id}`}
                                                                  isDragging={snapshot.isDragging}
                                                                  item={item}
                                                                  type={item.type} />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            </div>
                        )
                    }}
                </Droppable>
            </DragDropContext>
        </div>
    )
}