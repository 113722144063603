import {Prompt, useHistory, useLocation, useParams} from "react-router";
import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../../hooks";
import {
    deleteLink,
    fetchLink,
    saveLink,
    SettingsState
} from "../../../../modules/settings";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {PSLink} from "../../../../models/PSLink";
import {PSButton, PSButtonDanger, PSButtonPrimary} from "../../../app/PSButton";
import {Dots, Spinner} from "@zendeskgarden/react-loaders";
import {Field, Input, Label} from "@zendeskgarden/react-forms";
import {DangerModal} from "../../DangerModal";
import {Result} from "../../../../utils";
import {ReactComponent as LeftArrow} from "../../../../icons/left-arrow.svg";

export const LinkEdit = () => {
    const dispatch = useAppDispatch()
    const settingsState: SettingsState = useSelector((state: RootState) => state.settings)
    const pageState: {linkData?: PSLink} = useLocation().state as {linkData?: PSLink}
    const params: any = useParams()
    const history = useHistory()

    const [pageLoading, setPageLoading] = useState<boolean>(true)
    const [existingLink, setExistingLink] = useState<boolean>(false)
    const [hasEdits, setHasEdits] = useState<boolean>(false)
    const [saving, setSaving] = useState<boolean>(false)
    const [deleting, setDeleting] = useState<boolean>(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false)
    const [labelText, setLabelText] = useState<string | undefined>()
    const [linkURL, setLinkURL] = useState<string | undefined>()
    const [editableURL, setEditableURL] = useState<boolean>(false)
    const [record, setRecord] = useState<PSLink | undefined>()

    useEffect(() => {
        if (params.linkID) {
            dispatch(fetchLink(params.linkID))
        }
    }, [dispatch, params.linkID])

    useEffect(() => {
        if (params.linkID) {
            if (Object.keys(settingsState.link).length && settingsState.link.id === params.linkID) {
                setRecord(new PSLink(settingsState.link))
                setExistingLink(true)
                setPageLoading(false)
            }
        }
        else if (pageState?.linkData) {
            setRecord(new PSLink(pageState.linkData))
            setPageLoading(false)
        }
        else {
            history.goBack()
        }
    }, [history, params.linkID, settingsState.link, pageState?.linkData])

    useEffect(() => {
        if (!record) return

        setLabelText(record.label)
        if (record.type === "custom") {
            setLinkURL(record.url)
            setEditableURL(true)
        }
        else {
            setLinkURL(record.typeString())
        }
        setPageLoading(false)
    }, [record])

    useEffect(() => {
        if (!record) return

        setHasEdits(
            !existingLink ||
            record.label !== labelText ||
            (record.type === "custom" && record.url !== linkURL)
        )
    }, [existingLink, record, labelText, linkURL])

    const onSave = () => {
        if (!record) return

        if (labelText)
            record.label = labelText
        if (record.type === "custom")
            record.url = linkURL

        setSaving(true)
        Result(dispatch(saveLink(record)))
            .finally(() => setSaving(false))
    }

    const onDelete = () => {
        if (!(record && record.id)) return

        setDeleting(true);
        Result(dispatch(deleteLink(record.id)))
            .finally(() => setDeleting(false))
    }


    return (
        <div className="servicesSettings">
            <div className="header">
                <PSButton style={{marginLeft: 0, marginRight: 16}}
                          onClick={() => history.goBack()}
                >
                    <LeftArrow />
                </PSButton>
                <h1>Button</h1>
                {existingLink && (
                    <PSButtonDanger style={{height: '40px'}}
                                    onClick={() => setShowDeleteConfirmation(true)}
                                    disabled={pageLoading || deleting || saving}>
                        {deleting && <Dots />}
                        {!deleting && "Delete"}
                    </PSButtonDanger>
                )}
                <PSButtonPrimary style={{height: '40px', marginLeft: existingLink ? '16px' : 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={pageLoading || !hasEdits || deleting || saving}>
                    {saving && <Dots />}
                    {!saving && "Save"}
                </PSButtonPrimary>
            </div>
            {pageLoading &&
                <div style={{textAlign: 'center', padding: '128px 0'}}>
                    <Spinner size="128" color="#314A68" />
                </div>
            }
            {!pageLoading &&
                <div className="form">
                    <Field className="field">
                        <Label className="label">Label (required)</Label>
                        <Input type="text"
                               value={labelText}
                               onChange={e => setLabelText(e.target.value)}
                               placeholder="Enter text"/>
                    </Field>
                    <Field className="field">
                        <Label className="label">Link (required)</Label>
                        <Input type="text"
                               value={linkURL}
                               onChange={e => setLinkURL(e.target.value)}
                               placeholder="Enter text"
                               disabled={!editableURL}/>
                    </Field>
                </div>
            }
            {showDeleteConfirmation && (
                <DangerModal title="Confirm change"
                             body="Confirm that you'd like to delete this suite link button."
                             buttonText="Delete"
                             disableDismiss={true}
                             onClose={() => {
                                 setShowDeleteConfirmation(false);
                             }}
                             onAction={() => onDelete()}
                />
            )}
            <Prompt
                when={hasEdits && !settingsState.isSending}
                message="Are you sure you'd like to leave this page without saving your changes?" />
        </div>
    )
}