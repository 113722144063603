import React, {useEffect, useState} from "react"
import {AppHeader} from "../app/AppHeader";
import {Spinner} from "@zendeskgarden/react-loaders";
import {PSButtonPrimary} from "../app/PSButton";
import {useAppDispatch, usePSUser} from "../../hooks";
import {RootState} from "../../store";
import {useSelector} from "react-redux";
import {OnboardingField} from "./OnboardingField";
import {Field} from "../../models/Field";
import {
    selectIntake,
    setIntake,
    setOnboardingState,
    updateIntake
} from "../../modules/onboarding";
import {Result} from "../../utils";
import {apiClient} from "../../modules/apiclient";
import localforage from "localforage";
import {nextOnboardingStep} from "../../utils/onboarding";
import {processError} from "../../modules/error";

export const OnboardingIntakeQuestions = () => {
    const dispatch = useAppDispatch()
    const state: Pick<RootState, "onboarding"> = useSelector((state: RootState) => {
        return {
            onboarding: state.onboarding,
        }
    })
    const user = usePSUser()

    const [isSending, setIsSending] = useState(false)

    useEffect(() => {
        if (user && state.onboarding.intakeFields.length === 0) {
            setIsSending(true)

            // Load from the server first to make sure this bundle doesn't really have any intake fields
            const editionID = user.edition ? user.edition : `${user.industry}-industry-edition`
            apiClient.post('/edition/sync', {edition: editionID})
                .then(resp => resp.data)
                .then(json => {
                    if (json.intake) {
                        // Set the forms from the edition bundle
                        dispatch(setIntake(json.intake))

                        // Get the user's current forms, so we can render them
                        // with the edited fields and select it
                        apiClient.post('/field/sync', {is_booking: true})
                            .then(resp => resp.data)
                            .then(intakeJson => {
                                intakeJson.fields.filter((f: any) => !!f.edition_field).forEach((f: any) => {
                                    const fieldEditionID = state.onboarding.selectedIntakeIDs.find(fieldID => fieldID === f.edition_field)
                                    if (!fieldEditionID) {
                                        // Select it in the UI now
                                        dispatch(selectIntake(f.edition_field, true))
                                        // Replace the edition built in item with the installed item that is customized
                                        // but retain the edition ID and image_uri
                                        const tmpEditionIntake = json.intake.find((j: any) => j.id === f.edition_field)
                                        if (tmpEditionIntake) {
                                            const editionIntake = new Field()
                                            editionIntake.setData(tmpEditionIntake)
                                            for (const property in editionIntake) {
                                                if (property === 'image_uri' || property === 'id' || property === 'fields') continue
                                                // @ts-ignore
                                                editionIntake[property] = f[property]
                                            }
                                            dispatch(updateIntake(editionIntake))
                                        }
                                    }
                                })
                            })
                            .finally(() => setIsSending(false))
                    }
                    else {
                        Result(dispatch(nextOnboardingStep(user)))
                            .finally(() => setIsSending(false))
                    }
                })
        }
    }, [state.onboarding.fields, user, dispatch])

    const onNextHandler = () => {
        if (!user) return

        setIsSending(true)

        const clonedFields = state.onboarding.selectedIntakeIDs.map((id: string) => {
            const f = state.onboarding.intakeFields.find(f => f.id === id)
            if (!f) return null

            const tmpField = new Field()
            tmpField.setData(f)
            tmpField.edition = user.edition || `${user.industry}-industry-edition`
            tmpField.edition_field = f.id

            const newField = tmpField.toObject()
            delete newField.id // Only send the selected fields

            return newField
        })

        if (state.onboarding.selectedIntakeIDs.length === 0 || clonedFields[0] === null) {
            Result(dispatch(setOnboardingState('ob_intake')))
                .then(() => setIsSending(false))
            return
        }

        apiClient.post(`/onboarding/setup/intake`, {fields: clonedFields})
            .then(resp => resp.data)
            .then(json => {
                if (json.user) localforage.setItem('user', json.user)
                // Go to the next step
                Result(dispatch(nextOnboardingStep(json.user)))
                    .finally(() => setIsSending(false))
            })
            .catch(error => {
                dispatch(processError(error))
                setIsSending(false)
            })
    }

    return (
        <AppHeader title="Setup" showBackButton middleWidget={null}>
            {isSending && (
                <div className="loadingOverlay">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                        <h1>Saving...</h1>
                    </div>
                </div>
            )}
            <div className="scheduleClient servicesSettings onboarding">
                <div className="header">
                    <h1>Your intake questions</h1>
                    <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                     onClick={() => onNextHandler()}
                    >
                        Next
                    </PSButtonPrimary>
                </div>

                <div className="separator" />

                <div className="form">
                    <div className="text">Add and edit the questions that you’d like all new clients to answer.</div>
                    {state.onboarding.intakeFields.map((field: Field) => (
                        <OnboardingField field={field}
                                         key={field.id}
                                         forIntake
                                         isChecked={state.onboarding.selectedIntakeIDs.findIndex((id: string) => id === field.id) > -1}
                                         onToggleItem={(enabled: boolean) => {
                                             dispatch(selectIntake(field.id!, enabled))
                                         }}
                        />
                    ))}
                </div>
            </div>
        </AppHeader>
    )
}