import { push } from "connected-react-router";
import {UserInstance} from "../models/User";
import {apiClient} from "../modules/apiclient";
import {
    clearSelections, setCategories, setContracts, setForms, setIntake, setItems
} from "../modules/onboarding";
import {processError} from "../modules/error";
import { Item } from "../models/Item";
import {Edition} from "../models/Edition";
import {Form} from "../models/Form";
import {Contract} from "../models/Contract";

const filterByCategories = (items: Item[],
                            installedEditionCategories: string[],
                            itemsInCategories: string[]) => {
    if (installedEditionCategories.length === 0)
        return items

    return items.filter(i => itemsInCategories.indexOf(i.id!) > -1)
}

const getEditionItems = (filterType: "contracts" | "forms", edition: Edition, installedItems: Item[]) => {
    const installedEditionItems: Record<string, Item> = {}
    installedItems.forEach(i => {
        if (i.edition_item) {
            installedEditionItems[i.edition_item] = i
        }
    })

    const allEditionItems = []
    if (edition.services) allEditionItems.push(...edition.services)
    if (edition.products) allEditionItems.push(...edition.products)
    if (edition.packages) allEditionItems.push(...edition.packages)
    if (edition.subscriptions) allEditionItems.push(...edition.subscriptions)
    if (edition.classes) allEditionItems.push(...edition.classes)
    if (edition.gifts) allEditionItems.push(...edition.gifts)
    if (edition.reservations) allEditionItems.push(...edition.reservations)

    const editionItemsFiltered: Record<string, Item> = {}
    allEditionItems.forEach(i => {
        if (!i.id) return

        if (installedEditionItems[i.id]) {
            if (filterType === "forms" && i.onboarding_form_id) {
                editionItemsFiltered[i.onboarding_form_id] = installedEditionItems[i.id]
            }
            else if (filterType === "contracts" && i.onboarding_contract_id) {
                editionItemsFiltered[i.onboarding_contract_id] = installedEditionItems[i.id]
            }
        }
    })

    return editionItemsFiltered
}

const getEditionContracts = (edition: Edition, installedItems: Item[]) => {
    return getEditionItems("contracts", edition, installedItems)
}

const getEditionForms = (edition: Edition, installedItems: Item[]) => {
    return getEditionItems("forms", edition, installedItems)
}

export const nextDefaultInstallStep = (editionItemIDs: string[], editionID: string, initialStep: string, currentStep: string) =>
    (dispatch: any) : Promise<any> => {
        return Promise.all([
            apiClient.post('/edition/sync', {edition: editionID}).then(resp => resp.data),
            apiClient.post('/item/sync').then(resp => resp.data),
            apiClient.post('/type/sync').then(resp => resp.data),
            apiClient.post('/contract/sync').then(resp => resp.data),
        ]).then(values => {
            const [ editionJson, itemsJson, formsJson, contractsJson ] = values

            let filteredItems = []
            let itemType = ''

            // Remove any items the user has already installed
            const installedItemIDs = itemsJson.items
                .filter((i: any) => !!i.edition_item)
                .map((i: any) => i.edition_item)
            const filteredEditionItemIDs = editionItemIDs.filter((id: string) => installedItemIDs.indexOf(id) === -1)

            const steps = [
                {currentStep: 'categories', defaults: editionJson.services, nextStep: 'services', type: 'service'},
                {currentStep: 'services', defaults: editionJson.classes, nextStep: 'classes', type: 'class'},
                {currentStep: 'classes', defaults: editionJson.reservations, nextStep: 'reservations', type: 'reservation'},
                {currentStep: 'reservations', defaults: editionJson.packages, nextStep: 'packages', type: 'package'},
                {currentStep: 'packages', defaults: editionJson.subscriptions, nextStep: 'subscriptions', type: 'subscription'},
                {currentStep: 'subscriptions', defaults: editionJson.products, nextStep: 'products', type: 'product'},
                {currentStep: 'products', defaults: editionJson.gifts, nextStep: 'gifts', type: 'gift'},
            ]

            for (let j = 0; j < steps.length; j++) {
                const step = steps[j]
                if (currentStep === step.currentStep) {
                    filteredItems = step.defaults?.filter((i: any) => filteredEditionItemIDs.indexOf(i.id) > -1) ?? []

                    if (filteredItems.length > 0) {
                        itemType = step.nextStep
                        break
                    }
                    else {
                        currentStep = step.nextStep
                    }
                }
            }

            if (filteredItems.length > 0) {
                return dispatch(push(`/settings/${itemType}/defaults`, {items: filteredItems, editionItemIDs: filteredEditionItemIDs, initialStep}))
            }

            if (currentStep === 'gifts') {
                // Get a list of forms for the items in filteredEditionItemIDs
                const installedFormIDs = formsJson.forms.filter((f: any) => !!f.edition_type).map((f: any) => f.edition_type)
                const editionFormsToShow = getEditionForms(editionJson, itemsJson.items)
                // Filter out already installed forms
                const editionFormIDs = Object.keys(editionFormsToShow).filter((id: string) => installedFormIDs.indexOf(id) === -1)
                const filteredRecords = editionJson.forms?.filter((f: any) => editionFormIDs.indexOf(f.id) > -1) ?? []

                if (filteredRecords.length > 0) {
                    return dispatch(push(`/settings/forms/defaults`, {forms: filteredRecords, editionItemIDs: filteredEditionItemIDs, initialStep}))
                }

                currentStep = 'forms'
            }

            if (currentStep === 'forms') {
                // Get a list of contracts for the items in editionItemIDs
                const installedContractIDs = contractsJson.contracts.filter((c: any) => !!c.edition_contract).map((c: any) => c.edition_contract)
                const editionContractsToShow = getEditionContracts(editionJson, itemsJson.items)
                // Filter out already installed contracts
                const editionContractIDs = Object.keys(editionContractsToShow).filter((id: string) => installedContractIDs.indexOf(id) === -1)
                const filteredContracts = editionJson.contracts?.filter((c: any) => editionContractIDs.indexOf(c.id) > -1) ?? []

                if (filteredContracts.length > 0) {
                    return dispatch(push(`/settings/contracts/defaults`, {contracts: filteredContracts, editionItemIDs: filteredEditionItemIDs, initialStep}))
                }
            }

            // Go back to the settings screen that set this in action
            if (initialStep)
                return dispatch(push(`/settings/${initialStep}`))
            else
                return dispatch(push('/settings/services'))
        })
    }

export const nextOnboardingStep = (me: UserInstance) =>
    (dispatch: any) : Promise<any> => {
        let onboardingState = me.onboarding_state
        if (!onboardingState) return dispatch(push('/onboarding/hours'))

        const queryDefaultItems = [
            'ob_hours', 'ob_categories', 'ob_services', 'ob_classes', 'ob_reservations',
            'ob_packages', 'ob_subscriptions', 'ob_products', 'ob_gifts', 'ob_records', 'ob_contracts'
        ]

        dispatch(clearSelections())

        if (queryDefaultItems.indexOf(onboardingState) > -1) {
            // Dynamically load default items from the edition
            const editionID = me.edition ? me.edition : `${me.industry}-industry-edition`
            return Promise.all([
                apiClient.post('/edition/sync', {edition: editionID}).then(resp => resp.data),
                apiClient.post('/category/sync').then(resp => resp.data),
                apiClient.post('/item/sync').then(resp => resp.data),
            ]).then(values => {
                const [ editionJson, categoryJson, itemsJson ] = values

                if (onboardingState === 'ob_hours') {
                    if (editionJson.categories?.length > 0) {
                        dispatch(setCategories(editionJson.categories))
                        return dispatch(push('/onboarding/categories'))
                    }
                    onboardingState = 'ob_categories'
                }

                const installedEditionCategories: string[] = []
                categoryJson.categories.forEach((c: any) => {
                    if (c.edition_category && installedEditionCategories.indexOf(c.edition_category) === -1) {
                        installedEditionCategories.push(c.edition_category)
                    }
                })

                const itemsInCategories: string[] = []
                editionJson.categories.forEach((c: any) => {
                    if (installedEditionCategories.indexOf(c.id) > -1) {
                        itemsInCategories.push(...c.listings)
                    }
                })

                const selectedByDefault = installedEditionCategories.length > 0

                if (onboardingState === 'ob_categories') {
                    if (editionJson.services?.length > 0) {
                        const filteredItems = filterByCategories(
                            editionJson.services,
                            installedEditionCategories,
                            itemsInCategories
                        )
                        if (filteredItems.length > 0)  {
                            dispatch(setItems(filteredItems))
                            return dispatch(push('/onboarding/services',
                                {type: 'service', selectedByDefault, forOnboarding: true, itemsInCategories}))
                        }
                    }
                    onboardingState = 'ob_services'
                }

                if (onboardingState === 'ob_services') {
                    if (editionJson.classes?.length > 0) {
                        const filteredItems = filterByCategories(
                            editionJson.classes,
                            installedEditionCategories,
                            itemsInCategories
                        )
                        if (filteredItems.length > 0) {
                            dispatch(setItems(filteredItems))
                            return dispatch(push('/onboarding/classes',
                                {type: 'class', selectedByDefault, forOnboarding: true, itemsInCategories}))
                        }
                    }
                    onboardingState = 'ob_classes'
                }

                if (onboardingState === 'ob_classes') {
                    if (editionJson.reservations?.length > 0) {
                        const filteredItems = filterByCategories(
                            editionJson.reservations,
                            installedEditionCategories,
                            itemsInCategories
                        )
                        if (filteredItems.length > 0) {
                            dispatch(setItems(filteredItems))
                            return dispatch(push('/onboarding/reservations',
                                {type: 'reservation', selectedByDefault, forOnboarding: true, itemsInCategories}))
                        }
                    }
                    onboardingState = 'ob_reservations'
                }

                if (onboardingState === 'ob_reservations') {
                    if (editionJson.packages?.length > 0) {
                        const filteredItems = filterByCategories(
                            editionJson.packages,
                            installedEditionCategories,
                            itemsInCategories
                        )
                        if (filteredItems.length > 0) {
                            dispatch(setItems(filteredItems))
                            return dispatch(push('/onboarding/packages',
                                {type: 'package', selectedByDefault, forOnboarding: true, itemsInCategories}))
                        }
                    }
                    onboardingState = 'ob_packages'
                }

                if (onboardingState === 'ob_packages') {
                    if (editionJson.subscriptions?.length > 0) {
                        const filteredItems = filterByCategories(
                            editionJson.subscriptions,
                            installedEditionCategories,
                            itemsInCategories
                        )
                        if (filteredItems.length > 0) {
                            dispatch(setItems(filteredItems))
                            return dispatch(push('/onboarding/subscriptions',
                                {type: 'subscription', selectedByDefault, forOnboarding: true, itemsInCategories}))
                        }
                    }
                    onboardingState = 'ob_subscriptions'
                }

                if (onboardingState === 'ob_subscriptions') {
                    if (editionJson.products?.length > 0) {
                        dispatch(setItems(editionJson.products))
                        return dispatch(push('/onboarding/products',
                            {type: 'product', selectedByDefault: false, forOnboarding: true, itemsInCategories}))
                    }
                    onboardingState = 'ob_products'
                }

                if (onboardingState === 'ob_products') {
                    if (editionJson.gifts?.length > 0) {
                        const filteredItems = filterByCategories(
                            editionJson.gifts,
                            installedEditionCategories,
                            itemsInCategories
                        )
                        if (filteredItems.length > 0) {
                            dispatch(setItems(filteredItems))
                            return dispatch(push('/onboarding/gifts',
                                {type: 'gift', selectedByDefault, forOnboarding: true, itemsInCategories}))
                        }
                    }
                    onboardingState = 'ob_gifts'
                }

                if (onboardingState === 'ob_gifts') {
                    if (editionJson.forms?.length > 0) {
                        // Only show forms that are included by installed items or not associated with any services
                        const editionFormsToShow = getEditionForms(editionJson, itemsJson.items)
                        const filteredForms = editionJson.forms.filter((f: Form) => (f.id && editionFormsToShow[f.id]) || f.associated_with_service !== true)
                        if (filteredForms.length > 0) {
                            dispatch(setForms(filteredForms))
                            return dispatch(push('/onboarding/forms', {forOnboarding: true, selectedByDefault, editionItems: editionFormsToShow}))
                        }
                    }
                    onboardingState = 'ob_records'
                }

                if (onboardingState === 'ob_records') {
                    if (editionJson.contracts?.length > 0) {
                        // Only show contracts that are included by installed items or not associated with any services
                        const editionContractsToShow = getEditionContracts(editionJson, itemsJson.items)
                        const filteredContracts = editionJson.contracts.filter((c: Contract) => (c.id && editionContractsToShow[c.id]) || c.associated_with_service !== true)
                        if (filteredContracts.length > 0) {
                            dispatch(setContracts(filteredContracts))
                            return dispatch(push('/onboarding/contracts', {forOnboarding: true, selectedByDefault, editionItems: editionContractsToShow}))
                        }
                    }
                    onboardingState = 'ob_contracts'
                }

                if (onboardingState === 'ob_contracts') {
                    if (editionJson.intake?.length > 0) {
                        dispatch(setIntake(editionJson.intake))
                        return dispatch(push('/onboarding/intake', {forOnboarding: true, selectedByDefault}))
                    }
                    onboardingState = 'ob_intake'
                }

                return dispatch(push('/onboarding/location'))
            })
            .catch(error => dispatch(processError(error)))
        }

        if (onboardingState === 'ob_intake')
            return dispatch(push('/onboarding/location'))
        else if (onboardingState === 'ob_location')
            return dispatch(push('/onboarding/payment'))
        else if (onboardingState === 'ob_payments_1')
            return dispatch(push('/onboarding/payment-continued'))
        else if (onboardingState === 'ob_payments_2')
            return dispatch(push('/onboarding/profile'))
        else if (onboardingState === 'ob_profile')
            return dispatch(push('/onboarding/success'))
        else if (onboardingState === 'ob_complete')
            return dispatch(push('/'))

        return dispatch(push('/'))
    }

export const nextGoLiveStep = (me: UserInstance) =>
    (dispatch: any) : Promise<any> => {
        if (me.onboarding_state === 'ob_complete') {
            return dispatch(push('/golive/welcome'))
        }
        if (me.onboarding_state === 'gl_business') {
            return dispatch(push('/golive/business-info'))
        }
        if (me.onboarding_state === 'gl_personal') {
            return dispatch(push('/golive/personal-info'))
        }
        if (me.onboarding_state === 'gl_payouts') {
            return dispatch(push('/golive/receive-payouts'))
        }

        if (me.onboarding_state === 'gl_premium' && !me.plan) {
            return dispatch(push('/golive/select-plan'))
        }
        // todo else { goto online booking eventually gl_widget }

        return dispatch(push('/golive/success'))
    }
