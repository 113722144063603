import React from "react";
import {ReactComponent as SettingsProductIcon} from "../../../icons/ps_settings_products.svg";
import {Dropdown, Item, Menu, Trigger} from "@zendeskgarden/react-dropdowns";
import {IconButton} from "@zendeskgarden/react-buttons";
import {ReactComponent as KabobIcon} from "../../../icons/kabob_menu.svg";
import {Schedule} from "../../../models/Schedule";

type Props = {
    schedule: Schedule
    type: string
    onEdit: () => void,
    onDelete?: () => void,
}

export const ScheduleCard = ({schedule, type, onEdit, onDelete}: Props) => {
    return (
        <div className="serviceCard">
            <SettingsProductIcon />
            <div className="details">
                <div className="name">{schedule.prettyDescription()}</div>
            </div>
            <div className="edit link">
                <div onClick={() => onEdit()}
                      className="edit">
                    Edit
                </div>
            </div>
            {onDelete && (
                <Dropdown>
                    <Trigger>
                        <IconButton focusInset={false}>
                            <KabobIcon />
                        </IconButton>
                    </Trigger>
                    <Menu hasArrow>
                        <Item onClick={() => onDelete()} value="delete">
                            <span style={{color: 'red'}}>
                                Delete class lesson
                            </span>
                        </Item>
                    </Menu>
                </Dropdown>
            )}
        </div>
    )
}