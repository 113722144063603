import React, {useEffect, useState} from "react";
import {Modal, Body, Close, Footer, FooterItem, Header} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import {SettingsState} from "../../../modules/settings";
import {Item} from "../../../models/Item";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {Spinner} from "@zendeskgarden/react-loaders";
import {Checkbox, Field, Label } from "@zendeskgarden/react-forms";
import {Dropdown, Item as DropdownItem, Menu, Select, Field as DropdownField} from "@zendeskgarden/react-dropdowns";
import {SelectOption, sessionCountOptions} from "../Options";
import findIndex from "lodash.findindex";

export type EnabledServiceSelection = {
    name: string,
    id: string,
    count: number,
}

type Props = {
    onClose: () => void,
    onDone: (services: EnabledServiceSelection[]) => void,
    currentServices: EnabledServiceSelection[],
}

type ServiceSelection = {
    name?: string,
    id?: string,
    enabled: boolean,
    setEnabled?: (b: boolean) => void,
    count: SelectOption,
    setCount?: (n: SelectOption) => void,
}

export const SelectServicesModal = ({onClose, onDone, currentServices}: Props) => {
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const [items, setItems] = useState<Item[]>([])

    useEffect(() => {
        if (!state.items) return

        const limitedItems = state.items.filter((i: Item) => ['service', 'class', 'reservation'].indexOf(i.type) > -1)
        setItems(limitedItems)

        const s: ServiceSelection[] = []
        for (let i = 0; i < limitedItems.length; i++) {
            // Set data from the currentServices array, or default to disabled
            const index = findIndex(currentServices, s => s.id === limitedItems[i].id)
            const enabled = index > -1
            const countValue = enabled ? currentServices[index].count.toString() : '0'
            const countOption = {
                value: countValue,
                label: countValue !== '0' ? countValue : 'Same as package',
            }

            s.push({
                enabled,
                count: countOption,
            })
        }
        setServiceSelections(s)
    }, [state.items, currentServices])

    const [serviceSelections, setServiceSelections] = useState<ServiceSelection[]>([])
    const [fakeData, setFakeData] = useState(false)

    const setServiceEnabled = (index: number, enabled: boolean) => {
        setServiceSelections(currentValues => {
            currentValues[index].enabled = enabled
            return currentValues
        })

        // Fake an update to cause render to be called, so the above
        // setServiceSelections is updated in the UI
        setFakeData(!fakeData)
    }

    const setServiceCount = (index: number, count: SelectOption) => {
        setServiceSelections(currentValues => {
            currentValues[index].count = count
            return currentValues
        })

        // Fake an update to cause render to be called, so the above
        // setServiceSelections is updated in the UI
        setFakeData(!fakeData)
    }

    const onDoneClicked = () => {
        const enabledServices: EnabledServiceSelection[] = []
        for (let i = 0; i < serviceSelections.length; i++) {
            if (serviceSelections[i].enabled) {
                enabledServices.push({
                    name: items[i].name,
                    id: items[i].id as string,
                    count: Number(serviceSelections[i].count.value),
                })
            }
        }

        onDone(enabledServices)
    }

    return (
        <Modal className="selectServicesModal" onClose={onClose}>
            <Header>
                Select items
            </Header>
            <Body style={{paddingLeft: 24, paddingRight: 24}}>
                {state.isSending && (
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                )}
                {items.map((item, index) => (
                    <Field className="field" key={`service-${item.id}`}>
                        <div className="check">
                            <Checkbox checked={serviceSelections[index].enabled}
                                      onChange={e => {
                                          setServiceEnabled(index, e.target.checked)
                                      }}>
                                <Label>{item.name}</Label>
                            </Checkbox>
                        </div>
                        <div className="countSelection">
                            <Dropdown selectedItem={serviceSelections[index].count}
                                      onSelect={(selection) => {
                                          setServiceCount(index, selection)
                                      }}
                                      downshiftProps={{ itemToString: (item: SelectOption) => item && item.label}}
                            >
                                <DropdownField className="field" style={{marginBottom: 0}}>
                                    <Select className="input"
                                            disabled={!serviceSelections[index].enabled}>
                                        {serviceSelections[index].enabled && serviceSelections[index].count.label}
                                    </Select>
                                </DropdownField>
                                <Menu>
                                    <DropdownItem value={{value: '0', label: 'Same as package'}}>Same as package</DropdownItem>
                                    {sessionCountOptions.map(option => (
                                        <DropdownItem key={`sessioncount-${option.value}`} value={option}>
                                            {option.label}
                                        </DropdownItem>
                                    ))}
                                </Menu>
                            </Dropdown>
                        </div>
                    </Field>
                ))}
            </Body>
            <Footer>
                <FooterItem>
                    <Button onClick={() => onDoneClicked()} isPrimary>
                        Done
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )
}