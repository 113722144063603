import React, { useCallback, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router"
import { AppHeader } from "../app/AppHeader"
import { Spinner } from "@zendeskgarden/react-loaders"
import './records.css'
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store"
import { checkinBooking, getRecord, promoteBooking } from "../../modules/records"
import { Result } from "../../utils"
import { ContactAvatar } from "../contacts/ContactAvatar"
import moment from "moment"
import { Dropdown, Item, Menu, Trigger } from "@zendeskgarden/react-dropdowns"
import { IconButton } from "@zendeskgarden/react-buttons"
import {ReactComponent as KabobIcon} from "../../icons/kabob_menu.svg";
import { Link } from "react-router-dom"
import { dashboardTagColors } from "../../utils/colors"
import { DangerModal } from "../settings/DangerModal"
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals"
import { PSButton, PSButtonPrimary } from "../app/PSButton"
import { push } from "connected-react-router"
import { usePSUser } from "../../hooks"
import { UserInstance } from "../../models/User"
import { getUser } from "../../modules/settings"
import { bookingLateInfo, cancellationFeeString } from "../../utils/records"
import { cancelBooking } from "../../modules/transactions"

const displayBookingRosterStatus = (employee: any, booking: any) => {
    if (booking.checkin_date && booking.checkin_by) {
        const checkinMoment = moment(booking.checkin_date)
        let checkInTime = checkinMoment.format('h:mm a')
        if (!moment().isSame(checkinMoment, 'day'))
            checkInTime = `${checkInTime} on ${checkinMoment.format('MMM Do')}`

        return `Checked in at ${checkInTime} by ${employee.name.split(' ')[0]}`
    }
    if (booking.status !== "waitListed") {
        return 'Select the dot icon to cancel or check-in'
    }
    if (booking.status === "waitListed") {
        return 'Select the dot icon to cancel or promote into class'
    }

    return null
}

export const LessonRoster = () => {
    const params = useParams<{id: string}>()

    const dispatch = useDispatch()
    const history = useHistory()
    const state = useSelector((state: RootState) => (
        {
            entities: state.entities,
        }
    ))

    const [bookings, setBookings] = useState<any[]>([])
    useEffect(() => {
        setBookings(
            Object.keys(state.entities.bookings)
                .filter(id => state.entities.bookings[id].lesson === params.id)
                .map(id => state.entities.bookings[id])
        )
    }, [params.id, setBookings, state.entities.bookings])

    const [roster, setRoster] = useState<any[]>([])
    useEffect(() => {
        const clientIds = bookings.map(booking => booking.client)
        setRoster(
            Object.keys(state.entities.clients)
                .filter(id => clientIds.includes(id))
                .map(id => state.entities.clients[id].person)
                .map(id => state.entities.contacts[id])
        )
    }, [bookings, state.entities.clients, state.entities.contacts])

    useEffect(() => {
        if (!state.entities.bookings.length) {
            Result(dispatch(getRecord('lesson', params.id)))
                .finally(() => setIsLoading(false))
        }
        else {
            setIsLoading(false)
        }
    }, [dispatch, params.id, state.entities.bookings.length])

    const sortRoster = (clientA: any, clientB: any) => {
        const first = bookings.find(booking => booking.client === clientA.id)
        const second = bookings.find(booking => booking.client === clientB.id)

        // put waitlist last
        if (first.status !== "waitListed" && second.status === "waitListed") {
            return -1
        }
        if (second.status !== "waitListed" && first.status === "waitListed") {
            return 1
        }
        if (first.status === "waitListed" && second.status === "waitListed") {
            // if both waitlisted order by created date
            return moment(first.created_date) < moment(second.date) ? 1 :
                moment(first.created_date) > moment(second.date) ? -1 :
                0
        }

        // order by name after waitlist
        const firstName = clientA.name.toLowerCase()
        const secondName = clientB.name.toLowerCase()
        if (firstName < secondName) {
            return -1
        }
        if (firstName > secondName) {
            return 1
        }
        return 0
    }

    const user = usePSUser()
    const [vendor, setVendor] = useState<UserInstance>()
    useEffect(() => {
        if (!user || !bookings?.length || !bookings[0]?.vendor) return

        if (user.id === bookings[0].vendor) {
            setVendor(user)
            return
        }

        Result(dispatch(getUser(bookings[0].vendor)))
            .then((result: UserInstance) => setVendor(result))

    }, [user, dispatch, bookings])

    const [isLoading, setIsLoading] = useState(true)
    const [showPromoteModal, setShowPromoteModal] = useState(false)
    const [context, setContext] = useState<{client: any, booking: any}>()

    type ModalInfo = {title: string, body: string, button: string, optionalButton?: string, danger?: boolean, onClick: (optionalButton: boolean) => void}
    const [ctaModalInfo, setCtaModalInfo] = useState<ModalInfo>()
    const [ctaModalLoading, setCtaModalLoading] = useState(false)
    const [showCtaModal, setShowCtaModal] = useState(false)
    const closeCtaModal = () => {
        setShowCtaModal(false)
        setCtaModalLoading(false)
    }

    const cancelAppointment = useCallback((context: {client: any, booking: any}) => {
        if (!vendor || !user) {
            return
        }

        if (context.booking.confirmed_date) {
            dispatch(push(`/cancel/booking/${context.booking.id}`, { record: context.booking, doNotCancelAll: true }))
            return
        }

        const item = context.booking.item in state.entities.items ? state.entities.items[context.booking.item] : undefined
        const {isLate, forClass, forVendor} = bookingLateInfo(context.booking, item, user, vendor)

        const isRateSet = !!Number(vendor?.cancellation_rate)
        const title = !forVendor && isLate && context.booking.source && context.booking.confirmed_date  && isRateSet?
            `Cancel and pay fee ${cancellationFeeString(vendor)}` : forClass ?
            'Cancel this appointment' :
            'Cancel appointment'
        let destructiveButtonLabel = title
        let optionalButtonLabel = undefined

        let body = `If you would like to cancel this appointment choose "${destructiveButtonLabel}"`
        if (forVendor && !!context.booking.channel) {
            optionalButtonLabel = title;
            destructiveButtonLabel = "Cancel & notify"
            body = `If you would like to cancel this appointment and send a notification to the client select "Cancel & notify". To cancel the appointment without notifying the client select "${optionalButtonLabel}".`
        }

        setCtaModalInfo({
            title: "Cancel Appointment",
            body: body,
            button: destructiveButtonLabel,
            optionalButton: optionalButtonLabel,
            onClick: (optionalButton: boolean) => {
                const doNotNotify = !context.booking.channel || optionalButton

                setCtaModalLoading(true)
                Result(dispatch(cancelBooking({ id: context.booking.id, doNotNotify, cancelAll: false })))
                    .then(() => history.goBack())
                    .then(closeCtaModal)
            }
        })
        setShowCtaModal(true)
    }, [dispatch, state.entities.items, user, vendor])

    const promote = useCallback(() => {
        setShowPromoteModal(false)

        setIsLoading(true)
        Result(dispatch(promoteBooking(context?.booking.id)))
            .finally(() => setIsLoading(false))
    }, [context?.booking.id, dispatch])

    let formBody: JSX.Element | undefined = undefined;
    if (isLoading) {
        formBody = <Spinner size='128' color="#314A68" />
    }
    else {
        formBody = <div className="cardWrapper">{roster.sort(sortRoster).map(client => {
            const booking = bookings.find(booking => booking.client === client.id)
            const checkedInBy = booking.checkin_by in state.entities.users ? state.entities.users[booking.checkin_by] : undefined
            const tagText = booking.checkin_date ? "Checked in" : booking.status === "waitListed" ? "Wait list" : undefined
            const tagColor =  booking.checkin_date ? dashboardTagColors.green : dashboardTagColors.blue
            const description = displayBookingRosterStatus(checkedInBy, booking)
            return <div key={`card-${client.id}`} className="card">
                <ContactAvatar key={client.id} user={client} />
                <div className="titleArea">
                    <div className="title">{client.name}</div>
                    <div className="subtitle">{description}</div>
                </div>
                <div className="right">
                    {tagText && (
                        <div className="tag"
                            style={{backgroundColor: tagColor?.bg, color: tagColor?.fg}}
                        >
                            {tagText}
                        </div>
                    )}
                    <Link to={`/record/booking/${booking.id}`}>View</Link>
                </div>

                    <span style={{paddingLeft: '14px'}}>
                        <Dropdown>
                            <Trigger>
                                <IconButton focusInset={false}>
                                    <KabobIcon />
                                </IconButton>
                            </Trigger>
                            <Menu hasArrow>
                                {!booking.checkin_date && booking.status !== "waitListed" &&
                                    <Item onClick={() => {
                                        setIsLoading(true)
                                        Result(dispatch(checkinBooking(booking.id)))
                                            .finally(() => setIsLoading(false))
                                     }} value="checkin">
                                        <span style={{color: '#999'}}>Check in</span>
                                    </Item>
                                }
                                {booking.status === "waitListed" &&
                                    <Item onClick={() => {
                                        setContext({client, booking})
                                        setShowPromoteModal(true)
                                    }} value="promote">
                                        <span style={{color: '#999'}}>Promote into class</span>
                                    </Item>
                                }
                                <Item onClick={() => {
                                    setContext({client, booking})
                                    cancelAppointment({client, booking})
                                }} value="cancel">
                                    <span style={{color: 'red'}}>Cancel</span>
                                </Item>
                            </Menu>
                        </Dropdown>
                    </span>
            </div>
        })}</div>
    }

    return (
        <AppHeader title="Class" showBackButton middleWidget={null}>
            <div className="servicesSettings" style={{width: '680px'}}>
                <div className="header">
                        <h1>Roster</h1>
                </div>
                <div className="separator" />

                <div className="form">
                    {formBody}
                </div>
            </div>
            {showCtaModal && ctaModalInfo &&
                (ctaModalInfo.danger ?
                    <DangerModal
                        title={ctaModalInfo.title}
                        body={ctaModalLoading ? <Spinner size="48" color="#314A68" /> : ctaModalInfo.body}
                        onClose={closeCtaModal}
                        disableDismiss
                        buttonText={ctaModalInfo.button}
                        extraButtonText={ctaModalInfo.optionalButton}
                        onAction={(buttonIx?: number) => ctaModalInfo.onClick(buttonIx === 2)} /> :
                    <Modal onClose={closeCtaModal}>
                        <Header>{ctaModalInfo.title}</Header>
                        <Body>{ctaModalLoading ? <Spinner size="48" color="#314A68" /> : ctaModalInfo.body}</Body>
                        <Close aria-label="Close modal" />
                        <Footer>
                            <FooterItem>
                                {!ctaModalLoading && <>
                                    {ctaModalInfo.optionalButton && <PSButton style={{marginRight: '16px'}} onClick={() => ctaModalInfo.onClick(true)}>{ctaModalInfo.optionalButton}</PSButton>}
                                    <PSButtonPrimary onClick={() => ctaModalInfo.onClick(false)}>{ctaModalInfo.button}</PSButtonPrimary>
                                </>}
                            </FooterItem>
                        </Footer>
                    </Modal>
                )}
            {showPromoteModal &&
                <Modal onClose={() => setShowPromoteModal(false)}>
                    <Header>Promote into class</Header>
                    <Body>This client is currently on the wait list for this class. Would you like to promote them into the class?</Body>
                    <Close aria-label="Close modal" />
                    <Footer>
                        <FooterItem>
                            <PSButtonPrimary onClick={promote}>Continue</PSButtonPrimary>
                        </FooterItem>
                    </Footer>
                </Modal>}
        </AppHeader>)
}