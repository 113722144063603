import React from "react";
import {findSelection, discountPercentOptions, SelectOption} from "../settings/Options";
import {PSDropdown} from "./PSDropdown";
import {Item} from "@zendeskgarden/react-dropdowns";
import {Field, Hint, Input, Label} from "@zendeskgarden/react-forms";
import {PSToggleButton} from "./PSButton";
import {filterMonetaryInput, shiftThirdDecimal} from "../../utils/numbers";

type Props = {
    hint?: string,
    amount: Number | undefined,
    rateType: string,
    keyPrefix: string,
    label: string,
    setRateType: (rateType: string) => void,
    setAmount: (amount: Number | undefined) => void
}

export const PSRatePicker = ({ hint, keyPrefix, label, amount, rateType, setRateType, setAmount }: Props): any => {
    const findPercentSelection = (selected: string | undefined) => findSelection(discountPercentOptions, selected) ?? findSelection(discountPercentOptions, '')

    const isPercent = () => rateType === 'percent'

    const setRatePercentage = () => {
        setRateType('percent')

        setAmount(undefined)
        findPercentSelection(undefined)
    }
    const setRateFixed = () => {
        setRateType('rate')

        setAmount(undefined)
    }

    return (<div className="ratePicker">
        <Label className="label">{label}</Label>
        {hint && (
            <Hint className="hint">{hint}</Hint>
        )}
        <div className="nomargin" style={{display: 'flex'}}>
            <div style={{flex: '1'}}>
            {isPercent() ? (
                <PSDropdown selected={findPercentSelection(amount?.toString())}
                    nameProperty={"label"}
                    onSelect={(tz: SelectOption) => setAmount(Number(findPercentSelection(tz.value)?.value))}>
                <>
                    {discountPercentOptions.map(option => (
                        <Item key={`${keyPrefix}-${option.value}`} value={option}>
                            {option.label}
                        </Item>
                    ))}
                </>
                </PSDropdown>) : (
                <Field style={{flex: '1'}} className="field">
                    <Label style={{position: 'relative', display: 'block', top: 34, left: 10, color: '#999'}}>$</Label>
                    <Input style={{marginTop: 0, paddingLeft: 25, flex: '1'}} placeholder="Fixed amount"

                        value={(amount ?? '').toString()}
                        onChange={e => filterMonetaryInput(shiftThirdDecimal(e.target.value), setAmount)}/>
                </Field>
                )}
            </div>
            <PSToggleButton style={{marginTop: isPercent() ? 16 : 18}} isPressed={!isPercent()} onClick={setRateFixed}>Fixed amount</PSToggleButton>
            <PSToggleButton style={{marginTop: isPercent() ? 16 : 18}} isPressed={isPercent()} onClick={setRatePercentage}>Percent of total</PSToggleButton>
        </div>
    </div>);
}