import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../hooks";
import {
    clearPhoneNumber,
    fetchPhoneNumber,
    savePhoneNumber,
    SettingsState
} from "../../../modules/settings";
import {RootState} from "../../../store";
import {useSelector} from "react-redux";
import {Spinner} from "@zendeskgarden/react-loaders";
import {Field, Hint, Input, IInputProps, Label, Message} from "@zendeskgarden/react-forms";
import {Header, Modal, Body, Close} from "@zendeskgarden/react-modals";
import {Prompt, useParams, useHistory} from "react-router";
import {PSButton, PSButtonPrimary} from "../../app/PSButton";
import {ReactComponent as LeftArrow} from "../../../icons/left-arrow.svg"
import {Dots} from "@zendeskgarden/react-loaders";
import localforage from 'localforage'
import { VALIDATION } from "@zendeskgarden/react-forms/dist/typings/utils/validation";
import InputMask from 'react-input-mask'
import { PhoneNumber } from "../../../models/PhoneNumber";
import {
    LearnMoreYourNumberBody,
    LearnMoreTeamNumberBody
} from "../LearnMore";
import {POCKETSUITE_USER_ID} from "../../../utils/constants";
import {apiClient} from "../../../modules/apiclient";
import { e164ToPhone } from "../../../utils/phone";
import { SuccessModal } from "../SuccessModal";
import {push} from "connected-react-router";


export const NumberEdit = () => {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const [user, setUser] = useState<any>()

    const [phoneNumberID, setPhoneNumberID] = useState<string>('')
    const [sms, setSMS] = useState<string>('')
    const [main, setMain] = useState<string>('')
    const [name, setName] = useState<string>('')

    const [originalMain, setOriginalMain] = useState<string>('')
    const [mainField, setMainField] = useState<string>('')

    const [saveEnabled, setSaveEnabled] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)

    const [hasBack, setHasBack] = useState<boolean>(false)
    const [teamAccount, setTeamAccount] = useState<boolean>(false)
    const [newNumber, setNewNumber] = useState<boolean>(false)

    const [saving, setSaving] = useState<boolean>(false)

    const [forwardingValidation, setForwardingValidation] = useState<VALIDATION | undefined>(undefined)

    const [teamMemberID, setTeamMemberID] = useState<any>()
    const [person, setPerson] = useState<any>()
    const [areaCode, setAreaCode] = useState<string>('')

    const [requestingNewNumber, setRequestingNewNumber] = useState<boolean>(false)
    const [newNumberBody, setNewNumberBody] = useState<string | undefined>(undefined)

    // Learn more
    const [learnMoreVisible, setLearnMoreVisible] = useState(false)
    const [learnMoreTitle, setLearnMoreTitle] = useState('')
    const [learnMoreBody, setLearnMoreBody] = useState<React.ReactNode | null>()
    const showLearnMore = (visible: boolean) => {
        setLearnMoreTitle('About this number')
        if (person.id !== user.id)
            setLearnMoreBody(LearnMoreTeamNumberBody)
        else
            setLearnMoreBody(LearnMoreYourNumberBody)

        setLearnMoreVisible(visible)
    }

    // Load the PhoneNumber if this is an edit, otherwise treat it as a create
    const params: any = useParams()
    useEffect(() => {
        if (!dispatch) return
        if (!params) return
        if (!user) return

        if (!!params?.phoneNumberID || !!params?.teamMemberID) {
            if (!!params?.phoneNumberID) {
                setNewNumber(false)
                dispatch(fetchPhoneNumber({id: params.phoneNumberID}))
            }
            if (!!params?.teamMemberID) {
                setNewNumber(true)
                dispatch(clearPhoneNumber())
                setTeamMemberID(params?.teamMemberID)
            }
            setHasBack(true)
            setTeamAccount(true)
        }
        else {
            setNewNumber(false)            
            if (user.sms) {
                dispatch(fetchPhoneNumber({sms: user.sms}))
            }
            else {
                //TODO -- This is an error
                dispatch(clearPhoneNumber())
            }
    }

        
    }, [dispatch, user, params.phoneNumberID, params.teamMemberID])

    const [payees, setPayees] = useState<any[]>([])
    const [fetchingPayees, setFetchingPayees] = useState<boolean>(false)
    useEffect(() => {
        if (!user) return

        if (user.type === 'company') {
            setFetchingPayees(true)
            apiClient.post('/payee/sync')
                .then(resp => resp.data)
                .then((json: any) => {
                    // Ignore PS, get employee or contractor
                    const roles = ['employee', 'admin', 'contractor']
                    let payees = json.contacts.filter((u: any) => u.id !== POCKETSUITE_USER_ID)
                        .filter((u: any) => roles.some(role => role === u.role))
                    if (user.sms) {
                        payees.unshift(user)
                    }
                    setPayees(payees)
                })
                .catch(error => {
                    // @ts-ignore
                    window.Rollbar.error('payeeSync error', error)
                })
                .finally( () => {
                    setFetchingPayees(false)
                })
        }
        else {
            setPayees([user])
        }
    }, [user])

    useEffect(() => {
        if (!payees.length) return
        if (!teamMemberID && !state.phoneNumber) return

        if (teamMemberID) {
            const newPerson = payees.filter((u: any) => u.id === teamMemberID)[0]
            setName(newPerson.name)
            setMain(newPerson?.main?.slice(2)?.replace(/\D/g, ''))
            setOriginalMain(newPerson?.main?.slice(2)?.replace(/\D/g, ''))    
            setPerson(newPerson)
        }
        else if (state.phoneNumber) {
            const newSMS = state.phoneNumber.sms ? e164ToPhone(state.phoneNumber!.sms) : ''

            setPhoneNumberID(state.phoneNumber.id)
            setSMS(newSMS)
            setName(state.phoneNumber.name)
            setMain(state.phoneNumber.main?.slice(2)?.replace(/\D/g, ''))
            setMainField(state.phoneNumber.main?.slice(2)?.replace(/\D/g, '') || '')
            setOriginalMain(state.phoneNumber.main?.slice(2)?.replace(/\D/g, ''))
    
            if (!!requestingNewNumber) {
                setNewNumberBody(`This team member’s profile has been updated with a new Premium business line. Their new number is ${newSMS}`)
            }
    
            setPerson(payees.filter((u: any) => u.id === state.phoneNumber.person)[0])
        }

    }, [payees, state.phoneNumber, teamMemberID])

    useEffect(() => {
        if (!person) return

        if (person.role !== 'owner') 
            setTeamAccount(true)
    }, [person])

    useEffect(() => {
        const USNumber = !main?.match(/^1/)
        const forwardingValid = !main || (main?.length === 10 && USNumber)
        setForwardingValidation(!forwardingValid ? 'error' : undefined)
    }, [main])

    useEffect(() => {
        setMain(mainField.replace(/\D/g, ''))
    }, [mainField])

    useEffect(() => {
        setHasEdits(areaCode?.length === 3 || originalMain?.replace(/\D/g, '') !== main)
    }, [originalMain, main, areaCode])

    useEffect(() => {
        setSaveEnabled(hasEdits && !forwardingValidation)
    }, [hasEdits, forwardingValidation])

    useEffect(() => {
        localforage.getItem('user').then((user: any) => setUser(user))
    }, [])

    const onSave = () => {
        if (saving) return

        setSaving(true)

        const phoneNumber = new PhoneNumber()
        phoneNumber.main = main

        if (newNumber) {
            phoneNumber.person = person.id
            phoneNumber.sms = areaCode
            setRequestingNewNumber(true)
        }
        else {
            phoneNumber.id = phoneNumberID 
            phoneNumber.person = state.phoneNumber.person
            phoneNumber.name = name
        }

        //@ts-ignore
        dispatch(savePhoneNumber(phoneNumber)).then(setSaving(false))
    }    

    const onNewNumberSucessDone = () => {
        dispatch(push(`/settings/account/numbers`))
    }

    if (state.isSending || !!fetchingPayees) {
        return (
            <div className="servicesSettings">
                <div className="header">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="servicesSettings">
            <div className="header">
                {!!hasBack && (
                    <PSButton style={{marginLeft: 0, marginRight: 16}}
                            onClick={() => history.goBack()}
                    >
                        <LeftArrow />
                    </PSButton>
                )}

                <h1>Number</h1>
                <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!saveEnabled || state.isSending}>
                    {saving && <Dots />}
                    {!saving && "Save"}
                </PSButtonPrimary>
            </div>
            <div className="form">
               <Field className="field">
                {!!teamAccount && (
                    <Label className="label">Team member</Label>
                )}                    
                {!teamAccount && (
                    <Label className="label">Owner</Label>
                )}                    
                <Hint>{name}</Hint>
                </Field>
                {!newNumber && (
                <Field className="field">
                    <Label className="label">Number</Label>
                    <Hint>{sms}</Hint>
                </Field>
                )}
                {!!newNumber && (
                <Field className="field">
                    <Label className="label">Area code</Label>
                    <Hint>Add the area code of the Premium number you’d like to assign this team member</Hint>
                    <InputMask mask="999"
                               value={areaCode}
                               alwaysShowMask={false}
                               maskChar={''}
                               disabled={state.isSending || saving}
                               onChange={e => setAreaCode(e.target.value)}>
                               {(inputProps: IInputProps) => <Input {...inputProps}
                                   className="mobileNumberInput"
                                   placeholder="(123)" 
                                   disabled={state.isSending || saving}
                                   />}
                    </InputMask>                    
                </Field>
                )}
                <Field className="field">
                    <Label className="label">Forward to</Label>
                    <Hint>Forward all incoming calls on my PocketSuite business line to</Hint>
                    <InputMask mask="999-999-9999"
                               value={mainField}
                               alwaysShowMask={false}
                               maskChar={''}
                               disabled={state.isSending || saving}
                               onChange={e => setMainField(e.target.value)}>
                               {(inputProps: IInputProps) => <Input {...inputProps}
                                   className="mobileNumberInput"
                                   placeholder="Forwarding number" 
                                   disabled={state.isSending || saving}
                                   validation={forwardingValidation}
                                   />}
                    </InputMask>                    
                    {!!forwardingValidation && (
                        <Message validation={forwardingValidation}>Only US phone numbers are allowed.</Message>
                    )}
                    <div className="buttonLink brightBlue"
                         style={{display: 'block', paddingTop: 8}}
                         onClick={() => showLearnMore(true)}>
                        Learn more &gt;
                    </div>
                </Field>
                {learnMoreVisible && (
                    <Modal onClose={() => showLearnMore(false)}>
                        <Header>{learnMoreTitle}</Header>
                        <Body>{learnMoreBody}</Body>
                        <Close aria-label="Close modal" />
                    </Modal>
                )}
                {!!newNumberBody && (
                    <SuccessModal 
                        title = "Success"
                        body = {newNumberBody}
                        buttonText = "Done"
                        onClose = {()=> onNewNumberSucessDone()}
                        onAction = {()=> onNewNumberSucessDone()}
                    />
                )}
                <Prompt
                    when={hasEdits && !saving && !newNumberBody}
                    message="Are you sure you'd like to leave this page without saving your changes?" />
            </div>
        </div>
    )    
}
