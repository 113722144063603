import React, {useCallback, useEffect, useState} from "react";
import {useAppDispatch, usePSUser} from "../../hooks";
import {useHistory} from "react-router";
import {ReactComponent as LeftArrow} from "../../icons/left-arrow.svg";
import {PSButton, PSButtonPrimary} from "../app/PSButton";
import {SourceInstance} from "../../models/Source";
import {PSDropdown} from "../app/PSDropdown";
import { Item } from "@zendeskgarden/react-dropdowns";
import {Spinner} from "@zendeskgarden/react-loaders";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { fetchEdition, fetchSources, SettingsState, subscribe } from "../../modules/settings";
import { Result } from "../../utils";
import { Plan, planLabelString } from "../../models/Plan";
import { SuccessModal } from "./SuccessModal";
import { Subscription, subscriptionLabelString, subscriptionPlanOptions } from "../../models/Subscription";
import { Field } from "@zendeskgarden/react-forms";
import { SelectOption } from "./Options";
import { Edition } from "../../models/Edition";

type Props = {
    plan: Plan,
    setShowCardEdit: (value: boolean) => void,
    onNext: () => void,
    onBack: (() => void) | null
}

export const SetupBilling = ({plan, setShowCardEdit, onBack, onNext}: Props) => {
    const user = usePSUser()
    const history = useHistory()
    const dispatch = useAppDispatch()
    const settingsState: SettingsState = useSelector((state: RootState) => state.settings)

    const [edition, setEdition] = useState<Edition>()
    const [labelString, setLabelString] = useState('')
    const [planOptions, setPlanOptions] = useState<SelectOption[]>([])
    const [selectedPlan, setSelectedPlan] = useState<SelectOption>()
    const [currentSource, setCurrentSource] = useState<SourceInstance | undefined>()
    const [chargeEnabled, setChargeEnabled] = useState(false)
    const [chargeSending, setChargeSending] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [successPlanTitle, setSuccessPlanTitle] = useState('')

    useEffect(() => {
        if (!user?.edition) return

        let isSubscribed = true
        Result(dispatch(fetchEdition(user.edition)))
            .then((found: Edition) => isSubscribed ? setEdition(found) : null)

        return () => {
            isSubscribed = false
        }
    }, [user?.edition, dispatch])

    useEffect(() => {
        setPlanOptions(
            subscriptionPlanOptions(settingsState.activePSSubscription, edition)
        )
    }, [settingsState.activePSSubscription, edition])

    useEffect(() => {
        setSelectedPlan(planOptions.find(p => p.value === plan.plan))
    }, [planOptions, plan.plan])

    useEffect(() => {
        if (!user || !selectedPlan) return

        if (user.subscribed_date && settingsState.activePSSubscription) {
            setLabelString(
                subscriptionLabelString({
                    ...settingsState.activePSSubscription,
                    name: settingsState.plans.find(p => p.price === settingsState.activePSSubscription.amount.toString())?.title ?? ''
                } as Subscription)
            )
        }
        else
            setLabelString(planLabelString(selectedPlan.value))
    }, [user, settingsState.activePSSubscription, settingsState.plans, selectedPlan])

    useEffect(() => {
        setChargeEnabled(!!currentSource)
    }, [currentSource])

    useEffect(() => {
        Result(dispatch(fetchSources()))
    }, [dispatch])

    useEffect(() => {
        if (!selectedPlan) return
        const found = settingsState.plans.find(p => p.plan === selectedPlan.value)
        setSuccessPlanTitle(found?.title || '')
    }, [settingsState.plans, selectedPlan])

    const onChargeHandler = useCallback(() => {
        if (!user?.id || !currentSource || chargeSending || selectedPlan?.value === 'free') return
        setChargeSending(true)

        Result(dispatch(subscribe({userId: user.id, plan: selectedPlan?.value ?? 'free', sourceId: currentSource.id})))
            .then(() => setChargeSending(false))
            .then(() => setShowSuccess(true))
    }, [user?.id, currentSource, chargeSending, selectedPlan?.value, dispatch])

    return (
        <div className="servicesSettings">
            <div className="header">
                {onBack && (
                    <PSButton style={{marginLeft: 0, marginRight: 16}} onClick={onBack}>
                        <LeftArrow />
                    </PSButton>
                )}
                <h1>Change Plan</h1>
                <div className="right">
                    <PSButtonPrimary disabled={!chargeEnabled || chargeSending}
                                     onClick={() => onChargeHandler()}
                    >
                        Save
                    </PSButtonPrimary>
                    <PSButton onClick={() => setShowCardEdit(true)}
                              disabled={chargeSending}
                    >
                    Add a new card
                </PSButton>
                </div>
            </div>
            <div className="separator" />
            <div className="form">
                <Field className="field">
                    <PSDropdown selected={selectedPlan}
                                nameProperty="label"
                                label="Plan (required)"
                                hint={labelString}
                                onSelect={(selection: SelectOption) => setSelectedPlan(selection)}
                    >
                        {planOptions.map((option: SelectOption) => (
                            <Item key={`subscription-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </PSDropdown>
                </Field>
                <Field className="field">
                    <PSDropdown selected={currentSource}
                                nameProperty="display_name"
                                downshiftProperty="id"
                                label="Credit Card (required)"
                                placeholder={<div className="grey">Select a card</div>}
                                onSelect={(selection) => setCurrentSource(selection)}
                    >
                        {settingsState.sources.filter((source: any) => source.type !== "checking").map((source: any) => (
                            <Item key={`source-${source.id}`} value={source}>
                                {source.display_name}
                            </Item>
                        ))}
                    </PSDropdown>
                </Field>
                {chargeSending && (
                    <div style={{textAlign: 'center', paddingTop: '28px'}}>
                        <Spinner size="128" color="#314A68" />
                        <h1>Processing...</h1>
                    </div>
                )}
            </div>
            {showSuccess && (
                <SuccessModal
                    title="Success"
                    buttonText="Done"
                    body={<p>You're now on PocketSuite {successPlanTitle}.</p>}
                    onClose={() => {
                        setShowSuccess(false)
                        onNext ? onNext() : history.goBack()
                    }}
                    onAction={() => {
                        setShowSuccess(false)
                        onNext ? onNext() : history.goBack()
                    }}
                />
            )}
        </div>
    )
}