import React, {useCallback, useEffect, useState} from "react";
import {useAppDispatch} from "../../../hooks";
import {Redirect, useHistory, useLocation} from "react-router";
import {Invoice} from "../../../models/Invoice";
import {AppHeader} from "../../app/AppHeader";
import {Spinner} from "@zendeskgarden/react-loaders";
import {currencyFormat} from "../../../utils/numbers";
import {PSButton, PSButtonPrimary} from "../../app/PSButton";
import {Link} from "react-router-dom";
import {apiClient} from "../../../modules/apiclient";
import {UserInstance} from "../../../models/User";
import {LineItem} from "../../../models/LineItem";
import {ItemSummaryModal} from "../ItemSummaryModal";
import {TransactionProgress} from "../TransactionProgress";
import {processError} from "../../../modules/error";
import {
    Body, Close,
    Footer,
    FooterItem,
    Header,
    Modal
} from "@zendeskgarden/react-modals";
import {b64EncodeUnicode} from "../../../utils/strings";
import { sendInvoice } from "../../../modules/transactions";
import { Result } from "../../../utils";

export const InvoiceSummary = () => {
    const dispatch = useAppDispatch()

    const location = useLocation()
    const locationState: any = location.state
    const invoiceData: Invoice = locationState?.invoice
    const [invoice, setInvoice] = useState<Invoice>()
    useEffect(() => setInvoice(new Invoice(invoiceData)), [invoiceData])

    const history = useHistory()

    // Load from paymentData
    const [items, setItems] = useState<LineItem[]>([])
    const [itemTotal, setItemTotal] = useState(0)
    const [expenses, setExpenses] = useState<LineItem[]>([])
    const [expenseTotal, setExpenseTotal] = useState(0)
    const [appointments, setAppointments] = useState<LineItem[]>([])
    const [appointmentTotal, setAppointmentTotal] = useState(0)
    useEffect(() => {
        const i = invoiceData?.lines.filter(l => l.type === 'item') ?? []
        setItems(i)
        const itemSum = i.reduce((sum, cur) => sum + Number(cur.total), 0)
        setItemTotal(itemSum)

        const e = invoiceData?.lines.filter(l => l.type === 'expense') ?? []
        setExpenses(e)
        const expenseSum = e.reduce((sum, cur) => sum + Number(cur.total), 0)
        setExpenseTotal(expenseSum)

        const b = invoiceData?.lines.filter(l => l.type === 'booking') ?? []
        setAppointments(b)
        const appointmentSum = b.reduce((sum, cur) => sum + Number(cur.total), 0)
        setAppointmentTotal(appointmentSum)

        // Fetch data from the server that we need to render this screen
    }, [invoiceData])

    const [client, setClient] = useState<UserInstance>()
    useEffect(() => {
        if (invoiceData?.client) {
            apiClient.post('/client/sync', {person: invoiceData.client})
                .then(resp => resp.data)
                .then(json => {
                    if (json.contacts?.length > 0)
                        setClient(json.contacts[0])
                })
        }
    }, [invoiceData?.client])

    const [showItemsModal, setShowItemsModal] = useState(false)
    const [showExpensesModal, setShowExpensesModal] = useState(false)
    const [showAppointmentsModal, setShowAppointmentsModal] = useState(false)

    // Preview
    const [isPreviewLoading, setIsPreviewLoading] = useState(false)
    const [showPreviewModal, setShowPreviewModal] = useState(false)
    const [pdfPreviewData, setPDFPreviewData] = useState('')

    const [, discountDescription, ] = invoice?.discountDisplay() ?? ['', '', 0]

    const [invoiceSending, setInvoiceSending] = useState(false)
    const onSendInvoice = (chargeAfterSend: boolean, dontNotify: boolean) => {
        if (invoiceSending || !invoice) return

        setInvoiceSending(true)

        const data = {
            ...invoice,
            id: invoiceData?.id,
            dont_notify: dontNotify
        }
        return Result(dispatch(sendInvoice(data, chargeAfterSend)))
            .finally(() => setInvoiceSending(false))
    }

    const onPreviewInvoice = () => {
        setIsPreviewLoading(true)
        setShowPreviewModal(true)

        apiClient.post('/invoice/print', invoice)
            .then(resp => setPDFPreviewData(resp.data))
            .catch(error => dispatch(processError(error)))
            .finally(() => setIsPreviewLoading(false))
    }

    const totalDisplay = useCallback(() => {
        return Number(invoice?.calculateChargeAmount()) > 0 ? `${currencyFormat(invoice!.calculateChargeAmount())} Total` :
            invoice?.total === undefined ? "Price not set" :
            "Free"
    }, [invoice])

    if (!invoiceData) {
        return <Redirect to="/invoice/new" />
    }

    return (
        <AppHeader title="Send invoice"
                   showBackButton
                   onBack={() => history.replace(invoiceData.id ? `/invoice/edit/${invoiceData.id}` : '/invoice/new', {invoice: invoiceData})}
                   middleWidget={<TransactionProgress created reviewed secondStep="Preview "/>}
        >
            <div className="invoiceSummary scheduleClient servicesSettings">
                <div className="header">
                    <h1>Review</h1>
                </div>

                <div className="separator" />

                <div className="scheduleClientReview">
                    <div className="details">
                        <div className="receipt">
                            <div className="total">{totalDisplay()}</div>
                            <div className="subtitle">
                                <div>Invoice #{invoice?.number ? invoice.number : '(to be generated)'}</div>
                                <div>Requires payment</div>
                            </div>
                            <div className="lineItem final">
                                <div className="name">Subtotal</div>
                                <div className="amount">{currencyFormat(invoice?.calculateTotal())}</div>
                            </div>
                            {Number(invoice?.discount) > 0 && (
                                <div className="lineItem">
                                    <div className="name">{discountDescription}</div>
                                    <div className="amount">-{currencyFormat(invoice?.calculateDiscount())}</div>
                                </div>
                            )}
                            {Number(invoice?.calculateTax()) > 0 && (
                                <div className="lineItem">
                                    <div className="name">Sales tax</div>
                                    <div className="amount">{currencyFormat(invoice?.calculateTax())}</div>
                                </div>
                            )}
                            {invoice?.allow_surcharge === true && (
                                <div className="lineItem">
                                    <div className="name">Surcharge (3%)</div>
                                    <div className="amount">{currencyFormat(invoice?.calculateSurcharge())}</div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="actions">
                        <>
                            <PSButtonPrimary onClick={() => onSendInvoice(false, invoiceData.channel === '')}
                                             disabled={invoiceSending}
                            >
                                {invoiceData.channel === '' && (
                                    <span>Save</span>
                                )}
                                {invoiceData.channel !== '' && (
                                    <span>Send</span>
                                )}
                            </PSButtonPrimary>
                            <PSButton style={{border: '1px solid #e6e6e6'}}
                                      onClick={() => onPreviewInvoice()}
                                      disabled={invoiceSending}
                            >
                                Preview
                            </PSButton>
                            {!invoiceData.paid_date && (
                                <PSButton style={{border: '1px solid #e6e6e6'}}
                                        onClick={() => onSendInvoice(true, true)}
                                        disabled={invoiceSending}
                                >
                                    {invoice?.calculateChargeAmount() ? 'Charge' : 'Mark paid'}
                                </PSButton>
                            )}
                        </>
                    </div>

                    <div className="fields">
                        <>
                            <div className="field">
                                <div className="data">
                                    <div className="label">Client</div>
                                    <div className="value">{client?.name}</div>
                                </div>
                                <div className="action">
                                    <Link to={`/record/client/${client?.id}`}>View</Link>
                                </div>
                            </div>
                            {items.length > 0 && (
                                <div className="field">
                                    <div className="data">
                                        <div className="label">Items</div>
                                        <div className="value">{currencyFormat(itemTotal)}</div>
                                    </div>
                                    <div className="action">
                                        <button className="buttonLink"
                                                onClick={() => setShowItemsModal(true)}
                                        >
                                            View
                                        </button>
                                    </div>
                                </div>
                            )}
                            {expenses.length > 0 && (
                                <div className="field">
                                    <div className="data">
                                        <div className="label">Expenses</div>
                                        <div className="value">{currencyFormat(expenseTotal)}</div>
                                    </div>
                                    <div className="action">
                                        <button className="buttonLink"
                                                onClick={() => setShowExpensesModal(true)}
                                        >
                                            View
                                        </button>
                                    </div>
                                </div>
                            )}
                            {appointments.length > 0 && (
                                <div className="field">
                                    <div className="data">
                                        <div className="label">Appointments</div>
                                        <div className="value">{currencyFormat(appointmentTotal)}</div>
                                    </div>
                                    <div className="action">
                                        <button className="buttonLink"
                                                onClick={() => setShowAppointmentsModal(true)}
                                        >
                                            View
                                        </button>
                                    </div>
                                </div>
                            )}
                            {invoiceData.memo && (
                                <div className="field">
                                    <div className="data">
                                        <div className="label">Memo</div>
                                        <div className="value">{invoiceData.memo}</div>
                                    </div>
                                    <div className="action">
                                        <button className="buttonLink"
                                                onClick={() => {}}
                                        >
                                            View
                                        </button>
                                    </div>
                                </div>
                            )}
                        </>
                    </div>
                </div>
                {showItemsModal && (
                    <ItemSummaryModal lineItems={items}
                                      title="Items"
                                      onCancel={() => setShowItemsModal(false)}
                    />
                )}
                {showExpensesModal && (
                    <ItemSummaryModal expenses={expenses}
                                      title="Expenses"
                                      onCancel={() => setShowExpensesModal(false)}
                    />
                )}
                {showAppointmentsModal && (
                    <ItemSummaryModal appointments={appointments}
                                      title="Appointments"
                                      onCancel={() => setShowAppointmentsModal(false)}
                    />
                )}
                {showPreviewModal && (
                    <Modal onClose={() => setShowPreviewModal(false)}>
                        <Header>Preview</Header>
                        <Body>
                            {isPreviewLoading && (
                                <div style={{width: '100%', height: '420px'}}>
                                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                                        <Spinner size="128" color="#314A68" />
                                    </div>
                                </div>
                            )}
                            {!isPreviewLoading && pdfPreviewData && (
                                <iframe style={{width: '100%', height: '420px'}}
                                        src={`data:application/pdf;base64,${b64EncodeUnicode(pdfPreviewData)}`}
                                        title={'Preview'}>
                                </iframe>
                            )}
                        </Body>
                        <Footer style={{paddingBottom: 32}}>
                            <FooterItem>
                            </FooterItem>
                        </Footer>
                        <Close aria-label="Close modal" />
                    </Modal>
                )}
            </div>
        </AppHeader>
    )
}