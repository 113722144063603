import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import createRootReducer from './modules'
import { createBrowserHistory  } from 'history'
import errorHandler from "./errorHandler";

export const history = createBrowserHistory({
    basename: '',
})

const enhancers = []
const middleware = [thunk, routerMiddleware(history), errorHandler]

if (process.env.NODE_ENV === 'development') {
    // @ts-ignore
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension())
    }
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
)

const store = createStore(
    createRootReducer(history),
    {},
    composedEnhancers
)
export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch