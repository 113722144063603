import React from 'react'
import { useSelector } from 'react-redux'

export const ItemTable = () => {
    const state = useSelector(state => state.dashboard)

    return (
        <table className="incomeTable">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Amount</th>
                    <th>Percentage of Total</th>
                </tr>
            </thead>
            <tbody>
            {state.metrics.total.topList.length === 0 && (
                <tr>
                    <td colSpan="3" className="noData">
                        No data for time period
                    </td>
                </tr>
            )}
            {state.metrics.total.topList.map((obj) => {
                const percentOfTotalAbsolute = obj.__react_total / state.metrics.total.income
                const percentOfTotal = obj.__react_total / state.metrics.total.topList[0].__react_total
                return (
                    <tr key={`toplist-${obj.id}`}>
                        <td>
                            <span className="name">{obj.name}</span>
                        </td>
                        <td>
                            {(obj.__react_total).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}
                        </td>
                        <td>
                            {(percentOfTotalAbsolute*100).toFixed(1)}%
                            <span className="percentBar" style={{width: `${percentOfTotal*340}px`}} />
                        </td>
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}
