import {
    Modal,
    Body,
    Header,
    Footer,
    FooterItem, Close
} from "@zendeskgarden/react-modals";
import React, {useEffect, useState} from "react";
import {Button} from "@zendeskgarden/react-buttons";
import {Dropdown, Item, Menu, Select} from "@zendeskgarden/react-dropdowns";
import {Field as DropdownField} from "@zendeskgarden/react-dropdowns";
import {Input} from "@zendeskgarden/react-forms";
import {PSToggleButton} from "../../app/PSButton";
import {filterMonetaryInput} from "../../../utils/numbers";
import {discountOptions, SelectOption} from "../../settings/Options";

type Props = {
    currentData?: {discountType?: 'rate' | 'percent' | '', discountAmount?: string},
    onDone: (discountType: 'rate' | 'percent', discountAmount: string) => void,
    onCancel: () => void,
}

export const DiscountModal = ({currentData, onDone, onCancel}: Props) => {
    const [discountType, setDiscountType] = useState<'rate' | 'percent'>(currentData?.discountType || 'percent')
    const [discountRate, setDiscountRate] = useState<SelectOption | undefined>()
    const [fixedDiscount, setFixedDiscount] = useState(currentData?.discountAmount || '')

    useEffect(() => {
        if (currentData?.discountType !== 'percent') return
        const selectedRate = discountOptions.find(o => o.value === currentData.discountAmount)
        setDiscountRate(selectedRate)
    }, [currentData])

    const [doneEnabled, setDoneEnabled] = useState(false)
    useEffect(() => {
        if (discountType === 'percent')
            setDoneEnabled(!!discountRate)
        else if (discountType === 'rate')
            setDoneEnabled(!!fixedDiscount)
    }, [discountType, discountRate, fixedDiscount])

    const onDoneHandler = () => {
        onDone(discountType, (discountType === 'percent' ? discountRate?.value : fixedDiscount) ?? '')
    }

    return (
        <Modal className="discountModal modal"
               onClose={() => onCancel()}
        >
            <Header className="header">
                <div className="title">Discount</div>
            </Header>
            <Body className="body form">
                <Dropdown selectedItem={discountRate}
                          onSelect={(selection) => setDiscountRate(selection)}
                          downshiftProps={{ itemToString: (item: any) => item && item['label']}}>
                    <DropdownField className="field">
                        <div style={{display: 'flex'}}>
                            <PSToggleButton style={{margin: '12px 0'}}
                                            isPressed={discountType === 'percent'}
                                            onClick={() => setDiscountType('percent')}
                            >
                                Percent of total
                            </PSToggleButton>
                            <PSToggleButton style={{margin: '12px 16px'}}
                                            isPressed={discountType === 'rate'}
                                            onClick={() => setDiscountType('rate')}
                            >
                                Fixed amount
                            </PSToggleButton>
                            {discountType === 'percent' && (
                                <Select style={{width: 292, height: 48, marginTop: 12}} className="input">
                                    {discountRate && discountRate.label}
                                    {!discountRate && (<div className="grey">Select discount</div>)}
                                </Select>
                            )}
                            {discountType === 'rate' && (
                                <Input style={{width: 292, height: 48, marginTop: 12}}
                                       className="input"
                                       placeholder="Enter discount amount"
                                       value={fixedDiscount}
                                       onChange={e => filterMonetaryInput(e.target.value, setFixedDiscount)}
                                />
                            )}
                        </div>
                    </DropdownField>
                    <Menu>
                        {discountOptions.map(option => (
                            <Item key={`discount-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </Menu>
                </Dropdown>
            </Body>
            <Footer className="footer">
                <FooterItem>
                    <Button onClick={() => onCancel()}>Cancel</Button>
                </FooterItem>
                <FooterItem>
                    <Button isPrimary
                            disabled={!doneEnabled}
                            onClick={() => onDoneHandler()}
                    >
                        Done
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )
}