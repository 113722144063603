import React from "react";
import {ReactComponent as CheckingIcon} from "../../../icons/safe.svg"
import {ReactComponent as CardIcon} from "../../../icons/credit_card.svg"
import moment from "moment"

type Props = {
    source: any,
    selected?: boolean,
    onView: () => void,
    onSelect: () => void
}

export const SourceCard = ({source, selected, onView, onSelect}: Props) => {
    let label
    let description
    let expired
    let type

    if (source.bank_name)
        description = source.bank_name
    else if (source.type === "applepay")
        description = "Apple Pay"
    else
        description = source.name

    if (source?.type === "checking") {
        if (source?.verified_date) {
            expired = "Verified"
        }
        else if (source?.charge) {
            expired = "Pending"
        }
        else {
            expired = ""
        }

        label = source.type + " - " + source.account_number.slice(source.account_number.length - 4)
    }
    else {
        expired = moment(source.expired_date).format('MM/YY')
        if (expired) expired = "Expires " + expired

        label = source.card_brand + " - " + source.card_number.slice(source.card_number.length - 4)
        if (source.instant)
            label += " \u26A1"
    }
    type = source.type

    return (
        <div className="sourceCard">
            {source?.type === "checking" && (
                <CheckingIcon />
            )}
            {source?.type === "applepay" && (
                <CheckingIcon /> //TODO -- ApplePay icon
            )}
            {source?.type !== "checking" && source?.type !== "applepay" && (
                <CardIcon />
            )}
            <div className="details">
                <div className="line">
                    <div className="label">{label}</div>
                    <div className="expired">{expired}</div>
                </div>
                <div className="line">
                    <div className="description">{description}</div>
                    <div className="type">{type}</div>
                </div>
            </div>
            <div className="view">
                {selected === undefined &&
                    <div onClick={onView} className="buttonLink">
                        View
                    </div>
                }
                {selected === true &&
                    <div onClick={onSelect} className="buttonLink brightGreen">
                        Selected
                    </div>
                }
                {selected ===  false &&
                    <div onClick={onSelect} className="buttonLink">
                        Select
                    </div>
                }
            </div>
        </div>
    )
}