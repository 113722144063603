import React, {useEffect, useState} from "react"
import {AppHeader} from "../app/AppHeader";
import {PSButtonPrimary} from "../app/PSButton";
import {useAppDispatch, usePSUser} from "../../hooks";
import {Field, Label} from "@zendeskgarden/react-forms";
import {PSTextarea} from "../app/PSTextarea";
import {PSDropdown} from "../app/PSDropdown";
import {
    deadlineOptions,
    findSelection,
    requireCreditCardOptions, SelectOption
} from "../settings/Options";
import {Item} from "@zendeskgarden/react-dropdowns";
import {PSLearnMore} from "../app/PSLearnMore";
import {LearnMoreCancellationPolicy} from "../settings/LearnMore";
import {PSRatePicker} from "../app/PSRatePicker";
import {UserInstance} from "../../models/User";
import {saveUser} from "../../modules/settings";
import {Result} from "../../utils";
import {setOnboardingState} from "../../modules/onboarding";

export const OnboardingSetupPayments = () => {
    const findDeadlineSelection = (user: UserInstance) => findSelection(deadlineOptions, user.cancellation_duration)
    const findRequireCCSelection = (user: UserInstance) => findSelection(requireCreditCardOptions, user.confirm_payment)

    const dispatch = useAppDispatch()
    const user = usePSUser()

    const [isSending, setIsSending] = useState(false)
    const [lateFeeAmount, setLateFeeAmount] = useState<Number>()
    const [lateFeeType, setLateFeeType] = useState('')
    const [deadline, setDeadline] = useState<SelectOption>()
    const [cancellationPolicy, setCancellationPolicy] = useState('')
    const [requireCC, setRequireCC] = useState<SelectOption>()

    const [learnMoreComponent, showLearnMore] = PSLearnMore([
        { key: 'cancel-policy', title: 'Cancellation policy', body: LearnMoreCancellationPolicy },
    ]);

    useEffect(() => {
        if (!user) return

        if (user.cancellation_rate) setLateFeeAmount(Number(user.cancellation_rate))
        else setLateFeeAmount(undefined)
        setLateFeeType(user.cancellation_rate_type)

        setDeadline(findDeadlineSelection(user))
        setRequireCC(findRequireCCSelection(user))

        setCancellationPolicy(user.cancellation_policy)
    }, [user])

    const onNextHandler = () => {
        if (!user) return

        setIsSending(true)

        const userParams: any = {}
        userParams.id = user.id
        userParams.cancellation_rate = lateFeeAmount ?? null
        userParams.cancellation_rate_type = lateFeeType
        userParams.cancellation_policy = cancellationPolicy
        userParams.cancellation_duration = deadline?.value ?? null
        userParams.confirm = requireCC?.value ?? null

        // Save user
        Result(dispatch(saveUser(userParams)))
            .then(() => {
                // Send ob_payments_1 state
                Result(dispatch(setOnboardingState('ob_payments_1')))
                    .then(() => setIsSending(false))
            })
    }

    return (
        <AppHeader title="Setup" showBackButton middleWidget={null}>
            <div className="scheduleClient servicesSettings onboarding onboardingSetupPayments">
                <div className="header">
                    <h1>Get paid on PocketSuite</h1>
                    <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                     onClick={() => onNextHandler()}
                    >
                        Next
                    </PSButtonPrimary>
                </div>

                <div className="separator" />

                <div className="form">
                    <div className="text">We’ve set defaults for you that are ideal for your industry. You can change any of them below.</div>

                    <PSRatePicker
                        label={'Late fee'}
                        keyPrefix="cancel"
                        amount={lateFeeAmount} setAmount={setLateFeeAmount}
                        rateType={lateFeeType} setRateType={setLateFeeType} />

                    <PSDropdown selected={deadline}
                                nameProperty="label"
                                onSelect={(selection) => setDeadline(selection)}
                                label="Deadline"
                                hint="If your client confirms then cancels after this deadline they will be charged"
                                placeholder={<div className="grey">Select deadline</div>}>
                        <>
                            {deadlineOptions.map(option => (
                                <Item key={`deadline-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>

                    <Field className="field">
                        <Label className="label">Cancellation policy</Label>
                        <PSTextarea placeholder="Add cancellation policy"
                                    className="input"
                                    minRows={8}
                                    maxLength={999}
                                    value={cancellationPolicy}
                                    onLearnMore={() => showLearnMore('cancel-policy')}
                                    onChange={value => setCancellationPolicy(value)}/>
                    </Field>

                    <PSDropdown selected={requireCC}
                                nameProperty="label"
                                onSelect={(selection) => setRequireCC(selection)}
                                label="Require credit card"
                                hint="Require a credit card to enforce your cancellation policy"
                                placeholder={<div className="grey">Select option</div>}>
                        <>
                            {requireCreditCardOptions.map(option => (
                                <Item key={`require-card-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>

                </div>

                {learnMoreComponent}
            </div>
        </AppHeader>
    )
}
