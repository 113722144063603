import localforage from "localforage";

export const loadIndustry = (id: string): Promise<any> => {
    return new Promise<string>((resolve, reject) => {
        localforage.getItem('industries').then((industries: any) => {
            if (!industries) {
                reject()
                return
            }

            const result = industries.filter((k: any) => k.id === id)
            if (result.length === 1)
                resolve(result[0])
            else
                reject()
        })
    })
}

export const lookupIndustryTitle = (key: string): Promise<string> => {
    // Initial users do not have the industry list saved, so store it in here for now.
    // After 30days of this being released, we can remove the lookup variable, as all users
    // will have access to the cached server response of industries
    const lookup: {[k: string]: string} = {
        'accountant': 'Accountant',
        'barber': 'Barber',
        'campInstructor': 'Camp Instructor',
        'carpetCleaning': 'Carpet Cleaner',
        'catering': 'Caterer',
        'childCare': 'Child Care',
        'chiropractor': 'Chiropractor',
        'consulting': 'Consultant',
        'contractor': 'Contractor',
        'copyWriting': 'Writer',
        'dentist': 'Dentist',
        'dj': 'DJ',
        'dogTraining': 'Dog Trainer',
        'dogWalking': 'Dog Walker',
        'esthetician': 'Esthetician',
        'eventPlanning': 'Event Planner',
        'fitness': 'Fitness Trainer',
        'freelancer': 'Freelancer',
        'graphicDesign': 'Designer',
        'hairStylist': 'Hairstylist',
        'handyman': 'Handyman',
        'healthWellness': 'Wellness Pro',
        'homeCleaning': 'Cleaner',
        'horseTraining': 'Equine Specialist',
        'interiorDesign': 'Interior Designer',
        'junkRemoval': 'Junk Remover',
        'landscaping': 'Landscaper',
        'lashTech': 'Lash Tech',
        'lawyer': 'Lawyer',
        'lifeCoach': 'Life Coach',
        'locksmith': 'Locksmith',
        'makeupArtist': 'Makeup Artist',
        'masseuse': 'Massage/Bodywork Pro',
        'marketer': 'Marketer',
        'mechanic': 'Mechanic',
        'mobileDetailing': 'Mobile Detailer',
        'moving': 'Mover',
        'music': 'Music',
        'nailCare': 'Nail Tech',
        'notary': 'Notary',
        'other': 'Business',
        'painting': 'Painter',
        'personal': 'Professional Organizer',
        'personalChef': 'Chef',
        'pestControl': 'Pest Controller',
        'petGrooming': 'Pet Groomer',
        'petSitting': 'Pet Sitter',
        'phoneRepair': 'Equipment Repair',
        'photography': 'Photographer',
        'pilates': 'Pilates Instructor',
        'plumbing': 'Plumber',
        'realEstate': 'Rental Property Owner',
        'socialMedia': 'Social Influencer',
        'swimming': 'Swim Instructor',
        'tailor': 'Seamstress & Tailor',
        'tattooArtist': 'Tattoo Artist',
        'taxi': 'Taxi',
        'techSupport': 'Tech Support',
        'tennis': 'Tennis Instructor',
        'therapy': 'Therapist',
        'towing': 'Towing',
        'tutoring': 'Tutor',
        'videography': 'Videographer',
        'virtual': 'Virtual Assistant',
        'weddingPlanning': 'Wedding Planner',
        'windowCleaning': 'Pressure Washer',
        'yoga': 'Yoga Instructor',
    }

    return new Promise<string>((resolve, reject) => {
        localforage.getItem('industries').then((industries: any) => {
            if (!industries) {
                resolve(lookup[key] || '')
                return
            }

            const result = industries.filter((k: any) => k.id === key)
            if (result.length === 1)
                resolve(result[0].title)
            else
                resolve(lookup[key] || '')
        })
    })
}

export type Industry = {
    id: string,
    name: string,
    title: string,
    mcc: string,

    duration: number,
    churn_days: number,
    gratuity: boolean,
    estimate: boolean,
    recurring: boolean,
    classes: boolean,
    services: boolean,
    reservations: boolean,
    channel: string,
    type: string,
    product: boolean,

    large_payment: string,
    median_payment: string,

    cancelled_date: string,
    created_date: string,
    last_modified_date: string,

    features: string,
    tier: number,
    image_uri: string,
    edition: string,
    pluralized_title: string,
}

type ColorData = {
    color: string,
    label: string,
    type: 'color',
}

type ImageData = {
    id: string,
    url: string,
    type: 'image',
}

export type IndustryResponseData = {
    colors: ColorData[],
    images: ImageData[],
    industry: Industry,
}