import React from "react";
import {ReactComponent as GiftCertIcon} from "../../icons/ps_settings_gift_certs_nobg.svg";
import {ReactComponent as IncomeIcon} from "../../icons/ps_general_income_nobg.svg";
import {ReactComponent as InvoiceIcon} from "../../icons/ps_general_invoice_nobg.svg";
import {ReactComponent as BookingsIcon} from "../../icons/ps_general_double_booking_nobg.svg";
import {ReactComponent as ReminderIcon} from "../../icons/ps_general_reminder_nobg.svg";
import {ReactComponent as PackagesIcon} from "../../icons/ps_settings_packages_nobg.svg";
import {ReactComponent as SubscriptionsIcon} from "../../icons/ps_settings_subscriptions_nobg.svg";
import {ReactComponent as PeopleIcon} from "../../icons/ps_general_person_nobg.svg";
import {ReactComponent as ScheduleIcon} from "../../icons/ps_settings_schedule_nobg.svg";
import {ReactComponent as MoneyIcon} from "../../icons/ps_general_money_nobg.svg";
import {ReactComponent as FormsIcon} from "../../icons/ps_settings_forms_nobg.svg";
import {ReactComponent as ClassesIcon} from "../../icons/ps_settings_classes_nobg.svg";
import {ReactComponent as GratuityIcon} from "../../icons/ps_settings_gratuity_nobg.svg";
import {ReactComponent as FeaturesIcon} from "../../icons/ps_settings_features_nobg.svg";
import {ReactComponent as ContractsIcon} from "../../icons/ps_settings_contracts_nobg.svg";
import {ReactComponent as ChatIcon} from "../../icons/ps_general_chat_nobg.svg";
import {ReactComponent as CancellationIcon} from "../../icons/ps_settings_cancellations_nobg.svg";
import {ReactComponent as PaymentMethodsIcon} from "../../icons/ps_settings_payment_methods_nobg.svg";
import {ReactComponent as CalendarSyncIcon} from "../../icons/ps_settings_calendary_sync_nobg.svg";
import {ReactComponent as OnlineBookingIcon} from "../../icons/ps_settings_online_booking_nobg.svg";
import {ReactComponent as InventoryIcon} from "../../icons/inventory_nobg.svg";
import {ReactComponent as ItemsPurchasedIcon} from "../../icons/items_purchased_nobg.svg";
import {ReactComponent as ScheduledInvoiceIcon} from "../../icons/scheduled_invoice_nobg.svg";
import {Dashboard} from "../../models/Dashboard";

type Props = {
    dashboard: Dashboard;
}

export const DashboardIcon = ({dashboard}: Props) => {
    switch (dashboard.id) {
        case 'income': return <IncomeIcon />
        case 'complete': return <ReminderIcon />
        case 'bookings': return <BookingsIcon />
        case 'packages': return <PackagesIcon />
        case 'employees': return <PeopleIcon />
        case 'subscriptions': return <SubscriptionsIcon />
        case 'pending': return <ScheduleIcon />
        case 'clients': return <PeopleIcon />
        case 'suite': return <OnlineBookingIcon />
        case 'deposits': return <MoneyIcon />
        case 'records': return <FormsIcon />
        case 'classes': return <ClassesIcon />
        case 'contracts': return <ContractsIcon />
        case 'estimates': return <GratuityIcon />
        case 'triggers': return <ChatIcon />
        case 'inventory': return <InventoryIcon />
        case 'items': return <ItemsPurchasedIcon />
        case 'tasks': return <FeaturesIcon />
        case 'expenses': return <PaymentMethodsIcon />
        case 'cancellations': return <CancellationIcon />
        case 'billings': return <ScheduledInvoiceIcon />
        case 'gifts': return <GiftCertIcon />
        case 'schedule': return <ScheduleIcon />
        case 'rebooks': return <CalendarSyncIcon />
        case 'invoices': return <InvoiceIcon />
        default: return <InvoiceIcon />
    }
}