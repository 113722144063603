import axios, {AxiosResponse} from 'axios'
import { push } from "connected-react-router"
import localforage from 'localforage'
import promiseFinally from 'promise.prototype.finally'
import useragent from 'ua-parser-js'
import { InvalidDataError } from '../models/errors/InvalidDataError'

promiseFinally.shim()

export const API_BASE = process.env.REACT_APP_API_HOST || 'https://app.pocketsuite.io'
export const WEB_BASE = process.env.REACT_APP_BOOK_HOST || 'https://pocketsuite.io'

let browserId = localStorage.getItem('id')
if (!browserId) {
    // @ts-ignore allow msCrypto fallback
    const crypto = window.crypto || window.msCrypto

    const randomInt = crypto.getRandomValues(new Uint32Array(1))[0]
    browserId = randomInt.toString(16)
    localStorage.setItem('id', browserId)
}

const agent = useragent(navigator.userAgent)

export const apiClient = axios.create({
    baseURL: API_BASE,
    withCredentials: true,
    headers: {
        'X-Requested-With': 'DesktopPSRequest',
        'X-Requested-OS': `${agent.os.name} ${agent.os.version}`.substr(0, 19),
        'X-Requested-Build': '1000',
        'X-Requested-Device': browserId,
        'X-Requested-Make': 'desktop',
        'X-Requested-Type': `${agent.browser.name} ${agent.browser.version}`.substr(0, 49).replace('.', '_'),
    }
})

export const generateCancelToken = () => axios.CancelToken.source()

export const apiClientSetup = (dispatch: any) => {
    apiClient.interceptors.response.use((response) => {
        const loginRedirect : boolean = (response.status === 302 && response.headers['Location'].startsWith('/login'))
        const loginTimeout : boolean = (response.status === 206 && response.data.code === 'LOGIN_TIMEOUT')
        const disabledLogin : boolean = (response.status === 206 && response.data.code === 'ERR_USER_ACCOUNT_LOCKED_OUT')
        if (loginRedirect || loginTimeout || disabledLogin) {
            // If response is auth error, redirect user to /login
            // call logoutUser to be DRY
            localforage.clear();
            localStorage.clear();
            dispatch(push('/login'))
            return new Promise(() => {})
        }
        else if (response.status === 206) {
            throw new InvalidDataError(response.data?.message || 'Invalid input data')
        }
        else if (response.status >= 400) {
            throw response
        }
        return response
    })
}

export const registerDevice = (token: any) => {
    const body : any = {
        address: token
    }

    Promise.all([
        localforage.getItem('device'),
        localforage.getItem('savedToken'),
    ]).then(values => {
        const [device, savedToken]: [any, any] = values

        if (!device) {
            console.error('No device found, cannot register')
            return
        }

        // If device address is the same, skip it
        if (savedToken === token) {
            return
        }

        body.ttl = device?.ttl

        apiClient.post(`/device/${device.id}`, body)
            .then(resp => resp.data)
            .then((json) => {
                localforage.setItem('savedToken', json.device.address)
            })
            .catch(console.log)
    })
}
