import React from "react";

import "./psformfieldselection.css";
import {UserInstance} from "../../models/User";

type Props = {
    icon?: React.ReactNode,
    label: string,
    helperLabel?: string,
    selectedLabel?: string | React.ReactNode,
    linkText?: string,
    selection?: UserInstance | {name: string},
    extraComponent?: React.ReactNode,
    hideButton?: boolean,
    onClick: () => void,
}

export const PSFormFieldSelection = ({icon, label, helperLabel, selectedLabel, linkText, selection, extraComponent, hideButton, onClick}: Props) => {
    return (
        <div className="formFieldSelection">
            {icon && (
                <div className="icon">{icon}</div>
            )}
            <div className="labels">
                <div className="label">{label}</div>
                {selection && (
                    <div className="selected" style={{width: extraComponent ? '360px' : '510px'}}>{selection.name}</div>
                )}
                {helperLabel && !selection && !selectedLabel && (
                    <div className="helper">{helperLabel}</div>
                )}
                {selectedLabel && (
                    <div className="selected" style={{width: extraComponent ? '360px' : '510px'}}>{selectedLabel}</div>
                )}
            </div>
            <div className="action">
                {extraComponent && (
                    <div style={{flex: '1 0 auto'}}>
                        {extraComponent}
                    </div>
                )}
                {!hideButton && (
                    <div className="buttonLink"
                         onClick={() => onClick()}
                    >
                        {linkText || 'Select'}
                    </div>
                )}
            </div>
        </div>
    )
}