import React, {useEffect, useState} from "react";
import {useAppDispatch, usePSOwner} from "../../../hooks";
import {
    saveUser,
    SettingsState
} from "../../../modules/settings";
import {
    Field,
    Label,
} from "@zendeskgarden/react-forms";
import {RootState} from "../../../store";
import {useSelector} from "react-redux";
import {PSDropdown} from "../../app/PSDropdown";
import {Prompt} from "react-router";
import {PSButtonPrimary} from "../../app/PSButton";
import {Dots} from "@zendeskgarden/react-loaders";
import localforage from 'localforage'
import {findSelection, SelectOption} from "../Options";
import {deadlineOptions, packagePolicyOptions} from "../Options";
import {Item} from "@zendeskgarden/react-dropdowns";
import {PSLearnMore} from "../../app/PSLearnMore";
import {LearnMoreCancellationPolicy} from "../LearnMore";
import {PSRatePicker} from "../../app/PSRatePicker";
import {PSTextarea} from "../../app/PSTextarea";
import {isEmpty} from '../../../utils';
import { UserInstance } from "../../../models/User";


export const CancellationEdit = () => {
    const findDeadlineSelection = (user: UserInstance | undefined) => findSelection(deadlineOptions, user?.cancellation_duration)
    const findPackagePolicySelection = (user: UserInstance | undefined) => findSelection(packagePolicyOptions, user?.package_cancellation_type)

    const [learnMoreComponent, showLearnMore] = PSLearnMore([
        { key: 'cancel-policy', title: 'Cancellation policy', body: LearnMoreCancellationPolicy },
    ]);

    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const [saveEnabled, setSaveEnabled] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)
    const [user, setUser] = useState<UserInstance>()
    const owner = usePSOwner()

    const [cancelAmount, setCancelAmount] = useState<Number | undefined>()
    const [cancelRateType, setCancelRateType] = useState('')
    const [packageCancelAmount, setPackageCancelAmount] = useState<Number | undefined>()
    const [packageRateType, setPackageRateType] = useState('')
    const [cancellationPolicy, setCancellationPolicy] = useState('')
    const [showSubscriptionSection, setShowSubscriptionSection] = useState(true)
    const [deadline, setDeadline] = useState<SelectOption | undefined>(findDeadlineSelection(user))
    const [packagePolicy, setPackagePolicy] = useState<SelectOption | undefined>(findPackagePolicySelection(user))

    useEffect(() => {
        if (!user || isEmpty(user)) return

        const edits =
            Number(user.cancellation_rate) !== (cancelAmount ?? 0) ||
            user.cancellation_rate_type !== cancelRateType ||
            Number(user.subscription_cancellation_rate) !== (packageCancelAmount ?? 0) ||
            user.subscription_cancellation_rate_type !== packageRateType ||
            user.cancellation_policy !== cancellationPolicy ||
            user.package_cancellation_type !== (packagePolicy?.value ?? '') ||
            user.cancellation_duration !== (deadline?.value ?? '')

        setHasEdits(edits)
    }, [user, cancelAmount, cancellationPolicy, cancelRateType, packageCancelAmount, packageRateType, deadline, packagePolicy])

    useEffect(() => {
        setSaveEnabled(hasEdits)
    }, [hasEdits])

    useEffect(() => {
        if (!owner) return

        if (owner.cancellation_rate) setCancelAmount(Number(owner.cancellation_rate))
        else setCancelAmount(undefined)
        setCancelRateType(owner.cancellation_rate_type)

        if (owner.subscription_cancellation_rate) setPackageCancelAmount(Number(owner.subscription_cancellation_rate))
        else setPackageCancelAmount(undefined)
        setPackageRateType(owner.subscription_cancellation_rate_type)

        setCancellationPolicy(owner.cancellation_policy)
        setPackagePolicy(findPackagePolicySelection(owner))
        setDeadline(findDeadlineSelection(owner))

        setShowSubscriptionSection(owner.allow_subscription)
    }, [user, owner])

    useEffect(() => {
        localforage.getItem('user').then((user: any) => setUser(user))
    }, [])

    const onSave = () => {
        if (!user || state.isSending) return

        const userParams: any = {}
        userParams.id = user.id;

        userParams.cancellation_rate = cancelAmount ?? null
        userParams.cancellation_rate_type = cancelRateType
        userParams.subscription_cancellation_rate = packageCancelAmount ?? null
        userParams.subscription_cancellation_rate_type = packageRateType
        userParams.cancellation_policy = cancellationPolicy
        userParams.package_cancellation_type = packagePolicy?.value ?? null
        userParams.cancellation_duration = deadline?.value ?? null

        // @ts-ignore
        dispatch(saveUser(userParams)).then(() => localforage.getItem('user').then((user: any) => setUser(user)))
    }

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Cancellations</h1>
                <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!saveEnabled || state.isSending}>
                    {state.isSending ? <Dots /> : "Save"}
                </PSButtonPrimary>
            </div>


            <div className="form">
                <PSRatePicker
                    label={'Late fee'}
                    keyPrefix="cancel"
                    amount={cancelAmount} setAmount={setCancelAmount}
                    rateType={cancelRateType} setRateType={setCancelRateType} />

                <PSDropdown selected={deadline}
                            nameProperty={"label"}
                            onSelect={(selected) => setDeadline(selected)}
                            label={"Deadline"}
                            disablePlaceholderClick={true}
                            placeholder={<div className="grey">Select deadline</div>}
                            hint={"If your client confirms then cancels after this deadline they will be charged"}>
                    <>
                        {deadlineOptions.map(option => (
                            <Item key={`deadline-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>
                {showSubscriptionSection && (
                    <>
                        <PSDropdown selected={packagePolicy}
                                    nameProperty={"label"}
                                    onSelect={(selected) => setPackagePolicy(selected)}
                                    label={"Package policy"}
                                    disablePlaceholderClick={true}
                                    placeholder={<div className="grey">Select option</div>}>
                            <>
                                {packagePolicyOptions.map(option => (
                                    <Item key={`packagepolicy-${option.value}`} value={option}>
                                        {option.label}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>
                        <PSRatePicker
                            label={'Subscription fee'}
                            hint={'When a subscription is confirmed with a credit card and cancelled after the first payment this fee will automatically be charged'}
                            keyPrefix="cancel-package"
                            amount={packageCancelAmount} setAmount={setPackageCancelAmount}
                            rateType={packageRateType} setRateType={setPackageRateType} />
                    </>
                )}
                <Field className="field">
                    <Label className="label">Cancellation Policy</Label>
                    <PSTextarea placeholder="Add cancellation policy"
                                className="input"
                                minRows={8}
                                maxLength={999}
                                disabled={state.isSending}
                                value={cancellationPolicy}
                                onChange={value => setCancellationPolicy(value)}
                                onLearnMore={() => showLearnMore('cancel-policy')}/>
                </Field>
            </div>

            {learnMoreComponent}

            <Prompt
                when={hasEdits && !state.isSending}
                message="Are you sure you'd like to leave this page without saving your changes?" />

        </div>
    )
}