import React from "react";
import {AppHeader} from "../../app/AppHeader";
import {ReactComponent as CheckmarkImage} from "../../../icons/big_checkmark.svg";
import {Link} from "react-router-dom";
import {useLocation, useParams} from "react-router";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import { TransactionProgress } from "../TransactionProgress";

export const GiftSuccess = () => {
    const state: any = useSelector((state: RootState) => state.entities)
    const params: { id?: string } = useParams()

    const location = useLocation()
    const locationState: any = location.state

    const user = state.users[locationState?.client?.id] || {}
    let successMessage = `The gift certificate with ${user?.name || 'your client'} was saved`

    return (
        <AppHeader title="Subscriptions"
                   middleWidget={<TransactionProgress created reviewed sent secondStep='Preview' thirdStep='Save' />}
        >
            <div className="transactionSuccess">
                <CheckmarkImage/>

                <h1>Success</h1>
                <div className="details">{successMessage}</div>

                <div className="links">
                <div className="link">
                        <Link to="/gift/new">Create another</Link>
                        <div>Sell another gift certificate</div>
                    </div>
                    <div className="link">
                        <Link to={`/record/gift/${params.id}`}>View gift certificate</Link>
                        <div>View this gift certificate</div>
                    </div>
                    <div className="link">
                        <Link to={`/calendar`}>Calendar</Link>
                        <div>View your calendar</div>
                    </div>
                    <div className="link">
                        <Link to="/">Dashboard</Link>
                        <div>View your PocketSuite home screen</div>
                    </div>
                </div>
            </div>
        </AppHeader>
    )
}
