import React from "react";
import {AppHeader} from "../app/AppHeader";
import {TransactionProgress} from "./TransactionProgress";
import {ReactComponent as CheckmarkImage} from "../../icons/big_checkmark.svg";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {useLocation} from "react-router";

export const TransactionSuccess = () => {
    const state: any = useSelector((state: RootState) => state.entities)

    const location = useLocation()
    const locationState: any = location.state
    const notify: boolean = locationState.notify
    const booking: any = locationState?.booking

    const user = state.users[booking?.client] || {}
    let successMessage
    if  (booking.created_date === booking.last_modified_date && notify)
        successMessage = `The appointment was sent to ${user?.name || 'your client'}`
    else
        successMessage = `The appointment with ${user?.name || 'your client'} was saved`

    return (
        <AppHeader title="Schedule client"
                   showBackButton
                   middleWidget={<TransactionProgress created reviewed sent/>}
        >
            <div className="transactionSuccess">
                <CheckmarkImage/>
                <h1>Success</h1>
                <div className="details">{successMessage}</div>

                <div className="links">
                    <div className="link">
                        <Link to="/">Dashboard</Link>
                        <div>View your PocketSuite home screen</div>
                    </div>
                    <div className="link">
                        <Link to="/calendar">Calendar</Link>
                        <div>View your calendar</div>
                    </div>
                    <div className="link">
                        <Link to="/messages">Inbox</Link>
                        <div>View your inbox</div>
                    </div>
                    <div className="link">
                        <Link to={`/record/booking/${booking?.id}`}>
                            View appointment
                        </Link>
                        <div>View this appointment record</div>
                    </div>
                    <div className="link">
                        <Link to="/schedule/new">Schedule another</Link>
                        <div>Schedule another appointment</div>
                    </div>
                </div>
            </div>
        </AppHeader>
    )
}