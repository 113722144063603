import React, {useCallback, useEffect, useState} from "react";
import { useHistory } from "react-router";
import {useAppDispatch, usePSUser} from "../../hooks";
import {
    SettingsState,
    fetchActiveSubscriptions,
    fetchPlans,
    fetchEdition,
    subscribe,
    PlanResponse
} from "../../modules/settings";
import {RootState} from "../../store";
import {useSelector} from "react-redux";
import {Spinner} from "@zendeskgarden/react-loaders";
import {PSPlanCardView} from "../app/PSPlanCardView";
import {POCKETSUITE_USER_ID} from "../../utils/constants";
import moment from "moment";
import { Plan } from "../../models/Plan";
import { Subscription } from "../../models/Subscription";
import { processError } from "../../modules/error";
import { Edition } from "../../models/Edition";
import { PSButton, PSButtonPrimary } from "../app/PSButton";
import { SuccessModal } from "./SuccessModal";
import { Result } from "../../utils";
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { GuardrailModal } from "./GuardrailModal";
import {Item} from "../../models/Item";
import {BasicPlanConfirmationModal} from "./BasicPlanConfirmationModal";
import './settings.css'
import { Avatar } from "@zendeskgarden/react-avatars";
import { SetupBilling } from "./SetupBilling";
import { CardEdit } from "./payment-methods/CardEdit";
import localforage from "localforage";
import { push } from "connected-react-router";
import {markDashboard} from "../../modules/dashboard";
import {currencyFormat} from "../../utils/numbers";

export const CHANGE_PLAN_CONTEXT = "change"
export const NEW_PLAN_CONTEXT = "new"
export const TEAM_PLAN_CONTEXT = "team"
export const COMEBACK_CONTEXT = "comeback"
export const FEATURE_UPGRADE_CONTEXT = "feature"
export const AFFILIATE_PLAN_CONTEXT = "affiliate"

const ACTION_BUTTON = 1
const ACTION_EXTRABUTTON = 2
type GuardrailMetadata = {
    price?: string,
    currentTier: string,
    buttonText: string,
    extraButtonText?:string,
    handleClick: (buttonIx?: typeof ACTION_BUTTON | typeof ACTION_EXTRABUTTON) => void
}

export type HandleUpgradeOpts = {
    cancel?: boolean,
}

type Props = {
    context: typeof CHANGE_PLAN_CONTEXT | typeof NEW_PLAN_CONTEXT | typeof TEAM_PLAN_CONTEXT | typeof COMEBACK_CONTEXT | typeof FEATURE_UPGRADE_CONTEXT | typeof AFFILIATE_PLAN_CONTEXT,
    premiumItems?: Item[], // For ABMP Basic confirmation
    planId?: string,
    onNext: () => void,
    onSkip?: () => void,
    handleUpgrade?: (opts?: HandleUpgradeOpts) => void,
}

type UseSelectPlanReturnType = [() => void, JSX.Element]
export const useSelectPlan = (props : Props): UseSelectPlanReturnType => {
    const {context, premiumItems, planId, onNext, onSkip, handleUpgrade} = props
    const dispatch = useAppDispatch()
    const history = useHistory()

    const settingsState: SettingsState = useSelector((state: RootState) => state.settings)
    const [loading, setLoading] = useState(true)
    const [edition, setEdition] = useState<Edition>()
    const user = usePSUser()

    const [planGuardrail, setPlanGuardRail] = useState<GuardrailMetadata>()
    const [showUpgradeGuardrail, setShowUpgradeGuardrail] = useState(false)
    const [showDowngradeGuardrail, setShowDowngradeGuardrail] = useState(false)
    const [showCancelGuardrail, setShowCancelGuardrail] = useState(false)
    const [showDoubleDownGuardrail, setShowDoubleDownGuardrail] = useState(false)
    const [showContactUsGuardrail, setShowContactUsGuardrail] = useState(false)

    const [showSuccessfulDoubleDown, setShowSuccessfulDoubleDown] = useState(false)
    const [showSuccessfulTrialUpgrade, setShowSuccessfulTrialUpgrade] = useState(false)
    const [planTitle, setPlanTitle] = useState('')
    const [showBasicConfirmation, setShowBasicConfirmation] = useState(false)

    const [response, setResponse] = useState<PlanResponse>()

    useEffect(() => {
        if (!user) return

        Promise.all([
            dispatch(fetchActiveSubscriptions(user.id)),
            Result(dispatch(fetchPlans(user.id, context)))
                .then(result => {
                    setResponse(result)
                })
        ])
        .catch(error => {
            dispatch(processError(error))
            setLoading(false)
        })

    }, [dispatch, user, context])

    useEffect(() => {
        const editionId = settingsState.plans?.find(plan => plan.plan === "premium" && plan.edition)?.edition || user?.edition
        if (editionId) {
            Result(dispatch(fetchEdition(editionId)))
                .then((found) => setEdition(found))
                .finally(() => setLoading(false))
        }
        else if (settingsState.plans.length > 0) {
            setLoading(false)
        }

    }, [settingsState.plans, user?.edition, dispatch])

    const isSoldByPocketSuite = (subscription: Subscription) =>
        subscription.owner === POCKETSUITE_USER_ID && !!subscription.confirmed_date

    const isLessThan90DaysAgo = (cancelled_date: string) =>
        moment(cancelled_date).diff(moment(), 'days') < 90

    const allowFreeTrial = useCallback(() =>
        (settingsState.activeSubscriptions)
            .find(subscription => isSoldByPocketSuite(subscription) &&
                (!subscription.cancelled_date || isLessThan90DaysAgo(subscription.cancelled_date!)))
        === undefined
    , [settingsState.activeSubscriptions])

    const calculateTier = (tierName: string | undefined) => {
        return {
            "free": 1,
            "premium": 2,
            "plus": 3,
            "suite": 4
        }[tierName ?? ""] ?? 1
    }

    const tappedContactUs = useCallback((plan: Plan) => {
        setSelectedPlan(plan);
        setShowContactUsGuardrail(true);
    }, [user?.id, edition?.id, dispatch])

    const tappedDoubleDown = useCallback((plan: Plan) => {
        setLoading(true);
        setSelectedPlan(plan);
        Result(dispatch(markDashboard('alerts_affiliate', 'buy_annual')))
            .then(()=>{
                setShowSuccessfulDoubleDown(true)
            })
            .finally(() => setLoading(false))
    }, [user?.id, edition?.id, dispatch])

    const tappedTestDrive = useCallback((plan: Plan) => {
        if (!user?.id) return

        setLoading(true)
        setPlanTitle(plan.title ?? '')
        Result(dispatch(subscribe({userId: user.id, plan: plan.plan, trialPeriod: 30, editionId: edition?.id})))
            .then(() => setShowSuccessfulTrialUpgrade(true))
            .finally(() => setLoading(false))

    }, [user?.id, edition?.id, dispatch])

    const [selectedPlan, setSelectedPlan] = useState<Plan>()
    const [showBilling, setShowBilling] = useState(false)
    const [showCardEdit, setShowCardEdit] = useState(false)
    const tappedSubscribe = useCallback((plan: Plan) => {
        if (!user?.id) return

        setPlanGuardRail(undefined)

        setSelectedPlan(plan)
        setShowBilling(true)
    }, [user?.id])

    const onBack = useCallback(() => {
        setShowCardEdit((showCardEditValue) => {
            setShowBilling((showBillingValue) => {
                if (!showCardEditValue && !showBillingValue) history.goBack()

                return showCardEditValue
            })
            return false
        })
    }, [history])

    const tappedUnsubscribe = useCallback((_: Plan) => {
        dispatch(push('/settings/change-plan/cancel'))
    }, [dispatch])

    const getNextAction = useCallback((action: 'subscribe' | 'unsubscribe' | 'testdrive' | 'doubledown' | 'contactus') => {
        return {
            'contactus': tappedContactUs,
            'subscribe': tappedSubscribe,
            'testdrive': tappedTestDrive,
            'doubledown': tappedDoubleDown,
            'unsubscribe': tappedUnsubscribe
        }[action]
    }, [tappedSubscribe, tappedTestDrive, tappedUnsubscribe, tappedDoubleDown])

    const planSelected = useCallback((plan: Plan) => {
        const planId = plan.plan

        if (planId === 'free' && [NEW_PLAN_CONTEXT, COMEBACK_CONTEXT].includes(context)) {
            localforage.getItem('user').then((u: any) => {
                localforage.setItem('user', {
                    ...u,
                    requires_upgrade: false
                })

                if (premiumItems && premiumItems.length > 0) {
                    setShowBasicConfirmation(true)
                }
                else if (onNext) {
                    onNext()
                }
            })
            return
        }

        setPlanGuardRail({
            buttonText: (plan.subscribe_button_actions?.length ?? 0) > 0 ? plan.subscribe_button_actions![0].alert ?? 'Upgrade' : 'Upgrade',
            extraButtonText: (plan.subscribe_button_actions?.length ?? 0) > 1 ? plan.subscribe_button_actions![1].alert : undefined,
            price: plan.price,
            currentTier: plan.title ?? '',
            handleClick: (buttonIx?: typeof ACTION_BUTTON | typeof ACTION_EXTRABUTTON) => {
                if (plan.subscribe_button_actions?.length ?? 0 > 1) {
                    let action = undefined;
                    if (buttonIx === ACTION_BUTTON) {
                        action = getNextAction(plan.subscribe_button_actions![0].id)
                    }
                    else {
                        action = getNextAction(plan.subscribe_button_actions![1].id)
                    }
                    if (action) action(plan)
                }
            }
        })
        const currentTier = calculateTier(user?.plan)
        const requestedTier = calculateTier(planId)
        const forContactUs = (plan.subscribe_button_actions?.length ?? 0) == 1 && plan.subscribe_button_actions![0].id == "contactus";

        if (context === AFFILIATE_PLAN_CONTEXT) setShowDoubleDownGuardrail(true);
        else if (requestedTier === currentTier && forContactUs) setShowContactUsGuardrail(true)
        else if (requestedTier === currentTier && planId !== 'free') setShowCancelGuardrail(true)
        else if (requestedTier < currentTier) setShowDowngradeGuardrail(true)
        else if (requestedTier > currentTier) setShowUpgradeGuardrail(true)
        else if (onNext) onNext()

    }, [user, context, premiumItems, onNext, getNextAction])

    const closeSuccessModal = useCallback(() => {
        setPlanGuardRail(undefined)
        setShowSuccessfulTrialUpgrade(false)

        if (onNext) onNext()
    }, [onNext])

    const renderPlan = useCallback((plan: Plan) => {
        if (!user) return

        const allowTrial = allowFreeTrial()
        return (
            <PSPlanCardView key={`plan-card-${plan.plan}`}
                            allowFreeTrial={allowTrial}
                            plan={plan}
                            onPlanSelected={planSelected}
                            edition={edition}
                            industry={user?.industry}
            />
        )
    }, [edition, user, allowFreeTrial, planSelected])

    useEffect(() => {
        if (context !== FEATURE_UPGRADE_CONTEXT || !user || !response || !planId || planGuardrail) return

        const plan = settingsState.plans?.find(p => p.plan === planId)
        if (plan) planSelected(plan)
    }, [context, user, response, planId, planGuardrail, settingsState.plans, planSelected])

    const onUpgradeGuardrailClose = () => {
        setShowUpgradeGuardrail(false)
        if (handleUpgrade) handleUpgrade({ cancel: true })
    }

    const onUpgradeGuardrailAction = (guardRail: GuardrailMetadata, buttonIx: typeof ACTION_BUTTON | typeof ACTION_EXTRABUTTON) => {
        guardRail.handleClick(buttonIx)
        if (handleUpgrade) handleUpgrade()
    }

    const UpgradeGuardrailModal = ({ guardRail }: { guardRail: GuardrailMetadata }) => (
        <Modal onClose={() => onUpgradeGuardrailClose()}>
            <Header style={{display: 'flex'}}>
                <span style={{fontWeight: 700, paddingLeft: 8}}>{guardRail.currentTier}</span>
            </Header>
            <Body>
                {guardRail.extraButtonText ?
                    <p>Select whether you'd like to start a 30-day free trial or upgrade to {guardRail.currentTier}{guardRail.price ? ` for $${guardRail.price}/month.` : '.'}</p> :
                    <p>Would you like to upgrade your PocketSuite subscription?</p>  }
            </Body>
            <Footer>
                {guardRail.extraButtonText && (
                    <FooterItem>
                        <PSButton onClick={() => onUpgradeGuardrailAction(guardRail, ACTION_EXTRABUTTON)}>
                            {guardRail.extraButtonText}
                        </PSButton>
                    </FooterItem>
                )}
                <FooterItem>
                    <PSButtonPrimary onClick={() => onUpgradeGuardrailAction(guardRail, ACTION_BUTTON)}>
                        {guardRail.buttonText}
                    </PSButtonPrimary>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )

    const DowngradeGuardrailModal = ({ guardRail }: { guardRail: GuardrailMetadata }) => (
        <GuardrailModal
            disableDismiss
            title="Downgrade"
            buttonText={guardRail.buttonText}
            body={<p>Would you like to downgrade to PocketSuite {guardRail.currentTier}?</p>}
            onClose={() => setShowDowngradeGuardrail(false)}
            onAction={() => guardRail.handleClick(ACTION_BUTTON)}
        />
    )

    const CancelGuardrailModal = ({ guardRail }: { guardRail: GuardrailMetadata }) => (
        <GuardrailModal
            disableDismiss
            title="Cancel"
            buttonText={guardRail.buttonText}
            body={<p>Would you like to cancel your PocketSuite subscription?</p>}
            onClose={() => setShowCancelGuardrail(false)}
            onAction={() => guardRail.handleClick(ACTION_BUTTON)}
        />
    )

    const DoubleDownGuardrailModal = ({ guardRail }: { guardRail: GuardrailMetadata }) => (
        <GuardrailModal
            disableDismiss
            title="Upgrade"
            buttonText={guardRail.buttonText}
            body={<p>Would you like to upgrade to the annual {guardRail.currentTier.toLowerCase()} plan and save $50?</p>}
            onClose={() => setShowDoubleDownGuardrail(false)}
            onAction={() => guardRail.handleClick(ACTION_BUTTON)}
        />
    )

    const ContactUsGuardrailModal = ({ guardRail }: { guardRail: GuardrailMetadata }) => (
        <GuardrailModal
            disableDismiss
            title="Contact us"
            buttonText="Dismiss"
            body={<p>{guardRail.buttonText}</p>}
            onClose={() => setShowContactUsGuardrail(false)}
            onAction={() => {
                setPlanGuardRail(undefined)
                setShowContactUsGuardrail(false)
            }}
        />
    )

    const renderSelectPlanWorkflow = (): JSX.Element => {
        if (showBilling && selectedPlan) {
            return (
                <>
                    {!showCardEdit && (
                        <SetupBilling
                            plan={selectedPlan}
                            setShowCardEdit={setShowCardEdit}
                            onNext={onNext}
                            onBack={context !== NEW_PLAN_CONTEXT ? (!showCardEdit && handleUpgrade ? () => handleUpgrade({ cancel: true }) : onBack) : null}
                        />
                    )}
                    {showCardEdit && <CardEdit onBack={() => setShowCardEdit(false)} />}
                </>
            )
        }

        if (settingsState.isSending || loading || !user) {
            return (
                <div className="servicesSettings">
                    <div className="header">
                        <div style={{textAlign: 'center', padding: '128px 0'}}>
                            <Spinner size="128" color="#314A68" />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <>
                {context !== FEATURE_UPGRADE_CONTEXT && (
                    <div className="servicesSettings" style={{marginBottom: '70px'}}>
                        <div className="header">
                            {response?.welcome_image_uri && (
                                <Avatar size='small' style={{marginRight: '10px'}} >
                                    <img src={response.welcome_image_uri} alt='welcome-back-img' />
                                </Avatar>)}
                            {response?.welcome_title && <h1>{response.welcome_title}</h1>}
                            {response?.title && <h1>{response.title}</h1>}

                            {(edition?.allow_basic_plan || user?.plan) && onSkip &&
                                <PSButton style={{height: '40px'}} onClick={() => { if (onSkip) onSkip() }}>
                                    <p className="bold">Skip</p>
                                </PSButton>
                            }
                        </div>

                        {response?.welcome && <p className='welcome'>{response.welcome}</p>}
                        {response?.welcome_subtitle && <h1>{response.welcome_subtitle}</h1>}

                        {context !== COMEBACK_CONTEXT && <div className="separator" />}

                        {settingsState.plans.map(plan => renderPlan(plan))}
                    </div>
                )}

                {planGuardrail && showUpgradeGuardrail && <UpgradeGuardrailModal guardRail={planGuardrail} />}
                {planGuardrail && showDowngradeGuardrail && <DowngradeGuardrailModal guardRail={planGuardrail} />}
                {planGuardrail && showCancelGuardrail && <CancelGuardrailModal guardRail={planGuardrail} />}
                {planGuardrail && showDoubleDownGuardrail && <DoubleDownGuardrailModal guardRail={planGuardrail} />}
                {planGuardrail && showContactUsGuardrail && <ContactUsGuardrailModal guardRail={planGuardrail} />}

                {showSuccessfulTrialUpgrade &&
                    <SuccessModal
                        title="Success!"
                        buttonText={context === CHANGE_PLAN_CONTEXT ? "Done" : "Next"}
                        body={<p>You’ve just upgraded to a 30 day free trial of PocketSuite {planTitle}!</p>}
                        onClose={() => {
                            closeSuccessModal()
                            if (onNext) onNext()
                        }}
                        onAction={() => {
                            closeSuccessModal()
                            if (onNext) onNext()
                        }}
                    />
                }

                {showSuccessfulDoubleDown && selectedPlan &&
                    <SuccessModal
                        title="Congratulations!"
                        buttonText={"Okay"}
                        body={<p>You have successfully authorized an upgrade of your account to our {selectedPlan.title} Annual Plan for {currencyFormat(Number(selectedPlan.yearly) - Number(50))}.</p>}
                        onClose={() => {
                            setPlanGuardRail(undefined)
                            setShowSuccessfulDoubleDown(false)
                            dispatch(push("/"));
                        }}
                        onAction={() => {
                            setPlanGuardRail(undefined)
                            setShowSuccessfulDoubleDown(false)
                            dispatch(push("/"));
                        }}
                    />
                }

                {showBasicConfirmation && premiumItems && premiumItems.length > 0 &&
                    <BasicPlanConfirmationModal
                        premiumItems={premiumItems}
                        onCancel={() => setShowBasicConfirmation(false)}
                        onBasic={() => {
                            setShowBasicConfirmation(false)
                            if (onNext) onNext()
                        }}
                        onPremium={() => {
                            setShowBasicConfirmation(false)
                            const plan = settingsState.plans.filter(plan => plan.plan === "premium")
                            if (plan?.length > 0) {
                                tappedTestDrive(plan[0])
                            }
                        }}
                    />
                }
            </>
        )
    }

    return [onBack, renderSelectPlanWorkflow()]
}

export const SelectPlan = (props : Props) => {
    const [_, selectPlanComponent] = useSelectPlan(props)
    return selectPlanComponent
}