import React, {useCallback, useEffect, useState} from "react";
import { useAppDispatch } from "../../hooks";
import { setGoLiveState } from "../../modules/onboarding";
import { nextGoLiveStep } from "../../utils/onboarding";
import {AppHeader} from "../app/AppHeader";
import {
    NEW_PLAN_CONTEXT,
    useSelectPlan
} from "../settings/SelectPlan";
import {fetchItems} from "../../modules/settings";
import {RootState} from "../../store";
import {useSelector} from "react-redux";
import {Item} from "../../models/Item";
import { Result } from "../../utils";
import { push } from "connected-react-router";

export const GoLiveSelectPlan = () => {
    const dispatch = useAppDispatch()
    const state: Pick<RootState, "settings"> = useSelector((state: RootState) => {
        return {
            settings: state.settings,
        }
    })

    const onNext = useCallback(() =>
        setGoLiveState('gl_widget')
            .then((me) => dispatch(nextGoLiveStep(me)))
    , [dispatch])

    const onSkip = useCallback(() =>
        dispatch(push('/golive/success'))
    , [dispatch])

    const [premiumItems, setPremiumItems] = useState<Item[]>([])
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        Result(dispatch(fetchItems()))
            .finally(() => setIsLoading(false))
    }, [dispatch])

    useEffect(() => {
        if (state.settings.items) {
            setPremiumItems(state.settings.items.filter((i: Item) => i.type === 'package' || i.type === 'subscription'))
        }
    }, [state.settings.items])

    const [onBack, selectPlanComponent] = useSelectPlan({context: NEW_PLAN_CONTEXT, premiumItems, onSkip, onNext})
    return (
        <AppHeader middleWidget={null}
                   showBackButton onBack={onBack}
                   title="Grow Your Business">
            <div className="servicesSettings onboarding launchBusiness">
                {!isLoading && selectPlanComponent}
            </div>
        </AppHeader>
    )
}