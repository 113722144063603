import React, {useEffect, useState} from "react";
import {useAppDispatch, useConfirmModal} from "../../../hooks";
import {
    deleteReader,
    fetchReaders,
    SettingsState
} from "../../../modules/settings";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {push} from "connected-react-router";
import { Item } from '@zendeskgarden/react-dropdowns';
import {PSButton} from "../../app/PSButton";
import { ReaderCard } from "./ReaderCard";
import { Reader } from "../../../models/Reader";
import { Result } from "../../../utils";
import { Spinner } from "@zendeskgarden/react-loaders";
import { PurchaseReader } from "./PurchaseReader";
import { PSButtonDropdown } from "../../app/PSButtonDropdown";

export const CardReaderList = () => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)
    const [setModal, setModalLoading, showModal, closeModal, modalComponent] = useConfirmModal()

    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        Result(dispatch(fetchReaders()))
            .finally(() => setIsLoading(false))
    }, [dispatch])

    const orderReader = () => {
        dispatch(push('/settings/reader/checkout'));
    }

    const addReader = () => {
        dispatch(push('/settings/reader/add'));
    }

    const editReader = (reader: Reader) => {
        dispatch(push(`/settings/reader/${reader.id}/edit`, { reader }));
    }

    const registerReader = (reader: Reader) => {
        dispatch(push(`/settings/reader/${reader.id}/activate`, { reader }));
    }

    const onDelete = (reader: Reader) => {
        setModal({
            body: 'Are you sure you want to remove this reader? You can always add it back.',
            button: 'Cancel',
            optionalButton: 'Remove',
            title: 'Are you sure',
            danger: true,
            onClick: (optionalButton: boolean) => {
                if (optionalButton) {
                    setModalLoading(true)
                    Result(dispatch(deleteReader(reader.id!)))
                        .then(closeModal)
                        .finally(() => setModalLoading(false))
                }
                else {
                    closeModal()
                }
            }
        })
        showModal()
    }

    if (!isLoading && !state.readers.length) {
        return <PurchaseReader />
    }
    if (isLoading) {
        return (
            <div style={{textAlign: 'center', padding: '128px 0'}}>
                <Spinner size="128" color="#314A68" />
            </div>
        )
    }

    return (
        <div className="servicesSettings" style={{margin: '0'}}>
            <div className="header">
                <h1>Card Readers</h1>
                <div className="right">
                    <PSButton style={{height: '40px', marginRight: '8px'}}
                                onClick={() => { window.open("https://pocketsuite.io/pos-card-reader", "_blank")}}>
                        Learn more
                    </PSButton>
                    <PSButtonDropdown primary label ='Add a Reader' onSelect={(button: string) => {
                        if (button === 'new') {
                            orderReader()
                        }
                        else if (button === 'existing') {
                            addReader()
                        }
                    }}>
                        <Item value="new">Order new reader</Item>
                        <Item value="existing">Add existing reader</Item>
                    </PSButtonDropdown>

                </div>
            </div>
            <div className="separator" />
            <div>
                {state.readers
                    .map((reader: Reader) => {
                        return <ReaderCard key={`reader-${reader.id}`}
                                           reader={reader}
                                           onSelect={(action) => {
                                                if (action === 'edit')
                                                    editReader(reader)
                                                else if (action === 'activate')
                                                    registerReader(reader)
                                                else if (action === 'delete')
                                                    onDelete(reader)
                                           }} />
                })}
                {modalComponent}
            </div>
        </div>
    )
}


