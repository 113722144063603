import React, { createRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@zendeskgarden/react-buttons'
import { Field, Input } from '@zendeskgarden/react-forms'
import { Dots } from '@zendeskgarden/react-loaders'
import InputMask from 'react-input-mask'
import { loginWithPhoneNumber } from '../../modules/login'
import {useLocation} from "react-router-dom";
import { e164ToPhone } from '../../utils/phone'
import {BOOK_HOST} from '../../utils/constants'

import './login.css'

export const Login = () => {
    const dispatch = useDispatch()
    const state = useSelector(state => state.login)
    const search = useLocation().search
    const redirectUrl = new URLSearchParams(search).get('redirectUrl')

    const [phoneNumber, setPhoneNumber] = React.useState(state.e164 ? e164ToPhone(state.e164) : '')

    // Set input to phone input entry at load
    const phoneInputRef = createRef()
    useEffect(() => {
        if (phoneInputRef.current)
            phoneInputRef.current.focus()
    }, [phoneInputRef])

    const sendPhoneNumber = (e) => {
        e.preventDefault()
        if (state.isSending) return
        dispatch(loginWithPhoneNumber(phoneNumber, undefined, redirectUrl))
    }

    const isMobile = !!navigator.userAgent.match(/(iPhone|iPod|Android|BlackBerry|Windows Phone|webOS)/i)

    return (
        <div className="login-body">
            <img className="logo"
                 src="/logo-nobriefcase.svg"
                 height="62"
                 width="178"
                 alt="PocketSuite"
            />
            <div className="login-box">
                {isMobile && (
                    <p>Please sign into your account on a tablet or computer</p>
                )}
                {!isMobile && (
                    <>
                        <h3>Sign in</h3>
                        <form onSubmit={sendPhoneNumber}>
                            <p>Enter your mobile number</p>
                            <Field>
                                <InputMask mask="999-999-9999"
                                           value={phoneNumber}
                                           alwaysShowMask={false}
                                           maskChar={''}
                                           onChange={e => setPhoneNumber(e.target.value)}>
                                    {(inputProps) => <Input {...inputProps}
                                                            className="mobileNumberInput"
                                                            placeholder="Enter mobile number"
                                                            ref={phoneInputRef}/>}
                                </InputMask>
                            </Field>
                            <Button className="loginButton"
                                    isPrimary
                                    style={{
                                        padding: '0 16px',
                                        lineHeight: '14px',
                                        fontWeight: '700',
                                        opacity: state.isSending ? 0.6 : 1.0
                                    }}
                                    onClick={sendPhoneNumber}>
                                {state.isSending && (
                                    <Dots/>
                                )}
                                {!state.isSending && (
                                    <span>Next</span>
                                )}
                            </Button>
                        </form>
                    </>
                )}
                <div className="grayLine"/>
                <div style={{textAlign: 'center',
                             marginTop: '20px'}}>
                    <a href={`${BOOK_HOST}/register?utm_campaign=webv1redir&utm_medium=web&utm_source=desktop`} style={{
                        color: '#314A68',
                        fontWeight: '500',
                    }}>Signup online</a>
                </div>
            </div>
        </div>
    )
}
