import React, {useEffect, useState} from "react";
import {useAppDispatch, usePSOwner, usePSUser} from "../../../hooks";
import {
    clearItem, deleteItem,
    fetchAttachments, fetchCredentials, fetchItem, saveItem,
    SettingsState
} from "../../../modules/settings";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {
    classCountOptions,
    classSizeOptions,
    classTypeOptions,
    discountOptions,
    leadtimeOptions,
    SelectOption
} from "../Options";
import {useLearnMore, usePhotoUpload} from "../hooks";
import {Prompt, useHistory, useParams} from "react-router";
import {
    PSButtonDanger,
    PSButtonPrimary,
    PSButtonSecondary
} from "../../app/PSButton";
import {Dots, Spinner} from "@zendeskgarden/react-loaders";
import {SettingsPhotoField} from "../SettingsPhotoField";
import {Checkbox, Field, Hint, Input, Label} from "@zendeskgarden/react-forms";
import {
    currencyFloat,
    filterMonetaryInput,
    generateRandomID
} from "../../../utils/numbers";
import {PSDropdown} from "../../app/PSDropdown";
import {Item} from "@zendeskgarden/react-dropdowns";
import {PSTextarea} from "../../app/PSTextarea";
import {Body, Close, Header, Modal} from "@zendeskgarden/react-modals";
import {GuardrailModal} from "../GuardrailModal";
import {LocationModal} from "../LocationModal";
import {Item as PSItem} from "../../../models/Item";
import {apiClient} from "../../../modules/apiclient";
import {AddLessonModal} from "./AddLessonModal";
import {Schedule} from "../../../models/Schedule";
import {POCKETSUITE_USER_ID} from "../../../utils/constants";
import {ClassesSchedule} from "./ClassesSchedule";
import {updateItem} from "../../../modules/onboarding";

type Props = {
    forOnboarding?: boolean,
    refreshData?: boolean,
}

export const ClassesEdit = ({forOnboarding, refreshData}: Props) => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const history = useHistory()

    const [photo, setPhoto] = useState<undefined | string | File>('')
    const [image, setImage] = useState<undefined | string>('') // For editing
    const [name, setName] = useState('')
    const [price, setPrice] = useState('')
    const [deposit, setDeposit] = useState('')
    const [discount, setDiscount] = useState<any>('')
    const [description, setDescription] = useState('')
    const [classType, setClassType] = useState<any>(classTypeOptions[0])
    const [classSize, setClassSize] = useState<any>('')
    const [classInstructor, setClassInstructor] = useState<any>('')
    const [classCount, setClassCount] = useState<any>('')
    const [allowWaitlist, setAllowWaitlist] = useState(true)
    const [leadtime, setLeadtime] = useState<string | SelectOption>( '')
    const [online, setOnline] = useState(true)
    const [videoConference, setVideoConference] = useState(false)
    const [zoomConnected, setZoomConnected] = useState(false)
    const [conferenceType, setConferenceType] = useState<string>()
    const [contract, setContract] = useState<any>('')
    const [form, setForm] = useState<any>('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [saveEnabled, setSaveEnabled] = useState(false)
    const [formSending, setFormSending] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)

    const [salesTax, setSalesTax] = useState(false)
    const [addPhotoRef, selectPhoto, selectedPhoto] = usePhotoUpload({disabled: state.isSending, setPhoto})
    const [learnMoreVisible, learnMoreTitle, learnMoreBody, showLearnMore] = useLearnMore()
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [showAddLessonModal, setShowAddLessonModal] = useState(false)
    const [addLessonModalType, setAddLessonModalType] = useState('')

    const userRecord = usePSUser()
    const owner = usePSOwner()
    const [scheduleHere, setScheduleHere] = useState(false)
    useEffect(() => {
        if (!owner) return
        setScheduleHere(owner.location)
    }, [owner])

    useEffect(() => {
        dispatch(fetchCredentials())
    }, [dispatch])

    useEffect(() => {
        if (state.credentials.find(credential => credential.provider === 'zoom')) {
            setZoomConnected(true)
        }
    }, [state.credentials])

    const [pendingLessons, setPendingLessons] = useState<Schedule[]>([])
    const [pendingLessonDesc, setPendingLessonDesc] = useState('')
    const [showEditPendingLessons, setShowEditPendingLessons] = useState(false)
    const addedLesson = (data: any) => {
        const s = new Schedule()
        s.setData(data)
        s.temporary_id = data.schedule ? 'multiple-' : 'single-' + generateRandomID()
        s.capacity = Number((classSize as SelectOption).value) || 0

        // Add data to array of pending lessons, that get saved after the class is created
        setPendingLessons(pendingLessons.concat(s))
        // Hide modal
        setShowAddLessonModal(false)
    }
    useEffect(() => {
        if (pendingLessons.length === 0)
            setPendingLessonDesc('')
        else if (pendingLessons.length === 1)
            setPendingLessonDesc(`View all lessons (${pendingLessons[0].prettyDescription()})`)
        else
            setPendingLessonDesc(`${pendingLessons.length} lessons`)
    }, [pendingLessons])

    // Load payees if available, so we can show them as available instructors
    const [instructors, setInstructors] = useState<any[]>([])
    useEffect(() => {
        if (!owner) return

        if (owner.type === 'company') {
            apiClient.post('/payee/sync')
                .then(resp => resp.data)
                .then((json: any) => {
                    // Ignore PS
                    const payees = json.contacts.filter((u: any) => u.id !== POCKETSUITE_USER_ID)
                    setInstructors(payees)
                })
                .catch(error => {
                    // @ts-ignore
                    window.Rollbar.error('payeeSync error', error)
                })
        }
        else {
            setInstructors([userRecord])
        }
    }, [owner, userRecord])

    // Location selection handling. When 'Another location' is selected, we show
    // the LocationModal to get the user to input the address.
    const [locationDetails, setLocationDetails] = useState('')
    const [showLocationModal, setShowLocationModal] = useState(false)
    const locationOptions = [
        { label: 'No location', value: 'none' },
    ]
    if (scheduleHere)
        locationOptions.push({ label: 'Business location', value: '' })
    if (locationDetails)
        locationOptions.push({ label: locationDetails, value: 'another' })
    else
        locationOptions.push({ label: 'Another location', value: 'another' })
    const [location, setLocation] = useState(locationOptions[scheduleHere ? 1 : 0])

    const selectLocation = (selection: SelectOption) => {
        if (selection.value === 'another')
            setShowLocationModal(true)
        else
            setLocation(selection)
    }

    const locationModalCompleted = (location: string) => {
        setLocationDetails(location)
        setLocation({label: location, value: 'another'})
        setShowLocationModal(false)
    }

    useEffect(() => {
        if (classType.value === 'open')
            setSaveEnabled(!!name && !!classSize && !!classCount)
        else
            setSaveEnabled(!!name && !!classSize)
    }, [name, classSize, classCount, classType])

    useEffect(() => {
        if (forOnboarding) {
            setHasEdits(false)
            return
        }

        // TODO Add other form fields
        let changed = 0
        if ((state.item.name || name) && name !== state.item.name)
            changed = 1
        console.log('changed=', changed) // TODO Remove this
        setHasEdits(changed > 0)
    }, [
        state.item, name, forOnboarding,
    ])

    // Fetch the user's contracts and forms
    useEffect(() => {
        dispatch(fetchAttachments())
    }, [dispatch])

    // Load the class if this is an edit, otherwise treat it as a create
    const params: any = useParams()
    useEffect(() => {
        if (forOnboarding) return

        if (!params.classID) {
            dispatch(clearItem())
            return
        }
        dispatch(fetchItem('class', params.classID))
    }, [dispatch, params.classID, forOnboarding])

    // Update form's values after the class is loaded into state
    useEffect(() => {
        if (params.action !== 'copy')
            setName(state.item.name || '')
        setPhoto(state.item.image_uri || '')
        setImage(state.item.image || '')
        setPrice(state.item.rate ? currencyFloat(state.item.rate).toFixed(2) : '')
        setDeposit(state.item.deposit ? currencyFloat(state.item.deposit).toFixed(2) : '')
        setDescription(state.item.memo || '')
        setStartDate(state.item.start_date || '')
        setEndDate(state.item.end_date || '')

        // Get discount
        const selectedDiscount = discountOptions.find((option) => option.value === state.item.discount)
        setDiscount(selectedDiscount || '')

        const selectedClassType = classTypeOptions.find((option) => option.value === state.item.class_type)
        setClassType(selectedClassType || '')

        const selectedClassSize = classSizeOptions.find((option) => Number(option.value) === Number(state.item.capacity))
        setClassSize(selectedClassSize || '')

        if (instructors.length > 0) {
            const selectedInstructor = instructors.find((user) => user.id === state.item.employee)
            setClassInstructor(selectedInstructor || '')
        }

        const selectedClassCount = classCountOptions.find((option) => Number(option.value) === Number(state.item.class_count))
        setClassCount(selectedClassCount || '')

        setAllowWaitlist(state.item.allow_waitlist)
        setSalesTax(state.item.allow_salestax)

        // Get leadtime from leadtimeOptions
        const selectedLeadTime = leadtimeOptions.find((option) => option.value === state.item.leadtime)
        setLeadtime(selectedLeadTime || '')

        setOnline(state.item.is_public)

        // Get contract
        const selectedContract = state.contracts.find((c) => c.id === state.item.contract)
        setContract(selectedContract || '')

        // Get form
        const selectedForm = state.forms.find((f) => f.id === state.item.form)
        setForm(selectedForm || '')

        // Set location
        if (state.item.is_virtual)
            setLocation({ label: 'No location', value: 'none' })
        else if (state.item.location) {
            setLocation({label: state.item.location, value: 'another'})
            setLocationDetails(state.item.location)
        }
        else if (scheduleHere)
            setLocation({label: 'Business location', value: ''})
        else
            setLocation({label: 'No location', value: 'none'})

        setVideoConference(state.item.allow_conference)
        setConferenceType(state.item.conference_type)

    }, [state.item, state.contracts, state.forms, params.action, scheduleHere, instructors])

    const onSave = () => {
        if (formSending) return

        if (!name || !classSize) return

        const item = new PSItem('class')
        if (forOnboarding)
            item.setData(state.item)
        item.name = name
        item.image = image
        item.rate = price
        item.allow_conference = videoConference
        item.conference_type = conferenceType
        if (salesTax)
            item.allow_salestax = true
        if (deposit)
            item.deposit = deposit
        item.memo = description
        item.start_date = startDate
        item.end_date = endDate
        if (discount)
            item.discount = (discount as SelectOption).value
        if (classType)
            item.class_type = (classType as SelectOption).value
        if (classSize)
            item.capacity = (classSize as SelectOption).value
        if (classInstructor)
            item.employee = classInstructor.id
        if (classCount)
            item.class_count = (classCount as SelectOption).value
        if (classType.value === 'dropin')
            item.allow_waitlist = allowWaitlist
        else
            item.allow_waitlist = false
        if (leadtime)
            item.leadtime = (leadtime as SelectOption).value
        item.is_public = online
        if (form)
            item.form = form.id
        if (contract)
            item.contract = contract.id
        if (location.value === 'another')
            item.location = locationDetails
        if (params.classID && params.action !== 'copy')
            item.id = params.classID

        if (pendingLessons.length > 0) {
            // @ts-ignore
            item.lessons = pendingLessons.map((lesson) => {
                let schedule
                if (lesson.schedule_instance)
                    schedule = lesson.schedule_instance
                else
                    schedule = {
                        duration: lesson.duration,
                        interval: '',
                        dow: '',
                        frequency: 'weekly',
                        date: lesson.date,
                    }

                return {
                    employee: item.employee,
                    duration: lesson.duration,
                    capacity: item.capacity,
                    location: item.location,
                    date: lesson.date,
                    schedule: schedule,
                }
            })
        }

        if (forOnboarding) {
            item.id = state.item.id
            dispatch(updateItem(item.toObject(), !!refreshData))
            history.go(-2)
            return
        }

        setFormSending(true)

        if (typeof photo === 'string') {
            dispatch(saveItem(item, undefined))
        }
        else {
            dispatch(saveItem(item, photo as File))
        }
    }

    const showAttachments = (owner?.allow_contract || owner?.allow_record) &&
        (state.loadingAttachments || state.contracts.length > 0 || state.forms.length > 0)

    // Instead of doing a page transition, a modal, or pushing state down into redux
    // we just show the lesson view component if it is to be rendered
    if (showEditPendingLessons) {
        return (
            <ClassesSchedule pendingLessons={pendingLessons}
                             onUpdateLessons={(lessons) => {
                                 setPendingLessons(lessons)
                                 setShowEditPendingLessons(false)
                             }}
            />
        )
    }

    const showDeleteButton = params.classID && params.action !== 'copy'

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Class</h1>
                {showDeleteButton && (
                    <PSButtonDanger style={{height: '40px'}}
                                    onClick={() => setShowDeleteConfirmation(true)}>
                        Delete class
                    </PSButtonDanger>
                )}
                <PSButtonPrimary style={{height: '40px', marginLeft: showDeleteButton ? '16px' : 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!saveEnabled || formSending}>
                    {formSending && <Dots />}
                    {!formSending && "Save"}
                </PSButtonPrimary>
            </div>

            <div className="form">
                {!forOnboarding && (
                    <SettingsPhotoField photo={photo}
                                        selectPhoto={selectPhoto}
                                        selectedPhoto={selectedPhoto}
                                        addPhotoRef={addPhotoRef}
                                        title="Class" />
                )}

                <Field className="field">
                    <Label className="label">Name (required)</Label>
                    <Input placeholder="Class name"
                           value={name}
                           disabled={state.isSending}
                           onChange={e => setName(e.target.value)} />
                </Field>

                <Field className="field">
                    <Label className="label">Price · $</Label>
                    <Input placeholder="Class price"
                           value={price}
                           disabled={state.isSending}
                           onChange={e => filterMonetaryInput(e.target.value, setPrice)} />
                </Field>

                {classType.value === 'dropin' && (
                    <Field className="field">
                        <Label className="label">Deposit · $</Label>
                        <Input placeholder="Class deposit"
                               value={deposit}
                               disabled={state.isSending}
                               onChange={e => filterMonetaryInput(e.target.value, setDeposit)} />
                    </Field>
                )}

                {owner?.allow_discount && (
                    <PSDropdown selected={discount}
                                nameProperty="label"
                                disabled={state.isSending}
                                onSelect={(selection) => setDiscount(selection)}
                                label="Discount rate"
                                placeholder={<div className="grey">Select discount rate</div>}>
                        <>
                            {discountOptions.map(option => (
                                <Item key={`discount-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                )}

                {owner?.allow_salestax && (
                    <Field className="field">
                        <Checkbox checked={salesTax}
                                  disabled={state.isSending}
                                  onChange={e => setSalesTax(e.target.checked)}>
                            <Label className="withHint">Allow sales tax</Label>
                            <Hint>Automatically calculate & apply sales tax</Hint>
                        </Checkbox>
                    </Field>
                )}

                <Field className="field">
                    <Label className="label">Description</Label>
                    <PSTextarea placeholder="Class description"
                                className="input"
                                minRows={8}
                                maxLength={1500}
                                disabled={state.isSending}
                                value={description}
                                onChange={value => setDescription(value)}/>
                </Field>

                <h2>Details</h2>

                <PSDropdown selected={classType}
                            nameProperty="label"
                            disabled={state.isSending}
                            onSelect={(selection) => setClassType(selection)}
                            label="Class type (required)">
                    <>
                        {classTypeOptions.map(option => (
                            <Item key={`class-type-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>

                <PSDropdown selected={classSize}
                            nameProperty="label"
                            disabled={state.isSending}
                            onSelect={(selection) => setClassSize(selection)}
                            placeholder={<div className="grey">Select maximum class size</div>}
                            label="Class size (required)">
                    <>
                        {classSizeOptions.map(option => (
                            <Item key={`class-size-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>

                <PSDropdown selected={classInstructor}
                            nameProperty="name"
                            disabled={state.isSending}
                            onSelect={(selection) => setClassInstructor(selection)}
                            placeholder={<div className="grey">Select class instructor</div>}
                            label="Instructor">
                    <>
                        {instructors.map(payee => (
                            <Item key={`payee-${payee.id}`} value={payee}>
                                {payee.name}
                            </Item>
                        ))}
                    </>
                </PSDropdown>

                {classType.value === 'dropin' && (
                    <Field className="field">
                        <Checkbox checked={allowWaitlist}
                                  disabled={state.isSending}
                                  onChange={(e) => { setAllowWaitlist(e.target.checked)}}>
                            <Label className="withHint">Allow waitlist</Label>
                            <Hint>Automatically manage your waitlist</Hint>
                        </Checkbox>
                    </Field>
                )}


                {!!owner?.plan && (
                    <Field className="field">
                        <Checkbox disabled={!zoomConnected} checked={videoConference}
                                    onChange={(e) => {
                                        setConferenceType(e.target.checked ? 'zoom' : undefined)
                                        setVideoConference(e.target.checked)
                                    }}>
                            <Label className={`withHint ${(!zoomConnected && 'grey')}`}>
                                Zoom link
                            </Label>
                            {zoomConnected ?
                                <Hint>Attach a Zoom link</Hint> :
                                <Hint>Link Zoom account to use Zoom video conferencing</Hint>
                            }
                        </Checkbox>
                    </Field>
                )}

                {classType.value === 'open' && (
                    <PSDropdown selected={classCount}
                                nameProperty="label"
                                disabled={state.isSending}
                                onSelect={(selection) => setClassCount(selection)}
                                placeholder={<div className="grey">How many classes are in this series?</div>}
                                label="Number of classes (required)">
                        <>
                            {classCountOptions.map(option => (
                                <Item key={`class-count-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                )}

                <h2>Scheduling</h2>

                {!params.classID && (
                    <Field className="field">
                        <Label className="label">Schedule</Label>
                        <Hint className="hint">Setup a schedule for your class</Hint>
                        <div style={{display: 'flex', marginTop: 16}}>
                            <PSButtonSecondary onClick={() => {
                                setAddLessonModalType('single')
                                setShowAddLessonModal(true)
                            }}>
                                Add single lesson
                            </PSButtonSecondary>
                            <PSButtonSecondary style={{marginLeft: 16}}
                                               onClick={() => {
                                                   setAddLessonModalType('multiple')
                                                   setShowAddLessonModal(true)
                                               }}
                            >
                                Add multiple lessons
                            </PSButtonSecondary>
                        </div>
                        {pendingLessonDesc && (
                            <div className="buttonLink brightBlue"
                                 style={{display: 'inline-block', paddingTop: 8}}
                                 onClick={() => setShowEditPendingLessons(true)}
                            >
                                {pendingLessonDesc} &gt;
                            </div>
                        )}
                    </Field>
                )}

                <PSDropdown selected={leadtime}
                            onSelect={(selection) => setLeadtime(selection)}
                            disabled={state.isSending}
                            nameProperty="label"
                            label="Lead time"
                            hint="Minimum amount of time needed to prep for this service"
                            onLearnMore={() => showLearnMore('leadtime', true)}
                            placeholder={<div className="grey">Select lead time</div>}>
                    {leadtimeOptions.map(option => (
                        <Item key={`leadtime-${option.value}`} value={option}>
                            {option.label}
                        </Item>
                    ))}
                </PSDropdown>

                {owner?.allow_widget && (
                    <Field className="field">
                        <Checkbox checked={online}
                                  disabled={state.isSending}
                                  onChange={(e) => { setOnline(e.target.checked)}}>
                            <Label className="withHint">Show online</Label>
                            <Hint>Allow clients to purchase this class online</Hint>
                        </Checkbox>
                    </Field>
                )}


                {showAttachments && <h2>Attachments</h2>}

                {state.loadingAttachments && (
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                )}
                {owner?.allow_contract && !state.loadingAttachments && state.contracts.length > 0 && (
                    <PSDropdown selected={contract}
                                disabled={state.isSending}
                                nameProperty="name"
                                onSelect={(selection) => setContract(selection)}
                                label="Attach a contract"
                                hint="This contract will be signed when a client is booking this class"
                                onLearnMore={() => showLearnMore('contracts', true)}
                                placeholder={<div className="grey">Select contract</div>}>
                        <>
                            {state.contracts.map(option => (
                                <Item key={`contract-${option.id}`} value={option}>
                                    {option.name}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                )}
                {owner?.allow_record && !state.loadingAttachments && state.forms.length > 0 && (
                    <PSDropdown selected={form}
                                disabled={state.isSending}
                                nameProperty="name"
                                onSelect={(selection) => setForm(selection)}
                                label="Attach a form"
                                hint="This form will be completed when a client is booking this class"
                                onLearnMore={() => showLearnMore('forms', true)}
                                placeholder={<div className="grey">Select form</div>}>
                        <>
                            {state.forms.map(option => (
                                <Item key={`form-${option.id}`} value={option}>
                                    {option.name}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                )}

                <h2>Location</h2>

                <PSDropdown selected={location}
                            disabled={state.isSending}
                            nameProperty="label"
                            onSelect={(selection) => selectLocation(selection)}
                            label="Location">
                    <>
                        {locationOptions.map(option => (
                            <Item key={`location-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>

                <div style={{paddingBottom: 48}} />
            </div>
            {showLocationModal && (
                <LocationModal onClose={() => setShowLocationModal(false)}
                               onDone={locationModalCompleted}
                               currentLocation={locationDetails} />
            )}
            {learnMoreVisible && (
                <Modal onClose={() => showLearnMore('', false)}>
                    <Header>{learnMoreTitle}</Header>
                    <Body>{learnMoreBody}</Body>
                    <Close aria-label="Close modal" />
                </Modal>
            )}
            {showDeleteConfirmation && (
                <GuardrailModal title="Are you sure?"
                                body="Are you sure you'd like to delete this item?"
                                buttonText="Delete"
                                onClose={() => setShowDeleteConfirmation(false)}
                                onAction={() => dispatch(deleteItem(params.classID, 'classes'))}
                />
            )}
            {showAddLessonModal && (
                <AddLessonModal onClose={() => setShowAddLessonModal(false)}
                                onDone={(data: any) => addedLesson(data)}
                                classID={''}
                                isSingleLesson={addLessonModalType === 'single'}
                                showAllFields={false}
                                temporaryObject={true}
                />
            )}
            <Prompt
                when={hasEdits && !formSending}
                message="Are you sure you'd like to leave this page without saving your changes?" />
        </div>
    )
}