import React from "react";
import { Modal, Header, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { Button } from "@zendeskgarden/react-buttons";
import {ReactComponent as SuccessIcon} from "../../icons/success.svg";
import {SuccessModalButton} from "../app/PSButton";

type Props = {
    title: string,
    body: React.ReactNode | string,
    buttonText: string,
    extraButtonText?: string,
    dismiss?: boolean,
    onClose: () => void,
    onAction: (button?: number) => void,
}

export const SuccessModal = ({title, body, dismiss, buttonText, extraButtonText, onClose, onAction}: Props) => {
    return (
        <Modal onClose={onClose}>
            <Header style={{display: 'flex', color: '#219653'}}>
                <SuccessIcon />
                <span style={{fontWeight: 700, paddingLeft: 8}}>{title}</span>
            </Header>
            <Body>
                {body}
            </Body>
            <Footer>
                {!!dismiss && (
                <FooterItem>
                    <Button onClick={onClose} isBasic>
                        Dismiss
                    </Button>
                </FooterItem>
                )}
                {extraButtonText && (
                    <FooterItem>
                        <SuccessModalButton onClick={() => onAction(2)} isPrimary>
                            {extraButtonText}
                        </SuccessModalButton>
                    </FooterItem>
                )}
                <FooterItem>
                    <SuccessModalButton onClick={() => onAction(1)} isPrimary>
                        {buttonText}
                    </SuccessModalButton>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )
}