import {processError} from "./modules/error";

const errorHandler = (store: any) => (next: any) => (action: any) => {
    // Before redux-thunk handles this function, wrap it to catch any errors
    if (typeof action === 'function') {
        const wrapAction = (fn: any) => (dispatch: any, getState: any, args: any) => {
            try {
                fn(dispatch, getState, args)
            } 
            catch (e) {
                dispatch(processError(e))
            }
        }
        return next(wrapAction(action))
    }

    try {
        return next(action)
    } 
    catch (e) {
        store.dispatch(processError(e))
    }
}

export default errorHandler