import moment from "moment";
import {currencyFloat, currencyFormat} from "../utils/numbers";
import {Transaction} from "./Transaction";

const DATE_FORMAT = 'MMM Do, yyyy'

export const invoiceStatus = (record: any) => {
    const dateFormat = (date: string) => moment(date).format(DATE_FORMAT)

    if (record.cancelled_date)
        return `Canceled ${dateFormat(record.cancelled_date)}`
    if (record.marked_paid_date && record.paymeth)
        return `Marked paid ${dateFormat(record.marked_paid_date)} - ${record.paymeth}`
    if (record.marked_paid_date)
        return `Marked paid ${dateFormat(record.marked_paid_date)}`
    if (record.paid_date)
        return `Paid ${dateFormat(record.paid_date)}`
    if (record.type === 'schedule')
        if (record.next_invoice_date)
            return `Next invoice ${dateFormat(record.next_invoice_date)}`
        else
            return `Completed schedule`
    if (record.sent_date && currencyFloat(record.paid_amount) > 0)
        return `Paid ${currencyFormat(record.paid_amount)} (${currencyFormat(record.remaining_balance)} left)`
    if (record.sent_date && moment(record.due_date).startOf('day').isBefore(moment()))
        return `Sent ${dateFormat(record.due_date)} - past due`
    if (record.sent_date)
        return `Sent ${dateFormat(record.created_date)} - not paid`
    return `Saved ${dateFormat(record.created_date)} - not sent`
}

export class Invoice extends Transaction {
    location?: string;
    number?: string;
    due_date: string = '';
    billable_date: string = '';
    min_payment_percent: string = '';
    max_payment_count: string = '';
    created_date?: string;
    paid_date?: string;
    frequency: string = '';
    gift: string = '';
    estimate: string = '';
    contract: string = '';
    form: string = '';
    event?: string;
    paid_amount?: number = 0;
    remaining_balance?: number = 0;
    last_payment_date: string = ''

    constructor(data: Partial<Invoice>) {
        super(data)
        Object.assign(this, data)
    }
}