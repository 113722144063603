import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../hooks";
import {
    saveUser,
    SettingsState
} from "../../../modules/settings";
import {RootState} from "../../../store";
import {useSelector} from "react-redux";
import {Field, Hint, Label} from "@zendeskgarden/react-forms";
import {Prompt} from "react-router";
import {PSButtonPrimary} from "../../app/PSButton";
import {Dots} from "@zendeskgarden/react-loaders";
import localforage from 'localforage'
import {PSTextarea} from "../../app/PSTextarea";

export const DefaultMemos = () => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const [saveEnabled, setSaveEnabled] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)

    const [invoicePolicy, setInvoicePolicy] = useState('')
    const [receiptPolicy, setReceiptPolicy] = useState('')
    const [bookingPolicy, setBookingPolicy] = useState('')

    const [user, setUser] = useState<any>({})

    useEffect(() => {
        if (!user) return
        setInvoicePolicy(user?.invoice_policy)
        setReceiptPolicy(user?.receipt_policy)
        setBookingPolicy(user?.booking_policy)
    }, [user])

    useEffect(() => {
        setHasEdits(invoicePolicy !== user?.invoice_policy || receiptPolicy !== user?.receipt_policy || bookingPolicy !== user?.booking_policy)
    }, [user, invoicePolicy, receiptPolicy, bookingPolicy])

    useEffect(() => {
        setSaveEnabled(hasEdits)
    }, [hasEdits])

    useEffect(() => {
        localforage.getItem('user').then((user: any) => setUser(user))
    }, [])

    const onSave = () => {
        if (state.isSending) return

        const userParams: any = {}
        userParams.id = user.id
        userParams.invoice_policy = invoicePolicy
        userParams.receipt_policy = receiptPolicy
        userParams.booking_policy = bookingPolicy

        // @ts-ignore
        dispatch(saveUser(userParams)).then(() => localforage.getItem('user').then((user: any) => setUser(user)))
    }

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Default memos</h1>
                <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!saveEnabled || state.isSending}>
                    {state.isSending && <Dots />}
                    {!state.isSending && "Save"}
                </PSButtonPrimary>
            </div>
            <div className="form">
            <Field className="field">
                    <Label className="label">Invoice</Label>
                    <Hint>Default invoice memos will prefill when sending invoices to clients</Hint>
                    <PSTextarea placeholder="Add a default invoice memo. You can always adjust it on the fly if needed"
                                className="input"
                                minRows={8}
                                disabled={state.isSending}
                                value={invoicePolicy ? invoicePolicy : ''}
                                onChange={value => setInvoicePolicy(value)} />
                </Field>
                <Field className="field">
                    <Label className="label">Charge</Label>
                    <Hint>Default charge memos will prefill when charging clients</Hint>
                    <PSTextarea placeholder="Add a default charge memo. You can always adjust it on the fly if needed"
                                className="input"
                                minRows={8}
                                disabled={state.isSending}
                                value={receiptPolicy ? receiptPolicy : ''}
                                onChange={value => setReceiptPolicy(value)} />
                </Field>
                <Field className="field">
                    <Label className="label">Appointment</Label>
                    <Hint>Default appointment memos will prefill when scheduling clients</Hint>
                    <PSTextarea placeholder="Add a default appointment memo. You can always adjust it on the fly if needed"
                                className="input"
                                minRows={8}
                                disabled={state.isSending}
                                value={bookingPolicy ? bookingPolicy : ''}
                                onChange={value => setBookingPolicy(value)} />
                </Field>
            </div>

            <Prompt
                when={hasEdits && !state.isSending}
                message="Are you sure you'd like to leave this page without saving your changes?" />
        </div>
    )
}
