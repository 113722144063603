import React, {useCallback, useEffect, useMemo, useState} from "react";
import {AppHeader} from "../../app/AppHeader";
import {Redirect, useHistory, useParams} from "react-router";
import {TipType} from "../../../models/Transaction";
import "../transactions.css";
import {PSButtonPrimary, PSButtonSecondary} from "../../app/PSButton";
import {currencyFormat} from "../../../utils/numbers";
import {TransactionProgress} from "../TransactionProgress";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { describeSubscription, Subscription, useSubscriptionLocationState } from "../../../models/Subscription";
import { saveSubscription, sendSubscription } from "../../../modules/transactions";
import { Link } from "react-router-dom";

export const SubscriptionSummary = () => {

    const locationState = useSubscriptionLocationState()
    const params: { id?: string } = useParams()

    const subscription = useMemo(() => new Subscription(locationState?.subscription || {} as Partial<Subscription>), [locationState?.subscription])
    const client = locationState?.client

    const history = useHistory()
    const dispatch = useDispatch()

    const [discountType, setDiscountType] = useState<TipType>()
    const [discountAmount, setDiscountAmount] = useState<string>()
    const [surchargeEnabled, setSurchargeEnabled] = useState(false)

    const [discountDescription, setDiscountDescription] = useState('')

    useEffect(() => {
        setDiscountType(subscription.discount_type)
        setDiscountAmount(subscription.discount)
        setSurchargeEnabled(subscription.allow_surcharge || false)
    }, [subscription])

    useEffect(() => {
        if (subscription._gift?.item_name) {
            setDiscountDescription(subscription._gift.item_name)
        }
        else if (discountType === 'rate') {
            setDiscountDescription(`Discount (${currencyFormat(discountAmount)})`)
        }
        else if (discountType === 'percent' && Number(discountAmount) > 0) {
            // TODO discountAmount can go from 10 to 9.99997743 (floating arithmetic issue)
            setDiscountDescription(`Discount (${discountAmount}%)`)
        }
        else {
            setDiscountDescription('')
        }
    }, [discountType, discountAmount, subscription])

    const onBack = useCallback(
        () => history.replace(params?.id ? `/subscription/${params.id}/edit` : '/subscription/new', {subscription}),
    [history, subscription, params.id])

    const onSave = useCallback(() => {
        // @ts-ignore
        dispatch(saveSubscription({ ...subscription, id: params.id, client: client.id })).then(
            (subscription: Partial<Subscription>) => dispatch(push(`/subscription/success/${subscription.id}`, { client })))
    }, [subscription, params.id, client, dispatch])

    const onSend = useCallback(() => {
        // @ts-ignore
        dispatch(sendSubscription({ ...subscription, id: params.id, client: client.id })).then(
            (subscription: Partial<Subscription>) => dispatch(push(`/subscription/success/${subscription.id}`, { client })))
    }, [subscription, params.id, client, dispatch])

    if (!subscription) {
        return <Redirect to="/subscription/new" />
    }

    const taxPercentage = Number(subscription.tax ?? 0)
    return (
        <AppHeader showBackButton title="Subscriptions"
            middleWidget={<TransactionProgress created reviewed secondStep='Preview' thirdStep='Save' />}
            onBack={onBack}
        >
            <div className="scheduleClient servicesSettings ">
                <div className="header">
                    <h1>Preview</h1>
                </div>

                <div className="separator" />

                <div className="scheduleClientReview">
                    <div className="details">
                        <div className="receipt">
                            <div className="total">{currencyFormat(subscription.calculateChargeAmount())} Total</div>
                            <div className="final" style={{textAlign: 'center', marginTop: '10px', marginBottom: '10px'}}>
                                <p style={{marginBottom: '10px'}}>{subscription.name}</p>
                                <p>{describeSubscription(subscription)}</p>
                            </div>
                            <div style={{maxWidth:'245px', margin: 'auto'}}>
                                <div className="lineItem final">
                                    <div className="name">Subtotal</div>
                                    <div className="amount">{currencyFormat(subscription.total)}</div>
                                </div>
                                {Number(subscription.deposit) > 0 && (
                                    <div className="lineItem">
                                        <div className="name">Setup Fee</div>
                                        <div className="amount">{currencyFormat(subscription.deposit)}</div>
                                    </div>
                                )}
                                {Number(subscription.discount) > 0 && (
                                    <div className="lineItem">
                                        <div className="name">{discountDescription}</div>
                                        <div className="amount">-{currencyFormat(subscription.calculateDiscount())}</div>
                                    </div>
                                )}
                                {taxPercentage > 0 && (
                                    <div className="lineItem">
                                        <div className="name">Sales tax ({taxPercentage}%)</div>
                                        <div className="amount">{currencyFormat(subscription.calculateTax())}</div>
                                    </div>
                                )}
                                {surchargeEnabled && (
                                    <div className="lineItem">
                                        <div className="name">Surcharge (3%)</div>
                                        <div className="amount">{currencyFormat(subscription.calculateSurcharge())}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="actionsWrapper">
                        <div className="actions">
                                {params.id ?
                                (<>
                                    <PSButtonPrimary onClick={onSave}>Save</PSButtonPrimary>
                                    <PSButtonSecondary onClick={onBack}>Back</PSButtonSecondary>
                                </>) :
                                (<>
                                    <PSButtonPrimary onClick={onSend}>Send</PSButtonPrimary>
                                    <PSButtonSecondary onClick={onSave}>Save</PSButtonSecondary>
                                    <PSButtonSecondary onClick={onBack}>Back</PSButtonSecondary>
                                </>)}
                        </div>
                    </div>

                    <div className="infoWrapper">
                        <div className="item flex">
                            <div className="data">
                                <div className="bold">Client</div>
                                <div className="selected">{client?.name}</div>
                                <div className="grey2Text">Requires payment</div>
                            </div>
                            <div className="action">
                                <Link to={`/record/client/${client?.id}`}>View</Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="bold">Number of payments</div>
                            <div className="grey2Text">{subscription.instances ? `${subscription.instances} remaining` : 'Continuous'}</div>
                        </div>
                        {!!subscription.memo && (
                            <div className="item">
                                <div className="bold">Statement memo</div>
                                <div className="grey2Text">{subscription.memo}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </AppHeader>
    )
}