import React, { useCallback, useEffect, useState, useMemo } from 'react'
import moment from 'moment'
import { RecordStatusBar } from '../RecordStatusBar'
import { currencyFormat } from '../../../utils/numbers'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RecordDetail } from '../RecordDetail'
import { RecordBody } from '../RecordBody'
import { ReactComponent as ChevronIcon } from '../../../icons/chevron.svg'
import { ReactComponent as ColoredChevronIcon } from '../../../icons/colored_chevron.svg'
import { PaymentDetailsModal } from '../PaymentDetailsModal'
import { displayExpense, displayLineItem } from '../../../utils/lineitems'
import { displayStatus, Estimate } from '../../../models/Estimate'
import { RootState } from '../../../store'
import { ButtonProps, RecordButtons } from '../RecordButtons'
import {distinctButtons, isForVendor} from '../../../utils/records'
import { push } from 'connected-react-router'
import { API_BASE } from '../../../modules/apiclient'
import { useConfirmModal, usePSOwner } from '../../../hooks'
import { Result } from '../../../utils'
import { cancelEstimate, confirmEstimate, rollbackTransaction, sendClientReceipt, sendEstimate } from '../../../modules/transactions'
import { getRecord } from '../../../modules/records'
import { LineItem } from '../../../models/LineItem'

const MAX_HOURLY_DURATION = 840

const displayEstimateSubtitle = (record: any) => {
    if (record.duration && record.total) {
        if (record.duration > MAX_HOURLY_DURATION)
            return `${currencyFormat(record.amount / (record.duration / 1440))} per day`
        return `${currencyFormat(record.amount / (record.duration / 60))} per hour`
    }

    const dateFormat = record.source ? 'MMMM Do, YYYY'  : 'dddd MMM Do, YYYY'
    return `${moment(record.date).format(dateFormat)}`
}

export const RecordEstimate = (props: any) => {
    const dispatch = useDispatch()
    const state = useSelector((state: RootState) => (
        {
            entities: state.entities
        }
    ))

    const record = useMemo(() => new Estimate(props.record), [props.record])
    const client = useMemo(() => state.entities.contacts[record.client] || {}, [record.client, state.entities.contacts])
    const owner = usePSOwner()

    const statuses = [
        {label: 'Created', completed: !!record.created_date},
        {label: 'Sent', completed: !!record.sent_date},
        {label: 'Viewed', completed: !!record.viewed_date},
        {label: 'Accepted', completed: !!record.confirmed_date},
    ]

    const items = record.lines.filter((line: any) => line.type === 'item')
    const expenses = record.lines.filter((line: any) => line.type === 'expense')

    const recordDetails = []

    if (client.id) {
        const details = (
            <Link to={`/record/client/${client.id}`}>
                {client.name}
                <ChevronIcon className="chevron" />
            </Link>
        )
        recordDetails.push(
            <RecordDetail header="Client"
                          details={details}
                          subtitle={displayStatus(record)}
            />
        )
    }

    if (expenses.length > 0) {
        const expensesList = expenses.map(displayExpense)
        recordDetails.push(
            <RecordDetail header="Expenses"
                          details={expensesList}
                          isText />
        )
    }

    if (items.length > 0) {
        const lineItemsList = items.map((item) => displayLineItem(item, true))
        recordDetails.push(
            <RecordDetail header="Items"
                          details={lineItemsList}
                          isText />
        )
    }

    if (record.memo) {
        recordDetails.push(
            <RecordDetail header="Memo" details={record.memo} isText />
        )
    }

    if (record.source_display) {
        recordDetails.push(
            <RecordDetail header="Payment method" details={record.source_display}/>
        )
    }

    const [setCtaModalInfo, setCtaModalLoading, showCtaModal, closeCtaModal, ctaModalComponent] = useConfirmModal()

    const confirmChargeEstimate = useCallback(() => dispatch(push(`/charge/estimate/${record.id}/confirm`, {estimate: record})), [dispatch, record])

    const approveEstimate = useCallback(() => {
        if (record.status === "sentQuote" && Number(record.deposit) > 0 && !record.paid_deposit_date) {
            confirmChargeEstimate()
        }
        else {
            setCtaModalInfo({
                title: "Approve estimate",
                body: "Would you like to approve this estimate? ",
                button: "Approve",
                onClick: () => {
                    setCtaModalLoading(true)
                    Result(dispatch(confirmEstimate(record)))
                        .then(() => dispatch(getRecord('estimate', record.id)))
                        .then(closeCtaModal)
                }
            })
            showCtaModal()
        }
    }, [closeCtaModal, confirmChargeEstimate, dispatch, record, setCtaModalInfo, setCtaModalLoading, showCtaModal])

    const viewForm = useCallback(() =>
        dispatch(push(`/record/record/${record.record}`)), [record.record, dispatch])

    const viewContract = useCallback(() =>
        window.open(`${API_BASE}/estimate/${record.id}/contract`, "_blank"), [record.id])

    const sendMessage = useCallback(() =>
        dispatch(push(`/messages/${record.client}`)), [dispatch, record.client])

    const chargeClient = useCallback(() => dispatch(push(`/charge/estimate/${record.id}/complete`, {booking: record})), [record, dispatch])

    const viewNotes = useCallback(() =>
        dispatch(push(`/notes/estimate/${record.id}`)), [record.id, dispatch])

    const copyEstimate = useCallback(() =>
        dispatch(push(`/estimate/new`, { estimate: {...record, id: undefined, number: undefined} })), [dispatch, record])

    const sendInvoice = useCallback(() => {
        const invoice: any = {
            estimate: record.id,
            client: record.client,
            vendor: record.vendor,
            total: record.rate,
            allow_surcharge: record.allow_surcharge,
            tax_code: record.tax_code,
            tax: record.tax,
            nexus: record.nexus,
            location: record.location,
            reminder: owner?.invoice_reminder,
            discount: record.discount,
            discount_type: record.discount_type,
            lines: record.lines ?? []
        }

        if (owner?.allow_staffing) {
            invoice.employee = record.employee
        }

        if (record.paid_deposit_date) {
            invoice.discount = record.calculateDiscount()
            invoice.discount_type = "rate"
        }

        if (record.deposit && record.paid_deposit_date && invoice.lines.length) {
            const line = new LineItem({
                type: "expense",
                category: "adjustments",
                total: -record.deposit,
                rate: -record.deposit,
                quantity: 1,
                memo: `Deposit - paid on ${moment(record.paid_deposit_date).format("MMM d, yyyy")}`,
                date: record.paid_deposit_date
            })
            invoice.lines.push(line)
        }

        dispatch(push(`/invoice/new`, { invoice }))
    }, [dispatch, record, owner?.allow_staffing, owner?.invoice_reminder])


    const undoMarkPaid = useCallback(() => {
        setCtaModalInfo({
            title: "Undo mark paid",
            body: "Would you like to undo this payment?",
            button: "Undo mark paid",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(rollbackTransaction(record.id!, 'estimate')))
                    .then(() => dispatch(getRecord('estimate', record.id)))
                    .then(closeCtaModal)
            },
            danger: true
        })
        showCtaModal()
    }, [closeCtaModal, dispatch, record.id, setCtaModalInfo, setCtaModalLoading, showCtaModal])

    const undoCancel = useCallback(() => {
        setCtaModalInfo({
            title: "Undo cancellation",
            body: "Would you like to undo the estimate cancellation?",
            button: "Undo cancellation",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(rollbackTransaction(record.id!, 'estimate')))
                    .then(() => dispatch(getRecord('estimate', record.id)))
                    .then(closeCtaModal)
            },
            danger: true
        })
        showCtaModal()
    }, [closeCtaModal, dispatch, record.id, setCtaModalInfo, setCtaModalLoading, showCtaModal])

    const sendReceipt = useCallback(() => {
        setCtaModalInfo({
            title: "Send receipt",
            body: "Would you like to send a receipt to this client?",
            button: "Send receipt",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(sendClientReceipt(record.id!, 'estimate')))
                    .then(closeCtaModal)
                    .then(sendMessage)
            }
        })
        showCtaModal()
    }, [setCtaModalInfo, showCtaModal, setCtaModalLoading, dispatch, record.id, closeCtaModal, sendMessage])

    const resendEstimate = useCallback(() => {
        setCtaModalInfo({
            title: "Send estimate",
            body: "Would you like to resend this estimate?",
            button: "Send estimate",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(sendEstimate(record)))
                    .then(closeCtaModal)
            }
        })
        showCtaModal()
    }, [closeCtaModal, dispatch, record, setCtaModalInfo, setCtaModalLoading, showCtaModal])

    const showCancelModal = useCallback(() => {
        setCtaModalInfo({
            title: "Cancel Estimate",
            body: 'Would you like to cancel this estimate?',
            button: 'Cancel estimate',
            onClick: (_) => {
                setCtaModalLoading(true)
                Result(dispatch(cancelEstimate(record.id!)))
                    .then(() => dispatch(getRecord('estimate', record.id)))
                    .then(closeCtaModal)
            },
            danger: true
        })
        showCtaModal()
    }, [closeCtaModal, dispatch, record.id, setCtaModalInfo, setCtaModalLoading, showCtaModal])

    const scheduleEstimate = useCallback(() => {
        dispatch(push(`/schedule/new`, {
            estimate: {
                ...record,
                employee: owner?.allow_staffing ? record.employee : undefined
            },
            client
        }))
    }, [client, dispatch, record, owner?.allow_staffing])

    const printEstimate = useCallback(() => window.open(`${API_BASE}/estimate/${record.id}/print`, "_blank"), [record.id])

    const [showModal, setShowModal] = useState(false)
    const getButtons = useCallback((): [ButtonProps, ButtonProps[]] => {
        let primaryButton: ButtonProps = { text: "Edit", onClick: () => dispatch(push(`/estimate/edit/${record.id}`, {estimate: record}))}
        const buttons: ButtonProps[] = []

        if (!record.confirmed_date && !record.cancelled_date && !record.completed_date) {
            buttons.push({ text: "Approve", onClick: approveEstimate})
        }
        else if (record.confirmed_date && !record.cancelled_date && moment(record.date) < moment() &&
            !record.invoiced_date && !record.paid_date && (!record.rate || record.amount > 0)) {
            // replace primary 'Edit' button with 'Charge'
            buttons.push(primaryButton)
            primaryButton = { text: "Charge", onClick: chargeClient }
        }
        else if (!record.cancelled_date && !record.completed_date && !record.paid_date && !record.source && !!record.channel) {
            buttons.push({ text: "Cancel", onClick: showCancelModal })
            buttons.push({ text: "Resend", tooltip: "Resend estimate", onClick: resendEstimate })
        }
        else if (!record.cancelled_date && !record.completed_date) {
            buttons.push({ text: "Cancel", onClick: showCancelModal })
        }
        else {
            buttons.push({ text: "Copy", onClick: copyEstimate })
        }
        buttons.push({ text: "Chat", tooltip: "Send message", onClick: sendMessage})
        buttons.push({ text: "Notes", tooltip: "View notes", onClick: viewNotes})
        if (record.confirmed_date && !record.cancelled_date && !record.invoiced_date) {
            buttons.push({ text: "Invoice", onClick: sendInvoice})
        }
        if (record.confirmed_date && !record.cancelled_date) {
            buttons.push({ text: "Schedule", tooltip: "Schedule estimate", onClick: scheduleEstimate})
        }
        buttons.push({ text: "Print", onClick: printEstimate})
        if (record.record && record.captured_date && !record.cancelled_date) {
            buttons.push({ text: "Form", tooltip: "View form", onClick: viewForm})
        }
        if (record.contract && !record.cancelled_date) {
            buttons.push({ text: "Contract", tooltip: "View contract", onClick: viewContract})
        }
        if (record.mark_paid_date) {
            buttons.push({ text: "Receipt", tooltip: "Send receipt", onClick: sendReceipt})
            if (isForVendor(record, owner))
                buttons.push({ text: "Rollback", tooltip: "Undo paid", onClick: undoMarkPaid})
        }
        else if (record.cancelled_date && isForVendor(record, owner)) {
            buttons.push({ text: "Rollback", tooltip: "Undo cancel", onClick: undoCancel})
        }
        if (!record.completed_date && !record.cancelled_date) {
            buttons.push({ text: "Cancel", onClick: showCancelModal})
        }
        buttons.push({ text: "Copy", onClick: copyEstimate })

        return [primaryButton, distinctButtons(buttons)]
    }, [record, sendMessage, viewNotes, printEstimate, copyEstimate, dispatch, approveEstimate, chargeClient, showCancelModal, resendEstimate, sendInvoice, scheduleEstimate, viewForm, viewContract, sendReceipt, undoMarkPaid, undoCancel])

    const [primaryButton, buttons] = getButtons()
    return (
        <div className="recordView">
            <div className="topBar">
                <RecordStatusBar statuses={statuses} />
                <div className="actions">
                    <RecordButtons primaryButton={primaryButton} buttons={buttons} />
                </div>
            </div>
            <div className="recordHeader">
                <h2 className="payment" onClick={e => setShowModal(true)}>
                    {currencyFormat(record.askingAmount())} total
                    <ColoredChevronIcon fill="#62CE9A" />
                </h2>
                <div className="title">Estimate #{record.number}</div>
                <div className="subtitle">{displayEstimateSubtitle(record)}</div>
            </div>
            <RecordBody recordDetails={recordDetails} />
            <PaymentDetailsModal visible={showModal}
                                 onClose={() => setShowModal(false)}
                                 type="estimate"
                                 record={record} />
            {ctaModalComponent}

        </div>
    )
}