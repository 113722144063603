import React from 'react'

export const ConversationHeader = (props) => {
    return (
        <React.Fragment>
            <div
                style={{height: '68px', display: 'flex', flexDirection: 'row'}}>
                {props.title}
                <div style={{flex: 1}}/>
                <div style={{
                    marginLeft: 'auto',
                    paddingTop: '23px',
                    paddingRight: '24px'
                }}>
                    {props.trailingWidget}
                </div>
            </div>
            <div style={{
                height: '1px',
                backgroundColor: '#e4e4e4',
                width: '100%'
            }}/>
        </React.Fragment>
    )
}