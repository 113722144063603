export const e164ToPhone = (e164) => {
    if (e164.length === 12 && e164[0] === '+') {
        return `(${e164.substr(2, 3)}) ${e164.substr(5, 3)}-${e164.substr(8, 4)}`
    }
    else if (e164.length === 11 && e164[0] !== '+') {
        return `(${e164.substr(1, 3)}) ${e164.substr(4, 3)}-${e164.substr(7, 4)}`
    }
    else if (e164.length === 10 && e164[0] !== '1') {
        return `(${e164.substr(0, 3)}) ${e164.substr(3, 3)}-${e164.substr(6, 4)}`
    }

    return e164
}
export const phoneToE164 = (phoneNumber) => {
}