export const GoogleMapsAPIKey = 'AIzaSyA8XoDV3JCW6yRChH1n4SGbbFQvqTljaTw'

export const BOOK_HOST = process.env.REACT_APP_BOOK_HOST || 'https://pocketsuite.io'
export const POCKETSUITE_USER_ID = '5e188478-a964-44e2-baa9-14109fe4cc0d'

export const MINUTES_PER_DAY = 1440

// session storage cache key to indicate last server heartbeat, cleared on close & logout
export const SERVER_PING_DATE = "serverLastSyncDate"

// local forage cache key to indicate if a payments video has been watched
export const WATCHED_ONBOARDING_VIDEO = "watchedPaymentsVideo"
export const VERIFY_BUSINESS_FORM = "94b4cbca-e6aa-11e7-b874-040102a33301"
export const SHOW_TEXT_ME_DASHBOARD = "show_text_me_dashboard"
export const EMPLOYER_CACHE_KEY = "employer"

export const PREMIUM_ID = "f273f71c-688b-11e5-80b6-a0999b10cf73";
export const PREMIUM_PLUS_ID = "6495feb0-6ba2-11e5-abfb-a0999b10cf73";
export const PREMIUM_SUITE_ID = "926a698a-f90e-11e7-aade-a0999b10cf73";
export const PREMIUM_ANNUAL_ID = "f18e11de-907f-11e7-afcd-a0999b10cf73";
export const PREMIUM_PLUS_ANNUAL_ID = "f343007a-8fb2-11e7-97aa-a0999b10cf73";
export const PREMIUM_SUITE_ANNUAL_ID = "b90247a1-f90e-11e7-9138-a0999b10cf73";

export const PREMIUM_PRICE = 24.99
export const PREMIUM_LITE_PRICE = 14.99
export const PREMIUM_PLUS_PRICE = 34.99
export const PREMIUM_SUITE_PRICE = 99.99
export const PREMIUM_ANNUAL_PRICE = 300
export const PREMIUM_PLUS_ANNUAL_PRICE = 420
export const PREMIUM_SUITE_ANNUAL_PRICE = 1200

export const dashboardKey = (tabName: string, userId?: string) =>
    `dashboard-${tabName}${userId ? `-${userId}` : ''}`