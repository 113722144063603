import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useRef, useCallback } from 'react'
import { useLocation } from 'react-router'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MessageBubble } from './MessageBubble'
import { Spinner } from '@zendeskgarden/react-loaders'
import { conversationMarkRead, getConversation, newMessage } from '../../modules/conversation'
import debounce from 'lodash.debounce'
import { generateCancelToken } from '../../modules/apiclient'
import { ReactComponent as RightArrowBlue } from '../../icons/right-arrow-blue.svg'
import { Link } from 'react-router-dom'
import { ConversationHeader } from './ConversationHeader'
import { UserHeader } from '../app/UserHeader'
import { getTeamName } from '../../utils/teams'

const MessagesList = styled('div')`
  overscroll-behavior: contain;
  overflow-y: auto;
`

export const ConversationThreadView = (props) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const newGroupMessage = location.state?.bcc ?? false

    const state = useSelector(state => {
        return {
            login: state.login,
            conversation: state.conversation,
            entities: state.entities,
            messages: state.messages,
        }
    })

    const messageListHeight = props.inboxHeight - 77 - (state.conversation.inputBarHeight || 20)

    let loadMoreCancelToken

    const handleScroll = debounce(() => {
        const messageList = document.getElementById('messageList')
        if (!messageList) return
        const percentToTop = messageList.scrollTop / (messageList.scrollHeight - messageList.offsetHeight)
        if (state.conversation.pagination.hasMore && percentToTop < 0.20 && !state.conversation.isLoading) {
            console.log('Load more')
            loadMore()
        }
        if (messageList.scrollTop === 0) {
            // Hack around the issue of loading more messages causes us to lose our scroll
            // position when we are at 0. It jumps to the latest messages.
            messageList.scrollTop = 1
        }
    }, 200)

    const loadMore = () => {
        const {timestamp, hasMore} = state.conversation.pagination
        if (hasMore) {
            loadMoreCancelToken = generateCancelToken()
            dispatch(getConversation(state.conversation.currentThread, timestamp, loadMoreCancelToken.token))
        }
    }

    useEffect(() => {
        // dispatch new message for each new entity message in state
        // to update conversation thread when thread is already loaded
        if (state.conversation.isLoading || !state.entities.messages) return

        const messageEntities = Object.values(state.entities.messages) ?? []
        const smsMessages = messageEntities.filter(m => m.channel === 'sms')
        const conversationMessages = state.conversation.messages ?? []
        const conversationMessageIds = conversationMessages.map(m => m.id)
        const newMessages = smsMessages.filter(n => !conversationMessageIds.includes(n.id))

        newMessages.forEach(message => {
            if (message.recipient === state.conversation.currentThread || message.sender === state.conversation.currentThread) {
                dispatch(newMessage(message.recipient, message))
            }
        })
    }, [state.entities.messages, state.conversation, dispatch])

    // Cancel the async API request if we are going to clean up this component
    useEffect(() => {
        // This gets called on load. If we are coming from another page and opening
        // messages, we need to make the current selected thread as read now, only
        // if the the last message in the thread has not been viewed.
        if (state.conversation.messages.length) {
            const lastMessage = state.conversation.messages[0]
            const isInbound = state.login.userId !== lastMessage.sender
            const isUnread = !lastMessage.viewed_date
            if (isInbound && isUnread) {
                const isGroup = state.conversation.isGroupMessage ||
                    // special handling when group conversation is first opened, and there are no messages, do not clear out group flag
                    (currentThread in state.entities.teams && !(currentThread in state.entities.contacts))
                dispatch(conversationMarkRead(state.conversation.currentThread, lastMessage.id, isGroup))
            }
        }

        return _ => {
            if (loadMoreCancelToken) {
                loadMoreCancelToken.cancel()
            }
        }
    }, [dispatch])

    // Scrolling to bottom when the chat loads or after we send/receive a message,
    // but not when adding older messages due to scrolling
    const lastMessageRef = useRef(null)
    useEffect(() => {
        // justScrolled indicates the messages were loaded via loadMore
        if (lastMessageRef.current && !state.conversation.pagination.justScrolled)
            lastMessageRef.current.scrollIntoView({behavior: 'auto'})
    }, [state.conversation.messages.length, state.conversation.pagination.justScrolled]);

    // Automatically load more conversations when the scroll bar is near the top
    useEffect(() => {
        const messageList = document.getElementById('messageList')
        if (messageList)
            messageList.addEventListener('scroll', handleScroll)
        return _ => {
            if (messageList)
                messageList.removeEventListener('scroll', handleScroll)
        }
    }, [state.conversation, state.conversation.pagination.timestamp, handleScroll])

    const { currentThread, isGroupMessage } = state.conversation
    const isGroup = isGroupMessage ||
        // special handling when group conversation is first opened, and there are no messages, do not clear out group flag
        (currentThread in state.entities.teams && !(currentThread in state.entities.contacts))

    const contact = (isGroup ? state.entities.teams[currentThread] : state.entities.contacts[currentThread]) || {}

    let loadingIndicator, emptyStateMessage
    if (state.conversation.isLoading) {
        loadingIndicator = (
            <div style={{
                position: 'absolute',
                marginLeft: '-64px',
                width: 'calc(100% - 640px)',
                transform: 'translate(50%, 50%)'
            }}>
                <Spinner size={128} color="#314A68"/>
            </div>
        )
    }
    else if (!state.messages.isSending && !state.conversation.currentThread) {
        if (state.messages.inbox.length === 0)
            emptyStateMessage = (
                <div className="emptyState" style={{height: messageListHeight}}>
                    <div>Send a message to get started 💬</div>
                </div>
            )
        else
            emptyStateMessage = (
                <div className="emptyState" style={{height: messageListHeight}}>
                    <div>Select a message to get started</div>
                    <div className="unread">You have {state.messages.unreadCount} unread message{state.messages.unreadCount !== 1 ? 's' : ''} 💬</div>
                </div>
            )
    }

    let errorMessage
    if (state.conversation.error) {
        errorMessage = <div style={{
            color: 'red',
            weight: '700'
        }}>{state.conversation.error}</div>
    }

    let threadTitle, threadName, url
    if (isGroup && contact.id) {
        url = '#'
        threadName = getTeamName(contact, state.entities.contacts)
    }
    else if (contact.id) {
        url = `/record/client/${contact.id}`
        threadName = contact.name
    }

    if (threadName) {
        threadTitle = <Link style={{
            paddingTop: '23px',
            paddingLeft: '24px',
            lineHeight: '24px',
            fontSize: '14px',
            color: '#3D84F6',
            fontWeight: 700,
            textDecoration: 'none',
        }} to={url}>{threadName} <RightArrowBlue style={{marginLeft: '6px'}} /></Link>
    }

    const emptyHelperText = useCallback(() => {
        if (isGroup && newGroupMessage) {
            return <>
                Your message to {threadName} will be delivered to their phones via regular SMS text from your dedicated business number.<br /><br />
                These clients will receive your message as Bcc, meaning client replies will be sent privately to you.<br /><br />
            </>
        }
        return ''
    }, [isGroup, newGroupMessage, threadName])

    return (
        <div>
            <ConversationHeader title={threadTitle} trailingWidget={<UserHeader />} />
            {errorMessage}
            {loadingIndicator}
            {emptyStateMessage}
            {state.conversation.currentThread && (
                <MessagesList id="messageList"
                              style={{height: messageListHeight, width: 'calc(100vw - 655px)'}}>
                    {!state.conversation.messages.length && <div className="helperText">{emptyHelperText()}</div>}
                    {state.conversation.messages.slice().reverse().map((message) => {
                        return (
                            <MessageBubble key={`message-${message.id}`}
                                           message={message}/>
                        )
                    })}
                    <span ref={lastMessageRef}
                          style={{float: 'right', width: '1px', clear: 'both'}}/>
                </MessagesList>
            )}
        </div>
    )
}

ConversationThreadView.propTypes = {
    userId: PropTypes.string,
}