import moment from "moment"
import { apiClient, generateCancelToken } from './apiclient'
import axios from 'axios'
import merge from 'lodash.merge'
import keyBy from 'lodash.keyby'
import debounce from 'lodash.debounce'
import { processError } from './error'

export const DAY_FORMAT = 'YYYYMMDD'

let cancelToken

const initialState = {
    isSending: false,
    currentDate: moment(),
    currentView: 'week',
    eventsByDate: {},
    dataAvailableDates: {},
    // Calendar search
    searchText: '',
    isSearching: false,
    didSearch: false,
    searchResults: [],
}

export default function calendarReducer(state = initialState, action) {
    switch (action.type) {
        case 'calendar/REQUEST':
            return {
                ...state,
                currentDate: action.date || state.currentDate,
                currentView: action.view || state.currentView,
                isSending: action.sending,
            }
        case 'calendar/SET_EVENTS':
            const byDay = {}
            const addEntriesByDay = (entries, type) => {
                for (let i = 0; i < entries.length; i++) {
                    const entry = entries[i]
                    const startMoment = moment(entry.date)
                    const startDate = startMoment.format(DAY_FORMAT)
                    if (!byDay[startDate]) {
                        byDay[startDate] = []
                    }

                    // Add a duration for events so they render on the week/month view
                    if (type === 'event') {
                        entry.duration = moment.duration(moment(entry.end_date).diff(startMoment)).asMinutes()
                    }

                    // Determine if this is a multiday event now
                    const isMultiDay = entry.end_date && startMoment.dayOfYear() !== moment(entry.end_date).dayOfYear()

                    const calendarEntry = {
                        type,
                        data: entry,
                        title: type === 'event' ? entry.title : undefined,
                        start: startMoment,
                        end: entry.end_date ? moment(entry.end_date) : startMoment.clone().add(entry.duration || 60, 'minutes'),
                        isMultiDay,
                    }
                    byDay[startDate].push(calendarEntry)

                    // Support multi-day entries by adding other days that the entry spans
                    if (isMultiDay) {
                        const duration = moment.duration(moment(entry.end_date).diff(startMoment))
                        for (let j = 0; j < duration.days(); j++) {
                            const multiDayIncrement = startMoment.clone().add(j+1, 'day')
                            const dayFormat = multiDayIncrement.format(DAY_FORMAT)
                            if (!byDay[dayFormat]) {
                                byDay[dayFormat] = []
                            }

                            // calendarEntry.start = multiDayIncrement
                            byDay[dayFormat].push(calendarEntry)
                        }
                    }
                }
            }

            // Sort bookings, tasks, classes and events together into buckets by day (YYYYMMDD)
            addEntriesByDay(action.bookings, 'booking')
            addEntriesByDay(action.events, 'event')
            addEntriesByDay(action.classes, 'class')
            addEntriesByDay(action.blocks, 'block')

            // Merge new data with existing data
            const mergedDays = merge({}, state.eventsByDate, byDay)

            return {
                ...state,
                eventsByDate: mergedDays,
            }
        case 'calendar/SET_SEARCHING':
            return {
                ...state,
                searchText: action.query || state.searchText,
                isSearching: action.searching,
            }
        case 'calendar/CLEAR_SEARCH_RESULT':
            return {
                ...state,
                isSearching: false,
                searchText: '',
                didSearch: false,
                searchResults: [],
            }
        case 'calendar/SET_SEARCH_RESULT':
            return {
                ...state,
                didSearch: true,
                searchResults: action.results,
            }
        default:
            return state
    }
}

function calendarRequest(date, view, sending) {
    return { type: 'calendar/REQUEST', date, view, sending }
}

function calendarEvents(bookings, tasks, blocks, classes, events) {
    return { type: 'calendar/SET_EVENTS', bookings, tasks, blocks, classes, events }
}

export const getCalendar = (date, view, cancelToken) => {
    return dispatch => {
        // TODO We may want to think of ways to render the UI if the data is present already.
        // The biggest concern would be that it is stale data. We could refresh it in the background
        // or we could have a way to push data updates to the react state.

        dispatch(clearSearch())
        dispatch(calendarRequest(date ,view, true))

        const data = {}
        if (view === 'week') {
            data.start = date.clone().startOf('week').format('YYYY-MM-DDTHH:mm:ssZ')
            data.end = date.clone().endOf('week').format('YYYY-MM-DDTHH:mm:ssZ')
        }
        else if (view === 'month') {
            data.start = date.clone().startOf('month').startOf('week').format('YYYY-MM-DDTHH:mm:ssZ')
            data.end = date.clone().endOf('month').endOf('week').format('YYYY-MM-DDTHH:mm:ssZ')
        }
        else {
            data.start = date.clone().startOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
            data.end = date.clone().endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
        }

        apiClient.post('/calendar/sync', data, {cancelToken})
            .then(resp => resp.data)
            .then((json) => {
                if (json.contacts) {
                    const contactMap = keyBy(json.contacts, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'contacts', map: contactMap})
                }

                if (json.bookings) {
                    const bookingMap = keyBy(json.bookings, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'bookings', map: bookingMap})
                }

                if (json.items) {
                    const itemMap = keyBy(json.items, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'items', map: itemMap})
                }

                if (json.events) {
                    const eventMap = keyBy(json.events, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'events', map: eventMap})
                }

                dispatch(calendarEvents(json.bookings, json.tasks, json.blocks, json.classes, json.events))
            })
            .catch(error => {
                if (axios.isCancel(error))
                    console.log('axios cancel')
                else {
                    dispatch(processError(error))
                    return Promise.reject()
                }
            })
            .finally(() => {
                dispatch(calendarRequest(null, null, false))
            })
    }
}

// Search related

const setSearching = (searching, query) => {
    return { type: 'calendar/SET_SEARCHING', searching, query }
}

const setSearchResult = (results) => {
    return { type: 'calendar/SET_SEARCH_RESULT', results }
}

export const clearSearch = () => {
    if (cancelToken) {
        performSearchDebounced.cancel()
        cancelToken.cancel('clearSearch')
        cancelToken = null
    }

    return { type: 'calendar/CLEAR_SEARCH_RESULT' }
}

const performSearchDebounced = debounce((dispatch, query) => {
    dispatch(setSearching(true))

    // Cancel the previous query, if is still running
    if (cancelToken) {
        cancelToken.cancel('stop calendarSearch')
        cancelToken = null
    }

    cancelToken = generateCancelToken()

    const data = {keywords: query}
    apiClient.post('/calendar/search', data, {cancelToken: cancelToken.token})
        .then(resp => resp.data)
        .then(json => {
            const calendarResults = []

            // Sort all the results by type
            const resultsLists = {}
            json.types.forEach((type, index) => {
                if (!resultsLists[type]) resultsLists[type] = []
                resultsLists[type].push(json.results[index])
            })

            // Get each list into an map and update the entities
            Object.keys(resultsLists).forEach((type) => {
                const objectMap = keyBy(resultsLists[type], 'id')
                dispatch({type: 'entities/UPDATE', name: type, map: objectMap})

                resultsLists[type].forEach((entry) => {
                    // Convert the objects into calendar models that we use for rendering
                    const startMoment = moment(entry.date)

                    // Add a duration for events so they render on the week/month view
                    if (type === 'event') {
                        entry.duration = moment.duration(moment(entry.end_date).diff(startMoment)).asMinutes()
                    }

                    // Determine if this is a multiday event now
                    const isMultiDay = entry.end_date && startMoment.dayOfYear() !== moment(entry.end_date).dayOfYear()

                    const calendarEntry = {
                        type,
                        data: entry,
                        title: type === 'event' ? entry.title : undefined,
                        start: startMoment,
                        end: entry.end_date ? moment(entry.end_date) : startMoment.clone().add(entry.duration || 60, 'minutes'),
                        isMultiDay,
                    }

                    calendarResults.push(calendarEntry)
                })
            })

            dispatch(setSearchResult(calendarResults))
            dispatch(setSearching(false))
        })
        .catch(error => {
            if (!axios.isCancel(error)) {
                dispatch(processError(error))
                dispatch(setSearching(false))
            }
        })
}, 250, {trailing: true})

export const searchCalendar = (query) => {
    return dispatch => {
        dispatch(setSearching(true, query))

        performSearchDebounced(dispatch, query)
    }
}