import React, {useEffect, useState} from "react";
import {useAppDispatch, usePSUser} from "../../hooks";
import {AppHeader} from "../app/AppHeader";
import {PSButton, PSButtonPrimary} from "../app/PSButton";
import {PSAvailableHoursPerDay} from "../app/PSAvailableHoursPerDay";
import moment from "moment";
import "./onboarding.css";
import {apiClient} from "../../modules/apiclient";
import {Result} from "../../utils";
import localforage from "localforage";
import {processError} from "../../modules/error";
import {Spinner} from "@zendeskgarden/react-loaders";
import {availabilityHours} from "../settings/Options";
import {nextOnboardingStep} from "../../utils/onboarding";
import {useLocation} from "react-router";
import {push} from "connected-react-router";

export const OnboardingHours = () => {
    const dispatch = useAppDispatch()
    const user = usePSUser()

    const location = useLocation();

    const [simpleMode, setSimpleMode] = useState(!location.pathname.endsWith('full'))
    const [isSending, setIsSending] = useState(false)

    useEffect(() => {
        setSimpleMode(!location.pathname.endsWith('full'))
    }, [location.pathname])

    const onNextHandler = () => {
        if (!user || isSending) return

        setIsSending(true)

        let scheduleVal = monEnabled ? 1 : 0

        let userParams: any = {
            id: user.id,
        }
        userParams.mon_start_hour = monEnabled ? monStart.value.toString() : ''
        userParams.mon_end_hour = monEnabled ? monEnd.value.toString() : ''
        if (simpleMode) {
            if (monEnabled) scheduleVal = 1 | (1 << 1) | (1 << 2) | (1 << 3) | (1 << 4)
            userParams.tue_start_hour = monEnabled ? monStart.value.toString() : ''
            userParams.tue_end_hour = monEnabled ? monEnd.value.toString() : ''
            userParams.wed_start_hour = monEnabled ? monStart.value.toString() : ''
            userParams.wed_end_hour = monEnabled ? monEnd.value.toString() : ''
            userParams.thu_start_hour = monEnabled ? monStart.value.toString() : ''
            userParams.thu_end_hour = monEnabled ? monEnd.value.toString() : ''
            userParams.fri_start_hour = monEnabled ? monStart.value.toString() : ''
            userParams.fri_end_hour = monEnabled ? monEnd.value.toString() : ''
        }
        else {
            if (tueEnabled) scheduleVal |= (1 << 1)
            userParams.tue_start_hour = tueEnabled ? tueStart.value.toString() : ''
            userParams.tue_end_hour = tueEnabled ? tueEnd.value.toString() : ''
            if (wedEnabled) scheduleVal |= (1 << 2)
            userParams.wed_start_hour = wedEnabled ? wedStart.value.toString() : ''
            userParams.wed_end_hour = wedEnabled ? wedEnd.value.toString() : ''
            if (thuEnabled) scheduleVal |= (1 << 3)
            userParams.thu_start_hour = thuEnabled ? thuStart.value.toString() : ''
            userParams.thu_end_hour = thuEnabled ? thuEnd.value.toString() : ''
            if (friEnabled) scheduleVal |= (1 << 4)
            userParams.fri_start_hour = friEnabled ? friStart.value.toString() : ''
            userParams.fri_end_hour = friEnabled ? friEnd.value.toString() : ''
        }
        if (satEnabled) scheduleVal |= (1 << 5)
        userParams.sat_start_hour = satEnabled ? satStart.value.toString() : ''
        userParams.sat_end_hour = satEnabled ? satEnd.value.toString() : ''
        if (sunEnabled) scheduleVal |= (1 << 6)
        userParams.sun_start_hour = sunEnabled ? sunStart.value.toString() : ''
        userParams.sun_end_hour = sunEnabled ? sunEnd.value.toString() : ''
        userParams.schedule = scheduleVal;

        apiClient.post(`/user/${user.id}`, userParams)
            .then(response => response.data)
            .then(json => {
                // Save the onboarding state and advance to the next screen
                apiClient
                    .post('/onboarding', {state: 'ob_hours'})
                    .then(response => response.data)
                    .then(json2 => {
                        // Update cached user object
                        if (json2.user) localforage.setItem('user', json2.user)

                        // Go to the next step
                        Result(dispatch(nextOnboardingStep(json2.user)))
                            .finally(() => setIsSending(false))
                    })
            })
            .catch(error => {
                dispatch(processError(error))
                setIsSending(false)
            })
    }

    const defaultStart = {value: '8', label: '8:00am'}
    const defaultEnd = {value: '18', label: '6:00pm'}

    const [monEnabled, monSetEnabled] = useState(false)
    const [monStart, monSetStart] = useState(defaultStart)
    const [monEnd, monSetEnd] = useState(defaultEnd)
    const [tueEnabled, tueSetEnabled] = useState(false)
    const [tueStart, tueSetStart] = useState(defaultStart)
    const [tueEnd, tueSetEnd] = useState(defaultEnd)
    const [wedEnabled, wedSetEnabled] = useState(false)
    const [wedStart, wedSetStart] = useState(defaultStart)
    const [wedEnd, wedSetEnd] = useState(defaultEnd)
    const [thuEnabled, thuSetEnabled] = useState(false)
    const [thuStart, thuSetStart] = useState(defaultStart)
    const [thuEnd, thuSetEnd] = useState(defaultEnd)
    const [friEnabled, friSetEnabled] = useState(false)
    const [friStart, friSetStart] = useState(defaultStart)
    const [friEnd, friSetEnd] = useState(defaultEnd)
    const [satEnabled, satSetEnabled] = useState(false)
    const [satStart, satSetStart] = useState(defaultStart)
    const [satEnd, satSetEnd] = useState(defaultEnd)
    const [sunEnabled, sunSetEnabled] = useState(false)
    const [sunStart, sunSetStart] = useState(defaultStart)
    const [sunEnd, sunSetEnd] = useState(defaultEnd)

    const weekdays = [
        {enabled: monEnabled, setEnabled: monSetEnabled, start: monStart, setStart: monSetStart, end: monEnd, setEnd: monSetEnd},
        {enabled: tueEnabled, setEnabled: tueSetEnabled, start: tueStart, setStart: tueSetStart, end: tueEnd, setEnd: tueSetEnd},
        {enabled: wedEnabled, setEnabled: wedSetEnabled, start: wedStart, setStart: wedSetStart, end: wedEnd, setEnd: wedSetEnd},
        {enabled: thuEnabled, setEnabled: thuSetEnabled, start: thuStart, setStart: thuSetStart, end: thuEnd, setEnd: thuSetEnd},
        {enabled: friEnabled, setEnabled: friSetEnabled, start: friStart, setStart: friSetStart, end: friEnd, setEnd: friSetEnd},
        {enabled: satEnabled, setEnabled: satSetEnabled, start: satStart, setStart: satSetStart, end: satEnd, setEnd: satSetEnd},
        {enabled: sunEnabled, setEnabled: sunSetEnabled, start: sunStart, setStart: sunSetStart, end: sunEnd, setEnd: sunSetEnd},
    ]

    useEffect(() => {
        if (!user) return

        // Ensure that the user is allowed to use simple mode by making sure that
        // all the weekday start and end times are the same
        if (simpleMode) {
            const startSet = new Set([
                user.mon_start_hour, user.tue_start_hour, user.wed_start_hour,
                user.thu_start_hour, user.fri_start_hour
            ])
            const endSet = new Set([
                user.mon_end_hour, user.tue_end_hour, user.wed_end_hour,
                user.thu_end_hour, user.fri_end_hour
            ])
            const canUseSimpleMode = startSet.size === 1 && endSet.size === 1
            if (!canUseSimpleMode) {
                setSimpleMode(false)
                return
            }
        }

        for (let i = 0; i < 7; i++) {
            const dayAbbreviation = moment().day(i+1).format('ddd').toLowerCase()
            // @ts-ignore
            let startHour = parseFloat(user[`${dayAbbreviation}_start_hour`])
            // @ts-ignore
            let endHour = parseFloat(user[`${dayAbbreviation}_end_hour`])

            if (simpleMode) {
                if (i === 0) {
                    startHour = parseFloat(user.mon_start_hour)
                    endHour = parseFloat(user.mon_end_hour)
                }
                else if (i === 5) {
                    startHour = parseFloat(user.sat_start_hour)
                    endHour = parseFloat(user.sat_end_hour)
                }
                else if (i === 6) {
                    startHour = parseFloat(user.sun_start_hour)
                    endHour = parseFloat(user.sun_end_hour)
                }
            }

            if (startHour && endHour) {
                weekdays[i].setEnabled(true)
                weekdays[i].setStart(availabilityHours[startHour*2])
                weekdays[i].setEnd(availabilityHours[endHour*2])
            }
            else {
                weekdays[i].setEnabled(false)
            }
        }
    }, [user, simpleMode])

    return (
        <AppHeader title="Setup" showBackButton middleWidget={null}>
            {isSending && (
                <div className="loadingOverlay">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                        <h1>Saving...</h1>
                    </div>
                </div>
            )}
            <div className="scheduleClient servicesSettings onboarding onboardingHours">
                <div className="header">
                    <h1>{simpleMode ? 'Set your schedule' : 'Your hours'}</h1>
                    {simpleMode && (
                        <PSButton style={{height: '40px', marginLeft: 'auto'}}
                                  onClick={() => dispatch(push(`/onboarding/hours/${simpleMode ? 'full' : 'simple'}`))}>
                            Edit
                        </PSButton>
                    )}
                    <PSButtonPrimary style={{height: '40px', marginLeft: simpleMode ? 16 : 'auto'}}
                                     onClick={() => onNextHandler()}>
                        Next
                    </PSButtonPrimary>
                </div>

                <div className="separator" />

                <div className="form">
                    {simpleMode && (
                        <div className="text">Tap "edit" to customize your business availability.</div>
                    )}
                    {!simpleMode && (
                        <div className="text">Add your business hours and let clients know when you're available.</div>
                    )}
                    {simpleMode && (
                        <>
                            <PSAvailableHoursPerDay label={"Monday through Friday"}
                                                    enabled={monEnabled}
                                                    onEnabled={(b) => monSetEnabled(b)}
                                                    startValue={monStart}
                                                    onStartChanged={(i) => monSetStart(i)}
                                                    endValue={monEnd}
                                                    onEndChanged={monSetEnd} />
                            <PSAvailableHoursPerDay label={"Saturday"}
                                                    enabled={satEnabled}
                                                    onEnabled={(b) => satSetEnabled(b)}
                                                    startValue={satStart}
                                                    onStartChanged={(i) => satSetStart(i)}
                                                    endValue={satEnd}
                                                    onEndChanged={satSetEnd} />
                            <PSAvailableHoursPerDay label={"Sunday"}
                                                    enabled={sunEnabled}
                                                    onEnabled={(b) => sunSetEnabled(b)}
                                                    startValue={sunStart}
                                                    onStartChanged={(i) => sunSetStart(i)}
                                                    endValue={sunEnd}
                                                    onEndChanged={sunSetEnd} />
                        </>
                    )}
                    {!simpleMode && (
                        <>
                            <PSAvailableHoursPerDay label={"Monday"}
                                                    enabled={monEnabled}
                                                    onEnabled={(b) => monSetEnabled(b)}
                                                    startValue={monStart}
                                                    onStartChanged={(i) => monSetStart(i)}
                                                    endValue={monEnd}
                                                    onEndChanged={monSetEnd} />
                            <PSAvailableHoursPerDay label={"Tuesday"}
                                                    enabled={tueEnabled}
                                                    onEnabled={(b) => tueSetEnabled(b)}
                                                    startValue={tueStart}
                                                    onStartChanged={(i) => tueSetStart(i)}
                                                    endValue={tueEnd}
                                                    onEndChanged={tueSetEnd} />
                            <PSAvailableHoursPerDay label={"Wednesday"}
                                                    enabled={wedEnabled}
                                                    onEnabled={(b) => wedSetEnabled(b)}
                                                    startValue={wedStart}
                                                    onStartChanged={(i) => wedSetStart(i)}
                                                    endValue={wedEnd}
                                                    onEndChanged={wedSetEnd} />
                            <PSAvailableHoursPerDay label={"Thursday"}
                                                    enabled={thuEnabled}
                                                    onEnabled={(b) => thuSetEnabled(b)}
                                                    startValue={thuStart}
                                                    onStartChanged={(i) => thuSetStart(i)}
                                                    endValue={thuEnd}
                                                    onEndChanged={thuSetEnd} />
                            <PSAvailableHoursPerDay label={"Friday"}
                                                    enabled={friEnabled}
                                                    onEnabled={(b) => friSetEnabled(b)}
                                                    startValue={friStart}
                                                    onStartChanged={(i) => friSetStart(i)}
                                                    endValue={friEnd}
                                                    onEndChanged={friSetEnd} />
                            <PSAvailableHoursPerDay label={"Saturday"}
                                                    enabled={satEnabled}
                                                    onEnabled={(b) => satSetEnabled(b)}
                                                    startValue={satStart}
                                                    onStartChanged={(i) => satSetStart(i)}
                                                    endValue={satEnd}
                                                    onEndChanged={satSetEnd} />
                            <PSAvailableHoursPerDay label={"Sunday"}
                                                    enabled={sunEnabled}
                                                    onEnabled={(b) => sunSetEnabled(b)}
                                                    startValue={sunStart}
                                                    onStartChanged={(i) => sunSetStart(i)}
                                                    endValue={sunEnd}
                                                    onEndChanged={sunSetEnd} />
                        </>
                    )}
                </div>
            </div>
        </AppHeader>
    )
}