import {Field} from "./Field";

export class Form {
    id?: string;
    image_uri?: string;
    image?: string;
    name: string;
    memo: string;
    contract?: string;
    always_require: boolean;
    is_public: boolean;
    fields?: Field[];
    selected_fields?: Field[]; // For onboarding, allows users to select which fields to include in a form
    key?: string;
    photo?: File; // Transient storage for FormEdit to use when leaving to manage questions
    edition?: string;
    edition_type?: string;
    associated_with_service?: boolean;

    constructor() {
        this.name = ''
        this.memo = ''
        this.always_require = false
        this.is_public = false
    }

    toObject(forEdit: boolean): any {
        const data = { ...this }
        // If we are creating a new form, the questions with select, multiselect
        // need to formatted differently
        if (!forEdit && data.fields) {
            data.fields = data.fields.map((f: Field) => f.toObject())
        }
        return data
    }

    setData(data: any) {
        this.id = data.id
        this.image_uri = data.image_uri
        this.image = data.image
        this.name = data.name
        this.memo = data.memo
        this.contract = data.contract
        this.always_require = data.always_require
        this.is_public = data.is_public
        this.key = data.key
        if (data.fields) {
            this.fields = data.fields.map((f: any) => {
                const field = new Field()
                field.setData(f)
                return field
            })
        }
        if (data.edition)
            this.edition = data.edition
        if (data.edition_type)
            this.edition_type = data.edition_type
        this.associated_with_service = data.associated_with_service
    }
}