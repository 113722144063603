import React from "react";
import {Contract} from "../../models/Contract";
import {useAppDispatch} from "../../hooks";
import {push} from "connected-react-router";
import {Field, Label, Toggle} from "@zendeskgarden/react-forms";

type Props = {
    contract: Contract,
    isChecked: boolean,
    onToggleItem: (b: boolean) => void,
}

export const OnboardingContract = ({contract, isChecked, onToggleItem}: Props) => {
    const dispatch = useAppDispatch()

    return (
        <div className={`serviceCard onboardingCard contractCard ${isChecked ? 'selected' : ''}`}>
            <div className="topRow">
                <div className="name">{contract.name}</div>
                <div className="edit">
                    {isChecked && (
                        <div className={`buttonLink brightBlue ${isChecked ? '' : 'disabledLink'}`}
                             onClick={() => {
                                 if (isChecked) dispatch(push('/onboarding/contract/edit', {contract}))
                             }}
                        >
                            Edit
                        </div>
                    )}
                </div>
                <div className="toggle">
                    <Field>
                        <Toggle checked={isChecked}
                                onChange={e => {
                                    onToggleItem(e.target.checked)
                                }}>
                            <Label>&nbsp;</Label>
                        </Toggle>
                    </Field>
                </div>
            </div>
            <div className="bottomRow">
                <div className="contractBody">{contract.memo}</div>
                {!contract.signature_data && (
                    <div className="signature">Select & edit to add your signature</div>
                )}
                {contract.signature_data && (
                    <div className="signature">
                    <img style={{height: '60px', width: 'auto', objectFit: 'cover'}}
                             alt="Signature"
                             src={`data:image/png;base64,${contract.signature_data}`} />
                    </div>
                )}
            </div>
        </div>
    )
}