// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { generateCancelToken } from '../../modules/apiclient'
import {
    DashboardState,
    filterDashboardItems, getDashboard, markDashboard, switchTab
} from '../../modules/dashboard'
import {
    Crosshair,
    LabelSeries,
    LineSeries, VerticalBarSeries,
    XAxis,
    XYPlot,
} from 'react-vis'
import styled from 'styled-components'
import './dashboard.css'
import {dashboardSetOptions} from '../../modules/dashboard'
import 'react-vis/dist/style.css'
import {Dots, Spinner} from '@zendeskgarden/react-loaders'
import * as numbers from '../../utils/numbers'
import { AppHeader } from '../app/AppHeader'
import {useAppDispatch} from "../../hooks";
import {RootState} from "../../store";
import {ReactComponent as PocketSuiteLogo} from "../../icons/PocketSuite.svg";
import {DashboardCard} from "./DashboardCard";
import {Dashboard} from "../../models/Dashboard";
import {DashboardGroup} from "./DashboardGroup";
import {push} from "connected-react-router";
import localforage from "localforage";
import {lookupIndustryTitle} from "../../models/Industry";
import {userLogout} from "../../modules/login";
import {PSMainButton} from "../app/PSButton";
import {ReactComponent as ChargeIcon} from "../../icons/button_icon_charge.svg";
import {ReactComponent as InvoiceIcon} from "../../icons/button_icon_invoice.svg";
import {ReactComponent as ScheduleIcon} from "../../icons/button_icon_schedule.svg";
import {RequestCallComponent} from './RequestCallComponent'
import {DashboardAlertBanner} from './DashboardAlertBanner'
import {GetSetupAlertBanner} from './GetSetupAlertBanner'
import {GetTeamAlertBanner} from './GetTeamAlertBanner'
import {GetAffiliateAlertBanner} from './GetAffiliateAlertBanner'
import {OnboardingSetupDashboard} from "./OnboardingSetupDashboard";
import {LaunchBusiness} from "./LaunchBusiness";
import {Button, ButtonGroup} from "@zendeskgarden/react-buttons";
import {SHOW_TEXT_ME_DASHBOARD} from "../../utils/constants";
import { getRecord } from '../../modules/records'
import { fetchDashboards } from '../../modules/dashboard';
import { Tab } from '../../models/Tab'
import { Edition } from '../../models/Edition'
import {TextMeBanner} from "./TextMeBanner";
import { Result } from '../../utils'
import {dashboardKey} from '../../utils/constants'
import { CancelTokenSource } from 'axios'
import {currencyFloat} from "../../utils/numbers";

const GraphTooltip = styled('div')`
  position: absolute;
  font-size: 11px;
  pointer-events: none;
  border-radius: 4px;
  background: #3a3a48;
  color: #fff;
  padding: 7px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  text-align: left;
`

const PSTabButton = styled(Button)`
  border: 1px solid #e6e6e6;
  border-radius: 20px;
  width: 140px;
  height: 40px;
  font-weight: 700;
  font-size: 15px;

  &:active, &[aria-pressed='true'] {
    background-color: #FFFFFF;
    color: #0f0f0f;
  }
`

export const DashboardIndex = () => {
    const dispatch = useAppDispatch()
    const state: DashboardState = useSelector((state: RootState) => state.dashboard)
    const entityState: any = useSelector((state: RootState) => state.entities)
    const params = useParams<{userId: string}>()

    const [dashboards, setDashboards] = useState<Dashboard[]>([])
    const [tabs, setTabs] = useState<Tab[]>([])
    const [edition, setEdition] = useState<Edition>()

    const [dashboardCancelToken, setDashboardCancelToken] = useState<CancelTokenSource>()
    const fetchDashboardTab = useCallback((tabName: string) => {
        const cancelToken = generateCancelToken()
        setDashboardCancelToken(currentValue => {
            if (currentValue) {
                currentValue.cancel()
            }
            return cancelToken
        })

        localforage.getItem(dashboardKey(tabName, params.userId)).then((d: any) => {
            if (d?.dashboards && d.dashboards.length) {
                setDashboards(d.dashboards as Dashboard[])
                setTabs(d.tabs as Tab[])
                setEdition(d.edition)
            }
            else {
                setDashboards([])
                setTabs([])
                setEdition(undefined)
            }

            // always fetch new dashboard
            Result(dispatch(fetchDashboards(tabName, params.userId, cancelToken.token)))
                .finally(() => setDashboardCancelToken(undefined))

            return (_: any) => {
                cancelToken.cancel()
            }
        })
    }, [dispatch, params.userId])

    useEffect(() => {
        fetchDashboardTab('main')
    }, [fetchDashboardTab])

    useEffect(() => {
        setDashboards(state.dashboards)
        setTabs(state.tabs)
        setEdition(state.edition)
    }, [state.dashboards, state.tabs, state.edition])

    const [user, setUser] = useState<any>()
    useEffect(() => {
        localforage.getItem('user').then((user: any) => {
            // If the user is not set, force the user to login to fix whatever broke on their browser
            if (!user) {
                dispatch(userLogout())
                return
            }

            setUser(user)
        })
    }, [dispatch])

    const [textMeBanner, setTextMeBanner] = useState(false)
    useEffect(() => {
        localforage.getItem(SHOW_TEXT_ME_DASHBOARD).then((show?: boolean) => {
            setTextMeBanner(show ?? false)
        })
    }, [])
    const onTextMeBannerCompleted = useCallback(() => {
        alert('Please check your phone for the PocketSuite download link.')
        localforage.setItem(SHOW_TEXT_ME_DASHBOARD, false)
        setTextMeBanner(false)
    }, [])

    const [editionName, setEditionName] = useState<string>()
    useEffect(() => {
        if (!user || state.isSending) return
        if (user.edition && edition) {
            if (edition.allow_basic_plan && edition.prefix) {
                if (user.plan === 'premium')
                    setEditionName(`${edition.prefix} edition`)
                else if (user.plan === 'plus')
                    setEditionName(`${edition.prefix} Team edition`)
                else
                    setEditionName(`${edition.prefix} Basic edition`)
                return
            }
        }
        if (edition && user.plan)
            setEditionName(edition.name)
        else if (user.industry && user.plan)
            lookupIndustryTitle(user.industry).then((s: string) => setEditionName(`${s} edition`))
        else if (user.plan === 'plus')
            setEditionName( 'Team edition')
        else if (user.plan === 'basic')
            setEditionName( 'Basic edition')
        else
            setEditionName( 'Premium edition')
    }, [user, edition, state.isSending])

    const [showIncome, setShowIncome] = useState(false)
    const [incomeDashboard, setIncomeDashboard] = useState<Dashboard>()
    useEffect(() => {
        setShowIncome(!!incomeDashboard && localStorage.getItem('income') === '1')
    }, [incomeDashboard])

    // Initial page load, fetch the default metrics
    useEffect((): any => {
        if (localStorage.getItem('income') !== '1') return

        const cancelToken = generateCancelToken()
        dispatch(getDashboard(state.period, 'income', state.topList, cancelToken.token, params.userId))
        return (_: any) => {
            cancelToken.cancel()
        }
    }, [dispatch, state.period, state.topList, params.userId])

    const [credentialDashboard, setCredentialDashboard] = useState<Dashboard>()
    const [alertBanner, setAlertBanner] = useState<Dashboard>()
    const [teamAlertBanner, setTeamAlertBanner] = useState<Dashboard>()
    const [affiliateAlertBanner, setAffiliateAlertBanner] = useState<Dashboard>()
    const [onboardingDashboard, setOnboardingDashboard] = useState<Dashboard>()
    const [showSetupCall, setShowSetupCall] = useState(false)
    const [groupedDashboards, setGroupedDashboards] = useState<Dashboard[][]>([])
    const [actionButtons, setActionButtons] = useState(['charge', 'invoice', 'schedule'])
    useEffect(() => {
        // Group dashboards into buckets of 4
        const dashboardsCopy = filterDashboardItems(dashboards).slice()
        const bucketCount = Math.ceil(dashboardsCopy.length / 4)
        const buckets = []
        for (let i = 0; i < bucketCount; i++) {
            buckets[i] = dashboardsCopy.splice(0, 4)
        }
        setGroupedDashboards(buckets)

        const credentialDashboards = filterDashboardItems(dashboards, ['alerts_credential'])
        if (credentialDashboards.length) {
            setCredentialDashboard(credentialDashboards[0])
        }
        const alertBanner = filterDashboardItems(dashboards, ['alerts_asap']).find(alert => alert)
        if (alertBanner) {
            setAlertBanner(alertBanner)
        }
        const teamAlertBanner = filterDashboardItems(dashboards, ['alerts_team']).find(alert => alert)
        if (teamAlertBanner) {
            setTeamAlertBanner(teamAlertBanner)
        }
        const affiliateAlertBanner = filterDashboardItems(dashboards, ['alerts_affiliate']).find(alert => alert)
        if (affiliateAlertBanner) {
            setAffiliateAlertBanner(affiliateAlertBanner)
        }

        const onboarding = filterDashboardItems(dashboards, ['setup_onboarding', 'setup_launch'])
        if (onboarding.length) {
            setOnboardingDashboard(onboarding[0])
        }

        const actions = filterDashboardItems(dashboards, ['schedule', 'charge', 'invoice'])
        setActionButtons(actions.map(dashboard => dashboard.id))

        const income = filterDashboardItems(dashboards, ['income'])
        setIncomeDashboard(income.length ? income[0] : undefined)

    }, [dashboards, params.userId])

    const onCredentialBanner = () => {
        if (!!credentialDashboard?.meta_data?.credential) {
            dispatch(push(`/settings/calendar/setup/${credentialDashboard?.meta_data?.credential}`))
        }
        else {
            dispatch(push('/settings/calendar/sync'))
        }
    }

    const [client, setClient] = useState<any>()
    useEffect(() => {
        if (!params.userId) return

        const client = Object.keys(entityState.users).includes(params.userId) ?
            entityState.users[params.userId] :
            undefined

        setClient(client)
    }, [params.userId, entityState.users, dispatch])

    useEffect(() => {
        if (!params.userId) {
            dispatch(dashboardSetOptions('mtd', state.topList))
            return
        }

        if (!Object.keys(entityState.users).includes(params.userId)) {
            dispatch(getRecord('user', params.userId))
        }

        dispatch(dashboardSetOptions('ytd', state.topList))

        // we only care to dispatch if the user doesn't exist in the current state, but we don't want to dispatch on all state updates so we exclude entityState users
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.userId, state.topList, dispatch])

    const [loadingDashboards, setLoadingDashboards] = useState(true)
    useEffect(() => {
        setLoadingDashboards(!dashboards || dashboards.length === 0)
    }, [dashboards])

    const [graphWidth, setGraphWidth] = useState(Math.min(944, window.innerWidth-312))
    useEffect((): any => {
        const handleResize = () => {
            setGraphWidth(Math.min(944, window.innerWidth-312))
        }
        window.addEventListener('resize', handleResize)

        return (_: any) => {
            window.removeEventListener('resize', handleResize)
        }
    })

    // State for crosshair values in the graph
    const [ crosshairValue, setCrosshairValue ] = useState<any>()
    const onGraphMouseLeave = () => {
        setCrosshairValue(null)
    }
    const onGraphNearestX = (value: any) => {
        setCrosshairValue(value)
    }

    const onView = useCallback((d: Dashboard) => {
        dispatch(push(`/dashboards/${d.id}`, {client, dateFilter: d.date_type}))
    }, [client, dispatch])

    const titleWidget = params.userId ? undefined : (
        <div style={{padding: '15px 0 15px 26px'}}>
            <PocketSuiteLogo />
            {!editionName && (
                <div style={{opacity: 0.4}}><Dots /></div>
            )}
            {editionName && (
                <div style={{color: '#25496B', opacity: 0.4, lineHeight: '14px', fontSize: '13px', textTransform: 'capitalize'}}>
                    {editionName}
                </div>
            )}
        </div>
    )

    let middleWidget
    if (tabs.length > 0) {
        middleWidget = (
            <ButtonGroup className="" style={{display: 'flex', flex: '1 0', justifyContent: 'center', margin: '0 auto', paddingTop: 14}}>
                {tabs.map(tab => (
                    <PSTabButton value={tab.id}
                            key={`tab-${tab.id}`}
                            onClick={() => {
                                if (tab.id !== state.activeTab?.id) {
                                    Result(dispatch(switchTab(tab)))
                                        .then(() => fetchDashboardTab(tab.id))
                                }
                            }}
                    >
                        {tab.label}
                    </PSTabButton>
                ))}
            </ButtonGroup>
        )
    }

    return (
        <div className="dashboard">
        <AppHeader titleWidget={titleWidget} middleWidget={middleWidget} showBackButton={!!params.userId} title={!!params.userId ? client?.name : undefined}>
            {(!state.activeTab || state.activeTab.show_action_bar) && actionButtons?.length > 0 && (
                <div className="mainButtons">
                    {actionButtons.includes('charge') &&
                        <PSMainButton onClick={() => dispatch(push(`/charge/new`, {client}))}>
                            <ChargeIcon />
                            Charge
                        </PSMainButton>
                    }
                    {actionButtons.includes('invoice') &&
                        <PSMainButton onClick={() => dispatch(push(`/invoice/new`, {client}))}>
                            <InvoiceIcon />
                            Invoice
                        </PSMainButton>
                    }
                    {actionButtons.includes('schedule') &&
                        <PSMainButton onClick={() => dispatch(push(`/schedule/new`, {client}))}>
                            <ScheduleIcon />
                            Schedule
                        </PSMainButton>
                    }
                </div>
            )}
            {!!credentialDashboard && (
                <DashboardAlertBanner dashboard={credentialDashboard}
                                      onClick={() => onCredentialBanner()}
                />
            )}
            {textMeBanner && !onboardingDashboard && state.activeTab?.id === 'main' && (
                <TextMeBanner user={user}
                              onClick={onTextMeBannerCompleted} />
            )}
            {!!teamAlertBanner && !onboardingDashboard && state.activeTab?.id === 'main' && (
                <GetTeamAlertBanner dashboard={teamAlertBanner}
                                    onClick={() =>
                                        Result(dispatch(markDashboard('alerts_team', 'upgrade_team')))
                                            .then(()=>{
                                                dispatch(push("/settings/upgrade-team-plan"));
                                            })
                                    }/>
            )}
            {!!affiliateAlertBanner && !onboardingDashboard && state.activeTab?.id === 'main' && (
                <GetAffiliateAlertBanner dashboard={affiliateAlertBanner}
                                    onClick={() =>
                                        Result(dispatch(push("/settings/upgrade-affiliate-plan")))
                                    }/>
            )}
            {!!alertBanner && !onboardingDashboard && state.activeTab?.id === 'main' && (
                <GetSetupAlertBanner dashboard={alertBanner}
                                     onClick={() => setShowSetupCall(true)}
                />
            )}
            {onboardingDashboard && state.activeTab?.id === 'main' && edition?.id && !showIncome && (
                <>
                    <h1 className="editionName">{onboardingDashboard.id === "setup_onboarding" ? editionName : onboardingDashboard.title}</h1>
                    {textMeBanner && (
                        <TextMeBanner user={user}
                                      onClick={onTextMeBannerCompleted} />
                    )}
                    {teamAlertBanner && (
                        <GetTeamAlertBanner dashboard={teamAlertBanner}
                                            onClick={() =>
                                                Result(dispatch(markDashboard('alerts_team', 'upgrade_team')))
                                                    .then(()=>{
                                                        dispatch(push("/settings/upgrade-team-plan"));
                                                    })
                                                }/>
                    )}
                    {affiliateAlertBanner && (
                        <GetAffiliateAlertBanner dashboard={affiliateAlertBanner}
                                                 onClick={() =>
                                                     Result(dispatch(markDashboard('alerts_affiliate')))
                                                         .then(()=>{
                                                             dispatch(push("/settings/upgrade-affiliate-plan"));
                                                         })
                                                 }/>
                    )}
                    {alertBanner && (
                        <GetSetupAlertBanner dashboard={alertBanner}
                                             onClick={() => setShowSetupCall(true)}
                        />
                    )}
                    {onboardingDashboard.id === "setup_onboarding" ?
                        <OnboardingSetupDashboard edition={edition} me={user} title={editionName} /> :
                        <LaunchBusiness me={user} />
                    }

                </>
            )}
            {state.activeTab?.id === 'account' && edition?.id && !showIncome && (
                <>
                    <h1 className="editionName">Why is this screen empty?</h1>
                    <OnboardingSetupDashboard edition={edition}
                                              me={user}
                                              title={editionName}
                                              emptyState
                    />
                </>
            )}
            {showIncome && (
                <div className="wrapper">
                    {(state.isSending || !incomeDashboard) && (
                        <div className="loading">
                            <Spinner size="128" color="#314A68" />
                        </div>
                    )}
                    {!state.isSending && incomeDashboard && (
                        <>
                            <div className="incomeBox">
                                <div className="info">
                                    <h4>Income</h4>
                                    <div className="detail income">
                                        {numbers.abbreviatedDollar(currencyFloat(state.metrics.total.income))}
                                    </div>
                                    <div className="incomeDelta">
                                        {numbers.decoratedDelta(state.metrics.total.income_delta)}
                                    </div>
                                </div>
                                <div className="info">
                                    <h4># of Payments</h4>
                                    <div className="detail">
                                        {state.metrics.total.count}
                                    </div>
                                </div>
                            </div>
                            <div className="viewMore">
                                <div className="view" onClick={() => onView(incomeDashboard)}>View</div>
                            </div>
                            <XYPlot
                                xType="ordinal"
                                width={graphWidth}
                                height={340}
                                margin={{top: 30}}
                                onMouseLeave={onGraphMouseLeave}
                            >
                                <VerticalBarSeries
                                    data={state.metrics.graphData}
                                    color="#62ce9a"
                                    onNearestX={onGraphNearestX}
                                    barWidth={0.85}
                                />
                                <LineSeries
                                    data={[
                                        {x: state.metrics.firstLabel, y: state.metrics.maxValue},
                                        {x: state.metrics.lastLabel, y: state.metrics.maxValue},
                                    ]}
                                    strokeDasharray={[3,3]}
                                    strokeWidth="0.57px"
                                    color="#62ce9a"
                                />
                                <LabelSeries
                                    data={[{
                                        x: state.metrics.firstLabel,
                                        y: Math.round(state.metrics.maxValue),
                                        label: `$${Math.round(state.metrics.maxValue).toLocaleString('en-US')}`,
                                        yOffset: -20,
                                    }]}
                                />
                                <XAxis style={{text: {fill: '#999999'} }} tickSize={0} />
                                {crosshairValue && (
                                    <Crosshair values={[crosshairValue]}>
                                        <GraphTooltip>
                                            <p>{crosshairValue.x}: {(crosshairValue.y).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</p>
                                        </GraphTooltip>
                                    </Crosshair>
                                )}
                            </XYPlot>
                        </>
                    )}
                </div>
            )}
            {loadingDashboards && (
                <div className="loading">
                    <Spinner size="128" color="#314A68" />
                </div>
            )}
            <div className="topCards">
                {groupedDashboards.length > 0 && groupedDashboards[0].map((dashboard) => (
                    <DashboardCard dashboard={dashboard}
                                   onView={d => onView(d)}
                                   key={`dashboard-${dashboard.id}`}
                    />
                ))}
            </div>
            <div className="dashboardGroupWrapper">
                {groupedDashboards.length > 1 && groupedDashboards.slice(1).map((dashboards: Dashboard[], idx) => (
                    <DashboardGroup dashboards={dashboards}
                                   onView={d => onView(d)}
                                    key={`dashboard-group-${idx}`}
                    />
                ))}
            </div>

            {showSetupCall && (
                <RequestCallComponent forDashboard={true} onClose={() => setShowSetupCall(false)} />)}
        </AppHeader>
        </div>
    )
}
