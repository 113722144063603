import {LineItem} from "./LineItem";
import {currencyFloat, currencyFormat} from "../utils/numbers";

export type TipType = 'percent' | 'rate' | ''
export type TaxNexus = 'pro' | 'transaction' | ''
export type SourceType = 'credit' | 'debit' | 'prepaid' | 'checking' | ''

export class Transaction {
    id?: string;
    owner: string = '';
    vendor: string = '';
    client: string = '';
    employee: string = '';
    creator: string = '';
    date: string = '';
    source: string = '';
    source_type: SourceType = '';
    discount: string = '';
    discount_type: TipType = 'percent'
    gift?: string;
    _gift?: any;
    _discount_percentage: number = 0; // Local variable only
    nexus: TaxNexus = 'pro';
    taxcode: string = '';
    tax: number = 0;
    tax_amount: number = 0;
    lines: LineItem[] = [];
    total: number = 0;
    reminder: string = '';
    allow_surcharge: boolean = false;
    memo: string = '';
    channel: string = '';
    refnum: string = '';
    paymeth: string = '';
    status: string = '';
    tip_type: TipType = 'percent';
    tip: number = 0;
    amount: number = 0;
    rate?: number | string;

    constructor(data: Partial<Transaction>) {
        Object.assign(this, data)
    }

    calculateTotal() {
        if (this.lines.length > 0)
            this.total = this.lines.reduce((sum ,cur) => sum + Number(cur.total), 0)
        return this.total
    }

    calculateChargeAmount() {
        let netAmount = Number(this.total) || 0

        const discountAmount = this.calculateDiscount()
        netAmount -= discountAmount

        const tipAmount = this.calculateTip(netAmount)
        netAmount += tipAmount

        const taxAmount = this.calculateTax()
        netAmount += taxAmount

        const surchargeAmount = this.calculateSurcharge(netAmount)
        netAmount += surchargeAmount

        return currencyFloat(netAmount)
    }

    calculateDiscount() {
        let applicableTotalForDiscount = 0
        this.lines.forEach((lineItem) => {
            if (lineItem.discountable)
                applicableTotalForDiscount += Number(lineItem.total)
        })
        if (!this.lines.length) {
            // the case for record payments, we only initialize the lines to the lines on the record which is often empty
            applicableTotalForDiscount = Number(this.total)
        }

        let discount = 0
        if (Number(this.discount) > 0 && this.discount_type) {
            if (this.discount_type === 'rate')
                discount = Number(this.discount)
            else if (this.discount_type === 'percent')
                discount = (applicableTotalForDiscount * Number(this.discount) / 100)
        }

        if (discount > applicableTotalForDiscount) {
            discount = applicableTotalForDiscount
        }

        return discount
    }

    calculateTax() {
        let applicableTotalForSalesTax = 0
        this.lines.forEach((lineItem) => {
            if (lineItem.taxable) {
                applicableTotalForSalesTax += Number(lineItem.total)
                if (lineItem.discountable) {
                    if (Number(this.discount) > 0 && this.discount_type === 'percent') {
                        const discount = (Number(lineItem.total) * Number(this.discount) / 100)
                        applicableTotalForSalesTax -= discount
                    }
                    else if (Number(this.discount) > 0) {
                        applicableTotalForSalesTax -= Number(this.discount)
                    }
                }
            }
        })
        if (!this.lines.length) {
            // the case for record payments, we only initialize the lines to the lines on the record which is often empty
            applicableTotalForSalesTax = Number(this.total)
        }
        let tax = 0
        if (applicableTotalForSalesTax > Number(this.total)) {
            // this should only happen when we set the total to the deposit amount for deposit payments
            applicableTotalForSalesTax = Number(this.total)
        }
        if (Number(this.tax) > 0) {
            tax = applicableTotalForSalesTax * Number(this.tax) / 100
        }
        return tax
    }

    calculateSurcharge(netAmount?: number) {
        if (netAmount === undefined) {
            // Calculate it on the fly
            netAmount = Number(this.total) || 0

            const discountAmount = this.calculateDiscount()
            netAmount -= discountAmount

            const tipAmount = this.calculateTip(netAmount)
            netAmount += tipAmount

            const taxAmount = this.calculateTax()
            netAmount += taxAmount
        }

        let surcharge = 0
        if (this.allow_surcharge) {
            surcharge = netAmount * .03
        }
        return surcharge
    }

    calculateTip(subtotalAmount?: number) {
        if (subtotalAmount === undefined) {
            // Calculate it on the fly
            subtotalAmount = Number(this.total) || 0

            const discountAmount = this.calculateDiscount()
            subtotalAmount -= discountAmount
        }

        let tip = 0
        if (Number(this.tip) > 0) {
            if (this.tip_type === "rate")
                tip = Number(this.tip)
            else if (this.tip_type === "percent")
                tip = (subtotalAmount * Number(this.tip) / 100)
        }
        return currencyFloat(tip)
    }

    discountDisplay() {
        let displayAmount: string = '', description: string = ''
        let discountAmount = this.calculateDiscount()

        if (this.discount_type === 'rate') {
            displayAmount = currencyFormat(discountAmount)
            description = `Discount (${currencyFormat(discountAmount)})`
        }
        else if (this.discount_type === 'percent' && Number(discountAmount) > 0) {
            displayAmount = `${this.discount}%`
            description = `Discount (${this.discount}%)`
        }
        else {
            discountAmount = 0
        }

        return [displayAmount, description, discountAmount]
    }
}