import React from "react";
import {Button} from "@zendeskgarden/react-buttons";
import { Feature, featureIcon } from "../../../models/Feature";
import { Field, Label, Toggle } from "@zendeskgarden/react-forms";
import { useSelector } from "react-redux";
import { SettingsState } from "../../../modules/settings";
import { RootState } from "../../../store";

type Props = {
    feature: Feature,
    onView: () => void,
    onToggle: (checked: boolean) => void,
}

export const FeatureCard = ({ feature, onView, onToggle }: Props) => {
    const Icon = featureIcon(feature.id)
    const { isSending }: SettingsState = useSelector((state: RootState) => state.settings)

    return (
        <div className="featureCard">
            <Icon />
            <div className="column">
                <div className="name">{feature.name}</div>
                <div className="memo">{feature.memo}</div>
            </div>
            <div className="right">
                {feature.isRecommended && (
                    <div className="green">Recommended</div>
                )}
                {!feature.isRecommended && feature.isLocked && (
                    <div className="green">Upgrade</div>
                )}
                <Button className="view" isLink onClick={() => {onView()}}>Learn more</Button>
                <Field>
                    <Toggle checked={feature.isEnabled}
                            disabled={isSending}
                            onChange={e => onToggle(e.target.checked)}>
                        <Label>&nbsp;</Label>
                    </Toggle>
                </Field>
            </div>
        </div>
    )
}