import React, { useState } from "react";
import { PSButton, PSButtonPrimary } from "./PSButton";
import './card-view.css'
import { Plan } from "../../models/Plan";
import { PSVideoModal } from "./PSVideoModal";
import {ReactComponent as Checkmark} from "../../icons/task.svg"
import { Edition } from "../../models/Edition";
import { currencyFormat } from "../../utils/numbers";

type Props = {
    plan: Plan,
    edition?: Edition,
    industry?: string,
    onPlanSelected: (plan: Plan) => void,
    allowFreeTrial?: boolean
}

export const PSPlanCardView = ({plan, edition, industry, onPlanSelected, allowFreeTrial}: Props) => {
    const planId = plan.plan
    const costPerMonth = planId !== "free" ? plan.price_display : "Free forever"
    const planBanner = plan.banner

    const buttonParts = plan.subscribe_button_title.split('\n')

    let isSmallButton = true
    let buttonText = <h2>{buttonParts && buttonParts[0]}</h2>
    if (buttonParts && buttonParts.length > 1) {
        isSmallButton = false
        buttonText =
            <div style={{margin: "8px"}}>
                <h2>{buttonParts[0]}</h2>
                <span style={{color: plan.subscribe_button_color === "dark"  ? "#ffffff" : "000000", fontWeight: 200}}>{buttonParts[1]}</span>
            </div>
    }

    let benefits = plan.benefits;
    const firstLine = planId !== "free" ? benefits.find(benefit => !benefit.leading_image) : undefined;

    const [showVideoPlayer, setShowVideoPlayer] = useState(false)
    const [videoUri, setVideoUri] = useState<string>()
    const playVideo = (videoUrl: string) => {
        setVideoUri(videoUrl)
        setShowVideoPlayer(true)
    }

    return <>
        <div className="cardView">
            {planBanner && (<div className="topText">{planBanner}</div>)}
            <div className="cardWrapper">
                <div className="headerText">
                    <h1>{plan.title}</h1>
                    {allowFreeTrial && planId !== "free" ?
                        <div className="costPerMonth">Free 30 days</div> :
                        costPerMonth && plan.price_show_as_discounted ? <div className="costPerMonth showAsDiscounted">{costPerMonth}</div> :
                        costPerMonth && <div className="costPerMonth">{costPerMonth}</div>}
                </div>

                <div className="flex">
                    {plan.premium_sales_image_uri && <div className="img" style={{backgroundImage: `url('${plan.premium_sales_image_uri}')`}} />}
                    <div className="content">
                        {plan.subtitle && <h3>{plan.subtitle}</h3>}
                        {firstLine && <span className="everythingAndText">{firstLine.message}</span>}
                        <ul className="checkmark">
                            {benefits.filter(benefit => firstLine?.message !== benefit.message).map((benefit, index) =>
                                <li key={`benefit-${planId}-${index}`}><Checkmark />{benefit.message}</li>
                            )}
                        </ul>
                        {plan.premium_sales_video_uri && <PSButton onClick={() => playVideo(edition?.video_uri ?? plan.premium_sales_video_uri!)}>Watch Video</PSButton>}
                    </div>
                </div>

                {plan.subscribe_button_color === "dark" ?
                    <PSButtonPrimary className={`planButton ${isSmallButton ? 'small': ''}`} onClick={() => onPlanSelected(plan)}>{buttonText}</PSButtonPrimary> :
                    <PSButton className={`planButton ${isSmallButton ? 'small': ''}`} onClick={() => onPlanSelected(plan)}>{buttonText}</PSButton>
                }

                {allowFreeTrial && planId !== "free" && <div className='laterPrice'>{`$${plan.price} per month after free trial`}</div>}
            </div>
        </div>
        {showVideoPlayer &&
            <PSVideoModal title='Video'
                            hlsVideoURL={videoUri}
                            isLoading={false}
                            onClose={() => setShowVideoPlayer(false)} />
        }
    </>
}