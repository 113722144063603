import { Checkbox, Field, Label } from "@zendeskgarden/react-forms";
import React from "react";

type FieldProps = {
    fieldValue: boolean,
    label: string,
    onChange: (changed: boolean) => void
}

export const CheckboxInput = ({fieldValue, label, onChange}: FieldProps) => {
    return (
        <Field className='field'>
            <Checkbox checked={fieldValue} onChange={(e) => {
                onChange(!fieldValue)
            }}>
                <Label>{label}</Label>
            </Checkbox>
        </Field>
    )
}