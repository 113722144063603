export const triggerStatus: {[k: string]: string} = {
    'flash': 'Make announcement',
    'phone': 'Respond to missed call',
    'message': 'Respond to missed text',
    'welcome': 'Welcome new client',
    'anniversary': 'Wish happy anniversary',
    'churn': 'Reach out to past client',
    'activate': 'Convert to paying client',
    'overdue': 'Collect overdue payment',
    'expire': 'Renew expired package',
    'expiring': 'Renew expiring package',
    'exhaust': 'Renew exhausted package',
    'purchase': 'Purchase follow-up',
    'complete': 'Send after appointment',
    'confirm': 'Send prep for appointment',
    'form': 'Completed form follow-up',
    'contract': 'Signed contract follow-up',
}
