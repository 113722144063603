import { Dropdown, Item, Menu, Trigger } from "@zendeskgarden/react-dropdowns"
import { Col, Row } from "@zendeskgarden/react-grid"
import { Tooltip } from "@zendeskgarden/react-tooltips"
import React from "react"
import { PSButton, PSButtonPrimary } from "../app/PSButton"

export type ButtonProps = {
    text: string,
    tooltip?: string,
    class?: string,
    onClick: () => void
}

type Props = {
    primaryButton?: ButtonProps,
    buttons: ButtonProps[]
}

export const RecordButtons = ({primaryButton, buttons}: Props) => {
    // 5 buttons last one drop down if more than five, don't show 1 in the dropdown
    const topButtonCount = buttons.length > 5 ? 4 : buttons.length === 5 ? 5 : buttons.length
    const topGroup = buttons.slice(0, topButtonCount)
    const dropDown = buttons.length !== topButtonCount ? buttons.slice(topButtonCount, buttons.length) : undefined

    if (!topGroup.length) {
      return <></>
    }

    return <>
        {primaryButton && (
            <PSButtonPrimary onClick={primaryButton.onClick}>
                {primaryButton.text}
            </PSButtonPrimary>
        )}
        {topGroup.map((button) => {
            return button.tooltip ?
                <Tooltip key={`user-action-tt-${button.text}`} content={button.tooltip}>
                    <PSButton className={`cta ${button.class}`} onClick={() => { button.onClick()} }>
                        {button.text}
                    </PSButton>
                </Tooltip> :
                <PSButton key={`user-action-${button.text}`}
                          className={`cta ${button.class}`}
                          onClick={() => { button.onClick()} }>
                    {button.text}
                </PSButton>
        })}
        {dropDown && (
            <Row>
                <Col textAlign="center">
                    <Dropdown
                    onSelect={ix => dropDown[ix].onClick() }
                    >
                        <Trigger>
                            <PSButton className='cta more'>More</PSButton>
                        </Trigger>
                        <Menu>
                            {dropDown.map((buttonProps, ix) =>
                                <Item key={`extra-buttons-${buttonProps.text}`} value={ix}>{buttonProps.tooltip ?? buttonProps.text}</Item>
                            )}
                        </Menu>
                    </Dropdown>
                </Col>
            </Row>
        )}
    </>
}