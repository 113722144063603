import React, {useEffect, useState} from "react";
import {useAppDispatch, usePSUser} from "../../hooks";
import {AppHeader} from "../app/AppHeader";
import {Spinner} from "@zendeskgarden/react-loaders";
import {PSButtonPrimary} from "../app/PSButton";
import {PSDropdown} from "../app/PSDropdown";
import {
    findSelection,
    gratuityOptions,
    SelectOption,
    gratuityPercentOptions
} from "../settings/Options";
import {Item} from "@zendeskgarden/react-dropdowns";
import {Result} from "../../utils";
import {saveUser} from "../../modules/settings";
import {UserInstance} from "../../models/User";
import {useHistory} from "react-router";

export const OnboardingSetupPaymentsContinuedGratuity = () => {
    const findRequestGratuitySelection = (user: UserInstance) => findSelection(gratuityOptions, user.request_gratuity)
    const findSuggestedGratuitySelection = (user: UserInstance) => findSelection(gratuityPercentOptions, user.gratuity)

    const dispatch = useAppDispatch()
    const user = usePSUser()
    const history = useHistory()

    const [isSending, setIsSending] = useState(false)
    const [requestGratuity, setRequestGratuity] = useState<SelectOption>()
    const [suggestedGratuity, setSuggestedGratuity] = useState<SelectOption>()

    useEffect(() => {
        if (!user) return
        setRequestGratuity(findRequestGratuitySelection(user))
        setSuggestedGratuity(findSuggestedGratuitySelection(user))
    }, [user])

    const onNextHandler = () => {
        if (!user) return

        setIsSending(true)

        const userParams: any = {}
        userParams.id = user.id
        userParams.request_gratuity = requestGratuity?.value ?? null
        userParams.gratuity = suggestedGratuity?.value ?? null

        Result(dispatch(saveUser(userParams)))
            .then(() => {
                history.goBack()
            })
            .finally(() => setIsSending(false))
    }

    return (
        <AppHeader title="Setup" showBackButton middleWidget={null}>
            {isSending && (
                <div className="loadingOverlay">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                        <h1>Saving...</h1>
                    </div>
                </div>

            )}
            <div className="scheduleClient servicesSettings onboarding onboardingSetupPayments">
                <div className="header">
                    <h1>Gratuity</h1>
                    <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                     onClick={() => onNextHandler()}
                    >
                        Save
                    </PSButtonPrimary>
                </div>

                <div className="separator" />

                <div className="form">
                    <PSDropdown selected={requestGratuity}
                                nameProperty="label"
                                disabled={isSending}
                                onSelect={(selection) => setRequestGratuity(selection)}
                                label="Request gratuity"
                                placeholder={<div className="grey">Select option</div>}>
                        <>
                            {gratuityOptions.map(option => (
                                <Item key={`gratuity-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>

                    <PSDropdown selected={suggestedGratuity}
                                nameProperty="label"
                                disabled={isSending}
                                onSelect={(selection) => setSuggestedGratuity(selection)}
                                label="Suggested gratuity"
                                placeholder={<div className="grey">Select option</div>}>
                        <>
                            {gratuityPercentOptions.map(option => (
                                <Item key={`suggested-gratuity-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                </div>
            </div>
        </AppHeader>
    )
}

