import React, {useEffect, useState} from "react";
import {useAppDispatch, usePSOwner} from "../../../hooks";
import {
    clearItem, deleteItem,
    fetchItem,
    saveItem,
    SettingsState
} from "../../../modules/settings";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {PSButtonDanger, PSButtonPrimary} from "../../app/PSButton";
import {Dots} from "@zendeskgarden/react-loaders";
import {
    Checkbox,
    Field, Hint,
    Input,
    Label,
} from "@zendeskgarden/react-forms";
import {
    currencyFloat,
    filterMonetaryInput,
    filterNumericInput
} from "../../../utils/numbers";
import {usePhotoUpload} from "../hooks";
import { Item } from "../../../models/Item";
import {Prompt, useHistory, useParams} from "react-router";
import {SettingsPhotoField} from "../SettingsPhotoField";
import {GuardrailModal} from "../GuardrailModal";
import {PSTextarea} from "../../app/PSTextarea";
import {updateItem} from "../../../modules/onboarding";
import {PSLearnMore} from "../../app/PSLearnMore";
import {Result} from "../../../utils";

type Props = {
    forOnboarding?: boolean,
    refreshData?: boolean,
    redirect?: Function
}

export const ProductEdit = ({forOnboarding, refreshData, redirect}: Props) => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const history = useHistory()

    const [photo, setPhoto] = useState<undefined | string | File>('')
    const [image, setImage] = useState<undefined | string>('') // For editing
    const [name, setName] = useState('')
    const [price, setPrice] = useState('')
    const [description, setDescription] = useState('')
    const [online, setOnline] = useState(false)
    const [trackInventory, setTrackInventory] = useState(true)
    const [quantity, setQuantity] = useState<string>('')
    const [cost, setCost] = useState<string>('')
    const [saveEnabled, setSaveEnabled] = useState(false)
    const [formSending, setFormSending] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)

    const [addPhotoRef, selectPhoto, selectedPhoto] = usePhotoUpload({disabled: state.isSending, setPhoto})
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    const [learnMoreComponent, showLearnMore] = PSLearnMore([
        { key: 'cost', title: 'About total cost', body: 'Total cost is the total amount of money you paid to buy the specified quantity of this product. Total cost = Quantity x Unit cost' }
    ]);

    const [onlineBookingEnabled, setOnlineBookingEnabled] = useState(false)
    const owner = usePSOwner()
    useEffect(() => {
        setOnlineBookingEnabled(owner?.allow_widget ?? false)
    }, [owner])

    useEffect(() => {
        setSaveEnabled(!!name && !!price && ((parseFloat(quantity) >= 1 && parseFloat(cost) >= 0) || (quantity === '' && cost === '')))
    }, [name, price, quantity, cost])

    // Load the service if this is an edit, otherwise treat it as a create
    const params: any = useParams()
    useEffect(() => {
        if (forOnboarding) return

        if (!params.productID) {
            dispatch(clearItem())
            return
        }
        dispatch(fetchItem('product', params.productID))
    }, [dispatch, params.productID, forOnboarding])

    useEffect(() => {
        if (forOnboarding) {
            setHasEdits(false)
            return
        }

        let changed = 0
        if ((state.item.name || name) && name !== state.item.name)
            changed = 1
        else if ((state.item.rate || Number(price)) && Number(price) !== Number(state.item.rate))
            changed = 2
        else if ((state.item.memo || description) && description !== state.item.memo)
            changed = 3
        else if ((state.item.quantity || quantity) && quantity !== state.item.quantity)
            changed = 4
        else if (params.productID && (state.item.is_public || online) && online !== state.item.is_public)
            changed = 11
        else if (params.productID && (state.item.allow_inventory || trackInventory) && trackInventory !== state.item.allow_inventory)
            changed = 12
        else if (typeof photo === 'object')
            changed = 13
        setHasEdits(changed > 0)
    }, [
        state.item, params.productID, name, price, description, quantity, online, trackInventory,
        photo, forOnboarding,
    ])

    // Update form's values after the product is loaded into state
    useEffect(() => {
        if (params.action !== 'copy')
            setName(state.item.name || '')
        setPhoto(state.item.image_uri || '')
        setImage(state.item.image || '')
        setPrice(state.item.rate ? currencyFloat(state.item.rate).toFixed(2) : '')
        setDescription(state.item.memo || '')
        setOnline(state.item.is_public)
        setTrackInventory(state.item.allow_inventory ?? true)
        setQuantity(state.item?.quantity || '')
    }, [state.item, params.action])

    const onSave = () => {
        if (formSending) return

        if (!name || !price) return

        const product = new Item('product')
        if (forOnboarding)
            product.setData(state.item)
        product.name = name
        product.rate = price
        product.memo = description
        product.is_public = online
        product.allow_inventory = trackInventory
        product.quantity = quantity
        product.cost = cost
        product.image = image
        if (params.productID && params.action !== 'copy')
            product.id = params.productID

        if (forOnboarding) {
            product.id = state.item.id
            dispatch(updateItem(product.toObject(), !!refreshData))
            history.go(-2)
            return
        }

        setFormSending(true)
        if (!!redirect) {
            if (typeof photo === 'string') {
                Result(dispatch(saveItem(product, undefined, true)))
                    .then(redirect())
            }
            else {
                Result(dispatch(saveItem(product, photo as File, true)))
                    .then(redirect())
            }
        }
        else {
            if (typeof photo === 'string') {
                dispatch(saveItem(product))
            }
            else {
                dispatch(saveItem(product, photo as File))
            }
        }
    }

    const showDeleteButton = params.productID && params.action !== 'copy'

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Product</h1>
                {showDeleteButton && (
                    <PSButtonDanger style={{height: '40px'}}
                                    onClick={() => setShowDeleteConfirmation(true)}>
                        Delete product
                    </PSButtonDanger>
                )}
                <PSButtonPrimary style={{height: '40px', marginLeft: showDeleteButton ? '16px' : 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!saveEnabled || formSending}>
                    {formSending && <Dots />}
                    {!formSending && "Save"}
                </PSButtonPrimary>
            </div>

            <div className="form">
                {!forOnboarding && (
                    <SettingsPhotoField photo={photo}
                                        selectPhoto={selectPhoto}
                                        selectedPhoto={selectedPhoto}
                                        addPhotoRef={addPhotoRef}
                                        title="Service" />
                )}

                <Field className="field">
                    <Label className="label">Name (required)</Label>
                    <Input placeholder="Product name"
                           value={name}
                           disabled={state.isSending}
                           onChange={e => setName(e.target.value)} />
                </Field>

                <Field className="field">
                    <Label className="label">Price · $ (required)</Label>
                    <Input placeholder="Product price"
                           value={price}
                           disabled={state.isSending}
                           onChange={e => filterMonetaryInput(e.target.value, setPrice)} />
                </Field>

                <Field className="field">
                    <Label className="label">Description</Label>
                    <PSTextarea placeholder="Product description"
                                className="input"
                                minRows={8}
                                maxLength={1500}
                                disabled={state.isSending}
                                value={description}
                                onChange={value => setDescription(value)}/>
                </Field>

                <h2>Details</h2>

                {onlineBookingEnabled && (
                    <Field className="field">
                        <Checkbox checked={online}
                                  disabled={state.isSending}
                                  onChange={(e) => { setOnline(e.target.checked)}}>
                            <Label className="withHint">Show online</Label>
                            <Hint>Product will appear to clients after booking you online</Hint>
                        </Checkbox>
                    </Field>
                )}

                <Field className="field">
                    <Checkbox checked={trackInventory}
                              disabled={state.isSending}
                              onChange={(e) => { setTrackInventory(e.target.checked)}}>
                        <Label className="withHint">Track inventory</Label>
                        <Hint>Products sold will automatically deduct from your inventory</Hint>
                    </Checkbox>
                </Field>

                {!params.productID && (
                    <Field className="field">
                        <Label className="label">Quantity</Label>
                        <Hint className="hint">Add the total number of products you have available</Hint>
                        <Input placeholder="Quantity"
                               value={quantity}
                               disabled={state.isSending}
                               onChange={e => filterNumericInput(e.target.value, setQuantity)} />
                    </Field>
                )}

                {!params.productID && (
                    <Field className="field">
                        <Label className="label">Total cost</Label>
                        <Input placeholder="Total cost"
                               value={cost}
                               disabled={state.isSending}
                               onChange={e => filterMonetaryInput(e.target.value, setCost)} />
                    </Field>
                )}

                {!params.productID && (
                    <div className="buttonLink brightBlue"
                         style={{display: 'inline-block', paddingTop: 8}}
                         onClick={() => showLearnMore('cost')}>
                        Learn more &gt;
                    </div>
                )}
            </div>
            <Prompt
                when={hasEdits && !formSending}
                message="Are you sure you'd like to leave this page without saving your changes?" />
            {showDeleteConfirmation && (
                <GuardrailModal title="Are you sure?"
                                body="Are you sure you'd like to delete this item?"
                                buttonText="Delete"
                                onClose={() => setShowDeleteConfirmation(false)}
                                onAction={() => dispatch(deleteItem(params.productID, 'products'))}
                />
            )}
            {learnMoreComponent}
        </div>
    )
}
