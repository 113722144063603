import React from "react";
import { Button } from "@zendeskgarden/react-buttons";
import { featureName, featureIcon, Feature } from "../../../models/Feature";


type Props = {
    feature: Feature,
    onView: () => void,
}

export const TutorialCard = ({feature, onView}: Props) => {
    const Icon = featureIcon(feature.id)

    return (
        <div className="tutorialCard">
            <Icon />
            <div className="name">
                {featureName(feature)}
            </div>
            <div className="view">
                <Button
                    isLink
                    onClick={() => {onView()}}
                >
                    Play tutorial video
                </Button>
            </div>
        </div>
    )
}