import React, {createRef, useEffect, useState} from "react";
import {useAppDispatch} from "../../../hooks";
import {
    clearContract, deleteContract,
    fetchContract, saveContract,
    SettingsState
} from "../../../modules/settings";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {PSButton, PSButtonDanger, PSButtonPrimary} from "../../app/PSButton";
import {Dots, Spinner} from "@zendeskgarden/react-loaders";
import {Checkbox, Field, Hint, Input, Label} from "@zendeskgarden/react-forms";
import {PSTextarea} from "../../app/PSTextarea";
import {GuardrailModal} from "../GuardrailModal";
import {Prompt, useHistory, useParams} from "react-router";
import {Contract} from "../../../models/Contract";
import {SignatureModal} from "../SignatureModal";
import {API_BASE, apiClient} from "../../../modules/apiclient";
import {
    Body, Close,
    Footer,
    FooterItem,
    Header,
    Modal
} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import {b64EncodeUnicode} from "../../../utils/strings";
import {updateContract} from "../../../modules/onboarding";

type Props = {
    forOnboarding?: boolean,
}

export const ContractEdit = ({forOnboarding}: Props) => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)
    const history = useHistory()

    const [name, setName] = useState('')
    const [required, setRequired] = useState(false)
    const [body, setBody] = useState('')
    const [signatureData, setSignatureData] = useState('')
    const [signatureID, setSignatureID] = useState('')
    const [signatureURI, setSignatureURI] = useState('')
    const [saveEnabled, setSaveEnabled] = useState(false)
    const [formSending, setFormSending] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)

    const [isPreviewLoading, setIsPreviewLoading] = useState(true)
    const [pdfPreviewData, setPDFPreviewData] = useState('')

    const [showSignatureModal, setShowSignatureModal] = useState(false)
    const [showPreviewModal, setShowPreviewModal] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    const formPreviewRef = createRef<HTMLFormElement>()

    useEffect(() => {
        setSaveEnabled(!!name && !!body)
    }, [name, body])

    // Load the contract if this is an edit, otherwise treat it as a create
    const params: any = useParams()
    useEffect(() => {
        if (forOnboarding) return

        if (!params.contractID) {
            dispatch(clearContract())
            return
        }
        dispatch(fetchContract(params.contractID))
    }, [dispatch, params.contractID, forOnboarding])

    useEffect(() => {
        if (forOnboarding) {
            setHasEdits(false)
            return
        }

        let changed = 0
        if (params.action !== 'copy' && (state.contract.name || name) && name !== state.contract.name)
            changed = 1
        else if ((state.contract.memo || body) && body !== state.contract.memo)
            changed = 2
        setHasEdits(changed > 0)
    }, [
        params.action, state.contract, name, required, body, forOnboarding,
    ])

    useEffect(() => {
        if (params.action !== 'copy')
            setName(state.contract.name || '')
        setBody(state.contract.memo || '')
        setRequired(state.contract.always_require)
        setSignatureID(state.contract.signature)
        setSignatureURI(state.contract.signature_abs_uri)
        if (forOnboarding)
            setSignatureData(state.contract.signature_data)
    }, [params.action, state.contract, forOnboarding])

    const onSave = () => {
        if (formSending) return

        if (!name || !body) return

        const contract = new Contract()
        if (forOnboarding)
            contract.setData(state.contract)
        contract.name = name
        contract.memo = body
        contract.always_require = required
        if (signatureData)
            contract.signature_data = signatureData
        if (signatureID)
            contract.signature = signatureID

        if (params.contractID && params.action !== 'copy')
            contract.id = params.contractID

        if (forOnboarding) {
            contract.id = state.contract.id
            dispatch(updateContract(contract.toObject(), false))
            history.goBack()
            return
        }

        setFormSending(true)

        dispatch(saveContract(contract))
    }

    const showPreview = () => {
        if (formPreviewRef.current)
            formPreviewRef.current.submit()
    }

    const getPreview = () => {
        setShowPreviewModal(true)
        setIsPreviewLoading(true)

        const data = {
            name: name,
            memo: body,
            signature_data: signatureData,
        }

        apiClient.post(`/contract/${params.contractID}/print`, data)
            .then(resp => {
                setPDFPreviewData(resp.data)
                setIsPreviewLoading(false)
            })
    }

    const showDeleteButton = params.contractID && params.action !== 'copy'

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Contract</h1>
                {showDeleteButton && (
                    <PSButtonDanger style={{height: '40px'}}
                                    onClick={() => setShowDeleteConfirmation(true)}>
                        Delete contract
                    </PSButtonDanger>
                )}
                {showDeleteButton && (
                    <PSButton style={{height: '40px', marginLeft: '16px'}}
                                    onClick={() => getPreview()}>
                        Preview contract
                    </PSButton>
                )}
                <PSButtonPrimary style={{height: '40px', marginLeft: showDeleteButton ? '16px' : 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!saveEnabled || formSending}>
                    {formSending && <Dots />}
                    {!formSending && "Save"}
                </PSButtonPrimary>
            </div>

            <div className="form">
                <Field className="field">
                    <Label className="label">Name (required)</Label>
                    <Input placeholder="Contract name"
                           value={name}
                           disabled={state.isSending}
                           onChange={e => setName(e.target.value)} />
                </Field>

                <Field className="field">
                    <Checkbox checked={required}
                              disabled={state.isSending}
                              onChange={e => setRequired(e.target.checked)}>
                        <Label className="withHint">Always require</Label>
                        <Hint>Always require the contract to be signed (even if previously signed by a client)</Hint>
                    </Checkbox>
                </Field>

                <Field className="field">
                    <Label className="label">Signature</Label>
                    {signatureData && (
                        <div style={{paddingTop: 8}}>
                            <img style={{border: '1px solid #e6e6e9', borderRadius: 4}}
                                 width="96" height="86"
                                 alt="Signature"
                                 src={`data:image/png;base64,${signatureData}`} />
                        </div>
                    )}
                    {!signatureData && signatureURI && (
                        <div style={{paddingTop: 8}}>
                            <img style={{border: '1px solid #e6e6e9', borderRadius: 4}}
                                 width="140" height="100"
                                 alt="Signature"
                                 src={signatureURI} />
                        </div>
                    )}
                    {!signatureData && !signatureID && (
                        <Hint className="hint">Add your signature and it will appear on the contracts you send</Hint>
                    )}
                    {(signatureData || signatureID) && (
                        <PSButton style={{height: '40px', marginTop: 12}}
                                         onClick={() => setShowSignatureModal(true)}>
                            Edit signature
                        </PSButton>
                    )}
                    {!signatureData && !signatureID && (
                        <PSButtonPrimary style={{height: '40px', marginTop: 12}}
                                         onClick={() => setShowSignatureModal(true)}>
                            Add signature
                        </PSButtonPrimary>
                    )}
                </Field>

                <Field className="field">
                    <Label className="label">Contract body (required)</Label>
                    <Hint className="hint">Displayed for clients to view when signing this contract</Hint>
                    <PSTextarea placeholder="Start creating your contract from scratch or copy & paste an existing contract. If you have a contact that you’re using and you’d like our team to import send the PDF or Word file to support@pocketsuite.io."
                                className="input"
                                minRows={8}
                                disabled={state.isSending}
                                value={body}
                                maxLength={60000}
                                onChange={value => setBody(value)}/>
                </Field>
            </div>
            {showPreviewModal && (
                <Modal className="signatureModal" onClose={() => setShowPreviewModal(false)}>
                    <Header>{state.contract.name} - Preview </Header>
                    <Body>
                        {isPreviewLoading && (
                            <div style={{width: '100%', height: '420px'}}>
                                <div style={{textAlign: 'center', padding: '128px 0'}}>
                                    <Spinner size="128" color="#314A68" />
                                </div>
                            </div>
                        )}
                        {!isPreviewLoading && pdfPreviewData && (
                            <iframe style={{width: '100%', height: '420px'}}
                                    src={`data:application/pdf;base64,${b64EncodeUnicode(pdfPreviewData)}`}
                                    title={'Preview'}>
                            </iframe>
                        )}
                    </Body>
                    <Footer style={{paddingBottom: 32}}>
                        <FooterItem>
                            <form method="POST"
                                  ref={formPreviewRef}
                                  action={`${API_BASE}/contract/${params.contractID}/print`}
                                  target="pspreview">
                                <input type="hidden" name="name" value={name} />
                                <input type="hidden" name="memo" value={body} />
                                <input type="hidden" name="signature_data" value={signatureData} />
                            </form>
                            <Button onClick={() => showPreview()}
                                    isPrimary>
                                Open in browser
                            </Button>
                        </FooterItem>
                    </Footer>
                    <Close aria-label="Close modal" />
                </Modal>
            )}
            {showSignatureModal && (
                <SignatureModal onClose={() => setShowSignatureModal(false)}
                                onDone={(data) => {
                                    setSignatureData(data)
                                    setShowSignatureModal(false)
                                }}
                />
            )}
            {showDeleteConfirmation && (
                <GuardrailModal title="Are you sure?"
                                body="Are you sure you'd like to delete this contract?"
                                buttonText="Delete"
                                onClose={() => setShowDeleteConfirmation(false)}
                                onAction={() => dispatch(deleteContract(params.contractID))}
                />
            )}
            <Prompt
                when={hasEdits && !formSending}
                message="Are you sure you'd like to leave this page without saving your changes?" />
        </div>
    )
}