import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useAppDispatch, usePSUser} from "../../../hooks";
import {ReactComponent as DollarIcon} from "../../../icons/ps_general_money_nobg.svg";
import {ReactComponent as ClientIcon} from "../../../icons/ps_general_person_nobg.svg";
import {useHistory, useLocation, useParams} from "react-router";
import {ReactComponent as TotalIcon} from "../../../icons/ps_general_invoice_nobg.svg";
import {currencyFormat} from "../../../utils/numbers";
import {Spinner} from "@zendeskgarden/react-loaders";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { Result } from "../../../utils";
import '../../settings/settings.css'
import { getRecord } from "../../../modules/records";
import { AppHeader } from "../../app/AppHeader";
import { syncClient } from "../../../modules/contacts";
import { bookingToPayment, giftToPayment, invoiceToPayment, packageToPayment, estimateToPayment, Payment } from "../../../models/Payment";
import { PSFormFieldSelection } from "../../app/PSFormFieldSelection";
import { GratuityModal } from "../../transactions/charge/GratuityModal";
import { TipType } from "../../../models/Transaction";
import {RecordTotalModal} from "./RecordTotalModal"
import { UserInstance } from "../../../models/User";
import { getUser } from "../../../modules/settings";
import { replace } from "connected-react-router";
import { useChargeButton } from "../../../hooks/ChargeButton";

export const RecordCheckout = () => {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const location: any = useLocation().state
    const POS_LIMIT = 25000;
    const state = useSelector((state: RootState) => (
        {
            settings: state.settings,
            records: state.records
        }
    ))
    const params = useParams<{recordID: string, recordType: string, subType: string}>()

    const [loading, setLoading] = useState(false)
    const [record, setRecord] = useState(location?.record)
    const owedAmount = useCallback(() =>
        record?.paid_amount ? record?.amount - record?.paid_amount : record?.amount
    , [record?.paid_amount, record?.amount])

    const [totalWithDiscount, setTotalWithDiscount] = useState<number>()
    const [paymentData, setPaymentData] = useState<Payment>(new Payment(location?.payment))
    const [chargeButton, setChargeEnabled] = useChargeButton(paymentData, record, params.recordType)

    const user = usePSUser()
    const [vendor, setVendor] = useState(record?.vendor === user?.id ? user : undefined)
    useEffect(() => {
        if (user && record?.vendor) {
            if (record.vendor !== user.id) {
                Result(dispatch(getUser(record.owner)))
                    .then((response: UserInstance) => setVendor(response))
            }
            else {
                setVendor(user)
            }
        }
    }, [record, user, dispatch])

    const recordToPayment = useCallback((r: any) => {
        const payment = {
            'booking': bookingToPayment(r, params.subType),
            'estimate': estimateToPayment(r, params.subType),
            'invoice': invoiceToPayment(r),
            'gift': giftToPayment(r),
            'package': packageToPayment(r)
        }[params.recordType]

        if (payment && vendor && !vendor.allow_salestax) {
            payment.tax = 0;
            payment.taxcode = ''
            payment.nexus = ''
        }
        if (['package', 'invoice'].includes(params.recordType) && !!r?.paid_amount && payment) {
            payment.discount = ''; // if we set the discount on the payment here it results in UI issues with partial payments, this logic should live in the models
            payment.discount_type = '';
        }

        return payment
    }, [vendor?.allow_salestax, params.recordType, params.subType])

    const [max, setMax] = useState(POS_LIMIT)
    const [min, setMin] = useState(0)
    const [discount, setDiscount] = useState(0) // the discount before the total is updated, used for packages and invoices on partial payment plans
    const [canEditTotal, setCanEditTotal] = useState(false)

    const calcDiscount = useCallback((record: Payment) => {
        // TODO HACKALERT: these model specific rules should live in the models similar to mobile
        // we need to return the original discount because if discount is type percent & partial payment, this could alter the amount discounted (causing UI bugs)
        return ["package", "invoice"].includes(params.recordType) ? discount : record.calculateDiscount()
    }, [discount, params.recordType]);

    useEffect(() => {
        if (!record?.id) return

        const payment = recordToPayment(record)
        if (payment) {
            setDiscount(payment.calculateDiscount())
            const total = {
                'invoice': owedAmount(),
                'gift': owedAmount(),
                'package': owedAmount(),
                'booking': params.subType === "complete" ? payment.calculateTotal() - payment.calculateDiscount() - Number(record.deposit) : Number(record.deposit),
                'estimate': params.subType === "complete" ? payment.calculateTotal() - payment.calculateDiscount() - Number(record.deposit) : Number(record.deposit)
            }[params.recordType]
    
            setTotalWithDiscount(total)
            if (totalWithDiscount)
                payment.total = totalWithDiscount
            
            setPaymentData((currentVal) => {
                // calculate what discount was to add back to total (prevent double discounting)
                const paymentTotal = total + calcDiscount(currentVal)
                return new Payment({
                    ...payment,
                    total: paymentTotal
                })
            })
        }

        if (params.recordType === "booking") {
            setCanEditTotal(payment?.type === "complete")
        }
        else if (["invoice", "package"].includes(params.recordType) && Number(record.max_payment_count || 0) > 1) {
            let min = record.amount
            if (record.max_payment_count && payment) {
                min = Number(record.paid_amount) > 0 && record.max_payment_count === 2 ?
                    owedAmount() :
                    Math.min(payment.total, record.amount) * Number(record?.min_payment_percent) / 100
            }

            setMin(min)
            if (record.paid_amount) setMax(owedAmount())
            else if (payment) setMax(payment.total)
            else setMax(record.amount)

            setCanEditTotal(!record.paid_amount || min !== owedAmount())
        }
        else {
            setCanEditTotal(false)
        }
    }, [calcDiscount, owedAmount, params.recordType, params.subType, record, recordToPayment])

    const [contact, setContact] = useState(location?.contact)
    useEffect(() => {
        if (!record?.client) return

        Result(dispatch(syncClient(record.client)))
            .then((result) => {
                setContact(result.contact)
            })
    }, [record?.client, dispatch])

    const onBack = useCallback(() => {
        if (location?.summary && 'invoice' === params.recordType) {
            dispatch(replace('/invoice/confirm', { invoice: record, client: contact}))
        }
        else if (location?.summary && 'package' === params.recordType) {
            dispatch(replace(`/package/${record.id}/preview`, { package: record, client: contact}))
        }
        else if (location?.summary && 'gift' === params.recordType) {
            dispatch(replace(`/gift/${record.id}/preview`, { gift: record, client: contact}))
        }
        else {
            history.goBack();
        }
    }, [contact, dispatch, history, location?.summary, params.recordType, record])

    useEffect(() => {
        dispatch(getRecord(params.recordType, params.recordID))
    }, [params.recordType, params.recordID, dispatch])

    useEffect(() => setRecord(state.records.data), [state.records.data])

    useEffect(()=> {
        setLoading(!contact || !record)
    }, [contact, record])

    const [gratuityAmount, setGratuityAmount] = useState<number>(0)
    const [gratuityType, setGratuityType] = useState<TipType>('percent')
    const [showGratuityModal, setShowGratuityModal] = useState(false)
    const [showTotalModal, setShowTotalModal] = useState(false)

    useEffect(() => {
        setPaymentData((data) => {
            return new Payment({
                ...data,
                tip_type: gratuityType,
                tip: gratuityAmount
            })
        })
    }, [gratuityAmount, gratuityType])

    const title = {
        'invoice': 'Invoice',
        'booking': 'Appointment',
        'package': 'Package',
        'gift': 'Gift',
        'estimate': 'Estimate'
    }[params.recordType]

    useEffect(() => {
        if (!canEditTotal) setChargeEnabled(true)
        else setChargeEnabled(!!(totalWithDiscount !== undefined && totalWithDiscount >= min && totalWithDiscount <= max))
    }, [canEditTotal, min, max, totalWithDiscount, setChargeEnabled])

    const chargeAmount = useMemo<Number>(() => {
        const paymentCopy = new Payment(paymentData);
        if (params.subType === "confirm") {
            paymentCopy.tip = 0
        }
        return (paymentCopy?.calculateChargeAmount() ?? 0)
    }, [params.subType, paymentData])

    return (<>
            {(loading) &&
                <div className="loadingOverlay">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                        <h1>{'Loading data...'}</h1>
                    </div>
                </div>
            }
            {!loading &&
                <AppHeader title={title} showBackButton middleWidget={null} onBack={onBack}>
                    {
                        <div className="servicesSettings" style={{width: '680px'}}>
                            <div className="header">
                                <h1>Charge</h1>
                                <div style={{marginLeft: "auto"}}>
                                    {chargeButton}
                                </div>
                            </div>
                            <div className="scheduleClientReview">
                                <div className="details">
                                    <div className="receipt">
                                        <div className="total">{currencyFormat(chargeAmount)} Total</div>
                                        <div className="lineItem final">
                                            <div className="name">Subtotal</div>
                                            <div className="amount">{currencyFormat(paymentData?.total ?? 0)}</div>
                                        </div>
                                        {Number(paymentData?.discount) > 0 && (
                                            <div className="lineItem">
                                                <div className="name">Discount</div>
                                                <div className="amount">-{currencyFormat(paymentData?.calculateDiscount())}</div>
                                            </div>
                                        )}
                                        {Number(paymentData?.calculateTax()) > 0 && (
                                            <div className="lineItem">
                                                <div className="name">Sales tax ({Number(paymentData?.tax ?? 0)}%)</div>
                                                <div className="amount">{currencyFormat(paymentData?.calculateTax())}</div>
                                            </div>
                                        )}
                                        {record?.allow_surcharge && (
                                            <div className="lineItem">
                                                <div className="name">Surcharge (3%)</div>
                                                <div className="amount">{currencyFormat(paymentData?.calculateSurcharge())}</div>
                                            </div>
                                        )}
                                        {Number(paymentData?.tip) > 0 && (
                                            <div className="lineItem">
                                                <div className="name">Gratuity</div>
                                                <div className="amount">{currencyFormat(paymentData?.calculateTip())}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="separator" />
                            <div className="form">
                                <div>
                                    <PSFormFieldSelection label="Client (required)"
                                          helperLabel="Select a client"
                                          icon={<ClientIcon />}
                                          selection={contact} hideButton
                                          onClick={() => { }}
                                    />
                                    <PSFormFieldSelection label="Total"
                                          linkText={'Edit'}
                                          icon={<TotalIcon />} hideButton={!canEditTotal}
                                          selectedLabel={<div className='selected'>{currencyFormat(totalWithDiscount)}</div>}
                                          onClick={() => setShowTotalModal(true)}
                                    />

                                    {vendor?.allow_gratuity && (
                                        <PSFormFieldSelection label="Gratuity"
                                                            helperLabel="Add gratuity"
                                                            linkText="Edit"
                                                            icon={<DollarIcon />}
                                                            extraComponent={Number(paymentData?.tip) > 0 ? <div className="bold">{currencyFormat(paymentData?.calculateTip())}</div> : undefined}
                                                            selection={Number(paymentData?.tip) > 0 ? {name: paymentData?.tip_type === 'percent' ? `${paymentData?.tip}%` : 'Custom amount'} : undefined}
                                                            onClick={() => setShowGratuityModal(true)}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    }

                {showTotalModal && (
                    <RecordTotalModal max={max} currentData={totalWithDiscount} min={min}
                                    onDone={(total) => {
                                        setShowTotalModal(false)
                                        setTotalWithDiscount(total)
                                        setPaymentData((currentVal) => {
                                            // calculate what discount was to add back to paymentData total (prevent double discounting)
                                            const paymentTotal = total + calcDiscount(currentVal)
                                            return new Payment({
                                                ...currentVal,
                                                total: paymentTotal
                                            })
                                        })
                                    }}
                                    onCancel={() => setShowTotalModal(false)}
                    />
                )}
                {showGratuityModal && (
                    <GratuityModal currentData={{rate: gratuityType, amount: gratuityAmount}}
                                   paymentData={paymentData!}
                                   onDone={(rate, amount) => {
                                       setGratuityType(rate)
                                       setGratuityAmount(amount)
                                       setShowGratuityModal(false)
                                   }}
                                   onCancel={() => setShowGratuityModal(false)}
                    />
                )}
                </AppHeader>
            }
    </>)
}