import moment from "moment";
import { Transaction } from "./Transaction";

export class GiftCertificate extends Transaction {
    name: string = ''
    client: string = '';
    form: string = '';
    contract: string = '';
    customer: string = '';
    paid_date: string = '';
    rate: number = 0;
    asking_amount?: number;
    expired_date: string = '';
    cancelled_date?: string;
    marked_paid_date?: string;
    created_date?: string;
    location?: string;
    expires?: string;
    item?: string;
    allow_surcharge: boolean = false;

    constructor(data: Partial<GiftCertificate>) {
        super(data)
        Object.assign(this, data)
    }

    describe() {
        const dateFormat = (date: string) => moment(date).format('MMM Do, Y')
        if (!!this.paid_date) {
            return `Expires ${dateFormat(this.expired_date)}`
        }

        return {
            360: 'Expires after 1 year',
            720: 'Expires after 2 years',
            1800: 'Expires after 5 years'
        }[Number(this.expires)] || 'No Expiration'
    }
}