import React, {useCallback, useEffect, useState} from "react";
import {useAppDispatch} from "../../../hooks";
import {
    fetchCategories,
    moveCategory,
    SettingsState
} from "../../../modules/settings";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult
} from "react-beautiful-dnd";
import {SortableItemCard} from "../SortableItemCard";
import {PSButtonPrimary} from "../../app/PSButton";
import {push} from "connected-react-router";
import localforage from "localforage";
import findIndex from "lodash.findindex";
import {Spinner} from "@zendeskgarden/react-loaders";
import {Dropdown, Item, Menu, Trigger} from "@zendeskgarden/react-dropdowns";
import { Button } from "@zendeskgarden/react-buttons";
import { ReactComponent as ChevronIcon } from "@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg";

const BOOK_HOST = process.env.REACT_APP_BOOK_HOST || 'https://pocketsuite.io'

export const SettingsCategoriesList = () => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const [pageLoading, setPageLoading] = useState(true)
    const [dropdownRotate, setDropdownRotated] = useState(false)

    useEffect(() =>{
        // @ts-ignore
        dispatch(fetchCategories()).then(() => setPageLoading(false))
    }, [dispatch])

    const [sortedCategories, setSortedCategories] = useState<any[]>([])
    useEffect(() => {
        setSortedCategories(
            state.categories.sort((a, b) => (a.seqnum ?? 1) - (b.seqnum ?? 1))
        )
    }, [state.categories])

    const onDragEnd = useCallback((result: DropResult) => {
        if (!result.destination) return

        const categories = sortedCategories.slice()
        const target = categories[result.destination.index]
        const [removed] = categories.splice(result.source.index, 1)

        // Send to the server
        dispatch(moveCategory(removed, target.seqnum))

        categories.splice(result.destination.index, 0, removed)
        setSortedCategories(categories)
    }, [dispatch, sortedCategories])

    const onMenuSelect = (action: string, categoryId: string) => {
        switch (action) {
            case 'preview':
                localforage.getItem('user').then((user: any) => {
                    const username = user.username || user.key
                    const categoryIndex = findIndex(state.categories, (c) => c.id === categoryId)
                    const category = state.categories[categoryIndex]
                    const categoryURL = category.name.replaceAll('-', '')
                        .replaceAll(' ',  '-')
                        .toLowerCase()
                    const previewURL = encodeURI(`${BOOK_HOST}/book/${username}?category=${categoryURL}`)
                    window.open(previewURL, '_blank')
                })
                break
            case 'sort':
                dispatch(push(`/settings/categories/${categoryId}/sort`))
                break
            case 'edit':
            default:
                dispatch(push(`/settings/categories/${categoryId}/edit`))
                break
        }
    }

    const onAddButton = useCallback((selected: string) => {
        if (selected === 'new')
            dispatch(push(`/settings/categories/new`))
        else
            dispatch(push('/settings/categories/defaults'))
    }, [dispatch])

    if (pageLoading || state.isSending) {
        return (
            <div className="servicesSettings">
                <div className="header">
                    <h1>Categories</h1>
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                </div>
            </div>
        )
    }

    if (state.categories.length === 0) {
        return (
            <div className="servicesSettings">
                <div className="header">
                    <h1>Categories</h1>
                </div>
                <div className="empty">
                    <div>You don't have any categories yet</div>
                    <div className="promo">Organize your items and create sub-groups to make booking even easier for clients 📱</div>
                    <Dropdown
                        onSelect={item => onAddButton(item)}
                        onStateChange={options => options.hasOwnProperty('isOpen') && setDropdownRotated(options.isOpen ?? false)}
                    >
                        <Trigger>
                            <PSButtonPrimary style={{height: '40px'}}
                                             onClick={() => {}}>
                                Add a category
                                <Button.EndIcon isRotated={dropdownRotate}>
                                    <ChevronIcon />
                                </Button.EndIcon>
                            </PSButtonPrimary>
                        </Trigger>
                        <Menu>
                            <Item value="default">Add category from defaults</Item>
                            <Item value="new">Create a category</Item>
                        </Menu>
                    </Dropdown>
                </div>
            </div>
        )
    }

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Categories</h1>
                <Dropdown
                    onSelect={item => onAddButton(item)}
                    onStateChange={options => options.hasOwnProperty('isOpen') && setDropdownRotated(options.isOpen ?? false)}
                >
                    <Trigger>
                        <PSButtonPrimary style={{height: '40px'}}
                                         onClick={() => {}}>
                            Add a category
                            <Button.EndIcon isRotated={dropdownRotate}>
                                <ChevronIcon />
                            </Button.EndIcon>
                        </PSButtonPrimary>
                    </Trigger>
                    <Menu>
                        <Item value="default">Add category from defaults</Item>
                        <Item value="new">Create a category</Item>
                    </Menu>
                </Dropdown>
            </div>
            <div className="separator" />
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(droppableProps: any) => {
                        return (
                            <div ref={droppableProps.innerRef}>
                                {sortedCategories.map((category: any, index) => (
                                    <Draggable key={category.id}
                                               draggableId={category.id as string}
                                               index={index}>
                                        {(provided: any, snapshot: any) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                            >
                                                <SortableItemCard key={`item-${category.id}`}
                                                                  isDragging={snapshot.isDragging}
                                                                  item={category}
                                                                  handleProps={provided.dragHandleProps}
                                                                  showMenu={true}
                                                                  onMenuSelect={onMenuSelect}
                                                                  type="service" />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            </div>
                        )
                    }}
                </Droppable>
            </DragDropContext>
        </div>
    )
}