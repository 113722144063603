import React, {useCallback, useState} from "react"
import {useAppDispatch, usePSUser} from "../../../hooks";
import { PSButtonPrimary } from "../../app/PSButton";
import { PSFormFieldSelection } from "../../app/PSFormFieldSelection";
import { PSLearnMore } from "../../app/PSLearnMore";
import { sendTaxForm } from "../../../modules/settings";
import { Result } from "../../../utils";
import { SuccessModal } from "../SuccessModal";
import { Spinner } from "@zendeskgarden/react-loaders";


export const SendTaxForm = () => {
    const user  = usePSUser()
    const dispatch = useAppDispatch()
    
    const [isSending, setIsSending] = useState(false)
    const [showComplete, setShowComplete] = useState(false)
    const [learnMoreComponent, showLearnMore] = PSLearnMore([
        { key: 'email', title: 'Change Email', body: 'To send form to different email, please update email on file.' }
    ]);

  
    const onSend = useCallback(() => {
        if (!['company', 'solo'].includes(user?.type ?? '')) {
            return // only company owners can send tax form
        }

        setIsSending(true)
        Result(dispatch(sendTaxForm())).then(() => {
            setShowComplete(true)
        })
        .finally(() => setIsSending(false))
    }, [dispatch, user?.type])


    const openSuiteCenter = () =>
        window.open("https://support.pocketsuite.io/hc/en-us/articles/360029087991-Will-I-get-a-1099-", "_blank");

    return (<>
        <div className="servicesSettings">
            <div className="header">
                <h1>Send tax form</h1>
                <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                 onClick={onSend}
                                 disabled={isSending}>
                    Send
                </PSButtonPrimary>
            </div>

            {isSending && <Spinner size="128" color="#314A68" />}

            {!isSending && (<>
                <PSFormFieldSelection
                    label="Tax form"
                    helperLabel={"2023 1099-K"}
                    hideButton
                    onClick={() => {}}
                />
                <div onClick={() => showLearnMore('email')}>
                    <PSFormFieldSelection
                        label="Send via email"
                        helperLabel={user?.email}
                        hideButton
                        onClick={() => {}}
                    />
                </div>

                <div className="buttonLink brightBlue"
                        style={{display: 'inline-block', paddingTop: 8}}
                        onClick={() => openSuiteCenter()}>
                    Learn more &gt;
                </div>
            </>)}
        </div>

        {learnMoreComponent}

        {showComplete &&
            <SuccessModal title="Success" buttonText="Done" body={`Please check your email for your 1099K tax form.`} onClose={() => setShowComplete(false)} onAction={() => setShowComplete(false)} />
        }
    </>)
}