import React, { useEffect } from 'react'
import { Button, ButtonGroup } from '@zendeskgarden/react-buttons'
import { useDispatch, useSelector } from 'react-redux'
import { getCalendar } from '../../modules/calendar'
import moment from 'moment'
import { generateCancelToken } from '../../modules/apiclient'
import styled from 'styled-components'

import { ReactComponent as RightArrow } from '../../icons/right-arrow.svg'
import { ReactComponent as LeftArrow } from '../../icons/left-arrow.svg'
import { PSButton, PSButtonPrimary } from '../app/PSButton'
import { push } from 'connected-react-router'

const DayChangeButton = styled(Button)`
  border: none;
  background-color: #fafafb;
`

export const CalendarHeaderBar = () => {
    const dispatch = useDispatch()
    const state = useSelector(state => state.calendar)

    // TODO Fix cancel token, if state changes we get a new cancelToken
    // Create a useAPI hook
    let cancelToken
    useEffect(() => {
        return _ => {
            if (cancelToken) {
                cancelToken.cancel('header cancel')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const changeView = (newView) => {
        cancelToken = generateCancelToken()
        dispatch(getCalendar(state.currentDate, newView,  cancelToken.token))
    }

    const goToday = () => {
        cancelToken = generateCancelToken()
        dispatch(getCalendar(moment(), state.currentView, cancelToken.token))
    }

    const skipBackward = () => {
        cancelToken = generateCancelToken()
        const newDate = state.currentDate.clone()
        switch (state.currentView) {
            case 'week':
                newDate.add(-7, 'days')
                break
            case 'month':
                newDate.add(-1, 'month')
                break
            case 'schedule':
            default:
                newDate.add(-1, 'days')
                break
        }
        dispatch(getCalendar(newDate, state.currentView, cancelToken.token))
    }

    const skipForward = () => {
        cancelToken = generateCancelToken()
        const newDate = state.currentDate.clone()
        switch (state.currentView) {
            case 'week':
                newDate.add(7, 'days')
                break
            case 'month':
                newDate.add(1, 'month')
                break
            case 'schedule':
            default:
                newDate.add(1, 'days')
                break
        }
        dispatch(getCalendar(newDate, state.currentView, cancelToken.token))
    }

    let calendarDate
    switch (state.currentView) {
        case 'week':
            calendarDate = state.currentDate.format('MMM Do YYYY')
            break
        case 'month':
            calendarDate = state.currentDate.format('MMMM YYYY')
            break
        case 'schedule':
        default:
            calendarDate = state.currentDate.format('MMM Do YYYY')
            break
    }

    if (state.didSearch || state.isSearching) {
        calendarDate = 'Search results'
    }

    return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <PSButton size="small" onClick={e => goToday()}>Today</PSButton>
            <DayChangeButton isBasic size="small" onClick={e => skipBackward()} style={{marginLeft: '32px'}}>
                <LeftArrow />
            </DayChangeButton>
            <DayChangeButton isBasic size="small" onClick={e => skipForward()}>
                <RightArrow />
            </DayChangeButton>
            <h2 style={{marginLeft: '32px', lineHeight: '30px', fontSize: '32px', fontWeight: '700', marginRight: '24px'}}>
                {calendarDate}
            </h2>
            <PSButton onClick={() => dispatch(push('/block/new'))} style={{marginRight: '8px'}}>Block out time</PSButton>
            <PSButtonPrimary onClick={() => dispatch(push('/schedule/new'))}>Schedule</PSButtonPrimary>
            <ButtonGroup style={{marginLeft: 'auto'}}
                         onSelect={changeView}
                         selectedItem={!state.didSearch && !state.isSearching && state.currentView}>
                <PSButton size="small" value="day">Day</PSButton>
                <PSButton size="small" value="week">Week</PSButton>
                <PSButton size="small" value="month">Month</PSButton>
                <PSButton size="small" value="schedule">Schedule</PSButton>
            </ButtonGroup>
        </div>
    )
}