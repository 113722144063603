import React, {useState} from "react";
import {Dashboard} from "../../models/Dashboard";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {ContactAvatar} from "../contacts/ContactAvatar";
import moment from "moment";
import { Link } from "react-router-dom";
import {currencyFormat} from "../../utils/numbers";
import {invoiceStatus} from "../../models/Invoice";
import {bookingStatus} from "../../utils/models";
import {e164ToPhone} from "../../utils/phone";
import {displayStatus as displayPackageStatus} from "../../models/Package";
import {Estimate, displayStatus as displayEstimateStatus} from "../../models/Estimate";
import {dashboardTagColors} from "../../utils/colors";
import {triggerStatus} from "../../models/Trigger";
import {Avatar} from "@zendeskgarden/react-avatars";
import {DashboardIcon} from "./DashboardIcon";
import {paymentStatus} from "../../models/Payment";
import {classCapacity} from "../../models/Class";
import {taskStatus} from "../../models/Task";
import {Dropdown, Item, Menu, Trigger} from "@zendeskgarden/react-dropdowns";
import {IconButton} from "@zendeskgarden/react-buttons";
import {ReactComponent as KabobIcon} from "../../icons/kabob_menu.svg";
import {GuardrailModal} from "../settings/GuardrailModal";
import {deleteBooking} from "../../modules/settings";
import {useAppDispatch} from "../../hooks";
import {Spinner} from "@zendeskgarden/react-loaders";
import {getDescription} from "../../models/Source";
import {push} from "connected-react-router";
import {InventoryItem} from "../../models/InventoryAdjustment";

type Props = {
    dashboard: Dashboard,
    data: any,
    view: Number,
    onRefresh: () => void,
}

export const DashboardDetailCard = ({dashboard, data, view, onRefresh}: Props) => {
    const dispatch = useAppDispatch()
    const state: any = useSelector((state: RootState) => state.entities)

    let leftIcon, title, subtitle, extraText, tagColors, tagText, text, link,
        rightIcon, kabobMenuItems

    const [loading, setLoading] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    switch (dashboard.id) {
        case 'bookings':
        case 'pending':
        case 'cancellations':
        case 'rebooks':
        case 'complete': {
            const user = state.users[data.client]
            if (user) leftIcon = <ContactAvatar user={user} />
            title = user?.name
            if (state.items[data.item])
                subtitle = state.items[data.item]?.name
            else
                subtitle = bookingStatus(data)
            extraText = moment(data.date).format('ddd MMM D, YYYY h:mma')
            if (!data.paid_date && dashboard.id === 'complete') {
                let amount = data.amount
                if (data.deposit && data.paid_deposit_date)
                    amount -= data.deposit
                if (amount > 0)
                    text = currencyFormat(amount)
            }
            link = `/record/booking/${data.id}`

            if (dashboard.id === 'bookings') {
                kabobMenuItems = (
                    <>
                        <Item onClick={() => setShowDeleteModal(true)} value="cancel">
                            <span style={{color: 'red'}}>Cancel</span>
                        </Item>
                    </>
                )
            }
            break
        }
        case 'invoices': {
            const user = state.users[data.client]
            if (user) leftIcon = <ContactAvatar user={user} />
            title = user?.name
            if (data.source)
                title += ' 💳'
            subtitle = `${invoiceStatus(data)} (${data.number})`
            link = `/record/invoice/${data.id}`
            text = currencyFormat(data.asking_amount)
            break
        }
        case 'clients': {
            const user = state.users[data.person]
            const client = state.clients[data.id]
            if (user) leftIcon = <ContactAvatar user={user} />
            title = user?.name
            if (view > 0)
                subtitle = user?.phone ? e164ToPhone(user.phone) : ''
            else if (client?.channel?.id === 'online')
                subtitle = 'Online widget'
            else if (client?.channel)
                subtitle = client.channel.name
            else if (client?.referer)
                subtitle = client.referer.name
            else
                subtitle = user?.phone ? e164ToPhone(user.phone) : ''

            if (view === 0)
                text = moment(client.created_date).format('MMM D')
            else if (view === 1)
                text = moment(client.last_transaction_date).format('MMM D')
            else
                text = moment(client.last_payment_date).format('MMM D')

            link = `/record/client/${user.id}`
            break
        }
        case 'packages': {
            const user = state.users[data.client]
            if (user) leftIcon = <ContactAvatar user={user} />
            title = user?.name
            if (state.items[data.item])
                subtitle = state.items[data.item]?.name
            else
                subtitle = displayPackageStatus(data)
            if (data.paid_date) {
                const remaining = data.capacity - data.number_of_bookings
                if (data.capacity_type === 'duration')
                    tagText = `${Math.round(remaining/60)} h`
                else
                    tagText = `${remaining} sessions left`
                tagColors = view === 2 ? dashboardTagColors.red : dashboardTagColors.green
            }
            else {
                tagText = 'Not paid'
                tagColors = dashboardTagColors.grey
            }

            link = `/record/package/${data.id}`
            break
        }
        case 'links': {
            if (view === 2) {
                title = data.link
                text = Math.round(data.ct)
            }
            else {
                title = data.referer || 'Unknown'
                text = `${Math.round(data.percent)}%`
            }
            break
        }
        case 'deposits': {
            const source = state.sources[data.source]
            title = source?.type === 'checking' ? getDescription(source) : source?.name
            subtitle = moment(data.created_date).format('MMM D, YYYY')
            text = currencyFormat(data.amount)
            link = `/record/deposit/${data.id}`
            break
        }
        case 'triggers': {
            const trigger = state.triggers[data.trigger]
            title = trigger?.label || '-No label-'
            const creditCount = Number(data.credit_count)
            if (creditCount === 1) subtitle = 'Sent to 1 client'
            else if (creditCount > 1) subtitle = `Sent to ${creditCount} clients`
            else subtitle = triggerStatus[data.type]

            tagColors = dashboardTagColors.green
            tagText =  'LIVE'
            if (trigger?.paused_date) {
                tagColors = dashboardTagColors.yellow
                tagText = 'PAUSED'
            }
            else if (trigger && !moment(trigger.start_date).isBefore(moment())) {
                tagColors = dashboardTagColors.grey
                tagText = 'FUTURE'
            }
            else if (trigger && moment(trigger.completed_date).isBefore(moment()))
                tagText = 'DONE'

            if (data.income)
                tagText = currencyFormat(data.income)

            // TODO No smart campaign record view available at the moment
            // link = `/record/trigger/${data.id}`
            break
        }
        case 'subscriptions': {
            const user = state.users[data.client]
            const plan = state.items[data.plan]
            if (user) leftIcon = <ContactAvatar user={user} />
            title = user?.name
            subtitle = plan?.name
            if (view === 6)
                text = moment(data.cancelled_date).format('MMM D')
            else if (data.completed_date)
                text = moment(data.completed_date).format('MMM D')
            else if (data.next_invoice_date)
                text = moment(data.next_invoice_date).format('MMM D')
            else
                text = currencyFormat(data.amount)

            link = `/record/subscription/${data.id}`
            break
        }
        case 'records': {
            const user = state.users[data.person]
            if (user) leftIcon = <ContactAvatar user={user} />
            title = user?.name

            const type = state.types[data.type]
            subtitle = type?.name
            switch (view) {
                case 1:
                    text = moment(data.sent_date).format('MMM D')
                    break
                case 2:
                    text = moment(data.viewed_date).format('MMM D')
                    break
                case 3:
                    text = moment(data.cancelled_date).format('MMM D')
                    break
                case 4:
                    text = moment(data.created_date).format('MMM D')
                    break
                default:
                    text = moment(data.completed_date).format('MMM D')
                    break
            }
            link = `/record/record/${data.id}`
            break
        }
        case 'estimates': {
            const estimate = new Estimate(data);
            const user = state.users[data.client]
            if (user) leftIcon = <ContactAvatar user={user} />
            title = user?.name
            if (data.source)
                title += " 💳"
            subtitle = `${displayEstimateStatus(data)} (${data.number})`
            text = currencyFormat(estimate.askingAmount())
            link = `/record/estimate/${data.id}`
            break
        }
        case 'gifts': {
            const user = state.users[data.customer]
            if (user) leftIcon = <ContactAvatar user={user} />
            title = user?.name
            if (data.item)
                subtitle = state.items[data.item]?.name
            const balanceLeft = Number(data.balance)
            if (data.paid_date) {
                tagText = currencyFormat(data.balance)
                if (balanceLeft <= 0 || moment(data.expired_date).isBefore(moment()))
                    tagColors = dashboardTagColors.red
                else if (balanceLeft < Number(data.total)/10)
                    tagColors = dashboardTagColors.yellow
                else
                    tagColors = dashboardTagColors.green
            }
            else {
                tagText = 'Not paid'
                tagColors = dashboardTagColors.grey
            }
            link = `/record/gift/${data.id}`
            break
        }
        case 'expenses': {
            const me = localStorage.getItem('userId')
            const isMe = data.client === me
            const person = isMe ? (data.employee ? data.employee : (data.creator && data.creator !== me ? data.creator : data.vendor)) : data.client
            const user = state.users[data.type === 'payment' ? data.vendor : person]
            if (user) leftIcon = <ContactAvatar user={user} />

            title = user?.name
            subtitle = paymentStatus(data)
            text = currencyFormat(data.amount)
            if (["booking", "invoice", "package", "gift", "payment", "estimate", "charge", "subscription", "job"].includes(data.record_type))
                link = `/record/${data.record_type}/${data.id}`
            break
        }
        case 'messages': {
            const user = state.users[data.person]
            const template = state.templates[data.template]
            if (user) leftIcon = <ContactAvatar user={user} />
            title = user?.name
            subtitle = template?.title
            text = moment(data.completed_date || data.date).format('MMM D')
            // TODO No message record view at the moment
            // link = ''
            break
        }
        case 'inventory': {
            title = data.name
            if (data.image_uri)
                leftIcon = <Avatar size='large'><img src={data.image_uri} alt={data.name} /></Avatar>
            else
                leftIcon = <div style={{paddingLeft: '8px', paddingRight: '14px'}}><DashboardIcon dashboard={dashboard} /></div>
            const count = (dashboard as any).meta_data.balances[data.id]
            tagColors = count > 0 ? dashboardTagColors.green : dashboardTagColors.red
            tagText = Math.trunc(count) === 0 ? 'out of stock' : `${Math.abs(Math.trunc(count))} ${count < 0 ? 'out of stock' : 'remaining'}`;
            const item = new InventoryItem(data);
            kabobMenuItems = (
                <>
                    <Item onClick={() => dispatch(push('/inventory/history', {'item': item}))} value="history">
                        View change history
                    </Item>
                    <Item onClick={() => dispatch(push('/inventory/edit', {'item': item}))} value="edit">
                        Change quantity
                    </Item>
                </>
            )
            text = currencyFormat(data.rate);
            link = `/inventory/product/${data.id}`;
            break
        }
        case 'employees': {
            const user = state.users[data.person]
            if (user) leftIcon = <ContactAvatar user={user} />
            title = user.name
            if (user.solo || user.company)
                subtitle = user.entity
            else if (user.role && data.created_date) {
                subtitle = user.role
                switch (user.role) {
                    case 'admin': subtitle = 'Administrator'; break;
                    case 'employee': subtitle = 'Staff'; break;
                    case 'contractor': subtitle = 'Limited'; break;
                    case 'owner': subtitle = user.entity; break;
                    default: subtitle = user.role;
                }
            }
            link = `/record/payee/${data.person}`
            break
        }
        case 'contracts':
            const user = state.users[data.person]
            const contract = state.contracts[data.contract]
            if (user) leftIcon = <ContactAvatar user={user} />
            title = user.name
            subtitle = contract?.name
            switch (view) {
                case 1: text = moment(data.sent_date).format('MMM D'); break;
                case 2: text = moment(data.viewed_date).format('MMM D'); break;
                case 3: text = moment(data.cancelled_date).format('MMM D'); break;
                case 4: text = moment(data.cancelled_date).format('MMM D'); break;
                default: text = moment(data.signed_date).format('MMM D'); break;
            }
            link = `/record/signature/${data.id}`
            break
        case 'classes': {
            const item = state.items[data.item]
            title = item?.name
            subtitle = classCapacity(data, item, state.bookings)
            if (view === 2)
                text = moment(data.cancelled_date).format('MMM D')
            else
                text = moment(data.date).format('MMM D')
            link = `/record/class/${data.id}`
            break
        }
        case 'billings': {
            const user = state.users[data.client]
            if (user) leftIcon = <ContactAvatar user={user} />
            title = user?.name
            subtitle = invoiceStatus(data)
            text = currencyFormat(data.amount)
            link = `/record/invoice/${data.id}`
            break
        }
        case 'tasks': {
            const person = state.users[data.person]
            if (person)
                leftIcon = <ContactAvatar user={person} />
            if (!dashboard.person && person)
                title = person.name
            else
                title = taskStatus(data)
            subtitle = data.title
            text = moment(data.date).format('MMM D')
            link = `/record/task/${data.id}`
            break
        }
        case 'items': {
            const item = state.items[data.item]
            title = item.name

            const transaction: any = {
                'booking': state.bookings[data.trandoc],
                'invoice': state.invoices[data.trandoc],
                'charge': state.charges[data.trandoc],
                'estimate': state.estimates[data.trandoc],
            }[data.trantype as string]

            const client = state.users[transaction?.client]
            subtitle = !client?.name && data.trantype === "charge" ? "Anonymous" : client?.name;
            text = currencyFormat(data.amount)

            if (item.image_uri)
                leftIcon = <Avatar size='large'><img src={item.image_uri} alt={item.name} /></Avatar>
            else
                leftIcon = <div style={{paddingLeft: '8px', paddingRight: '14px'}}><DashboardIcon dashboard={dashboard} /></div>

            if (["booking", "invoice", "charge", "estimate"].includes(data.trantype))
                link = `/record/${data.trantype}/${data.trandoc}`

            break
        }
        default:
            title = dashboard.id
            break
    }

    if (data.employee) {
        const employee = state.users[data.employee]
        if (employee)
            rightIcon = <ContactAvatar user={employee} size="small" />
    }

    if (loading) {
        return (
            <div style={{textAlign: 'center', padding: '128px 0'}}>
                <Spinner size="128" color="#314A68" />
            </div>
        )
    }

    return (
        <div className="card">
            {leftIcon && (
                <div>{leftIcon}</div>
            )}
            <div className="titleArea">
                <>
                    {title && (
                        <div className="title">{title}</div>
                    )}
                    {subtitle && (
                        <div className="subtitle">{subtitle}</div>
                    )}
                    {extraText && (
                        <div className="extra">{extraText}</div>
                    )}
                </>
            </div>
            <div className="right">
                {tagText && (
                    <div className="tag"
                         style={{backgroundColor: tagColors?.bg, color: tagColors?.fg}}
                    >
                        {tagText}
                    </div>
                )}
                {rightIcon && (
                    <div className="employee">{rightIcon}</div>
                )}
                {text && (
                    <div className="text">{text}</div>
                )}
                {link && (
                    <Link to={link}>View</Link>
                )}
                {kabobMenuItems && (
                    <span style={{paddingLeft: '14px'}}>
                        <Dropdown>
                            <Trigger>
                                <IconButton focusInset={false}>
                                    <KabobIcon />
                                </IconButton>
                            </Trigger>
                            <Menu hasArrow>
                                {kabobMenuItems}
                            </Menu>
                        </Dropdown>
                    </span>
                )}
            </div>
            {showDeleteModal && (
                <GuardrailModal title="Are you sure?"
                                body="Are you sure you'd like to cancel this appointment?"
                                extraButtonText="Cancel and notify"
                                buttonText="Cancel"
                                onClose={() => setShowDeleteModal(false)}
                                onAction={(actionButton) => {
                                    setShowDeleteModal(false)
                                    setLoading(true)
                                    dispatch(deleteBooking(data.id, actionButton !== 2))
                                        // @ts-ignore
                                        .then(() => onRefresh())
                                        .finally(() => setLoading(false))
                                }}
                />
            )}
        </div>
    )
}