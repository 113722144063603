import React, {useCallback, useState} from "react"
import {AppHeader} from "../app/AppHeader";
import {PSButton, PSButtonPrimary} from "../app/PSButton";
import {useAppDispatch, usePSUser} from "../../hooks";
import { push } from "connected-react-router";
import { WebCameraModal } from "./WebCameraModal";
import { ErrorModal } from "../app/ErrorModal";
import { setGoLiveState, submitVerification } from "../../modules/onboarding";
import { Result } from "../../utils";
import { Spinner } from "@zendeskgarden/react-loaders";
import { useLocation } from "react-router";

type VerificationLocationState = {
    legalName: string,
    ssnLast4: string,
    address: string,
    birthday: Date
}

export const GoLivePhotoVerification = () => {
    const dispatch = useAppDispatch()
    const { legalName, ssnLast4, address, birthday} = useLocation<VerificationLocationState>().state

    const [image, setImage] = useState<string>()
    const [loading, setLoading] = useState(false)
    const [cameraError, setCameraError] = useState(false)
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [showCameraModal, setShowCameraModal] = useState(false)

    const user = usePSUser()
    const onNextHandler = useCallback(() => {
        if (!user) return

        setLoading(true)
        Result(dispatch(submitVerification(user.id, legalName, ssnLast4, address, birthday, image)))
            .then(() => setGoLiveState('gl_payouts'))
            .then((me) => dispatch(push('/golive/conditionally-verified')))
            .finally(() => setLoading(false))
    }, [user, dispatch, legalName, ssnLast4, address, birthday, image])

    const onCaptureImage = (image: string) => {
        setShowCameraModal(false)
        setImage(image)
    }

    const onCameraError = () => {
        if (cameraError) {
            // show the additional info popup if they try to click take photo after denying
            // because we change the body text we only need to show this if someone missed the updated body text
            setShowErrorModal(true)
        }
        setShowCameraModal(false)
        setImage(undefined)
        setCameraError(true)
    }

    let body = <>Take a photo of your driver's license or other type of government / state ID. Again, this is used to verify identity so that your account is appropriately and securely paid out.<br /><br />
        To make sure that there are no delays make sure the photo is clear and all information is visible.</>
    let title = <>Last Step!</>
    let bottomText = <span className="bold">Example</span>
    let button = <PSButtonPrimary onClick={() => setShowCameraModal(true)}>Take a Photo</PSButtonPrimary>

    if (image) {
        bottomText = <><span className="bold">Want to use this photo?</span><br />Make sure your documentation is readable</>
        button = <PSButton onClick={() => setShowCameraModal(true)}>Retake</PSButton>
    }

    if (cameraError) {
        title = <div style={{color: 'rgb(235, 87, 87)'}}>Camera needed!</div>
        body = <>PocketSuite needs access to your camera so that you can snap a photo of your ID. Once you have been verified you’ll be able to cash out client payments and see those payments hit your account within 15 minutes. </>
    }

    if (loading) {
        return (
            <div className="servicesSettings">
                <div className="header">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <AppHeader title="Grow your business" showBackButton middleWidget={null}>
            <div className="servicesSettings onboarding launchBusiness">
                <div className="header">
                    <h1>{title}</h1>
                    <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}} onClick={onNextHandler} disabled={!image}>
                        Next
                    </PSButtonPrimary>
                </div>

                <div className="separator" />

                <div className="form">
                    <div className="text">{body}</div>
                    <div style={{width:"343px", textAlign: "center"}}>
                        <img src={image ?? "/icons/example-driver-license.png"} alt="example drivers license" style={{borderRadius: image ? '0' : '12px'}}/>
                        {bottomText}
                    </div>
                    <div className="buttons left">{button}</div>
                </div>

                {showCameraModal && <WebCameraModal onCapture={onCaptureImage} onClose={() => setShowCameraModal(false)} onError={onCameraError} onCameraConnected={() => setCameraError(false)} />}

                {showErrorModal && <ErrorModal title="Camera needed!" message="Please grant browser permission to access camera or use a mobile device to complete verification." buttonText="Ok" onButtonClick={() => {setShowErrorModal(false)}} onClose={() => {setShowErrorModal(false)}} />}

            </div>
        </AppHeader>
    )
}