import {Route, Switch} from "react-router-dom";
import {DashboardIndex} from "../dashboard/DashboardIndex";
import {ContactView} from "../contacts/ContactView";
import {DashboardIncome} from "../dashboard/DashboardIncome";
import {DashboardView} from "../dashboard/DashboardView";
import {CalendarIndex} from "../calendar/CalendarIndex";
import {MessageView} from "../messages/MessageView";
import {RecordView} from "../records/RecordView";
import {SettingsView} from "../settings/SettingsView";
import React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import {Main} from "@zendeskgarden/react-chrome";
import {useSelector} from "react-redux";
import {useErrorHandler} from "react-error-boundary";
import {RootState} from "../../store";
import {ScheduleEdit} from "../transactions/schedule/ScheduleEdit";
import {ScheduleConfirm} from "../transactions/schedule/ScheduleConfirm";
import {TransactionSuccess} from "../transactions/TransactionSuccess";
import {ChargeNew} from "../transactions/charge/ChargeNew";
import {ChargeLineItemNew} from "../transactions/charge/ChargeLineItemNew";
import {ChargeSummary} from "../transactions/charge/ChargeSummary";
import {ChargeSuccess} from "../transactions/charge/ChargeSuccess";
import {ChargeReceipt} from "../transactions/charge/ChargeReceipt";
import {ChargeAddCard} from "../transactions/charge/ChargeAddCard";
import {ContactEdit} from "../contacts/ContactEdit";
import {ContactAddCard} from "../contacts/ContactAddCard"
import {ContactPaymentMethodList} from "../contacts/ContactPaymentMethodList";
import {ContactSourceView} from "../contacts/ContactSourceView";
import {InvoiceEdit} from "../transactions/invoice/InvoiceEdit";
import {ExpenseLineItem} from "../transactions/ExpenseLineItem";
import {InvoiceSummary} from "../transactions/invoice/InvoiceSummary";
import {InvoiceSuccess} from "../transactions/invoice/InvoiceSuccess";
import {ContractSend} from "../transactions/contract/ContractSend";
import {FormSend} from '../transactions/form/FormSend'
import {SubscriptionView} from "../transactions/subscriptions/SubscriptionView";
import {SubscriptionSummary} from "../transactions/subscriptions/SubscriptionSummary";
import {SubscriptionSuccess} from "../transactions/subscriptions/SubscriptionSuccess";
import {PackageView} from "../transactions/packages/PackageView";
import {PackageSummary} from "../transactions/packages/PackageSummary";
import {PackageSuccess} from "../transactions/packages/PackageSuccess";
import { GiftSuccess } from "../transactions/gift/GiftSuccess";
import { GiftSummary } from "../transactions/gift/GiftSummary";
import { GiftView } from "../transactions/gift/GiftView";
import { OnboardingHours } from "../onboarding/OnboardingHours";
import {OnboardingItems} from "../onboarding/OnboardingItems";
import { ItemEditLite } from "../onboarding/ItemEditLite";
import {ItemEdit} from "../onboarding/ItemEdit";
import {OnboardingForms} from "../onboarding/OnboardingForms";
import {OnboardingContracts} from "../onboarding/OnboardingContracts";
import {OnboardingLocation} from "../onboarding/OnboardingLocation";
import {OnboardingLocationEdit} from "../onboarding/OnboardingLocationEdit";
import {OnboardingContractEdit} from "../onboarding/OnboardingContractEdit";
import {GoLivePhotoVerification} from "../onboarding/GoLivePhotoVerification";
import {
    OnboardingIntakeQuestions
} from "../onboarding/OnboardingIntakeQuestions";
import {OnboardingSetupPayments} from "../onboarding/OnboardingSetupPayments";
import {
    OnboardingSetupPaymentsContinued
} from "../onboarding/OnboardingSetupPaymentsContinued";
import {OnboardingSetupProfile} from "../onboarding/OnboardingSetupProfile";
import {OnboardingSetupSuccess} from "../onboarding/OnboardingSuccess";
import { OnboardingFormEdit } from "../onboarding/OnboardingFormEdit";
import {OnboardingFieldEdit} from "../onboarding/OnboardingFieldEdit";
import {
    OnboardingSetupPaymentsContinuedTax
} from "../onboarding/OnboardingSetupPaymentsContinuedTax";
import {
    OnboardingSetupPaymentsContinuedGratuity
} from "../onboarding/OnboardingSetupPaymentsContinuedGratuity";
import { GoLivePaymentsVideo } from "../onboarding/GoLivePaymentsVideo";
import { GoLiveBusinessInfo } from "../onboarding/GoLiveBusinessInfo";
import { GoLivePersonalInfo } from "../onboarding/GoLivePersonalInfo";
import { GoLiveConditionallyVerified } from "../onboarding/GoLiveConditionallyVerified";
import { GoLiveReceivePayouts } from "../onboarding/GoLiveReceivePayouts";
import { GoLiveAddCard } from "../onboarding/GoLiveAddCard";
import { GoLiveAddChecking } from "../onboarding/GoLiveAddChecking";
import { GoLiveSelectPlan } from "../onboarding/GoLiveSelectPlan";
import { GoLiveSuccess } from "../onboarding/GoLiveSuccess";
import { ViewUserNotes } from "../notes/ViewUserNotes";
import { EditNote } from "../notes/EditNote";
import { ViewBookingNotes } from "../notes/ViewBookingNotes";
import { ViewEstimateNotes } from "../notes/ViewEstimateNotes";
import { RecordChangeCard } from "../records/RecordChangeCard";
import { RecordSelectPackage } from "../records/RecordSelectPackage";
import { RecordCheckout } from "../records/types/RecordCheckout";
import { RecordCancel } from "../records/RecordCancel";
import { BlockEdit } from "../settings/blocks/BlockEdit";
import { OnboardingCategories } from "../onboarding/OnboardingCategories";
import { OnboardingCategoryEdit } from "../onboarding/OnboardingCategoryEdit";
import { LessonRoster } from "../records/LessonRoster";
import { RecordPayments } from "../records/RecordPayments";
import { RecordEdit } from "../forms/RecordEdit";
import { RecordSubscriptionConfirmCharge } from "../records/types/RecordSubscriptionConfirmCharge";
import { RecordRefund } from "../records/types/RecordRefund";
import { AccountBalanceView } from "../dashboard/AccountBalanceView";
import { RecordPurchases } from "../records/RecordPurchases";
import { EstimateEdit } from "../transactions/estimate/EstimateEdit";
import { EstimateSummary } from "../transactions/estimate/EstimateSummary";
import { EstimateSuccess } from "../transactions/estimate/EstimateSuccess";
import { ChargeMarkPaid } from "../transactions/charge/ChargeMarkPaid";
import { EditInventory } from "../inventory/EditInventory";
import { ViewHistory } from "../inventory/ViewHistory";
import { InventoryProductEdit } from "../inventory/InventoryProductEdit";

const PaddedMain = styled(Main)`
  padding: 0;
  background-color: #fafafb;
`

type Props = {
    showDashboards: boolean,
}

export const AppRouter = ({showDashboards}: Props) => {
    const state = useSelector((state: RootState) => {
        return {
            error: state.error,
        }
    })

    useErrorHandler(state.error.error)

    return (
        <PaddedMain>
            <Switch>
                <Route path="/" exact
                       component={showDashboards ? DashboardIndex : ContactView}/>
                <Route path="/dashboard/user/:userId" component={DashboardIndex}/>
                <Route path="/dashboards/income" exact component={DashboardIncome}/>
                <Route path="/dashboards/:dashboard" exact component={DashboardView}/>
                <Route path="/dashboards/deposits/balance" component={AccountBalanceView}/>
                <Route path="/inventory/edit" exact component={EditInventory}/>
                <Route path="/inventory/history" exact component={ViewHistory}/>
                <Route path="/inventory/product/:productID?" component={InventoryProductEdit}/>
                <Route path="/block/new" exact component={BlockEdit}/>
                <Route path="/block/edit/:blockId" component={BlockEdit}/>
                <Route path="/block/edit-all/:blockId" component={BlockEdit}/>
                <Route path="/schedule/edit/:recordID" component={ScheduleEdit}/>
                <Route path="/schedule/new" exact component={ScheduleEdit}/>
                <Route path="/schedule/new/confirm" exact component={ScheduleConfirm}/>
                <Route path="/schedule/success" exact component={TransactionSuccess}/>
                <Route path="/lesson/:id/roster" exact component={LessonRoster}/>
                <Route path="/gift/new" exact component={GiftView}/>
                <Route path="/gift/:id/edit" exact component={GiftView}/>
                <Route path="/gift/:id/preview" exact component={GiftSummary}/>
                <Route path="/gift/preview" exact component={GiftSummary}/>
                <Route path="/gift/success/:id?" component={GiftSuccess}/>
                <Route path="/subscription/new" exact component={SubscriptionView}/>
                <Route path="/subscription/:id/edit" exact component={SubscriptionView}/>
                <Route path="/subscription/:id/preview" exact component={SubscriptionSummary}/>
                <Route path="/subscription/preview" exact component={SubscriptionSummary}/>
                <Route path="/subscription/success/:id?" component={SubscriptionSuccess}/>
                <Route path="/package/new" exact component={PackageView}/>
                <Route path="/package/:id/edit" exact component={PackageView}/>
                <Route path="/package/:id/preview" exact component={PackageSummary}/>
                <Route path="/package/preview" exact component={PackageSummary}/>
                <Route path="/package/success/:id?" component={PackageSuccess}/>
                <Route path="/charge/new" exact component={ChargeNew}/>
                <Route path="/charge/new/confirm" exact component={ChargeSummary}/>
                <Route path="/(charge|invoice|estimate)/new/lineitem/item" exact component={ChargeLineItemNew}/>
                <Route path="/(charge|invoice|estimate)/new/lineitem/item/:index/edit" component={ChargeLineItemNew}/>
                <Route path="/(charge|invoice|estimate)/new/lineitem/expense" exact component={ExpenseLineItem}/>
                <Route path="/(charge|invoice|estimate)/new/lineitem/expense/:index/edit" component={ExpenseLineItem}/>
                <Route path="/charge/mark-paid" component={ChargeMarkPaid}/>
                <Route path="/charge/success" component={ChargeSuccess}/>
                <Route path="/charge/receipt" component={ChargeReceipt}/>
                <Route path="/charge/add-card" component={ChargeAddCard}/>
                <Route path="/invoice/new" exact component={InvoiceEdit}/>
                <Route path="/invoice/edit/:recordID" component={InvoiceEdit}/>
                <Route path="/invoice/confirm" exact component={InvoiceSummary}/>
                <Route path="/invoice/success" exact component={InvoiceSuccess}/>
                <Route path="/estimate/new" exact component={EstimateEdit}/>
                <Route path="/estimate/edit/:recordID" component={EstimateEdit}/>
                <Route path="/estimate/confirm" exact component={EstimateSummary}/>
                <Route path="/estimate/success" exact component={EstimateSuccess}/>
                <Route path="/clients" component={ContactView}/>
                <Route path="/client/edit/:id?" component={ContactEdit}/>
                <Route path="/client/:id/payment-methods" component={ContactPaymentMethodList} />
                <Route path="/client/:id/add-card" component={ContactAddCard}/>
                <Route path="/client/:id/view-card/:sourceId" component={ContactSourceView}/>
                <Route path="/calendar" exact component={CalendarIndex}/>
                <Route path="/notes/payee/:id" component={ViewUserNotes} />
                <Route path="/notes/client/:id" component={ViewUserNotes} />
                <Route path="/notes/booking/:id" component={ViewBookingNotes} />
                <Route path="/notes/estimate/:id" component={ViewEstimateNotes} />
                <Route path="/note/edit/:id" component={EditNote} />
                <Route path="/:type/:id/add-note" component={EditNote} />
                <Route path="/messages" exact
                       component={MessageView}/>
                <Route path="/messages/:threadID"
                       component={MessageView}/>
                <Route path="/contract/send" exact component={ContractSend}/>
                <Route path="/form/send" exact component={FormSend}/>
                <Route path="/records/:id/edit" component={RecordEdit} />
                <Route path="/record/:recordType/:recordID"
                       component={RecordView}/>
                <Route path="/payments/:recordType/:recordID" component={RecordPayments} />
                <Route path="/purchases/:recordType/:recordID" component={RecordPurchases} />
                <Route path="/select-package/:recordType/:recordID" component={RecordSelectPackage} />
                <Route path="/confirm-charge/subscription/:recordID" component={RecordSubscriptionConfirmCharge} />
                <Route path="/charge/:recordType/:recordID/:subType?" component={RecordCheckout} />
                <Route path="/change-card/:recordType/:recordID" component={RecordChangeCard} />
                <Route path="/cancel/:recordType/:recordID" component={RecordCancel} />
                <Route path="/refund/:recordType/:recordID" component={RecordRefund} />
                <Route path="/settings/:settingType?"
                       component={SettingsView}/>
                <Route path="/onboarding/hours/:simpleMode?" component={OnboardingHours} />
                <Route path="/onboarding/categories" exact component={OnboardingCategories} />
                <Route path="/onboarding/category/edit" exact component={OnboardingCategoryEdit} />
                <Route path="/onboarding/(services|products|classes|reservations|packages|subscriptions|gifts)" component={OnboardingItems} />
                <Route path="/onboarding/item/edit-lite" exact component={ItemEditLite} />
                <Route path="/onboarding/item/edit" exact component={ItemEdit} />
                <Route path="/onboarding/contracts" exact component={OnboardingContracts} />
                <Route path="/onboarding/contract/edit" exact component={OnboardingContractEdit} />
                <Route path="/onboarding/forms" exact component={OnboardingForms} />
                <Route path="/onboarding/form/edit" exact component={OnboardingFormEdit} />
                <Route path="/onboarding/field/edit" exact component={OnboardingFieldEdit} />
                <Route path="/onboarding/intake" exact component={OnboardingIntakeQuestions} />
                <Route path="/onboarding/location" exact component={OnboardingLocation} />
                <Route path="/onboarding/location/edit" exact component={OnboardingLocationEdit} />
                <Route path="/onboarding/payment" exact component={OnboardingSetupPayments} />
                <Route path="/onboarding/payment-continued" exact component={OnboardingSetupPaymentsContinued} />
                <Route path="/onboarding/payment-continued/tax" exact component={OnboardingSetupPaymentsContinuedTax} />
                <Route path="/onboarding/payment-continued/gratuity" exact component={OnboardingSetupPaymentsContinuedGratuity} />
                <Route path="/onboarding/profile" exact component={OnboardingSetupProfile} />
                <Route path="/onboarding/success" exact component={OnboardingSetupSuccess} />
                <Route path="/onboarding/item/edit-lite" component={ItemEditLite} />
                <Route path="/onboarding/item/edit" component={ItemEdit} />
                <Route path="/onboarding/contracts" component={OnboardingContracts} />
                <Route path="/onboarding/contract/edit" component={OnboardingContractEdit} />
                <Route path="/onboarding/forms" component={OnboardingForms} />
                <Route path="/onboarding/intake" component={OnboardingIntakeQuestions} />
                <Route path="/onboarding/location" component={OnboardingLocation} />
                <Route path="/onboarding/payment" component={OnboardingSetupPayments} />
                <Route path="/onboarding/payment-continued" component={OnboardingSetupPaymentsContinued} />
                <Route path="/onboarding/profile" component={OnboardingSetupProfile} />
                <Route path="/onboarding/success" component={OnboardingSetupSuccess} />
                <Route path="/golive/welcome" exact component={GoLivePaymentsVideo} />
                <Route path="/golive/personal-info" exact component={GoLivePersonalInfo} />
                <Route path="/golive/business-info" exact component={GoLiveBusinessInfo} />
                <Route path="/golive/receive-payouts" exact component={GoLiveReceivePayouts} />
                <Route path="/golive/photo-verification" component={GoLivePhotoVerification} />
                <Route path="/golive/conditionally-verified" exact component={GoLiveConditionallyVerified} />
                <Route path="/golive/add-card" exact component={GoLiveAddCard} />
                <Route path="/golive/add-checking" exact component={GoLiveAddChecking} />
                <Route path="/golive/select-plan" exact component={GoLiveSelectPlan} />
                <Route path="/golive/success" component={GoLiveSuccess} />
            </Switch>
        </PaddedMain>
    )
}

AppRouter.propTypes = {showDashboards: PropTypes.bool}
