import { Item } from "@zendeskgarden/react-dropdowns"
import { Checkbox, Field, Hint, Input, Label } from "@zendeskgarden/react-forms"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router"
import { AppHeader } from "../../app/AppHeader"
import { PSButtonPrimary } from "../../app/PSButton"
import { PSDropdown } from "../../app/PSDropdown"
import { Spinner } from "@zendeskgarden/react-loaders"
import { markPaidPaymentOptions, SelectOption } from "../../settings/Options"
import '../../settings/settings.css'
import { useDispatch } from "react-redux"
import { Result } from "../../../utils"
import { markBookingPaid, markGiftPaid, markEstimatePaid, markInvoicePaid, markPackagePaid, markPaid } from "../../../modules/transactions"
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals"
import { Booking } from "../../../models/Booking"
import { Invoice } from "../../../models/Invoice"
import { Package } from "../../../models/Package"
import { GiftCertificate } from "../../../models/GiftCertificate"
import { Estimate } from "../../../models/Estimate"
import { Datepicker } from "@zendeskgarden/react-datepickers"
import { currencyFormat } from "../../../utils/numbers"
import { Payment } from "../../../models/Payment"
import { push } from "connected-react-router"
import moment from "moment"
import { andConcat } from "../../../utils/strings"

export const ChargeMarkPaid = () => {
    const dispatch = useDispatch()
    const location: {paymentData:any, record?:any, recordType?:string} = useLocation().state as {paymentData:any, record?:any, recordType?:string}

    const [paymentNumber, setPaymentNumber] = useState<string>()
    const [totalString, setTotalString] = useState<string>()
    const [paymentMethod, setPaymentMethod] = useState<SelectOption>()
    const [shouldSendReceipt, setShouldSendReceipt] = useState(true)
    const [date, setDate] = useState<Date>()
    const [markPaidSending, setMarkPaidSending] = useState<boolean>()

    const payment = useMemo(() => new Payment({
        ...location.paymentData,
        allow_surcharge: false
    }), [location.paymentData])

    useEffect(() => {
        if (payment.mark_paid_date) {
            setDate(moment(payment.mark_paid_date).toDate())
        }
        else {
            setDate(moment().toDate())
        }
    }, [payment.mark_paid_date])

    useEffect(() => {
        let totalStr = 'Total'
        let parts = []
        if (payment.calculateDiscount() > 0) {
            parts.push("discount")
        }
        if (payment.calculateTip() > 0) {
            parts.push("gratuity")
        }
        if (payment.calculateTax() > 0) {
            parts.push("tax")
        }

        if (parts.length) {
            totalStr += ' (incl. of '
            totalStr += andConcat(parts)
            totalStr += ')'
        }
        setTotalString(totalStr)
    }, [payment])

    const [record, setRecord] = useState<Booking | Invoice | Package | GiftCertificate | Estimate | Payment | undefined>();
    useEffect(() => {
        if (!location.record) return

        switch (location.recordType) {
            case 'invoice':
                setRecord(new Invoice(location.record))
                break
            case 'package':
                setRecord(new Package(location.record))
                break
            case 'gift':
                setRecord(new GiftCertificate(location.record))
                break
            case 'booking':
            case 'estimate':
                setRecord(new Booking(location.record))
                break
            default:
                setRecord(new Payment(location.record))
                break
        }
    }, [location.record, location.recordType])


    const [saveEnabled, setSaveEnabled] = useState(false)
    useEffect(() => setSaveEnabled(!!paymentMethod && !!date), [date, paymentMethod])

    const markAction = (recordType: string) => {
        return {
            'booking': markBookingPaid,
            'invoice': markInvoicePaid,
            'package': markPackagePaid,
            'gift': markGiftPaid,
            'estimate': markEstimatePaid,
        }[recordType] || markPaid
    }
    const onSave = useCallback(() => {
        let val: any = record ?? payment
        val.paymeth = paymentMethod?.value ?? undefined
        val.refnum = paymentNumber ?? undefined
        val.mark_paid_date = moment(date).format('YYYY-MM-DD')
        val.gratuity = payment.tip
        val.gratuity_type = payment.tip_type
        val.send_receipt = shouldSendReceipt

        if (!val.nexus) {
            val.tax = ""
            val.taxcode = ""
        }

        const action = markAction(location.recordType || '');
        setMarkPaidSending(true)
        setShowModal(false)
        Result(dispatch(action!(val)))
            .then(json => {
                dispatch(push(`/charge/success`, {
                    charge: json?.charge,
                    payment: json?.payment,
                    booking: json?.booking,
                    estimate: json?.estimate,
                    invoice: json?.invoice,
                    aPackage: json?.package,
                    gift: json?.gift
                }))
            })
            .finally(() => setMarkPaidSending(false))
    }, [date, dispatch, location.recordType, payment, paymentMethod?.value, paymentNumber, record, shouldSendReceipt])

    let formBody: JSX.Element | undefined = undefined;
    if (markPaidSending) {
        <div className="loadingOverlay">
            <div style={{textAlign: 'center', padding: '128px 0'}}>
                <Spinner size="128" color="#314A68" />
                <h1>Marking...</h1>
            </div>
        </div>
    }
    else {
        formBody = <>
            <Field className="field">
                    <PSDropdown selected={paymentMethod}
                                nameProperty="label"
                                onSelect={(selection) => setPaymentMethod(selection)}
                                label="Payment method"
                                hint="Specify how this payment was made and optionally include a check or payment number. "
                                placeholder={<div className="grey">Please Select</div>}>
                        <>
                            {markPaidPaymentOptions.map(option => (
                                <Item key={`payment-method-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                </Field>
            <Field className="field">
                <Label className="label">Payment number</Label>
                <Input placeholder="Enter payment number"
                        value={paymentNumber}
                        onChange={e => setPaymentNumber(e.target.value)} />
            </Field>
            <Field className="field">
                <Label>Transaction date</Label>
                <Datepicker value={date}
                            maxValue={moment().toDate()}
                            onChange={date => setDate(date)}>
                    <Input placeholder="Select date" />
                </Datepicker>
            </Field>
            {!!payment.client && (
                <Field className="field">
                    <Checkbox checked={shouldSendReceipt}
                                onChange={(e) => setShouldSendReceipt(e.target.checked)}>
                        <Label className="withHint">Send receipt</Label>
                        <Hint>Send the client a receipt</Hint>
                    </Checkbox>
                </Field>
            )}
            <div className="separator" />
            <div style={{display: 'flex'}}>
                <div>
                    <div>{totalString}</div>
                    <div style={{fontWeight: 700, fontSize: '20px', lineHeight: '22px'}}>{currencyFormat(Math.max(0, payment.calculateChargeAmount()), true)}</div>
                </div>
                <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}} disabled={!saveEnabled}
                                            onClick={() => setShowModal(true)}>
                                Done

                            </PSButtonPrimary>
            </div>
        </>
    }

    const modalBody = {
        'booking': 'Would you like to mark this appointment as paid?',
        'invoice': 'Would you like to mark this invoice as paid?',
        'package': 'Would you like to mark this package as paid?',
        'gift': 'Would you like to mark this gift certificate as paid?',
        'estimate': 'Would you like to mark this estimate as paid?'
    }[location.recordType || ''] || 'Would you like to mark this charge as paid?'

    const title = {
        'booking': 'Appointment',
        'invoice': 'Invoice',
        'package': 'Package',
        'gift': 'Gift Certificate',
        'estimate': 'Estimate'
    }[location.recordType || ''] || 'Charge'

    const [showModal, setShowModal] = useState(false)
    return (
        <AppHeader title={title} showBackButton middleWidget={null}>
            <div className="servicesSettings" style={{width: '680px'}}>
                <div className="header">
                        <h1>Other payment methods</h1>
                </div>
                <div className="separator" />
                <div className="form">
                    {formBody}
                </div>
            </div>
            {showModal &&
                <Modal onClose={() => setShowModal(false)}>
                    <Header>Mark Paid</Header>
                    <Body>{modalBody}</Body>
                    <Close aria-label="Close modal" />
                    <Footer>
                        <FooterItem>
                            <PSButtonPrimary onClick={onSave}>Mark Paid</PSButtonPrimary>
                        </FooterItem>
                    </Footer>
                </Modal>
            }
        </AppHeader>)
}