import React from "react";
import {
    Dropdown,
    Item,
    Menu,
    Field,
    Multiselect
} from "@zendeskgarden/react-dropdowns";
import {Hint, Label} from "@zendeskgarden/react-forms";
import { Tag } from '@zendeskgarden/react-tags';

type Props = {
    selected: any,
    disabled?: boolean,
    nameProperty: string,
    onSelect: (s: any) => void,
    label: string,
    hint?: string
    placeholder?: React.ReactNode,
    onLearnMore?: () => void,
    children: React.ReactNode,
}

export const PSMultiselect = (props: Props) => {
    const renderTag = ({value, removeValue}: any) => {
        return (
            <Tag>
                <span>{value.label}</span>
                <Tag.Close onClick={() => removeValue() } />
            </Tag>
        )
    }

    return (
        <Dropdown selectedItems={props.selected}
                  onSelect={(selection) => props.onSelect(selection)}
                  downshiftProps={{
                      itemToString: (item: any) => item && item[props.nameProperty],
                      inputId: "psDropdown",
                  }}>
            <Field className="field">
                <Label className="label"
                       style={{paddingBottom: props.hint ? 'inherit' : '14px'}}
                >
                    {props.label}
                </Label>
                {props.hint && (
                    <Hint className="hint">{props.hint}</Hint>
                )}
                <Multiselect className="input"
                             disabled={props.disabled}
                             placeholder={props.placeholder as string}
                             renderItem={renderTag}>
                </Multiselect>
                {props.onLearnMore && (
                    <div className="buttonLink brightBlue"
                         style={{display: 'block', paddingTop: 8}}
                         onClick={props.onLearnMore}>
                        Learn more &gt;
                    </div>
                )}
            </Field>
            <Menu>
                {props.placeholder && (
                    <Item value="">{props.placeholder}</Item>
                )}
                {props.children}
            </Menu>
        </Dropdown>
    )
}
