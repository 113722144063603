import React, {useEffect, useState} from "react"
import {useAppDispatch} from "../../hooks";
import {Redirect, useHistory, useLocation} from "react-router";
import {AppHeader} from "../app/AppHeader";
import {PSButtonPrimary} from "../app/PSButton";
import {Field} from "../../models/Field";
import {OnboardingField} from "./OnboardingField";
import {
    selectField,
    setRefreshData,
    updateForm
} from "../../modules/onboarding";
import {useSelector} from "react-redux";
import {RootState} from "../../store";

export const OnboardingFormEdit = () => {
    const dispatch = useAppDispatch()
    const state: Pick<RootState, "onboarding" | "settings"> = useSelector((state: RootState) => {
        return {
            onboarding: state.onboarding,
            settings: state.settings,
        }
    })
    const history = useHistory()

    useEffect(() => {
        if (state.onboarding.selectedFieldIDs.length > 0) return

        (state.settings.form.selected_fields ?? state.settings.form.fields)?.map((f: any) => {
            if (state.onboarding.selectedFieldIDs.indexOf(f.id) === -1)
                dispatch(selectField(f.id, true))
        })
    }, [state.settings.form.selected_fields, state.settings.form.fields, dispatch])

    const onNextHandler = () => {
        // Update the selected_fields for the parent from that exists in state.onboarding.forms
        state.settings.form.selected_fields = state.settings.form.fields
            ?.filter((f: any) => state.onboarding.selectedFieldIDs.indexOf(f.id!) > -1) ?? []
        state.settings.form.selected_fields = state.settings.form.selected_fields.map((f: any) => {
            const field = new Field()
            field.setData(f)
            return field
        })
        dispatch(updateForm(state.settings.form, false))
        history.goBack()
    }

    if (!state.settings.form) {
        return <Redirect to='/onboarding/forms' />
    }

    return (
        <AppHeader title="Setup" showBackButton middleWidget={null}>
            <div className="scheduleClient servicesSettings onboarding">
                <div className="header">
                    <h1>{state.settings.form.name}</h1>
                    <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                     onClick={() => onNextHandler()}
                    >
                        Next
                    </PSButtonPrimary>
                </div>

                <div className="separator" />

                <div className="form">
                    <div className="text">Attach forms to your offerings to automatically send when clients book, when applicable.</div>
                    {state.settings.form.fields?.map((field: Field) => {
                        const fieldObj = new Field()
                        fieldObj.setData(field)
                        fieldObj.record = state.settings.form.id
                        return (
                            <OnboardingField field={fieldObj}
                                             key={field.id}
                                             isChecked={state.onboarding.selectedFieldIDs.findIndex((id: string) => id === fieldObj.id) > -1}
                                             onToggleItem={(enabled: boolean) => {
                                                 dispatch(selectField(field.id!, enabled))
                                             }} />
                            )
                    })}
                </div>
            </div>
        </AppHeader>
    )
}