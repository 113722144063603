import React, {useCallback, useEffect, useState} from "react";
import {AppHeader} from "../../app/AppHeader";
import {ReactComponent as ClientIcon} from "../../../icons/ps_general_person_nobg.svg";
import {ReactComponent as DollarIcon} from "../../../icons/ps_general_money_nobg.svg";
import {ReactComponent as SubscriptionIcon} from "../../../icons/ps_settings_subscriptions_nobg.svg";
import {PSFormFieldSelection} from "../../app/PSFormFieldSelection";
import {useAppDispatch, usePSOwner, usePSTotalDisplay, usePSUser, useTaxCodeLookup} from "../../../hooks";
import {
    fetchAttachments,
    fetchItems,
    SettingsState
} from "../../../modules/settings";
import {
    syncClient,
    syncContacts,
    clearClientState
} from "../../../modules/contacts"
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {Field, Hint, Label, Input} from "@zendeskgarden/react-forms";
import {Datepicker} from "@zendeskgarden/react-datepickers";
import {PSDropdown} from "../../app/PSDropdown";
import {
    bookingSendViaOptions,
    findSelection,
    findSubscriptionFrequencySelection,
    numberOptions,
    SelectOption,
    subscriptionFrequencyOptions,
    subscriptionReminderOptions,
    subscriptionSendReminderOptions
} from "../../settings/Options";
import {Item} from "@zendeskgarden/react-dropdowns";
import {PSTextarea} from "../../app/PSTextarea";
import {useParams} from "react-router";
import {SelectModal} from "../SelectModal";
import moment from "moment";
import "../transactions.css";
import {PSButtonPrimary} from "../../app/PSButton";
import {TransactionProgress} from "../TransactionProgress";
import {TotalInstance, TotalModal} from "../TotalModal";
import {Spinner} from "@zendeskgarden/react-loaders";
import { TaxcodeInstance } from "../../../models/Taxcode";
import { apiClient } from "../../../modules/apiclient";
import { push } from "connected-react-router";
import { Subscription, useSubscriptionLocationState } from "../../../models/Subscription";
import { fetchSubscription, fetchTaxCodeById, fetchTaxCodeByLocation } from "../../../modules/transactions";
import debounce from "lodash.debounce";
import { isEmpty, reportError } from "../../../utils";

const numberOfPaymentOptions = numberOptions(52, true)
const findNumberOfPaymentOptionsSelection = (value: string) => findSelection(numberOfPaymentOptions, value)

export const SubscriptionView = () => {
    const dispatch = useAppDispatch()
    const locationState = useSubscriptionLocationState()
    const params = useParams<{id?: string}>()
    const isAddNew = !params?.id
    const user = usePSUser()
    const owner = usePSOwner()

    useEffect(() => {
        if (isAddNew) {
            dispatch(clearClientState())
        }
    }, [isAddNew, dispatch])

    const state: {settings: SettingsState, contacts: any, entities: any, records: any} = useSelector((state: RootState) => ({
        settings: state.settings,
        contacts: state.contacts,
        entities: state.entities,
        records: state.records,
    }))

    const [client, setClient] = useState<any>({})
    const [subscription, setSubscription] = useState<Subscription>()
    const [plan, setPlan] = useState<any>()
    const [date, setDate] = useState(moment().add(2, 'hours').minutes(0).seconds(0).toDate())
    const [sendVia, setSendVia] = useState(bookingSendViaOptions[2])
    const [reminder, setReminder] = useState(subscriptionReminderOptions[2])
    const [sendReminder, setSendReminder] = useState<SelectOption>()
    const [frequency, setFrequency] = useState<SelectOption>()
    const [numberPayments, setNumberPayments] = useState<SelectOption>(numberOfPaymentOptions[0])
    const [giftCertificates, setGiftCertificates] = useState<any[]>()
    const [memo, setMemo] = useState('')
    const [form, setForm] = useState<any>('')
    const [contract, setContract] = useState<any>('')
    const [nextEnabled, setNextEnabled] = useState(false)
    const [loading, setLoading] = useState(!isAddNew)
    const [loadingText, setLoadingText] = useState('Loading Subscription')
    const [showPriceModal, setShowPriceModal] = useState(false)
    const [taxCode, setTaxCode] = useState<TaxcodeInstance>()
    const prosTaxCode = useTaxCodeLookup(owner)
    const [totalData, setTotalData] = useState<TotalInstance>()
    const totalDisplay = usePSTotalDisplay(totalData, `Add a price`)
    const [selectModalType, setSelectModalType] = useState<'subscriptions' | 'clients'>()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchGiftCertificates = useCallback(debounce((client: string) => {
        if (!client) return

        setGiftCertificates(undefined)
        return apiClient.post(`/subscription/sync`, { type: 'client', client})
            .then(resp => resp.data)
            .then((json: any) => {
                if (json.gifts) setGiftCertificates(json.gifts)
            })
            .catch(error => reportError('fetchGiftCertificates', { error, client }))
    }, 300), [])

    const getSubscriptions = useCallback(() =>
        state.settings.items.filter((i: any) => i.type === 'subscription') ?? []
    , [state.settings.items])

    const getSourceData = useCallback(() => {
        switch (selectModalType) {
            case 'subscriptions':
                return getSubscriptions()
            case 'clients':
                return state.contacts.contacts ?? []
        }
    }, [selectModalType, getSubscriptions, state.contacts.contacts])

    useEffect(() => {
        dispatch(fetchAttachments())
        dispatch(fetchItems())
        dispatch(syncContacts(''))
    }, [dispatch])

    useEffect(() => {
        if (locationState?.subscription) {
            setSubscription(new Subscription(locationState!.subscription))
        }
    }, [locationState])

    useEffect(() => {
        if (!owner || totalData) return

        setTotalData({
            surchargeEnabled: owner.allow_surcharge
        } as TotalInstance)
    }, [owner, totalData])

    useEffect(() => {
        if (isAddNew || state.settings.items.length === 0) return
        if (locationState?.subscription) {
            const plan = getSubscriptions()?.find(s => locationState?.subscription!.plan === s.id)
            setPlan(plan)
            return
        }

        // @ts-ignore
        dispatch(fetchSubscription(params.id!)).then(subscription => {
            const plan = getSubscriptions()?.find(s => subscription.plan === s.id)

            setSubscription(subscription)
            setPlan(plan)
        })

    }, [dispatch, isAddNew, params?.id, state.settings.items.length, getSubscriptions, locationState])

    useEffect(() => {
        if (client?.id) fetchGiftCertificates(client.id)
    }, [client?.id, fetchGiftCertificates])

    useEffect(() => {
        setClient(!isEmpty(state.contacts.contact) ? state.contacts.contact : locationState?.client)
        setLoading(false)
    }, [locationState?.client, state.contacts.contact])

    const selectedItemFromModal = (item: any[], extraData: any) => {
        const [selected] = item
        switch (selectModalType) {
            case 'clients':
                if (client && client.id === selected?.id) {
                    setClient({})
                }
                else {
                    setLoading(true)
                    dispatch(syncClient(selected.id))
                }
                break
            case 'subscriptions':
                const plan = getSubscriptions().find(s => s.id === selected.id)
                setPlan(plan)
                setSubscription(new Subscription({
                    ...plan,
                    plan: selected.id?.toString(),
                    id: subscription?.id
                } as Partial<Subscription>))
                break
        }
        setSelectModalType(undefined)
    }

    useEffect(() => {
        if (!subscription) return

        setTotalData(currentData => {
            return {
                ...currentData,
                total: Number(subscription.rate || subscription.total),
                discountRate: !currentData?.discountRate ? Number(subscription.discount) : currentData?.discountRate,
                discountType: !currentData?.discountType ? subscription.discount_type : currentData?.discountType,
                discountInstances: subscription.discount_instances,
                surchargeEnabled: subscription.allow_surcharge,
                setupFee: subscription.deposit,
                nexus: subscription.nexus || '',
                taxLocation: subscription.location
            } as TotalInstance
        })

        if (subscription.taxcode && prosTaxCode?.id !== subscription.taxcode) {
            dispatch(fetchTaxCodeById(subscription.taxcode, (taxCode) => {
                setTaxCode(taxCode)
            }))
        }
        else if (subscription.taxcode && prosTaxCode?.id === subscription.taxcode) {
            setTaxCode(prosTaxCode)
        }

        setMemo(subscription.memo)
        setDate(subscription.date ? moment(subscription.date).toDate() : moment().toDate())
        setFrequency(findSubscriptionFrequencySelection(subscription.interval, subscription.frequency) ?? subscriptionFrequencyOptions[0])
        setNumberPayments(findNumberOfPaymentOptionsSelection(subscription.instances?.toString()) ?? numberOfPaymentOptions[0])
        setReminder(subscriptionReminderOptions.find(option => option.value === subscription.reminder) || subscriptionReminderOptions[2])
        setSendReminder(subscriptionSendReminderOptions(isAddNew).find(option => option.value === (!!subscription.reminder_instances ? '1' : '')))
    }, [subscription, getSubscriptions, prosTaxCode, user?.channel, dispatch, isAddNew])

    const findSendViaOption = (val: string) => bookingSendViaOptions.find(option => option.value === val)
    useEffect(()=> {
        if (!isAddNew && subscription?.channel) setSendVia(findSendViaOption(subscription.channel) || bookingSendViaOptions[2])
        else if (state.contacts.client.notify) setSendVia(findSendViaOption(state.contacts.client.notify) || bookingSendViaOptions[2])
        else if (user?.channel) setSendVia(findSendViaOption(user.channel) || bookingSendViaOptions[2])
        else setSendVia(bookingSendViaOptions[2])
    }, [subscription?.channel, user?.channel, state.contacts.client.notify, isAddNew])

    useEffect(() => {
        if (isAddNew && state.contacts.client.discount) {
            setTotalData(currentData => {
                return {
                    ...currentData,
                    discountRate: Number(state.contacts.client.discount),
                    discountType: 'percent'
                } as TotalInstance
            })
        }
    }, [state.contacts.client.discount, isAddNew])

    useEffect(() => {
        if (!subscription?.client) return

        if (isEmpty(state.contacts.contact) || state.contacts.contact.id !== client?.id) dispatch(syncClient(subscription.client))

    }, [subscription?.client, client, state.contacts.contact, dispatch])


    useEffect(() => {
        if (!subscription) return

        if ((totalData?.nexus || subscription.nexus) === 'transaction' && taxCode?.id !== (totalData?.taxcode || subscription.taxcode) && (totalData?.taxLocation || subscription.location)) {
            dispatch(fetchTaxCodeByLocation(totalData?.taxLocation || subscription.location!, (taxCode) => {
                setTaxCode(taxCode)
            }))
        }
    }, [subscription, totalData, dispatch, taxCode?.id])

    useEffect(() => {
        if (!subscription) return

        if (subscription.plan) {
            const p = getSubscriptions().find(s => s.id === subscription.plan)
            if (p) setPlan(p)
        }
    }, [dispatch, subscription, getSubscriptions])

    useEffect(()=> {
        setTotalData(currentData => {
            return {
                ...currentData,
                tax: taxCode?.rate,
                taxcode: taxCode?.id
            } as TotalInstance
        })
    }, [taxCode])

    useEffect(() => {
        if (!subscription) return

        setForm(state.settings.forms.find(form => form.id === subscription.form))
        setContract(state.settings.contracts.find(contract => contract.id === subscription.contract))
        // @ts-ignore
    }, [subscription, state.settings.forms, state.settings.contracts])

    const getCurrentSelectionByType = () => {
        switch (selectModalType) {
            case 'clients': return client ? [client] : []
            case 'subscriptions': return plan ? [plan] : []
            default: return []
        }
    }

    useEffect(() => {
        setNextEnabled(!isEmpty(client) && !isEmpty(subscription) && !isEmpty(totalData) && !!frequency)
    }, [client, subscription, totalData, taxCode, frequency])

    const onNextHandler = () => {
        dispatch(push(isAddNew ? '/subscription/preview' : `/subscription/${params.id!}/preview`, {
            subscription: {
                ...subscription,
                frequency: ((frequency!.value.indexOf('d') || -1) > -1) ? 'daily' : ((frequency!.value.indexOf('w') || -1) > -1) ? 'weekly' : 'monthly',
                instances: Number(numberPayments?.value) || undefined,
                interval: Number(frequency!.value.replace(/\D/g,'')) || 1,
                form: form?.id,
                contract: contract?.id,
                client: client!.id,
                memo,
                date,
                nexus: totalData?.nexus,
                channel: sendVia?.value,
                reminder: reminder?.value,
                reminder_instances: !!sendReminder?.value ? 1 : '',
                total: totalData?.total,
                discount: totalData?.discountRate,
                discount_type: totalData?.discountType,
                discount_instances: totalData?.discountInstances,
                deposit: totalData?.setupFee,
                tax: taxCode?.rate,
                taxcode: taxCode?.id,
                allow_surcharge: totalData?.surchargeEnabled,
                location: totalData?.taxLocation,
                _gift: totalData?.giftCertificate,
                gift: totalData?.giftCertificate?.id,
                lines: [{
                    total: totalData?.total,
                    taxable: taxCode?.rate !== undefined,
                    discountable: (totalData?.discountRate ?? 0) !== 0,
                }]
            },
            client
        }))
    }

    return (
        <AppHeader showBackButton={true} title="Subscriptions" middleWidget={<TransactionProgress created secondStep='Preview' thirdStep='Save' />}>
            {loading && (
                <div className="loadingOverlay">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                        <h1>{loadingText}</h1>
                    </div>
                </div>
            )}
            <div className="scheduleClient servicesSettings">
                <div className="header">
                    <h1>General</h1>
                    <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                     onClick={() => onNextHandler()}
                                     disabled={!nextEnabled}
                    >
                        Next
                    </PSButtonPrimary>
                </div>

                <div className="separator" />

                <div className="form">
                    <PSFormFieldSelection label="Client (required)"
                                          helperLabel="Select a client"
                                          icon={<ClientIcon />}
                                          selection={isEmpty(client) ? undefined : client}
                                          hideButton={!isAddNew}
                                          onClick={() => setSelectModalType('clients')}
                    />
                    <PSFormFieldSelection label="Plan (required)"
                                          helperLabel="Select a plan"
                                          icon={<SubscriptionIcon />}
                                          selection={plan}
                                          hideButton={!isAddNew}
                                          onClick={() => setSelectModalType('subscriptions')}
                    />
                    <Field className='field formFieldSelection'>
                        <div className='icon'><DollarIcon /></div>
                        <div className='labels'>
                            <Label>Price</Label>

                            <Hint>{totalDisplay}</Hint>
                        </div>
                        <div className='action'>
                            <div className='buttonLink brightBlue'
                                style={{display: 'inline-block', paddingBottom: '5px'}}
                                onClick={() => setShowPriceModal(true)}>
                                    {totalData?.total ? 'Edit': 'Add'}
                            </div>
                        </div>
                    </Field>

                    <h2>Schedule</h2>
                    <Field className="fieldWrapper">
                        <Label className="label">Start on</Label>
                        <Hint>Select when the subscription starts</Hint>
                        <Datepicker value={date}
                                    minValue={moment().toDate()}
                                    onChange={date => setDate(date)}>
                            <Input />
                        </Datepicker>
                    </Field>

                    <PSDropdown selected={frequency}
                                nameProperty="label"
                                hint="Set a payment schedule"
                                label="Frequency"
                                placeholder={<div className="grey">Select</div>}
                                onSelect={(selection) => setFrequency(selection)}
                    >
                        <>
                            {subscriptionFrequencyOptions.map(option => (
                                <Item key={`frequency-options-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>

                    <PSDropdown selected={numberPayments}
                                nameProperty="label"
                                hint="Select the number of payments"
                                label="Number of payments"
                                onSelect={(selection) => setNumberPayments(selection)}
                    >
                        <>
                            {numberOfPaymentOptions.map(option => (
                                <Item key={`number-options-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>

                    <h2>Notifications</h2>
                    <PSDropdown selected={sendVia}
                                nameProperty="label"
                                hint="Choose how you'd like your client to be sent this subscription"
                                label="Send via"
                                onSelect={(selection) => setSendVia(selection)}
                    >
                        <>
                            {bookingSendViaOptions.map(option => (
                                <Item key={`booking-send-via-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>

                    <PSDropdown selected={reminder}
                                nameProperty="label"
                                hint="Choose when you'd like your client to receive a reminder ahead of each renewal"
                                label="Payment reminder"
                                onSelect={(selection) => setReminder(selection)}
                    >
                        <>
                            {subscriptionReminderOptions.map(option => (
                                <Item key={`subscription-reminder-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>

                    {!!reminder.value && 
                        <PSDropdown selected={sendReminder}
                                    nameProperty="label"
                                    label="Send reminder"
                                    onSelect={(selection) => setSendReminder(selection)}
                        >
                            <>
                                {subscriptionSendReminderOptions(isAddNew).map(option => (
                                    <Item key={`subscription-reminder-${option.value}`} value={option}>
                                        {option.label}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>
                    }

                    <h2>More options</h2>
                    <Field className="field">
                        <Label className="label">Memo</Label>
                        <PSTextarea placeholder="Add a memo"
                                    className="input"
                                    minRows={5}
                                    maxLength={1500}
                                    value={memo}
                                    onChange={value => setMemo(value)}/>
                    </Field>

                    {owner?.allow_record && !state.settings.loadingAttachments && state.settings.forms.length > 0 && (
                        <PSDropdown selected={form}
                                    nameProperty="name"
                                    onSelect={(selection) => setForm(selection)}
                                    label="Requires form"
                                    placeholder={<div className="grey">Select a form</div>}
                        >
                            <>
                                {state.settings.forms.map(option => (
                                    <Item key={`form-${option.id}`} value={option}>
                                        {option.name}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>
                    )}

                    {owner?.allow_record && !state.settings.loadingAttachments && state.settings.contracts.length > 0 && (
                        <PSDropdown selected={contract}
                                    nameProperty="name"
                                    onSelect={(selection) => setContract(selection)}
                                    label="Requires signature"
                                    placeholder={<div className="grey">Select a contract</div>}
                        >
                            <>
                                {state.settings.contracts.map(option => (
                                    <Item key={`contract-${option.id}`} value={option}>
                                        {option.name}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>
                    )}

                </div>

                {selectModalType && (
                    <SelectModal type={selectModalType}
                                 data={getSourceData()}
                                 currentSelections={getCurrentSelectionByType()}
                                 currentDate={date}
                                 onSelect={(item: any[], extraData: any) => selectedItemFromModal(item, extraData) }
                                 onCancel={() => setSelectModalType(undefined) } />
                )}

                {showPriceModal && (
                    <TotalModal
                        showGiftCertificates requireTaxLocation showSetupFee showDiscountInstances
                        currentData={totalData}
                        taxCode={prosTaxCode}
                        giftCertificates={giftCertificates || []}
                        onDone={(data: TotalInstance) => {
                            if (data.taxLocation && data.taxLocation !== totalData?.taxLocation && data.nexus === 'transaction') {
                                setLoadingText(`Loading Tax Info`)
                                setLoading(true)
                                dispatch(fetchTaxCodeByLocation(data.taxLocation, (taxCode) => {
                                    setTaxCode(taxCode)
                                    setLoading(false)
                                }))
                            }
                            else if (data.nexus === 'pro' && user?.taxcode) {
                                setTaxCode(prosTaxCode)
                            }

                            setTotalData(data)
                            setShowPriceModal(false)
                        }}
                        onCancel={() => setShowPriceModal(false)}
                        />
                )}
            </div>
        </AppHeader>
    )
}