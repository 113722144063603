export const decoratedDelta = (delta) => {
    if (delta === '0') return ''
    const prefix = delta > 0 ? '▲' : '▼'
    return `${prefix} ${Math.abs(delta*100).toFixed(1)}%`
}

export const abbreviatedDollar = (amount) => {
    if (amount < 1_000)
        return `$${amount}`
    else if (amount < 100_000)
        return `$${(amount/1_000).toFixed(1)}K`
    else if (amount < 1_000_000)
        return `$${(amount/1_000).toFixed(0)}K`
    return `$${(amount/1_000_000).toFixed(2)}M`
}

// Use half even rounding, which is not supported by Intl.NumberFormat
// https://en.wikipedia.org/wiki/Rounding#Round_half_to_even
export const currencyFloat = (n) => {
    if (typeof n === 'undefined') return n
    const str = Number(n).toFixed(4)

    if (Number(str) < 0)
        return -1 * currencyFloat(-n)

    const numberOfDecimalDigits = 2
    const isEven = (d) => d % 2 === 0

    const decimalIndex = str.indexOf('.')
    if (decimalIndex < 0)
        return Number(n)

    const integerPortion = Number(str.slice(0, decimalIndex))

    let decimalPortion = str.slice(decimalIndex + 1)
    if (decimalPortion.length <= numberOfDecimalDigits)
        return Number(n)

    // Save the decimal portion we care about
    const newDecimalPortion = decimalPortion.slice(0, numberOfDecimalDigits)
    const completedNumber = Number(`${integerPortion}.${newDecimalPortion}`)

    const lastDigit = Number(decimalPortion[numberOfDecimalDigits])
    // If the last digit is below 5, there is no need to round up, just truncate
    if (lastDigit < 5) {
        return completedNumber
    }

    // Round up
    if (lastDigit > 5) {
        const decimalWithExtraDigit = decimalPortion.slice(0, numberOfDecimalDigits+1)
        const number = Number(`${integerPortion}.${decimalWithExtraDigit}`)
        return Number(number.toFixed(numberOfDecimalDigits))
    }

    // Tie break time

    // Get the digit before lastDigit to determine if we round up when it is odd
    // or if we do nothing, when it is even
    const digitBeforeLastDigit = Number(decimalPortion[numberOfDecimalDigits - 1])
    if (isEven(digitBeforeLastDigit)) {
        return completedNumber
    }

    // number before is odd, round up
    const number = completedNumber + 0.01
    return Number(number.toFixed(numberOfDecimalDigits))
}

export const currencyFormat = (amount, alwaysShowDecimals = false, round = false) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })
    const str = formatter.format(currencyFloat(round ? Math.round(amount) : amount))
    if (!alwaysShowDecimals && str.endsWith('.00')) {
        return str.substring(0, str.length -3)
    }
    return str
}

export const shiftThirdDecimal = function(value) {
    const monetaryFilter = /^(\d+)?\.\d{3,}$/
    if (value && monetaryFilter.test(value)) {
        value = value.replace('.','')
        value = value.slice(0, value.length -2) + '.' + value.slice(value.length -2, value.length)
    }
    return value
}

export const filterMonetaryInput = (value, setterCallback) => {
    const monetaryFilter = /^\d+(\.\d{0,2})?$/
    if (value && !monetaryFilter.test(value)) return

    const inputFilter = /[^0-9.]+/
    setterCallback(value.replace(inputFilter, ''))
}

export const filterNumericInput = (value, setterCallback) => {
    const inputFilter = /[^0-9]/
    setterCallback(value.replace(inputFilter, ''))
}

export const filterDecimalInput = (value, setterCallback) => {
    const inputFilter = /[^0-9.]/
    setterCallback(value.replace(inputFilter, ''))
}

export const generateRandomID = () => {
    const crypto = window.crypto || window.msCrypto
    const randomInt = crypto.getRandomValues(new Uint32Array(1))[0]
    return randomInt.toString(16)
}