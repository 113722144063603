import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../hooks";
import {
    saveSource,
    SettingsState
} from "../../../modules/settings";
import {RootState} from "../../../store";
import {useSelector} from "react-redux";
import {Spinner} from "@zendeskgarden/react-loaders";
import {Field, Hint, Input, IInputProps, Label, Message, Toggle} from "@zendeskgarden/react-forms";
import {Prompt, useHistory} from "react-router";
import {PSButtonPrimary} from "../../app/PSButton";
import {Dots} from "@zendeskgarden/react-loaders";
import localforage from 'localforage'
import { VALIDATION } from "@zendeskgarden/react-forms/dist/typings/utils/validation";
import InputMask from 'react-input-mask'


export const CheckingEdit = () => {
    const dispatch = useAppDispatch()
    const history = useHistory()

    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const [user, setUser] = useState<any>()

    const [name, setName] = useState('')
    const [accountNumber, setAccountNumber] = useState('')
    const [routingNumber, setRoutingNumber] = useState('')
    const [allowPayment, setAllowPayment] = useState(false)

    const [saveEnabled, setSaveEnabled] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)

    const [saving, setSaving] = useState(false)

    const [accountNumberValidation, setAccountNumberValidation] = useState<VALIDATION | undefined>()
    const [routingNumberValidation, setRoutingNumberValidation] = useState<VALIDATION | undefined>()
    const [nameValidation, setNameValidation] = useState<VALIDATION | undefined>()

    useEffect(() => {
        setSaveEnabled(!accountNumberValidation && !routingNumberValidation && !nameValidation && !!accountNumber?.length && !!routingNumber?.length && !!name?.length)
    }, [accountNumberValidation, routingNumberValidation, nameValidation, accountNumber, routingNumber, name])

    useEffect(() => {
        localforage.getItem('user').then((user: any) => setUser(user))
    }, [])

    useEffect(() => {
        if (accountNumberValidation)
            setAccountNumberValidation(accountNumber?.length >= 6 && accountNumber?.length <= 18 ? undefined : 'error')
    }, [accountNumber, accountNumberValidation])

    useEffect(() => {
        if (routingNumberValidation)
            setRoutingNumberValidation(routingNumber?.length === 9 ? undefined : 'error')
    }, [routingNumber, routingNumberValidation])

    useEffect(() => {
        if (nameValidation)
            setNameValidation(name ? undefined : 'error' )
    }, [name, nameValidation])

    useEffect(() => {
        if (routingNumber?.length || accountNumber?.length || name?.length )
            setHasEdits(true)
    }, [routingNumber, accountNumber, name])

    const onSave = () => {
        if (saving) return

        let validationError = false

        if (accountNumber?.length < 6 || accountNumber?.length > 18 ) {
            setAccountNumberValidation('error')
            validationError = true
        }

        if (!name) {
            setNameValidation('error')
            validationError = true
        }

        if (routingNumber?.length !== 9) {
            setRoutingNumberValidation('error')
            validationError = true
        }

        if (validationError) {
            return;
        }

        setSaving(true)

        const sourceParams: any = {}
        sourceParams.type = 'checking'
        sourceParams.account_number = accountNumber
        sourceParams.routing_number = routingNumber
        sourceParams.allow_payment = allowPayment
        sourceParams.name = name

        dispatch(saveSource(sourceParams))
            //@ts-ignore
            .then(() => {
                setHasEdits(false)
                history.goBack()
                setSaving(false)
            })
    }

    if (state.isSending || !user) {
        return (
            <div className="servicesSettings">
                <div className="header">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Add a checking account</h1>
                <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!saveEnabled || state.isSending}>
                    {saving && <Dots />}
                    {!saving && "Save"}
                </PSButtonPrimary>
            </div>
                <div className="form">
                    <Field className="field">
                        <Label className="label">Routing #</Label>
                        <InputMask mask="999999999"
                               value={routingNumber}
                               alwaysShowMask={false}
                               maskChar={''}
                               disabled={state.isSending}
                               onChange={e => setRoutingNumber(e.target.value)}>
                               {(inputProps: IInputProps) => <Input {...inputProps}
                                   placeholder="123 456 789"
                                   disabled={state.isSending}
                                   validation={routingNumberValidation}
                                   />}
                        </InputMask>
                        {!!routingNumberValidation && (
                            <Message validation={routingNumberValidation}>Routing number must have 9 digits.</Message>
                        )}
                    </Field>

                    <Field className="field">
                        <Label className="label">Account #</Label>
                        <InputMask mask="999999999999999999"
                               value={accountNumber}
                               alwaysShowMask={false}
                               maskChar={''}
                               disabled={state.isSending}
                               onChange={e => setAccountNumber(e.target.value)}>
                               {(inputProps: IInputProps) => <Input {...inputProps}
                                   placeholder="12345 12345 12345"
                                   disabled={state.isSending}
                                   validation={accountNumberValidation}
                                   />}
                        </InputMask>
                        {!!accountNumberValidation && (
                            <Message validation={accountNumberValidation}>US Bank Account Number must have between 6 and 18 digits.</Message>
                        )}
                    </Field>
                    <Field className="field">
                    <Label className="lable">Name on account</Label>
                    <Input placeholder="John Doe"
                           value={name}
                           disabled={state.isSending}
                           onChange={e => setName(e.target.value)} />
                    {!!nameValidation && (
                        <Message validation={nameValidation}>A name is required</Message>
                    )}
                    </Field>

                    {(user?.type === 'company' &&
                        <Field className="field">
                            <Toggle checked={allowPayment}
                                disabled={state.isSending || saving}
                                onChange={e => {setAllowPayment(e.target.checked)}}>
                                <Label className="label">
                                    Pay with ACH
                                </Label>
                            </Toggle>
                            <Hint>Lets you pay Pros from this account. To verify your account two small deposits will be sent to this account. May take upto 3 days to appear.</Hint>
                        </Field>
                    )}
                </div>
            <Prompt
                when={hasEdits && !saving}
                message="Are you sure you'd like to leave this page without saving your changes?" />
        </div>
    )
}
