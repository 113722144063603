import React, {useEffect, useState} from "react";
import {Redirect, useHistory, useLocation} from "react-router";
import {useAppDispatch} from "../../../hooks";
import { AppHeader } from "../../app/AppHeader";
import {TransactionProgress} from "../TransactionProgress";
import {PSButton, PSButtonDanger, PSButtonPrimary} from "../../app/PSButton";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {apiClient} from "../../../modules/apiclient";
import keyBy from "lodash.keyby";
import moment from "moment";
import {currencyFloat, currencyFormat} from "../../../utils/numbers";
import {fetchCustomFields} from "../../../modules/settings";
import {Field} from "../../../models/Field";
import {fetchPros, sendBooking, sendClass} from "../../../modules/transactions";
import { Link } from "react-router-dom";
import {Spinner} from "@zendeskgarden/react-loaders";
import {ItemSummaryModal} from "../ItemSummaryModal";
import {WarningModal} from "../../app/WarningModal";
import {ErrorModal} from "../../app/ErrorModal";
import {
    Body, Close,
    Header,
    Modal
} from "@zendeskgarden/react-modals";
import {scheduleDescription} from "../../../models/Schedule";
import {clearRecordData} from "../../../modules/records";
import {push} from "connected-react-router";
import { Result } from "../../../utils";
import { Transaction } from "../../../models/Transaction";

export const ScheduleConfirm = () => {
    const dispatch = useAppDispatch()
    const state: any = useSelector((state: RootState) => ({
        settings: state.settings,
        contacts: state.contacts,
        entities: state.entities,
    }))

    const location = useLocation()
    const locationState: any = location.state
    const scheduleData: any = locationState?.schedule
    const lessonData: any = locationState?.lesson
    const packageData: any = locationState?.package
    const itemData: any = locationState?.item

    const history = useHistory()

    // Some modals that can be shown here
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [showCancelModal, setShowCancelModal] = useState(false)
    const [showMemoModal, setShowMemoModal] = useState(false)
    const [showTeamMembersModal, setShowTeamMembersModal] = useState(false)

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        // Load all the objects that we need from the API to render this view
        const promises = []
        const p1 = apiClient.post('/item/sync')
            .then(resp => resp.data)
            .then(json => {
                const itemMap = keyBy(json.items, 'id')
                dispatch({type: 'entities/UPDATE', name: 'items', map: itemMap})
            })
        promises.push(p1)
        promises.push(dispatch(fetchCustomFields()) as unknown as PromiseLike<any>)
        promises.push(dispatch(fetchPros()) as unknown as PromiseLike<any>)
        Promise.all(promises).finally(() => setLoading(false))
    }, [dispatch])

    const [client, setClient] = useState<any>()
    useEffect(() => {
        // Fetch from server
        if (scheduleData.client) {
            apiClient.post('/client/sync', {person: scheduleData.client})
                .then(resp => resp.data)
                .then(json => {
                    if (json.contacts?.length > 0)
                        setClient(json.contacts[0])
                })
        }
    }, [scheduleData?.client])

    const [scheduleDisplay, setScheduleDisplay] = useState('')
    useEffect(() => {
        if (lessonData?.schedule_instance && !lessonData.dropin) {
            setScheduleDisplay(`${lessonData.schedule_instance.instances} classes`)
            return
        }

        if (scheduleData.date_values.length < 1 && scheduleData.dow === 0) {
            setScheduleDisplay('')
            return
        }

        if (scheduleData.date_values.length > 0) {
            const datesDisplay = scheduleData.date_values.map((d: string) => moment(d).format('M/D'))
            setScheduleDisplay(`Dates ${datesDisplay.join(', ')}`)
        }
        else if (scheduleData.dow > 0) {
            setScheduleDisplay(scheduleDescription(scheduleData))
        }
    }, [lessonData, scheduleData])

    const [services, setServices] = useState<any[]>([])
    const [products, setProducts] = useState<any[]>([])
    useEffect(() => {
        if (Object.keys(state.entities.items).length === 0) return

        const serviceList = scheduleData.lines
            .filter((lineItem: any) => lineItem.itemType === 'service' || lineItem.type === 'item')
            .map((lineItem: any) => state.entities.items[lineItem.item])
            .filter((item: any) => item.type === 'service')
        setServices(serviceList)

        const productList = scheduleData.lines
            .filter((lineItem: any) => lineItem.itemType === 'product' || lineItem.type === 'item')
            .map((lineItem: any) => state.entities.items[lineItem.item])
            .filter((item: any) => item.type === 'product')
        setProducts(productList)
    }, [scheduleData?.lines, state.entities.items])

    const [showItemModal, setShowItemModal] = useState(false)

    const [isSending, setIsSending] = useState(false)
    const onSaveBooking = (notify: boolean) => {
        if (isSending) return

        if (!notify) scheduleData.dont_notify = true

        setIsSending(true)
        if (!lessonData) {
            Result(dispatch(sendBooking(scheduleData))).then(() => {
                dispatch(clearRecordData())
                setIsSending(false)
            })
        }
        else {
            Result(dispatch(sendClass(lessonData.id, {
                    ...scheduleData,
                    item: lessonData.item,
                    lessons: lessonData.dropin ? [lessonData.id] : []
                }))).then(() => {
                dispatch(clearRecordData())
                setIsSending(false)
            })
        }
    }

    // Confirm we have schedule data to use
    if (!scheduleData?.client) {
        return <Redirect to="/schedule/new" />
    }

    const startDate = moment(scheduleData.date)
    let endDate
    if (scheduleData.duration)
        endDate = ` - ${startDate.clone().add(scheduleData.duration, 'minutes').format('h:mm a')}`

    let discountDescription: string
    let discountAmount: number
    const paymentInfo = new Transaction({...scheduleData} as Partial<Transaction>)
    const total = paymentInfo.calculateTotal()
    if (scheduleData.discount_type === 'percent' && Number(scheduleData.discount)) {
        discountDescription = `Discount (${scheduleData.discount}%)`
        discountAmount = paymentInfo.calculateDiscount()
    }
    else if (scheduleData.discount_type === 'rate' && Number(scheduleData.discount)) {
        discountDescription = `Discount (${currencyFormat(scheduleData.discount)})`
        discountAmount = paymentInfo.calculateDiscount()
    }
    else {
        discountDescription = ''
        discountAmount = 0
    }

    const netAmount = total - discountAmount
    const taxAmount = paymentInfo.calculateTax()
    const surcharge = paymentInfo.calculateSurcharge()
    const amountToCharge = paymentInfo.calculateChargeAmount()
    let totalDisplay = <>{currencyFormat(amountToCharge)} Total</>

    if (scheduleData.package && amountToCharge === 0)
        totalDisplay = <>Prepaid Package</>
    else if (scheduleData.package)
        totalDisplay = <>Prepaid Package<br/>{totalDisplay}</>

    return (
        <AppHeader title="Schedule client"
                   showBackButton
                   onBack={() => history.replace('/schedule/new', {schedule: scheduleData, item: itemData, package: packageData, lesson: lessonData})}
                   middleWidget={<TransactionProgress created reviewed />}
        >
            {isSending && (
                <div className="loadingOverlay">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                        <h1>Saving appointment</h1>
                    </div>
                </div>
            )}
            <div className="scheduleClient servicesSettings">
                <div className="header">
                    <h1>Review</h1>
                </div>

                <div className="separator" />

                <div className="scheduleClientReview">
                    <div className="details">
                        <div className="date">{startDate.format('dddd, MMM Do YYYY')}</div>
                        <div className="time">{startDate.format('h:mm a')}{endDate}</div>
                        <div className="receipt">
                            <div className="total">{totalDisplay}</div>
                            {!scheduleData?.package && (
                                <div className="lineItem final">
                                    <div className="name">Subtotal</div>
                                    <div className="amount">{currencyFormat(netAmount)}</div>
                                </div>
                            )}
                            {Number(scheduleData.deposit) > 0 && (
                                <div className="lineItem">
                                    <div className="name">Deposit</div>
                                    <div className="amount">{currencyFormat(scheduleData.deposit)}</div>
                                </div>
                            )}
                            {!scheduleData?.package && discountDescription && (
                                <div className="lineItem">
                                    <div className="name">{discountDescription}</div>
                                    <div className="amount">-{currencyFormat(discountAmount)}</div>
                                </div>
                            )}
                            {!scheduleData?.package && (
                                <div className="lineItem">
                                    <div className="name">Net amount</div>
                                    <div className="amount">{currencyFormat(netAmount)}</div>
                                </div>
                            )}
                            {scheduleData.tax && taxAmount > 0 && (
                                <div className="lineItem">
                                    <div className="name">Sales tax</div>
                                    <div className="amount">{currencyFormat(taxAmount)}</div>
                                </div>
                            )}
                            {surcharge > 0 && (
                                <div className="lineItem">
                                    <div className="name">Surcharge (3%)</div>
                                    <div className="amount">{currencyFormat(surcharge)}</div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="actions">
                        {scheduleData.channel && (
                            <>
                                <PSButtonPrimary onClick={() => setShowConfirmModal(true)} disabled={loading}>
                                    Send
                                </PSButtonPrimary>
                                <PSButton style={{border: '1px solid #e6e6e6'}} disabled={loading}
                                          onClick={() => setShowSaveModal(true)}
                                >
                                    Save
                                </PSButton>
                            </>
                        )}
                        {!scheduleData.channel && (
                            <PSButtonPrimary onClick={() => setShowSaveModal(true)} disabled={loading}>
                                Save
                            </PSButtonPrimary>
                        )}
                        <PSButtonDanger onClick={() => setShowCancelModal(true)} disabled={loading}>
                            Cancel
                        </PSButtonDanger>
                    </div>

                    <div className="fields">
                        {loading && (
                            <div style={{textAlign: 'center'}}>
                                <Spinner size="128" color="#314A68" />
                            </div>
                        )}
                        {!loading && (
                            <>
                                {scheduleDisplay && (
                                    <div className="field">
                                        <div className="data">
                                            <div className="label">Schedule</div>
                                            <div className="value">{scheduleDisplay}</div>
                                        </div>
                                    </div>
                                )}
                                {client?.id && (
                                    <div className="field">
                                        <div className="data">
                                            <div className="label">Client</div>
                                            <div className="value">{client.name}</div>
                                        </div>
                                        <div className="action">
                                            <Link to={`/record/client/${client.id}`}>View</Link>
                                        </div>
                                    </div>
                                )}
                                {services.length > 0 && (
                                    <div className="field">
                                        <div className="data">
                                            <div className="label">Services</div>
                                            <div className="value">
                                                {services.map((item: any) => item.name).join(', ')}
                                            </div>
                                        </div>
                                        <div className="action">
                                            <button className="buttonLink"
                                                    onClick={() => setShowItemModal(true)}
                                            >
                                                View
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {products.length > 0 && (
                                    <div className="field">
                                        <div className="data">
                                            <div className="label">Products</div>
                                            <div className="value">
                                                {products.map((item: any) => item.name).join(', ')}
                                            </div>
                                        </div>
                                        <div className="action">
                                            <button className="buttonLink"
                                                    onClick={() => setShowItemModal(true)}
                                            >
                                                View
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {scheduleData.memo && (
                                    <div className="field">
                                        <div className="data">
                                            <div className="label">Description</div>
                                            <div className="value">{scheduleData.memo}</div>
                                        </div>
                                        <div className="action">
                                            <button className="buttonLink"
                                                    onClick={() => setShowMemoModal(true)}
                                            >
                                                View
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {scheduleData.location && (
                                    <div className="field">
                                        <div className="data">
                                            <div className="label">Location</div>
                                            <div className="value">{scheduleData.location}</div>
                                        </div>
                                    </div>
                                )}
                                {scheduleData.jobs?.length > 0 && (
                                    <div className="field">
                                        <div className="data">
                                            <div className="label">Team member{scheduleData.jobs.length > 1 ? 's' : ''}</div>
                                            <div className="value">
                                                {scheduleData.jobs.map((job: any) => {
                                                    return state.entities.users[job.person]?.name
                                                }).join(', ')}
                                            </div>
                                        </div>
                                        <div className="action">
                                            <button className="buttonLink"
                                                    onClick={() => setShowTeamMembersModal(true)}
                                            >
                                                View
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>

                    {Object.keys(scheduleData.field_values || {}).length > 0 && state.settings.fields.length > 0 && (
                        <div className="fields">
                            {Object.keys(scheduleData.field_values).map(fieldName => {
                                const field = state.settings.fields.find((f: Field) => f.name === fieldName)
                                if (!field) return null
                                let value = scheduleData.field_values[fieldName]
                                let renderValue = ''
                                if (Array.isArray(field.options) && field.options.length > 0)
                                    if (Array.isArray(value))
                                        renderValue = field.options.filter((o: any) => value.indexOf(o.value) !== -1).map((o: any) => o.label).join(', ')
                                    else
                                        renderValue = field.options.filter((o: any) => o.value === value).map((o: any) => o.label).join(', ')
                                else
                                    renderValue = value

                                return (
                                    <div className="field" key={`cf-${fieldName}`}>
                                        <div className="data">
                                            <div className="label">{field?.label}</div>
                                            <div className="value">{renderValue}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>
                {showConfirmModal && (
                    <WarningModal title="Confirm appointment"
                                  message="Select whether you'd like to confirm this appointment or go back and make some changes."
                                  buttonText="Confirm appointment"
                                  onClose={() => setShowConfirmModal(false)}
                                  onButtonClick={() => {
                                      setShowConfirmModal(false)
                                      onSaveBooking(true)
                                  }}
                    />
                )}
                {showSaveModal && (
                    <WarningModal title="Save appointment"
                                  message="Select whether you'd like to save this appointment or go back and make some changes."
                                  buttonText="Save appointment"
                                  onClose={() => setShowSaveModal(false)}
                                  onButtonClick={() => {
                                      setShowSaveModal(false)
                                      onSaveBooking(false)
                                  }}
                    />
                )}
                {showCancelModal && (
                    <ErrorModal title="Cancel appointment"
                                message="Would you like to cancel this appointment?"
                                buttonText="Cancel appointment"
                                onClose={() => setShowCancelModal(false)}
                                onButtonClick={() => {
                                    setShowCancelModal(false)
                                    dispatch(push('/'))
                                }}
                    />
                )}
                {showMemoModal && (
                    <Modal className="modal" onClose={() => setShowMemoModal(false)}>
                        <Header>
                            <div className="title">Description</div>
                        </Header>
                        <Body className="body">
                            <div style={{whiteSpace: 'pre-wrap'}}>
                                {scheduleData.memo}
                            </div>
                        </Body>
                        <Close aria-label="Close modal" />
                    </Modal>
                )}
                {showItemModal && (
                    <ItemSummaryModal lineItems={scheduleData.lines}
                                      title="Items"
                                      onCancel={() => setShowItemModal(false)}
                    />
                )}
                {showTeamMembersModal && (
                    <ItemSummaryModal jobs={scheduleData.jobs}
                                      title="Team members"
                                      onCancel={() => setShowTeamMembersModal(false)}
                    />
                )}
            </div>
        </AppHeader>
    )
}