import React, {useState} from "react"
import {AppHeader} from "../app/AppHeader";
import {useAppDispatch, usePSUser} from "../../hooks";
import animation from "../../icons/confetti_desktop.json"
import Lottie from "lottie-react";
import { push } from "connected-react-router";
import {widgetURL} from "../../utils/urls";

export const OnboardingSetupSuccess = () => {
    const dispatch = useAppDispatch()
    const user = usePSUser()

    const [confettiVisible, setConfettiVisible] = useState(true)

    return (
        <AppHeader title="Setup" showBackButton middleWidget={null}>
            {confettiVisible && (
                <Lottie style={{position: 'absolute', width: '100%'}} animationData={animation}
                        loop={false}
                        onClick={() => setConfettiVisible(false)}
                        onComplete={() => setConfettiVisible(false)}
                />
            )}
            <div className="scheduleClient servicesSettings onboarding onboardingSuccess">
                <div className="wrapper">
                <div className="header">
                    <h1>Congratulations!</h1>
                </div>

                <div className="form">
                    <div className="text">You're on your way!</div>
                    <img alt="Congrats!" src="/congrats.png" width="336" height="360" />
                    <div className="serviceCard onboardingCard">
                        <div className="details">
                            <div className="name">Go Live! 🚀</div>
                            <div className="rate">Grow your business on PocketSuite with just a few quick steps</div>
                        </div>
                        <div className="edit">
                            <div className={`buttonLink brightBlue`}
                                 onClick={() => {
                                     dispatch(push('/golive/welcome'))
                                 }}
                            >
                                Select
                            </div>
                        </div>
                    </div>
                    <div className="serviceCard onboardingCard">
                        <div className="details">
                            <div className="name">Preview my booking site</div>
                            <div className="rate">Preview what your clients will see when booking you online </div>
                        </div>
                        <div className="edit">
                            <div className={`buttonLink brightBlue`}
                                 onClick={() => {
                                     window.open(widgetURL({user}), '_ps-web-booking')
                                 }}
                            >
                                Select
                            </div>
                        </div>
                    </div>
                    <div className="serviceCard onboardingCard">
                        <div className="details">
                            <div className="name">Dashboard</div>
                        <div className="rate">View your PocketSuite homescreen</div>
                        </div>
                        <div className="edit">
                            <div className={`buttonLink brightBlue`}
                                 onClick={() => dispatch(push('/'))}
                            >
                                Select
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </AppHeader>
    )
}
