import React, {useEffect, useState} from "react";
import {
    Header,
    Modal,
    Body,
    Footer,
    FooterItem, Close
} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import {Checkbox, Field, Hint, Input, Label} from "@zendeskgarden/react-forms";
import {filterMonetaryInput} from "../../utils/numbers";
import {PSDropdown} from "../app/PSDropdown";
import {
    discountOptions, invoicePaymentOptions, numberOptions, SelectOption,
    taxCodeOptions
} from "../settings/Options";
import {
    Dropdown,
    Item,
    Field as DropdownField,
    Select, Menu
} from "@zendeskgarden/react-dropdowns";
import { PSToggleButton } from "../app/PSButton";
import {TaxcodeInstance} from "../../models/Taxcode";
import {LocationAutocomplete} from "../app/LocationAutocomplete";
import { TipType } from "../../models/Transaction";
import { usePSOwner } from "../../hooks";

export type TotalInstance = {
    total: number,
    deposit: number,
    setupFee?: number,
    giftCertificate: any,
    discountType: TipType,
    discountRate: number,
    discountInstances?: number,
    surchargeEnabled: boolean,
    tax: number | '',
    taxcode: string,
    nexus: 'pro' | 'transaction' | '',
    taxLocation?: string,
    paymentPlan?: string,
}

type Props = {
    currentData?: TotalInstance,
    taxCode?: TaxcodeInstance,
    showPaymentPlan?: boolean,
    paymentPlanReadOnly?:boolean,
    showDeposit?: boolean,
    showSetupFee?: boolean,
    showDiscountInstances?: boolean,
    showGiftCertificates?: boolean,
    giftCertificates?: any[],
    totalReadOnly?: boolean,
    requireTaxLocation?: boolean,
    onDone: (data: TotalInstance) => void,
    onCancel: () => void,
}

export const TotalModal = ({
                               currentData, taxCode, showPaymentPlan, showDiscountInstances,
                               showDeposit, showSetupFee, showGiftCertificates,
                               giftCertificates, totalReadOnly, paymentPlanReadOnly,
                               requireTaxLocation,
                               onDone, onCancel,
                           }: Props) => {
    const discountInstanceOptions = numberOptions(52, true)
    const [total, setTotal] = useState(currentData?.total || '')
    const [deposit, setDeposit] = useState(currentData?.deposit || '')
    const [setupFee, setSetupFee] = useState(currentData?.setupFee)
    const [giftCertificate, _setGiftCertificate] = useState(currentData?.giftCertificate)
    const [discountType, setDiscountType] = useState<'rate' | 'percent'>(currentData?.discountType || 'percent')
    const [discountRate, setDiscountRate] = useState<SelectOption>()
    const [discountInstances, setDiscountInstances] = useState<SelectOption>(currentData?.discountInstances ?
        discountInstanceOptions.find(option => option.value === currentData.discountInstances?.toString()) || discountInstanceOptions[0] :
        discountInstanceOptions[0]
    )
    const [fixedDiscount, setFixedDiscount] = useState(currentData?.discountRate || '')
    const [salesTaxType, setSalesTaxType] = useState(taxCodeOptions[0])
    const [surcharge, setSurcharge] = useState(currentData?.surchargeEnabled || false)
    const [location, setLocation] = useState(currentData?.taxLocation || '')
    const [paymentPlan, setPaymentPlan] = useState<SelectOption>()
    const setGiftCertificate = (gift: any) => {
        if (gift?.balance) {
            setFixedDiscount(Number(gift?.balance))
            setDiscountType('rate')
        }
        else {
            setDiscountType('percent')
            setDiscountRate(undefined)
        }
        _setGiftCertificate(gift)
    }

    useEffect(() => {
        if (currentData?.discountType === 'percent') {
            const selected = discountOptions.find(option => option.value === currentData.discountRate?.toString())
            setDiscountRate(selected)
        }
    }, [currentData?.discountRate, currentData?.discountType])

    const [taxOptions, setTaxOptions] = useState(taxCodeOptions.slice())
    useEffect(() => {
        const options = taxCodeOptions.slice()
        if (taxCode && options[1].label.indexOf(Number(taxCode.rate).toString()) === -1) {
            options[1].label += ` · ${Number(taxCode.rate)}%`
            setTaxOptions(options)
        }
    }, [taxCode])

    useEffect(() => {
        const selected = invoicePaymentOptions.find(option => option.value === (currentData?.paymentPlan ?? ''))
        setPaymentPlan(selected)
    }, [currentData?.paymentPlan])

    const owner = usePSOwner()
    useEffect(() => {
        if (!currentData) setSurcharge(owner?.allow_surcharge || false)
    }, [owner?.allow_surcharge, currentData])

    useEffect(() => {
        // Set the passed SelectOptions
        const selectedTaxType = taxOptions.find((option) => option.value === currentData?.nexus)
        setSalesTaxType(selectedTaxType || taxCodeOptions[0])
    }, [currentData, currentData?.nexus, taxOptions])

    const priceValidator = () => {
        if (Number(deposit) > Number(total))
            setTotal(deposit)
    }

    const onDoneHandler = () => {
        const totalData: TotalInstance = {
            total: Number(total),
            deposit: Number(deposit),
            setupFee: setupFee ? Number(setupFee) : undefined,
            giftCertificate,
            discountType,
            discountRate: Number(discountRate?.value || 0),
            discountInstances: discountInstances ? Number(discountInstances.value) : undefined,
            surchargeEnabled: surcharge,
            tax: 0,
            taxcode: '',
            nexus: salesTaxType?.value as ('pro' | 'transaction' | ''),
            taxLocation: location,
            paymentPlan: paymentPlan?.value ?? '',
        }
        if (discountType === 'rate')
            totalData.discountRate = Number(fixedDiscount)
        if (!owner?.allow_salestax) {
            totalData.tax = 0;
            totalData.taxcode = ''
            totalData.nexus = ''
        }
        else if (salesTaxType?.value === 'pro' && taxCode) {
            totalData.tax = Number(taxCode.rate) || 0
            totalData.taxcode = taxCode.id
        }
        onDone(totalData)
    }

    return (
        <Modal className="totalModal modal"
               onClose={() => onCancel()}
        >
            <Header className="header">
                <div className="title">Total</div>
            </Header>
            <Body className="body form">
                <Field className="field" style={{paddingTop: 0}}>
                    <Label className="label">Total</Label>
                    <Hint className="hint">Total amount due</Hint>
                    <Input placeholder="Set a price"
                           value={total}
                           disabled={totalReadOnly}
                           onChange={e => filterMonetaryInput(e.target.value, setTotal)} />
                </Field>

                {showDeposit && (
                    <Field className="field">
                        <Label className="label">Deposit</Label>
                        <Hint className="hint">Deposits are collected when booking</Hint>
                        <Input placeholder="Deposit due upfront"
                               value={deposit}
                               onBlur={priceValidator}
                               onChange={e => filterMonetaryInput(e.target.value, setDeposit)} />
                    </Field>
                )}

                {showSetupFee && (
                    <Field className="field">
                        <Label className="label">Setup Fee</Label>
                        <Hint className="hint">One time setup fee</Hint>
                        <Input placeholder="Set a fee"
                               value={setupFee}
                               onBlur={priceValidator}
                               onChange={e => filterMonetaryInput(e.target.value, setSetupFee)} />
                    </Field>
                )}

                {owner?.allow_gift && showGiftCertificates && (
                    <Field className="field">
                        <PSDropdown selected={giftCertificate}
                                    nameProperty="display_name"
                                    onSelect={(selection) => setGiftCertificate(selection)}
                                    label="Gift certificate"
                                    placeholder={<div className="grey">Select a gift certificate</div>}>
                            <>
                                <Item key="gift-none" value={{display_name: 'None', id: ''}}>None</Item>
                                {giftCertificates?.map(gift => (
                                    <Item key={`gift-${gift.id}`} value={gift}>
                                        {gift.display_name}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>
                    </Field>
                )}

                {owner?.allow_discount && (<>
                    <Dropdown selectedItem={discountRate}
                              onSelect={(selection) => setDiscountRate(selection)}
                              downshiftProps={{ itemToString: (item: any) => item && item['label']}}>
                        <DropdownField className="field">
                            <Label className="label">Discount</Label>
                            <div style={{display: 'flex'}}>
                                <PSToggleButton style={{margin: '12px 0'}}
                                                isPressed={discountType === 'percent'}
                                                onClick={() => setDiscountType('percent')}
                                >
                                    Percent of total
                                </PSToggleButton>
                                <PSToggleButton style={{margin: '12px 16px'}}
                                                isPressed={discountType === 'rate'}
                                                onClick={() => setDiscountType('rate')}
                                >
                                    Fixed amount
                                </PSToggleButton>
                                {discountType === 'percent' && (
                                    <Select style={{width: 292, height: 48, marginTop: 12}} className="input">
                                        {discountRate && discountRate.label}
                                        {!discountRate && (<div className="grey">Select discount</div>)}
                                    </Select>
                                )}
                                {discountType === 'rate' && (
                                    <Input style={{width: 292, height: 48, marginTop: 12}}
                                           className="input"
                                           placeholder="Enter discount amount"
                                           value={fixedDiscount}
                                           onChange={e => filterMonetaryInput(e.target.value, setFixedDiscount)}
                                    />
                                )}
                            </div>
                        </DropdownField>
                        <Menu>
                            {discountOptions.map(option => (
                                <Item key={`discount-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </Menu>
                    </Dropdown>
                    {showDiscountInstances && (
                        <PSDropdown selected={discountInstances}
                                    nameProperty="label"
                                    onSelect={(selection) => setDiscountInstances(selection)}
                                    label="Number of Discounts">
                            <>
                                {discountInstanceOptions.map(option => (
                                    <Item key={`discount-instance-${option.value}`} value={option}>
                                        {option.label}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>
                    )}
                </>)}

                {owner?.allow_salestax && (
                    <>
                        <PSDropdown selected={salesTaxType}
                                    nameProperty="label"
                                    onSelect={(selection) => setSalesTaxType(selection)}
                                    label="Sales tax"
                                    placeholder={<div className="grey">Please select</div>}>
                            <>
                                {taxOptions.map(option => (
                                    <Item key={`tax-${option.value}`} value={option}>
                                        {option.label}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>
                        {salesTaxType?.value === "transaction" && requireTaxLocation && (
                            <Field className="field">
                                <LocationAutocomplete updateAddress={(l) => {
                                                        if (l) setLocation(l)
                                                      }}
                                                      label="Location"
                                                      currentAddress={currentData?.taxLocation}
                                />
                            </Field>
                        )}
                    </>
                )}

                {owner?.allow_surcharge && (
                    <Field className="field" style={{paddingTop: 18}}>
                        <Checkbox checked={surcharge}
                                  onChange={e => setSurcharge(e.target.checked)}>
                            <Label className="withHint">Add 3% surcharge</Label>
                            <Hint>A 3% surcharge will be added to client payments</Hint>
                        </Checkbox>
                    </Field>
                )}
                {showPaymentPlan && (
                    <Field className="field">
                        <Label>Payment plan</Label>
                        <Hint className="hint">Specify the max number of payments for this invoice and the minimum payment amount. Note that your clients can opt to pay the full invoice balance at any time.</Hint>
                        <PSDropdown selected={paymentPlan} disabled={paymentPlanReadOnly}
                                    nameProperty="label"
                                    onSelect={(selection) => setPaymentPlan(selection)}
                        >
                            {invoicePaymentOptions.map(option => (
                                <Item key={`payment-plan-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </PSDropdown>
                    </Field>
                )}
            </Body>
            <Footer className="footer">
                <FooterItem>
                    <Button onClick={() => onCancel()}>Cancel</Button>
                </FooterItem>
                <FooterItem>
                    <Button isPrimary
                            onClick={() => onDoneHandler()}
                    >
                        Done
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )
}