import React from "react";
import { Reader, readerStatus } from "../../../models/Reader";
import {Dropdown, Item, Menu, Trigger} from "@zendeskgarden/react-dropdowns";
import {IconButton} from "@zendeskgarden/react-buttons";
import {ReactComponent as KabobIcon} from "../../../icons/kabob_menu.svg";
import { readerTypeOptions } from "../Options";

type Props = {
    reader: Reader,
    onSelect: (action: 'edit' | 'activate' | 'delete') => void
}

export const ReaderCard = ({reader, onSelect}: Props) => {
    const kabobMenuItems = (
        <>
            {(!!reader.registered_date || !reader.is_internet) && (
                <Item onClick={() => { onSelect('edit') }} value='edit'>
                    Edit
                </Item>
            )}
            {!reader.registered_date && reader.is_internet && (
                <Item onClick={() => { onSelect('activate') }} value='activate'>
                    Activate
                </Item>
            )}
            <Item onClick={() => { onSelect('delete') }} value='delete'>
                <span style={{color: 'red'}}>
                    Remove
                </span>
            </Item>
        </>
    )

    const readerType = readerTypeOptions(true).find(o => o.value === reader.type)?.label
    return (
        <div className="sourceCard">
            <div className="details">
                <div className="line">
                    <div className="label">{reader.label || readerType}</div>
                </div>
                <div className="line">
                    <div className="description">
                        <div>{`${reader.line1}, ${reader.city} ${reader.state}`}</div>
                        <div>{readerStatus(reader)}</div>
                    </div>
                    <div className="type">{readerType}</div>
                </div>
            </div>
            <div className="view">
                {kabobMenuItems && (
                    <Dropdown>
                        <Trigger>
                            <IconButton focusInset={false}>
                                <KabobIcon />
                            </IconButton>
                        </Trigger>
                        <Menu hasArrow>
                            {kabobMenuItems}
                        </Menu>
                    </Dropdown>
                )}
            </div>
        </div>
    )
}