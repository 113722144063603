import React, {useCallback, useEffect, useState} from "react";
import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import {PSMainButton} from "../app/PSButton";
import {markDashboard, requestCall} from "../../modules/dashboard";
import {BOOK_HOST} from "../../utils/constants";
import {useAppDispatch, usePSUser} from "../../hooks";
import {UserInstance} from "../../models/User";
import moment from "moment";

type Props = {
    onClose: () => void,
    forDashboard: boolean
}

export const RequestCallComponent = (props: Props) => {
    const dispatch = useAppDispatch()
    const forDashboard: boolean = props.forDashboard;

    const user: UserInstance|undefined = usePSUser()
    const [title, setTitle] = useState<string>()
    const [body, setBody] = useState<React.ReactNode>()
    const [secondaryButtonText, setSecondaryButtonText] = useState<string>()

    const [showAsapModal, setShowAsapModal] = useState(false)
    const closeModal = useCallback(() => {
        setShowAsapModal(false)
        props.onClose();
    }, [props.onClose])

    const scheduleACall = useCallback(() => {
        const url = forDashboard ? `${BOOK_HOST}/link/pocketsuite-academy` : `${BOOK_HOST}/link/psacademy`;
        window.open(url, '_blank')
        closeModal()
    }, [closeModal])

    useEffect(() => {
        if (showAsapModal) {
            setTitle(forDashboard ? 'Request a setup call' : 'Request a support call')
            setBody(<p>A member of our Customer Success Team will reach out asap! However, it may take us a few minutes if we’re on the line with another Pro.</p>)
            setSecondaryButtonText('Dismiss')
        }
        else if (forDashboard) {
            setTitle('We are here for you!')
            setBody(<>
                <p>Whether you have a simple workflow or advanced set-up needs. Connect with our team to take full advantage of what PocketSuite offers:</p>
                <br />
                <ul>
                    <li>Begin with a live Welcome Call startup demo</li>
                    <li>Book a live Foundations Call for a more in depth set-up</li>
                    <li>Access video playlists on key features anytime</li>
                </ul>
                {!isAvailable() && <div><br/><i>PocketSuite live call support is available 7 days a week (Monday through Friday from 9am to 10pm EST; Saturday & Sunday from 12pm to 8pm EST). If you need support after hours and on federal holidays, please text us in the PocketSuite support thread and we will get back to you asap.</i></div>}
            </>)
            setSecondaryButtonText('Schedule a call or watch tutorials')
        }
        else {
            setTitle('As a VIP Pro, get live support anytime')
            setBody(<>
                <p>Whether you have a simple workflow or advanced set-up needs. Connect with our team to take full advantage of what PocketSuite offers:</p>
                <br />
                <ul>
                    <li>Book a Growth Lab call to master PocketSuite & get booked solid</li>
                    <li>Book a 1:1 Premium Concierge Call for help with complex workflows</li>
                    <li>Receive all the above in a self-service video tutorial</li>
                </ul>
                {!isAvailable() && <div><br/><i>PocketSuite live call support is available 7 days a week (Monday through Friday from 9am to 10pm EST; Saturday & Sunday from 12pm to 8pm EST). If you need support after hours and on federal holidays, please text us in the PocketSuite support thread and we will get back to you asap.</i></div>}
            </>)
            setSecondaryButtonText('Schedule a call or watch tutorials')
        }
    }, [showAsapModal, closeModal, scheduleACall])

    const dispatchAsapCall = () => {
        if (forDashboard)
            dispatch(markDashboard('alerts_asap', 'request_call'))
                // @ts-ignore
                .then(() => {
                    closeModal()
                })
        else if (user)
            dispatch(requestCall(user))
                // @ts-ignore
                .then(() => {
                    closeModal()
                })
    }

    return (
        <Modal className='setupCallWrapper' onClose={closeModal}>
            <Header style={{fontWeight: 700, paddingLeft: 8}}>
                {title}
            </Header>
            <Body>
                {body}
            </Body>
            <Footer>
                <FooterItem>
                    <Button onClick={() => showAsapModal ? closeModal() : scheduleACall()} isBasic>
                        {secondaryButtonText}
                    </Button>
                </FooterItem>
                <FooterItem>
                    <PSMainButton disabled={!isAvailable()} onClick={() => showAsapModal ? dispatchAsapCall() : setShowAsapModal(true)} isPrimary>
                        Request an ASAP callback
                    </PSMainButton>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )
}

const isAvailable = () : boolean => {
    // 10a-8p EST (weekdays), 12-8p EST (weekends)
    const date = moment();
    let startDate = moment.tz([date.format("yyyy-MM-DD"), "10:00:00"].join(" "), "America/New_York");
    let endDate = moment.tz([date.format("yyyy-MM-DD"), "20:00:00"].join(" "), "America/New_York");
    if (date.weekday() === 0 || date.weekday() === 6) {
        startDate = moment.tz([date.format("yyyy-MM-DD"), "12:00:00"].join(" "), "America/New_York");
        endDate = moment.tz([date.format("yyyy-MM-DD"), "20:00:00"].join(" "), "America/New_York");
    }
    return !date.isBefore(startDate) && !date.isAfter(endDate);
}