import React, {useState} from "react";
import {
    Body, Close,
    Footer,
    FooterItem,
    Header,
    Modal
} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import {PSTextarea} from "../../app/PSTextarea";
import { Field } from "@zendeskgarden/react-forms";

type Props = {
    currentData?: {memo: string},
    onDone: (memo: string) => void,
    onCancel: () => void,
}

export const ReceiptMemoModal = ({currentData, onDone, onCancel}: Props) => {
    const [memo, setMemo] = useState(currentData?.memo || '')

    return (
        <Modal className="salesTaxModal modal"
               onClose={() => onCancel()}
        >
            <Header className="header">
                <div className="title">Receipt memo</div>
            </Header>
            <Body className="body form">
                <Field>
                    <PSTextarea placeholder="Enter receipt memo"
                                className=""
                                minRows={4}
                                value={memo}
                                onChange={(s) => setMemo(s)}
                    />
                </Field>
            </Body>
            <Footer className="footer">
                <FooterItem>
                    <Button onClick={() => onCancel()}>Cancel</Button>
                </FooterItem>
                <FooterItem>
                    <Button isPrimary
                            disabled={memo.length < 1}
                            onClick={() => onDone(memo)}
                    >
                        Done
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )
}