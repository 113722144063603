import React from 'react'
import PropTypes from 'prop-types'

export const RecordBody = (props) => {
    const { recordDetails } = props
    const detailsPerColumn = Math.ceil(recordDetails.length / 2)

    // Split the details up like a newspaper, 2 columns, left column is written downwards
    // then the right column from the top. Even number of recordDetails on each side, with the
    // left having an extra row in the case of odd numbers.

    const leftColumns = recordDetails.slice(0, detailsPerColumn)
    const rightColumns = recordDetails.slice(detailsPerColumn)

    return (
        <div className="recordBody">
            <div className="recordLeft">
                {leftColumns.map((record, index) => {
                    return (
                        <React.Fragment key={`record-left-${index}`}>
                            {record}
                        </React.Fragment>
                    )
                })}
            </div>
            <div className="recordRight">
                {rightColumns.map((record, index) => {
                    return (
                        <React.Fragment key={`record-right-${index}`}>
                            {record}
                        </React.Fragment>
                    )
                })}
            </div>
        </div>
    )
}

RecordBody.propTypes = {
    recordDetails: PropTypes.arrayOf(PropTypes.element).isRequired,
}