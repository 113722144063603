import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { AppHeader } from '../app/AppHeader'
import { getRecord } from '../../modules/records'
import { RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from '@zendeskgarden/react-loaders'

import { RecordEvent } from './types/RecordEvent'
import { RecordBooking } from './types/RecordBooking'
import { RecordBlock } from './types/RecordBlock'
import { RecordClass } from './types/RecordClass'
import { RecordPayment } from './types/RecordPayment'
import { RecordDeposit } from './types/RecordDeposit'
import { RecordSignature } from './types/RecordSignature'
import { RecordPackage } from './types/RecordPackage'
import { RecordClient } from './types/RecordClient'
import { RecordSubscription } from './types/RecordSubscription'
import { RecordEstimate } from './types/RecordEstimate'
import { RecordInvoice } from './types/RecordInvoice'
import { RecordPayee } from './types/RecordPayee'
import { RecordGift } from './types/RecordGift'
import { RecordForm } from './types/RecordForm'
import { RecordTask } from './types/RecordTask'

import './records.css'
import { RecordCharge } from './types/RecordCharge'

export const RecordView = () => {
    const { recordType, recordID } = useParams<{recordType: string, recordID: string}>()
    const dispatch = useDispatch()
    const state = useSelector((state: RootState) => (
        {
            records: state.records,
            entities: state.entities,
        }
    ))

    useEffect(() => {
        dispatch(getRecord(recordType, recordID))
    }, [dispatch, recordType, recordID])

    let title
    switch (recordType) {
        case 'booking':
            title = 'Appointment'
            break
        case 'event':
            title = 'Event'
            break
        case 'block':
            title = 'Block'
            break
        case 'class':
        case 'lesson':
            title = 'Class'
            break
        case 'payment':
            title = 'Payment'
            break
        case 'charge':
            title = 'Charge'
            break
        case 'deposit':
            title = 'Deposit'
            break
        case 'signature':
            title = 'Contract'
            break
        case 'package':
            title = 'Package'
            break
        case 'signup':
        case 'client':
            title = 'Client'
            break
        case 'subscription':
            title = 'Subscription'
            break
        case 'estimate':
            title = 'Estimate'
            break
        case 'invoice':
            title = 'Invoice'
            break
        case 'payee':
            title = 'Team Member'
            break
        case 'gift':
            title = 'Gift Certificate'
            break
        case 'record':
            title = 'Form'
            break
        case 'task':
            title = 'Task'
            break
        default:
            title = 'Details'
    }

    const { data, isSending } = state.records

    let loadingIndicator
    if (isSending) {
        loadingIndicator = (
            <div style={{textAlign: 'center', padding: '128px 0'}}>
                <Spinner size="128" color="#314A68" />
            </div>
        )
    }

    // Verify the data.id and recordID are the same, since we reuse this
    // component and the state is stored globally, it can be the old state
    // for a brief moment when we navigate backwards
    let recordView
    if (data?.id && data.id === recordID && !isSending) {
        switch (recordType) {
            case 'booking':
                recordView = <RecordBooking record={data} />
                break
            case 'block':
                recordView = <RecordBlock record={data} />
                break
            case 'class':
            case 'lesson':
                recordView = <RecordClass record={data} />
                break
            case 'payment':
                recordView = <RecordPayment record={data} />
                break
            case 'charge':
                recordView = <RecordCharge record={data} />
                break
            case 'deposit':
                recordView = <RecordDeposit record={data} />
                break
            case 'signature':
                recordView = <RecordSignature record={data} />
                break
            case 'package':
                recordView = <RecordPackage record={data} />
                break
            case 'signup':
            case 'client':
                recordView = (
                    <RecordClient record={data}
                                  sources={state.records.sources} />
                )
                break
            case 'subscription':
                recordView = <RecordSubscription record={data} />
                break
            case 'estimate':
                recordView = <RecordEstimate record={data} />
                break
            case 'invoice':
                recordView = <RecordInvoice record={data} />
                break
            case 'payee':
                recordView = <RecordPayee record={data} />
                break
            case 'gift':
                recordView = <RecordGift record={data} />
                break
            case 'record':
                recordView = <RecordForm record={data} />
                break
            case 'task':
                recordView = <RecordTask record={data} />
                break
            case 'event':
            default:
                recordView = <RecordEvent record={data} />
                break
        }
    }

    return (
        <AppHeader title={title}
                   showBackButton
                   middleWidget={null}
        >
            <div style={{margin: '32px 32px 0 32px'}}>
                {loadingIndicator}
                {recordView}
            </div>
        </AppHeader>
    )
}