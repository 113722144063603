import {Item} from "../models/Item";
import {Edition} from "../models/Edition";
import {apiClient} from "./apiclient";
import localforage from "localforage";
import {nextOnboardingStep} from "../utils/onboarding";
import {processError} from "./error";
import {Contract} from "../models/Contract";
import {Form} from "../models/Form";
import {Field} from "../models/Field";
import { UserInstance } from "../models/User";
import { addImage, base64toFile, Result } from "../utils";
import { VERIFY_BUSINESS_FORM } from "../utils/constants";
import { syncClient, SyncClientResponse } from "./contacts";
import {Category} from "../models/Category";

type GrowBusinessFormSubmissions = {
    yearsInBusiness?: string,
    servicesOffered?: string,
    marketingInfo?: string,
    referralName?: string,
    address?: string,
    birthday?: Date,
    legalName?: string,
    ssnLast4?: string
}

export interface OnboardingState {
    selectedCategoryIDs: string[],
    selectedItemIDs: string[],
    selectedFormIDs: string[],
    selectedContractIDs: string[],
    selectedFieldIDs: string[],
    selectedIntakeIDs: string[],
    categories: Category[],
    category: Category,
    items: Item[],
    item: Item,
    contracts: Contract[],
    contract: Contract,
    forms: any[],
    form: Form,
    fields: Field[],
    field: Field,
    intakeFields: Field[],
    edition: Edition,
    refreshData: boolean,
    growYourBusiness: GrowBusinessFormSubmissions
}

const initialState: OnboardingState = {
    selectedCategoryIDs: [],
    selectedItemIDs: [],
    selectedFormIDs: [],
    selectedContractIDs: [],
    selectedFieldIDs: [],
    selectedIntakeIDs: [],
    categories: [],
    category: {} as Category,
    items: [],
    item: {} as Item,
    contracts: [],
    contract: {} as Contract,
    forms: [],
    form: {} as Form,
    fields: [],
    field: {} as Field,
    intakeFields: [],
    edition: {} as Edition,
    refreshData: true,
    growYourBusiness: {} as GrowBusinessFormSubmissions
}

export default function onboardingReducer(state = initialState, action: any): OnboardingState {
    switch (action.type) {
        case 'onboarding/SET_CATEGORIES':
            return {
                ...state,
                categories: action.categories ?? [],
                refreshData: true,
            }
        case 'onboarding/UPDATE_CATEGORY':
            const idx = state.categories.findIndex(c => c.id === action.category.id)
            const newCategories = [ ...state.categories ]
            if (idx > -1) {
                newCategories[idx] = action.category
            }
            return {
                ...state,
                categories: newCategories,
                refreshData: action.refreshData === true,
            }
        case 'onboarding/SET_ITEMS':
            return {
                ...state,
                items: action.items ?? [],
                refreshData: true,
            }
        case 'onboarding/UPDATE_ITEM':
            const itemIndex = state.items.findIndex(i => i.id === action.item.id)
            const newItems = [ ...state.items ]
            if (itemIndex > -1) {
                newItems[itemIndex] = action.item
            }
            return {
                ...state,
                items: newItems,
                refreshData: action.refreshData === true,
            }
        case 'onboarding/SET_CONTRACTS':
            return {
                ...state,
                contracts: action.contracts,
                refreshData: true,
            }
        case 'onboarding/UPDATE_CONTRACT':
        {
            const index = state.contracts.findIndex(c => c.id === action.contract.id)
            const newContracts = [...state.contracts]
            if (index > -1) {
                newContracts[index] = action.contract
            }
            return {
                ...state,
                contracts: newContracts,
                refreshData: action.refreshData === true,
            }
        }
        case 'onboarding/SET_FORMS':
            return {
                ...state,
                forms: action.forms,
                refreshData: true,
            }
        case 'onboarding/UPDATE_FORM':
        {
            const index = state.forms.findIndex(f => f.id === action.form.id)
            const newForms = [ ...state.forms ]
            if (index > -1) {
                newForms[index] = action.form
            }
            return {
                ...state,
                forms: newForms,
                refreshData: action.refreshData === true,
            }
        }
        case 'onboarding/SET_FIELDS':
            return {
                ...state,
                fields: action.fields.map((f: any) => {
                    const field = new Field()
                    field.setData(f)
                    return field
                }),
            }
        case 'onboarding/UPDATE_FIELD':
        {
            const index = state.fields.findIndex(f => f.id === action.field.id)
            const newFields = [ ...state.fields ]
            if (index > -1) {
                newFields[index] = action.field
            }
            return {
                ...state,
                fields: newFields,
            }
        }
        case 'onboarding/SET_INTAKES':
            return {
                ...state,
                intakeFields: action.intakeFields.map((f: any) => {
                    const field = new Field()
                    field.setData(f)
                    return field
                }),
            }
        case 'onboarding/UPDATE_INTAKE':
        {
            const index = state.intakeFields.findIndex(f => f.id === action.intakeField.id)
            const newIntakeFields = [ ...state.intakeFields ]
            if (index > -1) {
                newIntakeFields[index] = action.intakeField
            }
            return {
                ...state,
                intakeFields: newIntakeFields,
            }
        }
        case 'onboarding/SELECT_CATEGORY':
            let newSelectedCategoryIDs: string[] = []
            if (action.selected)
                newSelectedCategoryIDs = [ ...state.selectedCategoryIDs, action.id ]
            else {
                const index = state.selectedCategoryIDs.findIndex(id => action.id === id)
                if (index > -1) {
                    const tmpCategories = [...state.selectedCategoryIDs]
                    tmpCategories.splice(index, 1)
                    newSelectedCategoryIDs = tmpCategories
                }
            }
            return {
                ...state,
                selectedCategoryIDs: newSelectedCategoryIDs
            }
        case 'onboarding/SELECT_ITEM':
            let newSelectedItemIDs: string[] = []
            if (action.selected)
                newSelectedItemIDs = [ ...state.selectedItemIDs, action.id ]
            else {
                const index = state.selectedItemIDs.findIndex(id => action.id === id)
                if (index > -1) {
                    const tmpItems = [...state.selectedItemIDs]
                    tmpItems.splice(index, 1)
                    newSelectedItemIDs = tmpItems
                }
            }
            return {
                ...state,
                selectedItemIDs: Array.from(new Set(newSelectedItemIDs))
            }
        case 'onboarding/SELECT_FORM':
            let newSelectedFormIDs: string[] = []
            if (action.selected)
                newSelectedFormIDs = [ ...state.selectedFormIDs, action.id ]
            else {
                const index = state.selectedFormIDs.findIndex(id => action.id === id)
                if (index > -1) {
                    const tmpForms = [...state.selectedFormIDs]
                    tmpForms.splice(index, 1)
                    newSelectedFormIDs = tmpForms
                }
            }
            return {
                ...state,
                selectedFormIDs: Array.from(new Set(newSelectedFormIDs))
            }
        case 'onboarding/SELECT_CONTRACT':
            let newSelectedContractIDs: string[] = []
            if (action.selected)
                newSelectedContractIDs = [ ...state.selectedContractIDs, action.id ]
            else {
                const index = state.selectedContractIDs.findIndex(id => action.id === id)
                if (index > -1) {
                    const tmpContracts = [...state.selectedContractIDs]
                    tmpContracts.splice(index, 1)
                    newSelectedContractIDs = tmpContracts
                }
            }
            return {
                ...state,
                selectedContractIDs: Array.from(new Set(newSelectedContractIDs))
            }
        case 'onboarding/SELECT_FIELD':
            let newSelectedFieldIDs: string[] = []
            if (action.selected)
                newSelectedFieldIDs = [ ...state.selectedFieldIDs, action.id ]
            else {
                const index = state.selectedFieldIDs.findIndex(id => action.id === id)
                if (index > -1) {
                    const tmpFields = [...state.selectedFieldIDs]
                    tmpFields.splice(index, 1)
                    newSelectedFieldIDs = tmpFields
                }
            }
            return {
                ...state,
                selectedFieldIDs: Array.from(new Set(newSelectedFieldIDs))
            }
        case 'onboarding/SELECT_INTAKE':
            let newSelectedIntakeIDs: string[] = []
            if (action.selected)
                newSelectedIntakeIDs = [ ...state.selectedIntakeIDs, action.id ]
            else {
                const index = state.selectedIntakeIDs.findIndex(id => action.id === id)
                if (index > -1) {
                    const tmpIntakes = [...state.selectedIntakeIDs]
                    tmpIntakes.splice(index, 1)
                    newSelectedIntakeIDs = tmpIntakes
                }
            }
            return {
                ...state,
                selectedIntakeIDs: Array.from(new Set(newSelectedIntakeIDs))
            }
        case 'onboarding/CLEAR_SELECTIONS':
            return {
                ...state,
                selectedCategoryIDs: [],
                selectedItemIDs: [],
                selectedFormIDs: [],
                selectedContractIDs: [],
                selectedFieldIDs: [],
                selectedIntakeIDs: [],
            }
        case 'onboarding/CLEAR_FORM_FIELD_SELECTIONS':
            return {
                ...state,
                selectedFieldIDs: [],
            }
        case 'onboarding/SET_REFRESH_DATA':
            return {
                ...state,
                refreshData: action.refreshData,
            }
        case 'onboarding/SET_GROW_YOUR_BUSINESS':
            return {
                ...state,
                growYourBusiness: action.growYourBusiness
            }
        default:
            return state
    }
}

export const setCategories = (categories: Category[]) => {
    return { type: 'onboarding/SET_CATEGORIES', categories }
}

export const updateCategory = (category: Category, refreshData?: boolean) => {
    return { type: 'onboarding/UPDATE_CATEGORY', category, refreshData }
}

export const setItems = (items: Item[]) => {
    return { type: 'onboarding/SET_ITEMS', items }
}

export const updateItem = (item: Item, refreshData?: boolean) => {
    return { type: 'onboarding/UPDATE_ITEM', item, refreshData }
}

export const setContracts = (contracts: Contract[]) => {
    return { type: 'onboarding/SET_CONTRACTS', contracts }
}

export const updateContract = (contract: Contract, refreshData?: boolean) => {
    return { type: 'onboarding/UPDATE_CONTRACT', contract, refreshData }
}

export const setForms = (forms: Form[]) => {
    return { type: 'onboarding/SET_FORMS', forms }
}

export const updateForm = (form: Form, refreshData?: boolean) => {
    return { type: 'onboarding/UPDATE_FORM', form, refreshData }
}

export const setFields = (fields: Field[]) => {
    return { type: 'onboarding/SET_FIELDS', fields }
}

export const updateField = (field: Field) => {
    return { type: 'onboarding/UPDATE_FIELD', field }
}

export const setIntake = (fields: Field[]) => {
    return { type: 'onboarding/SET_INTAKES', intakeFields: fields }
}

export const updateIntake = (field: Field) => {
    return { type: 'onboarding/UPDATE_INTAKE', intakeField: field }
}

export const selectCategory = (id: string, selected: boolean) => {
    return { type: 'onboarding/SELECT_CATEGORY', id, selected }
}

export const selectItem = (id: string, selected: boolean) => {
    return { type: 'onboarding/SELECT_ITEM', id, selected }
}

export const selectForm = (id: string, selected: boolean) => {
    return { type: 'onboarding/SELECT_FORM', id, selected }
}

export const selectContract = (id: string, selected: boolean) => {
    return { type: 'onboarding/SELECT_CONTRACT', id, selected }
}

export const selectField = (id: string, selected: boolean) => {
    return { type: 'onboarding/SELECT_FIELD', id, selected }
}

export const selectIntake = (id: string, selected: boolean) => {
    return { type: 'onboarding/SELECT_INTAKE', id, selected }
}

export const clearSelections = () => {
    return { type: 'onboarding/CLEAR_SELECTIONS' }
}

export const clearFormFieldSelections = () => {
    return { type: 'onboarding/CLEAR_FORM_FIELD_SELECTIONS' }
}

export const setRefreshData = (refreshData: boolean) => {
    return { type: 'onboarding/SET_REFRESH_DATA', refreshData }
}

export const setGrowYourBusiness = (growYourBusiness: GrowBusinessFormSubmissions) => {
    return { type: 'onboarding/SET_GROW_YOUR_BUSINESS', growYourBusiness }
}
export const setGoLiveState = (newState: string): Promise<any> => {
        if (!newState) return Result()

        return apiClient.post('/onboarding', { state: newState, dashboard: 'setup_launch' })
            .then(resp => resp.data)
            .then(json => {
                if (json.user)
                    localforage.setItem('user', json.user)
                return json.user
            })
    }

export const setOnboardingState = (newState: string, onComplete?: () => void) =>
    (dispatch: any): Promise<any> =>
        apiClient.post('/onboarding', { state: newState })
            .then(resp => resp.data)
            .then(json => {
                if (json.user) localforage.setItem('user', json.user)
                Result(dispatch(nextOnboardingStep(json.user)))
                    .then(() => {
                        if (onComplete) onComplete()
                    })
            })

export const submitVerification = (userId: string, name?: string, ssnLast4?: string, address?: string, birthday?: Date, image?: string) =>
    (dispatch: any): Promise<any> => {
        let file: File | undefined
        const block = image?.split(';')
        if (block && block.length >= 2) {
            const contentType = block![0].split(':')[1]
            const data = block![1].split(',')[1]
            file = base64toFile(data, contentType, "capture")
        }

        const data = addImage({
            id: userId,
            address,
            dob: birthday,
            name,
            ssn_last_4: ssnLast4
        }, file)

        return apiClient.post(`user/${userId}/verify`, data)
            .then(resp => resp.data)
            .then((json?: UserInstance) => {
                if (json) {
                    localforage.setItem('user', json)
                    return json
                }
            })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }

export const submitBusinessForm = (user: UserInstance, record: any) =>
    sendRecord(user, record, VERIFY_BUSINESS_FORM)

const sendRecord = (user: UserInstance, record: any, recordType: string, sendOnly?: boolean, dontNotify?: boolean, ) =>
    (dispatch: any): Promise<any> => {
        const url = record.id ? `/record/${record.id}` : '/record'
        let data: any = {
            field_values: record,
            person: user.id,
            type: recordType
        }
        if (dontNotify) {
            data.dont_notify = dontNotify
        }

        const sendData = (data: any) =>
            apiClient.post(url, data)
                .then(resp => resp.data)
                .catch(error => dispatch(processError(error)))

        if (sendOnly) {
            let channel = record.channel
            if (!channel) {
                channel = !!user.e164 ? "sms" : "email"
                return dispatch(syncClient(user.id))
                    .then((result: SyncClientResponse) =>
                        sendData({
                            id: record.id,
                            channel: result.client?.notify ?? channel,
                            dont_notify: dontNotify
                        })
                    )
            }
            data = {
                id: record.id,
                channel: channel
            }
        }

        return sendData(data)
    }
