import React, {useCallback, useEffect, useState} from "react";
import {PSButtonPrimary, PSButtonSecondary} from "../../../app/PSButton";
import {useAppDispatch, useConfirmModal, usePSOwner} from "../../../../hooks";
import {
    fetchFeatures,
    fetchItemByType,
    moveLink,
    saveUser,
    SettingsState, submitFeatures
} from "../../../../modules/settings";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";
import localforage from "localforage";
import {isEmpty, Result} from "../../../../utils";
import {BOOK_HOST} from "../../../../utils/constants";
import {PSFormFieldSelection} from "../../../app/PSFormFieldSelection";
import {PSDropdown} from "../../../app/PSDropdown";
import {colorThemeOptions, findSelection, SelectOption} from "../../Options";
import {Dropdown, Item, Menu, Trigger} from "@zendeskgarden/react-dropdowns";
import {Dots, Spinner} from "@zendeskgarden/react-loaders";
import {Prompt} from "react-router";
import {SuccessModal} from "../../SuccessModal";
import {SettingsPhotoField} from "../../SettingsPhotoField";
import {usePhotoUpload} from "../../hooks";
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";
import {Link} from "react-router-dom";
import {ReactComponent as GripIcon} from "../../../../icons/grip.svg";
import {PSLink, SUITELINK_LIMIT} from "../../../../models/PSLink";
import {push} from "connected-react-router";
import {Button} from "@zendeskgarden/react-buttons";
import { ReactComponent as ChevronIcon } from "@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg";
import {featureEnabled, featureRequiresUpgrade} from "../../../../models/Feature";
import {loadIndustry} from "../../../../models/Industry";

export const SetupSuiteLinkComponent = () => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const [hasEdits, setHasEdits] = useState<boolean>(false)
    const [pageLoading, setPageLoading] = useState<boolean>(true)
    const [addEnabled, setAddEnabled] = useState<boolean>(false)
    const [dropdownRotated, setDropdownRotated] = useState<boolean>(false)
    const [showCopySuccess, setShowCopySuccess] = useState<boolean>(false)
    const [user, setUser] = useState<any | undefined>()
    const [industry, setIndustry] = useState<any | undefined>()
    const [suiteLinkURL, setSuiteLinkURL] = useState<string>('')
    const [primaryColor, setPrimaryColor] = useState<string>('')
    const [secondaryColor, setSecondaryColor] = useState<string>('')
    const [colorOptions, setColorOptions] = useState<SelectOption[]>([])
    const [links, setLinks] = useState<any[]>([])
    const [linkInfo, setLinkInfo] = useState<any>({})
    const [buttonOptions, setButtonOptions] = useState<any[]>([])

    const [photo, setPhoto] = useState<undefined | string | File>('')
    const [addPhotoRef, selectPhoto, selectedPhoto] = usePhotoUpload({disabled: state.isSending, setPhoto})

    useEffect(() => {
        Promise.all([
            localforage.getItem('user'),
            dispatch(fetchItemByType('link')),
            dispatch(fetchFeatures())
        ])
            .then((values) => {
                setUser(values[0])
            })
            .finally(() => setPageLoading(false))
    }, [dispatch])

    useEffect(() => {
        if (state.links.length)
            setLinks(state.links.filter((x: any) => !x.cancelled_date))
    }, [state.links])

    useEffect(() => {
        if (isEmpty(user)) return

        const colorListHex = colorThemeOptions.map(option => option.value)
        const primaryCheck = /^#[0-9a-f]{6}$/i.test(user.primary_color)
        const secondaryCheck = /^#[0-9a-f]{6}$/i.test(user.secondary_color)
        const primaryOptionMissing = primaryCheck && !colorListHex.includes(user.primary_color)
        const secondaryOptionMissing = secondaryCheck && !colorListHex.includes(user.secondary_color)
        const newColorOptions: SelectOption[] = []
        if (user.primary_color === user.secondary_color && primaryOptionMissing && secondaryOptionMissing){
            newColorOptions.push({ value: user.primary_color, label: 'Custom color' })
        }
        else {
            if (primaryOptionMissing){
                newColorOptions.push({ value: user.primary_color, label: 'Custom primary color' })
            }
            if (secondaryOptionMissing){
                newColorOptions.push({ value: user.secondary_color, label: 'Custom secondary color' })
            }
        }
        setColorOptions([...colorThemeOptions,...newColorOptions])
        setSuiteLinkURL(`${BOOK_HOST}/link/${!!user.username ? user.username : user.key}`)
        setPrimaryColor(!!user.primary_color ? user.primary_color : colorThemeOptions[0].value)
        setSecondaryColor(!!user.secondary_color ? user.secondary_color : colorThemeOptions[1].value)
        setPhoto(user.image_uri || '' )

        loadIndustry(user.industry).then((ii: any) => setIndustry(ii))

    }, [user])

    const colorItems = useCallback(() => {
        return colorOptions.map(option =>
            <Item key={`color-${option.value}`} value={option}>
                <div style={{display: 'flex', gap: '7px', alignItems: 'center'}}>
                    <svg width="14" height="14"><circle cx="7" cy="7" r="7" fill={option.value}/></svg>
                    <div style={{color: '#000'}}>{option.label}</div>
                    <div style={{color: '#aaa'}}>{option.value}</div>
                </div>
            </Item>
        )
    }, [colorOptions])

    useEffect(() => {
        if (isEmpty(user)) return

        setHasEdits(
            user.primary_color !== primaryColor ||
            user.secondary_color !== secondaryColor ||
            typeof photo === 'object'
        )
    }, [user, primaryColor, secondaryColor, photo])

    const owner = usePSOwner()
    useEffect(() => {
        if (isEmpty(owner)) return

        if (links.length < SUITELINK_LIMIT) {
            setAddEnabled(true)

            const existingLinks: any = {}
            for (let ii in links) {
                existingLinks[links[ii].type] = true
            }
            const options = []
            const info: any = {}
            if (!existingLinks.book) {
                options.push("book")
                info["book"] = ["widget", "Book a session"]
            }
            if (!existingLinks.chat) {
                options.push("chat")
                info["chat"] = ["chat", "Chat with me"]
            }
            if (!existingLinks.lead && owner?.allow_lead) {
                options.push("lead")
                info["lead"] = ["lead", "Sign up with me"]
            }
            if (!existingLinks.cash) {
                options.push("cash")
                info["cash"] = ["paypal", "Send me a payment"]
            }
            options.push("custom")
            info["custom"] = ["booking", "Add a custom button"]

            setLinkInfo(info)
            setButtonOptions(options.map(x => <Item value={x} key={`item-${x}`}>{info[x][1]}</Item>))
        }
    }, [owner, links])

    const [setCtaModalInfo, setCtaModalLoading, showCtaModal, closeCtaModal, ctaComponent] = useConfirmModal()

    const addButtonClick = useCallback((featureType: string) => {
        if (!(user && industry && linkInfo.hasOwnProperty(featureType) && state.features.length)) return

        const featureID = linkInfo[featureType][0]
        const featureTitle = linkInfo[featureType][1]
        const ix = state.features.findIndex(x => x.id === featureID)
        if (ix === -1) return
        const feature = state.features[ix]

        if (featureType === "custom") {
            dispatch(push('/settings/link/new', {linkData: {type: featureType, label: ""}}))
        }
        else if (!featureEnabled(featureID, user, industry) && featureRequiresUpgrade(feature, user)) {
            setCtaModalInfo({
                title: `${industry.title} Edition`,
                body: <p>{`This feature requires upgrading to the PocketSuite ${industry.title} Edition. Select upgrade to view all of the benefits that come along with upgrading!`}</p>,
                button: "Upgrade",
                onClick: () => {
                    dispatch(push("/settings/change-plan"))
                }
            })
            showCtaModal()
        }
        else if (!featureEnabled(featureID, user, industry)) {
            setCtaModalInfo({
                title: feature.name,
                body: <p>Would you like to unlock this feature?</p>,
                button: "Unlock feature",
                onClick: () => {
                    user[`allow_${featureID}`] = true
                    Result(dispatch(submitFeatures(user)))
                        .then(() => localforage.getItem('user'))
                        .then(u => setUser(u))
                        .then(() => closeCtaModal())
                }
            })
            showCtaModal()
        }
        else {
            dispatch(push('/settings/link/new', {linkData: {type: featureType, label: featureTitle}}))
        }

    }, [dispatch, linkInfo, user, industry, state.features, setCtaModalInfo, showCtaModal, closeCtaModal])

    const onDragEnd = useCallback((result: DropResult) => {
        if (!result.destination) return

        const linksCopy = links.slice()
        const target = linksCopy[result.destination.index]

        if (target.seqnum){
            const [removed] = linksCopy.splice(result.source.index, 1)
            linksCopy.splice(result.destination.index, 0, removed)
            dispatch(moveLink(removed.id, target.seqnum, linksCopy))
        }
    }, [dispatch, links])

    const linksDragDrop = useCallback(() => {
        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(droppableProps: any) => {
                        return (
                            <div ref={droppableProps.innerRef} style={{paddingTop: 28}}>
                                {links.map((linkData, index) => {
                                    const link = new PSLink(linkData)
                                    const editURL = `/settings/link/${link.id}/edit`

                                    return (
                                        <Draggable key={`link-${index}`}
                                                   draggableId={`link-${index}`}
                                                   index={index}>
                                            {(provided: any, snapshot: any) => (
                                                <div ref={provided.innerRef}
                                                     {...provided.draggableProps}
                                                     {...provided.dragHandleProps}
                                                >
                                                    <div className="serviceCard" style={{marginBottom: 28}}>
                                                        <div style={{display: 'flex', alignItems: 'center', gap: 16}}>
                                                            <svg width="36" height="36"><circle cx="18" cy="18" r="18" fill={secondaryColor}/></svg>
                                                            <div>
                                                                <div className="name" style={{color: '#000'}}>{link.label}</div>
                                                                <div className="rate" style={{color: '#aaa'}}>{link.typeString()}</div>
                                                            </div>
                                                        </div>
                                                        <div style={{marginLeft: 'auto', display: 'flex', alignItems: 'center'}}>
                                                            <Link to={editURL} className="edit">
                                                                Edit
                                                            </Link>
                                                            <div style={{marginLeft: 16}}>
                                                                <GripIcon/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}
                            </div>
                        )
                    }}
                </Droppable>
            </DragDropContext>
        )
    }, [onDragEnd, links, secondaryColor])

    const onSave = () => {
        if (state.isSending) return

        const userParams: any = {}
        userParams.id = user.id
        userParams.primary_color = primaryColor
        userParams.secondary_color = secondaryColor

        Result(dispatch(typeof photo === 'string' ? saveUser(userParams) : saveUser(userParams, photo as File)))
            .then(() => localforage.getItem('user'))
            .then((u: any) => setUser(u))
    }

    return (
        <div className="servicesSettings" style={{width: '750px'}}>
            <div className="header">
                <h1>Your Suite Link</h1>
                {!pageLoading && !hasEdits &&
                    <>
                        <PSButtonSecondary style={{height: '40px'}}
                                           onClick={() => {
                                               window.open(suiteLinkURL, "_blank")
                                           }}>
                            Preview
                        </PSButtonSecondary>

                        <Dropdown
                            onSelect={item => addButtonClick(item)}
                            onStateChange={options => setDropdownRotated(options?.isOpen ?? false)}
                        >
                            <Trigger>
                                <PSButtonPrimary style={{height: '40px', marginLeft: '16px'}}
                                                 onClick={() => {}}
                                                 disabled={!addEnabled}>
                                    Add link
                                    <Button.EndIcon isRotated={dropdownRotated}>
                                        <ChevronIcon />
                                    </Button.EndIcon>
                                </PSButtonPrimary>
                            </Trigger>
                            <Menu>
                                {buttonOptions}
                            </Menu>
                        </Dropdown>
                    </>
                }
                {!pageLoading && hasEdits &&
                    <PSButtonPrimary style={{height: '40px'}}
                                     onClick={onSave}>
                        {state.isSending ? <Dots /> : "Save"}
                    </PSButtonPrimary>
                }
            </div>
            <div className="separator" />
            {pageLoading &&
                <div style={{textAlign: 'center', padding: '128px 0'}}>
                    <Spinner size="128" color="#314A68" />
                </div>
            }
            {!pageLoading &&
                <div className="form">
                    <SettingsPhotoField photo={photo}
                                        selectPhoto={selectPhoto}
                                        selectedPhoto={selectedPhoto}
                                        addPhotoRef={addPhotoRef}
                                        title="Add a logo" />
                    <PSFormFieldSelection
                        label="Suite link"
                        helperLabel={suiteLinkURL}
                        linkText="Share"
                        onClick={() => {
                            navigator.clipboard.writeText(suiteLinkURL)
                                .then(() => setShowCopySuccess(true))
                        }}
                    />
                    <PSDropdown label="Primary color"
                                hint="Choose your widget colors here or send us your hex codes for more customization."
                                selected={findSelection(colorOptions, primaryColor)}
                                nameProperty={"label"}
                                onSelect={selected => setPrimaryColor(selected.value)}>
                        <>{colorItems()}</>
                    </PSDropdown>
                    <div style={{marginTop:"-10px"}}>
                        <PSDropdown label="Secondary color"
                                    selected={findSelection(colorOptions, secondaryColor)}
                                    nameProperty="label"
                                    onSelect={selected => setSecondaryColor(selected.value)}>
                            <>{colorItems()}</>
                        </PSDropdown>
                    </div>
                    {linksDragDrop()}
                </div>
            }
            {showCopySuccess &&
                <SuccessModal
                    title="Suite link copied!"
                    buttonText="Close"
                    body={<p>Your suite link has been copied to your clipboard! Paste it to share with a client or on social media.</p>}
                    onClose={() => {setShowCopySuccess(false)}}
                    onAction={() => {setShowCopySuccess(false)}}
                />
            }
            {ctaComponent}
            <Prompt
                when={hasEdits && !state.isSending && !state.loadingAttachments}
                message="Are you sure you'd like to leave this page without saving your changes?" />
        </div>
    )
}