import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from '../store'
import React, {useCallback, useEffect, useState} from "react";
import {apiClient} from "../modules/apiclient";
import { fetchReaders, SettingsState, updateReader } from '../modules/settings';
import { Result } from '../utils';
import { Spinner } from '@zendeskgarden/react-loaders';
import { PSButton, PSButtonDanger, PSButtonPrimary } from '../components/app/PSButton';
import { Dropdown, Item, Menu, Trigger } from '@zendeskgarden/react-dropdowns';
import { Button } from '@zendeskgarden/react-buttons';
import { chargeBooking, chargeEstimate, confirmBooking, confirmEstimate, createIntent, setPendingIntent, TransactionsState } from "../modules/transactions";
import { ReactComponent as ChevronIcon } from "@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg";
import { ReactComponent as CardIcon } from "../icons/ps_general_card.svg";
import { ReactComponent as PlusIcon } from "../icons/plus_bg.svg";
import { ReactComponent as RedCircle } from "../icons/alert_red_circle_x.svg";
import { ReactComponent as RedX } from "../icons/red_x.svg";
import { ReactComponent as ChevronLeft } from "../icons/chevron-blue.svg";
import { ReactComponent as MoneyIcon } from "../icons/ps_general_money.svg";
import { SourceInstance } from '../models/Source';
import { push } from 'connected-react-router';
import { Payment } from '../models/Payment';
import { Booking } from '../models/Booking';
import { Estimate } from '../models/Estimate';
import { Invoice } from '../models/Invoice';
import { Package } from '../models/Package';
import { GiftCertificate } from '../models/GiftCertificate';
import { processError } from '../modules/error';
import { Reader, readerDisabled, readerTitle } from '../models/Reader';
import { showPopover, hidePopover } from '../modules/popover';
import { Inline } from '@zendeskgarden/react-loaders';
import { usePSOwner } from '.';


type ChargeButtonReturnType = [JSX.Element, (enabled: boolean) => void]
export const useChargeButton = (paymentData: Payment, record?: Booking | Estimate | Invoice | Package | GiftCertificate, recordType?: string): ChargeButtonReturnType => {
    const dispatch = useDispatch()
    const owner = usePSOwner()
    const state: {settings: SettingsState, transactions: TransactionsState} = useSelector((state: RootState) => {
        return {
            settings: state.settings,
            transactions: state.transactions
        }
    })

    const [readers, setReaders] = useState<Reader[]>([])
    useEffect(() => {
        if (!state.settings.readers?.length) return

        setReaders(state.settings.readers.filter(r => r.is_internet))
    }, [state.settings.readers])

    const isDepositPayment = paymentData?.type === "confirm" && ["booking", "estimate"].includes(recordType || '')

    const [loading, setLoading] = useState(false)
    const [sources, setSources] = useState<SourceInstance[]>([])
    const [chargeEnabled, setChargeEnabled] = useState(true)

    const onAddNewCard = useCallback(() => {
        dispatch(push('/charge/add-card', {clientID: paymentData.client}))
    }, [paymentData?.client, dispatch])

    const onMarkPaid = useCallback(() => {
        dispatch(push('/charge/mark-paid', {paymentData, record, recordType}))
    }, [dispatch, paymentData, record, recordType])

    const [client, setClient] = useState<string>()
    const [chargeSending, setChargeSending] = useState(false)
    const onChargeCard = useCallback((currentSource?: string, paymentIntent?: string) => {
        if (chargeSending) return
        setChargeSending(true)

        const charging = <div style={{marginTop: '274px', textAlign: 'center'}}>
            <div>
                <Inline size={64} color='#314A68' />
            </div>
            <div style={{marginTop: '44px', fontSize: '32px', lineHeight: '40px', fontWeight: 700, color: '#314A68'}}>
                Charging...
            </div>
        </div>
        dispatch(showPopover(charging))

        const payment = new Payment(paymentData)
        payment.source = currentSource ?? ''
        payment.intent = paymentIntent
        payment.owner = owner?.id ?? paymentData.owner
        const isAnonymousCardReaderPayment = !!paymentIntent && !payment.client
        if (client)
            payment.client = client

        let apiCall: Promise<any> | undefined = undefined
        if (recordType === 'booking' && paymentData.type === "confirm") {
            apiCall = Result(dispatch(confirmBooking({
                ...record,
                source: payment.source,
                intent: paymentIntent,
                confirm_all: true,
                gratuity: paymentData.tip,
                gratuity_type: paymentData.tip_type,
            })))
        }
        else if (recordType === 'booking' && paymentData.type === "complete") {
            apiCall = Result(dispatch(chargeBooking({
                ...record,
                rate: paymentData.total,
                source: payment.source,
                intent: paymentIntent,
                gratuity: paymentData.tip,
                gratuity_type: paymentData.tip_type
            })))
        }
        else if (recordType === 'estimate' && paymentData.type === "confirm") {
            apiCall = Result(dispatch(confirmEstimate({
                ...record,
                source: payment.source,
                intent: paymentIntent,
                confirm_all: true,
                gratuity: paymentData.tip,
                gratuity_type: paymentData.tip_type,
            })))
        }
        else if (recordType === 'estimate' && paymentData.type === "complete") {
            apiCall = Result(dispatch(chargeEstimate({
                ...record,
                rate: paymentData.total,
                source:  payment.source,
                intent: paymentIntent,
                gratuity: paymentData.tip,
                gratuity_type: paymentData.tip_type
            })))
        }
        else if (recordType === 'invoice' && record?.id) {
            apiCall = apiClient.post(`/invoice/${record.id}/pay`, payment)
                .then(resp => resp.data)
                .catch(error => {
                    dispatch(processError(error))
                    return Promise.reject()
                })
        }
        else if (recordType === 'package' && record?.id) {
            apiCall = apiClient.post(`/package/${record.id}/pay`, payment)
                .then(resp => resp.data)
                .catch(error => {
                    dispatch(processError(error))
                    return Promise.reject()
                })
        }
        else if (recordType === 'gift' && record?.id) {
            apiCall = apiClient.post(`/gift/${record.id}/pay`, payment)
                .then(resp => resp.data)
                .catch(error => {
                    dispatch(processError(error))
                    return Promise.reject()
                })
        }
        else {
            apiCall = apiClient.post('/payment', payment)
                .then(resp => resp.data)
                .catch(error => {
                    dispatch(processError(error))
                    return Promise.reject()
                })
        }

        if (!apiCall) return

        return apiCall
            .then(json => {
                const locationData = {
                    charge: json?.charge,
                    payment: json?.payment,
                    booking: json?.booking,
                    estimate: json?.estimate,
                    invoice: json?.invoice,
                    aPackage: json?.package,
                    gift: json?.gift
                }
                return isAnonymousCardReaderPayment ?
                    dispatch(push('/charge/receipt', locationData)) :
                    dispatch(push('/charge/success', locationData))
            })
            .finally(() => {
                setChargeSending(false)
                dispatch(hidePopover())
            })
    }, [chargeSending, paymentData, client, recordType, record, owner?.id, dispatch])

    useEffect(() => {
        setLoading(true)
        const promises: PromiseLike<any>[] = [
            Result(dispatch(fetchReaders(true))),
        ]
        if (paymentData?.client) {
            promises.push(
                apiClient.post('/client/sync', {sources: true, person: paymentData.client})
                    .then(resp => resp.data)
                    .then(json => {
                        if (json.sources)
                            setSources(json.sources)
                    })
            )
        }

        Promise.all(promises).finally(() => setLoading(false))
    }, [dispatch, paymentData?.client])

    const [reader, setReader] = useState<Reader>()
    const [, setStripeIntentId] = useState<string>()
    const abortReaderCharge = useCallback(() => {
        dispatch(hidePopover())
        setReader(currentReader => {
            setStripeIntentId(currentVal => {
                if (currentVal && currentReader) {
                    dispatch(updateReader(currentReader, undefined, true))
                }
                return undefined
            })
            return undefined
        })

        dispatch(setPendingIntent(undefined))
    }, [dispatch])

    const chargeFailedBody = useCallback(() => <>
        <div style={{marginTop: '274px', textAlign: 'center'}}>
            <div>
                <RedCircle />
            </div>
            <div style={{marginTop: '44px', fontSize: '32px', lineHeight: '40px', fontWeight: 700, color: '#F34142'}}>
                Payment declined
            </div>
            <div style={{marginTop: '23px', fontSize: '20px', lineHeight: '22px', fontWeight: 400, color: '#999999'}}>
                Please try another payment method
            </div>
            <div style={{marginTop: '164px'}}>
                <PSButton onClick={abortReaderCharge} style={{height: '43px', fontSize: '17px', lineHeight: '22px'}}>
                    <ChevronLeft style={{marginRight: '7px'}} />Go back
                </PSButton>
            </div>
        </div>
    </>, [abortReaderCharge])

    useEffect(() => {
        window.onbeforeunload = () => { abortReaderCharge() }
    }, [abortReaderCharge])

    const sendChargeToReader = useCallback((readerId: string) => {
        const reader = state.settings.readers.find(r => r.id === readerId)!
        let total = paymentData.total
        // when creating the intent, total needs to be the total + deposit
        if (!!Number(paymentData.paid_deposit))
            total += Number(paymentData.paid_deposit)
        else if (!!Number(paymentData.deposit))
            total += Number(paymentData.deposit)
        const postData = new Payment({
            ...paymentData,
            owner: owner?.id ?? paymentData.owner,
            total: total
        } as Partial<Payment>)
        Result(dispatch(createIntent(postData, reader.serial_number!, reader.reader!)))
            .then((json) => {
                setClient(json.client.id)
                setStripeIntentId(json.stripe_intent_id)
                dispatch(setPendingIntent(json.intent))
                setReader(reader)
            })
    }, [dispatch, owner?.id, paymentData, state.settings.readers])

    useEffect(() => {
        if (reader && state.transactions.completedIntent !== undefined) {
            onChargeCard(undefined, state.transactions.completedIntent)
        }
    }, [dispatch, onChargeCard, reader, state.transactions.completedIntent])


    useEffect(() => {
        if (reader && state.transactions.failedIntent !== undefined) {
            dispatch(showPopover(chargeFailedBody()))
        }
    }, [chargeFailedBody, dispatch, reader, state.transactions.failedIntent])

    const onSelected = useCallback((value: string) => {
        switch (value) {
            case "new":
                onAddNewCard()
                break
            case "other":
                onMarkPaid()
                break
        }
        if (value.startsWith("source")) {
            const sourceId = value.split('_')[1]
            onChargeCard(sourceId)
        }
        else if (value.startsWith("card_on_file"))
            onChargeCard(record!.source)
        else if (value.startsWith("reader")) {
            const processing = <div style={{marginTop: '274px', textAlign: 'center'}}>
                <div>
                    <Inline size={64} color='#314A68' />
                </div>
                <div style={{marginTop: '44px', fontSize: '32px', lineHeight: '40px', fontWeight: 700, color: '#314A68'}}>
                    Processing...
                </div>
                <div style={{marginTop: '23px', fontSize: '20px', lineHeight: '22px', fontWeight: 400, color: '#999999'}}>
                    Payment processing on card reader. Do not refresh or exit this page.
                </div>
                <div style={{marginTop: '164px'}}>
                    <PSButtonDanger onClick={abortReaderCharge}  style={{height: '43px', fontSize: '17px', lineHeight: '22px'}}>
                        <RedX style={{marginRight: '7px'}} />Cancel charge
                    </PSButtonDanger>
                </div>
            </div>
            dispatch(showPopover(processing))

            const readerId = value.split('_')[1]
            sendChargeToReader(readerId)
        }
    }, [abortReaderCharge, dispatch, onAddNewCard, onChargeCard, onMarkPaid, sendChargeToReader, record])

    let isPartialPayment = false
    if (["package", "invoice"].includes(recordType ?? '') && record && 'max_payment_count' in record && Number(record.max_payment_count || 0) > 1) {
        // @ts-ignore
        isPartialPayment = Number(record.amount ?? 0) + Number(record.discount_amount) - Number(record.paid_amount ?? 0) !== paymentData.total
    }
    const [dropdownRotate, setDropdownRotated] = useState(false)
    const component = (<Dropdown
        onSelect={item => onSelected(item)}
        onStateChange={options => options.hasOwnProperty('isOpen') && setDropdownRotated(options.isOpen ?? false)}
    >
        <Trigger>
            <PSButtonPrimary style={{height: '40px'}} onClick={() => {}} disabled={!chargeEnabled}>
                Charge
                <Button.EndIcon isRotated={dropdownRotate}>
                    <ChevronIcon />
                </Button.EndIcon>
            </PSButtonPrimary>
        </Trigger>
        <Menu className="charge-menu">
            {loading &&
                <Spinner size="128" />}
            {!loading && <>
                {owner?.allow_reader && readers.filter(r => ['online', 'in_progress'].includes(r.stripe_reader?.status)).map((r: Reader) =>
                    <Item key={`reader_${r.id}`} value={`reader_${r.id}`} disabled={readerDisabled(r)}>
                        <CardIcon />
                        <div className="text-area">
                            <div className="title">Charge using card reader</div>
                            <div className="subtitle selected">({readerTitle(r)})</div>
                        </div>
                    </Item>)
                }
                {!!record?.source && !!recordType && ['booking', 'estimate'].includes(recordType) &&
                    <Item key={`card_on_file`} value={`card_on_file`}>
                        <CardIcon />
                        <div className="text-area">
                            <div className="title">Confirmed at booking</div>
                            {'source_display' in record  && <div className="subtitle selected">({record.source_display})</div>}
                        </div>
                    </Item>
                }
                {sources.map(s =>
                    <Item key={`source_${s.id}`} value={`source_${s.id}`}>
                        <CardIcon />
                        <div className="text-area">
                            <div className="title">Existing card on file</div>
                            <div className="subtitle selected">({s.display_name})</div>
                        </div>
                    </Item>)
                }
                {paymentData?.client &&
                    <Item value="new">
                        <PlusIcon />
                        <div className="text-area">
                            <div className="title">Add new card</div>
                            <div className="subtitle">Select to add new card</div>
                        </div>
                    </Item>
                }
                {owner?.allow_mark && !isDepositPayment && !isPartialPayment &&
                    <Item value="other">
                        <MoneyIcon />
                        <div className="text-area">
                            <div className="title">Other</div>
                            <div className="subtitle">Select other payment methods</div>
                        </div>
                    </Item>
                }
            </>}
        </Menu>
    </Dropdown>)

    return [component, setChargeEnabled]
}