import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { RecordStatusBar } from '../RecordStatusBar'
import { useDispatch, useSelector } from 'react-redux'
import { currencyFormat } from '../../../utils/numbers'
import { Link } from 'react-router-dom'
import { RecordDetail } from '../RecordDetail'
import { RecordBody } from '../RecordBody'
import { ReactComponent as ChevronIcon } from '../../../icons/chevron.svg'
import { PaymentDetailsModal } from '../PaymentDetailsModal'
import { ReactComponent as ColoredChevronIcon } from '../../../icons/colored_chevron.svg'
import { Modal } from '../../app/Modal'
import { push } from 'connected-react-router'
import { RecordBookingsModalBody } from './RecordBookingsModalBody'
import { describePackage, displayStatus, Package } from '../../../models/Package'
import { RootState } from '../../../store'
import { ButtonProps, RecordButtons } from '../RecordButtons'
import {distinctButtons, isForVendor} from '../../../utils/records'
import { isPassedDate, Result } from '../../../utils'
import { cancelPackage, rollbackTransaction, sendClientReceipt, sendPackage } from '../../../modules/transactions'
import { useConfirmModal, usePSOwner } from '../../../hooks'
import { getRecord } from '../../../modules/records'
import { loadIndustry } from '../../../models/Industry'
import { API_BASE } from '../../../modules/apiclient'

const displaySessions = (record: Package) => {
    if (record.capacity_type === 'session')
        return `${record.capacity - (record.number_of_bookings ?? 0)} left`
    else
        return `${moment.duration(record.available - record.duration, 'seconds').humanize()} left`
}

export const RecordPackage = (props: any) => {
    const record = new Package(props.record)
    const dispatch = useDispatch()
    const state = useSelector((state: RootState) => (
        {
            entities: state.entities,
            records: state.records,
        }
    ))

    const bookings = state.records.apiResponse.bookings.sort((a: any, b: any) => moment(a.date).isBefore(b.date))
    const remaining = record.capacity_type === "session" ?
        record.capacity - bookings.length :
        record.capacity - (bookings.length ? bookings.map((b: any) => b.duration).reduce((total: number, val: number) => total + val) : 0)

    const [showModal, setShowModal] = useState(false)
    const [showSessionsModal, setShowSessionsModal] = useState(false)

    const item = state.entities.items[record.item!] || {}
    const client = state.entities.contacts[record.customer] || {}
    const payer = state.entities.contacts[record.client] || {}
    const formattedRate = record.total ? currencyFormat(record.asking_amount) : 'No total'
    const form = state.entities.signatures[record.form]
    const contract = state.entities.signatures[record.contract]

    const goBooking = (booking: any) => {
        dispatch(push(`/record/booking/${booking.id}`))
    }

    const statuses = [
        {label: 'Sent', completed: !!record.sent_date},
        {label: 'Viewed', completed: !!record.viewed_date},
        {label: 'Paid', completed: !!record.paid_date},
    ]

    const recordDetails = []

    const clientDetails = (
        <Link to={`/record/client/${client.id}`}>
            {client.name}
            <ChevronIcon className="chevron" />
        </Link>
    )
    recordDetails.push(
        <RecordDetail header="Client"
                      details={clientDetails}
                      subtitle={displayStatus(record)} />
    )

    let sessionDetails: string | JSX.Element = displaySessions(record)
    if (record.number_of_bookings) {
        // Link to a modal
        sessionDetails = (
            <div className="buttonLink" onClick={e => setShowSessionsModal(true)}>
                {sessionDetails}
                <ChevronIcon className="chevron" />
            </div>
        )
    }
    recordDetails.push(
        <RecordDetail header="Sessions" details={sessionDetails} />
    )

    if (record.memo) {
        recordDetails.push(
            <RecordDetail header="Description" details={record.memo} isText />
        )
    }

    if (payer.id !== client.id) {
        const details = (
            <Link to={`/record/client/${payer.id}`}>
                {payer.name}
                <ChevronIcon className="chevron" />
            </Link>
        )
        recordDetails.push(
            <RecordDetail header="Paid by" details={details} />
        )
    }

    if (record.last_payment_date) {
        recordDetails.push(
            <RecordDetail header={record.paid_date ? "Payment date" : "Last payment date"}
                          details={moment(record.last_payment_date).format('MMM Do, yyyy')} />
        )
    }

    const [setCtaModalInfo, setCtaModalLoading, showCtaModal, closeCtaModal, ctaModalComponent] = useConfirmModal()

    const owner = usePSOwner()

    const makeCopy = useCallback(() => {
        const copy = new Package({
            client: record.customer,
            vendor: record.vendor,
            total: record.total,
            item: record.item,
            expires: record.expires,
            capacity: record.capacity,
            capacity_type: record.capacity_type,
            reminder: record.reminder,
            channel: record.channel,
            memo: record.memo,
            allow_surcharge: record.allow_surcharge,
            max_payment_count: record.max_payment_count,
            owner: record.owner,
        })

        if (owner?.allow_discount) {
            copy.discount = record.discount
            copy.discount_type = record.discount_type
        }
        if (owner?.allow_staffing) {
            copy.employee = record.employee
        }
        if (record.form && form?.always_require) {
            copy.form = record.form
        }
        if (record.contract && contract?.always_require) {
            copy.contract = record.form
        }

        dispatch(push('/package/new', { package: copy }))
    }, [contract?.always_require, dispatch, form, record, owner])

    const [industry, setIndustry] = useState<any>()
    useEffect(() => {
        if (!owner) return
        if (industry?.id === owner.industry) return

        loadIndustry(owner?.industry)
            .then((found) => setIndustry(found))

    }, [industry?.id, owner])

    const bookPackage = useCallback(() => {
        dispatch(push(`/schedule/new`, {
            package: {
                ...record,
                duration: industry?.duration,
                employee: owner?.allow_staffing ? record.employee : undefined
            },
            item,
            client
        }))
    }, [client, dispatch, industry?.duration, item, record, owner?.allow_staffing])

    const viewForm = useCallback(() =>
        dispatch(push(`/record/record/${record.record}`)), [record.record, dispatch])

    const viewPayments = useCallback(() =>
        dispatch(push(`/payments/package/${record.id}`)), [dispatch, record.id])

    const viewContract = useCallback(() =>
        window.open(`${API_BASE}/package/${record.id}/contract`, "_blank"), [record.id])

    const sendMessage = useCallback(() =>
        dispatch(push(`/messages/${record.client}`)), [dispatch, record.client])

    const sendReceipt = useCallback(() => {
        setCtaModalInfo({
            title: "Send receipt",
            body: "Would you like to send a receipt to this client?",
            button: "Send receipt",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(sendClientReceipt(record.id!, 'package')))
                    .then(closeCtaModal)
                    .then(sendMessage)
            }
        })
        showCtaModal()
    }, [setCtaModalInfo, showCtaModal, setCtaModalLoading, dispatch, record.id, closeCtaModal, sendMessage])

    const undoMarkPaid = useCallback(() => {
        setCtaModalInfo({
            title: "Undo mark paid",
            body: "Would you like to undo this payment?",
            button: "Undo mark paid",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(rollbackTransaction(record.id!, 'package')))
                    .then(() => dispatch(getRecord('package', record.id!)))
                    .then(closeCtaModal)
            },
            danger: true
        })
        showCtaModal()
    }, [closeCtaModal, dispatch, record.id, setCtaModalInfo, setCtaModalLoading, showCtaModal])

    const undoCancel = useCallback(() => {
        setCtaModalInfo({
            title: "Undo cancellation",
            body: "Would you like to undo the appointment cancellation?",
            button: "Undo cancellation",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(rollbackTransaction(record.id!, 'package')))
                    .then(() => dispatch(getRecord('package', record.id!)))
                    .then(closeCtaModal)
            },
            danger: true
        })
        showCtaModal()
    }, [closeCtaModal, dispatch, record.id, setCtaModalInfo, setCtaModalLoading, showCtaModal])

    const showCancelPackage = useCallback(() => {
        setCtaModalInfo({
            title: "Confirm cancellation",
            body: "Would you like to cancel this package? ",
            button: "Confirm",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(cancelPackage(record.id!)))
                    .then(() => dispatch(getRecord('package', record.id!)))
                    .then(closeCtaModal)
            }
        })
        showCtaModal()
    }, [closeCtaModal, dispatch, record.id, setCtaModalInfo, setCtaModalLoading, showCtaModal])

    const chargePackage = useCallback(() =>
        dispatch(push(`/charge/package/${record.id}`, {package: record}))
    , [record, dispatch])

    const resendPackage = useCallback(() => {
        setCtaModalInfo({
            title: "Resend package",
            body: "Would you like to resend this package to this client?",
            button: "Resend",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(sendPackage(record)))
                    .then(() => dispatch(push(`/package/success/${record.id}`)))
                    .then(closeCtaModal)
            }
        })
        showCtaModal()
    }, [closeCtaModal, dispatch, record, setCtaModalInfo, setCtaModalLoading, showCtaModal])


    const getButtons = useCallback((): [ButtonProps, ButtonProps[]] => {
        let primaryButton: ButtonProps = { text: "Edit", onClick: () => dispatch(push(`/package/${record.id}/edit`))}
        const buttons: ButtonProps[] = []

        const schedulable = record.paid_date && !record.cancelled_date && remaining > 0 && !isPassedDate(moment(record.expired_date).startOf('day').add(1, 'days').toDate())
        if (!record.paid_date && !record.cancelled_date && record.calculateChargeAmount() > 0) {
            buttons.push(primaryButton)
            primaryButton = { text: "Charge", onClick: chargePackage }
        }
        else if (!record.paid_date && !record.cancelled_date) {
            buttons.push({ text: "Mark paid", onClick: chargePackage })
        }
        else if (schedulable) {
            buttons.push({ text: "Schedule", onClick: bookPackage })
        }
        else {
            buttons.push({ text: "Copy", tooltip: "Make copy", onClick: makeCopy})
        }
        buttons.push({ text: "Resend", onClick: resendPackage})
        buttons.push({ text: "Chat", tooltip: "Send message", onClick: sendMessage})
        if (record.mark_paid_date) {
            buttons.push({ text: "Receipt", tooltip: "Send receipt", onClick: sendReceipt})
        }
        if (record.record && record.captured_date && !record.cancelled_date) {
            buttons.push({ text: "Form", tooltip: "View form", onClick: viewForm})
        }
        if (record.contract && !record.cancelled_date) {
            buttons.push({ text: "Contract", tooltip: "View contract", onClick: viewContract})
        }
        if (!record.cancelled_date && !record.completed_date) {
            buttons.push({ text: "Cancel", onClick: showCancelPackage})
        }
        if (record.mark_paid_date && isForVendor(record, owner)) {
            buttons.push({ text: "Undo", tooltip: "Undo paid", onClick: undoMarkPaid})
        }
        else if (record.cancelled_date && record.source && isForVendor(record, owner)) {
            buttons.push({ text: "Undo", tooltip: "Undo cancel", onClick: undoCancel})
        }
        if (Number(record.paid_amount) > 0) {
            buttons.push({ text: "Payments", tooltip: "View payments", onClick: viewPayments})
        }
        buttons.push({ text: "Copy", tooltip: "Make copy", onClick: makeCopy})

        return [primaryButton, distinctButtons(buttons)]
    }, [bookPackage, chargePackage, dispatch, makeCopy, owner, record, remaining, resendPackage, sendMessage, sendReceipt, showCancelPackage, undoCancel, undoMarkPaid, viewContract, viewForm, viewPayments])


    const [primaryButton, buttons] = getButtons()
    return (
        <div className="recordView">
            <div className="topBar">
                <RecordStatusBar statuses={statuses}/>
                <div className="actions">
                    <RecordButtons primaryButton={primaryButton} buttons={buttons} />
                </div>
            </div>
            <div className="recordHeader">
                <h2 className="payment" onClick={e => setShowModal(true)}>
                    {formattedRate}
                    <ColoredChevronIcon fill="#62CE9A" />
                </h2>
                <div className="title">{item.name}</div>
                <div className="time">{describePackage(record, remaining, true)}</div>
            </div>
            <RecordBody recordDetails={recordDetails} />
            <PaymentDetailsModal visible={showModal}
                                 onClose={() => setShowModal(false)}
                                 type="package"
                                 record={record} />
            <Modal visible={showSessionsModal}
                   onClose={() => setShowSessionsModal(false)}>
                <RecordBookingsModalBody
                    bookings={bookings}
                    contacts={state.entities.contacts}
                    isLoading={false}
                    goBooking={goBooking} />
            </Modal>
            {ctaModalComponent}
        </div>
    )
}