import React, {useCallback, useEffect, useState} from "react";
import {useAppDispatch, usePSOwner} from "../../../hooks";
import {PSDropdown} from "../../app/PSDropdown";
import {readerTypeOptions, SelectOption} from "../Options";
import {Item} from "@zendeskgarden/react-dropdowns";
import {Field, Hint, Input, Label} from '@zendeskgarden/react-forms';
import {ReactComponent as LeftArrow} from "../../../icons/left-arrow.svg";
import {ReactComponent as AddPhotoImage} from "../../../icons/add_photo.svg";
import {PSButton, PSButtonPrimary} from "../../app/PSButton";
import { isInternetReader, Reader } from "../../../models/Reader";
import { updateReader } from "../../../modules/settings";
import { Result } from "../../../utils";
import { useHistory, useLocation, useParams } from "react-router";
import Cropper, { Area, MediaSize } from 'react-easy-crop'
import { usePhotoUpload } from "../hooks";
import moment from "moment";
import { Spinner } from "@zendeskgarden/react-loaders";
import { PSLearnMore } from "../../app/PSLearnMore";

const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', (error) => reject(error))
        image.src = url
    })

const getCroppedImg = async (imageSrc: string, pixelCrop: Area) : Promise<File|undefined> => {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (!ctx) {
        return
    }

    // set canvas size to match the bounding box
    canvas.width = image.width
    canvas.height = image.height

    // draw rotated image
    ctx.drawImage(image, 0, 0)

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height
    )

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height
    ctx.putImageData(data, 0, 0)

    // As a blob
    return new Promise((resolve, reject) => {
        canvas.toBlob((blob: Blob | null) => {
            if (!blob || blob == null) {
                reject()
            }
            else {
                resolve(new File([blob], 'croppedImage.jpg', {type: 'image/jpeg', lastModified: Date.now()}))
            }
        }, 'image/jpeg')
    })
}

export const AddCardReader = () => {
    const params = useParams<{readerID?: string}>()
    const location = useLocation()
    const history = useHistory()

    const locationState: {reader?: Reader} | undefined = location.state as {reader?: Reader}
    const isActivate = !!params.readerID && location.pathname.endsWith('activate')
    const isEdit = !!params.readerID && location.pathname.endsWith('edit')
    const [croppedFile, setCroppedFile] = useState<File>()
    const [saveEnabled, setSaveEnabled] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [srcFile, setSrcFile] = useState<string|ArrayBuffer|null>()

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)

    if (isActivate && !locationState?.reader) {
        history.goBack();
    }

    const [learnMoreComponent, showLearnMore] = PSLearnMore([
        { key: 'activate', title: 'Registration code', body: 'To access your registration code swipe left to right on the screen and select Settings.  After entering your pin select "Generate pairing code".' }
    ]);

    const dispatch = useAppDispatch()
    const owner = usePSOwner()
    const [photo, setPhoto] = useState<undefined | string | File>('')
    const [addPhotoRef, selectPhoto, selectedPhoto] = usePhotoUpload({disabled: false, setPhoto})
    useEffect(() => {
        if (photo && typeof photo !== 'string') {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setSrcFile(reader.result)
            })
            reader.readAsDataURL(photo);
        }
    }, [photo])

    const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        if (!srcFile || typeof srcFile !== 'string') return

      getCroppedImg(srcFile, croppedAreaPixels)
        .then((file?: File) => {
            setCroppedFile(file)
        })
    }, [srcFile])

    const requiredAspectRatio = 720 / 1280
    const containerWidth = 568
    const [containerHeight, setContainerHeight] = useState<number>(426)
    const onMediaLoaded = useCallback((mediaSize: MediaSize) => {
        setContainerHeight(containerHeight => {
            const mediaAspectRatio = mediaSize.naturalWidth / mediaSize.naturalHeight
            const containerAspectRatio = containerWidth / containerHeight
            if (mediaAspectRatio > containerAspectRatio) {
                return containerWidth / mediaAspectRatio
            }
            return Math.min(containerHeight, mediaSize.naturalHeight)
        })

        const height = mediaSize.naturalHeight
        const width = Math.min(mediaSize.naturalWidth, mediaSize.naturalHeight * requiredAspectRatio)
        const minPixelBlur = 720*1280/4 // pixelation in 2 pixels in each direction
        if (height * width < minPixelBlur) {
            console.log("warning low resolution image")
        }
    }, [requiredAspectRatio])

    const [label, setLabel] = useState<string>()
    const [serialNumber, setSerialNumber] = useState<string>()

    const [readerType, setReaderType] = useState<SelectOption>()
    useEffect(() => {
        if (locationState?.reader?.type)
            setReaderType(readerTypeOptions().find(option => option.value === locationState.reader!.type))
        else
            setReaderType(readerTypeOptions()[0])

        if (locationState?.reader?.label)
            setLabel(locationState.reader.label)

        if (locationState?.reader?.image_uri)
            setPhoto(locationState.reader.image_uri)
    }, [locationState?.reader])

    const saveReader = () => {
        if (!owner)
            return

        const maxFileSize = 2 * 1024 * 1024
        if (croppedFile && croppedFile.size > maxFileSize) {
            // todo error file too large
        }
        const smallFile = 10 * 1024
        if (smallFile) {
            // todo warn about small file? seems like overkill
        }
        const type = readerType?.value as 'stripe_m2' | 'bbpos_chipper2x' | 'bbpos_wisee'
        const reader: any = {
            id: locationState?.reader?.id,
            label,
            serial_number: !isInternetReader(type) ? serialNumber : undefined,
            registration_code: isInternetReader(type) ? serialNumber : undefined,
            type: type,
            line1: locationState?.reader?.line1 ?? owner.line1,
            line2: (locationState?.reader?.line2 ?? owner.line2) || undefined,
            city: locationState?.reader?.city ?? owner.city,
            state: locationState?.reader?.state ?? owner.state,
            postal_code: locationState?.reader?.postal_code ?? owner.postal_code,
        }

        setIsLoading(true)
        Result(dispatch(updateReader(reader, croppedFile)))
            .then(() => {
                history.goBack()
            })
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        const isLabelValid = !isInternetReader(readerType?.value) || !!label
        const isAddValid = !isEdit && !isActivate && isLabelValid && !!serialNumber
        const isEditValid = (isEdit || isActivate) && isLabelValid
        setSaveEnabled(isLabelValid && (isEditValid || isAddValid))
    }, [isActivate, isEdit, label, readerType?.value, serialNumber])

    let title = 'Add existing reader'
    if (isActivate) {
        title = 'Activate reader'
    }
    else if (isEdit) {
        title = 'Edit reader'
    }

    if (isLoading) {
        return <Spinner size="128" />
    }
    return (
        <div className="servicesSettings" style={{margin: '0'}}>
            <div className="header">
                <PSButton style={{marginLeft: 0, marginRight: 16}} onClick={() => history.goBack()}>
                    <LeftArrow />
                </PSButton>
                <h1>{title}</h1>
                <PSButtonPrimary style={{height: '40px', marginRight: '8px', marginLeft: 'auto'}}
                                 disabled={!saveEnabled}
                                 onClick={() => saveReader()}>
                    Done
                </PSButtonPrimary>
            </div>
            <div className="separator" />
            <div className="form">
                {!isActivate && !isEdit && (
                    <PSDropdown selected={readerType}
                                nameProperty={"label"}
                                onSelect={(selected) => setReaderType(selected)}
                                label={"Choose a card reader"}>
                        <>
                            {readerTypeOptions().map(option => (
                                <Item key={`reader-type-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                )}
                {!isEdit && <>
                    <Field className='field'>
                        <Label className='label'>{isInternetReader(readerType?.value) ? 'Type registration code (required)' : 'Type serial number (required)'}</Label>
                        <Input placeholder='Please enter'
                            value={serialNumber}
                            onChange={e => setSerialNumber(e.target.value)} />
                    </Field>
                    <div className="buttonLink brightBlue"
                        style={{display: 'inline-block', paddingTop: 8}}
                        onClick={() => showLearnMore('activate')}>
                        Learn more &gt;
                    </div>
                </>}
                <Field className='field'>
                    <Label className='label'>Reader Label{isInternetReader(readerType?.value) ? ` (required)` : ''}</Label>
                    <Hint>What would you like to name this reader?</Hint>
                    <Input placeholder='Please enter'
                        value={label}
                        onChange={e => setLabel(e.target.value)} />
                </Field>
                {isInternetReader(readerType?.value) && <>
                    <Field style={{marginTop: '36px'}}>
                        <Label className='label'>{isActivate ? `Add a splashscreen to your reader?` : `Splashscreen`}</Label>
                        <div style={{marginTop: '18px'}}>
                            {!photo && (
                                <AddPhotoImage style={{cursor: 'pointer'}}
                                                onClick={() => selectPhoto()} />
                            )}
                            {!srcFile && !!photo && typeof photo === 'string' &&
                                <img alt='splashscreen'  src={`${photo}?q=${moment().unix()}`}
                                    style={{maxWidth: `${containerWidth}px`, maxHeight: `${containerHeight}px`}}/>
                            }
                            {srcFile && <>
                                <Cropper style={{
                                        containerStyle: {position: 'relative', width: `${containerWidth}px`, height: `${containerHeight}px`},
                                    }}
                                    image={srcFile as string}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={requiredAspectRatio}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onMediaLoaded={onMediaLoaded}
                                    onZoomChange={setZoom}
                                    />
                                <div className="controls" style={{marginTop: '18px', display: 'flex'}}>
                                    <div style={{fontSize: '20px'}}>-</div>
                                    <input type="range" value={zoom} min={1} max={3} step={0.1} className="zoom-range"
                                        style={{height:'2px', width: '100%', margin: '14px'}}
                                        onChange={(e) => {
                                            setZoom(Number(e.target.value))
                                        }}
                                    />
                                    <div style={{fontSize: '20px'}}>+</div>
                                </div>
                            </>}
                        </div>
                    </Field>
                    <div style={{marginBottom: '65px'}}>
                        <PSButton
                            onClick={() => selectPhoto()}
                            style={{borderRadius: '20px', fontWeight: 700, marginTop: '18px'}}>
                            {photo && "Edit image"}
                            {!photo && "Add image"}
                        </PSButton>
                        <input type="file"
                                ref={addPhotoRef}
                                onChange={selectedPhoto}
                                accept="image/png,image/jpg,image/jpeg"
                                style={{display: 'none'}} />
                    </div>
                </>}
            </div>
            {learnMoreComponent}
        </div>
    )
}


