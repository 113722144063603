import React, { useCallback, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { currencyFormat } from '../../../utils/numbers'
import { RecordStatusBar } from '../RecordStatusBar'
import { Link } from 'react-router-dom'
import { RecordDetail } from '../RecordDetail'
import { RecordBody } from '../RecordBody'
import { ReactComponent as ChevronIcon } from '../../../icons/chevron.svg'
import { PaymentDetailsModal } from '../PaymentDetailsModal'
import { push } from 'connected-react-router'
import { ReactComponent as ColoredChevronIcon } from '../../../icons/colored_chevron.svg'
import { GiftCertificate } from '../../../models/GiftCertificate'
import { RootState } from '../../../store'
import {distinctButtons, isForVendor} from '../../../utils/records'
import { ButtonProps, RecordButtons } from '../RecordButtons'
import { API_BASE } from '../../../modules/apiclient'
import { cancelGift, rollbackTransaction, sendClientReceipt, sendGift } from '../../../modules/transactions'
import { getRecord } from '../../../modules/records'
import { Result } from '../../../utils'
import {useConfirmModal, usePSOwner} from '../../../hooks'
import { Payment } from '../../../models/Payment'

const displayDetails = (record: GiftCertificate) => {
    let displayExpire: string | undefined
    if (record.expired_date && record.paid_date)
        displayExpire = `expires ${moment(record.expired_date).format('MMM DD, YYYY')}`
    else {
        displayExpire = {
            '360': '1 year',
            '720': '2 years',
            '1800': '5 years',
        }[record.expires ?? ''] || 'No expiration'
    }

    if (record.total)
        return `${currencyFormat(record.asking_amount)} total · ${displayExpire}`
    else
        return `No total · ${displayExpire}`
}

const displayStatus = (record: GiftCertificate) => {
    const dateFormat = (date?: string) => moment(date).format('MMM Do, yyyy')
    if (record.cancelled_date)
        return `Canceled ${dateFormat(record.cancelled_date)}`
    if (record.marked_paid_date && record.paymeth)
        return `Marked paid ${dateFormat(record.marked_paid_date)} - ${record.paymeth}`
    if (record.marked_paid_date)
        return `Marked paid ${dateFormat(record.marked_paid_date)}`
    if (record.paid_date)
        return `Paid ${dateFormat(record.paid_date)}`
    return `Sent ${dateFormat(record.created_date)} - not paid`
}

export const RecordGift = (props: any) => {
    const dispatch = useDispatch()
    const {record} = props
    const state = useSelector((state: RootState) => (
        {
            entities: state.entities,
        }
    ))

    const owner = usePSOwner()
    const item = state.entities.items[record.item] || {}
    const payer = state.entities.contacts[record.client] || {}
    const customer = state.entities.contacts[record.customer] || {}
    const employee = state.entities.contacts[record.employee] || {}

    const statuses = [
        {label: 'Sent', completed: !!record.sent_date},
        {label: 'Viewed', completed: !!record.viewed_date},
        {label: 'Paid', completed: !!record.paid_date},
    ]

    const recordDetails = []

    if (customer.id) {
        const details = (
            <Link to={`/record/client/${customer.id}`}>
                {customer.name}
                <ChevronIcon className="chevron" />
            </Link>
        )
        recordDetails.push(
            <RecordDetail header="Client"
                          details={details}
                          subtitle={displayStatus(record)}
            />
        )
    }

    if (payer.id && payer.id !== customer.id) {
        const details = (
            <Link to={`/record/client/${payer.id}`}>
                {payer.name}
                <ChevronIcon className="chevron" />
            </Link>
        )
        recordDetails.push(
            <RecordDetail header="Paid by" details={details} />
        )
    }

    if (employee.id) {
        const details = (
            <Link to={`/record/payee/${employee.id}`}>
                {employee.name}
                <ChevronIcon className="chevron" />
            </Link>
        )
        recordDetails.push(
            <RecordDetail header="Team member" details={details} />
        )
    }

    if (record.memo) {
        recordDetails.push(
            <RecordDetail header="Description" details={record.memo} isText />
        )
    }

    const [setCtaModalInfo, setCtaModalLoading, showCtaModal, closeCtaModal, ctaModalComponent] = useConfirmModal()

    const viewForm = useCallback(() =>
        dispatch(push(`/record/record/${record.record}`)), [record.record, dispatch])

    const viewPurchases = useCallback(() =>
        dispatch(push(`/purchases/gift/${record.id}`)),[record.id, dispatch])

    const viewContract = useCallback(() =>
        window.open(`${API_BASE}/gift/${record.id}/contract`, "_blank"), [record.id])

    const sendMessage = useCallback(() =>
        dispatch(push(`/messages/${record.client}`)), [dispatch, record.client])

    const chargeClient = useCallback(() => dispatch(push(`/charge/gift/${record.id}/complete`, {booking: record})), [record, dispatch])

    const copyGiftCard = useCallback(() =>
        dispatch(push(`/gift/new`, {
            gift: {
                ...record,
                id: undefined,
                paid_date: undefined
            }
        })), [dispatch, record])

    const undoMarkPaid = useCallback(() => {
        setCtaModalInfo({
            title: "Undo mark paid",
            body: "Would you like to undo this payment?",
            button: "Undo mark paid",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(rollbackTransaction(record.id, 'gift')))
                    .then(() => dispatch(getRecord('gift', record.id)))
                    .then(closeCtaModal)
            },
            danger: true
        })
        showCtaModal()
    }, [closeCtaModal, dispatch, record.id, setCtaModalInfo, setCtaModalLoading, showCtaModal])

    const undoCancel = useCallback(() => {
        setCtaModalInfo({
            title: "Undo cancellation",
            body: "Would you like to undo the gift cancellation?",
            button: "Undo cancellation",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(rollbackTransaction(record.id, 'gift')))
                    .then(() => dispatch(getRecord('gift', record.id)))
                    .then(closeCtaModal)
            },
            danger: true
        })
        showCtaModal()
    }, [closeCtaModal, dispatch, record.id, setCtaModalInfo, setCtaModalLoading, showCtaModal])

    const sendReceipt = useCallback(() => {
        setCtaModalInfo({
            title: "Send receipt",
            body: "Would you like to send a receipt to this client?",
            button: "Send receipt",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(sendClientReceipt(record.id, 'gift')))
                    .then(closeCtaModal)
                    .then(sendMessage)
            }
        })
        showCtaModal()
    }, [setCtaModalInfo, showCtaModal, setCtaModalLoading, dispatch, record.id, closeCtaModal, sendMessage])

    const showCancelModal = useCallback(() => {
        setCtaModalInfo({
            title: "Cancel gift certificate",
            body: 'Would you like to cancel this gift certificate?',
            button: 'Cancel gift certificate',
            onClick: (_) => {
                setCtaModalLoading(true)
                Result(dispatch(cancelGift(record.id)))
                    .then(() => dispatch(getRecord('gift', record.id)))
                    .then(closeCtaModal)
            },
            danger: true
        })
        showCtaModal()
    }, [closeCtaModal, dispatch, record.id, setCtaModalInfo, setCtaModalLoading, showCtaModal])

    const resendGift = useCallback(() => {
        setCtaModalInfo({
            title: "Resend gift certificate",
            body: "Would you like to resend this gift certificate to this client?",
            button: "Resend",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(sendGift(record)))
                    .then(() => dispatch(push(`/gift/success/${record.id}`)))
                    .then(closeCtaModal)
            }
        })
        showCtaModal()
    }, [closeCtaModal, dispatch, record, setCtaModalInfo, setCtaModalLoading, showCtaModal])

    const [showModal, setShowModal] = useState(false)
    const getButtons = useCallback((): [ButtonProps, ButtonProps[]] => {
        let primaryButton: ButtonProps = { text: "Edit", onClick: () => dispatch(push(`/gift/${record.id}/edit`))}
        const buttons: ButtonProps[] = []
        const payment = new Payment(record)
        if (!record.paid_date && !record.cancelled_date && payment.calculateChargeAmount() > 0) {
            buttons.push(primaryButton)
            primaryButton = { text: "Charge", onClick: chargeClient }
        }
        else if (!record.paid_date && !record.cancelled_date) {
            buttons.push({ text: "Mark paid", onClick: chargeClient })
        }
        else if (record.paid_date && Number(record.total) > Number(record.balance)) {
            buttons.push({ text: "Purchases", onClick: viewPurchases })
        }
        else {
            buttons.push({ text: "Copy", onClick: copyGiftCard})
        }
        buttons.push({ text: "Resend", onClick: resendGift})
        buttons.push({ text: "Chat", tooltip: "Send message", onClick: sendMessage})
        if (record.mark_paid_date) {
            buttons.push({ text: "Receipt", tooltip: "Send receipt", onClick: sendReceipt})
        }
        if (record.record && record.captured_date && !record.cancelled_date) {
            buttons.push({ text: "Form", tooltip: "View form", onClick: viewForm})
        }
        if (record.contract && !record.cancelled_date) {
            buttons.push({ text: "Contract", tooltip: "View contract", onClick: viewContract})
        }
        if (!record.cancelled_date && !record.paid_date) {
            buttons.push({ text: "Cancel", onClick: showCancelModal})
        }
        if (record.mark_paid_date && isForVendor(record, owner)) {
            buttons.push({ text: "Undo", tooltip: "Undo paid", onClick: undoMarkPaid})
        }
        else if (record.cancelled_date && isForVendor(record, owner)) {
            buttons.push({ text: "Undo", tooltip: "Undo cancel", onClick: undoCancel})
        }
        buttons.push({ text: "Copy", tooltip: "Make copy", onClick: copyGiftCard})

        return [primaryButton, distinctButtons(buttons)]
    }, [chargeClient, copyGiftCard, dispatch, owner, record, resendGift, sendMessage, sendReceipt, showCancelModal, undoCancel, undoMarkPaid, viewContract, viewForm, viewPurchases])

    const [primaryButton, buttons] = getButtons()
    return (
        <div className="recordView">
            <div className="topBar">
                <RecordStatusBar statuses={statuses} />
                <div className="actions">
                    <RecordButtons primaryButton={primaryButton} buttons={buttons} />
                </div>
            </div>
            <div className="recordHeader">
                <h2 className="payment" onClick={e => setShowModal(true)}>
                    {currencyFormat(record.balance)} total
                    <ColoredChevronIcon fill="#62CE9A" />
                </h2>
                <div className="title">{item.name}</div>
                <div className="time">{displayDetails(record)}</div>
            </div>
            <RecordBody recordDetails={recordDetails} />
            <PaymentDetailsModal visible={showModal}
                                 onClose={() => setShowModal(false)}
                                 type="gift"
                                 record={record} />
            {ctaModalComponent}
        </div>
    )
}
