import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import { ReactComponent as LeftArrow } from '../../../icons/left-arrow.svg'
import {PSButton} from "../../app/PSButton";
import {ScheduleCard} from "./ScheduleCard";
import {Schedule} from "../../../models/Schedule";
import {AddLessonModal} from "./AddLessonModal";
import {Spinner} from "@zendeskgarden/react-loaders";
import {GuardrailModal} from "../GuardrailModal";

type Props = {
    pendingLessons: any[],
    onUpdateLessons: (lessons: any[]) => void,
}

export const ClassesSchedule = ({pendingLessons, onUpdateLessons}: Props) => {
    const params: any = useParams()

    const [isLoading, setIsLoading] = useState(true)
    const [multipleLessons, setMultipleLessons] = useState<Schedule[]>([])
    const [singleLessons, setSingleLessons] = useState<Schedule[]>([])

    const [classData, setClassData] = useState<Schedule | undefined>()

    const [showAddSingleModal, setShowAddSingleModal] = useState(false)
    const [showAddMultipleModal, setShowAddMultipleModal] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<Schedule | undefined>()

    useEffect(() => {
        const multipleLessons = pendingLessons
            .filter((c: any) => !!c.schedule)
            .map((c: any, index) => {
                const s = new Schedule()
                s.setData(c)
                s.temporary_id = `multiple-${index}`
                return s
            })
        const singleLessons = pendingLessons
            .filter((c: any) => !c.schedule)
            .map((c: any, index) => {
                const s = new Schedule()
                s.setData(c)
                s.temporary_id = `single-${index}`
                return s
            })

        setMultipleLessons(multipleLessons)
        setSingleLessons(singleLessons)
        setIsLoading(false)
    }, [params.classID, pendingLessons])

    const goPreviousPage = () => {
        onUpdateLessons(singleLessons.slice().concat(multipleLessons))
    }

    if (isLoading) {
        return (
            <div className="servicesSettings classSchedule">
                <div className="header">
                    <PSButton style={{marginLeft: 0}}
                              onClick={() => goPreviousPage()}
                    >
                        <LeftArrow />
                    </PSButton>
                    <h1>Class schedule</h1>
                    <PSButton style={{marginLeft: 'auto'}}
                              onClick={() => {
                                  setClassData(undefined)
                                  setShowAddMultipleModal(true)
                              }}
                    >
                        Add multiple lessons
                    </PSButton>
                    <PSButton style={{marginLeft: '16px'}}
                              onClick={() => {
                                  setClassData(undefined)
                                  setShowAddSingleModal(true)
                              }}
                    >
                        Add a single lesson
                    </PSButton>
                </div>
                <div className="separator" />
                <div>
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="servicesSettings classSchedule">
            <div className="header">
                <PSButton style={{marginLeft: 0}}
                          onClick={() => goPreviousPage()}
                >
                    <LeftArrow />
                </PSButton>
                <h1>Class schedule</h1>
                <PSButton style={{marginLeft: 'auto'}}
                          onClick={() => {
                              setClassData(undefined)
                              setShowAddMultipleModal(true)
                          }}
                >
                    Add multiple lessons
                </PSButton>
                <PSButton style={{marginLeft: '16px'}}
                          onClick={() => {
                              setClassData(undefined)
                              setShowAddSingleModal(true)
                          }}
                >
                    Add a single lesson
                </PSButton>
            </div>
            <div className="separator" />
            <div>
                {multipleLessons.map((schedule) => {
                    return <ScheduleCard key={`multiple-${schedule.id || schedule.temporary_id}`}
                                         schedule={schedule}
                                         onEdit={() => {
                                             setClassData(schedule)
                                             setShowAddMultipleModal(true)
                                         }}
                                         onDelete={() => {
                                             setShowDeleteConfirmation(schedule)
                                         }}
                                         type="multiple" />
                })}
                {singleLessons.map((schedule) => {
                    return <ScheduleCard key={`single-${schedule.id || schedule.temporary_id}`}
                                         schedule={schedule}
                                         onEdit={() => {
                                             setClassData(schedule)
                                             setShowAddSingleModal(true)
                                         }}
                                         onDelete={() => {
                                             setShowDeleteConfirmation(schedule)
                                         }}
                                         type="single" />
                })}
            </div>
            {(showAddSingleModal || showAddMultipleModal) && (
                <AddLessonModal onClose={() => {
                                    setShowAddSingleModal(false)
                                    setShowAddMultipleModal(false)
                                }}
                                onDone={(data: any) => {
                                    const s = new Schedule()
                                    s.setData(data)
                                    s.temporary_id = data.id

                                    if (data.schedule) {
                                        const updatedLessons = multipleLessons.slice()
                                        if (classData) {
                                            // Update?
                                            const idx = multipleLessons.findIndex((s) => s.temporary_id === data.id)
                                            updatedLessons.splice(idx, 1, s)
                                        }
                                        else {
                                            // New?
                                            updatedLessons.push(s)
                                        }
                                        setMultipleLessons(updatedLessons)
                                    }
                                    else {
                                        const updatedLessons = singleLessons.slice()
                                        if (classData) {
                                            const idx = singleLessons.findIndex((s) => s.temporary_id === data.id)
                                            updatedLessons.splice(idx, 1, s)
                                        }
                                        else {
                                            updatedLessons.push(s)
                                        }
                                        setSingleLessons(updatedLessons)
                                    }

                                    setClassData(undefined)
                                    setShowAddSingleModal(false)
                                    setShowAddMultipleModal(false)
                                }}
                                isSingleLesson={showAddSingleModal}
                                showAllFields={false}
                                classID={params.classID}
                                classData={classData}
                                temporaryObject={true}
                />
            )}
            {showDeleteConfirmation && (
                <GuardrailModal title="Are you sure?"
                                body="Are you sure you'd like to delete this lesson?"
                                buttonText="Delete"
                                onClose={() => setShowDeleteConfirmation(undefined)}
                                onAction={() => {
                                    // Delete it from the appropriate array
                                    const isMultiple = !!showDeleteConfirmation.schedule
                                    if (isMultiple)
                                        setMultipleLessons(multipleLessons.filter((s) => s.temporary_id !== showDeleteConfirmation?.temporary_id))
                                    else
                                        setSingleLessons(singleLessons.filter(s => s.temporary_id !== showDeleteConfirmation?.temporary_id))
                                    setShowDeleteConfirmation(undefined)
                                }}
                />
            )}
        </div>
    )
}