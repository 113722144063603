import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useAppDispatch, usePSUser} from "../../../hooks";
import {
    cancelCredential,
    saveCalendars,
    SettingsState,
    fetchCalendarsWithCredentials,
} from "../../../modules/settings";
import {RootState} from "../../../store";
import {useSelector} from "react-redux";
import {PSButtonDanger, PSButtonPrimary} from "../../app/PSButton";
import {push } from 'connected-react-router';
import {CredentialCard} from "./CredentialCard";
import {
    Checkbox,
    Field,
    Hint,
    Label,
} from "@zendeskgarden/react-forms";
import {Dots} from "@zendeskgarden/react-loaders";
import { Calendar, useCalendarSyncLocationState } from "../../../models/Calendar";
import { Credential } from "../../../models/Credential";
import {Spinner} from "@zendeskgarden/react-loaders";
import { fetchDashboards } from "../../../modules/dashboard";


export const ExternalCalendarSettings = () => {
    const locationState = useCalendarSyncLocationState()
    const params = useParams<{credentialID?: string}>()
    const user = usePSUser()
    const dispatch = useAppDispatch()

    const settingsState: SettingsState = useSelector((state: RootState) => state.settings)

    const [credential, setCredential] = useState<Credential>()

    const [offerOnlyMine, setOfferOnlyMine] = useState<boolean>(false)

    const [hideClientInformation, setHideClientInformation] = useState<boolean>(false)
    const [onlyMine, setOnlyMine] = useState<boolean>(false)

    const [formSending, setFormSending] = useState<boolean>()

    const [calendars, setCalendars] = useState<Calendar[]>([])
    const [changes, setChanges] = useState(false)

    const [loading, setLoading] = useState<boolean>(true)

    const [requestedCalendars, setRequestedCalendars] = useState(false)

    useEffect(() => {
        if (!loading) return

        if (!!locationState?.credential?.id.length && !!locationState?.calendars?.length) {
            console.log("Getting it from locationState")
            setCredential(c => locationState?.credential ?? c)
            setCalendars(c => locationState?.calendars ?? c)
            setChanges(c => locationState?.changes ?? c)
            setOnlyMine(current => locationState?.onlyMine ?? current)
            setHideClientInformation(current => locationState?.hideClientInformation ?? current)
            setLoading(false)
        }
        else if (!! params?.credentialID?.length && !requestedCalendars) {
            setRequestedCalendars(true)
            dispatch(fetchCalendarsWithCredentials())
        }
    }, [dispatch, locationState, params, loading, requestedCalendars])

    useEffect(() => {
        if (settingsState.isSending) return
        if (!loading) return

        if (requestedCalendars && settingsState.credentials.length && settingsState.calendars.length) {
            setCredential(settingsState.credentials.find(credential => credential.id === params?.credentialID))
            setCalendars(
                settingsState.calendars.filter(calendar => calendar.credential === params?.credentialID)
                    .sort((a: Calendar, b: Calendar) => {
                        return a.summary.localeCompare(b.summary)
                    })
            )
            setOnlyMine(settingsState.calendars.find(calendar => calendar.credential === params?.credentialID)?.only_mine ?? false)
            setHideClientInformation(settingsState.calendars.find(calendar => calendar.credential === params?.credentialID)?.hipaa ?? false)
            setLoading(false)
        }
    }, [settingsState.credentials, settingsState.calendars, settingsState.isSending, loading, requestedCalendars, params])

    useEffect(() => {
        setOfferOnlyMine(onlyMine || user?.role === 'admin' || user?.type === 'company' || user?.type === 'client')
    }, [user, onlyMine])

    const onDisconnectSync = () => {
        if (!credential) return

        setFormSending(true)

        for (let i = 0; i < calendars.length || 0; i++) {
            calendars[i].sync_to = false
            calendars[i].sync_from = false
        }
        const promises = [
            dispatch(saveCalendars(calendars)),
            dispatch(cancelCredential(credential)),
        ]
        Promise.all(promises).then(()=> setFormSending(false)).then(() => dispatch(push('/settings/calendar/sync')))
    }

    const onSave = () => {
        setFormSending(true)
        for (let i = 0; i < calendars.length || 0; i++) {
            calendars[i].hipaa = hideClientInformation
            calendars[i].only_mine = onlyMine
        }

        Promise.all([dispatch(saveCalendars(calendars))]).then(() => {
            Promise.all([dispatch(fetchDashboards('main'))]).then(() => {
                dispatch(push('/settings/calendar/sync'))
            })
        })
    }

    const drillDown = (url: string) => {
        dispatch(push(url,
        {
            credential: credential,
            calendars: calendars,
            changes: changes,
            onlyMine: onlyMine,
            hideClientInformation: hideClientInformation
        }))
    }

    const editSyncTo = () => {
        drillDown(`/settings/calendar/sync_to/${credential?.id}`)
    }

    const editSyncFrom = () => {
        drillDown(`/settings/calendar/sync_from/${credential?.id}`)
    }

    const syncToList = () =>
        calendars.find(calendar => calendar.sync_to)?.summary || "Do you want to see appts. on your personal calendar?"

    const syncFromList = () =>
        calendars.filter(calendar => calendar.sync_from).map(calendar => calendar.summary).join(', ') || "To prevent double-booking. Events added aren’t visible to clients."

    const updateHideClientInfo = (hide: boolean) => {
        if (hideClientInformation === hide) return

        setHideClientInformation(hide)
        setChanges(true)
    }

    const updateOnlyMine = (only: boolean) => {
        if (onlyMine === only) return

        setOnlyMine(only)
        setChanges(true)
    }

    if (loading) {
        return (
            <div className="servicesSettings">
                <div className="header">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div className="servicesSettings">
        <div className="header">
            <h1>Google Calendar</h1>
            <PSButtonDanger style={{height: '40px'}}
                            onClick={() => onDisconnectSync()}
                            disabled={formSending || !credential}>
                {formSending && <Dots />}
                {!formSending && "Disconnect Sync"}
            </PSButtonDanger>
            <PSButtonPrimary style={{height: '40px', marginLeft: '16px'}}
                             onClick={() => onSave()}
                             disabled={!changes || formSending}>
                {formSending && <Dots />}
                {!formSending && "Save"}
            </PSButtonPrimary>
        </div>
        <div className="separator" />
        <div className="form">
            <CredentialCard key={`Connect-Calendars-SyncFrom`}
                            title="Connect calendars to PocketSuite"
                            subtitle={syncFromList()}
                            onEdit={editSyncFrom}
            />
            <CredentialCard key={`Connect-Calendars-SyncTo`}
                            title="Show PocketSuite appts. on"
                            subtitle={syncToList()}
                            onEdit={editSyncTo}
            />
            <Field className="field">
                <Checkbox checked={hideClientInformation}
                        disabled={settingsState.isSending}
                        onChange={(e) => updateHideClientInfo(e.target.checked)}>
                    <Label className="withHint">Hide client information</Label>
                    <Hint>Client info won’t appear for appts shown on your personal calendar</Hint>
                </Checkbox>
            </Field>
            {offerOnlyMine && (
                <Field className="field">
                    <Checkbox checked={onlyMine}
                            disabled={settingsState.isSending}
                            onChange={(e) => updateOnlyMine(e.target.checked)}>
                        <Label className="withHint">Sync only my appts. </Label>
                        <Hint>Only sync appts. I’m assigned to</Hint>
                    </Checkbox>
                </Field>
            )}
        </div>
    </div>
    )
}