import React, { useCallback, useEffect, useState } from 'react'
import { RecordStatusBar } from '../RecordStatusBar'
import { currencyFormat } from '../../../utils/numbers'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RecordBody } from '../RecordBody'
import { RecordDetail } from '../RecordDetail'
import { ReactComponent as ChevronIcon } from '../../../icons/chevron.svg'
import { PaymentDetailsModal } from '../PaymentDetailsModal'
import { ReactComponent as ColoredChevronIcon } from '../../../icons/colored_chevron.svg'
import { PSButtonPrimary } from '../../app/PSButton'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { describeSubscription, displayStatus, Subscription } from '../../../models/Subscription'
import { RootState } from '../../../store'
import { cancellationFeeString, distinctButtons } from '../../../utils/records'
import { ButtonProps, RecordButtons } from '../RecordButtons'
import { apiClient, API_BASE } from '../../../modules/apiclient'
import { useConfirmModal, usePSUser } from '../../../hooks'
import { SuccessModal } from '../../settings/SuccessModal'
import { cancelSubscription, skipNextRenewal } from '../../../modules/transactions'
import moment from 'moment'
import { isPassedDate, Result } from '../../../utils'
import { getRecord } from '../../../modules/records'
import { UserInstance } from '../../../models/User'
import { getUser } from '../../../modules/settings'
import { Body, Close, Header, Modal } from '@zendeskgarden/react-modals'


export const RecordSubscription = (props: any) => {
    const dispatch = useDispatch()
    const state = useSelector((state: RootState) => (
        {
            entities: state.entities
        }
    ))

    const [setCtaModalInfo, setCtaModalLoading, showCtaModal, closeCtaModal, ctaComponent] = useConfirmModal()

    const record = new Subscription(props.record)
    const item = record.plan ? state.entities.items[record.plan] : {} || {}
    const client = state.entities.contacts[record.client] || {}
    const contract = state.entities.contracts[record.contract] || {}
    const employee = state.entities.contacts[record.employee] || {}

    const statuses = [
        {label: 'Sent', completed: !!record.sent_date},
        {label: 'Viewed', completed: !!record.viewed_date},
        {label: 'Confirmed', completed: !!record.confirmed_date},
    ]

    const recordDetails = []

    if (client.id) {
        const details = (
            <Link to={`/record/client/${client.id}`}>
                {client.name}
                <ChevronIcon className="chevron" />
            </Link>
        )
        recordDetails.push(
            <RecordDetail header="Client"
                          details={details}
                          subtitle={displayStatus(record)}
            />
        )
    }

    if (record.instances > 0) {
        const details = `${Math.max(record.instances - record.paid_instances, 0)} remaining`
        recordDetails.push(
            <RecordDetail header="Number of payments" details={details} />
        )
    }

    if (contract.id) {
        recordDetails.push(
            <RecordDetail header="Contract" details={contract.name} />
        )
    }

    if (employee.id) {
        const details = (
            <Link to={`/record/payee/${employee.id}`}>
                {employee.name}
                <ChevronIcon className="chevron" />
            </Link>
        )

        recordDetails.push(
            <RecordDetail header="Team member" details={details} />
        )
    }

    if (record.memo) {
        recordDetails.push(
            <RecordDetail header="Statement memo" details={record.memo} isText />
        )
    }

    if (record.source_display) {
        recordDetails.push(
            <RecordDetail header="Payment method" details={record.source_display}/>
        )
    }

    const [payments, setPayments] = useState<any[]>([])
    const [packages, setPackages] = useState<any[]>([])
    useEffect(() => {
        apiClient.post('/subscription/sync', {id: record.id})
            .then(resp => resp.data)
            .then(json => {
                if (json.payments?.length) {
                    setPayments(json.payments)
                }
                if (json.packages?.length) {
                    setPackages(json.packages)
                }
        })
    }, [record.id])

    const user = usePSUser()
    const [vendor, setVendor] = useState<UserInstance>()
    useEffect(() => {
        if (!user || !record?.owner) return

        if (user.id === record.owner) {
            setVendor(user)
            return
        }

        Result(dispatch(getUser(record.owner)))
            .then((result: UserInstance) => setVendor(result))

    }, [user, record?.owner, dispatch])

    const sendMessage = useCallback(() =>
        dispatch(push(`/messages/${record.client}`)), [dispatch, record.client])

    const makeCopy = useCallback(() =>
        dispatch(push('/subscription/new', { subscription: { ...record, id: undefined } })), [dispatch, record])

    const viewForm = useCallback(() =>
        dispatch(push(`/record/record/${record.record}`)), [record.record, dispatch])

    const viewContract = useCallback(() =>
        window.open(`${API_BASE}/subscription/${record.id}/contract`, "_blank"), [record.id])

    const viewPayments = useCallback(() =>
        dispatch(push(`/payments/subscription/${record.id}`)), [dispatch, record.id])

    const showSkipModal = useCallback(() => {
        const dateFormat = (date: any) => moment(date).format('MMMM DD, YYYY')

        setCtaModalInfo({
            title: "Skip next payment",
            body: "Would you like to skip the next subscription payment?",
            button: "Skip next payment",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(skipNextRenewal(record.id!)))
                    .then((subscription: any) => {
                        closeCtaModal()
                        setSuccessBody(`The next subscription payment was skipped. The next payment is due on ${dateFormat(subscription.next_invoice_date)}.`)
                        setShowSuccessModal(true)
                    })
            }
        })
        showCtaModal()
    }, [closeCtaModal, dispatch, record.id, setCtaModalInfo, setCtaModalLoading, showCtaModal])

    const changeSubscriptionPayment = useCallback(() =>
        dispatch(push(`/change-card/subscription/${record.id}`, {record})), [record, dispatch])

    const [showPackagesModal, setShowPackagesModal] = useState(false)
    const showCancelSubscription = useCallback(() => {
        if (!user || !vendor) return

        if (record.confirmed_date && !record.cancelled_date) {
            dispatch(push(`/cancel/subscription/${record.id}`, { record/*, user, vendor*/ }))
            return
        }

        const date = record.instances > 0 ? record.date : record.next_invoice_date
        const isLate = !!vendor.cancellation_rate && (!date || isPassedDate(date, vendor.cancellation_duration ?? 0) || (record.instances === 0 && record.confirmed_date))
        const body = "Would you like to cancel this subscription?"
        let destructiveButtonLabel = "Cancel subscription"
        if (isLate && record.source && record.confirmed_date) {
            destructiveButtonLabel = `Cancel and pay ${cancellationFeeString(vendor)} fee`
        }
        setCtaModalInfo({
            title: "Cancel Subscription",
            body: body,
            button: destructiveButtonLabel,
            onClick: (optionalButton: boolean) => {
                setCtaModalLoading(true)
                Result(dispatch(cancelSubscription(record.id!, false)))
                    .then(() => {
                        closeCtaModal()
                        setSuccessBody(`This subscription has been cancelled.`)
                        setShowSuccessModal(true)
                    })
            }
        })
        showCtaModal()
    }, [closeCtaModal, dispatch, record, setCtaModalInfo, setCtaModalLoading, showCtaModal, user, vendor])

    const chargeSubscription = useCallback(() =>
        dispatch(push(`/confirm-charge/subscription/${record.id}`, {record})), [record, dispatch])

    const getButtons = useCallback(() => {
        const buttons: ButtonProps[] = []

        if (record.confirmed_date && !record.cancelled_date && !record.completed_date && record.instances !== 1) {
            buttons.push({ text: "Skip", tooltip: "Skip next", onClick: showSkipModal })
        }
        else if (!record.cancelled_date && !record.confirmed_date) {
            buttons.push({ text: "Charge", onClick: chargeSubscription })
        }
        else if (!record.cancelled_date && !record.completed_date) {
            buttons.push({ text: "Cancel", onClick: showCancelSubscription })
        }
        else {
            buttons.push({ text: "Copy", onClick: makeCopy })
        }
        if (payments.length) {
            buttons.push({ text: "Payments", tooltip: "View Payments", onClick: viewPayments })
        }
        buttons.push({ text: "Chat", onClick: sendMessage })
        if (!record.cancelled_date && !record.completed_date) {
            buttons.push({ text: "Cancel", onClick: showCancelSubscription })
        }
        if (record.record && record.captured_date && !record.cancelled_date) {
            buttons.push({ text: "Form", tooltip: "View form", onClick: viewForm })
        }
        if (record.contract && !record.cancelled_date) {
            buttons.push({ text: "Contract", tooltip: "View contract", onClick: viewContract })
        }
        buttons.push({ text: "Copy", tooltip: "Make copy", onClick: makeCopy })

        if (!record.completed_date && !record.cancelled_date && record.confirmed_date) {
            buttons.push({ text: "Card", tooltip: "Change card", onClick: changeSubscriptionPayment })
        }
        if (packages.length) {
            buttons.push({ text: "Packages", tooltip: "View packages", onClick: () => setShowPackagesModal(true) })
        }

        return distinctButtons(buttons)
    }, [showCancelSubscription, changeSubscriptionPayment, chargeSubscription, makeCopy, packages.length, payments.length, record.cancelled_date, record.captured_date, record.completed_date, record.confirmed_date, record.contract, record.instances, record.record, sendMessage, showSkipModal, viewContract, viewForm, viewPayments])

    const [showModal, setShowModal] = useState(false)
    const [successBody, setSuccessBody] = useState('')
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const closeSuccessModal = useCallback(() => {
        dispatch(getRecord('subscription', record.id))
        setShowSuccessModal(false)
    }, [dispatch, record.id])

    const buttons = getButtons()
    return (
        <div className="recordView">
            <div className="topBar">
                <RecordStatusBar statuses={statuses} />
                <div className="actions">
                    <PSButtonPrimary onClick={() => dispatch(push(`/subscription/${record.id}/edit`))}>
                        Edit
                    </PSButtonPrimary>
                    <RecordButtons buttons={buttons} />
                </div>
            </div>
            <div className="recordHeader">
                <h2 className="payment" onClick={e => setShowModal(true)}>
                    {currencyFormat(record.charge_amount)} total
                    <ColoredChevronIcon fill="#62CE9A" />
                </h2>
                <div className="title">{item.name}</div>
                <div className="time">{describeSubscription(record)}</div>
            </div>
            <RecordBody recordDetails={recordDetails} />
            <PaymentDetailsModal visible={showModal}
                                 onClose={() => setShowModal(false)}
                                 type="subscription"
                                 record={record} />
            {ctaComponent}
            {showSuccessModal &&
                <SuccessModal
                    title="Success"
                    body={successBody}
                    buttonText="Done"
                    onAction={closeSuccessModal}
                    onClose={closeSuccessModal}
                />
            }
            {showPackagesModal && (
                <Modal onClose={() => setShowPackagesModal(false)}>
                    <Header>Packages</Header>
                    <Body style={{backgroundColor: '#f9f9f9'}}>{packages.map((p: any) => {
                        return (
                            <div className="serviceCard" key={`package-card-${p.id}`} style={{padding: "16px 24px"}}>
                                <div>
                                    <div>{client.name}</div>
                                    <div style={{color: isPassedDate(p.expired_date) ? '#E22216' : '#999'}}>
                                        {moment(p.expired_date).format("MM/DD")}
                                    </div>
                                </div>
                                <div className="edit">
                                    <div onClick={() => dispatch(push(`/record/package/${p.id}`))}
                                            className="buttonLink">
                                        View
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    </Body>
                    <Close aria-label="Close modal" />
                </Modal>
            )}
        </div>
    )
}