import React from 'react'
import PropType from 'prop-types'
import { ContactAvatar } from '../contacts/ContactAvatar'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { calendarColors, userColors } from '../../utils/colors'

export const ScheduleEvent = (props) => {
    const dispatch = useDispatch()
    const state = useSelector(state => (
        {
            calendar: state.calendar,
            entities: state.entities
        }
    ))

    const showDate = !!props.showDate
    const { type, end, data, isMultiDay } = props.event
    const event = data
    const eventDate = moment(event.date)
    const endDate = event.end_date ? end.format('h:mm A') : event.duration > 0 ? eventDate.clone().add(event.duration, 'minutes').format('h:mm A') : 'TBD'
    const isAllDay = (event.end_date && eventDate.clone().add(1, 'day').subtract(1, 'seconds').isSame(event.end_date))

    const viewEvent = (type, eventData) => {
        dispatch(push(`/record/${type}/${eventData.id}`))
    }

    const timeInfoDiv = (
        <div className="times">
            {isAllDay && (
                <div className="startTime">All Day</div>
            )}
            {isMultiDay && (
                <>
                    <div className="startTime">{eventDate.format('M/D')}<br />{eventDate.format('h:mm A')}</div>
                    <div className="endTime">{end.format('M/D')}<br />{end.format('h:mm A')}</div>
                </>
            )}
            {!isAllDay && !isMultiDay && (
                <>
                    <div className="startTime">
                        {showDate && eventDate.format('M/D')}
                        {showDate && (<br />)}
                        {eventDate.format('h:mm A')}
                    </div>
                    <div className="endTime">{endDate}</div>
                </>
            )}
        </div>
    )

    const contact = state.entities.contacts[event.client] || {}
    const color = userColors(event.client || event.title || type).fg
    const calColor = calendarColors(type)

    let eventTitle = contact.name || event.title
    if (!eventTitle) {
        if (event.item) {
            eventTitle = state.entities.items[event.item] && state.entities.items[event.item].name
            if (!eventTitle)
                eventTitle = 'Class'
        }
        if (!eventTitle)
            eventTitle = 'Booking'
    }

    let eventSubtitle
    if (contact.name && event.item) {
        eventSubtitle = state.entities.items[event.item] && state.entities.items[event.item].name
    }

    return (
        <div className="eventWrapper"
             onClick={e => viewEvent(type, event)}
        >
            <div className="timeColumn">
                <div className="colorIcon" style={{borderColor: color}} />
                {timeInfoDiv}
            </div>
            <div className="detailColumn">
                <div className="detailWrapper">
                    <div className="topRow">
                        {contact && contact.id && (
                            <ContactAvatar user={contact}
                                           userId={contact.id}
                                           size="medium"
                            />
                        )}
                        <div className="topDetails">
                            <div className="title">{eventTitle}</div>
                            {eventSubtitle && (<div className="subtitle">{eventSubtitle}</div>)}
                            <div className="tagWrapper">
                                    <span className="tag"
                                          style={{color: calColor.fg, backgroundColor: calColor.bg}}
                                    >
                                        {type}
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="extraColumn">
            </div>
        </div>
    )
}

ScheduleEvent.props = {
    event: PropType.object.isRequired,
    showDate: PropType.bool,
}