import { push } from "connected-react-router";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PSButtonPrimary } from "../app/PSButton";
import { Spinner } from "@zendeskgarden/react-loaders";
import { NoteCard } from "./NoteCard";
import './notes.css'
import { NoteState } from "../../modules/notes";
import { RootState } from "../../store";

type NoteTypes = 'user' | 'estimate' | 'booking'
type Props = {
    type: NoteTypes,
    typeId: string,
    emptyState: JSX.Element
}

export const ViewNotes = ({type, typeId, emptyState}: Props) => {
    const state: NoteState = useSelector((state: RootState) => state.notes)

    const dispatch = useDispatch()
    const isEmpty = !state.notes?.length

    const addNote = useCallback(() =>
        dispatch(push(`/${type}/${typeId}/add-note`))
    , [type, typeId, dispatch])

    let body = <>
            {isEmpty && (
                <div className="empty">
                    {emptyState}
                    <PSButtonPrimary onClick={addNote} style={{padding: '20px 26px', marginTop: '22px'}}>
                        Add a Note
                    </PSButtonPrimary>
                </div>
            )}
            {!isEmpty && state.notes?.map(note =>
                <NoteCard key={note.id} note={note} />
            )}
        </>

    if (state.loading) {
        body = <Spinner size="128" color="#314A68" />
    }
    return (
        <div className="notes">
            <div className="header">
                <h1>Notes</h1>
                <PSButtonPrimary onClick={addNote} style={{padding: '20px 26px'}}>
                    Add a Note
                </PSButtonPrimary>
            </div>
            <div className="separator" />
            <div className="body">
                {body}
            </div>
        </div>
    )
}