import { dateFormat } from "../utils/dates"

export class Signature {
    id?: string
    contract?: string
    person?: string
    employee?: string
    channel?: string
    sent_date?: string;
    viewed_date?: string;
    owner?: string
    signed_date?: string;
    cancelled_date?: string;
    created_date?: string;
    ip_addr?: string;
    signature_data?: string;

    constructor(data?: Partial<Signature>) {
        Object.assign(this, data)
    }

    toObject(): any {
        const obj: any = {}
        obj.id = this?.id
        obj.contract = this.contract
        obj.person = this.person
        obj.employee = this.employee
        obj.channel = this.channel
        return obj
    }
}

export const signatureStatus = (signature: Signature) => {
    if (signature.signed_date && signature.ip_addr)
        return `Signed ${dateFormat(signature.signed_date)} (${signature.ip_addr})`
    else if (signature.signed_date)
        return `Signed ${dateFormat(signature.signed_date)}`
    else if (signature.cancelled_date)
        return `Canceled ${dateFormat(signature.cancelled_date)}`
    else if (signature.id)
        return `Sent ${dateFormat(signature.created_date)} - not signed`
    else
        return 'Requires signature'
}