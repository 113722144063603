import React, {useEffect, useState} from "react";
import {AppHeader} from "../../app/AppHeader";
import {Redirect, useHistory, useLocation} from "react-router";
import {usePSOwner} from "../../../hooks";
import {useChargeButton} from "../../../hooks/ChargeButton";
import {calculateGratuity, Payment} from "../../../models/Payment";
import {TaxNexus, TipType} from "../../../models/Transaction";
import {currencyFloat, currencyFormat} from "../../../utils/numbers";
import {PSFormFieldSelection} from "../../app/PSFormFieldSelection";
import {Field, Label, Toggle} from "@zendeskgarden/react-forms";
import {DiscountModal} from "./DiscountModal";
import {SalesTaxModal} from "./SalesTaxModal";
import {TaxcodeInstance} from "../../../models/Taxcode";
import {GratuityModal} from "./GratuityModal";
import {ReceiptMemoModal} from "./ReceiptMemoModal";
import {apiClient} from "../../../modules/apiclient";
import {Spinner} from "@zendeskgarden/react-loaders";

export const ChargeSummary = () => {
    const history = useHistory()
    const location = useLocation()
    const locationState: any = location.state
    const paymentData: Payment = locationState?.payment

    const owner = usePSOwner()
    useEffect(() => {
        if (!owner) return
        setSurchargeEnabled(owner.allow_surcharge)
        setMemo(owner.receipt_policy || "Thank you for your business!")
    }, [owner]) // ignore locationState

    // Load from paymentData
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setDiscountType(paymentData.discount_type)
        if (paymentData.discount_type === 'percent' && paymentData._discount_percentage)
            setDiscountAmount(paymentData._discount_percentage.toString())
        else
            setDiscountAmount(paymentData.discount)
        setSalesTaxNexus(paymentData.nexus)
        setSalesTaxLocation(paymentData.location ?? '')
        setGratuityRate(paymentData.tip_type)
        setGratuityAmount(paymentData.tip)
        setSurchargeEnabled(paymentData.allow_surcharge)
        setMemo(paymentData.memo ?? '')

        // Lookup by taxcode ID
        if (paymentData.taxcode) {
            setLoading(true)
            apiClient.post(`/taxcode`, {'taxcode': paymentData.taxcode})
                .then(resp => resp.data)
                .then(json => setSalesTaxCode(json as TaxcodeInstance))
                .finally(() => setLoading(false))
        }
    }, [paymentData])

    // Discount
    const [discountType, setDiscountType] = useState<'rate' | 'percent' | ''>('')
    const [discountAmount, setDiscountAmount] = useState<string | undefined>()
    // Sales tax
    const [salesTaxNexus, setSalesTaxNexus] = useState<TaxNexus>('')
    const [salesTaxCode, setSalesTaxCode] = useState<TaxcodeInstance | undefined>()
    const [salesTaxLocation, setSalesTaxLocation] = useState('')
    // Gratuity
    const [gratuityRate, setGratuityRate] = useState<TipType>('')
    const [gratuityAmount, setGratuityAmount] = useState(0)
    // Other fields
    const [surchargeEnabled, setSurchargeEnabled] = useState(false)
    const [memo, setMemo] = useState('')

    const [showDiscountModal, setShowDiscountModal] = useState(false)
    const [showSalesTaxModal, setShowSalesTaxModal] = useState(false)
    const [showGratuityModal, setShowGratuityModal] = useState(false)
    const [showMemoModal, setShowMemoModal] = useState(false)

    const [discountNumber, setDiscountNumber] = useState(0)
    const [discountDescription, setDiscountDescription] = useState('')
    const [discountDisplay, setDiscountDisplay] = useState('')
    useEffect(() => {
        if (discountType === 'rate') {
            setDiscountDisplay(currencyFormat(discountAmount))
            setDiscountDescription(`Discount (${currencyFormat(discountAmount)})`)
            setDiscountNumber(Number(discountAmount))
        }
        else if (discountType === 'percent' && Number(discountAmount) > 0) {
            setDiscountDisplay(`${discountAmount}%`)
            // TODO discountAmount can go from 10 to 9.99997743 (floating arithmetic issue)
            setDiscountDescription(`Discount (${discountAmount}%)`)
            setDiscountNumber(currencyFloat(paymentData.total / Number(discountAmount)))
        }
        else {
            setDiscountDisplay('')
            setDiscountDescription('')
            setDiscountNumber(0)
        }
    }, [discountType, discountAmount, paymentData])

    // Keep a payment model up to date with the latest changes
    const [payment, setPayment] = useState<Payment | undefined>()
    useEffect(() => {
        const p = new Payment(paymentData)
        p.discount_type = discountType
        if (discountAmount) p.discount = discountAmount
        if (salesTaxCode) {
            p.taxcode = salesTaxCode.id
            p.tax = Number(salesTaxCode.rate)
        }
        if (gratuityRate) {
            p.tip_type = gratuityRate
            p.tip = gratuityAmount
        }
        p.allow_surcharge = surchargeEnabled

        setPayment(p)
    }, [
        paymentData, discountType, discountAmount, salesTaxCode, gratuityRate,
        gratuityAmount, surchargeEnabled
    ])

    const [netAmount, setNetAmount] = useState(paymentData?.total ?? 0)
    useEffect(() => {
        setNetAmount((paymentData?.total ?? 0) - discountNumber)
    }, [paymentData, discountNumber])

    const [salesTaxNumber, setSalesTaxNumber] = useState(0)
    useEffect(() => {
        // TODO Iterate over lineItems to only apply to items where sales tax is enabled
        if (Number(salesTaxCode?.rate) > 0) {
            setSalesTaxNumber(netAmount * (Number(salesTaxCode?.rate)/100))
        }
        else {
            setSalesTaxNumber(0)
        }
    }, [salesTaxCode, netAmount])

    const [gratuityNumber, setGratuityNumber] = useState(0)
    useEffect(() => {
        const payment: Partial<Payment> = {
            tip_type: gratuityRate,
            tip: gratuityAmount,
            discount_type: discountType ?? '',
            discount: discountAmount ?? '',
            total: paymentData?.total ?? 0
        }
        const amount = calculateGratuity(payment)
        setGratuityNumber(amount)
    }, [paymentData, gratuityAmount, gratuityRate, discountType, discountAmount, netAmount])

    let amountToCharge = netAmount + salesTaxNumber
    let surchargeAmount = 0
    if (surchargeEnabled) {
        surchargeAmount = currencyFloat(amountToCharge * 0.03)
        amountToCharge += surchargeAmount
    }
    amountToCharge += gratuityNumber

    const buildPaymentObject = (): Payment => {
        const newPayment = new Payment({
            ...paymentData
        } as Partial<Payment>)

        if (discountType && discountNumber) {
            newPayment.discount_type = discountType
            if (discountType === 'percent') {
                newPayment._discount_percentage = Number(discountAmount ?? 0)
                newPayment.discount = (paymentData.total / discountNumber).toString()
            }
            else
                newPayment.discount = discountNumber.toString()
        }

        if (salesTaxCode) {
            newPayment.nexus = salesTaxNexus
            newPayment.taxcode = salesTaxCode.id
            newPayment.tax = Number(salesTaxCode.rate)
        }

        if (gratuityRate) {
            newPayment.tip_type = gratuityRate
            newPayment.tip = gratuityAmount
        }

        newPayment.allow_surcharge = surchargeEnabled
        newPayment.memo = memo

        return newPayment
    }

    let surchargeComponent = (
        <Field>
            <Toggle checked={surchargeEnabled}
                    onChange={e => setSurchargeEnabled(e.target.checked)}
            >
                <Label style={{paddingLeft: '30px'}}>&nbsp;</Label>
            </Toggle>
        </Field>
    )
    const [chargeButton] = useChargeButton(buildPaymentObject())

    if (!paymentData) {
        return <Redirect to="/charge/new" />
    }

    const taxTotal = Number(payment?.calculateTax())
    return (
        <AppHeader title="Charge"
                   showBackButton
                   onBack={() => history.replace('/charge/new', {payment: paymentData, fromConfirm: true})}
                   middleWidget={null}
        >
            {loading && (
                <div className="loadingOverlay">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                        <h1>Loading data...</h1>
                    </div>
                </div>
            )}
            {!loading && (
                <div className="chargeClient scheduleClient servicesSettings">
                    <div className="header">
                        <h1>Current sale</h1>
                        {chargeButton}
                    </div>

                    <div className="separator" />

                    <div className="scheduleClientReview">
                        <div className="details">
                            <div className="receipt">
                                <div className="total">{currencyFormat(payment?.calculateChargeAmount() ?? 0)} Total</div>
                                <div className="lineItem final">
                                    <div className="name">Subtotal</div>
                                    <div className="amount">{currencyFormat(payment?.total ?? 0)}</div>
                                </div>
                                {Number(payment?.discount) > 0 && (
                                    <div className="lineItem">
                                        <div className="name">{discountDescription}</div>
                                        <div className="amount">-{currencyFormat(payment?.calculateDiscount())}</div>
                                    </div>
                                )}
                                {Number(payment?.calculateTax()) > 0 && (
                                    <div className="lineItem">
                                        <div className="name">Sales tax ({Number(payment?.tax ?? 0)}%)</div>
                                        <div className="amount">{currencyFormat(payment?.calculateTax())}</div>
                                    </div>
                                )}
                                {surchargeEnabled && (
                                    <div className="lineItem">
                                        <div className="name">Surcharge (3%)</div>
                                        <div className="amount">{currencyFormat(payment?.calculateSurcharge())}</div>
                                    </div>
                                )}
                                {Number(payment?.tip) > 0 && (
                                    <div className="lineItem">
                                        <div className="name">Gratuity</div>
                                        <div className="amount">{currencyFormat(payment?.calculateTip())}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="actionsWrapper">
                            <div className="actions">
                                {owner?.allow_discount && (
                                    <PSFormFieldSelection label="Discount"
                                                        helperLabel="Add discount"
                                                        linkText="Edit"
                                                        extraComponent={Number(payment?.discount)> 0 ? <div className="bold">-{currencyFormat(payment?.calculateDiscount())}</div> : undefined }
                                                        selection={Number(payment?.discount) > 0 ? {name: discountDisplay} : undefined}
                                                        onClick={() => setShowDiscountModal(true)}
                                    />
                                )}
                                {owner?.allow_salestax && (
                                    <PSFormFieldSelection label="Sales tax"
                                                        helperLabel="Add sales tax"
                                                        linkText="Edit"
                                                        extraComponent={taxTotal > 0 ? <div className="bold">{currencyFormat(taxTotal)}</div> : undefined }
                                                        selection={taxTotal ? {name: Number(payment?.tax) + '%'} : undefined}
                                                        onClick={() => setShowSalesTaxModal(true)}
                                    />
                                )}
                                {owner?.allow_gratuity && (
                                    <PSFormFieldSelection label="Gratuity"
                                                        helperLabel="Add gratuity"
                                                        linkText="Edit"
                                                        extraComponent={Number(payment?.tip) > 0 ? <div className="bold">{currencyFormat(payment?.calculateTip())}</div> : undefined}
                                                        selection={Number(payment?.tip) > 0 ? {name: payment?.tip_type === 'percent' ? `${payment?.tip}%` : 'Custom amount'} : undefined}
                                                        onClick={() => setShowGratuityModal(true)}
                                    />
                                )}
                                {owner?.allow_surcharge && (
                                    <PSFormFieldSelection label="Surcharge"
                                                        helperLabel="3%"
                                                        extraComponent={surchargeComponent}
                                                        selection={surchargeEnabled ? {name: '3%'} : undefined}
                                                        hideButton
                                                        onClick={() => {}}
                                    />
                                )}
                                <PSFormFieldSelection label="Receipt memo"
                                                    helperLabel="Thank you for your business!"
                                                    linkText="Edit"
                                                    selection={{name: memo}}
                                                    onClick={() => setShowMemoModal(true)}
                                />
                            </div>
                        </div>
                    </div>
                    {showDiscountModal && (
                        <DiscountModal currentData={{discountType, discountAmount}}
                                    onDone={(discountType, discountAmount) => {
                                        setDiscountType(discountType)
                                        setDiscountAmount(discountAmount)
                                        setShowDiscountModal(false)
                                    }}
                                    onCancel={() => setShowDiscountModal(false)}
                        />
                    )}
                    {showSalesTaxModal && (
                        <SalesTaxModal currentData={{nexus: salesTaxNexus, location: salesTaxLocation}}
                                    onDone={(nexus, location, taxCode) => {
                                        setSalesTaxNexus(nexus)
                                        setSalesTaxCode(taxCode)
                                        setSalesTaxLocation(location)
                                        setShowSalesTaxModal(false)
                                    }}
                                    onCancel={() => setShowSalesTaxModal(false)}
                        />
                    )}
                    {showGratuityModal && (
                        <GratuityModal currentData={{rate: gratuityRate, amount: gratuityAmount}}
                                    paymentData={payment!}
                                    onDone={(rate, amount) => {
                                        setGratuityRate(rate)
                                        setGratuityAmount(amount)
                                        setShowGratuityModal(false)
                                    }}
                                    onCancel={() => setShowGratuityModal(false)}
                        />
                    )}
                    {showMemoModal && (
                        <ReceiptMemoModal currentData={{memo}}
                                        onDone={(memo) => {
                                            setMemo(memo)
                                            setShowMemoModal(false)
                                        }}
                                        onCancel={() => setShowMemoModal(false)}
                        />
                    )}
                </div>
            )}
        </AppHeader>
    )
}