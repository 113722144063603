import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { PSButton, PSButtonPrimary } from "../../../app/PSButton";
import { ReactComponent as LeftArrow } from "../../../../icons/left-arrow.svg";
import { ReactComponent as CheckmarkImage } from "../../../../icons/big_checkmark.svg";
import { useAppDispatch, usePSUser } from "../../../../hooks";
import { e164ToPhone } from "../../../../utils/phone";
import { push, replace } from "connected-react-router";
import { downgradePlan } from "../../../../modules/settings";
import { Result } from "../../../../utils";
import { Spinner } from "@zendeskgarden/react-loaders";

export const CancelPlanUpsellLite = () => {
    const me = usePSUser()
    const history = useHistory()
    const dispatch = useAppDispatch()
    const [showLite, setShowLite] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const getLite = useCallback(() => {
        if (!me) return

        setIsLoading(true)
        Result(dispatch(downgradePlan(me.id, { reason: 'lite', details: 'None' })))
            .then(() => {
                setShowLite(true)
                setIsLoading(false)
            })
    }, [me, dispatch])

    return (
        <div className="supportSettings">
            <div className="header">
                {!showLite && (
                    <PSButton className="left" onClick={() => history.goBack()}>
                        <LeftArrow />
                    </PSButton>
                )}
                <h1 className="title">Premium Lite</h1>
                {!showLite && (
                    <div className="right">
                        <PSButton disabled={isLoading} onClick={() => dispatch(push('feedback'))}>No thanks</PSButton>
                        <PSButtonPrimary disabled={isLoading} onClick={getLite}>Get Premium Lite</PSButtonPrimary>
                    </div>
                )}
                {showLite && (
                    <div className="right">
                        <PSButton onClick={() => dispatch(replace('/'))}>Setup later</PSButton>
                        <PSButtonPrimary onClick={() => dispatch(replace('/settings/account/features'))}>Select features</PSButtonPrimary>
                    </div>
                )}
            </div>
            <div className="separator" />
            {isLoading && (
                <div style={{textAlign: 'center', padding: '128px 0'}}>
                    <Spinner size="128" color="#314A68" />
                </div>
            )}
            {!isLoading && !showLite && (
                <>
                    <p className="details">
                        PocketSuite Premium Lite is just $14.99 per month and is packed with benefits!
                    </p>
                    <div className="supportCard column">
                        <div className="title">2 Premium features</div>
                        <div className="details">Access to 2 Premium features including online booking</div>
                    </div>
                    {me?.sms && (
                        <div className="supportCard column">
                            <div className="title">Premium business phone number</div>
                            <div className="details">Clients will still be able to contact you at {e164ToPhone(me.sms)}</div>
                        </div>
                    )}
                    <div className="supportCard column">
                        <div className="title">Video conferencing</div>
                        <div className="details">A  video conference link available on all appointments</div>
                    </div>
                    <div className="supportCard column">
                        <div className="title">Desktop access</div>
                        <div className="details">PocketSuite for desktop</div>
                    </div>
                </>
            )}
            {!isLoading && showLite && (
                <div className="column center margin">
                    <CheckmarkImage/>
                    <h1 className="heading">Welcome to PocketSuite Premium Lite!</h1>
                    <p className="text">Head over to the Settings Screen and select which features you'd like to use on Premium Lite!</p>
                </div>
            )}
        </div>
    )
}
