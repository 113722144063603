import React from "react";
import { Link } from "react-router-dom";
import {PhoneNumber} from "../../../models/PhoneNumber"
import {ContactAvatar} from "../../contacts/ContactAvatar"
import {ReactComponent as KabobIcon} from "../../../icons/kabob_menu.svg"
import { e164ToPhone } from "../../../utils/phone";
import {Dropdown, Item, Menu, Trigger} from "@zendeskgarden/react-dropdowns";
import {IconButton} from "@zendeskgarden/react-buttons";


type Props = {
    payee: any,
    phoneNumber: PhoneNumber,
    onDelete?: () => void,
}

export const NumberCard = ({payee, phoneNumber, onDelete}: Props) => {
    let subtitleText
    let kabobMenuItems

    if (!!onDelete) {
    kabobMenuItems = (
            <>
                <Item onClick={() => onDelete && onDelete()} value="delete">
                    <span style={{color: 'red'}}>
                        Delete
                    </span>
                </Item>
            </>
        )
    }


    subtitleText = `${payee.sms ? e164ToPhone(payee!.sms) : ''}`

    return (
        <div className="phoneNumberCard">
            <ContactAvatar user={payee} size="medium"/>
            <div className="details">
                <div className="name">{payee.name}</div>
                {subtitleText && (
                    <div className="rate">{subtitleText}</div>
                )}
            </div>
            <div className="edit">
                <Link to={`/settings/account/number/${phoneNumber.id}/edit`}
                      className="edit">
                    Edit
                </Link>
            </div>
            {kabobMenuItems && (
                <Dropdown>
                    <Trigger>
                        <IconButton focusInset={false}>
                            <KabobIcon />
                        </IconButton>
                    </Trigger>
                    <Menu hasArrow>
                        {kabobMenuItems}
                    </Menu>
                </Dropdown>
            )}
        </div>
    )
}