import {
    Body, Close,
    Footer,
    FooterItem,
    Header,
    Modal
} from "@zendeskgarden/react-modals";
import React, {useCallback, useEffect, useState} from "react";
import {PSDropdown} from "../app/PSDropdown";
import {Button} from "@zendeskgarden/react-buttons";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchCategories,
    fetchItemByType,
    fetchItems
} from "../../modules/settings";
import {RootState} from "../../store";
import {Item} from "@zendeskgarden/react-dropdowns";
import {Spinner} from "@zendeskgarden/react-loaders";
import {sendContract, sendForm} from "../../modules/conversation";
import {Category} from "../../models/Category";
import {Item as PSItem} from "../../models/Item";
import moment from "moment";
import {downloadURL, formURL, payURL, widgetURL} from "../../utils/urls";
import { usePSOwner } from "../../hooks";

type Props = {
    attachmentType: string
    onClose: () => void
    onSend: (item: any) => void
}

type LinkProps = {
    id: string
    name: string
    message: string
}

export const MessageAttachmentModal = ({attachmentType, onClose, onSend}: Props) => {
    const dispatch = useDispatch()
    const state = useSelector((state: RootState) => {
        return {
            conversation: state.conversation,
            entities: state.entities,
            messages: state.messages,
            settings: state.settings,
        }
    })

    const owner = usePSOwner()

    const [isLoading, setIsLoading] = useState(true)
    const [sendEnabled, setSendEnabled] = useState(false)
    const [selectedOption, setSelectedOption] = useState()

    const [linkList, setLinkList] = useState<LinkProps[]>([])
    const loadLinks = useCallback(() => {
        if (!owner) return

        const promises = []
        if (owner.allow_widget) {
            if (owner.allow_category)
                promises.push(dispatch(fetchCategories()))
            promises.push(dispatch(fetchItems()))
        }

        if (owner.allow_record)
            promises.push(dispatch(fetchItemByType('form')))

        Promise.all(promises).then(() => setIsLoading(false))
    }, [dispatch, owner])

    useEffect(() => {
        if (!owner) return

        const links = []

        if (owner.allow_link) {
            links.push({
                id:'link',
                name:'Suite link',
                message: `{firstname}, you can tap on my Suite link below: ${widgetURL({user: owner})}`,
            })
        }

        if (owner.allow_paypal) {
            links.push({
                id:'pay',
                name:'Send payment',
                message: `{firstname}, you can tap on the link below to easily pay me: ${payURL(owner)}`,
            })
        }

        links.push({
            id:'app',
            name:'Download PocketSuite',
            message: `{firstname}, you can download the PocketSuite app from here: ${downloadURL()}`,
        })

        if (owner.allow_widget) {
            links.push({
                id:'book',
                name:'Schedule me',
                message: `{firstname}, you can tap on the link below to easily see my schedule and book me: ${widgetURL({user: owner})}`,
            })

            if (owner.allow_category) {
                state.settings.categories.forEach((category: Category) => {
                    links.push({
                        id: category.id,
                        name: category.name,
                        message: `{firstname}, you can tap on the link below to easily see my schedule and book me: ${widgetURL({user: owner, category})}`,
                    })
                })
            }

            state.settings.items.forEach((item: PSItem) => {
                if (item.end_date && moment(item.end_date).isBefore(moment()))
                    return

                const validTypes = [
                    'service', 'class', 'reservation', 'package', 'gift',
                    'subscription'
                ]
                if (validTypes.indexOf(item.type) === -1)
                    return

                links.push({
                    id: item.id,
                    name: item.name,
                    message: `{firstname}, you can tap on the link below to easily see my schedule and book me: ${widgetURL({user: owner, item})}`,
                })
            })
        }

        if (owner.allow_record) {
            state.settings.forms.forEach((form: any) => {
                if (!form.is_public) return

                links.push({
                    id: form.id,
                    name: form.name,
                    message: `{firstname}, you can tap on the link below to easily fill out my form: ${formURL(form)}`,
                })
            })
        }

        setIsLoading(false)
        setLinkList(links)
    }, [dispatch, owner, state.settings.categories, state.settings.forms, state.settings.items])

    // Fetch the user's contracts and forms
    useEffect(() => {
        if (attachmentType === 'link')
            loadLinks()
        else {
            // @ts-ignore
            dispatch(fetchItemByType(attachmentType)).finally(() => setIsLoading(false))
        }
    }, [dispatch, attachmentType, loadLinks])

    useEffect(() => {
        setSendEnabled(!!selectedOption)
    }, [selectedOption])

    const [options, setOptions] = useState<any[]>([])
    useEffect(() => {
        switch (attachmentType) {
            case 'contract':
                setOptions(state.settings.contracts)
                break
            case 'form':
                setOptions(state.settings.forms)
                break
            case 'link':
                setOptions(linkList)
                break
        }
    }, [attachmentType, state.settings.contracts, state.settings.forms, linkList])

    const sendItem = () => {
        if (!selectedOption) return

        if (attachmentType === 'contract') {
            const contract = selectedOption as any
            dispatch(sendContract(
                (state.conversation as any).currentThread,
                contract.owner,
                '', // TODO Figure out how to get employee id
                contract.id,
            ))
        }
        else if (attachmentType === 'form') {
            const form = selectedOption as any
            dispatch(sendForm(
                (state.conversation as any).currentThread,
                '', // TODO Figure out how to get employee id
                form,
            ))
        }

        onSend(selectedOption)
    }

    return (
        <Modal className="attachmentModal" onClose={onClose}>
            <Header>Send client a {attachmentType}</Header>
            <Body>
                {isLoading && (
                    <Spinner size="128" color="#314A68" />
                )}
                {!isLoading && (
                    <PSDropdown selected={selectedOption}
                                onSelect={(selection) => setSelectedOption(selection)}
                                nameProperty={"name"}
                                label={`Select a ${attachmentType}`}
                                placeholder={<div className="grey">Select {attachmentType}</div>}
                    >
                        <>
                            {options.map(option => (
                                <Item key={`option-${option.id}`} value={option}>
                                    {option.name}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                )}
            </Body>
            <Footer style={{paddingBottom: 32}}>
                <FooterItem>
                    <Button onClick={() => sendItem()}
                            disabled={!sendEnabled}
                            isPrimary>
                        Send {attachmentType}
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )
}