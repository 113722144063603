import React, {useCallback, useEffect, useState} from "react";
import {AppHeader} from "../../app/AppHeader";
import {ReactComponent as ClientIcon} from "../../../icons/ps_general_person_nobg.svg";
import {ReactComponent as DollarIcon} from "../../../icons/ps_general_money_nobg.svg";
import {ReactComponent as GiftIcon} from "../../../icons/ps_settings_gift_certs_nobg.svg";
import {ReactComponent as CalendarIcon} from '../../../icons/ps_settings_schedule_nobg.svg';
import {PSFormFieldSelection} from "../../app/PSFormFieldSelection";
import {useAppDispatch, usePSOwner, usePSTotalDisplay, usePSUser, useTaxCodeLookup} from "../../../hooks";
import {
    fetchAttachments,
    fetchItems,
    SettingsState
} from "../../../modules/settings";
import {
    syncClient,
    syncContacts,
    clearClientState
} from "../../../modules/contacts"
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {Field, Hint, Label} from "@zendeskgarden/react-forms";
import {PSDropdown} from "../../app/PSDropdown";
import {
    bookingSendViaOptions,
    expireOptions,
    giftReminderOptions,
    SelectOption
} from "../../settings/Options";
import {Item} from "@zendeskgarden/react-dropdowns";
import {PSTextarea} from "../../app/PSTextarea";
import {useLocation, useParams} from "react-router";
import "../transactions.css";
import {PSButtonPrimary} from "../../app/PSButton";
import {TransactionProgress} from "../TransactionProgress";
import {TotalInstance, TotalModal} from "../TotalModal";
import {Spinner} from "@zendeskgarden/react-loaders";
import { TaxcodeInstance } from "../../../models/Taxcode";
import { push } from "connected-react-router";
import { fetchTaxCodeById, fetchTaxCodeByLocation, fetchGiftCertificate } from "../../../modules/transactions";
import { isEmpty } from "../../../utils";
import { LineItem } from "../../../models/LineItem";
import { GiftCertificate } from "../../../models/GiftCertificate";
import { SelectModal } from "../SelectModal";

export const GiftView = () => {
    const dispatch = useAppDispatch()
    const location = useLocation()
    const locationState: {gift: Partial<GiftCertificate>, client: any} = location.state as {gift: Partial<GiftCertificate>, client: any}
    const params = useParams<{id?: string}>()
    const isAddNew = !params?.id
    const user = usePSUser()
    const owner = usePSOwner()

    useEffect(() => {
        if (isAddNew) dispatch(clearClientState())
    }, [isAddNew, dispatch])

    const state: {settings: SettingsState, contacts: any, entities: any, records: any} = useSelector((state: RootState) => ({
        settings: state.settings,
        contacts: state.contacts,
        entities: state.entities,
        records: state.records,
    }))

    const [client, setClient] = useState<any>(locationState?.client ?? {})
    const [gift, setGift] = useState<GiftCertificate>()
    const [item, setItem] = useState<any>()
    const [sendVia, setSendVia] = useState(bookingSendViaOptions[2])
    const [reminder, setReminder] = useState(giftReminderOptions[0])
    const [expiresIn, setExpiresIn] = useState<SelectOption>();
    const [memo, setMemo] = useState('')
    const [form, setForm] = useState<any>('')
    const [contract, setContract] = useState<any>('')
    const [nextEnabled, setNextEnabled] = useState(false)
    const [loading, setLoading] = useState(!isAddNew)
    const [loadingText, setLoadingText] = useState('Loading Gift Certificate')
    const [showPriceModal, setShowPriceModal] = useState(false)
    const [taxCode, setTaxCode] = useState<TaxcodeInstance>()
    const prosTaxCode = useTaxCodeLookup(owner)
    const [totalData, setTotalData] = useState<TotalInstance>()
    const totalDisplay = usePSTotalDisplay(totalData, `Add a price`)
    const [selectModalType, setSelectModalType] = useState<'gifts' | 'clients' | undefined>()

    const getGiftCertificates = useCallback(() =>
        state.settings.items.filter((i: any) => i.type === 'gift') ?? []
    , [state.settings.items])

    const getSourceData = useCallback(() => {
        switch (selectModalType) {
            case 'gifts':
                return getGiftCertificates()
            case 'clients':
                return state.contacts.contacts ?? []
        }
    }, [selectModalType, getGiftCertificates, state.contacts.contacts])

    useEffect(() => {
        dispatch(fetchAttachments())
        dispatch(fetchItems())
        dispatch(syncContacts(''))
    }, [dispatch])

    useEffect(() => {
        if (locationState?.gift) {
            setGift(new GiftCertificate(locationState.gift))
        }
    }, [locationState])

    useEffect(() => {
        if (!owner || totalData) return

        setTotalData({
            surchargeEnabled: owner.allow_surcharge
        } as TotalInstance)
    }, [owner, totalData])

    useEffect(() => {
        if (isAddNew || state.settings.items.length === 0) return
        if (locationState?.gift) {
            setItem(getGiftCertificates()?.find(s => locationState?.gift!.item === s.id))
            return
        }

        // @ts-ignore
        dispatch(fetchGiftCertificate(params.id!)).then((gift: Partial<GiftCertificate>) => {
            setGift(new GiftCertificate(gift))
            setItem(getGiftCertificates()?.find(s => gift.item === s.id))
        })

    }, [dispatch, isAddNew, params?.id, state.settings.items.length, getGiftCertificates, locationState])

    useEffect(() => {
        setClient(!isEmpty(state.contacts.contact) ? state.contacts.contact : (locationState?.client ?? {}))
        setLoading(false)
    }, [locationState?.client, state.contacts.contact])

    const selectedItemFromModal = (item: any[], extraData: any) => {
        const [selected] = item
        switch (selectModalType) {
            case 'clients':
                if (client.id === selected?.id) {
                    setClient({})
                }
                else {
                    setLoading(true)
                    dispatch(syncClient(selected.id))
                }
                break
            case 'gifts':
                const found = getGiftCertificates().find(item => item.id === selected.id)
                setItem(found)
                setGift(new GiftCertificate({
                    ...found,
                    plan: selected.id?.toString(),
                    id: gift?.id,
                } as Partial<GiftCertificate>))
                if (found) {
                    setExpiresIn(expireOptions.find(option => Number(option.value) === Number(found.expires)))
                }
                break
        }
        setSelectModalType(undefined)
    }

    useEffect(() => {
        if (!gift) return

        setTotalData((currentData) => {
            return {
                ...currentData,
                total: Number(gift.rate || gift.total),
                discountRate: !currentData?.discountRate ? Number(gift.discount) : currentData?.discountRate,
                discountType: !currentData?.discountType ? gift.discount_type : currentData?.discountType,
                surchargeEnabled: gift.allow_surcharge,
                nexus: gift.nexus || '',
                taxLocation: gift.location
            } as TotalInstance
        })

        if (gift.taxcode && prosTaxCode?.id !== gift.taxcode) {
            dispatch(fetchTaxCodeById(gift.taxcode, (taxCode) => {
                setTaxCode(taxCode)
            }))
        }
        else if (gift.taxcode && prosTaxCode?.id === gift.taxcode) {
            setTaxCode(prosTaxCode)
        }

        setMemo(gift.memo)
        if (gift.expires) setExpiresIn(expireOptions.find(option => Number(option.value) === Number(gift.expires)))
        else if (gift.expires === '') setExpiresIn(expireOptions[0])

        setReminder(giftReminderOptions.find(option => option.value === gift.reminder) || giftReminderOptions[0])

    }, [gift, getGiftCertificates, prosTaxCode, dispatch])

    const findSendViaOption = (val: string) => bookingSendViaOptions.find(option => option.value === val) || bookingSendViaOptions[2]
    useEffect(()=> {
        if (!isAddNew && gift?.channel) setSendVia(findSendViaOption(gift.channel))
        else if (state.contacts.client.notify) setSendVia(findSendViaOption(state.contacts.client.notify))
        else if (user?.channel) setSendVia(findSendViaOption(user.channel))
        else setSendVia(bookingSendViaOptions[2])
    }, [gift?.channel, user?.channel, state.contacts.client.notify, isAddNew])

    useEffect(() => {
        if (isAddNew && state.contacts.client.discount) {
                setTotalData((currentData) => {
                    return {
                    ...currentData,
                    discountRate: Number(state.contacts.client.discount),
                    discountType: 'percent'
                } as TotalInstance
            })
        }
    }, [state.contacts.client.discount, isAddNew])

    useEffect(() => {
        if (!gift?.client) return

        if (isEmpty(state.contacts.contact) || state.contacts.contact.id !== client?.id) dispatch(syncClient(gift.client))

    }, [gift?.client, client, state.contacts.contact, dispatch])


    useEffect(() => {
        if (!gift) return

        if ((totalData?.nexus || gift.nexus) === 'transaction' && taxCode?.id !== (totalData?.taxcode || gift.taxcode) && (totalData?.taxLocation || gift.location)) {
            dispatch(fetchTaxCodeByLocation(totalData?.taxLocation || gift.location!, (taxCode) => {
                setTaxCode(taxCode)
            }))
        }
    }, [gift, totalData, dispatch, taxCode?.id])

    useEffect(() => {
        if (!gift) return

        if (gift.item) {
            const p = getGiftCertificates().find(s => s.id === gift.item)
            if (p) setItem(p)
        }
    }, [dispatch, gift, getGiftCertificates])

    useEffect(()=> {
        setTotalData(currentData => {
            return {
                ...currentData,
                tax: taxCode?.rate,
                taxcode: taxCode?.id
            } as TotalInstance
        })
    }, [taxCode])

    useEffect(() => {
        if (!gift) return

        setForm(state.settings.forms.find(form => form.id === gift.form))
        setContract(state.settings.contracts.find(contract => contract.id === gift.contract))
    }, [gift, state.settings.forms, state.settings.contracts])

    const getCurrentSelectionByType = () => {
        switch (selectModalType) {
            case 'clients': return client ? [client] : []
            case 'gifts': return item ? [item] : []
            default: return []
        }
    }

    useEffect(() => {
        setNextEnabled(!isEmpty(client) && !isEmpty(gift) && !isEmpty(totalData))
    }, [client, gift, totalData, taxCode])

    const onNextHandler = () => {
        const giftObj = new GiftCertificate({
            ...gift,
            expires: !!gift?.paid_date ? undefined :
                expiresIn?.value !== undefined ? expiresIn.value : gift?.expires,
            form: form?.id,
            contract: contract?.id,
            client: client!.id,
            memo,
            item: item?.id,
            nexus: totalData?.nexus,
            channel: sendVia?.value,
            reminder: reminder?.value,
            total: totalData?.total,
            discount: totalData?.discountRate?.toString(),
            discount_type: totalData?.discountType,
            tax: Number(taxCode?.rate) || undefined,
            taxcode: taxCode?.id,
            allow_surcharge: totalData?.surchargeEnabled,
            location: totalData?.taxLocation,
            lines: [new LineItem({
                total: Number(totalData?.total) || 0,
                rate: Number(totalData?.total) || 0,
                quantity: 1,
                taxable: taxCode?.rate !== undefined,
                discountable: (totalData?.discountRate ?? 0) !== 0,
            })]
        });

        dispatch(push(isAddNew ? '/gift/preview' : `/gift/${params.id!}/preview`, {
            gift: giftObj,
            client
        }))
    }

    return (
        <AppHeader showBackButton={true} title="Gift certificates" middleWidget={<TransactionProgress created secondStep='Preview' thirdStep='Save' />}>
            {loading && (
                <div className="loadingOverlay">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                        <h1>{loadingText}</h1>
                    </div>
                </div>
            )}
            <div className="scheduleClient servicesSettings">
                <div className="header">
                    <h1>General</h1>
                    <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                     onClick={() => onNextHandler()}
                                     disabled={!nextEnabled}
                    >
                        Next
                    </PSButtonPrimary>
                </div>

                <div className="separator" />

                <div className="form">
                    <PSFormFieldSelection label="Client (required)"
                                          helperLabel="Select a client"
                                          icon={<ClientIcon />}
                                          selection={isEmpty(client) ? undefined : client}
                                          hideButton={!isAddNew}
                                          onClick={() => setSelectModalType('clients')}
                    />
                    <PSFormFieldSelection label="Gift certificate (required)"
                                          helperLabel="Select a gift certificate"
                                          icon={<GiftIcon />}
                                          selection={item}
                                          hideButton={!isAddNew}
                                          onClick={() => setSelectModalType('gifts')}
                    />

                    <h2>Details</h2>
                    <Field className='field formFieldSelection'>
                        <div className='icon'><DollarIcon /></div>
                        <div className='labels'>
                            <Label>Price</Label>

                            <Hint>{totalDisplay}</Hint>
                        </div>
                        <div className='action'>
                            <div className='buttonLink brightBlue'
                                style={{display: 'inline-block', paddingBottom: '5px'}}
                                onClick={() => setShowPriceModal(true)}>
                                    {totalData?.total ? 'Edit': 'Add'}
                            </div>
                        </div>
                    </Field>

                    {!!gift?.paid_date ? (
                        <PSFormFieldSelection label="Expiration"
                                            helperLabel={gift?.describe() || ''}
                                            icon={<CalendarIcon />}
                                            hideButton={true} onClick={() => {}}/>
                        ) : (
                            <PSDropdown selected={expiresIn}
                                        nameProperty="label"
                                        label="Expires after"
                                        placeholder={<div className="grey">Select expiration</div>}
                                        onSelect={(selection) => setExpiresIn(selection)}
                            >
                                <>
                                    {expireOptions.map(option => (
                                        <Item key={`gift-expires${option.value}`} value={option}>
                                            {option.label}
                                        </Item>
                                    ))}
                                </>
                            </PSDropdown>
                        )
                    }

                    <h2>Notifications</h2>
                    <PSDropdown selected={sendVia}
                                nameProperty="label"
                                hint="Choose how you'd like your client to be sent this appointment"
                                label="Send via"
                                onSelect={(selection) => setSendVia(selection)}
                    >
                        <>
                            {bookingSendViaOptions.map(option => (
                                <Item key={`gift-send-via-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>

                    <PSDropdown selected={reminder}
                                nameProperty="label"
                                hint="If a gift certificate remains unpaid, choose how often you’d like your client to be politely reminded until payment is received."
                                label="Payment reminder"
                                onSelect={(selection) => setReminder(selection)}
                    >
                        <>
                            {giftReminderOptions.map(option => (
                                <Item key={`gift-reminder-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>

                    <h2>More options</h2>
                    <Field className="field">
                        <Label className="label">Memo</Label>
                        <PSTextarea placeholder="Add a memo"
                                    className="input"
                                    minRows={5}
                                    maxLength={1500}
                                    value={memo}
                                    onChange={value => setMemo(value)}/>
                    </Field>

                    {owner?.allow_record && !state.settings.loadingAttachments && state.settings.forms.length > 0 && (
                        <PSDropdown selected={form}
                                    nameProperty="name"
                                    onSelect={(selection) => setForm(selection)}
                                    label="Attach a form"
                                    placeholder={<div className="grey">Select a form</div>}
                        >
                            <>
                                {state.settings.forms.map(option => (
                                    <Item key={`form-${option.id}`} value={option}>
                                        {option.name}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>
                    )}

                    {owner?.allow_record && !state.settings.loadingAttachments && state.settings.contracts.length > 0 && (
                        <PSDropdown selected={contract}
                                    nameProperty="name"
                                    onSelect={(selection) => setContract(selection)}
                                    label="Attach a contract"
                                    placeholder={<div className="grey">Select a contract</div>}
                        >
                            <>
                                {state.settings.contracts.map(option => (
                                    <Item key={`contract-${option.id}`} value={option}>
                                        {option.name}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>
                    )}

                </div>

                {selectModalType && (
                    <SelectModal type={selectModalType}
                                 data={getSourceData()}
                                 currentSelections={getCurrentSelectionByType()}
                                 onSelect={(item: any[], extraData: any) => selectedItemFromModal(item, extraData) }
                                 onCancel={() => setSelectModalType(undefined) } />
                )}

                {showPriceModal && (
                    <TotalModal requireTaxLocation
                        currentData={totalData}
                        taxCode={prosTaxCode}
                        onDone={(data: TotalInstance) => {
                            if (data.taxLocation && data.taxLocation !== totalData?.taxLocation && data.nexus === 'transaction') {
                                setLoadingText(`Loading Tax Info`)
                                setLoading(true)
                                dispatch(fetchTaxCodeByLocation(data.taxLocation, (taxCode) => {
                                    setTaxCode(taxCode)
                                    setLoading(false)
                                }))
                            }
                            else if (data.nexus === 'pro' && user?.taxcode) {
                                setTaxCode(prosTaxCode)
                            }

                            setTotalData(data)
                            setShowPriceModal(false)
                        }}
                        onCancel={() => setShowPriceModal(false)}
                        />
                )}
            </div>
        </AppHeader>
    )
}