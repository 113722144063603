import React, {useCallback, useState} from 'react'
import {PSButtonPrimary} from "../app/PSButton";
import {UserInstance} from "../../models/User";
import {ReactComponent as DeviceIcon} from "../../icons/device.svg";
import {apiClient, WEB_BASE} from "../../modules/apiclient";
import {Dots} from "@zendeskgarden/react-loaders";

type Props = {
    user: UserInstance,
    onClick: () => void,
}

export const TextMeBanner = ({user, onClick}: Props) => {
    const [isSending, setIsSending] = useState(false)

    const sendText = useCallback(() => {
        setIsSending(true)
        apiClient.post(`${WEB_BASE}/register/download`)
            .then(() => onClick())
            .finally(() => setIsSending(false))
    }, [onClick])

    return (
        <div className="alertBannerWrapper" id="TextMeBanner">
            <div className="alertBanner">
                <div className="line">
                    <div className="icon">
                        <DeviceIcon style={{width: '28px'}} />
                    </div>
                    <div className="content">
                        <div className="title">
                            Text me the mobile app download link
                        </div>
                        <div className="status" style={{color: '#555555'}}>
                            We'll send the download link to {user.phone}
                        </div>
                    </div>
                    <div className="view" onClick={sendText}>
                        {isSending && <Dots style={{marginRight: 12}} />}
                        {!isSending && <PSButtonPrimary>Text me</PSButtonPrimary>}
                    </div>
                </div>
            </div>
        </div>
    )
}