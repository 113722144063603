import React, {useCallback, useEffect, useState} from "react";
import {AppHeader} from "../../app/AppHeader";
import {ReactComponent as ClientIcon} from "../../../icons/ps_general_person_nobg.svg";
import {ReactComponent as DollarIcon} from "../../../icons/ps_general_money_nobg.svg";
import {ReactComponent as PackageIcon} from "../../../icons/ps_settings_packages_nobg.svg";
import {PSFormFieldSelection} from "../../app/PSFormFieldSelection";
import {useAppDispatch, usePSOwner, usePSTotalDisplay, usePSUser, useTaxCodeLookup} from "../../../hooks";
import {
    fetchAttachments,
    fetchItems,
    SettingsState
} from "../../../modules/settings";
import {
    syncClient,
    syncContacts,
    clearClientState
} from "../../../modules/contacts"
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {Field, Hint, Label} from "@zendeskgarden/react-forms";
import {PSDropdown} from "../../app/PSDropdown";
import {
    bookingSendViaOptions,
    findSelection,
    numberOptions,
    SelectOption,
    packageReminderOptions,
    sessionExpireOptions
} from "../../settings/Options";
import {Item} from "@zendeskgarden/react-dropdowns";
import {PSTextarea} from "../../app/PSTextarea";
import {useLocation, useParams} from "react-router";
import {SelectModal} from "../SelectModal";
import "../transactions.css";
import {PSButtonPrimary} from "../../app/PSButton";
import {TransactionProgress} from "../TransactionProgress";
import {TotalInstance, TotalModal} from "../TotalModal";
import {Spinner} from "@zendeskgarden/react-loaders";
import { TaxcodeInstance } from "../../../models/Taxcode";
import { apiClient } from "../../../modules/apiclient";
import { push } from "connected-react-router";
import { expirationStrings, Package } from "../../../models/Package";
import { fetchPackage, fetchTaxCodeById, fetchTaxCodeByLocation } from "../../../modules/transactions";
import debounce from "lodash.debounce";
import { isEmpty, reportError } from "../../../utils";
import { LineItem } from "../../../models/LineItem";

const numberOfSessionOptions = numberOptions(100)
const findNumberOfSessionsOptionSelection = (value: string) => findSelection(numberOfSessionOptions, value)

export const PackageView = () => {
    const dispatch = useAppDispatch()
    const location = useLocation()
    const locationState: {package: Partial<Package>, client: any} = location.state as {package: Partial<Package>, client: any}
    const params = useParams<{id?: string}>()
    const isAddNew = !params?.id
    const user = usePSUser()
    const owner = usePSOwner()

    useEffect(() => {
        if (isAddNew) dispatch(clearClientState())
    }, [isAddNew, dispatch])

    const state: {settings: SettingsState, contacts: any, entities: any, records: any} = useSelector((state: RootState) => ({
        settings: state.settings,
        contacts: state.contacts,
        entities: state.entities,
        records: state.records,
    }))

    const [client, setClient] = useState<any>({})
    const [_package, setPackage] = useState<Package>()
    const [item, setItem] = useState<any>()
    const [expiresAfter, setExpiresAfter] = useState<SelectOption>()
    const [sendVia, setSendVia] = useState(bookingSendViaOptions[2])
    const [reminder, setReminder] = useState(packageReminderOptions[0])
    const [numberSessions, setNumberSessions] = useState<SelectOption>()
    const [giftCertificates, setGiftCertificates] = useState<any[]>()
    const [memo, setMemo] = useState('')
    const [form, setForm] = useState<any>('')
    const [contract, setContract] = useState<any>('')
    const [nextEnabled, setNextEnabled] = useState(false)
    const [loading, setLoading] = useState(!isAddNew)
    const [loadingText, setLoadingText] = useState('Loading Package')
    const [showPriceModal, setShowPriceModal] = useState(false)
    const [taxCode, setTaxCode] = useState<TaxcodeInstance>()
    const prosTaxCode = useTaxCodeLookup(owner)
    const [totalData, setTotalData] = useState<TotalInstance>()
    const totalDisplay = usePSTotalDisplay(totalData, `Add a price`)
    const [selectModalType, setSelectModalType] = useState<'packages' | 'clients' | undefined>()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchGiftCertificates = useCallback(debounce((client: string) => {
        if (!client) return

        setGiftCertificates(undefined)
        return apiClient.post(`/package/sync`, { type: 'client', client})
            .then(resp => resp.data)
            .then((json: any) => {
                if (json.gifts) setGiftCertificates(json.gifts)
            })
            .catch(error => reportError('fetchGiftCertificates', { error, client }))
    }, 300), [])

    const getPackages = useCallback(() =>
        state.settings.items.filter((i: any) => i.type === 'package') ?? []
    , [state.settings.items])

    const getSourceData = useCallback(() => {
        switch (selectModalType) {
            case 'packages':
                return getPackages()
            case 'clients':
                return state.contacts.contacts ?? []
        }
    }, [selectModalType, getPackages, state.contacts.contacts])

    useEffect(() => {
        dispatch(fetchAttachments())
        dispatch(fetchItems())
        dispatch(syncContacts(''))
    }, [dispatch])

    useEffect(() => {
        if (locationState?.package) {
            setPackage(new Package(locationState.package))
        }
    }, [locationState])

    useEffect(() => {
        if (!owner || totalData) return

        setTotalData({
            surchargeEnabled: owner.allow_surcharge
        } as TotalInstance)
    }, [owner, totalData])

    useEffect(() => {
        if (isAddNew || state.settings.items.length === 0) return
        if (locationState?.package) {
            setItem(getPackages()?.find(s => locationState?.package!.item === s.id))
            return
        }

        // @ts-ignore
        dispatch(fetchPackage(params.id!)).then((_package: Package) => {
            setPackage(_package)
            setItem(getPackages()?.find(s => _package.item === s.id))
        })

    }, [dispatch, isAddNew, params?.id, state.settings.items.length, getPackages, locationState])

    useEffect(() => {
        if (client?.id) fetchGiftCertificates(client.id)
    }, [client?.id, fetchGiftCertificates])

    useEffect(() => {
        setClient(!isEmpty(state.contacts.contact) ? state.contacts.contact : locationState?.client)
        setLoading(false)
    }, [locationState?.client, state.contacts.contact])

    const selectedItemFromModal = (item: any[], extraData: any) => {
        const [selected] = item
        switch (selectModalType) {
            case 'clients':
                if (client?.id === selected?.id) {
                    setClient({})
                }
                else {
                    setLoading(true)
                    dispatch(syncClient(selected.id))
                }
                break
            case 'packages':
                const found = getPackages().find(item => item.id === selected.id)
                setItem(found)
                setPackage(new Package({
                    ...found,
                    plan: selected.id?.toString(),
                    id: _package?.id,
                } as Partial<Package>))
                if (found?.expires) {
                    setExpiresAfter({
                        value: found.expires.toString(),
                        label: expirationStrings[found.expires]
                    })
                }
                break
        }
        setSelectModalType(undefined)
    }

    useEffect(() => {
        if (!_package) return

        setTotalData(currentData => {
            return {
                ...currentData,
                total: Number(_package.rate || _package.total),
                discountRate: !currentData?.discountRate ? Number(_package.discount) : currentData?.discountRate,
                discountType: !currentData?.discountType ? _package.discount_type : currentData?.discountType,
                discountInstances: _package.discount_instances,
                surchargeEnabled: _package.allow_surcharge,
                deposit: _package.deposit,
                nexus: _package.nexus || '',
                taxLocation: _package.location
            } as TotalInstance
        })

        if (_package.taxcode && prosTaxCode?.id !== _package.taxcode) {
            dispatch(fetchTaxCodeById(_package.taxcode, (taxCode) => {
                setTaxCode(taxCode)
            }))
        }
        else if (_package.taxcode && prosTaxCode?.id === _package.taxcode) {
            setTaxCode(prosTaxCode)
        }

        setMemo(_package.memo)
        if (_package.expires) setExpiresAfter({
            value: _package.expires.toString(),
            label: expirationStrings[_package.expires]
        })

        setNumberSessions(findNumberOfSessionsOptionSelection(_package.capacity?.toString()))
        setReminder(packageReminderOptions.find(option => option.value === _package.reminder) || packageReminderOptions[0])

    }, [_package, getPackages, prosTaxCode, dispatch])

    const findSendViaOption = (val: string) => bookingSendViaOptions.find(option => option.value === val) || bookingSendViaOptions[2]
    useEffect(()=> {
        if (!isAddNew && _package?.channel) setSendVia(findSendViaOption(_package.channel))
        else if (state.contacts.client.notify) setSendVia(findSendViaOption(state.contacts.client.notify))
        else if (user?.channel) setSendVia(findSendViaOption(user.channel))
        else setSendVia(bookingSendViaOptions[2])
    }, [_package?.channel, user?.channel, state.contacts.client.notify, isAddNew])

    useEffect(() => {
        if (isAddNew && state.contacts.client.discount) {
            setTotalData(currentData => {
                return {
                    ...currentData,
                    discountRate: Number(state.contacts.client.discount),
                    discountType: 'percent'
                } as TotalInstance
            })
        }
    }, [state.contacts.client.discount, isAddNew])

    useEffect(() => {
        if (!_package?.client) return

        if (isEmpty(state.contacts.contact) || state.contacts.contact.id !== client?.id) dispatch(syncClient(_package.client))

    }, [_package?.client, client, state.contacts.contact, dispatch])


    useEffect(() => {
        if (!_package) return

        if ((totalData?.nexus || _package.nexus) === 'transaction' && taxCode?.id !== (totalData?.taxcode || _package.taxcode) && (totalData?.taxLocation || _package.location)) {
            dispatch(fetchTaxCodeByLocation(totalData?.taxLocation || _package.location!, (taxCode) => {
                setTaxCode(taxCode)
            }))
        }
    }, [_package, totalData, dispatch, taxCode?.id])

    useEffect(() => {
        if (!_package) return

        if (_package.item) {
            const p = getPackages().find(s => s.id === _package.item)
            if (p) setItem(p)
        }
    }, [dispatch, _package, getPackages])

    useEffect(()=> {
        setTotalData(currentData => {
            return {
                ...currentData,
                tax: taxCode?.rate,
                taxcode: taxCode?.id
            } as TotalInstance
        })
    }, [taxCode])

    useEffect(() => {
        if (!_package) return

        setForm(state.settings.forms.find(form => form.id === _package.form))
        setContract(state.settings.contracts.find(contract => contract.id === _package.contract))
        // @ts-ignore
    }, [_package, state.settings.forms, state.settings.contracts])

    const getCurrentSelectionByType = () => {
        switch (selectModalType) {
            case 'clients': return client ? [client] : []
            case 'packages': return item ? [item] : []
            default: return []
        }
    }

    useEffect(() => {
        setNextEnabled(!isEmpty(client) && !isEmpty(_package) && !isEmpty(totalData))
    }, [client, _package, totalData, taxCode])

    const onNextHandler = () => {
        const packageObj = new Package({
            ..._package,
            instances: Number(numberSessions?.value) || undefined,
            form: form?.id,
            contract: contract?.id,
            client: client!.id,
            memo,
            item: item?.id,
            expires: Number(expiresAfter?.value),
            nexus: totalData?.nexus,
            channel: sendVia?.value,
            reminder: reminder?.value,
            total: totalData?.total,
            discount: totalData?.discountRate?.toString(),
            discount_type: totalData?.discountType,
            discount_instances: totalData?.discountInstances,
            deposit: totalData?.setupFee,
            tax: Number(taxCode?.rate) || undefined,
            taxcode: taxCode?.id,
            allow_surcharge: totalData?.surchargeEnabled,
            location: totalData?.taxLocation,
            _gift: totalData?.giftCertificate,
            gift: totalData?.giftCertificate?.id,
            lines: [new LineItem({
                total: Number(totalData?.total) || 0,
                rate: Number(totalData?.total) || 0,
                quantity: 1,
                taxable: taxCode?.rate !== undefined,
                discountable: (totalData?.discountRate ?? 0) !== 0,
            })]
        });
        if (totalData?.paymentPlan) {
            const [paymentCount, paymentPercent] = totalData.paymentPlan.split('_')
            packageObj.max_payment_count = paymentCount || ''
            packageObj.min_payment_percent = paymentPercent || ''
        }


        dispatch(push(isAddNew ? '/package/preview' : `/package/${params.id!}/preview`, {
            package: packageObj,
            client
        }))
    }

    return (
        <AppHeader showBackButton={true} title="Packages" middleWidget={<TransactionProgress created secondStep='Preview' thirdStep='Save' />}>
            {loading && (
                <div className="loadingOverlay">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                        <h1>{loadingText}</h1>
                    </div>
                </div>
            )}
            <div className="scheduleClient servicesSettings">
                <div className="header">
                    <h1>General</h1>
                    <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                     onClick={() => onNextHandler()}
                                     disabled={!nextEnabled}
                    >
                        Next
                    </PSButtonPrimary>
                </div>

                <div className="separator" />

                <div className="form">
                    <PSFormFieldSelection label="Client (required)"
                                          helperLabel="Select a client"
                                          icon={<ClientIcon />}
                                          selection={isEmpty(client) ? undefined : client}
                                          hideButton={!isAddNew}
                                          onClick={() => setSelectModalType('clients')}
                    />
                    <PSFormFieldSelection label="Package (required)"
                                          helperLabel="Select a package"
                                          icon={<PackageIcon />}
                                          selection={item}
                                          hideButton={!isAddNew}
                                          onClick={() => setSelectModalType('packages')}
                    />

                    <h2>Details</h2>
                    <Field className='field formFieldSelection'>
                        <div className='icon'><DollarIcon /></div>
                        <div className='labels'>
                            <Label>Price</Label>

                            <Hint>{totalDisplay}</Hint>
                        </div>
                        <div className='action'>
                            <div className='buttonLink brightBlue'
                                style={{display: 'inline-block', paddingBottom: '5px'}}
                                onClick={() => setShowPriceModal(true)}>
                                    {totalData?.total ? 'Edit': 'Add'}
                            </div>
                        </div>
                    </Field>

                    <PSDropdown selected={numberSessions}
                                nameProperty="label"
                                hint="Choose the number of sessions included in this package"
                                placeholder={<div className="grey">Select # of sessions</div>}
                                label="Sessions"
                                onSelect={(selection) => setNumberSessions(selection)}
                    >
                        <>
                            {numberOfSessionOptions.map(option => (
                                <Item key={`number-options-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>

                    <PSDropdown selected={expiresAfter}
                            nameProperty="label"
                            label="Expiration after"
                            onSelect={(selection) => setExpiresAfter(selection)}
                            placeholder={<div className="grey">Select expiration</div>}>
                        <>
                            {sessionExpireOptions.map(option => (
                                <Item key={`expires-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>

                    <h2>Notifications</h2>
                    <PSDropdown selected={sendVia}
                                nameProperty="label"
                                hint="Choose how you'd like your client to be sent this appointment"
                                label="Send via"
                                onSelect={(selection) => setSendVia(selection)}
                    >
                        <>
                            {bookingSendViaOptions.map(option => (
                                <Item key={`booking-send-via-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>

                    <PSDropdown selected={reminder}
                                nameProperty="label"
                                hint="If a package remains unpaid, choose how often you’d like your client to be politely reminded until payment is received."
                                label="Payment reminder"
                                onSelect={(selection) => setReminder(selection)}
                    >
                        <>
                            {packageReminderOptions.map(option => (
                                <Item key={`package-reminder-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>

                    <h2>More options</h2>
                    <Field className="field">
                        <Label className="label">Memo</Label>
                        <PSTextarea placeholder="Add a memo"
                                    className="input"
                                    minRows={5}
                                    maxLength={1500}
                                    value={memo}
                                    onChange={value => setMemo(value)}/>
                    </Field>

                    {owner?.allow_record && !state.settings.loadingAttachments && state.settings.forms.length > 0 && (
                        <PSDropdown selected={form}
                                    nameProperty="name"
                                    onSelect={(selection) => setForm(selection)}
                                    label="Attach a form"
                                    placeholder={<div className="grey">Select a form</div>}
                        >
                            <>
                                {state.settings.forms.map(option => (
                                    <Item key={`form-${option.id}`} value={option}>
                                        {option.name}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>
                    )}

                    {owner?.allow_record && !state.settings.loadingAttachments && state.settings.contracts.length > 0 && (
                        <PSDropdown selected={contract}
                                    nameProperty="name"
                                    onSelect={(selection) => setContract(selection)}
                                    label="Attach a contract"
                                    placeholder={<div className="grey">Select a contract</div>}
                        >
                            <>
                                {state.settings.contracts.map(option => (
                                    <Item key={`contract-${option.id}`} value={option}>
                                        {option.name}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>
                    )}

                </div>

                {selectModalType && (
                    <SelectModal type={selectModalType}
                                 data={getSourceData()}
                                 currentSelections={getCurrentSelectionByType()}
                                 onSelect={(item: any[], extraData: any) => selectedItemFromModal(item, extraData) }
                                 onCancel={() => setSelectModalType(undefined) } />
                )}

                {showPriceModal && (
                    <TotalModal
                        showGiftCertificates requireTaxLocation showPaymentPlan
                        currentData={totalData}
                        taxCode={prosTaxCode}
                        giftCertificates={giftCertificates || []}
                        onDone={(data: TotalInstance) => {
                            if (data.taxLocation && data.taxLocation !== totalData?.taxLocation && data.nexus === 'transaction') {
                                setLoadingText(`Loading Tax Info`)
                                setLoading(true)
                                dispatch(fetchTaxCodeByLocation(data.taxLocation, (taxCode) => {
                                    setTaxCode(taxCode)
                                    setLoading(false)
                                }))
                            }
                            else if (data.nexus === 'pro' && user?.taxcode) {
                                setTaxCode(prosTaxCode)
                            }

                            setTotalData(data)
                            setShowPriceModal(false)
                        }}
                        onCancel={() => setShowPriceModal(false)}
                        />
                )}
            </div>
        </AppHeader>
    )
}