import React, {useEffect, useState} from "react"
import {AppHeader} from "../app/AppHeader";
import {Spinner} from "@zendeskgarden/react-loaders";
import {
    PSButtonPrimary,
    PSButtonSimple
} from "../app/PSButton";
import {useAppDispatch, usePSUser} from "../../hooks";
import {PSTextarea} from "../app/PSTextarea";
import {ReactComponent as AddProfilePhoto} from "../../icons/onboarding_addphoto.svg"
import {Field, Label} from "@zendeskgarden/react-forms";
import {usePhotoUpload} from "../settings/hooks";
import {apiClient} from "../../modules/apiclient";
import {saveUser} from "../../modules/settings";
import {Result} from "../../utils";
import {push} from "connected-react-router";
import localforage from "localforage";

export const OnboardingSetupProfile = () => {
    const dispatch = useAppDispatch()
    const user = usePSUser()

    const [isLoading, setIsLoading] = useState(false)
    const [isSending, setIsSending] = useState(false)
    const [businessDescription, setBusinessDescription] = useState('')

    const [photo, setPhoto] = useState<undefined | string | File>('')
    const [addPhotoRef, selectPhoto, selectedPhoto] = usePhotoUpload({disabled: isSending, setPhoto})
    useEffect(() => {
        if (!user) return
        setPhoto(user.image_abs_uri)
        if (user.bio)
            setBusinessDescription(user.bio)
        else if (user.edition || user.industry) {
            setIsLoading(true)
            const editionID = user.edition ? user.edition : `${user.industry}-industry-edition`
            apiClient.post('/edition/sync', {edition: editionID})
                .then(resp => resp.data)
                .then(json => {
                    if (json.bio)
                        setBusinessDescription(json.bio)
                })
                .finally(() => setIsLoading(false))
        }
    }, [user])

    const onNextHandler = () => {
        if (!user) return

        setIsSending(true)

        const userParams: any = {}
        userParams.id = user.id
        userParams.bio = businessDescription

        Result(dispatch(saveUser(userParams, photo && typeof photo !== 'string' ? (photo as File) : undefined)))
            .then(() => {
                // Don't use setOnboardingState, as we want to manually redirect to /onboarding/success
                return apiClient.post('/onboarding', { state: 'ob_complete', dashboard: 'setup_onboarding' })
                    .then(resp => resp.data)
                    .then(json => {
                        if (json.user) localforage.setItem('user', json.user)
                    })
            })
            .then(() => {
                dispatch(push('/onboarding/success'))
            })
            .finally(() => setIsSending(false))
    }

    return (
        <AppHeader title="Setup" showBackButton middleWidget={null}>
            {(isLoading || isSending) && (
                <div className="loadingOverlay">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                        <h1>{isSending ? 'Saving...' : 'Loading...'}</h1>
                    </div>
                </div>
            )}
            <div className="scheduleClient servicesSettings onboarding onboardingSetupProfile">
                <div className="header">
                    <h1>{photo ? 'Looking great!' : 'Add your photo'}</h1>
                    <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                     onClick={() => onNextHandler()}
                    >
                        Next
                    </PSButtonPrimary>
                </div>

                <div className="separator" />

                <div className="form">
                    {!photo && (
                        <div className="text">This photo will represent you and your business. You can make it a logo or of yourself!</div>
                    )}
                    <div className="addPhoto">
                        {!photo && (
                            <AddProfilePhoto style={{cursor: 'pointer'}}
                                             onClick={() => selectPhoto()} />
                        )}
                        {photo && typeof photo === 'string' && (
                            <img alt="My business" src={photo} />
                        )}
                        {photo && typeof photo !== 'string' && (
                            <img alt="My business" src={URL.createObjectURL(photo)} />
                        )}
                        <input type="file"
                               ref={addPhotoRef}
                               onChange={selectedPhoto}
                               accept="image/png,image/jpg,image/jpeg"
                               style={{display: 'none'}} />
                        <div style={{marginBottom: 8}} />
                        <PSButtonSimple style={{width: 120, height: 44}}
                                        onClick={() => selectPhoto()}>
                            {photo ? 'Replace photo' : 'Add photo'}
                        </PSButtonSimple>
                    </div>
                    <Field className="field">
                        <Label className="label">Your business description</Label>
                        <PSTextarea placeholder="Enter your business description"
                                    className="input"
                                    minRows={4}
                                    maxLength={999}
                                    value={businessDescription}
                                    onChange={value => setBusinessDescription(value)} />
                    </Field>
                </div>
            </div>
        </AppHeader>
    )
}
