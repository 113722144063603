import React from "react";
import { Link } from "react-router-dom";
import {currencyFormat} from "../../utils/numbers";
import {Dropdown, Item, Menu, Trigger} from "@zendeskgarden/react-dropdowns";
import {IconButton} from "@zendeskgarden/react-buttons";
import {ReactComponent as GeneralCalendarIcon} from "../../icons/ps_general_calendar.svg";
import {ReactComponent as SettingsProductIcon} from "../../icons/ps_settings_products.svg";
import {ReactComponent as SettingsGiftIcon} from "../../icons/ps_settings_gift_certs.svg";
import {ReactComponent as SettingsReservationIcon} from "../../icons/ps_settings_reservations.svg";
import {ReactComponent as SettingsPackageIcon} from "../../icons/ps_settings_packages.svg";
import {ReactComponent as SettingsSubscriptionIcon} from "../../icons/ps_settings_subscriptions.svg";
import {ReactComponent as SettingsClassIcon} from "../../icons/ps_settings_classes.svg";
import {ReactComponent as SettingsContractIcon} from "../../icons/ps_settings_contracts.svg";
import {ReactComponent as SettingsFormIcon} from "../../icons/ps_settings_forms.svg";
import {ReactComponent as KabobIcon} from "../../icons/kabob_menu.svg";
import {push} from "connected-react-router";
import {useAppDispatch} from "../../hooks";

type Props = {
    item: any,
    type: string,
    onCopyItem: () => void,
    onShareLink?: () => void,
    onEditSchedule?: () => void,
    pluralName?: string,
}

export const ItemCard = ({item, type, onCopyItem, onShareLink, onEditSchedule, pluralName}: Props) => {
    const dispatch = useAppDispatch()

    let subtitleText
    let kabobMenuItems
    let itemImage

    switch (type) {
        case 'product':
            itemImage = <SettingsProductIcon />
            break
        case 'gift':
            itemImage = <SettingsGiftIcon />
            break
        case 'reservation':
            itemImage = <SettingsReservationIcon />
            break
        case 'package':
            itemImage = <SettingsPackageIcon />
            break
        case 'subscription':
            itemImage = <SettingsSubscriptionIcon />
            break
        case 'class':
            itemImage = <SettingsClassIcon />
            break
        case 'contract':
            itemImage = <SettingsContractIcon />
            break
        case 'form':
            itemImage = <SettingsFormIcon />
            subtitleText = `${item.fields?.length} questions`
            kabobMenuItems = (
                <>
                    <Item onClick={() => onShareLink && onShareLink()} value="share">
                        Share form link
                    </Item>
                    <Item onClick={() => dispatch(push(`/settings/forms/${item.id}/questions`))} value="questions">
                        View & edit questions
                    </Item>
                    <Item onClick={() => onCopyItem()} value="copy">
                        Copy {type}
                    </Item>
                </>
            )
            break
        case 'service':
        default:
            itemImage = <GeneralCalendarIcon />
            break
    }

    if (item.image_uri)
        itemImage = <img alt={item.name} src={item.image_uri} />

    if (!pluralName)
        pluralName = `${type}s`

    return (
        <div className="serviceCard">
            {itemImage}
            <div className="details">
                <div className="name">{item.name}</div>
                {subtitleText && (
                    <div className="rate">{subtitleText}</div>
                )}
                {type !== 'contract' && !subtitleText && (
                    <div className="rate">{currencyFormat(item.rate)}</div>
                )}
            </div>
            <div className="edit">
                <Link to={`/settings/${pluralName}/${item.id}/edit`}
                      className="edit">
                    Edit
                </Link>
            </div>
            {kabobMenuItems && (
                <Dropdown>
                    <Trigger>
                        <IconButton focusInset={false}>
                            <KabobIcon />
                        </IconButton>
                    </Trigger>
                    <Menu hasArrow>
                        {kabobMenuItems}
                    </Menu>
                </Dropdown>
            )}
            {type !== 'contract' && !kabobMenuItems && (
                <Dropdown>
                    <Trigger>
                        <IconButton focusInset={false}>
                            <KabobIcon />
                        </IconButton>
                    </Trigger>
                    <Menu hasArrow>
                        {onShareLink && (
                            <Item onClick={() => onShareLink()} value="share">
                                Share booking link
                            </Item>
                        )}
                        <Item onClick={() => onCopyItem()} value="copy">
                            Copy {type}
                        </Item>
                        {type === 'class' && onEditSchedule && (
                            <Item onClick={() => onEditSchedule()} value="schedule">
                                View & edit class schedule
                            </Item>
                        )}
                    </Menu>
                </Dropdown>
            )}
        </div>
    )
}