import React from "react";
import {currencyFormat} from "../../utils/numbers";
import {ReactComponent as GeneralCalendarIcon} from "../../icons/ps_general_calendar.svg";
import {ReactComponent as SettingsProductIcon} from "../../icons/ps_settings_products.svg";
import {ReactComponent as SettingsGiftIcon} from "../../icons/ps_settings_gift_certs.svg";
import {ReactComponent as SettingsReservationIcon} from "../../icons/ps_settings_reservations.svg";
import {ReactComponent as SettingsPackageIcon} from "../../icons/ps_settings_packages.svg";
import {ReactComponent as SettingsSubscriptionIcon} from "../../icons/ps_settings_subscriptions.svg";
import {ReactComponent as SettingsClassIcon} from "../../icons/ps_settings_classes.svg";
import {ReactComponent as GripIcon} from "../../icons/grip.svg";
import {Dropdown, Item, Menu, Trigger} from "@zendeskgarden/react-dropdowns";

type Props = {
    item: any,
    type: string,
    isDragging: boolean,
    handleProps?: any,
    showMenu?: boolean,
    onMenuSelect?: (selected: string, categoryId: string) => void,
}

export const SortableItemCard = ({item, type, isDragging, handleProps, showMenu, onMenuSelect}: Props) => {
    let itemImage
    if (item.image_uri)
        itemImage = <img alt={item.name} src={item.image_uri} />
    else {
        switch (type) {
            case 'product':
                itemImage = <SettingsProductIcon />
                break
            case 'gift':
                itemImage = <SettingsGiftIcon />
                break
            case 'reservation':
                itemImage = <SettingsReservationIcon />;
                break;
            case 'package':
                itemImage = <SettingsPackageIcon />;
                break;
            case 'subscription':
                itemImage = <SettingsSubscriptionIcon />;
                break;
            case 'class':
                itemImage = <SettingsClassIcon />;
                break;
            case 'service':
            default:
                itemImage = <GeneralCalendarIcon />
                break
        }
    }

    return (
        <div className="serviceCard hoverHighlight" style={{backgroundColor: isDragging ? '#F0F2F4' : ''}}>
            {itemImage}
            <div className="details">
                <div className="name">{item.name}</div>
                {item.rate && (
                    <div className="rate">{currencyFormat(item.rate)}</div>
                )}
            </div>
            <div className="edit" style={{display: 'flex'}}>
                {showMenu && (
                    <Dropdown>
                        <Trigger>
                            <span style={{paddingRight: 16, verticalAlign: 'top', cursor: 'pointer'}}>Select</span>
                        </Trigger>
                        {onMenuSelect && (
                            <Menu hasArrow>
                                <Item value="edit"
                                      onClick={() => onMenuSelect('edit', item.id)}>
                                    Edit
                                </Item>
                                <Item value="preview"
                                      onClick={() => onMenuSelect('preview', item.id)}>
                                    Preview
                                </Item>
                                <Item value="sort"
                                      onClick={() => onMenuSelect('sort', item.id)}>
                                    Sort items
                                </Item>
                            </Menu>
                        )}
                    </Dropdown>
                )}
                <div {...handleProps}>
                    <GripIcon />
                </div>
            </div>
        </div>
    )
}
