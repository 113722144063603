import React, {useEffect, useState} from "react";
import {Checkbox, Field, Label} from "@zendeskgarden/react-forms";
import {Dropdown, Item, Menu, Select} from "@zendeskgarden/react-dropdowns";
import {
    Field as DropdownField
} from "@zendeskgarden/react-dropdowns";

import "./psavailablehoursperday.css"
import {availabilityHours, SelectOption} from "../settings/Options";

type Props = {
    enabled: boolean;
    label: string;
    onEnabled: (enabled: boolean) => void;
    startValue: SelectOption;
    onStartChanged: (s: SelectOption) => void;
    endValue: SelectOption;
    onEndChanged: (s: SelectOption) => void;
}

export const PSAvailableHoursPerDay = ({label, enabled, onEnabled, startValue, onStartChanged, endValue, onEndChanged}: Props) => {
    const [endHours, setEndHours] = useState(availabilityHours.slice(20))
    const [endValueWrapper, setEndValueWrapper] = useState(endValue)

    const onSelectStartItem = (selection: SelectOption) => {
        // Have end hours show only 2 hours from the currently selected startValue
        const index = availabilityHours.findIndex((value) => value.value === selection.value)
        if (index > -1) {
            setEndHours(availabilityHours.slice(index+4))
        }

        onStartChanged(selection)
    }

    useEffect(() => {
        // If the current end value is not available in the list, set it to the
        // first available value
        if (parseFloat(endValue.value) < parseFloat(endHours[0].value))
            setEndValueWrapper(endHours[0])
        else
            setEndValueWrapper(endValue)
    }, [endValue, endHours])

    useEffect(() => {
        onEndChanged(endValueWrapper)
    }, [onEndChanged, endValueWrapper])

    return (
        <div className="psAvailableHours">
            <Field className="field">
                <div className="check">
                    <Checkbox checked={enabled}
                              onChange={e => onEnabled(e.target.checked)}>
                        <Label>{label}</Label>
                    </Checkbox>
                </div>
                {!enabled && (
                    <div className="unavailable">Unavailable</div>
                )}
                {enabled && (
                    <div className="timeSelection">
                        <Dropdown selectedItem={startValue}
                                  onSelect={onSelectStartItem}
                                  downshiftProps={{ itemToString: (item: SelectOption) => item.label}}
                        >
                            <DropdownField className="field">
                                <Select className="input">
                                    {startValue.label}
                                </Select>
                            </DropdownField>
                            <Menu>
                                {availabilityHours.slice(0, availabilityHours.length-4).map((option) => (

                                    <Item key={`start-${option.value}`}
                                          value={option}
                                          style={{padding: '8px 0 8px 36px'}}>
                                        {option.label}
                                    </Item>
                                ))}
                            </Menu>
                        </Dropdown>
                        <span className="spacer">-</span>
                        <Dropdown selectedItem={endValueWrapper}
                                  onSelect={(selection) => setEndValueWrapper(selection)}
                                  downshiftProps={{ itemToString: (item: SelectOption) => item.label}}
                        >
                            <DropdownField className="field">
                                <Select className="input">
                                    {endValueWrapper.label}
                                </Select>
                            </DropdownField>
                            <Menu>
                                {endHours.map((option: SelectOption, index: number) => (
                                    <Item key={`end-${index}`}
                                          value={option}
                                          style={{padding: '8px 0 8px 36px'}}>
                                        {option.label}
                                    </Item>
                                ))}
                            </Menu>
                        </Dropdown>
                    </div>
                )}
            </Field>
        </div>
    )
}