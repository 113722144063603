import React from "react";
import {Dashboard} from "../../models/Dashboard";
import {DashboardIcon} from "./DashboardIcon";

type Props = {
    dashboards: Dashboard[],
    onView: (d: Dashboard) => void,
}

export const DashboardGroup = ({dashboards, onView}: Props) => {
    return (
        <div className="dashboardGroup">
            {dashboards.map((dashboard: Dashboard) => {
                let displayValue = <div className="value" />
                if (dashboard.id === 'triggers' || dashboard.id === 'links') {
                    displayValue = (
                        <div className={`value ${dashboard?.metric && dashboard.metric[0] === '-' ? 'red' : 'green'}`}>
                            {dashboard.metric}
                        </div>
                    )
                }

                return (
                    <div className="line" key={`dashboard-${dashboard.id}`}>
                        <div className="icon">
                            <DashboardIcon dashboard={dashboard} />
                        </div>
                        <div className="title">{dashboard.title}</div>
                        <div className="status">{dashboard.status}</div>
                        {displayValue}
                        <div className="metric">{dashboard.count || 0}</div>
                        <div className="view" onClick={() => onView(dashboard)}>View</div>
                    </div>
                )
            })}
        </div>
    )
}