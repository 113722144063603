import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as CloseIcon } from '../../icons/close.svg'
import { currencyFloat, currencyFormat } from '../../utils/numbers'
import moment from 'moment'
import { useSelector } from 'react-redux'
import {Transaction} from '../../models/Transaction'

const PAYMENT_SURCHARGE_RATE = 0.03

const finalChargeAmount = (type, record) => {
    if ((type === 'booking' || type === 'estimate') && !record.paid_date) {
        const transaction = new Transaction(record)
        return transaction.calculateChargeAmount()
    }

    return currencyFloat(record.charge_amount)
}

export const PaymentDetailsModal = (props) => {
    const {type, record} = props
    const state = useSelector(state => (
        {
            entities: state.entities,
        }
    ))

    const handleCloseClick = (e) => {
        if (e.target.className === 'modal') {
            props.onClose()
        }
    }

    let discountName
    if (record.discount) {
        if (record.gift) {
            const gift = state.entities.gifts[record.gift] || {}
            discountName = gift.display_name || 'Gift discount'
        }
        else if (record.promotion)
            discountName = "Smart campaign"
        else
            discountName = "Discount"

        if (record.package)
            discountName = "Prepaid package"
        else if (record.discount_type === 'percent')
            discountName = `Discount (${record.discount}%)`
    }

    let gratuityName
    if (record.gratuity) {
        if (record.gratuity_type === 'percent')
            gratuityName = `Gratuity (${record.gratuity}%)`
        else
            gratuityName = 'Gratuity'
    }

    let netAmountName
    if (type === 'payment') {
        if (record.surcharge)
            netAmountName = `Payment (${PAYMENT_SURCHARGE_RATE*100}% surcharge)`
        else
            netAmountName = 'Payment'
    }
    else
        netAmountName = 'Net amount'

    let payoutStatus
    if (record.deposited_date)
        payoutStatus = `sent on ${moment(record.deposited_date).format('MMM Do, yyyy')}`
    else
        payoutStatus = 'pending'

    return (
        <div className="modal"
             style={{display: props.visible ? 'block' : 'none'}}
             onClick={handleCloseClick}
        >
            <div className="main">
                <div className="close" onClick={e => props.onClose()}>
                    <CloseIcon />
                </div>
                <div className="body">
                    <div className="lineItem total">
                        <div className="title">Total</div>
                        <div className="value">{currencyFormat(record.total)}</div>
                    </div>
                    {Number(record.deposit) > 0 && (
                        <div className="lineItem">
                            <div className="title">
                                Deposit{record.paid_deposit_date && (
                                    ` (paid on ${moment(record.paid_deposit_date).format('MMM Do')})`
                                )}
                            </div>
                            <div className="value">{currencyFormat(record.deposit)}</div>
                        </div>
                    )}
                    {Number(record.discount) > 0 && (
                        <>
                            <div className="lineItem">
                                <div className="title">{discountName}</div>
                                <div className="value">-{currencyFormat(record.discount_amount || record.discount)}</div>
                            </div>
                            <div className={`lineItem ${type === 'payment' ? '' : 'bold'}`}>
                                <div className="title">{netAmountName}</div>
                                <div className="value">{currencyFormat(record.amount)}</div>
                            </div>
                        </>
                    )}
                    {Number(record.tax_amount) > 0 && (
                        <div className="lineItem">
                            <div className="title">Sales tax ({Number(record.tax)}%)</div>
                            <div className="value">{currencyFormat(record.tax_amount)}</div>
                        </div>
                    )}
                    {Number(record.tip) > 0 && (
                        <div className="lineItem">
                            <div className="title">Gratuity</div>
                            <div className="value">{currencyFormat(record.tip)}</div>
                        </div>
                    )}
                    {Number(record.gratuity_amount) > 0 && (
                        <div className="lineItem">
                            <div className="title">{gratuityName}</div>
                            <div className="value">{currencyFormat(record.gratuity_amount)}</div>
                        </div>
                    )}
                    {Number(record.surcharge_amount) > 0 && (
                        <div className="lineItem">
                            <div className="title">Surcharge</div>
                            <div className="value">{currencyFormat(record.surcharge_amount)}</div>
                        </div>
                    )}
                    {type !== 'payment' && (
                        <div className="lineItem">
                            <div className="title">
                                {record.paid_date && "Amount charged"}
                                {!record.paid_date && "Amount to charge"}
                            </div>
                            <div className="value">
                                {currencyFormat(finalChargeAmount(type, record))}
                            </div>
                        </div>
                    )}
                    {Number(record.paid_amount) > 0 && !record.paid_date && (
                        <div className="lineItem">
                            <div className="title">Total owed</div>
                            <div className="value">{currencyFormat(record.owed_amount)}</div>
                        </div>
                    )}
                    {Number(record.balance) > 0 && (
                        <div className="lineItem">
                            <div className="title">Remaining balance</div>
                            <div className="value">{currencyFormat(record.balance)}</div>
                        </div>
                    )}
                    {Number(record.payout) > 0 && (
                        <div className="lineItem">
                            <div className="title">Payout ({payoutStatus})</div>
                            <div className="value bold">{currencyFormat(record.payout)}</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

PaymentDetailsModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    record: PropTypes.object.isRequired,
}