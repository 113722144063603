import React, {useCallback, useEffect, useState} from "react";
import {
    PSButtonDanger,
    PSButtonPrimary,
    PSToggleButton
} from "../../app/PSButton";
import {Dots, Spinner} from "@zendeskgarden/react-loaders";
import {
    Checkbox,
    Field,
    Hint,
    Input,
    Label, Radio,
} from "@zendeskgarden/react-forms";
import {useAppDispatch, usePSOwner} from "../../../hooks";
import {
    clearItem,
    clearPackage, deleteItem,
    fetchAttachments, fetchItem,
    fetchItems, saveItem,
    SettingsState
} from "../../../modules/settings";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {useLearnMore, usePackageComponent, usePhotoUpload} from "../hooks";
import {Body, Close, Header, Modal} from "@zendeskgarden/react-modals";
import {currencyFloat, filterMonetaryInput} from "../../../utils/numbers";
import {PSDropdown} from "../../app/PSDropdown";
import {
    discountOptions, instanceOptions,
    SelectOption,
    sessionCountOptions,
    sessionHourOptions,
    subscriptionFrequencyOptions
} from "../Options";
import {Dropdown, Item, Menu, Select, Field as DropdownField} from "@zendeskgarden/react-dropdowns";
import {Prompt, useHistory, useParams} from "react-router";
import {
    EnabledServiceSelection,
    SelectServicesModal
} from "../packages/SelectServicesModal";
import findIndex from "lodash.findindex";
import {Item as PSItem} from "../../../models/Item";
import {SettingsPhotoField} from "../SettingsPhotoField";
import localforage from "localforage";
import {GuardrailModal} from "../GuardrailModal";
import {PSTextarea} from "../../app/PSTextarea";
import {updateItem} from "../../../modules/onboarding";

type Props = {
    forOnboarding?: boolean,
    refreshData?: boolean,
}

export const SubscriptionEdit = ({forOnboarding, refreshData}: Props) => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const history = useHistory()

    const [photo, setPhoto] = useState<undefined | string | File>('')
    const [image, setImage] = useState<undefined | string>('') // For editing
    const [name, setName] = useState('')
    const [price, setPrice] = useState('')
    const [setupFee, setSetupFee] = useState('')
    const [discount, setDiscount] = useState<any>('')
    const [discountInstance, setDiscountInstance] = useState(instanceOptions[0])
    const [salesTax, setSalesTax] = useState(false)
    const [description, setDescription] = useState('')
    const [frequency, setFrequency] = useState<any>('')
    const [sessionCount, setSessionCount] = useState<SelectOption>()
    const [limitBy, setLimitBy] = useState('session')
    const [servicesSelection, setServicesSelection] = useState('')
    const [instances, setInstances] = useState<any>('')
    const [limitedServices, setLimitedServices] = useState<EnabledServiceSelection[]>([])
    const [online, setOnline] = useState(true)
    const [contract, setContract] = useState<any>('')
    const [form, setForm] = useState<any>('')
    const [saveEnabled, setSaveEnabled] = useState(false)
    const [formSending, setFormSending] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)

    const [addPhotoRef, selectPhoto, selectedPhoto] = usePhotoUpload({disabled: state.isSending, setPhoto})
    const [learnMoreVisible, learnMoreTitle, learnMoreBody, showLearnMore] = useLearnMore()
    const [showSelectServicesModal, setShowSelectServicesModal] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    const [salesTaxEnabled, setSalesTaxEnabled] = useState(false)
    const [onlineBookingEnabled, setOnlineBookingEnabled] = useState(false)
    const [discountEnabled, setDiscountEnabled] = useState(false)
    const [formsEnabled, setFormsEnabled] = useState(false)
    const [contractsEnabled, setContractsEnabled] = useState(false)
    const owner = usePSOwner()
    useEffect(() => {
        if (!owner) return
        setSalesTaxEnabled(owner.allow_salestax)
        setOnlineBookingEnabled(owner.allow_widget)
        setDiscountEnabled(owner.allow_discount)
        setFormsEnabled(owner.allow_record)
        setContractsEnabled(owner.allow_contract)
    }, [owner])

    useEffect(() => {
        setSaveEnabled(!!name && !!price && !!frequency)
    }, [name, price, frequency])

    // Load the subscription if this is an edit, otherwise treat it as a create
    const params: any = useParams()
    useEffect(() => {
        if (forOnboarding) return

        if (!params.subscriptionID) {
            dispatch(clearItem())
            dispatch(clearPackage())
            return
        }
        dispatch(fetchItem('subscription', params.subscriptionID))
    }, [dispatch, params.subscriptionID, forOnboarding])

    useEffect(() => {
        setSessionCount(currentVal => {
            if (!currentVal) return currentVal

            if (limitBy !== 'duration') {
                const count = Math.floor(Number(currentVal.value) / 60)
                return sessionCountOptions.find(option => option.value === count.toString())
            }
            else {
                const count = Number(currentVal.value) * 60
                return sessionHourOptions.find(option => option.value === count.toString())
            }
        })
    }, [limitBy])

    useEffect(() => {
        if (forOnboarding) {
            setHasEdits(false)
            return
        }

        let changed = 0
        if ((state.item.name || name) && name !== state.item.name)
            changed = 1
        else if ((state.item.rate || Number(price)) && Number(price) !== Number(state.item.rate))
            changed = 2
        else if ((state.item.deposit || Number(setupFee)) && Number(setupFee) !== Number(state.item.deposit))
            changed = 7
        else if ((state.item.memo || description) && description !== state.item.memo)
            changed = 3
        else if ((state.item.allow_salestax || salesTax) && salesTax !== state.item.allow_salestax)
            changed = 5
        else if ((state.item.discount || discount) && discount?.value !== state.item.discount)
            changed = 6
        else if ((state.item.contract || contract) && contract.id !== state.item.contract)
            changed = 9
        else if ((state.item.form || form) && form.id !== state.item.form)
            changed = 10
        else if ((state.item.is_public || online) && online !== state.item.is_public)
            changed = 11
        else if (limitBy === 'session' && (state.item.capacity || sessionCount) && Number(sessionCount?.value) !== Number(state.item.capacity))
            changed = 12
        else if (limitBy === 'duration' && (state.item.capacity || sessionCount) && Number(sessionCount?.value) !== Number(state.item.capacity))
            changed = 12
        else if (params.subscriptionID && (state.item.capacity_type || limitBy) && limitBy !== state.item.capacity_type)
            changed = 8
        else if ((state.item.frequency || frequency) && frequency.value !== state.item.frequency)
            changed = 14
        else if ((state.item.instances || instances) && instances.value !== state.item.instances)
            changed = 15
        else if (typeof photo === 'object')
            changed = 13
        // TODO serviceSelection, discountInstances
        setHasEdits(changed > 0)
    }, [
        state.item, name, price, description, salesTax, discount, contract,
        form, online, photo, limitBy, sessionCount, setupFee, frequency,
        instances, discountInstance, params.subscriptionID, forOnboarding,
    ])

    // Fetch the user's contracts and forms
    useEffect(() => {
        dispatch(fetchAttachments())
        dispatch(fetchItems())
    }, [dispatch])

    // Update state of the component when a subscription is loaded
    useEffect(() => {
        if (params.action !== 'copy')
            setName(state.item.name || '')

        setPhoto(state.item.image_uri || '')
        setImage(state.item.image || '')
        setPrice(state.item.rate ? currencyFloat(state.item.rate).toFixed(2) : '')
        setSetupFee(state.item.deposit ? currencyFloat(state.item.deposit).toFixed(2) : '')
        setDescription(state.item.memo || '')
        setOnline(state.item.is_public)

        const selectedDiscount = discountOptions.find(option => option.value === state.item.discount)
        setDiscount(selectedDiscount || '')

        const selectedDiscountInstance = instanceOptions.find(option => Number(option.value) === state.item.discount_instances)
        setDiscountInstance(selectedDiscountInstance || instanceOptions[0])
        setSalesTax(state.item.allow_salestax)

        // Load the package details here
        if (state.item.package && state.package.id) {
            if (state.package.capacity_type === 'session') {
                setLimitBy('session')
                const selectedCapacity = sessionCountOptions.find(option => option.value === state.package.capacity.toString())
                setSessionCount(selectedCapacity)
            }
            else if (state.package.capacity_type === 'duration') {
                setLimitBy('duration')
                const selectedCapacity = sessionHourOptions.find(option => option.value === state.item.capacity.toString())
                setSessionCount(selectedCapacity)
            }

            if (state.package.package_all)
                setServicesSelection('all')
            else {
                setServicesSelection('some')
                if (state.package.components) {
                    const componentList: EnabledServiceSelection[] = []
                    state.package.components.forEach((component: any) => {
                        const itemIndex = findIndex(state.items, item => item.id === component.component)
                        if (itemIndex > -1) {
                            const item = state.items[itemIndex]
                            componentList.push({
                                count: Number(component.capacity),
                                id: component.component,
                                name: item.name,
                            })
                        }
                    })
                    setLimitedServices(componentList)
                }
            }
        }
        else {
            // Clear the package settings
            setLimitBy('session')
            setSessionCount(undefined)
            setServicesSelection('')
            setLimitedServices([])
        }

        const frequencyInitial = state.item.frequency === 'daily' ? 'd' : state.item.frequency === 'weekly' ? 'w' : 'm'
        const frequencyValue = state.item.interval + frequencyInitial
        const selectedFrequency = subscriptionFrequencyOptions.find(option => option.value === frequencyValue)
        setFrequency(selectedFrequency || '')

        const selectedInstance = instanceOptions.find(option => Number(option.value) === state.item.instances)
        setInstances(selectedInstance || '')

        const selectedContract = state.contracts.find((c) => c.id === state.item.contract)
        setContract(selectedContract || '')

        const selectedForm = state.forms.find((f) => f.id === state.item.form)
        setForm(selectedForm || '')
    }, [state.item, state.items, state.contracts, state.forms, params.action, state.package])

    // Guardrail states
    const [showGuardrail, setShowGuardrail] = useState(false)
    const [guardrailTitle, setGuardrailTitle] = useState('')
    const [guardrailBody, setGuardrailBody] = useState<string | React.ReactNode>('')
    const [guardrailButton, setGuardrailButton] = useState('')
    const [guardrailType, setGuardrailType] = useState('')
    const guardrailAction = useCallback(() => {
        // TODO Add unsaved form guardrail
        switch (guardrailType) {
            case 'servicesSelection':
                setServicesSelection('all')
                setLimitedServices([])
                break
            default:
                console.log(`Unknown guardrail type: ${guardrailType}`)
        }
        setShowGuardrail(false)
    }, [guardrailType])

    const changeServicesSelection = (selected: string) => {
        if (selected === 'all') {
            setGuardrailTitle('Apply subscription to all services')
            setGuardrailBody('If a client has this subscription (and the subscription is active) they will be able to book any of your services and the price of that service will be covered by this subscription.')
            setGuardrailButton('Continue')
            setGuardrailType('servicesSelection')
            setShowGuardrail(true)
            return
        }
        else if (selected === 'some') {
            setShowSelectServicesModal(true)
        }
        setServicesSelection(selected)
    }

    const servicesSelectionDone = (enabledServices: EnabledServiceSelection[]) => {
        if (enabledServices.length === 0) {
            setServicesSelection('')
        }
        setLimitedServices(enabledServices)
        setShowSelectServicesModal(false)
    }

    // Update limited service description when it changes
    const [limitedServicesDescription] = usePackageComponent(limitBy, limitedServices)

    const onSave = () => {
        if (formSending) return

        if (!name || !price || !frequency) return

        const item = new PSItem('subscription')
        if (forOnboarding)
            item.setData(state.item)
        item.name = name
        item.image = image
        item.rate = price
        item.deposit = setupFee
        item.memo = description
        item.allow_salestax = salesTax
        item.is_public = online
        if (!forOnboarding) {
            if (limitBy === 'session' && sessionCount) {
                item.capacity_type = 'session'
                item.capacity = sessionCount.value
            }
            else if (limitBy === 'duration' && sessionCount) {
                item.capacity_type = 'duration'
                item.capacity = sessionCount.value
            }
            if (servicesSelection)
                item.package_all = (servicesSelection === 'all')
            if (servicesSelection === 'some') {
                item.components = limitedServices.map((serviceSelection) => (
                    {
                        component: serviceSelection.id,
                        capacity: Number(serviceSelection.count) > 0 ? serviceSelection.count.toString() : '',
                    }
                ))
            }
        }
        if (frequency) {
            const frequencyCombo = (frequency as SelectOption).value
            switch (frequencyCombo.slice(-1)) {
                case 'd':
                    item.frequency = 'daily'
                    break
                case 'w':
                    item.frequency = 'weekly'
                    break
                case 'm':
                default:
                    item.frequency = 'monthly'
                    break
            }
            item.interval = Number(frequencyCombo.slice(0, -1))
        }
        if (instances)
            item.instances = Number((instances as SelectOption).value)
        if (discount)
            item.discount = (discount as SelectOption).value
        item.discount_instances = Number(discountInstance.value)
        if (form)
            item.form = form.id
        if (contract)
            item.contract = contract.id
        if (params.subscriptionID && params.action !== 'copy')
            item.id = params.subscriptionID

        if (forOnboarding) {
            item.id = state.item.id
            dispatch(updateItem(item.toObject(), !!refreshData))
            history.go(-2)
            return
        }

        setFormSending(true)

        if (typeof photo === 'string') {
            dispatch(saveItem(item))
        }
        else {
            dispatch(saveItem(item, photo as File))
        }
    }

    const showAttachments = (contractsEnabled || formsEnabled) &&
        (state.loadingAttachments || state.contracts.length > 0 || state.forms.length > 0)

    const showDeleteButton = params.subscriptionID && params.action !== 'copy'

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Subscription</h1>
                {showDeleteButton && (
                    <PSButtonDanger style={{height: '40px'}}
                                    onClick={() => setShowDeleteConfirmation(true)}>
                        Delete subscription
                    </PSButtonDanger>
                )}
                <PSButtonPrimary style={{height: '40px', marginLeft: showDeleteButton ? '16px' : 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!saveEnabled || formSending}>
                    {formSending && <Dots />}
                    {!formSending && "Save"}
                </PSButtonPrimary>
            </div>

            <div className="form">
                {!forOnboarding && (
                    <SettingsPhotoField photo={photo}
                                        selectPhoto={selectPhoto}
                                        selectedPhoto={selectedPhoto}
                                        addPhotoRef={addPhotoRef}
                                        title="Subscription" />
                )}

                <Field className="field">
                    <Label className="label">Name (required)</Label>
                    <Input placeholder="Subscription name"
                           value={name}
                           disabled={state.isSending}
                           onChange={e => setName(e.target.value)} />
                </Field>

                <Field className="field">
                    <Label className="label">Payment amount (required)</Label>
                    <Hint className="hint">Charged every payment period</Hint>
                    <Input placeholder="Subscription payment amount"
                           value={price}
                           disabled={state.isSending}
                           onChange={e => filterMonetaryInput(e.target.value, setPrice)} />
                </Field>

                <Field className="field">
                    <Label className="label">Setup fee</Label>
                    <Hint className="hint">A one-time fee added to the first subscription payment</Hint>
                    <Input placeholder="Subscription setup fee"
                           value={setupFee}
                           disabled={state.isSending}
                           onChange={e => filterMonetaryInput(e.target.value, setSetupFee)} />
                </Field>

                {discountEnabled && (
                    <>
                        <PSDropdown selected={discount}
                                    nameProperty="label"
                                    disabled={state.isSending}
                                    onSelect={(selection) => setDiscount(selection)}
                                    label="Discount rate"
                                    placeholder={<div className="grey">Select discount rate</div>}>
                            <>
                                {discountOptions.map(option => (
                                    <Item key={`discount-${option.value}`} value={option}>
                                        {option.label}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>

                        <PSDropdown selected={discountInstance}
                                    nameProperty="label"
                                    disabled={state.isSending}
                                    onSelect={(selection) => setDiscountInstance(selection)}
                                    label="Number of discounts">
                            <>
                                {instanceOptions.map(option => (
                                    <Item key={`discount-instance-${option.value}`} value={option}>
                                        {option.label}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>
                    </>
                )}

                {salesTaxEnabled && (
                    <Field className="field">
                        <Checkbox checked={salesTax}
                                  disabled={state.isSending}
                                  onChange={e => setSalesTax(e.target.checked)}>
                            <Label className="withHint">Allow sales tax</Label>
                            <Hint>Automatically calculate & apply sales tax</Hint>
                        </Checkbox>
                    </Field>
                )}

                <Field className="field">
                    <Label className="label">Description</Label>
                    <PSTextarea placeholder="Subscription description"
                                className="input"
                                minRows={8}
                                maxLength={1500}
                                disabled={state.isSending}
                                value={description}
                                onChange={value => setDescription(value)}/>
                </Field>

                {!forOnboarding&& (
                    <>
                        <h2>Limit usage (optional)</h2>

                        <Dropdown selectedItem={sessionCount}
                                  onSelect={(selection) => setSessionCount(selection)}
                                  downshiftProps={{ itemToString: (item: any) => item && item['label']}}>
                            <DropdownField className="field">
                                <Label className="label">Sessions</Label>
                                <Hint className="hint">Limit the number of sessions or hours covered by this subscription</Hint>
                                <div style={{display: 'flex'}}>
                                    <Select style={{width: 292}} className="input" disabled={state.isSending}>
                                        {sessionCount && sessionCount.label}
                                        {!sessionCount && (<div className="grey">Select number</div>)}
                                    </Select>
                                    <PSToggleButton isPressed={limitBy === 'session'}
                                                    onClick={() => setLimitBy('session')}
                                                    disabled={state.isSending}>
                                        Sessions
                                    </PSToggleButton>
                                    <PSToggleButton isPressed={limitBy === 'duration'}
                                                    onClick={() => setLimitBy('duration')}
                                                    disabled={state.isSending}>
                                        Hours
                                    </PSToggleButton>
                                </div>
                                <div className="buttonLink brightBlue"
                                     style={{display: 'inline-block', paddingTop: 8}}
                                     onClick={() => showLearnMore('subscriptions', true)}>
                                    Learn more &gt;
                                </div>
                            </DropdownField>
                            <Menu>
                                <Item value="">Select number</Item>
                                {limitBy !== 'duration' && sessionCountOptions.map((option) => (
                                    <Item key={`sessioncount-${option.value}`} value={option}>
                                        {option.label}
                                    </Item>
                                ))}
                                {limitBy === 'duration' && sessionHourOptions.map((option) => (
                                    <Item key={`sessionduration-${option.value}`} value={option}>
                                        {option.label}
                                    </Item>
                                ))}
                            </Menu>
                        </Dropdown>

                        <Field className="field">
                            <Label className="label">Services</Label>
                            <Hint className="hint">Select the items this subscription will cover</Hint>
                        </Field>
                        <Field className="field" style={{marginTop: 12}}>
                            <Radio name="services-selection"
                                   value="all"
                                   checked={servicesSelection === 'all'}
                                   onChange={event => {
                                       changeServicesSelection(event.target.value)
                                   }}>
                                <Label style={{color: '#000000', fontWeight: 400}}>All services</Label>
                            </Radio>
                        </Field>
                        <Field className="field" style={{marginTop: 8}}>
                            <Radio name="services-selection"
                                   value="some"
                                   checked={servicesSelection === 'some'}
                                   onChange={event => changeServicesSelection(event.target.value)}>
                                <Label style={{color: '#000000', fontWeight: 400}}>Some services</Label>
                            </Radio>
                        </Field>
                        {servicesSelection === 'some' && limitedServices.length > 0 && (
                            <div style={{paddingLeft: 20, paddingTop: 4}}>
                                <div style={{color: '#c4c4c4', fontSize: '13px', lineHeight: '16px', paddingTop: 4}}>
                                    {limitedServicesDescription}
                                </div>
                                <div className="buttonLink"
                                     style={{display: 'inline-block', paddingTop: 8, color: '#314A68'}}
                                     onClick={() => setShowSelectServicesModal(true)}>
                                    Edit services &gt;
                                </div>
                            </div>
                        )}

                    </>
                )}
                <h2>Subscription details</h2>

                <PSDropdown selected={frequency}
                            nameProperty="label"
                            disabled={state.isSending}
                            onSelect={(selection) => setFrequency(selection)}
                            label="Auto-charge frequency (required)"
                            hint="Select when a client should be automatically charged"
                            placeholder={<div className="grey">Select auto-charge frequency</div>}>
                    <>
                        {subscriptionFrequencyOptions.map(option => (
                            <Item key={`frequency-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>

                <PSDropdown selected={instances}
                            nameProperty="label"
                            disabled={state.isSending}
                            onSelect={(selection) => setInstances(selection)}
                            label="Number of payments"
                            hint="Number of times the client will be automatically charged"
                            placeholder={<div className="grey">Select number of payments</div>}>
                    <>
                        {instanceOptions.map(option => (
                            <Item key={`instances-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>

                {onlineBookingEnabled && (
                    <Field className="field">
                        <Checkbox checked={online}
                                  disabled={state.isSending}
                                  onChange={(e) => { setOnline(e.target.checked)}}>
                            <Label className="withHint">Show online</Label>
                            <Hint>Allow clients to purchase this service online</Hint>
                        </Checkbox>
                    </Field>
                )}

                {showAttachments && <h2>Attachments</h2>}

                {state.loadingAttachments && (
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                )}
                {contractsEnabled && !state.loadingAttachments && state.contracts.length > 0 && (
                    <PSDropdown selected={contract}
                                disabled={state.isSending}
                                nameProperty="name"
                                onSelect={(selection) => setContract(selection)}
                                label="Attach a contract"
                                hint="This contract will be signed when a client books this service"
                                onLearnMore={() => showLearnMore('contracts', true)}
                                placeholder={<div className="grey">Select contract</div>}>
                        <>
                            {state.contracts.map(option => (
                                <Item key={`contract-${option.id}`} value={option}>
                                    {option.name}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                )}
                {formsEnabled && !state.loadingAttachments && state.forms.length > 0 && (
                    <PSDropdown selected={form}
                                disabled={state.isSending}
                                nameProperty="name"
                                onSelect={(selection) => setForm(selection)}
                                label="Attach a form"
                                hint="This form will be completed when a client books this service"
                                onLearnMore={() => showLearnMore('forms', true)}
                                placeholder={<div className="grey">Select form</div>}>
                        <>
                            {state.forms.map(option => (
                                <Item key={`form-${option.id}`} value={option}>
                                    {option.name}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                )}
                {showGuardrail && (
                    <GuardrailModal title={guardrailTitle}
                                    body={guardrailBody}
                                    buttonText={guardrailButton}
                                    onClose={() => setShowGuardrail(false)}
                                    onAction={guardrailAction} />
                )}
                {learnMoreVisible && (
                    <Modal onClose={() => showLearnMore('', false)}>
                        <Header>{learnMoreTitle}</Header>
                        <Body>{learnMoreBody}</Body>
                        <Close aria-label="Close modal" />
                    </Modal>
                )}
                {showSelectServicesModal && (
                    <SelectServicesModal onClose={() => servicesSelectionDone(limitedServices)}
                                         onDone={servicesSelectionDone}
                                         currentServices={limitedServices} />
                )}
                {showDeleteConfirmation && (
                    <GuardrailModal title="Are you sure?"
                                    body="Are you sure you'd like to delete this item?"
                                    buttonText="Delete"
                                    onClose={() => setShowDeleteConfirmation(false)}
                                    onAction={() => dispatch(deleteItem(params.subscriptionID, 'subscriptions'))}
                    />
                )}
                <Prompt
                    when={hasEdits && !formSending}
                    message="Are you sure you'd like to leave this page without saving your changes?" />
            </div>
        </div>
    )
}