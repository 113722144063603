import moment from "moment";

export type ScheduleInstance = {
    id?: string;
    interval: number;
    date: string;
    end_date: string;
    item: string;
    dow: number;
    wom: string;
    instances: string;
    frequency: "daily" | "weekly" | "monthly";
    duration: number;
    description: string;
}

export class Schedule {
    id?: string;
    item: string;
    capacity: number;
    date: string;
    end_date: string;
    completed_date?: string;
    duration: number;
    employee: string;
    schedule: string;
    location: string;
    schedule_instance?: ScheduleInstance;
    temporary_id?: string;

    constructor() {
        this.item = ''
        this.capacity = 0
        this.date = ''
        this.end_date = ''
        this.duration = 0
        this.employee = ''
        this.schedule = ''
        this.location = ''
    }

    setData(data: any) {
        this.id = data.id
        this.item = data.item
        this.capacity = data.capacity
        this.date = data.date
        this.end_date = data.end_date
        this.completed_date = data.completed_date
        this.duration = data.duration
        this.employee = data.employee
        this.schedule = data.schedule
        this.location = data.location
        this.schedule_instance = data.schedule_instance
    }

    prettyDescription(format?: string, forceSingle?: boolean): string {
        if (this.temporary_id && this.schedule_instance) {
            // For temporary objects
            const description = scheduleDescription(this.schedule_instance)
            return `${moment(this.date).format('h:mm A')} ${description}`
        }
        if (!this.schedule_instance || forceSingle)
            return moment(this.date).format(format || 'MMM D, h:mm A')
        return `${moment(this.date).format('h:mm A')} ${this.schedule_instance.description}`
    }
}

export const scheduleToStringArray = (dow: number): string[] => {
    const result = []

    if (dow & 1) result.push('mon')
    if (dow & 2) result.push('tue')
    if (dow & 4) result.push('wed')
    if (dow & 8) result.push('thu')
    if (dow & 16) result.push('fri')
    if (dow & 32) result.push('sat')
    if (dow & 64) result.push('sun')

    return result
}

export const scheduleDescription = (schedule: ScheduleInstance): string => {
    const days = scheduleToStringArray(schedule.dow).join(', ')
    const intervalString = schedule.interval > 1 ? schedule.interval.toString()+' ' : ''
    const frequencyString = schedule.frequency ? schedule.frequency.substring(0, schedule.frequency.length - 2) : ''
    const recurringString = frequencyString ? `every ${intervalString}${schedule.interval > 1 ? frequencyString + 's' : frequencyString}` : ''
    return recurringString ? `${days} ${recurringString}` : `every ${days}`
}
