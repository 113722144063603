import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userColors } from '../../utils/colors'

import { DAY_FORMAT } from '../../modules/calendar'
import moment from 'moment'
import { push } from 'connected-react-router'

export const CalendarMonth = (props) => {
    const dispatch = useDispatch()
    const state = useSelector(state => (
        {
            calendar: state.calendar,
            entities: state.entities
        }
    ))

    const viewEvent = (type, eventData) => {
        dispatch(push(`/record/${type}/${eventData.id}`))
    }

    const thisMonthNum = state.calendar.currentDate.month()
    const today = moment()
    const weeksToRender = []
    const firstDayToRender = state.calendar.currentDate.clone().startOf('month').startOf('week')
    const lastDayToRender = state.calendar.currentDate.clone().endOf('month').endOf('week')
    for (let i = 0; ; i++) { // No break condition, happens at outer for loop end
        weeksToRender.push([])
        for (let j = 0; j < 7; j++) {
            weeksToRender[i].push(firstDayToRender.clone())
            firstDayToRender.add(1, 'day')
        }

        if (firstDayToRender.isAfter(lastDayToRender)) break
    }

    const dailyEventDivHeight = Math.floor((props.height-33)/weeksToRender.length-24)
    let maxEventsPerDay = Math.floor(dailyEventDivHeight / 17.15)

    const renderEventsForDay = (day) => {
        let events = state.calendar.eventsByDate[day.format(DAY_FORMAT)]
        if (!events) return

        // sort by start time
        events = events.slice().sort((a, b) => {
            if (a.start.isSame(b.start)) return a.data.id - b.data.id
            return a.start.isBefore(b.start) ? -1 : 1
        })

        let skipEvents = false
        return events.map((item , index) => {
            if (index+1 === maxEventsPerDay && events.length > maxEventsPerDay) {
                const extraEvents = events.length - maxEventsPerDay + 1
                skipEvents = true
                return (
                    <div className="event" key="even-more" style={{cursor: 'default'}}>
                        {extraEvents} More
                    </div>
                )
            }

            if (skipEvents) return null

            const { type, data, isMultiDay } = item
            const event = data
            const eventDate = moment(event.date)
            const isAllDay = (event.end_date && eventDate.clone().add(1, 'day').subtract(1, 'seconds').isSame(event.end_date))

            const contact = state.entities.contacts[event.client] || {}
            const color = userColors(event.client || event.title || type)

            let eventTime
            if (eventDate.minutes() === 0) {
                eventTime = eventDate.format('ha')
            }
            else {
                eventTime = eventDate.format('h:mma')
            }

            let eventTitle = contact.name || event.title
            if (!eventTitle) {
                if (event.item) {
                    eventTitle = state.entities.items[event.item] && state.entities.items[event.item].name
                    if (!eventTitle)
                        eventTitle = 'Class'
                }
                if (!eventTitle)
                    eventTitle = 'Booking'
            }

            if (isAllDay || isMultiDay) {
                return (
                    <div className="event allDayEvent"
                         key={`month-event-${index}`}
                         title={eventTitle}
                         onClick={() => viewEvent(type, event)}
                         style={{ backgroundColor: color.bg }}
                    >
                        <span>{eventTitle}</span>
                    </div>
                )
            }

            return (
                <div className="event highlight"
                     key={`month-event-${index}`}
                     title={`${eventTime} ${eventTitle}`}
                     onClick={() => viewEvent(type, event)}
                >
                    <div className="colorIcon" style={{borderColor: color.fg}} />
                    <span className="time">{eventTime}</span> {eventTitle}
                </div>
            )
        })
    }

    return (
        <div className="calendarMonth">
            <div className="headerRow">
                <div className="dow">Sun</div>
                <div className="dow">Mon</div>
                <div className="dow">Tue</div>
                <div className="dow">Wed</div>
                <div className="dow">Thu</div>
                <div className="dow">Fri</div>
                <div className="dow">Sat</div>
            </div>
            <div className="calendarBody" style={{height: props.height-12}}>
                {weeksToRender.map((week, weekIndex) => (
                    <div key={`week-${weekIndex}`}
                         className="week"
                         style={{height: `calc(${(100/weeksToRender.length)-1}% + 2px`}}
                    >
                        {week.map((day, dayIndex) => {
                            const isToday = day.startOf('day').isSame(today.startOf('day'))
                            return (
                                <div key={`day-${weekIndex}-${dayIndex}`} className="day">
                                    <div className="dow"
                                         style={{opacity: thisMonthNum === day.month() ? null : '0.4'}}
                                    >
                                        <span className={`${isToday ? 'today' : ''}`}>
                                        {day.date()}
                                        </span>
                                    </div>
                                    <div className="events">
                                        {renderEventsForDay(day)}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                ))}
            </div>
        </div>
    )
}