import React, {useCallback, useEffect, useState} from 'react'
import {apiClient} from "../../modules/apiclient";
import {NEW_PLAN_CONTEXT, SelectPlan} from "../settings/SelectPlan";
import localforage from "localforage";
import {UserInstance} from "../../models/User";
import {Spinner} from "@zendeskgarden/react-loaders";
import {ReactComponent as BlueDesktopLogo} from "../../icons/blue-logo.svg";
import {useAppDispatch} from "../../hooks";
import {push, replace} from "connected-react-router";
import "./newuserselectplan.css";
import {useLocation} from "react-router";
import {SHOW_TEXT_ME_DASHBOARD} from "../../utils/constants";

export const NewUserSelectPlan = () => {
    const dispatch = useAppDispatch()

    const location = useLocation()

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getUser = (device: any) => {
            apiClient.get('/user').then(resp => resp.data).then(user => {
                if (!user) {
                    dispatch(replace('/login'))
                    return
                }

                localStorage.setItem('loggedIn', '1')
                localStorage.setItem('userId', user.id)
                localStorage.setItem('income', '1')

                // @ts-ignore
                if (window.Rollbar && window.Rollbar.configure) {
                    // @ts-ignore
                    window.Rollbar.configure({
                        payload: {
                            environment: process.env.NODE_ENV,
                            person: {
                                id: user.id,
                            }
                        }
                    })
                }

                Promise.all([
                    localforage.setItem('loggedIn', true),
                    localforage.setItem(SHOW_TEXT_ME_DASHBOARD, true),
                    localforage.setItem('device', device),
                    localforage.setItem('user', user),
                    localforage.setItem('income', '1'),
                    //localforage.setItem('industries', json.industries),
                ]).then(_ => {
                    if (user.plan)
                        dispatch(push("/"));
                    else
                        setLoading(false);
                })
            })
        }

        // Get device ID from location and set it
        const deviceID = new URLSearchParams(location.search).get('d') ?? ''
        if (deviceID) {
            localforage.setItem('device', {id: deviceID})
            // Get the device.device from the passed in device.id
            apiClient.get(`/device/${deviceID}`).then(resp => resp.data).then(json => {
                if (json.device) {
                    localStorage.setItem('id', json.device.device)
                    apiClient.defaults.headers['X-Requested-Device'] = json.device.device

                    getUser(json.device)
                }
                else {
                    alert('An error has occurred and we are unable to continue.')
                }
            })
        }
        else {
            // No device ID sent, just show the index page
            dispatch(replace('/'))
        }
    }, [dispatch, location.search])

    const [user, setUser] = useState<UserInstance>()
    useEffect(() => {
        if (!loading) {
            localforage.getItem('user').then((u: any) => {
                const user = u as UserInstance
                setUser(user)
            })
        }
    }, [loading])

    const onNext = useCallback(() => {
        dispatch(replace('/'))
    }, [dispatch])

    if (loading || !user) {
        return (
            <div style={{background: 'white', height: 'calc(100%)', width: '100vw'}}>
                <div className="header">
                    <BlueDesktopLogo width="116" height="40"
                                 style={{margin: '16px 32px'}}/>
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div style={{background: 'white', height: '100vh', width: '100vw', overflow: 'auto'}}>
            <BlueDesktopLogo width="116" height="40"
                             style={{margin: '32px 32px 0'}}/>
            <div style={{width: '600px', padding: '32px'}}>
                <SelectPlan context={NEW_PLAN_CONTEXT}
                            onNext={onNext}
                />
            </div>
        </div>
    )
}