import React, {useCallback, useEffect, useState} from "react"
import {AppHeader} from "../app/AppHeader";
import {PSButton, PSButtonPrimary} from "../app/PSButton";
import {useAppDispatch, usePSUser} from "../../hooks";
import { push } from "connected-react-router";
import { SelectOption, yearsInBusinessOptions } from "../settings/Options";
import { PSDropdown } from "../app/PSDropdown";
import { Item } from "@zendeskgarden/react-dropdowns";
import { PSTextarea } from "../app/PSTextarea";
import { Field, Hint, Input, Label } from "@zendeskgarden/react-forms";
import { OnboardingState, setGoLiveState, setGrowYourBusiness, submitBusinessForm } from "../../modules/onboarding";
import { Result } from "../../utils";
import { Spinner } from "@zendeskgarden/react-loaders";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { nextGoLiveStep } from "../../utils/onboarding";

export const GoLiveBusinessInfo = () => {
    const dispatch = useAppDispatch()
    const state: OnboardingState = useSelector((state: RootState) => state.onboarding)

    const findSelection = (option?: string) => option ? yearsInBusinessOptions.find((yearInBusiness) => yearInBusiness.value === option!) : undefined

    const [saveEnabled, setSaveEnabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [yearsInBusiness, setYearsInBusiness] = useState<SelectOption | undefined>(findSelection(state.growYourBusiness.yearsInBusiness))
    const [servicesOffered, setServicesOffered] = useState<string | undefined>(state.growYourBusiness.servicesOffered)
    const [marketingInfo, setMarketingInfo] = useState<string | undefined>(state.growYourBusiness.marketingInfo)
    const [referralName, setReferralName] = useState<string | undefined>(state.growYourBusiness.referralName)

    const user = usePSUser()
    const onNextHandler = useCallback(() => {
        if (!user) return

        setLoading(true)
        Result(dispatch(setGrowYourBusiness({
            ...state.growYourBusiness,
            yearsInBusiness: yearsInBusiness?.value,
            marketingInfo,
            servicesOffered,
            referralName
        })))
        .then(() => dispatch(submitBusinessForm(user, {
            custom_how_old_is_your_business: yearsInBusiness?.value,
            custom_urls_for_websitefbigetc: marketingInfo,
            custom_what_services_do_you_offer: servicesOffered,
            custom_if_referred_let_us_know_who: referralName
        })))
        .then(() => setGoLiveState('gl_personal'))
        .then((me) => dispatch(nextGoLiveStep(me)))
        .finally(() => setLoading(false))


    }, [dispatch, user, yearsInBusiness?.value, state.growYourBusiness, marketingInfo, servicesOffered, referralName])

    const onSkipHandler = useCallback(() =>
        dispatch(push('/golive/personal-info'))
    , [dispatch])

    useEffect(() => {
        setSaveEnabled(!!yearsInBusiness && !!servicesOffered && !!marketingInfo && !loading)
    }, [yearsInBusiness, servicesOffered, marketingInfo, loading])

    if (loading) {
        return (
            <div className="servicesSettings">
                <div className="header">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <AppHeader title="Grow your business" showBackButton middleWidget={null}>
            <div className="servicesSettings onboarding launchBusiness">
                <div className="header">
                    <h1>Tell us about your business</h1>
                    <div style={{marginLeft: "auto"}}>
                        <PSButton style={{height: '40px'}} onClick={onSkipHandler}>
                            Skip
                        </PSButton>
                        <PSButtonPrimary style={{height: '40px', marginLeft: '10px'}} onClick={onNextHandler} disabled={!saveEnabled}>
                            Submit
                        </PSButtonPrimary>
                    </div>
                </div>

                <div className="separator" />

                <div className="form">
                    <div className="text" style={{paddingBottom: 0}}>Let us know a little bit more about your business and the services you provide</div>

                    <PSDropdown selected={yearsInBusiness}
                        label="How long have you been in business? (required)"
                        nameProperty={"label"}
                        placeholder={<div className="grey">Please select</div>}
                        onSelect={(yearsInBusiness: SelectOption) => setYearsInBusiness(yearsInBusiness)}>
                    <>
                        {yearsInBusinessOptions.map(option => (
                            <Item key={`years-in-business-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                    </PSDropdown>

                    <Field className='field'>
                        <Label>What services do you offer? (required)</Label>
                        <PSTextarea
                            placeholder="How do you serve clients?"
                            className="input"
                            minRows={4}
                            maxLength={999}
                            value={servicesOffered ?? ''}
                            onChange={value => setServicesOffered(value)}
                        />
                    </Field>

                    <Field className='field'>
                        <Label>How do you market your services? (required)</Label>
                        <PSTextarea
                            placeholder="Add websites or social media profiles"
                            className="input"
                            minRows={4}
                            maxLength={999}
                            value={marketingInfo ?? ''}
                            onChange={value => setMarketingInfo(value)}
                        />
                    </Field>

                    <Field className='field'>
                        <Label className='label'>Were you referred?</Label>
                        <Hint className='hint'>Were you referred to PocketSuite? If so, let us know who referred you and we’ll be sure to thank them.</Hint>
                        <Input placeholder='Add name (optional)'
                            value={referralName}
                            onChange={e => setReferralName(e.target.value)} />
                    </Field>
                </div>
            </div>
        </AppHeader>
    )
}