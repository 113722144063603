import React from 'react'
import {useLocation} from "react-router";
import {Link} from "react-router-dom";

import './settings.css'

type Props = {
    title: string,
    path: string,
}

export const SettingsMenuItem = ({title, path}: Props) => {
    let location = useLocation()
    return (
        <div className={`menuItem ${location.pathname.match(path) ? 'active' : ''}`}>
            <Link to={path}>
                {title}
            </Link>
        </div>
    )
}
