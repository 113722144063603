import React from "react"
import {AppHeader} from "../app/AppHeader";
import {Field} from "../../models/Field";
import {
    FormQuestionBuilder
} from "../settings/marketing/forms/FormQuestionBuilder";
import {useLocation} from "react-router";

export const OnboardingFieldEdit = () => {
    const routerLocation = useLocation()
    const locationState: any = routerLocation.state
    const field: Field = locationState?.field
    const forIntake: boolean = locationState?.forIntake ?? false

    return (
        <AppHeader title="Setup" showBackButton middleWidget={null}>
            <div className="pageWrapper">
                <FormQuestionBuilder forOnboarding
                                     forIntake={forIntake}
                                     editField={field}
                                     hideBackButton
                />
            </div>
        </AppHeader>
    )
}