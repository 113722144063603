import {Form} from "./Form";
import {Category} from "./Category";
import { Item } from "./Item";

export class Edition {
    id: string = '';
    name: string = '';
    video_uri: string = '';
    allow_basic_plan: boolean = false;
    color: string = '';
    square_image_uri: string = '';
    image_uri: string = '';
    pitch: string[] = [];
    categories: Category[] = [];
    services: Item[] = [];
    classes: Item[] = [];
    reservations: Item[] = [];
    packages: Item[] = [];
    subscriptions: Item[] = [];
    products: Item[] = [];
    gifts: Item[] = [];
    forms: Form[] = [];
    basic_features: string[] = [];
    features: string[] = [];
    premium_price?: number;
    team_price?: number;
    suite_price?: number;

    constructor(data: Partial<Edition>) {
        Object.assign(this, data)
    }
}