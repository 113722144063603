import { AxiosResponse } from "axios"

export type ErrorState = {
    error?: Error | AxiosResponse;
    fallbackMessage?: string;
}

const initialState: ErrorState = {
    error: undefined,
    fallbackMessage: undefined,
}

export default function errorReducer(state = initialState, action: any) {
    switch (action.type) {
        case 'error/PROCESS_ERROR':
            return {
                ...state,
                error: action.error,
                fallbackMessage: action.fallbackMessage,
            }
        case 'error/CLEAR_ERROR':
            return {
                ...state,
                error: undefined,
            }
        default:
            return state
    }
}

export const processError = (error: Error | AxiosResponse, fallbackMessage?: string) => {
    return { type: 'error/PROCESS_ERROR', error, fallbackMessage }
}

export const clearError = () => {
    return { type: 'error/CLEAR_ERROR' }
}