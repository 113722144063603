import React from "react";
import { Modal, Header, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { Button } from "@zendeskgarden/react-buttons";
import {ReactComponent as AlertIcon} from "../../icons/alert_triangle.svg";
import styled from "styled-components";

type Props = {
    title: string,
    body: React.ReactNode | string,
    buttonText: string,
    extraButtonText?: string,
    disableDismiss?: boolean,
    dismissButtonText?: string,
    onClose: () => void,
    onAction: (button?: number) => void,
}

const GuardrailButton = styled(Button)`
    background-color: #F2994A;

    &:hover,
    &:focus,
    &[data-garden-focus-visible] {
      background-color: #F08C32;
    }

    &:active,
    &[aria-pressed='true'],
    &[aria-pressed='mixed'] {
      background-color: #EF7E1A;
    }
`

export const GuardrailModal = ({title, body, buttonText, extraButtonText, disableDismiss, dismissButtonText, onClose, onAction}: Props) => {
    return (
        <Modal onClose={onClose}>
            <Header style={{display: 'flex', color: '#F2994A'}}>
                <AlertIcon />
                <span style={{fontWeight: 700, paddingLeft: 8}}>{title}</span>
            </Header>
            <Body>
                {body}
            </Body>
            <Footer>
                {disableDismiss !== true && (
                    <FooterItem>
                        <Button onClick={onClose} isBasic>
                            {dismissButtonText ?? 'Back'}
                        </Button>
                    </FooterItem>
                )}
                {extraButtonText && (
                    <FooterItem>
                        <GuardrailButton onClick={() => onAction(2)} isPrimary>
                            {extraButtonText}
                        </GuardrailButton>
                    </FooterItem>
                )}
                <FooterItem>
                    <GuardrailButton onClick={() => onAction(1)} isPrimary>
                        {buttonText}
                    </GuardrailButton>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )
}