import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { currencyFormat } from '../../../utils/numbers'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { RecordDetail } from '../RecordDetail'
import { RecordBody } from '../RecordBody'
import { ReactComponent as ChevronIcon } from '../../../icons/chevron.svg'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { Button } from '@zendeskgarden/react-buttons'
import { GoogleMapsAPIKey } from '../../../utils/constants'
import { classCapacityDisplay } from '../../../models/Class'
import { RootState } from '../../../store'
import { usePSUser } from '../../../hooks'
import { Result } from '../../../utils'
import { deleteLesson } from '../../../modules/settings'
import { distinctButtons } from '../../../utils/records'
import { PSButton, PSButtonPrimary } from '../../app/PSButton'
import { push } from 'connected-react-router'
import { ButtonProps, RecordButtons } from '../RecordButtons'
import { DangerModal } from '../../settings/DangerModal'
import { Team } from '../../../models/Team'
import { getTeamByPeople } from '../../../modules/conversation'
import { Body, Close, Footer, FooterItem, Header, Modal } from '@zendeskgarden/react-modals'

const displayClassRate = (record: any, item: any) => {
    const rate = parseFloat(item.rate || '0')
    if (item.is_dropin) {
        if (item.rate === '0')
            return 'Free drop-in'
        if (rate)
            return `${currencyFormat(rate)} drop-in`
        else
            return 'TBD drop-in'
    }
    else {
        if (item.rate === '0')
            return 'Free'
        if (rate)
            return `${currencyFormat(rate)} total`
        else
            return 'TBD'
    }
}

type Props = {
    record: any
}

export const RecordClass = ({record} : Props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const state = useSelector((state: RootState) => (
        {
            entities: state.entities,
        }
    ))

    const item = state.entities.items[record.item] || {}
    const employee = state.entities.contacts[record.employee] || {}
    const showRoster = useCallback(() => {
        dispatch(push(`/lesson/${record.id}/roster`)) },
    [dispatch, record.id])

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GoogleMapsAPIKey,
    })

    const [ locationLatLong/*, setLocationLatLong*/ ] = useState<any>()

    if (record.location) {
        /*
        Geocode.setApiKey(GoogleMapsAPIKey)
        Geocode.fromAddress(record.location).then((response) => {
            if (response && response.results) {
                setLocationLatLong(response.results[0].geometry.location)
            }
        })
         */
    }

    const recordDetails = []

    if (record.schedule_instance && record.schedule_instance.description) {
        recordDetails.push(
            <RecordDetail header="Schedule"
                          details={record.schedule_instance.description}
            />
        )
    }

    if (item) {
        recordDetails.push(
            <RecordDetail header="Class" details={item.name} />
        )

        // If there is at least 1 booking for this class, show a modal with the roster
        const numberOfBookings = Object.keys(state.entities.bookings)
            .filter(k => state.entities.bookings[k].lesson === record.id).length

        let capacity
        if (numberOfBookings > 0) {
            capacity = (
                <div className="buttonLink" onClick={showRoster}>
                    {classCapacityDisplay(record, item, state.entities.bookings)}
                </div>
            )
        }
        else {
            capacity = classCapacityDisplay(record, item, state.entities.bookings)
        }

        recordDetails.push(
            <RecordDetail header="Class size"
                          details={capacity}
            />
        )
    }

     if (employee.id) {
         const details = (
             <Link to={`/record/payee/${employee.id}`}>
                 {employee.name}
                 <ChevronIcon className="chevron" />
             </Link>
         )
         recordDetails.push(
             <RecordDetail header="Instructor" details={details} />
         )
     }

    if (item.memo) {
        recordDetails.push(
            <RecordDetail header="Description" details={item.memo} isText />
        )
    }

    if (item.allow_conference) {
        const details = (
            <a href={item.conference_url_string} target="PSVideo">
                <Button isPrimary style={{height: '32px', width: '65px', backgroundColor: '#62CE9A'}}>Join</Button>
            </a>
        )
        recordDetails.push(
            <RecordDetail header="Video conferencing" details={details} />
        )
    }

    if (record.location) {
        recordDetails.push(
            <RecordDetail header="Location" subtitle={record.location}>
                {isLoaded && locationLatLong && locationLatLong.lat && (
                    <div style={{marginTop: '12px'}}>
                        <GoogleMap
                            center={locationLatLong}
                            zoom={15}
                            clickableIcons={false}
                            mapContainerStyle={{width: '100%', height: '320px'}}
                            options={{disableDefaultUI: true}}
                        >
                            <Marker position={locationLatLong} />
                        </GoogleMap>
                    </div>
                )}
            </RecordDetail>
        )
    }

    const bookings = Object.keys(state.entities.bookings)
        .filter(id => state.entities.bookings[id].lesson === record.id)
        .map(id => state.entities.bookings[id])
    const confirmedBookings = bookings
        .filter(booking => booking.status !== 'waitListed')

    const user = usePSUser()

    const [showDropinModal, setShowDropinModal] = useState(false)
    const [showWaitlistModal, setShowWaitlistModal] = useState(false)
    const [showCancelClass, setShowCancelClass] = useState(false)
    const [teamId, setTeamId] = useState<string>()
    const [, setMemeberIds] = useState<string[]>()
    useEffect(() => {
        if (!user) return

        let userIds: string[] = []
        confirmedBookings?.forEach((booking: any) => {
            if (!userIds.includes(booking.client)) {
                userIds.push(booking.client)
            }
        })

        if (item.employee && !userIds.includes(item.employee)) {
            userIds.unshift(item.employee)
        }
        if (userIds.length && !userIds.includes(user!.id)) {
            userIds.unshift(user!.id)
        }

        if (userIds.length) {
            // used to debounce the server call
            setMemeberIds(currentVal => {
                // current val includes all user ids, we either already have the team id or are awaiting a server response
                if (currentVal?.every(userId => userIds.includes(userId))) {
                    return currentVal;
                }

                Result(dispatch(getTeamByPeople(userIds)))
                    .then((team: Team) => {
                        setTeamId(team.id)
                    })

                // return value we are querying the server for
                return userIds;
            })
        }
        else {
            setTeamId(undefined)
        }

    }, [confirmedBookings, dispatch, item.employee, user])

    const cancelClass = (buttonIx?: number) => {
        const notify = (!record.schedule || moment(record.date) < moment()) && buttonIx === 1
        const cancelAll = record.schedule && moment(record.date) >= moment() && buttonIx === 2

        Result(dispatch(deleteLesson(record.id, notify, cancelAll)))
            .then(() => history.goBack())
    }

    const scheduleClass = useCallback(() => {
        if (item.is_dropin) {
            if (item.allow_waitlist && bookings.length >= record.capacity) {
                setShowWaitlistModal(true)
            }
            else {
                dispatch(push('/schedule/new', {lesson: {...record, dropin: true}, item}))
            }
        }
        else {
            setShowDropinModal(true)
        }

    }, [bookings.length, dispatch, item, record])

    const scheduleClassWaitlist = useCallback(() => {
        dispatch(push('/schedule/new', {lesson: {...record, waitlisted: true, dropin: true}, item}))
    }, [dispatch, item, record])

    const scheduleClassDropin = useCallback(() => {
        dispatch(push('/schedule/new', {lesson: {...record, dropin: true}, item}))
    }, [dispatch, item, record])

    const scheduleClasses = useCallback(() => {
        dispatch(push('/schedule/new', {lesson: record, item}))
    }, [dispatch, item, record])

    const sendMessage = useCallback(() =>
        dispatch(push(`/messages/${teamId}`, { bcc: true })), [dispatch, teamId])

    const getButtons = useCallback(() => {
        if (!user) return undefined

        const buttons: ButtonProps[] = []
        if (!record.cancelled_date && bookings?.length > 0) {
            buttons.push({ text: "Roster", onClick: showRoster })
        }
        if (!record.cancelled_date) {
            buttons.push({ text: "Book", onClick: scheduleClass })
        }
        if (teamId) {
            buttons.push({ text: "Message", onClick: sendMessage })
        }
        if (!record.cancelled_date) {
            buttons.push({ text: "Cancel", onClick: () => setShowCancelClass(true) })
        }

        return distinctButtons(buttons)
    }, [user, record.cancelled_date, bookings?.length, teamId, showRoster, scheduleClass, sendMessage])

    const buttons = getButtons()
    return (
        <div className="recordView">
            {buttons &&
                <div className="recordHeaderButtons actions" style={{margin: '0', float: 'none'}}>
                    <div style={{marginLeft: 'auto'}}>
                        <RecordButtons buttons={buttons} />
                    </div>
                </div>
            }
            <div className="recordHeader">
                <h2>{moment(record.date).format('ddd, MMM Do YYYY')}</h2>
                <div className="title">{displayClassRate(record, item)}</div>
                <div className="time">
                    {moment(record.date).format('h:mm a')} - {moment(record.end_date).format('h:mm a')}
                </div>
            </div>
            <RecordBody recordDetails={recordDetails} />
            {showCancelClass && (
                <DangerModal
                    title="Cancel class"
                    body={!record.schedule || moment(record.date) < moment() ?
                        'Would you like to cancel this class? Select “Cancel & notify” if you’d like a cancellation notification to be sent to the client. If you don’t want the client to be notified of the cancellation select “Cancel class”.' :
                        'Would you like to cancel this class? Select “Cancel this class” to just cancel this class. If you’d like to cancel this class as well as all future classes select “Cancel this and future classes”.'
                    }
                    buttonText={!record.schedule || moment(record.date) < moment() ? 'Cancel & notify': 'Cancel this class'}
                    extraButtonText={!record.schedule || moment(record.date) < moment() ? 'Cancel class' : 'Cancel this and future classes'}
                    disableDismiss
                    onAction={cancelClass}
                    onClose={() => setShowCancelClass(false)}
                />
            )}
            {showWaitlistModal && (
                <Modal onClose={() => setShowWaitlistModal(false)}>
                    <Header>Add to wait list </Header>
                    <Body>This class is currently full. You can add this client to the Wait List for this class. If space becomes available up to 12 hours before the class, the client will be automatically booked into the class. </Body>
                    <Close aria-label="Close modal" />
                    <Footer>
                        <FooterItem>
                            <PSButtonPrimary onClick={scheduleClassWaitlist}>Continue</PSButtonPrimary>
                        </FooterItem>
                    </Footer>
                </Modal>
            )}
            {showDropinModal && (
                <Modal onClose={() => setShowDropinModal(false)}>
                    <Header>Schedule Class</Header>
                    <Body>To schedule the class as a one time dropin choose 'Schedule dropin' to schedule all future classes in series choose 'Schedule classes'</Body>
                    <Close aria-label="Close modal" />
                    <Footer>
                        <FooterItem>
                            <PSButton style={{marginRight: '16px'}} onClick={scheduleClassDropin}>Schedule Dropin</PSButton>
                            <PSButtonPrimary onClick={scheduleClasses}>Schedule classes</PSButtonPrimary>
                        </FooterItem>
                    </Footer>
                </Modal>
            )}
        </div>
    )
}
