import React from 'react'
import './dashboard.css'
import {Dashboard} from "../../models/Dashboard";
import { PSButtonPrimary } from '../app/PSButton';

type Props = {
    dashboard: Dashboard,
    onClick: () => void,
}

export const GetAffiliateAlertBanner = ({dashboard, onClick}: Props) => {
    return (
        <div className="alertBannerWrapper">
            <div className="alertBanner">
                <div className="line">
                    <div className="icon">
                        <img style={{width: '36px'}} alt="get $50 off" src="/icons/ps_dashboard_alerts_affiliate.svg" />
                    </div>
                    <div className="content">
                        <div className="title">
                            {dashboard.title}
                        </div>
                        <div className="status" style={{color: '#555555'}}>{dashboard.status}</div>
                    </div>
                    <div className="view" onClick={onClick}>
                        <PSButtonPrimary>Get $50 off</PSButtonPrimary>
                    </div>
                </div>
            </div>
        </div>
    )
}