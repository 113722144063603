import React, { useCallback } from "react";
import {PSButtonPrimary} from "../app/PSButton";
import {ReactComponent as CheckmarkIcon} from "../../icons/task.svg";
import {AppDispatch} from "../../store";
import {useAppDispatch} from "../../hooks";
import {UserInstance} from "../../models/User";
import { nextGoLiveStep } from "../../utils/onboarding";

type Props = {
    me: UserInstance;
}

export const LaunchBusiness = ({me}: Props) => {
    const dispatch: AppDispatch = useAppDispatch()

    const onNextHandler = useCallback(() => {
        dispatch(nextGoLiveStep(me))
    }, [me, dispatch])

    return (
        <div className="launchBusiness">
            <img alt="edition logo" src="/icons/edition-blue-logo-no-hat.png" />
            <div className="details">
                <div className="benefits">
                    <div className="benefit">
                        <CheckmarkIcon />Need help? We'll setup your account.
                    </div>
                    <div className="benefit">
                        <CheckmarkIcon />Share your booking site
                    </div>
                    <div className="benefit">
                        <CheckmarkIcon />Get paid faster
                    </div>
                </div>
                <div className="buttons">
                    <PSButtonPrimary onClick={() => onNextHandler()}>
                        Start Now
                    </PSButtonPrimary>
                </div>
            </div>
        </div>
    )
}