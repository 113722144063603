import { Field, Label, Textarea } from "@zendeskgarden/react-forms";
import React from "react";

type FieldProps = {
    fieldValue?: string,
    label: string,
    onChange: (changed: string) => void
}

export const LongTextInput = ({fieldValue, label, onChange}: FieldProps) => {
    return (
        <Field className='field'>
            <Label className='label'>{label}</Label>
            <Textarea placeholder='Enter text'
                value={fieldValue}
                onChange={e => onChange(e.target.value)} rows={5}/>
        </Field>
    )
}