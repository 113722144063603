import React from "react";
import {useParams} from "react-router";
import {AppHeader} from "../app/AppHeader";
import {CardEdit} from "../settings/payment-methods/CardEdit";

export const ContactAddCard = () => {
    const params = useParams<{id: string}>()

    return (
        <AppHeader middleWidget={null}
                   showBackButton
                   title="Client">
            <CardEdit clientID={params.id} />
        </AppHeader>
    )
}