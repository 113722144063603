import React, {useEffect} from "react";
import {useAppDispatch} from "../../hooks";
import {Redirect, useLocation} from "react-router";
import {Contract} from "../../models/Contract";
import {setContract} from "../../modules/settings";
import {AppHeader} from "../app/AppHeader";
import {ContractEdit} from "../settings/marketing/ContractEdit";

export const OnboardingContractEdit = () => {
    const dispatch = useAppDispatch()

    const routerLocation = useLocation()
    const locationState: any = routerLocation.state
    const contract: Contract = locationState?.contract

    useEffect(() => {
        if (contract) dispatch(setContract(contract))
    }, [dispatch, contract])

    if (!contract) {
        return <Redirect to='/onboarding/contracts' />
    }

    return (
        <AppHeader title="Setup" showBackButton middleWidget={null}>
            <div className="pageWrapper">
                <ContractEdit forOnboarding />
            </div>
        </AppHeader>
    )
}
