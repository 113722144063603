import React, { useState, useCallback } from 'react'
import moment from 'moment'
import { RecordStatusBar } from '../RecordStatusBar'
import { currencyFormat } from '../../../utils/numbers'
import { useDispatch, useSelector } from 'react-redux'
import { e164ToPhone } from '../../../utils/phone'
import { RecordDetail } from '../RecordDetail'
import { Link } from 'react-router-dom'
import { RecordBody } from '../RecordBody'
import { ReactComponent as ChevronIcon } from '../../../icons/chevron.svg'
import { ReactComponent as ColoredChevronIcon } from '../../../icons/colored_chevron.svg'
import { PaymentDetailsModal } from '../PaymentDetailsModal'
import { displayExpense, displayLineItem, displayJob } from '../../../utils/lineitems'
import { invoiceStatus } from '../../../models/Invoice'
import { push } from 'connected-react-router'
import { RootState } from '../../../store'
import {distinctButtons, isForVendor} from '../../../utils/records'
import { ButtonProps, RecordButtons } from '../RecordButtons'
import { Payment } from '../../../models/Payment'
import { Result } from '../../../utils'
import { cancelInvoice, rollbackTransaction, sendClientReceipt, sendInvoice } from '../../../modules/transactions'
import { getRecord } from '../../../modules/records'
import {useConfirmModal, usePSOwner} from '../../../hooks'
import { API_BASE } from '../../../modules/apiclient'

type Props = {
    record: any
}

export const RecordInvoice = ({record}: Props) => {
    const dispatch = useDispatch()
    const state = useSelector((state: RootState) => (
        {
            entities: state.entities,
        }
    ))

    const owner = usePSOwner()
    const contact = state.entities.contacts[record.client] || {}
    const client = state.entities.clients[record.client] || {}
    const employee = state.entities.contacts[record.employee] || {}

    const clientInfo = contact.e164  ? e164ToPhone(contact.e164) : contact.email

    const statuses = [
        {label: 'Created', completed: !!record.created_date},
        {label: 'Sent', completed: !!record.sent_date},
        {label: 'Viewed', completed: !!record.viewed_date},
        {label: 'Paid', completed: !!record.paid_date},
    ]

    const total = record.total ? `${currencyFormat(record.asking_amount)} total` : 'No total'
    const status = invoiceStatus(record)

    const appointments = record.lines.filter((line: any) => line.type === 'booking')
    const items = record.lines.filter((line: any) => line.type === 'item')
    const expenses = record.lines.filter((line: any) => line.type === 'expense')

    const recordDetails = []

    const [setCtaModalInfo, setCtaModalLoading, showCtaModal, closeCtaModal, ctaModalComponent] = useConfirmModal()

    if (client.id) {
        const details = (
            <Link to={`/record/client/${contact.id}`}>
                {contact.name}
                <ChevronIcon className="chevron" />
            </Link>
        )
        recordDetails.push(
            <RecordDetail header="Client" details={details} subtitle={clientInfo} />
        )
    }

    if (record.id && record.date) {
        recordDetails.push(
            <RecordDetail header="Invoice date" details={moment(record.date).format('MMM Do, yyyy')} />
        )
    }

    if (appointments.length > 0) {
        const bookings = appointments.map((appointment: any) => state.entities.bookings[appointment.booking])
        const bookingsList = bookings
            .sort((a: any, b: any) => moment(a.date).isAfter(b.date))
            .map((booking: any) => displayJob(state.entities.items, booking))
        recordDetails.push(
            <RecordDetail header="Appointments"
                          details={bookingsList}
                          isText />
        )
    }

    if (items.length > 0) {
        const lineItemsList = items.map((i: any) => displayLineItem(i, true))
        recordDetails.push(
            <RecordDetail header="Items"
                          details={lineItemsList}
                          isText />
        )
    }

    if (expenses.length > 0) {
        const expensesList = expenses.map(displayExpense)
        recordDetails.push(
            <RecordDetail header="Expenses"
                          details={expensesList}
                          isText />
        )
    }

    if (record.memo) {
        recordDetails.push(
            <RecordDetail header="Memo" details={record.memo} isText />
        )
    }

    if (record.last_payment_date) {
        recordDetails.push(
            <RecordDetail header={record.paid_date ? "Payment date" : "Last payment date"}
                          details={moment(record.last_payment_date).format('MMM Do, yyyy')} />
        )
    }

    if (employee.id) {
        const details = (
            <Link to={`/record/payee/${employee.id}`}>
                {employee.name}
                <ChevronIcon className="chevron" />
            </Link>
        )
        recordDetails.push(
            <RecordDetail header="Team member" details={details} />
        )
    }

    const viewPayments = useCallback(() =>
        dispatch(push(`/payments/invoice/${record.id}`)), [dispatch, record.id])

    const sendMessage = useCallback(() =>
        dispatch(push(`/messages/${record.client}`)), [dispatch, record.client])

    const copyInvoice = useCallback(() =>
        dispatch(push(`/invoice/new`, { invoice: {...record, id: undefined, number: undefined} })), [dispatch, record])

    const viewForm = useCallback(() =>
        dispatch(push(`/record/record/${record.record}`)), [record.record, dispatch])
    const viewContract = useCallback(() =>
        dispatch(push(`/record/signature/${record.signature}`)), [record.signature, dispatch])

    const printInvoice = useCallback(() => window.open(`${API_BASE}/invoice/${record.id}/print`, "_blank"), [record.id])

    const sendReceipt = useCallback(() => {
        setCtaModalInfo({
            title: "Send receipt",
            body: "Would you like to send the client a receipt?",
            button: "Send receipt",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(sendClientReceipt(record.id, 'invoice')))
                    .then(closeCtaModal)
                    .then(sendMessage)
            }
        })
        showCtaModal()
    }, [closeCtaModal, dispatch, record.id, sendMessage, setCtaModalInfo, setCtaModalLoading, showCtaModal])

    const resendInvoice = useCallback(() => {
        setCtaModalInfo({
            title: "Send invoice",
            body: "Would you like to resend this invoice?",
            button: "Send invoice",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(sendInvoice(record)))
                    .then(closeCtaModal)
            }
        })
        showCtaModal()
    }, [closeCtaModal, dispatch, record, setCtaModalInfo, setCtaModalLoading, showCtaModal])

    const undoMarkPaid = useCallback(() => {
        setCtaModalInfo({
            title: "Undo mark paid",
            body: "Would you like to undo this payment?",
            button: "Undo mark paid",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(rollbackTransaction(record.id, 'invoice')))
                    .then(() => dispatch(getRecord('invoice', record.id)))
                    .then(closeCtaModal)
            },
            danger: true
        })
        showCtaModal()
    }, [closeCtaModal, dispatch, record.id, setCtaModalInfo, setCtaModalLoading, showCtaModal])

    const undoCancel = useCallback(() => {
        setCtaModalInfo({
            title: "Undo cancellation",
            body: "Would you like to undo the cancellation of this invoice?",
            button: "Undo cancellation",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(rollbackTransaction(record.id, 'invoice')))
                    .then(() => dispatch(getRecord('invoice', record.id)))
                    .then(closeCtaModal)
            },
            danger: true
        })
        showCtaModal()
    }, [closeCtaModal, dispatch, record.id, setCtaModalInfo, setCtaModalLoading, showCtaModal])

    const showCancelModal = useCallback(() => {
        setCtaModalInfo({
            title: "Cancel Invoice",
            body: 'Would you like to cancel this invoice?',
            button: 'Cancel invoice',
            optionalButton: record.schedule ? 'Cancel this and future dates' : undefined,
            onClick: (optionalButton: boolean) => {
                const cancelAll = optionalButton

                setCtaModalLoading(true)
                Result(dispatch(cancelInvoice(record.id, cancelAll)))
                    .then(() => dispatch(getRecord('invoice', record.id)))
                    .then(closeCtaModal)
            },
            danger: true
        })
        showCtaModal()
    }, [closeCtaModal, dispatch, record.id, record.schedule, setCtaModalInfo, setCtaModalLoading, showCtaModal])

    const chargeClient = useCallback(() =>
        dispatch(push(`/charge/invoice/${record.id}`, {invoice: record}))
    , [record, dispatch])

    const [showModal, setShowModal] = useState(false)
    const getButtons = useCallback((): [ButtonProps, ButtonProps[]] => {
        let primaryButton: ButtonProps = { text: "Edit", onClick: () => dispatch(push(`/invoice/edit/${record.id}`))}
        const buttons: ButtonProps[] = []

        if (!record) {
            return [primaryButton, buttons];
        }

        const payment = new Payment(record);
        if (!record.paid_date && !record.cancelled_date && payment.calculateChargeAmount() > 0) {
            buttons.push(primaryButton)
            primaryButton = { text: "Charge", onClick: chargeClient }
        }
        else if (!record.paid_date && !record.cancelled_date) {
            buttons.push({ text: "Mark paid", onClick: chargeClient })
        }
        else {
            buttons.push({ text: "Copy", tooltip: "Make copy", onClick: copyInvoice })
        }
        buttons.push({ text: "Resend", onClick: resendInvoice })
        buttons.push({ text: "Chat", tooltip: "Send message", onClick: sendMessage })
        buttons.push({ text: "View", onClick: printInvoice })
        if (record.mark_paid_date) {
            buttons.push({ text: "Receipt", onClick: sendReceipt })
        }
        if (record.record && record.captured_date && record.cancelled_date) {
            buttons.push({ text: "Form", tooltip: "View form", onClick: viewForm })
        }
        if (record.contract && !record.cancelled_date) {
            buttons.push({ text: "Contract", tooltip: "View contract", onClick: viewContract })
        }
        if (record.cancelled_date && isForVendor(record, owner)) {
            buttons.push({ text: "Undo", tooltip: "Undo cancelled", onClick: undoCancel })
        }
        else if (record.mark_paid_date && isForVendor(record, owner)) {
            buttons.push({ text: "Undo", tooltip: "Undo mark paid", onClick: undoMarkPaid })
        }
        if (!record.cancelled_date && !record.paid_date) {
            buttons.push({ text: "Cancel", onClick: showCancelModal })
        }
        if (record.paid_date || Number(record.paid_amount) > 0) {
            buttons.push({ text: "Payments", tooltip: "View payments", onClick: viewPayments })
        }
        buttons.push({ text: "Copy", tooltip: "Make copy", onClick: copyInvoice })

        return [primaryButton, distinctButtons(buttons)]
    }, [record, resendInvoice, sendMessage, printInvoice, owner, copyInvoice, dispatch, chargeClient, sendReceipt, viewForm, viewContract, undoCancel, undoMarkPaid, showCancelModal, viewPayments])

    const [primaryButton, buttons] = getButtons()
    return (
        <div className="recordView">
            <div className="topBar">
                <RecordStatusBar statuses={statuses} />
                <div className="actions">
                    <RecordButtons primaryButton={primaryButton} buttons={buttons} />
                </div>
            </div>
            <div className="recordHeader">
                <h2 className="payment" onClick={() => setShowModal(true)}>
                    {total}
                    <ColoredChevronIcon fill="#62CE9A" />
                </h2>
                <div className="title">Invoice #{record.number}</div>
                <div className="time">{status}</div>
            </div>
            <RecordBody recordDetails={recordDetails} />
            <PaymentDetailsModal visible={showModal}
                                 onClose={() => setShowModal(false)}
                                 type="invoice"
                                 record={record} />

            {ctaModalComponent}
        </div>
    )
}