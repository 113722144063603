import React, {useEffect, useState} from "react";
import { Field } from "../../models/Field";
import { CheckboxInput } from "./InputFields/CheckboxInput";
import { CurrencyInput } from "./InputFields/CurrencyInput";
import { DateInput } from "./InputFields/DateInput";
import { LongTextInput } from "./InputFields/LongTextInput";
import { MultiSelectInput } from "./InputFields/MultiSelectInput";
import { NumberInput } from "./InputFields/NumberInput";
import { PhoneInput } from "./InputFields/PhoneInput";
import { SelectInput } from "./InputFields/SelectInput";
import { TextInput } from "./InputFields/TextInput";

type FieldEditProps = {
    field: Field,
    value: any,
    onChange: (changed: any) => void
}

/**
 * Data input field on the Form page (TODO: generalize me and use for all pages, add support for image/document fields)
 * @param field
 * @param value
 * @param onChange
 * @constructor
 */
export const FieldInput = ({field, value, onChange}: FieldEditProps) => {
    const label = field.label + (field.is_required ? " (Required *)" : "")

    const [fieldValue, setFieldValue] = useState<any>()
    const handleChange = (newValue: any[] | boolean | string) => {
        setFieldValue(newValue)
        onChange(newValue)
    }

    useEffect(() => {
        setFieldValue(value)
    }, [value])

    return {
        'checkbox':
            <CheckboxInput onChange={handleChange}
                fieldValue={fieldValue}
                label={label} />,
        'currency':
            <CurrencyInput onChange={handleChange}
                fieldValue={fieldValue}
                label={label} />,
        'date':
            <DateInput onChange={handleChange}
                fieldValue={fieldValue}
                label={label} />,
        'email':
            <TextInput onChange={handleChange}
                fieldValue={fieldValue}
                label={label} placeholder={'Enter email address'} />,
        'float':
            <NumberInput onChange={handleChange} allowDecimal
                fieldValue={fieldValue}
                label={label} />,
        'integer':
            <NumberInput onChange={handleChange}
                fieldValue={fieldValue}
                label={label} />,
        'longtext':
            <LongTextInput onChange={handleChange}
                fieldValue={fieldValue}
                label={label} />,
        'multiselect':
            <MultiSelectInput onChange={handleChange}
                field={field}
                fieldValue={fieldValue}
                label={label} />,
        'phone':
            <PhoneInput onChange={handleChange}
                fieldValue={fieldValue}
                label={label} />,
        'select':
            <SelectInput onChange={handleChange}
                field={field}
                fieldValue={fieldValue}
                label={label} />,
        'text':
            <TextInput onChange={handleChange}
                fieldValue={fieldValue}
                label={label} placeholder={'Enter text'} />,
        'image': <></>,
        'document': <></>,
        '': <></>
    }[field.type]
}