import { Checkbox, Field, Input, Label } from "@zendeskgarden/react-forms";
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import React, { useCallback, useEffect, useState } from "react";
import { LineItem } from "../../../models/LineItem";
import { filterMonetaryInput, shiftThirdDecimal } from "../../../utils/numbers";
import { PSButtonPrimary } from "../../app/PSButton";

type Props = {
    item: LineItem,
    onCancel: () => void,
    onDone: (item: LineItem) => void
}

export const ChargeEditItemModal = ({item, onCancel, onDone}: Props) => {
    const [rate, setRate] = useState(item.rate ? item.rate : item.total / item.quantity)
    const [total, setTotal] = useState(item.total)
    const [quantity, setQuantity] = useState(item.quantity)
    const [taxable, setTaxable] = useState(item.taxable)
    const [discountable, setDiscountable] = useState(item.discountable)
    const [memo, setMemo] = useState(item.memo)

    useEffect(() => {
        if (item.total) {
            setRate(item.rate ? item.rate : item.total / item.quantity)
            setTotal(item.total)
        }
        if (item.quantity) setQuantity(item.quantity)
        if (item.memo) setMemo(item.memo)
    }, [item])

    useEffect(() => {
        setTotal(Number(rate) * quantity)
    }, [rate, quantity])

    const setTotalAndRate = (value: string) => {
        setTotal(Number(value))
        setRate(Number(value) / quantity)
    }

    const handleDone = useCallback(() => {
        onDone({
            ...item,
            total,
            rate,
            quantity,
            taxable,
            discountable,
            memo
        })
    }, [discountable, item, memo, onDone, quantity, rate, taxable, total])

    return <Modal onClose={onCancel}>
        <Header>Edit Item</Header>
        <Body>
            <div className="form">
                <Field className='field'>
                    <Label className='label'>Amount</Label>
                    <Input disabled={!!item.item}
                        value={total}
                        onChange={e => filterMonetaryInput(shiftThirdDecimal(e.target.value), setTotalAndRate)} />
                </Field>

                <Field className='field' style={{marginTop: '24px'}}>
                    <Label className='label'>Quantity</Label>
                    <Input
                        value={quantity}
                        onChange={e => setQuantity(Number(e.target.value))} />
                </Field>

                <Field className='field' style={{marginTop: '24px'}}>
                    <Label className='label'>Description</Label>
                    <Input placeholder='Enter item description'
                        value={memo}
                        onChange={e => setMemo(e.target.value)} />
                </Field>

                <Field className='field' style={{marginTop: '24px'}}>
                    <Checkbox checked={taxable}
                                onChange={(e) => setTaxable(e.target.checked)}>
                        <Label className="withHint">Allow Tax</Label>
                    </Checkbox>
                </Field>


                <Field className='field'>
                    <Checkbox checked={discountable}
                                onChange={(e) => setDiscountable(e.target.checked)}>
                        <Label className="withHint">Allow Discount</Label>
                    </Checkbox>
                </Field>
            </div>
        </Body>
        <Close aria-label="Close modal" />
        <Footer>
            <FooterItem>
                <PSButtonPrimary onClick={handleDone}>Done</PSButtonPrimary>
            </FooterItem>
        </Footer>
    </Modal>
}