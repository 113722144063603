import React, {useCallback, useEffect, useMemo, useState} from "react";
import {AppHeader} from "../../app/AppHeader";
import {Redirect, useHistory, useLocation, useParams} from "react-router";
import {TipType} from "../../../models/Transaction";
import "../transactions.css";
import {PSButtonPrimary, PSButtonSecondary} from "../../app/PSButton";
import {currencyFormat} from "../../../utils/numbers";
import {TransactionProgress} from "../TransactionProgress";
import {ReceiptMemoModal} from "../charge/ReceiptMemoModal";
import { useDispatch } from "react-redux";
import { push, replace } from "connected-react-router";
import { saveGift, sendGift } from "../../../modules/transactions";
import { Link } from "react-router-dom";
import { GiftCertificate } from "../../../models/GiftCertificate";
import { Result } from "../../../utils";

export const GiftSummary = () => {

    const location = useLocation()
    const locationState: {gift: Partial<GiftCertificate>, client: any} = location.state as {gift: Partial<GiftCertificate>, client: any}
    const params: { id?: string } = useParams()

    const gift = useMemo(() => new GiftCertificate(locationState?.gift || {} as Partial<GiftCertificate>), [locationState.gift])
    const client = locationState?.client

    const history = useHistory()
    const dispatch = useDispatch()

    const [discountType, setDiscountType] = useState<TipType>()
    const [discountAmount, setDiscountAmount] = useState<string>()
    const [surchargeEnabled, setSurchargeEnabled] = useState(false)
    const [memo, setMemo] = useState('')

    const [showMemoModal, setShowMemoModal] = useState(false)

    const [discountDescription, setDiscountDescription] = useState('')

    useEffect(() => {
        setDiscountType(gift.discount_type)
        setDiscountAmount(gift.discount)
        setSurchargeEnabled(gift.allow_surcharge || false)
    }, [gift])

    useEffect(() => {
        if (gift._gift?.item_name) {
            setDiscountDescription(gift._gift.item_name)
        }
        else if (discountType === 'rate') {
            setDiscountDescription(`Discount (${currencyFormat(discountAmount)})`)
        }
        else if (discountType === 'percent' && Number(discountAmount) > 0) {
            // TODO discountAmount can go from 10 to 9.99997743 (floating arithmetic issue)
            setDiscountDescription(`Discount (${discountAmount}%)`)
        }
        else {
            setDiscountDescription('')
        }
    }, [discountType, discountAmount, gift])

    const onBack = useCallback(
        () => history.replace(params?.id ? `/gift/${params.id}/edit` : '/gift/new', {gift}),
    [history, gift, params.id])

    const onSave = useCallback(() => {
        Result(dispatch(saveGift({ ...gift, id: params.id, client: client.id })))
            .then((gift: Partial<GiftCertificate>) =>
                dispatch(replace(`/gift/success/${gift.id}`, { client }))
            )
    }, [gift, params.id, client, dispatch])

    const onSend = useCallback((chargeAfterSend: boolean) => {
        Result(dispatch(sendGift({ ...gift, id: params.id, client: client.id }))).then(
            (gift: Partial<GiftCertificate>) => {
                return chargeAfterSend ?
                    dispatch(replace(`/charge/gift/${gift.id}`, { gift, summary: true })) :
                    dispatch(push(`/gift/success/${gift.id}`, { client }))
            })
    }, [gift, params.id, client, dispatch])

    const totalDisplay = useCallback(() => {
        return Number(gift?.calculateChargeAmount()) > 0 ? `${currencyFormat(gift!.calculateChargeAmount())} Total` :
        gift?.total === undefined ? "Price not set" :
            "Free"
    }, [gift])

    if (!gift) {
        return <Redirect to="/gift/new" />
    }

    const taxPercentage = Number(gift.tax ?? 0)
    return (
        <AppHeader showBackButton title="Gift certificates"
            middleWidget={<TransactionProgress created reviewed secondStep='Preview' thirdStep='Save' />}
            onBack={onBack}
        >
            <div className="scheduleClient servicesSettings ">
                <div className="header">
                    <h1>Review</h1>
                </div>

                <div className="separator" />

                <div className="scheduleClientReview">
                    <div className="details">
                        <div className="receipt">
                            <div className="total">{totalDisplay()}</div>
                            <div className="final" style={{textAlign: 'center', marginTop: '10px', marginBottom: '10px'}}>
                                <p style={{marginBottom: '10px'}}>{gift.name}</p>
                                <p>{gift.describe()}</p>
                            </div>
                            <div style={{maxWidth:'245px', margin: 'auto'}}>
                                <div className="lineItem final">
                                    <div className="name">Subtotal</div>
                                    <div className="amount">{currencyFormat(gift.total)}</div>
                                </div>
                                {Number(gift.discount) > 0 && (
                                    <div className="lineItem">
                                        <div className="name">{discountDescription}</div>
                                        <div className="amount">-{currencyFormat(gift.calculateDiscount())}</div>
                                    </div>
                                )}
                                {taxPercentage > 0 && (
                                    <div className="lineItem">
                                        <div className="name">Sales tax ({taxPercentage}%)</div>
                                        <div className="amount">{currencyFormat(gift.calculateTax())}</div>
                                    </div>
                                )}
                                {surchargeEnabled && (
                                    <div className="lineItem">
                                        <div className="name">Surcharge (3%)</div>
                                        <div className="amount">{currencyFormat(gift.calculateSurcharge())}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="actionsWrapper">
                        <div className="actions">
                            {!!gift.channel && <>
                                <PSButtonPrimary onClick={() => onSend(false)}>Send</PSButtonPrimary>
                                <PSButtonSecondary onClick={onSave}>Save</PSButtonSecondary>
                            </>}
                            {!gift.channel &&
                                <PSButtonPrimary onClick={onSave}>Save</PSButtonPrimary>
                            }
                            {!gift.paid_date && <PSButtonSecondary onClick={() => onSend(true)}>{gift.calculateChargeAmount() ? 'Charge' : 'Mark paid'}</PSButtonSecondary>}
                            {gift.paid_date && params.id && <PSButtonSecondary onClick={onBack}>Cancel</PSButtonSecondary>}
                        </div>
                    </div>

                    <div className="infoWrapper">
                            <div className="item flex">
                                <div className="data">
                                    <div className="bold">Client</div>
                                    <div className="selected">{client?.name}</div>
                                </div>
                                <div className="action">
                                    <Link to={`/record/client/${client?.id}`}>View</Link>
                                </div>
                            </div>
                        {!!gift.memo && (
                            <div className="item">
                                <div className="bold">Description</div>
                                <div className="grey2Text">{gift.memo}</div>
                            </div>
                        )}
                    </div>
                </div>
                {showMemoModal && (
                    <ReceiptMemoModal currentData={{memo}}
                                      onDone={(memo) => {
                                          setMemo(memo)
                                          setShowMemoModal(false)
                                      }}
                                      onCancel={() => setShowMemoModal(false)}
                    />
                )}
            </div>
        </AppHeader>
    )
}