import {useLocation} from "react-router";
import {Credential} from "./Credential";
import {findSelection, subscriptionFrequencyOptions} from "../components/settings/Options";

type CalendarSyncLocationState = {
    credential: Credential;
    calendars: Calendar[];
    changes: boolean;
    onlyMine: boolean;
    hideClientInformation: boolean;
}

export const useCalendarSyncLocationState = (): CalendarSyncLocationState | undefined => {
    const location = useLocation()
    return location.state as CalendarSyncLocationState
}

export const findFrequencySelect = (interval: number, frequency: string) => {
    const value = `${interval}${frequency === 'weekly' ? 'w' : frequency === 'daily' ? 'd' : 'm'}`
    return findSelection(subscriptionFrequencyOptions, value)
}

export class Calendar {
    id: string = '';
    summary: string = '';
    credential: string = '';
    sync_to: boolean = false;
    sync_from: boolean = false;
    color: Number = 0x999999;
    hipaa: boolean = false;
    only_mine: boolean = false;
    read_only: boolean = false;

    constructor(data: Partial<Calendar>) {
        Object.assign(this, data)
    }
}
