import moment from "moment";
import { currencyFloat, currencyFormat } from "../utils/numbers";
import {Transaction} from "./Transaction";

const DATE_FORMAT = 'MMM Do, yyyy'
const dateFormat = (date: string) => moment(date).format(DATE_FORMAT)

export const paymentStatus = (payment: any): string => {
    if (payment.refunded_date)
        return `Refunded ${currencyFormat(payment.refunded_amount)} on ${dateFormat(payment.refunded_date)}`
    else if (payment.charge_back_date)
        return `Disputed ${currencyFormat(payment.charge_back_amount)} on ${dateFormat(payment.charge_back_date)}`
    else if (payment.cancelled_date)
        return `Canceled on ${dateFormat(payment.cancelled_date)}`
    else if (payment.refund_reason)
        return `Refund requested`
    if (payment.created_date)
        return `Paid ${dateFormat(payment.created_date)}`
    return 'Unpaid'
}

const recordToPayment = (record: any) => {
    const payment = new Payment({
        client: record.client,
        vendor: record.vendor,
        owner: record.owner,
        date: moment().toISOString(),
        total: record.total,
        tax: record.tax,
        taxcode: record.taxcode,
        nexus: record.nexus,
        location: record.location,
        discount: record.discount,
        discount_type: record.discount_type,
        allow_surcharge: record.allow_surcharge,
        paid_deposit: record.paid_deposit,
        lines: record.lines,
        is_pos: true,
        source: record.source,
        gratuity: !record.paid_deposit && "percent" === record.gratuityType ? record.gratuity : (Number(record.gratuity) * Number(record.amount) / 100).toFixed(2),
        gratuity_type: !!record.paid_deposit && "percent" === record.gratuityType ? record.gratuity_type : "rate",
    })

    if (record.paid_deposit_date && !payment.paid_deposit)
        payment.paid_deposit = record.deposit
    if (payment.calculateTax() === 0) {
        payment.taxcode = ''
        payment.tax = 0
        payment.nexus = ''
    }

    return payment;
}

export const packageToPayment = (record: any) => {
    if (!record) return undefined

    const payment = recordToPayment(record)
    payment.package = record.id
    return payment;
}

export const invoiceToPayment = (record: any) => {
    if (!record) return undefined

    const payment = recordToPayment(record)
    payment.type = "invoice"
    payment.invoice = record.id
    if (record.booking) {
        payment.booking = record.booking
    }
    return payment;
}

export const giftToPayment = (record: any) => {
    if (!record) return undefined

    const payment = recordToPayment(record)
    payment.type = "gift"
    payment.gift = record.id
    return payment;
}

export const bookingToPayment = (record: any, subType: string) => {
    if (!record) return undefined

    const payment = recordToPayment(record)
    payment.type = subType
    payment.booking = record.id
    return payment;
}

export const estimateToPayment = (record: any, subType: string) => {
    if (!record) return undefined

    const payment = recordToPayment(record)
    payment.type = subType
    payment.estimate = record.id
    if (record.booking) {
        payment.booking = record.booking
    }
    return payment;
}

export class Payment extends Transaction {
    type?: string;
    location?: string;
    is_pos: boolean = false;
    contract?: string;
    form?: string;
    pos?: boolean;
    deposited_date?: string;
    intent?: string;
    paid_deposit?: string;
    gratuity?: string;
    gratuity_type?: string;
    refunded_date?: string;
    refunded_amount?: number;
    charge_back_date?: string;
    charge_back_amount?: number;
    refund_reason?: string;
    refund_details?: string;
    cancelled_date?: string;
    created_date?: string;
    mark_paid_date?: string;
    paid_date?: string;
    available_on_date?: string;
    booking?: string;
    estimate?: string;
    package?: string;
    gift?: string;
    invoice?: string;
    subscription?: string;
    deposit?: string;
    source_display?: string;

    constructor(data: Partial<Payment>) {
        super(data)
        Object.assign(this, data)
    }

    // override calculate tip on Transaction to include paid_deposit in tip
    calculateTip(subtotalAmount?: number) {
        if (subtotalAmount === undefined) {
            // Calculate it on the fly
            subtotalAmount = Number(this.total) || 0

            const discountAmount = this.calculateDiscount()
            subtotalAmount -= discountAmount
        }

        let tip = 0
        if (Number(this.tip) > 0) {
            if (this.tip_type === "rate")
                tip = Number(this.tip)
            else if (this.tip_type === "percent")
                subtotalAmount += Number(this.paid_deposit) || 0
                tip = (subtotalAmount * Number(this.tip) / 100)
        }
        return currencyFloat(tip)
    }
}

export const calculateGratuity = ({tip_type, tip, discount_type, discount, total}: Partial<Payment>): number => {
    if (!total)
        return 0

    if (tip_type === 'rate')
        return tip ?? 0

    let subTotal = total
    if (discount) {
        if (discount_type === 'percent')
            subTotal -= subTotal * (Number(discount) / 100)
        else
            subTotal -= Number(discount)
    }

    return subTotal * ((tip ?? 0) / 100)
}

export const getUserPayee = (payment: Payment, user: string) => {
    const { employee, creator, vendor } = payment
    return !!employee ? employee : (!!creator && creator !== user ? creator : vendor)
}