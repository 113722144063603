import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../../hooks";
import {
    clearForm,
    deleteForm,
    fetchContracts, fetchForm, saveForm, setForm, setFormEditing,
    SettingsState
} from "../../../../modules/settings";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {usePhotoUpload} from "../../hooks";
import localforage from "localforage";
import {Prompt, useParams} from "react-router";
import {
    PSButtonDanger,
    PSButtonPrimary,
    PSButtonSecondary
} from "../../../app/PSButton";
import {Dots} from "@zendeskgarden/react-loaders";
import {GuardrailModal} from "../../GuardrailModal";
import {SettingsPhotoField} from "../../SettingsPhotoField";
import {Checkbox, Field, Hint, Input, Label} from "@zendeskgarden/react-forms";
import {PSDropdown} from "../../../app/PSDropdown";
import {Item} from "@zendeskgarden/react-dropdowns";
import {PSTextarea} from "../../../app/PSTextarea";
import {Form} from "../../../../models/Form";
import {Field as PSField} from "../../../../models/Field";
import {push} from "connected-react-router";
import { usePSOwner } from "../../../../hooks";

export const FormEdit = () => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const params: any = useParams()

    const [photo, setPhoto] = useState<undefined | string | File>('')
    const [image, setImage] = useState<undefined | string>('') // For editing
    const [name, setName] = useState('')
    const [alwaysRequire, setAlwaysRequire] = useState(false)
    const [forClients, setForClients] = useState(true)
    const [contract, setContract] = useState<any>('')
    const [description, setDescription] = useState('')
    const [saveEnabled, setSaveEnabled] = useState(false)
    const [formSending, setFormSending] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)

    const [fieldList, setFieldList] = useState<PSField[]>([])
    const [addPhotoRef, selectPhoto, selectedPhoto] = usePhotoUpload({disabled: formSending, setPhoto})
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    const owner = usePSOwner()

    useEffect(() => {
        if (params.formID && params.action !== 'copy')
            setSaveEnabled(!!name)
        else
            setSaveEnabled(!!name && fieldList.length > 0)
    }, [name, params.formID, params.action, fieldList])

    useEffect(() => {
        if (params.formID)
            setFieldList(state.form.fields || [])
        else
            setFieldList(state.fields)
    }, [params.formID, state.form, state.fields])

    useEffect(() => {
        // TODO Compare current fields with state
        let changed = 0
        setHasEdits(changed > 0)
    }, [])

    // Fetch the user's contracts
    useEffect(() => {
        dispatch(fetchContracts())
    }, [dispatch])

    // Load the form if this is an edit, otherwise treat it as a create
    useEffect(() => {
        // If coming back from an edit page, load from state.form instead of fetching
        if (state.formEditing) {
            dispatch(setFormEditing(false))
            return
        }
        else {
            // Otherwise clear form in state and load it
            dispatch(clearForm())
        }

        if (!params.formID) {
            return
        }

        setFormSending(true)
        // @ts-ignore
        dispatch(fetchForm(params.formID)).then(() => setFormSending(false))
    }, [dispatch, params.formID]) // Do not add state.formEditing, it will cause a re-render and break this functionality

    // Update form's values after the gift cert is loaded into state
    useEffect(() => {
        if (params.action !== 'copy')
            setName(state.form.name || '')
        if (state.form.photo)
            setPhoto(state.form.photo)
        else
            setPhoto(state.form.image_uri || '')
        setImage(state.form.image || '')
        setDescription(state.form.memo || '')
        setAlwaysRequire(state.form.always_require)
        setForClients(state.form.is_public)
        const selectedContract = state.contracts.find((c) => c.id === state.form.contract)
        setContract(selectedContract || '')
    }, [state.form, state.contracts, params.action])

    const onSave = () => {
        if (formSending) return

        if (!name) return

        setFormSending(true)

        const form = new Form()
        form.name = name
        form.memo = description
        form.always_require = alwaysRequire
        form.is_public = forClients
        form.contract = contract ? contract.id : ''
        form.image = image
        if (params.formID && params.action !== 'copy')
            form.id = params.formID

        if (!params.formID) {
            // Add questions so they get saved when this form is created
            form.fields = state.fields
        }
        else if (params.action === 'copy') {
            // Add questions so they get saved when this form is copied
            form.fields = fieldList
        }

        if (typeof photo === 'string') {
            dispatch(saveForm(form))
        }
        else {
            dispatch(saveForm(form, photo as File))
        }
    }

    const manageQuestions = () => {
        // Save the state of the form into state.form and load it from there when we come back
        const form = new Form()
        form.name = name
        form.memo = description
        form.always_require = alwaysRequire
        form.is_public = forClients
        form.contract = contract ? contract.id : ''
        form.image = image
        if (typeof photo !== 'string')
            form.photo = photo as File
        if (params.formID) {
            form.fields = state.form.fields
        }

        dispatch(setForm(form))
        dispatch(setFormEditing(true))

        if (params.formID)
            dispatch(push(`/settings/forms/${params.formID}/questions`))
        else
            if (state.fields.length === 0)
                dispatch(push(`/settings/forms/questions/new`))
            else
                dispatch(push(`/settings/forms/questions`))
    }

    let questionEditDesc
    if (fieldList.length > 0) {
        questionEditDesc = (
            <div onClick={() => manageQuestions()}
                 className="buttonLink brightBlue"
                 style={{display: 'block', paddingTop: 8}}
            >
                View & edit questions ({fieldList.length}) &gt;
            </div>
        )
    }

    const showDeleteButton = params.formID && params.action !== 'copy'

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Form</h1>
                {showDeleteButton && (
                    <PSButtonDanger style={{height: '40px'}}
                                    onClick={() => setShowDeleteConfirmation(true)}>
                        Delete form
                    </PSButtonDanger>
                )}
                <PSButtonPrimary style={{height: '40px', marginLeft: showDeleteButton ? '16px' : 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!saveEnabled || formSending}>
                    {formSending && <Dots />}
                    {!formSending && "Save"}
                </PSButtonPrimary>
            </div>
            <div className="form">
                <SettingsPhotoField photo={photo}
                                    selectPhoto={selectPhoto}
                                    selectedPhoto={selectedPhoto}
                                    addPhotoRef={addPhotoRef}
                                    title="Form" />

                <Field className="field">
                    <Label className="label">Name (required)</Label>
                    <Input placeholder="Form name"
                           value={name}
                           disabled={formSending}
                           onChange={e => setName(e.target.value)} />
                </Field>

                {(!params.formID || params.action === 'copy') && (
                    <Field className="field">
                        <Label className="label">Questions (required)</Label>
                        <Hint className="hint">Add questions for clients to complete when filing out this form</Hint>
                        <PSButtonSecondary style={{marginTop: 16}}
                                           disabled={formSending}
                                           onClick={() => manageQuestions()}>
                            Add question
                        </PSButtonSecondary>
                        {questionEditDesc}
                    </Field>
                )}

                <Field className="field">
                    <Checkbox checked={alwaysRequire}
                              disabled={formSending}
                              onChange={e => setAlwaysRequire(e.target.checked)}>
                        <Label className="withHint">Always require</Label>
                        <Hint>Always require the form to be completed (even if previously completed by a client)</Hint>
                    </Checkbox>
                </Field>

                <Field className="field">
                    <Checkbox checked={forClients}
                              disabled={formSending}
                              onChange={e => setForClients(e.target.checked)}>
                        <Label className="withHint">For clients to complete</Label>
                        <Hint>Enable if you’d like clients to complete this form (disable if form is for internal use)</Hint>
                    </Checkbox>
                </Field>

                {owner?.allow_contract && !state.loadingAttachments && state.contracts.length > 0 && (
                    <PSDropdown selected={contract}
                                disabled={formSending}
                                nameProperty="name"
                                onSelect={(selection) => setContract(selection)}
                                label="Require signature"
                                hint="Select a contract to be sent with this form "
                                placeholder={<div className="grey">Select contract</div>}>
                        <>
                            {state.contracts.map(option => (
                                <Item key={`contract-${option.id}`} value={option}>
                                    {option.name}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                )}

                <Field className="field">
                    <Label className="label">Form description</Label>
                    <Hint className="hint">Displayed for clients to view when completing this form</Hint>
                    <PSTextarea placeholder="Add a description for a client to read through prior to completing this form. If you have a form that you’re using and you’d like our team to import it to your account send the PDF or Word file to support@pocketsuite.io. "
                                className="input"
                                minRows={8}
                                disabled={formSending}
                                value={description}
                                onChange={value => setDescription(value)}/>
                </Field>

            </div>
            {showDeleteConfirmation && (
                <GuardrailModal title="Are you sure?"
                                body="Are you sure you'd like to delete this form?"
                                buttonText="Delete"
                                onClose={() => setShowDeleteConfirmation(false)}
                                onAction={() => dispatch(deleteForm(params.formID))}
                />
            )}
            <Prompt
                when={hasEdits && !formSending}
                message="Are you sure you'd like to leave this page without saving your changes?" />
        </div>
    )
}