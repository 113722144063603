import React, {useCallback, useEffect, useState} from "react";
import {useAppDispatch, usePSOwner} from "../../../hooks";
import {Prompt, useHistory, useLocation, useParams} from "react-router";
import {PSButtonPrimary} from "../../app/PSButton";
import {Dots} from "@zendeskgarden/react-loaders";
import {
    Checkbox,
    Field,
    Hint,
    Input,
    Label,
} from "@zendeskgarden/react-forms";
import {PSDropdown} from "../../app/PSDropdown";
import {Item} from "@zendeskgarden/react-dropdowns";
import {blockCountOptions, blockDurationOptions, bookingDOWMonthlyOptions, daysOfTheWeekOptions, lessonCountOptions, recurringScheduleFrequencyOptions, SelectOption, timeHourOptions, timeMinuteIntervalFiveOptions} from "../Options";
import {Datepicker} from "@zendeskgarden/react-datepickers";
import moment from "moment";
import { AppHeader } from "../../app/AppHeader";
import "./block.css"
import { RecurringScheduleModal } from "../../transactions/RecurringScheduleModal";
import { Result } from "../../../utils";
import { sendBlock } from "../../../modules/settings";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { getRecord } from "../../../modules/records";

export type RepeatData = {
    frequency: SelectOption,
    dow: SelectOption[],
    instance: SelectOption,
    wom?: SelectOption
}

const useRepeatModalAndCheckbox = (currentValue?: RepeatData, onDone?: (selected: RepeatData) => void) => {
    const [data, setData] = useState<RepeatData>()
    const [repeat, setRepeat] = useState(false)
    const [repeatDisplay, setRepeatDisplay] = useState('')
    const [showRepeatModal, setShowRepeatModal] = useState(false)

    useEffect(() => {
        if (currentValue) {
            setData(currentValue)
            setRepeat(true)
        }
    }, [currentValue])

    useEffect(() => {
        if (!data || !repeat) {
            setRepeatDisplay('')
            return
        }

        const {frequency, dow, wom} = data
        if (!frequency || dow.length === 0) {
            setRepeatDisplay('')
        }

        if (frequency.value.endsWith('m') && wom) {
            setRepeatDisplay(`${wom.label} of ${frequency.label.toLowerCase()}`)
        }
        else {
            const days = dow.map(o => o.label).join(', ')
            setRepeatDisplay(`${days} ${frequency.label.toLowerCase()}`)
        }
    }, [data, repeat])

    const checkbox = (
        <Field className="field">
            <Checkbox checked={repeat}
                    onChange={(e) => {
                        setShowRepeatModal(e.target.checked)
                        setRepeat(e.target.checked)
                    }}>
                <Label className="withHint">Repeat</Label>
                {repeatDisplay && (
                    <Hint style={{color: '#3C94A4'}}>{repeatDisplay}</Hint>
                )}
                {!repeatDisplay && (
                    <Hint>Select whether this block repeats</Hint>
                )}
            </Checkbox>
        </Field>
    )

    const modal = showRepeatModal && (
        <RecurringScheduleModal defaultRecurrance={recurringScheduleFrequencyOptions[0]}
                                instanceOptions={blockCountOptions}
                                onClose={() => {
                                    setRepeat(false)
                                    setShowRepeatModal(false)
                                }}
                                onDone={(frequency, dow, instance, wom) => {
                                    const data = {frequency, dow, instance, wom}
                                    setData(data)
                                    setRepeat(true)
                                    setShowRepeatModal(false)

                                    if (onDone) {
                                        onDone(data)
                                    }
                                }}
        />
    )
    return [repeat, checkbox, modal]
}

export const BlockEdit = () => {

    const state = useSelector((state: RootState) => {
        return {
            entities: state.entities
        }
    })

    const dispatch = useAppDispatch()
    const history = useHistory()
    const location = useLocation()

    const [title, setTitle] = useState('')
    const [duration, setDuration] = useState<SelectOption>()
    const [saveEnabled, setSaveEnabled] = useState(false)
    const [formSending, setFormSending] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)

    const [date, setDate] = useState<Date>(moment().toDate())
    const [selectedHour, setSelectedHour] = useState<SelectOption>(timeHourOptions[0])
    const [selectedMinute, setSelectedMinute] = useState<SelectOption>(timeMinuteIntervalFiveOptions[0])

    const [repeatSchedule, setRepeateSchedule] = useState<{frequency: SelectOption, dow: SelectOption[], instance: SelectOption, wom?: SelectOption}>()
    const [isRepeated, repeatCheckbox, repeatModal] = useRepeatModalAndCheckbox(repeatSchedule, (selected) => {
        setRepeateSchedule(selected)
    })
    const [repeatEnabled, setRepeatEnabled] = useState(false)

    useEffect(() => {
        const isDateSet = selectedMinute !== undefined && selectedHour !== undefined
        const isDurationSet = !!duration
        setSaveEnabled(!!title && isDateSet && isDurationSet && hasEdits)
    }, [duration, hasEdits, selectedHour, selectedMinute, title])

    const params: {blockId?:string} = useParams()
    const isEdit = !!params.blockId
    const isEditAll = location.pathname.toLowerCase().includes("edit-all")

    useEffect(() => {
        if (!isEdit) {
            setHasEdits(true)
        }
        else {
            const block = state.entities.blocks && params.blockId! in state.entities.blocks ? state.entities.blocks[params.blockId!] : undefined
            if (!block) {
                return
            }

            const blockDate = moment(block.date)
            setDate(blockDate.toDate())

            const hours = blockDate.hours()
            const minutes = blockDate.minutes()

            setHasEdits(title !== block.title ||
                Number(selectedMinute?.value) !== minutes ||
                Number(selectedHour?.value) !== hours ||
                Number(duration?.value) !== Number(block.duration))
        }
    }, [duration?.value, isEdit, params.blockId, selectedHour?.value, selectedMinute?.value, state.entities.blocks, title])

    useEffect(() => {
        if (!state.entities?.blocks) {
            setFormSending(true)
            Result(dispatch(getRecord('block', params.blockId)))
                .finally(() => setFormSending(false))
            return
        }
        if (!isEdit) return

        const block = params.blockId! in state.entities.blocks ? state.entities.blocks[params.blockId!] : undefined
        if (!block) {
            setFormSending(true)
            Result(dispatch(getRecord('block', params.blockId)))
                .finally(() => setFormSending(false))
        }
        else {
            setTitle(block.title)
            setDuration(blockDurationOptions.find(o => Number(o.value) === block.duration))

            const blockDate = moment(block.date)
            setDate(blockDate.toDate())

            const hours = blockDate.hours()
            const minutes = blockDate.minutes()

            const selectedMin = timeMinuteIntervalFiveOptions.find(o => Number(o.value) === minutes)
            if (selectedMin) setSelectedMinute(selectedMin)

            const selectedHr = timeHourOptions.find(o => Number(o.value) === hours)
            if (selectedHr) setSelectedHour(selectedHr)

            if (block.schedule_instance && (isEditAll || !isEdit)) {
                const data = block.schedule_instance
                const intervalValue = `${data.interval}${data.frequency === 'monthly' ? 'm' : ''}`
                const frequency = recurringScheduleFrequencyOptions.find(o => o.value === intervalValue)
                const instance = lessonCountOptions.find(o => o.value === data.instances.toString())
                let dowList: SelectOption[] = []
                let wom: SelectOption | undefined = undefined
                if (data.frequency === 'weekly') {
                    dowList = daysOfTheWeekOptions.filter(o => Number(o.value) & data.dow)
                }
                else {
                    dowList = daysOfTheWeekOptions.filter(o => o.value === data.dow.toString())
                    wom = bookingDOWMonthlyOptions[Math.log2(data.dow || 1)].find(o => o.value === data.wom.toString())
                }
                setRepeatEnabled(true)

                setRepeateSchedule({
                    dow: dowList,
                    frequency: frequency!,
                    instance: instance!,
                    wom
                })
            }
        }

    }, [dispatch, isEdit, isEditAll, params.blockId, state.entities.blocks])

    const formatDate = useCallback(() => {
        const startOfDay = moment(date).startOf('day')
        const hours = Number(selectedHour!.value)
        const minutes = Number(selectedMinute!.value)

        return startOfDay.add(hours, 'hours').add(minutes, 'minutes').toISOString(true)
    }, [date, selectedHour, selectedMinute])

    const onSave = () => {
        let data: any = {
            id: params.blockId,
            title,
            owner: owner?.id,
            date: formatDate(),
            duration: duration!.value,
            change_all: isEditAll,
            instances: isRepeated ? repeatSchedule?.instance?.value : undefined,
            dow: isRepeated ? repeatSchedule?.dow.reduce((sum, o) => sum + Number(o.value), 0) : undefined
        }

        if (repeatSchedule?.frequency?.value) {
            data.frequency = repeatSchedule.frequency.value.endsWith('m') ? 'monthly' : 'weekly'
            data.interval = Number(repeatSchedule.frequency.value[0])
            if (data.frequency === 'monthly')
                data.wom = repeatSchedule.wom?.value
        }

        setFormSending(true)
        Result(dispatch(sendBlock(data)))
            .then(() => history.goBack())
    }

    const owner = usePSOwner()
    useEffect(() => {
        if (owner?.allow_recurring) {
            setRepeatEnabled(!isEdit || isEditAll)
        }
    }, [isEdit, isEditAll, owner])

    return (
        <AppHeader title="Block" middleWidget={null} showBackButton>
        <div className="servicesSettings" style={{width: '680px'}}>
            <div className="header">
                <h1>General</h1>
                <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!saveEnabled}>
                    {formSending && <Dots />}
                    {!formSending && "Save"}
                </PSButtonPrimary>
            </div>
            <div className="separator"></div>
            <div className="form">
                <Field className="field">
                    <Label className="label">Title (required)</Label>
                    <Input placeholder="Enter text"
                           value={title}
                           disabled={false}
                           onChange={e => setTitle(e.target.value)} />
                </Field>


                <Field className="fieldWrapper">
                                        <Label className="label">Date (Required)</Label>
                                        <Datepicker value={date} onChange={setDate}>
                                            <Input />
                                        </Datepicker>
                                    </Field>

                <div className="fieldWrapper">
                    <Label className="label">Time (Required)</Label>
                    <div className="fieldGroup">
                        <Field className="fieldGroupItem" style={{marginRight: '8px'}}>
                            <PSDropdown selected={selectedHour}
                                        nameProperty="label"
                                        onSelect={setSelectedHour}
                                        placeholder={(<div className="grey">Hour</div>)}>
                                {timeHourOptions.map(o => (
                                    <Item key={`time-hour-${o.value}`} value={o}>
                                        {o.label}
                                    </Item>
                                ))}
                            </PSDropdown>
                        </Field>
                        <Field className="fieldGroupItem" style={{marginLeft: '8px'}}>
                            <PSDropdown selected={selectedMinute}
                                        nameProperty="label"
                                        onSelect={setSelectedMinute}
                                        placeholder={(<div className="grey">Minute</div>)}>
                                {timeMinuteIntervalFiveOptions.map(o => (
                                    <Item key={`time-minute-${o.value}`} value={o}>
                                        {o.label}
                                    </Item>
                                ))}
                            </PSDropdown>
                        </Field>
                    </div>
                </div>

                <div className="fieldWrapper">
                    <PSDropdown selected={duration}
                                nameProperty="label"
                                disabled={false}
                                onSelect={setDuration}
                                label="Duration (Required)"
                                placeholder={<div className="grey">Select Duration</div>}>
                        <>
                            {blockDurationOptions.map(option => (
                                <Item key={`duration-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>

                </div>

                {repeatEnabled && repeatCheckbox}
            </div>
            {repeatModal}
            <Prompt
                when={hasEdits && !formSending}
                message="Are you sure you'd like to leave this page without saving your changes?" />
        </div>
        </AppHeader>
    )
}
