import React from 'react'
import Moment from 'react-moment'
import { useSelector } from 'react-redux'
import { MediaMessageBubble } from './MediaMessageBubble'
import { ContactAvatar } from '../contacts/ContactAvatar'
import { RootState } from '../../store'
import Linkify from "linkify-react";

type MessageBubbleProps = {
    message: any,
}

export const MessageBubble = ({ message }: MessageBubbleProps) => {
    const state = useSelector((state: RootState) => {
        return {
            login: state.login,
            entities: state.entities,
        }
    })

    const calendarStrings = {
        // sameDay: 'LT',
        // lastDay: 'dddd LT',
        // lastWeek: 'dddd LT',
        sameDay: 'ddd, MMM Do YYYY h:mm a',
        lastDay: 'ddd, MMM Do YYYY h:mm a',
        lastWeek: 'ddd, MMM Do YYYY h:mm a',
        sameElse: 'ddd, MMM Do YYYY h:mm a',
    }

    const isOutbound = state.login.userId === message.sender

    if (message.type && message.type !== 'person' && message.type !== 'team') {
        return <MediaMessageBubble isOutbound={isOutbound} message={message} />
    }

    let userAvatar
    if (!isOutbound && message.type === 'team') {
        const contact = state.entities.contacts[message.sender] || {}
        if (contact.id) {
            userAvatar = (
                <ContactAvatar user={contact} />
            )
        }
    }

    return (
        <div
            className={isOutbound ? 'outboundMessage' : 'inboundMessage'}
        >
            <div style={{display: 'flex', flexDirection: 'row'}}>
                {userAvatar && (
                    <div className="avatar">
                        {userAvatar}
                    </div>
                )}
                <div className="message"
                     style={{
                         backgroundColor: message.image_uri ? 'inherit' : '',
                         padding: message.image_uri ? '0': '16px',
                     }}
                >
                    {message.image_uri && (
                        <img className="userImage"
                             src={message.image_uri}
                             alt="Media message" />
                    )}
                    <div style={{whiteSpace: 'pre-line', wordBreak: 'break-word', wordWrap: 'break-word'}}>
                        <Linkify options={{className: 'link', target: '_blank'}}>
                            {message.message}
                        </Linkify>
                    </div>
                </div>
            </div>
            <Moment calendar={calendarStrings}>{message.created_date}</Moment>
        </div>
    )
}