import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {Dropdown, Item, Menu, Trigger} from "@zendeskgarden/react-dropdowns";
import {IconButton} from "@zendeskgarden/react-buttons";
import {ReactComponent as KabobIcon} from "../../icons/kabob_menu.svg";
import {Note} from "../../models/note";
import moment from "moment";
import { removeNote } from "../../modules/notes";
import { useDispatch } from "react-redux";
import { DangerModal } from "../settings/DangerModal";
import { Avatar } from "@zendeskgarden/react-avatars";

type Props = {
    note: Note
}

export const NoteCard = ({note}: Props) => {
    const dispatch = useDispatch()
    const [showDeleteGuardrail, setShowDeleteGuardrail] = useState(false)

    useEffect(() => {
        if (!note.person) {
            return
        }

    }, [note.person, dispatch])

    const formatDate = (date: string) =>
        moment(date).format('MMM Do')

    const formatWeekDay = (date: string) => {
        return {
            1: 'Mon',
            2: 'Tues',
            3: 'Wed',
            4: 'Thur',
            5: 'Fri',
            6: 'Sat'
        }[moment(date).isoWeekday()]
    }

    return (<>
        <div className="noteCard">
            <div className="details">
                {note.image_uri &&
                    <Avatar style={{marginTop: '5px'}} size='small'>
                        <img src={note.image_uri} alt={note.title} />
                    </Avatar>
                }
                <div style={note.image_uri ? {marginLeft: '18px'} : {}}>
                    <div className="name">{note.title}</div>
                    <div className="note">{note.note}</div>
                </div>
            </div>
            <div className="right">
                {formatWeekDay(note.created_date)}, {formatDate(note.created_date)}
                <div className="edit">
                    <Link to={`/note/edit/${note.id}`} className="edit">
                        View
                    </Link>
                </div>
            </div>
            <Dropdown>
                <Trigger>
                    <IconButton focusInset={false}>
                        <KabobIcon />
                    </IconButton>
                </Trigger>
                <Menu hasArrow style={{border: 'none', borderRadius: '12px', boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)'}}>
                    <Item onClick={() => setShowDeleteGuardrail(true)} value="delete" style={{color: '#e22216'}}>
                        Delete note
                    </Item>
                </Menu>
            </Dropdown>
        </div>
        {showDeleteGuardrail &&
            <DangerModal title="Delete note" disableDismiss
                body={"Are you sure you’d like to delete this note?"}
                buttonText="Delete note"
                onClose={() => setShowDeleteGuardrail(false)}
                onAction={() => {
                    setShowDeleteGuardrail(false)
                    dispatch(removeNote(note.id))
                }}
            />
        }
    </>)
}