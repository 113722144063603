import {Option} from "./Option";

export type FieldType = 'checkbox' | 'currency' | 'date' | 'email' | 'float' |
    'integer' | 'longtext' | 'multiselect' | 'phone' | 'select' | 'text' | 'image' | 'document' | '';

export class Field {
    id?: string;
    name?: string;
    label: string;
    type: FieldType;
    is_required: boolean;
    is_public: boolean;
    is_client: boolean;
    is_booking: boolean;
    is_order: boolean;
    is_subaccount: boolean;
    checkout_client: boolean;
    checkout_booking: boolean;
    checkout_order: boolean;
    checkout_other: boolean;
    seqnum: number;
    record?: string;
    options: Option[];
    edition?: string;
    edition_field?: string;

    constructor() {
        this.label = ''
        this.type = ''
        this.is_required = false
        this.is_public = true
        this.is_client = false
        this.is_booking = false
        this.is_order = false
        this.is_subaccount = false
        this.checkout_client = false
        this.checkout_booking = false
        this.checkout_order = false
        this.checkout_other = false
        this.seqnum = 0
        this.options = []
    }

    toObject(): any {
        const data: any = {}
        if (this.record)
            data.record = this.record
        data.is_required = this.is_required
        data.is_public = this.is_public
        data.is_client = this.is_client
        data.is_booking = this.is_booking
        data.is_order = this.is_order
        data.is_subaccount = this.is_subaccount
        data.checkout_client = this.checkout_client
        data.checkout_booking = this.checkout_booking
        data.checkout_order = this.checkout_order
        data.checkout_other = this.checkout_other
        data.id = this.id
        data.label = this.label
        data.type = this.type
        data.name = this.name
        if (this.options.length > 0) {
            data.labels = []
            data.values = []
            this.options.forEach((option) => {
                if (option.label !== '') {
                    data.labels.push(option.label)
                    data.values.push(option.value)
                }
            })
        }
        data.edition = this.edition
        data.edition_field = this.edition_field
        return data
    }

    setData(data: any) {
        this.id = data.id
        this.name = data.name
        this.label = data.label
        this.type = data.type
        this.is_required = data.is_required
        this.is_public = data.is_public
        this.is_client = data.is_client ?? this.is_client
        this.is_booking = data.is_booking ?? this.is_booking
        this.is_order = data.is_order ?? this.is_order
        this.is_subaccount = data.is_subaccount ?? this.is_subaccount
        this.checkout_client = data.checkout_client ?? this.checkout_client
        this.checkout_booking = data.checkout_booking ?? this.checkout_booking
        this.checkout_order = data.checkout_order ?? this.checkout_order
        this.checkout_other = data.checkout_other ?? this.checkout_other
        this.seqnum = data.seqnum
        this.record = data.record
        if (data.options) {
            this.options = data.options.map((o: any) => {
                const option = new Option()
                option.setData(o)
                return option
            })
        }
        if (data.labels) {
            let seqnum = 1
            this.options = data.labels.map((l: any) => {
                const option = new Option()
                option.label = l
                option.value = ""
                option.seqnum = seqnum++
                return option
            })
        }
        this.edition_field = data.edition_field
    }

    prettyType(): string {
        switch (this.type) {
            case 'multiselect':
                return 'Multiple choice'
            case 'select':
                return 'Single choice'
            case 'float':
                return 'Decimal number'
            case 'longtext':
                return 'Long text'
            case 'phone':
                return 'Phone number'
            case 'document':
                return 'File upload'
            case 'text':
            case 'integer':
            case 'checkbox':
            case 'date':
            case 'currency':
            case 'email':
            case 'image':
            default:
                return this.type[0].toUpperCase() + this.type.substring(1)
        }
    }
}