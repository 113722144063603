import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from '../store'
import React, {useCallback, useEffect, useRef, useState} from "react";
import {UserInstance} from "../models/User";
import localforage from "localforage";
import {TaxcodeInstance} from "../models/Taxcode";
import {apiClient} from "../modules/apiclient";
import {TotalInstance} from "../components/transactions/TotalModal";
import {currencyFormat} from "../utils/numbers";
import { getUser } from '../modules/settings';
import { Result } from '../utils';
import { EMPLOYER_CACHE_KEY } from '../utils/constants';
import { DangerModal } from '../components/settings/DangerModal';
import { Spinner } from '@zendeskgarden/react-loaders';
import { Body, Close, Footer, FooterItem, Header, Modal } from '@zendeskgarden/react-modals';
import { PSButton, PSButtonPrimary } from '../components/app/PSButton';
import { userLogout } from '../modules/login';

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const usePSUser = () => {
    const dispatch = useAppDispatch()
    const [user, setUser] = useState<UserInstance>()

    useEffect(() => {
        let isSubscribed = true

        localforage.getItem('user').then((u: any) => {
            const user = u as UserInstance
            if (isSubscribed) setUser(user)

            if (!user?.id) {
                dispatch(userLogout())
            }
        })

        return () => {
            isSubscribed = false
        }
    }, [dispatch])

    return user
}

export const usePSOwner = () => {
    const dispatch = useDispatch()
    const user = usePSUser()
    const [employer, setEmployer] = useState<UserInstance>()

    useEffect(() => {
        if (!['admin', 'employee'].includes(user?.role ?? '') || !user?.employer) {
            // No employer, your the boss or single man show
            setEmployer(user)
            return
        }

        localforage.getItem(EMPLOYER_CACHE_KEY).then((employer: any) => {
            if (employer) {
                setEmployer(employer)
                return
            }
            Result(dispatch(getUser(user.employer)))
                .then((e: UserInstance) => {
                    setEmployer(e)
                    localforage.setItem(EMPLOYER_CACHE_KEY, e)
                })
        })
    }, [user, dispatch])

    return employer
}

export const useTaxCodeLookup = (user?: UserInstance) => {
    const [taxCode, setTaxCode] = useState<TaxcodeInstance>()
    const [fetching, setFetching] = useState(false)

    useEffect(() => {
        if (user?.taxcode && !fetching) {
            setFetching(true)
            apiClient.post(`/taxcode`, {'taxcode': user.taxcode})
                .then(resp => resp.data)
                .then(json => {
                    if (json)
                        setTaxCode(json as TaxcodeInstance)
                })
        }
    }, [user, fetching])

    return taxCode
}

export const usePSTotalDisplay = (totalData?: TotalInstance, fallback? :string) => {
    const [totalDisplay, setTotalDisplay] = useState<string | React.ReactNode>('')

    const calculateDiscountAmount = useCallback((total: number) => {
        let discountAmount = 0
        if (totalData?.discountType === 'percent')
            discountAmount = total * totalData?.discountRate / 100
        else if (totalData?.discountType === 'rate')
            discountAmount = totalData?.discountRate

        return discountAmount
    }, [totalData?.discountRate, totalData?.discountType])

    const setupFeeDisplay = useCallback(() => {
        let setupDisplay
        if (totalData?.setupFee) {
            setupDisplay = ` (setup fee ${currencyFormat(totalData.setupFee)})`
        }
        return setupDisplay
    }, [totalData?.setupFee])

    useEffect(() => {
        if (!totalData?.total) {
            setTotalDisplay(fallback ?? '')
            return
        }

        const discountAmount = calculateDiscountAmount(totalData.total)
        setTotalDisplay((
            <div className="selected">{currencyFormat(totalData.total - discountAmount)}{discountAmount !== 0 && (<>
                · <span style={{textDecoration: 'line-through'}}>{currencyFormat(totalData.total)}</span>
            </>)}{setupFeeDisplay()}</div>
        ))

    }, [totalData?.total, calculateDiscountAmount, setupFeeDisplay, fallback])

    return totalDisplay
}

type ModalInfo = {title: string, body: string | JSX.Element, button: string, optionalButton?: string, danger?: boolean, onClick: (optionalButton: boolean) => void}
type CtaModalReturnType = [(info: ModalInfo) => void, (isLoading: boolean) => void, () => void, () => void, JSX.Element]
export const useConfirmModal = (): CtaModalReturnType => {
    const [ctaModalInfo, setCtaModalInfo] = useState<ModalInfo>()
    const [ctaModalLoading, setCtaModalLoading] = useState(false)
    const [showCtaModal, setShowCtaModal] = useState(false)
    const closeCtaModal = () => {
        setShowCtaModal(false)
        setCtaModalLoading(false)
    }

    const component = <>{showCtaModal && ctaModalInfo &&
        (ctaModalInfo.danger ?
            <DangerModal
                title={ctaModalInfo.title}
                body={ctaModalLoading ? <Spinner size="48" color="#314A68" /> : ctaModalInfo.body}
                onClose={closeCtaModal}
                disableDismiss
                buttonText={!ctaModalLoading ? ctaModalInfo.button : ''}
                extraButtonText={!ctaModalLoading ? ctaModalInfo.optionalButton: undefined}
                onAction={(buttonIx?: number) => !ctaModalLoading ? ctaModalInfo.onClick(buttonIx === 2) : { }} /> :
            <Modal onClose={closeCtaModal}>
                <Header>{ctaModalInfo.title}</Header>
                <Body>{ctaModalLoading ? <Spinner size="48" color="#314A68" /> : ctaModalInfo.body}</Body>
                <Close aria-label="Close modal" />
                <Footer>
                    <FooterItem>
                        {!ctaModalLoading && <>
                            {ctaModalInfo.optionalButton && <PSButton style={{marginRight: '16px'}} onClick={() => ctaModalInfo.onClick(true)}>{ctaModalInfo.optionalButton}</PSButton>}
                            <PSButtonPrimary onClick={() => ctaModalInfo.onClick(false)}>{ctaModalInfo.button}</PSButtonPrimary>
                        </>}
                    </FooterItem>
                </Footer>
            </Modal>
        )}</>

    return [setCtaModalInfo, setCtaModalLoading, () => setShowCtaModal(true), closeCtaModal, component]
}

export const useInterval = (callback: Function, delay: number) => {
    const savedCallback = useRef<Function>();
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        let id: NodeJS.Timeout
        const tick = async () => {
            try {
                if (!savedCallback.current) {
                    return
                }

                await savedCallback.current()
                id = setTimeout(tick, delay)
            }
            catch (e) {
                console.error(e)
            }
        }
        tick()

        return () => id && clearTimeout(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delay]);
};