import React, {useEffect, useState} from "react";
import {
    Header,
    Modal,
    Body,
} from "@zendeskgarden/react-modals";
import {Spinner} from "@zendeskgarden/react-loaders";
import {SelectModalCard} from "../transactions/SelectModalCard";
import {isEmpty} from "lodash";

type Props = {
    onSelect: (item: any) => void,
    onCancel: () => void,
    pros: any[],
    selectedPro: any
}

export const SelectProModal = ({onSelect, onCancel, pros, selectedPro}: Props) => {
    const [searchText, setSearchText] = useState('')
    const [filteredSelectionList, setFilteredSelectionList] = useState<any[]>(Object.values(pros))

    useEffect(()=> {
        if (searchText) {
            const escapedSearchText = searchText.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')
            const regex = new RegExp(escapedSearchText, 'i')
            setFilteredSelectionList(Object.values(pros).filter(i => i.name.match(regex)))
        }
        else {
            setFilteredSelectionList(Object.values(pros))
        }
    }, [pros, searchText, setFilteredSelectionList])

    return (
        <Modal className="selectModal"
               onClose={() => onCancel()}
        >
            <Header className="header">
                <div className="title">Pros</div>
                <input type="text"
                       placeholder={'Search pros'}
                       value={searchText}
                       onChange={e => setSearchText(e.target.value)}
                />
            </Header>
            <Body className="body" id="clientList">
                {isEmpty(pros) && (
                    <div style={{textAlign: 'center'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                )}
                {!isEmpty(pros) && filteredSelectionList.map((item: any) => {
                    return (
                        <SelectModalCard type='pros'
                            key={`selection-${item.id}`}
                            selected={selectedPro?.id === item.id}
                            item={item}
                            onSelection={onSelect} />
                    )
                })}
            </Body>
        </Modal>
    )
}