import React from "react"
import {Field as PSField} from "../../models/Field";
import {useAppDispatch} from "../../hooks";
import {push} from "connected-react-router";
import {Field, Label, Toggle} from "@zendeskgarden/react-forms";

type Props = {
    field: PSField,
    forIntake?: boolean,
    isChecked: boolean,
    onToggleItem: (b: boolean) => void,
}

export const OnboardingField = ({field, forIntake, isChecked, onToggleItem}: Props) => {
    const dispatch = useAppDispatch()

    return (
        <div className={`serviceCard onboardingCard ${isChecked ? 'selected' : ''}`}>
            <div className="details">
                <div className="name">{field.label}</div>
                <div className="rate">{field.prettyType()}</div>
            </div>
            <div className="edit">
                {isChecked && (
                    <div className={`buttonLink brightBlue ${isChecked ? '' : 'disabledLink'}`}
                         onClick={() => {
                             if (isChecked) dispatch(push('/onboarding/field/edit', {field, forIntake}))
                         }}
                    >
                        Edit
                    </div>
                )}
            </div>
            <div className="toggle">
                <Field>
                    <Toggle checked={isChecked}
                            onChange={e => {
                                onToggleItem(e.target.checked)
                            }}>
                        <Label>&nbsp;</Label>
                    </Toggle>
                </Field>
            </div>
        </div>
    )
}