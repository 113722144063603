import React, {useCallback, useEffect, useState} from "react";
import {useAppDispatch} from "../../hooks";
import {fetchRecord} from "../../modules/settings";
import {Prompt, useHistory, useParams} from "react-router";
import {PSButton, PSButtonPrimary} from "../app/PSButton";
import {Spinner} from "@zendeskgarden/react-loaders";
import {Result} from "../../utils";
import {Field} from "../../models/Field";
import {FieldInput} from "./FieldInput";
import { AppHeader } from "../app/AppHeader";
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { sendForm } from "../../modules/transactions";
import { push } from "connected-react-router";

export const RecordEdit = () => {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const params: any = useParams<{id: string}>()

    const [saveEnabled, setSaveEnabled] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)

    const [fields, setFields] = useState<Field[]>([])
    const [record, setRecord] = useState<any>()
    const [originalFieldData, setOriginalFieldData] = useState<any>()
    const [fieldData, setFieldData] = useState<any>()
    useEffect(() => {
        if (!params.id) return

        setIsLoading(true)
        Result(dispatch(fetchRecord(params.id)))
            .then((response: any) => {
                if (response?.fields) {
                    setFields(response.fields)
                }
                if (response?.record) {
                    setRecord(response.record)

                    const data = response.record.field_values
                    setFieldData(data)
                    setOriginalFieldData({ ...data })
                }
            })
            .finally(() => setIsLoading(false))
    }, [params.id, dispatch])

    const removeEmpty = (_: string, value: any) => {
        // filtering out empty values & arrays
        if (!value || value?.length === 0) {
            return undefined;
        }
        return value;
    }

    useEffect(() => {
        setHasEdits(JSON.stringify(originalFieldData, removeEmpty) !== JSON.stringify(fieldData, removeEmpty))
    }, [fieldData, originalFieldData])

    const isValidForm = useCallback(() => {
        if (!fields) return false

        const requiredFields = fields.filter(field => field.is_required)

        let isValid = true
        requiredFields.forEach((field: any) => {
            const data = field.name in fieldData ? fieldData[field.name] : undefined
            isValid = isValid && (!!data || data === 0)
        })

        return isValid
    }, [fieldData, fields])

    useEffect(() => {
        setSaveEnabled(hasEdits && !isLoading && isValidForm())
    }, [isLoading, hasEdits, isValidForm])

    const [showSaveForm, setShowSaveForm] = useState(false)
    const saveRecord = (shouldNotify?: boolean) => {
        const recordToSend: any = { id: record.id, field_values: fieldData }
        if (!shouldNotify) {
            recordToSend.dont_notify = true
        }
        setIsLoading(true)
        setShowSaveForm(false)
        Result(dispatch(sendForm(recordToSend)))
            .then(() => shouldNotify ?
                dispatch(push(`/messages/${record.person}`)) :
                history.goBack()
            )
    }

    const sortedFields = fields.sort((a, b) => a.seqnum - b.seqnum)
    return (
        <AppHeader title="Form" showBackButton middleWidget={null}>
            <div className="servicesSettings" style={{maxWidth: '680px', paddingBottom: '40px'}}>
                <div className="header">
                    <h1>Form</h1>
                    <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                    onClick={() => record.channel ? setShowSaveForm(true) : saveRecord(false) }
                                    disabled={!saveEnabled}>
                        Save
                    </PSButtonPrimary>
                </div>
                <div className="separator" />
                <div className="form">
                    {isLoading && <Spinner size="128" color="#314A68" />}
                    {!isLoading && sortedFields.map(field => {
                        return (
                            <FieldInput key={`field-${field.id}`}
                                field={field}
                                value={fieldData && field.name ? fieldData[field.name] : undefined}
                                onChange={(newValue) => {
                                    setFieldData((currentVal: any) => {
                                        if (field.name && currentVal) {
                                            currentVal[field.name] = newValue
                                            // we need to return a new object here to trigger the change notification
                                            return {
                                                ...currentVal
                                            }
                                        }
                                        return currentVal;
                                    })
                                }}
                            />
                    )})}
                </div>
                {showSaveForm && (
                    <Modal onClose={() => setShowSaveForm(false)}>
                        <Header>Form</Header>
                        <Body>If you would like to notify the client of changes to this form select “Send form”. To save the changes without sending the client a notification select “Save”. </Body>
                        <Close aria-label="Close modal" />
                        <Footer>
                            <FooterItem>
                                <PSButton onClick={() => saveRecord(false)} style={{marginRight: '16px'}}>Save</PSButton>
                                <PSButtonPrimary onClick={() => saveRecord(true)}>Send form</PSButtonPrimary>
                            </FooterItem>
                        </Footer>
                    </Modal>
                )}
                <Prompt
                    when={hasEdits && !isLoading}
                    message="Are you sure you'd like to leave this page without saving your changes?" />
            </div>
        </AppHeader>
    )
}