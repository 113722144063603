import React, {useCallback, useEffect, useState} from "react";
import {PSButton, PSButtonPrimary} from "../../../app/PSButton";
import {ReactComponent as LeftArrow} from '../../../../icons/left-arrow.svg'
import {useHistory, useParams} from "react-router";
import {useAppDispatch} from "../../../../hooks";
import {
    fetchForm, fetchLeadsQuestions, moveField,
    setFields,
    SettingsState
} from "../../../../modules/settings";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {Spinner} from "@zendeskgarden/react-loaders";
import {FormQuestionListEmptyState} from "./FormQuestionListEmptyState";
import {push} from "connected-react-router";
import {Link} from "react-router-dom";
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult
} from "react-beautiful-dnd";
import {ReactComponent as GripIcon} from "../../../../icons/grip.svg";
import {fieldQuestionTypeOptions} from "../../Options";
import {Field} from "../../../../models/Field";
import {Result} from "../../../../utils";

type Props = {
    forOnboarding?: boolean,
    forClient?: boolean,
}

export const FormQuestionList = ({forOnboarding, forClient}: Props) => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)
    const history = useHistory()
    const params: any = useParams()

    const [pageLoading, setPageLoading] = useState(true)
    const [fieldList, setFieldList] = useState<Field[]>([])

    useEffect(() => {
        if (!params.formID || state.form.id === params.formID || state.formEditing || forOnboarding) {
            if (forClient){
                Result(dispatch(fetchLeadsQuestions()))
                    .finally(() => setPageLoading(false))
            }
            else {
                setPageLoading(false)
            }
            return
        }

        dispatch(fetchForm(params.formID))
    }, [dispatch, params.formID, state.form.id, state.formEditing, forOnboarding, forClient])

    useEffect(() => {
        if (params.formID || forOnboarding)
            setFieldList(state.form.fields || [])
        else
            setFieldList(state.fields)
    }, [params.formID, state.form.fields, state.fields, forOnboarding])

    const addNewField = () => {
        if (params.formID)
            dispatch(push(`/settings/forms/${params.formID}/questions/new`))
        else if (forClient)
            dispatch(push('/settings/leads/questions/new'))
        else
            dispatch(push(`/settings/forms/questions/new`))
    }

    const onDragEnd = useCallback((result: DropResult) => {
        if (!result.destination) return

        const fields = params.formID ? state.form.fields!.slice() : state.fields.slice()

        const target = fields[result.destination.index]
        const [removed] = fields.splice(result.source.index, 1)

        fields.splice(result.destination.index, 0, removed)
        if (params.formID) {
            // Send to server and trigger UI update
            dispatch(moveField(removed, target.seqnum, fields))
        }
        else if (forClient) {
            Result(dispatch(moveField(removed, target.seqnum, fields)))
                .then(() => dispatch(setFields(fields)))
        }
        else {
            // Local change only
            dispatch(setFields(fields))
        }
    }, [dispatch, state.fields, state.form, params.formID, forClient])

    let loading
    if (pageLoading)
        loading = (
            <div style={{textAlign: 'center', padding: '128px 0'}}>
                <Spinner size="128" color="#314A68" />
            </div>
        )

    // If there are no fields, show the empty state
    if (!pageLoading && state.form.fields?.length === 0) {
        return <FormQuestionListEmptyState onCreateNewField={addNewField} />
    }

    return (
        <div className="servicesSettings">
            <div className="header">
                <PSButton style={{marginLeft: 0, marginRight: 16}}
                          onClick={() => history.goBack()}
                >
                    <LeftArrow />
                </PSButton>
                <h1>List of questions</h1>
                <PSButtonPrimary style={{height: 40}}
                                 onClick={() => addNewField()}
                >
                    Add question
                </PSButtonPrimary>
            </div>
            <div className="separator" />
            {loading}
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(droppableProps: any) => {
                        return (
                            <div ref={droppableProps.innerRef}>
                                {fieldList.map((field, index) => {
                                    const typeOption = fieldQuestionTypeOptions.find(option => option.value === field.type)
                                    const editURL = params.formID ?
                                        `/settings/forms/${params.formID}/questions/${field.id}/edit` :
                                        forClient ? `/settings/leads/questions/${field.id}` :
                                        `/settings/forms/questions/${index}/edit`

                                    return (
                                        <Draggable key={`field-${index}`}
                                                   draggableId={`${field.label}-${index}`}
                                                   index={index}>
                                            {(provided: any, snapshot: any) => (
                                                <div ref={provided.innerRef}
                                                     {...provided.draggableProps}
                                                     {...provided.dragHandleProps}
                                                >
                                                    <div className="serviceCard">
                                                        <div className="details">
                                                            <div className="name">{field.label}</div>
                                                            <div className="rate">{typeOption?.label}</div>
                                                        </div>
                                                        <div className="edit" style={{display: 'flex', alignItems: 'center'}}>
                                                            <Link to={editURL} className="edit">
                                                                Edit
                                                            </Link>
                                                            <div style={{
                                                                height: 48,
                                                                paddingTop: 16,
                                                                paddingLeft: 24,
                                                            }}>
                                                                <GripIcon/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}
                            </div>
                        )
                    }}
                </Droppable>
            </DragDropContext>
        </div>
    )
}
