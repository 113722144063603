import React from 'react'
import {Field} from "../../models/Field";
import {ReactComponent as DocumentIcon} from '../../icons/new_field_document.svg'
import './display-custom-field.css'
import moment from "moment/moment";


type DisplayCustomFieldProps = {
    name: string,
    field: Field,
    instance: any
}

/**
 * Component for returning the displayable content for custom fields on pages in view or preview mode.
 *
 * @param name string - field name for the field being displayed
 * @param field Field - field instance for the field being displayed
 * @param instance object - dictionary containing data for the record.
 * @constructor
 *
 * @version 4.30.4
 * @author [Yang Forjindam](yang@pocketsuite.io)
 */
export const DisplayCustomField = ({name, field, instance}: DisplayCustomFieldProps) => {
    const value = instance.field_values[name]
    if (field.type === 'select' || field.type === 'multiselect') {
        if (Array.isArray(value))
            return field.options.filter((o) => value.indexOf(o.value) !== -1).map((o) => o.label).join(', ')
        else
            return field.options.find((o) => value.indexOf(o.value) !== -1)?.label
    }
    else if (field.type === 'date')
        return moment(value).format('MMM D, YYYY')
    else if (field.type === "image" || field.type === "document") {
        const fileInfo = instance.field_values[name + "_result"]
        const url = fileInfo.type === "application/pdf" || fileInfo.type.indexOf("image/") === 0 ? fileInfo.abs_uri : `https://docs.google.com/viewer?url=${fileInfo.abs_uri}`
        if (fileInfo.type.indexOf("image/") === 0)
            return <div id={`${fileInfo.name}_image_wrapper`} className="field-wrapper-image readonly">
                <div className="view-image-icon">
                    <a href={url} target="_blank" className="view-file">
                        <img src={url} alt="View image"/>
                    </a>
                </div>

                <div className="image-info">
                    <div className="image-info-name">
                        {fileInfo.name}
                    </div>
                    <div className="image-info-type">
                        {fileInfo.type_string} · {fileInfo.size_string}
                    </div>
                </div>
            </div>
        else {
            return <div id={`${fileInfo.name}_file_wrapper`} className="field-wrapper-file readonly">
                <div className="view-file-icon">
                    <a href={url} target="_blank" className="view-file">
                        {<DocumentIcon/>}
                    </a>
                </div>

                <div className="file-info">
                    <div className="file-info-name">
                        {fileInfo.name}
                    </div>
                    <div className="file-info-type">
                        {fileInfo.type_string} · {fileInfo.size_string}
                    </div>
                </div>
            </div>
        }
    }
    else
        return value
}