import React, {useEffect, useState} from "react";
import {
    Body, Close,
    Footer,
    FooterItem,
    Header,
    Modal
} from "@zendeskgarden/react-modals";
import {Spinner} from "@zendeskgarden/react-loaders";
import {Button} from "@zendeskgarden/react-buttons";
import {useDispatch, useSelector} from "react-redux";
import {fetchSavedMessages} from "../../modules/conversation";
import {RootState} from "../../store";
import {Checkbox, Field, Input, Label, Radio} from "@zendeskgarden/react-forms";
import {PSDropdown} from "../app/PSDropdown";
import {Datepicker} from "@zendeskgarden/react-datepickers";
import moment from "moment";
import {ItemOption} from "../settings/AvailableHoursModal";
import {Item} from "@zendeskgarden/react-dropdowns";

const laterHours: ItemOption[] = []
for (let i = 0; i < 24; i++) {
    laterHours.push({value: i, name: moment().hour(i).minute(0).format('h:mma') })
    laterHours.push({value: i+0.5, name: moment().hour(i).minute(30).format('h:mma') })
}
laterHours.push({value: 24, name: moment().hour(0).minute(0).format('h:mma') })

type Props = {
    onClose: () => void
    onSend: (message: string, templateID: string, sendAt?: Date) => void
}

export const SavedMessagesModal = ({onClose, onSend}: Props) => {
    const dispatch = useDispatch()
    const state = useSelector((state: RootState) => {
        return {
            conversation: state.conversation as any,
        }
    })

    //TODO Only show send later if the user has scheduled messages feature enabled
    const [isLoading, setIsLoading] = useState(true)
    const [sendEnabled, setSendEnabled] = useState(false)
    const [sendLater, setSendLater] = useState(false)
    const [laterDate, setLaterDate] = useState(new Date())
    const [laterTime, setLaterTime] = useState<ItemOption | undefined>()
    const [selectedMessage, setSelectedMessage] = useState<any>()

    // Fetch the user's contracts and forms
    useEffect(() => {
        // @ts-ignore
        dispatch(fetchSavedMessages()).then(() => setIsLoading(false))
    }, [dispatch])

    useEffect(() => {
        if (sendLater) {
            if (!laterTime) {
                setSendEnabled(false)
                return
            }

            const sendAt = moment(laterDate).hour(Number(laterTime.value)).minute(laterTime.value.toString().endsWith('.5') ? 30 : 0)
            setSendEnabled(sendAt.isAfter(moment()) && !!selectedMessage)
        }
        else
            setSendEnabled(!!selectedMessage)
    }, [sendLater, laterTime, selectedMessage])

    const onSendClicked = () => {
        // Calculate schedule date with laterDate and laterTime
        let sendAt
        if (sendLater && laterTime) {
            sendAt = moment(laterDate).hour(Number(laterTime.value)).minute(laterTime.value.toString().endsWith('.5') ? 30 : 0).toDate()
        }

        onSend(selectedMessage.message, selectedMessage.id, sendAt)
    }

    return (
        <Modal className="savedMessagesModal" onClose={onClose}>
            <Header>Select a saved message</Header>
            <Body>
                {isLoading && (
                    <Spinner size="128" color="#314A68" />
                )}
                {!isLoading && (
                    <>
                        <div className="details">
                            Select send and clients will be sent the message right away. If you’d like to schedule the message to go out later tap “Send later” to schedule when your message will be sent.
                        </div>
                        <Field className="field">
                            <div className="check">
                                <Checkbox checked={sendLater}
                                          onChange={e => setSendLater(e.target.checked)}
                                          >
                                    <Label>Send later</Label>
                                </Checkbox>
                            </div>
                            <div className="sendLaterSelections">
                                <div className="slimField">
                                    <Datepicker value={sendLater ? laterDate : undefined}
                                                onChange={setLaterDate}
                                                minValue={new Date()}
                                    >
                                        <Input disabled={!sendLater} />
                                    </Datepicker>
                                </div>
                                <div className="slimFieldTime" style={{margin: '0 24px 0 12px'}}>
                                    <PSDropdown selected={sendLater ? laterTime : undefined}
                                                nameProperty={'name'}
                                                onSelect={setLaterTime}
                                                label={''}
                                                disabled={!sendLater}
                                    >
                                        <>
                                            {laterHours.map(option => (
                                                <Item key={`later-${option.value}`} value={option}>
                                                    {option.name}
                                                </Item>
                                            ))}
                                        </>
                                    </PSDropdown>
                                </div>
                            </div>
                        </Field>
                        {state.conversation.savedMessages.map((savedMessage: any) => {
                            return (
                                <Field className="messageOption" key={`savedmessage-${savedMessage.id}`}>
                                    <Radio name="savedmessage radio"
                                           value={savedMessage}
                                           checked={selectedMessage && selectedMessage.id === savedMessage.id}
                                           onChange={event => setSelectedMessage(savedMessage)}
                                    >
                                        <Label>{savedMessage.title}</Label>
                                        <div className="messageBody">{savedMessage.message}</div>
                                    </Radio>
                                </Field>
                            )
                        })}
                    </>
                )}
            </Body>
            <Footer style={{paddingBottom: 32}}>
                <FooterItem>
                    <Button onClick={onSendClicked}
                            disabled={!sendEnabled}
                            isPrimary>
                        Send
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )
}