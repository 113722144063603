import { e164ToPhone } from "../utils/phone";

export type UserInstance = {
    id: string;
    image_uri: string;
    image_abs_uri?: string;
    name: string;
    bio: string;
    type: string;
    plan: string;
    industry: string;
    edition: string;
    edition_allow_basic_plan: boolean;
    always_collect_tax: boolean;
    allow_salestax: boolean;
    allow_surcharge: boolean;
    allow_group: boolean;
    allow_reminder: boolean;
    allow_template: boolean;
    allow_class: boolean;
    allow_category: boolean;
    allow_package: boolean;
    allow_subscription: boolean;
    allow_lead: boolean;
    allow_contract: boolean;
    allow_widget: boolean;
    allow_chat: boolean;
    allow_link: boolean;
    allow_record: boolean;
    allow_discount: boolean;
    allow_staffing: boolean;
    allow_estimate: boolean;
    allow_recurring: boolean;
    allow_services: boolean;
    allow_gratuity: boolean;
    allow_reservation: boolean;
    allow_product: boolean;
    allow_itemize: boolean;
    allow_benchmark: boolean;
    allow_task: boolean;
    allow_gift: boolean;
    allow_drip: boolean;
    allow_billing: boolean;
    allow_mark: boolean;
    allow_booking: boolean;
    allow_invoice: boolean;
    allow_reader: boolean;
    allow_payment: boolean;
    allow_paypal: boolean;
    allow_trigger: boolean;
    allow_post: boolean;
    allow_bnpl: boolean;
    allow_resource: boolean;
    channel: string;
    e164?: string;
    instant_payout: boolean;
    location: boolean;
    address: string;
    line1: string;
    line2: string;
    city: string;
    state: string;
    postal_code: string;
    taxcode: string;
    tax_address: string;
    employer: string;
    role: string;
    receipt_policy: string;
    booking_policy: string;
    invoice_policy: string;
    invoice_reminder: string;
    estimate_policy: string;
    phone: string;
    sms?: string;
    email: string;
    subscribed_date?: string;
    first_payment_date?: string;
    signup_date?: string;
    mon_start_hour: string;
    tue_start_hour: string;
    wed_start_hour: string;
    thu_start_hour: string;
    fri_start_hour: string;
    sat_start_hour: string;
    sun_start_hour: string;
    mon_end_hour: string;
    tue_end_hour: string;
    wed_end_hour: string;
    thu_end_hour: string;
    fri_end_hour: string;
    sat_end_hour: string;
    sun_end_hour: string;
    schedule?: number;
    cancellation_duration: string;
    cancellation_rate: string;
    cancellation_rate_type: string;
    subscription_cancellation_rate: string;
    subscription_cancellation_rate_type: string;
    package_cancellation_type: string;
    cancellation_policy: string;
    confirm_payment: string;
    request_gratuity: string;
    gratuity: string;
    minimum_gratuity: string;
    gratuity_options: string[];
    onboarding_state?: string;
    requires_upgrade: boolean;
    last_sync_date: string;
    featurecap: number;
    allow_priority_support: boolean;
    reminder: number;
    username: string;
    key: string;
}

/*
export class User {
    id: string;
    name: string;
    type: string;
    plan: string;
    industry: string;
    allow_salestax: boolean;
    allow_surcharge: boolean;
    allow_group: boolean;
    allow_reminder: boolean;
    allow_template: boolean;
    allow_class: boolean;
    allow_category: boolean;
    allow_package: boolean;
    allow_subscription: boolean;
    allow_lead: boolean;
    allow_contract: boolean;
    allow_widget: boolean;
    allow_chat: boolean;
    allow_link: boolean;
    allow_record: boolean;
    allow_discount: boolean;
    allow_staffing: boolean;
    allow_estimate: boolean;
    allow_recurring: boolean;
    allow_services: boolean;
    allow_gratuity: boolean;
    allow_reservation: boolean;
    allow_product: boolean;
    allow_itemize: boolean;
    allow_benchmark: boolean;
    allow_task: boolean;
    allow_gift: boolean;
    allow_drip: boolean;
    allow_billing: boolean;
    allow_booking: boolean;
    allow_invoice: boolean;
    allow_reader: boolean;
    allow_payment: boolean;
    allow_paypal: boolean;
    allow_trigger: boolean;
    channel: string;
    instant_payout: boolean;
    location: boolean;
    address: string;
    line1: string;
    line2: string;
    city: string;
    state: string;
    taxcode: string;
    tax_address: string;
    employer: string;
    role: string;
    receipt_policy: string;
    booking_policy: string;
    invoice_policy: string;
    estimate_policy: string;
    phone: string;
    sms?: string;
    email: string;
    subscribed_date?: string;
    mon_start_hour: number;
    tue_start_hour: number;
    wed_start_hour: number;
    thu_start_hour: number;
    fri_start_hour: number;
    sat_start_hour: number;
    sun_start_hour: number;
    mon_end_hour: number;
    tue_end_hour: number;
    wed_end_hour: number;
    thu_end_hour: number;
    fri_end_hour: number;
    sat_end_hour: number;
    sun_end_hour: number;
    schedule?: number;
    onboarding_state?: string;

    constructor(data: Partial<UserInstance>) {
        Object.assign(this, data)
        if (data.mon_start_hour) this.mon_start_hour = parseFloat(data.mon_start_hour)
    }
}
*/

export const userDescription = (user: Partial<UserInstance>) => {
    const { state, city, phone, email } = user

    if (!!state && !!city)
        return `${city}, ${state}`
    else if (!!phone)
        return `${e164ToPhone(phone)}`
    else if (!!email)
        return `${email}`
    else
        return ''
}
