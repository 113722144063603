import React from "react";
import {currencyFormat} from "../../utils/numbers";
import {dashboardTagColors} from "../../utils/colors";
import {useAppDispatch} from "../../hooks";
import {push} from "connected-react-router";
import {adjustmentTypeTitle, InventoryAdjustment} from "../../models/InventoryAdjustment";
import { Link } from "react-router-dom";
import moment from "moment";

type Props = {
    record: InventoryAdjustment
}

export const HistoryCard = ({record}: Props) => {
    const dispatch = useAppDispatch()
    const title = `Quantity: ${record.adjustmentSign() < 0 ? '-' : '+'}${record.quantity}`;
    const subtitle = moment(record.created_date).format('MMM D, YYYY');
    const tagColors = record.adjustmentSign() === 1 ? dashboardTagColors.green : dashboardTagColors.red;
    const tagText = adjustmentTypeTitle(record.type);
    const text = currencyFormat(record.total);

    const clickHandler = (e: any) => {
        e.preventDefault();
        dispatch(push('/inventory/edit', {'adjustment':record}));
    }

    return (
        <div className="card">
            <div className="titleArea">
                <div className="title">{title}</div>
                <div className="subtitle">{subtitle}</div>
            </div>
            <div className="right">
                <div className="tag" style={{backgroundColor: tagColors?.bg, color: tagColors?.fg}}>
                    {tagText}
                </div>
                <div className="text">{text}</div>
                <Link to='.' onClick={clickHandler}> View </Link>
            </div>
        </div>
    )
}