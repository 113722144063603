import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ConversationThreadView } from './ConversationThreadView'
import { InboxView } from './InboxView'
import { MessageInputBar } from './MessageInputBar'
import { NotificationBanner } from '../app/NotificationBanner'
import { NewConversation } from './NewConversation'
import { useSelector } from 'react-redux'

import './messages.css'
import { ConversationGroupEdit } from './ConversationGroupEdit'

const InboxWrapper = styled('div')`
  display: flex;
  flex: 1;
  max-width: 440px;
  min-height: 100px;
`

const ThreadWrapper = styled('div')`
  display: flex;
  flex: 2;
  flex-direction: column;
`

export const MessageView = (props) => {
    const state = useSelector(state => state.conversation)

    const [inboxHeight, setInboxHeight] = useState(window.innerHeight-32)
    useEffect(() => {
        const handleResize = () => {
            setInboxHeight(window.innerHeight-32)
        }
        window.addEventListener('resize', handleResize)

        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    })

    let conversationView
    if (state.showCreateGroup) {
        conversationView = <ConversationGroupEdit inboxHeight={inboxHeight}  />
    }
    else if (state.showNewMessage) {
        conversationView = <NewConversation inboxHeight={inboxHeight}  />
    }
    else {
        conversationView = (
            <React.Fragment>
                <ConversationThreadView inboxHeight={inboxHeight} />
                {state.currentThread && (
                    <MessageInputBar />
                )}
            </React.Fragment>
        )
    }

    return (
        <div style={{display: 'flex', minHeight: 'min-content'}}>
            <NotificationBanner />
            <InboxWrapper>
                <InboxView inboxHeight={inboxHeight} />
            </InboxWrapper>
            <ThreadWrapper>
                {conversationView}
            </ThreadWrapper>
        </div>
    )
}