import React from 'react'
import { useSelector } from 'react-redux'
import { Spinner } from '@zendeskgarden/react-loaders'
import { ScheduleEvent } from './ScheduleEvent'
import PropType from 'prop-types'

export const CalendarSearch = (props) => {
    const state = useSelector(state => (
        {
            calendar: state.calendar,
            entities: state.entities
        }
    ))

    let loadingIndicator
    if (state.calendar.isSearching) {
        loadingIndicator = (
            <div style={{textAlign: 'center', padding: '128px 0'}}>
                <Spinner size={128} color="#314A68" />
            </div>
        )
    }

    return (
        <div className="calendarSchedule">
            <div className="header">
                <div className="dow">{state.calendar.searchText}</div>
            </div>
            <div className="calendarBody" style={{height: `${props.height}px`}}>
                {loadingIndicator}
                {!state.calendar.isSearching && state.calendar.searchResults.map((result) => {
                    return <ScheduleEvent key={`calendar-result-${result.data.id}`}
                                          showDate={true}
                                          event={result} />
                })}
                {state.calendar.didSearch && !state.calendar.isSearching && state.calendar.searchResults.length === 0 && (
                    <div className="noResults">No matching entries found</div>
                )}
            </div>
        </div>
    )
}

CalendarSearch.props = {
    height: PropType.number,
}
