import React, {RefObject} from "react";
import {ReactComponent as AddPhotoImage} from "../../icons/add_photo.svg";
import {PSButton} from "../app/PSButton";

type Props = {
    photo: any,
    selectPhoto: () => void,
    selectedPhoto: () => void,
    addPhotoRef: RefObject<HTMLInputElement>,
    title: string,
}

export const SettingsPhotoField = ({photo, selectPhoto, selectedPhoto, addPhotoRef, title}: Props) => {
    return (
        <>
            <div className="image">
                {!photo && (
                    <AddPhotoImage style={{cursor: 'pointer'}}
                                   onClick={() => selectPhoto()} />
                )}
                {photo && typeof photo === 'string' && (
                    <img alt={title} src={photo} />
                )}
                {photo && typeof photo !== 'string' && (
                    <img alt={title} src={URL.createObjectURL(photo)} />
                )}
            </div>
            <div>
                <PSButton
                    onClick={() => selectPhoto()}
                    style={{borderRadius: '20px', fontWeight: 700}}>
                    {photo && "Edit photo"}
                    {!photo && "Add a photo"}
                </PSButton>
                <input type="file"
                       ref={addPhotoRef}
                       onChange={selectedPhoto}
                       accept="image/png,image/jpg,image/jpeg"
                       style={{display: 'none'}} />
            </div>
        </>
    )
}