import React, {useCallback, useEffect, useMemo, useState} from "react";
import {AppHeader} from "../../app/AppHeader";
import {Redirect, useHistory, useLocation, useParams} from "react-router";
import {TipType} from "../../../models/Transaction";
import "../transactions.css";
import {PSButtonPrimary, PSButtonSecondary} from "../../app/PSButton";
import {currencyFormat} from "../../../utils/numbers";
import {TransactionProgress} from "../TransactionProgress";
import {ReceiptMemoModal} from "../charge/ReceiptMemoModal";
import { useDispatch } from "react-redux";
import { push, replace } from "connected-react-router";
import { describePackage, Package } from "../../../models/Package";
import { savePackage, sendPackage } from "../../../modules/transactions";
import { Link } from "react-router-dom";
import { Result } from "../../../utils";

export const PackageSummary = () => {

    const location = useLocation()
    const locationState: {package: Partial<Package>, client: any} = location.state as {package: Partial<Package>, client: any}
    const params: { id?: string } = useParams()

    const _package = useMemo(() => new Package(locationState?.package || {} as Partial<Package>), [locationState?.package])
    const client = locationState?.client

    const history = useHistory()
    const dispatch = useDispatch()

    const [discountType, setDiscountType] = useState<TipType>()
    const [discountAmount, setDiscountAmount] = useState<string>()
    const [surchargeEnabled, setSurchargeEnabled] = useState(false)
    const [memo, setMemo] = useState('')

    const [showMemoModal, setShowMemoModal] = useState(false)

    const [discountDescription, setDiscountDescription] = useState('')

    useEffect(() => {
        setDiscountType(_package.discount_type)
        setDiscountAmount(_package.discount)
        setSurchargeEnabled(_package.allow_surcharge || false)
    }, [_package])

    useEffect(() => {
        if (_package._gift?.item_name) {
            setDiscountDescription(_package._gift.item_name)
        }
        else if (discountType === 'rate') {
            setDiscountDescription(`Discount (${currencyFormat(discountAmount)})`)
        }
        else if (discountType === 'percent' && Number(discountAmount) > 0) {
            // TODO discountAmount can go from 10 to 9.99997743 (floating arithmetic issue)
            setDiscountDescription(`Discount (${discountAmount}%)`)
        }
        else {
            setDiscountDescription('')
        }
    }, [discountType, discountAmount, _package])

    const onBack = useCallback(
        () => history.replace(params?.id ? `/package/${params.id}/edit` : '/package/new', {package: _package}),
    [history, _package, params.id])

    const onSave = useCallback(() => {
        Result(dispatch(savePackage({ ..._package, id: params.id, client: client.id })))
            .then((_package: Partial<Package>) =>
                    dispatch(replace(`/package/success/${_package.id}`, { client })))
    }, [_package, params.id, client, dispatch])

    const onSend = useCallback((chargeAfterSend: boolean) => {
        Result(dispatch(sendPackage({ ..._package, id: params.id, client: client.id }))).then(
            (_package: Partial<Package>) => {
                return chargeAfterSend ?
                    dispatch(replace(`/charge/package/${_package.id}`, { package: _package, summary: true })) :
                    dispatch(push(`/package/success/${_package.id}`, { client }))
                })
    }, [_package, params.id, client, dispatch])

    const totalDisplay = useCallback(() => {
        return Number(_package?.calculateChargeAmount()) > 0 ? `${currencyFormat(_package!.calculateChargeAmount())} Total` :
        _package?.total === undefined ? "Price not set" :
            "Free"
    }, [_package])

    if (!_package) {
        return <Redirect to="/package/new" />
    }

    const taxPercentage = Number(_package.tax ?? 0)
    return (
        <AppHeader showBackButton title="Packages"
            middleWidget={<TransactionProgress created reviewed secondStep='Preview' thirdStep='Save' />}
            onBack={onBack}
        >
            <div className="scheduleClient servicesSettings ">
                <div className="header">
                    <h1>Review</h1>
                </div>

                <div className="separator" />

                <div className="scheduleClientReview">
                    <div className="details">
                        <div className="receipt">
                            <div className="total">{totalDisplay()}</div>
                            <div className="final" style={{textAlign: 'center', marginTop: '10px', marginBottom: '10px'}}>
                                <p style={{marginBottom: '10px'}}>{_package.name}</p>
                                <p>{describePackage(_package, _package.capacity)}</p>
                            </div>
                            <div style={{maxWidth:'245px', margin: 'auto'}}>
                                <div className="lineItem final">
                                    <div className="name">Subtotal</div>
                                    <div className="amount">{currencyFormat(_package.total)}</div>
                                </div>
                                {Number(_package.discount) > 0 && (
                                    <div className="lineItem">
                                        <div className="name">{discountDescription}</div>
                                        <div className="amount">-{currencyFormat(_package.calculateDiscount())}</div>
                                    </div>
                                )}
                                {Number(_package.deposit) > 0 && (
                                    <div className="lineItem">
                                        <div className="name">Deposit</div>
                                        <div className="amount">{currencyFormat(_package.deposit)}</div>
                                    </div>
                                )}
                                {taxPercentage > 0 && (
                                    <div className="lineItem">
                                        <div className="name">Sales tax ({taxPercentage}%)</div>
                                        <div className="amount">{currencyFormat(_package.calculateTax())}</div>
                                    </div>
                                )}
                                {surchargeEnabled && (
                                    <div className="lineItem">
                                        <div className="name">Surcharge (3%)</div>
                                        <div className="amount">{currencyFormat(_package.calculateSurcharge())}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="actionsWrapper">
                        <div className="actions">
                            {!!_package.channel && <>
                                <PSButtonPrimary onClick={() => onSend(false)}>Send</PSButtonPrimary>
                                <PSButtonSecondary onClick={onSave}>Save</PSButtonSecondary>
                            </>}
                            {!_package.channel &&
                                <PSButtonPrimary onClick={onSave}>Save</PSButtonPrimary>
                            }
                            {!_package.paid_date && <PSButtonSecondary onClick={() => onSend(true)}>{_package.calculateChargeAmount() ? 'Charge' : 'Mark paid'}</PSButtonSecondary>}
                            {_package.paid_date && params.id && <PSButtonSecondary onClick={onBack}>Cancel</PSButtonSecondary>}
                        </div>
                    </div>

                    <div className="infoWrapper">
                            <div className="item flex">
                                <div className="data">
                                    <div className="bold">Client</div>
                                    <div className="selected">{client?.name}</div>
                                    <div className="grey2Text">Requires payment</div>
                                </div>
                                <div className="action">
                                    <Link to={`/record/client/${client?.id}`}>View</Link>
                                </div>
                            </div>
                        {!!_package.memo && (
                            <div className="item">
                                <div className="bold">Statement memo</div>
                                <div className="grey2Text">{_package.memo}</div>
                            </div>
                        )}
                    </div>
                </div>
                {showMemoModal && (
                    <ReceiptMemoModal currentData={{memo}}
                                      onDone={(memo) => {
                                          setMemo(memo)
                                          setShowMemoModal(false)
                                      }}
                                      onCancel={() => setShowMemoModal(false)}
                    />
                )}
            </div>
        </AppHeader>
    )
}