import { Dropdown, Item, Menu, Select, Field } from "@zendeskgarden/react-dropdowns";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LineItem } from "../../../models/LineItem";
import { SettingsState } from "../../../modules/settings";
import { RootState } from "../../../store";
import { currencyFormat } from "../../../utils/numbers";
import { numberOptions } from "../../settings/Options";

type Props = {
    line: LineItem,
    index: number,
    onDeleted: (index: number) => void,
    onEdit: (index: number) => void,
    onQuantityChanged: (index: number, quantity: number) => void
}

export const ChargeSummaryItemCard = ({line, index, onDeleted, onEdit, onQuantityChanged}: Props) => {
    const [name, setName] = useState<string>()
    const [quantity, setQuantity] = useState(1)

    const state: SettingsState = useSelector((state: RootState) => state.settings)
    useEffect(() => {
        setName(line.item ? state.items.find(i => i.id === line.item)?.name : "Custom amount")
    }, [line.item, state.items])

    useEffect(() => {
        setQuantity(line.quantity)
    }, [line.quantity])

    const quantityOptions = numberOptions(30)
    return (
        <div className='itemSummaryCard'>
            <div className="row">
                <div className="description">
                    {name}
                </div>
                <div className="cost">
                    {currencyFormat(Number(line.rate) * Number(line.quantity))}
                </div>
            </div>
            <div className="row">
                <div className="quantity">
                    <span className="text">Quantity:</span>
                    <Dropdown selectedItem={quantity}
                        onSelect={(quantity) => {
                            onQuantityChanged(index, quantity)
                            setQuantity(quantity)
                        }}>
                            <Field className="field">
                                <Select className="input">
                                    {quantity}
                                </Select>
                            </Field>
                        <Menu className="list">
                            {quantityOptions.map(option => (
                                <Item key={`quantity-${option.value}`} value={Number(option.value)}>
                                    {option.label}
                                </Item>
                            ))}
                        </Menu>
                </Dropdown>

                </div>
                <div className="edit">
                    <div className="buttonLink" onClick={() => onDeleted(index)}>Delete</div>
                    <span>|</span>
                    <div className="buttonLink" onClick={() => onEdit(index)}>Edit</div>
                </div>
            </div>
        </div>
    )
}