// @ts-nocheck
import React, {useEffect, useState} from "react";
import * as numbers from "../../utils/numbers";
import {
    Crosshair,
    LabelSeries,
    LineSeries,
    VerticalBarSeries,
    XAxis,
    XYPlot
} from "react-vis";
import {useAppDispatch} from "../../hooks";
import {
    dashboardSetOptions,
    DashboardState,
    getDashboard
} from "../../modules/dashboard";
import {useDashboardLocation} from "../../models/Dashboard"
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import styled from "styled-components";
import {AppHeader} from "../app/AppHeader";
import {ReactComponent as PocketSuiteLogo} from "../../icons/PocketSuite.svg";
import {PSDropdown} from "../app/PSDropdown";
import {
    futureIncomeDateFilterOptions,
    incomeDateFilterOptions,
    SelectOption
} from "../settings/Options";
import {Item} from "@zendeskgarden/react-dropdowns";
import {ButtonGroup} from "@zendeskgarden/react-buttons";
import {PSButton} from "../app/PSButton";
import {Spinner} from "@zendeskgarden/react-loaders";
import {ItemTable} from "./incometable/ItemTable";
import {DateTable} from "./incometable/DateTable";
import {ClientTable} from "./incometable/ClientTable";
import {generateCancelToken} from "../../modules/apiclient";
import {lookupIndustryTitle} from "../../models/Industry";
import localforage from "localforage";
import {Redirect} from "react-router";

const GraphTooltip = styled('div')`
  position: absolute;
  font-size: 11px;
  pointer-events: none;
  border-radius: 4px;
  background: #3a3a48;
  color: #fff;
  padding: 7px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  text-align: left;
`

export const DashboardIncome = () => {
    const dispatch = useAppDispatch()
    const state: DashboardState = useSelector((state: RootState) => state.dashboard)
    const locationState = useDashboardLocation()

    const [user, setUser] = useState<any>()
    useEffect(() => {
        localforage.getItem('user').then((user: any) => setUser(user))
    }, [])

    const [industryTitle, setIndustryTitle] = useState('')
    useEffect(() => {
        if (!user) return
        lookupIndustryTitle(user.industry).then((s: string) => setIndustryTitle(s))
    }, [user])

    const [periodOptions, setPeriodOptions] = useState(incomeDateFilterOptions)
    const [periodFilter, setPeriodFilter] = useState<SelectOption | null>(incomeDateFilterOptions[1])
    const graphOptions: SelectOption[] = [
        { value: 'income', label: 'Income' },
        { value: 'receivables', label: 'Future income' },
        { value: 'salestax', label: 'Sales tax' },
        { value: 'refunds', label: 'Refunds' },
    ]
    const [graphFilter, setGraphFilter] = useState(graphOptions[0])

    useEffect((): any => {
        if (periodFilter === null) return

        const cancelToken = generateCancelToken()
        dispatch(getDashboard(periodFilter.value, graphFilter.value, state.topList, cancelToken.token, locationState?.client?.id))
        return (_: any) => {
            cancelToken.cancel()
        }
    }, [dispatch, periodFilter, graphFilter, state.topList, locationState?.client])

    useEffect(() => {
        if (graphFilter.value === 'receivables') {
            setPeriodFilter(null)
            setPeriodOptions(futureIncomeDateFilterOptions)
            setPeriodFilter(futureIncomeDateFilterOptions[1])
        }
        else {
            setPeriodFilter(null)
            setPeriodOptions(incomeDateFilterOptions)
            setPeriodFilter(locationState?.dateFilter ?
                incomeDateFilterOptions.find(option => option.value === locationState.dateFilter) ?? incomeDateFilterOptions[1] :
                incomeDateFilterOptions[1])
        }
    }, [graphFilter, locationState?.dateFilter])

    const [graphWidth, setGraphWidth] = useState(Math.min(944, window.innerWidth-312))
    useEffect((): any => {
        const handleResize = () => {
            setGraphWidth(Math.min(944, window.innerWidth-312))
        }
        window.addEventListener('resize', handleResize)

        return (_: any) => {
            window.removeEventListener('resize', handleResize)
        }
    })

    // State for crosshair values in the graph
    const [ crosshairValue, setCrosshairValue ] = useState<any>()
    const onGraphMouseLeave = () => {
        setCrosshairValue(null)
    }
    const onGraphNearestX = (value: any) => {
        setCrosshairValue(value)
    }

    const changeTopList = (newTopList: string) => {
        dispatch(dashboardSetOptions(state.period, newTopList))
    }

    let incomeTable
    switch (state.topList) {
        case 'item':
        case 'source':
            incomeTable = <ItemTable />
            break
        case 'date':
            incomeTable = <DateTable />
            break
        case 'client':
        case 'employee':
        default:
            incomeTable = <ClientTable />
            break
    }

    const titleWidget = (
        <div style={{padding: '15px 0 15px 26px'}}>
            <PocketSuiteLogo />
            <div style={{color: '#25496B', opacity: 0.4, lineHeight: '14px', fontSize: '13px'}}>
                {industryTitle && (
                    `${industryTitle} Edition`
                )}
            </div>
        </div>
    )

    // Make sure the user has permission to see the income report
    const showIncome = localStorage.getItem('income') === '1'
    if (!showIncome) {
        return <Redirect to="/" />
    }

    return (
        <div className="dashboard">
            <AppHeader titleWidget={titleWidget} middleWidget={null} showBackButton>
                <div className="incomeView">
                    <div className="header">
                        <h2>Income</h2>
                        <div className="dateFilter">
                            <PSDropdown selected={periodFilter}
                                        nameProperty="label"
                                        onSelect={(selection) => setPeriodFilter(selection)}
                                        selectWidth="200px"
                                        label="">
                                <>
                                    {periodOptions.map(option => (
                                        <Item key={`period-option-${option.value}`} value={option}>
                                            {option.label}
                                        </Item>
                                    ))}
                                </>
                            </PSDropdown>
                        </div>
                        <PSDropdown selected={graphFilter}
                                    nameProperty="label"
                                    onSelect={(selection) => setGraphFilter(selection)}
                                    selectWidth="200px"
                                    label="">
                            <>
                                {graphOptions.map(option => (
                                    <Item key={`income-filter-${option.value}`} value={option}>
                                        {option.label}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>
                    </div>
                    <div className="wrapper">
                        {state.isSending && (
                            <div className="loading">
                                <Spinner size="128" color="#314A68" />
                            </div>
                        )}
                        {!state.isSending && state.metrics.graphData.length > 0 && (
                            <>
                                <div className="incomeBox">
                                    <div className="info">
                                        <h4>Income</h4>
                                        <div className="detail income">
                                            {numbers.abbreviatedDollar(state.metrics.total.income)}
                                        </div>
                                        <div className="incomeDelta">
                                            {numbers.decoratedDelta(state.metrics.total.income_delta)}
                                        </div>
                                    </div>
                                    <div className="info">
                                        <h4># of Payments</h4>
                                        <div className="detail">
                                            {state.metrics.total.count}
                                        </div>
                                    </div>
                                </div>
                                <XYPlot
                                    xType="ordinal"
                                    width={graphWidth}
                                    height={340}
                                    margin={{top: 30}}
                                    onMouseLeave={onGraphMouseLeave}
                                >
                                    <VerticalBarSeries
                                        data={state.metrics.graphData}
                                        color="#62ce9a"
                                        onNearestX={onGraphNearestX}
                                        barWidth={0.85}
                                    />
                                    <LineSeries
                                        data={[
                                            {x: state.metrics.firstLabel, y: state.metrics.maxValue},
                                            {x: state.metrics.lastLabel, y: state.metrics.maxValue},
                                        ]}
                                        strokeDasharray={[3,3]}
                                        strokeWidth="0.57px"
                                        color="#62ce9a"
                                    />
                                    <LabelSeries
                                        data={[{
                                            x: state.metrics.firstLabel,
                                            y: Math.round(state.metrics.maxValue),
                                            label: `$${Math.round(state.metrics.maxValue).toLocaleString('en-US')}`,
                                            yOffset: -20,
                                        }]}
                                    />
                                    <XAxis style={{text: {fill: '#999999'} }} tickSize={0} />
                                    {crosshairValue && (
                                        <Crosshair values={[crosshairValue]}>
                                            <GraphTooltip>
                                                <p>{crosshairValue.x}: {(crosshairValue.y).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</p>
                                            </GraphTooltip>
                                        </Crosshair>
                                    )}
                                </XYPlot>
                            </>
                        )}
                    </div>
                    <ButtonGroup className="groupButtonGroup"
                                 onSelect={changeTopList}
                                 selectedItem={state.topList}>
                        <PSButton size="small" value="client">Client</PSButton>
                        <PSButton size="small" value="item">Item</PSButton>
                        <PSButton size="small" value="date">Date</PSButton>
                        <PSButton size="small" value="employee">Pro</PSButton>
                        <PSButton size="small" value="source">Source</PSButton>
                    </ButtonGroup>
                    <div className="tableWrapper">
                        {state.isSending && (
                            <div className="loading">
                                <Spinner size="128" color="#314A68" />
                            </div>
                        )}
                        {!state.isSending && (
                            <>{incomeTable}</>
                        )}
                    </div>
                </div>
            </AppHeader>
        </div>
    )
}