import React, {useCallback, useEffect, useState} from "react";
import {useAppDispatch} from "../../../../hooks";
import {
    addField, deleteField,
    fetchForm, removeField,
    saveField, setForm,
    SettingsState, updateField
} from "../../../../modules/settings";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {useHistory, useParams} from "react-router";
import {PSButton, PSButtonDanger, PSButtonPrimary} from "../../../app/PSButton";
import {ReactComponent as LeftArrow} from "../../../../icons/left-arrow.svg";
import {ReactComponent as PlusIcon} from "../../../../icons/ps_general_plus_dark.svg";
import {Checkbox, Field, Hint, Input, Label} from "@zendeskgarden/react-forms";
import {fieldQuestionTypeOptions, SelectOption} from "../../Options";
import {PSDropdown} from "../../../app/PSDropdown";
import {Item} from "@zendeskgarden/react-dropdowns";
import {ReactComponent as GripIcon} from "../../../../icons/grip.svg";
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult
} from "react-beautiful-dnd";
import {Field as PSField, FieldType} from "../../../../models/Field";
import {Option as PSOption} from "../../../../models/Option";
import {GuardrailModal} from "../../GuardrailModal";
import {
    updateIntake,
    updateForm
} from "../../../../modules/onboarding";
import {Result} from "../../../../utils";
import {push} from "connected-react-router";

type Props = {
    editField?: PSField,
    forOnboarding?: boolean,
    forIntake?: boolean,
    forClient?: boolean,
    hideBackButton?: boolean,
    onSave?: () => void,
}

export const FormQuestionBuilder = ({editField, forOnboarding, forIntake, forClient, hideBackButton, onSave}: Props) => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)
    const history = useHistory()
    const params: any = useParams()

    const [saveEnabled, setSaveEnabled] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    const [label, setLabel] = useState('')
    const [type, setType] = useState<SelectOption | ''>('')
    const [required, setRequired] = useState(true)
    const [showOnline, setShowOnline] = useState(false)
    const [isClient, setIsClient] = useState(false)
    const [isBooking, setIsBooking] = useState(false)
    const [choices, setChoices] = useState<string[]>([''])
    const [showChoices, setShowChoices] = useState(false)

    useEffect(() => {
        setLabel(editField?.label ?? '')
        const selectedType = fieldQuestionTypeOptions.find(o => o.value === editField?.type)
        setType(selectedType ?? '')
        setRequired(editField?.is_required ?? true)
        setShowOnline(editField?.is_public ?? false)
        setIsClient(editField?.is_client ?? false)
        setIsBooking(editField?.is_booking ?? false)
        setChoices(editField?.options?.map(o => o.label) ?? [''])
    }, [editField])

    useEffect(() => {
        if (!params.fieldID || forOnboarding || forClient) {
            return
        }

        // If the question and field is passed in, checked if it is already loaded
        if (state.form.id === params.formID) {
            // TODO Make sure state.form.fields has this field by an ID check
            return
        }

        dispatch(fetchForm(params.formID))
    }, [dispatch, params.fieldID, params.formID, state.form, forOnboarding, forClient])

    useEffect(() => {
        if (forOnboarding || forClient) return

        const fields = params.formID ? state.form.fields : state.fields
        let psField: PSField

        if (params.formID) {
            // Edit a saved question
            psField = fields?.find(f => f.id === params.fieldID) || new PSField()
        }
        else {
            // Edit an ephemeral question (form is not saved yet)
            psField = fields![params.fieldID]
        }

        if (psField) {
            setLabel(psField.label)
            setRequired(psField.is_required)
            setShowOnline(psField.is_public)
            setIsClient(psField.is_client)
            setIsBooking(psField.is_booking)
            setChoices(psField.options.map(o => o.label))
            const selectedType = fieldQuestionTypeOptions.find(o => o.value === psField.type)
            setType(selectedType || '')
        }
    }, [state.form, state.fields, params.formID, params.fieldID, forOnboarding, forClient])

    useEffect(() => {
        if (type && (type.value === 'select' || type.value === 'multiselect'))
            setSaveEnabled(!!label && !!type && choices.length > 0 && !!choices[0].trim())
        else
            setSaveEnabled(!!label.trim() && !!type)
    }, [label, type, choices])

    useEffect(() => {
        const b = !!(type && (type.value === 'select' || type.value === 'multiselect'))
        if (!showChoices && b && choices.length === 0) {
            setChoices([''])
        }
        setShowChoices(b)
    }, [type, choices, showChoices])

    const addNewChoice = () => {
        const choicesCopy = choices.slice()
        choicesCopy.push('')
        setChoices(choicesCopy)
    }

    const setChoice = (value: string, index: number) => {
        if (choices.length >= index +1) {
            const choicesCopy = choices.slice()
            choicesCopy[index] = value
            setChoices(choicesCopy)
        }
    }

    const removeChoice = (index: number) => {
        const choicesCopy = choices.slice()
        choicesCopy.splice(index, 1)
        setChoices(choicesCopy)
    }

    const onDragEnd = useCallback((result: DropResult) => {
        if (!result.destination) return

        const [removed] = choices.splice(result.source.index, 1)

        choices.splice(result.destination.index, 0, removed)
        setChoices(choices)
    }, [choices])

    const onSaveQuestion = () => {
        if (type === '') return

        const field = new PSField()
        field.id = params.fieldID
        field.record = params.formID
        field.label = label
        field.type = type.value as FieldType
        field.is_required = required
        field.is_public = showOnline
        field.is_client = isClient || !!forClient
        field.is_booking = isBooking
        choices.forEach((choice, index) => {
            const option = new PSOption()
            option.seqnum = index
            option.label = choice
            field.options.push(option)
        })

        if (forOnboarding) {
            if (forIntake) {
                // For the client intake form, so we don't have a form to edit, just the fields
                field.id = editField?.id
                dispatch(updateIntake(field))
                history.goBack()
                return
            }

            // Update the form with the modified field
            field.id = editField?.id
            const fieldIndex = state.form.fields!.findIndex(f => f.id === field.id)
            if (fieldIndex > -1)
                state.form.fields![fieldIndex] = field
            dispatch(setForm(state.form))
            dispatch(updateForm(state.form))
            history.goBack()
            return
        }

        if (params.formID || forClient) {
            // Send it to the server
            Result(dispatch(saveField(field, !!params.formID)))
                .then(() => {
                    if (forClient){
                        dispatch(push('/settings/leads/questions'))
                    }
                })
        }
        else {
            // Keep it in state, so we can send it when the form is created
            if (params.fieldID)
                dispatch(updateField(field, params.fieldID))
            else
                dispatch(addField(field))
            history.goBack()
        }
    }

    const onDeleteQuestion = () => {
        if (params.formID || forClient)
            Result(dispatch(deleteField(params.fieldID, !!params.formID)))
                .then(() => {
                    if (forClient){
                        dispatch(push('/settings/leads/questions'))
                    }
                })
        else {
            dispatch(removeField(params.fieldID))
            history.goBack()
        }
    }

    return (
        <div className="servicesSettings">
            <div className="header">
                {!hideBackButton && (
                    <PSButton style={{marginLeft: 0, marginRight: 16}}
                              onClick={() => history.goBack()}
                    >
                        <LeftArrow />
                    </PSButton>
                )}
                <h1>Question builder</h1>
                {params.fieldID && (
                    <PSButtonDanger style={{height: '40px'}}
                                    onClick={() => setShowDeleteConfirmation(true)}>
                        Delete question
                    </PSButtonDanger>
                )}
                <PSButtonPrimary style={{height: '40px', marginLeft: params.fieldID ? '16px' : 'auto'}}
                                 onClick={() => onSaveQuestion()}
                                 disabled={!saveEnabled}>
                    Save
                </PSButtonPrimary>
            </div>
            <div className="separator" />
            <div className="form">
                <Field className="field">
                    <Label className="label">Question (required)</Label>
                    <Input placeholder="Add question"
                                disabled={state.isSending}
                                value={label}
                                maxLength={199}
                                onChange={e => setLabel(e.target.value)} />
                </Field>

                <PSDropdown selected={type}
                            nameProperty="label"
                            disabled={!!params.fieldID || state.isSending || forOnboarding}
                            onSelect={(selection) => setType(selection)}
                            label="Question type (required)"
                            placeholder={<div className="grey">Select question type</div>}>
                    <>
                        {fieldQuestionTypeOptions.map(option => (
                            <Item key={`question-type-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>

                <Field className="field">
                    <Checkbox checked={required}
                              disabled={state.isSending}
                              onChange={e => setRequired(e.target.checked)}>
                        <Label className="withHint">Required</Label>
                        <Hint>Is this question required on this form?</Hint>
                    </Checkbox>
                </Field>

                {forClient && <Field className="field">
                    <Checkbox checked={showOnline}
                              disabled={state.isSending}
                              onChange={e => setShowOnline(e.target.checked)}>
                        <Label>Show online</Label>
                    </Checkbox>
                </Field>}

                {forClient && !editField && <Field className="field">
                    <Checkbox checked={isBooking}
                              disabled={state.isSending}
                              onChange={e => setIsBooking(e.target.checked)}>
                        <Label>Show on appointment</Label>
                    </Checkbox>
                </Field>}

                {editField?.record && (
                    <Field className="field">
                        <Checkbox checked={showOnline}
                                  disabled={state.isSending}
                                  onChange={e => setShowOnline(e.target.checked)}>
                            <Label className="withHint">Show online</Label>
                            <Hint>Enable if you'd like clients to fill out this question</Hint>
                        </Checkbox>
                    </Field>
                )}

                {showChoices && (
                    <>
                        <div className="separator" />
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(droppableProps: any) => {
                                    return (
                                        <div ref={droppableProps.innerRef}>
                                            {choices.map((choice, index) => {
                                                return (
                                                    <Draggable key={`choice-${index}`}
                                                               draggableId={`${choice}-${index}`}
                                                               index={index}>
                                                        {(provided: any, snapshot: any) => (
                                                            <div ref={provided.innerRef}
                                                                 {...provided.draggableProps}
                                                                 {...provided.dragHandleProps}
                                                            >
                                                                <Field className="field">
                                                                    <Label>Choice {index + 1}</Label>
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'row'
                                                                    }}>
                                                                        <div
                                                                            style={{flex: 1}}>
                                                                            <Input
                                                                                placeholder="Add a choice"
                                                                                maxLength={99}
                                                                                value={choice}
                                                                                onChange={e => setChoice(e.target.value, index)}/>
                                                                            <div
                                                                                className="count"
                                                                                style={{color: 99 - choice.length < 10 ? '#eb5757' : '#999999'}}
                                                                            >
                                                                                {99 - choice.length}
                                                                            </div>
                                                                        </div>
                                                                        <div style={{
                                                                            height: 48,
                                                                            paddingTop: 16,
                                                                            paddingLeft: 16,
                                                                            alignSelf: 'center'
                                                                        }}>
                                                                            <GripIcon/>
                                                                        </div>
                                                                    </div>
                                                                    {index > 0 && (
                                                                        <div style={{
                                                                            color: '#ff3f3f',
                                                                            fontWeight: 700,
                                                                            cursor: 'pointer',
                                                                            display: 'inline-block',
                                                                        }}
                                                                             onClick={() => removeChoice(index)}
                                                                        >
                                                                            Remove
                                                                            choice &gt;
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                            })}
                                        </div>
                                    )
                                }}
                            </Droppable>
                        </DragDropContext>
                        <div style={{display: 'flex', alignItems: 'center', margin: '32px 0 64px', cursor: 'pointer'}}
                             onClick={() => addNewChoice()}
                        >
                            <PlusIcon />
                            <span style={{paddingLeft: 12, fontWeight: 700}}>Add another choice</span>
                        </div>
                    </>
                )}
            </div>
            {showDeleteConfirmation && (
                <GuardrailModal title="Are you sure?"
                                body="Are you sure you'd like to delete this question?"
                                buttonText="Delete"
                                onClose={() => setShowDeleteConfirmation(false)}
                                onAction={() => onDeleteQuestion()}
                />
            )}
        </div>
    )
}
