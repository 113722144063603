import {push} from "connected-react-router";
import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory, useParams} from "react-router";
import {useAppDispatch} from "../../hooks";
import {isEmpty, useQuery} from "../../utils";
import {ContactsState, setSource, syncClient} from "../../modules/contacts";
import {RootState} from "../../store";
import {AppHeader} from "../app/AppHeader";
import {PaymentMethodsListComponent} from "../settings/payment-methods/PaymentMethodsListComponent";
import './contacts.css'

const determineBreadCrumbTextFromCallback = (callbackUrl: string | null) => {
    if (!callbackUrl) return

    const parts = callbackUrl.split('/')
    if (parts.length > 1 && !parts[0] && !!parts[1]) {
        return parts[1]
    }
    else if (parts.length > 0 && !!parts[0]) {
        return parts[0]
    }
}

export const ContactPaymentMethodList = () => {
    const query = useQuery()
    const history = useHistory()

    const dispatch = useAppDispatch()
    const state: ContactsState = useSelector((state: RootState) => state.contacts)

    const params = useParams<{id: string}>()
    const [sources, setSources] = useState<Array<any>>()

    const loadClientSources = useCallback(() => {
        if (params?.id && params.id !== state.client.id) {
            dispatch(syncClient(params.id))
        }
    }, [params.id, state.client.id, dispatch])

    useEffect(() => {
        if (!isEmpty(state.sources)) {
            setSources(state.sources)
        }
    }, [state.sources])

    useEffect(() => {
        loadClientSources()
    }, [loadClientSources])

    const addPaymentCard = () => {
        dispatch(push(`/client/${params.id}/add-card`));
    }

    const onViewItemClick = (source: any) => {
        dispatch(setSource(source))
        dispatch(push(`/client/${params.id}/view-card/${source.id}`))
    }

    const callbackUrl = query.get("callbackUrl")
    return (
        <AppHeader middleWidget={null}
                   showBackButton
                   title={determineBreadCrumbTextFromCallback(callbackUrl) ?? 'Client'}
                   onBack={() => {
                       if (callbackUrl) dispatch(push(callbackUrl))
                       else history.goBack()
                    }}>
            <div className="pageWrapper">
                <PaymentMethodsListComponent
                        sources={sources!}
                        loading={state.isSending}
                        addPaymentCard={addPaymentCard}
                        onViewItemClick={onViewItemClick} />
            </div>
        </AppHeader>
    )
}