import React, { createRef, useEffect, useState } from 'react'
import { ConversationHeader } from './ConversationHeader'
import {
    toggleSelectedUser,
    searchContactsForNewMessage,
    setShowNewMessage, setShowCreateGroup
} from '../../modules/conversation'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@zendeskgarden/react-buttons'
import { ContactAvatar } from '../contacts/ContactAvatar'
import { Spinner } from '@zendeskgarden/react-loaders'
import { push } from 'connected-react-router'
import { ReactComponent as BackImage } from '../../icons/back.svg'
import { ReactComponent as ChipCloseIcon } from '../../icons/chip_close.svg'
import { ReactComponent as CheckMarkIcon } from '../../icons/checkmark.svg'
import keyBy from 'lodash.keyby'
import { splitName } from '../../utils/users'
import { usePSOwner } from '../../hooks'

export const NewConversation = (props) => {
    const dispatch = useDispatch()
    const state = useSelector(state => state.conversation)

    // Set name search input to have focus at load
    const searchInputRef = createRef()
    useEffect(() => {
        if (searchInputRef.current)
            searchInputRef.current.focus()
    }, [searchInputRef])

    // Initial search when the page loads
    useEffect(() => {
        dispatch(searchContactsForNewMessage(''))
    }, [dispatch])

    // Determine if the user has allow_group enabled for group messaging or not
    const [allowGroup, setAllowGroup] = useState(false)
    const owner = usePSOwner()
    useEffect(() => {
        if (!owner) return
        setAllowGroup(owner.allow_group)
    }, [owner])

    const search = (value) => {
        const cleanValue = value.trimLeft()
        dispatch(searchContactsForNewMessage(cleanValue))
    }

    const startNewConversation = (userId) => {
        if (allowGroup) {
            // Add the selected user to the message list
            dispatch(toggleSelectedUser(userId))
        }
        else {
            // Just go to message the selected user
            dispatch(push(`/messages/${userId}`))
            dispatch(setShowNewMessage(false))
        }
    }

    const nextClicked = () => {
        if (state.newConversation.selectedUsers.length === 1) {
            // Start a new conversation with this user
            const userId = state.newConversation.selectedUsers[0].id
            dispatch(push(`/messages/${userId}`))
            dispatch(setShowNewMessage(false))
        }
        else {
            // Go to Create group screen with the selected users
            dispatch(setShowCreateGroup(true))
        }
    }

    const backLink = (
        <Button isLink
                onClick={e => dispatch(setShowNewMessage(false))}
                style={{
                    marginTop: '15px',
                    marginLeft: '8px',
                    lineHeight: '24px',
                    fontSize: '14px',
                    color: '#3D84F6',
                    fontWeight: 700,
                    textDecoration: 'none',
                }}><BackImage style={{marginRight: '4px'}} /> Back</Button>
    )

    const nextLink = (
        <Button isLink
                onClick={e => nextClicked()}
                disabled={state.newConversation.selectedUsers.length === 0}
                style={{
                    marginTop: '-8px',
                    color: '#3D84F6',
                    opacity: state.newConversation.selectedUsers.length ? '1' : '0.3',
                    fontSize: '14px',
                    lineHeight: '24px',
                    fontWeight: 700,
                    textDecoration: 'none',
                }}
        >
            Next
        </Button>
    )

    let loadingIndicator
    if (state.newConversation.isSearching) {
        loadingIndicator = (
            <div style={{textAlign: 'center', padding: '128px 0'}}>
                <Spinner size={128} color="#314A68" />
            </div>
        )
    }

    const selectedUsers = keyBy(state.newConversation.selectedUsers, 'id')

    const userListHeight = props.inboxHeight - 77 - 64

    return (
        <React.Fragment>
            <ConversationHeader title={backLink} trailingWidget={allowGroup ? nextLink : null} />
            <div className="newConversation">
                <div className="newConversationChipHolder">
                    {state.newConversation.selectedUsers.map((user) => (
                        <div className="chip" key={`new-message-selected-${user.id}`}>
                            {user.name}
                            <ChipCloseIcon className="closeIcon"
                                           onClick={e => dispatch(toggleSelectedUser(user.id)) }
                            />
                        </div>
                    ))}
                    <input type="text"
                           value={state.newConversation.searchText}
                           onChange={e => { e.persist(); search(e.target.value); }}
                           placeholder="Who would you like to text?"
                           ref={searchInputRef}
                    />
                </div>
            </div>
            <div className="newConversationUsers" style={{height: userListHeight}}>
                {loadingIndicator}
                {state.newConversation.didSearch && !state.newConversation.isSearching && state.newConversation.searchResults.length === 0 && (
                    <div>
                        No matching clients found
                    </div>
                )}
                {state.newConversation.didSearch && !state.newConversation.isSearching && state.newConversation.searchResults.map((searchResult) => {
                    const [firstNamePart, lastNamePart] = splitName(searchResult)

                    // If allowGroup is enabled, show checkboxes and make a click
                    // toggle the checkbox status. Only go to the message screen when at
                    // least 1 user is checked.

                    let groupSelectedCheckbox
                    if (allowGroup) {
                        const isUserSelected = !!selectedUsers[searchResult.id]
                        groupSelectedCheckbox = (
                            <div className="checkmark">
                                {isUserSelected && (
                                    <CheckMarkIcon />
                                )}
                                {!isUserSelected && (
                                    <div className="unselected" />
                                )}
                            </div>
                        )
                    }

                    return (
                        <div className="searchResultUser"
                             key={`new-message-result-${searchResult.id}`}
                             onClick={e => startNewConversation(searchResult.id)}
                        >
                            <ContactAvatar user={searchResult} userId={searchResult.id}/>
                            <span style={{
                                marginLeft: '16px',
                                verticalAlign: 'middle',
                                color: '#000000'
                            }}>
                                {firstNamePart}&nbsp;
                                <span style={{fontWeight: 700}}>{lastNamePart}</span>
                            </span>
                            {groupSelectedCheckbox}
                        </div>
                    )
                })}
            </div>
        </React.Fragment>
    )
}