import React from 'react'
import { Avatar } from '@zendeskgarden/react-avatars'
import { ReactComponent as InboundClassIcon } from '../../icons/msg_inbound_class.svg'

export const GroupAvatar = (props) => {
    return (
        <Avatar
            size={props.size ? props.size : 'large'}
            style={props.style}
        >
            <InboundClassIcon width="48" height="48"
                              style={{width: '48px', height: '48px'}}
            />
        </Avatar>
    )
}