import React from "react";
import { Button } from "@zendeskgarden/react-buttons";
import { featureName, featureIcon } from "../../../models/Feature";

export type GuidedSetupRowType = {
    key: String,
    icon: JSX.Element,
    title: String,
    subtitle: string,
    onClick: () => void
}

type Props = {
    item: GuidedSetupRowType
}

export const GuidedSetupCard = ({item}: Props) => {
    return (
        <div className="guidedSetupCard">
            <div className="img">
                {item.icon}
            </div>
            <div className="nameWrapper">
                <div className="name">
                    {item.title}
                </div>
                <div className="subtitle">
                    {item.subtitle}
                </div>
            </div>
            <div className="view">
                <Button
                    style={{minWidth: '150px'}}
                    isLink
                    onClick={item.onClick}
                >
                    Play tutorial video
                </Button>
            </div>
        </div>
    )
}