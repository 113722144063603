import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../hooks";
import {Redirect, useHistory, useLocation} from "react-router";
import {AppHeader} from "../../app/AppHeader";
import {Spinner} from "@zendeskgarden/react-loaders";
import {currencyFormat} from "../../../utils/numbers";
import {PSButton, PSButtonPrimary} from "../../app/PSButton";
import {Link} from "react-router-dom";
import {apiClient} from "../../../modules/apiclient";
import {UserInstance} from "../../../models/User";
import {LineItem} from "../../../models/LineItem";
import {ItemSummaryModal} from "../ItemSummaryModal";
import {TransactionProgress} from "../TransactionProgress";
import {processError} from "../../../modules/error";
import {
    Body, Close,
    Footer,
    FooterItem,
    Header,
    Modal
} from "@zendeskgarden/react-modals";
import {b64EncodeUnicode} from "../../../utils/strings";
import { sendEstimate } from "../../../modules/transactions";
import { Result } from "../../../utils";
import { Estimate } from "../../../models/Estimate";

export const EstimateSummary = () => {
    const dispatch = useAppDispatch()

    const location = useLocation()
    const locationState: any = location.state
    const estimateData: Estimate = locationState?.estimate
    const [estimate, setEstimate] = useState<Estimate>()
    useEffect(() => setEstimate(new Estimate(estimateData)), [estimateData])

    const history = useHistory()

    // Load from paymentData
    const [items, setItems] = useState<LineItem[]>([])
    const [itemTotal, setItemTotal] = useState(0)
    const [expenses, setExpenses] = useState<LineItem[]>([])
    const [expenseTotal, setExpenseTotal] = useState(0)
    const [appointments, setAppointments] = useState<LineItem[]>([])
    const [appointmentTotal, setAppointmentTotal] = useState(0)
    useEffect(() => {
        const i = estimateData?.lines.filter(l => l.type === 'item') ?? []
        setItems(i)
        const itemSum = i.reduce((sum, cur) => sum + Number(cur.total), 0)
        setItemTotal(itemSum)

        const e = estimateData?.lines.filter(l => l.type === 'expense') ?? []
        setExpenses(e)
        const expenseSum = e.reduce((sum, cur) => sum + Number(cur.total), 0)
        setExpenseTotal(expenseSum)

        const b = estimateData?.lines.filter(l => l.type === 'booking') ?? []
        setAppointments(b)
        const appointmentSum = b.reduce((sum, cur) => sum + Number(cur.total), 0)
        setAppointmentTotal(appointmentSum)

        // Fetch data from the server that we need to render this screen
    }, [estimateData])

    const [client, setClient] = useState<UserInstance>()
    useEffect(() => {
        if (estimateData?.client) {
            apiClient.post('/client/sync', {person: estimateData.client})
                .then(resp => resp.data)
                .then(json => {
                    if (json.contacts?.length > 0)
                        setClient(json.contacts[0])
                })
        }
    }, [estimateData?.client])

    const [showItemsModal, setShowItemsModal] = useState(false)
    const [showExpensesModal, setShowExpensesModal] = useState(false)
    const [showAppointmentsModal, setShowAppointmentsModal] = useState(false)

    // Preview
    const [isPreviewLoading, setIsPreviewLoading] = useState(false)
    const [showPreviewModal, setShowPreviewModal] = useState(false)
    const [pdfPreviewData, setPDFPreviewData] = useState('')

    const [, discountDescription, ] = estimate?.discountDisplay() ?? ['', '', 0]

    const [estimateSending, setEstimateSending] = useState(false)
    const onSendEstimate = (doNotNotify: boolean) => {
        if (estimateSending || !estimate) return

        setEstimateSending(true)
        Result(dispatch(sendEstimate({...estimate, id: estimateData?.id}, doNotNotify)))
            .finally(() => setEstimateSending(false))
    }

    const onPreviewEstimate = () => {
        setIsPreviewLoading(true)
        setShowPreviewModal(true)

        apiClient.post('/estimate/print', estimate)
            .then(resp => setPDFPreviewData(resp.data))
            .catch(error => dispatch(processError(error)))
            .finally(() => setIsPreviewLoading(false))
    }

    if (!estimateData) {
        return <Redirect to="/estimate/new" />
    }

    return (
        <AppHeader title="Send estimate"
                   showBackButton
                   onBack={() => history.replace(estimate?.id ? `/estimate/edit/${estimate.id}` : '/estimate/new', {estimate:estimateData})}
                   middleWidget={<TransactionProgress created reviewed secondStep="Preview "/>}
        >
            <div className="invoiceSummary scheduleClient servicesSettings">
                <div className="header">
                    <h1>Review</h1>
                </div>

                <div className="separator" />

                <div className="scheduleClientReview">
                    <div className="details">
                        <div className="receipt">
                            <div className="total">{currencyFormat(estimate?.calculateChargeAmount() ?? 0)} Total</div>
                            <div className="subtitle">
                                <div>Estimate #{estimateData?.number ? estimateData.number : '(to be generated)'}</div>
                                <div>Requires payment</div>
                            </div>
                            <div className="lineItem final">
                                <div className="name">Subtotal</div>
                                <div className="amount">{currencyFormat(estimate?.calculateTotal())}</div>
                            </div>
                            {Number(estimate?.discount) > 0 && (
                                <div className="lineItem">
                                    <div className="name">{discountDescription}</div>
                                    <div className="amount">-{currencyFormat(estimate?.calculateDiscount())}</div>
                                </div>
                            )}
                            {Number(estimate?.calculateTax()) > 0 && (
                                <div className="lineItem">
                                    <div className="name">Sales tax</div>
                                    <div className="amount">{currencyFormat(estimate?.calculateTax())}</div>
                                </div>
                            )}
                            {estimate?.allow_surcharge === true && (
                                <div className="lineItem">
                                    <div className="name">Surcharge (3%)</div>
                                    <div className="amount">{currencyFormat(estimate?.calculateSurcharge())}</div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="actions">
                        <>
                            <PSButtonPrimary onClick={() => onPreviewEstimate()}
                                      disabled={estimateSending}
                            >
                                Preview

                            </PSButtonPrimary>
                        {estimateData.channel !== '' && <PSButton style={{border: '1px solid #e6e6e6'}}
                                      onClick={() => onSendEstimate(false)}
                                      disabled={estimateSending}
                            >
                                <span>Send</span>
                            </PSButton>
                        }
                        <PSButton style={{border: '1px solid #e6e6e6'}}
                                onClick={() => onSendEstimate(true)}
                                disabled={estimateSending}>
                                <span>Save</span>
                        </PSButton>
                        </>
                    </div>

                    <div className="fields">
                        <>
                            <div className="field">
                                <div className="data">
                                    <div className="label">Client</div>
                                    <div className="value">{client?.name}</div>
                                </div>
                                <div className="action">
                                    <Link to={`/record/client/${client?.id}`}>View</Link>
                                </div>
                            </div>
                            {items.length > 0 && (
                                <div className="field">
                                    <div className="data">
                                        <div className="label">Items</div>
                                        <div className="value">{currencyFormat(itemTotal)}</div>
                                    </div>
                                    <div className="action">
                                        <button className="buttonLink"
                                                onClick={() => setShowItemsModal(true)}
                                        >
                                            View
                                        </button>
                                    </div>
                                </div>
                            )}
                            {expenses.length > 0 && (
                                <div className="field">
                                    <div className="data">
                                        <div className="label">Expenses</div>
                                        <div className="value">{currencyFormat(expenseTotal)}</div>
                                    </div>
                                    <div className="action">
                                        <button className="buttonLink"
                                                onClick={() => setShowExpensesModal(true)}
                                        >
                                            View
                                        </button>
                                    </div>
                                </div>
                            )}
                            {appointments.length > 0 && (
                                <div className="field">
                                    <div className="data">
                                        <div className="label">Appointments</div>
                                        <div className="value">{currencyFormat(appointmentTotal)}</div>
                                    </div>
                                    <div className="action">
                                        <button className="buttonLink"
                                                onClick={() => setShowAppointmentsModal(true)}
                                        >
                                            View
                                        </button>
                                    </div>
                                </div>
                            )}
                            {estimateData.memo && (
                                <div className="field">
                                    <div className="data">
                                        <div className="label">Memo</div>
                                        <div className="value">{estimateData.memo}</div>
                                    </div>
                                    <div className="action">
                                        <button className="buttonLink"
                                                onClick={() => {}}
                                        >
                                            View
                                        </button>
                                    </div>
                                </div>
                            )}
                        </>
                    </div>
                </div>
                {showItemsModal && (
                    <ItemSummaryModal lineItems={items}
                                      title="Items"
                                      onCancel={() => setShowItemsModal(false)}
                    />
                )}
                {showExpensesModal && (
                    <ItemSummaryModal expenses={expenses}
                                      title="Expenses"
                                      onCancel={() => setShowExpensesModal(false)}
                    />
                )}
                {showAppointmentsModal && (
                    <ItemSummaryModal appointments={appointments}
                                      title="Appointments"
                                      onCancel={() => setShowAppointmentsModal(false)}
                    />
                )}
                {showPreviewModal && (
                    <Modal onClose={() => setShowPreviewModal(false)}>
                        <Header>Preview</Header>
                        <Body>
                            {isPreviewLoading && (
                                <div style={{width: '100%', height: '420px'}}>
                                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                                        <Spinner size="128" color="#314A68" />
                                    </div>
                                </div>
                            )}
                            {!isPreviewLoading && pdfPreviewData && (
                                <iframe style={{width: '100%', height: '420px'}}
                                        src={`data:application/pdf;base64,${b64EncodeUnicode(pdfPreviewData)}`}
                                        title={'Preview'}>
                                </iframe>
                            )}
                        </Body>
                        <Footer style={{paddingBottom: 32}}>
                            <FooterItem>
                            </FooterItem>
                        </Footer>
                        <Close aria-label="Close modal" />
                    </Modal>
                )}
            </div>
        </AppHeader>
    )
}