import { combineReducers  } from 'redux'
import { connectRouter  } from 'connected-react-router'
import app from './app'
import entities from './entities'
import login from './login'
import contacts from './contacts'
import messages from './inbox'
import notes from './notes'
import conversation from './conversation'
import dashboard from './dashboard'
import calendar from './calendar'
import records from './records'
import settings from './settings'
import error from './error'
import transactions from './transactions'
import onboarding from './onboarding'
import inventory from "./inventory";
import popover from "./popover";

export default function allReducers(history: any) {
    return combineReducers({
        router: connectRouter(history),
        app,
        entities,
        login,
        contacts,
        messages,
        notes,
        inventory,
        conversation,
        dashboard,
        calendar,
        records,
        settings,
        transactions,
        onboarding,
        popover,
        error,
    })
}