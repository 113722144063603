export type CategoryInstance = {
    id?: string;
    name: string;
    type: string;
    image?: string;
    image_uri?: string;
    memo: string;
    is_public: boolean;
    listings: string[];
    seqnum?: number;
    edition?: string
    edition_category?: string
}

export class Category {
    id?: string;
    name: string = '';
    type: string = '';
    image?: string;
    image_uri?: string;
    memo: string = '';
    is_public: boolean = true;
    listings: string[] = [];
    seqnum?: number;
    edition?: string;
    edition_category?: string;
    [key: string]: any;

    constructor(data: Partial<CategoryInstance>) {
        Object.assign(this, data)
    }
}