import {InventoryAdjustment, InventoryItem} from "../models/InventoryAdjustment";
import {apiClient} from "./apiclient";
import {processError} from "./error";

export type InventoryState = {
    adjustmentList: InventoryAdjustment[],
    loading: boolean
}

const initialState: InventoryState = {
    adjustmentList: [],
    loading: false
}

type InventoryEvent = {
    type: string,
    loading: boolean,
    adjustment: InventoryAdjustment,
    adjustmentList: InventoryAdjustment[]
}

export default function inventoryReducer(state = initialState, action: InventoryEvent): InventoryState {
    const ff = (adjustment: InventoryAdjustment) => adjustment.id === action.adjustment.id;

    if (action.type === 'inventory/SET_LOADING'){
        state.loading = action.loading;
    }
    else if (action.type === 'inventory/SET_ADJUSTMENT_LIST'){
        state.adjustmentList = action.adjustmentList;
    }
    else if (action.type === 'inventory/ADD_ADJUSTMENT'){
        state.adjustmentList.unshift(action.adjustment);
    }
    else if (action.type === 'inventory/UPDATE_ADJUSTMENT'){
        const ix = state.adjustmentList.findIndex(ff);
        if (ix > -1) {
            state.adjustmentList[ix] = action.adjustment;
        }
    }
    else if (action.type === 'inventory/DELETE_ADJUSTMENT'){
        const ix = state.adjustmentList.findIndex(ff);
        if (ix > -1) {
            state.adjustmentList.splice(ix, 1);
        }
    }

    return state;
}

const setLoadingEvent = (loading: boolean) => {
    return { type: 'inventory/SET_LOADING', loading };
}

const setAdjustmentListEvent = (adjustmentList: InventoryAdjustment[]) => {
    return { type: 'inventory/SET_ADJUSTMENT_LIST', adjustmentList };
}

const addAdjustmentEvent = (adjustment: InventoryAdjustment) => {
    return { type: 'inventory/ADD_ADJUSTMENT', adjustment };
}

const updateAdjustmentEvent = (adjustment: InventoryAdjustment) => {
    return { type: 'inventory/UPDATE_ADJUSTMENT', adjustment };
}

const deleteAdjustmentEvent = (adjustment: InventoryAdjustment) => {
    return { type: 'inventory/DELETE_ADJUSTMENT', adjustment };
}

export const fetchAdjustments = (item: InventoryItem) => {
    return (dispatch: any): any => {
        dispatch(setLoadingEvent(true));
        return apiClient.post(`/adjustment/${item.id}/history`)
            .then(resp => resp.data.adjustment)
            .then(results => results.map((result: any) => {
                const adjustment = new InventoryAdjustment(item);
                adjustment.setData(result);
                return adjustment;
            }))
            .then(adjustmentList => dispatch(setAdjustmentListEvent(adjustmentList)))
            .catch(error => {
                dispatch(processError(error));
                return Promise.reject();
            })
            .finally(() => dispatch(setLoadingEvent(false)));
    };
}

export const addAdjustment = (adjustment: InventoryAdjustment) => {
    return (dispatch: any): any => {
        dispatch(setLoadingEvent(true));
        return apiClient.post('/adjustment', adjustment.toObject())
            .then(resp => resp.data.adjustment)
            .then(data => {
                adjustment.setData(data);
                return adjustment;
            })
            .then(newAdjustment => dispatch(addAdjustmentEvent(newAdjustment)))
            .catch(error => {
                dispatch(processError(error));
                return Promise.reject();
            })
            .finally(() => dispatch(setLoadingEvent(false)));
    };
}

export const updateAdjustment = (adjustment: InventoryAdjustment) => {
    return (dispatch: any): any => {
        dispatch(setLoadingEvent(true));
        return apiClient.post(`/adjustment/${adjustment.id}`, adjustment.toObject())
            .then(() => dispatch(updateAdjustmentEvent(adjustment)))
            .catch(error => {
                dispatch(processError(error));
                return Promise.reject();
            })
            .finally(() => dispatch(setLoadingEvent(false)));
    };
}

export const deleteAdjustment = (adjustment: InventoryAdjustment) => {
    return (dispatch: any): any => {
        dispatch(setLoadingEvent(true));
        return apiClient.post(`/adjustment/${adjustment.id}/delete`)
            .then(() => dispatch(deleteAdjustmentEvent(adjustment)))
            .catch(error => {
                dispatch(processError(error));
                return Promise.reject();
            })
            .finally(() => dispatch(setLoadingEvent(false)));
    };
}