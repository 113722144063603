import React from "react";
import {Item} from "../../models/Item";
import {
    Body, Close,
    Modal
} from "@zendeskgarden/react-modals";
import {PSButton, PSButtonPrimary} from "../app/PSButton";
import {currencyFormat} from "../../utils/numbers";

type Props = {
    premiumItems: Item[],
    onCancel: () => void,
    onBasic: () => void,
    onPremium: () => void,
}

export const BasicPlanConfirmationModal = ({premiumItems, onCancel, onBasic, onPremium}: Props) => {
    return (
        <Modal className="basicPlanConfirmationModal" onClose={onCancel}>
            <Body>
                <h2>Are you sure?</h2>
                <div className="details">
                    During setup you added {premiumItems.length} Premium
                    item{premiumItems.length > 1 ? 's' : ''} to your account.
                    Remaining on the Basic plan will remove {premiumItems.length > 1 ? 'these items': 'this item'}.
                </div>
                <div className="listTitle">
                    You've selected the following ABMP Premium Edition default
                    item{premiumItems.length > 1 ? 's': ''}:
                </div>
                <div className="list">
                    {premiumItems.map((i: Item) => (
                        <div className="item" key={`item-${i.id}`}>
                            <img src={i.image_uri} />
                            <span>{i.name} ({currencyFormat(i.rate)})</span>
                        </div>
                    ))}
                </div>
                <PSButtonPrimary className="planButton"
                                 style={{marginBottom: 8}}
                                 onClick={onBasic}>
                    <div style={{margin: 8}}>
                        <h2>Yes, stay with Basic</h2>
                        <span style={{color: "#ffffff", fontWeight: 200}}>
                            Free forever
                        </span>
                    </div>
                </PSButtonPrimary>
                <PSButton className="planButton" onClick={onPremium}>
                    <div style={{margin: 8}}>
                        <h2>Try Premium for 30 days</h2>
                        <span style={{color: "#314a68", fontWeight: 200}}>
                            No credit card required
                        </span>
                    </div>
                </PSButton>
            </Body>
            <Close aria-label="Close modal" />
        </Modal>
    )
}
