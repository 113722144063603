import { Field, Input, Label } from "@zendeskgarden/react-forms";
import React from "react";

type FieldProps = {
    fieldValue?: string,
    label: string,
    onChange: (changed: string) => void,
    allowDecimal?: boolean
}

export const NumberInput = ({fieldValue, label, onChange, allowDecimal}: FieldProps) => {
    return (
        <Field className='field'>
            <Label className='label'>{label}</Label>
            <Input placeholder='Enter text'
                type='number'
                value={fieldValue}
                onKeyDown={e => {
                    if (e.key === '.' && !allowDecimal) {
                        e.preventDefault()
                    }
                }}
                onChange={e => onChange(e.target.value)} />
        </Field>
    )
}