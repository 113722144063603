import {useHistory} from "react-router";
import {PSButton, PSButtonPrimary} from "../../../app/PSButton";
import {ReactComponent as LeftArrow} from "../../../../icons/left-arrow.svg";
import React from "react";

type Props = {
    onCreateNewField: () => void,
}

export const FormQuestionListEmptyState = ({onCreateNewField}: Props) => {
    const history = useHistory()

    return (
        <div className="servicesSettings">
            <div className="header">
                <PSButton style={{marginLeft: 0, marginRight: 16}}
                          onClick={() => history.goBack()}
                >
                    <LeftArrow/>
                </PSButton>
                <h1>List of questions</h1>
            </div>
            <div className="empty">
                <div>You don't have any questions added yet</div>
                <div className="promo">Add questions for clients to fill out
                    📝
                </div>
                <PSButtonPrimary style={{height: '40px'}}
                                 onClick={() => onCreateNewField()}>
                    Add a question
                </PSButtonPrimary>
            </div>
        </div>
    )
}