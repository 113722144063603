import React, {useCallback, useEffect, useState} from "react"
import {AppHeader} from "../app/AppHeader";
import {PSButton, PSButtonPrimary} from "../app/PSButton";
import { Tooltip } from '@zendeskgarden/react-tooltips';
import {useAppDispatch, usePSUser} from "../../hooks";
import { PSVideoModal } from "../app/PSVideoModal";
import styled from "styled-components";
import localforage from "localforage";
import { Result } from "../../utils";
import { WATCHED_ONBOARDING_VIDEO } from "../../utils/constants";
import { Spinner } from "@zendeskgarden/react-loaders";
import { setGoLiveState } from "../../modules/onboarding";
import { nextGoLiveStep } from "../../utils/onboarding";
import { push } from "connected-react-router";
import { DashboardState, fetchDashboards, filterDashboardItems } from "../../modules/dashboard";
import { Dashboard } from "../../models/Dashboard";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export const GoLivePaymentsVideo = () => {
    const dispatch = useAppDispatch()
    const user = usePSUser()
    const state: DashboardState = useSelector((state: RootState) => state.dashboard)

    const [dashboard, setDashboard] = useState<Dashboard>()
    const [showVideoModal, setShowVideoModal] = useState(false)
    const [didWatchVideo, setDidWatchVideo] = useState(false)
    const [skipVisible, setSkipVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const onNextHandler = useCallback(() => {
        if (!user) return

        setLoading(true)
        setGoLiveState('gl_business')
            .then((me) => dispatch(nextGoLiveStep(me)))
            .finally(() => setLoading(false))
    }, [user, dispatch])

    useEffect(() => {
        let setupDashboard: Dashboard[] = [];
        if (state.dashboards.length) {
            setupDashboard = filterDashboardItems(state.dashboards, ['setup_launch'])

            setDashboard(setupDashboard?.length ? setupDashboard[0] : undefined)
        }
        if (!setupDashboard.length) {
            setLoading(true)
            Result(dispatch(fetchDashboards("main"))).then(() => {
                setLoading(false)
            })
        }
    }, [dispatch, state.dashboards])

    const videoFinished = () => {
        localforage.setItem(WATCHED_ONBOARDING_VIDEO, true)
        setDidWatchVideo(true)
        setShowVideoModal(false)
        setSkipVisible(false)
    }

    const onClose = () => {
        setShowVideoModal(false)
        setSkipVisible(true)
    }

    const onSkipHandler = useCallback(() => {
        dispatch(push('/golive/business-info'))
    }, [dispatch])

    useEffect(() => {
        Result(localforage.getItem<boolean>(WATCHED_ONBOARDING_VIDEO)).then((value: boolean) => setDidWatchVideo(value))
    }, [])

    // if we disable the button the tooltip doesn't work, style a primary button disabled
    const PSButtonDisabled = styled(PSButtonPrimary)`
        background: #314a68;
        opacity: 0.4;
        cursor: default;
    `

    if (loading) {
        return (
            <div className="servicesSettings">
                <div className="header">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <AppHeader title="Grow your business" showBackButton middleWidget={null}>
            <div className="servicesSettings onboarding launchBusiness">
                <div className="header">
                    <h1>Your payments</h1>
                    <div style={{marginLeft: "auto"}}>
                        {skipVisible &&
                            <PSButton style={{height: '40px'}} onClick={onSkipHandler}>
                                Skip
                            </PSButton>
                        }
                        {!skipVisible && (
                            didWatchVideo ?
                            <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}} onClick={onNextHandler}>
                                Next
                            </PSButtonPrimary> :
                            <Tooltip content="Watch video to continue" style={{backgroundColor: "#314a68", borderRadius: "6px"}}>
                                <PSButtonDisabled style={{height: '40px', marginLeft: 'auto'}} onClick={() => {}}>
                                    Next
                                </PSButtonDisabled>
                            </Tooltip>
                        )}
                    </div>
                </div>

                <div className="separator" />

                <div className="form">
                    <div className="text">PocketSuite it easy for your clients to pay you! Watch a quick overview video to learn more. </div>
                    <div className="watchVideoWrapper">
                        <img alt="onboarding laptop &amp; phone" src="/icons/onboarding-laptop-phone.png" />
                        <div className="buttons">
                            <PSButtonPrimary onClick={()=> setShowVideoModal(true)}>Watch video</PSButtonPrimary>
                        </div>
                    </div>
                </div>

                {showVideoModal &&
                    <PSVideoModal title="Your payments"
                        hlsVideoURL={(dashboard?.meta_data as any)?.verify_video_uri}
                        isLoading={false} onEnded={videoFinished} onClose={onClose} />
                }
            </div>
        </AppHeader>
    )
}