import moment from "moment";

export const taskStatus = (record: any): string => {
    const dateFormat = (date: string) => moment(date).format('MMM Do, h:mm a')

    if (record.completed_date)
        return `Completed ${dateFormat(record.completed_date)}`
    if (record.date)
        return `Due ${dateFormat(record.date)}`

    return 'Task'
}
