import React, { useState } from "react";
import {
    Body,
    Close,
    Footer,
    FooterItem,
    Header,
    Modal
} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import {Checkbox, Field, Label } from "@zendeskgarden/react-forms";
import moment from "moment";
import {Dropdown, Item, Menu, Select, Field as DropdownField} from "@zendeskgarden/react-dropdowns";

type Props = {
    onClose: () => void,
    onDone: (weekdays: any[]) => void,
    currentAvailability: any[],
    title: string
}

export type ItemOption = {
    value: number,
    name: string,
}

export type Weekday = {
    name: string,
    enabled: boolean,
    setEnabled?: (b: boolean) => void,
    start: ItemOption | string,
    setStart?: (s: ItemOption) => void,
    end: ItemOption | string,
    setEnd?: (e: ItemOption) => void,
    endHours: ItemOption[],
    setEndHours?: (i: ItemOption[]) => void,
}

export const AvailableHoursModal = ({onClose, onDone, currentAvailability, title}: Props) => {
    const itemHours: ItemOption[] = []
    for (let i = 0; i < 24; i++) {
        itemHours.push({value: i, name: moment().hour(i).minute(0).format('h:mma') })
        itemHours.push({value: i+0.5, name: moment().hour(i).minute(30).format('h:mma') })
    }
    itemHours.push({value: 24, name: moment().hour(0).minute(0).format('h:mma') })

    const weekdays: Weekday[] = []
    for (let weekday = 1; weekday <= 7; weekday++) {
        let defaultEnabled = false
        let defaultStart = itemHours[16]
        let defaultEnd = itemHours[36]

        // Set defaults from the currentAvailability that is passed in
        if (currentAvailability.length >= weekday) {
            const dayAvailability = currentAvailability[weekday-1]
            if (dayAvailability.enabled) {
                defaultEnabled = dayAvailability.enabled
                defaultStart = dayAvailability.start
                defaultEnd = dayAvailability.end
            }
        }

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [enabled, setEnabled] = useState(defaultEnabled)
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [start, setStart] = useState(defaultStart)
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [end, setEnd] = useState(defaultEnd)
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [endHours, setEndHours] = useState(itemHours.slice(20))

        weekdays.push({
            name: moment().day(weekday).format('ddd'),
            enabled, setEnabled,
            start, setStart,
            end, setEnd,
            endHours, setEndHours
        })
    }

    const selectStartItem = (weekday: Weekday, selection: ItemOption) => {
        weekday.setStart!(selection)

        // Make the itemHours for end time selection be at least 2 hours more
        // than what the selected start time is
        const itemHoursIndex = itemHours.findIndex((i: ItemOption) => i.value === selection.value)
        const endHourStartIndex = itemHoursIndex + 4
        if (itemHours.length > endHourStartIndex)
            weekday.setEndHours!(itemHours.slice(endHourStartIndex))
        else
            weekday.setEndHours!(itemHours.slice(itemHours.length-1))

        // If the current end time is not valid anymore, set it to be 2 hours
        // from the newly selected start time
        if (typeof weekday.end !== "string" && weekday.end.value - 2 < selection.value) {
            const newEndValue = selection.value + 2
            const newEndHour = itemHours.find((i: ItemOption) => i.value === newEndValue)
            if (newEndHour)
                weekday.setEnd!(newEndHour)
        }
    }

    const selectEndItem = (weekday: any, selection: ItemOption) => {
        weekday.setEnd(selection)
    }

    return (
        <Modal className="availableHoursModal" onClose={onClose}>
            <Header>{title}</Header>
            <Body>
                {weekdays.map((weekday: any, index: number) => (
                    <Field className="field" key={`available-${index}`}>
                        <div className="check">
                            <Checkbox checked={weekday.enabled}
                                      onChange={e => weekday.setEnabled(e.target.checked)}>
                                <Label>{weekday.name}</Label>
                            </Checkbox>
                        </div>
                        {!weekday.enabled && (
                            <div className="unavailable">Unavailable</div>
                        )}
                        {weekday.enabled && (
                            <div className="timeSelection">
                                <Dropdown selectedItem={weekday.start}
                                          onSelect={(selection) => selectStartItem(weekday, selection)}
                                          downshiftProps={{ itemToString: (item: ItemOption) => item.name}}
                                >
                                    <DropdownField className="field">
                                        <Select className="input">
                                            {weekday.start.name}
                                        </Select>
                                    </DropdownField>
                                    <Menu>
                                        {itemHours.slice(0, itemHours.length-4).map((option) => (
                                            <Item key={`start-${option.value}`}
                                                  value={option}
                                                  style={{padding: '8px 0 8px 36px'}}>
                                                {option.name}
                                            </Item>
                                        ))}
                                    </Menu>
                                </Dropdown>
                                <span className="spacer">-</span>
                                <Dropdown selectedItem={weekday.end}
                                          onSelect={(selection) => selectEndItem(weekday, selection)}
                                          downshiftProps={{ itemToString: (item: ItemOption) => item.name}}
                                >
                                    <DropdownField className="field">
                                        <Select className="input">
                                            {weekday.end.name}
                                        </Select>
                                    </DropdownField>
                                    <Menu>
                                        {weekday.endHours.map((option: ItemOption, index: number) => (
                                            <Item key={`end-${index}`}
                                                  value={option}
                                                  style={{padding: '8px 0 8px 36px'}}>
                                                {option.name}
                                            </Item>
                                        ))}
                                    </Menu>
                                </Dropdown>
                            </div>
                        )}
                    </Field>
                ))}
            </Body>
            <Footer>
                <FooterItem>
                    <Button onClick={() => onDone(weekdays)} isPrimary>
                        Done
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )
}