import { isPassedDate } from "."
import { ButtonProps } from "../components/records/RecordButtons"
import { UserInstance } from "../models/User"
import { currencyFormat } from "./numbers"

export type RecordStatus = {
    label: string;
    completed: boolean;
}

export const distinctButtons = (buttons: ButtonProps[]) =>
    // Create a unique array of button.text to process
    Array.from(new Set(buttons.map(button => button.text)))
        .map(buttonText => {
            return {
                ...buttons.find(button => button.text === buttonText)!
            } as ButtonProps
        })


export const isForVendor = (record?: any, owner?: UserInstance) =>
    !record?.vendor ? owner?.id === record?.owner : owner?.id === record.vendor

type LateInfo = {
    isCancellableClass: boolean,
    isCancellableSeries: boolean,
    isLate: boolean,
    forVendor: boolean,
    forClass: boolean
}

export const bookingLateInfo = (record: any, item: any, user: UserInstance, vendor: UserInstance): LateInfo => {
    const forVendor = isForVendor(record, user)
    const forClass = (record.item && record.type === "class") || (record.id && record.lesson)
    const isCancellableClass = forClass && (forVendor || item.class_type === "dropin")
    const isCancellableSeries = forVendor && record.schedule_instance && record.schedule_instance.created_date
    const isLate = !!vendor.cancellation_rate && isPassedDate(record.date, vendor.cancellation_duration ?? 0)
        && record.status === "confirmed"

    return {
        isCancellableClass,
        isCancellableSeries,
        isLate,
        forClass,
        forVendor
    }
}

export const cancellationFeeString = (vendor: UserInstance) =>
    vendor.cancellation_rate_type === "percent" ? `${vendor.cancellation_rate}%` : `${currencyFormat(vendor.cancellation_rate)}`

export const subscriptionCancellationFeeString = (vendor: UserInstance) =>
    vendor.subscription_cancellation_rate_type === "percent" ? `${vendor.subscription_cancellation_rate}%` : `${currencyFormat(vendor.subscription_cancellation_rate)}`