import React, { useCallback, useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Field, Hint, Input, IInputProps, Label, Message, Checkbox} from '@zendeskgarden/react-forms';
import { Modal, Header, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import {ReactComponent as ProIcon} from '../../icons/ps_general_client_nobg.svg';
import {ReactComponent as CardIcon} from '../../icons/ps_general_card_nobg.svg'
import {ReactComponent as MoreDetailsIcon} from '../../icons/ps_settings_online_leads_nobg.svg';
import {Item} from '@zendeskgarden/react-dropdowns';
import {useHistory, useParams} from 'react-router';
import {PSFormFieldSelection} from '../app/PSFormFieldSelection';
import {PSButtonPrimary, PSButtonDanger} from '../app/PSButton';
import {PSDropdown} from '../app/PSDropdown';
import {Dots, Spinner} from '@zendeskgarden/react-loaders';
import {ContactsState, syncChannels, syncClient} from '../../modules/contacts';
import {RootState} from '../../store';
import {DangerModal} from '../settings/DangerModal'
import {
    fetchCustomFields, deleteContact
} from '../../modules/contacts'
import {AppHeader} from '../app/AppHeader'
import {SettingsPhotoField} from '../settings/SettingsPhotoField';
import {usePhotoUpload} from '../settings/hooks';
import './contacts.css'
import {VALIDATION} from '@zendeskgarden/react-forms/dist/typings/utils/validation';
import InputMask from 'react-input-mask'
import {
    isValid,
    isValidEmailAddress,
    isValidPhoneNumber,
    Result,
    stringEqual
} from '../../utils';
import {bookingSendViaOptionsNoDont, bookingReminderOptionsNoDont, SelectOption, findSelection, discountPercentOptions} from '../settings/Options';
import {isEmpty} from 'lodash';
import {push} from 'connected-react-router';
import {UserInstance} from '../../models/User';
import {fetchPros} from '../../modules/transactions';
import {SelectProModal} from './SelectProModal';
import {CustomFieldsModal} from '../transactions/CustomFieldsModal';
import {saveContact} from '../../modules/contacts';
import {Field as PSField} from "../../models/Field";
import {Option as PSOption} from "../../models/Option"
import {ContactAvatar} from './ContactAvatar';
import { GuardrailModal } from '../settings/GuardrailModal';
import { usePSOwner, usePSUser } from '../../hooks';
import { filterMonetaryInput, shiftThirdDecimal } from '../../utils/numbers';
import { SelectModal } from '../transactions/SelectModal';
import { dateFormat } from '../../utils/dates';
import {RecordDetail} from "../records/RecordDetail";
import {DisplayCustomField} from "../records/DisplayCustomField";

export const ContactEdit = () => {
    const params = useParams<{id?: string}>()
    const history = useHistory()
    const dispatch = useDispatch()
    const state: {contacts: ContactsState, entities: any} = useSelector((state: RootState) => ({
        contacts: state.contacts,
        entities: state.entities
    }))

    const isAddNewClient = Boolean(!params.id)
    const findNotificationPreferenceSelection = (notify: string) => findSelection(bookingSendViaOptionsNoDont, notify)
    const findReminderSelection = (reminder: string) => findSelection(bookingReminderOptionsNoDont, reminder)
    const findDiscountSelection = (reminder: string) => findSelection(discountPercentOptions, reminder)

    const [saveEnabled, setSaveEnabled] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [showCustomFieldsModal, setShowCustomFieldsModal] = useState(false)
    const [showPaymentMethodsScreen, setShowPaymentMethodsScreen] = useState(false)
    const [showInvalidNotificationConfig, setShowInvalidNotificationConfig] = useState(false)
    const [shouldSendSmartCampaign, setShouldSendSmartCampaign] = useState(true)
    const [customFields, setCustomFields] = useState<any>({})
    const [isSelfSignUp, setIsSelfSignUp] = useState(false)

    const [showProSelector, setShowProSelector] = useState(false)
    const [proList, setProList] = useState<any>()
    const [employee, setEmployee] = useState('')

    const [sources, setSources] = useState<Array<any>>()
    const [client, setClient] = useState<any>()
    const [contact, setContact] = useState<any>()

    const [photo, setPhoto] = useState<string | File>()
    const [addPhotoRef, selectPhoto, selectedPhoto] = usePhotoUpload({disabled: state.contacts.isSending, setPhoto})

    const [name, setName] = useState<string>()
    const [company, setCompany] = useState('')
    const [mobile, setMobile] = useState('')
    const [email, setEmail] = useState('')

    const [addressLine1, setAddressLine1] = useState('')
    const [addressLine2, setAddressLine2] = useState('')
    const [city, setCity] = useState('')
    const [postalCode, setPostalCode] = useState('')

    const [nameValidation, setNameValidation] = useState<VALIDATION>()
    const [mobileValidation, setMobileValidation] = useState<VALIDATION>()
    const [mobileValidationMessage, setMobileValidationMessage] = useState('')
    const [emailValidation, setEmailValidation] = useState<VALIDATION>()
    const [emailValidationMessage, setEmailValidationMessage] = useState('')
    const [notificationPreference, _setNotificationPreference] = useState<SelectOption>()
    const [notificationConfigErrorMsg, setNotificationConfigErrorMsg] = useState('')
    const [reminder, setReminder] = useState<SelectOption>()
    const [discount, setDiscount] = useState<SelectOption>()
    const [leadSource, setLeadSource] = useState<any>()
    const [leadCost, setLeadCost] = useState<any>()
    const [showLeadCostModal, setShowLeadCostModal] = useState<any>()
    const [showLeadRefererModal, setShowLeadRefererModal] = useState<any>()
    const [referer, setReferer] = useState<UserInstance>()
    const [leadSources, setLeadSources] = useState<any[]>()
    const [isLoading, setIsLoading] = useState(true)

    const user = usePSUser()
    const owner = usePSOwner()
    const validateNotificationEmail = useCallback((optionLabel: string) => {
        if (isValidPhoneNumber(mobile) && !isValidEmailAddress(email)) {
            setNotificationConfigErrorMsg(`Don't forget to add an email address if you want your notifications sent via "${optionLabel}"`)
            setShowInvalidNotificationConfig(true)
            return false
        }
        return true
    }, [email, mobile])

    const validateNotificationMobile = useCallback((optionLabel: string) => {
        if (isValidEmailAddress(email) && !isValidPhoneNumber(mobile)) {
            setNotificationConfigErrorMsg(`Don't forget to add a phone number if you want your notifications sent via "${optionLabel}"`)
            setShowInvalidNotificationConfig(true)
            return false
        }
        return true
    }, [email, mobile])

    const setNotificationPreference = useCallback((option: SelectOption) => {
        switch (option.value) {
            case 'sms':
                validateNotificationMobile(option.label)
                break
            case 'email':
                validateNotificationEmail(option.label)
                break
            case 'combo':
                validateNotificationMobile(option.label) && validateNotificationEmail(option.label)
                break
        }

        _setNotificationPreference(option)

    }, [_setNotificationPreference, validateNotificationMobile, validateNotificationEmail])

    const loadClientSources = useCallback(() => {
        if (params?.id && params.id !== contact?.id) {
            dispatch(syncClient(params.id))
        }
        else if (!params?.id) {
            dispatch(syncChannels())
            setClient(undefined)
            setContact(undefined)
            setIsSelfSignUp(false)

            setSources([])
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
        }

    }, [params?.id, dispatch, contact?.id])

    useEffect(() => {
        loadClientSources()
    }, [loadClientSources])

    useEffect(() => setSources(state.contacts.sources), [state.contacts.sources])
    useEffect(() => setClient(state.contacts.client), [state.contacts.client])
    useEffect(() => setContact(state.contacts.contact), [state.contacts.contact])
    useEffect(() => setLeadSources(state.contacts.channels), [state.contacts.channels])

    useEffect(() => {
        dispatch(fetchPros())
        dispatch(fetchCustomFields())
    }, [dispatch])

    const convertFieldValuesToViewObject = useCallback((customFieldsValues?: any) => {
        if (!customFieldsValues) return {};

        const fieldValues: any = {}
        Object.entries(customFieldsValues).forEach(([fieldName, value]: [string, any]) => {
            const field = state.contacts.fields.find((f: PSField) => f.name === fieldName)
            if (!field) return
            if (Array.isArray(field.options) && field.options.length > 0)
                if (Array.isArray(value))
                    fieldValues[fieldName] = field.options.filter((o: PSOption) => value.indexOf(o.value) !== -1)
                else
                    fieldValues[fieldName] = field.options.find((o: PSOption) => o.value === value)
            else if (field.type === 'date')
                fieldValues[fieldName] = dateFormat(value, 'MMM D, yyyy')
            else
                fieldValues[fieldName] = value
        })
        return fieldValues
    }, [state.contacts.fields])

    const convertCustomFieldsToDataObject = useCallback(() => {
        const fieldValues: any = {}
        Object.entries(customFields).forEach(([fieldName, value]: [string, any]) => {
            const field = state.contacts.fields.find((f: PSField) => f.name === fieldName)
            if (!field) return
            if (typeof value === 'object')
                if (Array.isArray(value))
                    value = value.map((v: any) => v.value)
                else if (field.type === 'date')
                    value = dateFormat(value, 'YYYY-MM-DD')
                else
                    value = value.value
            fieldValues[fieldName] = value
        })

        return fieldValues
    }, [state.contacts.fields, customFields])

    useEffect(() => {
        setProList((currentVal: any) => {
            if (isEmpty(currentVal) && state.entities.users && state.entities.payees) {
                const payeeIds = Object.keys(state.entities.payees)
                const payees = Object.fromEntries(
                    Object.entries(state.entities.users).filter(([id, user]) =>
                        (user as UserInstance)?.role === 'employee' && payeeIds.includes(id)
                    )
                )
                return payees
            }
            return currentVal
        })
    }, [state.entities.users, state.entities.payees])

    const clearMobileValidationError = () => {
        setMobileValidation(undefined)
        setMobileValidationMessage('')
    }

    const clearEmailValidationError = () => {
        setEmailValidation(undefined)
        setEmailValidationMessage('')
    }

    const clientChangeDetected = useCallback(() => client && (
        (client.channel !== leadSource && client.referer !== referer?.id) ||
        findNotificationPreferenceSelection(client.notify) !== notificationPreference ||
        findDiscountSelection(client.discount) !== discount ||
        findReminderSelection(client.reminder) !== reminder ||
        client.allow_trigger !== shouldSendSmartCampaign || client.employee !== employee ||
        !stringEqual(JSON.stringify(client.field_values ?? {}), JSON.stringify(convertCustomFieldsToDataObject()))
    ), [client, leadSource, referer?.id, notificationPreference, discount, reminder, shouldSendSmartCampaign, employee, convertCustomFieldsToDataObject])

    const contactChangeDetected = useCallback(() => contact && (
        contact.image !== (photo ?? '') ||
        !stringEqual(contact.name, name) || !stringEqual(contact.entity, company) ||
        !stringEqual(contact.phone, mobile) || !stringEqual(contact.email, email) ||
        !stringEqual(contact.line1, addressLine1) || !stringEqual(contact.line2, addressLine2) ||
        !stringEqual(contact.city, city) || !stringEqual(contact.postal_code, postalCode)
    ), [contact, photo, name, email, company, mobile, addressLine1, addressLine2, city, postalCode])

    useEffect(() => {
        setSources(sources)

        const isSelfSignUp = !isEmpty(contact) && !isEmpty(client) && (
            contact.source === 'signup' || contact.signup_date ||
            (client.last_source_date && contact.verified_date)
        )

        setIsSelfSignUp(isSelfSignUp)
    }, [contact, client, sources])

    useEffect(() => {
        if (!params.id) return

        setHasEdits(clientChangeDetected() || contactChangeDetected())
    }, [params.id, clientChangeDetected, contactChangeDetected])

    useEffect(() => {
        if (!params.id) return

        setPhoto(contact?.image_uri)
        setName(contact?.name)
        setCompany(contact?.entity)
        setMobile(contact?.phone)
        setEmail(contact?.email)

        const referer = state.entities.users[client?.referer]
        if (referer) {
            setLeadSource({name:`Referred by (${referer.name})`})
            setLeadCost(0)
            setReferer(referer)
        }
        else {
            setLeadSource(leadSources?.find(ls => ls.id === client?.channel))
            setLeadCost(client?.cost)
            setReferer(undefined)
        }
        _setNotificationPreference(findNotificationPreferenceSelection(client?.notify))
        setDiscount(findDiscountSelection(client?.discount))
        setReminder(findReminderSelection(client?.reminder))
        setEmployee(client?.employee)
        setCustomFields(convertFieldValuesToViewObject(client?.field_values))
        setShouldSendSmartCampaign(client?.allow_trigger)

        setAddressLine1(contact?.line1)
        setAddressLine2(contact?.line2)
        setCity(contact?.city)
        setPostalCode(contact?.postal_code)

    }, [contact, client, params.id, convertFieldValuesToViewObject, leadSources, state.entities.users])

    const validateEmailOrPhone = useCallback(() => {
        if (state.contacts.isSending || (!email && !mobile)) {
            clearEmailValidationError()
            clearMobileValidationError()
            return
        }

        const atLeastOne: VALIDATION | undefined = !!mobile || !!email ? undefined : 'error'
        if (!isValid(atLeastOne)) {
            setMobileValidation(atLeastOne)
            setMobileValidationMessage('A mobile number or email address is required')
            return
        }

        clearMobileValidationError()
        clearEmailValidationError()

        if (mobile) {
            const isValid = isValidPhoneNumber(mobile)
            if (!isValid) {
                setMobileValidation('error')
                setMobileValidationMessage('Only US phone numbers are allowed.')
            }
        }

        if (email) {
            const isValid = isValidEmailAddress(email)
            if (!isValid) {
                setEmailValidation('error')
                setEmailValidationMessage('That does not appear to be a valid email address.')
            }
        }

    }, [mobile, email, state.contacts.isSending])

    useEffect(() => setNameValidation(!!name || name === undefined || state.contacts.isSending ? undefined : 'error'), [name, state.contacts.isSending])
    useEffect(validateEmailOrPhone, [validateEmailOrPhone])

    useEffect(() =>{
        const isFormValid = isValid(mobileValidation) && isValid(emailValidation) && isValid(nameValidation) &&
            !!name && (!!mobile || !!email)
        setSaveEnabled(params.id ? hasEdits && isFormValid : isFormValid)
    }, [mobileValidation, emailValidation, nameValidation, params.id, hasEdits, name, mobile, email])

    const deleteAndBlockButton = 2
    const handleDeleteClick = (buttonIx?: number) => {
        const blockToo = buttonIx === deleteAndBlockButton
        dispatch(deleteContact(client.id, contact.id, blockToo))
            // @ts-ignore
            .then(() => dispatch(push('/clients')))
    }

    const [customFieldDesc, setCustomFieldDesc] = useState('')
    useEffect(() => {
        if (!customFields) return
        const desc = Object.keys(customFields).filter(field => !!customFields[field]).map((name: any) => {
            const value = customFields[name];
            const field = state.entities.fields[name] || {}
            if (Array.isArray(value))
                return value.map((v: any) => v.label).join(', ')
            else if (field.type === 'date')
                return dateFormat(value, 'MMM D, yyyy')
            else if (field.type === 'select')
                return value.label
            else if (field.type === "image" || field.type === "document") {
                const fileInfo = client.field_values[name + "_result"]
                return fileInfo && fileInfo.name ? fileInfo.name : value
            }
            return value
        }).join(', ')
        setCustomFieldDesc(desc)
    }, [customFields])


    const onSave = () => {
        const userParams: any = {
            name,
            email,
            city,
            employee,
            phone: mobile,
            entity: company,
            line1: addressLine1,
            line2: addressLine2,
            postal_code: postalCode,
            allow_trigger: shouldSendSmartCampaign,
            referer: referer?.id ?? '',
            channel: !referer && leadSource ? leadSource.id : '',
            cost: !referer ? leadCost: 0

        }
        if (contact?.id) userParams.id = contact.id
        if (notificationPreference) userParams.notify = notificationPreference.value
        if (reminder) userParams.reminder = reminder.value
        if (discount) userParams.discount = discount.value

        userParams.field_values = convertCustomFieldsToDataObject()

        return Result(dispatch(saveContact(userParams, !isSelfSignUp ? photo as File : undefined))).then((clientId?: string) => {
            if (!isAddNewClient) dispatch(push(`/record/client/${clientId}`))
            else if (!showPaymentMethodsScreen) dispatch(push('/clients'))
            else if (clientId) dispatch(push(`/client/${clientId}/payment-methods?callbackUrl=${encodeURIComponent('/clients')}`))
        })
    }

    const assignedPro = employee && proList ? proList[employee] : undefined
    let pageBody = (
        <div className='form'>
        {isSelfSignUp && contact ?
            (<ContactAvatar user={contact} style={{marginTop: '-6px', height: '80px', width: '80px'}} />) :
            (<SettingsPhotoField photo={photo}
                selectPhoto={selectPhoto}
                selectedPhoto={selectedPhoto}
                addPhotoRef={addPhotoRef}
                title={`Add an image`} />)
        }

        <Field className='field'>
            <Label className='label'>Name (required)</Label>
            <Input placeholder='Enter your name'
                value={name}
                validation={isSelfSignUp ? undefined : nameValidation}
                disabled={isSelfSignUp || state.contacts.isSending}
                onChange={e => setName(e.target.value)} />
        </Field>
        {!!nameValidation && (
            <Message validation={nameValidation}>Enter a value for client name</Message>
        )}
        <Field className='field'>
            <Label className='label'>Company</Label>
            <Input placeholder='Enter company name'
                value={company}
                disabled={isSelfSignUp || state.contacts.isSending}
                onChange={e => setCompany(e.target.value)} />
        </Field>

        <Field className='field'>
            <Label className='label'>{`Mobile number ${(isValidPhoneNumber(mobile)) || !isValidEmailAddress(email) ? ' (required)' : ''}`}</Label>
            {isSelfSignUp ? (
            <Input placeholder='Enter company name'
                value={mobile}
                disabled={true} />
            ) : (
            <InputMask mask='999-999-9999'
                    value={mobile}
                    alwaysShowMask={false}
                    maskChar={''}
                    disabled={state.contacts.isSending}
                    onChange={e => setMobile(e.target.value)}>
                    {(inputProps: IInputProps) => <Input {...inputProps}
                        className='mobileNumberInput'
                        placeholder='Enter mobile number'
                        disabled={state.contacts.isSending}
                        validation={mobileValidation}
                        />}
            </InputMask>)}
        </Field>
        {!isValid(mobileValidation) && (
            <Message validation={mobileValidation}>{mobileValidationMessage}</Message>
        )}
        <Field className='field'>
            <Label className='label'>{`Email address ${!isValidPhoneNumber(mobile) ? ' (required)' : ''}`}</Label>
            <Input placeholder='Enter email address'
                value={email}
                validation={isSelfSignUp ? undefined : emailValidation}
                disabled={isSelfSignUp || state.contacts.isSending}
                onChange={e => setEmail(e.target.value)} />
        </Field>
        {!isValid(emailValidation) && (
            <Message validation={emailValidation}>{emailValidationMessage}</Message>
        )}

        <h2>Notifications</h2>
        <PSDropdown selected={notificationPreference}
                nameProperty={'label'}
                onSelect={(notify) => setNotificationPreference(notify)}
                label={'Send via'}
                placeholder={<div className='grey'>Select</div>}
                hint={'Choose how to send notifications'}>
            <>
                {bookingSendViaOptionsNoDont.map(option => (
                    <Item key={`notify-${option.value}`} value={option}>
                        {option.label}
                    </Item>
                ))}
            </>
        </PSDropdown>
        <PSDropdown selected={reminder}
                nameProperty={'label'}
                onSelect={(reminder) => setReminder(reminder)}
                label={'Send reminder'}
                placeholder={<div className='grey'>Select</div>}
                hint={'Choose when to send reminders'}>
            <>
                {bookingReminderOptionsNoDont.map(option => (
                    <Item key={`notify-${option.value}`} value={option}>
                        {option.label}
                    </Item>
                ))}
            </>
        </PSDropdown>

        <Field className='field'>
            <Checkbox checked={shouldSendSmartCampaign}
                        disabled={state.contacts.isSending}
                        onChange={(e) => setShouldSendSmartCampaign(e.target.checked)}>
                <Label className="withHint">Send smart campaigns</Label>
                <Hint>Send custom notifications</Hint>
            </Checkbox>
        </Field>

        <h2>Address</h2>
        <Field className='field'>
            <Label className='label'>Street</Label>
            <Input placeholder='Enter street'
                value={addressLine1}
                disabled={isSelfSignUp || state.contacts.isSending}
                onChange={e => setAddressLine1(e.target.value)} />
        </Field>
        <Field className='field'>
            <Label className='label'>Unit</Label>
            <Input placeholder='Enter unit'
                value={addressLine2}
                disabled={isSelfSignUp || state.contacts.isSending}
                onChange={e => setAddressLine2(e.target.value)} />
        </Field>
        <Field className='field'>
            <Label className='label'>City</Label>
            <Input placeholder='Enter city'
                value={city}
                disabled={isSelfSignUp || state.contacts.isSending}
                onChange={e => setCity(e.target.value)} />
        </Field>
        <Field className='field'>
            <Label className='label'>Zipcode</Label>
            <Input placeholder='Enter zipcode'
                value={postalCode}
                disabled={isSelfSignUp || state.contacts.isSending}
                onChange={e => setPostalCode(e.target.value)} />
        </Field>

        <h2>More details</h2>
        {user?.type === 'company' && (
            <PSFormFieldSelection label='Pro'
                helperLabel={'Staff somebody with this client'}
                icon={<ProIcon />}
                linkText={assignedPro ? 'Edit' : 'Add'}
                selection={assignedPro}
                onClick={() => {setShowProSelector(true)}}
            />
        )}
        {state.contacts.fields.length > 0 && (
            <PSFormFieldSelection label='More details'
                helperLabel='Add more details'
                linkText='Add'
                selection={customFieldDesc ? {name: customFieldDesc} : undefined}
                icon={<MoreDetailsIcon />}
                onClick={() => setShowCustomFieldsModal(true)} />
        )}
        <Field className={`field${!isAddNewClient ? ' formFieldSelection' : ''}`}>
            {isAddNewClient && (
                <Checkbox onChange={(e) => setShowPaymentMethodsScreen(e.target.checked)} checked={showPaymentMethodsScreen}>
                    <Label>Payment methods</Label>
                    <Hint>{`Add a card for this client`}</Hint>
                </Checkbox>
            )}
            {!isAddNewClient && <>
                <div className='icon'><CardIcon /></div>
                <div className='labels'>
                    <Label>Payment methods</Label>
                    {sources?.length ?
                        <Hint className="selected">{`${sources.length} payment methods on file`}</Hint>:
                        <Hint>{`Add a card for this client`}</Hint>
                    }
                </div>
                <div className='action'>
                    <div className='buttonLink brightBlue'
                        style={{display: 'inline-block', paddingBottom: '5px'}}
                        onClick={() => dispatch(push(`/client/${params.id}/payment-methods`))}>
                            Edit
                    </div>
                </div>
            </>}
        </Field>
        <div style={{marginTop:'40px'}}>
            {owner?.allow_lead && !!leadSources?.length && (
                <PSDropdown selected={leadSource}
                        nameProperty={'name'}
                        onSelect={(ls) => {
                            setLeadSource(ls)

                            if (ls.name.startsWith('Referred by '))
                                setShowLeadRefererModal(true)
                            else
                                setShowLeadCostModal(true)
                        }}
                        label={'Sent lead source'}
                        placeholder={<div className='grey'>Select</div>}>
                    <>
                        <Item key={`channel-by`} value={{name:`Referred by (${referer?.name ?? 'Select Client'})`}}>
                            Referred by ({referer?.name ?? 'Select Client'})
                        </Item>
                        {leadSources.map(ls => (
                            <Item key={`channel-${ls.id}`} value={ls}>
                                {ls.name}
                            </Item>
                        ))}
                    </>
                </PSDropdown>
            )}
            <PSDropdown selected={discount}
                    nameProperty={'label'}
                    onSelect={(discount) => setDiscount(discount)}
                    label={'Discount'} marginTop='10px'
                    placeholder={<div className='grey'>Select</div>}>
                <>
                    {discountPercentOptions.map(option => (
                        <Item key={`discount-${option.value}`} value={option}>
                            {option.label}
                        </Item>
                    ))}
                </>
            </PSDropdown>
        </div>
    </div>)

    const cancelLeadSource = () => {
        const referer = state.entities.users[client?.referer]
        if (referer) {
            setLeadSource({name:`Referred by (${referer.name})`})
            setLeadCost(0)
            setReferer(referer)
        }
        else {
            setLeadSource(leadSources?.find(ls => ls.id === client?.channel))
            setLeadCost(client?.cost)
            setReferer(undefined)
        }
        setShowLeadCostModal(false)
        setShowLeadRefererModal(false)
    }

    if (isLoading) {
        pageBody = <Spinner size="128" color="#314A68" />
    }
    return (
        <AppHeader
            title={isAddNewClient ? 'Clients' : 'Client'} middleWidget={<></>} showBackButton={true}
            onBack={() => isAddNewClient ? dispatch(push('/clients')) : history.goBack()}
        >
            <div className='pageWrapper' style={{paddingBottom:'140px'}}>
            <div className='settings'>
                <div className='header'>
                    <h1>{`${isAddNewClient ? 'New ' : ''}Client`}</h1>
                    {!isAddNewClient && (
                        <PSButtonDanger style={{height: '40px', marginLeft: 'auto'}}
                                        onClick={() => {setShowDeleteConfirmation(true)}}
                                        disabled={state.contacts.isSending}>
                            {state.contacts.isSending && <Dots />}
                            {!state.contacts.isSending && 'Delete'}
                        </PSButtonDanger>
                    )}
                    <PSButtonPrimary style={{height: '40px', marginLeft: isAddNewClient ? 'auto' : '10px'}}
                                    onClick={() => onSave()}
                                    disabled={!saveEnabled || state.contacts.isSending}>
                        {state.contacts.isSending && <Dots />}
                        {!state.contacts.isSending && 'Save'}
                    </PSButtonPrimary>
                </div>
                {pageBody}
            </div>
            </div>
            {showProSelector && (
                <SelectProModal
                    pros={proList || []}
                    selectedPro={proList[employee]}
                    onSelect={(item: any) => {
                        if (item.id === employee) {
                            setEmployee('')
                        }
                        else {
                            setEmployee(item.id === employee ? undefined : item.id)
                        }
                        setShowProSelector(false)
                    }}
                    onCancel={() => setShowProSelector(false) } />
            )}
            {showCustomFieldsModal && (
                <CustomFieldsModal onCancel={() => setShowCustomFieldsModal(false)}
                    currentAnswers={customFields}
                    fields={state.contacts.fields}
                    onDone={(answers) => {
                        setCustomFields(answers)
                        setShowCustomFieldsModal(false)
                    }} />
            )}
            {showDeleteConfirmation && (
                <DangerModal title='Delete client'
                    body='Deleting a client will remove them from your client list. Deleting &amp; blocking a client will remove them from your client list and they won’t be able to contact or book you in the future.'
                    extraButtonText='Delete &amp; block client'
                    buttonText='Delete client'
                    disableDismiss={true}
                    onClose={() => setShowDeleteConfirmation(false)}
                    onAction={handleDeleteClick} />
            )}
            {showInvalidNotificationConfig && (
                <GuardrailModal title='Delete client'
                    body={notificationConfigErrorMsg}
                    buttonText='OK' disableDismiss
                    onClose={() => setShowInvalidNotificationConfig(false)}
                    onAction={() => setShowInvalidNotificationConfig(false)} />
            )}
            {showLeadCostModal && (
                <Modal onClose={cancelLeadSource}>
                    <Header>
                        Cost
                    </Header>
                    <Body>
                        <Field className='field'>
                            <Label className='label'>Enter the amount paid for this lead.</Label>
                            <Input placeholder='Enter cost'
                                value={leadCost}
                                disabled={state.contacts.isSending}
                                onChange={e => filterMonetaryInput(shiftThirdDecimal(e.target.value), setLeadCost)} />
                            </Field>
                    </Body>
                    <Footer>
                        <FooterItem>
                            <PSButtonPrimary onClick={() => {
                                setReferer(undefined)
                                setShowLeadCostModal(false)
                            }}>
                                Done
                            </PSButtonPrimary>
                        </FooterItem>
                    </Footer>
                    <Close aria-label="Close modal" />
                </Modal>
            )}
            {showLeadRefererModal && (
                <SelectModal type="clients"
                    currentSelections={referer ? [referer] : []}
                    onSelect={(selected) => {
                        const referer = selected[0]
                        setLeadSource({name:`Referred by (${referer.name})`})
                        setLeadCost(0)
                        setReferer(referer)
                        setShowLeadRefererModal(false)
                    }}
                    onCancel={cancelLeadSource} />
            )}

        </AppHeader>
    )
}