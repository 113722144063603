import { Field, IInputProps, Input, Label, Message } from "@zendeskgarden/react-forms";
import { VALIDATION } from "@zendeskgarden/react-forms/dist/typings/utils/validation";
import React, { useEffect, useState } from "react";
import InputMask from 'react-input-mask'
import { isValidPhoneNumber } from "../../../utils";

type FieldProps = {
    fieldValue?: string,
    label: string,
    onChange: (changed: string) => void
}

export const PhoneInput = ({fieldValue, label, onChange}: FieldProps) => {
    const [phoneValidation, setPhoneValidation] = useState<VALIDATION>()

    useEffect(() => {
        if (!fieldValue || fieldValue?.length < 12) setPhoneValidation(undefined)
        else setPhoneValidation(isValidPhoneNumber(fieldValue) ? 'success' : 'error')
    }, [fieldValue])

    return <>
        <Field className="field">
            <Label className="label">
                {label}
            </Label>
            <InputMask mask='999-999-9999'
                    value={fieldValue}
                    alwaysShowMask={false}
                    maskChar={''}
                    onChange={e => onChange(e.target.value)}>
                    {(inputProps: IInputProps) => <Input {...inputProps}
                        className='mobileNumberInput'
                        placeholder='Enter phone number'
                        validation={phoneValidation}
                        />}
            </InputMask>
        </Field>
        {phoneValidation === 'error' && (
            <Message validation={phoneValidation}>Invalid phone number</Message>
        )}
    </>
}