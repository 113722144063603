import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../hooks";
import {saveUser, SettingsState} from "../../../modules/settings";
import {RootState} from "../../../store";
import {useSelector} from "react-redux";
import {Field, Hint, IInputProps, Input, Label, Message} from "@zendeskgarden/react-forms";
import {Prompt} from "react-router";
import {Item} from "@zendeskgarden/react-dropdowns";
import {PSDropdown} from "../../app/PSDropdown";
import {PSButtonPrimary} from "../../app/PSButton";
import {Dots} from "@zendeskgarden/react-loaders";
import localforage from 'localforage'
import InputMask from 'react-input-mask'
import {VALIDATION} from "@zendeskgarden/react-forms/dist/typings/utils/validation";

export const BusinessEdit = () => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const [saveEnabled, setSaveEnabled] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)

    const [user, setUser] = useState<any>({})
    const [industries, setIndustries] = useState<[{name: String, id:String}]>()

    const [entity, setEntity] = useState('')
    useEffect(() => {
        if (!user) return
        setEntity(user.entity)
    }, [user])

    const [industry, setIndustry] = useState<{name: String, id:String}>({id: '', name: ''})
    useEffect(() => {
        if (!user) return
        if (!industries) return
        industries.forEach(ind => {
            if (ind.id === user.industry)
            setIndustry(ind)
        })
    }, [user, industries])

    const [originalBusinessLine, setOriginalBusinessLine] = useState('')
    const [businessLine, setBusinessLine] = useState('')
    const [businessLineValidation, setBusinessLineValidation] = useState<VALIDATION | undefined>(undefined)
    useEffect(() => {
        if (!user) return
        const digits = user.main?.substring(2).replace(/\D/g, '')
        setOriginalBusinessLine(digits ? digits : '')
        setBusinessLine(digits ? digits : '')
    }, [user])

    useEffect(() => {
        const digits = businessLine?.replace(/\D/g, '')
        setHasEdits(entity !== user.entity || digits !== originalBusinessLine || industry.id !== user.industry)
    }, [user, entity, businessLine, industry, originalBusinessLine])

    useEffect(() => {
        const digits = businessLine?.replace(/\D/g, '')
        const USNumber = !businessLine?.match(/^1/)
        const businessLineValid = !digits || (digits?.length === 10 && USNumber)
        setSaveEnabled(industry.id.length > 0 && hasEdits && businessLineValid)
        setBusinessLineValidation(digits?.length && !USNumber ? 'error' : undefined)
    }, [entity, hasEdits, businessLine, industry])

    useEffect(() => {
        localforage.getItem('user').then((user: any) => setUser(user))
    }, [])

    useEffect(() => {
        if (!user) return
        localforage.getItem('industries')
          .then((industries: any) => setIndustries(industries.filter((industry: any) => industry.cancelled_date == null || industry.id == user.industry)))
    }, [user])

    const onSave = () => {
        if (state.isSending) return

        const userParams: any = {}
        userParams.id = user.id
        userParams.entity = entity
        userParams.main = businessLine
        if (!!industry && industry.id.length > 0)
            userParams.industry = industry.id

        // @ts-ignore
        dispatch(saveUser(userParams)).then(() => localforage.getItem('user').then((user: any) => setUser(user)))
    }    

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Business</h1>
                <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!saveEnabled || state.isSending}>
                    {state.isSending && <Dots />}
                    {!state.isSending && "Save"}
                </PSButtonPrimary>
            </div>
            <div className="form">
               <Field className="field">
                    <Label className="label">Name</Label>
                    <Hint>Appears on transactions and receipts sent to clients</Hint>
                    <Input placeholder="Business Name"
                           value={entity}
                           disabled={state.isSending}
                           onChange={e => setEntity(e.target.value)} />
                </Field>
                <Field className="field">
                    <PSDropdown selected={industry}
                                nameProperty="name"
                                disabled={state.isSending}
                                onSelect={(selection) => setIndustry(selection)}
                                label="Industry (required)">
                            <>
                                {industries && industries!.map((option: { name: String; id: String;}) => (
                                        <Item key={`industry-${option.id}`} value={option}>
                                            {option.name}
                                        </Item>
                                ))}
                            </>
                        </PSDropdown>
                </Field>
                <Field className="field">
                    <Label className="label">Business Line</Label>
                    <Hint>Appears on transactions and receipts sent to clients</Hint>
                    <InputMask mask="999-999-9999"
                               value={businessLine}
                               alwaysShowMask={false}
                               maskChar={''}
                               disabled={state.isSending}
                               onChange={e => setBusinessLine(e.target.value)}>
                               {(inputProps: IInputProps) => <Input {...inputProps}
                                   className="mobileNumberInput"
                                   placeholder="Business line" 
                                   disabled={state.isSending}
                                   validation={businessLineValidation}
                                   />}
                    </InputMask>
                    {!!businessLineValidation && (
                        <Message validation={businessLineValidation}>Only US phone numbers are allowed.</Message>
                    )}
                </Field>
            </div>
                
            <Prompt
                when={hasEdits && !state.isSending}
                message="Are you sure you'd like to leave this page without saving your changes?" />
        </div>
    )    
}
