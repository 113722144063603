import React, {useEffect} from "react";
import {AppHeader} from "../app/AppHeader";
import {useAppDispatch} from "../../hooks";
import {Redirect, useLocation} from "react-router";
import {Item} from "../../models/Item";
import {PackageEdit} from "../settings/packages/PackageEdit";
import {setItem} from "../../modules/settings";
import {ServiceEdit} from "../settings/services/ServiceEdit";
import {ProductEdit} from "../settings/products/ProductEdit";
import {SubscriptionEdit} from "../settings/subscriptions/SubscriptionEdit";
import {ClassesEdit} from "../settings/classes/ClassesEdit";
import {GiftEdit} from "../settings/gifts/GiftEdit";
import {ReservationEdit} from "../settings/reservations/ReservationEdit";

export const ItemEdit = () => {
    const dispatch = useAppDispatch()

    const routerLocation = useLocation()
    const locationState: any = routerLocation.state
    const item: Item = locationState?.item
    const refreshData: boolean = locationState?.forOnboarding ?? true

    useEffect(() => {
        if (item) dispatch(setItem(item))
    }, [dispatch, item])

    let editComponent
    switch (item.type) {
        case 'package':
            editComponent = <PackageEdit forOnboarding refreshData={refreshData} />
            break
        case 'product':
            editComponent = <ProductEdit forOnboarding refreshData={refreshData} />
            break
        case 'subscription':
            editComponent = <SubscriptionEdit forOnboarding refreshData={refreshData} />
            break
        case 'class':
            editComponent = <ClassesEdit forOnboarding refreshData={refreshData} />
            break
        case 'gift':
            editComponent = <GiftEdit forOnboarding refreshData={refreshData} />
            break
        case 'reservation':
            editComponent = <ReservationEdit forOnboarding refreshData={refreshData} />
            break
        case 'service':
        default:
            editComponent = <ServiceEdit forOnboarding refreshData={refreshData} />
            break
    }

    if (!item) {
        return <Redirect to='/onboarding/hours' />
    }

    return (
        <AppHeader title="Setup" showBackButton middleWidget={null}>
            <div className="pageWrapper">
                {editComponent}
            </div>
        </AppHeader>
    )
}