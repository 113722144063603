import {ReactComponent as OverviewIcon} from "../icons/ps_general_logo_nobg.svg"
import {ReactComponent as SetupIcon} from "../icons/ps_general_magic_wand_nobg.svg"
import {ReactComponent as ConferencingIcon} from "../icons/ps_general_video_nobg.svg"
import {ReactComponent as ClassIcon} from "../icons/ps_settings_classes_nobg.svg"
import {ReactComponent as GroupIcon} from "../icons/icon-settings-group-meassaging.svg"
import {ReactComponent as TaskIcon} from "../icons/ps_general_check_nobg.svg"
import {ReactComponent as GiftIcon} from "../icons/ps_settings_gift_certs_nobg.svg"
import {ReactComponent as TemplateIcon} from "../icons/ps_settings_saved_messages_nobg.svg"
import {ReactComponent as ReminderIcon} from "../icons/ps_general_reminder_nobg.svg"
import {ReactComponent as FieldIcon} from "../icons/ps_general_more_nobg.svg"
import {ReactComponent as PackageIcon} from "../icons/ps_settings_packages_nobg.svg"
import {ReactComponent as EstimateIcon} from "../icons/ps_general_invoice_nobg.svg"
import {ReactComponent as SubscriptionIcon} from "../icons/icon-settings-subscriptions.svg"
import {ReactComponent as ContractIcon} from "../icons/ps_settings_contracts_nobg.svg"
import {ReactComponent as LinkIcon} from "../icons/icon-settings-link-suitelink.svg"
import {ReactComponent as WidgetIcon} from "../icons/ps_settings_online_booking_nobg.svg"
import {ReactComponent as ChatIcon} from "../icons/ps_general_chat_nobg.svg"
import {ReactComponent as RecordIcon} from "../icons/ps_general_form_nobg.svg"
import {ReactComponent as DiscountIcon} from "../icons/ps_general_percentage_nobg.svg"
import {ReactComponent as SalestaxIcon} from "../icons/ps_general_sales_tax_nobg.svg"
import {ReactComponent as LeadIcon} from "../icons/ps_settings_online_leads_nobg.svg"
import {ReactComponent as SurchargeIcon} from "../icons/ps_general_surcharge_nobg.svg"
import {ReactComponent as StaffingIcon} from "../icons/icon-settings-staffing-teams.svg"
import {ReactComponent as ReservationIcon} from "../icons/ps_settings_reservations_nobg.svg"
import {ReactComponent as ServicesIcon} from "../icons/ps_settings_services_nobg.svg"
import {ReactComponent as RecurringIcon} from "../icons/ps_settings_subscriptions_nobg.svg"
import {ReactComponent as GratuityIcon} from "../icons/ps_settings_gratuity_nobg.svg"
import {ReactComponent as ProductIcon} from "../icons/ps_settings_products_nobg.svg"
import {ReactComponent as ItemizeIcon} from "../icons/ps_settings_services_nobg.svg"
import {ReactComponent as PaymentIcon} from "../icons/ps_settings_payouts_nobg.svg"
import {ReactComponent as ReaderIcon} from "../icons/ps_settings_card_reader_nobg.svg"
import {ReactComponent as InvoiceIcon} from "../icons/ps_general_invoice_nobg.svg"
import {ReactComponent as BillingIcon} from "../icons/icon-settings-dashboard_billing.svg"
import {ReactComponent as DripIcon} from "../icons/icon-settings-drip.svg"
import {ReactComponent as BookingIcon} from "../icons/ps_settings_schedule_nobg.svg"
import {ReactComponent as BenchmarkIcon} from "../icons/icon-settings-benchmark-leaderboards.svg"
import {ReactComponent as LocationIcon} from "../icons/ps_settings_location_nobg.svg"
import {ReactComponent as PaypalIcon} from "../icons/ps_settings_online_payment_nobg.svg"
import {ReactComponent as CategoryIcon} from "../icons/ps_settings_categories_nobg.svg"
import {ReactComponent as TriggerIcon} from "../icons/icon-settings-triggers-smart campaigns.svg"
import {ReactComponent as ImportsIcon} from "../icons/ps_settings_data_import_nobg.svg"
import {ReactComponent as MarkPaidIcon} from "../icons/ps_settings_mark_paid.svg"
import {ReactComponent as InfoIcon} from "../icons/ps_general_info_nobg.svg"
import {ReactComponent as BNPLIcon} from "../icons/ps_settings_payouts_nobg.svg"
import {ReactComponent as ResourceIcon} from "../icons/tool.svg"
import { Industry } from "./Industry"
import { UserInstance } from "./User"
import { Edition } from "./Edition"

export const featureEnabled = (featureID: string, account: any, industry: Industry): boolean => {
    if (!featureAllowed(featureID, industry))
        return false

    if (featureID === 'field')
        return featureEnabled('lead', account, industry) || featureEnabled('widget', account, industry)

    return !!account[`allow_${featureID}`]
}

export const featureAllowed = (featureID: string, industry: Industry): boolean => {
    if (featureID === 'class')
        return industry.classes
    else if (featureID === 'estimate')
        return industry.estimate
    else if (featureID === 'reservation')
        return industry.reservations
    else if (featureID === 'services')
        return industry.services
    else if (featureID === 'recurring')
        return industry.recurring
    else if (featureID === 'gratuity')
        return industry.gratuity
    else if (featureID === 'product')
        return industry.product
    else
        return true
}

export const featureTutorial = (featureID: string): string => {
    if (featureID === 'estimate')
        return 'estimates'
    else if (featureID === 'lead')
        return 'leads'
    else if (featureID === 'package')
        return 'packages'
    else if (featureID === 'gift')
        return 'gifts'
    else if (featureID === 'class')
        return 'classes'
    else if (featureID === 'subscription')
        return 'subscriptions'
    else if (featureID === 'record')
        return 'forms'
    else if (featureID === 'contract')
        return 'contracts'
    else if (featureID === 'staffing')
        return 'employees'
    else if (featureID === 'template')
        return 'templates'

    return featureID
}

export const featureName = (feature: Feature): string => {
    if (feature.id === 'overview')
        return 'Overview'
    else if (feature.id === 'setup')
        return 'Get started'
    else if (feature.id === 'imports')
        return 'Import contracts'
    else if (feature.id === 'conference')
        return 'Video conference'

    return feature.name
}

export const featureIcon = (featureID: string): React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }> => {
    if (featureID === 'overview')
        return OverviewIcon
    else if (featureID === 'setup')
        return SetupIcon
    else if (featureID === 'conference')
        return ConferencingIcon
    else if (featureID === 'class')
        return ClassIcon
    else if (featureID === 'group')
        return GroupIcon
    else if (featureID === 'task')
        return TaskIcon
    else if (featureID === 'gift')
        return GiftIcon
    else if (featureID === 'template')
        return TemplateIcon
    else if (featureID === 'reminder')
        return ReminderIcon
    else if (featureID === 'field')
        return FieldIcon
    else if (featureID === 'package')
        return PackageIcon
    else if (featureID === 'estimate')
        return EstimateIcon
    else if (featureID === 'subscription')
        return SubscriptionIcon
    else if (featureID === 'contract')
        return ContractIcon
    else if (featureID === 'link')
        return LinkIcon
    else if (featureID === 'widget')
        return WidgetIcon
    else if (featureID === 'chat')
        return ChatIcon
    else if (featureID === 'record')
        return RecordIcon
    else if (featureID === 'discount')
        return DiscountIcon
    else if (featureID === 'salestax')
        return SalestaxIcon
    else if (featureID === 'lead')
        return LeadIcon
    else if (featureID === 'surcharge')
        return SurchargeIcon
    else if (featureID === 'staffing')
        return StaffingIcon
    else if (featureID === 'reservation')
        return ReservationIcon
    else if (featureID === 'services')
        return ServicesIcon
    else if (featureID === 'recurring')
        return RecurringIcon
    else if (featureID === 'gratuity')
        return GratuityIcon
    else if (featureID === 'product')
        return ProductIcon
    else if (featureID === 'itemize')
        return ItemizeIcon
    else if (featureID === 'payment')
        return PaymentIcon
    else if (featureID === 'reader')
        return ReaderIcon
    else if (featureID === 'invoice')
        return InvoiceIcon
    else if (featureID === 'billing')
        return BillingIcon
    else if (featureID === 'drip')
        return DripIcon
    else if (featureID === 'booking')
        return BookingIcon
    else if (featureID === 'benchmark')
        return BenchmarkIcon
    else if (featureID === 'location')
        return LocationIcon
    else if (featureID === 'paypal')
        return PaypalIcon
    else if (featureID === 'category')
        return CategoryIcon
    else if (featureID === 'trigger')
        return TriggerIcon
    else if (featureID === 'imports')
        return ImportsIcon
    else if (featureID === 'mark')
        return MarkPaidIcon
    else if (featureID === 'bnpl')
        return BNPLIcon
    else if (featureID === 'resource')
        return ResourceIcon
    else
        return InfoIcon
}

export const checkPartnerFeature = (feature: Feature, account: UserInstance, edition?: Edition) => {
    if (edition?.allow_basic_plan) {
        if (!account.plan) return edition.basic_features.includes(feature.id)
        else return [...edition.basic_features, ...edition.features].includes(feature.id)
    }
    return false
}

export const featureRequiredPlan = (feature?: Feature) => {
    return feature?.requires_plus ? 'plus' : feature?.requires_premium ? 'premium' : ''
}

export const featureRequiresUpgrade = (feature: Feature, account: UserInstance) => {
    if (!feature.requires_plus && !feature.requires_premium) return false
    else if (!account.plan) return true
    else if (feature.requires_plus) return account.plan === 'premium'
    return false
}

type DecorateFeaturesReturn = {
    recommended: Feature[],
    available: Feature[]
}
export const decorateFeatures = (features: Feature[], account: UserInstance, industry: Industry, edition?: Edition): DecorateFeaturesReturn => {
    const recommended: Feature[] = []
    const available: Feature[] = []
    const industryDefaultFeatures = industry.features.split(',')

    features.sort((a, b) => a.seqnum - b.seqnum).forEach(feature => {
        if (['booking', 'invoice', 'payment'].includes(feature.id))
            return

        if (featureAllowed(feature.id, industry)) {
            const isEnabled = featureEnabled(feature.id, account, industry)
            const isLocked = featureRequiresUpgrade(feature, account) && !checkPartnerFeature(feature, account, edition)

            if (industryDefaultFeatures.includes(feature.id))
                recommended.push({
                    ...feature,
                    isRecommended: true,
                    isLocked,
                    isEnabled
                })
            else
                available.push({
                    ...feature,
                    isLocked,
                    isEnabled
                })
        }
    })

    return { recommended, available }
}

export type Feature = {
    id: string,
    name: string,
    seqnum: number,
    parent: string,
    memo: string,
    pitch: string,

    cancelled_date: string,
    created_date: string,
    last_modified_date: string,

    requires_plus: boolean,
    requires_premium: boolean,

    isRecommended?: boolean,
    isLocked?: boolean,
    isEnabled?: boolean,
}