import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {useLocation} from "react-router";
import {Invoice} from "../../../models/Invoice";
import {AppHeader} from "../../app/AppHeader";
import {ReactComponent as CheckmarkImage} from "../../../icons/big_checkmark.svg";
import {Link} from "react-router-dom";
import {TransactionProgress} from "../TransactionProgress";

type InvoiceLocationState = {
    mark_paid: boolean,
    invoice: Invoice,
}

export const InvoiceSuccess = () => {
    const state: any = useSelector((state: RootState) => state.entities)

    const location = useLocation<InvoiceLocationState>()
    const locationState = location.state
    const markPaid: boolean = locationState?.mark_paid
    const invoiceData: Invoice = locationState?.invoice
    const [invoice, setInvoice] = useState<Invoice>()
    useEffect(() => setInvoice(new Invoice(invoiceData)), [invoiceData])

    const user = state.users[invoice?.client ?? ''] || {}
    let successMessage = `Invoice #${invoice?.number ?? ''} was sent to ${user?.name || 'your client'}`
    if (markPaid)
        successMessage = `Invoice #${invoice?.number ?? ''} with ${user?.name || 'your client'} was saved`

    return (
        <AppHeader title="Send invoice"
                   middleWidget={<TransactionProgress created reviewed sent secondStep="Preview" />}
        >
            <div className="transactionSuccess">
                <CheckmarkImage/>
                <h1>Success</h1>
                <div className="details">{successMessage}</div>

                <div className="links">
                    <div className="link">
                        <Link to="/invoice/new">Create another</Link>
                        <div>Create a new invoice</div>
                    </div>
                    <div className="link">
                        <Link to={`/record/invoice/${invoice?.id}`}>
                            View invoice
                        </Link>
                        <div>View this invoice record</div>
                    </div>
                    <div className="link">
                        <Link to="/messages">Inbox</Link>
                        <div>View your inbox</div>
                    </div>
                    <div className="link">
                        <Link to="/calendar">Calendar</Link>
                        <div>View your calendar</div>
                    </div>
                    <div className="link">
                        <Link to="/">Dashboard</Link>
                        <div>View your PocketSuite home screen</div>
                    </div>
                </div>
            </div>
        </AppHeader>
    )
}