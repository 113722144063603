import React, {useEffect, useState} from "react";
import {DangerButton, PSButton} from "../../app/PSButton";
import {ReactComponent as LeftArrow} from "../../../icons/left-arrow.svg";
import {useHistory, useParams} from "react-router";
import moment from "moment";
import {apiClient} from "../../../modules/apiclient";
import {Schedule} from "../../../models/Schedule";
import {LessonCard} from "./LessonCard";
import {Spinner} from "@zendeskgarden/react-loaders";
import {ReactComponent as AlertIcon} from "../../../icons/alert_red_circle.svg";
import {
    Body, Close,
    Footer,
    FooterItem,
    Header,
    Modal
} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import {AddLessonModal} from "./AddLessonModal";
import {GuardrailModal} from "../GuardrailModal";
import {deleteLesson} from "../../../modules/settings";
import {useAppDispatch} from "../../../hooks";

export const ClassesLessonView = () => {
    const dispatch = useAppDispatch()
    const params: any = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [lessons, setLessons] = useState<Schedule[]>([])
    const [bookingCounts, setBookingCounts] = useState<any>({})
    const [showEditModal, setShowEditModal] = useState(false)
    const [editLesson, setEditLesson] = useState<Schedule | undefined>()
    const [editFuture, setEditFuture] = useState(false)
    const [showAddSingleModal, setShowAddSingleModal] = useState(false)
    const [showAddMultipleModal, setShowAddMultipleModal] = useState(false)
    const [showEditLessonModal, setShowEditLessonModal] = useState<Schedule | undefined>()
    const [showDeleteModal, setShowDeleteModal] = useState<Schedule | undefined>()
    const [showDeleteSingleModal, setShowDeleteSingleModal] = useState<Schedule | undefined>()
    const [forceRefresh, setForceRefresh] = useState(false)

    useEffect(() => {
        setIsLoading(true)

        const data = {
            start: moment().add(-1, 'weeks').toISOString(),
            end: moment().add(6, 'months').toISOString(),
            item: params.classID,
        }
        apiClient.post('/calendar/sync', data)
            .then(resp => resp.data)
            .then(json => {
                // Have a count of bookings per lesson, so we can calculate spots remaining
                const bookingCounts: any = {}
                json.bookings.forEach((booking: any) => {
                    if (bookingCounts[booking.lesson])
                        bookingCounts[booking.lesson] += 1
                    else
                        bookingCounts[booking.lesson] = 1
                })
                setBookingCounts(bookingCounts)

                // Filter out the multiple lessons with the specified schedule, if we have a schedule
                const lessons = json.classes
                    .filter((c: any) => !params.lessonID || c.schedule === params.lessonID)
                    .map((c: any) => {
                        const s = new Schedule()
                        s.setData(c)
                        return s
                    })
                setLessons(lessons)
            })
            // @ts-ignore
            .catch(error => window.Rollbar.error('classLesson calendar sync error', error))
            .finally(() => setIsLoading(false))

    }, [params.classID, params.lessonID, forceRefresh])

    let history = useHistory()
    const goPreviousPage = () => {
        history.goBack()
    }

    const onEdit = (schedule: Schedule) => {
        setEditLesson(schedule)
        if (schedule.schedule_instance?.date)
            setShowEditModal(true)
        else {
            setEditFuture(false)
            setShowEditLessonModal(editLesson as Schedule)
        }

    }

    const onDelete = (schedule: Schedule) => {
        if (schedule.schedule_instance?.date)
            setShowDeleteModal(schedule)
        else
            setShowDeleteSingleModal(schedule)
    }

    const cancelLesson = (schedule: Schedule, cancelAll: boolean) => {
        setIsLoading(true)
        setShowDeleteModal(undefined)

        const data = {
            notify_all: false,
            cancel_all: cancelAll,
        }
        apiClient.post(`/class/${schedule.id}/cancel`, data)
            .then(resp => resp.data)
            .then(json => {
                setForceRefresh(!forceRefresh)
            })
    }

    return (
        <div className="servicesSettings classSchedule">
            <div className="header">
                <PSButton style={{marginLeft: 0}}
                          onClick={() => goPreviousPage()}
                >
                    <LeftArrow/>
                </PSButton>
                <h1>Lessons</h1>
                <PSButton style={{marginLeft: 'auto'}}
                          onClick={() => {
                              setEditLesson(undefined)
                              setShowAddMultipleModal(true)
                          }}
                >
                    Add multiple lessons
                </PSButton>
                <PSButton style={{marginLeft: '16px'}}
                          onClick={() => {
                              setEditLesson(undefined)
                              setShowAddSingleModal(true)
                          }}
                >
                    Add a single lesson
                </PSButton>
            </div>
            <div className="separator" />
            <div>
                {isLoading && (
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                )}
                {!isLoading && lessons.map((schedule) => {
                    return <LessonCard key={`multiple-lesson-${schedule.id || schedule.date}`}
                                       schedule={schedule}
                                       bookingCount={bookingCounts[schedule.id as string]}
                                       onEdit={() => onEdit(schedule)}
                                       onDelete={() => onDelete(schedule)} />
                })}
            </div>
            {showEditModal && (
                <Modal onClose={() => {
                    setShowEditModal(false)
                    setEditLesson(undefined)
                    setEditFuture(false)
                }}>
                    <Header style={{display: 'flex'}}>
                        <span style={{fontWeight: 700, paddingLeft: 8}}>Select which classes to edit</span>
                    </Header>
                    <Body>
                        This is a repeating class. If you’d like the edits to apply to just the lesson on this date select “Edit this class”. However, if you’d like the edits that you make here to apply to this class as well as all future classes select “Edit this and future classes”.
                    </Body>
                    <Footer>
                        <FooterItem>
                            <Button onClick={() => {
                                        setEditFuture(true)
                                        setShowEditLessonModal(editLesson as Schedule)
                                        setShowEditModal(false)
                                    }}
                                    isBasic>
                                Edit this and future classes
                            </Button>
                        </FooterItem>
                        <FooterItem>
                            <Button onClick={() => {
                                        setEditFuture(false)
                                        setShowEditLessonModal(editLesson as Schedule)
                                        setShowEditModal(false)
                                    }}
                                    isPrimary
                            >
                                Edit this class
                            </Button>
                        </FooterItem>
                    </Footer>
                    <Close aria-label="Close modal" />
                    {/*
                        // TODO Open up edit lesson modal with the selection and this schedule
                    */}
                </Modal>
            )}
            {(showAddSingleModal || showAddMultipleModal) && (
                <AddLessonModal onClose={() => {
                                    setShowAddSingleModal(false)
                                    setShowAddMultipleModal(false)
                                }}
                                onDone={() => {
                                    setForceRefresh(!forceRefresh)
                                    setShowAddSingleModal(false)
                                    setShowAddMultipleModal(false)
                                }}
                                isSingleLesson={showAddSingleModal}
                                showAllFields={true}
                                classID={params.classID}
                                classData={editLesson}
                />
            )}
            {showDeleteModal && (
                <Modal onClose={() => setShowDeleteModal(undefined)}>
                    <Header style={{display: 'flex', color: '#eb5757'}}>
                        <AlertIcon />
                        <span style={{fontWeight: 700, paddingLeft: 8}}>Select which classes to cancel</span>
                    </Header>
                    <Body>
                        This is a repeating class. If you’d like to cancel just the lesson on this date select “Cancel this class”. However, if you’d like to cancel this class as well as all future classes select “Cancel this and future classes”.
                    </Body>
                    <Footer>
                        <FooterItem>
                            <Button onClick={() => cancelLesson(showDeleteModal, true)}
                                    style={{color: '#EB5757', fontWeight: 700}}
                                    isBasic
                            >
                                Cancel this and future classes
                            </Button>
                        </FooterItem>
                        <FooterItem>
                            <DangerButton onClick={() => cancelLesson(showDeleteModal, false)} isPrimary>
                                Cancel this class
                            </DangerButton>
                        </FooterItem>
                    </Footer>
                    <Close aria-label="Close modal" />
                </Modal>
            )}
            {showDeleteSingleModal && (
                <GuardrailModal title="Are you sure?"
                                body="Are you sure you'd like to cancel this lesson?"
                                extraButtonText="Cancel and notify"
                                buttonText="Cancel"
                                onClose={() => setShowDeleteSingleModal(undefined)}
                                onAction={(actionButton) => {
                                    setShowDeleteSingleModal(undefined)
                                    setIsLoading(true)
                                    dispatch(deleteLesson((showDeleteSingleModal as Schedule).id as string, actionButton === 2, false))
                                        // @ts-ignore
                                        .then(() => setForceRefresh(!forceRefresh))
                                }}
                />
            )}
            {showEditLessonModal && (
                <AddLessonModal onClose={() => setShowEditLessonModal(undefined)}
                                onDone={() => {
                                    setForceRefresh(!forceRefresh)
                                    setShowEditLessonModal(undefined)
                                }}
                                showAllFields={true}
                                classID={((editLesson as Schedule).id as string)}
                                isSingleLesson={!(editLesson as Schedule).schedule_instance}
                                classData={editLesson as Schedule}
                                editFuture={editFuture}
                />
            )}
        </div>
    )
}