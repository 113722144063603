import React, {useCallback, useEffect, useState} from "react";
import {
    Body, Close,
    Footer,
    FooterItem,
    Header,
    Modal
} from "@zendeskgarden/react-modals";
import {Field, Input, Label} from "@zendeskgarden/react-forms";
import {Button} from "@zendeskgarden/react-buttons";
import {PSDropdown} from "../../app/PSDropdown";
import {
    classSizeOptions, daysOfTheWeekOptions,
    durationOptionsNoTBD, lessonCountOptions, lessonFrequencyOptions,
    SelectOption, timeOfDayOptions,
} from "../Options";
import {Item} from "@zendeskgarden/react-dropdowns";
import {Datepicker} from "@zendeskgarden/react-datepickers";
import moment from "moment";
import localforage from "localforage";
import {apiClient} from "../../../modules/apiclient";
import debounce from "lodash.debounce";
import {Schedule} from "../../../models/Schedule";
import {PSMultiselect} from "../../app/PSMultiselect";
import {generateRandomID} from "../../../utils/numbers";

type Props = {
    onClose: () => void,
    onDone: (data?: any) => void,
    showAllFields: boolean,
    classID: string,
    isSingleLesson: boolean,
    classData?: Schedule,
    editFuture?: boolean,
    temporaryObject?: boolean
}

export const AddLessonModal = ({onClose, onDone, isSingleLesson, showAllFields, classID, classData, editFuture, temporaryObject}: Props) => {
    const [duration, setDuration] = useState<SelectOption>(durationOptionsNoTBD[9])
    const [date, setDate] = useState<Date | undefined>()
    const [time, setTime] = useState<SelectOption | ''>(timeOfDayOptions[36])
    // Below are for multiple lessons
    const [frequency, setFrequency] = useState<SelectOption | undefined>(lessonFrequencyOptions[0])
    const [dow, setDOW] = useState<SelectOption[] | never[]>([])
    const [instances, setInstances] = useState<SelectOption | ''>(!temporaryObject ? lessonCountOptions[0] : '')
    // The below 3 items are only available if showAllFields are set to true
    const [classSize, setClassSize] = useState<SelectOption | ''>('')
    const [classInstructor, setClassInstructor] = useState<any>('')
    const [location, setLocation] = useState('')

    const [saveEnabled, setSaveEnabled] = useState(false)

    // Location (address) prefill
    const [selectedAddress, setSelectedAddress] = useState('')
    const [addressOptions, setAddressOptions] = useState([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchAddress = useCallback(debounce((address: string) => {
            setSelectedAddress('')

            if (!address) {
                setAddressOptions([])
                return
            }

            apiClient.post(`/location/autocomplete?q=${address}`)
                .then(resp => resp.data)
                .then((json) => {
                    const addressList = json.predictions.map((p: any) => p.description)
                    setAddressOptions(addressList)
                })
                // @ts-ignore
                .catch(error => window.Rollbar.error('SingleLesson locationAutocomplete error', error))
        }, 250, {trailing: true})
        , [])

    useEffect(() => {
        // Skip loading for an edit
        if (classData && classData.location === location) return

        searchAddress(location)
    }, [classData, location, searchAddress])

    const selectAddress = (address: string) => {
        setSelectedAddress(address)
        setLocation(address)
        setAddressOptions([])
    }

    const [userRecord, setUserRecord] = useState<any>({})
    useEffect(() => {
        localforage.getItem('user').then((user: any) => {
            setUserRecord(user)
        })
    }, [])

    const [instructors, setInstructors] = useState<any[]>([])
    useEffect(() => {
        if (!userRecord) return

        if (userRecord.type === 'company') {
            apiClient.post('/payee/sync')
                .then(resp => resp.data)
                .then((json: any) => {
                    setInstructors(json.contacts)
                })
                .catch(error => {
                    // @ts-ignore
                    window.Rollbar.error('payeeSync error', error)
                })
        }
        else {
            setInstructors([userRecord])
        }
    }, [userRecord])

    // Load fields for an edit
    useEffect(() => {
        if (!classData) return

        const selectedDuration = durationOptionsNoTBD.find((option) => Number(option.value) === Number(classData.duration))
        if (selectedDuration)
            setDuration(selectedDuration)
        else
            setDuration(durationOptionsNoTBD[1])

        const classDate = moment(classData.date)
        setDate(classDate.toDate())

        const timeValue = classDate.hour() + (classDate.minute() / 60)
        const selectedTime = timeOfDayOptions.find((option) => Number(option.value) === timeValue)
        setTime(selectedTime || '')

        if (!isSingleLesson) {
            const frequencyInitial = classData.schedule_instance?.frequency === 'weekly' ? '' : 'm'
            const frequencyValue = classData.schedule_instance?.interval + frequencyInitial
            const selectedFrequency = lessonFrequencyOptions.find((option) => option.value === frequencyValue)
            setFrequency(selectedFrequency)

            const dow: SelectOption[] = []
            daysOfTheWeekOptions.forEach((option) => {
                if (Number(option.value) & Number(classData?.schedule_instance?.dow))
                    dow.push(option)
            })
            setDOW(dow)

            if (classData.schedule_instance?.instances) {
                const selectedInstance = lessonCountOptions.find((option) => option.value === classData.schedule_instance?.instances)
                setInstances(selectedInstance || '')
            }
        }

        if (showAllFields) {
            if (classData.capacity) {
                const selectedClassSize = classSizeOptions.find((option) => Number(option.value) === Number(classData.capacity))
                setClassSize(selectedClassSize || '')
            }

            if (classData.location) {
                setLocation(classData.location)
            }
        }
    }, [showAllFields, classData, isSingleLesson])

    // Load instructor for an edit
    useEffect(() => {
        if (showAllFields && classData) {
            if (classData.employee && instructors.length > 0) {
                const selectedInstructor = instructors.find((user) => user.id === classData.employee)
                setClassInstructor(selectedInstructor || '')
            }
        }
    }, [classData, showAllFields, instructors])

    useEffect(() => {
        if (isSingleLesson)
            if (showAllFields)
                setSaveEnabled(!!time && !!date && !!classSize)
            else
                setSaveEnabled(!!time && !!date)
        else
            if (showAllFields)
                setSaveEnabled(!!time && !!date && !!classSize && dow.length > 0)
            else
                setSaveEnabled(!!time && !!date && dow.length > 0 && !!instances)
    }, [showAllFields, isSingleLesson, time, date, classSize, dow, instances])

    const onSave = () => {
        const data: any = {
            edit_all: editFuture || false,
            notify_all: false,
            duration: duration.value,
        }

        // Convert time dropdown float value to hours and minutes
        const timeValue = (time as SelectOption).value
        let minutes = 0
        if (timeValue.indexOf('.') > -1) {
            minutes = Number('.' + timeValue.split('.')[1]) * 60
        }
        data.date = moment(date)
            .seconds(0)
            .minutes(minutes)
            .hours(Number(timeValue))
            .toISOString()

        if (classData)
            data.item = classData.id
        else
            data.item = classID

        if (classSize)
            data.capacity = classSize.value
        if (location)
            data.location = location
        if (classInstructor)
            data.employee = classInstructor.id
        else
            data.employee = ''

        if (!isSingleLesson) {
            const dowValue = (dow as SelectOption[])
                    .reduce((sum, o) => sum + Number(o.value), 0)
            const frequencyValue = (frequency as SelectOption).value.endsWith('m') ? 'monthly' : 'weekly'
            const intervalValue = parseInt((frequency as SelectOption).value) || 1

            data.schedule = {
                duration: duration.value,
                dow: dowValue,
                interval: intervalValue,
                frequency: frequencyValue,
                date: data.date,
                instances: (instances as SelectOption).value,
            }

            if (editFuture) {
                data.schedule.id = classData?.schedule_instance?.id
            }
        }

        // If this is for a new class, we just save the object to send later, and render it in the list
        if (temporaryObject) {
            data.id = classData?.temporary_id || classID || generateRandomID()
            data.schedule_instance = data.schedule
            onDone(data)
            return
        }

        const url = classData ? `/class/${classData.id}` : '/class'
        apiClient.post(url, data)
            .then(resp => resp.data)
            .then(json => {
                onDone()
            })
            // @ts-ignore
            .catch(error => window.Rollbar.error('add single class error', error))
    }

    return (
        <Modal className="addLessonModal" onClose={onClose}>
            <Header>{isSingleLesson ? 'Single lesson' : 'Multiple lessons'}</Header>
            <Body>
                <PSDropdown selected={duration}
                            nameProperty="label"
                            onSelect={(selection) => setDuration(selection)}
                            label="Duration (required)">
                    <>
                        {durationOptionsNoTBD.map(option => (
                            <Item key={`duration-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>

                <Field className="field">
                    <Label className="label">Start date (required)</Label>
                    <Datepicker value={date}
                                minValue={moment().toDate()}
                                onChange={date => setDate(date)}
                    >
                        <Input
                            style={{height: '48px'}}
                            placeholder="Select start date" />
                    </Datepicker>
                </Field>

                <PSDropdown selected={time}
                            nameProperty="label"
                            onSelect={(selection) => setTime(selection)}
                            label="Time (required)">
                    <>
                        {timeOfDayOptions.map(option => (
                            <Item key={`timeofday-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>

                {!isSingleLesson && !classData && (
                    <>
                        <PSDropdown selected={frequency}
                                    nameProperty={"label"}
                                    onSelect={(selection) => setFrequency(selection)}
                                    label={"Repeat every (required)"}>
                            <>
                                {lessonFrequencyOptions.map(option => (
                                    <Item key={`lesson-frequency-${option.value}`} value={option}>
                                        {option.label}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>

                        <PSMultiselect selected={dow}
                                       nameProperty="label"
                                       onSelect={(selection) => setDOW(selection)}
                                       label="Days of the week (required)">
                            <>
                                {daysOfTheWeekOptions.map(option => (
                                    <Item key={`dow-${option.value}`} value={option}>
                                        {option.label}
                                    </Item>
                                ))}
                            </>
                        </PSMultiselect>

                        <PSDropdown selected={instances}
                                    nameProperty={"label"}
                                    onSelect={(selection) => setInstances(selection)}
                                    label={"Number of lessons (required)"}>
                            <>
                                {(temporaryObject ? lessonCountOptions.slice(1) : lessonCountOptions).map(option => (
                                    <Item key={`lesson-count-${option.value}`} value={option}>
                                        {option.label}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>

                    </>
                )}

                {showAllFields && (
                    <>
                        <PSDropdown selected={classSize}
                                    nameProperty="label"
                                    onSelect={(selection) => setClassSize(selection)}
                                    label="Class size (required)">
                            <>
                                {classSizeOptions.map(option => (
                                    <Item key={`class-size-${option.value}`} value={option}>
                                        {option.label}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>

                        <PSDropdown selected={classInstructor}
                                    nameProperty="name"
                                    onSelect={(selection) => setClassInstructor(selection)}
                                    placeholder={<div className="grey">Select class instructor</div>}
                                    label="Instructor">
                            <>
                                {instructors.map(payee => (
                                    <Item key={`payee-${payee.id}`} value={payee}>
                                        {payee.name}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>

                        <Field className="field">
                            <Label className="label">Location</Label>
                            <Input placeholder="Address"
                                   value={selectedAddress || location}
                                   onChange={e => setLocation(e.target.value)} />
                            {addressOptions.length > 0 && selectedAddress === '' && (
                                <div className="searchResults"
                                >
                                    {addressOptions.map((address: string) => (
                                        <div className="entry"
                                             key={address}
                                             onClick={() => selectAddress(address)}
                                        >
                                            {address}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </Field>
                    </>
                )}
            </Body>
            <Footer style={{paddingBottom: 32}}>
                <FooterItem>
                    <Button onClick={() => onSave()}
                            disabled={!saveEnabled}
                            isPrimary>
                        Save {editFuture ? 'all' : ''}
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )
}