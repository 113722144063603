import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../hooks";
import {PSButton, PSButtonPrimary} from "../../app/PSButton";
import {push} from 'connected-react-router';
import {useCalendarSyncLocationState, Calendar} from "../../../models/Calendar";
import {Hint} from "@zendeskgarden/react-forms";
import {intToColorString} from "../../../utils/colors"
import {ReactComponent as LeftArrow} from "../../../icons/left-arrow.svg";
import {useHistory} from "react-router";


export const CalendarSyncFromPicker = () => {
    const history = useHistory()
    const locationState = useCalendarSyncLocationState()
    const dispatch = useAppDispatch()

    const [calendars, setCalendars] = useState<Calendar[]>([])
    const [changes, setChanges] = useState(false)

    useEffect(() => {
        setCalendars(c => locationState?.calendars ?? c)
        setChanges(c => locationState?.changes ?? c)
    }, [locationState])

    const onDone = () => {
        dispatch(push(`/settings/calendar/setup/${locationState?.credential.id}`,
            {
                ...locationState,
                calendars: calendars,
                changes: changes
            }))
    }

    const onSelected = (selectedCalendar: Calendar) => {
        calendars.forEach((c: Calendar) => {
            if (c.id === selectedCalendar.id)
                c.sync_from = !c.sync_from
        })

        setCalendars([...calendars])
        setChanges(true)
    }

    return (
        <div className="servicesSettings">
            <div className="header">
                <PSButton style={{marginLeft: 0, marginRight: 16}}
                        onClick={() => history.goBack()}
                >
                    <LeftArrow />
                </PSButton>
                <h1>Selected calendar(s)</h1>
                <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                onClick={() => onDone()}>
                    Done
                </PSButtonPrimary>
            </div>
            <div className="separator" />
            <div className="form">
                <div className="field">
                    <Hint>
                        Appointments scheduled with you in PocketSuite will appear on the calendar selected
                    </Hint>
                </div>
                <div className="field">
                    {calendars.filter((calendar: Calendar) => !calendar.read_only).map(calendar => {
                        return (
                            <div className="calendarCard">
                                <div className="dot" style={{backgroundColor: intToColorString(calendar.color)}}/>
                                <div className="details">
                                    <div className="name">{calendar.summary}</div>
                                </div>
                                <div className="edit">
                                        <div className="view">
                                        {calendar.sync_from && (
                                            <div onClick={() => onSelected(calendar)} className={"view buttonLink brightGreen"}>
                                                Selected
                                            </div>
                                        )}
                                    {! calendar.sync_from && (
                                            <div onClick={() => onSelected(calendar)} className="view buttonLink brightBlue">
                                                Select
                                            </div>
                                    )}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}