import { Datepicker } from "@zendeskgarden/react-datepickers";
import { Field, Input, Label } from "@zendeskgarden/react-forms";
import moment from "moment";
import React from "react";

type FieldProps = {
    fieldValue: string,
    label: string,
    onChange: (changed: string) => void
}

export const DateInput = ({fieldValue, label, onChange}: FieldProps) => {
    return <>
        <Field className='field'>
            <Label className="label">{label}</Label>
            <Datepicker value={fieldValue ? moment(fieldValue).toDate() : new Date()} onChange={(date) => onChange(moment(date).format('YYYY-MM-DD'))}>
                <Input />
            </Datepicker>
        </Field>
    </>
}