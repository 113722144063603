import React, { useCallback, useEffect, useState } from "react"
import { useHistory, useLocation, useParams } from "react-router"
import { AppHeader } from "../app/AppHeader"
import { Spinner } from "@zendeskgarden/react-loaders"
import '../settings/settings.css'
import { useDispatch, useSelector } from "react-redux"
import { getRecord } from "../../modules/records"
import { RootState } from "../../store"
import { isEmpty, Result } from "../../utils"
import { fetchItems, fetchPackages } from "../../modules/settings"
import {ReactComponent as SettingsPackageIcon} from "../../icons/ps_settings_packages.svg";

import { applyPackage } from "../../modules/transactions"
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals"
import { PSButtonPrimary } from "../app/PSButton"
import moment from "moment"

export const RecordSelectPackage = () => {
    const history = useHistory()

    const dispatch = useDispatch()
    const location: any = useLocation().state
    const state = useSelector((state: RootState) => (
        {
            settings: state.settings,
            records: state.records
        }
    ))

    const params = useParams<{recordType: string, recordID: string}>()
    const [record, setRecord] = useState(location?.record)

    useEffect(() => {
        if (!record) return

        dispatch(fetchPackages(record.client))
        dispatch(fetchItems(undefined, true))
    }, [record, dispatch])

    useEffect(() => {
        if (!isEmpty(record)) return

        dispatch(getRecord(params.recordType, params.recordID))
    }, [record, params, dispatch])

    useEffect(() => setRecord(state.records.data), [state.records.data])

    const [isLoading, setIsLoading] = useState(isEmpty(record))
    useEffect(() => setIsLoading(!state.settings.packages.length && !state.settings.item.length), [state.settings.item.length, state.settings.packages])

    const [showModal, setShowModal] = useState(false)
    const [currentItem, setCurrentItem] = useState<any>()
    const showConfirmModal = (selected: any) => {
        setShowModal(true)
        setCurrentItem(selected)
    }
    const onSelect = useCallback(() => {
        // todo show modal
        setRecord((current: any) => {
            return {
                ...current,
                package: currentItem.id
            }
        })

        setIsLoading(true)
        Result(dispatch(applyPackage(currentItem.id, record.id)))
            .then(() => history.goBack())
            .finally(() => {
                setIsLoading(false)
                setShowModal(false)
            })
    }, [currentItem, history, record?.id, dispatch])

    return (
        <AppHeader title="Appointment" showBackButton middleWidget={null}>
            <div className="servicesSettings" style={{width: '680px'}}>
                <div className="header">
                        <h1>Packages</h1>
                </div>
                <div className="form">
                    {isLoading && !showModal ?
                        <Spinner size='128' color="#314A68" /> :
                        state.settings.packages.map((p: any) => {
                                const item = state.settings.items.find((item: any) => item.id === p.item)
                                return (
                                    <div key={`package-card-${p.id}`} className="serviceCard">
                                        <SettingsPackageIcon />
                                        <div className="name">{item?.name}</div>
                                        <div className="rate">{moment(p.expired_date).format("MM/DD")}</div>
                                        <div className="view">
                                            {p.id === record.package ?
                                                <div className="buttonLink brightGreen" onClick={() => showConfirmModal(p)}>Selected</div> :
                                                <div className="buttonLink" onClick={() => showConfirmModal(p)}>Select</div>
                                            }
                                        </div>
                                    </div>)
                            }
                        )
                    }
                </div>
            </div>
            {showModal &&
                <Modal>
                    <Header>Apply package</Header>
                    {isLoading && <Spinner size='48' color="#314A68" />}
                    {!isLoading && <>
                        <Body>Would you like to apply this package to the client’s appointment?</Body>
                        <Close aria-label="Close modal" />
                        <Footer>
                            <FooterItem>
                                <PSButtonPrimary onClick={onSelect}>Apply package</PSButtonPrimary>
                            </FooterItem>
                        </Footer>
                    </>}
                </Modal>
            }
        </AppHeader>)
}