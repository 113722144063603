export const getTeamName = (team, userLookup) => {
    if (team.name) {
        return team.name
    }
    else {
        const memberList = team.members.map(member => {
            const person = userLookup[member.person]
            if (!person || !person.name) return person.phone
            return person.name.split(' ')[0]
        })
        const lastMember = memberList.length > 3 ? `and ${memberList.length - 3} more` : memberList.pop()
        return `${memberList.slice(0, 3).join(', ')} and ${lastMember}`
    }
}