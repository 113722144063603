import { VALIDATION } from "@zendeskgarden/react-forms/dist/typings/utils/validation"
import moment from "moment"
import React from "react"
import { useLocation } from "react-router"
import { UserInstance } from "../models/User"

export const isEmpty = (user: any) => !user || Object.keys(user).length === 0

export const stringEqual = (item1: string | undefined, item2: string | undefined) =>
    (item1 === item2) || (!item1 && !item2)

const lowestInUseZipCode = 501
export const isValidPostalCode = (zipcode: string) =>
    zipcode && zipcode.match(/\d{5}/) &&
    Number(zipcode) >= lowestInUseZipCode

export const isValidEmailAddress = (email: string) =>
    email?.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i)

export const isValidPhoneNumber = (phone?: string) => {
    if (!phone) return false

    const onlyNumbers = phone.replace(/[^\d]/g, "");
    // verify it doesn't start with 0 or 1
    return onlyNumbers?.match(/^[2-9]{1}[0-9]{9}$/i)
}

export const isValid = (flag?: VALIDATION) =>
    flag === undefined || flag === 'success'

export const isUserValid = (user: any, emptyIsValid?: boolean) =>
    (emptyIsValid && isEmpty(user)) || containsRequiredUserKeys(user)

const requiredUserKeys = ["id", "name", "phone", "signup_date", "last_login_date"]
const containsRequiredUserKeys = (user: any) =>
    requiredUserKeys.every(expectedKey =>
        Object.keys(user).findIndex(key => key === expectedKey) > -1
    )

export const reportError = (namespace: string, error: any) =>
    // @ts-ignore
    window.Rollbar.error(`${namespace} error`, error)

export const capitalizeFirstLetters = (value: string, splitCharacter?: string) =>
    value.split(splitCharacter || ' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(splitCharacter || ' ')

export enum SourceType {
    AnyCard,
    Debit,
    Ach,
    Credit
}

export const determineDefaultSource = (type: SourceType, sources: Array<any>) =>
    sources.sort(source => source.created_date).find(source => {
        if (type === SourceType.AnyCard && ["debit", "credit", "prepaid"].some(source.type)) {
            return true
        }
        else if (type === SourceType.Debit && source.verify_date) {
            return true
        }
        else if (type === SourceType.Ach && source.type === "checking" && source.verified_date && source.charge) {
            return true
        }
        else if (type === SourceType.Credit && source.type === "checking" && source.transfer) {
            return true
        }
        return false
    })

export const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const Result = (obj?: any) => Promise.resolve(obj)

export const addImage = (data: any, image?: File) => {
    let formData: FormData | undefined = undefined
    if (image) {
        formData = new FormData()
        formData.append('image', image, image!.name)

        Object.keys(data).forEach((key: string) => {
            const value = data[key]
            if (typeof value === 'object' && value !== null) {
                if (Array.isArray(value))
                    value.forEach(valueObj => formData!.append(key, JSON.stringify(valueObj)))
                else if ('toJSON' in value && typeof value.toJSON === 'function')
                    formData!.append(key, value.toJSON())
                else
                    formData!.append(key, JSON.stringify(value))
            }
            else if (value != null)
                formData!.append(key, value)
        })
    }
    return formData ?? data
}

export const base64toFile = (b64Data: string, contentType: string, fileName?: string) => {
    const sliceSize = 512

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

  return new File(byteArrays, fileName ?? "image", {type: contentType});
}

export const PERM_ROLE_CLIENT = 0
export const PERM_ROLE_CONTRACTOR = 1
export const PERM_ROLE_EMPLOYEE = 2
export const PERM_ROLE_ADMIN = 3
export const PERM_ROLE_OWNER = 4
export const PERM_ROLE_PARTNER = 5

export const userPermission = (loggedInUser: UserInstance) => {
    if (['solo', 'company'].includes(loggedInUser.type)) {
        return PERM_ROLE_OWNER
    }
    if (loggedInUser.type === 'client') {
        return PERM_ROLE_CLIENT
    }
    if (loggedInUser.type === 'partner') {
        return PERM_ROLE_PARTNER
    }

    return {
        'admin': PERM_ROLE_ADMIN,
        'employee': PERM_ROLE_EMPLOYEE,
        'contractor': PERM_ROLE_CONTRACTOR
    }[loggedInUser.role] ?? PERM_ROLE_CLIENT
}

export const PERM_LEVEL_VIEW = 1
export const PERM_LEVEL_EDIT = 2
export const PERM_LEVEL_DELETE = 3
export const PERM_LEVEL_OWNER = 4

export const checkRecordPermission = (loggedInUser: UserInstance, record: any, level: typeof PERM_LEVEL_VIEW | typeof PERM_LEVEL_EDIT | typeof PERM_LEVEL_DELETE | typeof PERM_LEVEL_OWNER) => {
    if (!record || !loggedInUser) return false

    const perm = userPermission(loggedInUser)
    const owner = Object.keys(record).includes('owner') ? record['owner'] : undefined
    const client = Object.keys(record).includes('client') ? record['client'] : undefined
    const person = Object.keys(record).includes('person') ? record['person'] : undefined
    const employee = Object.keys(record).includes('employee') ? record['employee'] : undefined
    if (owner === loggedInUser.id) {
        return true
    }
    else if (level === PERM_LEVEL_OWNER) {
        return false
    }
    if (owner && perm === PERM_ROLE_ADMIN && (loggedInUser.employer ?? loggedInUser.id) === owner) {
        return true
    }
    if (perm === PERM_ROLE_EMPLOYEE && (!!employee && employee === loggedInUser.id)) {
        return level < PERM_LEVEL_DELETE
    }

    return ((!!person && person === loggedInUser.id) || (!!client && client === loggedInUser.id)) && level <= PERM_LEVEL_VIEW
}

export const isPassedDate = (date: string | Date, hoursBeforeDate?: number | string) => {
    const dueDate = hoursBeforeDate ? moment(date).add(-Number(hoursBeforeDate), 'hours') : date
    return moment().isAfter(dueDate)
}

export const formatHourMinute = (duration: number) => {
    const hours = Math.floor(duration / 60)
    const minutes = duration % 60
    let minutePart = ''
    let hourPart = ''
    if (minutes) {
        minutePart = `${minutes} ${minutes > 1 ? 'mins' :'min'}`
    }
    if (hours) {
        hourPart = `${hours} ${hours > 1 ? 'hours' :'hour'}`
    }
    return `${hourPart}${hourPart ? ' ' : ''}${minutePart}`
}