import { Item } from "@zendeskgarden/react-dropdowns";
import React, { useEffect, useState } from "react";
import { Field } from "../../../models/Field";
import { PSMultiselect } from "../../app/PSMultiselect";

type FieldProps = {
    fieldValue?: any[],
    label: string,
    field: Field,
    onChange: (changed: any[]) => void
}

export const MultiSelectInput = ({fieldValue, label, field, onChange}: FieldProps) => {
    const [selected, setSelected] = useState<any[] | never[]>([])

    useEffect(() => {
        if (!fieldValue) return

        setSelected(fieldValue.map((value: any) => field.options.find(option => option.value === value)))
    }, [field.options, fieldValue])

    return (
        <PSMultiselect selected={selected}
            nameProperty={"label"}
            label={label}
            onSelect={(newSelection) => {
                setSelected(newSelection)
                onChange(newSelection.map((selection: any) => selection.value))
            }}>
        <>
            {field.options.map(option => (
                <Item key={`${field.name}-${option.value}`} value={option}>
                    {option.label}
                </Item>
            ))}
        </>
        </PSMultiselect>
    )
}