import moment from "moment";
import {useLocation} from "react-router";
import {findSelection, SelectOption, subscriptionFrequencyOptions} from "../components/settings/Options";
import {
    PREMIUM_ID, PREMIUM_PLUS_ID, PREMIUM_SUITE_ID, PREMIUM_ANNUAL_ID, PREMIUM_PLUS_ANNUAL_ID, PREMIUM_SUITE_ANNUAL_ID,
    PREMIUM_PRICE, PREMIUM_PLUS_PRICE, PREMIUM_SUITE_PRICE, PREMIUM_ANNUAL_PRICE, PREMIUM_PLUS_ANNUAL_PRICE, PREMIUM_SUITE_ANNUAL_PRICE,
} from "../utils/constants";
import {dateByMovingToEndOfDay, dateFormat, isPast} from "../utils/dates";
import {currencyFormat} from "../utils/numbers";
import {Edition} from "./Edition";
import {Transaction} from "./Transaction";

export type FrequencyType = 'daily' | 'monthly' | 'weekly' | ''

type SubscriptionLocationState = {
    subscription?: Partial<Subscription>;
    client?: any;
}

export const useSubscriptionLocationState = (): SubscriptionLocationState | undefined => {
    const location = useLocation()
    return location.state as SubscriptionLocationState
}

export const findFrequencySelect = (interval: number, frequency: string) => {
    const value = `${interval}${frequency === 'weekly' ? 'w' : frequency === 'daily' ? 'd' : 'm'}`
    return findSelection(subscriptionFrequencyOptions, value)
}

export class Subscription extends Transaction {
    name: string = ''
    creator: string = '';
    start_date: string = '';
    end_date: string = '';
    location?: string;
    rate: number = 0;
    deposit: number = 0;
    gratuity?: string;
    gratuity_type?: string;
    discount_instances?: number;
    discount_amount?: number;
    remaining_instances?: number;
    reminder_instances?: number;
    frequency: FrequencyType = '';
    form: string = '';
    record?: string = '';
    contract: string = '';
    charge_amount?: string | number;
    interval: number = 0;
    instances: number = 0;
    paid_instances: number = 0;
    plan?: string;
    memo: string = '';
    allow_conference: boolean = false;
    allow_gifting: boolean = false;
    allow_inventory: boolean = false;
    allow_salestax: boolean = false;
    allow_surcharge: boolean = false;
    allow_waitlist: boolean = false;
    cancelled_date?: string;
    confirmed_date?: string;
    created_date?: string;
    sent_date?: string;
    viewed_date?: string;
    next_invoice_date?: string;
    completed_date?: string;
    captured_date?: string;
    seqnum: number = 0;
    trial_period?: number;
    source_display?: string;

    constructor(data: Partial<Subscription>) {
        super(data)
        Object.assign(this, data)
    }
}

export const describeSubscription = (record: Subscription) => {
    if (record.instances === 1) {
        return `Payment due on ${dateFormat(record.date)}`
    }

    return `Payment due ${findFrequencySelect(record.interval, record.frequency)?.label.toLowerCase()}`
}

export const displayStatus = (record: Subscription) => {
    if (record.cancelled_date)
        return `Canceled ${dateFormat(record.cancelled_date)}`
    else if (record.completed_date)
        return `Completed ${dateFormat(record.completed_date)}`
    else if (record.confirmed_date && moment(record.next_invoice_date).add(1, 'day').isBefore(moment()))
        return `Next retry ${dateFormat(moment().add(1, 'day'))}`
    else if (record.confirmed_date)
        return `Next payment ${dateFormat(record.next_invoice_date)}`
    else
        return `Sent ${dateFormat(record.created_date)} - not paid`
}

export const subscriptionLabelString = (subscription: Subscription): string => {
    if (isPast(dateByMovingToEndOfDay(subscription.next_invoice_date)))
        return `Your subscription ${subscription.name ? 'to ' + subscription.name : ''} expired on ${dateFormat(subscription.next_invoice_date, 'MMMM d, yyyy')}.`
    if (subscription.trial_period && subscription.trial_period > 0)
        return `Your free trial ${subscription.name ? 'for ' + subscription.name : ''} expires on ${dateFormat(subscription.next_invoice_date, 'MMMM d, yyyy')}.`
    return `You are currently subscribed ${subscription.name ? 'to ' + subscription.name : ''} through ${dateFormat(subscription.next_invoice_date, 'MMMM d, yyyy')}.`
}

export const subscriptionPlanOptions = (subscription?: Subscription, edition?: Edition): SelectOption[] => {
    if (subscription?.plan && [PREMIUM_ANNUAL_ID, PREMIUM_PLUS_ANNUAL_ID, PREMIUM_SUITE_ANNUAL_ID].includes(subscription.plan)) {
        return [
            { value: 'premium', label: `Premium (${currencyFormat(subscription.plan === PREMIUM_ANNUAL_ID ? subscription.amount : PREMIUM_ANNUAL_PRICE)}/yr)` },
            { value: 'plus', label: `Team (${currencyFormat(subscription.plan === PREMIUM_PLUS_ANNUAL_ID ? subscription.amount : PREMIUM_PLUS_ANNUAL_PRICE)}/yr)` },
            { value: 'suite', label: `Enterprise (${currencyFormat(subscription.plan === PREMIUM_SUITE_ANNUAL_ID ? subscription.amount : PREMIUM_SUITE_ANNUAL_PRICE)}/yr)` },
        ]
    }
    else {
        return [
            { value: 'premium', label: `Premium (${currencyFormat(subscription?.plan === PREMIUM_ID ? subscription.amount : edition?.premium_price ? edition.premium_price : PREMIUM_PRICE)}/mo)` },
            { value: 'plus', label: `Team (${currencyFormat(subscription?.plan === PREMIUM_PLUS_ID ? subscription.amount : edition?.team_price ? edition.team_price : PREMIUM_PLUS_PRICE)}/mo)` },
            { value: 'suite', label: `Enterprise (${currencyFormat(subscription?.plan === PREMIUM_SUITE_ID ? subscription.amount : edition?.suite_price ? edition.suite_price : PREMIUM_SUITE_PRICE)}/mo)` },
        ]
    }
}