import React, { useEffect, useState } from "react";
import {AppHeader} from "../../app/AppHeader";
import {ReactComponent as CheckmarkImage} from "../../../icons/big_checkmark.svg";
import {ReactComponent as NoThanksImage} from "../../../icons/ps_general_x.svg";
import {ReactComponent as ChatImage} from "../../../icons/ps_general_chat.svg";
import {ReactComponent as EmailImage} from "../../../icons/ps_general_email.svg";
import {useLocation} from "react-router";
import {useDispatch} from "react-redux";
import { push } from "connected-react-router";
import { Body, Close, Footer, FooterItem, Header, Modal } from '@zendeskgarden/react-modals';
import { Spinner } from "@zendeskgarden/react-loaders";
import { Field, Input, Label, IInputProps } from "@zendeskgarden/react-forms";
import { PSButtonPrimary } from "../../app/PSButton";
import { isValid, isValidEmailAddress, isValidPhoneNumber, Result } from "../../../utils";
import { sendReaderReceipt } from "../../../modules/transactions";
import { VALIDATION } from "@zendeskgarden/react-forms/dist/typings/utils/validation";
import InputMask from 'react-input-mask'

export const ChargeReceipt = () => {
    const dispatch = useDispatch()

    const location = useLocation()
    const locationState: any = location.state

    const [showModal, setShowModal] = useState(false)
    const [modalType, setModalType] = useState<'email' | 'text'>()
    const [modalLoading, setModalLoading] = useState(false)
    const [modalSaveEnable, setModalSaveEnabled] = useState(false)
    const [destination, setDestination] = useState('')
    const [name, setName] = useState('')

    const [phoneValidation, setPhoneValidation] = useState<VALIDATION>()
    const [emailValidation, setEmailValidation] = useState<VALIDATION>()
    useEffect(() => {
        if (modalType === 'email') {
            setPhoneValidation(undefined)
            setEmailValidation(isValidEmailAddress(destination) ? undefined : 'error')
        }
        else {
            setEmailValidation(undefined)
            setPhoneValidation(isValidPhoneNumber(destination) ? undefined : 'error')
        }
    }, [destination, modalType])

    useEffect(() => {
        setModalSaveEnabled(isValid(emailValidation) && isValid(phoneValidation))
    }, [emailValidation, phoneValidation])

    const nextPage = () => {
        dispatch(push('/charge/success', locationState))
    }

    const sendText = () => {
        setModalLoading(true)
        Result(dispatch(sendReaderReceipt(locationState.payment.id, name, undefined, destination)))
            .then(nextPage)
            .finally(() => setModalLoading(false))
    }

    const sendEmail = () => {
        setModalLoading(true)
        Result(dispatch(sendReaderReceipt(locationState.payment.id, name, destination, undefined)))
            .then(nextPage)
            .finally(() => setModalLoading(false))
    }

    return (
        <AppHeader title="Charge"
                   middleWidget={null}
        >
            <div className="transactionSuccess">
                <div className="transactionReceipt">
                    <CheckmarkImage/>
                    <h1>All done!</h1>
                    <div className="details">How would you like to send client's receipt?</div>

                    <div className="links">
                        <div className="row" onClick={() => {
                            setModalType('text')
                            setShowModal(true)
                        }}>
                            <ChatImage /><div className="link">Text receipt</div>
                        </div>
                        <div className="separator"></div>
                        <div className="row" onClick={() => {
                            setModalType('email')
                            setShowModal(true)
                        }}>
                            <EmailImage /><div className="link">Email receipt</div>
                        </div>
                        <div className="separator"></div>
                        <div className="row" onClick={nextPage}>
                            <NoThanksImage /><div className="link">No thanks</div>
                        </div>
                    </div>
                </div>
            </div>
            {showModal &&
                <Modal onClose={() => setShowModal(false)}>
                    <Header>{modalType === 'email' ? 'Email Receipt' : 'Text Receipt'}</Header>
                    <Body>{modalLoading ? <Spinner size="48" color="#314A68" /> :
                        <>
                            <Field className='field' style={{marginBottom: '13px'}}>
                                <Label className='label'>Enter name</Label>
                                <Input
                                    value={name}
                                    onChange={e => setName(e.target.value)} />
                            </Field>
                            {modalType === 'email' ?
                                <Field className="field">
                                    <Label className="label">Enter email</Label>
                                    <Input placeholder="Email"
                                            value={destination}
                                            validation={emailValidation}
                                            disabled={modalLoading}
                                            onChange={e => setDestination(e.target.value)} />
                                </Field> :
                                <Field className="field">
                                    <Label className="label">
                                        Enter number
                                    </Label>
                                    <InputMask mask='999-999-9999'
                                            value={destination}
                                            alwaysShowMask={false}
                                            disabled={modalLoading}
                                            maskChar={''}
                                            onChange={e => setDestination(e.target.value)}>
                                            {(inputProps: IInputProps) => <Input {...inputProps}
                                                className='mobileNumberInput'
                                                placeholder='Enter phone number'
                                                validation={phoneValidation}
                                                />}
                                    </InputMask>
                                </Field>
                            }
                        </>}
                    </Body>
                    <Close aria-label="Close modal" />
                    <Footer>
                        <FooterItem>
                            <PSButtonPrimary disabled={!modalSaveEnable && !modalLoading} onClick={modalType === 'email' ? sendEmail : sendText}>
                                Done
                            </PSButtonPrimary>
                        </FooterItem>
                    </Footer>
                </Modal>
            }
        </AppHeader>
    )
}
