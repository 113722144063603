import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router"
import { AppHeader } from "../app/AppHeader"
import { Spinner } from "@zendeskgarden/react-loaders"
import '../settings/settings.css'
import { useDispatch, useSelector } from "react-redux"
import { getRecord } from "../../modules/records"
import { RootState } from "../../store"
import { isEmpty, Result } from "../../utils"
import { currencyFormat } from "../../utils/numbers"
import { push } from "connected-react-router"
import { apiClient } from "../../modules/apiclient"
import { ContactAvatar } from "../contacts/ContactAvatar"
import { keyBy } from "lodash"
import moment from "moment"

export const RecordPayments = () => {
    const dispatch = useDispatch()
    const state = useSelector((state: RootState) => (
        {
            entities: state.entities,
            records: state.records
        }
    ))

    const params = useParams<{recordType: string, recordID: string}>()

    const [payments, setPayments] = useState<any[]>()

    const handlePaymentResponse = useCallback((json: any) => {
        if (json.payments) {
            setPayments(json.payments)
        }
        if (json.contacts) {
            const contactMap = keyBy(json.contacts, 'id')
            dispatch({type: 'entities/UPDATE', name: 'contacts', map: contactMap})
        }
    }, [dispatch])

    const fetchPayments = useCallback(() => {
        if (params.recordType === 'invoice') {
            return apiClient.post('/invoice/sync', {id: params.recordID})
                .then(resp => resp.data)
                .then(handlePaymentResponse)
        }
        if (params.recordType === 'package') {
            return apiClient.post('/package/sync', {id: params.recordID})
                .then(resp => resp.data)
                .then(handlePaymentResponse)
        }
        if (params.recordType === 'subscription') {
            return apiClient.post('/subscription/sync', {id: params.recordID})
                .then(resp => resp.data)
                .then(handlePaymentResponse)
        }
    }, [handlePaymentResponse, params.recordID, params.recordType])

    const fetchRecord = useCallback(() => {
        return Result(dispatch(getRecord(params.recordType, params.recordID)))
    }, [dispatch, params.recordID, params.recordType])

    const [record, setRecord] = useState()
    useEffect(() => {
        if (!isEmpty(record)) return

        const task1 = fetchPayments()
        const task2 = fetchRecord()

        setIsLoading(true)
        Promise.all([task1, task2])
            .finally(() => setIsLoading(false))

    }, [record, params, dispatch, fetchPayments, fetchRecord])

    useEffect(() => setRecord(state.records.data), [state.records.data])

    const [isLoading, setIsLoading] = useState(false)

    let formBody: JSX.Element | undefined = undefined;
    if (isLoading || !record) {
        formBody = <Spinner size='128' color="#314A68" />
    }
    else {
        formBody = <>
            {payments?.map((payment: any) => {
                const client = state.entities.contacts[payment.client]
                return (
                    <div key={`payment-card-${payment.id}`} className="serviceCard">
                        {client && <ContactAvatar user={client} />}
                        <div className="details">
                            <div className="name">{client?.name}</div>
                            <div className="rate">{moment(payment.created_date).format('MMMM DD, YYYY')}</div>
                        </div>
                        <div className="name" style={{marginLeft: 'auto'}}>{currencyFormat(payment.amount, true)}</div>
                        <div className="view" style={{marginLeft: '24px'}} onClick={() => dispatch(push(`/record/payment/${payment.id}`))}>View</div>
                    </div>)
            })}
        </>
    }

    const title = params.recordType === 'invoice' ? "Invoice" : params.recordType === 'package' ? "Package" : "Subscription"
    return (
        <AppHeader title={title} showBackButton middleWidget={null}>
            <div className="servicesSettings" style={{width: '680px'}}>
                <div className="header">
                        <h1>Payments</h1>
                </div>
                <div className="separator" />
                <div className="form">
                    {formBody}
                </div>
            </div>
        </AppHeader>)
}