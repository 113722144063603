import {currencyFloat} from "../utils/numbers";

export class InventoryItem {
    id: string = '';
    name: string = '';
    rate: string = '';

    constructor(item: undefined | any) {
        if (item) {
            this.id = item.id;
            this.name = item.name;
            this.rate = item.rate;
        }
    }
}

export class InventoryAdjustment {
    item: InventoryItem;
    id: string = '';
    type: string = '';
    total: string = '';
    quantity: string = '';
    created_date: string = '';

    constructor(item: InventoryItem) {
        this.item = item;
    }

    setData(data: any) {
        this.id = data.id;
        this.type = data.type;
        this.total = currencyFloat(data.total).toFixed(2).toString();
        this.quantity = Math.abs(Math.trunc(parseFloat(data.lines[0].quantity))).toString();
        this.created_date = data.created_date;
    }

    toObject(): any {
        const obj: any = {};
        obj.item = this.item.id;
        obj.id = this.id;
        obj.type = this.type;
        obj.total = parseFloat(this.total || '0');
        obj.quantity = this.adjustmentSign() * parseFloat(this.quantity || '0');
        return obj;
    }

    adjustmentSign(): 1 | (-1) {
        return ['resupply','recount'].includes(this.type) ? 1 : (-1);
    }
}

export const adjustmentTypeTitle = (type: string): string => {
    let title: string = '';
    if (type === 'resupply')
        title = 'Resupply'
    else if (type === 'recount')
        title = 'Recount'
    else if (type === 'sold')
        title = 'Sold'
    else if (type === 'lost')
        title = 'Lost'
    else if (type === 'expired')
        title = 'Expired'
    else if (type === 'internal')
        title = 'Internal use'
    return title;
}