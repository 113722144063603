import {Item} from "../models/Item";
import {apiClient} from "./apiclient";
import {push} from "connected-react-router";
import findIndex from "lodash.findindex";
import {Category, CategoryInstance} from "../models/Category";
import {Contract} from "../models/Contract";
import {Form} from "../models/Form";
import {Field} from "../models/Field";
import {PhoneNumber} from "../models/PhoneNumber"
import {processError} from "./error";
import localforage from "localforage";
import {addImage, isUserValid} from "../utils";
import { Plan } from "../models/Plan";
import { Subscription } from "../models/Subscription";
import { Edition } from "../models/Edition";
import { UserInstance } from "../models/User";
import { Calendar } from "../models/Calendar";
import { Credential } from "../models/Credential";
import { Feature } from "../models/Feature";
import { POCKETSUITE_USER_ID, PREMIUM_ANNUAL_ID, PREMIUM_ID, PREMIUM_PLUS_ANNUAL_ID, PREMIUM_PLUS_ID, PREMIUM_SUITE_ANNUAL_ID, PREMIUM_SUITE_ID } from "../utils/constants";
import { BillingFeature, BillingHistory } from "../models/Billing";
import { Industry, IndustryResponseData } from "../models/Industry";
import {PSLink} from "../models/PSLink";
import { Reader } from "../models/Reader";

export type SettingsState = {
    isSending: boolean,
    items: Item[],
    item: Item,
    loadingAttachments: boolean,
    loadingSources: boolean,
    forms: any[],
    form: Form,
    field: Field,
    fields: Field[],
    formEditing: boolean,
    contracts: any[],
    contract: any,
    links: any[],
    link: any,
    savedMessages: any[],
    categories: Category[],
    category: Category,
    package: Item,
    packages: any[],
    phoneNumber: PhoneNumber,
    phoneNumbers: PhoneNumber[],
    source: any,
    sources: any[],
    readers: Reader[],
    plans: Plan[],
    activeSubscriptions: Subscription[],
    activePSSubscription: Subscription,
    billingHistory: BillingHistory,
    billingFeatures: BillingFeature[],
    features: Feature[],
    credentials: Credential[],
    calendars: Calendar[],
    expandedMenus: number[],
}

const initialState: SettingsState = {
    isSending: false,
    items: [],
    item: {} as Item,
    loadingAttachments: false,
    loadingSources: false,
    forms: [],
    form: {} as Form,
    field: {} as Field,
    fields: [],
    formEditing: false,
    contracts: [],
    contract: {},
    links: [],
    link: {},
    savedMessages: [],
    categories: [],
    category: {} as Category,
    package: {} as Item,
    packages: [],
    phoneNumber: {} as PhoneNumber,
    phoneNumbers: [],
    source: {},
    sources: [],
    readers: [],
    plans: [],
    activeSubscriptions: [],
    activePSSubscription: {} as Subscription,
    billingHistory: {} as BillingHistory,
    billingFeatures: [],
    features: [],
    credentials: [],
    calendars: [],
    expandedMenus: [0, 1, 2, 3, 4]
}

export default function settingsReducer(state = initialState, action: any) {
    switch (action.type) {
        case 'settings/REQUEST':
            return {
                ...state,
                isSending: action.sending,
            }
        case 'settings/SET_ITEMS':
            return {
                ...state,
                items: action.items,
            }
        case 'settings/SET_ITEM':
            return {
                ...state,
                item: action.item,
            }
        case 'settings/SET_PACKAGE':
            return {
                ...state,
                package: action.item,
            }
        case 'settings/SET_PACKAGES':
            return {
                ...state,
                packages: action.packages,
            }
        case 'settings/SET_CONTRACT':
            return {
                ...state,
                contract: action.contract,
            }
        case 'settings/SET_FORM':
            return {
                ...state,
                form: action.form,
            }
        case 'settings/UPDATE_ITEMS':
            action.newItems.forEach((item: Item) => {
                const index  = findIndex(state.items, (i: Item) => i.id === item.id)
                if (index > -1) {
                    state.items[index] = item
                }
            })
            return state
        case 'settings/UPDATE_LINKS':
            const linksCopy = state.links.slice()
            action.newLinks.forEach((newLink: any) => {
                const index = findIndex(linksCopy, x => x.id === newLink.id)
                if (index > -1) {
                    linksCopy[index] = newLink
                }
            })
            return {
                ...state,
                links: linksCopy,
            }
        case 'settings/FETCHING_ATTACHMENTS':
            return {
                ...state,
                loadingAttachments: action.sending,
            }
        case 'settings/FETCHING_SOURCES':
            return {
                ...state,
                loadingSources: action.sending,
            }
        case 'settings/SET_ATTACHMENTS':
            return {
                ...state,
                contracts: action.contracts,
                forms: action.forms,
            }
        case 'settings/SET_CATEGORIES':
            return {
                ...state,
                categories: action.categories,
            }
        case 'settings/SET_CATEGORY':
            return {
                ...state,
                category: action.category,
            }
        case 'settings/UPDATE_CATEGORIES':
            return {
                ...state,
                categories: state.categories.map(category => {
                    const index = action.newCategories.findIndex((c: Category) => c.id === category.id)
                    return index > -1 ? {...category, ...action.newCategories[index] } : category
                })
            }
        case 'settings/SET_ITEM_BY_TYPE':
            switch (action.itemType) {
                case 'contract':
                    state.contracts = action.items
                    break
                case 'form':
                    state.forms = action.items
                    break
                case 'link':
                    state.links = action.items
                    break
                case 'fields':
                    state.fields = action.items
                    break
            }
            return state
        case 'settings/SET_LINK':
            return {
                ...state,
                link: action.link,
            }
        case 'settings/SET_FORM_EDITING':
            return {
                ...state,
                formEditing: action.editing,
            }
        case 'settings/ADD_FIELD':
            const newFields = state.fields.slice()
            newFields.push(action.field)
            return {
                ...state,
                fields: newFields,
            }
        case 'settings/UPDATE_TEMP_FIELD':
            state.fields[action.index] = action.field
            return state
        case 'settings/REMOVE_TEMP_FIELD': {
            const newFields = state.fields.slice()
            newFields.splice(action.index, 1)
            return {
                ...state,
                fields: newFields
            }
        }
        case 'settings/UPDATE_FIELD':
            if (!state.form.fields) return state

            return {
                ...state,
                form: {
                    ...state.form,
                    fields: state.form.fields.map(f => f.id === action.field.id ? { ...f, ...action.field } : f)
                }
            }
        case 'settings/REMOVE_FIELD': {
            if (!state.form.fields) return state
            const newFields = state.form.fields.slice()
            const removeFieldIndex = newFields.findIndex(f => f.id === action.fieldID)
            if (Number(removeFieldIndex) > -1) {
                newFields.splice(removeFieldIndex as number, 1)
            }
            return {
                ...state,
                form: {
                    ...state.form,
                    fields: newFields,
                }
            }
        }
        case 'settings/UPDATE_FORM_FIELDS':
            return {
                ...state,
                form: {
                    ...state.form,
                    fields: action.fields,
                }
            }
        case 'settings/SET_PHONE_NUMBER':
            return {
                ...state,
                phoneNumber: action.phoneNumber,
            }
        case 'settings/SET_PHONE_NUMBERS':
            return {
                ...state,
                phoneNumbers: action.phoneNumbers,
            }
        case 'settings/SET_SOURCE':
            let sources = state.sources
            if (!state.sources.find(s => s.id === action.source.id)) {
                sources = [action.source, ...state.sources]
            }
            return {
                ...state,
                source: action.source,
                sources
            }
        case 'settings/SET_SOURCES':
            return {
                ...state,
                sources: action.sources,
            }
        case 'settings/SET_READERS':
            return {
                ...state,
                readers: action.readers,
            }
        case 'settings/REMOVE_READER':
            const readers = state.readers.filter(r => r.id !== action.reader)
            return {
                ...state,
                readers
            }
        case 'settings/SET_PLANS':
            return {
                ...state,
                plans: action.plans
            }
        case 'settings/SET_ACTIVE_SUBSCRIPTIONS':
            return {
                ...state,
                activeSubscriptions: action.subscriptions
            }
        case 'settings/SET_ACTIVE_PS_SUBSCRIPTION':
            return {
                ...state,
                activePSSubscription: action.subscription
            }
        case 'settings/SET_BILLING_HISTORY':
            return {
                ...state,
                billingHistory: action.billingHistory
            }
        case 'settings/SET_BILLING_FEATURES':
            return {
                ...state,
                billingFeatures: action.billingFeatures
            }
        case 'settings/SET_FEATURES':
            return {
                ...state,
                features: action.features,
            }
        case 'settings/MENU_TOGGLE':
            let menu = state.expandedMenus.slice()
            const openIndex = menu.findIndex((r: number) => r === action.row)
            if (openIndex === -1)
                menu.push(action.row)
            else
                menu = menu.filter((r: number) => r !== action.row)

            return {
                ...state,
                expandedMenus: menu
            }
        case 'settings/SET_CREDENTIALS':
            return {
                ...state,
                credentials: action.credentials,
            }
        case 'settings/SET_CALENDARS':
            return {
                ...state,
                calendars: action.calendars,
            }
        default:
            return state
    }
}

const settingsRequest = (sending: boolean) => {
    return { type: 'settings/REQUEST', sending }
}

const setItems = (items: Item[]) => {
    return { type: 'settings/SET_ITEMS', items }
}

export const setItem = (item: Item) => {
    return { type: 'settings/SET_ITEM', item }
}

const setPackage = (item: Item) => {
    return { type: 'settings/SET_PACKAGE', item }
}

const setPackages = (packages: any[]) => {
    return { type: 'settings/SET_PACKAGES', packages }
}

export const clearItem = () => {
    return { type: 'settings/SET_ITEM', item: {} }
}

export const clearPackage = () => {
    return { type: 'settings/SET_PACKAGE', item: {} }
}

export const fetchItems = (type?: string | null, showAll?: boolean, checkPerm?: boolean) => {
    return (dispatch: any) => {
        dispatch(setItems([]))
        dispatch(settingsRequest(true))

        const data: any = {}
        if (type) data['type'] = type
        if (showAll) data['show_all'] = true
        if (checkPerm) data['check_perm'] = true

        return apiClient.post('/item/sync', data)
            .then(resp => resp.data)
            .then(json => {
                dispatch(setItems(json.items))
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => {
                dispatch(settingsRequest(false))
            })
    }
}

export const fetchItem = (type: string, id: string): any => {
    return (dispatch: any): any => {
        dispatch(settingsRequest(true))

        return apiClient.post('/item/sync', {type, id})
            .then(resp => resp.data)
            .then(json => {
                if (json.items.length > 0) {
                    dispatch(setItem(json.items[0]))
                    if (type === 'subscription' && json.items[0].package) {
                        dispatch(
                            fetchPackage(json.items[0].package, true)
                        )
                    }
                }
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => {
                dispatch(settingsRequest(false))
            })
    }
}

export const fetchPackages = (client: string): any =>
    (dispatch: any): any => {
        dispatch(settingsRequest(true))

        return apiClient.post('/packages/query', {client})
            .then(resp => resp.data)
            .then((json: any[]) => {
                if (json?.length) {
                    dispatch(setPackages(json))
                }
            })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => dispatch(settingsRequest(false)))
    }

export const fetchPackage = (id: string, showAll: boolean = false): any => {
    return (dispatch: any): any => {
        dispatch(settingsRequest(true))

        const data = {
            type: 'package',
            id,
            show_all: showAll
        }

        return apiClient.post('/item/sync', data)
            .then(resp => resp.data)
            .then(json => {
                if (json.items.length > 0) {
                    dispatch(setPackage(json.items[0]))
                }
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => {
                dispatch(settingsRequest(false))
            })
    }
}

const fetchingAttachments = (sending: boolean) => {
    return { type: 'settings/FETCHING_ATTACHMENTS', sending }
}

const setAttachments = (contracts: any[], forms: any[]) => {
    return { type: 'settings/SET_ATTACHMENTS', contracts, forms }
}

export const fetchCustomFields = (callback?: () => void) => {
    return (dispatch: any): any => {
        dispatch(clearFields())

        const params = {
            is_booking: true,
            show_standard: false,
            owner: true,
        }
        return apiClient.post('/field/sync', params)
            .then(resp => resp.data)
            .then(json => {
                if (json.fields) {
                    const fields = json.fields.map((f: any) => {
                        const result = new Field()
                        result.setData(f)
                        return result
                    })
                    dispatch(setFields(fields))
                }
                if (callback) callback()
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const fetchAttachments = () => {
    return (dispatch: any): any => {
        dispatch(fetchingAttachments(true))

        Promise.all([
            apiClient.post('/contract/sync').then(resp => resp.data),
            apiClient.post('/type/sync').then(resp => resp.data),
        ])
        .then(values => {
            dispatch(setAttachments(values[0].contracts, values[1].forms))
        })
        .catch((error) => {
            dispatch(processError(error))
            return Promise.reject()
        })
        .finally(() => {
            dispatch(fetchingAttachments(false))
        })
    }
}

export const fetchContracts = () => {
    return (dispatch: any): any => {
        dispatch(settingsRequest(true))
        return apiClient.post('/contract/sync')
            .then(resp => resp.data)
            .then(json => {
                dispatch(setAttachments(json.contracts, []))
            })
            .catch((error) => {
                dispatch(processError(error?.data?.message || error))
                return Promise.reject()
            })
            .finally(() => dispatch(settingsRequest(false)))
    }
}

export type PlanResponse = {
    plans?: Plan[],
    title?: string,
    welcome?: string,
    welcome_subtitle?: string,
    welcome_title?: string,
    welcome_image_uri?: string
}
export const fetchPlans = (user_id:string, context:string) => {
    return (dispatch: any): PromiseLike<any> => {
        return apiClient.post(`/billing/${user_id}/plans`, { context })
            .then(resp => resp.data)
            .then((json: PlanResponse) => {
                if (json.plans) {
                    dispatch(setPlans(json.plans))
                }
                return json
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

const findActivePSSubscription = (subscriptions: Subscription[], userId: string): Subscription | undefined => {
    for (let i = subscriptions.length-1; i >= 0; i--) {
        const s = subscriptions[i]

        if (s.owner === POCKETSUITE_USER_ID && s.client === userId && !s.cancelled_date && !!s.confirmed_date) {
            return s
        }
    }
    return
}

export const fetchActiveSubscriptions = (userId?: string) => {
    return (dispatch: any): PromiseLike<any> => {
        return apiClient.post(`/subscription/sync`, { type: 'active'})
            .then(resp => resp.data)
            .then((json: {subscriptions?: Subscription[]}) => {
                if (json.subscriptions) {
                    dispatch(setActiveSubscriptions(json.subscriptions))

                    if (userId) {
                        const sub = findActivePSSubscription(json.subscriptions, userId)
                        if (sub) dispatch(setActivePSSubscription(sub))
                    }
                }
                return json.subscriptions
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const fetchBillingHistory = (user_id: string) => {
    return (dispatch: any): PromiseLike<any> => {
        return apiClient.get(`/billing/${user_id}/history`)
            .then(resp => resp.data)
            .then((json: BillingHistory) => {
                dispatch(setBillingHistory(json))
                return json
            })
            .catch((error) => {
                dispatch(processError(error?.data?.message || error))
                return Promise.reject()
            })
    }
}

export const fetchBillingFeatures = (user_id: string) => {
    return (dispatch: any): PromiseLike<any> => {
        return apiClient.get(`/billing/${user_id}/features`)
            .then(resp => resp.data)
            .then((json: BillingFeature[]) => {
                dispatch(setBillingFeatures(json))
                return json
            })
            .catch((error) => {
                dispatch(processError(error?.data?.message || error))
                return Promise.reject()
            })
    }
}

const setPlans = (plans: Plan[]) => {
    return { type: 'settings/SET_PLANS', plans }
}

const setActiveSubscriptions = (subscriptions: Subscription[]) => {
    return { type: 'settings/SET_ACTIVE_SUBSCRIPTIONS', subscriptions }
}

const setActivePSSubscription = (subscription: Subscription) => {
    return { type: 'settings/SET_ACTIVE_PS_SUBSCRIPTION', subscription }
}

const setBillingHistory = (billingHistory: BillingHistory) => {
    return { type: 'settings/SET_BILLING_HISTORY', billingHistory }
}

const setBillingFeatures = (billingFeatures: BillingFeature[]) => {
    return { type: 'settings/SET_BILLING_FEATURES', billingFeatures }
}

export const setContract = (contract: any) => {
    return { type: 'settings/SET_CONTRACT', contract }
}

export const clearContract = () => {
    return { type: 'settings/SET_CONTRACT', contract: {} }
}

export const fetchContract = (id: string) => {
    return (dispatch: any): any => {
        return apiClient.post('/contract/sync', {id})
            .then(resp => resp.data)
            .then(json => {
                if (json.contracts.length === 1)
                    dispatch(setContract(json.contracts[0]))
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const saveContract = (contract: Contract) => {
    return (dispatch: any): any => {
        const url = !!contract.id ? `/contract/${contract.id}` : '/contract'
        const data = contract.toObject()

        return apiClient.post(url, data)
            .then(resp => resp.data)
            .then(json => {
                dispatch(push('/settings/contracts'))
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const deleteContract = (contractID: string) => {
    return (dispatch: any): any => {
        const url = `/contract/${contractID}/delete`
        return apiClient.post(url)
            .then(resp => resp.data)
            .then((json: any) => {
                // TODO Delete this contract from state, so it doesn't flash
                dispatch(push(`/settings/contracts`))
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const sendBlock = (blockData: any) => {
    return (dispatch: any): any => {
        const url = blockData.id ? `/block/${blockData.id}` : `/block`
        return apiClient.post(url, blockData)
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const deleteBlock = (blockID: string, deleteAll?: boolean) => {
    return (dispatch: any): any => {
        const url = `/block/${blockID}/delete`
        let data: any = {}
        if (deleteAll) {
            data.delete_all = deleteAll
        }
        return apiClient.post(url, data)
            .then(resp => resp.data)
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const fetchForms = () => {
    return (dispatch: any): any => {
        return apiClient.post('/type/sync')
            .then(resp => resp.data)
            .then(json => {
                dispatch(setAttachments([], json.forms))
            })
            .catch((error) => {
                dispatch(processError(error?.data?.message || error))
                return Promise.reject()
            })
    }
}

const setItemByType = (itemType: string, items: any[]) => {
    return { type: 'settings/SET_ITEM_BY_TYPE', itemType, items }
}

export const setLink = (link: any) => {
    return { type: 'settings/SET_LINK', link }
}

export const fetchItemByType = (itemType: string) => {
    return (dispatch: any): any => {
        dispatch(fetchingAttachments(true))

        let url = ''
        switch (itemType) {
            case 'contract': url = '/contract/sync'; break;
            case 'form': url = '/type/sync'; break;
            case 'link': url = '/link/sync'; break;
        }

        return apiClient.post(url)
            .then(resp => resp.data)
            .then(json => {
                let results = []
                switch (itemType) {
                    case 'contract': results = json.contracts; break;
                    case 'form': results = json.forms; break;
                    case 'link': results = json.links; break;
                }
                dispatch(setItemByType(itemType, results))
            })
            .catch((error) => {
                dispatch(processError(error?.data?.message || error))
                return Promise.reject()
            })
            .finally(() => {
                dispatch(fetchingAttachments(false))
            })
    }
}

export const fetchLink = (id: string): any => {
    return (dispatch: any): any => {
        dispatch(settingsRequest(true))

        return apiClient.post('/link/sync', {id})
            .then(resp => resp.data)
            .then(json => {
                if (json.links.length > 0) {
                    dispatch(setLink(json.links[0]))
                }
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => {
                dispatch(settingsRequest(false))
            })
    }
}

export const saveItem = (item: Item, photo?: File, skipRedirect?: boolean): any => {
    return (dispatch: any) => {
        dispatch(settingsRequest(true))

        const url = !!item.id ? `/item/${item.id}` : '/item'

        const data = item.toObject()

        if (photo) {
            const formData = new FormData()
            formData.append('image', photo, photo.name)

            Object.keys(data).forEach((key) => {
                const value = data[key]
                if (typeof value === 'object' && value !== null) {
                    if (Array.isArray(value))
                        value.forEach(valueObj => formData.append(key, JSON.stringify(valueObj)))
                    else
                        formData.append(key, JSON.stringify(value))
                }
                else if (value != null)
                    formData.append(key, value)
            })

            return apiClient.post(url, formData)
                .then(resp => resp.data)
                .then((json: any) => {
                    if (skipRedirect) return json

                    if (item.type === 'class')
                        dispatch(push(`/settings/${item.type}es`))
                    else
                        dispatch(push(`/settings/${item.type}s`))
                })
                .catch((error) => {
                    dispatch(processError(error))
                    return Promise.reject()
                })
                .finally(() => {
                    dispatch(settingsRequest(false))
                })
        }
        else {
            return apiClient.post(url, data)
                .then(resp => resp.data)
                .then((json: any) => {
                    if (skipRedirect) return json

                    if (item.type === 'class')
                        dispatch(push(`/settings/${item.type}es`))
                    else
                        dispatch(push(`/settings/${item.type}s`))
                })
                .catch((error) => {
                    dispatch(processError(error))
                    return Promise.reject()
                })
                .finally(() => {
                    dispatch(settingsRequest(false))
                })
        }
    }
}

const updateItems = (newItems: Item[]) => {
    return { type: 'settings/UPDATE_ITEMS', newItems }
}

export const moveItem = (item: Item, newPosition: number) => {
    return (dispatch: any) => {
        const url = `/item/${item.id}/move`
        const data = {position: newPosition}

        return apiClient.post(url, data)
            .then(resp => resp.data)
            .then((json: any) => {
                // Update the items that have new positions
                dispatch(updateItems(json))
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

const setCategories = (categories: any[]) => {
    return { type: 'settings/SET_CATEGORIES', categories }
}

export const fetchCategories = () => {
    return (dispatch: any) => {
        return apiClient.post('/category/sync')
            .then(resp => resp.data)
            .then((json: any) => {
                // Update the items that have new positions
                const categories = json.categories.map((d: Partial<CategoryInstance>) =>
                    new Category(d)
                )
                dispatch(setCategories(categories))
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}
const updateCategories = (newCategories: any[]) => {
    return { type: 'settings/UPDATE_CATEGORIES', newCategories }
}

export const setCategory = (category: Category) => {
    return { type: 'settings/SET_CATEGORY', category }
}

export const clearCategory = () => {
    return { type: 'settings/SET_CATEGORY', category: {} }
}

export const fetchCategory = (id: string) => {
    return (dispatch: any): any => {
        dispatch(settingsRequest(true))

        return apiClient.get(`/category/${id}`)
            .then(resp => resp.data)
            .then((json: Partial<CategoryInstance>) => {
                dispatch(setCategory(new Category(json)))
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => {
                dispatch(settingsRequest(false))
            })
    }
}

export const moveCategory = (category: Category, newPosition: number) => {
    return (dispatch: any) => {
        const url = `/category/${category.id}/move`
        const data = {position: newPosition}

        return apiClient.post(url, data)
            .then(resp => resp.data)
            .then((json: any) => {
                // Update the items that have new positions
                dispatch(updateCategories(json))
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const moveCategoryItem = (categoryId: string, item: Item, newPosition: number) => {
    return (dispatch: any) => {
        const url = `/category/${categoryId}/move/item`
        const data = {item: item.id, position: newPosition}

        return apiClient.post(url, data)
            .then(resp => resp.data)
            .then((json: Partial<CategoryInstance>) => {
                // Update the items that have new positions
                dispatch(setCategory(new Category(json)))
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const saveCategory = (category: Partial<CategoryInstance>, photo?: File) => {
    return (dispatch: any) => {
        let url = '/category'
        if (category.id) {
            url = `/category/${category.id}`
        }

        const data: any = category
        const formData = new FormData()

        if (photo) {
            formData.append('image', photo, photo.name)

            Object.keys(data).forEach((key: string) => {
                const value = data[key]
                if (key === 'listings')
                    value.forEach((valueObj: string) => formData.append(key, valueObj))
                else if (typeof value === 'object' && value !== null) {
                    if (Array.isArray(value))
                        value.forEach(valueObj => formData.append(key, JSON.stringify(valueObj)))
                    else
                        formData.append(key, JSON.stringify(value))
                }
                else if (value != null)
                    formData.append(key, value)
            })
        }

        return apiClient.post(url, photo ? formData : data)
            .then(resp => resp.data)
            .then((json: any) => {
                dispatch(push('/settings/categories'))
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const deleteItem = (itemID: string, type: string) => {
    return (dispatch: any) => {
        const url = `/item/${itemID}/delete`
        const data = {
            notify_all: false,
        }
        return apiClient.post(url, data)
            .then(resp => resp.data)
            .then((json: any) => {
                // TODO Delete this item from state, so it doesn't flash
                dispatch(push(`/settings/${type}`))
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const deleteCategory = (categoryID: string) => {
    return (dispatch: any) => {
        const url = `/category/${categoryID}/delete`
        const data = {
            notify_all: false,
        }
        return apiClient.post(url, data)
            .then(resp => resp.data)
            .then((json: any) => {
                // TODO Delete this item from state, so it doesn't flash
                dispatch(push(`/settings/categories`))
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const deleteBooking = (bookingID: string, skipNotification?: boolean) => {
    return (dispatch: any) => {
        const url = `/booking/${bookingID}/cancel`

        const data: any = {}
        if (skipNotification) data.dont_notify = true

        return apiClient.post(url, data)
            .then(resp => resp.data)
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const deleteLesson = (lessonID: string, notify: boolean, cancelAll: boolean) => {
    return (dispatch: any) => {
        const url = `/class/${lessonID}/cancel`
        const data = {
            cancel_all: cancelAll,
            notify_all: notify,
        }

        return apiClient.post(url, data)
            .then(resp => resp.data)
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const saveForm = (form: Form, photo?: File) => {
    return (dispatch: any): any => {
        const url = !!form.id ? `/type/${form.id}` : '/type'
        let data = form.toObject(!!form.id)

        if (photo) {
            const formData = new FormData()
            formData.append('image', photo, photo.name)
            Object.keys(data).forEach((key) => {
                const value = data[key]
                if (typeof value === 'object' && value !== null) {
                    if (Array.isArray(value))
                        value.forEach(valueObj => formData.append(key, JSON.stringify(valueObj)))
                    else
                        formData.append(key, JSON.stringify(value))
                }
                else if (value != null)
                    formData.append(key, value)
            })

            data = formData
        }

        return apiClient.post(url, data)
            .then(resp => resp.data)
            .then(json => {
                dispatch(push('/settings/forms'))
                // Clear the saved data
                dispatch(setFormEditing(false))
                dispatch(clearFields())
                dispatch(clearForm())
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const fetchForm = (formID: string) => {
    return (dispatch: any): any => {
        return apiClient.post(`/type/sync`, {id: formID})
            .then(resp => resp.data)
            .then(json => {
                if (json.forms?.length) {
                    const form = new Form()
                    form.setData(json.forms[0])
                    dispatch(setForm(form))
                }
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const setForm = (form: Form) => {
    return { type: 'settings/SET_FORM', form }
}

export const clearForm = () => {
    return { type: 'settings/SET_FORM', form: {} }
}

export const deleteForm = (formID: string) => {
    return (dispatch: any): any => {
        const url = `/type/${formID}/delete`
        return apiClient.post(url)
            .then(resp => resp.data)
            .then((json: any) => {
                dispatch(push(`/settings/forms`))
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const setFormEditing = (editing: boolean) => {
    return { type: 'settings/SET_FORM_EDITING', editing }
}

export const clearFields = () => {
    return { type: 'settings/SET_ITEM_BY_TYPE', itemType: 'fields', items: [] }
}

export const setFields = (fields: Field[]) => {
    return { type: 'settings/SET_ITEM_BY_TYPE', itemType: 'fields', items: fields }
}

export const addField = (field: Field) => {
    return { type: 'settings/ADD_FIELD', field }
}

export const updateField = (field: Field, index: number) => {
    return { type: 'settings/UPDATE_TEMP_FIELD', field, index }
}

export const removeField = (index: number) => {
    return { type: 'settings/REMOVE_TEMP_FIELD', index }
}

const updateFieldInForm = (field: Field) => {
    return { type: 'settings/UPDATE_FIELD', field }
}

const removeFieldInForm = (fieldID: string) => {
    return { type: 'settings/REMOVE_FIELD', fieldID }
}

export const saveField = (field: Field, forForm: boolean) => {
    return (dispatch: any): any => {
        const url = field.id ? `/field/${field.id}` : `/field`
        const data = field.toObject()
        return apiClient.post(url, data)
            .then(resp => resp.data)
            .then(json => {
                // Update local state with the modified field
                dispatch(updateFieldInForm(json))
                if (forForm) {
                    dispatch(push(`/settings/forms/${json.record}/questions`))
                }
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

const updateFormFieldOrder = (fields: Field[]) => {
    return { type: 'settings/UPDATE_FORM_FIELDS', fields }
}

export const moveField = (field: Field, newIndex: number, sortedFields: Field[]) => {
    return (dispatch: any): any => {
        // Move it in the ui by updating the state of state.form.fields to use
        // the new sorting order
        dispatch(updateFormFieldOrder(sortedFields))

        const url = `/field/${field.id}/move`
        const data = {
            kind: "record",
            position: newIndex,
        }
        return apiClient.post(url, data)
            .then(resp => resp.data)
            .then(json => json.forEach((field: Field) => dispatch(updateFieldInForm(field))))
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const deleteField = (fieldID: string, forForm: boolean) => {
    return (dispatch: any): any => {
        const url = `/field/${fieldID}/delete`
        return apiClient.post(url)
            .then(resp => resp.data)
            .then(json => {
                dispatch(removeFieldInForm(fieldID))
                if (forForm) {
                    dispatch(push(`/settings/forms/${json.record}/questions`))
                }
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

const updateLinks = (newLinks: any[]) => {
    return { type: 'settings/UPDATE_LINKS', newLinks }
}

export const moveLink = (idString: string, newPosition: number, reorderedLinks: any[]) => {
    return (dispatch: any) => {
        dispatch(settingsRequest(true))

        const url = `/link/${idString}/move`
        const data = {position: newPosition}

        dispatch(setItemByType('link', reorderedLinks))

        return apiClient.post(url, data)
            .then(resp => resp.data)
            .then((json: any) => {
                // Update the items that have new positions
                dispatch(updateLinks(json))
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => dispatch(settingsRequest(false)))
    }
}

export const saveLink = (link: PSLink) => {
    return (dispatch: any): any => {
        const url = link.id ? `/link/${link.id}` : "/link"
        const data = link.toObject()
        dispatch(settingsRequest(true))
        return apiClient.post(url, data)
            .then(resp => resp.data)
            .then(() => dispatch(push('/settings/link')))
            .finally(() => {
                dispatch(settingsRequest(false))
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const deleteLink = (idString: string) => {
    return (dispatch: any): any => {
        const url = `/link/${idString}/delete`
        const data = {}
        dispatch(settingsRequest(true))
        return apiClient.post(url, data)
            .then(resp => resp.data)
            .then(() => dispatch(push('/settings/link')))
            .finally(() => {
                dispatch(settingsRequest(false))
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const toggleSettingsMenu = (row: number) => {
    return { type: 'settings/MENU_TOGGLE', row }
}

export const submitLoginSettings = (settingParams: any) => {
    return (dispatch: any): any => {
        dispatch(settingsRequest(true))
        const url = `/device/${settingParams.id}`
        const data: any = {
            ttl: settingParams.ttl
        }
        if (!settingParams.password.length || settingParams.password.toLowerCase() !== "xxxx")
            data.password = settingParams.password
        return apiClient.post(url, data)
            .then(resp => resp.data)
            .then(json => {
                if ('device' in json)
                    localforage.setItem('device', json.device)
                if ('user' in json && isUserValid(json.user))
                    localforage.setItem('user', json.user)
            })
            .catch(error => {
                dispatch(processError(error));
                return Promise.reject();
            })
            .finally(() => {
                dispatch(settingsRequest(false))
            });
    }
}

export const sendTaxForm = () => {
    return (dispatch: any): any => {
        dispatch(settingsRequest(true))
        return apiClient.post(`/user/send-tax`, {})
            .then(resp => resp.data)
            .catch(error => dispatch(processError(error)))
    }
}

export const saveUser = (userParams: any, photo?: File) => {
    return (dispatch: any): any => {
        dispatch(settingsRequest(true))
        const url = `/user/${userParams.id}`

        const data = addImage(userParams, photo)
        return apiClient.post(url, data)
            .then(resp => resp.data)
            .then(json => {
                if (isUserValid(json)) {
                    localforage.setItem('user', json).then(() => {
                        dispatch(settingsRequest(false))
                    })
                }
                else if (isUserValid(json.user)) {
                    localforage.setItem('user', json.user).then(() => {
                        dispatch(settingsRequest(false))
                    })
                }
            })
            .catch(error => dispatch(processError(error)))
    }
}

export const getUser = (id: string) => {
    return (dispatch: any): any => {
        return apiClient.post("/user/sync", {id})
            .then(resp => resp.data)
            .then(json => {
                let user: UserInstance | undefined = undefined
                if (isUserValid(json)) {
                    user = json as UserInstance
                }
                else if (isUserValid(json.user)) {
                    user = json.user as UserInstance
                }
                return user
            })
            .then(user =>
                localforage.getItem('user').then((cachedUser: any) => {
                    if (user && cachedUser?.id === user?.id) {
                        localforage.setItem('user', user)
                    }
                    return user
                })
            )
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const setSource = (source: any) => {
    return { type: 'settings/SET_SOURCE', source: source}
}

export const clearSource = () => {
    return { type: 'settings/SET_SOURCE', source: {}}
}

export const setSources = (sources: any[]) => {
    return { type: 'settings/SET_SOURCES', sources}
}

export const setReaders = (readers: Reader[]) => {
    return { type: 'settings/SET_READERS', readers: readers}
}

export const removeReader = (readerId: string) => {
    return { type: 'settings/REMOVE_READER', reader: readerId}
}

export const clearSources = () => {
    return { type: 'settings/SET_SOURCES', sources: []}
}

export const saveSource = (sourceParams: any) => {
    return (dispatch: any): any => {
        dispatch(settingsRequest(true))
        const url = `/source`

        return apiClient.post(url, sourceParams)
            .then(resp => resp.data)
            .then(json => dispatch(setSource(json)))
            .catch(error => dispatch(processError(error)))
            .finally(() => {
                dispatch(settingsRequest(false))
            })
    }
}

export const deleteSource = (source: any) => {
    return (dispatch: any): any => {
        dispatch(settingsRequest(true))
        const url = `/source/${source.id}/cancel`
        const data: any = {}

        return apiClient.post(url, data)
            .catch(error => dispatch(processError(error)))
            .finally(() => {
                dispatch(settingsRequest(false))
            })
    }
}

export const fetchSource = (id: string) => {
    return (dispatch: any): any => {
        dispatch(settingsRequest(true))
        const url = `/source/${id}`

        return apiClient.get(url)
        .then(resp => resp.data)
        .then(json => {
            dispatch(setSource(json))
        })
        .catch(error => dispatch(processError(error)))
        .finally(() => {
            dispatch(settingsRequest(false))
        })
    }
}

export const authorizeSource = (source: any, accountNumber:string) => {
    return (dispatch: any): any => {
        dispatch(settingsRequest(true))
        const url = `/source/${source.id}/authorize`
        const data: any = {}
        data.id = source.id
        data.account_number = accountNumber

        return apiClient.post(url, data)
            .then(resp => resp.data)
            .then(json => {
                dispatch(setSource(json))
            })
            .catch(error => dispatch(processError(error)))
            .finally(() => {
                dispatch(settingsRequest(false))
            })
    }
}

export const verifySource = (source: any, firstAmount: number, secondAmount: number) => {
    return (dispatch: any): any => {
        dispatch(settingsRequest(true))
        const url = `/source/${source.id}/verify`
        const data: any = {}
        data.id = source.id
        data.amount = firstAmount
        data.amount2 = secondAmount

        return apiClient.post(url, data)
            .then(resp => resp.data)
            .then(json => {
                dispatch(setSource(json))
            })
            .catch(error => dispatch(processError(error)))
            .finally(() => {
                dispatch(settingsRequest(false))
            })
    }
}

export const fetchSources = () => {
    return (dispatch: any): any => {
        dispatch(settingsRequest(true))
        dispatch(fetchingSources(true))
        return apiClient.post(`/source/sync`)
            .then(resp => resp.data)
            .then(json => {
                 const sources = json.sources.filter((f: any) => !f.cancelled_date).map((d: any) => {
                    return d
                })
               dispatch(setSources(sources))
            })
            .catch(error => dispatch(processError(error)))
            .finally(() => {
                dispatch(settingsRequest(false))
                dispatch(fetchingSources(false))
            })
        }
}

export const fetchReaders = (includeStripeInfo?: boolean) =>
    (dispatch: any): any => {
        dispatch(settingsRequest(true))
        dispatch(fetchingSources(true))
        return apiClient.post(`/reader/sync`, {all: includeStripeInfo})
            .then(resp => resp.data)
            .then((json: any) => {
                if (json.readers?.length) {
                    const readers: Reader[] = json.readers.filter((r: Reader) => !r.cancelled_date)
                    dispatch(setReaders(readers))
                }
                return json
            })
            .catch(error => dispatch(processError(error?.data?.message || error)))
            .finally(() => {
                dispatch(settingsRequest(false))
                dispatch(fetchingSources(false))
            })
    }

export const deleteReader = (readerId: string) =>
    (dispatch: any) => {
        return apiClient.post(`/reader/${readerId}/delete`)
            .then(resp => resp.data)
            .then(json => {
                dispatch(removeReader(readerId))
                return json
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }

export const updateReader = (reader: any, image?: File, cancelAction?: boolean) =>
    (dispatch: any) => {
        const url = reader.id ? `/reader/${reader.id}` : `/reader`

        const data = addImage({
            ...reader,
            cancel_action: cancelAction
        }, image)

        return apiClient.post(url, data)
            .then(resp => resp.data)
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }

export const orderReader = (quantity: number, source: string, taxCode: string, line1: string, line2: string, city: string, postalCode: string) =>
    (dispatch: any) => {
        const data = {
            quantity,
            type: 'bbpos_wisee',
            source,
            line1,
            line2,
            city,
            postal_code: postalCode,
            taxcode: taxCode
        }
        return apiClient.post('/reader/purchase', data)
            .then(resp => resp.data)
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }


const fetchingSources = (sending: boolean) => {
    return { type: 'settings/FETCHING_SOURCES', sending }
}

export const savePhoneNumber = (phoneNumber: PhoneNumber) => {
    return (dispatch: any): any => {
        dispatch(settingsRequest(true))
        const existing = !!phoneNumber.id
        const url = existing ? `/number/${phoneNumber.id}` : `/number`
        const data = phoneNumber.toObject()

        return apiClient.post(url, data)
        .then(resp => resp.data)
        .then(json => {
            const newNumber = new PhoneNumber()
            newNumber.setData(json.number)
            dispatch(setPhoneNumber(newNumber))
        })
        .catch(error => dispatch(processError(error)))
        .finally(() => {
            dispatch(settingsRequest(false))
        })
    }
}

export const deletePhoneNumber = (phoneNumber: PhoneNumber) => {
    return (dispatch: any): any => {
        dispatch(settingsRequest(true))
        if (!phoneNumber.id) {
            dispatch(processError(new Error("Can't delete a phone number without an id")))
            return
        }

        const url = `/number/${phoneNumber.id}/delete`
        return apiClient.post(url)
        .catch(error => dispatch(processError(error)))
        .then(() => dispatch(fetchPhoneNumbers()))
    }
}

export const fetchPhoneNumber = (numberParams: any) => {
    return (dispatch: any): any => {
        dispatch(settingsRequest(true))
        return apiClient.post(`/number/sync`, numberParams)
            .then(resp => resp.data)
            .then(json => {
                const phoneNumber = new PhoneNumber()
                phoneNumber.setData(json.numbers[0])
                dispatch(setPhoneNumber(phoneNumber))
            })
            .catch(error => dispatch(processError(error)))
            .finally(() => {
                dispatch(settingsRequest(false))
            })
        }
}

export const setPhoneNumber = (phoneNumber: PhoneNumber) => {
    return { type: 'settings/SET_PHONE_NUMBER', phoneNumber: phoneNumber}
}

export const clearPhoneNumber = () => {
    return { type: 'settings/SET_PHONE_NUMBER', phoneNumber: {} as PhoneNumber}
}

export const fetchPhoneNumbers = () => {
    return (dispatch: any): any => {
        dispatch(settingsRequest(true))
        return apiClient.post(`/number/sync`)
            .then(resp => resp.data)
            .then(json => {
                 const phoneNumbers = json.numbers.filter((f: any) => !f.cancelled_date).map((d: any) => {
                    const number = new PhoneNumber()
                    number.setData(d)
                    return number
                })
               dispatch(setPhoneNumbers(phoneNumbers))
            })
            .catch(error => dispatch(processError(error)))
            .finally(() => {
                dispatch(settingsRequest(false))
            })
        }
}

export const setPhoneNumbers = (phoneNumbers: PhoneNumber[]) => {
    return { type: 'settings/SET_PHONE_NUMBERS', phoneNumbers: phoneNumbers}
}

export const clearPhoneNumbers = () => {
    return { type: 'settings/SET_PHONE_NUMBERS', phoneNumbers: [] as PhoneNumber[]}
}

export const saveSalesTax = (userId: string, address: string, taxCode:string, collectTax: boolean) => {
    return (dispatch: any): any => {
        dispatch(settingsRequest(true))
        const url = `/user/${userId}/taxcode`

        return apiClient.post(url, { tax_address: address, taxcode: taxCode, always_collect_tax: collectTax})
            .then(response => response.data)
            .then(json => {
                if (isUserValid(json)) {
                    localforage.setItem('user', json).then(() => {
                        dispatch(settingsRequest(false))
                    })
                }
            })
            .catch((error) => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export const setFeatures = (features: Feature[]) => {
    return { type: 'settings/SET_FEATURES', features }
}

export const clearFeatures = () => {
    return { type: 'settings/SET_FEATURES', features: []}
}

export const fetchFeatures = () => {
    return (dispatch: any): any => {
        dispatch(settingsRequest(true))
        return apiClient.post(`/feature/sync`)
            .then(resp => resp.data)
            .then(json => dispatch(setFeatures(json)))
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => dispatch(settingsRequest(false)))
    }
}

export const submitFeatures = (user: UserInstance) => {
    return (dispatch: any) => {
        dispatch(settingsRequest(true))
        return apiClient.post(`/user/${user.id}/feature`, user)
            .then(response => response.data)
            .then(json => {
                if (json.user && isUserValid(json.user)) {
                    localforage.setItem('user', json.user).then(() => {
                        dispatch(settingsRequest(false))
                    })
                }
            })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => dispatch(settingsRequest(false)))
    }
}

export const setCredentials = (credentials: Credential[]) => {
    return { type: 'settings/SET_CREDENTIALS', credentials:credentials.filter((credential: Credential) => {return !credential.cancelled_date})}
}

export const clearCredentials = () => {
    return { type: 'settings/SET_CREDENTIALS', credentials:[]}
}

export const fetchCredentials = () => {
    return (dispatch: any) => {
        dispatch(settingsRequest(true))
        return apiClient.post(`/credential/sync`)
            .then(response => response.data)
            .then(json => dispatch(setCredentials(json.credentials)))
            .catch(error => dispatch(processError(error)))
            .finally(dispatch(settingsRequest(false)))
    }
}

export const fetchRecord = (id: string) =>
    (dispatch: any) => {
        dispatch(settingsRequest(true))
        return apiClient.post(`/record/sync`, {id})
            .then(response => response.data)
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(dispatch(settingsRequest(false)))
    }

export const cancelCredential = (credential: Credential) => {
    return (dispatch: any) => {
        dispatch(settingsRequest(true))
        return apiClient.post(`/credential/${credential.id}/cancel`)
            .catch(error => dispatch(processError(error)))
            .finally(dispatch(settingsRequest(false)))
    }
}

export const setCalendars = (calendars: Calendar[]) => {
    return {type: 'settings/SET_CALENDARS', calendars:calendars}
}

export const clearCalendars = () => {
    return {type: 'settings/SET_CALENDARS', calendars:[]}
}

export const fetchCalendars = () => {
    return (dispatch: any): Promise<any> => {
        dispatch(settingsRequest(true))
        return apiClient.get(`/externalcalendars/sync`)
            .then(resp => resp.data)
            .then(json => {
                dispatch(setCalendars(json.calendars))
            })
            .catch(error => dispatch(processError(error)))
            .finally(() => {
                dispatch(settingsRequest(false))
            })
    }
}

export const saveCalendars = (calendars: Calendar[]) => {
    return (dispatch: any): Promise<any> => {
        dispatch(settingsRequest(true))
        return apiClient.post(`/externalcalendars`, {calendars: calendars})
            .then(resp => resp.data)
            .then(json => {
                dispatch(setCalendars(json.calendars))
            })
            .catch(error => dispatch(processError(error)))
            .finally(() => {
                dispatch(settingsRequest(false))
            })
    }
}


export const fetchCalendarsWithCredentials = () => {
    return (dispatch: any): Promise<any> => {
        dispatch(settingsRequest(true))
        return Promise.all([
            apiClient.post(`/credential/sync`).then(resp => resp.data),
            apiClient.get(`/externalcalendars/sync`).then(resp => resp.data),
        ])
        .then(values => {
            dispatch(setCredentials(values[0].credentials))
            dispatch(setCalendars(values[1].calendars))
        })
        .catch(error => dispatch(processError(error)))
        .finally(() => {
            dispatch(settingsRequest(false))
        })
    }
}


export const fetchIndustry = (id: string) =>
    (dispatch: any): Promise<Industry> =>
        apiClient.post('/industry/sync', {id})
            .then((resp: {data: IndustryResponseData}) => resp.data)
            .then(json => json.industry)
            .catch(error => dispatch(processError(error)))


export const fetchEdition = (editionId: string) =>
    (dispatch: any): Promise<any> =>
        apiClient.post('/edition/sync', {edition: editionId})
            .then((resp: {data: Edition}) => resp.data)
            .catch(error => dispatch(processError(error)))


export const subscribeToEdition = (userId: string, editionId: string) =>
    (dispatch: any): Promise<any> =>
        apiClient.post(`/billing/${userId}/edition`, {edition: editionId})
            .then(() => dispatch(getUser(userId)))
            .catch(error => dispatch(processError(error)))


export const subscribe = ({userId, plan, sourceId, trialPeriod, receipt, instance, premiumFeatures, editionId, subscription}: {userId: string, plan: string, sourceId?: string, trialPeriod?: number, receipt?: string, instance?: string, premiumFeatures?: string[], editionId?: string, subscription?: Subscription}) => {
    return (dispatch: any): Promise<any> => {
        let subscriptionId = "premium" === plan ? PREMIUM_ID : "plus" === plan ? PREMIUM_PLUS_ID : PREMIUM_SUITE_ID
        if (subscription && [PREMIUM_ANNUAL_ID, PREMIUM_PLUS_ANNUAL_ID, PREMIUM_SUITE_ANNUAL_ID].includes(subscription.plan ?? '')) {
            subscriptionId = "premium" === plan ? PREMIUM_ANNUAL_ID : "plus" === plan ? PREMIUM_PLUS_ANNUAL_ID : PREMIUM_SUITE_ANNUAL_ID
        }
        let data: any = {
            "source": sourceId,
            "plan": plan,
            "subscription": subscriptionId,
            "receipt": receipt ?? "",
            "trial_period": (trialPeriod ?? 0) > 0 ? trialPeriod! : 0,
        }
        if ((premiumFeatures?.length ?? 0) > 0) {
            data.features = premiumFeatures
        }
        if (instance) {
            data.instance = instance
        }
        if (editionId) {
            data.edition = editionId
        }

        return apiClient.post(`/billing/${userId}/subscribe`, data)
            .then(resp => resp.data)
            .then(json => {
                let user: UserInstance | undefined = undefined
                if (isUserValid(json.user)) {
                    user = json.user as UserInstance
                }
                return user
            })
            .then(user =>
                localforage.getItem('user').then((cachedUser: any) => {
                    if (user && cachedUser?.id === user?.id) {
                        return localforage.setItem('user', user)
                            .then(() => user)
                    }
                    return user
                })
            )
            .catch(error => dispatch(processError(error)))
    }
}

export const downgradePlan = (user_id: string, jsonData: { reason?: string, details?: string } = {}) => {
    return (dispatch: any): PromiseLike<any> => {
        return apiClient.post(`/billing/${user_id}/downgrade`, jsonData)
            .then(resp => resp.data)
            .then(json => getSetUser(json.user))
            .catch((error) => {
                dispatch(processError(error?.data?.message || error))
                return Promise.reject()
            })
    }
}

export const cancelPlan = (user_id: string, jsonData: { reason?: string, details?: string } = {}) => {
    return (dispatch: any): PromiseLike<any> => {
        return apiClient.post(`/billing/${user_id}/cancel`, jsonData)
            .then(resp => resp.data)
            .then(json => getSetUser(json.user))
            .catch((error) => {
                dispatch(processError(error?.data?.message || error))
                return Promise.reject()
            })
    }
}

export const discountPlan = (user_id: string, jsonData: { reason?: string } = {}) => {
    return (dispatch: any): PromiseLike<any> => {
        return apiClient.post(`/billing/${user_id}/discount`, jsonData)
            .then(resp => resp.data)
            .then(json => getSetUser(json.user))
            .catch((error) => {
                dispatch(processError(error?.data?.message || error))
                return Promise.reject()
            })
    }
}

const getSetUser = (user: UserInstance): UserInstance => {
    if (isUserValid(user)) {
        localforage.getItem('user').then((cachedUser: any) => {
            if (user && cachedUser?.id === user?.id) {
                return localforage.setItem('user', user)
                    .then(() => user)
            }
            return user
        })
    }
    return user
}

export const fetchLeadsQuestions = () => {
    return (dispatch: any): any => {
        dispatch(settingsRequest(true))
        return apiClient.post('/field/sync')
            .then(resp => resp.data)
            .then(json => {
                if ('fields' in json) {
                    let fields = []
                    for (let key in json.fields) {
                        if (json.fields[key].is_client === true) {
                            const ff = new Field()
                            ff.setData(json.fields[key])
                            fields.push(ff)
                        }
                    }
                    dispatch(setFields(fields))
                }
            })
            .catch((error) => {
                dispatch(processError(error?.data?.message || error))
                return Promise.reject()
            })
            .finally(() => dispatch(settingsRequest(false)))
    }
}