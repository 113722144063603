import React, {useEffect, useState} from "react";
import {PSButton, PSButtonPrimary} from "../app/PSButton";
import {ReactComponent as CheckmarkIcon} from "../../icons/task.svg";
import {PSVideoModal} from "../app/PSVideoModal";
import {Edition} from "../../models/Edition";
import {AppDispatch} from "../../store";
import {useAppDispatch} from "../../hooks";
import {UserInstance} from "../../models/User";
import {nextGoLiveStep, nextOnboardingStep} from "../../utils/onboarding";
import {lookupIndustryTitle} from "../../models/Industry";

type Props = {
    edition: Edition;
    me: UserInstance;
    title?: string;
    emptyState?: boolean;
}

export const OnboardingSetupDashboard = ({edition, me, title, emptyState}: Props) => {
    const dispatch: AppDispatch = useAppDispatch()
    const [showVideoModal, setShowVideoModal] = useState(false)

    const [industryTitle, setIndustryTitle] = useState('')
    useEffect(() => {
        if (!me) return
        lookupIndustryTitle(me.industry).then((s: string) => setIndustryTitle(s))
    }, [me])

    const onboardingNextStep = () => {
        if (me.onboarding_state === 'ob_complete' || me.onboarding_state?.startsWith('gl_')) {
            dispatch(nextGoLiveStep(me))
        }
        else {
            dispatch(nextOnboardingStep(me))
        }
    }

    return (
        <div className="onboarding">
            <img style={{objectFit: edition.id.endsWith('-industry-edition') ? 'cover' : 'scale-down'}}
                 src={edition.image_uri}
                 alt={title ?? edition.name} />
            <div className="details">
                <div className="benefits" style={{whiteSpace: emptyState ? 'normal' : 'nowrap'}}>
                    {!emptyState && (
                        edition.pitch.map((pitch, ix) =>
                            <div key={`pitch-${ix}}`} className="benefit">
                                <CheckmarkIcon style={{marginRight: 14}} />
                                {pitch}
                            </div>
                        )
                    )}
                    {emptyState && (
                        <>
                            Once your account is setup your dashboard will be where you run your business. Until then, tap “Let's go!” and start setting up your business.
                        </>
                    )}
                </div>
                <div className="buttons">
                    <PSButton onClick={() => setShowVideoModal(true)}>
                        Watch video
                    </PSButton>
                    <PSButtonPrimary onClick={() => onboardingNextStep()}>
                        Let's go!
                    </PSButtonPrimary>
                </div>
            </div>
            {showVideoModal && (
                <PSVideoModal title={`Setup PocketSuite as a ${industryTitle}`}
                              hlsVideoURL={edition.video_uri}
                              isLoading={false}
                              onClose={() => setShowVideoModal(false)} />
            )}
        </div>
    )
}
