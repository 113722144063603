import React, { useEffect, useState } from 'react'
import { currencyFormat } from '../../../utils/numbers'
import { RecordStatusBar } from '../RecordStatusBar'
import { useSelector } from 'react-redux'
import { RecordDetail } from '../RecordDetail'
import { RecordBody } from '../RecordBody'
import { Link } from 'react-router-dom'
import { ReactComponent as ChevronIcon } from '../../../icons/chevron.svg'
import { RootState } from '../../../store'
import { Deposit } from '../../../models/Deposit'
import { getDescription, SourceInstance } from '../../../models/Source'
import { UserInstance } from '../../../models/User'
import { dateFormat } from '../../../utils/dates'
import { Payment } from '../../../models/Payment'
import { RecordStatus } from '../../../utils/records'

export const RecordDeposit = (props: any) => {
    const state = useSelector((state: RootState) => state.entities)

    const record = props.record.id ? state.deposits[props.record.id] || props.record : props.record
    const deposit = new Deposit(record)

    const [statuses, setStatuses] = useState<RecordStatus[]>([])
    useEffect(() => {
        setStatuses([
            {label: 'Created', completed: true},
            {label: 'Sent', completed: !!deposit.sent_date},
            {label: 'Received', completed: !!deposit.paid_date},
        ])
    }, [deposit.sent_date, deposit.paid_date])

    const [payments, setPayments] = useState<Payment[]>([])
    useEffect(() => {
        if (!deposit.id) return
        setPayments(Object.values(state.payments as Payment[]).filter(p => p.deposit === deposit.id))
    }, [deposit.id, state.payments])

    const [payee, setPayee] = useState<Partial<UserInstance>>({id: deposit.owner})
    useEffect(() => {
        if (deposit.owner && deposit.owner in state.payees)
            setPayee(state.payees[deposit.owner])
    }, [deposit.owner, state.payees])

    const [source, setSource] = useState<Partial<SourceInstance>>({id: deposit.source})
    useEffect(() => {
        if (deposit.source && deposit.source in state.sources)
            setSource(state.sources[deposit.source])
    }, [deposit.source, state.sources])

    const [recordDetails, setRecordDetails] = useState<JSX.Element[]>([])
    useEffect(() => {
        let recordDetailElements = []

        if (payee.id && payee.name) {
            const details = (
                <Link to={`/record/client/${payee.id}`}>
                    {payee?.name}
                    <ChevronIcon className="chevron" />
                </Link>
            )
            recordDetailElements.push(
                <RecordDetail header="Payee" details={details} subtitle={getDescription(source)}/>
            )
        }

        if (payments) {
            const details = (
                <>
                    {payments.map((payment: any) => (
                        <div style={{paddingBottom: 8}} key={`deposit-payment-${payment.id}`}>
                            <Link to={`/record/payment/${payment.id}`}>
                                {state.contacts && payment.client in state.contacts && state.contacts[payment.client].name}
                                {' '} ({currencyFormat(payment.payout)})
                                <ChevronIcon className="chevron" />
                            </Link>
                            <div className="subtitle">{dateFormat(payment.created_date, 'MMMM D, YYYY')}</div>
                        </div>
                    ))}
                </>
            )
            recordDetailElements.push(
                <RecordDetail header="Payments" details={details} isText />
            )
        }


        if (deposit.memo) {
            recordDetailElements.push(
                <RecordDetail header="Memo" details={deposit.memo} isText />
            )
        }

        setRecordDetails(recordDetailElements)
    }, [payee.id, payee.name, source, payments, state.contacts, deposit.memo])

    return (
        <div className="recordView">
            <RecordStatusBar statuses={statuses} />
            <div className="recordHeader">
                {deposit.amount && (
                    <h2 className="payment" style={{ cursor: 'text'}}>
                        {currencyFormat(deposit.amount)}
                    </h2>
                )}
                {deposit.refnum && (
                    <div className="title">{deposit.refnum} · {deposit.statusString()}</div>
                )}
                {deposit.created_date && (
                    <div className="time">{dateFormat(deposit.created_date, 'dddd MMM Do, YYYY')}</div>
                )}
            </div>
            <RecordBody recordDetails={recordDetails} />
        </div>
    )
}