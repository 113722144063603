import React, {ReactNode, useEffect, useState} from 'react'
import {SettingsMenuItem} from "./SettingsMenuItem";
import {Accordion} from "@zendeskgarden/react-accordions";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {useAppDispatch, usePSOwner, usePSUser} from "../../hooks";
import {toggleSettingsMenu} from "../../modules/settings";
import { POCKETSUITE_USER_ID } from '../../utils/constants';
import moment from 'moment';

type MenuSectionConfig = {
    title: string,
    menuItems: MenuItemConfig[],
    enabled?: boolean
}

type MenuItemConfig = {
    title: string,
    path: string,
    enabled?: boolean
}

export const SettingsMenu = () => {
    const user = usePSUser()
    const owner = usePSOwner()
    const [configuration, setConfiguration] = useState<MenuSectionConfig[]>([])

    useEffect(() => {
        if (!user || !owner) return

        const isSuperAdmin = user.role === 'owner'
        const singularNumber = (user.type !== 'company')
        const isBasic = !!user.subscribed_date
        const showNumber = !isBasic && !!singularNumber && !!user.sms
        const showNumbers = !isBasic && !singularNumber
        const config: MenuSectionConfig[] = [
            {
                title: 'Account',
                menuItems: [
                    {title:'Features', path: '/settings/account/features'},
                    {title:'Profile', path: '/settings/account/profile'},
                    {title:'Business', path: '/settings/account/business', enabled: isSuperAdmin},
                    {title:'Location', path: '/settings/account/location'},
                    {title:'Default memos', path: '/settings/account/memos', enabled: isSuperAdmin},
                    {title:'Notifications', path: '/settings/account/notifications', enabled: isSuperAdmin},
                    {title:'Number', path: '/settings/account/number/edit', enabled: showNumber},
                    {title:'Number', path: '/settings/account/numbers', enabled: showNumbers}
                ]
            },
            {
                title: 'Services',
                menuItems: [
                    {title:'Services', path: '/settings/services'},
                    {title:'Classes', path: '/settings/classes', enabled: owner.allow_class},
                    {title:'Reservations', path: '/settings/reservations', enabled: owner.allow_reservation},
                    {title:'Products', path: '/settings/products', enabled: owner.allow_product},
                    {title:'Packages', path: '/settings/packages', enabled: owner.allow_package},
                    {title:'Subscriptions', path: '/settings/subscriptions', enabled: owner.allow_subscription},
                    {title:'Gift certificates', path: '/settings/gifts', enabled: owner.allow_gift},
                    {title:owner.allow_product ? 'Sort services & products' : 'Sort services', path: '/settings/sort'},
                    {title:'Categories', path: '/settings/categories', enabled: owner.allow_category}
                ]
            },
            {
                title: 'Scheduling',
                menuItems: [
                    {title:'Scheduling', path: '/settings/scheduling'},
                    {title:'Cancellations', path: '/settings/cancellations'},
                    {title:'Calendar Sync', path: '/settings/calendar/sync'},
                    {title:'Video conference', path: '/settings/videoconferencing', enabled: !!owner.plan}
                ],
                enabled: owner.allow_booking
            },
            {
                title: 'Marketing Suite',
                menuItems: [
                    {title:'Suite link', path: '/settings/link', enabled: owner.allow_link},
                    {title:'Online chat', path: '/settings/chat', enabled: owner.allow_chat},
                    {title:'Online leads', path: '/settings/leads', enabled: owner.allow_lead},
                    {title:'Direct payments', path: '/settings/payments', enabled: owner.allow_payment},
                    {title:'Contracts', path: '/settings/contracts', enabled: owner.allow_contract},
                    {title:'Forms', path: '/settings/forms', enabled: owner.allow_record}
                ],
                enabled: owner.allow_contract || owner.allow_record || owner.allow_lead || owner.allow_chat || owner.allow_payment || owner.allow_link
            },
            {
                title: 'Payments',
                menuItems: [
                    {title:'Payment methods', path: '/settings/payments/methods'},
                    {title:'Sales tax', path: '/settings/payments/sales-tax', enabled: owner.allow_salestax},
                    {title:'Gratuity', path: '/settings/payments/gratuity', enabled: owner.allow_gratuity},
                    {title:'Payouts', path: '/settings/payments/payout'},
                    {title:'Card readers', path: '/settings/reader', enabled: isSuperAdmin}
                ]
            },
            {
                title: 'Support',
                menuItems: [
                    {title:'Change plan', path: '/settings/change-plan'},
                    {title:'Tutorials', path: '/settings/tutorials'},
                    {title:'Send tax form', path: '/settings/tax-form', enabled: isSuperAdmin && !!owner.first_payment_date && moment(owner.first_payment_date).year() !== moment().year() },
                    {title:'Data export', path: '/settings/data-export', enabled: isSuperAdmin},
                    {title:'Contact us', path: `/messages/${POCKETSUITE_USER_ID}`},
                    {title:'Request a call', path: '/settings/request-call', enabled: user.allow_priority_support},
                    {title:'Login & security', path: '/settings/login-security'},
                    {title:'Privacy & terms', path: `/settings/privacy-terms`}
                ]
            }
        ]

        setConfiguration(config)
    }, [user, owner])

    return <PSAccordion configuration={configuration}/>
}

type Props = {
    configuration: MenuSectionConfig[]
}

const PSAccordion = ({configuration} : Props) => {
    const dispatch = useAppDispatch()
    const state = useSelector((state: RootState) => state.settings)
    const content: Array<ReactNode> = []
    if (configuration) {
        let ix = 0
        for (let i = 0; i < configuration.length; i++) {
            const item = configuration[i]
            const menuItems = item.menuItems;
            const rows: Array<ReactNode> = []

            if (item.enabled === false) continue
            for (let j = 0; j < menuItems.length; j++) {
                rows.push(menuItems[j].enabled || menuItems[j].enabled === undefined ?
                    <SettingsMenuItem key={`menu-item-${i}${j}${menuItems[j].title}`} title={menuItems[j].title} path={menuItems[j].path} /> :
                    <React.Fragment key={`menu-item-${i}${j}${menuItems[j].title}`}></React.Fragment>
                )
            }

            const rowNum = ix++
            content.push(
                <Accordion.Section key={`menu-item-${rowNum}${item.title}`}>
                    <Accordion.Header onClick={() => dispatch(toggleSettingsMenu(rowNum))}>
                        <Accordion.Label>{item.title}</Accordion.Label>
                    </Accordion.Header>
                    <Accordion.Panel>
                        {rows}
                    </Accordion.Panel>
                </Accordion.Section>)
        }
    }
    return (
        <div className="settingsMenu">
            <Accordion level={2} expandedSections={state.expandedMenus}>
                    {content}
            </Accordion>
        </div>
    )
}