import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Redirect, useLocation, useHistory } from 'react-router'
import { Body, Chrome, Content, Nav } from '@zendeskgarden/react-chrome'
import { isUserLoggedIn } from '../../modules/login'
import { PSNavItem } from './PSNavItem'
import { useDispatch, useSelector } from 'react-redux'
import { getUnreadCount } from '../../modules/inbox'
import { ReactComponent as DashboardIcon } from '../../icons/dashboard.svg'
import { ReactComponent as CalendarIcon } from '../../icons/calendar.svg'
import { ReactComponent as ClientIcon } from '../../icons/clients.svg'
import { ReactComponent as MessageIcon } from '../../icons/messages.svg'
import { ReactComponent as DesktopLogo } from '../../icons/logo.svg'
import { ReactComponent as MiniDesktopLogo } from '../../icons/minimized-logo.svg'
import { ReactComponent as SettingsIcon } from '../../icons/settings.svg'
import { connectWebSocket } from '../../utils/websocket'
import { ErrorBoundary } from 'react-error-boundary'
import { AppRouter } from './AppRouter'
import { ErrorFallback } from './ErrorFallback'
import {loadStripe} from '@stripe/stripe-js';
import {Elements as StripeElements} from '@stripe/react-stripe-js';
import { usePSUser } from '../../hooks'
import {WelcomeBack} from '../welcome-back/WelcomeBack'
import { MINUTES_PER_DAY, SERVER_PING_DATE } from '../../utils/constants'
import moment from 'moment'
import { apiClient } from '../../modules/apiclient'
import { InvalidDataError } from '../../models/errors/InvalidDataError'
import { NewUserSelectPlan } from './NewUserSelectPlan'
import { Modal } from '@zendeskgarden/react-modals'
import './app.css'

const FullContent = styled(Content)`
  height: 100%;
`

export const stripePromise = loadStripe(process.env.REACT_APP_API_STRIPE_KEY)

const App = () => {
    const dispatch = useDispatch()
    const state = useSelector(state => {
        return {
            messages: state.messages,
            popover: state.popover
        }
    })

    const user = usePSUser()
    const location = useLocation()
    const [isExpanded, setIsExpanded] = useState(user?.requires_upgrade || location.pathname.match('/settings') === null)
    useEffect(() => {
        setIsExpanded(user?.requires_upgrade || location.pathname.match('/settings') === null)
    }, [user?.requires_upgrade, location.pathname])

    const messageNavRef = useRef(null)

    useEffect(() => {
        connectWebSocket()
    }, [])

    const history = useHistory()
    useEffect(() => {
        const postPingIfRequired = () => {
            const found = localStorage.getItem(SERVER_PING_DATE)
            if (!found || moment(found).add(MINUTES_PER_DAY, 'minutes') < moment()) {
                localStorage.setItem(SERVER_PING_DATE, new Date().toISOString())
                apiClient.post('/sync')
            }
        }

        // ping on first mount to handle just logged in case
        if (user) postPingIfRequired()

        // unregister listener when component unmounts
        return history.listen((location) => {
            // check if a ping is required on each page load
            if (!user || ['/login', '/login/code'].includes(location.pathname)) return

            postPingIfRequired()
        });
    }, [history, user])

    useEffect(() => {
        dispatch(getUnreadCount())
    }, [dispatch])

    const [unreadTop, setUnreadTop] = useState(-100)
    const [unreadLeft, setUnreadLeft] = useState(-100)
    useEffect(() => {
        if (messageNavRef.current) {
            setUnreadTop(messageNavRef.current.offsetTop - 20)
            setUnreadLeft(messageNavRef.current.offsetLeft - 25)
        }
    }, [isExpanded])

    const [showDashboards, setShowDashboards] = useState(true)
    useEffect(() => {
        if (user) setShowDashboards(user.role === 'owner' || user.role === 'admin')
    }, [user])

    // For signups from web registration, ensure that we have their login state
    // setup properly. Also, we do not want to show the Chrome
    if (location.pathname === '/signup-plan') {
        return (
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={(error) => {
                    if (!(error instanceof InvalidDataError)) {
                        window.Rollbar.error(error)
                    }
                }}
            >
                <StripeElements stripe={stripePromise}>
                    <NewUserSelectPlan />
                </StripeElements>
            </ErrorBoundary>
        )
    }

    const redirectUrl = window.location.pathname === '/' ? '' : `?redirectUrl=${encodeURIComponent(window.location.pathname)}`
    if (!isUserLoggedIn()) {
        return <Redirect to={`/login${redirectUrl}`} />
    }

    return (
        <Chrome hue="#314A68" isFluid className="pocketSuiteChrome">
            <Nav isExpanded={isExpanded}
                 style={{width: !isExpanded ? '48px' : null}}
                 className="pocketSuiteNav"
            >
                {state.messages.unreadCount > 0 && (
                    <div className="unreadIndicator"
                         style={{top: unreadTop, left: unreadLeft}}>
                        {state.messages.unreadCount}
                    </div>
                )}
                {isExpanded && (
                    <DesktopLogo width="116" height="40"
                                 style={{margin: '16px auto 32px'}}/>
                )}
                {!isExpanded && (
                    <MiniDesktopLogo width="18" height="18"
                                     style={{margin: '24px auto 46px'}}/>
                )}
                <PSNavItem title="Dashboard"
                           path="/"
                           secondaryPath="/dashboards"
                           secondaryFuzzyMatch
                           isExpanded={isExpanded}
                           icon={<DashboardIcon/>}/>
                <PSNavItem title="Clients" path="/clients"
                           isExpanded={isExpanded}
                           fuzzyMatch
                           disabled={user?.requires_upgrade}
                           icon={<ClientIcon/>}/>
                <PSNavItem title="Messages" path="/messages"
                           isExpanded={isExpanded}
                           fuzzyMatch
                           disabled={user?.requires_upgrade}
                           messageRef={messageNavRef}
                           icon={<MessageIcon/>}/>
                <PSNavItem title="Calendar" path="/calendar"
                           isExpanded={isExpanded}
                           disabled={user?.requires_upgrade}
                           icon={<CalendarIcon/>}/>
                <PSNavItem title="Settings"
                           path="/settings"
                           fuzzyMatch
                           disabled={user?.requires_upgrade}
                           isExpanded={isExpanded}
                           icon={<SettingsIcon/>}/>
                {isExpanded && (
                    <div style={{
                        fontSize: '12px',
                        textAlign: 'center',
                        position: 'absolute',
                        top: 'calc(100% - 90px)',
                        width: '100%'
                    }}>
                        <div style={{fontWeight: '700'}}>Desktop Beta</div>
                        <a href="https://pocketsuite.io/desktop" target="_blank"
                           rel="noopener noreferrer">
                            <div style={{
                                textDecoration: 'underline',
                                color: '#ffffff',
                                margin: '8px 16px'
                            }}>View upcoming updates &amp; leave feedback
                            </div>
                        </a>
                    </div>
                )}
            </Nav>
            <style>
                {`@media print {.pocketSuiteNav{display: none;} .pocketSuiteChrome{height: 100%;}`}
            </style>
            <Body hasFooter={false}>
                <FullContent>
                    <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onError={(error) => {
                            if (!(error instanceof InvalidDataError)) {
                                window.Rollbar.error(error)
                            }
                        }}
                    >
                        <StripeElements stripe={stripePromise}>
                            {user?.requires_upgrade ? <WelcomeBack /> : <AppRouter showDashboards={showDashboards}/>}
                        </StripeElements>
                    </ErrorBoundary>
                </FullContent>
            </Body>
            {state.popover.visible && state.popover.content && <>
                <div className='popover'>
                    {state.popover.content}
                </div>
            </>}
        </Chrome>
    )
}

export default App