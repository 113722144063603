import React from 'react'
import { useSelector } from 'react-redux'
import moment from "moment"

export const DateTable = () => {
    const state = useSelector(state => state.dashboard)

    // Find the largest __react_total, as they are sorted by dates, not numerical value
    const maxIncome = Math.max(...state.metrics.total.topList.map(t => t.__react_total))

    return (
        <table className="incomeTable">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Percentage of Total</th>
                </tr>
            </thead>
            <tbody>
            {state.metrics.total.topList.length === 0 && (
                <tr>
                    <td colSpan="3" className="noData">
                        No data for time period
                    </td>
                </tr>
            )}
            {state.metrics.total.topList.map((obj) => {
                const percentOfTotalAbsolute = obj.__react_total / state.metrics.total.income
                const percentOfTotal = obj.__react_total / maxIncome
                return (
                    <tr key={`toplist-${obj.id}`}>
                        <td>
                            <span className="name">
                                {moment(obj.name, 'YYYY-MM-DD').format('MMM Do, YYYY')}
                            </span>
                        </td>
                        <td>
                            {(obj.__react_total).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}
                        </td>
                        <td>
                            {(percentOfTotalAbsolute*100).toFixed(1)}%
                            <span className="percentBar" style={{width: `${percentOfTotal*340}px`}} />
                        </td>
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}
