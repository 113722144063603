import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {InventoryItem} from "../../models/InventoryAdjustment";
import {fetchAdjustments, InventoryState} from "../../modules/inventory";
import {RootState} from "../../store";
import {HistoryCard} from "./HistoryCard";
import {PSButtonPrimary} from "../app/PSButton";
import {push} from "connected-react-router";
import {Spinner} from "@zendeskgarden/react-loaders";
import {AppHeader} from "../app/AppHeader";
import {useLocation} from "react-router";

export const ViewHistory = () => {
    const state: InventoryState = useSelector((state: RootState) => state.inventory);
    const dispatch = useDispatch();

    const location: {item:InventoryItem} = useLocation().state as {item:InventoryItem};
    const item = location.item;

    useEffect(() => {
        if (!item) return;
        dispatch(fetchAdjustments(item));
    }, [item, dispatch]);

    const getHeaderButton = useCallback(() => {
        return (
            <PSButtonPrimary className="actionButton" onClick={() => dispatch(push('/inventory/edit', {'item': item}))}>
                Change quantity
            </PSButtonPrimary>
        );
    }, [item, dispatch]);

    const getHeaderView = useCallback(() => {
        return (
            <div className="header">
                <h2>Change History</h2>
                {getHeaderButton()}
            </div>
        )
    }, [getHeaderButton]);

    const getEmptyListView = useCallback(() => {
        return (
            <div className="empty">
                <p>This product has</p>
                <p style={{fontWeight: 700, padding: '8px 0'}}>No history changes</p>
                {getHeaderButton()}
            </div>
        );
    }, [getHeaderButton]);

    const loadingIndicator = (
        <div style={{textAlign: 'center', padding: '128px 0'}}>
            <Spinner size="128" color="#314A68" />
        </div>
    );

    const body = (
        <div className="dashboardDetails">
            {state.loading && loadingIndicator}
            {!state.loading && (
                <>
                    {getHeaderView()}
                    <div className="separator" />
                    <div className="list">
                        {state.adjustmentList.length === 0 && getEmptyListView()}
                        {state.adjustmentList.map((result: any, index: number) =>
                            <HistoryCard record={result} key={`history-${result.id || index}`} />
                        )}
                    </div>
                </>
            )}
        </div>
    );

    return (
        <AppHeader title='Inventory' showBackButton={true} middleWidget={null}>
            <div className="pageWrapper">
                {!!item && body}
            </div>
        </AppHeader>
    );
}