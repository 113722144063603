import React from "react";
import {Schedule} from "../../../models/Schedule";
import {ReactComponent as SettingsClassIcon} from "../../../icons/ps_settings_classes.svg";
import {Dropdown, Item, Menu, Trigger} from "@zendeskgarden/react-dropdowns";
import {IconButton} from "@zendeskgarden/react-buttons";
import {ReactComponent as KabobIcon} from "../../../icons/kabob_menu.svg";

type Props = {
    schedule: Schedule,
    bookingCount?: number,
    onEdit: () => void,
    onDelete: () => void,
}

export const LessonCard = ({schedule, bookingCount, onEdit, onDelete}: Props) => {
    let remaining = schedule.capacity
    if (bookingCount)
        remaining = Math.max(schedule.capacity - bookingCount, 0)
    const remainingDesc = remaining ? `${remaining} spot${remaining !== 1 ? 's' : ''} left` : ''

    return (
        <div className="serviceCard">
            <SettingsClassIcon />
            <div className="details">
                <div className="name">{schedule.prettyDescription('dddd MMMM D, h:mm A', true)}</div>
                <div>{remainingDesc}</div>
            </div>
            <div className="edit link">
                <div onClick={() => onEdit()}
                     className="edit">
                    Edit
                </div>
            </div>
            {onDelete && (
                <Dropdown>
                    <Trigger>
                        <IconButton focusInset={false}>
                            <KabobIcon />
                        </IconButton>
                    </Trigger>
                    <Menu hasArrow>
                        <Item onClick={() => onDelete()} value="delete">
                            <span style={{color: 'red'}}>
                                Delete class lesson
                            </span>
                        </Item>
                    </Menu>
                </Dropdown>
            )}
        </div>
    )
}