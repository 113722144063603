import React, {useState} from "react";
import {ContactAvatar} from "../contacts/ContactAvatar";
import {currencyFormat} from "../../utils/numbers";
import moment from "moment";
import {durationOptions} from "../settings/Options";
import {Field, Input, Label, Toggle} from "@zendeskgarden/react-forms";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import { formatHourMinute } from "../../utils";

type Props = {
    type: 'pros' | 'clients' | 'services' | 'products' | 'items' | 'expenses' | 'appointments' | 'subscriptions' | 'packages' | 'export' | 'gifts',
    selected: boolean,
    item: any,
    allowSalesTax?: boolean,
    availability?: any,
    extraData?: any,
    prepaid?: boolean,
    onSelection: (item: any) => void,
    onUpdateValue?: (item: any, newValue: any) => void,
}

export const SelectModalCard = ({type, selected, item, allowSalesTax, extraData, availability, prepaid, onSelection, onUpdateValue}: Props) => {
    const state: { entities: any } = useSelector((state: RootState) => ({
        entities: state.entities,
    }))

    const [salesTaxEnabled, setSalesTaxEnabled] = useState(type === 'services' ? extraData || false : false)
    const [quantity, setQuantity] = useState(type === 'products' ? extraData?.toString() || '' : '')

    const showSalesTax = allowSalesTax && type === 'services'

    const onSelect = (item: any) => {
        if (type === 'products' && quantity === '') {
            setQuantity('1')
            if (onUpdateValue) onUpdateValue(item, 1)
        }
        onSelection(item)
    }

    let icon, details
    switch (type) {
        case "pros":
        case "clients":
            const isAvailable = availability && availability[item.id]
            icon = (
                <div className="icon">
                    <ContactAvatar user={item} />
                </div>
            )
            details = (
                <div className="details">
                    <div className="title">{item.name}</div>
                    {type === 'pros' && availability && (
                        <div className={isAvailable ? 'green' : 'red'}>{isAvailable ? 'Available' : 'Unavailable'}</div>
                    )}
                </div>
            )
            break
        case "services":
            let priceString: string = '', durationString: string = ''
            let showDiscount = false

            if (item.type === 'reservation' && item.start_date)
                durationString = `${moment(item.start_date).format('MMM Do')} - ${moment(item.end_date).format('MMM Do')}`
            else if (item.type === 'class')
                durationString = item.class_type === 'dropin' ? 'Drop-in class' : item.lesson_count_string
            else if (item.type === 'gift')
                durationString = `${currencyFormat(item.rate)} value`
            else if (item.type === 'package' && item.capacity_type === 'duration')
                durationString = formatHourMinute(item.capacity)
            else if (item.type === 'package')
                durationString = Number(item.capacity) === 1 ? '1 session' : `${item.capacity} sessions`
            else if (item.type === 'subscription')
                durationString = 'TODO' // TODO: durationString = [Schedule describe:item.frequency weekDays:nil weekMonth:nil interval:item.interval instances:item.instances dates:nil];
            else if (item.duration) {
                const selected = durationOptions.find(o => Number(o.value) === Number(item.duration))
                durationString = selected?.label || ''
            }
            else
                durationString = 'TBD'

            /* TODO This is only for editing a booking
            if (['package', 'gift', 'subscription'].indexOf(item.type) === -1)
                priceString = 'Prepaid package'
             */
            if (prepaid) {
                priceString = 'Prepaid Package'
            }
            else if (item.rate && Number(item.discount) > 0)
                showDiscount = true
            else if (item.rate && !item.duration && item.type === 'reservation')
                priceString = `${currencyFormat(item.rate)}/day`
            else if (item.rate)
                priceString = currencyFormat(item.rate)
            else
                priceString = 'Price not set'

            if (showDiscount) {
                priceString = currencyFormat(item.rate)
                const totalString = currencyFormat(item.amount)
                // TODO Strike out priceString
                let amountString = `${priceString} ${totalString}`
                if (durationString)
                    priceString = `${amountString} · ${durationString}`
                else
                    priceString = amountString
            }
            else if (priceString && durationString)
                priceString = `${priceString} · ${durationString}`
            else if (!priceString && durationString)
                priceString = durationString
            else
                priceString = ''

            details = (
                <>
                    <div className="details">
                        <div className="title">{item.name}</div>
                        <div className="subtitle">{priceString}</div>
                    </div>
                    {showSalesTax && (
                        <div style={{marginLeft: 'auto'}}>
                            <Field>
                                <Toggle disabled={!selected}
                                        checked={salesTaxEnabled}
                                        onChange={e => {
                                            setSalesTaxEnabled(e.target.checked)
                                            if (onUpdateValue) onUpdateValue(item, e.target.checked)
                                        }}
                                >
                                    <Label style={{fontWeight: 400, color: selected ? '#000000' : '#e5e5e5'}}>
                                        Allow sales tax
                                    </Label>
                                </Toggle>
                            </Field>
                        </div>
                    )}
                </>
            )
            break;
        case "products":
            details = (
                <>
                    <div className="details">
                        <div className="title">{item.name}</div>
                        <div className="subtitle">{currencyFormat(item.rate)}</div>
                    </div>
                    <div>
                        <Field>
                            <Label style={{paddingRight: '4px', color: selected ? '#000000' : '#999999'}}>
                                Quantity
                            </Label>
                            <Input isCompact style={{width: '48px', fontWeight: 400}}
                                   value={quantity}
                                   disabled={!selected}
                                   onChange={e => {
                                       setQuantity(e.target.value)
                                       if (onUpdateValue) onUpdateValue(item, e.target.value)
                                   }}
                            />
                        </Field>
                    </div>
                </>
            )
            break;
        case "subscriptions":
            details = (
                <>
                    <div className="details">
                        <div className="title">{item.name}</div>
                        <div className="subtitle">{currencyFormat(item.rate)}</div>
                    </div>
                </>
            )
            break
        case "packages":
            details = (
                <>
                    <div className="details">
                        <div className="title">{item.name}</div>
                        <div className="subtitle">{currencyFormat(item.rate)}</div>
                    </div>
                </>
            )
            break
        case "gifts":
            details = (
                <>
                    <div className="details">
                        <div className="title">{item.name}</div>
                        <div className="subtitle">{currencyFormat(item.rate)}</div>
                    </div>
                </>
            )
            break
        case "items":
            details = (
                <div className="details">
                    <div className="title">{item.name}</div>
                    <div className="subtitle">{currencyFormat(item.rate)}</div>
                </div>
            )
            break;
        case "appointments":
            let subtitle = `${moment(item.date).format('h:mma')} - ${moment(item.end_date).format('h:mma')}`
            if (item.item && state.entities.items[item.item]) {
                const i = state.entities.items[item.item]
                if (i.name)
                    subtitle = i.name
            }

            details = (
                <>
                    <div className="details">
                        <div className="title">{moment(item.date).format('ddd, MMMM Do')}</div>
                        <div className="subtitle">{subtitle}</div>
                    </div>
                    <div style={{marginLeft: 'auto'}}>
                        <span style={{fontWeight: 700}}>{currencyFormat(item.total)}</span>
                    </div>
                </>
            )
            break;
        case "export":
            details = (
                <div className="details">
                    <div className="title">{item.name}</div>
                </div>
            )
            break;
    }

    return (
        <div className={`selection ${selected ? 'selected' : ''}`}
             key={`selection-card-${item.id}`}
        >
            {icon}
            {details}
            <div className={showSalesTax ? 'serviceAction' : 'action'}
                 style={{marginLeft: selected ? '12px' : '29px'}}
                 onClick={() => onSelect(item)}
            >
                    {selected ? 'Selected' : 'Select'}
            </div>
        </div>
    )
}