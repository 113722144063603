import React, {useState} from "react";
import {Modal, Body, Close, Footer, FooterItem, Header} from "@zendeskgarden/react-modals";
import {Field, Input, Label} from "@zendeskgarden/react-forms";
import {PSButtonPrimary} from "../../app/PSButton";

type Props = {
    source: any,
    onClose: () => void,
    onAuthorize: (accountNumber: string) => void,
}

export const AuthorizeAccountModal = ({source, onClose, onAuthorize}: Props) => {
    const [accountNumber, setAccountNumber] = useState('')

    const onAuthorizeClicked = () => {
        onAuthorize(accountNumber)
    }

    return (
        <Modal className="addNumberModal" onClose={onClose}>
            <Header>
                Verify account
            </Header>
            <Body style={{paddingLeft: 0, paddingRight: 0}}>
                <div className="servicesSettings" style={{maxWidth: 536}}>
                <div className="form">
                <Field className="field">
                    <Label className="label">Routing #</Label>
                    <Input value={source.routing_number}
                           readOnly
                    />
                </Field>
                <Field className="field">
                    <Label className="label">Confirm account #</Label>
                    <Input value={accountNumber}
                           placeholder="12345 12345 12345"
                           onChange={e => setAccountNumber(e.target.value)}
                    />
                </Field>
                </div>
                </div>
            </Body>
            <Footer>
                <FooterItem>
                    <PSButtonPrimary isLink
                            onClick={() => onAuthorizeClicked()}
                            disabled={!accountNumber}>
                    Send
                    </PSButtonPrimary>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )
}