import { Item } from "@zendeskgarden/react-dropdowns";
import { Input, Label, Field } from "@zendeskgarden/react-forms";
import { Spinner } from "@zendeskgarden/react-loaders";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router";
import { SourceInstance } from "../../../models/Source";
import { Subscription } from "../../../models/Subscription";
import { apiClient } from "../../../modules/apiclient";
import { getRecord } from "../../../modules/records";
import { RootState } from "../../../store";
import { isPassedDate, Result } from "../../../utils";
import { currencyFormat } from "../../../utils/numbers";
import { AppHeader } from "../../app/AppHeader";
import { PSButton, PSButtonPrimary } from "../../app/PSButton";
import { PSDropdown } from "../../app/PSDropdown";
import { useSelector } from "react-redux";
import { push } from "connected-react-router";
import { Body, Close, Header, Modal, Footer, FooterItem } from "@zendeskgarden/react-modals";
import { POCKETSUITE_USER_ID, PREMIUM_SUITE_ID, PREMIUM_SUITE_ANNUAL_ID, PREMIUM_PLUS_ID, PREMIUM_PLUS_ANNUAL_ID } from "../../../utils/constants";
import { subscribe } from "../../../modules/settings";
import { confirmSubscription } from "../../../modules/transactions";
import { syncClient, SyncClientResponse } from "../../../modules/contacts";

export const RecordSubscriptionConfirmCharge = () => {
    const dispatch = useDispatch()
    const params = useParams<{recordID: string}>()
    const state = useSelector((state: RootState) => (
        {
            settings: state.settings,
            records: state.records
        }
    ))
    const location: any = useLocation().state
    const [record, setRecord] = useState(new Subscription(location?.record))
    useEffect(() => setRecord(new Subscription(state.records.data)), [state.records.data])
    useEffect(() => {
        if (record?.id === params.recordID) return

        setIsLoading(true)
        Result(dispatch(getRecord('subscription', params.recordID)))
            .finally(() => setIsLoading(false))
    }, [record, dispatch, params.recordID])

    const [isLoading, setIsLoading] = useState(false)
    const [currentSource, setCurrentSource] = useState<SourceInstance | undefined>()
    const [sources, setSources] = useState<SourceInstance[]>([])
    useEffect(() => {
        if (!record?.client) return

        setIsLoading(true)
        Result(dispatch(syncClient(record.client)))
            .then((response: SyncClientResponse) => {
                if (response.sources?.length) {
                    setSources(response.sources)
                }
            })
            .finally(() => setIsLoading(false))
    }, [dispatch, record?.client])

    const [saveEnabled, setSaveEnabled] = useState(false)
    useEffect(() => {
        setSaveEnabled(!!currentSource)
    }, [currentSource])

    const addCard = useCallback(() =>
        dispatch(push(`/client/${record.client}/add-card`))
    , [record?.client, dispatch])

    const description = useCallback(() => {
        let l: string[] = []
        if (record.calculateTax() > 0) {
            l.push(`A sales tax of ${currencyFormat(record.calculateTax())} will be included.`)
        }
        if (Number(record.deposit) > 0) {
            l.push(`A setup fee of ${currencyFormat(record.deposit)} will be charged upfront.`)
        }
        return l.join(' ')
    }, [record])

    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [confirmModalLoading, setConfirmModalLoading] = useState(false)

    let pocketsuitePlan: string | undefined = undefined
    if (record.owner === POCKETSUITE_USER_ID && record?.plan) {
        pocketsuitePlan =
            [PREMIUM_SUITE_ID, PREMIUM_SUITE_ANNUAL_ID].includes(record.plan) ? "suite" :
            [PREMIUM_PLUS_ID, PREMIUM_PLUS_ANNUAL_ID].includes(record.plan) ? "plus" :
            "premium"
    }
    const sendMessage = useCallback(() =>
        dispatch(push(`/messages/${record.client}`)), [dispatch, record.client])

    const confirmPocketsuiteSubscription = useCallback(() => {
        setConfirmModalLoading(true)
        Result(dispatch(subscribe({userId: record.client, plan: pocketsuitePlan!})))
            .then(sendMessage)
    }, [dispatch, pocketsuitePlan, record.client, sendMessage])

    const confrim = () => {
        const confirmData: any = {
            subscriptionId: record.id,
            sourceId: currentSource?.id,
            pos: false
        }
        if (record.gratuity) {
            confirmData.gratuity = record.gratuity
            confirmData.gratuity_type= record.gratuity_type
        }

        setConfirmModalLoading(true)
        Result(dispatch(confirmSubscription(confirmData)))
            .then(sendMessage)
    }

    const body = (
        <div className="pageWrapper">
            <div className="servicesSettings">
                <div className="header">
                    <h1>Confirm &amp; Charge</h1>
                    <div style={{marginLeft: 'auto'}}>
                        <PSButton onClick={addCard} style={{padding: '21px 16px'}}>Add a Card</PSButton>
                        <PSButtonPrimary onClick={() => setShowConfirmModal(true)}disabled={!saveEnabled} style={{padding: '21px 16px', marginLeft: '10px'}}>Save</PSButtonPrimary>
                    </div>
                </div>
                <div className="separator" />
                <div className="description">{description()}</div>
                <div className="form">
                    <Field className='field'>
                        <Label className='label'>Price</Label>
                        <Input disabled
                            value={currencyFormat(record?.calculateChargeAmount())} />
                    </Field>
                    {record?.allow_surcharge && <>
                        <Field className='field'>
                            <Label className='label'>Surcharge</Label>
                            <Input disabled
                                value={currencyFormat(record?.calculateSurcharge())} />
                        </Field>
                    </>}

                    <PSDropdown selected={currentSource}
                                nameProperty="display_name"
                                downshiftProperty="id"
                                placeholder={<div className="grey">Select a card</div>}
                                label="Payment (required)"
                                onSelect={(selection) => setCurrentSource(selection)}
                    >
                        <>
                            {sources.map(source => (
                                <Item key={`source-${source.id}`} value={source}>
                                    {source.display_name}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                </div>
            </div>
            {showConfirmModal && (
                <Modal onClose={() => setShowConfirmModal(false)}>
                    <Header>Confirm and charge</Header>
                    <Body>
                        {confirmModalLoading ?
                            <Spinner size="48" color="#314A68" /> :
                            `Would you like to confirm this subscription${isPassedDate(record.date) ? ' and charge the client' : ''}?`
                        }
                    </Body>
                    <Close aria-label="Close modal" />
                    <Footer>
                        <FooterItem>
                            {!confirmModalLoading && record.owner === POCKETSUITE_USER_ID &&
                                <PSButtonPrimary onClick={confirmPocketsuiteSubscription}>
                                    {pocketsuitePlan === 'suite' ? 'Upgrade to Enterprise' : pocketsuitePlan === 'plus' ? 'Upgrade to Team' : 'Upgrade to Premium'}
                                </PSButtonPrimary>
                            }
                            {!confirmModalLoading && record.owner !== POCKETSUITE_USER_ID &&
                                <PSButtonPrimary onClick={confrim}>
                                    {isPassedDate(record.date) ? 'Confirm and charge' : 'Confirm Subscription'}
                                </PSButtonPrimary>
                            }
                        </FooterItem>
                    </Footer>
                </Modal>
            )}
        </div>
    )

    return (
        <AppHeader title="Subscription" showBackButton middleWidget={null}>
            {!isLoading && body}
            {isLoading && <Spinner size="128" color="#314A68" />}
        </AppHeader>
    )
}