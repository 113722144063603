import React from "react";
import {Form} from "../../models/Form";
import {useAppDispatch} from "../../hooks";
import {push} from "connected-react-router";
import {Field, Label, Toggle} from "@zendeskgarden/react-forms";
import {setForm} from "../../modules/settings";
import {clearFormFieldSelections} from "../../modules/onboarding";

type Props = {
    form: Form,
    isChecked: boolean,
    onToggleItem: (b: boolean) => void,
}

export const OnboardingForm = ({form, isChecked, onToggleItem}: Props) => {
    const dispatch = useAppDispatch()

    return (
        <div className={`serviceCard onboardingCard ${isChecked ? 'selected' : ''}`}>
            <div className="details">
                <div className="name">{form.name}</div>
                <div className="rate">{(form.selected_fields ?? form.fields)?.length ?? 0} questions</div>
            </div>
            <div className="edit">
                {isChecked && (
                    <div className={`buttonLink brightBlue ${isChecked ? '' : 'disabledLink'}`}
                         onClick={() => {
                             if (isChecked) {
                                 dispatch(clearFormFieldSelections())
                                 dispatch(setForm(form))
                                 dispatch(push('/onboarding/form/edit'))
                             }
                         }}
                    >
                        Edit
                    </div>
                )}
            </div>
            <div className="toggle">
                <Field>
                    <Toggle checked={isChecked}
                            onChange={e => {
                                onToggleItem(e.target.checked)
                            }}>
                        <Label>&nbsp;</Label>
                    </Toggle>
                </Field>
            </div>
        </div>
    )
}
