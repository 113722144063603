import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { currencyFormat } from '../../../utils/numbers'
import { RecordStatusBar } from '../RecordStatusBar'
import { useDispatch, useSelector } from 'react-redux'
import { RecordDetail } from '../RecordDetail'
import { RecordBody } from '../RecordBody'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as ChevronIcon } from '../../../icons/chevron.svg'
import { PaymentDetailsModal } from '../PaymentDetailsModal'
import { ReactComponent as ColoredChevronIcon } from '../../../icons/colored_chevron.svg'
import { RootState } from '../../../store'
import { distinctButtons, RecordStatus } from '../../../utils/records'
import { ButtonProps, RecordButtons } from '../RecordButtons'
import { PSButtonPrimary } from '../../app/PSButton'
import { push, replace } from 'connected-react-router'
import { useConfirmModal } from '../../../hooks'
import { Result } from '../../../utils'
import { sendMarkPaidReceipt } from '../../../modules/transactions'
import { SuccessModal } from '../../settings/SuccessModal'
import { UserInstance } from '../../../models/User'
import { refundReasonOptions } from '../../settings/Options'
import { Charge, chargeStatus } from '../../../models/Charge'
import { Payment, paymentStatus } from '../../../models/Payment'
import { displayLineItem } from '../../../utils/lineitems'

export const RecordCharge = (props: any) => {
    const dispatch = useDispatch()
    const location = useLocation<{ refundSuccess: boolean }>().state
    const state = useSelector((state: RootState) => state.entities)

    const charge = useMemo(() => new Charge(props.record), [props.record])
    const payment = useMemo(() => props.record.payment && props.record.payment in state.payments ?
        new Payment(state.payments[props.record.payment]) : undefined, [props.record.payment, state.payments])
    const items = useMemo(() => charge.lines.filter((line: any) => line.type === 'item'), [charge.lines])

    const [statuses, setStatuses] = useState<RecordStatus[]>([])
    const [contact, setContact] = useState<Partial<UserInstance>>({})
    const [recordDetails, setRecordDetails] = useState<JSX.Element[]>([])
    const [showModal, setShowModal] = useState(false)
    const [showRefundSuccessModal, setShowRefundSuccessModal] = useState(false)


    useEffect(() => {
        let recordStatuses = [
            {label: 'Paid', completed: !!charge.created_date},
            {label: 'Sent', completed: !!charge.deposited_date}
        ]

        if ((!!charge.refund_amount && charge.refund_amount > 0) || !!charge.refund_reason)
            recordStatuses.push({label: 'Refunded', completed: charge.refund_amount! > 0})

        setStatuses(recordStatuses)
    }, [charge.created_date, charge.deposited_date, charge.refund_amount, charge.refund_reason])

    useEffect(() => {
        const person = charge.client
        if (person && person in state.contacts)
            setContact(state.contacts[person])
    }, [charge, state.contacts])

    useEffect(() => {
        let recordDetailElements = []

        if (contact.id) {
            const details = (
                <Link to={`/record/client/${contact.id}`}>
                    {contact.name}
                    <ChevronIcon className="chevron" />
                </Link>
            )
            recordDetailElements.push(
                <RecordDetail header={'Client'}
                              details={details}
                              subtitle={!!payment ? paymentStatus(payment) : chargeStatus(charge)}/>
            )
        }

        const refnum = charge.payment ? payment?.refnum : charge.refnum
        if (refnum) {
            recordDetailElements.push(
                <RecordDetail header="Refnum" details={refnum} />
            )
        }

        if (charge.memo) {
            recordDetailElements.push(
                <RecordDetail header="Memo" details={charge.memo} isText />
            )
        }

        if (items.length > 0) {
            const lineItemsList = items.map((i: any) => displayLineItem(i, true))
            recordDetailElements.push(
                <RecordDetail header="Items"
                              details={lineItemsList}
                              isText />
            )
        }

        if (charge.refund_reason) {
            const reason = refundReasonOptions.find(option => option.value === charge.refund_reason)

            if (!!reason) {
                recordDetailElements.push(
                    <RecordDetail header="Refund reason" details={reason.label} />
                )
            }
        }

        if (charge.refund_details) {
            recordDetailElements.push(
                <RecordDetail header="Refund details" details={charge.refund_details} isText />
            )
        }

        setRecordDetails(recordDetailElements)
    }, [contact, charge, state.contacts, payment, items])

    useEffect(() => setShowRefundSuccessModal(location?.refundSuccess), [location?.refundSuccess])

    const [setCtaModalInfo, setCtaModalLoading, showCtaModal, closeCtaModal, ctaComponent] = useConfirmModal()

    const showSendReceipt = useCallback(() => {
        if (!charge?.id) return

        setCtaModalInfo({
            title: "Send receipt",
            body: "Would you like to send the client a receipt?",
            button: "Send receipt",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(sendMarkPaidReceipt(charge.id!)))
                    .then(() => {
                        closeCtaModal()
                        dispatch(replace(`/messages/${charge.client}`))
                    })
            }
        })
        showCtaModal()
    }, [dispatch, charge.id, charge.client, setCtaModalInfo, setCtaModalLoading, closeCtaModal, showCtaModal])

    const sendRefund = useCallback(() =>
        dispatch(push(`/refund/charge/${charge.id}`, { record: charge })), [dispatch, charge])

    const showPayment = useCallback(() =>
        dispatch(push(`/record/payment/${charge.payment}`)), [dispatch, charge.payment])

    const sendMessage = useCallback(() =>
        dispatch(push(`/messages/${charge.client}`)), [charge.client, dispatch])

    const copyCharge = useCallback(() => {
        const payment = new Payment({
            type: "charge",
            owner: charge.owner,
            vendor: charge.owner,
            total: charge.total,
            tax: charge.tax,
            taxcode: charge.taxcode,
            nexus: charge.nexus,
            discount: charge.discount,
            discount_type: charge.discount_type,
            memo: charge.memo,
            lines: charge.lines,
            pos: true
        });
        if (payment.nexus === "transaction")
            payment.location= charge.location

        dispatch(push(`/charge/new`, { payment }))
    }, [charge, dispatch])

    const getButtons = useCallback(() => {
        const buttons: ButtonProps[] = []
        if (charge.mark_paid_date && charge.client)
            buttons.push({ text: "Receipt", onClick: showSendReceipt })
        if (charge.client)
            buttons.push({ text: "Chat", tooltip: "Send Message", onClick: sendMessage })
        if (charge.payment)
            buttons.push({ text: "Payment", tooltip: "View Payment", onClick: showPayment })
        if (charge.mark_paid_date && !charge.cancelled_date && !charge.refunded_date)
            buttons.push({ text: "Refund", onClick: sendRefund })

        return distinctButtons(buttons)
    }, [charge.mark_paid_date, charge.client, charge.payment, charge.cancelled_date, charge.refund_reason,
        showSendReceipt, sendMessage, showPayment, sendRefund])

    const buttons = getButtons()

    return (
        <div className="recordView">
            <div className="topBar">
                <RecordStatusBar statuses={statuses} />
                <div className="actions">
                    <PSButtonPrimary onClick={copyCharge}>
                        Copy
                    </PSButtonPrimary>
                    <RecordButtons buttons={buttons} />
                </div>
            </div>
            <div className="recordHeader">
                <h2 className="payment" onClick={e => setShowModal(true)}>
                    {`${currencyFormat(charge.total)} total`}
                    <ColoredChevronIcon fill="#62CE9A" />
                </h2>
            </div>
            <RecordBody recordDetails={recordDetails} />
            <PaymentDetailsModal visible={showModal}
                                 onClose={() => setShowModal(false)}
                                 type="payment"
                                 record={charge} />
            {ctaComponent}
            {showRefundSuccessModal &&
                <SuccessModal
                    title="Refund request processed"
                    body="This refund will be recorded for reporting purposes only. No funds will be transferred as the payment was not processed through PocketSuite."
                    buttonText="Done"
                    onAction={() => setShowRefundSuccessModal(false)}
                    onClose={() => setShowRefundSuccessModal(false)}
                />
            }
        </div>
    )
}