import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as CloseIcon } from '../../icons/close.svg'
import './typeaheadsearchbar.css'

// To use TypeAheadSearchBar, the state[props.stateField] needs to have the
// following properties included in it:
//  - searchText: string, search query that gets typed into the <input>
//  - isSearching: boolean, search is running
//  - didSearch: boolean, search is complete, searchResults is ready
//  - searchResults: array, search results from the server

export const TypeAheadSearchBar = (props) => {
    const dispatch = useDispatch()
    const state = useSelector(state => state[props.stateField])

    const search = (value) => {
        const cleanValue = value.trimLeft()
        if (cleanValue === '')
            dispatch(props.clearSearch())
        else
            dispatch(props.search(cleanValue))
    }

    return (
        <div className={props.className || 'searchBar'} style={props.style}>
            <input type="text"
                   value={state.searchText}
                   onChange={e => { e.persist(); search(e.target.value); }}
                   placeholder={props.searchBarPlaceholder} />
                {state.searchText && (
                    <span className="clearIcon">
                       <CloseIcon onClick={e => dispatch(props.clearSearch())} />
                    </span>
                )}
        </div>
    )
}

TypeAheadSearchBar.propTypes = {
    stateField: PropTypes.string.isRequired,
    clearSearch: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    searchBarPlaceholder: PropTypes.string.isRequired,
    style: PropTypes.object,
}