import React, {useCallback, useEffect, useState} from "react";
import {useAppDispatch, usePSOwner} from "../../hooks";
import {DashboardState, fetchDashboard} from "../../modules/dashboard";
import {useDashboardLocation} from "../../models/Dashboard";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {AppHeader} from "../app/AppHeader";
import {useParams} from "react-router";
import {Spinner} from "@zendeskgarden/react-loaders";
import {PSDropdown} from "../app/PSDropdown";
import {Item} from "@zendeskgarden/react-dropdowns";
import {
    dashboardDateFilterOptions,
    inventoryDateFilterOptions,
    SelectOption
} from "../settings/Options";
import {DashboardDetailCard} from "./DashboardDetailCard";
import {PSButtonPrimary} from "../app/PSButton";
import {push} from "connected-react-router";

export const DashboardView = () => {
    const dispatch = useAppDispatch()
    const state: DashboardState = useSelector((state: RootState) => state.dashboard)
    const dashboard = state.dashboardResponse.dashboard
    const params = useParams<{dashboard: string}>()
    const locationState = useDashboardLocation()
    const owner = usePSOwner()

    const dateFilterOptions = useCallback(() => {
        return {
            'inventory': inventoryDateFilterOptions
        }[params.dashboard] || dashboardDateFilterOptions
    }, [params.dashboard])

    const defaultDateFilter = useCallback((dashboardId: string) =>
    {
        let found = locationState?.dateFilter ?
            dateFilterOptions().find(option => option.value === locationState?.dateFilter) :
            undefined

        return found ?? ({
            'complete': dateFilterOptions().find(option => option.value === 'ty'),
            'invoices': dateFilterOptions().find(option => option.value === 'ty'),
            'gifts': dateFilterOptions().find(option => option.value === 'all'),
            'records': dateFilterOptions().find(option => option.value === 'mtd')
        }[dashboardId] || dateFilterOptions()[1])
    }, [locationState?.dateFilter, dateFilterOptions])

    const [dateFilter, setDateFilter] = useState(defaultDateFilter(params.dashboard))
    const [viewOptions, setViewOptions] = useState<SelectOption[]>([])
    const [viewFilter, setViewFilter] = useState<SelectOption>()
    const [hideDateFilter, setHideDateFilter] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(false)

    // Show the search bar if we are on a list of items that has more than 10
    const [showSearchBar, setShowSearchBar] = useState(false)
    const [filterText, setFilterText] = useState('')

    useEffect(() => {
        let show = false
        const searchableDashboards = ['inventory']
        if (searchableDashboards.includes(dashboard?.id)){
            show = true
        }
        setShowSearchBar(show && state.dashboardResponse.results.length > 10)
    }, [dashboard?.id, state.dashboardResponse.results])

    useEffect(() => {
        const hiddenDateDashboards = ['employees', 'triggers', 'gifts']
        setHideDateFilter(hiddenDateDashboards.indexOf(params.dashboard) > -1)
    }, [params.dashboard])

    useEffect(() => {
        if (params.dashboard === 'inventory')
            if  (Number(viewFilter?.value || 0) === 0)
                setHideDateFilter(true)
            else
                setHideDateFilter(false)
    }, [params.dashboard, viewFilter])

    useEffect(() => {
        if (!dateFilter || (!viewFilter && params.dashboard !== 'bookings')) return

        dispatch(fetchDashboard(params.dashboard, dateFilter.value, viewFilter?.value ?? '0', locationState?.client?.id))
    }, [dispatch, params.dashboard, locationState?.client, dateFilter, viewFilter, forceRefresh])

    useEffect(() => {
        const options: SelectOption[] = []

        switch (params.dashboard) {
            case 'clients':
                options.push({value: '0', label: 'New'})
                options.push({value: '1', label: 'Active'})
                options.push({value: '2', label: 'Paying'})
                options.push({value: '3', label: 'Past'})
                options.push({value: '4', label: 'Deleted'})
                options.push({value: '5', label: 'Blocked'})
                break
            case 'expenses':
                options.push({value: '0', label: 'Payments'})
                options.push({value: '1', label: 'Future payments'})
                break
            case 'invoices':
                options.push({value: '0', label: 'Open'})
                options.push({value: '1', label: 'Paid'})
                options.push({value: '2', label: 'Sent'})
                options.push({value: '3', label: 'Viewed'})
                options.push({value: '4', label: 'Canceled'})
                options.push({value: '5', label: 'Saved'})
                break
            case 'contracts':
                options.push({value: '0', label: 'Signed'})
                options.push({value: '1', label: 'Sent'})
                options.push({value: '2', label: 'Viewed'})
                options.push({value: '3', label: 'Canceled'})
                options.push({value: '4', label: 'Unsigned'})
                break
            case 'records':
                options.push({value: '0', label: 'Completed'})
                options.push({value: '1', label: 'Sent'})
                options.push({value: '2', label: 'Viewed'})
                options.push({value: '3', label: 'Canceled'})
                options.push({value: '4', label: 'Incomplete'})
                break
            case 'items':
                options.push({value: '0', label: 'All items'})
                options.push({value: '1', label: 'Services'})
                if (owner?.allow_package)
                    options.push({value: '2', label: 'Packages'})
                if (owner?.allow_subscription)
                    options.push({value: '3', label: 'Subscriptions'})
                if (owner?.allow_reservation)
                    options.push({value: '4', label: 'Reservations'})
                if (owner?.allow_product)
                    options.push({value: '5', label: 'Products'})
                if (owner?.allow_class)
                    options.push({value: '6', label: 'Classes'})
                if (owner?.allow_gift)
                    options.push({value: '7', label: 'Gift certificates'})
                break
            case 'tasks':
                options.push({value: '0', label: 'Open'})
                options.push({value: '1', label: 'Completed'})
                break
            case 'links':
                options.push({value: '0', label: 'Views'})
                options.push({value: '1', label: 'Sessions'})
                options.push({value: '2', label: 'Clicks'})
                break
            case 'triggers':
                options.push({value: '0', label: 'Active'})
                options.push({value: '1', label: 'Completed'})
                options.push({value: '2', label: 'Cancelled'})
                options.push({value: '3', label: 'Paused'})
                break
            case 'packages':
                options.push({value: '0', label: 'Active'})
                options.push({value: '1', label: 'Sent'})
                options.push({value: '2', label: 'Viewed'})
                options.push({value: '3', label: 'Expiring'})
                options.push({value: '4', label: 'Expired'})
                options.push({value: '5', label: 'Not paid'})
                options.push({value: '6', label: 'Canceled'})
                break
            case 'subscriptions':
                options.push({value: '0', label: 'Active'})
                options.push({value: '1', label: 'Sent'})
                options.push({value: '2', label: 'Viewed'})
                options.push({value: '3', label: 'Lapsed'})
                options.push({value: '4', label: 'Completed'})
                options.push({value: '5', label: 'Not confirmed'})
                options.push({value: '6', label: 'Canceled'})
                break
            case 'estimates':
                options.push({value: '0', label: 'Open'})
                options.push({value: '1', label: 'Sent'})
                options.push({value: '2', label: 'Viewed'})
                options.push({value: '3', label: 'Accepted'})
                options.push({value: '4', label: 'Deposit paid'})
                options.push({value: '5', label: 'Invoiced'})
                options.push({value: '6', label: 'Scheduled'})
                options.push({value: '7', label: 'Canceled'})
                options.push({value: '8', label: 'Saved'})
                break
            case 'messages':
                options.push({value: '0', label: 'Scheduled'})
                options.push({value: '1', label: 'Completed'})
                break
            case 'gifts':
                options.push({value: '0', label: 'Active'})
                options.push({value: '1', label: 'Sent'})
                options.push({value: '2', label: 'Viewed'})
                options.push({value: '3', label: 'Expiring'})
                options.push({value: '4', label: 'Expired'})
                options.push({value: '5', label: 'Not paid'})
                options.push({value: '6', label: 'Canceled'})
                break
            case 'employees':
                options.push({value: '', label: 'All'})
                break
            case 'classes':
                options.push({value: '0', label: 'Scheduled'})
                options.push({value: '2', label: 'Canceled'})
                break
            case 'billings':
                options.push({value: '0', label: 'Scheduled'})
                options.push({value: '1', label: 'Canceled'})
                options.push({value: '2', label: 'Completed'})
                break
            case 'inventory':
                options.push({value: '0', label: 'Inventory status'})
                options.push({value: '1', label: 'Products to restock'})
                break
            case 'bookings':
                // Intentionally empty
                break
            case 'complete':
            default:
                options.push({value: '', label: 'All'})
                break
        }
        setViewOptions(options)
        setViewFilter(options[0])
    }, [params.dashboard, owner])

    let loadingIndicator
    if (state.isSending) {
        loadingIndicator = (
            <div style={{textAlign: 'center', padding: '128px 0'}}>
                <Spinner size="128" color="#314A68" />
            </div>
        )
    }

    const getTitle = useCallback(() => {
        if (params.dashboard === 'records') return 'forms'
        return params.dashboard
    }, [params.dashboard])

    const getHeaderButton = useCallback(() =>{
        let headerButton
        if (params.dashboard === 'bookings') {
            headerButton = (
                <PSButtonPrimary className="actionButton"
                                 onClick={() => dispatch(push(`/schedule/new`, locationState))}
                >
                    Schedule client
                </PSButtonPrimary>
            )
        }
        else if (params.dashboard === 'subscriptions' || params.dashboard === 'packages' || params.dashboard === 'gifts') {
            const getLinkUrl = (dashboardId: string) => {
                return {
                    'subscriptions': `/subscription/new`,
                    'packages': `/package/new`,
                    'gifts': `/gift/new`
                }[dashboardId]
            }

            headerButton = (
                <PSButtonPrimary className="actionButton" style={{marginLeft: '16px'}}
                                 onClick={() => dispatch(push(getLinkUrl(params.dashboard)!, locationState))}>
                    Sell
                </PSButtonPrimary>
            )
        }
        else if (params.dashboard === 'contracts') {
            headerButton = (
                <PSButtonPrimary className="actionButton"
                                 onClick={() => dispatch(push(`/contract/send`, locationState))}
                >
                    Send
                </PSButtonPrimary>
            )
        }
        else if (params.dashboard === 'records') {
            headerButton = (
                <PSButtonPrimary className="actionButton"
                                 onClick={() => dispatch(push(`/form/send`, locationState))}
                >
                    Send
                </PSButtonPrimary>
            )
        }
        else if (params.dashboard === 'invoices' || params.dashboard === 'estimates') {
            const getLinkUrl = (dashboardId: string) => {
                return {
                    'invoices': `/invoice/new`,
                    'estimates': `/estimate/new`
                }[dashboardId]
            }
            headerButton = (
                <PSButtonPrimary className="actionButton"
                                 onClick={() => dispatch(push(getLinkUrl(params.dashboard)!, locationState))}
                >
                    Create
                </PSButtonPrimary>
            )
        }
        else if (params.dashboard === 'inventory') {
            headerButton = (
                <PSButtonPrimary className="actionButton"
                                 onClick={() => dispatch(push('/inventory/product'))}
                >
                    Add product
                </PSButtonPrimary>
            )
        }
        return headerButton
    }, [params.dashboard, locationState, dispatch])

    const getHeaderLink = useCallback(() => {
        let headerLink
        if (params.dashboard === 'deposits') {
            headerLink = (
                <div className="buttonLink brightBlue"
                     onClick={() => dispatch(push('/dashboards/deposits/balance'))}>
                    View account balance &gt;
                </div>
            )
        }
        if (params.dashboard === 'links') {
            headerLink = (
                <div className="buttonLink brightBlue"
                     onClick={() => dispatch(push('/settings/link'))}>
                    Edit your suite link &gt;
                </div>
            )
        }
        return headerLink
    }, [dispatch, params.dashboard])

    const getBodyButton = useCallback(() =>{
        let bodyButton
        if (params.dashboard === 'bookings') {
            bodyButton = getHeaderButton()
        }
        else if (params.dashboard === 'contracts') {
            bodyButton = (
                <PSButtonPrimary className="actionButton"
                                 onClick={() => dispatch(push(`/contract/send`, locationState))}
                >
                    Send a contract
                </PSButtonPrimary>
            )
        }
        else if (params.dashboard === 'records') {
            bodyButton = (
                <PSButtonPrimary className="actionButton"
                                 onClick={() => dispatch(push(`/form/send`, locationState))}
                >
                    Send a form
                </PSButtonPrimary>
            )
        }
        else if (params.dashboard === 'subscriptions') {
            bodyButton = (
                <PSButtonPrimary className="actionButton"
                                 onClick={() => dispatch(push(`/subscription/new`, locationState))}
                >
                    Auto-charge a client
                </PSButtonPrimary>
            )
        }
        else if (params.dashboard === 'packages') {
            bodyButton = (
                <PSButtonPrimary className="actionButton"
                                 onClick={() => dispatch(push(`/package/new`, locationState))}
                >
                    Sell a package
                </PSButtonPrimary>
            )
        }
        else if (params.dashboard === 'gifts') {
            bodyButton = (
                <PSButtonPrimary className="actionButton"
                                 onClick={() => dispatch(push(`/gift/new`, locationState))}
                >
                    Sell a gift certificate
                </PSButtonPrimary>
            )
        }
        else if (params.dashboard === 'invoices') {
            bodyButton = (
                <PSButtonPrimary className="actionButton"
                                 onClick={() => dispatch(push(`/invoice/new`, locationState))}
                >
                    Create Invoice
                </PSButtonPrimary>
            )
        }
        else if (params.dashboard === 'estimates') {
            bodyButton = (
                <PSButtonPrimary className="actionButton"
                                 onClick={() => dispatch(push(`/estimate/new`, locationState))}
                >
                    Create estimate
                </PSButtonPrimary>
            )
        }
        return bodyButton
    }, [params.dashboard, locationState, dispatch, getHeaderButton])

    const isHeaderButtonAfterFilters = useCallback(() => {
        const buttonIsAfter = ['contacts', 'gifts', 'packages', 'records', 'subscriptions', 'invoices', 'estimates', 'inventory'];
        return buttonIsAfter.includes(params.dashboard)
    }, [params.dashboard])

    const getHeaderView = useCallback(() => {
        const headerButton = getHeaderButton()
        const headerLink = getHeaderLink()

        return (
            <div className="header">
                <div className="titleBox">
                    <h2>{dashboard.title} ({dashboard.count})</h2>
                    {headerLink}
                </div>
                {!isHeaderButtonAfterFilters() && headerButton}
                <div className="dateFilter"
                    style={{marginLeft: headerButton && !isHeaderButtonAfterFilters() ? '0' : 'auto'}}
                >
                    {!hideDateFilter && (
                        <PSDropdown selected={dateFilter}
                                    nameProperty="label"
                                    onSelect={(selection) => setDateFilter(selection)}
                                    selectWidth="200px"
                                    label="">
                            <>
                                {dateFilterOptions().map(option => (
                                    <Item key={`date-filter-${option.value}`} value={option}>
                                        {option.label}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>
                    )}
                </div>
                {viewOptions.length > 1 && (
                    <PSDropdown selected={viewFilter}
                                nameProperty="label"
                                onSelect={(selection) => setViewFilter(selection)}
                                selectWidth="200px"
                                label="">
                        <>
                            {viewOptions.map(option => (
                                <Item key={`view-option-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                )}
                {isHeaderButtonAfterFilters() && headerButton}
            </div>
        )

    }, [viewFilter, viewOptions, dateFilter, hideDateFilter, dashboard, isHeaderButtonAfterFilters, getHeaderButton, getHeaderLink, dateFilterOptions])

    const getMiddleWidget = () => {
        if (showSearchBar)
            return (
                <div style={{display: 'flex', margin: 'auto', borderRadius: '10px', flex: '1', justifyContent: 'center', alignContent: 'center', height: '40px', minWidth: '33%'}}>
                    <div className="searchBar">
                        <input type="text"
                               placeholder={`Search ${dashboard!.id}`}
                               onChange={e => setFilterText(e.target.value.toLowerCase())}
                        />
                    </div>
                </div>
            );
        return null
    }

    const getEmptyListView = useCallback(() => {
        const bodyButton = getBodyButton()
        if (['invoices', 'estimates', 'subscriptions', 'packages', 'gifts'].includes(dashboard?.id)) {
            return (
                <div className="empty">
                    <p>Nothing here yet.</p>
                    <p style={{fontWeight: 700, padding: '8px 0'}}>You got this!</p>
                    {bodyButton}
                </div>
            )
        }
        else if ( dashboard?.id === 'inventory' && Number(viewFilter?.value || 0) === 1) {
            return (
                <div className="empty">
                    <p>Great job!</p>
                    <p style={{fontWeight: 700, padding: '8px 0'}}>No products out of stock.</p>
                </div>
            )
        }

        return (
            <div className="empty">
                <p>You don't have any {dashboard.title.toLowerCase()} {dateFilter.value !== 'all' ? dateFilter.label.toLowerCase() : ''}</p>
                <p style={{fontWeight: 700, padding: '8px 0'}}>Select a different date or filter to view more</p>
                {bodyButton}
            </div>
        )
    }, [dashboard, dateFilter, getBodyButton, viewFilter?.value])

    return (
        <AppHeader title={getTitle()}
                   showBackButton={true}
                   middleWidget={getMiddleWidget()}>
            <div className="dashboardDetails">
                {loadingIndicator}
                {!state.isSending && dashboard && (
                    <>
                        {getHeaderView()}
                        <div className="separator" />
                        <div className="list">
                            {state.dashboardResponse.results.length === 0 && getEmptyListView()}
                            {state.dashboardResponse.results
                                .filter((result: any) => !filterText || result.name.toLowerCase().indexOf(filterText) > -1)
                                .map((result: any, index: number) => {
                                    result.record_type =  state.dashboardResponse.types?.length > index ? state.dashboardResponse.types[index] : undefined
                                    return (
                                        <DashboardDetailCard
                                            dashboard={dashboard}
                                            data={result}
                                            view={Number(viewFilter?.value || 0)}
                                            key={`dashboard-${result.id || index}`}
                                            onRefresh={() => setForceRefresh(!forceRefresh)}
                                        />
                                    )
                                }
                            )}
                        </div>
                    </>
                )}
            </div>
        </AppHeader>
    )
}