import React, {useEffect, useState, useCallback} from "react";
import {useAppDispatch} from "../../../hooks";
import {
    SettingsState,
    fetchCalendarsWithCredentials,
    saveCalendars,
    cancelCredential
} from "../../../modules/settings";
import {RootState} from "../../../store";
import {useSelector} from "react-redux";
import {Spinner} from "@zendeskgarden/react-loaders";
import {PSButtonPrimary} from "../../app/PSButton";
import {apiClient} from "../../../modules/apiclient";
import {push} from 'connected-react-router';
import {processError} from "../../../modules/error";
import {CredentialCard} from "./CredentialCard";
import { Calendar } from "../../../models/Calendar";
import { Credential } from "../../../models/Credential";
import {ReactComponent as CalendarSyncIcon} from "../../../icons/ps_settings_calendary_sync_nobg.svg";


export const CalendarSyncList = () => {
    const dispatch = useAppDispatch()
    const settingsState: SettingsState = useSelector((state: RootState) => state.settings)
    const credentials = settingsState.credentials.filter(f => f.provider === 'google')
    const [loading, setLoading] = useState(true)

    const reloadFromServer = useCallback(() => {
        const promises = [
            dispatch(fetchCalendarsWithCredentials())
        ]
        Promise.all(promises).then(() => setLoading(false))
    }, [dispatch])

    useEffect(() => {
        reloadFromServer()
    }, [reloadFromServer])

    const startGoogleAuth = (credential?: Credential) =>
        apiClient.get('/auth/google' + (!!credential?.id ? `?credential=${credential?.id}` : ''))
            .then(resp => resp.data )
            .then((json: any) => {
                window.location.href = json.url
            })
            .catch(error => dispatch(processError(error, "Error starting auth process")))

    const disconnectAuth = (credential: Credential) => {
        setLoading(true)
        const calendars = settingsState.calendars.filter((calendar: Calendar) => {return calendar.credential === credential.id})
        calendars.forEach((calendar: Calendar) => {
            calendar.sync_to = false
            calendar.sync_from = false
        })
        const promises = [
            dispatch(saveCalendars(calendars)),
            dispatch(cancelCredential(credential))
        ]
        Promise.all(promises).then(() => reloadFromServer())
    }

    const onEdit = (credential: Credential) => {
        if (credential.failing_date) {
            startGoogleAuth(credential)
            return
        }

        dispatch(push(`/settings/calendar/setup/${credential.id}`, {
            credential: credential,
            calendars: [
                ...settingsState.calendars.filter((calendar: Calendar) => {
                    return calendar.credential === credential.id
                })
                .sort((a: Calendar, b: Calendar) => {
                    return a.summary.localeCompare(b.summary)
                })
            ],
            changes: false
        }))
    }

    const getCredentialState = (credential: Credential) => {
        if (!!credential.failing_date) {
            return 'disconnected'
        }

        if (settingsState.calendars.find((calendar) => calendar.sync_to || calendar.sync_from)) {
            return 'connected'
        }

        return 'unconfigured'
    }

    if (settingsState.isSending || loading) {
        return (
            <div className="servicesSettings">
                <div className="header">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Calendar Sync</h1>
                {!!credentials.length && (
                    <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                     onClick={() => startGoogleAuth()}>
                        Add another
                    </PSButtonPrimary>
                )}
            </div>
            <div className="separator" />
            {credentials.map((credential: any) => {
                                return <CredentialCard key={`credential-${credential.id}`}
                                                    state={getCredentialState(credential)}
                                                    credential={credential}
                                                    onDisconnect={() => disconnectAuth(credential)}
                                                    onEdit={() => onEdit(credential)}
                                    />
            })}
            {!credentials.length && (
                <div className="calendarCard">
                    <CalendarSyncIcon/>
                    <div className="details">
                        <div className="name">Google Calendar</div>
                            <div className="rate">
                                <span style={{color: "#999999"}}>
                                    Sync your Google Calendar
                                </span>
                        </div>

                    </div>
                    <div className="edit">
                        <div className="view">
                            <div onClick={() => startGoogleAuth()} className="view buttonLink brightBlue">
                                Sync
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}