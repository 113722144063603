import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../hooks";
import {
    fetchPhoneNumbers,
    deletePhoneNumber,
    SettingsState
} from "../../../modules/settings";
import {RootState} from "../../../store";
import {useSelector} from "react-redux";
import {Spinner} from "@zendeskgarden/react-loaders";
import {PSButtonPrimary} from "../../app/PSButton";
import localforage from 'localforage'
import { PhoneNumber } from "../../../models/PhoneNumber";
import {POCKETSUITE_USER_ID} from "../../../utils/constants";
import {apiClient} from "../../../modules/apiclient";
import {NumberCard} from "./NumberCard";
import { AddNumberModal } from "./AddNumberModal";
import { DangerModal } from "../DangerModal";


export const Numbers = () => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const [user, setUser] = useState<any>({})

    const [showAddNumberModal, setShowAddNumberModal] = useState<boolean>(false)

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [numberToDelete, setNumberToDelete] = useState<PhoneNumber>(new PhoneNumber())


    useEffect(() => {
        localforage.getItem('user').then((user: any) => setUser(user))
    }, [])

    const [payees, setPayees] = useState<any[]>([])
    const [fetchingPayees, setFetchingPayees] = useState<boolean>(false)
    useEffect(() => {
        if (!user) return

        if (user.type === 'company') {
            setFetchingPayees(true)
            apiClient.post('/payee/sync')
                .then(resp => resp.data)
                .then((json: any) => {
                    // Ignore PS, get employee or contractor
                    const roles = ['employee', 'admin', 'contractor']
                    let payees = json.contacts.filter((u: any) => u.id !== POCKETSUITE_USER_ID)
                        .filter((u: any) => roles.some(role => role === u.role))
                    if (user.sms) {
                        payees.unshift(user)
                    }
                    setPayees(payees)
                })
                .catch(error => {
                    // @ts-ignore
                    window.Rollbar.error('payeeSync error', error)
                })
                .finally( () => {
                    setFetchingPayees(false)
                })
        }
        else {
            setPayees([user])
        }

        dispatch(fetchPhoneNumbers())
    }, [user])

    const confirmDelete = (number: PhoneNumber) => {
        setNumberToDelete(number)
        setShowDeleteConfirmation(true)
    }
    
    const onConfirmDelete = () => {
        setShowDeleteConfirmation(false)
        dispatch(deletePhoneNumber(numberToDelete))
    }
    
    if (state.isSending || fetchingPayees) {
        return (
            <div className="servicesSettings">
                <div className="header">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Numbers</h1>
                <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                 onClick={() => setShowAddNumberModal(true)}>
                    Add Number
                </PSButtonPrimary>
            </div>
            <div className="separator" />
            <div>
                {payees
                    .map((payee: any) => {
                    if (payee.sms) {
                        let num = state.phoneNumbers?.filter((u: PhoneNumber) => u.person === payee.id)[0]
                        if (num)
                            return <NumberCard key={`payee-${payee.id}`}
                                            payee={payee}
                                            phoneNumber={num}
                                            onDelete={() => {confirmDelete(num)}} />
                    }
                })}
            </div>
            {showAddNumberModal && (
                    <AddNumberModal
                        onClose={() => {setShowAddNumberModal(false)}}
                        payees={payees}
                    />
                )}
            {showDeleteConfirmation && (
                <DangerModal title="Delete number"
                             body="Are you sure that you’d like to delete this number? When a number is deleted the system releases it and it may not be available in the future."
                             buttonText="Confirm delete"
                             onClose={() => setShowDeleteConfirmation(false)}
                             onAction={() => onConfirmDelete()}
                />
            )}
        </div>
    )
}