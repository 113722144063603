import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropType from 'prop-types'
import moment from 'moment'
import 'moment-timezone'
import { push } from 'connected-react-router'

import './calendar.css'
import { userColors } from '../../utils/colors'
import { DAY_FORMAT } from '../../modules/calendar'
import {MINUTES_PER_DAY} from '../../utils/constants'
import localforage from 'localforage'

export const CalendarDay = (props) => {
    const dispatch = useDispatch()
    const state = useSelector(state => (
        {
            calendar: state.calendar,
            entities: state.entities
        }
    ))

    useEffect(() => {
        // Skip if we cannot find calendar for some reason
        const calendarBody = document.getElementById('calendarBody')
        if (!calendarBody) {
            return
        }

        // Scroll to the start of day business hours
        localforage.getItem('user').then((user) => {
            const startHours = [
                parseInt(user.sun_start_hour, 10) || 10,
                parseInt(user.mon_start_hour, 10) || 10,
                parseInt(user.tue_start_hour, 10) || 10,
                parseInt(user.wed_start_hour, 10) || 10,
                parseInt(user.thu_start_hour, 10) || 10,
                parseInt(user.fri_start_hour, 10) || 10,
                parseInt(user.sat_start_hour, 10) || 10,
            ]
            const startHour = startHours[state.calendar.currentDate.day()]
            // Adjust for timezone differences
            let adjustedStartHour = moment().tz(user.timezone).hour(startHour).tz(moment.tz.guess()).hours()
            if (adjustedStartHour < 3) {
                adjustedStartHour = 3
            }
            // Scroll to 3 hours before adjusted start hour
            const hourPosition = 84*(adjustedStartHour-3) - 10
            if (calendarBody) {
                calendarBody.scrollTo(0, hourPosition)
            }
        })
    }, [state.calendar.eventsByDate, state.calendar.currentDate])

    if (state.calendar.isSending) return <div />

    const viewEvent = (type, eventData) => {
        dispatch(push(`/record/${type}/${eventData.id}`))
    }

    const renderAllDayEventsForDay = () => {
        let events = state.calendar.eventsByDate[state.calendar.currentDate.format(DAY_FORMAT)]
        if (!events) return

        return events.map((item) => {
            const { type, data, isMultiDay } = item
            const event = data
            const eventDate = moment(event.date)
            const isAllDay = (event.end_date && eventDate.clone().add(1, 'day').subtract(1, 'seconds').isSame(event.end_date))

            // Only render all day or multi-day events here
            if (!isAllDay && !isMultiDay) return null

            const elapsedDuration = state.calendar.currentDate.diff(eventDate, 'minute')
            const durationInMinutes = event.duration - elapsedDuration
            const extendsBeyondThisDay = durationInMinutes > MINUTES_PER_DAY

            const userColor = userColors(event.client || event.title || type)

            const contact = state.entities.contacts[event.client] || {}
            let eventTitle = contact.name || event.title
            if (!eventTitle) {
                if (event.item) {
                    eventTitle = state.entities.items[event.item] && state.entities.items[event.item].name
                    if (!eventTitle)
                        eventTitle = 'Class'
                }
                if (!eventTitle)
                    eventTitle = 'Booking'
            }

            return (
                <div key={`allday-event-${event.id}`}
                     className="allDayWrapper"
                     onClick={e => viewEvent(type, event)}
                     style={{backgroundColor: userColor.bg, width: "calc(100% - 1px)"}}>
                    {eventTitle}
                    {extendsBeyondThisDay && (
                        <span style={{textAlign: 'right', flex: '1', color: userColor.fg}}>&rarr;</span>
                    )}
                </div>
            )
        })
    }

    const renderEventsForDay = () => {
        let events = state.calendar.eventsByDate[state.calendar.currentDate.format(DAY_FORMAT)]
        if (!events) return

        // Sort events so that the overlapping events are readable.
        // Events that start earlier should be sorted first. Events that have
        // the same start time, the event with the longer duration should be sorted first.
        // We then render the events first to last, with each event having a higher
        // z-index than the previous event.
        events = events.slice().sort((a, b) => {
            if (a.data.date === b.data.date)
                return (a.data.duration || 60) - (b.data.duration || 60)
            return a.start.isBefore(b.start) ? -1 : 1
        })

        let overlappingConsecutiveCount = 0

        return events.filter(event => event.data.type !== "class").map((item, index) => {
            const { type, start, end, data, isMultiDay } = item
            const event = data
            const eventDate = moment(event.date)
            const endDate = event.end_date ? end.format('h:mma') : event.duration > 0 ? eventDate.clone().add(event.duration, 'minutes').format('H:mma') : 'TBD'
            const isAllDay = (event.end_date && eventDate.clone().add(1, 'day').subtract(1, 'seconds').isSame(event.end_date))
            const eventDuration = event.duration || 60

            if (isAllDay || isMultiDay) return null

            const startHour = eventDate.hours()
            const startMinute = eventDate.minutes()

            const top = 84*startHour + (84*startMinute)/60
            const height = Math.max(83*eventDuration/60 + 2*((eventDuration/60)-1), 19.25)

            const contact = state.entities.contacts[event.client] || {}
            const bgColor = userColors(event.client || event.title || type).bg

            // If this event's start time is before the previous event's end time, it is
            // an overlapping event and should be shifted to the right some, with a smaller
            // width. This would allow all overlapping events to be visible properly.
            if (index > 0) {
                const previousEvent = events[index - 1]
                if (start.isBefore(previousEvent.end))
                    overlappingConsecutiveCount += 1
                else
                    overlappingConsecutiveCount = 0
            }

            let eventTitle = contact.name || event.title
            if (!eventTitle) {
                if (event.item) {
                    eventTitle = state.entities.items[event.item] && state.entities.items[event.item].name
                    if (!eventTitle)
                        eventTitle = 'Class'
                }
                if (!eventTitle)
                    eventTitle = 'Booking'
            }

            return (
                <div key={`event-${event.id}`}
                     className="eventWrapper"
                     onClick={() => viewEvent(type, event)}
                     title={`${eventTitle} ${eventDate.format('h:mma')} - ${endDate}`}
                >
                    <div className="event"
                         style={{
                             top: `${top}px`,
                             backgroundColor: bgColor,
                             height: `${height}px`,
                             zIndex: `${100+index}`,
                             left: `calc(${Math.log2(overlappingConsecutiveCount+1)*200}px)`,
                             width: `calc(100% - ${Math.log2(overlappingConsecutiveCount+1)*200}px)`,
                             fontSize: eventDuration < 30 ? '11px' : undefined,
                             lineHeight: eventDuration <= 15 ? '15px' : eventDuration < 30 ? '11px' : undefined,
                             paddingTop: eventDuration < 30 ? '1px' : undefined,
                         }}
                    >
                        <div style={{whiteSpace: 'pre', overflow: 'hidden', textOverflow: 'ellipsis'}}>{eventTitle}</div>
                        <div>{eventDate.format('h:mma')} - {endDate}</div>
                        {eventDuration >= 60 && (
                            <div className="location">{event.location}</div>
                        )}
                    </div>
                </div>
            )
        })
    }

    // Determine the height of the all-day event area, so we can adjust the calendar
    // body's height, to keep the entire page from scrolling.
    const events = state.calendar.eventsByDate[state.calendar.currentDate.format(DAY_FORMAT)]
    const dayHeaderCount = !events ? 0 : events.filter((item) => {
        const { data, isMultiDay } = item
        const event = data
        const eventDate = moment(event.date)
        const isAllDay = (event.end_date && eventDate.clone().add(1, 'day').subtract(1, 'seconds').isSame(event.end_date))

        // Only render all day or multi-day events here
        return isAllDay || isMultiDay;
    }).length

    let allDayHeaderHeight
    if (dayHeaderCount > 5)
        allDayHeaderHeight = 125
    else if (dayHeaderCount === 0)
        allDayHeaderHeight = 18
    else
        allDayHeaderHeight = dayHeaderCount*23+12

    return (
        <div className="calendarWeek">
            <div className="headerRow">
                <div className="timezone">{moment.tz(moment.tz.guess()).zoneAbbr()}</div>
                <div className="date">
                    <div>{state.calendar.currentDate.format('ddd')}</div>
                    <div><span className="today">{state.calendar.currentDate.format('D')}</span></div>
                </div>
            </div>
            <div className="allDayRow">
                <div className="timeRow">all day</div>
                <div className="allDayHour">
                    {renderAllDayEventsForDay(state.calendar.currentDate)}
                </div>
            </div>
            <div style={{height: props.height-allDayHeaderHeight}} className="calendarBody" id="calendarBody">
                <div className="timeColumn">
                   <div className="timeRow hour">12am</div>
                   <div className="timeRow hour">1am</div>
                   <div className="timeRow hour">2am</div>
                   <div className="timeRow hour">3am</div>
                   <div className="timeRow hour">4am</div>
                   <div className="timeRow hour">5am</div>
                   <div className="timeRow hour">6am</div>
                   <div className="timeRow hour">7am</div>
                   <div className="timeRow hour">8am</div>
                   <div className="timeRow hour">9am</div>
                   <div className="timeRow hour">10am</div>
                   <div className="timeRow hour">11am</div>
                   <div className="timeRow hour">12pm</div>
                   <div className="timeRow hour">1pm</div>
                   <div className="timeRow hour">2pm</div>
                   <div className="timeRow hour">3pm</div>
                   <div className="timeRow hour">4pm</div>
                   <div className="timeRow hour">5pm</div>
                   <div className="timeRow hour">6pm</div>
                   <div className="timeRow hour">7pm</div>
                   <div className="timeRow hour">8pm</div>
                   <div className="timeRow hour">9pm</div>
                   <div className="timeRow hour">10pm</div>
                   <div className="timeRow hour">11pm</div>
               </div>
                <div style={{marginTop: '-1px'}}>
                    <div className="dayHour" />
                    <div className="dayHour" />
                    <div className="dayHour" />
                    <div className="dayHour" />
                    <div className="dayHour" />
                    <div className="dayHour" />
                    <div className="dayHour" />
                    <div className="dayHour" />
                    <div className="dayHour" />
                    <div className="dayHour" />
                    <div className="dayHour" />
                    <div className="dayHour" />
                    <div className="dayHour" />
                    <div className="dayHour" />
                    <div className="dayHour" />
                    <div className="dayHour" />
                    <div className="dayHour" />
                    <div className="dayHour" />
                    <div className="dayHour" />
                    <div className="dayHour" />
                    <div className="dayHour" />
                    <div className="dayHour" />
                    <div className="dayHour" />
                    <div className="dayHour" />
                </div>
                <div className="day">
                    {renderEventsForDay(state.calendar.currentDate)}
                </div>
            </div>
        </div>
    )
}

CalendarDay.props = {
    height: PropType.number,
}