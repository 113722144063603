import React, {useState} from "react";
import {useAppDispatch} from "../../hooks";
import {Redirect, useHistory, useLocation} from "react-router";
import {Item} from "../../models/Item";
import {AppHeader} from "../app/AppHeader";
import {PSButton, PSButtonPrimary} from "../app/PSButton";
import {Field, Input, Label} from "@zendeskgarden/react-forms";
import {filterMonetaryInput} from "../../utils/numbers";
import {updateItem} from "../../modules/onboarding";
import {push} from "connected-react-router";

export const ItemEditLite = () => {
    const dispatch = useAppDispatch()

    const routerLocation = useLocation()
    const locationState: any = routerLocation.state
    const item: Item = locationState?.item
    const forOnboarding: boolean = locationState?.forOnboarding

    const history = useHistory()

    const onSaveHandler = () => {
        item.name = name
        item.rate = price
        item.deposit = item.type === 'package' ? price : deposit
        // Only refresh the data if we are in onboarding, so if the user pushes
        // back, they should refresh the item list. In forever defaults though,
        // there is no back button, so no need to refresh the data after an edit
        dispatch(updateItem(item, forOnboarding))
        history.goBack()
    }

    const [name, setName] = useState(item.name)
    const [price, setPrice] = useState(item.rate)
    const [deposit, setDeposit] = useState(item.deposit)
    const [showDeposit, setShowDeposit] = useState(['package', 'product'].indexOf(item.type) === -1)

    if (!item) {
        return <Redirect to='/onboarding/hours' />
    }

    return (
        <AppHeader title="Setup" showBackButton middleWidget={null}>
            <div className="scheduleClient servicesSettings">
                <div className="header">
                    <h1 style={{textTransform: 'capitalize'}}>{item.type}</h1>
                    <PSButton style={{height: 40, marginLeft: 'auto'}}
                        onClick={() => dispatch(push('/onboarding/item/edit', {item, forOnboarding}))}
                    >
                        Edit more details
                    </PSButton>
                    <PSButtonPrimary style={{height: 40, marginLeft: 16}}
                                     onClick={() => onSaveHandler()}
                    >
                        Save
                    </PSButtonPrimary>
                </div>

                <div className="separator" />

                <div className="form">
                    <Field className="field">
                        <Label className="label">Name (required)</Label>
                        <Input value={name}
                               onChange={e => setName(e.target.value)} />
                    </Field>

                    <Field className="field">
                        <Label className="label">Price · $</Label>
                        <Input value={price}
                               onChange={e => filterMonetaryInput(e.target.value, setPrice)} />
                    </Field>

                    {showDeposit && (
                        <Field className="field">
                            <Label className="label">{item.type === 'subscription' ? 'Setup fee' : 'Deposit'} · $</Label>
                            <Input value={deposit}
                                   onChange={e => filterMonetaryInput(e.target.value, setDeposit)} />
                        </Field>
                    )}
                </div>
            </div>
        </AppHeader>
    )
}