import React, {useEffect} from "react";
import {OnboardingCategories} from "../../onboarding/OnboardingCategories";
import {clearSelections, setCategories} from "../../../modules/onboarding";
import {useAppDispatch} from "../../../hooks";

export const CategoryFromDefaults = () => {
    const dispatch = useAppDispatch()

    // Ensure state is clear when loading this
    useEffect(() => {
        dispatch(clearSelections())
        dispatch(setCategories([]))
    }, [dispatch])

    return <OnboardingCategories forOnboarding={false} />
}