import { useLocation } from "react-router"
import { UserInstance } from "./User"

type DashboardLocationState = {
    client?: UserInstance;
    dateFilter?: string;
}

export const useDashboardLocation = (): DashboardLocationState | undefined => {
    const location = useLocation()
    return location.state as DashboardLocationState
}

export type DashboardInstance = {
    id: string;
    status: string;
    date_type: string;
    title: string;
    feature: string;
    count: number;
    metric?: string;
    value: string;
    view: number;
    view_count: number;
    person?: string;
    type?: string;
    results?: {id: string, title: string}[];
    meta_data?: object;
}

export class Dashboard {
    id: string = '';
    status: string = '';
    date_type: string = '';
    title: string = '';
    feature: string = '';
    count: number = 0;
    metric?: string;
    value: string = '';
    view: number = 0;
    view_count: number = 0;
    person?: string;
    results?: {id: string, title: string}[];
    meta_data?: any;

    constructor(dashboard: DashboardInstance) {
        this.setData(dashboard)
    }

    setData(data: any) {
        this.id = data.id
        this.status = data.status
        this.date_type = data.date_type
        this.title = data.title
        this.feature = data.feature
        this.count = data.count
        this.metric = data.metric
        this.value = data.value
        this.view = data.view
        this.view_count = data.view_count
        this.person = data.person
        this.results = data.results
        this.meta_data = data.meta_data
    }
}