import React, {useState} from "react";
import {useAppDispatch} from "../../../hooks";
import {Modal, Body, Close, Footer, FooterItem, Header} from "@zendeskgarden/react-modals";
import {Item as DropdownItem} from "@zendeskgarden/react-dropdowns";
import {PSDropdown} from "../../app/PSDropdown";
import {PSButtonPrimary} from "../../app/PSButton";
import { push } from "connected-react-router";

type Props = {
    onClose: () => void,
    payees: any[],
}

export const AddNumberModal = ({onClose, payees}: Props) => {
    const dispatch = useAppDispatch()

    const [selected, setSelected] = useState<any>(undefined)


    const onNextClicked = () => {
        if (selected)
            dispatch(push(`/settings/account/number/${selected.id}/new`))
    }

    return (
        <Modal className="addNumberModal" onClose={onClose}>
            <Header>
                Select Pro
            </Header>
            <Body style={{paddingLeft: 0, paddingRight: 0}}>
                <div className="servicesSettings" style={{maxWidth: 536}}>
                <div className="form">
                <PSDropdown
                            selected ={selected}
                            nameProperty="name"
                            onSelect={(selection) => setSelected(selection)}
                            label="Team member"
                            hint="Select a Pro to add a Premium number for them or setup call forwarding"
                            placeholder={<div className="grey">Team member</div>}>
                            <>
                            {payees.map(payee => (
                                <DropdownItem key={`payee-${payee.id}`} value={payee}>
                                    {payee.name}
                                </DropdownItem>
                            ))}    
                            </>
                </PSDropdown>
                </div>
                </div>
            </Body>
            <Footer>
                <FooterItem>
                    <PSButtonPrimary isLink
                            onClick={() => onNextClicked()}
                            disabled={!selected}>
                        Next
                    </PSButtonPrimary>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )
}