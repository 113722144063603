import React, {useEffect} from "react";
import {useAppDispatch} from "../../hooks";
import {clearSelections, selectItem, setItems} from "../../modules/onboarding";
import {OnboardingItems} from "../onboarding/OnboardingItems";
import {RootState} from "../../store";
import {useSelector} from "react-redux";
import {useLocation} from "react-router";

export const ItemFromDefaults = () => {
    const dispatch = useAppDispatch()
    const state: Pick<RootState, "onboarding"> = useSelector((state: RootState) => {
        return {
            onboarding: state.onboarding,
        }
    })

    const routerLocation = useLocation()
    const locationState: any = routerLocation.state

    // Ensure state is clear when loading this
    useEffect(() => {
        if (state.onboarding.refreshData === false) return

        dispatch(clearSelections())
        dispatch(setItems(locationState?.items ?? []))
        locationState?.items?.forEach((i: any) => {
            dispatch(selectItem(i.id, true))
        })
    }, [dispatch, state.onboarding.refreshData, locationState])

    return <OnboardingItems forOnboarding={false}
                            allEditionItemIDs={locationState?.editionItemIDs}
                            initialStep={locationState?.initialStep} />
}