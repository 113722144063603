import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { currencyFloat, currencyFormat } from '../../../utils/numbers'
import { RecordStatusBar } from '../RecordStatusBar'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@zendeskgarden/react-buttons'
import { Link } from 'react-router-dom'
import { RecordDetail } from '../RecordDetail'
import { RecordBody } from '../RecordBody'
import { ReactComponent as ChevronIcon } from '../../../icons/chevron.svg'
import { ReactComponent as ColoredChevronIcon } from '../../../icons/colored_chevron.svg'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { GoogleMapsAPIKey } from '../../../utils/constants'
import { PaymentDetailsModal } from '../PaymentDetailsModal'
import { displayLineItem } from '../../../utils/lineitems'
import { bookingStatus } from '../../../utils/models'
import { getBookings, getNotes, getRecord } from '../../../modules/records'
import { Modal } from '../../app/Modal'
import { RecordBookingsModalBody } from './RecordBookingsModalBody'
import { push } from 'connected-react-router'
import { PSButton, PSButtonPrimary } from '../../app/PSButton'
import { RootState } from '../../../store'
import { usePSUser } from '../../../hooks'
import { checkRecordPermission, PERM_LEVEL_EDIT, PERM_ROLE_CONTRACTOR, Result, userPermission } from '../../../utils'
import { getUser } from '../../../modules/settings'
import { UserInstance } from '../../../models/User'
import { ButtonProps, RecordButtons } from '../RecordButtons'
import { bookingLateInfo, cancellationFeeString, distinctButtons, isForVendor } from '../../../utils/records'
import { DangerModal } from '../../settings/DangerModal'
import { Body, Close, Footer, FooterItem, Header, Modal as ZenModal } from '@zendeskgarden/react-modals'
import { Spinner } from '@zendeskgarden/react-loaders'
import { cancelBooking, confirmBooking, rollbackTransaction, sendBooking, sendClientReceipt } from '../../../modules/transactions'
import { Payment } from '../../../models/Payment'
import { Invoice } from '../../../models/Invoice'
import { LineItem } from '../../../models/LineItem'
import { DisplayCustomField } from '../DisplayCustomField'

const bookingRate = (record: any) => {
    const isZero = !currencyFloat(record.amount) || (!currencyFloat(record.rate) && !currencyFloat(record.deposit))
    if (record.package && isZero)
        return 'Prepaid Package'
    else if (record.rate !== "" && isZero)
        return 'Free'
    else if (isZero)
        return 'Price Not Set'
    else
        return `${currencyFormat(record.asking_amount)} Total`
}

const bookingToLineItem = (booking: any) => {
    if (booking.cancelled_date || booking.invoice_date || booking.paid_date) {
        return undefined
    }

    const payment = new Payment(booking)
    return new LineItem({
        type: "booking",
        booking: booking.id,
        bookingItemID: booking.item,
        date: booking.date,
        memo: booking.memo,
        total: payment.calculateChargeAmount(),
        rate: payment.calculateChargeAmount(),
        quantity: 1
    })
}

type Props = {
    record: any
}

export const RecordBooking = ({record}: Props) => {
    const dispatch = useDispatch()
    const state: {entities: any, records: any} = useSelector((state: RootState) => (
        {
            entities: state.entities,
            records: state.records,
        }
    ))

    useEffect(() => {
        dispatch(getNotes('booking', record.id))
    }, [dispatch, record.id])

    const [showModal, setShowModal] = useState(false)
    const [showGenericModal, setShowGenericModal] = useState(false)
    const [showNoteModal, setShowNoteModal] = useState(false)
    const [modalContents, setModalContents] = useState<JSX.Element>()

    const employees = record.jobs ? record.jobs.map((j: any) => j.person) : []
    const contact = state.entities.contacts[record.client] || {}

    const customFieldList = (record.field_values ? Object.keys(record.field_values) : []).sort((a, b) => {
        const aData = state.entities.fields[a]
        const bData = state.entities.fields[b]
        return aData && bData ? aData.seqnum - bData.seqnum : aData ? 1 : -1
    })

    const formattedEndDate = record.end_date ? moment(record.end_date).format('h:mm a') : 'TBD'

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GoogleMapsAPIKey,
    })

    const user = usePSUser()
    const [owner, setOwner] = useState(record?.owner === user?.id ? user : undefined)
    useEffect(() => {
        if (user && record?.owner) {
            if (record.owner !== user.id) {
                Result(dispatch(getUser(record.owner)))
                    .then((response: UserInstance) => setOwner(response))
            }
            else {
                setOwner(user)
            }
        }
    }, [record, user, dispatch])

    const [client, setClient] = useState<any>()
    useEffect(() => {
        if (!record?.client) return

        const client = Object.keys(state.entities.users).includes(record.client) ?
            state.entities.users[record.client] :
            undefined

        setClient(client)
    }, [record?.client, state.entities.users, dispatch])

    useEffect(() => {
        if (!record?.client) return

        if (!Object.keys(state.entities.users).includes(record.client)) {
            dispatch(getRecord('user', record.client))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record?.client, dispatch])

    const viewNotes = useCallback(() =>
        dispatch(push(`/notes/booking/${record.id}`)), [record.id, dispatch])

    const sendMessage = useCallback(() =>
        dispatch(push(`/messages/${record.client}`)), [record.client, dispatch])

    const chargeClient = useCallback(() => dispatch(push(`/charge/booking/${record.id}/complete`, {booking: record})), [record, dispatch])

    const changeBookingPayment = useCallback(() =>
        dispatch(push(`/change-card/booking/${record.id}`, {record})), [record, dispatch])

    const rebookAppointment = useCallback(() =>
        dispatch(push(`/schedule/new/`, {record, client})), [record, client, dispatch])

    const selectPackage = useCallback(() =>
        dispatch(push(`/select-package/booking/${record.id}`)), [record, dispatch])

    const sendReceipt = useCallback(() => {
        setCtaModalInfo({
            title: "Send receipt",
            body: "Would you like to send a receipt to this client?",
            button: "Send receipt",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(sendClientReceipt(record.id, 'booking')))
                    .then(() => dispatch(getRecord('booking', record.id)))
                    .then(closeCtaModal)
            }
        })
        setShowCtaModal(true)
    }, [dispatch, record.id])

    const confirmAppointment = useCallback(() => {
        setCtaModalInfo({
            title: `Confirm ${record.schedule ? "appointments" : "appointment"}`,
            body: `Would you like to confirm ${record.schedule ? "these appointments" : "this appointment"}?`,
            button: "Confirm",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(confirmBooking(record)))
                    .then(() => dispatch(getRecord('booking', record.id)))
                    .then(closeCtaModal)
            }
        })
        setShowCtaModal(true)
    }, [dispatch, record])

    const undoMarkPaid = useCallback(() => {
        setCtaModalInfo({
            title: "Undo mark paid",
            body: "Would you like to undo this payment?",
            button: "Undo mark paid",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(rollbackTransaction(record.id, 'booking')))
                    .then(() => dispatch(getRecord('booking', record.id)))
                    .then(closeCtaModal)
            },
            danger: true
        })
        setShowCtaModal(true)
    }, [dispatch, record?.id])

    const undoCancel = useCallback(() => {
        setCtaModalInfo({
            title: "Undo cancellation",
            body: "Would you like to undo the appointment cancellation?",
            button: "Undo cancellation",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(rollbackTransaction(record.id, 'booking')))
                    .then(() => dispatch(getRecord('booking', record.id)))
                    .then(closeCtaModal)
            },
            danger: true
        })
        setShowCtaModal(true)
    }, [dispatch, record?.id])

    const viewClass = useCallback(() => dispatch(push(`/record/class/${record.lesson}`)), [dispatch, record.lesson])
    const viewForm = useCallback(() => dispatch(push(`/record/record/${record.record}`)), [record.record, dispatch])
    const viewContract = useCallback(() => dispatch(push(`/record/signature/${record.signature}`)), [record.signature, dispatch])
    const confirmChargeAppointment = useCallback(() => dispatch(push(`/charge/booking/${record.id}/confirm`, {booking: record})), [dispatch, record])

    const [vendor, setVendor] = useState<UserInstance>()
    useEffect(() => {
        if (!user || !record?.vendor) return

        if (user.id === record.vendor) {
            setVendor(user)
            return
        }

        Result(dispatch(getUser(record.vendor)))
            .then((result: UserInstance) => setVendor(result))

    }, [user, record?.vendor, dispatch])

    const [isCancellableClass, setIsCancellableClass] = useState(false)
    const [isCancellableSeries, setIsCancellableSeries] = useState(false)

    const [isLate, setIsLate] = useState(false)
    const [forClass, setForClass] = useState(false)
    const [forVendor, setForVendor] = useState(false)

    useEffect(() => {
        if (!user || !vendor) return

        const item = record.item in state.entities.items ? state.entities.items[record.item] : undefined
        const {isCancellableClass, isCancellableSeries, isLate, forClass, forVendor} = bookingLateInfo(record, item, user, vendor)
        setIsCancellableClass(isCancellableClass)
        setIsCancellableSeries(isCancellableSeries)
        setIsLate(isLate)
        setForClass(forClass)
        setForVendor(forVendor)

    }, [record, state.entities.items, user, vendor])

    const invoiceClient = useCallback((schedule?: any) => {
        const payment = new Payment(record)
        const invoice = new Invoice({
            client: record.client,
            vendor: record.vendor,
            memo: record.memo,
            lines: record.lines
        })
        if (payment.calculateTax() > 0) {
            invoice.taxcode = record.taxcode
            invoice.tax = record.tax
            invoice.nexus = record.nexus
            if (invoice.nexus === "transaction") {
                invoice.location = record.location
            }
        }
        invoice.reminder = vendor?.invoice_reminder ?? ''

        if (schedule) {
            invoice.lines = state.records.bookings.map(bookingToLineItem).filter((z?: LineItem) => !!z)
            invoice.total = invoice.lines.reduce((sum: number, line: LineItem) => {
                return sum + Number(line.total)
            }, 0)
        }
        else {
            invoice.lines = []
            const lineItem = bookingToLineItem(record)
            const owedAmount = !!Number(record.deposit) && !!record.paid_deposit_date ?
                Number(record.total) - Number(record.deposit) :
                record.total

            if (lineItem) {
                invoice.lines.push(lineItem)
            }

            invoice.total = owedAmount
            if (record.paid_deposit_date) {
                invoice.discount = payment.calculateDiscount().toFixed(2)
                invoice.discount_type = "rate"

                invoice.lines.push(new LineItem({
                    taxable: true,
                    category: "adjustments",
                    total: -Number(record.deposit),
                    rate: -Number(record.deposit),
                    quantity: 1,
                    memo: `Deposit - paid on ${moment(record.paid_deposit_date).format("MMM d, yyyy")}`,
                    date: record.paid_deposit_date
                }))
            }
            else {
                invoice.discount = record.discount
                invoice.discount_type = record.discount_type
            }
        }
        dispatch(push(`/invoice/new`, {client, invoice}))


    }, [record, vendor?.invoice_reminder, state.records.bookings, dispatch, client])

    const sendInvoice = useCallback(() => {
        if (!record) return

        const schedule: any = record.schedule_instance
        if (forVendor && !!schedule?.created_date && !schedule?.interval) {
            setCtaModalInfo({
                title: "Send Invoice",
                body: "If you would like to invoice the entire series choose 'Invoice entire series'.",
                button: "Invoice entire series",
                optionalButton: "Invoice this appointment",
                onClick: (optionalButton: boolean) => {
                    invoiceClient(optionalButton ? undefined : record.schedule_instance)
                }
            })
            setShowCtaModal(true)
        }
        else {
            invoiceClient()
        }
    }, [forVendor, invoiceClient, record])

    const cancelAppointment = useCallback(() => {
        if (!user || !vendor) return

        if (forVendor && record.confirmed_date) {
            dispatch(push(`/cancel/booking/${record.id}`, { record, user, vendor }))
            return
        }

        const isRateSet = !!Number(vendor.cancellation_rate)
        const offerCancelAll = isCancellableClass || isCancellableSeries
        const title = !forVendor && isLate && record.source && record.confirmed_date  && isRateSet?
            `Cancel and pay fee ${cancellationFeeString(vendor)}` : isCancellableSeries || forClass ?
            'Cancel this appointment' :
            'Cancel appointment'
        let destructiveButtonLabel = offerCancelAll ? "Cancel this and future dates" : title
        let optionalButtonLabel = offerCancelAll ? title : undefined

        let body = offerCancelAll ?
            `If you would like to cancel only this appointment choose "${optionalButtonLabel}". To cancel this and future appointments choose "Cancel this and future dates".` :
            `If you would like to cancel this appointment choose "${destructiveButtonLabel}"`
        if (forVendor && !!record.channel && !isCancellableClass && !isCancellableSeries) {
            optionalButtonLabel = title;
            destructiveButtonLabel = "Cancel & notify"
            body = `If you would like to cancel this appointment and send a notification to the client select "Cancel & notify". To cancel the appointment without notifying the client select "${optionalButtonLabel}".`
        }

        setCtaModalInfo({
            title: "Cancel Appointment",
            body: body,
            button: destructiveButtonLabel,
            optionalButton: optionalButtonLabel,
            onClick: (optionalButton: boolean) => {
                const cancelAll = offerCancelAll && !optionalButton
                const doNotNotify = !record.channel || optionalButton

                setCtaModalLoading(true)
                Result(dispatch(cancelBooking({ id: record.id, doNotNotify, cancelAll })))
                    .then(() => dispatch(getRecord('booking', record.id)))
                    .then(closeCtaModal)
            }
        })
        setShowCtaModal(true)
    }, [dispatch, forClass, forVendor, isCancellableClass, isCancellableSeries, isLate, record, user, vendor])

    const resendAppointment = useCallback(() => {
        setCtaModalInfo({
            title: "Resend appointment",
            body: "Would you like to resend this appointment to this client?",
            button: "Resend",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(sendBooking(record)))
                    .then(closeCtaModal)
            }
        })
        setShowCtaModal(true)
    }, [dispatch, record])

    const getButtons = useCallback((): [ButtonProps, ButtonProps[]] => {
        let primaryButton : ButtonProps = { text: "Edit", onClick: () => dispatch(push(`/schedule/edit/${record.id}`))}
        const buttons: ButtonProps[] = []

        if (!user || !owner) return [primaryButton, buttons]
        const payment = new Payment(record)

        const forVendor = isForVendor(record, owner)
        const canEdit = checkRecordPermission(user, record, PERM_LEVEL_EDIT)
        if (forVendor && canEdit && record.status === "sentRequest") {
            buttons.push({ text: "Confirm", onClick: confirmAppointment })
            buttons.push({ text: "Chat", tooltip: "Send Message", onClick: sendMessage })
        }
        else if ((!forVendor || canEdit) && !record.cancelled_date && record.status === "sentQuote" && (record.deposit || 0) > 0 && !record.paid_deposit_date) {
            buttons.push({ text: "Confirm", onClick: confirmChargeAppointment, tooltip: "Confirm & Charge" })
            if (forVendor && !record.paid_date && !record.source && record.channel) {
                buttons.push({ text: "Resend", onClick: resendAppointment, tooltip: "Resend Appointment" })
            }
            buttons.push({ text: "Chat", tooltip: "Send Message", onClick: sendMessage })
        }
        else if (forVendor && canEdit && record.confirmed_date && !record.cancelled_date && moment(record.date).startOf('day') <= moment() &&
            !record.invoiced_date && !record.paid_date && !record.package && (payment.rate === undefined || payment.rate === "" || payment.calculateChargeAmount() > 0)) {
            // replace primary 'Edit'  button with 'Charge'
            buttons.push(primaryButton)
            primaryButton = { text: "Charge", onClick: chargeClient }
        }
        else if (forVendor && canEdit && record.confirmed_date && !record.cancelled_date && moment(record.date).startOf('day') <= moment() &&
            !record.invoiced_date && !record.paid_date && (!!record.package || !record.rate || payment.calculateChargeAmount() <= 0)) {
                buttons.push({ text: "Mark paid", onClick: chargeClient })
        }
        else if (forVendor && !record.cancelled_date && !record.completed_date && canEdit) {
            if (!record.confirmed_date)
                buttons.push({ text: "Confirm", onClick: confirmAppointment })
            if (!record.paid_date && !record.source && record.channel)
                buttons.push({ text: "Resend", onClick: resendAppointment, tooltip: "Resend Appointment" })
            buttons.push({ text: "Cancel", tooltip: "Cancel Appointment", onClick: cancelAppointment })
        }
        else if (forVendor && !record.cancelled_date && record.paid_date && canEdit) {
            if (record.type !== "class")
                buttons.push({ text: "Rebook", onClick: rebookAppointment, tooltip: "Rebook Appointment" })
            buttons.push({ text: "Receipt", onClick: sendReceipt, tooltip: "Send Receipt" })
        }
        else if (record.status === "sentQuote" && (!forVendor || canEdit)) {
            buttons.push({ text: "Confirm", onClick: confirmAppointment })
        }
        else if (record.signed_date && (!forVendor || canEdit)) {
            buttons.push({ text: "Signature", onClick: viewContract, tooltip: "View Signature" })
        }
        if (record.id && forVendor && record.confirmed_date && !record.paid_date && !record.invoiced_date && !record.a_package) {
            buttons.push({ text: "Invoice", onClick: sendInvoice })
        }
        buttons.push({ text: "Chat", tooltip: "Send Message", onClick: sendMessage })
        if (record.id && forVendor) {
            buttons.push({ text: "Note", onClick: viewNotes })
        }
        if (record.id && record.type === "class") {
            buttons.push({ text: "Class", onClick: viewClass })
        }
        if (record.completed_date && forVendor) {
            if (record.mark_paid_date)
                buttons.push({ text: "Undo", tooltip: "Undo mark paid", onClick: undoMarkPaid })
            if (record.cancelled_date)
                buttons.push({ text: "Undo", tooltip: "Undo cancel", onClick: undoCancel })
        }
        else if (record.status === "sentRequest") {
            if (forVendor && owner.allow_package && userPermission(user) > PERM_ROLE_CONTRACTOR)
                buttons.push({ text: "Package", tooltip: "Apply Package", onClick: selectPackage })
        }
        else if (record.cancelled_date && forVendor) {
            buttons.push({ text: "Undo", tooltip: "Undo cancel", onClick: undoCancel })
        }
        else if (record.status !== "waitListed") {
            if (forVendor && owner.allow_package && userPermission(user) > PERM_ROLE_CONTRACTOR && !record.paid_deposit_date)
                buttons.push({ text: "Package", tooltip: "Apply Package", onClick: selectPackage })
        }
        if (record.id && (!forVendor || canEdit) && !record.cancelled_date && !record.completed_date)
            buttons.push({ text: "Cancel", tooltip: "Cancel Appointment", onClick: cancelAppointment })
        if (record.id && (!forVendor || canEdit) && record.confirmed_date && record.type !== "class")
            buttons.push({ text: "Rebook", tooltip: "Rebook Appointment", onClick: rebookAppointment })
        if (record.record && record.captured_date && !record.cancelled_date && record.status !== "waitListed")
            buttons.push({ text: "Form", tooltip: "View Form", onClick: viewForm })
        if (record.contract && !record.cancelled_date && record.status !== "waitListed")
            buttons.push({ text: "Contract", tooltip: "View Contract", onClick: viewContract })
        if (record.id && !record.completed_date && !record.captured_date && !record.cancelled_date && record.confirmed_date)
            buttons.push({ text: "Card", tooltip: "Change Card", onClick: changeBookingPayment })

        return [primaryButton, distinctButtons(buttons)]
    }, [user, owner, record, sendMessage, cancelAppointment, rebookAppointment, viewForm, viewContract, changeBookingPayment, dispatch, confirmAppointment, confirmChargeAppointment, resendAppointment, chargeClient, sendReceipt, sendInvoice, viewNotes, viewClass, undoMarkPaid, undoCancel, selectPackage])

    const statuses = []
    if (record.lesson) {
        statuses.push({label: 'Sent', completed: !!record.sent_date})
        statuses.push({label: 'Viewed', completed: !!record.viewed_date})
        statuses.push({label: 'Registered', completed: !!record.confirmed_date})
        statuses.push({label: 'Checked in', completed: !!record.checkin_date})
    }
    else if (record.package) {
        statuses.push({label: 'Created', completed: !!record.created_date})
        statuses.push({label: 'Sent', completed: !!record.sent_date})
        statuses.push({label: 'Confirmed', completed: !!record.confirmed_date})
    }
    else {
        statuses.push({label: 'Created', completed: !!record.created_date})
        statuses.push({label: 'Sent', completed: !!record.sent_date})
        statuses.push({label: 'Confirmed', completed: !!record.confirmed_date})
        statuses.push({label: 'Complete', completed: !!(record.completed_date || record.paid_date)})
    }

    const goBooking = (booking: any) => {
        dispatch(push(`/record/booking/${booking.id}`))
    }

    useEffect(() => {
        if (record.schedule) {
            dispatch(getBookings(record.schedule))
        }
    }, [dispatch, record.schedule])

    type ModalInfo = {title: string, body: string, button: string, optionalButton?: string, danger?: boolean, onClick: (optionalButton: boolean) => void}
    const [ctaModalInfo, setCtaModalInfo] = useState<ModalInfo>()
    const [ctaModalLoading, setCtaModalLoading] = useState(false)
    const [showCtaModal, setShowCtaModal] = useState(false)
    const closeCtaModal = () => {
        setShowCtaModal(false)
        setCtaModalLoading(false)
    }

    const recordDetails = []

    if (contact.id) {
        const details = (
            <Link to={`/record/client/${contact.id}`}>
                {contact.name}
                <ChevronIcon className="chevron" />
            </Link>
        )
        recordDetails.push(
            <RecordDetail header="Client"
                          details={details}
                          subtitle={bookingStatus(record)}
            />
        )
    }

    if (employees.length > 0 && !!employees[0]) {
        const header = `Team member${employees.length > 1 ? 's' : ''}`
        const details = (
            employees.map((employeeID: any) => {
                const employee = state.entities.contacts[employeeID] || {}
                return (
                    <div key={`employee-${employeeID}`} className="details">
                        <Link to={`/record/payee/${employee.id}`}>
                            {employee.name}
                            <ChevronIcon className="chevron" />
                        </Link>
                    </div>
                )
            })
        )

        recordDetails.push(<RecordDetail header={header} details={details} />)
    }

    if (record.schedule_instance && record.schedule_instance.description) {
        const scheduleDetails = (
            <div className="buttonLink" onClick={() => setShowGenericModal(true)}>
                {record.schedule_instance.description}
                <ChevronIcon className="chevron" />
            </div>
        )

        recordDetails.push(
            <RecordDetail header="Schedule"
                          details={scheduleDetails}
            />
        )
    }

    if (record.lines.length > 0) {
        const servicesList = record.lines.map((i: any) => displayLineItem(i, false))

        recordDetails.push(
            <RecordDetail header={`Service${record.lines.length > 1 ? 's' : ''}`}
                          details={servicesList}
            />
        )
    }

    if (record.allow_conference) {
        const details = (
            <a href={record.conference_url_string} target="PSVideo">
                <Button isPrimary style={{height: '32px', width: '65px', backgroundColor: '#62CE9A'}}>Join</Button>
            </a>
        )
        recordDetails.push(
            <RecordDetail header="Video conferencing" details={details} />
        )
    }

    if (record.memo) {
        recordDetails.push(
            <RecordDetail header="Description" details={record.memo} isText />
        )
    }

    if (forVendor && record.source_display) {
        recordDetails.push(
            <RecordDetail header="Payment method" details={record.source_display}/>
        )
    }

    const showNote = (note: any) => {
        const noteBody = (
            <div className="bodyWrapper">
                {note.title && (
                    <h1>{note.title}</h1>
                )}
                <div className="scroll">
                    <p className="text">
                        {note.note}
                    </p>
                    {note.image_uri && (
                        <img className="userImage"
                             src={note.image_uri}
                             alt={note.title} />
                    )}
                </div>
            </div>
        )
        setModalContents(noteBody)
        setShowNoteModal(true)
    }

    if (state.records.notes.length > 0) {
        const notes = state.records.notes.map((note: any, index: any) => (
            <div key={`notes-${note.id}`}>
                <div className="buttonLink" onClick={e => showNote(note)}>
                    {note.title || moment(note.created_date).format('MMM D, YYYY')}
                </div>
                <div style={{lineHeight: '16px', paddingBottom: '6px', color: '#999999', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre'}}>
                    {note.note.replace(/\n/g, ' ')}
                </div>
            </div>
        ))

        recordDetails.push(
            <RecordDetail header="Notes"
                          isText
                          details={notes} />
        )
    }

    if (customFieldList.length > 0) {
        customFieldList.map((field, index) => {
            const fieldData = state.entities.fields[field] || {}
            if (!fieldData) return null

            recordDetails.push(
                <RecordDetail header={fieldData.label}
                              details={<DisplayCustomField name={field} field={fieldData} instance={record} />} />
            )

            return null
        })
    }

    if (record.location) {
        const latLng = {
            lat: parseFloat(record.latitude),
            lng: parseFloat(record.longitude)
        }
        recordDetails.push(
            <RecordDetail header="Location" subtitle={record.location}>
                {isLoaded && !!latLng.lat && !!latLng.lng && (
                    <div style={{marginTop: '12px'}}>
                        <GoogleMap
                            center={latLng}
                            zoom={15}
                            clickableIcons={false}
                            mapContainerStyle={{width: '100%', height: '320px'}}
                            options={{disableDefaultUI: true}}
                        >
                            <Marker position={latLng} />
                        </GoogleMap>
                    </div>
                )}
            </RecordDetail>
        )
    }

    const [primaryButton, buttons] = getButtons()
    return (
        <div className="recordView">
            <div className="topBar">
                <RecordStatusBar statuses={statuses} />
                <div className="actions">
                    <RecordButtons primaryButton={primaryButton} buttons={buttons} />
                </div>
            </div>
            <div className="recordHeader">
                <h2 className="payment" onClick={e => setShowModal(true)}>
                    {bookingRate(record)}
                    <ColoredChevronIcon fill="#62CE9A" />
                </h2>
                <div className="title">{moment(record.date).format('dddd, MMM Do YYYY')}</div>
                <div className="time">
                    {moment(record.date).format('h:mm a')} - {formattedEndDate}
                </div>
            </div>
            <RecordBody recordDetails={recordDetails} />
            <PaymentDetailsModal visible={showModal}
                                 onClose={() => setShowModal(false)}
                                 type="booking"
                                 record={record} />
            <Modal visible={showNoteModal}
                   onClose={() => setShowNoteModal(false)}>
                {modalContents}
            </Modal>
            <Modal visible={showGenericModal}
                   onClose={() => setShowGenericModal(false)}>
                <RecordBookingsModalBody bookings={state.records.bookings}
                                         contacts={state.entities.contacts}
                                         isLoading={state.records.fetchingExtra}
                                         goBooking={goBooking} />
            </Modal>
            {showCtaModal && ctaModalInfo &&
                (ctaModalInfo.danger ?
                    <DangerModal
                        title={ctaModalInfo.title}
                        body={ctaModalLoading ? <Spinner size="48" color="#314A68" /> : ctaModalInfo.body}
                        onClose={closeCtaModal}
                        disableDismiss
                        buttonText={ctaModalInfo.button}
                        extraButtonText={ctaModalInfo.optionalButton}
                        onAction={(buttonIx?: number) => ctaModalInfo.onClick(buttonIx === 2)} /> :
                    <ZenModal onClose={closeCtaModal}>
                        <Header>{ctaModalInfo.title}</Header>
                        <Body>{ctaModalLoading ? <Spinner size="48" color="#314A68" /> : ctaModalInfo.body}</Body>
                        <Close aria-label="Close modal" />
                        <Footer>
                            <FooterItem>
                                {!ctaModalLoading && <>
                                    {ctaModalInfo.optionalButton && <PSButton style={{marginRight: '16px'}} onClick={() => ctaModalInfo.onClick(true)}>{ctaModalInfo.optionalButton}</PSButton>}
                                    <PSButtonPrimary onClick={() => ctaModalInfo.onClick(false)}>{ctaModalInfo.button}</PSButtonPrimary>
                                </>}
                            </FooterItem>
                        </Footer>
                    </ZenModal>
                )}
        </div>
    )
}
