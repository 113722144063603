export class Contract {
    id?: string;
    name: string;
    memo: string;
    always_require: boolean;
    signature?: string;
    signature_data?: string;
    signature_abs_uri?: string;
    edition?: string;
    edition_contract?: string;
    associated_with_service?: boolean;
    refnum?: string;

    constructor() {
        this.name = ''
        this.memo = ''
        this.always_require = false
    }

    setData(data: any) {
        this.id = data.id
        this.name = data.name
        this.memo = data.memo
        this.always_require = data.always_require
        this.signature = data.signature
        this.signature_data = data.signature_data
        this.signature_abs_uri = data.signature_abs_uri
        this.edition = data.edition
        this.edition_contract = data.edition_contract
        this.associated_with_service = data.associated_with_service
    }

    toObject(): any {
        const obj: any = {}
        obj.id = this.id
        obj.name = this.name
        obj.memo = this.memo
        obj.always_require = this.always_require
        if (this.signature_data)
            obj.signature_data = this.signature_data
        if (this.signature)
            obj.signature = this.signature
        if (this.edition)
            obj.edition = this.edition
        if (this.edition_contract)
            obj.edition_contract = this.edition_contract
        return obj
    }
}