import React from "react";

export const LearnMoreLeadTimeBody = (
    <>
        <p>Lead time is the amount of time between when a client views your schedule and when they book an appointment with you. Essentially, it’s how much of a heads up you’d like to have prior to an appointment.</p>
        <br />
        <p>For example:</p>
        <br />
        <p>A 3-hour lead time means that a client viewing your schedule right now would NOT be able to book an appointment with you that begins in less than 3 hours. So if it’s 9:00 am, with a 3-hour lead time, clients won’t be able to book you until noon.</p>
        <br />
        <p>Whereas a "no lead time" setting means that you will take a "walk in" right up until their appointment time is scheduled to begin.</p>
    </>
)

export const LearnMoreFormsBody = (
    <>
        <p>Forms are a great way to collect information from clients, which will automatically be added to their client profile. On the client’s end, they’re able to easily fill it out from their phone, tablet, or computer.</p>
        <br />
        <p>We've gone ahead and pre-loaded some of the most popular forms in your industry. Check them out under Settings &gt; Forms</p>
        <br />
        <p>If you're already using a form we'll upload it for you! Just send it over to <a href="mailto:support@pocketsuite.io">support@pocketsuite.io</a> and we'll add it to your account.</p>
        <br />
        <p>Once it is available in your account, you can add it to your online booking site or send it out directly. Just head to Settings, tap on Services, Packages, Subscriptions, etc. and select a specific Item Name or tap + and select "Attach a form”. The form will then be automatically available for clients to fill out when they are booking or buying something from you.</p>
    </>
)

export const LearnMoreContractsBody = (
    <>
        <p>Contracts make it easy to protect yourself from liability by allowing clients to review and sign your contracts from any phone, tablet or computer.</p>
        <br />
        <p>We've already pre-loaded some of the most popular contracts used by PocketSuite Pros in your industry under Settings &gt; Contracts.</p>
        <br />
        <p>However, if you're already using a contract we'd be happy to upload it for you. Just send it over to <a href="mailto:support@pocketsuite.io">support@pocketsuite.io</a> and we'll add it to your account.</p>
        <br />
        <p>Once it is available in your account, you can add it to your online booking site or send it out directly. Just head to Settings, tap on Services, Packages, Subscriptions, etc. and select a specific Item Name or tap + and select "Attach a contract". The contract will then be automatically available for clients to sign when they are booking or buying something from you.</p>
    </>
)

export const LearnMorePackagesBody = (
    <>
        <p>Packages are a great way to sell a set number of sessions or hours upfront (select from one service, some services, or all services). Once the client has an active package, any session they book that is covered by the package will be automatically deducted and tracked (shown as prepaid package). If you select “show online”, clients can purchase this package through your online booking site.</p>
        <br />
        <p>There are 2 ways to view usage on any package:<br />
           1. Tap on “Packages” from the homescreen &gt; tap on the client/package<br />
           2. Tap on the client’s profile &gt; tap history &gt; select “Packages” &gt; tap active package
        </p>
        <br />
        <p>Here you can view the number of sessions / hours remaining as well as the list of sessions already covered by the package.</p>
    </>
)

export const LearnMoreSubscriptionsBody = (
    <>
        <p>Subscriptions are recurring payments that will automatically charge a client based on your selected auto-charge frequency. Under “Number of Payments”, you can select how many times you would like the client to be billed (continuous means that there is no end date vs setting a number will cap the number of charge cycles).</p>
        <br />
        <p>You have the option to create a subscription that simply charges a recurring payment or to set the number of sessions that can be booked with the subscription.</p>
        <br />
        <p>Creating a subscription and limiting usage allows you to select the number of sessions or hours, and services (select from one service, some services, or all services) that are included in the subscription. Sessions will be renewed at each subscription charge (depending on the frequency).</p>
        <br />
        <p>For example, a monthly subscription could include 4 sessions and at each monthly charge, another 4 sessions are added. </p>
        <br />
        <p>If you’d like to limit usage on a subscription, our system will track session usage with a package. This can be found under “Packages” on the homescreen. Tap the active package and you’ll be able to view the number of sessions / hours remaining.</p>
    </>
)

export const LearnMoreRequireCC = (
    <>
        <p>Specify if you want clients to confirm appointments with a credit card. To enforce your cancellation policy, you would need to require credit cards during booking.”Only if priced” means a credit card would be required when the service has a price specified</p>
    </>
)

export const LearnMoreConfirmationStatus = (
    <>
        <p>Choose what appointment confirmation status you’d like to default to when you’re scheduling. “Already confirmed” lets you schedule without confirmation by the client. Or if your client initiates the appointment booking, lets them schedule without requiring your confirmation. “Requires confirmation” and “Requires credit card” both require confirmation by the client. Or if the client initiates the appointment booking requires your confirmation. You can change these appointment status confirmation settings on an individual appointment if you enable the Itemization feature.</p>
    </>
)

export const LearnMoreCannotBookBeyond = (
    <>
        <p>By default, clients can book you anytime. If you’d like to restrict how far in advance a client can book you tap the “client’s cant book beyond” setting and select an option.</p>
        <br />
        <p>For example, if you do not want clients to book you more than 2 months out, select 2 months from the list of options. When you do this, clients will not see any availability on you calendar beyond the upcoming 2 months on your calendar. </p>
    </>
)

export const LearnMoreFlexStart = (
    <>
        <p>Flex time controls the timing of when your appointments start.</p>
        <br />
        <p>For example, if you work 9 am to 5 pm and have 1 hour and 15 minute appointments your clients will see the following: </p>
        <br />
        <p>Flex 30 minutes:</p>
        <p>You’ll be available every 30 minutes and your clients will see availability at: 9:00 am / 9:30 am / 10:00 am / 10:30 am ... </p>
        <br />
        <p>Flex 60 minutes:</p>
        <p>You’ll be available every 60 minutes and your clients will see availability at: 9:00 am / 10:00 am / 11:00 am / 12:00 pm ...  </p>
        <br />
        <p>No Flex:</p>
        <p>Clients have no flexibility when booking you. Appointment times are based on duration. For the above example your clients will see availability at: 9:00 am / 10:15 am / 11:30 am / 12:45 pm ... </p>
        <br />
        <p>No flex + buffer:</p>
        <p>Same as no flex and includes buffer time (the amount of time in between appointments). With 1 hour and 15 minute appointments and a 15 minute buffer time your clients will see availability at: 9:00 am / 10:30 am / 12:00 pm / 1:30 pm ... </p>
    </>
)

export const LearnMoreBufferTime = (
    <>
        <p>Buffer time is extra time that is added to the end of appointments to prevent being booked back to back.</p>
        <br />
        <p>You can use this time to take a break, clean up, prepare for an upcoming appointment, or travel to your next location.</p>
        <br />
        <p>15 minute:</p>
        <p>If you have a 30-minute appointment starting at 9:00 am with a 15 minute buffer time your next available time slot will appear at 9:45 am (appointment + buffer time). </p>
    </>
)

export const LearnMoreCancellationPolicy = (
    <>
        <p>When your client confirms an appointment or subscription with a credit card and then cancels after this deadline, they will automatically be charged your late cancellation fee. Your clients are shown your cancellation policy when confirming appoinments with a credit card via the app or the online booking widget or if you send them transactions and required a credit card. </p>
    </>
)

export const LearnMoreYourNumberBody = (
    <>
        <p>This number is unique to you and any messages that come from the app will be sent from this number.</p>
        <br />
        <p>All texts sent to this number will show up in your message feed.</p>
        <br />
        <p>If a client calls this number, those calls will be forwarded to your login number, unless a forwarding number is specified above.</p>
        <br />
        <p>You will get a push notification letting you know it's a business call.</p>
        <br />
        <p>To call clients outbound with this number, tap on a client's number on their profile, and tap "Use premium number".</p>
        <br />
        <p>We will call you first then ring your client in and the caller ID used will be your PocketSuite business line</p>
    </>
)

export const LearnMoreTeamNumberBody = (
    <>
        <p>This number is unique to your team member and any messages they send from the app will be sent from this number.</p>
        <br />
        <p>All texts sent to this number will show up in their message feed.</p>
        <br />
        <p>If a client calls this number, those calls will be forwarded to their login number, unless a forwarding number is specified above.</p>
        <br />
        <p>They will get a push notification letting them know it's a business call.</p>
        <br />
        <p>To call clients outbound with this number, they can tap on a client's number on their profile, and tap "Use premium number".</p>
        <br />
        <p>We will call them first then ring in the client and the caller ID used will be their PocketSuite business line</p>
    </>
)

export const LearnMoreSalesTax = (
    <>
        <p>Our system will automatically calculate your sales tax based on local regulations. So if you’re in a state where services are not taxed then our system does not add tax by default. If you’d like to override that and always collect sales tax toggle “Always collect sales tax” on. </p>
    </>
)

export const LearnMoreCancellingClient = (
    <>
        <p>Note that all fields above will only be seen by you and not the client. This information is for your records and to better understand your client history.</p>
        <br />
        <p>To provide courtsey cancellation, uncheck “Enforce cancellation policy”. We recommend providing additional reasoning (ex: covid, family emergency, etc.) around this decision for future reference.</p>
    </>
)

export const LearnMoreRefunds = (
    <>
        <p>When refunding a client you can either refund the full amount or add a partial refund amount. You will be responsible for the exact refund amount you request as PocketSuite will not refund any related processing fees. Please communicate with your client about the amount to be refunded to avoid any disputes. There are two options when selecting a full refund:</p>
        <br />
        <p>- A full refund including processing fees will refund the total amount of the payment to your client.</p>
        <br />
        <p>- A full refund without processing fees will refund the client payment amount minus processing fees.</p>
        <br />
        <p>Funds to complete the refund will be withdrawn from your PocketSuite balance, incoming payments and/or bank account.</p>
        <br />
        <p>Refunds will typically be reflected in the cardholder's statement within 5 busienss days. However, it could take up to 10 business days for the cardholder to see it.</p>
    </>
)