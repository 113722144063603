import React, {useEffect, useState} from "react";
import {useAppDispatch, usePSOwner} from "../../../hooks";
import {
    saveUser,
    SettingsState
} from "../../../modules/settings";
import {RootState} from "../../../store";
import {useSelector} from "react-redux";
import {Field, Hint, Checkbox, Label} from "@zendeskgarden/react-forms";
import {Prompt} from "react-router";
import {PSButtonPrimary} from "../../app/PSButton";
import {Dots} from "@zendeskgarden/react-loaders";
import localforage from 'localforage'
import {PSDropdown} from "../../app/PSDropdown";
import {Item} from "@zendeskgarden/react-dropdowns";
import {
    SelectOption,
    bookingSendViaOptionsNoDont,
    invoiceReminderOptions,
    bookingReminderOptions,
    silenceTransactionOptions
} from "../Options"

export const NotificationsEdit = () => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const [saveEnabled, setSaveEnabled] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)

    const [user, setUser] = useState<any>({})
    const owner = usePSOwner()

    const [channel, setChannel] = useState<SelectOption>({value: '', label: ''})
    const [invoiceReminder, setInvoiceReminder] = useState<SelectOption>({value: '', label: ''})
    const [reminder, setReminder] = useState<SelectOption>({value: '', label: ''})
    const [silenceTransactions, setSilenceTransactions] = useState<SelectOption>({value: '', label: ''})

    useEffect(() => {
        if (!user) return

        const selectedChannel = bookingSendViaOptionsNoDont?.find(option => option.value === user.channel)
        setChannel((channel) => selectedChannel || channel)

        const selectedInvoiceReminder = invoiceReminderOptions?.find(option => option.value === user.invoice_reminder)
        setInvoiceReminder((invoiceReminder) => selectedInvoiceReminder || invoiceReminder)

        const selectedReminder = bookingReminderOptions?.find(option => option.value === user.reminder)
        setReminder((reminder) => selectedReminder || reminder)

        const selectedSilenceTransactions = silenceTransactionOptions?.find(option => option.value === user.silence_transactions)
        setSilenceTransactions((silenceTransactions) => selectedSilenceTransactions || silenceTransactions)
    }, [user])

    useEffect(() => {
        let edits = false
        if (!user) return

        if (channel.value !== user.channel)
            edits = true
        else if (invoiceReminder.value !== user.invoice_reminder)
            edits = true
        else if (reminder.value !== user.reminder)
            edits = true
        else if (silenceTransactions.value !== user.silence_transactions)
            edits = true

        setHasEdits(edits)
    }, [user, channel, invoiceReminder, reminder, silenceTransactions])

    useEffect(() => {
        setSaveEnabled(hasEdits)
    }, [hasEdits])

    useEffect(() => {
        localforage.getItem('user').then((user: any) => setUser(user))
    }, [])

    const onSave = () => {
        if (state.isSending) return

        const userParams: any = {}
        userParams.id = user.id
        userParams.channel = channel.value
        userParams.invoice_reminder = invoiceReminder.value
        userParams.reminder = reminder.value
        userParams.silence_transactions = silenceTransactions.value

        // @ts-ignore
        dispatch(saveUser(userParams)).then(() => localforage.getItem('user').then((user: any) => setUser(user)))
    }

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Notifications</h1>
                <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!saveEnabled || state.isSending}>
                    {state.isSending && <Dots />}
                    {!state.isSending && "Save"}
                </PSButtonPrimary>
            </div>
            <div className="form">
            <Field className="field">
                    <PSDropdown selected={channel}
                                nameProperty="label"
                                hint="Choose how to notify clients about appointments or invoices"
                                disabled={state.isSending}
                                onSelect={(selection) => setChannel(selection)}
                                label="Send via">
                            <>
                                {bookingSendViaOptionsNoDont && bookingSendViaOptionsNoDont!.map((option: SelectOption) => (
                                        <Item key={`channel-${option.value}`} value={option}>
                                            {option.label}
                                        </Item>
                                ))}
                            </>
                        </PSDropdown>
                </Field>
                {(!!owner?.allow_reminder &&
                    <Field className="field">
                        <PSDropdown selected={reminder}
                                    nameProperty="label"
                                    hint="Choose when you’d like your client to receive a reminder ahead of their appointment"
                                    disabled={state.isSending}
                                    onSelect={(selection) => setReminder(selection)}
                                    label="Booking reminders">
                            <>
                                {bookingReminderOptions && bookingReminderOptions!.map((option: SelectOption) => (
                                    <Item key={`appointmentReminder-${option.value}`} value={option}>
                                        {option.label}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>
                    </Field>
                )}
                <Field className="field">
                    <PSDropdown selected={invoiceReminder}
                                nameProperty="label"
                                hint="Choose how often your client will be reminded to pay outstanding balances"
                                disabled={state.isSending}
                                onSelect={(selection) => setInvoiceReminder(selection)}
                                label="Payment reminders">
                            <>
                                {invoiceReminderOptions && invoiceReminderOptions!.map((option: SelectOption) => (
                                        <Item key={`invoiceReminder-${option.value}`} value={option}>
                                            {option.label}
                                        </Item>
                                ))}
                            </>
                        </PSDropdown>
                </Field>
                <Field className="field">
                    <PSDropdown selected={silenceTransactions}
                                nameProperty="label"
                                hint="Reduce the noise level in your account by silencing all transaction notifications"
                                disabled={state.isSending}
                                onSelect={(selection) => setSilenceTransactions(selection)}
                                label="Silence your transactions">
                            <>
                                {silenceTransactionOptions && silenceTransactionOptions!.map((option: SelectOption) => (
                                        <Item key={`silenceTransaction-${option.value}`} value={option}>
                                            {option.label}
                                        </Item>
                                ))}
                            </>
                        </PSDropdown>
                </Field>
            </div>
            <Prompt
                    when={hasEdits && !state.isSending}
                    message="Are you sure you'd like to leave this page without saving your changes?" />
        </div>
    )
}
