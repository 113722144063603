import React, {useState} from "react";
import {useAppDispatch} from "../../hooks";
import {useLocation} from "react-router";
import {AppHeader} from "../app/AppHeader";
import {LocationEdit} from "../settings/location/LocationEdit";
import {setOnboardingState} from "../../modules/onboarding";
import {Spinner} from "@zendeskgarden/react-loaders";

export const OnboardingLocationEdit = () => {
    const dispatch = useAppDispatch()

    const routerLocation = useLocation()
    const locationState: any = routerLocation.state
    const vendorLocation: boolean = locationState?.vendorLocation ?? false

    const [isSending, setIsSending] = useState(false)

    const onComplete = () => {
        setIsSending(true)
        // @ts-ignore
        dispatch(setOnboardingState('ob_location')).then(() => setIsSending(false))
    }

    return (
        <AppHeader title="Setup" showBackButton middleWidget={null}>
            {isSending && (
                <div className="loadingOverlay">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                        <h1>Saving...</h1>
                    </div>
                </div>
            )}
            <div className="pageWrapper">
                <LocationEdit forOnboarding
                              forceVendorLocation={vendorLocation}
                              onComplete={onComplete} />
            </div>
        </AppHeader>
    )
}
