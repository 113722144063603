import React, {useEffect, useState} from "react";
import {AppHeader} from "../../app/AppHeader";
import {PSButton, PSButtonPrimary} from "../../app/PSButton";
import {
    useAppDispatch,
    usePSOwner,
    usePSTotalDisplay,
    usePSUser,
    useTaxCodeLookup
} from "../../../hooks";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {useLocation, useParams} from "react-router";
import {PSFormFieldSelection} from "../../app/PSFormFieldSelection";
import {ReactComponent as ClientIcon} from "../../../icons/ps_general_person_nobg.svg";
import {UserInstance} from "../../../models/User";
import {SelectModal} from "../SelectModal";
import {TotalInstance, TotalModal} from "../TotalModal";
import {ReactComponent as TotalIcon} from "../../../icons/ps_general_invoice_nobg.svg";
import {fetchAttachments, SettingsState} from "../../../modules/settings";
import {PSDropdown} from "../../app/PSDropdown";
import {Item} from "@zendeskgarden/react-dropdowns";
import {Field, Label} from "@zendeskgarden/react-forms";
import {PSTextarea} from "../../app/PSTextarea";
import {
    bookingSendViaOptions, invoiceCategoryOptions, invoiceReminderOptions
} from "../../settings/Options";
import moment from "moment";
import {push} from "connected-react-router";
import {Invoice} from "../../../models/Invoice";
import {ChargeLineItemProps} from "../charge/ChargeLineItemNew";
import {LineItem} from "../../../models/LineItem";
import {currencyFormat} from "../../../utils/numbers";
import {apiClient} from "../../../modules/apiclient";
import keyBy from "lodash.keyby";
import {TransactionProgress} from "../TransactionProgress";
import {Booking} from "../../../models/Booking";
import {Dots, Spinner} from "@zendeskgarden/react-loaders";
import {processError} from "../../../modules/error";
import {
    Header,
    Modal,
    Body,
    Footer,
    FooterItem,
    Close
} from "@zendeskgarden/react-modals";
import {b64EncodeUnicode} from "../../../utils/strings";
import {getRecord} from "../../../modules/records";
import {ErrorModal} from "../../app/ErrorModal";

export const InvoiceEdit = () => {
    const dispatch = useAppDispatch()
    const state: {settings: SettingsState, contacts: any, entities: any, records: any} = useSelector((state: RootState) => ({
        settings: state.settings,
        contacts: state.contacts,
        entities: state.entities,
        records: state.records,
    }))

    const params = useParams<{recordID?: string}>()
    // Loading for an edit
    useEffect(() => {
        if (!params.recordID) return
        dispatch(getRecord('invoice', params.recordID))
    }, [dispatch, params.recordID])

    const routerLocation = useLocation()
    const locationState: any = routerLocation.state
    const invoiceData: Invoice = locationState?.invoice ?? locationState?.payment

    useEffect(() => {
        const data = params.recordID ?
            (invoiceData?.id === params.recordID ? invoiceData : state.records.data) : invoiceData

        // Start fetching data to render what exists
        const promises = []
        if (Object.keys(state.entities.items).length === 0) {
            const p1 = new Promise<void>((resolve, reject) => {
                apiClient.post('/item/sync')
                    .then(resp => resp.data)
                    .then(json => {
                        const itemMap = keyBy(json.items, 'id')
                        dispatch({type: 'entities/UPDATE', name: 'items', map: itemMap})
                        resolve()
                    })
                    .catch(reject)
            })
            promises.push(p1)
        }

        if (data?.client && !state.entities.users[data.client]) {
            const p2 = new Promise<void>((resolve, reject) => {
                apiClient.post('/client/sync', {person: data.client})
                    .then(resp => resp.data)
                    .then(json => {
                        if (json.contacts?.length > 0) {
                            const user = json.contacts[0]
                            const userMap = {
                                [user.id]: user
                            }
                            dispatch({type: 'entities/UPDATE', name: 'users', map: userMap})
                        }
                        resolve()
                    })
                    .catch(reject)
            })
            promises.push(p2)
        }

        Promise.all(promises)
    }, [dispatch, invoiceData, state.entities.items, state.entities.users, params.recordID, state.records.data])

    // Load existing data
    const [loadExistingData, setLoadExistingData] = useState(false)
    useEffect(() => {
        if ((!invoiceData && (!params.recordID || !state.records.data.id)) || loadExistingData) {
            return
        }

        setLoadExistingData(true)

        const data = params.recordID ?
            (invoiceData?.id === params.recordID ? invoiceData : state.records.data) : invoiceData

        setClient(state.entities.users[data.client])
        setTotal(data.total)

        const lines: LineItem[] = data.lines.map((i: any) => new LineItem(i))

        const itemList = lines.filter(i => i.type === 'item')
        setItems(itemList)
        const expenseList = lines.filter(i => i.type === 'expense')
        setExpenses(expenseList)
        const bookingList = lines.filter(i => i.type === 'booking')
        setAppointments(bookingList)

        setTotalData({
            deposit: data.deposit,
            discountRate: Number(data.discount),
            discountType: data.discount_type === 'percent' ? 'percent' : data.discount_type,
            surchargeEnabled: data.allow_surcharge,
            tax: data.tax,
            taxcode: data.taxcode,
            taxLocation: data.taxLocation || data.location,
            nexus: data.nexus,
            total: Number(data.total),
            giftCertificate: state.entities.gifts[data.gift],
            paymentPlan: data.min_payment_percent ? `${data.max_payment_count}_${data.min_payment_percent}` : '',
        })

        const channel = bookingSendViaOptions.find(s => s.value === data.channel)
        setSendVia(channel || bookingSendViaOptions[2])

        const reminder = invoiceReminderOptions.find(s => s.value === data.reminder.toString())
        setReminder(reminder || invoiceReminderOptions[0])

        setMemo(data.memo || '')

        const form = state.settings.forms.find(f => f.id === data.form)
        setForm(form)

        const contract = state.settings.contracts.find(c => c.id === data.contract)
        setContract(contract)

        // Clear state so when we reload it is a fresh form
        window?.history?.replaceState(null, '')

        setLoadExistingData(false)
    }, [
        invoiceData, loadExistingData, state.entities.users, state.entities.gifts,
        state.settings.forms, state.settings.contracts, state.records.data,
        params.recordID,
    ])

    // Form state
    const [client, setClient] = useState<UserInstance>(locationState?.client)
    const [memo, setMemo] = useState('')
    const [total, setTotal] = useState(0)
    const [items, setItems] = useState<LineItem[]>([])
    const [itemTotal, setItemTotal] = useState(0)
    const [expenses, setExpenses] = useState<LineItem[]>([])
    const [expenseTotal, setExpenseTotal] = useState(0)
    const [appointments, setAppointments] = useState<LineItem[]>([])
    const [appointmentTotal, setAppointmentTotal] = useState(0)
    const [sendVia, setSendVia] = useState(bookingSendViaOptions[2])
    const [reminder, setReminder] = useState(invoiceReminderOptions[0])
    const [form, setForm] = useState<any>('')
    const [contract, setContract] = useState<any>('')
    const [previewEnabled, setPreviewEnabled] = useState(false)
    const [nextEnabled, setNextEnabled] = useState(false)

    const [totalData, setTotalData] = useState<TotalInstance>()
    const totalDisplay = usePSTotalDisplay(totalData, total > 0 ? currencyFormat(total.toString()) : '')

    const [selectModalType, setSelectModalType] = useState<'clients' | 'items' | 'expenses' | 'appointments'>()
    const [showTotalModal, setShowTotalModal] = useState(false)

    // value is index of appointment to show confirmation of removal
    const [showRemoveAppointmentModal, setShowRemoveAppointmentModal] = useState(-1)

    const [showPreviewModal, setShowPreviewModal] = useState(false)
    const [isPreviewLoading, setIsPreviewLoading] = useState(false)
    const [pdfPreviewData, setPDFPreviewData] = useState('')

    const user = usePSUser()
    const owner = usePSOwner()
    const proTaxCode = useTaxCodeLookup(owner)
    useEffect(() => {
        // Don't set default totalData if this is an edit
        if (params.recordID) return

        setTotalData((currentVal) => {
            return {
                deposit: currentVal?.deposit || 0,
                discountRate: currentVal?.discountRate || 0,
                discountType: currentVal?.discountType ?? '',
                giftCertificate: currentVal?.giftCertificate,
                nexus: currentVal?.nexus || 'pro',
                surchargeEnabled: owner?.allow_surcharge ?? false,
                tax: currentVal?.tax || Number(proTaxCode?.rate) || 0,
                taxcode: currentVal?.taxcode || proTaxCode?.id || '',
                taxLocation: currentVal?.taxLocation || '',
                total: currentVal?.total || 0,
            }
        })
    }, [params.recordID, owner, proTaxCode])

    useEffect(() => {
        setNextEnabled(!!client)
        setPreviewEnabled(!!client)
    }, [client])

    // Fetch bookings for the selected client, as well as their gift cards
    const [loadingBookings, setLoadingBookings] = useState(false)
    const [bookings, setBookings] = useState<Booking[]>([])
    const [giftCertificates, setGiftCertificates] = useState<any[]>([])
    useEffect(() => {
        if (!client) return
        setAppointments([])
        setLoadingBookings(true)

        const promises = []
        const p1 = new Promise<void>((resolve, reject) => {
            apiClient.post('/invoice/sync', {type: 'client', client: client.id})
                .then(resp => resp.data)
                .then(json => {
                    if (json.gifts) setGiftCertificates(json.gifts)
                    resolve()
                })
                .catch(reject)
        })
        promises.push(p1)

        const p2 = new Promise<void>((resolve, reject) => {
            apiClient.post(`/bookings/${client.id}/billable`)
                .then(resp => resp.data)
                .then(json => {
                    setBookings(json.bookings)
                    resolve()
                })
                .catch(reject)
        })
        promises.push(p2)

        Promise.all(promises).then(() => setLoadingBookings(false))
    }, [client])

    // Fetch the user's contracts and forms
    useEffect(() => {
        dispatch(fetchAttachments())
    }, [dispatch])

    // Update totals for the itemization fields
    useEffect(() => {
        const sum = items.reduce((sum, cur) => sum + Number(cur.total), 0)
        setItemTotal(sum)
    }, [items])
    useEffect(() => {
        const sum = expenses.reduce((sum, cur) => sum + Number(cur.total), 0)
        setExpenseTotal(sum)
    }, [expenses])
    useEffect(() => {
        const sum = appointments.reduce((sum, cur) => sum + Number(cur.total), 0)
        setAppointmentTotal(sum)
    }, [appointments])
    useEffect(() => {
        const itemized = items.length > 0 || expenses.length > 0 || appointments.length > 0
        if (itemized) {
            const total = itemTotal + expenseTotal + appointmentTotal
            setTotal(total)
        }
    }, [items, expenses, appointments, itemTotal, expenseTotal, appointmentTotal])

    const buildInvoiceObject = () => {
        const invoice = new Invoice({
            owner: user?.id || '',
            vendor: user?.employer || user?.id || '',
            client: client?.id || '',
            total: totalData?.total || 0,
            lines: items.slice().concat(expenses).concat(appointments),
            due_date: moment().format('YYYY-MM-DD'),
            date: moment().format('YYYY-MM-DD'),
            status: 'sentQuote',
            channel: sendVia.value,
            reminder: reminder.value,
            memo,
            allow_surcharge: totalData?.surchargeEnabled || false,
            tax: Number(totalData?.tax || 0),
            taxcode: totalData?.taxcode || '',
            nexus: totalData?.nexus || '',
            gift: totalData?.giftCertificate?.id || '',
        })

        if (totalData?.taxLocation) invoice.location = totalData.taxLocation
        if (params.recordID) invoice.id = params.recordID

        if (totalData?.discountType && totalData?.discountRate) {
            invoice.discount_type = totalData.discountType
            invoice.discount = totalData.discountRate.toString()
        }

        if (totalData?.paymentPlan) {
            const [paymentCount, paymentPercent] = totalData.paymentPlan.split('_')
            invoice.max_payment_count = paymentCount || ''
            invoice.min_payment_percent = paymentPercent || ''
        }
        if (contract) invoice.contract = contract.id
        if (form) invoice.form = form.id
        invoice.total = invoice.calculateTotal()
        invoice.number = invoiceData?.number
        return invoice
    }

    const onItemSelected = (item: any[]) => {
        setClient(item[0])
        setSelectModalType(undefined)
    }

    const onTotalUpdated = (d: TotalInstance) => {
        if (d.nexus === 'transaction') {
            if (!d.taxLocation)
                d.nexus = 'pro'
            else {
                // Lookup taxcode for the location
                apiClient.post(`/taxcode`, {location: d.taxLocation})
                    .then(resp => resp.data)
                    .then(json => {
                        if (json) {
                            setTotalData({
                                ...d,
                                taxcode: json.id || '',
                                tax: Number(json.rate) || 0,
                            } as TotalInstance)
                        }
                    })
            }
        }

        if (d.nexus === 'pro') {
            d.taxcode = proTaxCode?.id || ''
            d.tax = Number(proTaxCode?.rate) || 0
        }
        setTotalData(d)
        setShowTotalModal(false)
    }

    const onAddItemClicked = (lineItemType: 'item' | 'expense', editIndex?: number) => {
        const invoice = buildInvoiceObject()

        let url = `/invoice/new/lineitem/${lineItemType}`
        if (typeof editIndex !== 'undefined')
            url += `/${editIndex}/edit`

        const chargeProps: ChargeLineItemProps = {
            showDescription: true,
            requireItem: true,
            pageTitle: 'Send invoice',
            returnURL: '/invoice/new',
            dataType: 'invoice',
        }

        dispatch(push(url, {data: invoice, props: chargeProps}))
    }

    const onRemoveBookingClicked = (index: number) => {
        const appointmentsCopy = appointments.slice()
        appointmentsCopy.splice(index, 1)
        setAppointments(appointmentsCopy)
        setShowRemoveAppointmentModal(-1)
    }

    const onPreviewHandler = () => {
        setIsPreviewLoading(true)
        setShowPreviewModal(true)

        const invoice = buildInvoiceObject()

        apiClient.post('/invoice/print', invoice)
            .then(resp => setPDFPreviewData(resp.data))
            .catch(error => dispatch(processError(error)))
            .finally(() => setIsPreviewLoading(false))
    }

    const onNextHandler = () => {
        const invoice = buildInvoiceObject()
        dispatch(push('/invoice/confirm', {invoice}))
    }

    return (
        <AppHeader title="Send invoice"
                   showBackButton
                   middleWidget={<TransactionProgress created secondStep="Preview "/>}
        >
            {loadExistingData && (
                <div className="loadingOverlay">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                        <h1>Loading invoice</h1>
                    </div>
                </div>
            )}
            <div className="sendInvoice transaction scheduleClient servicesSettings">
                <div className="header">
                    <h1>General</h1>
                    <PSButton style={{height: '40px', marginLeft: 'auto'}}
                              onClick={() => onPreviewHandler()}
                              disabled={!previewEnabled}
                    >
                        Preview
                    </PSButton>
                    <PSButtonPrimary style={{height: '40px', marginLeft: '16px'}}
                                     onClick={() => onNextHandler()}
                                     disabled={!nextEnabled}
                    >
                        Next
                    </PSButtonPrimary>
                </div>

                <div className="separator" />

                <div className="form">
                    <PSFormFieldSelection label="Client (required)"
                                          helperLabel="Select a client"
                                          icon={<ClientIcon />}
                                          selection={client}
                                          hideButton={!!params.recordID}
                                          onClick={() => setSelectModalType('clients')}
                    />

                    <PSFormFieldSelection label="Total"
                                          helperLabel="Add a total"
                                          linkText={totalDisplay === '' ? 'Add' : 'Edit'}
                                          icon={<TotalIcon />}
                                          selectedLabel={totalDisplay}
                                          onClick={() => setShowTotalModal(true)}
                    />

                    {owner?.allow_itemize && (
                        <>
                            <div className="itemsWrapper">
                                <h2>Items ({currencyFormat(itemTotal)})</h2>
                                <PSButtonPrimary onClick={() => onAddItemClicked('item')}>
                                    Add
                                </PSButtonPrimary>
                            </div>

                            <div className="separator" />

                            {items.map((i, index) => {
                                const item = state.entities.items[i.item]
                                let itemName = item ? item.name : 'Custom amount'
                                if (i.quantity > 1)
                                    itemName += ` x${i.quantity}`
                                return (
                                    <div key={`line-item-${i.item}-${i.type}-${i.total}`}
                                         className="serviceCard"
                                    >
                                        <div className="details">
                                            <div className="name">{itemName}</div>
                                            <div className="rate">{currencyFormat(i.total)}</div>
                                        </div>
                                        <div className="edit">
                                            <div onClick={() => onAddItemClicked('item', index)}
                                                 className="buttonLink">
                                                Edit
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                            <div className="itemsWrapper">
                                <h2>Expenses ({currencyFormat(expenseTotal)})</h2>
                                <PSButtonPrimary onClick={() => onAddItemClicked('expense')}>
                                    Add
                                </PSButtonPrimary>
                            </div>

                            <div className="separator" />

                            {expenses.map((i, index) => {
                                const categoryOption = invoiceCategoryOptions.find(o => o.value === i.category)

                                return (
                                    <div key={`line-item-expense-${i.category}-${i.total}`}
                                         className="serviceCard"
                                    >
                                        <div className="details">
                                            <div className="name">{categoryOption?.label}</div>
                                            <div className="blue rate">{currencyFormat(i.total)}</div>
                                        </div>
                                        <div className="edit">
                                            <div onClick={() => onAddItemClicked('expense', index)}
                                                 className="buttonLink">
                                                Edit
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                            <div className="itemsWrapper">
                                <h2>Appointments ({currencyFormat(appointmentTotal)})</h2>
                                <PSButtonPrimary onClick={() => setSelectModalType('appointments')}
                                                 disabled={!client || bookings.length === 0}
                                >
                                    {loadingBookings ? <Dots /> : 'Add'}
                                </PSButtonPrimary>
                            </div>

                            <div className="separator" />

                            {appointments.map((i, index) => {
                                if (!i.booking) return null

                                const item = i.bookingItemID ? state.entities.items[i.bookingItemID!] : undefined

                                return (
                                    <div key={`line-item-booking-${i.booking}`}
                                         className="serviceCard"
                                    >
                                        <div className="details">
                                            <div className="name">{moment(i.date).format('ddd, MMMM Do')}</div>
                                            <div className="rate">{item?.name ?? 'Booking'}</div>
                                        </div>
                                        <div className="edit" style={{display: 'flex'}}>
                                            <div style={{marginRight: '16px', color: '#000000'}}>
                                                {currencyFormat(i.total)}
                                            </div>
                                            <div onClick={() => setShowRemoveAppointmentModal(index)}
                                                 className="buttonLink red bold">
                                                Remove
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    )}

                    <h2>Notifications</h2>

                    <PSDropdown selected={sendVia}
                                nameProperty="label"
                                hint="Choose how you'd like your client to be sent this invoice. Clients can pay this invoice online - from their smartphone or computer."
                                label="Send via"
                                onSelect={(selection) => setSendVia(selection)}
                    >
                        <>
                            {bookingSendViaOptions.map(option => (
                                <Item key={`invoice-send-via-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>

                    <PSDropdown selected={reminder}
                                nameProperty="label"
                                hint="If an invoice remains unpaid, chose how often you'd like your client to be politely reminded until payment is received."
                                label="Payment reminder"
                                onSelect={(selection) => setReminder(selection)}
                    >
                        <>
                            {invoiceReminderOptions.map(option => (
                                <Item key={`invoice-reminder-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>

                    <h2>More options</h2>

                    <Field className="field">
                        <Label className="label">Memo</Label>
                        <PSTextarea placeholder="Add a memo"
                                    className="input"
                                    minRows={5}
                                    maxLength={5000}
                                    value={memo}
                                    onChange={value => setMemo(value)}
                        />
                    </Field>

                    {owner?.allow_record && !state.settings.loadingAttachments && state.settings.forms.length > 0 && (
                        <PSDropdown selected={form}
                                    nameProperty="name"
                                    onSelect={(selection) => setForm(selection)}
                                    label="Attach a form"
                                    placeholder={<div className="grey">Select form</div>}
                        >
                            <>
                                {state.settings.forms.map(option => (
                                    <Item key={`form-${option.id}`} value={option}>
                                        {option.name}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>
                    )}

                    {owner?.allow_contract && !state.settings.loadingAttachments && state.settings.contracts.length > 0 && (
                        <PSDropdown selected={contract}
                                    nameProperty="name"
                                    onSelect={(selection) => setContract(selection)}
                                    label="Attach a contract"
                                    placeholder={<div className="grey">Select contract</div>}
                        >
                            <>
                                {state.settings.contracts.map(option => (
                                    <Item key={`contract-${option.id}`} value={option}>
                                        {option.name}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>
                    )}
                </div>

                {selectModalType === 'appointments' && (
                    <SelectModal type={selectModalType}
                                 multiSelect
                                 data={bookings}
                                 currentSelections={appointments}
                                 onSelect={(selectedBookings) => {
                                     const list: LineItem[] = []
                                     selectedBookings.forEach((b: any) => {
                                         const lineItem = new LineItem({})
                                         lineItem.date = moment(b.date).format('YYYY-MM-DD')
                                         lineItem.type = 'booking'
                                         lineItem.booking = b.id
                                         lineItem.total = Number(b.total)
                                         lineItem.rate = Number(b.total)
                                         lineItem.quantity = 1
                                         lineItem.taxable = !!b.taxcode
                                         lineItem.discountable = true
                                         lineItem.bookingItemID = b.item
                                         list.push(lineItem)
                                     })
                                     setAppointments(list)
                                     setSelectModalType(undefined)
                                 }}
                                 onCancel={() => setSelectModalType(undefined) }
                    />
                )}

                {selectModalType === 'clients' && (
                    <SelectModal type={selectModalType}
                                 multiSelect={false}
                                 currentSelections={client ? [client] : []}
                                 onSelect={onItemSelected}
                                 onCancel={() => setSelectModalType(undefined) }
                    />
                )}

                {showTotalModal && (
                    <TotalModal currentData={totalData}
                                taxCode={proTaxCode}
                                showPaymentPlan
                                paymentPlanReadOnly={!!params.recordID && Number(state.records.data?.paid_amount || 0) > 0}
                                showDeposit={false}
                                showGiftCertificates
                                giftCertificates={giftCertificates}
                                totalReadOnly={items.length > 0 || expenses.length > 0 || appointments.length > 0}
                                requireTaxLocation={true}
                                onDone={onTotalUpdated}
                                onCancel={() => setShowTotalModal(false)}
                    />
                )}

                {showPreviewModal && (
                    <Modal onClose={() => setShowPreviewModal(false)}>
                        <Header>Preview</Header>
                        <Body>
                            {isPreviewLoading && (
                                <div style={{width: '100%', height: '420px'}}>
                                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                                        <Spinner size="128" color="#314A68" />
                                    </div>
                                </div>
                            )}
                            {!isPreviewLoading && pdfPreviewData && (
                                <iframe style={{width: '100%', height: '420px'}}
                                        src={`data:application/pdf;base64,${b64EncodeUnicode(pdfPreviewData)}`}
                                        title={'Preview'}>
                                </iframe>
                            )}
                        </Body>
                        <Footer style={{paddingBottom: 32}}>
                            <FooterItem>
                            </FooterItem>
                        </Footer>
                        <Close aria-label="Close modal" />
                    </Modal>
                )}

                {showRemoveAppointmentModal > -1 && (
                    <ErrorModal title="Are you sure?"
                                message="Are you sure that you'd like to remove this appointment from this invoice?"
                                buttonText="Remove"
                                onClose={() => setShowRemoveAppointmentModal(-1)}
                                onButtonClick={() => onRemoveBookingClicked(showRemoveAppointmentModal)}
                    />
                )}
            </div>
        </AppHeader>
    )
}