import React, { useCallback } from 'react'
import moment from 'moment'
import { distinctButtons } from '../../../utils/records'
import { ButtonProps, RecordButtons } from '../RecordButtons'
import { useAppDispatch, useConfirmModal, usePSUser } from '../../../hooks'
import { cancelEvent } from '../../../modules/transactions'
import { push, replace } from 'connected-react-router'
import { PERM_ROLE_ADMIN, PERM_ROLE_OWNER, Result, userPermission } from '../../../utils'
import { Invoice } from '../../../models/Invoice'
import { Booking, MAX_HOURLY_DURATION } from '../../../models/Booking'

export const RecordEvent = (props: any) => {
    const user = usePSUser()
    const dispatch = useAppDispatch()
    const { record } = props

    const [setCtaModalInfo, setCtaModalLoading, showCtaModal, closeCtaModal, ctaComponent] = useConfirmModal()

    const importAppointment = useCallback(() => {
        const data = {
            event: record,
            vendor: record.owner,
            date: record.date,
            memo: record.notes,
            duration: record.duration,
            location: record.location,
        }

        const duration = moment(record.end_date).diff(moment(record.date)) / (60 * 1000)
        if (duration && duration % 15 === 0 && duration <= MAX_HOURLY_DURATION)
            data.duration = duration

        const schedule = new Booking(data)
        dispatch(push('/schedule/new', {schedule}))
    }, [record, dispatch])

    const importAndInvoice = useCallback(() => {
        const data = {
            event: record,
            vendor: record.owner,
            memo: record.notes,
        }
        const invoice = new Invoice(data)
        dispatch(push('/invoice/new', {invoice}))
    }, [record, dispatch])

    const remove = useCallback(() => {
        if (!record.id) return

        setCtaModalInfo({
            title: "Remove this event",
            body: "Would you like to remove this event from your calendar?",
            button: "Remove this event",
            danger: true,
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(cancelEvent(record.id)))
                    .then(() => {
                        closeCtaModal()
                        dispatch(replace('/calendar'))
                    })
            }
        })
        showCtaModal()
    }, [record.id, dispatch, setCtaModalInfo, setCtaModalLoading, cancelEvent, closeCtaModal, showCtaModal])

    const getButtons = useCallback(() => {
        const buttons: ButtonProps[] = []
        const perm = user ? userPermission(user) : -1

        if (perm === PERM_ROLE_OWNER || perm === PERM_ROLE_ADMIN) {
            if (!!record.created_date && !record.imported_date) {
                buttons.push({ text: "Import appointment", class: "autoWidth", onClick: importAppointment })
                buttons.push({ text: "Import & invoice", class: "autoWidth", onClick: importAndInvoice })
            }
        }

        if (!record.cancelled_date)
            buttons.push({ text: "Remove", class: "danger", onClick: remove })

        return distinctButtons(buttons)
    }, [user, record.created_date, record.imported_date, record.cancelled_date, importAppointment, importAndInvoice, remove])

    const buttons = getButtons()

    return (
        <div className="recordView">
            <div className="topBar">
                <div className="actions">
                    <RecordButtons buttons={buttons} />
                </div>
            </div>
            <div className="recordHeader">
                <h2>{record.title}</h2>
                <div className="title">{moment(record.date).format('ddd, MMM Do YYYY')}</div>
                <div className="time">
                    {moment(record.date).format('h:mm a')} - {moment(record.end_date).format('h:mm a')}
                </div>
            </div>
            {record.location && (
                <div className="recordBody">
                    <div className="recordDetails fullWidth">
                        <h2>Location</h2>
                        <div className="subtitle">{record.location}</div>
                    </div>
                </div>
            )}
            {ctaComponent}
        </div>
    )
}