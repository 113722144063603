import { apiClient, WEB_BASE } from './apiclient'
import { push } from 'connected-react-router'
import localforage from 'localforage'
import {
    SERVER_PING_DATE,
    SHOW_TEXT_ME_DASHBOARD,
    WATCHED_ONBOARDING_VIDEO,
    EMPLOYER_CACHE_KEY
} from '../utils/constants'

const initialState = {
    isSending: false,
    isLoggingOut: false,
    loginType: '',
    e164: '',
    errorMessage: null,
    userId: localStorage.getItem('userId'),
}

export default function loginReducer(state = initialState, action) {
    switch (action.type) {
        case 'login/SEND':
            return {
                ...state,
                isSending: action.sending,
            }
        case 'login/RESPONSE':
            return {
                ...state,
                loginType: action.loginType,
                e164: action.e164,
            }
        case 'login/SUCCESS':
            return {
                ...state,
                userId: action.userId,
            }
        case 'login/RESET_DATA':
            return {
                ...state,
                loginType: '',
                e164: '',
            }
        case 'login/SEND_LOGOUT':
            return {
                ...state,
                isLoggingOut: action.loggingOut,
            }
        case 'login/ERROR':
            return {
                ...state,
                errorMessage: action.message,
            }
        default:
            return state
    }
}

function loginRequest(sending) {
    return { type: 'login/SEND', sending }
}

function loginResponse(loginType, e164) {
    return { type: 'login/RESPONSE', loginType, e164 }
}

function loginSuccess(userId) {
    return { type: 'login/SUCCESS', userId }
}

export const loginResetData = () => {
    return { type: 'login/RESET_DATA' }
}

export const loginWithPhoneNumber = (phoneNumber, code, redirectUrl) => {
    return dispatch => {
        dispatch(loginRequest(true))

        const data = {
            phone: phoneNumber,
        }
        if (code) {
            data['code'] = code
        }

        return apiClient.post('/login', data)
            .then(resp => resp.data)
            .then((json) => {
                if (code) {
                    if (!json.login) {
                        alert('Whoops! Looks like you don\'t have an account with PocketSuite yet.\n\nPlease press OK to start registering your business.')
                        window.location = `${WEB_BASE}/register`
                        return
                    }

                    dispatch(loginSuccess(json.login.id))

                    // Update the device ID now that we have it
                    localStorage.setItem('id', json.device.device)
                    apiClient.defaults.headers['X-Requested-Device'] = json.device.device

                    // The user logged in successfully, redirect to dashboard
                    localStorage.setItem('loggedIn', '1')
                    localStorage.setItem('userId', json.login.id)
                    localStorage.setItem('income', json.show_income ? '1': '0')

                    if (window.Rollbar && window.Rollbar.configure) {
                        window.Rollbar.configure({
                            payload: {
                                environment: process.env.NODE_ENV,
                                person: {
                                    id: json.login.id,
                                }
                            }
                        })
                    }

                    Promise.all([
                        localforage.setItem('loggedIn', true),
                        localforage.setItem('device', json.device),
                        localforage.setItem('user', json.login),
                        localforage.setItem('income', json.show_income),
                        localforage.setItem('industries', json.industries),
                    ]).then(_ => {
                        if (redirectUrl)
                            dispatch(push(redirectUrl))
                        else if (json.login.role === 'owner' || json.login.role === 'admin')
                            dispatch(push('/'))
                        else
                            dispatch(push('/clients'))
                    })
                    return
                }
                dispatch(loginResponse(json.login_type, json.e164))
                dispatch(push('/login/code', {redirectUrl}))
            })
            .catch((error) => {
                const data = error;
                if (data?.title && data?.message) {
                    alert(`${data.title}\n\n${data.message}`)
                }
                else if (data?.message) {
                    alert(`${data.message}`)
                }
                else {
                    if (window.Rollbar) {
                        window.Rollbar.error('A login error has occurred', {error: error.toString ? error.toString() : error})
                    }
                    console.error('A login error has occurred', error)
                    alert('An error has occurred')
                }
            })
            .finally(() => {
                dispatch(loginRequest(false))
            })
    }
}

export const isUserLoggedIn = () => {
    return localStorage.getItem('loggedIn') === '1'
}

const logoutRequest = (loggingOut) => {
    return { type: 'login/SEND_LOGOUT', loggingOut }
}

export const userLogout = () => {
    return dispatch => {
        dispatch(logoutRequest(true))

        localforage.getItem('device')
            .then(device =>
                apiClient.post('/logout', {device: device.id})
                    .finally(() => {
                        Promise.all([
                            localforage.removeItem('loggedIn'),
                            localforage.removeItem('device'),
                            localforage.removeItem('user'),
                            localforage.removeItem('income'),
                            localforage.removeItem('savedToken'),
                            localforage.removeItem('dashboard-main'),
                            localforage.removeItem(EMPLOYER_CACHE_KEY),
                            localforage.removeItem(SHOW_TEXT_ME_DASHBOARD),
                            localforage.removeItem(WATCHED_ONBOARDING_VIDEO)
                        ]).then(values => {
                            localStorage.removeItem('loggedIn')
                            localStorage.removeItem('userId')
                            localStorage.removeItem('income')
                            localStorage.removeItem(SERVER_PING_DATE)

                            dispatch(loginRequest(false))
                            window.location = '/login'
                        })
                    })
            )
            .catch(error => window.Rollbar.error('userLogout error', error))
            .finally(() => {
                dispatch(logoutRequest(false))
            })
    }
}