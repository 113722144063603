export type TaxcodeInstance = {
    id: string;
    name: string;
    rate: string;
    postal_code: string;
}

export const isShippingTaxable = (state: string) => {
    return !!state && ["AK", "CT", "DC", "GA", "HI", "IL", "IN", "IL", "KS", "KY", "MI", "MN", "MS", "NE", "NJ", "NM", "NY", "NC", "ND", "OH", "PA", "RI", "SC", "SD", "TN", "TX", "VT", "WV", "WI"].includes(state)
}

export const isProductTaxable = (state: string) =>
    !["OR", "DE", "NH", "MT", "AK"].includes(state)
    