import React, {useCallback, useEffect, useState} from "react";
import {useAppDispatch, usePSUser} from "../../hooks";
import {RootState} from "../../store";
import {useSelector} from "react-redux";
import {apiClient} from "../../modules/apiclient";
import {
    selectCategory,
    setCategories, updateCategory
} from "../../modules/onboarding";
import {Result} from "../../utils";
import {
    nextDefaultInstallStep,
    nextOnboardingStep
} from "../../utils/onboarding";
import {AppHeader} from "../app/AppHeader";
import {Spinner} from "@zendeskgarden/react-loaders";
import {PSButtonPrimary} from "../app/PSButton";
import {Edition} from "../../models/Edition";
import {OnboardingCategory} from "./OnboardingCategory";
import {Category} from "../../models/Category";
import {processError} from "../../modules/error";
import localforage from "localforage";
import {useLocation} from "react-router";
import {DefaultsEmptyState} from "../settings/DefaultsEmptyState";

type Props = {
    forOnboarding?: boolean;
}

export const OnboardingCategories = ({forOnboarding}: Props) => {
    const dispatch = useAppDispatch()
    const state: Pick<RootState, "onboarding"> = useSelector((state: RootState) => {
        return {
            onboarding: state.onboarding,
        }
    })
    const user = usePSUser()

    const routerLocation = useLocation()
    const locationState: any = routerLocation.state
    forOnboarding = locationState?.forOnboarding ?? forOnboarding ?? true

    const [showLoadingMessage, setShowLoadingMessage] = useState('')

    const [nextEnabled, setNextEnabled] = useState(forOnboarding)
    useEffect(() => {
        setNextEnabled(forOnboarding || state.onboarding.selectedCategoryIDs.length > 0)
    }, [forOnboarding, state.onboarding.selectedCategoryIDs])

    const [header, setHeader] = useState('Select categories')
    useEffect(() => {
        if (state.onboarding.selectedCategoryIDs.length === 0)
            setHeader('Select categories')
        else {
            setHeader(`Select categories (${state.onboarding.selectedCategoryIDs.length})`)
        }
    }, [state.onboarding.selectedCategoryIDs])

    useEffect(() => {
        if (!user || state.onboarding.refreshData === false) return
        if (state.onboarding.categories.length > 0) return

        setShowLoadingMessage('Loading...')
        const editionID = user.edition ? user.edition : `${user.industry}-industry-edition`
        apiClient.post('/edition/sync', {edition: editionID})
            .then(resp => resp.data)
            .then(json => {
                const edition = new Edition(json)

                if (edition.categories.length > 0) {
                    if (forOnboarding) {
                        dispatch(setCategories(edition.categories))
                    }

                    // Get the user's active categories, so if they have one from
                    // this bundle, we can show it as it was edited and select it
                    apiClient.post('/category/sync')
                        .then(resp => resp.data)
                        .then(categoryJson => {
                            // If not from onboarding, remove categories from state that are in categoryJson
                            if (!forOnboarding) {
                                const installedDefaultCategoryIDs = categoryJson.categories
                                    .filter((c: any) => !!c.edition_category)
                                    .map((c: any) => c.edition_category)
                                const newCategoriesFromEdition = edition.categories.filter((c: any) => {
                                    return installedDefaultCategoryIDs.indexOf(c.id) === -1
                                })
                                dispatch(setCategories(newCategoriesFromEdition))
                                return
                            }

                            categoryJson.categories.filter((c: any) => !!c.edition_category).forEach((c: any) => {
                                const categoryEditionID = state.onboarding.selectedCategoryIDs.find(categoryID => categoryID === c.edition_category)
                                if (!categoryEditionID) {
                                    // Select it in the UI now
                                    dispatch(selectCategory(c.edition_category, true))
                                    // Replace the edition category with the installed category to retain customizations
                                    const editionCategory = edition.categories.find((c: any) => c.id === c.edition_category)
                                    if (editionCategory) {
                                        for (const property in editionCategory) {
                                            if (property === 'image_uri' || property === 'id' || property === 'components') continue
                                            editionCategory[property] = c[property]
                                        }
                                        dispatch(updateCategory(editionCategory))
                                    }
                                }
                            })
                        })
                        .finally(() => setShowLoadingMessage(''))
                }
                else {
                    if (forOnboarding) {
                        // No categories found, go to the next step
                        Result(dispatch(nextOnboardingStep(user)))
                            .finally(() => setShowLoadingMessage(''))
                    }
                }
            })
    }, [dispatch, forOnboarding, user, state.onboarding.categories, state.onboarding.refreshData])

    const onNextHandler = useCallback(() => {
        if (!user) return

        setShowLoadingMessage('Saving...')

        const editionID = user.edition || `${user.industry}-industry-edition`

        // Send the categories with the proper IDs set
        const clonedCategories = state.onboarding.selectedCategoryIDs.map((id: string) => {
            const c = state.onboarding.categories.find(c => c.id === id)
            if (!c) return null

            const newCategory: Category = {
                ...c,
                listings: [],
                edition: editionID,
                edition_category: c.id
            }
            delete newCategory.id
            return newCategory
        }).filter(c => !!c)

        const postData = {
            categories: clonedCategories,
            edition: editionID,
            forOnboarding,
        }

        apiClient.post('onboarding/setup/categories', postData)
            .then(resp => resp.data)
            .then(json => {
                if (json.user) localforage.setItem('user', json.user)
                if (forOnboarding) {
                    Result(dispatch(nextOnboardingStep(json.user)))
                        .finally(() => setShowLoadingMessage(''))
                }
                else {
                    // Create a list of items ids from categories that we just installed
                    const l: string[] = []
                    state.onboarding.selectedCategoryIDs.forEach((id: string) => {
                        const c = state.onboarding.categories.find(c => c.id === id)
                        if (!c) return
                        l.push(...c.listings)
                    })
                    const editionItemIDs = Array.from(new Set(l)) // Ensure it is a unique list
                    Result(dispatch(nextDefaultInstallStep(editionItemIDs, editionID, "categories", "categories")))
                        .finally(() => setShowLoadingMessage(''))
                }
            })
            .catch(error => {
                dispatch(processError(error))
                setShowLoadingMessage('')
                return Promise.reject()
            })
    }, [dispatch, user, forOnboarding, state.onboarding.selectedCategoryIDs, state.onboarding.categories])

    const body = (
        <>
            {showLoadingMessage && (
                <div className="loadingOverlay">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                        <h1>{showLoadingMessage}</h1>
                    </div>
                </div>
            )}
            <div className="scheduleClient servicesSettings onboarding onboardingItems">
                <div className="header">
                    <h1>{header}</h1>
                    <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                     onClick={() => onNextHandler()}
                                     disabled={!nextEnabled}
                    >
                        Next
                    </PSButtonPrimary>
                </div>

                <div className="separator" />

                <div className="form">
                    <div className="text">
                        This is a set of suggested categories to organize your services and products. Select and customize them for your business.
                    </div>
                    {state.onboarding.categories.map((category: Category) => (
                        <OnboardingCategory category={category}
                                            key={`category-${category.id}`}
                                            isChecked={state.onboarding.selectedCategoryIDs.findIndex((id: string) => id === category.id) > -1}
                                            editURL={`/onboarding/category/${category.id}`}
                                            onToggleCategory={(enabled: boolean) => {
                                                dispatch(selectCategory(category.id!, enabled))
                                            }}
                        />
                    ))}
                </div>
            </div>
        </>
    )

    if (forOnboarding) {
        return (
            <AppHeader title="Setup" showBackButton middleWidget={null}>
                {body}
            </AppHeader>
        )
    }

    if (!showLoadingMessage && state.onboarding.categories.length === 0) {
        return (
            <DefaultsEmptyState title="Categories" />
        )
    }

    return body
}
