import React from 'react'
import { e164ToPhone } from '../../../utils/phone'
import { useDispatch, useSelector } from 'react-redux'
import { currencyFormat } from '../../../utils/numbers'
import { RecordDetail } from '../RecordDetail'
import { RecordBody } from '../RecordBody'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import moment from 'moment'
import { GoogleMapsAPIKey } from '../../../utils/constants'
import { RootState } from '../../../store'
import { PSButton } from '../../app/PSButton'
import { push } from 'connected-react-router'
import { usePSUser } from '../../../hooks'
import { PERM_ROLE_ADMIN, PERM_ROLE_OWNER, userPermission } from '../../../utils'

const displayRole = (role: 'employee' | 'admin' | 'contractor' | 'owner' | undefined) => {
    if (role === 'employee')
        return 'Staff'
    if (role === 'admin')
        return 'Administrator'
    if (role === 'contractor')
        return 'Limited'
    if (role === 'owner')
        return 'Owner'
    return null
}

const displaySchedule = (schedule: number) => {
    const weekDays = [ 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    const days = []
    for (let i = 0; i < 7; i++) {
        if ((schedule & (1 << i)) !== 0)
            days.push(weekDays[i])
    }
    return days.length > 0 ? days.join(', ') : null
}

const displaySkills = (skills: any[], itemLookup: any[]) => {
    if (!skills) return null

    const skillList = []
    for (let i = 0; i < skills.length; i++) {
        const item = itemLookup[skills[i]] || {}
        if (item.id)
            skillList.push(item.name)
    }
    return skillList.length > 0 ? skillList.join(', ') : null
}

const displayPayment = (payee: any) => {
    const formatByRateType = (rate: any, type: string) => {
        if (type === 'percent')
            return `${rate}%, percentage`
        if (type === 'class')
            return `${currencyFormat(rate)}, class`
        if (type === 'fixed')
            return `${currencyFormat(rate)}, fixed`
        if (type === 'hourly')
            return `${currencyFormat(rate)}, hourly`
        if (type === 'daily')
            return `${currencyFormat(rate)}, daily`
        return null
    }

    const rates = []
    if (payee.rate)
        rates.push(`${formatByRateType(payee.rate, payee.rate_type)}, appointment`)
    if (payee.invoice_rate)
        rates.push(`${formatByRateType(payee.invoice_rate, payee.invoice_rate_type)}, invoice`)
    if (payee.class_rate)
        rates.push(`${formatByRateType(payee.class_rate, payee.class_rate_type)}, class`)
    if (payee.package_rate)
        rates.push(`${formatByRateType(payee.package_rate, payee.package_rate_type)}, package`)
    if (payee.subscription_rate)
        rates.push(`${formatByRateType(payee.subscription_rate, payee.subscription_rate_type)}, subscription`)
    if (payee.reservation_rate)
        rates.push(`${formatByRateType(payee.reservation_rate, payee.reservation_rate_type)}, reservation`)
    return rates.length > 0 ? rates.join('\n') : null
}

export const RecordPayee = (props: any) => {
    const {record} = props
    const dispatch = useDispatch()
    const user = usePSUser()
    const state = useSelector((state: RootState) => (
        {
            records: state.records,
            entities: state.entities,
        }
    ))

    const apiResponse = state.records.apiResponse
    const payments = apiResponse.payments ? currencyFormat(apiResponse.payments) : '-'
    const lastDate = apiResponse.last_job ? moment(apiResponse.last_job).format('MMM Do') : null // null so we can fall through to nextDate
    const nextDate = apiResponse.next_job ? moment(apiResponse.next_job).format('MMM Do') : '-'
    const unpaidJobs = apiResponse.open_jobs ? currencyFormat(apiResponse.open_jobs) : '-'

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GoogleMapsAPIKey,
    })

    const address = [
        (record.line1 && !record.line2) ? `${record.line1},` : record.line1,
        record.line2 ? `${record.line2},` : '',
        (record.city && record.state) ? `${record.city},` : record.city,
        record.state
    ].join(' ')

    const payee = state.entities.payees[record.id] || {}
    const payeeSkills = displaySkills(payee.skills, state.entities.items)
    const payeePayment = displayPayment(payee)

    const recordDetails: JSX.Element[] = []

    if (record.e164) {
        recordDetails.push(
            <RecordDetail header="Phone"
                          details={e164ToPhone(record.e164)}
            />
        )
    }

    if (record.sms) {
        recordDetails.push(
            <RecordDetail header="Premium #"
                          details={e164ToPhone(record.sms)}
            />
        )
    }

    if (record.email) {
        recordDetails.push(
            <RecordDetail header="Email"
                          details={<a href={`mailto:${record.email}`}>{record.email}</a>}
            />
        )
    }

    if (address.trim().length !== 0) {
        const latLng = {
            lat: parseFloat(record.latitude),
            lng: parseFloat(record.longitude)
        }
        recordDetails.push(
            // @ts-ignore
            <RecordDetail header="Address" details={address}>
                {isLoaded && (
                    <div style={{marginTop: '12px'}}>
                        <GoogleMap
                            center={latLng}
                            zoom={15}
                            clickableIcons={false}
                            mapContainerStyle={{width: '100%', height: '320px'}}
                            options={{disableDefaultUI: true}}
                        >
                            <Marker position={latLng} />
                        </GoogleMap>
                    </div>
                )}
            </RecordDetail>
        )
    }

    if (record.role) {
        recordDetails.push(
            <RecordDetail header="Role"
                          details={displayRole(record.role)}
            />
        )
    }

    if (record.schedule > 0) {
        recordDetails.push(
            <RecordDetail header="Calendar"
                          details={displaySchedule(record.schedule)}
            />
        )
    }

    if (payeePayment) {
        recordDetails.push(
            <RecordDetail header="Payment"
                          details={payeePayment}
            />
        )
    }

    if (payeeSkills) {
        recordDetails.push(
            <RecordDetail header="Services"
                          details={payeeSkills}
            />
        )
    }

    return (
        <div className="recordView">
            <div className="recordHeaderButtons">
                {user && record.type === 'payee' && userPermission(user) >= PERM_ROLE_ADMIN && <>
                    <PSButton style={{height: '40px', marginLeft: 'auto'}}
                                    onClick={() => dispatch(push(`/dashboard/user/${record.id}`))}>
                        History
                    </PSButton>
                    <PSButton style={{height: '40px', marginLeft: '10px'}}
                                    onClick={() => dispatch(push(`/messages/${record.id}`))}>
                        Chat
                    </PSButton>
                    <PSButton style={{height: '40px', marginLeft: '10px'}}
                                    onClick={() => dispatch(push(`/notes/payee/${record.id}`))}>
                        Notes
                    </PSButton>
                </>}

            </div>
            <div className="recordHeader">
                <div className="detailsWrapper">
                    <div className="details">
                        <div className="box">
                            <div className="name">Payments</div>
                            <div className="value">{payments}</div>
                        </div>
                        <div className="box">
                            <div className="name">{lastDate ? 'Last' : 'Next'} job</div>
                            <div className="value">{lastDate || nextDate}</div>
                        </div>
                        <div className="box">
                            <div className="name">Unpaid jobs</div>
                            <div className="value">{unpaidJobs}</div>
                        </div>
                    </div>
                </div>
                <h2>{record.name}</h2>
                <div className="title">{record.entity}</div>
            </div>
            <RecordBody recordDetails={recordDetails} />
        </div>
    )
}
