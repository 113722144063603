import moment from "moment";
import { Transaction } from "./Transaction";

export class Estimate extends Transaction {
    id?: string;
    owner: string = '';
    vendor: string = '';
    client: string = '';
    min_payment_percent: string = '';
    max_payment_count: string = '';
    form?: string;
    created_date?: string;
    last_modified_date?: string;
    paid_date?: string
    scheduled_date?: string;
    confirmed_date?: string;
    cancelled_date?: string;
    invoiced_date?: string;
    paid_deposit_date?: string;
    viewed_date?: string;
    mark_paid_date?: string;
    completed_date?: string;
    captured_date?: string;
    record?: string;
    contract?: string;
    sent_date?: string;
    location: string = '';
    tax_code?: string;
    deposit?: number;
    number?: string;
    confirm_status?: string;
    source_display?: string;

    constructor(data: Partial<Estimate>) {
        super(data)
        Object.assign(this, data)
    }

    askingAmount: () => Number = () =>
        Number(this.amount) + this.calculateTax()
}

export const displayStatus = (record: Estimate): string => {
    const dateFormat = (date: string) => moment(date).format('MMM Do, yyyy')

    if (record.mark_paid_date && record.paymeth)
        return `Marked paid ${dateFormat(record.mark_paid_date)} - ${record.paymeth}`
    if (record.mark_paid_date)
        return `Marked paid ${dateFormat(record.mark_paid_date)}`
    if (record.paid_date)
        return `Paid ${dateFormat(record.paid_date)}`
    if (record.invoiced_date)
        return `Invoiced ${dateFormat(record.invoiced_date)}`
    if (record.cancelled_date)
        return `Canceled ${dateFormat(record.cancelled_date)}`
    if (record.scheduled_date)
        return `Scheduled ${dateFormat(record.scheduled_date)}`
    if (record.confirmed_date && record.paid_deposit_date)
        return `Approved ${dateFormat(record.confirmed_date)} with deposit`
    if (record.confirmed_date)
        return `Approved ${dateFormat(record.confirmed_date)}`
    if (record.status === 'confirmed')
        return `Approved`
    if (record.status === 'sentQuote' && record.confirm_status === 'confirmPayment')
        return `Requires credit card`
    return 'Requires approval'
}
