import React from "react";
import {ReactComponent as GeneralCalendarIcon} from "../../icons/ps_general_calendar.svg";
import {Item} from "../../models/Item";
import {currencyFormat} from "../../utils/numbers";
import {Field, Label, Toggle} from "@zendeskgarden/react-forms";
import {useAppDispatch} from "../../hooks";
import {push} from "connected-react-router";

type Props = {
    item: Item,
    isChecked: boolean,
    forOnboarding?: boolean,
    onToggleItem: (b: boolean) => void,
}

export const OnboardingItem = ({item, isChecked, forOnboarding, onToggleItem}: Props) => {
    const dispatch = useAppDispatch()

    let itemImage = <GeneralCalendarIcon />
    // TODO Set generic icons based on item.type
    if (item.image_uri)
        itemImage = <img alt={item.name} src={item.image_uri} />


    return (
        <div className={`serviceCard onboardingCard ${isChecked ? 'selected' : ''}`}>
            {itemImage}
            <div className="details">
                <div className="name">{item.name}</div>
                <div className="rate">{currencyFormat(item.rate)}</div>
            </div>
            <div className="edit">
                {isChecked && (
                    <div className={`buttonLink brightBlue`}
                         onClick={() => {
                             dispatch(push('/onboarding/item/edit-lite', {item, forOnboarding: !!forOnboarding}))
                         }}
                    >
                       Edit
                    </div>
                )}
            </div>
            <div className="toggle">
                <Field>
                    <Toggle checked={isChecked}
                            onChange={e => {
                                onToggleItem(e.target.checked)
                            }}>
                        <Label>&nbsp;</Label>
                    </Toggle>
                </Field>
            </div>
        </div>
    )
}