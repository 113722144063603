import { Field, Input, Label } from "@zendeskgarden/react-forms";
import React from "react";
import { filterMonetaryInput, shiftThirdDecimal } from "../../../utils/numbers";

type FieldProps = {
    fieldValue?: string,
    label: string,
    onChange: (changed: string) => void
}

export const CurrencyInput = ({fieldValue, label, onChange}: FieldProps) => {
    return (
        <Field className='field'>
            <Label className='label'>{label}</Label>
            <Input placeholder='Enter amount'
                value={fieldValue}
                onChange={e => filterMonetaryInput(shiftThirdDecimal(e.target.value), onChange)} />
        </Field>
    )
}