import React, {useEffect, useState} from "react";
import {AppHeader} from "../../app/AppHeader";
import {Redirect, useHistory, useLocation, useParams} from "react-router";
import {Payment} from "../../../models/Payment";
import {PSButtonDanger, PSButtonPrimary} from "../../app/PSButton";
import {Checkbox, Field, Input, Label} from "@zendeskgarden/react-forms";
import {filterMonetaryInput, filterNumericInput} from "../../../utils/numbers";
import {PSFormFieldSelection} from "../../app/PSFormFieldSelection";
import {SelectModal} from "../SelectModal";
import {PSTextarea} from "../../app/PSTextarea";
import {LineItem} from "../../../models/LineItem";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import moment from "moment";
import {usePSOwner} from "../../../hooks";
import {Invoice} from "../../../models/Invoice";
import { Estimate } from "../../../models/Estimate";

export type ChargeLineItemProps = {
    showDescription: boolean,
    pageTitle: string,
    returnURL: string,
    dataType: 'payment' | 'invoice' | 'estimate',
    requireItem?: boolean,
}

export const ChargeLineItemNew = () => {
    const state: {entities: any} = useSelector((state: RootState) => ({
        entities: state.entities,
    }))

    const params = useParams<{index?: string}>()
    const isEdit = Number(params.index) >= 0

    const location = useLocation()
    const locationState: any = location.state
    const props: ChargeLineItemProps = locationState?.props
    const paymentData: Payment | Invoice | Estimate = locationState?.data

    const history = useHistory()

    const [total, setTotal] = useState('')
    const [item, setItem] = useState<any>()
    const [quantity, setQuantity] = useState(1)
    const [description, setDescription] = useState('')
    const [salesTax, setSalesTax] = useState(false)
    const [allowDiscount, setAllowDiscount] = useState(false)
    const [doneEnabled, setDoneEnabled] = useState(false)

    const [selectModalType, setSelectModalType] = useState<'items' | undefined>()

    useEffect(() => {
        if (props.requireItem)
            setDoneEnabled(!!total && !!item)
        else
            setDoneEnabled(!!total)
    }, [props.requireItem, item, total])

    const owner = usePSOwner()
    // If this is not an edit, set the default discountable and taxable booleans
    useEffect(() => {
        if (params.index) return
        setAllowDiscount(owner?.allow_discount ?? false)
        setSalesTax(owner?.allow_salestax ?? false)
    }, [owner, params.index])

    const onRemoveHandler = () => {
        paymentData.lines.splice(Number(params.index), 1)
        paymentData.total = paymentData.lines.reduce((sum, cur) => sum + cur.total, 0)
        history.replace(props.returnURL, {payment: paymentData})
    }

    const onDoneHandler = () => {
        const lineItem = new LineItem({})
        lineItem.total = Number(total)
        lineItem.item = item?.id || ''
        lineItem.quantity = Number(quantity)
        lineItem.rate = Number(total) / Number(quantity)
        lineItem.memo = description
        lineItem.taxable = salesTax
        lineItem.discountable = allowDiscount
        lineItem.type = item?.id ? 'item' : 'custom'
        lineItem.date = moment().format('YYYY-MM-DD')
        lineItem.itemType = item?.type || 'service'

        if (isEdit)
            paymentData.lines[Number(params.index)] = lineItem
        else
            paymentData.lines.push(lineItem)

        paymentData.total = paymentData.lines.reduce((sum, cur) => sum + cur.total, 0)
        history.replace(props.returnURL, {payment: paymentData})
    }

    useEffect(() => {
        if (isEdit) {
            // Set the form fields to what we are editing
            const lineItem = paymentData?.lines[Number(params.index)]
            if (lineItem) {
                if (state.entities.items[lineItem.item])
                    setItem(state.entities.items[lineItem.item])
                setTotal(lineItem.total.toString())
                setQuantity(lineItem.quantity)
                setDescription(lineItem?.memo || '')
                setSalesTax(lineItem.taxable)
                setAllowDiscount(lineItem.discountable)
            }
        }
    }, [isEdit, params.index, paymentData, state.entities.items])

    useEffect(() => {
        if (!item) return
        setTotal((Number(item.rate) * quantity).toString())
    }, [quantity, item]) // ignore total, causes an infinite loop

    if (!props || !paymentData || !state.entities.items) {
        return <Redirect to={props?.returnURL ?? '/charge/new'} />
    }

    return (
        <AppHeader title={props.pageTitle}
                   showBackButton
                   onBack={() => history.replace(props.returnURL, {payment: paymentData})}
                   middleWidget={null}
        >
            <div className="chargeClient scheduleClient servicesSettings">
                <div className="header">
                    <h1>Item</h1>
                    {isEdit && (
                        <PSButtonDanger style={{height: '40px', fontWeight: 700, marginLeft: 'auto', marginRight: '12px'}}
                            onClick={() => onRemoveHandler()}
                        >
                            Remove
                        </PSButtonDanger>
                    )}
                    <PSButtonPrimary style={{height: '40px', marginLeft: isEdit ? 0 : 'auto'}}
                                     onClick={() => onDoneHandler()}
                                     disabled={!doneEnabled}
                    >
                        Done
                    </PSButtonPrimary>
                </div>

                <div className="separator" />

                <div className="form">
                    <Field className="field">
                        <Label className="label">Total (required)</Label>
                        <Input placeholder="Add a price"
                               value={total}
                               disabled={props.dataType === 'payment' && !!item}
                               onChange={e => filterMonetaryInput(e.target.value, setTotal)} />
                    </Field>

                    <PSFormFieldSelection label={`Item${props.requireItem ? ' (required)' : ''}`}
                                          helperLabel="Select an item"
                                          selection={item}
                                          icon={undefined}
                                          onClick={() => setSelectModalType('items')}
                    />

                    <Field className="field">
                        <Label className="label">Quantity</Label>
                        <Input placeholder="Set a quantity"
                               value={quantity}
                               disabled={!item}
                               onChange={e => filterNumericInput(e.target.value, setQuantity)} />
                    </Field>

                    <Field className="field">
                        <Label className="label">Description</Label>
                        <PSTextarea placeholder="Enter a description"
                                    className="input"
                                    minRows={5}
                                    value={description}
                                    onChange={value => setDescription(value)}/>
                    </Field>

                    <Field className="field">
                        <Checkbox checked={salesTax}
                                  onChange={(e) => {
                                      setSalesTax(e.target.checked)
                                  }}
                        >
                            <Label className="withHint">Add sales tax</Label>
                        </Checkbox>
                    </Field>

                    <Field className="field">
                        <Checkbox checked={allowDiscount}
                                  onChange={(e) => {
                                      setAllowDiscount(e.target.checked)
                                  }}
                        >
                            <Label className="withHint">Allow discount</Label>
                        </Checkbox>
                    </Field>
                </div>

                {selectModalType && (
                    <SelectModal type={selectModalType}
                                 multiSelect={false}
                                 currentSelections={item ? [item] : []}
                                 onSelect={(item: any[]) => {
                                     setItem(item[0])
                                     setTotal((Number(item[0].rate) * quantity).toString())
                                     setSelectModalType(undefined)
                                 }}
                                 onCancel={() => setSelectModalType(undefined) }
                    />
                )}
            </div>
        </AppHeader>
    )
}