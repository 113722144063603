import React, { useCallback, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { RecordStatusBar } from '../RecordStatusBar'
import { Link } from 'react-router-dom'
import { RecordBody } from '../RecordBody'
import { RecordDetail } from '../RecordDetail'
import { ReactComponent as ChevronIcon } from '../../../icons/chevron.svg'
import { RootState } from '../../../store'
import { ButtonProps, RecordButtons } from '../RecordButtons'
import { distinctButtons } from '../../../utils/records'
import { push } from 'connected-react-router'
import { DangerModal } from '../../settings/DangerModal'
import { Spinner } from '@zendeskgarden/react-loaders'
import { Body, Close, Footer, FooterItem, Header, Modal } from '@zendeskgarden/react-modals'
import { PSButton, PSButtonPrimary } from '../../app/PSButton'
import { isValidPhoneNumber, Result } from '../../../utils'
import { cancelForm, sendForm } from '../../../modules/transactions'
import { API_BASE } from '../../../modules/apiclient'
import { getRecord } from '../../../modules/records'
import { DisplayCustomField } from '../DisplayCustomField'

const displayFormStatus = (record: any) => {
    const date = moment(record.created_date).format('MMM D, YYYY')
    if (record.channel)
        return `Sent on ${date}`
    return `Added on ${date}`
}

const displayRecordStatus = (record: any) => {
    const dateFormat = (date: any) => moment(date).format('MMM Do, yyyy')

    if (record.cancelled_date)
        return `Canceled ${dateFormat(record.cancelled_date)}`
    if (record.completed_date)
        return `Completed ${dateFormat(record.completed_date)}`
    return `Sent ${dateFormat(record.created_date)} - not completed`
}

export const RecordForm = (props: any) => {
    const dispatch = useDispatch()
    const {record} = props
    const state = useSelector((state: RootState) => (
        {
            entities: state.entities,
        }
    ))

    const type = state.entities.types[record.type] || {}
    const client = state.entities.contacts[record.person] || {}
    // Sort the custom field list by the seqnum in the fields
    const customFieldList = (record.field_values ? Object.keys(record.field_values) : []).sort((a, b) => {
        const aData = state.entities.fields[a]
        const bData = state.entities.fields[b]
        return aData && bData ? aData.seqnum - bData.seqnum : aData ? 1 : -1
    })

    const statuses = [
        {label: 'Sent', completed: !!record.sent_date},
        {label: 'Viewed', completed: !!record.viewed_date},
        {label: 'Completed', completed: !!record.completed_date},
    ]


    type ModalInfo = {title: string, body: string | JSX.Element, button: string, optionalButton?: string, danger?: boolean, onClick: (optionalButton: boolean) => void}
    const [ctaModalInfo, setCtaModalInfo] = useState<ModalInfo>()
    const [ctaModalLoading, setCtaModalLoading] = useState(false)
    const [showCtaModal, setShowCtaModal] = useState(false)
    const closeCtaModal = () => {
        setShowCtaModal(false)
        setCtaModalLoading(false)
    }

    const recordDetails = []

    if (client.id) {
        const details = (
            <Link to={`/record/client/${client.id}`}>
                {client.name}
                <ChevronIcon className="chevron" />
            </Link>
        )
        recordDetails.push(
            <RecordDetail header="Client"
                          details={details}
                          subtitle={displayRecordStatus(record)}
            />
        )
    }

    if (customFieldList.length > 0) {
        customFieldList.map((field, index) => {
            const fieldData = state.entities.fields[field] || {}
            if (!fieldData.type) return null

            recordDetails.push(
                <RecordDetail header={fieldData.label}
                              details={<DisplayCustomField name={field} field={fieldData} instance={record} />} />
            )

            return null
        })
    }

    const sendMessage = useCallback(() =>
        dispatch(push(`/messages/${record.person}`)), [dispatch, record.person])

    const viewContract = useCallback(() =>
        dispatch(push(`/record/signature/${record.signature}`)), [record.signature, dispatch])

    const resendForm = useCallback(() => {
        setCtaModalInfo({
            title: "Resend form",
            body: "Would you like to resend this form to the client? ",
            button: "Resend",
            onClick: () => {
                setCtaModalLoading(true)
                let channel = record.channel
                if (!channel) {
                    channel = isValidPhoneNumber(client.e164) ? "sms" : "email"
                    if (client.client in state.entities.clients) {
                        const person = state.entities.clients[client]
                        channel = person.notify_type || channel
                    }
                }
                Result(dispatch(sendForm({ id: record.id, channel })))
                    .then(closeCtaModal)
                    .then(sendMessage)
            }
        })
        setShowCtaModal(true)
    }, [client, dispatch, record.channel, record.id, sendMessage, state.entities.clients])

    const showCancelModal = useCallback(() => {
        setCtaModalInfo({
            title: "Cancel Form",
            body: 'Would you like to cancel this form?',
            button: 'Cancel & notify',
            optionalButton: 'Confirm cancellation',
            onClick: (optionalButton: boolean) => {
                setCtaModalLoading(true)
                Result(dispatch(cancelForm(record.id, optionalButton)))
                    .then(() => dispatch(getRecord('forms', record.id)))
                    .then(closeCtaModal)
                    .then(() => {
                        if (!optionalButton) sendMessage()
                    })
            },
            danger: true
        })
        setShowCtaModal(true)
    }, [dispatch, record])

    const previewForm = useCallback(() => window.open(`${API_BASE}/record/${record.id}/view`, "_blank"), [record.id])

    const getButtons = useCallback(() => {
        const buttons: ButtonProps[] = []

        if (!record.completed_date && !record.cancelled_date) {
            buttons.push({text: "Cancel", onClick: showCancelModal })
        }
        else if (record.contract) {
            buttons.push({text: "Contract", tooltip: "View contract", onClick: viewContract })
        }
        if (record.completed_date) {
            buttons.push({text: "Preview", tooltip: "View online", onClick: previewForm })
        }
        else if (!record.cancelled_date && type.is_public) {
            buttons.push({text: "Resend", onClick: resendForm })
        }
        buttons.push({ text: "Chat", onClick: sendMessage })

        return distinctButtons(buttons)
    }, [previewForm, record.cancelled_date, record.completed_date, record.contract, resendForm, sendMessage, showCancelModal, type.is_public, viewContract])

    const buttons = getButtons()
    return (
        <div className="recordView">
            {buttons &&
                <div className="recordHeaderButtons actions" style={{margin: '0', float: 'none'}}>
                    <RecordStatusBar statuses={statuses} />

                    <div style={{marginLeft: 'auto'}}>
                        <PSButtonPrimary onClick={() => dispatch(push(`/records/${record.id}/edit`))}>
                            Edit
                        </PSButtonPrimary>
                        <RecordButtons buttons={buttons} />
                    </div>
                </div>
            }
            <div className="recordHeader">
                <h2>{type.name}</h2>
                <div className="details">{type.refnum}</div>
                <div className="time">{displayFormStatus(record)}</div>
            </div>
            <RecordBody recordDetails={recordDetails} />

            {showCtaModal && ctaModalInfo &&
                (ctaModalInfo.danger ?
                    <DangerModal
                        title={ctaModalInfo.title}
                        body={ctaModalLoading ? <Spinner size="48" color="#314A68" /> : ctaModalInfo.body}
                        onClose={closeCtaModal}
                        disableDismiss
                        buttonText={!ctaModalLoading ? ctaModalInfo.button : ''}
                        extraButtonText={!ctaModalLoading ? ctaModalInfo.optionalButton: undefined}
                        onAction={(buttonIx?: number) => !ctaModalLoading ? ctaModalInfo.onClick(buttonIx === 2) : { }} /> :
                    <Modal onClose={closeCtaModal}>
                        <Header>{ctaModalInfo.title}</Header>
                        <Body>{ctaModalLoading ? <Spinner size="48" color="#314A68" /> : ctaModalInfo.body}</Body>
                        <Close aria-label="Close modal" />
                        <Footer>
                            <FooterItem>
                                {!ctaModalLoading && <>
                                    {ctaModalInfo.optionalButton && <PSButton style={{marginRight: '16px'}} onClick={() => ctaModalInfo.onClick(true)}>{ctaModalInfo.optionalButton}</PSButton>}
                                    <PSButtonPrimary onClick={() => ctaModalInfo.onClick(false)}>{ctaModalInfo.button}</PSButtonPrimary>
                                </>}
                            </FooterItem>
                        </Footer>
                    </Modal>
                )}
        </div>
    )
}
