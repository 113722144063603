import moment from "moment";
import { currencyFormat } from "../utils/numbers";
import {Transaction} from "./Transaction";

const DATE_FORMAT = 'MMM Do, yyyy'
const dateFormat = (date: string) => moment(date).format(DATE_FORMAT)

export const chargeStatus = (charge: Charge): string => {
    if (charge.refunded_date)
        return `Refunded ${currencyFormat(charge.refund_amount)} on ${dateFormat(charge.refunded_date)}`
    else if (charge.cancelled_date)
        return `Canceled on ${dateFormat(charge.cancelled_date)}`
    else if (charge.refund_reason)
        return `Refund requested`

    let source
    switch (charge.source_type) {
        case 'credit': source = 'credit card'; break;
        case 'debit': source = 'debit card'; break;
        case 'prepaid': source = 'prepaid'; break;
        case 'checking': source = 'ach'; break;
        default: source = 'apple pay'
    }

    if (charge.created_date)
        return `Mark paid ${dateFormat(charge.created_date)} - ${source}`

    return 'Unpaid'
}

export class Charge extends Transaction {
    type?: string;
    location?: string;
    is_pos: boolean = false;
    contract?: string;
    form?: string;
    pos?: boolean;
    deposited_date?: string;
    paid_deposit?: string;
    gratuity?: string;
    gratuity_type?: string;
    payment?: string;
    refunded_date?: string;
    refund_amount?: number;
    refund_reason?: string;
    refund_details?: string;
    cancelled_date?: string;
    created_date?: string;
    mark_paid_date?: string;
    paid_date?: string;
    available_on_date?: string;
    booking?: string;
    estimate?: string;
    package?: string;
    gift?: string;
    invoice?: string;
    subscription?: string;
    deposit?: string;

    constructor(data: Partial<Charge>) {
        super(data)
        Object.assign(this, data)
    }
}
