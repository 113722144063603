import 'react-app-polyfill/ie11'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import { DEFAULT_THEME, ThemeProvider } from '@zendeskgarden/react-theming'
import { css } from 'styled-components'

import '@zendeskgarden/css-bedrock'
import './index.css'

import App from './components/app/App'
import { Login } from './components/login/Login'
import { CodeEntry } from './components/login/CodeEntry'

// Prepare the API client, now that the store is ready
import { apiClientSetup } from "./modules/apiclient"
apiClientSetup(store.dispatch)

// React entrypoint here
const target = document.querySelector('#root')

const theme = {
    ...DEFAULT_THEME,
    colors: {
        ...DEFAULT_THEME.colors,
        primaryHue: '#314A68',
    },
    components: {
        'chrome.chrome': css`
          font-family: 'DM Sans', sans-serif;
        `,
        'buttons.button': css`
          border-radius: 8px;
          padding: 8px 16px;
          font-size: 13px;
          font-family: 'DM Sans', sans-serif;
          height: 32px;
        `,
        'dropdowns.item': css`
          color: #314a68;
        `,
        'dropdowns.select': css`
          height: 26px;
          padding-top: 3px;
        `,
        'forms.media_figure': css`
            margin-top: 6px;
        `,
        'forms.input': css`
          border-radius: 4px;
          &::placeholder {
            color: #999999;
          }
        `,
        'forms.input_hint': css`
            color: #999999;
        `,
        'forms.checkbox_hint': css`
            color: #999999;
        `,
        'accordions.panel': css`
            border: none;
        `,
        'accordions.section': css`
            padding-bottom: 12px;
        ` ,
        'accordions.button': css`
            font-size: 17px;
            font-weight: 700;
            line-height: 22px;
            padding: 0 0 0 24px;
            color: #000000;
        `,
        'accordions.rotate_icon': css`
            padding: 0 8px 0 8px;
        `,
    }
}

render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route path="/login/code" component={CodeEntry} />
                    <Route path="/login" component={Login} />
                    <Route path="/" component={App} />
                </Switch>
            </ConnectedRouter>
        </Provider>
    </ThemeProvider>,
    target
)