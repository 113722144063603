import React, { CSSProperties } from 'react'
import { Avatar } from '@zendeskgarden/react-avatars'
import { userColors} from '../../utils/colors'
import { UserInstance } from '../../models/User'


type Props = {
    user: UserInstance,
    size?: 'extraextrasmall' | 'extrasmall' | 'small' | 'medium' | 'large';
    style?: CSSProperties;
}
export const ContactAvatar = ({user, size, style}: Props) => {
    const ALWAYS_BLUE = '1003'
    const color = userColors(user.id || ALWAYS_BLUE)

    let avatarDoc
    if (user.image_uri) {
        avatarDoc = <img src={user.image_uri} alt={user.name} />
    }

    if (!avatarDoc && user.name === user.phone) {
        avatarDoc = <Avatar.Text style={{fontSize: '14px', fontWeight: 600}}>#</Avatar.Text>
    }

    if (!avatarDoc) {
        const firstInitial = user.name[0].toUpperCase()
        const nameParts = user.name.split(' ')
        let lastNameInitial
        if (nameParts.length > 1) {
            const lastNamePart = nameParts.pop()
            if (lastNamePart) {
                lastNameInitial = lastNamePart[0].toUpperCase()
            }
        }

        avatarDoc = <Avatar.Text style={{fontSize: '14px', fontWeight: 600}}>{firstInitial}{lastNameInitial}</Avatar.Text>
    }

    return (
        <Avatar
            backgroundColor={color.bg}
            foregroundColor={color.fg}
            size={size ? size : 'large'}
            style={style}
        >
            {avatarDoc}
        </Avatar>
    )
}

