import React, { createRef, useEffect, useState } from "react";
import {useSelector} from "react-redux";
import {useAppDispatch, usePSUser} from "../../../hooks";
import {RootState} from "../../../store";
import {AppHeader} from "../../app/AppHeader";
import {PSButton, PSButtonPrimary} from "../../app/PSButton";
import {PSFormFieldSelection} from "../../app/PSFormFieldSelection";
import {ReactComponent as ClientIcon} from "../../../icons/ps_general_person_nobg.svg";
import {SelectModal} from "../SelectModal";
import {PSDropdown} from "../../app/PSDropdown";
import {Item} from "@zendeskgarden/react-dropdowns";
import {SelectOption, bookingSendViaOptionsNoDont} from "../../../components/settings/Options"
import {fetchAttachments} from "../../../modules/settings";
import {
    Body, Close,
    Footer,
    FooterItem,
    Header,
    Modal
} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import {Dots, Spinner} from "@zendeskgarden/react-loaders";
import {b64EncodeUnicode} from "../../../utils/strings";
import {API_BASE, apiClient} from "../../../modules/apiclient";
import {CustomFieldsModal} from "../CustomFieldsModal";
import { sendForm } from "../../../modules/transactions";
import { useDashboardLocation } from "../../../models/Dashboard";
import { Result } from "../../../utils";
import { push } from "connected-react-router";

const answersToKeyVal = (answers: any) => {
    const newCustomFields: any = {}
    Object.keys(answers).forEach(key => {
        let value = answers[key]
        if (typeof value === 'object') { // TODO: refactor ContactEdit.convertCustomFieldsToDataObject
            if (Array.isArray(value))
                value = value.map((v: any) => v.value)
            else if (value.value)
                value = value.value
        }
        newCustomFields[key] = value
    })
    return newCustomFields
}

export const FormSend = () => {
    const dispatch = useAppDispatch()
    const user = usePSUser()

    const state = useSelector((state: RootState) => {
        return {
            settings: state.settings,
            transactions: state.transactions,
        }
    })

    const locationState = useDashboardLocation()
    const [selectedContract, setSelectedContract] = useState<any>()
    const [selectedForm, setSelectedForm] = useState<any>()
    const [client, setClient] = useState(locationState?.client)
    const [channel, setChannel] = useState<SelectOption>({value: '', label: ''})

    const [formPublic, setFormPublic] = useState<boolean>(true)
    const [formAnswers] = useState({})

    const [selectModalType, setSelectModalType] = useState<'clients'>()
    const [contractOptions, setContractOptions] = useState<any[]>([])
    const [formOptions, setFormOptions] = useState<any[]>([])

    const [isPreviewLoading, setIsPreviewLoading] = useState(true)
    const [pdfPreviewData, setPDFPreviewData] = useState('')
    const [showPreviewModal, setShowPreviewModal] = useState(false)
    const formPreviewRef = createRef<HTMLFormElement>()

    const [showSendModal, setShowSendModal] = useState(false)

    const [showCustomFieldsModal, setShowCustomFieldsModal] = useState(false)

    const onClientSelected = (item: any[]) => {
        setClient(item[0])
        setSelectModalType(undefined)

        if (!item[0]) return
        if (!item[0]?.phone && channel.value === 'sms') {
            const selectedChannel = bookingSendViaOptionsNoDont?.find( option => option.value === 'email' )
            setChannel((channel) => selectedChannel || channel)
        }
        else if (!item[0]?.email && channel.value === 'email') {
            const selectedChannel = bookingSendViaOptionsNoDont?.find( option => option.value === 'sms' )
            setChannel((channel) => selectedChannel || channel)
        }
    }

    useEffect( () => {
        const selectedChannel = bookingSendViaOptionsNoDont?.find( option => option.value === 'sms' )
        setChannel( c => selectedChannel || c)
    }, [])

    useEffect(() => {
        dispatch(fetchAttachments())
    }, [dispatch])

    useEffect( () => {
        setContractOptions(state.settings.contracts)
    }, [state.settings.contracts])

    useEffect( () => {
        setFormOptions(state.settings.forms)
    }, [state.settings.forms])

    const getPreview = () => {
        setShowPreviewModal(true)
        setIsPreviewLoading(true)

        apiClient.get(`/contract/${selectedContract.id}/print`)
            .then(resp => {
                setPDFPreviewData(resp.data)
                setIsPreviewLoading(false)
            })
    }

    const showPreview = () => {
        if (formPreviewRef.current)
            formPreviewRef.current.submit()
    }

    const onFormSelected = (selection: any) => {
        setSelectedForm(selection)

        if (selection?.contract) {
            setSelectedContract(contractOptions.find((contract) => {return contract.id === selection?.contract}))
        }

        setFormPublic(selection?.is_public ?? true)
    }

    const doSendForm = (dontNotify:boolean = false, customFields?:any) => {
        if (!client || !selectedForm) return;

        if (state.transactions.isSending) return

        if (!selectedForm.is_public && !customFields) {
            return
        }

        const recordParams:any = {
            dont_notify: dontNotify,
            type: selectedForm.id,
            channel: channel.value,
            person: client!.id,
            name: selectedForm.name
        }

        if (selectedContract) recordParams.contract = selectedContract.id
        if (customFields) recordParams.field_values = customFields
        if (user?.employer) recordParams.employee = user.id

        Result(dispatch(sendForm(recordParams)))
            .then(() => dispatch(push('/dashboards/records')))
    }

    const customFieldsDone = (answers:any, dontNotify:boolean) => {
        if (state.transactions.isSending) return

        doSendForm(dontNotify, answers)
    }


    if (state.settings.isSending || !user) {
        return (
            <div className="servicesSettings">
                <div className="header">
                    <h1>Send Contract</h1>
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <AppHeader middleWidget={null}
                   showBackButton
                   title="Form"
        >
            <div className="contractSend servicesSettings">
                <div className="header">
                    <h1>Send form</h1>
                    <div className="right">
                        {selectedContract && (
                            <PSButton style={{height: '40px', marginLeft: 'auto'}}
                                      onClick={() => getPreview()}
                                      disabled={!selectedContract}
                            >
                            Preview contract
                            </PSButton>
                        )}
                        <PSButtonPrimary style={{height: '40px', marginLeft: '16px'}}
                                         onClick={() => { formPublic ? setShowSendModal(true) : setShowCustomFieldsModal(true)}}
                                         disabled={!selectedForm || !client || showSendModal || state.transactions.isSending}
                        >
                            {state.transactions.isSending && (
                                <Dots/>
                            )}
                            {!state.transactions.isSending && formPublic && (
                                "Send"
                            )}
                            {!state.transactions.isSending && !formPublic && (
                                "Fill out form"
                            )}
                        </PSButtonPrimary>
                    </div>
                </div>
                <div className="separator" />
                <div className="form">
                    <PSFormFieldSelection label="Client (required)"
                                          helperLabel="Select a client"
                                          icon={<ClientIcon />}
                                          selection={client}
                                          onClick={() => setSelectModalType('clients')}
                    />
                    <PSDropdown selected={selectedForm}
                                onSelect={(selection) => {
                                    onFormSelected(selection)
                                }}
                                nameProperty={"name"}
                                label="Form (required)"
                                placeholder={<div className="grey">Select a form</div>}
                    >
                        <>
                            {formOptions.map(option => (
                                <Item key={`form-${option.id}`} value={option}>
                                    {option.name}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                    <PSDropdown selected={channel}
                                nameProperty="label"
                                hint="Specify how you want this form to be sent"
                                onSelect={(selection) => setChannel(selection)}
                                disabled={!selectedForm || !formPublic}
                                label="Send via">
                            <>
                                {bookingSendViaOptionsNoDont && bookingSendViaOptionsNoDont!.map((option: SelectOption) => (
                                        <Item key={`channel-${option.value}`} value={option}>
                                            {option.label}
                                        </Item>
                                ))}
                            </>
                    </PSDropdown>
                    <PSDropdown selected={selectedContract}
                                onSelect={(selection) => setSelectedContract(selection)}
                                disabled={!selectedForm || !formPublic}
                                hint="Attach a contract for your client to sign prior to completing the form."
                                nameProperty={"name"}
                                label="Attach a contract"
                                placeholder={<div className="grey">Select a contract</div>}
                    >
                        <>
                            {contractOptions.map(option => (
                                <Item key={`contract-${option.id}`} value={option}>
                                    {option.name}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                </div>

                {selectModalType && (
                    <SelectModal type={selectModalType}
                                 multiSelect={false}
                                 currentSelections={client ? [client] : []}
                                 onSelect={onClientSelected}
                                 onCancel={() => setSelectModalType(undefined) }
                    />
                )}
                {showPreviewModal && (
                    <Modal className="signatureModal" onClose={() => setShowPreviewModal(false)}>
                        <Header>{selectedContract.name} - Preview </Header>
                        <Body>
                            {isPreviewLoading && (
                                <div style={{width: '100%', height: '420px'}}>
                                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                                        <Spinner size="128" color="#314A68" />
                                    </div>
                                </div>
                            )}
                            {!isPreviewLoading && pdfPreviewData && (
                                <iframe style={{width: '100%', height: '420px'}}
                                        src={`data:application/pdf;base64,${b64EncodeUnicode(pdfPreviewData)}`}
                                        title={'Preview'}>
                                </iframe>
                            )}
                        </Body>
                        <Footer style={{paddingBottom: 32}}>
                            <FooterItem>
                                <form method="POST"
                                    ref={formPreviewRef}
                                    action={`${API_BASE}/contract/${selectedContract.id}/print`}
                                    target="pspreview">
                                </form>
                                <Button onClick={() => showPreview()}
                                        isPrimary>
                                    Open in browser
                                </Button>
                            </FooterItem>
                        </Footer>
                        <Close aria-label="Close modal" />
                    </Modal>
                )}
                {showSendModal && (
                    <Modal className="contractSendModal" onClose={() => setShowSendModal(false)}>
                        <Header>Form</Header>
                        <Body>
                        Select whether you’d like to send this form to the client or fill it out yourself. Sending a form to the client will notify and allow them to fill out the form. Filling the form out yourself will allow you to fill out this form on behalf of the client.
                        </Body>
                        <Footer>
                            <PSButton
                                onClick={() => {
                                    setShowCustomFieldsModal(true)
                                    setShowSendModal(false)
                                }}
                                disabled={state.transactions.isSending}
                            >
                                Fill out form
                            </PSButton>
                            {selectedForm.is_public && (
                                <PSButtonPrimary
                                    style={{marginLeft: '16px'}}
                                    onClick={() => doSendForm()}
                                    disabled={state.transactions.isSending}
                                >
                                    {state.transactions.isSending && (
                                        <Dots/>
                                    )}
                                    {!state.transactions.isSending && (
                                        "Send form"
                                    )}
                            </PSButtonPrimary>
                            )}
                        </Footer>
                        <Close aria-label="Close modal" />
                    </Modal>
                )}
                {showCustomFieldsModal && (
                    <CustomFieldsModal onCancel={() => setShowCustomFieldsModal(false)}
                                       fields={selectedForm?.fields || []}
                                       currentAnswers={formAnswers}
                                       doneNode={state.transactions.isSending ?
                                                 <Dots/> :
                                                 (formPublic ? "Send" : "Save")}
                                       onDone={(answers) => {
                                            customFieldsDone(answersToKeyVal(answers), !formPublic)
                                       }}
                                       otherNode={formPublic ?
                                                  (state.transactions.isSending ? <Dots/> : "Save") :
                                                  undefined}
                                       onOther={(answers) => {
                                           customFieldsDone(answersToKeyVal(answers), true)
                                       }}
                    />
                )}
            </div>
        </AppHeader>
    )
}