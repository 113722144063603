import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {Redirect, useHistory, useLocation, useParams} from "react-router";
import {Payment} from "../../models/Payment";
import {Invoice} from "../../models/Invoice";
import {invoiceCategoryOptions, SelectOption} from "../settings/Options";
import {usePSOwner} from "../../hooks";
import {LineItem} from "../../models/LineItem";
import moment from "moment";
import {AppHeader} from "../app/AppHeader";
import {PSButtonDanger, PSButtonPrimary} from "../app/PSButton";
import {Checkbox, Field, Input, Label} from "@zendeskgarden/react-forms";
import {filterMonetaryInput} from "../../utils/numbers";
import {PSTextarea} from "../app/PSTextarea";
import {PSDropdown} from "../app/PSDropdown";
import {Item} from "@zendeskgarden/react-dropdowns";

export type ExpenseLineItemProps = {
    showDescription: boolean,
    pageTitle: string,
    returnURL: string,
    dataType: 'payment' | 'invoice',
}

export const ExpenseLineItem = () => {
    const state: {entities: any} = useSelector((state: RootState) => ({
        entities: state.entities,
    }))

    const params = useParams<{index?: string}>()
    const isEdit = Number(params.index) >= 0

    const location = useLocation()
    const locationState: any = location.state
    const props: ExpenseLineItemProps = locationState?.props
    const paymentData: Payment | Invoice = locationState?.data

    const history = useHistory()

    const [total, setTotal] = useState('')
    const [category, setCategory] = useState<SelectOption>()
    const [memo, setMemo] = useState('')
    const [salesTax, setSalesTax] = useState(false)
    const [allowDiscount, setAllowDiscount] = useState(false)
    const [doneEnabled, setDoneEnabled] = useState(false)

    useEffect(() => {
        setDoneEnabled(!!total && !!category)
    }, [total, category])

    useEffect(() => {
        if (!category) return

        if (category.value === 'adjustments')
            setMemo('Negative adjustments')
        else
            setMemo(`${category.label} expenses`)
    }, [category])

    const owner = usePSOwner()
    // If this is not an edit, set the default discountable and taxable booleans
    useEffect(() => {
        if (isEdit) return
        setAllowDiscount(owner?.allow_discount ?? false)
        setSalesTax(owner?.allow_salestax ?? false)
    }, [owner, isEdit])

    useEffect(() => {
        if (isEdit) {
            // Set the form fields that we are editing
            const lineItem = paymentData.lines.filter(i => i.type === 'expense')[Number(params.index)]
            if (lineItem) {
                setTotal(lineItem.total.toString())
                const selectedCategory = invoiceCategoryOptions.find(o => o.value === lineItem.category)
                setCategory(selectedCategory)
                setMemo(lineItem.memo ?? '')
                setSalesTax(lineItem.taxable)
                setAllowDiscount(lineItem.discountable)
            }
        }
    }, [isEdit, params.index, paymentData])

    const onRemoveHandler = () => {
        // lines is created as items + expenses, so make sure we remove it from the proper location

        const items = paymentData.lines.filter(i => i.type !== 'expense')
        const expenses = paymentData.lines.filter(i => i.type === 'expense')

        expenses.splice(Number(params.index), 1)

        paymentData.lines = items.concat(expenses)
        paymentData.total = paymentData.lines.reduce((sum, cur) => sum + Number(cur.total), 0)

        history.replace(props.returnURL, {payment: paymentData})
    }

    const onDoneHandler = () => {
        const lineItem = new LineItem({})
        lineItem.total = Number(total)
        lineItem.quantity = 1
        lineItem.rate = Number(total)
        lineItem.memo = memo
        lineItem.taxable = salesTax
        lineItem.discountable = allowDiscount
        lineItem.type = 'expense'
        lineItem.category = category?.value
        lineItem.date = moment().format('YYYY-MM-DD')

        if (isEdit) {
            const items = paymentData.lines.filter(i => i.type !== 'expense')
            const expenses = paymentData.lines.filter(i => i.type === 'expense')
            expenses[Number(params.index)] = lineItem
            paymentData.lines = items.concat(expenses)
        }
        else
            paymentData.lines.push(lineItem)

        paymentData.total = paymentData.lines.reduce((sum, cur) => sum + Number(cur.total), 0)
        history.replace(props.returnURL, {payment: paymentData})
    }

    if (!props || !paymentData || !state.entities.items) {
        return <Redirect to={props?.returnURL ?? '/invoice/new'} />
    }

    return (
        <AppHeader title={props.pageTitle}
                   showBackButton
                   onBack={() => history.replace(props.returnURL, {payment: paymentData})}
                   middleWidget={null}
        >
            <div className="chargeClient scheduleClient servicesSettings">
                <div className="header">
                    <h1>Expense</h1>
                    {isEdit && (
                        <PSButtonDanger style={{height: '40px', fontWeight: 700, marginLeft: 'auto', marginRight: '12px'}}
                                        onClick={() => onRemoveHandler()}
                        >
                            Remove
                        </PSButtonDanger>
                    )}
                    <PSButtonPrimary style={{height: '40px', marginLeft: isEdit ? 0 : 'auto'}}
                                     onClick={() => onDoneHandler()}
                                     disabled={!doneEnabled}
                    >
                        Done
                    </PSButtonPrimary>
                </div>

                <div className="separator" />

                <div className="form">
                    <Field className="field">
                        <Label className="label">Amount</Label>
                        <Input placeholder="Add an amount"
                               value={total}
                               onChange={e => filterMonetaryInput(e.target.value, setTotal)} />
                    </Field>

                    <PSDropdown selected={category}
                                nameProperty="label"
                                label="Type"
                                placeholder={<div className="grey">Select a type</div>}
                                onSelect={(selection) => setCategory(selection)}
                    >
                        <>
                            {invoiceCategoryOptions.map(option => (
                                <Item key={`invoice-category-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>


                    <Field className="field">
                        <Label className="label">Memo</Label>
                        <PSTextarea placeholder="Add a memo"
                                    className="input"
                                    minRows={1}
                                    maxLength={99}
                                    value={memo}
                                    onChange={value => setMemo(value)}/>
                    </Field>

                    <Field className="field">
                        <Checkbox checked={salesTax}
                                  onChange={(e) => {
                                      setSalesTax(e.target.checked)
                                  }}
                        >
                            <Label className="withHint">Add sales tax</Label>
                        </Checkbox>
                    </Field>

                    <Field className="field">
                        <Checkbox checked={allowDiscount}
                                  onChange={(e) => {
                                      setAllowDiscount(e.target.checked)
                                  }}
                        >
                            <Label className="withHint">Allow discount</Label>
                        </Checkbox>
                    </Field>
                </div>
            </div>
        </AppHeader>
    )
}