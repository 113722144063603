import { apiClient, generateCancelToken } from './apiclient'
import {getUnreadCount, inboxLastMessageUpdate, setUnreadCount} from './inbox'
import axios from 'axios'
import keyBy from 'lodash.keyby'
import debounce from 'lodash.debounce'
import store from '../store'
import { push } from 'connected-react-router'
import { processError } from './error'

let searchCancelToken

const initialState = {
    isLoading: false,
    isMessageSending: false,
    isGroupMessage: false,
    currentThread: null,
    draftMessage: {
        message: '',
        templateID: ''
    },
    messages: [],
    error: null,
    inputBarHeight: 20,
    // New conversation
    showNewMessage: false,
    showCreateGroup: false,
    newConversation: {
        searchText: '', // the actual query
        isSearching: false, // search is running
        didSearch: false, // set to true when we debounce the input and send it to the server
        searchResults: [],
        selectedUsers: [],
    },
    // Pagination
    pagination: {
        justScrolled: false,
        hasMore: true,
        timestamp: '',
    },
    savedMessages: [],
}

export default function conversationReducer(state = initialState, action) {
    switch (action.type) {
        case 'conversation/REQUEST':
            return {
                ...state,
                isLoading: action.sending,
            }
        case 'conversation/RESET':
            return {
                ...state,
                currentThread: action.userId,
                messages: [],
                error: null,
                pagination: {
                    ...state.pagination,
                    justScrolled: false,
                    hasMore: true,
                    timestamp: '',
                },
                draftMessage: {
                    message: '',
                    templateID: ''
                }
            }
        case 'conversation/LOADED':
            const hadMessages = state.messages.length > 0
            let lastMessageTimestamp
            if (action.messages.length) {
                lastMessageTimestamp = action.messages[action.messages.length-1].created_date
            }
            let hasMore = state.pagination.hasMore
            if (action.messages.length === 0) {
                hasMore = false
            }
            return {
                ...state,
                error: null,
                isGroupMessage: action.isGroupMessage,
                currentThread: action.userId,
                messages: state.messages.slice().concat(action.messages),
                pagination: {
                    ...state.pagination,
                    justScrolled: hadMessages,
                    timestamp: lastMessageTimestamp,
                    hasMore: hasMore,
                }
            }
        case 'conversation/SENDING':
            return {
                ...state,
                isMessageSending: action.sending,
            }
        case 'conversation/SENT':
            return {
                ...state,
                messages: [ action.message, ...state.messages ],
                pagination: {
                    ...state.pagination,
                    justScrolled: false,
                }
            }
        case 'conversation/NEW_MESSAGE':
            if (state.currentThread !== action.userId)
                return state
            return {
                ...state,
                messages: [ action.message, ...state.messages ],
                pagination: {
                    ...state.pagination,
                    justScrolled: false,
                }
            }
        case 'conversation/ERROR':
            return {
                ...state,
                error: action.error.toString(),
            }
        case 'conversation/SET_INPUT_BAR_HEIGHT':
            return {
                ...state,
                inputBarHeight: Math.min(action.height, 160),
            }
        case 'conversation/SHOW_NEW_MESSAGE':
            return {
                ...state,
                showNewMessage: action.show,
                showCreateGroup: false,
                newConversation: {
                    ...state.newConversation,
                    selectedUsers: [],
                    searchText: '',
                }
            }
        case 'conversation/SET_SEARCHING':
            return {
                ...state,
                newConversation: {
                    ...state.newConversation,
                    searchText: action.query || state.searchText,
                    isSearching: action.searching,
                }
            }
        case 'conversation/CLEAR_SEARCH_RESULT':
            return {
                ...state,
                newConversation: {
                    ...state.newConversation,
                    isSearching: false,
                    searchText: '',
                    didSearch: false,
                    searchResults: [],
                }
            }
        case 'conversation/SET_SEARCH_RESULT':
            return {
                ...state,
                newConversation: {
                    ...state.newConversation,
                    didSearch: true,
                    searchResults: action.users,
                }
            }
        case 'conversation/REMOVE_SELECTED_USER':
            const selectedUsers = state.newConversation.selectedUsers.filter(u => u.id !== action.user.id)
            return {
                ...state,
                newConversation: {
                    ...state.newConversation,
                    selectedUsers: selectedUsers,
                }
            }
        case 'conversation/ADD_SELECTED_USER':
            return {
                ...state,
                newConversation: {
                    ...state.newConversation,
                    selectedUsers: state.newConversation.selectedUsers.slice().concat(action.user),
                }
            }
        case 'conversation/SET_SHOW_GROUP':
            return {
                ...state,
                showCreateGroup: action.show,
            }
        case 'conversation/SET_SAVED_MESSAGES':
            return {
                ...state,
                savedMessages: action.templates,
            }
            case 'conversation/DRAFT_MESSAGE':
                return {
                    ...state,
                    draftMessage: {
                        message: action.message,
                        templateID: action.templateID
                    }
                }
        default:
            return state
    }
}

const conversationRequest = (sending) => {
    return { type: 'conversation/REQUEST', sending }
}

export const conversationReset = (userId) => {
    return { type: 'conversation/RESET', userId }
}

const conversationLoaded = (userId, messages, isGroupMessage) => {
    return { type: 'conversation/LOADED', userId, messages, isGroupMessage }
}

export const conversationError = (error) => {
    return { type: 'conversation/ERROR', error }
}

export const getConversation = (userId, timestamp = '', cancelToken) => {
    return dispatch => {
        dispatch(conversationRequest(true))
        if (timestamp === '') {
            dispatch(conversationReset(userId))
        }

        const data = {
            thread: userId,
            ts: timestamp || undefined,
        }

        return apiClient.post('/thread/sync', data, {cancelToken})
            .then(resp => resp.data)
            .then((json) => {
                if (json.signatures) {
                    const signatureMap = keyBy(json.signatures, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'signatures', map: signatureMap})
                }

                if (json.gifts) {
                    const giftMap = keyBy(json.gifts, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'gifts', map: giftMap})
                }

                if (json.tasks) {
                    const taskMap = keyBy(json.tasks, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'tasks', map: taskMap})
                }

                if (json.conferencess) {
                    const conferenceMap = keyBy(json.conferences, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'conferences', map: conferenceMap})
                }

                if (json.invoices) {
                    const invoiceMap = keyBy(json.invoices, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'invoices', map: invoiceMap})
                }

                if (json.estimates) {
                    const estimateMap = keyBy(json.estimates, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'estimates', map: estimateMap})
                }

                if (json.teams) {
                    const teamMap = keyBy(json.teams, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'teams', map: teamMap})
                }

                if (json.bookings) {
                    const bookingMap = keyBy(json.bookings, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'bookings', map: bookingMap})
                }

                if (json.records) {
                    const recordMap = keyBy(json.records, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'records', map: recordMap})
                }

                if (json.classes) {
                    const classMap = keyBy(json.classes, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'classes', map: classMap})
                }

                if (json.payments) {
                    const paymentMap = keyBy(json.payments, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'payments', map: paymentMap})
                }

                if (json.packages) {
                    const packageMap = keyBy(json.packages, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'packages', map: packageMap})
                }

                if (json.types) {
                    const typeMap = keyBy(json.types, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'types', map: typeMap})
                }

                if (json.subscriptions) {
                    const subscriptionMap = keyBy(json.subscriptions, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'subscriptions', map: subscriptionMap})
                }

                if (json.signatures) {
                    const signatureMap = keyBy(json.signatures, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'signatures', map: signatureMap})
                }

                if (json.contracts) {
                    const contractMap = keyBy(json.contracts, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'contracts', map: contractMap})
                }

                if (json.tasks) {
                    const taskMap = keyBy(json.tasks, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'tasks', map: taskMap})
                }

                if (json.estimates) {
                    const estimateMap = keyBy(json.estimates, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'estimates', map: estimateMap})
                }

                if (json.gifts) {
                    const giftMap = keyBy(json.gifts, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'gifts', map: giftMap})
                }

                if (json.packages) {
                    const packageMap = keyBy(json.packages, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'packages', map: packageMap})
                }
                if (json.charges) {
                    const chargeMap = keyBy(json.charges, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'charges', map: chargeMap})
                }

                const isGroupMessage = json.messages.length > 0 && json.messages[0].type === 'team'

                dispatch(conversationLoaded(userId, json.messages, isGroupMessage))

                if (json.messages.length > 0 && json.messages[0].viewed_date == null) {
                    dispatch(conversationMarkRead(userId, json.messages[0].id, isGroupMessage))
                }
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    dispatch(conversationError(error))
                    dispatch(processError(error))
                    return Promise.reject()
                }
            })
            .finally(() => {
                dispatch(conversationRequest(false))
            })
    }
}

function sendingMessage(sending) {
    return { type: 'conversation/SENDING', sending }
}

function sentMessage(message) {
    return { type: 'conversation/SENT', message }
}

const draftMessage = (message, templateID) => {
    return { type: 'conversation/DRAFT_MESSAGE', message, templateID }
}

export const sendMessage = (recipient, message, isTeam, isBroadcast, templateID) => {
    return dispatch => {
        dispatch(sendingMessage(true))

        const data = {
            recipient,
            message,
        }

        if (templateID)
            data.template = templateID

        if (isTeam) {
            data.team = recipient
            data.recipient = undefined
        }

        return apiClient.post(isBroadcast ? '/broadcast' : '/message', data)
            .then(resp => resp.data)
            .then(json =>  {
                const msgResponse = isBroadcast ? json.message : json
                dispatch(sentMessage(msgResponse))
                // Also update the inbox list
                dispatch(inboxLastMessageUpdate(recipient, msgResponse, isTeam))
            })
            .catch(error => {
                dispatch(processError(error, 'An error has occurred. Please try again in a few moments.'))
                // set draft message to the failed message
                dispatch(draftMessage(message, templateID))
            })
            .finally(() => {
                dispatch(sendingMessage(false))
            })
    }
}

export function newMessage(userId, message, isGroupMessage = false) {
    return { type: 'conversation/NEW_MESSAGE', userId, message, isGroupMessage}
}

export const getMessage = (senderId, messageId) => {
    return (dispatch, getState) => {
        if (!messageId) {
            return
        }

        return apiClient.get(`/message/${messageId}`)
            .then(resp => resp.data)
            .then(json => {
                if (!json) {
                    window.Rollbar.error('getMessage returned null')
                    return
                }

                const isTeamMessage = json.type === 'team'
                const threadId = isTeamMessage ? json.team : senderId

                dispatch(newMessage(threadId, json, isTeamMessage))

                // If the conversation is active, mark this message as read right away
                const { conversation, router } = getState()
                if (router.location && router.location.pathname.startsWith("/messages") && conversation.currentThread === threadId) {
                    dispatch(conversationMarkRead(threadId, messageId))
                }
            })
            .catch(error => dispatch(processError(error)))
    }
}

export const conversationMarkRead = (userId, messageId, isGroupMessage = false) => {
    return dispatch => {
        return apiClient.post(`/message/${messageId}/mark`)
            .then(resp => resp.data)
            .then(json => {
                dispatch(setUnreadCount(json.badge))
                dispatch(inboxLastMessageUpdate(userId, json.messages[0], isGroupMessage))
            })
            .catch(error => dispatch(processError(error)))
    }
}

export const setMessageInputBarHeight = (height) => {
    return { type: 'conversation/SET_INPUT_BAR_HEIGHT', height }
}

// new conversation methods
export const setShowNewMessage = (show) => {
    return { type: 'conversation/SHOW_NEW_MESSAGE', show }
}

const setNewMessageSearching = (searching, query) => {
    return { type: 'conversation/SET_SEARCHING', searching, query }
}

const setNewMessageSearchResult = (users) => {
    return { type: 'conversation/SET_SEARCH_RESULT', users }
}

const performSearchDebounced = debounce((dispatch, query) => {
    dispatch(setNewMessageSearching(true))

    if (searchCancelToken) {
        searchCancelToken.cancel('stop messageSearch')
        searchCancelToken = null
    }

    searchCancelToken = generateCancelToken()

    const data = {keywords: query}
    apiClient.post('/user/search', data, {cancelToken: searchCancelToken.token})
        .then(resp => resp.data)
        .then(json => {
            const indexOfUsers = []
            for (let i = 0; i < json.types.length; i++) {
                if (json.types[i] === 'user') {
                    indexOfUsers.push(i)
                }
            }

            const users = []
            for (let i = 0; i < indexOfUsers.length; i++) {
                users.push(json.results[indexOfUsers[i]])
            }

            // Update the entities map so if they click the search result, we have
            // the user entity ready to be queried
            const userMap = keyBy(users, 'id')
            dispatch({type: 'entities/UPDATE', name: 'contacts', map: userMap})

            dispatch(setNewMessageSearchResult(users))
            dispatch(setNewMessageSearching(false))
        })
        .catch(error => {
            if (!axios.isCancel(error)) {
                dispatch(setNewMessageSearching(false))
                dispatch(processError(error))
            }
        })
}, 250, {trailing: true})

export const searchContactsForNewMessage = (query) => {
    return dispatch => {
        dispatch(setNewMessageSearching(true, query))

        performSearchDebounced(dispatch, query)
    }
}

export const toggleSelectedUser = (userId) => {
    const state = store.getState()
    const user = state.entities.contacts[userId] || {}
    if (!user.id) return { type: '' }
    // Make sure the user is not in the selectedUsers list already
    const existingUser = state.conversation.newConversation.selectedUsers.filter(u => u.id === userId).length > 0
    if (existingUser)
        return { type: 'conversation/REMOVE_SELECTED_USER', user }
    else
        return { type: 'conversation/ADD_SELECTED_USER', user }
}

export const setShowCreateGroup = (show) => {
    return { type: 'conversation/SET_SHOW_GROUP', show }
}

export const createGroup = (members, name, bcc) => {
    return dispatch => {
        const data = {
            members,
            bcc,
        }
        if (name) data.name = name
        return apiClient.post('/team', data)
            .then(resp => resp.data)
            .then(json => {
                // transition to the view message thread of this group
                if (json.id) {
                    // Add the new team to our entity holder
                    const teamMap = {}
                    teamMap[json.id] = json
                    dispatch({type: 'entities/UPDATE', name: 'teams', map: teamMap})

                    dispatch(push(`/messages/${json.id}`))
                    dispatch(conversationReset(json.id))
                    dispatch(setShowNewMessage(false))
                }
            })
            .catch(error => dispatch(processError(error)))
    }
}

export const sendContract = (client, owner, employee, contract) => {
    return dispatch => {
        const data = {
            contract,
            owner,
            channel: 'sms',
            person: client,
            employee,
        }

        return apiClient.post('/signature', data)
            .then(resp => resp.data)
            .then(json => {
                const msgResponse = json.message

                dispatch(sentMessage(msgResponse))
                // Also update the inbox list
                dispatch(inboxLastMessageUpdate(client, msgResponse, false))
            })
            .catch(error => dispatch(processError(error)))
    }
}

export const sendForm = (client, employee, form) => {
    return dispatch => {
        const data = {
            contract: form.contract,
            type: form.id,
            channel: 'sms',
            person: client,
            employee,
            name: form.name,
            field_values: {},
        }

        return apiClient.post('/record', data)
            .then(resp => resp.data)
            .then(json => {
                const msgResponse = json.message

                dispatch(sentMessage(msgResponse))
                // Also update the inbox list
                dispatch(inboxLastMessageUpdate(client, msgResponse, false))
            })
            .catch(error => dispatch(processError(error)))
    }
}

const setSavedMessages = (templates) => {
    return { type: 'conversation/SET_SAVED_MESSAGES', templates }
}

export const fetchSavedMessages = () => {
    return dispatch => {
        return apiClient.post('/template/sync')
            .then(resp => resp.data)
            .then(json => {
                dispatch(setSavedMessages(json.templates))
            })
            .catch(error => dispatch(processError(error)))
    }
}

export const sendPhotoMessage = (recipient, message, photo, isTeam) => {
    return dispatch => {
        const formData = new FormData()
        formData.append('image', photo, photo.name)
        formData.append('message', message)
        if (isTeam)
            formData.append('team', recipient)
        else
            formData.append('recipient', recipient)

        return apiClient.post(isTeam ? '/broadcast' : '/message', formData)
            .then(resp => resp.data)
            .then(json => {
                const msgResponse = isTeam ? json.message : json
                dispatch(sentMessage(msgResponse))
                // Also update the inbox list
                dispatch(inboxLastMessageUpdate(recipient, msgResponse, false))
            })
            .catch(error => dispatch(processError(error)))
    }
}

export const sendScheduledMessage = (recipient, message, date, templateID) => {
    return dispatch => {
        const data = {
            date,
            message,
            template: templateID,
            person: recipient,
        }

        return apiClient.post('/campaign', data)
            .then(resp => resp.data)
            .then(json => {
            })
            .catch(error => dispatch(processError(error)))
    }
}

export const getTeamByPeople = (userIds) =>
    dispatch => {
        return apiClient.post('/team/sync', {people: userIds})
            .then(resp => resp.data)
            .then(json => {
                if (json?.team) {
                    const teamMap = {}
                    teamMap[json.team.id] = json.team

                    dispatch({type: 'entities/UPDATE', name: 'teams', map: teamMap})
                }

                return json?.team;
            })
    }
