import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { RecordStatusBar } from '../RecordStatusBar'
import { useDispatch, useSelector } from 'react-redux'
import { RecordBody } from '../RecordBody'
import { RecordDetail } from '../RecordDetail'
import { Link } from 'react-router-dom'
import { ReactComponent as ChevronIcon } from '../../../icons/chevron.svg'
import { RootState } from '../../../store'
import { distinctButtons, RecordStatus } from '../../../utils/records'
import { ButtonProps, RecordButtons } from '../RecordButtons'
import { PSButtonPrimary } from '../../app/PSButton'
import { push, replace } from 'connected-react-router'
import { signatureStatus, Signature } from '../../../models/Signature'
import { useConfirmModal, usePSUser } from '../../../hooks'
import { deleteSignature, sendSignature, signSignature } from '../../../modules/transactions'
import { Result } from '../../../utils'
import { API_BASE } from '../../../modules/apiclient'
import { SignatureModal } from '../../settings/SignatureModal'
import { UserInstance } from '../../../models/User'
import { Contract } from '../../../models/Contract'

export const RecordSignature = (props: any) => {
    const dispatch = useDispatch()
    const state = useSelector((state: RootState) => state.entities)
    const user = usePSUser()

    const [signature, setSignature] = useState<Signature>(new Signature(props.record))
    const [statuses, setStatuses] = useState<RecordStatus[]>([])
    const [contract, setContract] = useState<Partial<Contract>>({})
    const [forVendor, setForVendor] = useState(true)
    const [contact, setContact] = useState<Partial<UserInstance>>({})
    const [recordDetails, setRecordDetails] = useState<JSX.Element[]>([])
    const [isSigning, setIsSigning] = useState(false)
    const [showSignatureModal, setShowSignatureModal] = useState(false)

    useEffect(() => {
        if (props.record.id && props.record.id in state.signatures)
            setSignature(new Signature(state.signatures[props.record.id]))
    }, [props.record.id, state.signatures])

    useEffect(() => {
        if (!signature.sent_date) return

        setStatuses([
            {label: 'Sent', completed: !!signature.sent_date},
            {label: 'Viewed', completed: !!signature.viewed_date},
            {label: 'Signed', completed: !!signature.signed_date},
        ])
    }, [signature.sent_date, signature.viewed_date, signature.signed_date])

    useEffect(() => {
        if (signature?.contract && signature.contract in state.contracts)
            setContract(state.contracts[signature.contract])
    },[signature.contract, state.contracts])

    useEffect(() => {
        if (!user || !signature?.owner) return
        setForVendor(user.id === signature.owner)
    }, [user, signature?.owner])

    useEffect(() => {
        const cosigner = forVendor ? signature.person : signature.owner
        if (cosigner && cosigner in state.contacts)
            setContact(state.contacts[cosigner])
    }, [forVendor, signature.person, signature.owner, state.contacts])

    useEffect(() => {
        if (!contact.id) return

        const details = (
            <Link to={`/record/client/${contact.id}`}>
                {contact.name}
                <ChevronIcon className="chevron" />
            </Link>
        )

        setRecordDetails([
            <RecordDetail header={forVendor ? 'Client' :  'Pro'} details={details} subtitle={signatureStatus(signature)}/>,
            <RecordDetail header="Contract" details={contract.memo} isText />
        ])
    }, [contact.id, contact.name, forVendor, signature, contract.memo])

    const [setCtaModalInfo, setCtaModalLoading, showCtaModal, closeCtaModal, ctaComponent] = useConfirmModal()

    const viewContract = useCallback(() =>
        window.open(`${API_BASE}/signature/${signature.id}/print`, "_blank"), [signature.id])

    const showCancelContract = useCallback(() => {
        if (!signature.id) return

        const cancelContract = "Cancel contract"
        const cancelAndNotify = "Cancel & notify"
        const body = `Would you like to cancel this contract? Select "${cancelAndNotify}" \
            if you'd like a cancellation notification to be sent to the client. If you don't \
            want the client to be notified of the cancellation select "${cancelContract}".`

        setCtaModalInfo({
            title: cancelContract,
            body,
            button: cancelAndNotify,
            optionalButton: cancelContract,
            danger: true,
            onClick: (doNotNotify: boolean) => {
                setCtaModalLoading(true)
                Result(dispatch(deleteSignature(signature.id, doNotNotify)))
                    .then(() => doNotNotify ? closeCtaModal() : dispatch(replace(`/messages/${signature.person}`)))
            }
        })
        showCtaModal()
    }, [signature.id, signature.person, setCtaModalInfo, setCtaModalLoading, dispatch, closeCtaModal, showCtaModal])

    const showResendContract = useCallback(() => {
        if (!signature) return

        setCtaModalInfo({
            title: "Resend contract",
            body: "Would you like to resend this contract to this client?",
            button: "Resend",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(sendSignature(signature)))
                    .then(() => dispatch(replace(`/messages/${signature.person}`)))
            }
        })
        showCtaModal()
    }, [signature, setCtaModalInfo, setCtaModalLoading, dispatch, showCtaModal])

    const sendMessage = useCallback(() =>
        dispatch(push(`/messages/${forVendor ? signature.person : signature.owner}`))
    , [dispatch, signature.person, signature.owner, forVendor])

    const getButtons = useCallback(() => {
        const buttons: ButtonProps[] = []

        if (!signature.signed_date && !signature.cancelled_date) {
            if (!forVendor || signature.person === user?.id)
                buttons.push({ text: "Sign", onClick: () => setShowSignatureModal(true) })
            else if (forVendor)
                buttons.push({ text: "Cancel", onClick: showCancelContract })
            if (forVendor)
                buttons.push({ text: "Resend", onClick: showResendContract })
        }

        buttons.push({ text: "Chat", onClick: sendMessage })

        return distinctButtons(buttons)
    }, [signature.signed_date, signature.cancelled_date, forVendor, signature.person, user?.id, showCancelContract, showResendContract, sendMessage])

    const buttons = getButtons()

    return (
        <div className="recordView">
            <div className="topBar">
                <RecordStatusBar statuses={statuses}/>
                <div className="actions">
                    <PSButtonPrimary onClick={viewContract}>
                        {signature.signed_date ? 'Print' : 'Preview' }
                    </PSButtonPrimary>
                    <RecordButtons buttons={buttons} />
                </div>
            </div>
            <div className="recordHeader">
                <h2>{contract.name}</h2>
                <div className="title">{contract.refnum}</div>
                <div className="time">
                    {signature.signed_date
                        ? `Signed on ${moment(signature.signed_date).format('MMM D, YYYY h:mm a')}`
                        : `Sent on ${moment(signature?.created_date).format('MMM D, YYYY')}`
                    }
                </div>
            </div>
            <RecordBody recordDetails={recordDetails} />
            {ctaComponent}
            {showSignatureModal && (
                <SignatureModal
                    isSigning={isSigning}
                    onClose={() => setShowSignatureModal(false)}
                    onDone={(data) => {
                        signature.signature_data = data
                        setIsSigning(true)
                        Result(dispatch(signSignature(signature)))
                            .then(() => setShowSignatureModal(false))
                            .finally(() => setIsSigning(false))
                    }}
                />
            )}
        </div>
    )
}