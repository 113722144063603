import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import {
    sendMessage, sendPhotoMessage, sendScheduledMessage,
    setMessageInputBarHeight
} from '../../modules/conversation'
import { IconButton } from '@zendeskgarden/react-buttons'
import { ReactComponent as MessageAttachmentIcon } from '../../icons/message_input_attachment.svg'
import { ReactComponent as MessageCameraIcon } from '../../icons/message_input_camera.svg'
import { ReactComponent as MessageEmojiIcon } from '../../icons/message_input_emoji.svg'
import { usePhotoUpload } from '../settings/hooks'
import EmojiPicker from 'emoji-picker-react'
import { MessageAttachmentModal} from './MessageAttachmentModal'
import { SavedMessagesModal } from './SavedMessagesModal'
import { usePSOwner } from '../../hooks'

const BarWrapper = styled('div')`
  z-index: 150;
  margin: 0;
  padding: 0 8px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  border-top: 1px solid #e4e4e4;
  background: #ffffff;
  align-items: center;
`

const InputField = styled('div')`
  flex: 1 1 auto;
  background-color: #ffffff;
  border: none;
  margin: 8px 0;
  min-width: 100px;
  padding: 6px 8px 6px 12px;
  cursor: text;
`

export const MessageInputBar = (props) => {
    const dispatch = useDispatch()
    const state = useSelector(state => {
        return {
            conversation: state.conversation,
            entities: state.entities,
            messages: state.messages,
        }
    })

    const [showSavedMessagesModal, setShowSavedMessagesModal] = useState(false)
    const [showEmojiPicker, setShowEmojiPicker] = useState(false)
    const [showAttachmentModal, setShowAttachmentModal] = useState('')
    const [inputSelectionStart, setInputSelectionStart] = useState(0)
    const [inputSelectionEnd, setInputSelectionEnd] = useState(0)
    const [templateID, setTemplateID] = useState('')

    const [templatesEnabled, setTemplatesEnabled] = useState(false)
    const [widgetEnabled, setWidgetEnabled] = useState(false)
    const [formsEnabled, setFormsEnabled] = useState(false)
    const [contractsEnabled, setContractsEnabled] = useState(false)

    const owner = usePSOwner()
    useEffect(() => {
        if (!owner) return

        setTemplatesEnabled(owner.allow_template)
        setWidgetEnabled(owner.allow_widget)
        setFormsEnabled(!state.conversation.isGroupMessage && owner.allow_record)
        setContractsEnabled(!state.conversation.isGroupMessage && owner.allow_contract)
    }, [state.conversation.isGroupMessage, owner])

    // Attachment popup handler
    const [showAttachmentMenu, setShowAttachmentMenu] = useState(false)
    const toggleAttachmentMenu = useCallback(() => {
        setShowAttachmentMenu(!showAttachmentMenu)
    }, [showAttachmentMenu])

    useEffect(() => {
        const handleClick = () => {
            if (showAttachmentMenu)
                toggleAttachmentMenu()
        }

        if (showAttachmentMenu)
            window.addEventListener('click', handleClick)
        else
            window.removeEventListener('click', handleClick)

        return _ => {
            window.removeEventListener('click', handleClick)
        }
    }, [toggleAttachmentMenu, showAttachmentMenu])

    const sendPhoto = (photo) => {
        // TODO Check file size?
        // TODO Insert the photo visually, so it can be sent with text and removed from the input bar, if desired
        dispatch(sendPhotoMessage(state.conversation.currentThread, '', photo, state.conversation.isGroupMessage))
    }
    const [addPhotoRef, selectPhoto, selectedPhoto] = usePhotoUpload({disabled: false, setPhoto: sendPhoto})

    const handleSelectedItem = (selectedItem) => {
        if (showAttachmentModal === 'link') {
            messageRef.current.value = selectedItem.message
            onKeyUp(null)
            messageRef.current.focus()
        }
        setShowAttachmentModal('')
    }

    const messageRef = useRef()

    // Focus on the send message input box when this component loads
    useEffect(() => {
        if (messageRef.current && !state.messages.searchText) {
            messageRef.current.focus()
        }
    }, [messageRef, state.messages.searchText])

    useEffect(() => {
        if (state.conversation.draftMessage.message) {
            messageRef.current.value = state.conversation.draftMessage.message
        }
        if (state.conversation.draftMessage.templateID) {
            setTemplateID(state.conversation.draftMessage.templateID)
        }
    }, [state.conversation.draftMessage])

    if (state.conversation.isLoading) return null

    const onKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()

            const msg = messageRef.current.value
            if (msg.trim().length === 0) return

            const { currentThread, isGroupMessage } = state.conversation
            const isGroup = isGroupMessage ||
                // special handling when group conversation is first opened, and there are no messages, do not clear out group flag
                (currentThread in state.entities.teams && !(currentThread in state.entities.contacts))

            let isBroadcast = false
            if (isGroup) {
                const team = state.entities.teams[currentThread] || {}
                isBroadcast = team && team.id && team.bcc
            }

            dispatch(sendMessage(currentThread, msg, isGroup, isBroadcast, templateID))
            messageRef.current.value = ''
            if (templateID)
                setTemplateID('')
        }
    }

    const onKeyUp = (e) => {
        // Clear the template ID of a saved message if the whole message was deleted
        if (templateID && messageRef.current.value === '') {
            setTemplateID('')
        }

        messageRef.current.style.height = '1px'
        messageRef.current.style.height = `${messageRef.current.scrollHeight}px`
        if (messageRef.current.scrollHeight !== state.conversation.inputBarHeight) {
            dispatch(setMessageInputBarHeight(messageRef.current.scrollHeight))
        }
    }

    const onBlur = (e) => {
        setInputSelectionStart(messageRef.current.selectionStart)
        setInputSelectionEnd(messageRef.current.selectionEnd)
    }

    const onEmojiClick = (e, emoji) => {
        e.preventDefault()

        setShowEmojiPicker(false)

        const message = messageRef.current.value
        messageRef.current.value =
            message.slice(0, inputSelectionStart) + emoji.emoji +
            message.slice(inputSelectionEnd)
    }

    const selectedSaveMessage = (savedMessageBody, savedMessageID, sendAt) => {
        if (!sendAt) {
            messageRef.current.value = savedMessageBody
            onKeyUp(null)
            messageRef.current.focus()
            setTemplateID(savedMessageID)
        }
        else {
            // Schedule this message
            dispatch(sendScheduledMessage(state.conversation.currentThread, savedMessageBody, sendAt, savedMessageID))
        }
        setShowSavedMessagesModal(false)
    }

    let wrapperTopAdjustment = 200
    if (!templatesEnabled) wrapperTopAdjustment -= 38
    if (!widgetEnabled) wrapperTopAdjustment -= 38
    if (!contractsEnabled) wrapperTopAdjustment -= 38
    if (!formsEnabled) wrapperTopAdjustment -= 38

    return (
        <BarWrapper style={{height: `${state.conversation.inputBarHeight + 38}px`}}
                    className="inputBar"
        >
            <InputField>
                <textarea
                    style={{
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',
                        margin: '4px',
                        border: 'none',
                        width: '100%',
                        outline: 'none',
                        resize: 'none',
                        overflow: `hidden ${state.conversation.inputBarHeight >= 160 ? 'auto' : 'hidden'}`,
                        height: `${state.conversation.inputBarHeight}px`,
                        maxHeight: '160px',
                    }}
                    placeholder="Send a message"
                    ref={messageRef}
                    onKeyUp={onKeyUp}
                    onKeyPress={onKeyPress}
                    onBlur={onBlur}
                />
            </InputField>
            <div className="actionButtons">
                {(templatesEnabled || widgetEnabled || contractsEnabled || formsEnabled) && (
                    <IconButton onClick={() => setShowAttachmentMenu(!showAttachmentMenu)}>
                        <MessageAttachmentIcon />
                    </IconButton>
                )}
                {showAttachmentMenu && (
                    <div className="attachmentWrapper"
                         style={{top: `calc(100% - ${wrapperTopAdjustment}px)`}}
                    >
                        {templatesEnabled && (
                            <div className="link"
                                 onClick={() => {
                                     setShowAttachmentMenu(false)
                                     setShowSavedMessagesModal(true)
                                 }}
                            >
                                Saved messages
                            </div>
                        )}
                        {widgetEnabled && (
                            <div className="link"
                                 onClick={() => {
                                     setShowAttachmentMenu(false)
                                     setShowAttachmentModal('link')
                                 }}
                            >
                                View & send links
                            </div>
                        )}
                        {contractsEnabled && (
                            <div className="link"
                                 onClick={() => {
                                     setShowAttachmentMenu(false)
                                     setShowAttachmentModal('contract')
                                 }}
                            >
                                Send a contract
                            </div>
                        )}
                        {formsEnabled && (
                            <div className="link"
                                 onClick={() => {
                                     setShowAttachmentMenu(false)
                                     setShowAttachmentModal('form')
                                 }}
                            >
                                Send a form
                            </div>
                        )}
                    </div>
                )}
                <IconButton onClick={() => selectPhoto()}>
                    <MessageCameraIcon />
                </IconButton>
                <IconButton onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                    <MessageEmojiIcon />
                </IconButton>
                <input type="file"
                       ref={addPhotoRef}
                       onChange={selectedPhoto}
                       accept="image/png,image/jpg,image/jpeg"
                       style={{display: 'none'}} />
            </div>
            {showEmojiPicker && (
                <div className="pickerWrapper">
                    <EmojiPicker onEmojiClick={onEmojiClick} />
                </div>
            )}
            {showAttachmentModal && (
                <MessageAttachmentModal attachmentType={showAttachmentModal}
                                        onClose={() => setShowAttachmentModal('')}
                                        onSend={handleSelectedItem}
                />
            )}
            {showSavedMessagesModal && (
                <SavedMessagesModal onClose={() => setShowSavedMessagesModal(false)}
                                    onSend={selectedSaveMessage}
                />
            )}
        </BarWrapper>
    )
}