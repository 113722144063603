import React, {useEffect, useState} from "react";
import {
    Body, Close,
    Footer,
    FooterItem,
    Header,
    Modal
} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import {Field, Hint, Input, Label} from "@zendeskgarden/react-forms";
import { currencyFormat, filterMonetaryInput} from "../../../utils/numbers";

type Props = {
    currentData?: number,
    max: number,
    min: number,
    onDone: (amount: number) => void,
    onCancel: () => void,
}

export const RecordTotalModal = ({currentData, max, min, onDone, onCancel}: Props) => {
    const [customAmount, setCustomAmount] = useState(currentData?.toString())

    const onDoneHandler = () => {
        onDone(Number(customAmount))
    }

    const [saveEnabled, setSaveEnabled] = useState(false)
    useEffect(() => {
        setSaveEnabled(Number(customAmount) >= min && Number(customAmount) <= max)
    }, [customAmount, max, min])

    return (
        <Modal className="modal"
               onClose={onCancel}
        >
            <Header className="header">
                <div className="title">Total</div>
            </Header>
            <Body className="body form">

                <Field className="field">
                    <Label className="label">
                        Total
                    </Label>
                    <Hint className='hint'>Enter a value between {currencyFormat(min)} and {currencyFormat(max)}</Hint>
                    <Input placeholder="Enter tip amount"
                           value={customAmount}
                           onChange={e => filterMonetaryInput(e.target.value, setCustomAmount)}
                            />
                </Field>
            </Body>
            <Footer className="footer">
                <FooterItem>
                    <Button onClick={onCancel}>Cancel</Button>
                </FooterItem>
                <FooterItem>
                    <Button isPrimary disabled={!saveEnabled}
                            onClick={onDoneHandler}
                    >
                        Done
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )
}