import { Field, Input, Label } from "@zendeskgarden/react-forms";
import React from "react";

type FieldProps = {
    fieldValue?: string,
    label: string,
    placeholder: string,
    onChange: (changed: string) => void
}

export const TextInput = ({fieldValue, label, placeholder, onChange}: FieldProps) => {
    return (
        <Field className='field'>
            <Label className='label'>{label}</Label>
            <Input placeholder={placeholder}
                value={fieldValue}
                onChange={e => onChange(e.target.value)} />
        </Field>
    )
}