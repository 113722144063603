import React from "react";
import {Dashboard} from "../../models/Dashboard";
import {DashboardIcon} from "./DashboardIcon";
import {currencyFormat} from "../../utils/numbers";

type Props = {
    dashboard: Dashboard,
    onView: (d: Dashboard) => void,
}

export const DashboardCard = ({dashboard, onView}: Props) => {
    let displayValue = dashboard.value
    if (['income', 'complete', 'invoices'].indexOf(dashboard.id) > -1)
        displayValue = currencyFormat(dashboard.value, false, true)

    let displayMetric
    if (dashboard.metric) {
        displayMetric = (
            <div className={dashboard.metric[0] === '+' ? 'green' : 'red'}>
                {dashboard.metric}
            </div>
        )
    }

    return (
        <div className="cardGroup">
            <div className="card">
                <div className="icon">
                    <DashboardIcon dashboard={dashboard} />
                </div>
                <div className="title">{dashboard.title}</div>
                <div className="status">{dashboard.status}</div>
                <div className="value">{displayValue}</div>
                <div className="metric">{displayMetric}</div>
                <div className="view" onClick={() => onView(dashboard)}>View</div>
            </div>
        </div>
    )
}