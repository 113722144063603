import { capitalizeFirstLetters } from "../utils";

export type SourceInstance = {
    id?: string;
    name: string;
    type: string;
    owner: string;
    creator: string;
    charge: string;
    transfer: string;
    token: string;
    allow_payment: boolean;
    allow_deposit: boolean;
    instant: boolean;
    card_brand: string;
    card_number: string;
    card_scanned: boolean;
    security_code: string;
    postal_code: string;
    country_code: string;
    expired_date: Date;
    bank_name: string;
    routing_number: string;
    account_number: string;
    display_name: string;
    verified_date: Date;
    cancelled_date?: Date;
    last_modified_date: Date;
}

export const getDescription = (source: Partial<SourceInstance>) => {
    return source.type === 'checking'
        ? `${capitalizeFirstLetters(source.type)} - ${source.account_number?.slice(-4)}`
        : `${capitalizeFirstLetters(source.card_brand || 'Card')} - ${source.card_number?.slice(-4)}`
}