import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import { usePSUser } from '../../hooks'
import { AppHeader } from '../app/AppHeader'
import { SelectPlan, COMEBACK_CONTEXT, NEW_PLAN_CONTEXT } from '../settings/SelectPlan'

export const WelcomeBack = () => {
    const history = useHistory()
    const user = usePSUser()
    const onNext = useCallback(() => {
        // this will reload the page, which is required to register routes
        history.go(0)
    }, [history])

    return (
        <AppHeader style={{width: '100%', overflowY: 'scroll'}} middleWidget={null}>
            <div style={{width: '600px'}}>
                <SelectPlan context={user?.last_sync_date ? COMEBACK_CONTEXT : NEW_PLAN_CONTEXT} onNext={onNext} />
            </div>
        </AppHeader>
    )
}