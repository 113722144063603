import React, {useCallback, useEffect, useState} from "react";
import {
    clearCategory,
    fetchCategory,
    fetchItems, moveCategoryItem
} from "../../../modules/settings";
import {useAppDispatch} from "../../../hooks";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {useParams} from "react-router";
import {Spinner} from "@zendeskgarden/react-loaders";
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult
} from "react-beautiful-dnd";
import {Item} from "../../../models/Item";
import {SortableItemCard} from "../SortableItemCard";
import { ReactComponent as LeftArrow } from '../../../icons/left-arrow.svg'
import findIndex from "lodash.findindex";
import {PSButton} from "../../app/PSButton";

export const CategorySort = () => {
    const dispatch = useAppDispatch()
    const state = useSelector((state: RootState) => state.settings)

    // Load the items so we can render them from the id list
    useEffect(() => {
        dispatch(fetchItems())
    }, [dispatch])

    // Load the category if this is an edit, otherwise treat it as a create
    const params: any = useParams()
    useEffect(() => {
        if (!params.categoryID) {
            dispatch(clearCategory())
            return
        }
        dispatch(fetchCategory(params.categoryID))
    }, [dispatch, params.categoryID])

    // Convert category.listings into an array of Items
    const [sortedItems, setSortedItems] = useState([])
    useEffect(() =>{
        if (!state.category.listings) return

        const itemList = state.category.listings.map((itemId: string) => {
            const itemIndex = findIndex(state.items, (i: Item) => i.id === itemId)
            if (itemIndex > -1) {
                return state.items[itemIndex]
            }
            return new Item('service')
        }).filter((i: Item) => !!i.id)
        setSortedItems(itemList)
    }, [state.category, state.items])

    const onDragEnd = useCallback((result: DropResult) => {
        if (!result.destination) return

        const [removed] = sortedItems.splice(result.source.index, 1)

        // Send to the server
        dispatch(moveCategoryItem(state.category.id, removed, result.destination.index+1))

        sortedItems.splice(result.destination.index, 0, removed)
        setSortedItems(sortedItems)
    }, [dispatch, state.category.id, sortedItems])

    if (state.isSending) {
        return (
            <div className="servicesSettings">
                <div className="header">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="servicesSettings">
            <div className="header">
                <PSButton style={{marginLeft: 0, marginRight: 18}} onClick={() => window.history.back()}>
                    <LeftArrow />
                </PSButton>
                <h1>{state.category.name}</h1>
            </div>
            <div className="separator" />
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(droppableProps: any) => {
                        return (
                            <div ref={droppableProps.innerRef}>
                                {sortedItems.map((item: Item, index) => (
                                    <Draggable key={item.id}
                                               draggableId={item.id as string}
                                               index={index}>
                                        {(provided: any, snapshot: any) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={provided.draggableProps.style}
                                            >
                                                <SortableItemCard key={`item-${item.id}`}
                                                                  isDragging={snapshot.isDragging}
                                                                  item={item}
                                                                  type={item.type} />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            </div>
                        )
                    }}
                </Droppable>
            </DragDropContext>
        </div>
    )
}