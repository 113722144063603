import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../hooks";
import {
    saveUser,
    SettingsState
} from "../../../modules/settings";
import {RootState} from "../../../store";
import {useSelector} from "react-redux";
import {Field, Hint, Input, Label, Message} from "@zendeskgarden/react-forms";
import {Prompt} from "react-router";
import {PSButtonPrimary} from "../../app/PSButton";
import {Dots} from "@zendeskgarden/react-loaders";
import localforage from 'localforage'
import { VALIDATION } from "@zendeskgarden/react-forms/dist/typings/utils/validation";
import {PSTextarea} from "../../app/PSTextarea";
import {SettingsPhotoField} from "../SettingsPhotoField";
import {usePhotoUpload} from "../hooks";
import { isValidEmailAddress } from "../../../utils";

export const ProfileEdit = () => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const [photo, setPhoto] = useState<undefined | string | File>('')
    const [addPhotoRef, selectPhoto, selectedPhoto] = usePhotoUpload({disabled: state.isSending, setPhoto})

    const [saveEnabled, setSaveEnabled] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)

    const [user, setUser] = useState<any>({})

    const [superAdmin, setSuperAdmin] = useState<boolean>(true)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [website, setWebsite] = useState('')
    const [username, setUsername] = useState('')
    const [instagram, setInstagram] = useState('')
    const [description, setDescription] = useState('')

    const [firstNameValidation, setFirstNameValidation] = useState<VALIDATION | undefined>(undefined)
    const [emailValidation, setEmailValidation] = useState<VALIDATION | undefined>(undefined)
    const [websiteValidation, setWebsiteValidation] = useState<VALIDATION | undefined>(undefined)
    const [usernameValidation, setUsernameValidation] = useState<VALIDATION | undefined>(undefined)
    const [instagramValidation, setInstagramValidation] = useState<VALIDATION | undefined>(undefined)

    const [descriptionTitle, setDescriptionTitle] = useState('')
    const [descriptionPlaceholder, setDescriptionPlaceholder] = useState('')

    useEffect(() => {
        if (!user) return
        setPhoto(user.image_uri || '' )
        if (user.name) {
            const match = user.name.match(/^\s*(\S+)\s?(.*)$/)
            setFirstName(match[1])
            setLastName(match[2])
        }
        setEmail(user.email)
        setWebsite(user.url)
        setUsername(user.username)
        setInstagram(user.instagram)
        setDescription(user.bio)
        setSuperAdmin(user.role === 'owner')
    }, [user])

    useEffect(() => {
        if (superAdmin) {
            setDescriptionTitle('Description')
            setDescriptionPlaceholder('Profile description')
        }
        else {
            setDescriptionTitle('About yourself')
            setDescriptionPlaceholder('Description')
        }
    }, [superAdmin])

    useEffect(() => {
        setFirstNameValidation( !!firstName ? undefined : 'error')
    }, [firstName])

    useEffect(() => {
        setEmailValidation(isValidEmailAddress(email) ? undefined : 'error')
    }, [email])

    useEffect(() => {
        // copied from https://www.regextester.com/93652
        setWebsiteValidation( !website || website?.match('^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,24}(:[0-9]{1,5})?(\\/.*)?$') ? undefined : 'error')
    }, [website])

    useEffect(() => {
        setUsernameValidation( !username || username?.match(/^[a-zA-Z0-9_-]{0,49}$/) ? undefined : 'error')
    }, [username])

    useEffect(() => {
        //(instagram algo lifted from https://blog.jstassen.com/2016/03/code-regex-for-instagram-username-and-hashtags/)
        setInstagramValidation( !instagram || instagram?.match(/(?:@?)([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\\.(?!\\.))){0,28}(?:[A-Za-z0-9_]))?)/) ? undefined : 'error')
    }, [instagram])

    useEffect(() => {
        let edits = false
        if (!user) return
        if (user.email !== email)
            edits = true
        else if (user.url !== website)
            edits = true
        else if (user.username !== username)
            edits = true
        else if (user.instagram !== instagram)
            edits = true
        else if (user.bio !== description)
            edits = true
        else if (typeof photo === 'object')
            edits = true
        else if (user.name) {
            const match = user.name.match(/^\s*(\S+)\s?(.*)$/)
            if (firstName !== match[1])
                edits = true
            else if (lastName !== match[2])
                edits = true
        }
        setHasEdits(edits)
    }, [user, firstName, lastName, email, website, username, instagram, description, photo])

    useEffect(() => {
        setSaveEnabled(hasEdits && !firstNameValidation && !websiteValidation && !emailValidation && !usernameValidation && !instagramValidation)
    }, [hasEdits, firstNameValidation, emailValidation, websiteValidation, usernameValidation, instagramValidation])

    useEffect(() => {
        localforage.getItem('user').then((user: any) => setUser(user))
    }, [])

    const onSave = () => {
        if (state.isSending) return

        const userParams: any = {}
        userParams.id = user.id

        userParams.name = firstName
        if (lastName) userParams.name += " " + lastName
        userParams.email = email
        userParams.username = username
        userParams.url = website
        userParams.instagram = instagram
        userParams.bio = description

        if (typeof photo === 'string') {
            // @ts-ignore
            dispatch(saveUser(userParams)).then(() => localforage.getItem('user').then((user: any) => setUser(user)))
        }
        else {
            // @ts-ignore
            dispatch(saveUser(userParams, photo as File)).then(() => localforage.getItem('user').then((user: any) => setUser(user)))
        }
    }

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Profile</h1>
                <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!saveEnabled || state.isSending}>
                    {state.isSending && <Dots />}
                    {!state.isSending && "Save"}
                </PSButtonPrimary>
            </div>
            <div className="form">
                <SettingsPhotoField photo={photo}
                                    selectPhoto={selectPhoto}
                                    selectedPhoto={selectedPhoto}
                                    addPhotoRef={addPhotoRef}
                                    title="Add a logo" />

               <Field className="field">
                    <Label className="label">First name (required)</Label>
                    <Input placeholder="First name"
                           value={firstName}
                           validation={firstNameValidation}
                           disabled={state.isSending}
                           onChange={e => setFirstName(e.target.value)} />
                </Field>
                {!!firstNameValidation && (
                    <Message validation={firstNameValidation}>Enter a value for First name</Message>
                )}
               <Field className="field">
                    <Label className="label">Last name</Label>
                    <Input placeholder="Last name"
                           value={lastName}
                           disabled={state.isSending}
                           onChange={e => setLastName(e.target.value)} />
                </Field>
                <Field className="field">
                    <Label className="label">Email (required)</Label>
                    <Hint>Appears on transactions and receipts sent to clients</Hint>
                    <Input placeholder="Email"
                           value={email}
                           validation={emailValidation}
                           disabled={state.isSending}
                           onChange={e => setEmail(e.target.value)} />
                </Field>
                {!!emailValidation && (
                    <Message validation={emailValidation}>That does not appear to be a valid email address.</Message>
                )}
                {!!superAdmin && (
                <div>
                <Field className="field">
                        <Label className="label">Website</Label>
                        <Hint>Appears on transactions and receipts sent to clients</Hint>
                        <Input placeholder="Add website"
                            value={website}
                            validation={websiteValidation}
                            disabled={state.isSending}
                            onChange={e => setWebsite(e.target.value)} />
                    </Field>
                    {!!websiteValidation && (
                        <Message validation={websiteValidation}>That does not appear to be a valid website address.</Message>
                    )}
                    <Field className="field">
                        <Label className="label">Username</Label>
                        <Hint>The “User ID” for your Suite link and online booking pages </Hint>
                        <Input placeholder="Add username"
                            value={username}
                            validation={usernameValidation}
                            disabled={state.isSending}
                            maxLength={49}
                            onChange={e => setUsername(e.target.value)} />
                    </Field>
                    {!!usernameValidation && (
                        <Message validation={usernameValidation}>Limited to 49 characters & can only contain letters, numbers, dashes & underscores</Message>
                    )}
                    <Field className="field">
                        <Label className="label">Instagram</Label>
                        <Hint>Instagram Profile</Hint>
                        <Input placeholder="Add Instagram handle"
                            value={instagram}
                            validation={instagramValidation}
                            disabled={state.isSending}
                            maxLength={30}
                            onChange={e => setInstagram(e.target.value)} />
                    </Field>
                    {!!instagramValidation && (
                        <Message validation={instagramValidation}>Limited to 30 characters & can only contain letters, numbers, periods & underscores</Message>
                    )}
                </div>
                )}
                <Field className="field">
                    <Label className="label">{descriptionTitle}</Label>
                    <Hint>Displayed for clients to view when booking</Hint>
                    <PSTextarea placeholder={descriptionPlaceholder}
                                className="input"
                                minRows={8}
                                disabled={state.isSending}
                                value={description ? description : ''}
                                onChange={value => setDescription(value)} />
                </Field>
            </div>

            <Prompt
                when={hasEdits && !state.isSending}
                message="Are you sure you'd like to leave this page without saving your changes?" />
        </div>
    )
}
