import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../hooks";
import {
    authorizeSource,
    deleteSource,
    fetchSource,
    verifySource,
    SettingsState
} from "../../../modules/settings";
import {RootState} from "../../../store";
import {useSelector} from "react-redux";
import {useParams} from "react-router";
import localforage from 'localforage'
import { push } from "connected-react-router";
import { SourceViewComponent } from "./SourceViewComponent";

export const SourceView = () => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const [user, setUser] = useState<any>()
    const [saveEnabled, setSaveEnabled] = useState<boolean>()
    const params: any = useParams()

    useEffect(() => {
        if (!dispatch) return
        if (!params.sourceID) return
        if (!user) return

        if (!!params?.sourceID) {
            dispatch(fetchSource(params.sourceID))
        }
    }, [dispatch, user, params.sourceID])


    useEffect(() => {
        localforage.getItem('user').then((user: any) => setUser(user))
        setSaveEnabled(true)
    }, [])

    const doVerify = (firstAmount: number, secondAmount: number) => {
        dispatch(verifySource(state.source, firstAmount, secondAmount))
    }

    const doAuthorize = (accountNumber: string) => {
        dispatch(authorizeSource(state.source, accountNumber))
    }

    const doDelete = () => {
        // @ts-ignore
        dispatch(deleteSource(state.source)).then(
            dispatch(push(`/settings/payments/methods`))
        )
    }

    return <SourceViewComponent
        source={state.source}
        disabled={!saveEnabled || state.isSending}
        isCompany={user?.type === 'company'}
        doVerify={doVerify}
        doAuthorize={doAuthorize}
        doDelete={doDelete} />
    //
}