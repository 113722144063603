import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { PSButton, PSButtonPrimary } from "../../../app/PSButton";
import { ReactComponent as LeftArrow } from "../../../../icons/left-arrow.svg";
import { Item } from "@zendeskgarden/react-dropdowns";
import { Field } from "@zendeskgarden/react-forms";
import { PSDropdown } from "../../../app/PSDropdown";
import { cancellationReasonOptions, SelectOption } from "../../Options";
import { useAppDispatch, usePSUser } from "../../../../hooks";
import { push } from "connected-react-router";
import { useSelector } from "react-redux";
import { fetchActiveSubscriptions, SettingsState } from "../../../../modules/settings";
import { RootState } from "../../../../store";
import { PREMIUM_ID } from "../../../../utils/constants";
import moment from "moment";

export const CancelPlanFeedback = () => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const { activePSSubscription }: SettingsState = useSelector((state: RootState) => state.settings)
    const me = usePSUser()

    const [reason, setReason] = useState<SelectOption>()
    const [offerDiscount, setOfferDiscount] = useState(false)
    const [reasonOptions, setReasonOptions] = useState<SelectOption[]>(cancellationReasonOptions)

    useEffect(() => {
        if (!me?.id || activePSSubscription.id) return
        dispatch(fetchActiveSubscriptions(me.id))
    }, [me?.id, activePSSubscription, dispatch])

    useEffect(() => {
        if (!activePSSubscription.id) return

        const { plan, interval, frequency, discount_amount } = activePSSubscription
        const shouldOfferDiscount = !(
            plan !== PREMIUM_ID ||
            interval !== 1 ||
            frequency !== 'monthly' ||
            (!!Number(discount_amount) && Number(discount_amount) - 1.99 < 1)
        )

        setOfferDiscount(shouldOfferDiscount)
    }, [activePSSubscription])

    useEffect(() => {
        if (!me) return

        const isNewSubscriber = Math.abs(moment(me.subscribed_date).diff(moment(), 'days')) <= 30
        const isNewUser = Math.abs(moment(me.signup_date).diff(moment(), 'days')) <= 90

        if (isNewSubscriber || isNewUser)
            setReasonOptions([
                ...cancellationReasonOptions.slice(0, 4),
                { value: 'more_time', label: 'Need more time to get setup?' },
                ...cancellationReasonOptions.slice(5)
            ])
    }, [me])

    const handleContinue = useCallback(() => {
        if (!reason) return
        const isReasonForDiscount = reason.value && ['price', 'covid19', 'break', 'more_time'].includes(reason.value)
        dispatch(push(offerDiscount && isReasonForDiscount ? 'discount' : 'confirm', { reason }))
    }, [dispatch, offerDiscount, reason])

    return (
        <div className="supportSettings">
            <div className="header">
                <PSButton className="left" onClick={() => history.goBack()}>
                    <LeftArrow />
                </PSButton>
                <h1 className="title">Let us know why</h1>
                <div className="right">
                    <PSButtonPrimary disabled={!reason} onClick={handleContinue}>
                        Continue
                    </PSButtonPrimary>
                </div>
            </div>
            <div className="separator" />
            <div className="form">
                <Field className="field">
                    <PSDropdown selected={reason}
                                nameProperty="label"
                                onSelect={(selection) => setReason(selection)}
                                label="Select a reason (required)"
                                placeholder={<div className="grey">Please select</div>}>
                        <>
                            {reasonOptions.map(option => (
                                <Item key={`refund-amount-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                </Field>
            </div>
        </div>
    )
}
