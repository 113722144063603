import moment from "moment";

export type AvailabilityInstance = {
}

export class Availability {
    duration: number;
    available: boolean;
    start: moment.Moment;
    end: moment.Moment;
    date: moment.Moment;

    constructor() {
        this.duration = 0
        this.available = false
        this.start = this.end = this.date = moment()
    }

    setData(data: any) {
        this.duration = Number(data.duration)
        this.available = data.available
        this.start = moment(data.start)
        this.end = moment(data.end)
        this.date = moment(data.date)
    }
}