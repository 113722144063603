import React, {useCallback, useEffect, useState} from "react"
import {AppHeader} from "../app/AppHeader";
import {PSButton, PSButtonPrimary} from "../app/PSButton";
import {useAppDispatch, usePSUser} from "../../hooks";
import { push } from "connected-react-router";
import { Field, Input, Label } from "@zendeskgarden/react-forms";
import { filterNumericInput } from "../../utils/numbers";
import { Datepicker } from "@zendeskgarden/react-datepickers";
import { OnboardingState, setGrowYourBusiness } from "../../modules/onboarding";
import { Result } from "../../utils";
import { Spinner } from "@zendeskgarden/react-loaders";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { LocationAutocomplete } from "../app/LocationAutocomplete";

export const GoLivePersonalInfo = () => {
    const dispatch = useAppDispatch()
    const state: OnboardingState = useSelector((state: RootState) => state.onboarding)

    const [saveEnabled, setSaveEnabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [birthday, setBirthday] = useState<Date | undefined>(state.growYourBusiness.birthday)
    const [ssnLast4, setSsnLast4] = useState<string | undefined>(state.growYourBusiness.ssnLast4)
    const [address, setAddress] = useState<string | undefined>(state.growYourBusiness.address)
    const [legalName, setLegalName] = useState<string | undefined>(state.growYourBusiness.legalName)

    const user = usePSUser()
    const onNextHandler = useCallback(() => {
        if (!user) return

        setLoading(true)
        Result(dispatch(setGrowYourBusiness({
            ...state.growYourBusiness,
            address,
            birthday,
            legalName,
            ssnLast4
        })))
        .then(() => dispatch(push('/golive/photo-verification', { legalName, ssnLast4, address, birthday})))
        .finally(() => setLoading(false))


    }, [dispatch, user, address, state.growYourBusiness, birthday, legalName, ssnLast4])

    const onSkipHandler = useCallback(() =>
        dispatch(push('/golive/receive-payouts'))
    , [dispatch])

    useEffect(() => {
        setSaveEnabled(!!legalName && ssnLast4?.length === 4 && !!address && !loading)
    }, [legalName, ssnLast4, address, loading])

    if (loading) {
        return (
            <div className="servicesSettings">
                <div className="header">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <AppHeader title="Grow your business" showBackButton middleWidget={null}>
            <div className="servicesSettings onboarding launchBusiness">
                <div className="header">
                    <h1>Tell us about your yourself</h1>
                    <div style={{marginLeft: "auto"}}>
                        <PSButton style={{height: '40px'}} onClick={onSkipHandler}>
                            Skip
                        </PSButton>
                        <PSButtonPrimary id="nextButton" style={{height: '40px', marginLeft: '10px'}} onClick={onNextHandler} disabled={!saveEnabled}>
                            Next
                        </PSButtonPrimary>
                    </div>
                </div>

                <div className="separator" />

                <div className="form">
                    <div className="text" style={{paddingBottom: 0}}>Once verified you’ll be able to cash out payments &amp; see those payments hit your account within 15 minutes. Birth date and last 4 digits of your SSN are used to verify identify, to ensure secure payout. This information is encrypted and will not impact your credit.  ⚡️</div>

                    <Field className='field'>
                        <Label className='label'>Full name (required)</Label>
                        <Input placeholder='Add your legal name'
                            value={legalName}
                            onChange={e => setLegalName(e.target.value)} />
                    </Field>

                    <Field className='field'>
                        <LocationAutocomplete
                            label="What's your address? (required)"
                            placeholder='Add your address'
                            currentAddress={address}
                            updateAddress={(address) => {
                                if (address || (address?.length ?? 0) === 1)
                                    setAddress(address)
                            }}
                        />
                    </Field>

                    <Field className="field">
                        <Label>Birthday (required)</Label>
                        <Datepicker value={birthday}
                                    onChange={date => setBirthday(date)}>
                            <Input placeholder="Select date" />
                        </Datepicker>
                    </Field>

                    <Field className='field'>
                        <Label className='label'>Social security number (required)</Label>
                        <Input placeholder='Add the last 4 digits'
                            value={ssnLast4}
                            onChange={e => filterNumericInput(e.target.value.substring(0, 4), setSsnLast4)} />
                    </Field>
                </div>
            </div>
        </AppHeader>
    )
}