import { Transaction } from "./Transaction";

export const MAX_HOURLY_DURATION = 840

export class Booking extends Transaction {
    id?: string;
    owner: string = '';
    vendor: string = '';
    client: string = '';
    employee: string = '';
    date: string = '';
    created_date?: string;
    last_modified_date?: string;
    paid_date?: string
    schedule_end_date?: string;
    schedule_created_date?: string;
    confirmed_date?: string;
    cancelled_date?: string;
    invoiced_date?: string;
    completed_date?: string;
    paid_deposit_date?: string;
    mark_paid_date?: string;
    captured_date?: string;
    signed_date?: string;
    a_package?: object;
    record?: string;
    contract?: string;
    package_cancelled_date?: string;
    job_cancelled_date?: string;
    job_paid_date?: string;
    sent_date?: string;
    duration: number = 0;
    job_duration: string = '';
    schedule_duration: string = '';
    source: string = '';
    location: string = '';
    latitude: string = '';
    longitude: string = '';
    paymeth: string = '';
    gratuity_type: string = '';
    gratuity: number = 0;
    item: string = '';
    type: string = '';
    memo: string = '';
    tax: number = 0;
    total: number = 0;
    asking_amount: number = 0;
    deposit?: number;
    complete_charge_amount?: number;
    number: number = 0;
    reminder: string = '';
    channel: string = '';
    status: string = '';
    event?: string;
    source_display?: string;

    constructor(data: Partial<Booking>) {
        super(data)
        Object.assign(this, data)
    }
}
