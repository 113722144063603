export type LineItemInstance = {
    id?: string,
    taxable: boolean,
    rate: number,
    discountable: boolean,
    quantity: number,
    date: string,
    type: string,
    total: number,
    item: string,
    category?: string,
    memo?: string,
    booking?: string,
    // Below are for internal usage only
    itemType?: string,
    bookingItemID?: string,
}

export class LineItem {
    id?: string;
    taxable: boolean = false;
    rate: number = 0;
    discountable: boolean = true;
    quantity: number = 1;
    date: string = '';
    type: string = '';
    total: number = 0;
    item: string = '';
    category?: string;
    memo?: string;
    booking?: string;
    // Below are for internal usage only
    itemType?: string;
    bookingItemID?: string;

    constructor(data: Partial<LineItemInstance>) {
        Object.assign(this, data)
    }
}