import React, {useCallback} from "react"
import {AppHeader} from "../app/AppHeader";
import {PSButtonPrimary} from "../app/PSButton";
import {useAppDispatch, usePSUser} from "../../hooks";
import {ReactComponent as Image} from "../../icons/conditionally_verified.svg"
import { nextGoLiveStep } from "../../utils/onboarding";

export const GoLiveConditionallyVerified = () => {
    const dispatch = useAppDispatch()
    const user = usePSUser()

    const onNextHandler = useCallback(() => {
        if (!user) return

        dispatch(nextGoLiveStep(user))
    }, [user, dispatch])

    return (
        <AppHeader title="Grow your business" middleWidget={null}>
            <div className="servicesSettings onboarding launchBusiness">
                <div className="header">
                    <h1>Conditionally verified!</h1>
                    <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}} onClick={onNextHandler}>
                        Next
                    </PSButtonPrimary>
                </div>

                <div className="separator" />

                <div className="form">
                    <div className="text">
                        Your profile has been conditionally verified and you’re ready to start collecting payments!
                        <br /><br />
                        You’ll receive a final notice within 2 business days and will be able to cash out payments once you’re fully verified.
                        <br /><br />
                        Tap Next and add accounts to deposit your funds.
                    </div>
                    <Image />
                </div>
            </div>
        </AppHeader>
    )
}