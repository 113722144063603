import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../hooks";
import {saveSource, SettingsState} from "../../../modules/settings";
import {RootState} from "../../../store";
import {useSelector} from "react-redux";
import {Spinner} from "@zendeskgarden/react-loaders";
import {Field, Input, IInputProps, Label, Message, Toggle} from "@zendeskgarden/react-forms";
import {Prompt, useHistory} from "react-router";
import {PSButton, PSButtonPrimary} from "../../app/PSButton";
import {Dots} from "@zendeskgarden/react-loaders";
import localforage from 'localforage'
import {VALIDATION} from "@zendeskgarden/react-forms/dist/typings/utils/validation";
import InputMask from 'react-input-mask'
import {CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {UserInstance} from "../../../models/User";
import {processError} from "../../../modules/error";
import {isValidPostalCode, Result} from  "../../../utils"
import {StripeCardCvcElementChangeEvent, StripeCardExpiryElementChangeEvent, StripeCardNumberElementChangeEvent} from "@stripe/stripe-js";
import {ReactComponent as LeftArrow} from "../../../icons/left-arrow.svg";

type Props = {
    clientID?: string,
    onBack?: () => void
}

export const CardEdit = ({clientID, onBack}: Props) => {
    const dispatch = useAppDispatch()
    const elements = useElements()
    const stripe = useStripe()

    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const history = useHistory()

    const [stripeReady, setStripeReady] = useState(false)

    const [user, setUser] = useState<UserInstance | undefined>()

    const [name, setName] = useState('')

    const [zipcode, setZipcode] = useState<string | undefined>()
    const [instantPayout, setInstantPayout] = useState(false)

    const [saveEnabled, setSaveEnabled] = useState(false)
    const [hasEdits] = useState(false)

    const [saving, setSaving] = useState(false)

    const [cardValidation, setCardValidation] = useState<VALIDATION | undefined>()
    const [expiryValidation, setExpiryValidation] = useState<VALIDATION | undefined>()
    const [cvcValidation, setCVCValidation] = useState<VALIDATION | undefined>()
    const [zipcodeValidation, setZipcodeValidation] = useState<VALIDATION | undefined>()
    const [nameValidation, setNameValidation] = useState<VALIDATION | undefined>()

    const [numberReady, setNumberReady] = useState(false)
    const [expiryReady, setExpiryReady] = useState(false)
    const [cvcReady, setCVCReady] = useState(false)

    const [cardError, setCardError] = useState('')
    const [expiryError, setExpiryError] = useState('')
    const [cvcError, setCVCError] = useState('')

    const [stripeOptions, setStripeOptions] = useState<any>()


    useEffect(() => {
        setSaveEnabled(stripeReady
                       && !zipcodeValidation && !cardValidation && !expiryValidation && !cvcValidation
                       && cvcReady && expiryReady && numberReady
                       && !!name?.length && (zipcode?.length ?? 0) >= 5)
    }, [stripeReady, zipcodeValidation, cardValidation, expiryValidation, cvcValidation, numberReady, expiryReady, cvcReady, name, zipcode])

    useEffect(() => {
        localforage.getItem('user').then((user: any) => setUser(user as UserInstance))

        setStripeOptions(
         {
            classes: {base: "stripeInput"},
            style: {
                base: {
                  color: '#2f3941',
                  fontWeight: '100',
                  fontFamily: "'DM Sans', sans-serif",
                  fontSize: '15px',
                  '::placeholder': {
                    color: '#999999',
                  },
                },
            }
        })
    }, [])

    useEffect(() => {
        setStripeReady(!!stripe && !!elements)
    }, [stripe, elements])

    useEffect(() => {
        if (zipcode) setZipcodeValidation(isValidPostalCode(zipcode ?? '') ? undefined : 'error')
    }, [zipcode, zipcodeValidation])

    useEffect(() => {
        if (nameValidation)
            setNameValidation(name ? undefined : 'error' )
    }, [name, nameValidation])

    const cardOnChangeHandler = (event: StripeCardNumberElementChangeEvent) => {
        setNumberReady(event.complete)
        setCardValidation(event.error ? 'error' : undefined)
        setCardError(event.error?.message ?? '')
    }

    const expiryOnChangeHandler = (event: StripeCardExpiryElementChangeEvent) => {
        setExpiryReady(event.complete)
        setExpiryValidation(event.error ? 'error' : undefined)
        setExpiryError(event.error?.message ?? '')
    }

    const cvcOnChangeHandler = (event: StripeCardCvcElementChangeEvent) =>{
        setCVCReady(event.complete)
        setCVCValidation(event.error ? 'error' : undefined)
        setCVCError(event.error?.message ?? '')
    }

    const onSave = () => {
        if (saving) return

        let validationError = false

        if (!isValidPostalCode(zipcode ?? '')) {
            setZipcodeValidation('error')
            validationError = true
        }

        if (!name) {
            setNameValidation('error')
            validationError = true
        }

        if (!stripe || !elements || validationError) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        setSaving(true)

        const cardElement = elements!.getElement(CardNumberElement);
        if (cardElement) {
            stripe.createToken(cardElement, {name: name,
                                             address_zip: zipcode,
                                             address_country: 'US',
                                             currency: 'USD'})
                .then(({error, token}) => {
                    if (error) {
                        console.log('[error]', error)
                        dispatch(processError(new Error(error.message || 'Error adding card')))
                        setSaving(false)
                    }
                    else if (token) {
                        const sourceParams: any = {}
                        sourceParams.token = token.id
                        if (clientID) {
                            sourceParams.owner = clientID
                            sourceParams.allow_deposit = false
                            sourceParams.card_scanned = false
                            sourceParams.allow_payment = false
                            sourceParams.type = 'credit'
                            sourceParams.name = name
                        }
                        else {
                            sourceParams.type = 'card'
                            sourceParams.allow_deposit = instantPayout
                        }

                        Result(dispatch(saveSource(sourceParams)))
                            .then(() => {
                                if (onBack) {
                                    onBack()
                                }
                                else {
                                    history.goBack()
                                }
                                setSaving(false)
                            })
                    }
                    else {
                        //Should never happen
                    }
                })
        }
    }

    if (state.isSending || !user || !stripe || !elements) {
        return (
            <div className={clientID ? 'chargeAddCard': 'servicesSettings'}>
                <div className="header">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={clientID ? 'chargeAddCard servicesSettings scheduleClient': 'servicesSettings'}>
            {!stripeReady && (
                <div className="loadingOverlay">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                        <h1>Loading...</h1>
                    </div>
                </div>
            )}
            <div className="header">
                {onBack && (
                    <PSButton style={{marginLeft: 0, marginRight: 16}} onClick={onBack}>
                        <LeftArrow />
                    </PSButton>
                )}
                <h1>Add a payment card</h1>
                <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!saveEnabled || state.isSending}>
                    {saving && <Dots />}
                    {!saving && "Save"}
                </PSButtonPrimary>
            </div>
            <div className="separator" />
            <div className="form">
                <Field className="field">
                    <Label className="label">Card #</Label>
                    <CardNumberElement
                        options={stripeOptions}
                        onChange={cardOnChangeHandler}
                    />
                    {!!cardValidation && (
                            <Message validation={cardValidation}>{cardError}</Message>
                    )}
                </Field>
                <Field className="field">
                    <Label className="label">Expiration date</Label>
                    <CardExpiryElement
                        options={stripeOptions}
                        onChange={expiryOnChangeHandler}
                    />
                    {!!expiryValidation && (
                            <Message validation={expiryValidation}>{expiryError}</Message>
                    )}
                </Field>
                <Field className="field">
                    <Label className="label">CVC</Label>
                    <CardCvcElement
                        options={stripeOptions}
                        onChange={cvcOnChangeHandler}
                    />
                    {!!cvcValidation && (
                            <Message validation={cvcValidation}>{cvcError}</Message>
                    )}
                </Field>
                <Field className="field">
                    <Label className="label">Name on card</Label>
                    <Input placeholder="John Doe"
                               value={name}
                               disabled={state.isSending}
                               onChange={e => setName(e.target.value)} />
                    {!!nameValidation && (
                            <Message validation={nameValidation}>A name is required</Message>
                    )}
                </Field>
                <Field className="field">
                    <Label className="label">Zipcode</Label>
                    <InputMask mask="99999"
                                   value={zipcode}
                                   alwaysShowMask={false}
                                   maskChar={''}
                                   disabled={state.isSending}
                                   onChange={e => setZipcode(e.target.value)}>
                                   {(inputProps: IInputProps) => <Input {...inputProps}
                                       className="mobileNumberInput"
                                       placeholder="90210"
                                       validation={zipcodeValidation}
                                       />}
                        </InputMask>
                        {!!zipcodeValidation && (
                            <Message validation={zipcodeValidation}>That does not appear to be a valid zipcode.</Message>
                        )}
                </Field>
                {!clientID && (
                    <Field className="field">
                        <Toggle checked={instantPayout}
                                disabled={state.isSending || saving}
                                onChange={e => {setInstantPayout(e.target.checked)}}>
                            <Label className="label">
                                Accept instant payouts
                            </Label>
                        </Toggle>
                    </Field>
                )}
                {clientID && (
                    <p className="clientNote">This card will be securely stored in PocketSuite, allowing you to make authorized charges to this client in the future.</p>
                )}
            </div>
            <Prompt
                when={hasEdits && !saving}
                message="Are you sure you'd like to leave this page without saving your changes?" />
        </div>
    )
}
