import React, {useEffect, useState} from "react";
import {AppHeader} from "../../app/AppHeader";
import {PSButton, PSButtonPrimary} from "../../app/PSButton";
import {
    useAppDispatch,
    usePSOwner,
    usePSTotalDisplay,
    usePSUser,
    useTaxCodeLookup
} from "../../../hooks";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {useLocation, useParams} from "react-router";
import {PSFormFieldSelection} from "../../app/PSFormFieldSelection";
import {ReactComponent as ClientIcon} from "../../../icons/ps_general_person_nobg.svg";
import {UserInstance} from "../../../models/User";
import {SelectModal} from "../SelectModal";
import {TotalInstance, TotalModal} from "../TotalModal";
import {ReactComponent as TotalIcon} from "../../../icons/ps_general_invoice_nobg.svg";
import {fetchAttachments, SettingsState} from "../../../modules/settings";
import {PSDropdown} from "../../app/PSDropdown";
import {Item} from "@zendeskgarden/react-dropdowns";
import {Field, Label} from "@zendeskgarden/react-forms";
import {PSTextarea} from "../../app/PSTextarea";
import {
    bookingSendViaOptions, bookingStatusOptions, invoiceCategoryOptions, estimateReminderOptions, SelectOption
} from "../../settings/Options";
import {push} from "connected-react-router";
import {ChargeLineItemProps} from "../charge/ChargeLineItemNew";
import {LineItem} from "../../../models/LineItem";
import {currencyFormat} from "../../../utils/numbers";
import {apiClient} from "../../../modules/apiclient";
import keyBy from "lodash.keyby";
import {TransactionProgress} from "../TransactionProgress";
import {Spinner} from "@zendeskgarden/react-loaders";
import {processError} from "../../../modules/error";
import {
    Header,
    Modal,
    Body,
    Footer,
    FooterItem,
    Close
} from "@zendeskgarden/react-modals";
import {b64EncodeUnicode} from "../../../utils/strings";
import {getRecord} from "../../../modules/records";
import { Estimate } from "../../../models/Estimate";
import moment from "moment";

export const EstimateEdit = () => {
    const dispatch = useAppDispatch()
    const state: {settings: SettingsState, contacts: any, entities: any, records: any} = useSelector((state: RootState) => ({
        settings: state.settings,
        contacts: state.contacts,
        entities: state.entities,
        records: state.records,
    }))

    const params = useParams<{recordID?: string}>()
    // Loading for an edit
    useEffect(() => {
        if (!params.recordID) return
        dispatch(getRecord('estimate', params.recordID))
    }, [dispatch, params.recordID])

    const routerLocation = useLocation()
    const locationState: any = routerLocation.state
    const estimateData: Estimate = locationState?.estimate ?? locationState?.payment

    useEffect(() => {
        const data = params.recordID ?
            (estimateData?.id === params.recordID ? estimateData : state.records.data) : estimateData

        // Start fetching data to render what exists
        const promises = []
        if (Object.keys(state.entities.items).length === 0) {
            const p1 = new Promise<void>((resolve, reject) => {
                apiClient.post('/item/sync')
                    .then(resp => resp.data)
                    .then(json => {
                        const itemMap = keyBy(json.items, 'id')
                        dispatch({type: 'entities/UPDATE', name: 'items', map: itemMap})
                        resolve()
                    })
                    .catch(reject)
            })
            promises.push(p1)
        }

        if (data?.client && !state.entities.users[data.client]) {
            const p2 = new Promise<void>((resolve, reject) => {
                apiClient.post('/client/sync', {person: data.client})
                    .then(resp => resp.data)
                    .then(json => {
                        if (json.contacts?.length > 0) {
                            const user = json.contacts[0]
                            const userMap = {
                                [user.id]: user
                            }
                            dispatch({type: 'entities/UPDATE', name: 'users', map: userMap})
                        }
                        resolve()
                    })
                    .catch(reject)
            })
            promises.push(p2)
        }

        Promise.all(promises)
            .catch(error => {
                processError(error)
                return Promise.reject()
            })

    }, [dispatch, estimateData, state.entities.items, state.entities.users, params.recordID, state.records.data])

    // Load existing data
    const [loadExistingData, setLoadExistingData] = useState(false)
    useEffect(() => {
        if ((!estimateData && (!params.recordID || !state.records.data.id)) || loadExistingData) {
            return
        }

        setLoadExistingData(true)

        const data = params.recordID ?
            (estimateData?.id === params.recordID ? estimateData : state.records.data) : estimateData

        setClient(state.entities.users[data.client])
        setTotal(data.total)

        const lines: LineItem[] = data.lines.map((i: any) => new LineItem(i))

        const itemList = lines.filter(i => i.type === 'item')
        setItems(itemList)
        const expenseList = lines.filter(i => i.type === 'expense')
        setExpenses(expenseList)

        setTotalData({
            deposit: data.deposit,
            discountRate: Number(data.discount),
            discountType: data.discount_type === 'percent' ? 'percent' : data.discount_type,
            surchargeEnabled: data.allow_surcharge,
            tax: data.tax,
            taxcode: data.taxcode,
            taxLocation: data.taxLocation || data.location,
            nexus: data.nexus,
            total: Number(data.total),
            giftCertificate: state.entities.gifts[data.gift]
        })

        const channel = bookingSendViaOptions.find(s => s.value === data.channel)
        setSendVia(channel || bookingSendViaOptions[2])

        const reminder = estimateReminderOptions.find(s => s.value === data.reminder.toString())
        setReminder(reminder || estimateReminderOptions[0])

        setMemo(data.memo || '')

        const form = state.settings.forms.find(f => f.id === data.form)
        setForm(form)

        const contract = state.settings.contracts.find(c => c.id === data.contract)
        setContract(contract)

        const status = bookingStatusOptions.find(option => option.value === data.confirm_status)
        setStatus(status)
        // Clear state so when we reload it is a fresh form
        window?.history?.replaceState(null, '')

        setLoadExistingData(false)
    }, [
        estimateData, loadExistingData, state.entities.users, state.entities.gifts,
        state.settings.forms, state.settings.contracts, state.records.data,
        params.recordID,
    ])

    // Form state
    const [client, setClient] = useState<UserInstance>(locationState?.client)
    const [memo, setMemo] = useState('')
    const [total, setTotal] = useState(0)
    const [items, setItems] = useState<LineItem[]>([])
    const [itemTotal, setItemTotal] = useState(0)
    const [expenses, setExpenses] = useState<LineItem[]>([])
    const [expenseTotal, setExpenseTotal] = useState(0)
    const [sendVia, setSendVia] = useState(bookingSendViaOptions[2])
    const [reminder, setReminder] = useState(estimateReminderOptions[0])
    const [form, setForm] = useState<any>('')
    const [contract, setContract] = useState<any>('')
    const [status, setStatus] = useState<SelectOption>()
    const [previewEnabled, setPreviewEnabled] = useState(false)
    const [nextEnabled, setNextEnabled] = useState(false)

    const [totalData, setTotalData] = useState<TotalInstance>()
    const totalDisplay = usePSTotalDisplay(totalData, total > 0 ? currencyFormat(total.toString()) : '')

    const [selectModalType, setSelectModalType] = useState<'clients' | 'items' | 'expenses' | 'appointments'>()
    const [showTotalModal, setShowTotalModal] = useState(false)

    const [showPreviewModal, setShowPreviewModal] = useState(false)
    const [isPreviewLoading, setIsPreviewLoading] = useState(false)
    const [pdfPreviewData, setPDFPreviewData] = useState('')

    const user = usePSUser()
    const owner = usePSOwner()
    const proTaxCode = useTaxCodeLookup(owner)
    useEffect(() => {
        // Don't set default totalData if this is an edit
        if (params.recordID) return

        setTotalData({
            deposit: totalData?.deposit || 0,
            discountRate: totalData?.discountRate || 0,
            discountType: totalData?.discountType ?? '',
            giftCertificate: totalData?.giftCertificate,
            nexus: totalData?.nexus || 'pro',
            surchargeEnabled: owner?.allow_surcharge ?? false,
            tax: totalData?.tax || Number(proTaxCode?.rate) || 0,
            taxcode: totalData?.taxcode || proTaxCode?.id || '',
            taxLocation: totalData?.taxLocation || '',
            total: totalData?.total || 0,
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.recordID, user, proTaxCode]) // Ignore totalData

    useEffect(() => {
        setNextEnabled(!!client)
        setPreviewEnabled(!!client)
    }, [client])

    const [giftCertificates, setGiftCertificates] = useState<any[]>([])
    useEffect(() => {
        if (!client) return

        apiClient.post('/estimate/sync', {type: 'client', client: client.id})
            .then(resp => resp.data)
            .then(json => {
                if (json.gifts) setGiftCertificates(json.gifts)
            })
            .catch((error) => {
                processError(error)
                return Promise.reject()
            })

    }, [client])

    // Fetch the user's contracts and forms
    useEffect(() => {
        dispatch(fetchAttachments())
    }, [dispatch])

    // Update totals for the itemization fields
    useEffect(() => {
        const sum = items.reduce((sum, cur) => sum + Number(cur.total), 0)
        setItemTotal(sum)
    }, [items])
    useEffect(() => {
        const sum = expenses.reduce((sum, cur) => sum + Number(cur.total), 0)
        setExpenseTotal(sum)
    }, [expenses])

    useEffect(() => {
        const itemized = items.length > 0 || expenses.length > 0
        if (itemized) {
            const total = itemTotal + expenseTotal
            setTotal(total)
        }
    }, [items, expenses, itemTotal, expenseTotal])

    const buildEstimateObject = () => {
        const estimate = new Estimate({
            owner: user?.id || '',
            vendor: user?.employer || user?.id || '',
            client: client?.id || '',
            total: totalData?.total || 0,
            rate: (totalData?.total ?? 0) + (totalData?.deposit ?? 0),
            lines: items.slice().concat(expenses),
            deposit: totalData?.deposit,
            date: moment().format('YYYY-MM-DD'),
            confirm_status: status?.value,
            status: status ? 'sentQuote' : 'confirmed',
            channel: sendVia.value,
            reminder: reminder.value,
            memo,
            allow_surcharge: totalData?.surchargeEnabled || false,
            tax: Number(totalData?.tax || 0),
            taxcode: totalData?.taxcode || '',
            nexus: totalData?.nexus || '',
            gift: totalData?.giftCertificate?.id || '',
        })

        if (totalData?.taxLocation) estimate.location = totalData.taxLocation
        if (params.recordID) estimate.id = params.recordID

        if (totalData?.discountType && totalData?.discountRate) {
            estimate.discount_type = totalData.discountType
            estimate.discount = totalData.discountRate.toString()
        }

        if (contract) estimate.contract = contract.id
        if (form) estimate.form = form.id
        estimate.total = estimate.calculateTotal()
        estimate.number = estimateData?.number
        return estimate
    }

    const onItemSelected = (item: any[]) => {
        setClient(item[0])
        setSelectModalType(undefined)
    }

    const onTotalUpdated = (d: TotalInstance) => {
        if (d.nexus === 'transaction') {
            if (!d.taxLocation)
                d.nexus = 'pro'
            else {
                // Lookup taxcode for the location
                apiClient.post(`/taxcode`, {location: d.taxLocation})
                    .then(resp => resp.data)
                    .then(json => {
                        if (json) {
                            setTotalData({
                                ...d,
                                taxcode: json.id || '',
                                tax: Number(json.rate) || 0,
                            } as TotalInstance)
                        }
                    })
            }
        }

        if (d.nexus === 'pro') {
            d.taxcode = proTaxCode?.id || ''
            d.tax = Number(proTaxCode?.rate) || 0
        }
        setTotalData(d)
        setShowTotalModal(false)
    }

    const onAddItemClicked = (lineItemType: 'item' | 'expense', editIndex?: number) => {
        const estimate = buildEstimateObject()

        let url = `/estimate/new/lineitem/${lineItemType}`
        if (typeof editIndex !== 'undefined')
            url += `/${editIndex}/edit`

        const chargeProps: ChargeLineItemProps = {
            showDescription: true,
            requireItem: true,
            pageTitle: 'Send estimate',
            returnURL: '/estimate/new',
            dataType: 'estimate',
        }

        dispatch(push(url, {data: estimate, props: chargeProps}))
    }

    const onPreviewHandler = () => {
        setIsPreviewLoading(true)
        setShowPreviewModal(true)

        const estimate = buildEstimateObject()

        apiClient.post('/estimate/print', estimate)
            .then(resp => setPDFPreviewData(resp.data))
            .catch(error => dispatch(processError(error)))
            .finally(() => setIsPreviewLoading(false))
    }

    const onNextHandler = () => {
        const estimate = buildEstimateObject()
        dispatch(push('/estimate/confirm', {estimate}))
    }

    return (
        <AppHeader title="Send estimate"
                   showBackButton
                   middleWidget={<TransactionProgress created secondStep="Preview "/>}
        >
            {loadExistingData && (
                <div className="loadingOverlay">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                        <h1>Loading invoice</h1>
                    </div>
                </div>
            )}
            <div className="sendInvoice transaction scheduleClient servicesSettings">
                <div className="header">
                    <h1>General</h1>
                    <PSButton style={{height: '40px', marginLeft: 'auto'}}
                              onClick={() => onPreviewHandler()}
                              disabled={!previewEnabled}
                    >
                        Preview
                    </PSButton>
                    <PSButtonPrimary style={{height: '40px', marginLeft: '16px'}}
                                     onClick={() => onNextHandler()}
                                     disabled={!nextEnabled}
                    >
                        Next
                    </PSButtonPrimary>
                </div>

                <div className="separator" />

                <div className="form">
                    <PSFormFieldSelection label="Client (required)"
                                          helperLabel="Select a client"
                                          icon={<ClientIcon />}
                                          selection={client}
                                          hideButton={!!params.recordID}
                                          onClick={() => setSelectModalType('clients')}
                    />

                    <PSFormFieldSelection label="Total"
                                          helperLabel="Add a total"
                                          linkText={totalDisplay === '' ? 'Add' : 'Edit'}
                                          icon={<TotalIcon />}
                                          selectedLabel={totalDisplay}
                                          onClick={() => setShowTotalModal(true)}
                    />

                    <PSDropdown selected={status}
                                nameProperty="label"
                                label="Status"
                                placeholder={<div className="grey">Please Select</div>}
                                onSelect={(selection) => setStatus(selection)}
                    >
                        <>
                            {bookingStatusOptions.map(option => (
                                <Item key={`booking-status-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                    {owner?.allow_itemize && (
                        <>
                            <div className="itemsWrapper">
                                <h2>Items ({currencyFormat(itemTotal)})</h2>
                                <PSButtonPrimary onClick={() => onAddItemClicked('item')}>
                                    Add
                                </PSButtonPrimary>
                            </div>

                            <div className="separator" />

                            {items.map((i, index) => {
                                const item = state.entities.items[i.item]
                                let itemName = item ? item.name : 'Custom amount'
                                if (i.quantity > 1)
                                    itemName += ` x${i.quantity}`
                                return (
                                    <div key={`line-item-${i.item}-${i.type}-${i.total}`}
                                         className="serviceCard"
                                    >
                                        <div className="details">
                                            <div className="name">{itemName}</div>
                                            <div className="rate">{currencyFormat(i.total)}</div>
                                        </div>
                                        <div className="edit">
                                            <div onClick={() => onAddItemClicked('item', index)}
                                                 className="buttonLink">
                                                Edit
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                            <div className="itemsWrapper">
                                <h2>Expenses ({currencyFormat(expenseTotal)})</h2>
                                <PSButtonPrimary onClick={() => onAddItemClicked('expense')}>
                                    Add
                                </PSButtonPrimary>
                            </div>

                            <div className="separator" />

                            {expenses.map((i, index) => {
                                const categoryOption = invoiceCategoryOptions.find(o => o.value === i.category)

                                return (
                                    <div key={`line-item-expense-${i.category}-${i.total}`}
                                         className="serviceCard"
                                    >
                                        <div className="details">
                                            <div className="name">{categoryOption?.label}</div>
                                            <div className="blue rate">{currencyFormat(i.total)}</div>
                                        </div>
                                        <div className="edit">
                                            <div onClick={() => onAddItemClicked('expense', index)}
                                                 className="buttonLink">
                                                Edit
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    )}

                    <h2>Notifications</h2>

                    <PSDropdown selected={sendVia}
                                nameProperty="label"
                                hint="Choose how you’d like your client to be sent this estimate"
                                label="Send via"
                                onSelect={(selection) => setSendVia(selection)}
                    >
                        <>
                            {bookingSendViaOptions.map(option => (
                                <Item key={`invoice-send-via-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>

                    <PSDropdown selected={reminder}
                                nameProperty="label"
                                hint="If an estimate remains unconfirmed, chose how often you'd like your client to be politely reminded until they respond."
                                label="Payment reminder"
                                onSelect={(selection) => setReminder(selection)}
                    >
                        <>
                            {estimateReminderOptions.map(option => (
                                <Item key={`estimate-reminder-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>

                    <h2>More options</h2>

                    <Field className="field">
                        <Label className="label">Memo</Label>
                        <PSTextarea placeholder="Add a memo"
                                    className="input"
                                    minRows={5}
                                    maxLength={5000}
                                    value={memo}
                                    onChange={value => setMemo(value)}
                        />
                    </Field>

                    {owner?.allow_record && !state.settings.loadingAttachments && state.settings.forms.length > 0 && (
                        <PSDropdown selected={form}
                                    nameProperty="name"
                                    onSelect={(selection) => setForm(selection)}
                                    label="Attach a form"
                                    placeholder={<div className="grey">Select form</div>}
                        >
                            <>
                                {state.settings.forms.map(option => (
                                    <Item key={`form-${option.id}`} value={option}>
                                        {option.name}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>
                    )}

                    {owner?.allow_contract && !state.settings.loadingAttachments && state.settings.contracts.length > 0 && (
                        <PSDropdown selected={contract}
                                    nameProperty="name"
                                    onSelect={(selection) => setContract(selection)}
                                    label="Attach a contract"
                                    placeholder={<div className="grey">Select contract</div>}
                        >
                            <>
                                {state.settings.contracts.map(option => (
                                    <Item key={`contract-${option.id}`} value={option}>
                                        {option.name}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>
                    )}
                </div>

                {selectModalType === 'clients' && (
                    <SelectModal type={selectModalType}
                                 multiSelect={false}
                                 currentSelections={client ? [client] : []}
                                 onSelect={onItemSelected}
                                 onCancel={() => setSelectModalType(undefined) }
                    />
                )}

                {showTotalModal && (
                    <TotalModal currentData={totalData}
                                taxCode={proTaxCode}
                                showDeposit
                                showGiftCertificates
                                giftCertificates={giftCertificates}
                                totalReadOnly={!!items.length || !!expenses.length}
                                requireTaxLocation={true}
                                onDone={onTotalUpdated}
                                onCancel={() => setShowTotalModal(false)}
                    />
                )}

                {showPreviewModal && (
                    <Modal onClose={() => setShowPreviewModal(false)}>
                        <Header>Preview</Header>
                        <Body>
                            {isPreviewLoading && (
                                <div style={{width: '100%', height: '420px'}}>
                                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                                        <Spinner size="128" color="#314A68" />
                                    </div>
                                </div>
                            )}
                            {!isPreviewLoading && pdfPreviewData && (
                                <iframe style={{width: '100%', height: '420px'}}
                                        src={`data:application/pdf;base64,${b64EncodeUnicode(pdfPreviewData)}`}
                                        title={'Preview'}>
                                </iframe>
                            )}
                        </Body>
                        <Footer style={{paddingBottom: 32}}>
                            <FooterItem>
                            </FooterItem>
                        </Footer>
                        <Close aria-label="Close modal" />
                    </Modal>
                )}
            </div>
        </AppHeader>
    )
}