import React, {useEffect, useState} from "react";
import {
    Body, Close,
    Footer,
    FooterItem,
    Header,
    Modal
} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import {Payment} from "../../../models/Payment";
import {TipType} from "../../../models/Transaction";
import {PSDropdown} from "../../app/PSDropdown";
import {SelectOption} from "../../settings/Options";
import {Item} from "@zendeskgarden/react-dropdowns";
import {Field, Input, Label} from "@zendeskgarden/react-forms";
import {currencyFormat, filterMonetaryInput} from "../../../utils/numbers";
import { usePSOwner } from "../../../hooks";

type Props = {
    paymentData: Payment,
    currentData?: {rate: TipType, amount: number},
    onDone: (rate: TipType, amount: number) => void,
    onCancel: () => void,
}

const noTipOption: SelectOption = { label: "No tip", value: "0" }
const customAmountOption: SelectOption = { label: "Custom tip amount", value: "custom" }

export const GratuityModal = ({paymentData, currentData, onDone, onCancel}: Props) => {
    const [gratuity, setGratuity] = useState(noTipOption)
    const [gratuityOptions, setGratuityOptions] = useState([noTipOption, customAmountOption])
    const [customAmount, setCustomAmount] = useState('')
    const owner = usePSOwner();

    useEffect(() => {
        if (owner?.gratuity_options?.length) {
            const options = [noTipOption]
            owner.gratuity_options.forEach(option => {
                const tempPayment = new Payment(paymentData)
                tempPayment.tip = Number(option)
                options.push({
                    label: `${option}% (${currencyFormat(tempPayment.calculateTip())})`,
                    value: `${option}`
                })
            })
            options.push(customAmountOption)

            setGratuity(options[0])
            setGratuityOptions(options)
        }
    }, [owner, paymentData])

    // Load from currentData
    useEffect(() => {
        if (currentData?.rate === "percent") {
            const g = gratuityOptions.find(o => o.value === currentData.amount.toString())
            if (g) setGratuity(g)
        }
        else if (currentData?.rate === "rate") {
            const g = gratuityOptions.find(o => o.value === 'custom')
            if (g) setGratuity(g)
            setCustomAmount(currentData!.amount.toString())
        }
    }, [currentData, gratuityOptions])

    const [disabledInput, setDisabledInput] = useState(false)
    useEffect(() => {
        setDisabledInput(gratuity.value !== 'custom')
    }, [gratuity])

    const onDoneHandler = () => {
        let amount = 0
        let tipType: TipType = ''

        if (gratuity.value === 'custom') {
            amount = Number(customAmount)
            tipType = 'rate'
        }
        else if (gratuity.value !== 'none') {
            tipType = 'percent'
            amount = Number(gratuity.value)
        }

        onDone(tipType, amount)
    }

    return (
        <Modal className="salesTaxModal modal"
               onClose={() => onCancel()}
        >
            <Header className="header">
                <div className="title">Gratuity</div>
            </Header>
            <Body className="body form">
                <PSDropdown selected={gratuity}
                            nameProperty="label"
                            label="Gratuity amount"
                            onSelect={(selection) => setGratuity(selection)}
                >
                    <>
                        {gratuityOptions.map(option => (
                            <Item key={`gratuity-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>
                <Field className="field">
                    <Label className="label"
                           style={{color: disabledInput ? '#999999' : ''}}
                    >
                        Custom amount
                    </Label>
                    <Input placeholder="Enter custom tip amount"
                           disabled={disabledInput}
                           value={customAmount}
                           onChange={e => filterMonetaryInput(e.target.value, setCustomAmount)} />
                </Field>
            </Body>
            <Footer className="footer">
                <FooterItem>
                    <Button onClick={() => onCancel()}>Cancel</Button>
                </FooterItem>
                <FooterItem>
                    <Button isPrimary
                            onClick={() => onDoneHandler()}
                    >
                        Done
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )
}