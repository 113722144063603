import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { currencyFormat } from '../../../utils/numbers'
import { RecordStatusBar } from '../RecordStatusBar'
import { useDispatch, useSelector } from 'react-redux'
import { RecordDetail } from '../RecordDetail'
import { RecordBody } from '../RecordBody'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as ChevronIcon } from '../../../icons/chevron.svg'
import { PaymentDetailsModal } from '../PaymentDetailsModal'
import { ReactComponent as ColoredChevronIcon } from '../../../icons/colored_chevron.svg'
import { RootState } from '../../../store'
import { paymentStatus, Payment, getUserPayee } from '../../../models/Payment'
import { distinctButtons, RecordStatus } from '../../../utils/records'
import { ButtonProps, RecordButtons } from '../RecordButtons'
import { PSButtonPrimary } from '../../app/PSButton'
import { WEB_BASE } from '../../../modules/apiclient'
import { push, replace } from 'connected-react-router'
import { useConfirmModal, usePSUser } from '../../../hooks'
import { Result } from '../../../utils'
import { sendReceipt } from '../../../modules/transactions'
import { SuccessModal } from '../../settings/SuccessModal'
import { userDescription, UserInstance } from '../../../models/User'
import { Deposit } from '../../../models/Deposit'
import { refundReasonOptions } from '../../settings/Options'

export const RecordPayment = (props: any) => {
    const dispatch = useDispatch()
    const location = useLocation<{ refundSuccess: boolean }>().state
    const state = useSelector((state: RootState) => state.entities)
    const user = usePSUser()

    const [payment, setPayment] = useState<Payment>(new Payment(props.record))
    const [deposit, setDeposit] = useState<Deposit>(new Deposit(props.record?.deposit))
    const [statuses, setStatuses] = useState<RecordStatus[]>([])
    const [forVendor, setForVendor] = useState(true)
    const [forClient, setForClient] = useState(false)
    const [contact, setContact] = useState<Partial<UserInstance>>({})
    const [recordDetails, setRecordDetails] = useState<JSX.Element[]>([])
    const [showModal, setShowModal] = useState(false)
    const [showRefundSuccessModal, setShowRefundSuccessModal] = useState(false)

    useEffect(() => {
        if (props.record.id && props.record.id in state.payments)
            setPayment(new Payment(state.payments[props.record.id]))
    }, [props.record.id, state.payments])

    useEffect(() => {
        if (payment.deposit && payment.deposit in state.deposits)
            setDeposit(new Deposit(state.deposits[payment.deposit]))
    }, [payment.deposit, state.deposits])

    useEffect(() => {
        let recordStatuses = [
            {label: 'Paid', completed: !!payment.created_date},
            {label: 'Sent', completed: !!payment.deposited_date},
            {label: 'Received', completed: !!deposit.paid_date},
        ]

        if ((!!payment.refunded_amount && payment.refunded_amount > 0) || !!payment.refund_reason)
            recordStatuses.push({label: 'Refunded', completed: payment.refunded_amount! > 0})

        setStatuses(recordStatuses)
    }, [payment.created_date, payment.deposited_date, payment.refunded_amount, payment.refund_reason, deposit.paid_date])

    useEffect(() => {
        if (!user?.id) return
        if (!!payment.vendor) setForVendor(user.id === payment.vendor)
        if (!!payment.client) setForClient(user.id === payment.client)
    }, [user?.id, payment.vendor, payment.client])

    useEffect(() => {
        if (!user?.id) return

        const person = forClient ? getUserPayee(payment, user.id): payment.client
        if (person && person in state.contacts)
            setContact(state.contacts[person])
    }, [user?.id, forClient, payment, state.contacts])

    useEffect(() => {
        let recordDetailElements = []

        if (contact.id) {
            const details = (
                <Link to={`/record/client/${contact.id}`}>
                    {contact.name}
                    <ChevronIcon className="chevron" />
                </Link>
            )
            recordDetailElements.push(
                <RecordDetail header={forVendor ? 'Client' : 'Payee'}
                              details={details}
                              subtitle={forVendor ? paymentStatus(payment) : userDescription(contact)}/>
            )
        }

        if (payment.refnum) {
            recordDetailElements.push(
                <RecordDetail header="Refnum" details={payment.refnum} />
            )
        }

        const vendorHasPayee = !!payment.vendor && payment.vendor in state.contacts && !!state.contacts[payment.vendor].payee
        if (user?.type === 'company' && vendorHasPayee && !payment.deposited_date) {
            const details = `Payout at ${moment(payment.available_on_date).format('MMM D, h:mm a')}`
            recordDetailElements.push(
                <RecordDetail header="Payout" details={details} />
            )
        }

        if (payment.memo) {
            recordDetailElements.push(
                <RecordDetail header="Memo" details={payment.memo} isText />
            )
        }

        if (forVendor) {
            recordDetailElements.push(
                <RecordDetail header="Payment method" details={payment.source_display} />
            )
        }

        if (forVendor && payment.refund_reason) {
            const reason = refundReasonOptions.find(option => option.value === payment.refund_reason)

            if (!!reason) {
                recordDetailElements.push(
                    <RecordDetail header="Refund reason" details={reason.label} />
                )
            }
        }

        if (forVendor && payment.refund_details) {
            recordDetailElements.push(
                <RecordDetail header="Refund details" details={payment.refund_details} isText />
            )
        }

        setRecordDetails(recordDetailElements)
    }, [contact, forVendor, payment, user?.type, state.contacts])

    useEffect(() => setShowRefundSuccessModal(location?.refundSuccess), [location?.refundSuccess])

    const [setCtaModalInfo, setCtaModalLoading, showCtaModal, closeCtaModal, ctaComponent] = useConfirmModal()

    const printReceipt = useCallback(() =>
        window.open(`${WEB_BASE}/view/${payment.id}/download`, "_blank"), [payment.id])

    const showSendReceipt = useCallback(() => {
        if (!payment?.id) return

        setCtaModalInfo({
            title: "Send receipt",
            body: "Would you like to send the client a receipt?",
            button: "Send receipt",
            onClick: () => {
                setCtaModalLoading(true)
                Result(dispatch(sendReceipt(payment.id!)))
                    .then(() => {
                        closeCtaModal()
                        dispatch(replace(`/messages/${payment.client}`))
                    })
            }
        })
        showCtaModal()
    }, [dispatch, payment.id, payment.client, setCtaModalInfo, setCtaModalLoading, closeCtaModal, showCtaModal])

    const sendRefund = useCallback(() =>
        dispatch(push(`/refund/payment/${payment.id}`, { record: payment })), [dispatch, payment])

    const viewSource = useCallback(() => {
        if (!!payment.booking)
            dispatch(push(`/record/booking/${payment.booking}`))
        else if (!!payment.estimate)
            dispatch(push(`/record/estimate/${payment.estimate}`))
        else if (!!payment.package)
            dispatch(push(`/record/package/${payment.package}`))
        else if (!!payment.gift)
            dispatch(push(`/record/gift/${payment.gift}`))
        else if (!!payment.invoice)
            dispatch(push(`/record/invoice/${payment.invoice}`))
        else if (!!payment.subscription)
            dispatch(push(`/record/subscription/${payment.subscription}`))
    }, [dispatch, payment.booking, payment.estimate, payment.package, payment.gift, payment.invoice, payment.subscription])

    const getButtons = useCallback(() => {
        const buttons: ButtonProps[] = []

        buttons.push({ text: "Receipt", onClick: showSendReceipt })

        if (forVendor && !payment.cancelled_date && !payment.refund_reason)
            buttons.push({ text: "Refund", onClick: sendRefund })

        if (!!payment.booking || !!payment.estimate || !!payment.package || !!payment.gift || !!payment.subscription || !!payment.invoice)
            buttons.push({ text: "Source", onClick: viewSource })

        return distinctButtons(buttons)
    }, [forVendor, payment, showSendReceipt, sendRefund, viewSource])

    const buttons = getButtons()

    return (
        <div className="recordView">
            <div className="topBar">
                <RecordStatusBar statuses={statuses} />
                <div className="actions">
                    <PSButtonPrimary onClick={printReceipt}>
                        Print
                    </PSButtonPrimary>
                    <RecordButtons buttons={buttons} />
                </div>
            </div>
            <div className="recordHeader">
                <h2 className="payment" onClick={e => setShowModal(true)}>
                    {`${currencyFormat(payment.amount)} payment`}
                    <ColoredChevronIcon fill="#62CE9A" />
                </h2>
            </div>
            <RecordBody recordDetails={recordDetails} />
            <PaymentDetailsModal visible={showModal}
                                 onClose={() => setShowModal(false)}
                                 type="payment"
                                 record={payment} />
            {ctaComponent}
            {showRefundSuccessModal &&
                <SuccessModal
                    title="Refund request processed"
                    body="The cardholder will see the refund amount applied to the account that was used to make the payment within 7 to 10 business days. Funds to make this payment will be deducted from incoming payments and/or your bank account."
                    buttonText="Done"
                    onAction={() => setShowRefundSuccessModal(false)}
                    onClose={() => setShowRefundSuccessModal(false)}
                />
            }
        </div>
    )
}