import {push} from "connected-react-router";
import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory, useLocation, useParams} from "react-router";
import {useAppDispatch} from "../../hooks";
import {isEmpty, Result} from "../../utils";
import {syncClient} from "../../modules/contacts";
import {RootState} from "../../store";
import {AppHeader} from "../app/AppHeader";
import {PaymentMethodsListComponent} from "../settings/payment-methods/PaymentMethodsListComponent";
import '../contacts/contacts.css'
import { getRecord } from "../../modules/records";
import { Spinner } from "@zendeskgarden/react-loaders";
import { changeBookingSource, changeSubscriptionSource } from "../../modules/transactions";

export const RecordChangeCard = () => {
    const history = useHistory()
    const location: any = useLocation().state
    const dispatch = useAppDispatch()
    const state = useSelector((state: RootState) => {
        return {
            contacts: state.contacts,
            records: state.records
        }
    })

    const params = useParams<{recordType: string, recordID: string}>()
    const [record, setRecord] = useState(location?.record)
    useEffect(() => {
        if (!isEmpty(record)) return

        dispatch(getRecord(params.recordType, params.recordID))
    }, [record, params, dispatch])

    useEffect(() => setRecord(state.records?.data), [state.records?.data])

    const [isLoading, setIsLoading] = useState(isEmpty(record))
    useEffect(() => setIsLoading(isEmpty(record)), [record])

    const [sources, setSources] = useState<Array<any>>()
    useEffect(() => {
        if (!isEmpty(state.contacts.sources)) {
            setSources(state.contacts.sources)
        }
    }, [state.contacts.sources])

    useEffect(() => {
        dispatch(syncClient(record.client))
    }, [record.client, dispatch])

    const addPaymentCard = () => dispatch(push(`/client/${record.client}/add-card`));

    const changeSource = useCallback((sourceId: string) => {
        if (params.recordType === 'booking') {
            return dispatch(changeBookingSource(record.id, sourceId))
        }
        if (params.recordType === 'subscription') {
            return dispatch(changeSubscriptionSource(record.id, sourceId))
        }
    }, [dispatch, params.recordType, record.id])

    const onSelectItemClick = useCallback((source: any) => {
        setRecord((current: any) => {
            return {
                ...current,
                source: source.id
            }
        })

        setIsLoading(true)
        Result(changeSource(source.id))
            .then(() => history.goBack())
            .finally(() => setIsLoading(false))

    }, [changeSource, history])

    const title = {
        'booking': 'Appointment',
        'subscription': 'Subscription'
    }[params.recordType]

    return (
        <AppHeader middleWidget={null}
                   showBackButton
                   title={title}>
            <div className="pageWrapper">
                {isLoading ?
                    <Spinner size='128' color="#314A68" /> :
                    <PaymentMethodsListComponent
                            title="Select card"
                            subtitle="Add a payment method for this client to charge them"
                            selected={record.source ??  ''}
                            sources={sources!}
                            loading={state.contacts.isSending}
                            onSelectItemClick={onSelectItemClick}
                            addPaymentCard={addPaymentCard} />
                }
            </div>
        </AppHeader>
    )
}