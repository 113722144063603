import React, {useCallback, useEffect, useState} from "react";
import {AppHeader} from "../../app/AppHeader";
import {ReactComponent as ClientIcon} from "../../../icons/ps_general_person_nobg.svg";
import {ReactComponent as ProIcon} from "../../../icons/ps_general_client_nobg.svg";
import {ReactComponent as ServiceIcon} from "../../../icons/ps_general_services_nobg.svg";
import {ReactComponent as ProductIcon} from "../../../icons/ps_settings_packages_nobg.svg";
import {ReactComponent as DateIcon} from "../../../icons/ps_general_calendar_nobg.svg";
import {ReactComponent as TotalIcon} from "../../../icons/ps_general_invoice_nobg.svg";
import {ReactComponent as MoreDetailsIcon} from "../../../icons/ps_settings_online_leads_nobg.svg";
import {PSFormFieldSelection} from "../../app/PSFormFieldSelection";
import {
    useAppDispatch,
    usePSOwner,
    usePSUser,
    useTaxCodeLookup
} from "../../../hooks";
import {
    fetchAttachments,
    fetchCredentials,
    fetchCustomFields
} from "../../../modules/settings";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {Checkbox, Field, Hint, Label, Toggle} from "@zendeskgarden/react-forms";
import {PSDropdown} from "../../app/PSDropdown";
import {
    bookingDOWMonthlyOptions,
    bookingRebookOptions,
    bookingReminderOptions,
    bookingSendViaOptions,
    bookingStatusOptions,
    daysOfTheWeekOptions,
    lessonCountOptions,
    recurringScheduleFrequencyOptions,
    SelectOption,
    videoConferenceOptions
} from "../../settings/Options";
import {Item} from "@zendeskgarden/react-dropdowns";
import {PSTextarea} from "../../app/PSTextarea";
import {useLocation, useParams} from "react-router";
import {SelectModal} from "../SelectModal";
import {Item as PSItem, ServiceComponent} from "../../../models/Item";
import moment from "moment";
import {ScheduleDateModal} from "./ScheduleDateModal";
import "../transactions.css";
import {TotalInstance, TotalModal} from "../TotalModal";
import {apiClient} from "../../../modules/apiclient";
import {PSButtonPrimary} from "../../app/PSButton";
import {LocationAutocomplete} from "../../app/LocationAutocomplete";
import {CustomFieldsModal} from "../CustomFieldsModal";
import {TransactionProgress} from "../TransactionProgress";
import {push} from "connected-react-router";
import {LineItemInstance} from "../../../models/LineItem";
import {RecurringScheduleModal} from "../RecurringScheduleModal";
import {Availability} from "../../../models/Availability";
import keyBy from "lodash.keyby";
import {fetchPros} from "../../../modules/transactions";
import {getRecord} from "../../../modules/records";
import {Field as PSField} from "../../../models/Field";
import {Spinner} from "@zendeskgarden/react-loaders";
import { isEmpty } from "lodash";
import { ScheduleDateViewModal } from "./ScheduleDateViewModal";
import { processError } from "../../../modules/error";
import { currencyFormat } from "../../../utils/numbers";
import {dateFormat} from "../../../utils/dates";

export const ScheduleEdit = () => {
    const dispatch = useAppDispatch()
    const state: Pick<RootState, "settings" | "contacts" | "entities" | "records"> = useSelector((state: RootState) => {
        return {
            settings: state.settings,
            contacts: state.contacts,
            entities: state.entities,
            records: state.records,
        }
    })

    const params = useParams<{recordID?: string}>()
    // Loading for an edit
    useEffect(() => {
        if (!params.recordID) return
        dispatch(getRecord('booking', params.recordID))
    }, [dispatch, params.recordID])

    const syncLineItems = useCallback(() =>
        apiClient.post('/item/sync')
            .then(resp => resp.data)
            .then(json => {
                const itemMap = keyBy(json.items, 'id')
                dispatch({type: 'entities/UPDATE', name: 'items', map: itemMap})
            })
            .catch((error) => {
                processError(error)
                return Promise.reject()
            })
    , [dispatch])

    const routerLocation = useLocation()
    const locationState: any = routerLocation.state
    const scheduleData: any = locationState?.schedule

    /**
     * set custom field data on this page from a record (client or booking) TODO: refactor to use Booking, RecordFactory
     * @param data: any client or booking data used to initialize custom fields
     * @param fromClient: boolean true if this is coming from a client (only apply fields if also stored on client)
     * @param fields: PSField[] these are all the user custom fields
     */
    const setCustomFieldsFromData = useCallback((data: any, fromClient: boolean, fields: PSField[]) => {
        const fieldValues: any = {}
        Object.keys(data?.field_values || []).forEach((fieldName: string) => {
            const field = fields.find((f: PSField) => f.name === fieldName)
            if (!field || (fromClient && !(field.is_client && field.is_booking))) return
            const value = data.field_values[fieldName]
            if (Array.isArray(field.options) && field.options.length > 0)
                if (Array.isArray(value))
                    fieldValues[fieldName] = field.options.filter(o => value.indexOf(o.value) !== -1)
                else
                    fieldValues[fieldName] = field.options.find(o => o.value === value)
            else
                fieldValues[fieldName] = value
        })
        setCustomFields((lastCustomFields: any) => { return {...lastCustomFields, ...fieldValues }})
    }, [])

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        // Only for edit from booking ID or if scheduleData is present
        if (!scheduleData && !params.recordID) {
            setLoading(false)
            return
        }

        // If this is an edit, use state.records.data, unless the scheduleData
        // has an id that matches this edit.
        const data = params.recordID ?
            (scheduleData?.id === params.recordID ? scheduleData : state.records.data) : scheduleData

        setLoading(true)

        // Start fetching data to render what exists
        const promises = []
        promises.push(syncLineItems())

        if (data.client) {
            const p2 = new Promise<void>((resolve, reject) => {
                apiClient.post('/client/sync', {person: data.client})
                    .then(resp => resp.data)
                    .then(json => {
                        if (json.contacts?.length > 0) {
                            const user = json.contacts[0]
                            const userMap = {
                                [user.id]: user
                            }
                            dispatch({type: 'entities/UPDATE', name: 'users', map: userMap})
                        }
                        resolve()
                    })
                    .catch(reject)
            })
            promises.push(p2)
        }

        const p3 = new Promise<void>((resolve, reject) => {
            dispatch(fetchCustomFields(resolve))
        })
        promises.push(p3)

        const p4 = new Promise<void>((resolve, reject) => {
            dispatch(fetchPros(resolve))
        })
        promises.push(p4)

        Promise.all(promises).finally(() => setLoading(false))
    }, [dispatch, scheduleData, params.recordID, syncLineItems]) // Ignore state.records.data, as we do not care if that changes

    const lessonToApply: any = locationState?.lesson
    useEffect(() => {
        if (!lessonToApply?.id) return

        syncLineItems()
    }, [syncLineItems, lessonToApply?.id])
    useEffect(() => {
        if (!lessonToApply) {
            return
        }

        const lessonItem = locationState?.item
        setDate(moment(lessonToApply.date).toDate())
        const lessonDuration = Number(lessonToApply.duration)
        if (lessonDuration) setDuration(lessonDuration)

        setLocation(lessonItem.location)
        setDescription(lessonItem.memo)

        const form = state.settings.forms.find((f: any) => f.id === lessonItem.form)
        setForm(form)

        const contract = state.settings.contracts.find((c: any) => c.id === lessonItem.contract)
        setContract(contract)

        const services = [{
            ...lessonItem,
            itemType: 'class',
            item: lessonItem.id,
        }]

        setServices(services)
        setLineItems(services)

    }, [lessonToApply, locationState?.item, state.entities.gifts, state.settings.contracts, state.settings.forms])

    const packageToApply: any = locationState?.package
    const [components, setComponents] = useState<ServiceComponent[]>()
    useEffect(() => {
        if (!packageToApply?.id) return

        syncLineItems()
    }, [syncLineItems, packageToApply?.id])
    useEffect(() => {
        if (!packageToApply) {
            return
        }

        const packageItem = locationState?.item
        const packageDuration = Number(packageToApply.duration)
        if (packageDuration) setDuration(packageDuration)

        setLocation(packageItem.location)
        setDescription(packageItem.memo)

        const form = state.settings.forms.find((f: any) => f.id === packageItem.form)
        setForm(form)

        const contract = state.settings.contracts.find((c: any) => c.id === packageItem.contract)
        setContract(contract)

        const status = bookingStatusOptions.find(s => s.value === "")
        if (status) setStatus(status)

        setTotalData((currentVal) => {
            return {
                ...currentVal,
                discountRate: 100,
                discountType: 'percent'
            } as TotalInstance;
        })

        setComponents(packageItem.package_all ? [] : packageItem.components)
    }, [packageToApply, locationState?.item, state.entities.gifts, state.settings.contracts, state.settings.forms])

    const serviceLineItemInstance = useCallback((i: any, extraData: any) => {
        const newLineItem: LineItemInstance = {
            date: moment().format('YYYY-MM-DD'),
            discountable: true,
            item: i.id,
            quantity: 1,
            rate: i.rate,
            taxable: extraData[i.id] || false,
            total: i.rate,
            type: 'item',
            itemType: 'service',
        }
        const packageItem = locationState?.item
        if (packageToApply && !packageItem?.package_all) {
            newLineItem.discountable = !!components?.length && (components.find(c => c.component === i.id) !== undefined)
        }
        return newLineItem
    }, [components, locationState?.item, packageToApply])

    const productLineItemInstance = useCallback((i: any, extraData: any) => {
        const newLineItem: LineItemInstance = {
            date: moment().format('YYYY-MM-DD'),
            discountable: true,
            item: i.id,
            quantity: Number(extraData[i.id] || 1),
            rate: i.rate,
            taxable: false,
            total: i.rate * Number(extraData[i.id] || 1),
            type: 'item',
            itemType: 'product',
        }
        return newLineItem
    }, [])

    const applyLineItems = useCallback((lines: any[]) => {
        const lineItemsExtraData: any = {}
        // The API doesn't return itemType, so we do a 2nd filter
        const serviceList = lines
            .filter((lineItem: any) => lineItem.itemType === 'service' || lineItem.type === 'item')
            .map((lineItem: any) => {
                const i = state.entities.items[lineItem.item]
                if (i && i.type === 'service')
                    lineItemsExtraData[i.id] = lineItem.taxable
                return i
            })
            .filter((item: any) => item?.type === 'service')
        setServices(serviceList)

        const productList = lines
            .filter((lineItem: any) => lineItem.itemType === 'product' || lineItem.type === 'item')
            .map((lineItem: any) => {
                const i = state.entities.items[lineItem.item]
                if (i && i.type === 'product')
                    lineItemsExtraData[i.id] = Number(lineItem.quantity) || 1
                return i
            })
            .filter((item: any) => item?.type === 'product')
        setProducts(productList)
        setLineItemsExtraData(lineItemsExtraData)

        const serviceLineItems = serviceList.map(item => serviceLineItemInstance(item, lineItemsExtraData))
        const productLineItems = productList.map(item => productLineItemInstance(item, lineItemsExtraData))
        setLineItems([...serviceLineItems, ...productLineItems])

    }, [serviceLineItemInstance, productLineItemInstance, state.entities.items])

    const estimateToApply: any = locationState?.estimate
    useEffect(() => {
        if (!estimateToApply?.id) return

        syncLineItems()
    }, [syncLineItems, estimateToApply?.id])
    useEffect(() => {
            if (!estimateToApply) {
                return
            }

            setDate(moment(estimateToApply.date).toDate())

            const duration = Number(estimateToApply.duration)
            if (duration) setDuration(duration)

            setLocation(estimateToApply.location)
            setDescription(estimateToApply.memo)

            if (estimateToApply.allow_staffing) setPro([estimateToApply.employee])

            const status = bookingStatusOptions.find(s => s.value === "")
            if (status) setStatus(status)

            applyLineItems(estimateToApply.lines)

    }, [applyLineItems, estimateToApply])

    const recordToCopy: any = locationState?.record
    const user = usePSUser()
    const [zoomConnected, setZoomConnected] = useState(false)
    // Called when we have data that we are editing
    useEffect(() => {
        // Only allow this to execute if we have data and after all data is
        // loaded into state (by checking loading boolean)
        if ((!scheduleData && (!params.recordID || !state.records.data.id) && isEmpty(recordToCopy)) || loading) {
            return
        }

        // If this is an edit, use state.records.data, unless the scheduleData
        // has an id that matches this edit.
        const data = params.recordID ?
            (scheduleData?.id === params.recordID ? scheduleData : state.records.data) : recordToCopy ? recordToCopy : scheduleData

        const scheduleInstances = params.recordID ? state.records.data.schedule_instances : recordToCopy ? recordToCopy.schedule_instances : scheduleData.instances
        const scheduleDOW = (params.recordID ? state.records.data.schedule_dow : recordToCopy ? recordToCopy.schedule_dow : scheduleData.dow) ?? 0
        const scheduleWOM = (params.recordID ? state.records.data.schedule_wom : recordToCopy ? recordToCopy.schedule_wom : scheduleData.wom) ?? 0

        setClient(state.entities.users[data.client])
        const pros = data.jobs
            ?.map((p: {person: string}) => state.entities.users[p.person])
            .filter((p: any) => !!p)
        setPro(pros || [])

        if (data.lines) applyLineItems(data.lines)

        setDate(moment(data.date).toDate())
        setTotalData({
            deposit: data.deposit,
            discountRate: Number(data.discount),
            discountType: data.discount_type === 'percent' ? 'percent' : data.discount_type,
            surchargeEnabled: data.allow_surcharge,
            tax: data.tax,
            taxcode: data.taxcode,
            nexus: data.nexus,
            total: Number(data.rate) + Number(data.deposit),
            giftCertificate: state.entities.gifts[data.gift],
        })

        const status = bookingStatusOptions.find(s => s.value === data.confirm_status)
        setStatus(status || bookingStatusOptions[0])

        const channel = bookingSendViaOptions.find(s => s.value === data.channel)
        setSendVia(channel || bookingSendViaOptions[0])

        const reminder = bookingReminderOptions.find(s => s.value === data.reminder)
        setReminder(reminder || bookingReminderOptions[0])

        const rebook = bookingRebookOptions.find(s => s.value === data.rebook)
        setRebook(rebook || '')

        setVideoConference(data.allow_conference)
        setVideoConferenceType(!!data.conference_type ? videoConferenceOptions(zoomConnected).find(option => option.value === data.conference_type) : undefined)

        setLocation(data.location)
        setDescription(data.memo)

        const form = state.settings.forms.find((f: any) => f.id === data.form)
        setForm(form)

        const contract = state.settings.contracts.find((c: any) => c.id === data.contract)
        setContract(contract)
        setCustomFieldsFromData(data, false, state.settings.fields);

        // Repeating schedule options
        const intervalValue = `${data.interval}${data.frequency === 'monthly' ? 'm' : ''}`
        const frequency = recurringScheduleFrequencyOptions.find(o => o.value === intervalValue)
        setInterval(frequency)
        const instance = lessonCountOptions.find(o => o.value === scheduleInstances?.toString())
        setInstances(instance)
        let dowList = []
        if (data.frequency === 'weekly') {
            dowList = daysOfTheWeekOptions.filter(o => Number(o.value) & data.dow)
            setWOM(undefined)
        }
        else {
            dowList = daysOfTheWeekOptions.filter(o => o.value === scheduleDOW.toString())
            const wom = bookingDOWMonthlyOptions[Math.log2(scheduleDOW || 1)]?.find(o => o.value === scheduleWOM.toString())
            setWOM(wom)
        }
        setDOW(dowList)
        setRepeat(dowList.length > 0)

        setDuration(data.duration)
        const dateValues = data.date_values?.map((startTime: string) => {
            const availability = new Availability()
            availability.duration = data.duration
            availability.available = true
            availability.start = availability.end = availability.date = moment(startTime)
            return availability
        })
        setAvailability(dateValues || [])

        // Clear the location state so if we reload, it is a fresh form
        if (locationState?.schedule) {
            window?.history?.replaceState(null, '')
        }
    }, [
        state.entities.users, state.entities.items, state.entities.payees, state.settings.forms, state.settings.contracts,
        state.settings.fields, loading, scheduleData, state.records.data, zoomConnected, params.recordID,
        state.entities.gifts, recordToCopy, locationState?.schedule, applyLineItems, setCustomFieldsFromData
    ])

    const [client, setClient] = useState<any>(locationState?.client)
    const [pro, setPro] = useState<any[]>([])
    const [services, setServices] = useState<PSItem[]>([])
    const [serviceSalesTax, setServiceSalesTax] = useState<any>({})
    const [products, setProducts] = useState<any[]>([])
    const [lineItems, setLineItems] = useState<LineItemInstance[]>([])
    const [lineItemsExtraData, setLineItemsExtraData] = useState<any>({})
    const [giftCertificates, setGiftCertificates] = useState<any[]>([])
    const [date, setDate] = useState(moment().add(2, 'hours').minutes(0).seconds(0).toDate())
    const [availability, setAvailability] = useState<Availability[]>([])
    const [duration, setDuration] = useState(120)
    const [status, setStatus] = useState(bookingStatusOptions[0])
    const [repeat, setRepeat] = useState(false)
    const [instances, setInstances] = useState<SelectOption>()
    const [dow, setDOW] = useState<SelectOption[]>([])
    const [wom, setWOM] = useState<SelectOption>()
    const [interval, setInterval] = useState<SelectOption>()
    const [repeatDisplay, setRepeatDisplay] = useState('')
    const [sendVia, setSendVia] = useState(bookingSendViaOptions[2])
    const [reminder, setReminder] = useState(bookingReminderOptions[6])
    const [rebook, setRebook] = useState<SelectOption | ''>('')
    const [location, setLocation] = useState('')
    const [description, setDescription] = useState('')
    const [videoConference, setVideoConference] = useState(false)
    const [videoConferenceType, setVideoConferenceType] = useState<SelectOption>()
    const [customFields, setCustomFields] = useState<any>({})
    const [form, setForm] = useState<any>('')
    const [contract, setContract] = useState<any>('')
    const [nextEnabled, setNextEnabled] = useState(false)

    const [totalData, setTotalData] = useState<TotalInstance>()
    const [totalDisplay, setTotalDisplay] = useState<string | React.ReactNode>('')

    const calculateDiscountAmount = useCallback((total: number) => {
        let discountAmount = 0
        if (totalData?.discountType === 'percent')
            discountAmount = total * totalData?.discountRate / 100
        else if (totalData?.discountType === 'rate')
            discountAmount = totalData?.discountRate

        return discountAmount
    }, [totalData?.discountRate, totalData?.discountType])

    const setupFeeDisplay = useCallback(() => {
        let setupDisplay
        if (totalData?.setupFee) {
            setupDisplay = ` (setup fee ${currencyFormat(totalData.setupFee)})`
        }
        return setupDisplay
    }, [totalData?.setupFee])

    useEffect(() => {
        if (!totalData?.total) {
            setTotalDisplay('')
            return
        }
        let applicableTotalForDiscount = totalData.total
        if (lineItems?.length) {
            applicableTotalForDiscount = 0
            lineItems.forEach((lineItem) => {
                if (lineItem.discountable)
                    applicableTotalForDiscount += Number(lineItem.total)
            })
        }
        const discountAmount = calculateDiscountAmount(applicableTotalForDiscount)
        if (packageToApply && discountAmount !== totalData?.total) {
            setTotalDisplay((
                <div className="selected">{currencyFormat(totalData?.total - discountAmount)}{discountAmount !== 0 && (<>
                    · <span style={{textDecoration: 'line-through'}}>{currencyFormat(totalData?.total)}</span>
                </>)}{setupFeeDisplay()}{` · Prepaid (${locationState?.item?.name})`}</div>
            ))
        }
        else if (packageToApply) {
            setTotalDisplay(`Prepaid (${locationState?.item?.name})`)
        }
        else {
            setTotalDisplay((
                <div className="selected">{currencyFormat(totalData?.total - discountAmount)}{discountAmount !== 0 && (<>
                    · <span style={{textDecoration: 'line-through'}}>{currencyFormat(totalData?.total)}</span>
                </>)}{setupFeeDisplay()}</div>
            ))
        }

    }, [totalData?.total, calculateDiscountAmount, setupFeeDisplay, packageToApply, lineItems, locationState?.item?.name])

    const [showTeamMembers, setShowTeamMembers] = useState(false)
    const owner = usePSOwner()
    useEffect(() => {
        if (!user || !owner) {
            setShowTeamMembers(false)
            return
        }

        const hasPermission = (user.type === 'solo' || user.type === 'company') || (user.role === 'admin' || user.role === 'employee')
        const canSelectTeamMembers = owner.allow_staffing && hasPermission
        setShowTeamMembers(canSelectTeamMembers)

        // Set defaults from the owner, if new
        if (!params.recordID) {
            const reminder = bookingReminderOptions.find(s => s.value === String(owner.reminder))
            if (reminder) {
                setReminder(reminder)
            }
            const channel = bookingSendViaOptions.find(s => s.value === owner.channel)
            if (channel) {
                setSendVia(channel)
            }
        }
    }, [dispatch, owner, user])

    const [selectModalType, setSelectModalType] = useState<'pros' | 'clients' | 'services' | 'products' | undefined>()
    const [showSelectDateModal, setShowSelectDateModal] = useState(false)
    const [showTotalModal, setShowTotalModal] = useState(false)
    const [showCustomFieldsModal, setShowCustomFieldsModal] = useState(false)
    const [showRepeatModal, setShowRepeatModal] = useState(false)

    const taxCode = useTaxCodeLookup(owner)
    useEffect(() => {
        setTotalData((currentData) => {
            return {
                ...currentData,
                surchargeEnabled: owner?.allow_surcharge ?? false,
            } as TotalInstance
        })

        if (!params.recordID && user) {
            let userAddressParts: string[] = []
            if (user.location) {
                if (user.line1) userAddressParts.push(user.line1)
                if (user.line2) userAddressParts.push(user.line2)
            }
            userAddressParts.push(`${user.city}, ${user.state}`)
            setLocation(userAddressParts.join(' '))
        }
    }, [params.recordID, user, owner])

    useEffect(() => {
        // find first service with video conferencing enabled
        const service = services.find((service) => service.allow_conference)
        if (service) {
            const notZoomOrZoomConnected = service.conference_type !== "zoom" || zoomConnected
            setVideoConference(true)
            setVideoConferenceType(!!service.conference_type && notZoomOrZoomConnected ?
                videoConferenceOptions(zoomConnected).find(option => option.value === service.conference_type) :
                undefined
            )
        }
    }, [services, zoomConnected])

    // Fetch the user's contracts and forms and credentials
    useEffect(() => {
        dispatch(fetchAttachments())
        dispatch(fetchCustomFields())
        dispatch(fetchCredentials())
    }, [dispatch])

    useEffect(() => {
        if (state.settings.credentials.find((credential: any) => credential?.provider === 'zoom')) {
            setZoomConnected(true)
        }
    }, [state.settings.credentials])

    const [dateDescription, setDateDescription] = useState('')
    useEffect(() => {
        if (availability.length > 1)
            setDateDescription(`Custom schedule (${availability.length} dates)`)
        else if (date)
            setDateDescription(moment(date).format('MMM D, YYYY h:mm a'))
        else
            setDateDescription('')
    }, [date, availability])

    const [showCustomFields, setShowCustomFields] = useState(false)
    useEffect(() => {
        setShowCustomFields(state.settings.fields.length > 0)
    }, [state.settings.fields])
    const jsonCustomFields = JSON.stringify(customFields)
    const [customFieldDesc, setCustomFieldDesc] = useState('')
    useEffect(() => {
        const desc = Object.keys(customFields).filter(field => !!customFields[field]).map((name: any) => {
            const value = customFields[name];
            const field = state.entities.fields[name] || {}
            if (Array.isArray(value))
                return value.map((v: any) => v.label).join(', ')
            else if (field.type === 'date')
                return dateFormat(value, 'MMM D, yyyy')
            else if (field.type === 'select')
                return value.label
            else if (field.type === "image" || field.type === "document") {
                const fileInfo = client.field_values[name + "_result"]
                return fileInfo && fileInfo.name ? fileInfo.name : value
            }
            return value
        }).join(', ')
        setCustomFieldDesc(desc)
    }, [jsonCustomFields, customFields]) // JSON.stringify to do an easy deep comparison of the object

    useEffect(() => {
        if (!repeat || !interval || dow.length === 0) {
            setRepeatDisplay('')
            return
        }

        if (interval.value.endsWith('m') && wom) {
            setRepeatDisplay(`${wom.label} of ${interval.label.toLowerCase()}`)
        }
        else {
            const days = dow.map(o => o.label).join(', ')
            setRepeatDisplay(`${days} ${interval.label.toLowerCase()}`)
        }
    }, [dow, wom, interval, repeat])

    useEffect(() => {
        // autocomplete client location

        if (!client) return

        const data = state.records?.data
        const useClientLocation = (!user?.location || (data?.item && !!data?.item?.client_selects_location))
            && !data?.item?.location
            && !data?.event?.location

        if (useClientLocation) {
            const { line1, line2, city, state, address } = client
            const clientLocation = [line1, line2, city, state].filter(s => !!s).map(s => s.trim()).join(', ')
            setLocation(city || state ? address || clientLocation : '')
        }

        const instance = state.contacts.clients.find(o => o["person"] === client.id)
        setCustomFieldsFromData(instance, true, state.settings.fields)
    }, [client, state.records?.data, user?.location, state.contacts.clients, state.settings.fields, setCustomFieldsFromData])

    const updateBookingSync = (type: string, item: any[]) => {
        if (type === 'client') setClient(item[0])
        else if (type === 'item') setServices(item)

        const params: any = {
            vendor: user?.employer || user?.id,
            location: user?.tax_address,
            for_vendor: true,
            date: moment(date).toISOString(),
            duration: duration,
            type,
        }

        if (type === 'client') params.client = item[0].id

        if (services.length > 0) params.item = services[0].id
        else if (type === 'item') params.item = item[0].id

        apiClient.post('/booking/sync', params)
            .then(resp => resp.data)
            .then(json => {
                if (json.gifts)
                    setGiftCertificates(json.gifts)
            })
    }

    const selectedItemFromModal = (item: any[], extraData: any) => {
        switch (selectModalType) {
            case 'clients':
                updateBookingSync('client', item)
                break
            case 'pros':
                setPro(item)
                break
            case 'services': {
                updateBookingSync('item', item)
                let longestDuration = 0
                // Remove all existing services from lineItems, as we are replacing them
                let newForm = '', newContract = ''
                const updatedLineItems = lineItems.filter(i => i.itemType !== 'service')
                item.forEach((i: any) => {
                    if (!newForm && i.form) newForm = i.form
                    if (!newContract && i.contract) newContract = i.contract

                    if (Number(i.duration) > longestDuration) longestDuration = Number(i.duration)

                    updatedLineItems.push(serviceLineItemInstance(i, extraData))
                })
                setLineItems(updatedLineItems)
                setLineItemsExtraData(extraData)
                setDuration(longestDuration)

                if (item.length > 0) setDescription(item[0].memo)

                // Set form & contract if service has it and we do not have one already set
                if (newForm && !form) {
                    const form = state.settings.forms.find((f: any) => f.id === newForm)
                    setForm(form)
                }
                if (newContract && !contract) {
                    const contract = state.settings.contracts.find((c: any) => c.id === newContract)
                    setContract(contract)
                }

                break
            }
            case 'products':
                // Remove all existing products from lineItems, as we are replacing them
                const updatedLineItems = lineItems.filter(i => i.itemType !== 'product')
                setProducts(item)
                item.forEach((i: any) => {
                    updatedLineItems.push(productLineItemInstance(i, extraData))
                })
                setLineItems(updatedLineItems)
                setLineItemsExtraData(extraData)
                break
        }
        setSelectModalType(undefined)
    }

    const [showMultipleServices, setShowMultipleServices] = useState(false)
    useEffect(() => {
        setShowMultipleServices(selectModalType === 'services' && !!(owner?.allow_services))
    }, [selectModalType, owner])

    const onDateSelected = (date: moment.Moment, duration: number, availabilities: Availability[]) => {
        setDate(date.toDate())
        setDuration(duration)
        setAvailability(availabilities)
        setShowSelectDateModal(false)
    }

    const getCurrentSelectionByType = () => {
        switch (selectModalType) {
            case 'clients': return client ? [client] : []
            case 'pros': return pro || []
            case 'services': return services || []
            case 'products': return products || []
            default: return []
        }
    }

    // Show a "Add sales tax" for the service, only if the user does not have
    // multiple services enabled, has sales tax enabled, and there is 1 service
    // selected
    const [serviceSalesTaxComponent, setServiceSalesTaxComponent] = useState<React.ReactNode>()
    useEffect(() => {
        let component
        if (!owner?.allow_services && owner?.allow_salestax && services.length === 1) {
            component = (
                <Field>
                    <Toggle checked={serviceSalesTax}
                            onChange={e => setServiceSalesTax(e.target.checked)}
                    >
                        <Label style={{fontWeight: 400, height: '22px'}}>Add sales tax</Label>
                    </Toggle>
                </Field>
            )
        }
        setServiceSalesTaxComponent(component)
    }, [owner, services, serviceSalesTax])

    useEffect(() => {
        if (services.length === 0 && products.length === 0) return

        let total = 0, deposit = 0
        // Sum up the services and products
        services.forEach(service => {
            if (service.rate) total += Number(service.rate)
            const isPrepaidPackage = (components !== undefined && components.length === 0) ||
                components?.find(c => c.component === service.id) !== undefined
            if (!isPrepaidPackage && service.deposit) deposit += Number(service.deposit)
        })
        products.forEach(product => {
            const quantity = lineItemsExtraData[product.id] || 1
            if (product.rate) total += Number(product.rate * quantity)
        })

        // Update the total object
        setTotalData((currentData) => {
            return {
                ...currentData,
                total,
                deposit,
                setupFee: 0
            } as TotalInstance
        })

        if (deposit > 0)
            setStatus(bookingStatusOptions[2]) // confirmPayment

    }, [services, products, lineItemsExtraData])

    // Next button logic
    useEffect(() => {
        setNextEnabled(client && date)
    }, [client, date])

    const onNextHandler = () => {
        const scheduleData: any = {
            client: client?.id,
            vendor: user?.employer || user?.id,
            owner: user?.id,
            date: moment(date).toISOString(),
            confirm_status: status.value,
            status: status.value === '' ? 'confirmed' : 'sentQuote',
            channel: sendVia.value,
            reminder: reminder.value,
            duration,
            allow_conference: videoConference,
            conference_type: videoConferenceType?.value ?? '',
            form: form?.id || '',
            contract: contract?.id || '',
            deposit: totalData?.deposit || 0,
            discount: totalData?.discountRate || 0,
            discount_type: totalData?.discountType || '',
            allow_surcharge: totalData?.surchargeEnabled || false,
            tax: totalData?.tax || '',
            taxcode: totalData?.taxcode || '',
            nexus: totalData?.nexus || '',
            gift: totalData?.giftCertificate?.id || '',
            location,
            memo: description,
            lines: lineItems,
            jobs: pro.map((p: any) => {return {person: p.id}}) || [],
            instances: instances?.value || '',
            dow: dow.reduce((sum, o) => sum + Number(o.value), 0),
            date_values: availability.length > 1 ? availability.map(a => a.start.toISOString()) : []
        }
        if (lessonToApply?.waitlisted) {
            scheduleData.status = 'waitListed'
        }
        if (packageToApply?.id) {
            scheduleData.package = packageToApply.id
        }
        if (estimateToApply?.id) {
            scheduleData.estimate = estimateToApply.id
        }
        scheduleData.rate =  Math.max((totalData?.total || 0) - scheduleData.deposit, 0)
        if (rebook !== '') scheduleData.rebook = rebook.value
        if (interval?.value) {
            scheduleData.frequency = interval.value.endsWith('m') ? 'monthly' : 'weekly'
            scheduleData.interval = Number(interval.value[0])
            if (scheduleData.frequency === 'monthly')
                scheduleData.wom = wom?.value
        }

        scheduleData.field_values = {}
        Object.keys(customFields).forEach(fieldName => {
            let value = customFields[fieldName]
            if (typeof value === 'object') {
                if (Array.isArray(value))
                    value = value.map((v: any) => v.value)
                else
                    value = value.value
            }
            scheduleData.field_values[fieldName] = value
        })

        if (params.recordID) scheduleData.id = params.recordID

        dispatch(push('/schedule/new/confirm', {schedule: scheduleData, lesson: lessonToApply, package: packageToApply, item: locationState?.item}))
    }

    return (
        <AppHeader title="Schedule client" middleWidget={<TransactionProgress created />} showBackButton>
            {loading && (
                <div className="loadingOverlay">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                        <h1>Loading appointment</h1>
                    </div>
                </div>
            )}
            <div className="scheduleClient servicesSettings">
                <div className="header">
                    <h1>General</h1>
                    <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                     onClick={() => onNextHandler()}
                                     disabled={!nextEnabled}
                    >
                        Next
                    </PSButtonPrimary>
                </div>

                <div className="separator" />

                <div className="form">
                    <PSFormFieldSelection label="Client (required)"
                                          helperLabel="Select a client"
                                          icon={<ClientIcon />}
                                          selection={client}
                                          onClick={() => setSelectModalType('clients')}
                    />
                    {showTeamMembers && (
                        <PSFormFieldSelection label="Pros"
                                              helperLabel="Select a pro"
                                              icon={<ProIcon />}
                                              selection={pro.length ? {name: pro.map((p: any) => p.name).join(', ')} : undefined}
                                              onClick={() => setSelectModalType('pros')}
                        />
                    )}
                    <PSFormFieldSelection label={owner?.allow_services ? "Services" : "Service"}
                                          helperLabel="Select a service"
                                          icon={<ServiceIcon />}
                                          selection={services.length ? {name: services.map(i => i.name).join(', ')} : undefined}
                                          extraComponent={serviceSalesTaxComponent}
                                          onClick={() => setSelectModalType('services')}
                    />
                    {owner?.allow_product && (
                        <PSFormFieldSelection label="Products"
                                              helperLabel="Select a product"
                                              icon={<ProductIcon />}
                                              selection={products.length ? {name: products.map(i => i.name).join(', ')} : undefined}
                                              onClick={() => setSelectModalType('products')}
                        />
                    )}

                    <h2>Details</h2>

                    <PSFormFieldSelection label="Date (required)"
                                          helperLabel="Select a date"
                                          icon={<DateIcon />}
                                          selectedLabel={dateDescription}
                                          onClick={() => setShowSelectDateModal(true)}
                    />
                    <PSFormFieldSelection label="Total"
                                          helperLabel="Add a total"
                                          linkText={totalDisplay === '' ? 'Add' : 'Edit'}
                                          icon={<TotalIcon />}
                                          hideButton={!!packageToApply}
                                          selectedLabel={totalDisplay}
                                          onClick={() => setShowTotalModal(true)}
                    />

                    <PSDropdown selected={status}
                                nameProperty="label"
                                hint="Select the appointment confirmation status"
                                label="Status"
                                onSelect={(selection) => setStatus(selection)}
                    >
                        <>
                            {bookingStatusOptions.map(option => (
                                <Item key={`booking-status-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>

                    {owner?.allow_recurring && (
                        <Field className="field">
                            <Checkbox checked={repeat}
                                      onChange={(e) => {
                                          setShowRepeatModal(e.target.checked)
                                          setRepeat(e.target.checked)
                                          if (!e.target.checked)
                                              setRepeatDisplay('')
                                      }}>
                                <Label className="withHint">Repeat</Label>
                                {repeatDisplay && (
                                    <Hint style={{color: '#3C94A4'}}>{repeatDisplay}</Hint>
                                )}
                                {!repeatDisplay && (
                                    <Hint>Select whether this appointment repeats</Hint>
                                )}
                            </Checkbox>
                        </Field>
                    )}

                    <h2>Notifications</h2>

                    <PSDropdown selected={sendVia}
                                nameProperty="label"
                                hint="Choose how you'd like your client to be sent this appointment"
                                label="Send via"
                                onSelect={(selection) => setSendVia(selection)}
                    >
                        <>
                            {bookingSendViaOptions.map(option => (
                                <Item key={`booking-send-via-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>

                    <PSDropdown selected={reminder}
                                nameProperty="label"
                                hint="Choose when you'd like your client to receive a reminder ahead of this appointment"
                                label="Reminder"
                                onSelect={(selection) => setReminder(selection)}
                    >
                        <>
                            {bookingReminderOptions.map(option => (
                                <Item key={`booking-reminder-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>

                    <PSDropdown selected={rebook}
                                nameProperty="label"
                                hint="Send an automated follow-up to remind your client to rebook this appointment"
                                label="Follow-up reminder"
                                onSelect={(selection) => setRebook(selection)}
                                placeholder={<div className="grey">Select follow-up reminder</div>}
                    >
                        <>
                            {bookingRebookOptions.map(option => (
                                <Item key={`booking-rebook-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>

                    <h2>More options</h2>

                    {(user?.plan?.length || 0) > 0 && (
                        <PSDropdown selected={videoConferenceType}
                                    nameProperty="label"
                                    onSelect={(selection) => {
                                        setVideoConferenceType(selection || undefined)
                                        setVideoConference(!!selection.value)
                                    }}
                                    label="Add video conference"
                                    hint="Add a video conference link to this appointment"
                                    placeholder={<div className="grey">No Video Link</div>}>
                            <>
                                {videoConferenceOptions(zoomConnected).map(option => (
                                    <Item key={`conference-${option.value}`} value={option}>
                                        {option.label}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>
                    )}

                    <LocationAutocomplete
                        label="Location"
                        currentAddress={location}
                        updateAddress={(address) => {
                            if (address || (location?.length ?? 0) === 1)
                                setLocation(address)
                        }}
                    />

                    <Field className="field">
                        <Label className="label">Description</Label>
                        <PSTextarea placeholder="Enter a description"
                                    className="input"
                                    minRows={5}
                                    maxLength={1500}
                                    value={description || ''}
                                    onChange={value => setDescription(value)}/>
                    </Field>

                    {showCustomFields && (
                        <PSFormFieldSelection label="More details"
                                              helperLabel="Add more details"
                                              linkText="Add"
                                              selection={customFieldDesc ? {name: customFieldDesc} : undefined}
                                              icon={<MoreDetailsIcon />}
                                              onClick={() => setShowCustomFieldsModal(true)}
                        />
                    )}

                    {owner?.allow_record && !state.settings.loadingAttachments && state.settings.forms.length > 0 && (
                        <PSDropdown selected={form}
                                    nameProperty="name"
                                    onSelect={(selection) => setForm(selection)}
                                    label="Attach a form"
                                    placeholder={<div className="grey">Select form</div>}
                        >
                            <>
                                {state.settings.forms.map((option: any) => (
                                    <Item key={`form-${option.id}`} value={option}>
                                        {option.name}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>
                    )}

                    {owner?.allow_contract && !state.settings.loadingAttachments && state.settings.contracts.length > 0 && (
                        <PSDropdown selected={contract}
                                    nameProperty="name"
                                    onSelect={(selection) => setContract(selection)}
                                    label="Attach a contract"
                                    placeholder={<div className="grey">Select contract</div>}
                        >
                            <>
                                {state.settings.contracts.map((option: any) => (
                                    <Item key={`contract-${option.id}`} value={option}>
                                        {option.name}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>
                    )}
                </div>

                {selectModalType && (
                    <SelectModal type={selectModalType}
                                 multiSelect={showMultipleServices || selectModalType === 'products' || selectModalType === 'pros'}
                                 currentSelections={getCurrentSelectionByType()}
                                 currentExtraData={lineItemsExtraData}
                                 currentDate={date}
                                 currentDuration={duration}
                                 currentItem={services}
                                 prepaidItems={components?.map(c => c.component)}
                                 onSelect={(item: any[], extraData: any) => selectedItemFromModal(item, extraData) }
                                 onCancel={() => setSelectModalType(undefined) } />
                )}
                {showSelectDateModal && (
                    !!lessonToApply && !lessonToApply.dropin ?
                        <ScheduleDateViewModal lesson={lessonToApply} schedule={lessonToApply.schedule_instance} onClose={() => setShowSelectDateModal(false)} /> :
                        <ScheduleDateModal onClose={() => setShowSelectDateModal(false)}
                                           onDone={onDateSelected}
                                           allowRecurring={owner?.allow_recurring || false}
                                           currentDate={date}
                                           currentAvailabilities={availability}
                                           currentDuration={duration}
                                           currentItemID={services?.length > 0 ? services[0].id : undefined}
                                           currentLocation={location}
                                           currentEmployeeID={pro?.length > 0 ? pro[0].id : undefined}
                        />
                )}
                {showTotalModal && (
                    <TotalModal currentData={totalData}
                                taxCode={taxCode}
                                showDeposit
                                showGiftCertificates
                                giftCertificates={giftCertificates}
                                onDone={(d) => {
                                    if (d.nexus === 'transaction') {
                                        if (!location) {
                                            d.nexus = 'pro'
                                        }
                                        else {
                                            // Lookup taxcode for the location
                                            apiClient.post(`/taxcode`, {location})
                                                .then(resp => resp.data)
                                                .then(json => {
                                                    json = taxCode
                                                    if (json) {
                                                        setTotalData({
                                                            ...d,
                                                            taxcode: json.id || '',
                                                            tax: Number(json.rate) || 0,
                                                        } as TotalInstance)
                                                    }
                                                })
                                        }
                                    }

                                    // Could be changed to 'pro' by the above check, so not an else if statement
                                    if (d.nexus === 'pro') {
                                        d.taxcode = taxCode?.id || ''
                                        d.tax = Number(taxCode?.rate) || 0
                                    }

                                    setTotalData(d)
                                    setShowTotalModal(false)
                                }}
                                onCancel={() => setShowTotalModal(false)}
                    />
                )}
                {showCustomFieldsModal && (
                    <CustomFieldsModal onCancel={() => setShowCustomFieldsModal(false)}
                                       fields={state.settings.fields}
                                       currentAnswers={customFields}
                                       onDone={(answers) => {
                                           setCustomFields(answers)
                                           setShowCustomFieldsModal(false)
                                       }}
                    />
                )}
                {showRepeatModal && (
                    <RecurringScheduleModal instanceOptions={lessonCountOptions}
                                            onClose={() => {
                                                setRepeat(false)
                                                setShowRepeatModal(false)
                                            }}
                                            onDone={(frequency, dow, instance, wom) => {
                                                setInterval(frequency)
                                                setDOW(dow)
                                                setWOM(wom)
                                                setInstances(instance)
                                                setRepeat(true)
                                                setShowRepeatModal(false)
                                            }}
                    />
                )}
            </div>
        </AppHeader>
    )
}
