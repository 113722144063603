import moment from "moment";

export const classCapacity = (record: any, item: any, bookings: any[]): string => {
    if (record.cancelled_date)
        return `Canceled ${moment(record.cancelled_date).format('MMM D')}`
    if (record.completed_date)
        return `Completed ${moment(record.cancelled_date).format('MMM D')}`

    let numberOfBookings = record.number_of_bookings
    if (typeof numberOfBookings === 'undefined' && bookings) {
        // Calculate this on the fly
        numberOfBookings = Object.keys(bookings)
            .filter((k: any) => bookings[k].lesson === record.id).length
    }

    const remainingSeats = Math.max(record.capacity - numberOfBookings, 0)
    let capacityDescription
    if (remainingSeats === 0)
        capacityDescription = 'This class is full'
    else if (remainingSeats === 1)
        capacityDescription = '1 spot left'
    else
        capacityDescription = `${remainingSeats} spots left`

    if (moment(record.date).isBefore(moment()))
        return `Past - ${capacityDescription}`

    return capacityDescription
}

export const classCapacityDisplay = (record: any, item: any, bookings: any[]): string => {
    return `Capacity ${record.capacity} (${classCapacity(record, item, bookings)})`
}