import moment from "moment"

export const bookingStatus = (record: any) => {
    const dateFormat = (date: Date) => moment(date).format('MMM Do, yyyy')

    if (record.mark_paid_date && record.paymeth)
        return `Marked paid ${dateFormat(record.mark_paid_date)} - ${record.paymeth}`
    if (record.mark_paid_date)
        return `Marked paid ${dateFormat(record.mark_paid_date)}`
    if (record.paid_date)
        return `Paid ${dateFormat(record.paid_date)}`
    if (record.invoiced_date)
        return `Invoiced ${dateFormat(record.invoiced_date)}`
    if (record.cancelled_date)
        return `Canceled ${dateFormat(record.cancelled_date)}`
    if (record.checkin_date)
        return `Checked in ${dateFormat(record.checkin_date)}`
    if (record.completed_date)
        return `Completed ${dateFormat(record.completed_date)}`
    if (record.confirmed_date && record.paid_deposit_date)
        return `Confirmed ${dateFormat(record.confirmed_date)} with deposit`
    if (record.confirmed_date)
        return `Confirmed ${dateFormat(record.confirmed_date)}`
    if (record.status === 'confirmed')
        return 'Confirmed'
    if (record.status === 'sentQuote' && record.confirm_status === 'confirmPayment')
        return 'Requires credit card'
    if (record.status === 'sentQuote')
        return 'Requires confirmation'
    if (record.status === 'waitListed')
        return `Joined wait list ${dateFormat(record.created_date)}`
    if (record.is_online)
        return `Requested online ${dateFormat(record.created_date)}`
    return `Requested ${dateFormat(record.created_date)}`
}
