export type PopoverState = {
    visible: boolean;
    content?: JSX.Element ;
}

const initialState: PopoverState = {
    visible: false,
    content: undefined,
}

export default function errorReducer(state = initialState, action: any) {
    switch (action.type) {
        case 'popover/SHOW_POPOVER':
            return {
                ...state,
                visible: true,
                content: action.content
            }
        case 'popover/HIDE_POPOVER':
            return {
                ...state,
                visible: false,
                content: undefined
            }
        default:
            return state
    }
}

export const showPopover = (content: JSX.Element) => {
    return { type: 'popover/SHOW_POPOVER', content }
}

export const hidePopover = () => {
    return { type: 'popover/HIDE_POPOVER' }
}