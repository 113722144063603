export const splitName = (user) => {
    const nameParts = user.name.split(' ')
    let firstNamePart, lastNamePart
    if (nameParts.length > 1) {
        lastNamePart = nameParts[nameParts.length - 1]
        firstNamePart = nameParts.splice(0, nameParts.length - 1).join(' ')
    }
    else {
        firstNamePart = user.name
    }

    return [ firstNamePart, lastNamePart ]
}
