import store from '../store'
import { getUnreadCount } from '../modules/inbox'
import { getMessage } from '../modules/conversation'
import { registerDevice } from '../modules/apiclient'
import { setCompletedIntent, setFailedIntent, TransactionsState } from '../modules/transactions'
import { userLogout } from '../modules/login'

let websocketRetryCount = 0

const handleIncomingEvent = (wsMessage: { data: string }) => {
    console.log('Websocket message', wsMessage.data)
    const message = JSON.parse(wsMessage.data)
    const { data } = message

    if (data && data.type === 'message') {
        // Due to an issue where the server will send this notification to use before
        // the message is saved to the database, we will wait 250ms before fetching it
        setTimeout(() => {
            const { conversation, router } = store.getState() as { conversation: any, router: any }
            // Only show a notification and update unread count if the thread is not open
            if (!(router.location && router.location.pathname.startsWith("/messages") && conversation.currentThread === data.sender_id)) {
                store.dispatch(getUnreadCount())

                if (window.Notification && window.Notification.permission === 'granted' && message.alert) {
                    new Notification('New message', {body: message.alert, icon: '/logo192.png'})
                }
            }

            store.dispatch(getMessage(data.sender_id, data.id))
        }, 250)
    }
    else if (data && data.type === 'intent') {
        // The payment intent webhook is fired just before the charge is fully captured, delay a bit to ensure the charge is captured on stripes side
        setTimeout(() => {
            const { transactions } = store.getState() as  { transactions: TransactionsState }
            if (transactions.pendingIntent === data.intent && data.status === 'succeeded') {
                store.dispatch(setCompletedIntent(data.intent))
            }
            else if (transactions.pendingIntent === data.intent && data.status === 'payment_failed') {
                store.dispatch(setFailedIntent(data.intent))
            }
        }, 250)
    }
}

export const connectWebSocket = () => {
    const browserId = localStorage.getItem('id')
    const websocket = new WebSocket(`${process.env.REACT_APP_WSS_HOST}/websocket/desktop/${browserId}`)

    websocket.onopen = (e) => {
        websocketRetryCount = 0
        const userId = localStorage.getItem('userId')
        if (!userId) return
        console.log('Websocket opened')
        const browserAddress = `DESKTOP:WSS:${userId}:${browserId}`
        registerDevice(browserAddress)
    }

    websocket.onmessage = handleIncomingEvent

    websocket.onclose = (e) => {
        console.log('Websocket close', e)
        if (e && e.reason && e.reason.indexOf('authentication required') === 0) {
            store.dispatch(userLogout())
        }
        else if (websocketRetryCount < 15) {
            websocketRetryCount += 1
            // Retry with a delay that increases exponentially, up to 163 seconds
            setTimeout(connectWebSocket, (2**websocketRetryCount)*10)
        }
        else {
            // fatal error logout
            store.dispatch(userLogout())
        }
    }

    websocket.onerror = (e) => {
        console.log('Websocket error', e)
    }
}