import React from "react";
import {
    Body, Close,
    Footer,
    FooterItem,
    Header,
    Modal
} from "@zendeskgarden/react-modals";
import {ReactComponent as AlertIcon} from "../../icons/alert_triangle.svg";
import {Button} from "@zendeskgarden/react-buttons";

export const ACTION_BUTTON = 1
export const OPTIONAL_BUTTON = 2
type Props = {
    title: string,
    message: string | React.ReactNode,
    buttonText: string,
    extraButtonText?: string,
    onClose: () => void,
    onButtonClick: (buttonIx: typeof ACTION_BUTTON | typeof OPTIONAL_BUTTON) => void,
}

export const WarningModal = ({title, message, buttonText, extraButtonText, onClose, onButtonClick}: Props) => {
    return (
        <Modal onClose={onClose}>
            <Header style={{display: 'flex', color: '#F2994A'}}>
                <AlertIcon />
                <span style={{fontWeight: 700, paddingLeft: 8}}>{title}</span>
            </Header>
            <Body>
                {message}
            </Body>
            <Footer>
                <FooterItem>
                    {extraButtonText && (
                        <FooterItem>
                            <Button onClick={() => onButtonClick(OPTIONAL_BUTTON)}
                                    style={{
                                        marginRight: '20px',
                                        backgroundColor: '#ffffff',
                                        border: '1px solid #F2994A',
                                        color: '#F2994A'}}
                            >
                                {extraButtonText}
                            </Button>
                        </FooterItem>
                    )}
                    <Button onClick={() => onButtonClick(ACTION_BUTTON)}
                            style={{
                                backgroundColor: '#F2994A',
                                border: '1px solid #F2994A',
                                color: '#ffffff'}}
                    >
                        {buttonText}
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )
}