import React, {useEffect, useState} from "react";
import {useAppDispatch, usePSOwner} from "../../../hooks";
import {
    clearItem, deleteItem,
    fetchAttachments, fetchItem, saveItem,
    SettingsState
} from "../../../modules/settings";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {useLearnMore, usePhotoUpload} from "../hooks";
import {Prompt, useHistory, useParams} from "react-router";
import {currencyFloat, filterMonetaryInput} from "../../../utils/numbers";
import {
    createAvailabilityArray,
    Item as PSItem
} from "../../../models/Item";
import {PSButtonDanger, PSButtonPrimary} from "../../app/PSButton";
import {Dots, Spinner} from "@zendeskgarden/react-loaders";
import {
    Checkbox,
    Field,
    Hint,
    Input,
    Label,
} from "@zendeskgarden/react-forms";
import {PSDropdown} from "../../app/PSDropdown";
import {
    SelectOption,
    capacityOptions,
    discountOptions,
    daysOfTheWeekOptions, durationDayOptions, leadtimeOptions, timeOptions
} from "../Options";
import {Item} from "@zendeskgarden/react-dropdowns";
import {Body, Close, Header, Modal} from "@zendeskgarden/react-modals";
import {PSMultiselect} from "../../app/PSMultiselect";
import {Datepicker} from "@zendeskgarden/react-datepickers";
import moment from "moment";
import {ItemOption} from "../AvailableHoursModal";
import localforage from "localforage";
import {SettingsPhotoField} from "../SettingsPhotoField";
import {GuardrailModal} from "../GuardrailModal";
import {PSTextarea} from "../../app/PSTextarea";
import {LocationModal} from "../LocationModal";
import {updateItem} from "../../../modules/onboarding";

type Props = {
    forOnboarding?: boolean,
    refreshData?: boolean,
}

export const ReservationEdit = ({forOnboarding, refreshData}: Props) => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const history = useHistory()

    const [photo, setPhoto] = useState<undefined | string | File>('')
    const [image, setImage] = useState<undefined | string>('') // For editing
    const [name, setName] = useState('')
    const [price, setPrice] = useState('')
    const [deposit, setDeposit] = useState('')
    const [discount, setDiscount] = useState<any>('')
    const [salesTax, setSalesTax] = useState(false)
    const [description, setDescription] = useState('')
    const [capacity, setCapacity] = useState<SelectOption | string>('')
    const [fixedStartTime, setFixedStartTime] = useState(false)
    const [daysOfTheWeek, setDaysOfTheWeek] = useState<SelectOption[] | never[]>([])
    const [startDate, setStartDate] = useState<Date | undefined>()
    const [duration, setDuration] = useState<SelectOption | ''>('')
    const [checkIn, setCheckIn] = useState<SelectOption | ''>('')
    const [checkOut, setCheckOut] = useState<SelectOption | ''>('')
    const [leadTime, setLeadTime] = useState<SelectOption | ''>('')
    const [online, setOnline] = useState(true)
    const [isVirtual, setIsVirtual] = useState(false)
    const [clientSelectsLocation, setClientSelectsLocation] = useState(false)
    const [contract, setContract] = useState<any>('')
    const [form, setForm] = useState<any>('')
    const [saveEnabled, setSaveEnabled] = useState(false)
    const [formSending, setFormSending] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)

    const [addPhotoRef, selectPhoto, selectedPhoto] = usePhotoUpload({disabled: state.isSending, setPhoto})
    const [learnMoreVisible, learnMoreTitle, learnMoreBody, showLearnMore] = useLearnMore()
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    const [salesTaxEnabled, setSalesTaxEnabled] = useState(false)
    const [businessAvailability, setBusinessAvailability] = useState(0)
    const [onlineBookingEnabled, setOnlineBookingEnabled] = useState(false)
    const [discountEnabled, setDiscountEnabled] = useState(false)
    const [formsEnabled, setFormsEnabled] = useState(false)
    const [contractsEnabled, setContractsEnabled] = useState(false)
    const [scheduleHere, setScheduleHere] = useState(false)
    const owner = usePSOwner()
    useEffect(() => {
        if (!owner) return
        setSalesTaxEnabled(owner.allow_salestax)
        setBusinessAvailability(Number(owner.schedule))
        setOnlineBookingEnabled(owner.allow_widget)
        setDiscountEnabled(owner.allow_discount)
        setFormsEnabled(owner.allow_record)
        setContractsEnabled(owner.allow_contract)
        setScheduleHere(owner.location)
    }, [owner])

    // Location selection handling. When 'Another location' is selected, we show
    // the LocationModal to get the user to input the address.
    const [locationDetails, setLocationDetails] = useState('')
    const [showLocationModal, setShowLocationModal] = useState(false)
    const locationOptions = [
        { label: 'No location', value: 'none' },
    ]
    if (scheduleHere)
        locationOptions.push({ label: 'Business location', value: 'business' })
    locationOptions.push({ label: 'Client selects location', value: 'client' })
    if (locationDetails)
        locationOptions.push({ label: locationDetails, value: 'another' })
    else
        locationOptions.push({ label: 'Another location', value: 'another' })
    const [location, setLocation] = useState(locationOptions[1])

    const selectLocation = (selection: SelectOption) => {
        if (selection.value === 'another')
            setShowLocationModal(true)
        else
            setLocation(selection)
        setIsVirtual(selection.value === 'none')
        setClientSelectsLocation(selection.value === 'client')
    }

    const locationModalCompleted = (location: string) => {
        setLocationDetails(location)
        setLocation({label: location, value: 'another'})
        setShowLocationModal(false)
    }

    useEffect(() => {
        setSaveEnabled(!!name && !!capacity)
    }, [name, capacity])

    useEffect(() => {
        if (forOnboarding) {
            setHasEdits(false)
            return
        }

        let changed = 0
        if ((state.item.name || name) && name !== state.item.name)
            changed = 1
        else if ((state.item.rate || Number(price)) && Number(price) !== Number(state.item.rate))
            changed = 2
        else if ((state.item.memo || description) && description !== state.item.memo)
            changed = 3
        else if ((state.item.deposit || Number(deposit)) && Number(deposit) !== Number(state.item.deposit))
            changed = 4
        else if ((state.item.allow_salestax || salesTax) && salesTax !== state.item.allow_salestax)
            changed = 5
        else if ((state.item.discount || discount) && discount?.value !== state.item.discount)
            changed = 6
        else if ((state.item.capacity || capacity) && (capacity as SelectOption).value !== state.item.capacity.toString())
            changed = 7
        else if ((state.item.contract || contract) && contract.id !== state.item.contract)
            changed = 9
        else if ((state.item.form || form) && form.id !== state.item.form)
            changed = 10
        else if ((state.item.is_public || online) && online !== state.item.is_public)
            changed = 11
        else if ((state.item.leadtime || leadTime) && (leadTime as SelectOption).value !== state.item.leadtime)
            changed = 12
        else if ((state.item.start_date || (fixedStartTime && startDate)) && moment(startDate).utc().dayOfYear() !== moment(state.item.start_date).utc().dayOfYear())
            changed = 14
        else if (typeof photo === 'object')
            changed = 13
        else if ((state.item.location || location.value) && location.value !== 'none' && location.value !== state.item.location)
            changed = 14
        else if ((state.item.client_selects_location || clientSelectsLocation) && clientSelectsLocation !== state.item.client_selects_location)
            changed = 15
        // TODO availability, check in, check out
        setHasEdits(changed > 0)
    }, [
        state.item, name, price, deposit, description, discount, salesTax,
        online, capacity, contract, form, leadTime, fixedStartTime, startDate,
        photo, location, clientSelectsLocation, forOnboarding
    ])

    // Fetch the user's contracts and forms
    useEffect(() => {
        dispatch(fetchAttachments())
    }, [dispatch])

    // Load the reservation if this is an edit, otherwise treat it as a create
    const params: any = useParams()
    useEffect(() => {
        if (forOnboarding) return

        if (!params.reservationID) {
            dispatch(clearItem())
            return
        }
        dispatch(fetchItem('reservation', params.reservationID))
    }, [dispatch, params.reservationID, forOnboarding])

    // Update form's values after the reservation is loaded into state
    useEffect(() => {
        if (params.action !== 'copy')
            setName(state.item.name || '')
        setPhoto(state.item.image_uri || '')
        setImage(state.item.image || '')
        setPrice(state.item.rate ? currencyFloat(state.item.rate).toFixed(2) : '')
        setDeposit(state.item.deposit ? currencyFloat(state.item.deposit).toFixed(2) : '')

        const selectedDiscount = discountOptions.find((option) => option.value === state.item.discount)
        setDiscount(selectedDiscount || '')

        setSalesTax(state.item.allow_salestax)
        setDescription(state.item.memo || '')

        const selectedCapacity = capacityOptions.find(option => option.value === state.item.capacity?.toString())
        setCapacity(selectedCapacity || '')

        setFixedStartTime(!!state.item.start_date)

        const dow: SelectOption[] = []
        daysOfTheWeekOptions.forEach((option) => {
            if (Number(option.value) & state.item.schedule)
                dow.push(option)
        })
        setDaysOfTheWeek(dow)

        // startDate
        if (state.item.start_date)
            setStartDate(moment(state.item.start_date, 'YYYY-MM-DDTHH:mm:ss[Z]').toDate())
        else
            setStartDate(undefined)

        const selectedDuration = durationDayOptions.find((option) => option.value === state.item.duration?.toString())
        setDuration(selectedDuration || '')

        // Check-in
        const weekdays = createAvailabilityArray(state.item)
        // Find the first weekday that has a start value
        const startDays = weekdays.filter(weekday => !!weekday.start)
        if (startDays.length > 0) {
            const selectedCheckIn = timeOptions.find(option => option.value === (startDays[0].start as ItemOption).value.toString())
            setCheckIn(selectedCheckIn || '')
        }

        // Check-out
        const endDays = weekdays.filter(weekday => !!weekday.end)
        if (endDays.length > 0) {
            const selectedCheckOut = timeOptions.find(option => option.value === (endDays[0].end as ItemOption).value.toString())
            setCheckOut(selectedCheckOut || '')
        }

        const selectedLeadTime = leadtimeOptions.find((option) => option.value === state.item.leadtime)
        setLeadTime(selectedLeadTime || '')

        setOnline(state.item.is_public)

        setIsVirtual(state.item.is_virtual)
        setClientSelectsLocation(state.item.client_selects_location)

        // Set location
        if (state.item.is_virtual)
            setLocation({ label: 'No location', value: 'none' })
        else if (state.item.location) {
            setLocation({label: state.item.location, value: 'another'})
            setLocationDetails(state.item.location)
        }
        else if (state.item.client_selects_location)
            setLocation({label: 'Client selects location', value: 'client'})
        else if (scheduleHere)
            setLocation({label: 'Business location', value: 'business'})
        else
            setLocation({label: 'Client selects location', value: 'client'})

        const selectedContract = state.contracts.find((c) => c.id === state.item.contract)
        setContract(selectedContract || '')

        const selectedForm = state.forms.find((f) => f.id === state.item.form)
        setForm(selectedForm || '')
    }, [state.item, state.contracts, state.forms, params.action, scheduleHere])

    // Enforce that price must be greater or equal to deposit
    const priceValidator = () => {
        if (Number(deposit) > Number(price))
            setPrice(deposit)
    }

    const updateDaysOfTheWeek = (selection: any[]) => {
        const sameAsBusiness = selection.filter(s => s === "")
        if (sameAsBusiness.length === 1)
            setDaysOfTheWeek([])
        else
            setDaysOfTheWeek(selection)
    }

    const onSave = () => {
        if (formSending) return

        if (!name || !capacity) return

        const reservation = new PSItem('reservation')
        if (forOnboarding)
            reservation.setData(state.item)
        reservation.name = name
        reservation.image = image
        reservation.rate = price
        reservation.deposit = deposit
        reservation.memo = description
        reservation.allow_salestax = salesTax
        reservation.is_public = online
        reservation.is_virtual = isVirtual
        reservation.client_selects_location = clientSelectsLocation
        if (capacity)
            reservation.capacity = (capacity as SelectOption).value
        if (discount)
            reservation.discount = (discount as SelectOption).value
        if (location.value === 'another')
            reservation.location = locationDetails

        // Start and end date, including duration, check-in, check-out calculations
        if (fixedStartTime && startDate) {
            let checkInValue = '', checkOutValue = '', durationValue = ''
            if (checkIn) checkInValue = checkIn.value
            if (checkOut) checkOutValue = checkOut.value
            if (duration) durationValue = duration.value
            reservation.setReservationTimes(startDate, checkInValue, checkOutValue, durationValue)
        }
        else {
            if (daysOfTheWeek.length > 0) {
                daysOfTheWeek.forEach((dow) => {
                    reservation.setStartEndHoursForDay(Number(dow.value), checkIn, checkOut)
                })

                reservation.schedule = (daysOfTheWeek as SelectOption[])
                    .reduce((sum,o) => sum + Number(o.value), 0)
            }
            else {
                // Use businessAvailability as the days instead
                for (let i = 1; i <= 64; i++) {
                    if (businessAvailability & i)
                        reservation.setStartEndHoursForDay(i, checkIn, checkOut)
                }

                reservation.schedule = businessAvailability
            }
        }

        if (duration)
            reservation.duration = Number((duration as SelectOption).value)
        if (leadTime)
            reservation.leadtime = (leadTime as SelectOption).value
        if (form)
            reservation.form = form.id
        if (contract)
            reservation.contract = contract.id
        if (params.reservationID && params.action !== 'copy')
            reservation.id = params.reservationID

        if (forOnboarding) {
            reservation.id = state.item.id
            reservation.image = undefined
            dispatch(updateItem(reservation.toObject(), !!refreshData))
            history.go(-2)
            return
        }

        setFormSending(true)

        if (typeof photo === 'string') {
            dispatch(saveItem(reservation))
        }
        else {
            dispatch(saveItem(reservation, photo as File))
        }
    }

    const showAttachments = (contractsEnabled || formsEnabled) &&
        (state.loadingAttachments || state.contracts.length > 0 || state.forms.length > 0)

    const showDeleteButton = params.reservationID && params.action !== 'copy'

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Reservation</h1>
                {showDeleteButton && (
                    <PSButtonDanger style={{height: '40px'}}
                                    onClick={() => setShowDeleteConfirmation(true)}>
                        Delete reservation
                    </PSButtonDanger>
                )}
                <PSButtonPrimary style={{height: '40px', marginLeft: showDeleteButton ? '16px' : 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!saveEnabled || formSending}>
                    {formSending && <Dots />}
                    {!formSending && "Save"}
                </PSButtonPrimary>
            </div>

            <div className="form">
                <SettingsPhotoField photo={photo}
                                    selectPhoto={selectPhoto}
                                    selectedPhoto={selectedPhoto}
                                    addPhotoRef={addPhotoRef}
                                    title="Reservation" />

                <Field className="field">
                    <Label className="label">Name (required)</Label>
                    <Input placeholder="Reservation name"
                           value={name}
                           disabled={state.isSending}
                           onChange={e => setName(e.target.value)} />
                </Field>

                <Field className="field">
                    <Label className="label">Price · $</Label>
                    <Input placeholder="Reservation price"
                           value={price}
                           disabled={state.isSending}
                           onChange={e => filterMonetaryInput(e.target.value, setPrice)} />
                </Field>

                <Field className="field">
                    <Label className="label">Deposit · $</Label>
                    <Input placeholder="Reservation deposit"
                           value={deposit}
                           disabled={state.isSending}
                           onBlur={priceValidator}
                           onChange={e => filterMonetaryInput(e.target.value, setDeposit)} />
                </Field>

                {discountEnabled && (
                    <PSDropdown selected={discount}
                                nameProperty="label"
                                disabled={state.isSending}
                                onSelect={(selection) => setDiscount(selection)}
                                label="Discount rate"
                                placeholder={<div className="grey">Select discount rate</div>}>
                        <>
                            {discountOptions.map(option => (
                                <Item key={`discount-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                )}

                {salesTaxEnabled && (
                    <Field className="field">
                        <Checkbox checked={salesTax}
                                  disabled={state.isSending}
                                  onChange={e => setSalesTax(e.target.checked)}>
                            <Label className="withHint">Allow sales tax</Label>
                            <Hint>Automatically calculate & apply sales tax</Hint>
                        </Checkbox>
                    </Field>
                )}

                <Field className="field">
                    <Label className="label">Description</Label>
                    <PSTextarea placeholder="Reservation description"
                                className="input"
                                minRows={8}
                                maxLength={1500}
                                disabled={state.isSending}
                                value={description}
                                onChange={value => setDescription(value)}/>
                </Field>

                <h2>Scheduling</h2>

                <PSDropdown selected={capacity}
                            nameProperty="label"
                            disabled={state.isSending}
                            onSelect={(selection) => setCapacity(selection)}
                            label="Capacity (required)"
                            hint="Total number of available slots"
                            placeholder={<div className="grey">Select capacity</div>}>
                    <>
                        {capacityOptions.map(option => (
                            <Item key={`capacity-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>

                <PSDropdown selected={duration}
                            onSelect={(selection) => setDuration(selection)}
                            disabled={state.isSending}
                            nameProperty="label"
                            label="Duration"
                            hint='Select "TBD" if your check-in and check-out times are flexible'
                            placeholder={<div className="grey">Select duration</div>}>
                    {durationDayOptions.map(option => (
                        <Item key={`duration-${option.value}`} value={option}>
                            {option.label}
                        </Item>
                    ))}
                </PSDropdown>

                <PSDropdown selected={checkIn}
                            onSelect={(selection) => setCheckIn(selection)}
                            disabled={state.isSending}
                            nameProperty="label"
                            label="Check-in"
                            placeholder={<div className="grey">Select check-in time</div>}>
                {timeOptions.map(option => (
                        <Item key={`checkin-${option.value}`} value={option}>
                            {option.label}
                        </Item>
                    ))}
                </PSDropdown>

                <PSDropdown selected={checkOut}
                            onSelect={(selection) => setCheckOut(selection)}
                            disabled={state.isSending}
                            nameProperty="label"
                            label="Check-out"
                            placeholder={<div className="grey">Select check-out time</div>}>
                    {timeOptions.map(option => (
                        <Item key={`checkout-${option.value}`} value={option}>
                            {option.label}
                        </Item>
                    ))}
                </PSDropdown>

                <PSDropdown selected={leadTime}
                            onSelect={(selection) => setLeadTime(selection)}
                            disabled={state.isSending}
                            nameProperty="label"
                            label="Lead time"
                            hint="Minimum amount of time needed to prep for this service"
                            onLearnMore={() => showLearnMore('leadtime', true)}
                            placeholder={<div className="grey">Select lead time</div>}>
                    {leadtimeOptions.map(option => (
                        <Item key={`leadtime-${option.value}`} value={option}>
                            {option.label}
                        </Item>
                    ))}
                </PSDropdown>

                <Field className="field">
                    <Checkbox checked={fixedStartTime}
                              disabled={state.isSending}
                              onChange={(e) => { setFixedStartTime(e.target.checked)}}>
                        <Label className="withHint">This is an event</Label>
                        <Hint>Enable for one-time events like camps</Hint>
                    </Checkbox>
                </Field>

                {!fixedStartTime && (
                    <PSMultiselect selected={daysOfTheWeek}
                                   nameProperty="label"
                                   disabled={state.isSending}
                                   onSelect={(selection) => updateDaysOfTheWeek(selection)}
                                   label="Days of the week"
                                   placeholder="Same as business">
                        <>
                            {daysOfTheWeekOptions.map(option => (
                                <Item key={`dow-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSMultiselect>
                )}

                {fixedStartTime && (
                    <Field className="field">
                        <Label className="label">Start date</Label>
                        <Hint className="hint">Add a start date for this event</Hint>
                        <Datepicker value={startDate}
                                    minValue={moment().toDate()}
                                    onChange={date => setStartDate(date)}>
                            <Input placeholder="Select start date" />
                        </Datepicker>
                    </Field>
                )}

                {onlineBookingEnabled && (
                    <Field className="field">
                        <Checkbox checked={online}
                                  disabled={state.isSending}
                                  onChange={(e) => { setOnline(e.target.checked)}}>
                            <Label className="withHint">Show online</Label>
                            <Hint>Allow clients to make this reservation online</Hint>
                        </Checkbox>
                    </Field>
                )}

                <h2>Location</h2>

                <PSDropdown selected={location}
                            disabled={state.isSending}
                            nameProperty="label"
                            onSelect={(selection) => selectLocation(selection)}
                            label="Location">
                    <>
                        {locationOptions.map(option => (
                            <Item key={`location-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>

                {showAttachments && <h2>Attachments</h2>}

                {state.loadingAttachments && (
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                )}
                {contractsEnabled && !state.loadingAttachments && state.contracts.length > 0 && (
                    <PSDropdown selected={contract}
                                disabled={state.isSending}
                                nameProperty="name"
                                onSelect={(selection) => setContract(selection)}
                                label="Attach a contract"
                                hint="This contract will be signed when a client purchases this gift certificate"
                                onLearnMore={() => showLearnMore('contracts', true)}
                                placeholder={<div className="grey">Select contract</div>}>
                        <>
                            {state.contracts.map(option => (
                                <Item key={`contract-${option.id}`} value={option}>
                                    {option.name}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                )}
                {formsEnabled && !state.loadingAttachments && state.forms.length > 0 && (
                    <PSDropdown selected={form}
                                disabled={state.isSending}
                                nameProperty="name"
                                onSelect={(selection) => setForm(selection)}
                                label="Attach a form"
                                hint="This form will be completed when a client purchases this gift certificate"
                                onLearnMore={() => showLearnMore('forms', true)}
                                placeholder={<div className="grey">Select form</div>}>
                        <>
                            {state.forms.map(option => (
                                <Item key={`form-${option.id}`} value={option}>
                                    {option.name}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                )}
            </div>
            {learnMoreVisible && (
                <Modal onClose={() => showLearnMore('', false)}>
                    <Header>{learnMoreTitle}</Header>
                    <Body>{learnMoreBody}</Body>
                    <Close aria-label="Close modal" />
                </Modal>
            )}
            {showLocationModal && (
                <LocationModal onClose={() => setShowLocationModal(false)}
                               onDone={locationModalCompleted}
                               currentLocation={locationDetails} />
            )}
            {showDeleteConfirmation && (
                <GuardrailModal title="Are you sure?"
                                body="Are you sure you'd like to delete this item?"
                                buttonText="Delete"
                                onClose={() => setShowDeleteConfirmation(false)}
                                onAction={() => dispatch(deleteItem(params.reservationID, 'reservations'))}
                />
            )}
            <Prompt
                when={hasEdits && !formSending}
                message="Are you sure you'd like to leave this page without saving your changes?" />
        </div>
    )
}
