import React, {useEffect, useState} from "react";
import {useAppDispatch, usePSOwner} from "../../../hooks";
import {
    clearItem,
    deleteItem,
    fetchAttachments,
    fetchItem, saveItem,
    SettingsState
} from "../../../modules/settings";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {useLearnMore, usePhotoUpload} from "../hooks";
import {Prompt, useHistory, useParams} from "react-router";
import {PSButtonDanger, PSButtonPrimary} from "../../app/PSButton";
import {Dots, Spinner} from "@zendeskgarden/react-loaders";
import {
    Checkbox,
    Field, Hint,
    Input,
    Label,
} from "@zendeskgarden/react-forms";
import {
    currencyFloat,
    filterMonetaryInput
} from "../../../utils/numbers";
import {Item as PSItem} from "../../../models/Item";
import {PSDropdown} from "../../app/PSDropdown";
import {Item} from "@zendeskgarden/react-dropdowns";
import {Body, Close, Header, Modal} from "@zendeskgarden/react-modals";
import {discountOptions, expireOptions, SelectOption} from "../Options";
import {SettingsPhotoField} from "../SettingsPhotoField";
import {PSTextarea} from "../../app/PSTextarea";
import {updateItem} from "../../../modules/onboarding";
import { GuardrailModal } from "../GuardrailModal";

type Props = {
    forOnboarding?: boolean,
    refreshData?: boolean,
}

export const GiftEdit = ({forOnboarding, refreshData}: Props) => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const history = useHistory()

    const [photo, setPhoto] = useState<undefined | string | File>('')
    const [image, setImage] = useState<undefined | string>('') // For editing
    const [name, setName] = useState('')
    const [price, setPrice] = useState('')
    const [discount, setDiscount] = useState<any>('')
    const [salesTax, setSalesTax] = useState(false)
    const [description, setDescription] = useState('')
    const [expires, setExpires] = useState<any>(expireOptions[1])
    const [online, setOnline] = useState(true)
    const [contract, setContract] = useState<any>('')
    const [form, setForm] = useState<any>('')
    const [saveEnabled, setSaveEnabled] = useState(false)
    const [formSending, setFormSending] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)

    const [addPhotoRef, selectPhoto, selectedPhoto] = usePhotoUpload({disabled: state.isSending, setPhoto})
    const [learnMoreVisible, learnMoreTitle, learnMoreBody, showLearnMore] = useLearnMore()
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    const [salesTaxEnabled, setSalesTaxEnabled] = useState(false)
    const [onlineBookingEnabled, setOnlineBookingEnabled] = useState(false)
    const [discountEnabled, setDiscountEnabled] = useState(false)
    const [formsEnabled, setFormsEnabled] = useState(false)
    const [contractsEnabled, setContractsEnabled] = useState(false)
    const owner = usePSOwner()
    useEffect(() => {
        setSalesTaxEnabled(owner?.allow_salestax ?? false)
        setOnlineBookingEnabled(!!owner?.allow_widget ?? false)
        setDiscountEnabled(!!owner?.allow_discount ?? false)
        setFormsEnabled(!!owner?.allow_record ?? false)
        setContractsEnabled(!!owner?.allow_contract ?? false)
    }, [owner])

    useEffect(() => {
        setSaveEnabled(!!name)
    }, [name])

    useEffect(() => {
        if (forOnboarding) {
            setHasEdits(false)
            return
        }

        let changed = 0
        if ((state.item.name || name) && name !== state.item.name)
            changed = 1
        else if ((state.item.rate || Number(price)) && Number(price) !== Number(state.item.rate))
            changed = 2
        else if ((state.item.memo || description) && description !== state.item.memo)
            changed = 3
        else if ((state.item.allow_salestax || salesTax) && salesTax !== state.item.allow_salestax)
            changed = 5
        else if ((state.item.discount || discount) && discount?.value !== state.item.discount)
            changed = 6
        else if ((state.item.contract || contract) && contract.id !== state.item.contract)
            changed = 9
        else if ((state.item.form || form) && form.id !== state.item.form)
            changed = 10
        else if ((state.item.is_public || online) && online !== state.item.is_public)
            changed = 11
        else if ((state.item.expires && expires) && expires?.value !== state.item.expires?.toString())
            changed = 7
        else if (typeof photo === 'object')
            changed = 13
        setHasEdits(changed > 0)
    }, [
        state.item, name, price, description, discount, salesTax, online,
        contract, form, expires, photo, forOnboarding
    ])

    // Fetch the user's contracts and forms
    useEffect(() => {
        dispatch(fetchAttachments())
    }, [dispatch])

    // Load the gift certificate if this is an edit, otherwise treat it as a create
    const params: any = useParams()
    useEffect(() => {
        if (forOnboarding) return

        if (!params.giftID) {
            dispatch(clearItem())
            return
        }
        dispatch(fetchItem('gift', params.giftID))
    }, [dispatch, params.giftID, forOnboarding])

    // Update form's values after the gift cert is loaded into state
    useEffect(() => {
        if (params.action !== 'copy')
            setName(state.item.name || '')
        setPhoto(state.item.image_uri || '')
        setImage(state.item.image || '')
        setPrice(state.item.rate ? currencyFloat(state.item.rate).toFixed(2) : '')
        setDescription(state.item.memo || '')
        setOnline(state.item.is_public)

        const selectedExpiry = expireOptions.find((option) => option.value === state.item.expires?.toString())
        setExpires(selectedExpiry || expireOptions[1])

        const selectedDiscount = discountOptions.find(option => option.value === state.item.discount)
        setDiscount(selectedDiscount || '')
        setSalesTax(state.item.allow_salestax)

        const selectedContract = state.contracts.find((c) => c.id === state.item.contract)
        setContract(selectedContract || '')

        const selectedForm = state.forms.find((f) => f.id === state.item.form)
        setForm(selectedForm || '')
    }, [state.item, state.contracts, state.forms, params.action])

    const onSave = () => {
        if (formSending) return

        if (!name) return

        const gift = new PSItem('gift')
        if (forOnboarding)
            gift.setData(state.item)
        gift.name = name
        gift.rate = price
        gift.deposit = price
        gift.allow_salestax = salesTax
        gift.memo = description
        gift.is_public = online
        gift.image = image
        if (expires)
            gift.expires = (expires as SelectOption).value
        if (discount)
            gift.discount = (discount as SelectOption).value
        if (form)
            gift.form = form.id
        if (contract)
            gift.contract = contract.id
        if (params.giftID && params.action !== 'copy')
            gift.id = params.giftID

        if (forOnboarding) {
            gift.id = state.item.id
            gift.image = undefined
            dispatch(updateItem(gift.toObject(), !!refreshData))
            history.go(-2)
            return
        }

        setFormSending(true)

        if (typeof photo === 'string') {
            dispatch(saveItem(gift))
        }
        else {
            dispatch(saveItem(gift, photo as File))
        }
    }

    const showAttachments = (contractsEnabled || formsEnabled) &&
        (state.loadingAttachments || state.contracts.length > 0 || state.forms.length > 0)

    const showDeleteButton = params.giftID && params.action !== 'copy'

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Gift Certificate</h1>
                {showDeleteButton && (
                    <PSButtonDanger style={{height: '40px'}}
                                    onClick={() => setShowDeleteConfirmation(true)}>
                        Delete gift certificate
                    </PSButtonDanger>
                )}
                <PSButtonPrimary style={{height: '40px', marginLeft: showDeleteButton ? '16px' : 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!saveEnabled || formSending}>
                    {formSending && <Dots />}
                    {!formSending && "Save"}
                </PSButtonPrimary>
            </div>

            <div className="form">
                {!forOnboarding && (
                    <SettingsPhotoField photo={photo}
                                        selectPhoto={selectPhoto}
                                        selectedPhoto={selectedPhoto}
                                        addPhotoRef={addPhotoRef}
                                        title="Gift Certificate" />
                )}

                <Field className="field">
                    <Label className="label">Name (required)</Label>
                    <Input placeholder="Gift certificate name"
                           value={name}
                           disabled={state.isSending}
                           onChange={e => setName(e.target.value)} />
                </Field>

                <Field className="field">
                    <Label className="label">Price · $</Label>
                    <Input placeholder="Gift certificate price"
                           value={price}
                           disabled={state.isSending}
                           onChange={e => filterMonetaryInput(e.target.value, setPrice)} />
                </Field>

                {discountEnabled && (
                    <PSDropdown selected={discount}
                                nameProperty="label"
                                disabled={state.isSending}
                                onSelect={(selection) => setDiscount(selection)}
                                label="Discount rate"
                                placeholder={<div className="grey">Select discount rate</div>}>
                        <>
                            {discountOptions.map(option => (
                                <Item key={`discount-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                )}

                {salesTaxEnabled && (
                    <Field className="field">
                        <Checkbox checked={salesTax}
                                  disabled={state.isSending}
                                  onChange={e => setSalesTax(e.target.checked)}>
                            <Label className="withHint">Allow sales tax</Label>
                            <Hint>Automatically calculate & apply sales tax</Hint>
                        </Checkbox>
                    </Field>
                )}

                <Field className="field">
                    <Label className="label">Description</Label>
                    <PSTextarea placeholder="Gift certificate description"
                                className="input"
                                minRows={8}
                                maxLength={1500}
                                disabled={state.isSending}
                                value={description}
                                onChange={value => setDescription(value)}/>
                </Field>

                <h2>Details</h2>

                <PSDropdown selected={expires}
                            nameProperty="label"
                            disabled={state.isSending}
                            onSelect={(selection) => setExpires(selection)}
                            label="Expires after">
                    <>
                        {expireOptions.map(option => (
                            <Item key={`expire-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>

                {onlineBookingEnabled && (
                    <Field className="field">
                        <Checkbox checked={online}
                                  disabled={state.isSending}
                                  onChange={(e) => { setOnline(e.target.checked)}}>
                            <Label className="withHint">Show online</Label>
                            <Hint>Allow clients to purchase this gift certificate online</Hint>
                        </Checkbox>
                    </Field>
                )}

                {showAttachments && <h2>Attachments</h2>}

                {state.loadingAttachments && (
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                )}
                {contractsEnabled && !state.loadingAttachments && state.contracts.length > 0 && (
                    <PSDropdown selected={contract}
                                disabled={state.isSending}
                                nameProperty="name"
                                onSelect={(selection) => setContract(selection)}
                                label="Attach a contract"
                                hint="This contract will be signed when a client purchases this gift certificate"
                                onLearnMore={() => showLearnMore('contracts', true)}
                                placeholder={<div className="grey">Select contract</div>}>
                        <>
                            {state.contracts.map(option => (
                                <Item key={`contract-${option.id}`} value={option}>
                                    {option.name}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                )}
                {formsEnabled && !state.loadingAttachments && state.forms.length > 0 && (
                    <PSDropdown selected={form}
                                disabled={state.isSending}
                                nameProperty="name"
                                onSelect={(selection) => setForm(selection)}
                                label="Attach a form"
                                hint="This form will be completed when a client purchases this gift certificate"
                                onLearnMore={() => showLearnMore('forms', true)}
                                placeholder={<div className="grey">Select form</div>}>
                        <>
                            {state.forms.map(option => (
                                <Item key={`form-${option.id}`} value={option}>
                                    {option.name}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                )}
            </div>
            {learnMoreVisible && (
                <Modal onClose={() => showLearnMore('', false)}>
                    <Header>{learnMoreTitle}</Header>
                    <Body>{learnMoreBody}</Body>
                    <Close aria-label="Close modal" />
                </Modal>
            )}
            {showDeleteConfirmation && (
                <GuardrailModal title="Are you sure?"
                                body="Are you sure you'd like to delete this item?"
                                buttonText="Delete"
                                onClose={() => setShowDeleteConfirmation(false)}
                                onAction={() => dispatch(deleteItem(params.giftID, 'gifts'))}
                />
            )}
            <Prompt
                when={hasEdits && !formSending}
                message="Are you sure you'd like to leave this page without saving your changes?" />
        </div>
    )
}
