import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RecordBody } from '../RecordBody'
import { RecordDetail } from '../RecordDetail'
import { ReactComponent as ChevronIcon } from '../../../icons/chevron.svg'
import { taskStatus } from '../../../models/Task'

export const RecordTask = (props) => {
    const {record} = props
    const state = useSelector(state => (
        {
            entities: state.entities,
        }
    ))

    const person = state.entities.contacts[record.person] || {}
    const employee = state.entities.contacts[record.employee] || {}

    const recordDetails = []

    if (person.id) {
        const details = (
            <Link to={`/record/client/${person.id}`}>
                {person.name}
                <ChevronIcon className="chevron" />
            </Link>
        )
        recordDetails.push(
            <RecordDetail header="Client" details={details} />
        )
    }

    if (employee.id) {
        const details = (
            <Link to={`/record/payee/${employee.id}`}>
                {employee.name}
                <ChevronIcon className="chevron" />
            </Link>
        )
        recordDetails.push(
            <RecordDetail header="Team member" details={details} />
        )
    }

    if (record.memo) {
        recordDetails.push(
            <RecordDetail header="Memo" details={record.memo} isText />
        )
    }

    return (
        <div className="recordView">
            <div className="recordHeader">
                <h2>{record.title}</h2>
                <div className="title">{taskStatus(record)}</div>
            </div>
            <RecordBody recordDetails={recordDetails} />
        </div>
    )
}