import React from 'react'
import { NotificationBanner } from './NotificationBanner'
import { UserHeader } from './UserHeader'
import {PSButton} from "./PSButton";
import {ReactComponent as LeftArrow} from "../../icons/left-arrow.svg";
import {useHistory} from "react-router";

type Props = {
    showBackButton?: boolean,
    onBack?: () => void,
    title?: string | React.ReactNode,
    titleWidget?: React.ReactNode,
    middleWidget: React.ReactNode,
    style?: React.CSSProperties,
    children: React.ReactNode,
}

export const AppHeader = ({ showBackButton, onBack, title, titleWidget, middleWidget, style, children }: Props) => {
    const history = useHistory()

    return (
        <div style={{...style, display: 'flex', flexDirection: 'column', height: '100%'}}
             data-testid="appheader"
        >
            <div style={{display: 'flex'}}>
                {title && (
                    <div style={{
                            padding: '21px 0 23px 26px',
                            fontSize: '14px',
                            lineHeight: '24px',
                            color: '#828282',
                            fontWeight: 600,
                            textTransform: 'capitalize'
                        }}
                         data-testid="appheader-title"
                    >
                        {showBackButton && (
                            <PSButton style={{marginLeft: 0, marginRight: 16}}
                                      onClick={() => onBack ? onBack() : history.goBack()}
                            >
                                <LeftArrow />
                            </PSButton>
                        )}
                        {title}
                    </div>
                )}
                {titleWidget}
                {middleWidget}
                <div style={{marginLeft: 'auto', paddingTop: '23px', paddingRight: '24px'}}>
                    <UserHeader />
                </div>
            </div>
            <div style={{height: '1px', minHeight: '1px', backgroundColor: '#e4e4e4', width: '100%'}} />
            {children}
            <NotificationBanner />
        </div>
    )
}