import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

export const RecordDetail = (props) => {
    const [isExpanded, setExpanded] = useState(false)
    const [isExpanding, setExpanding] = useState(false)
    const ref = useRef()
    useEffect(() => {
        if (ref.current.clientHeight > 150)
            setExpanding(true)
    }, [setExpanding, ref])

    let expandedClassName = ''
    if (props.isText && isExpanding) {
        expandedClassName = `text-${isExpanded ? 'expanded' : 'minimized'}`
    }

    const clickExpansion = (e, expanded) => {
        e.preventDefault()
        setExpanded(expanded)
    }

    return (
        <div className="recordDetails">
            <h2>{props.header}</h2>
            <div ref={ref}>
                {props.details && (
                    <>
                        <div className={`details ${props.isText ? 'text' : ''} ${expandedClassName}`}>
                            {props.details}
                        </div>
                        {props.isText && isExpanding && !isExpanded && (
                            <div className="viewExpansion">
                                <button onClick={e => clickExpansion(e, true)}>View more</button>
                            </div>
                        )}
                        {props.isText && isExpanded && (
                            <div className="viewExpansion">
                                <button onClick={e => clickExpansion(e, false)}>View less</button>
                            </div>
                        )}
                    </>
                )}
                {props.subtitle && (
                    <div className="subtitle">{props.subtitle}</div>
                )}
                {props.children}
            </div>
        </div>
    )
}

RecordDetail.propTypes = {
    header: PropTypes.string.isRequired,
    details: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ]),
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    isText: PropTypes.bool,
    children: PropTypes.any
}