import React, {useEffect} from "react";
import {useAppDispatch} from "../../../hooks";
import {
    fetchSources,
    SettingsState
} from "../../../modules/settings";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {push} from "connected-react-router";
import {PaymentMethodsListComponent} from "./PaymentMethodsListComponent"


export const PaymentMethodList = () => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    useEffect(() => {
        dispatch(fetchSources())
    }, [dispatch])

    const addPaymentCard = () => {
        dispatch(push('/settings/payments/methods/card/new'));
    }

    const addChecking = () => {
        dispatch(push('/settings/payments/methods/checking/new'));
    }

    const onViewItemClick = (source: any) => {
        dispatch(push(`/settings/payments/methods/${source.id}/view`))
    }

    return (
        <PaymentMethodsListComponent
            sources={state.sources}
            loading={state.isSending}
            addChecking={addChecking}
            addPaymentCard={addPaymentCard}
            onViewItemClick={onViewItemClick} />
    )
}
