import React, { useCallback, useState } from 'react'
import moment from 'moment'
import { PSButton, PSButtonDanger, PSButtonPrimary } from '../../app/PSButton'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import {deleteBlock} from '../../../modules/settings'
import { DangerModal } from '../../settings/DangerModal'
import { Body, Close, Footer, FooterItem, Header, Modal } from '@zendeskgarden/react-modals'
import { Result } from '../../../utils'
import { useHistory } from 'react-router'

type Props = {
    record: any
}

export const RecordBlock = ({record} : Props) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const [showDeleteBlock, setShowDeleteBlock] = useState(false)
    const [showEditMultipleBlocks, setShowEditMultipleBlocks] = useState(false)

    const removeBlock = useCallback((button: number | undefined) => {
        Result(dispatch(deleteBlock(record.id, button === 2)))
            .then(() => history.goBack())
    }, [dispatch, history, record.id])

    return (
        <div className="recordView">
            <div className="recordHeaderButtons" >
                <PSButtonPrimary style={{height: '45px', marginLeft: 'auto'}} onClick={() => !!record?.schedule ? setShowEditMultipleBlocks(true) : dispatch(push(`/block/edit/${record.id}`))}>
                    Edit
                </PSButtonPrimary>
                <PSButtonDanger style={{height: '45px', marginLeft: '16px'}} onClick={() => setShowDeleteBlock(true)}>
                    Delete Block
                </PSButtonDanger>
            </div>
            <div className="recordHeader">
                <h2>{record.title}</h2>
                <div className="title">{moment(record.date).format('ddd, MMM Do YYYY')}</div>
                <div className="time">
                    {moment(record.date).format('h:mm a')} - {moment(record.end_date).format('h:mm a')}
                </div>
            </div>

            {showDeleteBlock && (
                <DangerModal
                    title="Delete block"
                    body={!!record.schedule ?
                        'This block repeats on your calendar, If you would like to delete just this block select "Delete this block". If you would like to delete all blocks choose "Delete this and future blocks".':
                        'Would you like to remove this block from your calendar?'}
                    buttonText={!!record.schedule ? 'Delete this Block': "Delete block"}
                    extraButtonText={!!record.schedule ? 'Delete this and future Blocks' : undefined}
                    disableDismiss
                    onAction={removeBlock}
                    onClose={() => setShowDeleteBlock(false)}
                />
            )}

            {showEditMultipleBlocks && (
                <Modal onClose={() => setShowEditMultipleBlocks(false)}>
                    <Header>Edit</Header>
                    <Body>This block repeats on your calendar. If you would like to edit just this block select “Edit this block”. If you would like to edit all blocks choose "Edit this and future blocks".</Body>
                    <Close aria-label="Close modal" />
                    <Footer>
                        <FooterItem>
                            <PSButton style={{marginRight: '16px'}} onClick={() => dispatch(push(`/block/edit-all/${record.id}`))}>Edit this and future blocks</PSButton>
                            <PSButtonPrimary onClick={() =>  dispatch(push(`/block/edit/${record.id}`))}>Edit this block</PSButtonPrimary>
                        </FooterItem>
                    </Footer>
                </Modal>
            )}
        </div>
    )
}