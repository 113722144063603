import React, { createRef, useEffect } from 'react'
import { Field, Input } from '@zendeskgarden/react-forms'
import { Button } from '@zendeskgarden/react-buttons'
import { Dots } from '@zendeskgarden/react-loaders'
import { useDispatch, useSelector } from 'react-redux'
import { loginWithPhoneNumber } from '../../modules/login'
import { Redirect } from 'react-router'
import {useLocation} from "react-router-dom";

import './login.css'

export const CodeEntry = (props) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const state = useSelector(state => state.login)

    const [loginCode, setLoginCode] = React.useState('')

    const codeInputRef = createRef()
    useEffect(() => {
        if (codeInputRef && codeInputRef.current)
            codeInputRef.current.focus()
    }, [codeInputRef])

    const sendLoginCode = (e) => {
        e.preventDefault()
        if (state.isSending) return
        dispatch(loginWithPhoneNumber(state.e164, loginCode, location.state.redirectUrl))
    }

    if (!state.e164) {
        return <Redirect to={`/login${location.state.redirectUrl ? `?redirectUrl=${location.state.redirectUrl}` : ''}`} />
    }

    return (
        <div className="login-body">
            <img className="logo"
                 src="/logo-nobriefcase.svg"
                 height="62"
                 width="178"
                 alt="PocketSuite"
            />
            <div className="login-box">
                {state.loginType === 'sms' && (
                    <h3>Enter sign in code</h3>
                )}
                {state.loginType === 'password' && (
                    <h3>Enter password</h3>
                )}
                <form onSubmit={sendLoginCode}>
                    <p>
                        Keep your account secure
                    </p>
                    <Field>
                        <Input type="password"
                               maxLength="4"
                               value={loginCode}
                               onChange={e => setLoginCode(e.target.value)}
                               placeholder={`Enter ${state.loginType === 'password' ? 'password': 'security code'}`}
                               ref={codeInputRef}
                               data-testid="password"
                        />
                    </Field>
                    <Button className="loginButton"
                            isPrimary
                            style={{padding: '0 16px', lineHeight: '14px', fontWeight: '700', opacity: state.isSending ? 0.6 : 1.0}}
                            onClick={sendLoginCode}>
                        {state.isSending && (
                            <Dots />
                        )}
                        {!state.isSending && (
                            <span>Sign in</span>
                        )}
                    </Button>
                </form>
                <div className="grayLine" />
                <div style={{textAlign: 'center', color: '#314A68', fontWeight: '500', marginTop: '20px'}}>Desktop Beta</div>
            </div>
        </div>
    )
}