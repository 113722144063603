import React from "react";
import {AppHeader} from "../app/AppHeader";
import {ProductEdit} from "../settings/products/ProductEdit";
import {useHistory} from "react-router";

export const InventoryProductEdit = () => {
    const history = useHistory();
    return (
        <AppHeader title='Inventory' showBackButton={true} middleWidget={null}>
            <div className="pageWrapper">
                <ProductEdit redirect={() => history.goBack()}/>
            </div>
        </AppHeader>
    );
}