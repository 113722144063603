import moment from "moment";
import {bookingStatus} from "../../../utils/models";
import {ReactComponent as ChevronIcon} from "../../../icons/chevron.svg";
import React from "react";
import keyBy from 'lodash.keyby'
import {Spinner} from "@zendeskgarden/react-loaders";

type Props = {
    bookings: any[],
    contacts: any,
    isLoading: boolean,
    goBooking: (booking: any) => void,
}

export const RecordBookingsModalBody = ({bookings, contacts, isLoading, goBooking}: Props) => {
    return (
        <div className="bodyWrapper">
            <h1>Sessions</h1>
            {isLoading && (
                <div style={{textAlign: 'center', padding: '48px 0'}}>
                    <Spinner size="128" color="#314A68" />
                </div>
            )}
            <div className="scroll">
                {bookings.map((booking) => {
                    const itemMap = keyBy(booking.lines, 'item')
                    const item = itemMap[booking.item] || {}
                    const person = contacts[booking.client] || {}

                    const title = []
                    if (item.item_display)
                        title.push(item.item_display)
                    else
                        title.push(bookingStatus(booking))
                    if (person.name)
                        title.push(person.name)

                    const bookingDate = moment(booking.date)
                    let bookingDateFormat
                    if (bookingDate.isSame(moment(), 'year'))
                        bookingDateFormat = bookingDate.format('M/D')
                    else
                        bookingDateFormat = bookingDate.format('M/D/YY')
                    const startTime = moment(booking.date).format('h:mm a')
                    const endTime = moment(booking.end_date).format('h:mm a')

                    return (
                        <div key={`booking-${booking.id}`} style={{marginBottom: '12px'}}>
                            <div
                                className="buttonLink"
                                onClick={() => goBooking(booking)}>
                                {title.join(' • ')}
                                <ChevronIcon className="chevron" />
                            </div>
                            <div className="grey">
                                {bookingDateFormat} at {startTime} to {endTime}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}