import React, {useEffect} from "react";
import {useAppDispatch} from "../../hooks";
import {Redirect, useLocation} from "react-router";
import {Category} from "../../models/Category";
import {setCategory} from "../../modules/settings";
import {AppHeader} from "../app/AppHeader";
import {CategoryEdit} from "../settings/categories/CategoryEdit";

export const OnboardingCategoryEdit = () => {
    const dispatch = useAppDispatch()

    const routerLocation = useLocation()
    const locationState: any = routerLocation.state
    const category: Category = locationState?.category

    useEffect(() => {
        if (category) dispatch(setCategory(category))
    }, [dispatch, category])

    if (!category) {
        return <Redirect to='/onboarding/categories' />
    }

    return (
        <AppHeader title="Setup" showBackButton middleWidget={null}>
            <div className="pageWrapper">
                <CategoryEdit forOnboarding />
            </div>
        </AppHeader>
    )
}
