export const b64EncodeUnicode = (str: string) => {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        // @ts-ignore
        return String.fromCharCode('0x' + p1)
    }))
}

export const andConcat = (strings: string[]) => {
    let lastItem = strings.length > 1 ? strings.pop() : undefined
    if (!lastItem) {
        // array empty or length 1
        return strings.join()
    }
    lastItem = ` and ${lastItem}`

    return strings.join(', ')
        + lastItem
}