import {useDispatch, useSelector} from "react-redux";
import {clearError, ErrorState} from "../../modules/error";
import {
    Close,
    Footer,
    FooterItem,
    Header,
    Modal
} from "@zendeskgarden/react-modals";
import {ReactComponent as ErrorIcon} from "../../icons/alert_red_circle.svg";
import {Body as ModalBody} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import React from "react";
import {AxiosResponse} from "axios";
import { RootState } from "../../store";

type Props = {
    error?: AxiosResponse | Error;
    resetErrorBoundary: (...args: Array<unknown>) => void;
}

export const ErrorFallback = ({error, resetErrorBoundary}: Props) => {
    const dispatch = useDispatch()
    const state: ErrorState = useSelector((state: RootState) => state.error)

    const onClose = () => {
        dispatch(clearError())
        resetErrorBoundary()
    }

    const message: string = error instanceof Error ? error.message : error?.data?.message
    return (
        <Modal onClose={onClose}>
            <Header style={{display: 'flex', color: '#FA3C69'}}>
                <ErrorIcon />
                <span style={{fontWeight: 700, paddingLeft: 8}}>Error</span>
            </Header>
            <ModalBody>
                <div>{message || state.fallbackMessage || `We're sorry, something went wrong.  If the error persists, please contact support.`}</div>
            </ModalBody>
            <Footer>
                <FooterItem>
                    <Button onClick={onClose} isBasic>
                        Dismiss
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>

    )
}
