import moment from "moment";

export const momentToDateLocalTZ = (m: moment.Moment): Date => {
    return new Date(m.toDate().getTime() + (m.utcOffset() * 60_000))
}

export const dateFormat = (date: moment.MomentInput, format = 'MMM Do, yyyy') => moment(date).format(format)

export const isPast = (date: moment.MomentInput): boolean => {
    return moment(date).isBefore(moment())
}

export const dateByMovingToEndOfDay = (date: moment.MomentInput): Date => {
    return moment(date).set({'hour': 23, 'minute': 59, 'second': 59}).toDate()
}
