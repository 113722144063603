import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router"
import { AppHeader } from "../app/AppHeader"
import { Spinner } from "@zendeskgarden/react-loaders"
import '../settings/settings.css'
import { useDispatch, useSelector } from "react-redux"
import { getRecord } from "../../modules/records"
import { RootState } from "../../store"
import { isEmpty, Result } from "../../utils"
import { currencyFormat } from "../../utils/numbers"
import { push } from "connected-react-router"
import { apiClient } from "../../modules/apiclient"
import { ContactAvatar } from "../contacts/ContactAvatar"
import moment from "moment"
import { Subscription } from "../../models/Subscription"
import { Package } from "../../models/Package"
import { Invoice } from "../../models/Invoice"
import { Booking } from "../../models/Booking"

export const RecordPurchases = () => {
    const dispatch = useDispatch()
    const state = useSelector((state: RootState) => (
        {
            entities: state.entities,
            records: state.records
        }
    ))

    const params = useParams<{recordType: string, recordID: string}>()

    const [bookings, setBookings] = useState<Booking[]>()
    const [invoices, setInvoices] = useState<Invoice[]>()
    const [packages, setPackages] = useState<Package[]>()
    const [subscriptions, setSubscriptions] = useState<Subscription[]>()

    const fetchPurchases = useCallback(() => {
        if (params.recordType === 'gift') {
            return apiClient.post('/gift/sync', {id: params.recordID})
                .then(resp => resp.data)
                .then((json: any) => {
                    if (json?.bookings?.length) {
                        setBookings(json.bookings)
                    }
                    if (json?.invoices?.length) {
                        setInvoices(json.invoices)
                    }
                    if (json?.packages?.length) {
                        setPackages(json.packages)
                    }
                    if (json?.subscriptions?.length) {
                        setSubscriptions(json.subscriptions)
                    }
                })
        }
    }, [params.recordID, params.recordType])

    const fetchRecord = useCallback(() => {
        return Result(dispatch(getRecord(params.recordType, params.recordID)))
    }, [dispatch, params.recordID, params.recordType])

    const [record, setRecord] = useState()
    useEffect(() => {
        if (!isEmpty(record)) return

        const task1 = fetchPurchases()
        const task2 = fetchRecord()

        setIsLoading(true)
        Promise.all([task1, task2])
            .finally(() => setIsLoading(false))

    }, [record, params, dispatch, fetchPurchases, fetchRecord])

    useEffect(() => setRecord(state.records.data), [state.records.data])

    const [isLoading, setIsLoading] = useState(false)

    const renderType = useCallback((recordUrl: string, payment: Booking | Subscription | Package | Invoice) => {
        const client = state.entities.contacts[payment.client]
        return (
            <div key={`payment-card-${payment.id}`} className="serviceCard">
                {client && <ContactAvatar user={client} />}
                <div className="details">
                    <div className="name">{client?.name}</div>
                    <div className="rate">{moment(payment.created_date).format('MMMM DD, YYYY')}</div>
                </div>
                <div className="name" style={{marginLeft: 'auto'}}>{currencyFormat(payment.total, true)}</div>
                <div className="view" style={{marginLeft: '24px'}} onClick={() => dispatch(push(recordUrl))}>View</div>
            </div>)
    }, [dispatch, state.entities.contacts])

    let formBody: JSX.Element | undefined = undefined;
    if (isLoading || !record) {
        formBody = <Spinner size='128' color="#314A68" />
    }
    else {
        formBody = <>
            {bookings?.map(b =>
                renderType(`/record/booking/${b.id}`, b))}
            {packages?.map(p =>
                renderType(`/record/package/${p.id}`, p))}
            {invoices?.map(i =>
                renderType(`/record/invoice/${i.id}`, i))}
            {subscriptions?.map(s =>
                renderType(`/record/subscription/${s.id}`, s))}
        </>
    }


    return (
        <AppHeader title="Gift Certificate" showBackButton middleWidget={null}>
            <div className="servicesSettings" style={{width: '680px'}}>
                <div className="header">
                        <h1>Purchases</h1>
                </div>
                <div className="separator" />
                <div className="form">
                    {formBody}
                </div>
            </div>
        </AppHeader>)
}