import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../hooks";
import {AppHeader} from "../app/AppHeader";
import {Spinner} from "@zendeskgarden/react-loaders";
import { fetchBalances } from "../../modules/transactions";
import { Link } from "react-router-dom";
import { dateFormat } from "../../utils/dates";
import { Result } from "../../utils";
import { currencyFormat } from "../../utils/numbers";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

type Balance = {
    type_string: string,
    payment: string,
    amount: string,
    deposit: string,
    type: string,
    event_date: string,
    id: string
}

export const AccountBalanceView = () => {
    const dispatch = useAppDispatch()
    const state = useSelector((state: RootState) => state.entities)

    const [loading, setLoading] = useState(false)
    const [balances, setBalances] = useState<Balance[]>([])

    useEffect(() => setBalances(Object.values(state.balances)), [state.balances])
    useEffect(() => {
        setLoading(true)
        Result(dispatch(fetchBalances())).then(() => setLoading(false))
    }, [dispatch])

    return (
        <AppHeader title="Deposits" showBackButton={true} middleWidget={null}>
            <div className="dashboardDetails">
                <>
                    <div className="header">
                        <h2>Balance history</h2>
                    </div>
                    <div className="separator" />
                    <div className="list">
                        {loading && balances.length === 0 && (
                            <div style={{textAlign: 'center', padding: '128px 0'}}>
                                <Spinner size="128" color="#314A68" />
                            </div>
                        )}
                        {!loading && balances.length === 0 && (
                            <div className="empty">
                                <p>Nothing here yet.</p>
                                <p style={{fontWeight: 700, padding: '8px 0'}}>You got this!</p>
                            </div>
                        )}
                        {balances.map(({ id, type_string, event_date, amount, deposit, payment }: Balance) => (
                            <div className="card" key={`balance-${id}`}>
                                <div className="titleArea">
                                    <div className="title">{type_string}</div>
                                    <div className="subtitle">{dateFormat(event_date, 'MMMM D, YYYY')}</div>
                                </div>
                                <div className="right">
                                    <div className="text"
                                         style={parseFloat(amount) < 0 ? { color: '#E22216' } : {}}>
                                        {currencyFormat(amount)}
                                    </div>
                                    {!!deposit && <Link to={`/record/deposit/${deposit}`}>View</Link>}
                                    {!!payment && <Link to={`/record/payment/${payment}`}>View</Link>}
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            </div>
        </AppHeader>
    )
}