import React, {useEffect, useState} from "react";
import {Modal, Body, Footer, FooterItem, Header} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import {PSDropdown} from "../app/PSDropdown";
import {
    daysOfTheWeekOptions, recurringScheduleFrequencyOptions,
    SelectOption,
} from "../settings/Options";
import {Item} from "@zendeskgarden/react-dropdowns";
import {PSMultiselect} from "../app/PSMultiselect";
import moment from "moment";

type Props = {
    instanceOptions: SelectOption[],
    defaultRecurrance?: SelectOption,
    onClose: () => void,
    onDone: (frequency: SelectOption, dow: SelectOption[], instance: SelectOption, wom?: SelectOption) => void,
}

export const RecurringScheduleModal = ({instanceOptions, defaultRecurrance, onClose, onDone}: Props) => {
    const [frequency, setFrequency] = useState<SelectOption>(defaultRecurrance ?? recurringScheduleFrequencyOptions[1])
    const currentDay = Math.max(0, moment().day() - 1)
    const [dow, setDOW] = useState<SelectOption[]>([daysOfTheWeekOptions[currentDay]])
    const [monthlyDOW, setMonthlyDOW] = useState<SelectOption>({label: '', value: ''})
    const [instances, setInstances] = useState<SelectOption>(instanceOptions[0])

    const [monthlyFrequency, setMonthlyFrequency] = useState(false)
    const [dowOptions, setDOWOptions] = useState(daysOfTheWeekOptions)
    useEffect(() => {
        if (frequency) {
            const isMonthlyFrequency = frequency.value.endsWith('m')
            if (isMonthlyFrequency) {
                // Make sure the DOW is set to something, default it to today if not set
                if (dow.length === 0) {
                    setDOW([daysOfTheWeekOptions[currentDay]])
                    return
                }

                const day = dow[0].label
                const options: SelectOption[] = [
                    { label: `First ${day}`, value: '1' },
                    { label: `Second ${day}`, value: '2' },
                    { label: `Third ${day}`, value: '3' },
                    { label: `Fourth ${day}`, value: '4' },
                    { label: `Last ${day}`, value: '-1' },
                    { label: `Second to last ${day}`, value: '-2' },
                ]
                setDOWOptions(options)
                setMonthlyDOW(options[0])
            }
            setMonthlyFrequency(isMonthlyFrequency)
        }
        else
            setMonthlyFrequency(false)
    }, [frequency, dow, currentDay])

    const doneHandler = () => {
        onDone(frequency, dow, instances, monthlyFrequency ? monthlyDOW : undefined)
    }

    return (
        <Modal onClose={onClose}>
            <Header className="header">Repeat</Header>
            <Body className="body">
                <div style={{paddingBottom: '26px'}}>
                    <PSDropdown selected={frequency}
                                nameProperty="label"
                                onSelect={(selection) => setFrequency(selection)}
                                label="Repeat every">
                        {recurringScheduleFrequencyOptions.map(option => (
                            <Item key={`frequency-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </PSDropdown>
                </div>
                {monthlyFrequency && (
                    <div style={{paddingBottom: '26px'}}>
                        <PSDropdown selected={monthlyDOW}
                                    nameProperty="label"
                                    onSelect={(selection) => setMonthlyDOW(selection)}
                                    label="Day of the week">
                            <>
                                {dowOptions.map(option => (
                                    <Item key={`dow-${option.value}`} value={option}>
                                        {option.label}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>
                    </div>
                )}
                {!monthlyFrequency && (
                    <div style={{paddingBottom: '26px'}}>
                        <PSMultiselect selected={dow}
                                       nameProperty="label"
                                       onSelect={(selection) => setDOW(selection)}
                                       label="Days of the week">
                            <>
                                {daysOfTheWeekOptions.map(option => (
                                    <Item key={`dow-${option.value}`} value={option}>
                                        {option.label}
                                    </Item>
                                ))}
                            </>
                        </PSMultiselect>
                    </div>
                )}
                <div style={{paddingBottom: '26px'}}>
                    <PSDropdown selected={instances}
                                nameProperty="label"
                                onSelect={(selection) => setInstances(selection)}
                                label="Number of times">
                        <>
                            {instanceOptions.map(option => (
                                <Item key={`count-${option.value}`} value={option}>
                                    {option.label}
                                </Item>
                            ))}
                        </>
                    </PSDropdown>
                </div>
            </Body>
            <Footer className="footer">
                <FooterItem>
                    <Button style={{marginRight: '12px'}} onClick={() => onClose()}>Cancel</Button>
                    <Button onClick={doneHandler} isPrimary>
                        Done
                    </Button>
                </FooterItem>
            </Footer>
        </Modal>
    )
}