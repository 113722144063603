import React, {useEffect, useState} from "react"
import {FormQuestionBuilder} from "../forms/FormQuestionBuilder";
import {useHistory, useParams} from "react-router";
import {Field} from "../../../../models/Field";
import {useAppDispatch} from "../../../../hooks";
import {fetchLeadsQuestions, SettingsState} from "../../../../modules/settings";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";

export const LeadsFieldEdit = () => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)
    const history = useHistory()
    const params: any = useParams()

    const [field, setField] = useState<Field | undefined>(undefined)

    useEffect(() => {
        if (params.fieldID) {
            if (!state.fields) {
                dispatch(fetchLeadsQuestions())
                return
            }
            let ix = state.fields.findIndex(f => f.id === params.fieldID)
            if (ix >= 0) {
                setField(state.fields[ix])
            }
            else {
                history.goBack()
            }
        }
    }, [dispatch, history, params.fieldID, state.fields])
    return <>
        {!!field && <FormQuestionBuilder editField={field} forClient={true} />}
        {!params.fieldID && <FormQuestionBuilder forClient={true} />}
    </>
}