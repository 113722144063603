import { apiClient } from "./apiclient"
import moment from "moment"
import axios, {AxiosRequestConfig, Cancel, CancelToken} from 'axios'
import keyBy from 'lodash.keyby'
import {Dashboard, DashboardInstance} from "../models/Dashboard";
import {processError} from "./error";
import {Tab} from "../models/Tab";
import {Edition} from "../models/Edition";
import localforage from "localforage";
import { dashboardKey } from "../utils/constants";
import {UserInstance} from "../models/User";

export type DashboardState = {
    isSending: boolean;
    period: string;
    topList: string;
    metrics: {
        graphData: any[];
        firstLabel: string;
        lastLabel: string;
        maxValue: number;
        total: {
            income: number;
            income_delta: number;
            count: number;
            topList: any[];
        }
    },
    tabs: Tab[];
    activeTab?: Tab;
    dashboards: Dashboard[];
    edition?: Edition;
    dashboardResponse: any;
}

const initialState: DashboardState = {
    isSending: true,
    period: 'mtd',
    topList: 'client',
    dashboards: [],
    tabs: [],
    metrics: {
        graphData: [],
        firstLabel: '',
        lastLabel: '',
        maxValue: 0,
        total: {
            income: 0,
            income_delta: 0,
            count: 0,
            topList: [],
        }
    },
    dashboardResponse: {}
}

export default function dashboardReducer(state = initialState, action: any) {
    switch (action.type) {
        case 'dashboard/REQUEST':
            return {
                ...state,
                isSending: action.sending,
            }
        case 'dashboard/SET_OPTIONS':
            return {
                ...state,
                period: action.period,
                topList: action.topList,
            }
        case 'dashboard/LOADED':
            return {
                ...state,
                metrics: {
                    ...state.metrics,
                    graphData: action.graphData,
                    firstLabel: action.firstLabel,
                    lastLabel: action.lastLabel,
                    maxValue: action.maxValue,
                    total: {
                        income: action.json.total.value,
                        count: action.json.total.count,
                        income_delta: action.json.delta,
                        topList: action.topList,
                    }
                },
            }
        case 'dashboard/SET_DASHBOARDS':
            const tab = action.tabs.find((tab: Tab) => tab.id === action.activeTab) ?? action.tabs[0]
            return {
                ...state,
                dashboards: action.dashboards,
                tabs: action.tabs,
                activeTab: tab,
                edition: action.edition,
            }
        case 'dashboard/SET_DASHBOARD':
            let dashboards = state.dashboards;
            const currentItem = dashboards.find((dash: Dashboard) => dash.id === action.dashboard.id)
            const ix = currentItem ? dashboards.indexOf(currentItem) : -1
            if (ix !== -1) {
                dashboards = state.dashboards.splice(ix, 1, action.dashboard)
            }
            else {
                dashboards.push(action.dashboard)
            }

            return {
                ...state,
                dashboards
            }
        case 'dashboard/LOADED_DASHBOARD':
            return {
                ...state,
                dashboardResponse: action.dashboardResponse,
            }
        case 'dashboard/SWITCH_TAB':
            let newTab = state.tabs.find(t => t.id === action.tab.id)
            if (!newTab) return state
            return {
                ...state,
                activeTab: newTab,
            }
        default:
            return state
    }
}

const dashboardRequest = (sending: boolean) => {
    return { type: 'dashboard/REQUEST', sending }
}

export const dashboardSetOptions = (period: string, topList: string) => {
    return { type: 'dashboard/SET_OPTIONS', period, topList }
}

const dashboardLoaded = (graphData: any[], firstLabel: string, lastLabel: string, maxValue: number, topList: any[], json: any) => {
    return { type: 'dashboard/LOADED', graphData, firstLabel, lastLabel, maxValue, topList, json }
}

export const getDashboard = (period: string, graphType: string, topList: string, cancelToken: CancelToken, userId?: string) => {
    return (dispatch: any): any => {
        dispatch(dashboardRequest(true))

        const data = {
            type: graphType,
            date_type: period,
            top_type: topList,
            person: userId
        }

        return apiClient.post('/dashboard/metrics', data, {cancelToken})
            .then(resp => resp.data)
            .then(json => {
                const totalDates = Object.keys(json['total_by_date']).sort((a, b) => {
                    return moment(a).isBefore(b) ? -1 : 1
                })
                // Create the labels and captions
                const { graphData, firstLabel, lastLabel, maxValue, totals } = createChartData(topList, period, totalDates, json)
                dispatch(dashboardLoaded(graphData, firstLabel, lastLabel, maxValue, totals, json))
                dispatch(dashboardRequest(false))
            })
            .catch((_: Cancel) => { })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

const createChartData = (topType: string, period: string, totalDates: string[], data: any) => {
    const startDate = moment(data['date'] || totalDates[0], "YYYY-MM-DD")
    let labelCount, labelFormat, captionFormatter
    let periodIncrementer
    const labels = []
    const captions = []
    const graphData = []
    const totals = []

    switch (period) {
        case 'wtd':
        case 'lw':
        case 'nw':
        case 'tw':
            labelCount = 7
            labelFormat = 'ddd'
            captionFormatter = (day: moment.Moment, _: any, amount: number) => `${moment(day).format('ddd MMM, d')}: ${amount}`
            periodIncrementer = (d: moment.Moment, n: number) => d.add(n, 'days')
            break
        case 'mtd':
        case 'lm':
        case 'nm':
        case 'tm':
            labelCount = 30
            labelFormat = 'M/D'
            captionFormatter = (day: moment.Moment, _: any, amount: number) => `${moment(day).format('ddd MMM, d')}: ${amount}`
            periodIncrementer = (d: moment.Moment, n: number) => d.add(n, 'days')
            break
        case 'qtd':
        case 'lq':
        case 'nq':
        case 'tq':
            labelCount = 13 // TODO or 14 if ??? starts on a Sunday/Monday?
            labelFormat = 'M/D'
            captionFormatter = (start: moment.Moment, end: any, amount: number) => `${moment(start).format('M/D')} - ${moment(end).format('M/D')}: ${amount}`
            periodIncrementer = (d: moment.Moment, n: number) => d.add(7*n, 'days')
            break
        case 'ytd':
        case 'ly':
        case 'lyq':
        case 'ty':
            labelCount = 12
            labelFormat = 'MMM'
            captionFormatter = (start: moment.Moment, _: any, amount: number) => `${moment(start).format('MMMM YYYY')}: ${amount}`
            periodIncrementer = (d: moment.Moment, n: number) => d.add(n, 'month')
            break
        case 'all':
        default:
            labelCount = totalDates.length
            labelFormat = 'YYYY'
            captionFormatter = (start: moment.Moment, _: any, amount: number) => `${moment(start).format('YYYY')}: ${amount}`
            periodIncrementer = (d: moment.Moment, n: number) => d.add(n, 'year')
            break
    }

    let maxValue = 0

    for (let i = 0; i < labelCount; i++) {
        let thisDate = periodIncrementer(startDate.clone(), i)
        labels[i] = thisDate.format(labelFormat)
        captions[i] = captionFormatter(thisDate, null, data['total_by_date'][thisDate.format('YYYY-MM-DD')])
        const dataPoint = {
            x: labels[i],
            y: parseFloat(data['total_by_date'][thisDate.format('YYYY-MM-DD')] || 0),
        }
        if (dataPoint.y > maxValue) {
            maxValue = dataPoint.y
        }
        graphData.push(dataPoint)
    }

    let totalLookup: any = {}
    if (data['extra_results'].length) {
        // Convert the extra results array into an object for O(1) lookups, after initial O(n) hit
        totalLookup = keyBy(data['extra_results'], 'id')
    }

    for (let i = 0; i < data['total_by_type']['keys'].length; i++) {
        const key = data['total_by_type']['keys'][i]

        let extraObject
        if (data['extra_results'].length) {
            let defaultName
            if (topType === 'item') defaultName = '- No Name -'
            else if (topType === 'employee') defaultName = '- No Pro -'
            else if (topType === 'source') defaultName = key || '- No Source -'
            extraObject = totalLookup[key] || {name: defaultName, id: Math.random()}
        }
        else {
            if (topType === 'date') extraObject = {name: key, id: Math.random()}
            else if (topType === 'source') extraObject = {name: key || '- No Source -', id: Math.random()}
            else if (topType === 'item') extraObject = {name: '- No Item -', id: Math.random()}
            else if (topType === 'employee') extraObject = {name: '- No Pro -', id: Math.random()}
        }

        if (extraObject) {
            extraObject['__react_total'] = parseFloat(data['total_by_type']['totals'][i])
            totals.push(extraObject)
        }
    }

    return {
        graphData,
        firstLabel: labels[0],
        lastLabel: labels[labels.length-1],
        maxValue,
        totals,
    }
}

const setDashboards = (dashboards: Dashboard[], tabs: Tab[], activeTab: string, edition?: Edition) => {
    return { type: 'dashboard/SET_DASHBOARDS', dashboards, tabs, edition, activeTab }
}

const setDashboard = (dashboard: Dashboard) => {
    return { type: 'dashboard/SET_DASHBOARD', dashboard }
}

const loadedDashboard = (dashboardResponse: any) => {
    return { type: 'dashboard/LOADED_DASHBOARD', dashboardResponse }
}

export const fetchDashboard = (dashboardID: string, dateFilter: string, viewFilter: string, person?: string) => {
    return (dispatch: any): any => {
        dispatch(dashboardRequest(true))

        const data = {
            view: Number(viewFilter),
            date_type: dateFilter,
            person
        }

        return apiClient.post(`/dashboard/${dashboardID}`, data)
            .then(resp => resp.data)
            .then(json => {
                dispatch(loadedDashboard(json))
                // Update entities with the extra_results
                const extraResults: any = {}
                json.extra_types.forEach((t: string, index: number) => {
                    if (!extraResults[t]) extraResults[t] = []
                    extraResults[t].push(json.extra_results[index])
                })
                Object.keys(extraResults).forEach((key: string) => {
                    const entityKey = `${key}s`
                    const dataMap = keyBy(extraResults[key], 'id')
                    dispatch({type: 'entities/UPDATE', name: entityKey, map: dataMap})
                })
            })
            .catch(error => dispatch(processError(error)))
            .finally(() => {
                dispatch(dashboardRequest(false))
            })
    }
}

export const filterDashboardItems = (dashboards: Dashboard[], sortOrder?: string[]) => {
    sortOrder = sortOrder || [
        'complete',
        'invoices',
        'bookings',
        'packages',
        'employees',
        'subscriptions',
        'pending',
        'clients',
        'links',
        'deposits',
        'records',
        'classes',
        'contracts',
        'estimates',
        'triggers',
        'inventory',
        'items',
        'tasks',
        'expenses',
        'cancellations',
        'billings',
        'gifts',
        'messages',
        'rebooks'
    ]

    return dashboards
        .filter(a => sortOrder!.indexOf(a.id) > - 1)
        .sort((a, b): number => {
            const aIndex = sortOrder!.indexOf(a.id)
            const bIndex = sortOrder!.indexOf(b.id)
            return aIndex - bIndex
        })
}

export const fetchDashboards = (tab: 'main' | 'account', person?: string, cancelToken?: CancelToken) => {
    return (dispatch: any): Promise<any> => {
        dispatch(dashboardRequest(true))

        return apiClient.post('/dashboards', {tab, person}, {cancelToken})
            .then(resp => resp.data)
            .then(json => {
                const dashboards: Dashboard[] = json.dashboards.map((data: DashboardInstance) =>
                    new Dashboard(data))
                const tabs: Tab[] = json.tabs.map((data: any) => new Tab(data))
                dispatch(setDashboards(dashboards, tabs, tab, json.edition))

                localforage.setItem(dashboardKey(tab, person), {dashboards, tabs, edition: json.edition})
            })
            .catch((_: Cancel) => { })
            .catch((error: any) => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => dispatch(dashboardRequest(false)))
    }
}

export const markDashboard = (dashboardId: string, setting?: string, label?: string) => {
    return (dispatch: any): any => {
        dispatch(dashboardRequest(true))

        return apiClient.post(`/dashboard/${dashboardId}/mark`, { setting, label })
            .then(resp => resp.data?.dashboard)
            .then((dashboard: DashboardInstance) => {
                if (dashboard) {
                    dispatch(setDashboard(new Dashboard(dashboard)))
                }
            })
            .catch(error => dispatch(processError(error)))
            .finally(() => dispatch(dashboardRequest(false)))
    }
}

export const requestCall = (user: UserInstance) => {
    return (dispatch: any): any => {
        dispatch(dashboardRequest(true))
        return apiClient.post(`/user/${user.id}/request-call`, null)
            .catch(error => dispatch(processError(error)))
            .finally(() => dispatch(dashboardRequest(false)))
    }
}

export const switchTab = (tab: Tab) => {
    return { type: 'dashboard/SWITCH_TAB', tab }
}
