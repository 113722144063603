import React from "react";
import { WEB_BASE } from "../../../modules/apiclient";

export const PrivacyAndTerms = () => {
    return (
        <div className="supportSettings">
            <div className="header">
                <h1 className="title">Privacy & terms</h1>
            </div>
            <div className="separator" />
            <div className="supportCard">
                <div className="left column">
                    <div className="title">Privacy policy</div>
                    <div className="details">View the PocketSuite privacy policy</div>
                </div>
                <div className="view">
                    <div onClick={() => window.open(`${WEB_BASE}/privacy/`, "_blank")} className="buttonLink">View</div>
                </div>
            </div>
            <div className="supportCard">
                <div className="left column">
                    <div className="title">Terms of service</div>
                    <div className="details">View the PocketSuite terms of service</div>
                </div>
                <div className="view">
                    <div onClick={() => window.open(`${WEB_BASE}/terms-service/`, "_blank")} className="buttonLink">View</div>
                </div>
            </div>
        </div>
    )
}