import { Checkbox, Field } from "@zendeskgarden/react-forms";
import React, { useEffect, useState } from "react";
import {ReactComponent as ProductIcon} from "../../../icons/ps_items_products_blue.svg";
import {ReactComponent as ClassIcon} from "../../../icons/ps_items_classes_blue.svg";
import {ReactComponent as ServiceIcon} from "../../../icons/ps_items_services_blue.svg";
import { currencyFormat } from "../../../utils/numbers";

type Props = {
    item: any,
    selected: boolean,
    view: ('list' | 'widget'),
    onItemDeselected: (item: any) => void,
    onItemSelected: (item: any) => void
}

export const ChargeItemCard = ({item, selected, view, onItemDeselected, onItemSelected}: Props) => {
    const [isSelected, setIsSelected] = useState(false)

    useEffect(() => {
        setIsSelected(selected)
    }, [selected])

    const onClick = () => {
        setIsSelected(value => {
            if (value) {
                onItemDeselected(item)
            }
            else {
                onItemSelected(item)
            }
            return !value;
        })
    }

    const defaultImage = () => {
        const height = 64;
        const width = 64
        return {
            'product': <ProductIcon className="default" height={height} width={width} />,
            'class': <ClassIcon className="default" height={height} width={width} />
        }[item.type as string] ?? <ServiceIcon className="default" height={height} width={width} />
    }

    return <>
        <div onClick={onClick} className={`itemCard ${isSelected ? 'selected' : ''}`}>
            {view === 'list' &&
                <Field className="checkboxField">
                    <Checkbox style={{position: 'relative'}}
                        checked={isSelected}>
                    </Checkbox>
                </Field>
            }

            <div className="imgContainer"
                style={{
                    background: (item.image_uri ? `url('${item.image_uri}') no-repeat center` : '#E6EAEE')
                }}
            >
                {!item.image_uri && defaultImage()}
            </div>
            <div className="title">
                {item.name}
            </div>
            <div className="cost">
                {currencyFormat(item.rate)}
            </div>
        </div>
        {view === 'list' && <div className="itemCard separator" />}
    </>
}