import React from "react";
import {Dropdown, Item, Menu, Trigger} from "@zendeskgarden/react-dropdowns";
import {IconButton} from "@zendeskgarden/react-buttons";
import {ReactComponent as KabobIcon} from "../../../icons/kabob_menu.svg";
import {ReactComponent as CalendarSyncIcon} from "../../../icons/ps_settings_calendary_sync_nobg.svg";
import {Credential} from "../../../models/Credential";

type Props = {
    state?: 'connected' | 'disconnected' | 'unconfigured',
    credential?: Credential,
    title?: string,
    subtitle?: string,
    icon?: any,
    onDisconnect?: () => void,
    onEdit?:() => void
}

export const CredentialCard = ({state, credential, title, subtitle, icon, onDisconnect, onEdit}: Props) => {
    let kabobMenuItems

    if (!!onDisconnect && !!credential) {
    kabobMenuItems = (
            <>
                <Item onClick={() => onDisconnect && onDisconnect()} value="disconnect">
                    <span style={{color: 'red'}}>
                        Disconnect Sync
                    </span>
                </Item>
            </>
        )
    }

    const subtitleForState = () => {
        if (state === 'connected') {
            return {subtitle: "Connected", color:"#20BC52"}
        }
        else if (state === 'disconnected') {
            return {subtitle: "Disconnected", color:"#E22216"}
        }
        return {subtitle: "Setup calendar", color:"#999999"}
    }

    const actionForState = () => {
        if (state === 'disconnected') {
            return 'Reconnect'
        }
        return 'Edit'
    }

    return (
         <div className="calendarCard">
             {icon ? icon : <CalendarSyncIcon />}
             <div className="details">
                <div className="name">{title || credential?.email}</div>
                    <div className="rate">
                        <span style={{color: subtitleForState().color ?? "#999999"}}>
                           {subtitle || subtitleForState().subtitle}
                        </span>
                </div>
            </div>
            <div className="edit">
                {onEdit && (
                    <div className="view">
                        <div onClick={onEdit} className="view buttonLink brightBlue">
                            {actionForState()}
                        </div>
                    </div>
                )}
            </div>
            {kabobMenuItems && (
                <Dropdown>
                    <Trigger>
                        <IconButton focusInset={false}>
                            <KabobIcon />
                        </IconButton>
                    </Trigger>
                    <Menu hasArrow>
                        {kabobMenuItems}
                    </Menu>
                </Dropdown>
            )}
        </div>
    )
}