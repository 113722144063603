import React, {useCallback, useEffect, useState} from "react";
import {useAppDispatch} from "../../../hooks";
import {fetchFeatures,SettingsState, submitFeatures} from "../../../modules/settings";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {Dots, Spinner} from "@zendeskgarden/react-loaders";
import { PSButtonPrimary } from "../../app/PSButton";
import { FeaturesList } from "./FeaturesList";
import { UserInstance } from "../../../models/User";
import { Feature } from "../../../models/Feature";
import { isEqual } from "lodash";
import localforage from "localforage";

export const FeatureEdit = () => {
    const dispatch = useAppDispatch()
    const { features, isSending }: SettingsState = useSelector((state: RootState) => state.settings)
    const [user, setUser] = useState<UserInstance>()
    const [editedUser, setEditedUser] = useState<UserInstance>()
    const [hasChanges, setHasChanges] = useState(false)
    const [showHeader, setShowHeader] = useState(true)

    useEffect(() => {
        if (features.length > 0) return
        dispatch(fetchFeatures())
    }, [dispatch, features.length])

    useEffect(() => {
        if (isSending) return
        localforage.getItem('user').then((u: any) => {
            const user = u as UserInstance
            setUser(user)
            setEditedUser(user)
        })
    }, [isSending])

    useEffect(() => {
        if (!user || !editedUser) return
        setHasChanges(!isEqual(user, editedUser))
    }, [user, editedUser])

    const onChange = useCallback((feature: Feature) => {
        if (isSending || !user) return

        setEditedUser(lastEditedUser => {
            const lastUser = lastEditedUser ?? user
            const featureKey = `allow_${feature.id}` as keyof UserInstance
            const isValidChange = lastUser.hasOwnProperty(featureKey) && lastUser[featureKey] !== feature.isEnabled

            return isValidChange ? { ...lastUser, ...{[featureKey]: feature.isEnabled} } : {...lastUser}
        })
    }, [isSending, user])

    const onSave = useCallback(() => {
        if (isSending || !editedUser) return
        dispatch(submitFeatures(editedUser))
    }, [isSending, editedUser, dispatch])

    return (
        <div className="servicesSettings">
            {showHeader && (
                <>
                    <div className="header">
                        <h1>Features</h1>
                        <div className="right">
                            <PSButtonPrimary
                                onClick={onSave}
                                disabled={isSending || !hasChanges}>
                                {isSending ? <Dots /> : 'Save' }
                            </PSButtonPrimary>
                        </div>
                    </div>
                    <div className="separator" />
                    {isSending && features.length === 0 && (
                        <div style={{textAlign: 'center', padding: '128px 0'}}>
                            <Spinner size="128" color="#314A68" />
                        </div>
                    )}
                </>
            )}
            <FeaturesList features={features}
                          onChange={feature => onChange(feature)}
                          onUpgrade={opts => setShowHeader(opts?.cancel ?? false)} />
        </div>
    )
}