import { Button, ToggleButton } from '@zendeskgarden/react-buttons'
import styled from 'styled-components'

export const PSButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  padding: 0 16px;
  line-height: 16px;
  font-size: 13px;
  font-family: 'DM Sans', sans-serif;

  &:hover {
    border: 1px solid #f5f5f5;
  }
  
  &:active, &[aria-pressed='true'] {
    background-color: #314A68;
  }
  
  &:disabled {
    background: #ffffff;
    border: 1px solid #f5f5f5;
    color: #314A68;
    opacity: 0.4;
  }
`

export const PSButtonDanger = styled(PSButton)`
  border: 1px solid #e6e6e6;
  background: #ffffff;
  color: #e22216;
  border-radius: 8px;
  
  &:hover {
    border: 1px solid #e22216;
    background: #f5f5f5;
    color: #e22216;
  }
  
  &:active, &[aria-pressed='true'] {
    border: 1px solid #e22216;
    background: #fbd7d5;
    color: #e22216;
  }
`

export const PSButtonPrimary = styled(PSButton)`
  border: none;
  background: #314a68;
  color: #ffffff;

  &:hover {
    border: none;
    background: #314a68;
    color: #dddddd;
  }

  &:active, &[aria-pressed='true'] {
    border: none;
    color: #999999;
  }
  
  &:disabled {
    border: none;
    background: #314a68;
    opacity: 0.4;
  }
  
  &:disabled, &:active {
    color: #ffffff;
  }
`

export const PSToggleButton = styled(ToggleButton)`
  margin: 16px 0 0 16px;
  height: 48px;
  border-color: #cecece;
  color: #999999;
  background-color: #ffffff;
  min-width: 136px;
  
  &:hover {
    background-color: #ffffff;
    color: #999999;
  }
  
  &:active, &[aria-pressed='true'] {
    background-color: #ffffff;
    color: #314a68;
  }
`

export const PSButtonSecondary = styled(PSButton)`
  height: 48px;
  border: 1px solid #314a68;
  
  &:hover {
    border: 1px solid #314a68;
  }
`

export const PSButtonSimple = styled(PSButton)`
  height: 48px;
  border: 1px solid #e6e6e6;
  
  &:hover {
    border: 1px solid #e6e6e6;
  }
`

export const DangerButton = styled(Button)`
    background-color: #EB5757;
  
    &:hover,
    &:focus,
    &[data-garden-focus-visible] {
      background-color: #e84040;
    }

    &:active,
    &[aria-pressed='true'],
    &[aria-pressed='mixed'] {
      background-color: #e52929;
    }
`

export const PSMainButton = styled(PSButton)`
  height: 44px;
  background-color: #314A68;
  color: #ffffff;
  font-weight: 700;

  &:hover,
  &[data-garden-focus-visible] {
    background-color: #314A68;
    opacity: 0.8;
    color: #dddddd;
  }
  
  &:active,
  &[aria-pressed='true'],
  &[aria-pressed='mixed'] {
    background-color: #314A68;
    opacity: 1;
  }

  &:disabled {
    border: none;
    background-color: #314A68;
    color: #ffffff;
    opacity: 0.3;
  }
`

export const SuccessModalButton = styled(Button)`
    background-color: #219653;
  
    &:hover,
    &:focus,
    &[data-garden-focus-visible] {
      border: 1px solid #000000;
      background-color: #219653;
    }

    &:active,
    &[aria-pressed='true'],
    &[aria-pressed='mixed'] {
      background-color: #FFFFFF;
      color: #219653;
    }
`

export const DangerModalButton = styled(Button)`
    background-color: #E22216;
  
    &:hover,
    &:focus,
    &[data-garden-focus-visible] {
      background-color: #E22216;
    }

    &:active,
    &[aria-pressed='true'],
    &[aria-pressed='mixed'] {
      background-color: #FFFFFF;
      color: #E22216;
    }
`