import React from "react";
import {AppHeader} from "../app/AppHeader";
import {SalesTaxEdit} from "../settings/payments/SalesTaxEdit";
import {useHistory} from "react-router";

export const OnboardingSetupPaymentsContinuedTax = () => {
    const history = useHistory()

    const onNextHandler = () => {
        history.goBack()
    }

    return (
        <AppHeader title="Setup" showBackButton middleWidget={null}>
            <div className="scheduleClient servicesSettings onboarding onboardingSetupPayments">
                <div className="pageWrapper" style={{paddingTop: 0, paddingLeft: 0, maxWidth: 600, marginLeft: -32}}>
                    <SalesTaxEdit headerTitle="Sales tax"
                                  buttonTitle="Done"
                                  onComplete={() => onNextHandler()} />
                </div>
            </div>
        </AppHeader>
    )
}