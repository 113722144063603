import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Prompt, useHistory, useLocation} from "react-router";
import {adjustmentTypeTitle, InventoryAdjustment, InventoryItem} from "../../models/InventoryAdjustment";
import {PSButtonDanger, PSButtonPrimary} from "../app/PSButton";
import {Dots} from "@zendeskgarden/react-loaders";
import {Field, Input, Label} from "@zendeskgarden/react-forms";
import {filterMonetaryInput, filterNumericInput} from "../../utils/numbers";
import {GuardrailModal} from "../settings/GuardrailModal";
import moment from "moment";
import {addAdjustment, deleteAdjustment, updateAdjustment} from "../../modules/inventory";
import {Result} from "../../utils";
import {findSelection, SelectOption} from "../settings/Options";
import {Item} from "@zendeskgarden/react-dropdowns";
import {PSDropdown} from "../app/PSDropdown";
import {PSLearnMore} from "../app/PSLearnMore";
import {DangerModal} from "../settings/DangerModal";
import {AppHeader} from "../app/AppHeader";

export const EditInventory = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const location: {item?:InventoryItem, adjustment?:InventoryAdjustment} = useLocation().state as {item?:InventoryItem, adjustment?:InventoryAdjustment};
    const item = location.item;
    const adjustment = location.adjustment;

    const forEdit = !!adjustment;
    const record = adjustment ?? (item ? new InventoryAdjustment(item) : new InventoryAdjustment(new InventoryItem(undefined)));

    const typeOptions: SelectOption[] = [
        { value: 'resupply', label: `${adjustmentTypeTitle('resupply')} (Add)`},
        { value: 'recount', label: `${adjustmentTypeTitle('recount')} (Add)`},
        { value: 'sold', label: `${adjustmentTypeTitle('sold')} (Reduce)`},
        { value: 'lost', label: `${adjustmentTypeTitle('lost')} (Reduce)`},
        { value: 'expired', label: `${adjustmentTypeTitle('expired')} (Reduce)`},
        { value: 'internal', label: `${adjustmentTypeTitle('internal')} (Reduce)`}
    ]

    const [name, setName] = useState<string>('');
    const [date, setDate] = useState<undefined | string>();
    const [type, setType] = useState<undefined | SelectOption>(typeOptions[0]);
    const [quantity, setQuantity] = useState<undefined | number>();
    const [total, setTotal] = useState<undefined | number>();

    if (!forEdit){
        record.type = typeOptions[0].value;
    }

    const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [hasEdits, setHasEdits] = useState(false);
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [formSending, setFormSending] = useState(false);

    useEffect(() => {
        if (!record) return;
        setName(record.item.name);
        if (forEdit) {
            setDate(record.created_date);
            setType(findSelection(typeOptions, record.type));
            setQuantity(parseInt(record.quantity));
            setTotal(parseFloat(record.total));
        }
    }, [record]);

    useEffect(() => {
        const c1 = type!.value !== record.type ||
            (quantity !== undefined && quantity != parseInt(record.quantity)) ||
            (total !== undefined && total != parseFloat(record.total));
        const c2 = quantity !== undefined && quantity >= 1 && total !== undefined && total >= 0;
        setHasEdits(c1);
        setSaveEnabled(c1 && c2);
    }, [type, quantity, total]);

    const goBack = () => history.goBack();

    const onSave = () => {
        if (formSending || !saveEnabled || !record) return;

        record.type = type?.value ?? '';
        record.quantity = quantity?.toString() ?? '';
        record.total = total?.toString() ?? '';

        setFormSending(true);

        if (forEdit){
            setShowUpdateConfirmation(true);
        }
        else {
            Result(dispatch(addAdjustment(record))).then(goBack);
        }
    };

    const [learnMoreComponent, showLearnMore] = PSLearnMore([
        { key: 'cost', title: 'About total cost', body: 'Total cost is the total amount of money associated with buying or selling the specified quantity of this product, whether you add to or reduce the inventory. Total cost = Quantity x Unit cost' }
    ]);

    let body = (
        <div className="servicesSettings">
            <div className="header">
                <h1>Change quantity</h1>

                {forEdit && (
                    <PSButtonDanger style={{height: '40px'}}
                                    onClick={() => {
                                        setFormSending(true);
                                        setShowDeleteConfirmation(true);}}
                    >
                        {showDeleteConfirmation && formSending && <Dots />}
                        {!showDeleteConfirmation && "Delete"}
                    </PSButtonDanger>
                )}

                <PSButtonPrimary style={{height: '40px', marginLeft: forEdit ? '16px' : 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!saveEnabled || formSending}>
                    {!showDeleteConfirmation && formSending && <Dots />}
                    {(!formSending || showDeleteConfirmation) && (forEdit ? "Update" : "Save")}
                </PSButtonPrimary>
            </div>

            <div className="form">
                <Field className="field">
                    <Label className="label">Product</Label>
                    <Input value={name}
                           disabled={true} />
                </Field>

                {forEdit && (
                    <Field className="field">
                        <Label className="label">Date</Label>
                        <Input value={moment(date).format('MMM D, YYYY')}
                               disabled={true} />
                    </Field>
                )}

                <PSDropdown selected={type}
                            nameProperty={"label"}
                            label={"Type (required)"}
                            onSelect={(selection) => setType(selection)}
                            disabled={formSending}>
                    <>
                        {typeOptions.map(option => (
                            <Item key={`type-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>

                <Field className="field">
                    <Label className="label">Quantity (required)</Label>
                    <Input placeholder="Quantity"
                           value={quantity}
                           onChange={e => filterNumericInput(e.target.value, setQuantity)}
                           disabled={formSending} />
                </Field>

                <Field className="field">
                    <Label className="label">Total cost (required)</Label>
                    <Input placeholder="Total cost"
                           value={total}
                           onChange={e => filterMonetaryInput(e.target.value, setTotal)}
                           disabled={formSending} />
                </Field>

                <div className="buttonLink brightBlue"
                     style={{display: 'inline-block', paddingTop: 8}}
                     onClick={() => showLearnMore('cost')}>
                    Learn more &gt;
                </div>
            </div>
            <Prompt
                when={hasEdits && !formSending}
                message="Are you sure you'd like to leave this page without saving your changes?" />
            {showDeleteConfirmation && (
                <DangerModal title="Confirm change"
                             body="Confirm that you'd like to delete this adjustment."
                             buttonText="Delete adjustment"
                             disableDismiss={true}
                             onClose={() => {
                                 setFormSending(false);
                                 setShowDeleteConfirmation(false);
                             }}
                             onAction={() => Result(dispatch(deleteAdjustment(record))).then(goBack)}
                />
            )}
            {showUpdateConfirmation && (
                <GuardrailModal title="Confirm change"
                                body="Confirm that you'd like to update this record."
                                buttonText="Update record"
                                disableDismiss={true}
                                onClose={() => {
                                    setFormSending(false);
                                    setShowUpdateConfirmation(false);
                                }}
                                onAction={() => Result(dispatch(updateAdjustment(record))).then(goBack)}
                />
            )}
            {learnMoreComponent}
        </div>
    );

    return (
        <AppHeader title='Inventory' showBackButton={true} middleWidget={null}>
            <div className="pageWrapper">
                {!!record.item.id && body}
            </div>
        </AppHeader>
    )
}