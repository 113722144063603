import React, {useEffect, useState} from "react";
import {
    Body, Close,
    Footer,
    FooterItem,
    Header,
    Modal
} from "@zendeskgarden/react-modals";
import {Item} from "@zendeskgarden/react-dropdowns";
import {Button} from "@zendeskgarden/react-buttons";
import {PSDropdown} from "../../app/PSDropdown";
import {SelectOption, taxCodeOptions} from "../../settings/Options";
import {apiClient} from "../../../modules/apiclient";
import {TaxcodeInstance} from "../../../models/Taxcode";
import {LocationAutocomplete} from "../../app/LocationAutocomplete";
import {TaxNexus} from "../../../models/Transaction";
import { usePSOwner } from "../../../hooks";

type Props = {
    currentData?: {nexus: TaxNexus, location: string},
    onDone: (nexus: TaxNexus, location: string, taxCode: TaxcodeInstance | undefined) => void,
    onCancel: () => void,
}

export const SalesTaxModal = ({currentData, onDone, onCancel}: Props) => {
    const [salesTaxType, setSalesTaxType] = useState(taxCodeOptions[0])
    const [taxOptions, setTaxOptions] = useState(taxCodeOptions.slice())
    const [location, setLocation] = useState(currentData?.location ?? '')
    const [locationEnabled, setLocationEnabled] = useState(false)
    const [doneEnabled, setDoneEnabled] = useState(false)

    const owner = usePSOwner()
    const [businessLocationTaxCode, setBusinessLocationTaxCode] =
        useState<TaxcodeInstance | undefined>()
    const [transactionLocationTaxCode, setTransactionLocationTaxCode] =
        useState<TaxcodeInstance | undefined>()

    useEffect(() => {
        if (businessLocationTaxCode) return

        if (owner?.taxcode) {
            apiClient.post(`/taxcode`, {'taxcode': owner.taxcode})
                .then(resp => resp.data)
                .then(json => {
                    if (json)
                        setBusinessLocationTaxCode(json as TaxcodeInstance)
                })
        }
    }, [owner, currentData, taxOptions, businessLocationTaxCode])

    // Set the default selected option
    useEffect(() => {
        const selectedTaxType = taxOptions.find((option) => option.value === currentData?.nexus)
        setSalesTaxType(selectedTaxType || (owner?.location ? taxCodeOptions[1] : taxCodeOptions[0]))
    }, [currentData, owner]) // ignore taxOptions on purpose

    // Update the business location to show the tax rate in the selection
    useEffect(() => {
        const options = taxCodeOptions.slice()
        if (businessLocationTaxCode && options[1].label.indexOf(Number(businessLocationTaxCode?.rate).toString()) === -1) {
            options[1].label += ` · ${Number(businessLocationTaxCode.rate)}%`
            setTaxOptions(options)
        }
    }, [businessLocationTaxCode])

    // Update the transaction location to show the tax rate in the selection
    useEffect(() => {
        const options = taxCodeOptions.slice()
        if (transactionLocationTaxCode && options[2].label.indexOf(Number(transactionLocationTaxCode?.rate).toString()) === -1) {
            options[2].label = `At transaction location · ${Number(transactionLocationTaxCode?.rate)}%`
            setTaxOptions(options)
        }
    }, [transactionLocationTaxCode])

    // Update the transaction tax code when the address changes
    useEffect(() => {
        if (!location) {
            setTransactionLocationTaxCode(undefined)
            return
        }

        apiClient.post(`/taxcode`, {location})
            .then(resp => resp.data)
            .then(json => {
                if (json)
                    setTransactionLocationTaxCode(json as TaxcodeInstance)
            })
    }, [location])

    useEffect(() => {
        if (salesTaxType.value !== 'transaction')
            setLocation('') // TODO Fix LocationAutocomplete to work like this after the initial address has been selected
        setLocationEnabled(salesTaxType.value === 'transaction')
    }, [salesTaxType])

    useEffect(() => {
        setDoneEnabled(salesTaxType.value !== 'transaction' || (transactionLocationTaxCode?.name ?? '').length > 0)
    }, [salesTaxType, transactionLocationTaxCode])

    const onSelectSalesTaxType = (selection: SelectOption) => {
        setSalesTaxType(selection)
    }

    const onDoneHandler = () => {
        let selectedTaxCode
        switch (salesTaxType.value) {
            case 'pro':
                selectedTaxCode = businessLocationTaxCode
                break
            case 'transaction':
                selectedTaxCode = transactionLocationTaxCode
                break
            default:
                break
        }
        onDone(salesTaxType.value as 'pro' | 'transaction' | '', location, selectedTaxCode)
    }

    return (
        <Modal className="salesTaxModal modal"
               onClose={() => onCancel()}
        >
            <Header className="header">
                <div className="title">Sales tax</div>
            </Header>
            <Body className="body form">
                <PSDropdown selected={salesTaxType}
                            nameProperty="label"
                            onSelect={(selection) => onSelectSalesTaxType(selection)}
                            label="Sales tax"
                            placeholder={<div className="grey">Please select</div>}>
                    <>
                        {taxOptions.map(option => (
                            <Item key={`tax-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>
                <LocationAutocomplete
                    label="Location"
                    currentAddress={location}
                    disabled={!locationEnabled}
                    updateAddress={(address) => {
                        if (address || (location?.length ?? 0) === 1)
                            setLocation(address)
                    }}
                />
            </Body>
            <Footer className="footer">
                <FooterItem>
                    <Button onClick={() => onCancel()}>Cancel</Button>
                </FooterItem>
                <FooterItem>
                    <Button isPrimary
                            disabled={!doneEnabled}
                            onClick={() => onDoneHandler()}
                    >
                        Done
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )
}