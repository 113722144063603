import React from 'react'
import PropType from 'prop-types'
import { useSelector } from 'react-redux'
import { DAY_FORMAT } from '../../modules/calendar'
import { ScheduleEvent } from './ScheduleEvent'

export const CalendarSchedule = (props) => {
    const state = useSelector(state => (
        {
            calendar: state.calendar,
            entities: state.entities
        }
    ))

    const renderEventsForDay = (day) => {
        let events = state.calendar.eventsByDate[day.format(DAY_FORMAT)]
        if (!events) return

        // sort by start time
        events = events.slice().sort((a, b) => {
            if (a.isMultiDay && b.isMultiDay) return a.start.isBefore(b.start) ? -1 : 1
            if (a.isMultiDay) return -1
            if (b.isMultiDay) return 1
            if (a.start.isSame(b.start)) return a.data.id - b.data.id
            return a.start.isBefore(b.start) ? -1 : 1
        })

        return events.map((item) => {
            return <ScheduleEvent key={`schedule-event-${item.data.id}`}
                                  event={item} />
        })
    }

    return (
        <div className="calendarSchedule">
            <div className="header">
                <div className="dow">{state.calendar.currentDate.format('dddd')}</div>
            </div>
            <div className="calendarBody" style={{height: `${props.height}px`}}>
                {renderEventsForDay(state.calendar.currentDate)}
            </div>
        </div>
    )
}

CalendarSchedule.props = {
    height: PropType.number,
}
