import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {useLocation} from "react-router";
import {AppHeader} from "../../app/AppHeader";
import {ReactComponent as CheckmarkImage} from "../../../icons/big_checkmark.svg";
import {Link} from "react-router-dom";
import {TransactionProgress} from "../TransactionProgress";
import { Estimate } from "../../../models/Estimate";

export const EstimateSuccess = () => {
    const state: any = useSelector((state: RootState) => state.entities)

    const location = useLocation()
    const locationState: any = location.state
    const estimateData: Estimate = locationState?.estimate
    const [estimate, setEstimate] = useState<Estimate>()
    useEffect(() => setEstimate(new Estimate(estimateData)), [estimateData])

    const user = state.users[estimate?.client ?? ''] || {}
    let successMessage = `Estimate #${estimate?.number ?? ''} was sent to ${user?.name || 'your client'}`

    return (
        <AppHeader title="Send estimate"
                   middleWidget={<TransactionProgress created reviewed sent secondStep="Preview" />}
        >
            <div className="transactionSuccess">
                <CheckmarkImage/>
                <h1>Success</h1>
                <div className="details">{successMessage}</div>

                <div className="links">
                    <div className="link">
                        <Link to="/estimate/new">Create another</Link>
                        <div>Create a new estimate</div>
                    </div>
                    <div className="link">
                        <Link to={`/record/estimate/${estimate?.id}`}>
                            View estimate
                        </Link>
                        <div>View this estimate record</div>
                    </div>
                    <div className="link">
                        <Link to="/messages">Inbox</Link>
                        <div>View your inbox</div>
                    </div>
                    <div className="link">
                        <Link to="/calendar">Calendar</Link>
                        <div>View your calendar</div>
                    </div>
                    <div className="link">
                        <Link to="/">Dashboard</Link>
                        <div>View your PocketSuite home screen</div>
                    </div>
                </div>
            </div>
        </AppHeader>
    )
}