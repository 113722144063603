import React from "react";
import {AppHeader} from "../../app/AppHeader";
import {CardEdit} from "../../settings/payment-methods/CardEdit";
import {useLocation} from "react-router";

export const ChargeAddCard = () => {
    const location = useLocation()
    const locationState: any = location.state
    const clientID: string = locationState?.clientID

    return (
        <AppHeader middleWidget={null}
                   showBackButton
                   title="Charge"
        >
            <CardEdit clientID={clientID} />
        </AppHeader>
    )
}