import React, {useCallback, useEffect, useState} from "react";
import {useAppDispatch, usePSOwner, usePSUser} from "../../hooks";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {
    Header,
    Modal,
    Body,
    Footer,
    FooterItem
} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import "./transactions.css";
import {searchContacts, syncContacts, clearSearch} from "../../modules/contacts";
import debounce from "lodash.debounce";
import {fetchProAvailability, fetchPros} from "../../modules/transactions";
import {fetchItems} from "../../modules/settings";
import {SelectModalCard} from "./SelectModalCard";
import moment from "moment";
import {Spinner} from "@zendeskgarden/react-loaders";
import {Item as PSItem} from "../../models/Item";
import {GuardrailModal} from "../settings/GuardrailModal";
import {PSDropdown} from "../app/PSDropdown";
import {Item} from "@zendeskgarden/react-dropdowns";
import {SelectOption} from "../settings/Options";
import {apiClient} from "../../modules/apiclient";
import keyBy from "lodash.keyby";

type Props = {
    type: 'pros' | 'clients' | 'services' | 'products' | 'items' | 'expenses' | 'appointments' | 'subscriptions' | 'packages' | 'gifts' | 'export',
    multiSelect?: boolean,
    onSelect: (item: any[], extraData: any) => void,
    onCancel: () => void,
    currentSelections: any[],
    data?: any[],
    currentExtraData?: any,
    currentItem?: PSItem[],
    prepaidItems?: string[],
    currentDate?: Date,
    currentDuration?: number
}

export const SelectModal = ({type, multiSelect, onSelect, onCancel, data, currentSelections, currentExtraData, currentItem, prepaidItems, currentDate, currentDuration}: Props) => {
    const dispatch = useAppDispatch()
    const state: Pick<RootState, "settings" | "contacts" | "entities"> = useSelector((state: RootState) => ({
        settings: state.settings,
        contacts: state.contacts,
        entities: state.entities,
    }))

    const [loadingData, setLoadingData] = useState(false)
    useEffect(() => {
        setLoadingData(state.contacts.isSearching)
    }, [state.contacts.isSearching])

    const [selectionList, setSelectionList] = useState<any[]>([])
    const [selections, setSelections] = useState<any[]>(currentSelections)
    const [selectionExtraData, setSelectionExtraData] = useState<any>(currentExtraData || {})
    const [doneEnabled, setDoneEnabled] = useState(false)
    useEffect(() => {
        // Allow no items to be set, to clear the input

        if (type === 'products') {
            // Ensure quantity is set for all selected products
            const missingQuantities = selections
                .filter((s: any) => Number(selectionExtraData[s.id] || 0) < 1)
            setDoneEnabled(missingQuantities.length === 0)
        }
        else
            setDoneEnabled(true)
    }, [type, selections, selectionExtraData])

    const owner = usePSOwner()
    const [searchText, setSearchText] = useState('')
    const [filteredSelectionList, setFilteredSelectionList] = useState<any[]>(data ?? [])
    useEffect(() => {
        if (type === 'appointments') return

        // If this is for clients, search on the server if we have more to load
        if (type === 'clients' && state.contacts.pagination.hasMore) {
            if (searchText && searchText !== state.contacts.searchText) {
                dispatch(searchContacts(searchText))
                return
            }
            else if (searchText === '' && (state.contacts.isSearching || state.contacts.didSearch)) {
                dispatch(clearSearch())
            }
            else if (state.contacts.didSearch) {
                setFilteredSelectionList(state.contacts.searchResults)
                return
            }
        }

        if (!searchText)
            setFilteredSelectionList(selectionList)
        else {
            const escapedSearchText = searchText.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')
            const regex = new RegExp(escapedSearchText, 'i')
            setFilteredSelectionList(selectionList.filter(i => i.name.match(regex)))
        }
    }, [
        state.contacts.didSearch, state.contacts.searchResults,
        state.contacts.searchText, state.contacts.isSearching,
        state.contacts.pagination.hasMore,
        type, dispatch, searchText, selectionList,
    ])

    // Item filters for the 'items' type modal, for /charge/new/lineitem
    const [availableItemFilters, setAvailableItemFilters] = useState<SelectOption[]>([
        {label: 'All items', value: ''},
        {label: 'Service', value: 'service'},
    ])
    const [loadedItems, setLoadedItems] = useState(false)
    const [itemFilter, setItemFilter] = useState(availableItemFilters[0])
    useEffect(() => {
        if (type !== 'items' || !owner) return

        // Set the item filter list
        const options = [{label: 'All items', value: ''}, {label: 'Service', value: 'service'}]
        if (owner.allow_product)
            options.push({label: 'Product', value: 'product'})
        if (owner.allow_class)
            options.push({label: 'Class', value: 'class'})
        if (owner.allow_reservation)
            options.push({label: 'Reservation', value: 'reservation'})
        setAvailableItemFilters(options)

        // Load items always to make sure our entities cache is up to date
        if (!loadedItems) {
            setLoadingData(true)
            apiClient.post('/item/sync')
                .then(resp => resp.data)
                .then(json => {
                    setLoadedItems(true)
                    const itemMap = keyBy(json.items, 'id')
                    dispatch({type: 'entities/UPDATE', name: 'items', map: itemMap})
                    setLoadingData(false)
                })
        }
    }, [type, owner, state.entities.items, loadedItems, dispatch])

    useEffect(() => {
        // TODO Load items based on filter
    }, [itemFilter])

    // Load the data based on type that we are selecting
    const [proAvailability, setProAvailability] = useState<any>({})
    useEffect(() => {
        switch (type) {
            case "pros":
                setLoadingData(true)
                const promises = []
                promises.push(dispatch(fetchPros()))
                // TODO Add itemID parameter to fetchProAvailability, if there is one
                promises.push(
                    dispatch(fetchProAvailability({duration: currentDuration ?? 60, start: currentDate ?? new Date()}))
                        // @ts-ignore
                        .then((json: any[]) => {
                            const newAvailability: any = {}
                            json.forEach((jsonAvailability: any) => {
                                newAvailability[jsonAvailability.person.id] = jsonAvailability.availability[0].available
                            })
                            setProAvailability(newAvailability)
                        })
                )
                Promise.all(promises).then(() => setLoadingData(false))
                break
            case "clients":
                if (state.contacts.contacts.length === 0 && !loadingData) {
                    setLoadingData(true)
                    // @ts-ignore
                    dispatch(syncContacts('', null)).then(() => setLoadingData(false))
                }
                break
            case "services":
            case "products":
            case "subscriptions":
            case "packages":
            case "gifts":
                if (data) {
                    setSelectionList(data)
                }
                else {
                    setLoadingData(true)
                    // @ts-ignore
                    dispatch(fetchItems()).then(() => setLoadingData(false))
                }
                break
            case "appointments":
            case "expenses":
            case "items":
                break

        }

        // omit loadingData to prevent endless update loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, type, state.contacts.contacts, data])

    useEffect(() => {
        switch (type) {
            case "pros":
                const payeeUsers: any[] = []
                Object.keys(state.entities.payees).forEach((k: string) => {
                    const payee = state.entities.payees[k]
                    if (state.entities.users[payee.person])
                        payeeUsers.push(state.entities.users[payee.person])
                })
                setSelectionList(payeeUsers)
                break
            case "clients":
                setSelectionList(state.contacts.contacts)
                break
            case "services":
                const allowedServices = state.settings.items
                    .filter((i: any) => !i.end_date || moment(i.end_date).isAfter(moment()))
                    .filter((i: any) => ['service', 'class', 'reservation'].indexOf(i.type) > -1)
                setSelectionList(allowedServices)
                break
            case "products":
                const allowedProducts = state.settings.items
                    .filter((i: any) => i.type === 'product')
                setSelectionList(allowedProducts)
                break
            case "subscriptions":
                const allowedSubscriptions = state.settings.items
                    .filter((i: any) => i.type === 'subscription')
                setSelectionList(allowedSubscriptions)
                break
            case "packages":
                const allowedPackages = state.settings.items
                    .filter((i: any) => i.type === 'package')
                setSelectionList(allowedPackages)
                break
            case "gifts":
                const allowedGifts = state.settings.items
                    .filter((i: any) => i.type === 'gift')
                setSelectionList(allowedGifts)
                break
            case "items":
                const allowedItems: any[] = []
                Object.keys(state.entities.items).forEach((k: string) => {
                    const item = state.entities.items[k]
                    if (!itemFilter.value || item.type === itemFilter.value)
                        allowedItems.push(item)
                })
                setSelectionList(allowedItems)
                break
            case "appointments":
                if (data) {
                    // Convert the currentSelections from LineItem[] to Booking[]
                    const bookingSelections: any[] = []
                    currentSelections.forEach((s: any) => {
                        const bookingSelection = data.find(i => i.id === s.booking)
                        if (bookingSelection)
                            bookingSelections.push(bookingSelection)
                    })
                    setSelections(bookingSelections)
                }
                break
            case "export":
                if (data) {
                    setSelectionList(data)
                }
                break;
            case "expenses":
                break
        }
    }, [
        type, state.contacts.contacts, state.entities.payees,
        state.entities.users, state.settings.items, state.entities.items, itemFilter,
        data, currentSelections
    ])

    // Infinite scrolling for clients
    useEffect(() => {
        if (type !== 'clients') return

        const loadMore = (e: Event | undefined) => {
            if (e) {
                e.preventDefault()
            }

            const { hasMore, lastKey } = state.contacts.pagination

            if (hasMore) {
                dispatch(syncContacts(lastKey))
            }
        }

        const handleScroll = debounce(() => {
            const contactList = document.getElementById('clientList')
            if (!contactList) {
                return
            }
            const percentToBottom = contactList.scrollTop / (contactList.scrollHeight - contactList.offsetHeight)
            if (percentToBottom > 0.65 && !state.contacts.isSending) {
                loadMore(undefined)
            }
        }, 200)

        const contactList = document.getElementById('clientList')
        contactList?.addEventListener('scroll', handleScroll)
        return () => {
            contactList?.removeEventListener('scroll', handleScroll)
        }
    }, [dispatch, type, state.contacts.isSending, state.contacts.pagination])

    // Guardrail states
    const [showGuardrail, setShowGuardrail] = useState(false)
    const [guardrailTitle, setGuardrailTitle] = useState('')
    const [guardrailBody, setGuardrailBody] = useState<string | React.ReactNode>('')
    const [guardrailButton, setGuardrailButton] = useState('')
    const [guardrailType, setGuardrailType] = useState('')
    const guardrailAction = useCallback(() => {
        switch (guardrailType) {
            case 'doublebook':
                onSelect(selections, selectionExtraData)
                break
            default:
                console.log(`Unknown guardrail type: ${guardrailType}`)
        }
        setShowGuardrail(false)
    }, [guardrailType, selections, selectionExtraData, onSelect])

    const onSelection = (item: any) => {
        if (!multiSelect) {
            onSelect([item], selectionExtraData)
        }
        else {
            // Toggle the selection, if it is in selections already, remove it,
            // otherwise add it.
            const selectionCopy = selections.slice()
            const itemPos = selections.findIndex(i => (i.id ?? i.booking) === item.id)
            if (itemPos > -1)
                selectionCopy.splice(itemPos, 1)
            else {
                // Only services can be multi-selected, make sure they aren't
                // being mixed together.
                if (type === 'services') {
                    const nonServices = selections.filter(i => i.type !== 'service')
                    if (selections.length > 0 && item.type !== 'service') {
                        alert(`${item.name} cannot be scheduled with other services`)
                        return
                    }
                    else if (nonServices.length > 0) {
                        alert(`${nonServices[0].name} cannot be scheduled with other services`)
                        return
                    }
                }

                // TODO For services and products push a LineItem
                selectionCopy.push(item)
            }
            setSelections(selectionCopy)
        }
    }

    const onUpdateValue = (item: any, newValue: any) => {
        switch (type) {
            case "services":
            case "products":
                // Set quantity for products or sales tax flag for services
                const newExtraData = {
                    ...selectionExtraData
                }
                newExtraData[item.id] = newValue
                setSelectionExtraData(newExtraData)
                break;
            case "appointments":
            case "clients":
            case "expenses":
            case "items":
            case "packages":
            case "gifts":
            case "pros":
            case "subscriptions":
                break;
        }
    }

    // Create a wrapper around onSelect that throws a guardrail of type is pro
    // and a pro is not available
    const onDoneHandler = () => {
        if (type === 'pros') {
            const unavailablePros = selections.filter((p: any) => !proAvailability[p.id])
            if (unavailablePros.length > 0) {
                // Show guardrail about double booking
                setGuardrailTitle('Double book')
                setGuardrailBody(`${unavailablePros[0].name} is not available at ${moment(currentDate).format('h:mm a')}, do you want to double book this Pro?`)
                setGuardrailButton('Double book')
                setGuardrailType('doublebook')
                setShowGuardrail(true)
                return
            }
        }
        onSelect(selections, selectionExtraData)
    }

    return (
        <Modal className="selectModal"
               onClose={() => onCancel()}
        >
            <Header className="header">
                <div className="title">{type}</div>
                {type !== 'appointments' && (
                    <input type="text"
                           placeholder={`Search ${type}`}
                           value={searchText}
                           onChange={e => setSearchText(e.target.value)}
                    />
                )}
                {type === 'items' && (
                    <div style={{marginLeft: '18px'}}>
                        <PSDropdown selected={itemFilter}
                                    nameProperty="label"
                                    onSelect={(selection) => setItemFilter(selection)}
                                    selectHeight="40px"
                                    marginTop="0"
                                    paddingTop="7px"
                        >
                            <>
                                {availableItemFilters.map(option => (
                                    <Item key={`item-filter-${option.value}`} value={option}>
                                        {option.label}
                                    </Item>
                                ))}
                            </>
                        </PSDropdown>
                    </div>
                )}
            </Header>
            <Body className="body" id="clientList">
                {loadingData && (
                    <div style={{textAlign: 'center'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                )}
                {!loadingData && filteredSelectionList.map((item: any) => {
                    const selected = selections.find((s: any) => (s.id ?? s.booking) === item.id)
                    const prepaid = (prepaidItems !== undefined && prepaidItems.length === 0) ||
                        (prepaidItems?.find(s => s === item.id) ?? undefined) !== undefined
                    return (
                        <SelectModalCard type={type}
                                         key={`selection-${item.id}`}
                                         selected={!!selected}
                                         item={item}
                                         allowSalesTax={owner?.allow_salestax || false}
                                         prepaid={prepaid}
                                         availability={proAvailability}
                                         onSelection={onSelection}
                                         onUpdateValue={onUpdateValue}
                                         extraData={selectionExtraData[item.id]}
                        />
                    )
                })}
                {showGuardrail && (
                    <GuardrailModal title={guardrailTitle}
                                    body={guardrailBody}
                                    buttonText={guardrailButton}
                                    onClose={() => setShowGuardrail(false)}
                                    onAction={guardrailAction} />
                )}
            </Body>
            {multiSelect && (
                <Footer className="footer">
                    <FooterItem>
                        <Button onClick={() => onCancel()}>Cancel</Button>
                    </FooterItem>
                    <FooterItem>
                        <Button isPrimary
                                onClick={() => onDoneHandler()}
                                disabled={!doneEnabled}
                        >
                            Done
                        </Button>
                    </FooterItem>
                </Footer>
            )}
        </Modal>
    )
}