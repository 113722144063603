import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearSearch, getInbox, searchInbox } from '../../modules/inbox'
import { Spinner } from '@zendeskgarden/react-loaders'
import { IconButton } from '@zendeskgarden/react-buttons'
import { ContactAvatar } from '../contacts/ContactAvatar'
import styled from 'styled-components'
import Moment from 'react-moment'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { getConversation, setShowNewMessage } from '../../modules/conversation'
import debounce from 'lodash.debounce'
import { generateCancelToken } from '../../modules/apiclient'
import { GroupAvatar } from '../contacts/GroupAvatar'
import { TypeAheadSearchBar } from '../app/TypeAheadSearchBar'
import { Tooltip } from '@zendeskgarden/react-tooltips'
import { ReactComponent as PlusIcon } from '../../icons/plus.svg'
import { getTeamName } from '../../utils/teams'

const InboxList = styled('div')`
  overscroll-behavior: contain;
  overflow-y: auto;
`

const InboxEntry = styled(Link)`
  text-decoration: none;
  margin-left: 8px;
  margin-right: 8px;
  color: rgb(47,57,65);
  display: flex;
  padding: 16px 21px 16px 16px;
  &:focus, &:visited, &:link, &:active, &:hover {
      color: rgb(47,57,65);
      text-decoration: none;
  }
`

const InboxRow = styled('div')`
  display: flex;
  width: 100%;
  overflow-x: hidden;
  flex-direction: column;
`

const AvatarWrapper = styled('div')`
    width: 48px;
    margin-right: 16px;
`

export const InboxView = (props) => {
    const dispatch = useDispatch()
    const state = useSelector(state => {
        return {
            messages: state.messages,
            conversation: state.conversation,
            entities: state.entities,
        }
    })

    const { threadID } = useParams()
    // Initial page load will get the first page of the inbox
    useEffect(() => {
        const cancelToken = generateCancelToken()

        if (state.messages.inbox.length === 0)
            dispatch(getInbox('', threadID, cancelToken.token))
        else if (threadID && threadID !== state.conversation.currentThread)
            dispatch(getConversation(threadID, '', cancelToken.token))
        return _ => {
            cancelToken.cancel()
        }
    }, [dispatch, threadID])

    // Handle cancellation of load more
    let loadMoreCancelToken
    useEffect(() => {
        return _ => {
            if (loadMoreCancelToken) {
                loadMoreCancelToken.cancel()
            }
        }
    }, [loadMoreCancelToken])

    // Automatically load more inbox items when the scroll bar is near the bottom
    useEffect(() => {
        const inboxList = document.getElementById('inboxList')
        inboxList.addEventListener('scroll', handleScroll)
        return _ => {
            inboxList.removeEventListener('scroll', handleScroll)
        }
    })

    const handleScroll = debounce(() => {
        const inboxList = document.getElementById('inboxList')
        if (!inboxList) return
        const percentToBottom = inboxList.scrollTop / (inboxList.scrollHeight - inboxList.offsetHeight)
        if (percentToBottom > 0.75 && !state.messages.isSending)
            loadMore()
    }, 200)

    const loadMore = (e) => {
        if (e) {
            e.preventDefault()
        }
        const { timestamp, hasMore } = state.messages.pagination
        if (hasMore) {
            loadMoreCancelToken = generateCancelToken()
            dispatch(getInbox(timestamp, state.conversation.currentThread, loadMoreCancelToken.token))
        }
    }

    let history = useHistory();
    const onClickThread = (e, message) => {
        e.preventDefault()
        if (message) {
            dispatch(clearSearch())
            history.push(`/messages/${message.person || message.team}`)
        }
    }

    const startNewMessage = () => {
        dispatch(setShowNewMessage(true))
    }

    let loadingIndicator
    if (state.messages.isSending || state.messages.isSearching) {
        loadingIndicator = (
            <div style={{position: 'absolute', marginLeft: '-64px', width: '440px', transform: 'translate(50%, 50%)', top: 'calc(50% - 64px)'}}>
                <Spinner size={128} color="#314A68" />
            </div>
        )
    }

    const calendarStrings = {
        sameDay: 'LT',
        lastDay: '[Yesterday]',
        lastWeek: 'dddd',
        sameElse: 'MMM D',
    }

    return (
        <div style={{minWidth: '440px', borderRight: '1px solid #e4e4e4', display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex'}}>
                <div style={{padding: '21px 0 23px 26px', fontSize: '14px', lineHeight: '24px', color: '#828282', fontWeight: '700'}}>Messages</div>
                <Tooltip content="Create new message" placement="top" style={{backgroundColor: '#314A68'}}>
                    <IconButton onClick={e => startNewMessage()}
                                style={{margin: '21px 40px 0 auto', width: '32px', borderRadius: '16px'}}
                    >
                        <PlusIcon />
                    </IconButton>
                </Tooltip>
            </div>
            <div style={{height: '1px', backgroundColor: '#e4e4e4', width: '100%', marginBottom: '12px'}} />
            {!state.messages.isSending && (
                <TypeAheadSearchBar stateField="messages"
                                    clearSearch={clearSearch}
                                    search={searchInbox}
                                    searchBarPlaceholder="Search messages by name"
                                    style={{
                                        margin: '18px 21px',
                                    }} />
            )}
            {loadingIndicator}
            <InboxList id="inboxList" style={{height: props.inboxHeight-128, scrollbarWidth: 'none'}}>
                {state.messages.didSearch && !state.messages.isSearching && state.messages.searchResults.length === 0 && (
                    <div style={{textAlign: 'center'}}>No matching contacts found</div>
                )}
                {state.messages.didSearch && !state.messages.isSearching && state.messages.searchResults.map((searchResult) => {
                    const threadAvatar = (
                        <AvatarWrapper>
                            <ContactAvatar user={searchResult} userId={searchResult.id} />
                        </AvatarWrapper>
                    )
                    return (
                        <InboxEntry key={`inbox-result-${searchResult.id}`}
                                    to={`/messages/${searchResult.id}`}
                                    onClick={e => onClickThread(e, {person: searchResult.id})}>
                            {threadAvatar}
                            <InboxRow>
                                <div style={{display: 'flex', flexDirection: 'row', lineHeight: '24px'}}>
                                    <div style={{fontSize: '14px', color: '#000000', fontWeight: '400'}}>{searchResult.name}</div>
                                </div>
                                <span style={{fontSize: '13px'}}>
                                    <div style={{color: '#999999', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre'}}>
                                        Click to open message thread
                                    </div>
                                </span>
                            </InboxRow>
                        </InboxEntry>
                    )
                })}
                {!state.messages.didSearch && !state.messages.isSearching && state.messages.inbox.map((message) => {
                    const threadID = message.person || message.team
                    const contact = message.person ? state.entities.contacts[message.person] : state.entities.teams[message.team]
                    if (!contact) return null

                    let threadName
                    let threadAvatar

                    if (message.person) {
                        threadName = contact.name
                        threadAvatar = (
                            <AvatarWrapper>
                                <ContactAvatar user={contact} userId={contact.id} />
                            </AvatarWrapper>
                        )
                    }
                    else {
                        threadName = getTeamName(contact, state.entities.contacts)
                        threadAvatar = (
                            <AvatarWrapper>
                                <GroupAvatar />
                            </AvatarWrapper>
                        )
                    }

                    const selected = threadID === state.conversation.currentThread
                    return (
                        <InboxEntry key={`inbox-${threadID}`}
                                     className={selected ? 'active' : ''}
                                     to={`/messages/${threadID}`}
                                     onClick={e => onClickThread(e, message)}>
                            {!selected && message.viewed_date == null &&
                                <div style={{margin: 'auto 0 auto -16px', width: '20px', color: '#E22216'}}>●</div>
                            }
                            {threadAvatar}
                            <InboxRow>
                                <div style={{display: 'flex', flexDirection: 'row', lineHeight: '24px'}}>
                                    <div style={{fontSize: '14px', color: selected ? '' : '#000000', fontWeight: selected ? '600' : '400'}}>{threadName}</div>
                                    <Moment style={{fontSize: '11px', marginLeft: 'auto', color: selected ? '' : '#999999'}} calendar={calendarStrings}>{message.created_date}</Moment>
                                </div>
                                <span style={{fontSize: '13px'}}>
                                    <div style={{color: selected ? '' : '#999999', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre'}}>
                                        {message.message.replace(/\n/g, ' ')}
                                    </div>
                                </span>
                            </InboxRow>
                        </InboxEntry>
                    )
                })}
            </InboxList>
        </div>
    )
}
