export class PlanBenefit {
    leading_image?: string;
    message: string = '';
}

export type SubscribeButtonAction = {
    alert?: string;
    id: 'testdrive' | 'subscribe' | 'unsubscribe' | 'doubledown' | 'contactus'
}

export class Plan {
    alert_label?: string;
    banner?: string;
    title?: string;
    benefits: PlanBenefit[] = [];
    current_price?: string;
    details?: string;
    edition?: string;
    free_trial: boolean = false;
    label?: string;
    next_invoice_date?: string;
    plan: string = '';
    premium_sales_image_uri?: string;
    premium_sales_video_uri?: string;
    price?: string;
    yearly?: string;
    price_display?: string;
    price_show_as_discounted: boolean = false;
    subscribe_button_title: string = '';
    subtitle?: string;
    switch_to_signature_edition?: string | boolean;
    subscribe_button_actions?: SubscribeButtonAction[];
    subscribe_button_color?: 'light' | 'dark';
}

export const planLabelString = (plan: string): string => {
    if (plan === 'plus')
        return 'Team includes Premium and lets you manage a team of up to 2. You can schedule, pay, and group message your workers.'
    else if (plan === 'suite')
        return 'Enterprise includes Team and lets you manage teams of greater than 5. There is no limit to the number of workers you can add.'
    else if (plan === 'premium')
        return 'Premium gives you a local business number which you can use to communicate with your clients and team members.'
    return ''
}