import React, {useEffect, useState} from "react";
import {AppHeader} from "../app/AppHeader";
import {Spinner} from "@zendeskgarden/react-loaders";
import {PSButtonPrimary} from "../app/PSButton";
import {
    clearSelections,
    selectForm,
    setForms, setRefreshData,
    updateForm
} from "../../modules/onboarding";
import {useAppDispatch, usePSUser} from "../../hooks";
import {RootState} from "../../store";
import {useSelector} from "react-redux";
import {Form} from "../../models/Form";
import {OnboardingForm} from "./OnboardingForm";
import {apiClient} from "../../modules/apiclient";
import localforage from "localforage";
import {
    nextDefaultInstallStep,
    nextOnboardingStep
} from "../../utils/onboarding";
import {processError} from "../../modules/error";
import {Result} from "../../utils";
import {useLocation} from "react-router";
import {Item} from "../../models/Item";
import {ACTION_BUTTON, WarningModal} from "../app/WarningModal";
import {DefaultsEmptyState} from "../settings/DefaultsEmptyState";

type Props = {
    forOnboarding?: boolean;
    allEditionItemIDs?: string[];
    initialStep?: string;
}

export const OnboardingForms = ({forOnboarding, allEditionItemIDs, initialStep}: Props) => {
    const dispatch = useAppDispatch()
    const state: Pick<RootState, "onboarding"> = useSelector((state: RootState) => {
        return {
            onboarding: state.onboarding,
        }
    })
    const user = usePSUser()

    const routerLocation = useLocation()
    const locationState: any = routerLocation.state
    const selectedByDefault = locationState?.selectedByDefault ?? false
    const editionItems = locationState?.editionItems ?? []
    forOnboarding = locationState?.forOnboarding ?? forOnboarding ?? true

    const [showLoadingMessage, setShowLoadingMessage] = useState('')
    const [showLinkedFormWarning, setShowLinkedFormWarning] = useState<{form: Form, item: Item}>()

    const [nextEnabled, setNextEnabled] = useState(forOnboarding)
    useEffect(() => {
        setNextEnabled(forOnboarding || state.onboarding.selectedFormIDs.length > 0 || (!!initialStep && initialStep !== 'forms'))
    }, [forOnboarding, state.onboarding.selectedFormIDs])

    const [header, setHeader] = useState('Select Forms')
    useEffect(() => {
        if (state.onboarding.selectedFormIDs.length === 0)
            setHeader('Select Forms')
        else {
            setHeader(`Select Forms (${state.onboarding.selectedFormIDs.length})`)
        }
    }, [state.onboarding.selectedFormIDs])

    useEffect(() => {
        if (state.onboarding.refreshData === false) return

        if (selectedByDefault && state.onboarding.selectedFormIDs.length === 0) {
            state.onboarding.forms.forEach(form => {
                if (!form.id) return
                dispatch(selectForm(form.id, true))
            })
        }
        // We only want to select default forms at initial load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.onboarding.forms, state.onboarding.refreshData])

    useEffect(() => {
        return () => {
            dispatch(setRefreshData(true))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!user || state.onboarding.refreshData === false) return
        if (state.onboarding.forms.length > 0) return

        setShowLoadingMessage('Loading...')

        // Load from the server first to make sure this bundle doesn't really have any forms
        const editionID = user.edition ? user.edition : `${user.industry}-industry-edition`
        apiClient.post('/edition/sync', {edition: editionID})
            .then(resp => resp.data)
            .then(json => {
                if (json.forms && json.forms.length > 0) {
                    if (forOnboarding) {
                        // Set the forms from the edition bundle
                        dispatch(setForms(json.forms))
                    }

                    // Get the user's current forms, so we can render them
                    // with the edited fields and select it
                    apiClient.post('/type/sync')
                        .then(resp => resp.data)
                        .then(formsJson => {
                            if (!forOnboarding) {
                                const installedDefaultForms = formsJson.forms
                                    .filter((f: any) => !!f.edition_type)
                                    .map((f: any) => f.edition_type)
                                const newFormsFromEdition = json.forms.filter((f: any) => {
                                    return installedDefaultForms.indexOf(f.id) === -1
                                })
                                dispatch(setForms(newFormsFromEdition))
                                return
                            }

                            formsJson.forms.filter((f: any) => !!f.edition_type).forEach((f: any) => {
                                const formEditionID = state.onboarding.selectedFormIDs.find(formID => formID === f.edition_type)
                                if (!formEditionID) {
                                    // Select it in the UI now
                                    dispatch(selectForm(f.edition_type, true))
                                    // Replace the edition built in item with the installed item that is customized
                                    // but retain the edition ID and image_uri
                                    const editionForm = json.forms.find((j: any) => j.id === f.edition_type)
                                    if (editionForm) {
                                        for (const property in editionForm) {
                                            if (property === 'image_uri' || property === 'id' || property === 'fields') continue
                                            editionForm[property] = f[property]
                                        }
                                        dispatch(updateForm(editionForm))
                                    }
                                }
                            })
                        })
                        .finally(() => setShowLoadingMessage(''))
                }
                else {
                    if (forOnboarding) {
                        Result(dispatch(nextOnboardingStep(user)))
                            .finally(() => setShowLoadingMessage(''))
                    }
                    else {
                        setShowLoadingMessage('')
                    }
                }
            })
    }, [dispatch, forOnboarding, user, state.onboarding.refreshData])

    const onNextHandler = () => {
        if (!user) return

        setShowLoadingMessage('Saving...')

        const editionID = user.edition || `${user.industry}-industry-edition`

        const clonedForms = state.onboarding.selectedFormIDs.map((id: string) => {
            const f = state.onboarding.forms.find(f => f.id === id)
            if (!f) return null

            const tmpForm = new Form()
            tmpForm.setData(f)
            if (f.selected_fields) {
                tmpForm.fields = f.selected_fields
            }
            tmpForm.edition = editionID
            tmpForm.edition_type = f.id
            tmpForm.associated_with_service = undefined

            const newForm = tmpForm.toObject(false)
            delete newForm.id // Only send the selected fields

            return newForm
        })

        const postData = {
            types: clonedForms,
            edition: editionID,
            forOnboarding,
        }

        apiClient.post(`/onboarding/setup/types`, postData)
            .then(resp => resp.data)
            .then(json => {
                if (json.user) localforage.setItem('user', json.user)
                if (forOnboarding) {
                    Result(dispatch(nextOnboardingStep(json.user)))
                        .finally(() => setShowLoadingMessage(''))
                }
                else {
                    const editionItemAndFormIDs = allEditionItemIDs ? [ ...allEditionItemIDs ] : []
                    editionItemAndFormIDs.push(...state.onboarding.selectedFormIDs)
                    Result(dispatch(nextDefaultInstallStep(editionItemAndFormIDs, editionID, initialStep!, 'forms')))
                        .finally(() => setShowLoadingMessage(''))
                }
            })
            .catch(error => {
                dispatch(processError(error))
                setShowLoadingMessage('')
                return Promise.reject()
            })
    }

    const body = (
        <>
            {showLoadingMessage && (
                <div className="loadingOverlay">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                        <h1>{showLoadingMessage}</h1>
                    </div>
                </div>
            )}
            <div className="scheduleClient servicesSettings onboarding">
                <div className="header">
                    <h1>{header}</h1>
                    <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                     onClick={() => onNextHandler()}
                                     disabled={!nextEnabled}
                    >
                        Next
                    </PSButtonPrimary>
                </div>

                <div className="separator" />

                <div className="form">
                    <div className="text">Attach forms to your offerings to automatically send when clients book, when applicable.</div>
                    {state.onboarding.forms.map((form: Form) => (
                        <OnboardingForm form={form}
                                        key={form.id}
                                        isChecked={state.onboarding.selectedFormIDs.findIndex((id: string) => id === form.id) > -1}
                                        onToggleItem={(enabled: boolean) => {
                                            if (!form.id) return

                                            const editionItemForm = editionItems[form.id]
                                            if (editionItemForm && !enabled) {
                                                setShowLinkedFormWarning({form: form, item: editionItemForm})
                                            }
                                            else {
                                                dispatch(selectForm(form.id, enabled))
                                            }
                                        }}
                        />
                    ))}
                </div>
            </div>

            {showLinkedFormWarning && (
                <WarningModal title="Linked form"
                              message={`Are you sure? This form is linked to an item (${showLinkedFormWarning.item.name}) recently added to your account.`}
                              buttonText="Yes"
                              onButtonClick={(buttonIx) => {
                                  if (buttonIx === ACTION_BUTTON) {
                                      dispatch(selectForm(showLinkedFormWarning.form.id!, false))
                                  }
                                  setShowLinkedFormWarning(undefined)
                              }}
                              extraButtonText="Cancel"
                              onClose={() => setShowLinkedFormWarning(undefined)}
                />
            )}
        </>
    )

    if (forOnboarding) {
        return (
            <AppHeader title="Setup" showBackButton middleWidget={null}>
                {body}
            </AppHeader>
        )
    }

    if (!showLoadingMessage && state.onboarding.forms.length === 0) {
        return (
            <DefaultsEmptyState title="Forms" />
        )
    }

    return body
}
