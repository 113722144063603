import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@zendeskgarden/react-buttons'
import { Checkbox, Field, Hint, Label } from '@zendeskgarden/react-forms'
import { ConversationHeader } from './ConversationHeader'
import { createGroup, setShowCreateGroup } from '../../modules/conversation'
import { ReactComponent as BackImage } from '../../icons/back.svg'
import { splitName } from '../../utils/users'
import { ContactAvatar } from '../contacts/ContactAvatar'

export const ConversationGroupEdit = (props) => {
    const dispatch = useDispatch()
    const state = useSelector(state => state.conversation)

    const [name, setName] = useState('')
    const [bcc, setBcc] = useState(true)

    const createGroupMessage = () => {
        const memberList = state.newConversation.selectedUsers.map(u => u.id)
        dispatch(createGroup(memberList, name, bcc))
    }

    const backLink = (
        <Button isLink
                onClick={e => dispatch(setShowCreateGroup(false))}
                style={{
                    marginTop: '15px',
                    marginLeft: '8px',
                    lineHeight: '24px',
                    fontSize: '14px',
                    color: '#3D84F6',
                    fontWeight: 700,
                    textDecoration: 'none',
                }}><BackImage style={{marginRight: '4px'}} /> Back</Button>
    )

    const saveLink = (
        <Button isLink
                onClick={e => createGroupMessage()}
                style={{
                    marginTop: '-8px',
                    color: '#3D84F6',
                    fontSize: '14px',
                    lineHeight: '24px',
                    fontWeight: 700,
                    textDecoration: 'none',
                }}
                >Create</Button>
    )

    return (
        <React.Fragment>
            <ConversationHeader title={backLink} trailingWidget={saveLink} />
            <div className="groupEdit">
                <input type="text"
                       value={name}
                       onChange={e => setName(e.target.value)}
                       placeholder="Enter group name (optional)"
                />
                <Field>
                    <Checkbox checked={bcc} onChange={e => setBcc(!bcc)}>
                        <Label>Bcc group</Label>
                        <Hint style={{maxWidth: 'calc(50%)'}}>
                            Bcc means the group message will be sent over SMS text and client replies will be sent privately to you. The number of recipients is limited to 25.
                        </Hint>
                    </Checkbox>
                </Field>
                <div className="userSeparator" />
                {state.newConversation.selectedUsers.map((user) => {
                    const [firstNamePart, lastNamePart] = splitName(user)

                    return (
                        <div className="user" key={`conversation-group-edit-user-${user.id}`}>
                            <ContactAvatar user={user} userId={user.id} />
                            <span style={{
                                marginLeft: '16px',
                                verticalAlign: 'middle',
                                color: '#000000'
                            }}>
                            {firstNamePart}&nbsp;
                                <span style={{fontWeight: 700}}>{lastNamePart}</span>
                            </span>
                        </div>
                    )
                })}
            </div>
        </React.Fragment>
    )
}