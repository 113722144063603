import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../hooks";
import {
    saveUser,
    SettingsState
} from "../../../modules/settings";
import {RootState} from "../../../store";
import {useSelector} from "react-redux";
import {PSDropdown} from "../../app/PSDropdown";
import {Prompt} from "react-router";
import {PSButtonPrimary} from "../../app/PSButton";
import {Dots} from "@zendeskgarden/react-loaders";
import localforage from 'localforage'
import {findSelection, gratuityPercentOptions, SelectOption} from "../Options";
import {gratuityOptions} from "../Options";
import {Item} from "@zendeskgarden/react-dropdowns";
import {isEmpty, stringEqual} from '../../../utils';


export const GratuityEdit = () => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const findGratuitySelection = (user: any) => findSelection(gratuityOptions, user.request_gratuity)
    const findPercentSelection = (user: any) => findSelection(gratuityPercentOptions, user.gratuity)

    const [saveEnabled, setSaveEnabled] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)
    const [user, setUser] = useState<any>({})

    const [gratuity, setGratuity] = useState<SelectOption | undefined>(findGratuitySelection(user) ?? gratuityOptions[0])
    const [suggestedPercentage, setSuggestedPercentage] = useState<SelectOption | undefined>(findPercentSelection(user))

    useEffect(() => {
        if (isEmpty(user)) return

        const edits =
            !stringEqual(user.gratuity.toString() ?? gratuityOptions[0], suggestedPercentage?.value) ||
            !stringEqual(user.request_gratuity, gratuity?.value)

        setHasEdits(edits)
    }, [user, suggestedPercentage, gratuity])

    useEffect(() => {
        setSaveEnabled(hasEdits)
    }, [hasEdits])

    useEffect(() => {
        if (isEmpty(user)) return

        setGratuity(findGratuitySelection(user))
        setSuggestedPercentage(findPercentSelection(user))
    }, [user])

    useEffect(() => {
        localforage.getItem('user').then((user: any) => setUser(user))
    }, [])

    const onSave = () => {
        if (state.isSending) return

        const userParams: any = {}
        userParams.id = user.id;

        userParams.request_gratuity = gratuity?.value ?? null
        userParams.gratuity = suggestedPercentage?.value ?? null

        // @ts-ignore
        dispatch(saveUser(userParams)).then(() => localforage.getItem('user').then((user: any) => setUser(user)))
    }

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Gratuity</h1>
                <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!saveEnabled || state.isSending}>
                    {state.isSending ? <Dots /> : "Save"}
                </PSButtonPrimary>
            </div>


            <div className="form">
                <PSDropdown selected={gratuity}
                            nameProperty={"label"}
                            onSelect={(selected) => setGratuity(selected)}
                            label={"Request gratuity"}>
                    <>
                        {gratuityOptions.map(option => (
                            <Item key={`gratuity-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>
                <PSDropdown selected={suggestedPercentage}
                            nameProperty={"label"}
                            onSelect={(selected) => setSuggestedPercentage(selected)}
                            label={"Suggested gratuity"}>
                    <>
                        {gratuityPercentOptions.map(option => (
                            <Item key={`gratuity-rate-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>
            </div>

            <Prompt
                when={hasEdits && !state.isSending}
                message="Are you sure you'd like to leave this page without saving your changes?" />

        </div>
    )
}