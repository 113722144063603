export class PhoneNumber {
    id: string
    sms: string
    name: string
    main: string
    owner: string
    person: string

    constructor() {
        this.id = ''
        this.sms = ''
        this.name = ''
        this.main = ''
        this.owner = ''
        this.person = ''
    }

    setData(data: any) {
        this.id = data.id
        this.sms = data.sms
        this.name = data.name
        this.main = data.main
        this.owner = data.owner
        this.person = data.person
    }

    toObject(): any {
        const obj: any = {}
        obj.id = this?.id
        obj.sms = this?.sms
        obj.name = this?.name
        obj.main = this?.main
        obj.owner = this?.owner
        obj.person = this?.person
        return obj
    }
}