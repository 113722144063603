import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../hooks";
import {
    saveUser,
    SettingsState
} from "../../../modules/settings";
import {
    Checkbox,
    Field,
    Hint,
    Label,
} from "@zendeskgarden/react-forms";
import {RootState} from "../../../store";
import {useSelector} from "react-redux";
import {PSDropdown} from "../../app/PSDropdown";
import {Prompt} from "react-router";
import {PSButtonPrimary} from "../../app/PSButton";
import {Dots} from "@zendeskgarden/react-loaders";
import localforage from 'localforage'
import {findSelection, SelectOption, timeZoneOptions} from "../Options";
import {bufferTimeOptions, confirmationStatusOptions, flexStartOptions, maxAvailableRangeOptions, requireCreditCardOptions} from "../Options";
import {AvailableHoursModal, Weekday, ItemOption} from "../AvailableHoursModal";
import {Item} from "@zendeskgarden/react-dropdowns";
import {PSLearnMore} from "../../app/PSLearnMore";
import {LearnMoreBufferTime, LearnMoreCannotBookBeyond, LearnMoreConfirmationStatus, LearnMoreFlexStart, LearnMoreRequireCC} from "../LearnMore";
import {areEqual, createAvailabilityArray, createAvailabilitySummary} from "../../../models/Item";
import {GuardrailModal} from "../GuardrailModal";
import {stringEqual, isEmpty} from '../../../utils';

export const SchedulingEdit = () => {
    const findCcSelection = (user: any) => findSelection(requireCreditCardOptions, user.confirm_payment)
    const findConfirmStatusSelection = (user: any) => findSelection(confirmationStatusOptions, user.booking_status)
    const findTzSelection = (user: any) => findSelection(timeZoneOptions, user.timezone)
    const findMaxBookBeyondSelection = (user: any) => findSelection(maxAvailableRangeOptions, user.available_range)
    const findFlexStartSelection = (user: any) => findSelection(flexStartOptions, user.booking_flex_start)
    const findBufferTimeSelection = (user: any) => findSelection(bufferTimeOptions(user.type === "payee"), user.travel)

    const [learnMoreComponent, showLearnMore] = PSLearnMore([
        { key: 'book-beyond', title: 'Client\'s can\'t book beyond', body: LearnMoreCannotBookBeyond },
        { key: 'buffer-time', title: 'Buffer time', body: LearnMoreBufferTime },
        { key: 'confirm-status', title: 'Confirmation status', body: LearnMoreConfirmationStatus },
        { key: 'flex-start', title: 'Flex start time', body: LearnMoreFlexStart },
        { key: 'require-cc', title: 'Require CC', body: LearnMoreRequireCC }
    ]);
    const notAvailable = 'No availability set';

    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const [saveEnabled, setSaveEnabled] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)

    const [user, setUser] = useState<any>({})

    const [requireCreditCard, setRequireCreditCard] = useState<SelectOption | undefined>(findCcSelection(user))
    const [confirmationStatus, setConfirmationStatus] = useState<SelectOption | undefined>(findConfirmStatusSelection(user))

    const [timezone, setTimeZone] = useState<SelectOption | undefined>(findTzSelection(user))
    const [maxBookBeyond, setMaxBookBeyond] = useState<SelectOption | undefined>(findMaxBookBeyondSelection(user))

    const [flexStart, setFlexStart] = useState<SelectOption | undefined>(findFlexStartSelection(user))
    const [bufferInterval, setBufferInterval] = useState<SelectOption | undefined>(findBufferTimeSelection(user))

    const [availability, setAvailability] = useState<Weekday[]>([])
    const [availabilityDescription, setAvailabilityDescription] = useState(notAvailable)
    const [showAvailableHours, setShowAvailableHours] = useState(false)
    const [bookingCapacity, setBookingCapacity] = useState(1)

    const [showUnavailableWarning, setShowUnavailableWarning] = useState(false)

    useEffect(() => {
        if (isEmpty(user)) return

        const edits =
            !stringEqual(user.booking_status, confirmationStatus?.value) ||
            !stringEqual(user.travel,  bufferInterval?.value) ||
            !stringEqual(user.confirm_payment,  requireCreditCard?.value) ||
            !stringEqual(user.timezone,  timezone?.value) ||
            !stringEqual(user.available_range,  maxBookBeyond?.value) ||
            !stringEqual(user.booking_flex_start,  flexStart?.value) ||
            user.capacity !== bookingCapacity ||
            !areEqual(createAvailabilityArray(user), availability);

        setHasEdits(edits)
    }, [user, availability, bookingCapacity, bufferInterval, confirmationStatus, flexStart, maxBookBeyond, requireCreditCard, timezone])

    useEffect(() => {
        setSaveEnabled(hasEdits)
    }, [hasEdits])

    useEffect(() => {
        if (!user?.capacity) return

        setRequireCreditCard(findCcSelection(user))
        setConfirmationStatus(findConfirmStatusSelection(user))

        setTimeZone(findTzSelection(user))
        setMaxBookBeyond(findMaxBookBeyondSelection(user))
        setFlexStart(findFlexStartSelection(user))
        setBufferInterval(findBufferTimeSelection(user))
        setBookingCapacity(Number(user.capacity))

        const availableDays = createAvailabilityArray(user)
        setAvailability(availableDays)
        setAvailabilityDescription(createAvailabilitySummary(availableDays, notAvailable))
    }, [user])

    useEffect(() => {
        localforage.getItem('user').then((user: any) => setUser(user))
    }, [])

    const onSave = (skipAvailabilityCheck?: boolean) => {
        if (state.isSending) return

        if (!skipAvailabilityCheck) {
            const isUnavailable = !availability?.some(day => day.enabled);
            if (isUnavailable){
                setShowUnavailableWarning(true);
                return;
            }
        }

        const userParams: any = {}
        userParams.id = user.id;

        // reset to null if set to placeholder
        userParams.booking_status = confirmationStatus?.value ?? null
        userParams.confirm_payment = requireCreditCard?.value ?? null
        userParams.available_range = maxBookBeyond?.value ?? null
        userParams.travel = bufferInterval?.value ?? null

        // required should never be null
        userParams.timezone = timezone?.value
        userParams.booking_flex_start = flexStart?.value
        userParams.capacity = bookingCapacity
        userParams.schedule = 0

        if (availability) {
            for (let i = 0; i < availability.length; i++) {
                const start = availability[i].start as ItemOption
                const end = availability[i].end as ItemOption
                const day =
                    i === 0 ? "mon" :
                    i === 1 ? "tue" :
                    i === 2 ? "wed" :
                    i === 3 ? "thu" :
                    i === 4 ? "fri" :
                    i === 5 ? "sat" :
                    "sun"

                if (availability[i].enabled === false) {
                    userParams[`${day}_start_hour`] = ''
                    userParams[`${day}_end_hour`] = ''
                    continue
                } 
                else {
                    userParams.schedule += (1 << i)
                }

                if (start) userParams[`${day}_start_hour`] = start.value
                if (end) userParams[`${day}_end_hour`] = end.value
            }
        }

        // @ts-ignore
        dispatch(saveUser(userParams)).then(() => localforage.getItem('user').then((user: any) => setUser(user)))
    }

    const onAvailableHoursDone = (weekdays: Weekday[]) => {
        setShowAvailableHours(false)
        setAvailability(weekdays)
        setAvailabilityDescription(createAvailabilitySummary(weekdays, notAvailable))
    }

    return (
        <div className="servicesSettings">
            <div className="header">
                <h1>Scheduling</h1>
                <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                 onClick={() => onSave()}
                                 disabled={!saveEnabled || state.isSending}>
                    {state.isSending ? <Dots /> : "Save"}
                </PSButtonPrimary>
            </div>
            <div className="form">
                <PSDropdown selected={requireCreditCard}
                            nameProperty={"label"}
                            onSelect={(cardRequirement) => setRequireCreditCard(cardRequirement)}
                            label={"Require credit card"}
                            placeholder={<div className="grey">Select option</div>}
                            hint={"Require a credit card to enforce cancellation policies (Settings > Cancellations)"}
                            onLearnMore={() => showLearnMore('require-cc')}>
                    <>
                        {requireCreditCardOptions.map(option => (
                            <Item key={`require-card-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>
                <PSDropdown selected={confirmationStatus}
                            nameProperty={"label"}
                            onSelect={(status) => setConfirmationStatus(status)}
                            label={"Confirmation status"}
                            placeholder={<div className="grey">Select confirmation status</div>}
                            hint={"Select whether an appointment is automatically confirmed"}
                            onLearnMore={() => showLearnMore('confirm-status')}>
                    <>
                        {confirmationStatusOptions.map(option => (
                            <Item key={`confirmation-status-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>

                <h2>Availability</h2>
                <PSDropdown selected={timezone}
                            nameProperty={"label"}
                            onSelect={(tz) => setTimeZone(tz)}
                            label={"Timezone (required)"}
                            hint={"Availability is determined based on this timezone"}
                            >
                    <>
                        {timeZoneOptions.map(option => (
                            <Item key={`tz-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>

                <Field className="field">
                    <div style={{display: 'flex'}}>
                        <Label style={{flex: '1'}}>Availability</Label>
                        <div className="buttonLink brightBlue"
                            style={{display: 'inline-block', paddingBottom: '5px'}}
                            onClick={() => setShowAvailableHours(true)}>
                            Edit
                        </div>
                    </div>
                    <Hint>{availabilityDescription}</Hint>
                </Field>

                <Field className="field">
                    <Checkbox checked={bookingCapacity > 1}
                              disabled={state.isSending}
                              onChange={(e) => { e.target.checked ? setBookingCapacity(10) : setBookingCapacity(1) }}>
                        <Label className="withHint">Double booking</Label>
                        <Hint>Allow multiple clients to select the same time slot</Hint>
                    </Checkbox>
                </Field>

                <PSDropdown selected={maxBookBeyond}
                            nameProperty={"label"}
                            onSelect={(bookLimit) => setMaxBookBeyond(bookLimit)}
                            label={"Clients can't book beyond"}
                            placeholder={<div className="grey">No Limit</div>}
                            hint={"Control how far in advance clients can book you"}
                            onLearnMore={() => showLearnMore('book-beyond')}>
                    <>
                        {maxAvailableRangeOptions.map(option => (
                            <Item key={`book-beyond-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>

                <PSDropdown selected={flexStart}
                            nameProperty={"label"}
                            onSelect={(flex) => setFlexStart(flex)}
                            label={"Flex start time (required)"}
                            hint={"Select when your appointments start"}
                            onLearnMore={() => showLearnMore('flex-start')}>
                    <>
                        {flexStartOptions.map(option => (
                            <Item key={`flex-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>


                <PSDropdown selected={bufferInterval}
                            nameProperty={"label"}
                            onSelect={(buffer) => setBufferInterval(buffer)}
                            label={"Buffer time"}
                            placeholder={<div className="grey">Select buffer time</div>}
                            hint={"Set time between appointments for a break, travel time or extra prep"}
                            onLearnMore={() => showLearnMore('buffer-time')}>
                    <>
                        {bufferTimeOptions(user.type === "payee").map(option => (
                            <Item key={`buffer-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>
            </div>

            {showAvailableHours && (
                <AvailableHoursModal title={'My hours'}
                    onClose={() => setShowAvailableHours(false)}
                    onDone={onAvailableHoursDone}
                    currentAvailability={availability} />
            )}
            {showUnavailableWarning && (
                <GuardrailModal title="Setup your business hours so clients can book you"
                                body="Without adding business hours your clients will not see your availability when they are booking you."
                                buttonText="Continue"
                                onClose={() => setShowUnavailableWarning(false)}
                                onAction={() => { onSave(true); setShowUnavailableWarning(false)}}
                />
            )}
            {learnMoreComponent}

            <Prompt
                when={hasEdits && !state.isSending}
                message="Are you sure you'd like to leave this page without saving your changes?" />

        </div>
    )
}