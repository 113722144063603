import { Item } from "@zendeskgarden/react-dropdowns";
import React, {useCallback, useEffect, useState} from "react"
import {useAppDispatch, usePSOwner, usePSUser} from "../../../hooks";
import { UserInstance } from "../../../models/User";
import { apiClient } from "../../../modules/apiclient";
import { PSButtonPrimary } from "../../app/PSButton";
import { PSDropdown } from "../../app/PSDropdown";
import { PSFormFieldSelection } from "../../app/PSFormFieldSelection";
import {dataExportDateFilterOptions} from "../Options";
import { SelectModalOption } from "../../../models/SelectModalOption";
import { SelectModal } from "../../transactions/SelectModal";
import { PSLearnMore } from "../../app/PSLearnMore";
import { SuccessModal } from "../SuccessModal";
import {processError} from "../../../modules/error";

export const DataExportView = () => {
    const dispatch = useAppDispatch()
    const user  = usePSUser()
    const owner = usePSOwner()
    const [dateFilter, setDateFilter] = useState(dataExportDateFilterOptions[1])

    const [client, setClient] = useState<UserInstance>()
    const [exportType, setExportType] = useState<SelectModalOption[]>([])
    const [exportTypeOptions, setExportTypeOptions] = useState<SelectModalOption[]>([])
    const [showClientModal, setShowClientModal] = useState(false)
    const [showExportTypes, setShowExportTypes] = useState(false)
    const [showExportComplete, setShowExportComplete] = useState(false)

    const [learnMoreComponent, showLearnMore] = PSLearnMore([
        { key: 'export', title: 'Data export', body: 'Data export lets you download your entire transaction history for your records. An email will be sent to you once the export is completed with download instructions. Open this email from a computer. The attached CSV/Excel file in the email will not open on a mobile device.' }
    ]);

    useEffect(() => {
        if (!user || !owner) {
            return
        }

        const exports: SelectModalOption[] = []
        if (['company','solo','payee'].includes(user.type)) {
            exports.push(...[
                { name: 'Payments', id: 'payments' },
                { name: 'Invoices', id: 'invoices' },
                { name: 'Appointments', id: 'bookings' },
                { name: 'Clients', id: 'clients' },
                { name: 'Active clients', id: 'active' },
                { name: 'Paying clients', id: 'paying' },
                { name: 'Notes', id: 'notes' },
                { name: 'Item purchased', id: 'items' }
            ])
            if (owner.allow_product)
                exports.push({ name: 'Inventory', id: 'inventory' })
            if (owner.allow_contract)
                exports.push({ name: 'Contracts', id: 'contracts' })
            if (owner.allow_record)
                exports.push({ name: 'Forms', id: 'records' })
            if (owner.allow_staffing)
                exports.push({ name: 'Staffing', id: 'payees' })
            if (owner.allow_class)
                exports.push({ name: 'Classes', id: 'classes' })
            if (owner.allow_package)
                exports.push({ name: 'Packages', id: 'packages' })
            if (owner.allow_subscription)
                exports.push({ name: 'Subscriptions', id: 'subscriptions' })
            if (owner.allow_gift)
                exports.push({ name: 'Gift certificates', id: 'gifts' })
            if (owner.allow_estimate)
                exports.push({ name: 'Estimates', id: 'estimates' })
            if (owner.allow_trigger)
                exports.push({ name: 'Smart campaigns', id: 'triggers' })
            if (owner.allow_task)
                exports.push({ name: 'Tasks', id: 'tasks' })
        }
        if (owner.allow_post) {
            exports.push({ name: 'Posts', id: 'posts' })
            exports.push({ name: 'Members', id: 'subscribers' })
        }
        setExportTypeOptions(exports)
    }, [user, owner])

    useEffect(() => {
        if (!(exportTypeOptions && exportTypeOptions.length)) {
            return
        }

        setExportType([exportTypeOptions[0]])
    }, [exportTypeOptions])

    const onSend = useCallback(() => {
        if (!user) {
            return
        }

        const exportSet = new Set<string>(exportType.map(type => type.id))
        const body = {
            client: !!client ? client.id : "",
            notes: exportSet.has("notes") ? "1" : "0",
            payees: exportSet.has("payees") ? "1" : "0",
            bookings: exportSet.has("bookings") ? "1" : "0",
            contracts: exportSet.has("contracts") ? "1" : "0",
            packages: exportSet.has("packages") ? "1" : "0",
            subscriptions: exportSet.has("subscriptions") ? "1" : "0",
            records: exportSet.has("records") ? "1" : "0",
            estimates: exportSet.has("estimates") ? "1" : "0",
            invoices: exportSet.has("invoices") ? "1" : "0",
            payments: exportSet.has("payments") ? "1" : "0",
            clients: exportSet.has("clients") ? "1" : "0",
            active: exportSet.has("active") ? "1" : "0",
            paying: exportSet.has("paying") ? "1" : "0",
            posts: exportSet.has("posts") ? "1" : "0",
            subscribers: exportSet.has("subscribers") ? "1" : "0",
            classes: exportSet.has("classes") ? "1" : "0",
            triggers: exportSet.has("triggers") ? "1" : "0",
            gifts: exportSet.has("gifts") ? "1" : "0",
            items: exportSet.has("items") ? "1" : "0",
            tasks: exportSet.has("tasks") ? "1" : "0",
            inventory: exportSet.has("inventory") ? "1" : "0",
            schedule: dateFilter.value,
        }

        apiClient.post(`/user/${user?.id}/export`, body)
            .then(() => setShowExportComplete(true))
            .catch(error => dispatch(processError(error)))

    }, [user, client, exportType, dateFilter, dispatch])

    const onClientSelect = (items: any[], extraData: any) => {
        if (!client) {
            setClient(items[0])
        }
        if (items[0].id === client?.id) {
            setClient(undefined)
        }
        else {
            setClient(items[0])
        }
        setShowClientModal(false)
    }

    const onExportTypeSelect = (items: any[]) => {
        setExportType(!!items ? items : [])
        setShowExportTypes(false)
    }

    return (<>
        <div className="servicesSettings">
            <div className="header">
                <h1>Data Export</h1>
                <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                 onClick={onSend}
                                 disabled={false}>
                    Send
                </PSButtonPrimary>
            </div>
            <div className="form">
                <PSDropdown
                        selected={dateFilter}
                        nameProperty="label"
                        onSelect={(selection) => setDateFilter(selection)}
                        label="Time Period">

                        {dataExportDateFilterOptions.map(option => (
                            <Item key={`date-filter-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}

                </PSDropdown>
                <div className="buttonLink brightBlue"
                        style={{display: 'inline-block', paddingTop: 8}}
                        onClick={() => showLearnMore('export')}>
                    Learn more &gt;
                </div>
            </div>
            <PSFormFieldSelection
                label="Client (required)"
                selectedLabel={client ? "":"All clients"}
                selection={client}

                onClick={() => setShowClientModal(true)}
            />
            <PSFormFieldSelection
                label="Export types"
                selectedLabel={!!exportType ? exportType.map(exportItem => exportItem.name).join(", ") : ""}
                onClick={() => setShowExportTypes(true)}
            />
        </div>

        {learnMoreComponent}
        {showClientModal &&
            <SelectModal type="clients" currentSelections={client ? [client] : []} onSelect={onClientSelect} onCancel={() => setShowClientModal(false)} />
        }
        {showExportTypes &&
            <SelectModal type="export" data={exportTypeOptions} multiSelect currentSelections={exportType} onSelect={onExportTypeSelect} onCancel={() => setShowExportTypes(false)} />
        }
        {showExportComplete &&
            <SuccessModal title="Success" buttonText="Done" body={`Export completed! Your data was emailed to ${user?.email}`} onClose={() => setShowExportComplete(false)} onAction={() => setShowExportComplete(false)} />
        }
    </>)
}