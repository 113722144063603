import React from "react";
import { ReactComponent as Checkmark } from "../../icons/defaults_check.svg";
import {PSButtonPrimary} from "../app/PSButton";
import {useHistory} from "react-router";

type Props = {
    title: string;
}

export const DefaultsEmptyState = ({title}: Props) => {
    const history = useHistory()

    return (
        <div className="scheduleClient servicesSettings onboarding onboardingItems">
            <div className="header">
                <h1>{title}</h1>
            </div>
            <div style={{textAlign: 'center', width: '70vw', padding: '80px 0'}}>
                <Checkmark />
                <h3 style={{fontSize: 24, lineHeight: '32px', fontWeight: 700, paddingBottom: 10}}>
                    Well done
                </h3>
                <div style={{margin: '0 auto', paddingBottom: 44, fontSize: 17, lineHeight: '24px', width: '242px', color: '#555'}}>
                    You've successfully added all recommended {title.toLowerCase()}.
                </div>
                <PSButtonPrimary style={{height: 46}}
                                 onClick={() => history.goBack()}>
                    <span style={{padding: '12px 17px', fontWeight: 700}}>
                        Go back
                    </span>
                </PSButtonPrimary>
            </div>
        </div>
    )
}