import styled from 'styled-components'
import { NavItem } from '@zendeskgarden/react-chrome'
import { useHistory, useLocation } from 'react-router'
import React from 'react'
import PropTypes from 'prop-types'

const StyledNavItem = styled(NavItem)`
  margin: 0 16px;
  border-radius: 26px;
  background-color: ${props => props.isCurrent ? 'rgba(255,255,255,0.1)' : 'inherit'};

  &:hover {
    background-color: ${props => props.isCurrent ? 'rgba(255,255,255,0.1)' : 'inherit'};
  }
`

const DisabledNavItem = styled(NavItem)`
  margin: 0 16px;
  color: rgba(255,255,255,0.2);
  opacity: .6;

  svg {
      opacity: .6;
  }

  &:hover, &:focus {
    color: rgba(255,255,255,0.2);
    cursor: default;
    background-color: #314A68;
    opacity: .6;
  }
`

export const PSNavItem = (props) => {
    let history = useHistory()
    let location = useLocation()

    const clickHandler = () => {
        if (props.onClick)
            props.onClick()
        history.push(props.path)
    }

    let iconPadding = {padding: '4px 16px 0 12px'}
    let activeSettingsStyle
    if (props.title === 'Settings' && !props.isExpanded) {
        iconPadding.padding = '4px 14px 0 12px'
        activeSettingsStyle = {
            width: '40px',
            height: '40px',
            minHeight: '40px',
            margin: '0 auto',
            borderRadius: '50%'
        }
    }

    if (props.disabled) {
        return (
            <DisabledNavItem title={props.title}

                        isCurrent={false}>
                <span style={iconPadding}>
                    {props.icon}
                </span>
                {props.messageRef && (
                    <span ref={props.messageRef} />
                )}
                {props.isExpanded && (
                    <span style={{
                        fontSize: '16px',
                        fontWeight: '700',
                        lineHeight: '24px',
                    }}>{props.title}</span>
                )}
            </DisabledNavItem>
        )
    }

    const isCurrent = location.pathname === props.path ||
        (props.fuzzyMatch && location.pathname.match(props.path) && true) ||
        (props.secondaryFuzzyMatch && location.pathname.match(props.secondaryPath) && true)

    return (
        <StyledNavItem title={props.title}
                       onClick={clickHandler}
                       style={activeSettingsStyle}
                       isCurrent={isCurrent}>
            <span style={iconPadding}>
                {props.icon}
            </span>
            {props.messageRef && (
                <span ref={props.messageRef} />
            )}
            {props.isExpanded && (
                <span style={{
                    fontSize: '16px',
                    fontWeight: '700',
                    lineHeight: '24px',
                }}>{props.title}</span>
            )}
        </StyledNavItem>
    )
}

PSNavItem.propTypes = {
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    fuzzyMatch: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    secondaryPath: PropTypes.string,
    secondaryFuzzyMatch: PropTypes.bool,
}
