import React, {useCallback, useEffect, useState} from "react"
import {AppHeader} from "../app/AppHeader";
import {PSButton, PSButtonPrimary} from "../app/PSButton";
import {useAppDispatch, usePSUser} from "../../hooks";
import { push } from "connected-react-router";
import { SettingsState } from "../../modules/settings";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import {fetchSources} from "../../modules/settings";
import { Result } from "../../utils";
import { Spinner } from "@zendeskgarden/react-loaders";
import { setGoLiveState } from "../../modules/onboarding";
import { nextGoLiveStep } from "../../utils/onboarding";

export const GoLiveReceivePayouts = () => {
    const dispatch = useAppDispatch()
    const user = usePSUser()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const [saveEnabled, setSaveEnabled] = useState(false)
    const [loading, setLoading] = useState(true)
    const [isCheckingAccountAdded, setIsCheckingAccountAdded] = useState(false)
    const [isPaymentCardAdded, setIsPaymentCardAdded] = useState(false)

    useEffect(() => {
        Result(dispatch(fetchSources()))
            .finally(() => setLoading(false))
    }, [dispatch])

    useEffect(() => {
        setIsCheckingAccountAdded(state.sources.find(source => source.type === 'checking'))
        setIsPaymentCardAdded(state.sources.find(source => source.type === 'credit'))
    },[state.sources])

    useEffect(() => {
        setSaveEnabled(isCheckingAccountAdded && isPaymentCardAdded)
    }, [isCheckingAccountAdded, isPaymentCardAdded])

    const onNextHandler = useCallback(() =>
        setGoLiveState('gl_premium')
            .then((me) => dispatch(nextGoLiveStep(me)))
    , [dispatch])

    const onSkipHandler = useCallback(() =>
        !user?.plan ?
            dispatch(push('/golive/select-plan')) :
            dispatch(push('/golive/success'))
    , [user, dispatch])

    if (loading) {
        return (
            <div className="servicesSettings">
                <div className="header">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <AppHeader title="Grow your business" showBackButton middleWidget={null}>
            <div className="servicesSettings onboarding launchBusiness">
                <div className="header">
                    <h1>Receive payouts</h1>
                    <div style={{marginLeft: "auto"}}>
                        {(!isCheckingAccountAdded || !isPaymentCardAdded) &&
                            <PSButton style={{height: '40px'}} onClick={onSkipHandler}>
                                Skip
                            </PSButton>
                        }
                        <PSButtonPrimary style={{height: '40px', marginLeft: '10px'}} onClick={onNextHandler} disabled={!saveEnabled}>
                            Next
                        </PSButtonPrimary>
                    </div>
                </div>

                <div className="separator" />

                <div className="form">
                    <div className="text">Tell us where you’d like to receive your payments + process refunds</div>

                    <div className="paymentMethodWrapper text">
                        <div className="content">
                            <div className="bold">Checking account</div>
                            <p className="grey">Add a checking account. Bank transfers to a checking account take 1 business day.</p>
                            {isCheckingAccountAdded && <div className="accountAdded">Checking account added</div>}
                        </div>
                        <div className="link bold" onClick={() => dispatch(push('/golive/add-checking'))}>
                            Add
                        </div>
                    </div>

                    <div className="paymentMethodWrapper text">
                        <div className="content">
                            <div className="bold">Payment card</div>
                            <p className="grey">Add a debit card. Instant transfers to a debit card take 15 minutes.</p>
                            {isPaymentCardAdded && <div className="accountAdded">Payment Card added</div>}
                        </div>
                        <div className="link bold" onClick={() => dispatch(push('/golive/add-card'))}>
                            Add
                        </div>
                    </div>
                </div>
            </div>
        </AppHeader>
    )
}