import React, {useCallback, useEffect, useState} from "react";
import {
    Checkbox,
    Field,
    Hint,
    Label,
} from "@zendeskgarden/react-forms";
import {RootState} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {Prompt, useHistory, useLocation, useParams} from "react-router";
import {PSButton, PSButtonPrimary} from "../app/PSButton";
import {Dots, Spinner} from "@zendeskgarden/react-loaders";
import {PSLearnMore} from "../app/PSLearnMore";
import {LearnMoreCancellingClient} from "../settings/LearnMore";
import { PSTextarea } from "../app/PSTextarea";
import { PSDropdown } from "../app/PSDropdown";
import { cancellationTypeOptions, SelectOption } from "../settings/Options";
import { Item } from "@zendeskgarden/react-dropdowns";
import { getRecord } from "../../modules/records";
import { isEmpty, Result } from "../../utils";
import { AppHeader } from "../app/AppHeader";
import { cancelBooking, cancelSubscription } from "../../modules/transactions";
import { ACTION_BUTTON, OPTIONAL_BUTTON, WarningModal } from "../app/WarningModal";
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { bookingLateInfo, cancellationFeeString, subscriptionCancellationFeeString } from "../../utils/records";
import { usePSUser } from "../../hooks";
import { UserInstance } from "../../models/User";
import { getUser } from "../../modules/settings";
import { push } from "connected-react-router";

export const RecordCancel = () => {

    const dispatch = useDispatch()
    const location: any = useLocation().state
    const history = useHistory()

    const [learnMoreComponent, showLearnMore] = PSLearnMore([
        { key: 'cancel-a-client', title: 'Cancelling a client', body: LearnMoreCancellingClient }
    ]);

    const state = useSelector((state: RootState) => {
        return {
            entities: state.entities,
            settings: state.settings,
            records: state.records
        }
    })

    const [saveEnabled, setSaveEnabled] = useState(false)
    const [hasEdits, setHasEdits] = useState(false)

    const [shouldEnforce, setShouldEnforce] = useState(false)
    const [cancellationReason, setCancellationReason] = useState<string>()
    const [cancellationType, setCancellationType] = useState<SelectOption>()

    useEffect(() => {
        if (cancellationType?.value === "courtesy") {
            setShouldEnforce(false)
        }
    }, [cancellationType])

    const [isLoading, setIsLoading] = useState(false)

    const params = useParams<{recordType: string, recordID: string}>()
    const [record, setRecord] = useState(location?.record)
    const user = usePSUser()
    useEffect(() => {
        if (!isEmpty(record)) return

        setIsLoading(true)
        Result(dispatch(getRecord(params.recordType, params.recordID)))
            .finally(() => setIsLoading(false))
    }, [record, dispatch, params.recordType, params.recordID])

    useEffect(() => {
        if (state.records.recordType === params.recordType && state.records.data) {
            setRecord(state.records.data)
        }
    }, [params.recordType, state.records.data, state.records.recordType])

    useEffect(() => {
        setHasEdits(!!cancellationType || !!cancellationReason)
    }, [cancellationType, cancellationReason])


    const [vendor, setVendor] = useState<UserInstance>()
    useEffect(() => {
        const vendorId = {
            'booking': record?.vendor,
            'subscription': record?.owner
        }[params.recordType]

        if (!user || !vendorId) return

        if (user.id === vendorId) {
            setVendor(user)
            return
        }

        setIsLoading(true)
        Result(dispatch(getUser(vendorId)))
            .then((result: UserInstance) => setVendor(result))
            .finally(() => setIsLoading(false))

    }, [user, record?.vendor, dispatch, record?.owner, params.recordType])

    const bookingEnforceText = useCallback(() => {
        if (!vendor) return

        if (!record.aPackage || vendor.package_cancellation_type === "charge") {
            return `${cancellationFeeString(vendor)} cancellation fee`
        }
        else if (vendor.package_cancellation_type === "session") {
            return `Lose a session`;
        }
        else { // both
            return `Lose a session & charge a ${cancellationFeeString(vendor)} cancellation fee`
        }
    }, [record.aPackage, vendor])

    const subscriptionEnforceText = useCallback(() => {
        if (!vendor) return

        return `${subscriptionCancellationFeeString(vendor)} cancellation fee`
    }, [vendor])

    const enforceText = useCallback(() => {
        return {
            'booking': bookingEnforceText(),
            'subscription': subscriptionEnforceText(),
        }[params.recordType]
    }, [bookingEnforceText, params.recordType, subscriptionEnforceText])

    const [isCancellableClass, setIsCancellableClass] = useState(false)
    const [isCancellableSeries, setIsCancellableSeries] = useState(false)
    const [forClass, setForClass] = useState(false)
    const [forVendor, setForVendor] = useState(false)
    const [isLate, setIsLate] = useState(false)
    useEffect(() => {
        if (!user || !vendor) return

        const item = record.item in state.entities.items ? state.entities.items[record.item] : undefined
        const {isCancellableClass, isCancellableSeries, forVendor, forClass, isLate} = bookingLateInfo(record, item, user, vendor)

        setIsLate(isLate)
        setIsCancellableClass(isCancellableClass)
        setIsCancellableSeries(isCancellableSeries)
        setForClass(forClass)
        setForVendor(forVendor)

    }, [record, state.entities.items, user, vendor])

    type ModalInfo = {
        title: string,
        body: string,
        button: string,
        optionalButton?: string,
        danger?: boolean,
        onClick: (button: typeof ACTION_BUTTON | typeof OPTIONAL_BUTTON) => void
    }
    const [ctaModalInfo, setCtaModalInfo] = useState<ModalInfo>()
    const [ctaModalLoading, setCtaModalLoading] = useState(false)
    const [showCtaModal, setShowCtaModal] = useState(false)

    const closeCtaModal = () => {
        setShowCtaModal(false)
        setCtaModalLoading(false)
    }

    useEffect(() => {
        setSaveEnabled(!!cancellationType)
    }, [cancellationType])

    const saveBooking = useCallback(() => {
        if (!record || !vendor) return

        const isSeries = isCancellableClass || isCancellableSeries
        const isRateSet = !!Number(vendor.cancellation_rate)
        const offerCancelAll = (isCancellableClass || isCancellableSeries) && !location.doNotCancelAll
        const title = !forVendor && isLate && record.source && record.confirmed_date  && isRateSet?
            `Cancel and pay fee ${cancellationFeeString(vendor)}` : isCancellableSeries || forClass ?
            'Cancel this appointment' :
            'Cancel appointment'
        let destructiveButtonLabel = offerCancelAll ? "Cancel this and future dates" : title
        let optionalButtonLabel = offerCancelAll ? title : undefined

        let body = offerCancelAll ?
            `If you would like to cancel only this appointment choose "${optionalButtonLabel}". To cancel this and future appointments choose "Cancel this and future dates".` :
            `If you would like to cancel this appointment choose "${destructiveButtonLabel}"`
        if (forVendor && !!record.channel && !offerCancelAll) {
            optionalButtonLabel = title;
            destructiveButtonLabel = "Cancel & notify"
            body = `If you would like to cancel this appointment and send a notification to the client select "Cancel & notify". To cancel the appointment without notifying the client select "${optionalButtonLabel}".`
        }

        setCtaModalInfo({
            body,
            button: destructiveButtonLabel,
            optionalButton: optionalButtonLabel,
            title,
            danger: !isSeries && record.channel,
            onClick: (buttonIx: number) => {
                const cancelAll = offerCancelAll && buttonIx === ACTION_BUTTON
                const doNotNotify = !record.channel || buttonIx === OPTIONAL_BUTTON

                setCtaModalLoading(true)
                Result(dispatch(cancelBooking({
                    id: record.id,
                    enforce: shouldEnforce,
                    cancelAll,
                    doNotNotify,
                    cancellationMemo: cancellationReason,
                    cancellationType: cancellationType!.value
                })))
                    .then(() => dispatch(getRecord('booking', record.id)))
                    .then(() => setHasEdits(false))
                    .then(history.goBack)
                    .finally(() => setCtaModalLoading(false))
            }
        } as ModalInfo)
        setShowCtaModal(true)
    }, [cancellationReason, cancellationType, dispatch, forClass, forVendor, history.goBack, isCancellableClass, isCancellableSeries, isLate, location.doNotCancelAll, record, shouldEnforce, vendor])

    const sendMessage = useCallback(() =>
        dispatch(push(`/messages/${record.client}`)), [dispatch, record.client])

    const saveSubscription = useCallback(() => {
        if (!record || !vendor) return

        const body = shouldEnforce ?
            `By confirming cancellation, you have selected to enforce your policy, charging your client a ${subscriptionCancellationFeeString(vendor)} fee` :
            'Cancel this subscription'

        setCtaModalInfo({
            body,
            button: 'Cancel subscription',
            title: 'Cancel subscription',
            danger: record.channel,
            onClick: (buttonIx: number) => {
                setCtaModalLoading(true)
                Result(dispatch(cancelSubscription(record.id, shouldEnforce)))
                    .then(() => dispatch(getRecord('subscription', record.id)))
                    .then(() => setHasEdits(false))
                    .then(() => record.channel ? sendMessage() : history.goBack())
                    .finally(() => setCtaModalLoading(false))
            }
        } as ModalInfo)
        setShowCtaModal(true)
    }, [dispatch, history, record, sendMessage, shouldEnforce, vendor])


    const onSave = useCallback(() => {
        if (params.recordType === 'booking') {
            saveBooking()
        }
        else if (params.recordType === 'subscription') {
            saveSubscription()
        }
    }, [params.recordType, saveBooking, saveSubscription])

    const showEnforce = params.recordType === 'subscription' ?
        !!Number(vendor?.subscription_cancellation_rate) :
        !!Number(vendor?.cancellation_rate)

    let body = (
        <div className="servicesSettings" style={{width: '680px'}}>
            <div className="header">
                <h1>Cancellation</h1>
                <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                onClick={onSave}
                                disabled={!saveEnabled || state.settings.isSending}>
                    {state.settings.isSending ? <Dots /> : "Save"}
                </PSButtonPrimary>
            </div>
            <div className="separator" />
            <div className="form">
                <PSDropdown selected={cancellationType}
                            nameProperty="label"
                            onSelect={(selection) => {
                                setCancellationType(selection)
                            }}
                            label="Cancellation type (required)"
                            placeholder={<div className="grey">Select cancellation type</div>}>
                    <>
                        {cancellationTypeOptions.map(option => (
                            <Item key={`cancellation-type-${option.value}`} value={option}>
                                {option.label}
                            </Item>
                        ))}
                    </>
                </PSDropdown>
                <Field className="field">
                    <Label className="label">Cancellation reason</Label>
                    <PSTextarea
                            placeholder="Enter additional reasoning for record"
                            className="input"
                            minRows={8}
                            maxLength={999}
                            value={cancellationReason ?? ''}
                            onChange={value => setCancellationReason(value)}
                            onLearnMore={() => showLearnMore('cancel-a-client')}
                        />
                </Field>
                {showEnforce &&
                    <Field className="field">
                        <Checkbox checked={shouldEnforce}
                                disabled={state.settings.isSending}
                                onChange={(e) => setShouldEnforce(e.target.checked)}>
                            <Label className="withHint">Enforce cancellation policy</Label>
                            <Hint>{enforceText()}</Hint>
                        </Checkbox>
                    </Field>
                }
            </div>

            {learnMoreComponent}

            {showCtaModal && ctaModalInfo &&
                (ctaModalInfo.danger ?
                    <WarningModal
                        title={ctaModalInfo.title}
                        message={ctaModalInfo.body}
                        onClose={closeCtaModal}
                        extraButtonText={ctaModalInfo.optionalButton}
                        buttonText={ctaModalInfo.button}
                        onButtonClick={ctaModalInfo.onClick} /> :
                    <Modal onClose={closeCtaModal}>
                        <Header>{ctaModalInfo.title}</Header>
                        <Body>{ctaModalInfo.body}</Body>
                        <Close aria-label="Close modal" />
                        <Footer>
                            <FooterItem>
                                {!ctaModalLoading && <>
                                    {ctaModalInfo.optionalButton &&<PSButton style={{marginRight: '20px'}} onClick={() => ctaModalInfo.onClick(OPTIONAL_BUTTON)}>{ctaModalInfo.optionalButton}</PSButton>}
                                    <PSButtonPrimary onClick={() => ctaModalInfo.onClick(ACTION_BUTTON)}>{ctaModalInfo.button}</PSButtonPrimary>
                                </>}
                            </FooterItem>
                        </Footer>
                    </Modal>
                )}

            <Prompt
                when={hasEdits && !state.settings.isSending}
                message="Are you sure you'd like to leave this page without saving your changes?" />

        </div>
    )

    const title = {
        'booking': 'Appointment',
        'subscription': 'Subscription'
    }[params.recordType]

    return (
        <AppHeader title={title} showBackButton middleWidget={null}>
            {!isLoading && body}
            {isLoading && <Spinner size="128" color="#314A68" />}
        </AppHeader>
    )
}