import React from "react";
import {useAppDispatch} from "../../../hooks";
import {push} from "connected-react-router";
import {PSButtonPrimary, PSButton} from "../../app/PSButton";
import { BOOK_HOST } from "../../../utils/constants";

export const PurchaseReader = () => {
    const dispatch = useAppDispatch()

    const orderReader = () => {
        dispatch(push('/settings/reader/checkout'));
    }

    const addReader = () => {
        dispatch(push('/settings/reader/add'));
    }

    return (
        <div className="servicesSettings fullWidth purchaseReader">
            <img alt="card reader" src="/icons/card-reader-splash.png" />
            <div>
                <div className="row center">
                    <h1>Card Reader</h1>
                </div>
                <div className="row center">
                    <span className="darkGrey" style={{fontSize: '20px'}}>Swipe, tap magnetic stripe, or read chip cards.</span>
                </div>
                <div className="row" style={{marginTop: '33px', marginBottom: '22px'}}>
                    <PSButton style={{height: '40px', marginRight: '8px', fontSize: '15px', fontWeight: 700}}
                                onClick={addReader}>
                        Add an existing reader
                    </PSButton>
                    <PSButtonPrimary style={{height: '40px', marginLeft: '8px', fontSize: '15px', fontWeight: 700}}
                                        onClick={orderReader}>
                        Order a card reader
                    </PSButtonPrimary>
                </div>
                <div className="learnMore link">
                    <a href={`${BOOK_HOST}/pos-card-reader`} rel="noreferrer" target="_blank">
                        Learn More &gt;
                    </a>
                </div>
            </div>
        </div>
    )
}


