import React, {useCallback, useEffect, useState} from "react";
import {PSButtonPrimary, PSButtonSecondary} from "../../../app/PSButton";
import {useAppDispatch, useConfirmModal} from "../../../../hooks";
import {fetchContracts, fetchForms, saveUser, SettingsState} from "../../../../modules/settings";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";
import localforage from "localforage";
import {isEmpty, Result} from "../../../../utils";
import {BOOK_HOST} from "../../../../utils/constants";
import {PSFormFieldSelection} from "../../../app/PSFormFieldSelection";
import {PSDropdown} from "../../../app/PSDropdown";
import {colorThemeOptions, findSelection, SelectOption} from "../../Options";
import {Item} from "@zendeskgarden/react-dropdowns";
import {Dots, Spinner} from "@zendeskgarden/react-loaders";
import {Prompt} from "react-router";
import {SuccessModal} from "../../SuccessModal";
import {apiClient} from "../../../../modules/apiclient";
import {processError} from "../../../../modules/error";
import {push} from "connected-react-router";

export const LeadsEdit = () => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const [hasEdits, setHasEdits] = useState<boolean>(false)
    const [pageLoading, setPageLoading] = useState<boolean>(true)
    const [displayForms, setDisplayForms] = useState<boolean>(false)
    const [displayContracts, setDisplayContracts] = useState<boolean>(false)
    const [user, setUser] = useState<any>({})
    const [email, setEmail] = useState<string>('')
    const [username, setUsername] = useState<string>('')
    const [leadLink, setLeadLink] = useState<string>('')
    const [primaryColor, setPrimaryColor] = useState<string>('')
    const [secondaryColor, setSecondaryColor] = useState<string>('')
    const [leadForm, setLeadForm] = useState<string>('')
    const [leadContract, setLeadContract] = useState<string>('')
    const [colorOptions, setColorOptions] = useState<SelectOption[]>([])
    const [formOptions, setFormOptions] = useState<SelectOption[]>([])
    const [contractOptions, setContractOptions] = useState<SelectOption[]>([])
    const [showCopySuccess, setShowCopySuccess] = useState(false)
    const [showEmailSuccess, setShowEmailSuccess] = useState(false)

    useEffect(() => {
        localforage.getItem('user')
            .then((u: any) => {
                setUser(u)
                let actions = []
                if (u.allow_record){
                    actions.push(dispatch(fetchForms()))
                }
                if (u.allow_contract){
                    actions.push(dispatch(fetchContracts()))
                }
                return Promise.all(actions)
            })
            .then(() => setPageLoading(false))
    }, [dispatch])

    useEffect(() => {
        if (isEmpty(user)) return

        const colorListHex = colorThemeOptions.map(option => option.value)
        const primaryCheck = /^#[0-9a-f]{6}$/i.test(user.primary_color)
        const secondaryCheck = /^#[0-9a-f]{6}$/i.test(user.secondary_color)
        const primaryOptionMissing = primaryCheck && !colorListHex.includes(user.primary_color)
        const secondaryOptionMissing = secondaryCheck && !colorListHex.includes(user.secondary_color)
        const newColorOptions: SelectOption[] = []
        if (user.primary_color === user.secondary_color && primaryOptionMissing && secondaryOptionMissing){
            newColorOptions.push({ value: user.primary_color, label: 'Custom color' })
        }
        else {
            if (primaryOptionMissing){
                newColorOptions.push({ value: user.primary_color, label: 'Custom primary color' })
            }
            if (secondaryOptionMissing){
                newColorOptions.push({ value: user.secondary_color, label: 'Custom secondary color' })
            }
        }
        setColorOptions([...colorThemeOptions,...newColorOptions])
        setEmail(user.email || 'no_email')
        setUsername(!!user.username ? user.username : user.key)
        setLeadLink(`${BOOK_HOST}/lead/${!!user.username ? user.username : user.key}`)
        setPrimaryColor(!!user.primary_color ? user.primary_color : colorThemeOptions[0].value)
        setSecondaryColor(!!user.secondary_color ? user.secondary_color : colorThemeOptions[1].value)
        setLeadForm(!!user.lead_form ? user.lead_form : '')
        setLeadContract(!!user.lead_contract ? user.lead_contract : '')
    }, [user])

    useEffect(() => {
        if (state.forms.length) {
            setFormOptions(state.forms.map(ff => {return {value: ff.id, label: ff.name}}))
            setDisplayForms(true)
        }
    }, [state.forms])

    useEffect(() => {
        if (state.contracts.length) {
            setContractOptions(state.contracts.map(cc => {return {value: cc.id, label: cc.name}}))
            setDisplayContracts(true)
        }
    }, [state.contracts])

    const colorItems = useCallback(() => {
        return colorOptions.map(option =>
            <Item key={`color-${option.value}`} value={option}>
                <div style={{display: 'flex', gap: '7px', alignItems: 'center'}}>
                    <svg width="14" height="14"><circle cx="7" cy="7" r="7" fill={option.value}/></svg>
                    <div style={{color: '#000'}}>{option.label}</div>
                    <div style={{color: '#aaa'}}>{option.value}</div>
                </div>
            </Item>
        )
    }, [colorOptions])

    const formItems = useCallback(() => {
        return formOptions.map(option =>
            <Item key={`form-${option.value}`} value={option}>
                {option.label}
            </Item>
        )
    }, [formOptions])

    const contractItems = useCallback(() => {
        return contractOptions.map(option =>
            <Item key={`contract-${option.value}`} value={option}>
                {option.label}
            </Item>
        )
    }, [contractOptions])

    useEffect(() => {
        setHasEdits(
            user.primary_color !== primaryColor ||
            user.secondary_color !== secondaryColor ||
            user.lead_form !== leadForm ||
            user.lead_contract !== leadContract
        )
    }, [user, primaryColor, secondaryColor, leadForm, leadContract])

    const [setCtaModalInfo, setCtaModalLoading, showCtaModal, closeCtaModal, ctaComponent] = useConfirmModal()

    const displaySetupInstructions = useCallback(() => {
        if (!(leadLink && username && secondaryColor && email)) return

        const instructionText = <>
            <p>Here are simple directions to set up your online lead widget on your website and Facebook page.</p><br/>
            <p>You can also add your widget to your email signature or reference your widget link: {leadLink}</p><br/>
            <p>Per the above directions, please refer to the following "User ID" which is specific to your account only: {username}</p><br/>
            <p>If you are comfortable with HTML here is the code snippet to add to your web page:</p><br/>
            <p>&lt;div class="ps-book" data-lead="{username}" data-color="{secondaryColor}"&gt;&lt;/div&gt;&lt;script src="https://book.pocketsuite.io/static/javascript/widget.js"&gt;&lt;/script&gt;</p><br/>
            <p>Alternatively, we're happy to install this on your site. Just send us an email to support@pocketsuite.io with your website builder name (ie: Wix, Squarespace), username/password, and where you want it to display. Our team will complete this, typically within 48 hours.</p>
        </>
        setCtaModalInfo({
            title: "Your PocketSuite online lead widget setup instructions",
            body: instructionText,
            button: email !== 'no_email' ? "Send instructions via email" : "Close",
            onClick: () => {
                if (email !== 'no_email'){
                    apiClient.post(`/widget/${username}/lead`)
                        .then((resp) => {
                            closeCtaModal()
                            if (resp.data.status === 'ok'){
                                setShowEmailSuccess(true)
                            }
                        })
                        .catch(error => dispatch(processError(error)))
                }
                else {
                    closeCtaModal()
                }
            }
        })
        showCtaModal()
    }, [dispatch, leadLink, username, secondaryColor, email, setCtaModalInfo, closeCtaModal, showCtaModal])

    const onSave = () => {
        if (state.isSending) return

        const userParams: any = {}
        userParams.id = user.id
        userParams.primary_color = primaryColor
        userParams.secondary_color = secondaryColor
        userParams.lead_form = leadForm || ""
        userParams.lead_contract = leadContract || ""

        Result(dispatch(saveUser(userParams)))
            .then(() => localforage.getItem('user'))
            .then(u => setUser(u))
    }

    return (
        <div className="servicesSettings" style={{width: '750px'}}>
            <div className="header">
                <h1>Online leads</h1>
                {!pageLoading && !hasEdits &&
                    <>
                        <PSButtonSecondary style={{height: '40px'}}
                                           onClick={() => {
                                               window.open(leadLink, "_blank")
                                           }}>
                            Preview
                        </PSButtonSecondary>
                        <PSButtonPrimary style={{height: '40px', marginLeft: '16px'}}
                                         onClick={() => {
                                             navigator.clipboard.writeText(leadLink)
                                                 .then(() => setShowCopySuccess(true))
                                         }}>
                            Share
                        </PSButtonPrimary>
                    </>
                }
                {!pageLoading && hasEdits &&
                    <PSButtonPrimary style={{height: '40px'}}
                                     onClick={onSave}>
                        {state.isSending ? <Dots /> : "Save"}
                    </PSButtonPrimary>
                }
            </div>
            <div className="separator" />
            {pageLoading &&
                <div style={{textAlign: 'center', padding: '128px 0'}}>
                    <Spinner size="128" color="#314A68" />
                </div>
            }
            {!pageLoading &&
                <div className="form">
                    <PSFormFieldSelection
                        label="Online lead link"
                        helperLabel={leadLink}
                        linkText="Setup"
                        onClick={displaySetupInstructions}
                    />
                    <PSFormFieldSelection
                        label="Questions"
                        helperLabel="Questions entered can show up for new clients to fill out on your online lead widget."
                        linkText="View & edit questions"
                        onClick={() => dispatch(push('/settings/leads/questions'))}
                    />
                    <PSDropdown label="Primary color"
                                hint="Choose your widget colors here or send us your hex codes for more customization."
                                selected={findSelection(colorOptions, primaryColor)}
                                nameProperty={"label"}
                                onSelect={selected => setPrimaryColor(selected.value)}>
                        <>{colorItems()}</>
                    </PSDropdown>
                    <div style={{marginTop:"-10px"}}>
                        <PSDropdown label="Secondary color"
                                    selected={findSelection(colorOptions, secondaryColor)}
                                    nameProperty="label"
                                    onSelect={selected => setSecondaryColor(selected.value)}>
                            <>{colorItems()}</>
                        </PSDropdown>
                    </div>
                    {displayForms &&
                        <PSDropdown label="Requires form"
                                    hint="Attach a form for new leads to fill out during registration."
                                    selected={findSelection(formOptions, leadForm)}
                                    placeholder={<div style={{color: '#aaa'}}>{"Please Select"}</div>}
                                    nameProperty={"label"}
                                    onSelect={selected => setLeadForm(selected.value)}>
                            <>{formItems()}</>
                        </PSDropdown>
                    }
                    {displayContracts &&
                        <PSDropdown label="Requires signature"
                                    hint="Attach a contract for new leads to sign during registration."
                                    placeholder={<div style={{color: '#aaa'}}>{"Please Select"}</div>}
                                    selected={findSelection(contractOptions, leadContract)}
                                    nameProperty={"label"}
                                    onSelect={selected => setLeadContract(selected.value)}>
                            <>{contractItems()}</>
                        </PSDropdown>
                    }
                </div>
            }
            {showCopySuccess &&
                <SuccessModal
                    title="Online lead link copied!"
                    buttonText="Close"
                    body={<p>Your online lead link has been copied to your clipboard! Paste it to share with a client or on social media.</p>}
                    onClose={() => setShowCopySuccess(false)}
                    onAction={() => setShowCopySuccess(false)}
                />
            }
            {showEmailSuccess &&
                <SuccessModal
                    title="Success!"
                    buttonText="Close"
                    body={<p>Setup instructions sent to {email}.</p>}
                    onClose={() => setShowEmailSuccess(false)}
                    onAction={() => setShowEmailSuccess(false)}
                />
            }
            {ctaComponent}
            <Prompt
                when={hasEdits && !state.isSending}
                message="Are you sure you'd like to leave this page without saving your changes?" />
        </div>
    )
}