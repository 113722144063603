import React, { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { fetchEstimateNotes } from "../../modules/notes";
import { AppHeader } from "../app/AppHeader";
import { ViewNotes } from "./ViewNotes";

export const ViewEstimateNotes = () => {
    const params = useParams<{id?: string}>()
    const dispatch = useDispatch()

    const reloadData = useCallback(() => {
        if (!params.id) return

        return dispatch(fetchEstimateNotes(params.id))
    }, [params.id, dispatch])

    useEffect(() => {
        reloadData()
    }, [reloadData])

    const emptyState = <>
        <p>You don't have any notes yet</p>
        <p className="bold">Once you add estimate notes for this estimate they'll appear here. 😁</p>
    </>

    return (
        <AppHeader title='Estimate' showBackButton middleWidget={null}>
            <ViewNotes
                type='estimate'
                typeId={params.id ?? ''}
                emptyState={emptyState}
            />
        </AppHeader>
    )
}