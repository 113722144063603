import React from "react";
import { Modal, Header, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { PSButtonPrimary } from "../app/PSButton";
import Webcam from "react-webcam";

type Props = {
    onCapture: (image: string) => void,
    onCameraConnected: () => void,
    onClose: () => void,
    onError: () => void
}

export const WebCameraModal = ({ onCapture, onCameraConnected, onClose, onError }: Props) => {
    const [capture, webCamComponent] = useWebcamCapture({ onCapture, onCameraConnected, onError })

    return (
        <Modal onClose={onClose} style={{ width: "600px" }}>
            <Header style={{ fontWeight: 700, paddingLeft: 8 }}>
                Take photo
            </Header>
            <Body style={{ padding: "0" }}>
                {webCamComponent}
            </Body>
            <Footer>
                <FooterItem>
                    <PSButtonPrimary onClick={capture}>Take photo</PSButtonPrimary>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )
}

type UseWebcamCaptureReturnType = [() => void, JSX.Element]
type CaptureProps = {
    onCapture: (image: string) => void,
    onCameraConnected: () => void,
    onError: () => void
}

const useWebcamCapture = ({ onCapture, onError, onCameraConnected }: CaptureProps): UseWebcamCaptureReturnType => {
    const webcamRef = React.useRef(null);
    const takePhoto = React.useCallback(
        () => {
            // @ts-ignore
            onCapture(webcamRef.current?.getScreenshot());
        },
        [webcamRef, onCapture]
    );
    return [takePhoto, (
        <>
            <Webcam
                audio={false}
                height={375}
                ref={webcamRef}
                onUserMedia={onCameraConnected}
                onUserMediaError={onError}
                screenshotFormat="image/jpeg"
                width={600}
                videoConstraints={{
                    width: 600,
                    height: 375,
                    facingMode: "user"
                }}
            />
        </>
    )];
};
