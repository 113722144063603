import React from 'react'
import { PSButton } from './PSButton'
import { Button } from '@zendeskgarden/react-buttons'
import { ReactComponent as CloseIcon } from '../../icons/close.svg'
import { setBannerVisibility } from '../../modules/app'

import './banner.css'
import {useAppDispatch} from "../../hooks";
import {useSelector} from "react-redux";

interface AppState {
    bannerVisibility: boolean,
}

interface RootState {
    app: AppState,
}

export const NotificationBanner = () => {
    const dispatch = useAppDispatch()
    const state = useSelector((state: RootState) => state.app)

    const close = () => {
        dispatch(setBannerVisibility(false))
    }

    const remindMe = () => {
        localStorage.setItem('hide-notification-banner', (new Date()).getTime().toString())
        close()
    }

    const notificationPermission = () => {
        Notification.requestPermission(() => {
            close()
        })
    }

    if (!state.bannerVisibility) return null

    return (
        <div className="notificationBanner">
            <div className="text">Allow desktop notifications so you don't miss a message</div>
            <div className="buttons">
                <Button style={{padding:'0 16px', lineHeight: '15px'}}
                        onClick={e => notificationPermission()}
                        className="primary"
                        isPrimary>
                    Allow
                </Button>
                <PSButton onClick={(_) => remindMe()}>Remind Me</PSButton>
                <CloseIcon onClick={(_) => close()} className="close" />
            </div>
        </div>
    )
}