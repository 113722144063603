import React, {useCallback, useEffect, useState} from "react";
import {Modal, Body, Footer, FooterItem, Header} from "@zendeskgarden/react-modals";
import {Spinner} from "@zendeskgarden/react-loaders";
import {SettingsState} from "../../../modules/settings";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {Button} from "@zendeskgarden/react-buttons";
import {Field, Input, Label} from "@zendeskgarden/react-forms";
import {Datepicker} from "@zendeskgarden/react-datepickers";
import moment from "moment";
import {PSDropdown} from "../../app/PSDropdown";
import {Item} from "@zendeskgarden/react-dropdowns";
import {
    durationOptions, SelectOption,
    timeHourOptions,
    timeMinuteIntervalFiveOptions
} from "../../settings/Options";
import {useAppDispatch} from "../../../hooks";
import {Availability} from "../../../models/Availability";
import {apiClient} from "../../../modules/apiclient";
import {GuardrailModal} from "../../settings/GuardrailModal";
import {ReactComponent as Chevron} from "../../../icons/left-arrow.svg";

type Props = {
    onClose: () => void,
    onDone: (date: moment.Moment, duration: number, availabilities: Availability[]) => void,
    allowRecurring: boolean,
    currentDate: Date,
    currentAvailabilities: Availability[],
    currentDuration: number,
    currentLocation?: string,
    currentItemID?: string,
    currentEmployeeID?: string,
}

export const ScheduleDateModal = ({onClose, onDone, allowRecurring, currentDate, currentAvailabilities, currentDuration, currentLocation, currentItemID, currentEmployeeID}: Props) => {
    const dispatch = useAppDispatch()
    const state: SettingsState = useSelector((state: RootState) => state.settings)

    const [date, setDate] = useState(currentDate)

    // Break the date's time up into SelectOption values
    const [selectedHour, setSelectedHour] = useState<SelectOption | ''>('')
    const [selectedMinute, setSelectedMinute] = useState<SelectOption | ''>('')
    useEffect(() => {
        const dateHourValue = moment(currentDate).format('hh')
        const sH = timeHourOptions.find(option => option.value === dateHourValue.toString())
        setSelectedHour(sH || '')

        const dateMinuteValue = moment(currentDate).minute()
        const sM = timeMinuteIntervalFiveOptions.find(option => option.value === dateMinuteValue.toString())
        setSelectedMinute(sM || '')
    }, [currentDate])

    useEffect(() => {
        setSelectedAvailabilities(currentAvailabilities)
    }, [currentAvailabilities])

    const [selectedDuration, setSelectedDuration] = useState<SelectOption | ''>('')
    useEffect(() => {
        setSelectedDuration(
            durationOptions.find(option => option.value === currentDuration.toString()) ||
            durationOptions.find(option => option.value === '') ||
            ''
        )
    }, [currentDuration])

    const [availability, setAvailability] = useState<Availability[]>([])
    const [selectedAvailabilities, setSelectedAvailabilities] = useState<Availability[]>([])
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        if (!selectedDuration || !date || !selectedHour || !selectedMinute) return

        setIsLoading(true)

        // Create data from date, selectedHour and selectedMinute
        const fullDate = moment(date)
            .hours(Number(selectedHour.value))
            .minutes(Number(selectedMinute.value))
            .seconds(0)
        const params: any = {
            start: fullDate.toDate(),
            duration: selectedDuration.value,
        }
        if (currentLocation)
            params.location = currentLocation
        if (currentItemID)
            params.item = currentItemID
        if (currentEmployeeID)
            params.employee = currentEmployeeID
        apiClient.post('/availability/client', params)
            .then(resp => resp.data)
            .then(json => {
                setAvailability(json.results[0].availability.map((j: any) => {
                    const a = new Availability()
                    a.setData(j)
                    return a
                }).filter((a: Availability) => a.end.isAfter(fullDate)))
            })
            .finally(() => setIsLoading(false))
    }, [dispatch, selectedDuration, date, selectedHour, selectedMinute, currentLocation])

    // TODO Infinite scrolling of availability list

    const [selectedAvailability, setSelectedAvailability] = useState<Availability | undefined>()
    const selectAvailability = (a: Availability, skipGuardrail?: boolean) => {
        // Create an array with the selection removed to see if it was added or removed
        let removedSelection: Availability[] = []
        let removingSchedule = false

        // If we  have recurring schedules on and this item is listed already, we don't want
        // to show a guardrail.
        if (allowRecurring) {
            removedSelection = selectedAvailabilities.filter(value =>
                !(value.start.isSame(a.start) && value.duration === a.duration)
            )
            removingSchedule = removedSelection.length !== selectedAvailabilities.length

            if (removingSchedule)
                skipGuardrail = true
        }

        if (a.available || skipGuardrail) {
            if (!allowRecurring)
                onDone(a.start, a.duration, [a])
            else {
                // Remove from array if already selected
                if (removingSchedule)
                    setSelectedAvailabilities(removedSelection)
                else
                    setSelectedAvailabilities([ ...selectedAvailabilities, a])
            }
        }
        else {
            setSelectedAvailability(a)
            setGuardrailTitle('Double book')
            setGuardrailBody('Would you like to double book this time?')
            setGuardrailButton('Double book')
            setGuardrailType('doublebook')
            setShowGuardrail(true)
        }
    }

    const doneHandler = () => {
        if (selectedAvailabilities.length > 0) {
            const a = selectedAvailabilities[0]
            onDone(a.start, a.duration, selectedAvailabilities)
        }
        else
            onClose()
    }

    const [showDates, setShowDates] = useState(false)
    const viewDatesHandler = () => {
        setShowDates(true)
    }

    // Guardrail states
    const [showGuardrail, setShowGuardrail] = useState(false)
    const [guardrailTitle, setGuardrailTitle] = useState('')
    const [guardrailBody, setGuardrailBody] = useState<string | React.ReactNode>('')
    const [guardrailButton, setGuardrailButton] = useState('')
    const [guardrailType, setGuardrailType] = useState('')
    const guardrailAction = useCallback(() => {
        switch (guardrailType) {
            case 'doublebook':
                selectAvailability(selectedAvailability as Availability, true)
                break
            default:
                console.log(`Unknown guardrail type: ${guardrailType}`)
        }
        setShowGuardrail(false)
    }, [guardrailType, selectAvailability, selectedAvailability])

    let lastSeenDOY: number

    return (
        <Modal className="selectModal scheduleDateModal"
               onClose={onClose}
        >
            <Header className="header">
                {showDates && (
                    <div onClick={() => setShowDates(false)}
                         style={{marginRight: '8px', cursor: 'pointer', width: '12px'}}
                    >
                        <Chevron />
                    </div>
                )}
                {showDates ? 'Dates' : 'Date & Time'}
            </Header>
            <Body className="body">
                {!showDates && (
                    <>
                    {state.isSending && (
                        <div style={{textAlign: 'center', padding: '24px 0'}}>
                            <Spinner size="128" color="#314A68" />
                        </div>
                    )}
                    {!state.isSending && (
                        <>
                            <div className="fields">
                                <div className="fieldGroup">
                                    <Field className="fieldWrapper">
                                        <Label className="label">Date</Label>
                                        <Datepicker value={date}
                                                    minValue={moment().toDate()}
                                                    onChange={date => setDate(date)}>
                                            <Input />
                                        </Datepicker>
                                    </Field>
                                    <Field className="fieldWrapper">
                                        <Label className="label">Duration</Label>
                                        <PSDropdown selected={selectedDuration}
                                                    nameProperty="label"
                                                    onSelect={(selected) => setSelectedDuration(selected)}
                                                    label="">
                                            {durationOptions.map(o => (
                                                <Item key={`duration-${o.value}`} value={o}>
                                                    {o.label}
                                                </Item>
                                            ))}
                                        </PSDropdown>
                                    </Field>
                                </div>
                                <div className="fieldGroup" style={{paddingTop: '8px'}}>
                                    <Field className="fieldWrapper">
                                        <Label className="label">Time (hour)</Label>
                                        <PSDropdown selected={selectedHour}
                                                    nameProperty="label"
                                                    onSelect={(selected) => setSelectedHour(selected)}
                                                    label="">
                                            {timeHourOptions.map(o => (
                                                <Item key={`time-hour-${o.value}`} value={o}>
                                                    {o.label}
                                                </Item>
                                            ))}
                                        </PSDropdown>
                                    </Field>
                                    <Field className="fieldWrapper">
                                        <Label className="label">Time (minutes)</Label>
                                        <PSDropdown selected={selectedMinute}
                                                    nameProperty="label"
                                                    onSelect={(selected) => setSelectedMinute(selected)}
                                                    label="">
                                            {timeMinuteIntervalFiveOptions.map(o => (
                                                <Item key={`time-minute-${o.value}`} value={o}>
                                                    {o.label}
                                                </Item>
                                            ))}
                                        </PSDropdown>
                                    </Field>
                                </div>
                            </div>
                            <>
                                {isLoading && (
                                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                                        <Spinner size="128" color="#314A68" />
                                    </div>
                                )}
                                {!isLoading && availability.map(a => {
                                    // Show the date header if the previous date is different than this date
                                    let dateHeader
                                    if (lastSeenDOY !== moment(a.start).dayOfYear()) {
                                        lastSeenDOY = moment(a.start).dayOfYear()
                                        dateHeader = (
                                            <>
                                                <div className="dateHeader">{moment(a.start).format('dddd, MMMM D')}</div>
                                                <div className="dateSeparator" />
                                            </>
                                        )
                                    }

                                    // Determine if this is selected already
                                    const isSelected = selectedAvailabilities.filter(value =>
                                        value.start.isSame(a.start) && value.duration === a.duration
                                    )?.length === 1

                                    return (
                                        <div key={`availability-${a.start.toString()}`}>
                                            {dateHeader}
                                            <div className={`serviceCard availabilityCard ${isSelected ? "selected" : ""}`}>
                                                <div className="details">
                                                    <div className="name">
                                                        {a.start.format('h:mm a')} - {a.end.format('h:mm a')}
                                                    </div>
                                                    <div className={`subtitle ${a.available ? 'green' : 'red'}`}>
                                                        {a.available ? 'Available' : 'Unavailable'}
                                                    </div>
                                                </div>
                                                <div className="action"
                                                     onClick={() => selectAvailability(a)}
                                                >
                                                    {isSelected ? "Selected" : "Select"}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        </>
                    )}
                    {showGuardrail && (
                        <GuardrailModal title={guardrailTitle}
                                        body={guardrailBody}
                                        buttonText={guardrailButton}
                                        onClose={() => setShowGuardrail(false)}
                                        onAction={guardrailAction} />
                    )}
                    </>
                )}
                {showDates && (
                    <>
                        {selectedAvailabilities.map(a => (
                            <div className="serviceCard" key={`availability-${a.start}`}>
                                <div className="details">
                                    <div className="name">
                                        {a.start.format('ddd, MMM Do')}
                                    </div>
                                    <div className="subtitle">
                                        {a.start.format('h:mm a')}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </Body>
            <Footer className="footer">
                <FooterItem>
                    {!showDates && (
                       <>
                           {!allowRecurring && (
                               <Button onClick={() => onClose()}>Cancel</Button>
                           )}
                           {allowRecurring && (
                               <>
                                   <Button onClick={viewDatesHandler}
                                           style={{marginRight: '24px'}}
                                           disabled={selectedAvailabilities.length === 0}
                                   >
                                       View dates ({selectedAvailabilities.length})
                                   </Button>
                                   <Button onClick={doneHandler} isPrimary>
                                       Done
                                   </Button>
                               </>
                           )}
                       </>
                    )}
                </FooterItem>
            </Footer>
        </Modal>
    )
}