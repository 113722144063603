import React, {useEffect} from "react";
import {useAppDispatch} from "../../../hooks";
import {
    clearSelections,
    selectContract,
    setContracts
} from "../../../modules/onboarding";
import { OnboardingContracts } from "../../onboarding/OnboardingContracts";
import {RootState} from "../../../store";
import {useSelector} from "react-redux";
import {useLocation} from "react-router";

export const ContractFromDefaults = () => {
    const dispatch = useAppDispatch()
    const state: Pick<RootState, "onboarding"> = useSelector((state: RootState) => {
        return {
            onboarding: state.onboarding,
        }
    })

    const routerLocation = useLocation()
    const locationState: any = routerLocation.state

    // Ensure state is clear when loading this
    useEffect(() => {
        if (state.onboarding.refreshData === false) return

        dispatch(clearSelections())
        dispatch(setContracts(locationState?.contracts ?? []))
        locationState?.contracts?.forEach((c: any) => {
            dispatch(selectContract(c.id, true))
        })
    }, [dispatch, state.onboarding.refreshData, locationState])

    return <OnboardingContracts forOnboarding={false}
                                allEditionItemIDs={locationState?.editionItemIDs}
                                initialStep={locationState?.initialStep} />
}