import {Body, Close, Header, Modal} from "@zendeskgarden/react-modals";
import {Spinner} from "@zendeskgarden/react-loaders";
import React, {useRef} from "react";
import ReactHlsPlayer from "react-hls-player";

type Props = {
    title: string,
    text?: string,
    videoURL?: string,
    hlsVideoURL?: string,
    isLoading: boolean,
    hideControls?: boolean,
    onClose: () => void,
    onEnded?: () => void
}
export const PSVideoModal = ({title, text, videoURL, hlsVideoURL, isLoading, hideControls, onClose, onEnded}: Props) => {
    const videoPlayerRef = useRef<HTMLVideoElement>(null)

    return (
        <Modal className="videoPlayerModal" onClose={onClose}>
            <Header style={{paddingLeft: 24}}>
                {title}
            </Header>

            <Body style={{paddingLeft: 24, paddingRight: 24}}>
                {isLoading && (
                    <Spinner size="128" color="#314A68" />
                )}
                {text && (
                    <p className="text">{text}</p>
                )}
                {!isLoading && videoURL && (
                    <iframe title={title}
                            src={videoURL}
                            width="640"
                            height="360"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen />
                )}
                {!isLoading && hlsVideoURL && (
                    <ReactHlsPlayer
                        playerRef={videoPlayerRef}
                        src={hlsVideoURL}
                        controls={!hideControls}
                        autoPlay
                        width="640px"
                        height="360px"
                        onEnded={onEnded}
                    />
                )}
            </Body>
            <Close aria-label="Close modal" />
        </Modal>
    )
}