import React, { useCallback, useEffect, useState } from 'react'
import localforage from 'localforage'
import { userLogout } from '../../modules/login'
import { ContactAvatar } from '../contacts/ContactAvatar'
import { ReactComponent as LogoutIcon } from '../../icons/logout.svg'
import { useDispatch } from 'react-redux'

export const UserHeader = (props) => {
    const dispatch = useDispatch()

    const [ isMenuOpen, setMenuOpen ] = useState(false)
    const toggleMenu = useCallback(() => {
        setMenuOpen(!isMenuOpen)
    }, [isMenuOpen])

    useEffect(() => {
        const handleClick = () => {
            if (isMenuOpen)
                toggleMenu()
        }

        if (isMenuOpen)
            window.addEventListener('click', handleClick)
        else
            window.removeEventListener('click', handleClick)

        return _ => {
            window.removeEventListener('click', handleClick)
        }
    }, [toggleMenu, isMenuOpen])


    const [ loginInfo, setLoginInfo ] = useState(null)
    useEffect(() => {
        localforage.getItem('user').then((user) => {
            setLoginInfo(user)
        }).catch(console.error)
    }, [])

    let profileBox = <span />
    if (loginInfo) {
        let userImage
        if (loginInfo.image_uri !== '') {
            userImage = (
                <img width="36" height="36"
                     style={{
                         borderRadius: '50%',
                         marginTop: '-8px',
                         border: '0.5px solid rgba(0, 0, 0, 0.15)'
                     }}
                     alt={loginInfo.name}
                     src={loginInfo.image_uri}/>
            )
        }
        else {
            userImage = (
                <ContactAvatar user={loginInfo} size="small" style={{marginTop: '-6px'}} />
            )
        }

        profileBox = (
            <div style={{display: 'flex'}}>
                <span
                    onClick={toggleMenu}
                    style={{display: 'flex', flexDirection: 'row', cursor: 'pointer'}}>
                    <div style={{marginRight: '16px', fontSize: '15px', color: '#000000'}}>
                        {loginInfo.name}
                    </div>
                    <span style={{width: '36px', height: '36px'}}>
                        {userImage}
                    </span>
                </span>
                <div style={{
                    display: isMenuOpen ? 'block' : 'none',
                    position: 'absolute',
                    zIndex: '100000',
                    boxShadow: '0px 14px 14px rgba(0, 0, 0, 0.07)',
                    borderRadius: '18px',
                    width: '250px',
                    top: '60px',
                    left: 'calc(100% - 273px)',
                    backgroundColor: '#ffffff',
                    padding: '20px',
                }}>
                    <span style={{cursor: 'pointer'}}>
                        <LogoutIcon />
                        <span
                            style={{color: '#E22216', fontSize: '15px', marginLeft: '8px', verticalAlign: 'text-bottom'}}
                            onClick={e => dispatch(userLogout())}
                        >
                            Logout
                        </span>
                    </span>
                </div>
            </div>
        )
    }

    return profileBox
}