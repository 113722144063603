import React, {useEffect, useState} from "react";
import {useAppDispatch, usePSUser} from "../../hooks";
import {AppHeader} from "../app/AppHeader";
import {PSButtonPrimary} from "../app/PSButton";
import {useLocation, useParams} from "react-router";
import {Item} from "../../models/Item";
import {OnboardingItem} from "./OnboardingItem";
import localforage from "localforage";
import {Spinner} from "@zendeskgarden/react-loaders";
import {apiClient} from "../../modules/apiclient";
import {
    nextDefaultInstallStep,
    nextOnboardingStep
} from "../../utils/onboarding";
import {processError} from "../../modules/error";
import {
    clearSelections,
    selectItem,
    setItems, setRefreshData,
    updateItem
} from "../../modules/onboarding";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {Result} from "../../utils";
import {WarningModal} from "../app/WarningModal";
import {Edition} from "../../models/Edition";
import {DefaultsEmptyState} from "../settings/DefaultsEmptyState";

type Props = {
    forOnboarding?: boolean;
    allEditionItemIDs?: string[];
    initialStep?: string;
}

export const OnboardingItems = ({forOnboarding, allEditionItemIDs, initialStep}: Props) => {
    const dispatch = useAppDispatch()
    const state: Pick<RootState, "onboarding"> = useSelector((state: RootState) => {
        return {
            onboarding: state.onboarding,
        }
    })
    const user = usePSUser()

    const depluralize = (s?: string) => {
        if (!s) return
        if (s === 'classes') return 'class'
        return s.substr(0, s.length-1)
    }

    const params: any = useParams()

    const routerLocation = useLocation()
    const locationState: any = routerLocation.state
    const type = locationState?.type ?? depluralize(params.itemType ?? routerLocation.pathname.split('/').pop()) ?? 'service'
    const selectedByDefault = locationState?.selectedByDefault ?? false
    const itemsInCategories = locationState?.itemsInCategories
    forOnboarding = locationState?.forOnboarding ?? forOnboarding ?? true

    const pluralType = type === 'class' ? 'classes' : `${type}s`
    const title = type === 'gift' ? 'Gift Certificates' : pluralType

    const [edition, setEdition] = useState<Edition>()
    const [showLoadingMessage, setShowLoadingMessage] = useState('')
    const [showPremiumWarningModal, setShowPremiumWarningModal] = useState(false)

    const [nextEnabled, setNextEnabled] = useState(forOnboarding)
    useEffect(() => {
        setNextEnabled(forOnboarding || state.onboarding.selectedItemIDs.length > 0 || !!initialStep)
    }, [forOnboarding, state.onboarding.selectedItemIDs])

    const [header, setHeader] = useState(`Select ${pluralType}`)
    useEffect(() => {
        if (state.onboarding.selectedItemIDs.length === 0)
            setHeader(`Select ${title}`)
        else
            setHeader(`Select ${title} (${state.onboarding.selectedItemIDs.length})`)
    }, [title, state.onboarding.selectedItemIDs])

    useEffect(() => {
        if (state.onboarding.refreshData === false || !forOnboarding) return

        if (selectedByDefault && state.onboarding.selectedItemIDs.length === 0) {
            dispatch(clearSelections())
            // Select all items
            state.onboarding.items.filter(i => i.type === type).forEach(item => {
                if (!item.id) return
                dispatch(selectItem(item.id, true))
            })
        }
        // We only want to select default items if the type or items list changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, type, state.onboarding.items, state.onboarding.refreshData])

    useEffect(() => {
        return () => {
            dispatch(setRefreshData(true))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type])

    useEffect(() => {
        if (!user || state.onboarding.refreshData === false) return

        const editionID = user.edition ? user.edition : `${user.industry}-industry-edition`
        apiClient.post('/edition/sync', {edition: editionID})
            .then(resp => resp.data)
            .then(json => setEdition(json))
    }, [dispatch, user])

    useEffect(() => {
        if (!user || state.onboarding.refreshData === false) return

        // If we already have items of this type loaded, bail out
        const itemTypes = state.onboarding.items.filter(i => i.type === type)
        if (itemTypes.length > 0) return

        setShowLoadingMessage(`Loading...`)
        const editionID = user.edition ? user.edition : `${user.industry}-industry-edition`
        apiClient.post('/edition/sync', {edition: editionID})
            .then(resp => resp.data)
            .then(json => {
                if (json[pluralType] && json[pluralType].length > 0) {
                    if (forOnboarding) {
                        dispatch(clearSelections())
                        if (itemsInCategories?.length > 0) {
                            const categoryItems = json[pluralType].filter((i: any) => itemsInCategories.indexOf(i.id) > -1)
                            dispatch(setItems(categoryItems))
                        }
                        else {
                            // Set the items from the edition bundle
                            dispatch(setItems(json[pluralType]))
                        }
                    }

                    // Get the user's active items of this type, so if they have one from
                    // this bundle, we can show it as it was edited and select it
                    apiClient.post('/item/sync', {type: type})
                        .then(resp => resp.data)
                        .then(itemJson => {
                            if (!forOnboarding) {
                                const installedDefaultItemIDs = itemJson.items
                                    .filter((i: any) => !!i.edition_item)
                                    .map((i: any) => i.edition_item)
                                const newItemsFromEdition = json[pluralType].filter((i: any) => {
                                    return installedDefaultItemIDs.indexOf(i.id) === -1
                                })
                                dispatch(setItems(newItemsFromEdition))
                                return
                            }

                            itemJson.items.filter((i: any) => !!i.edition_item).forEach((i: any) => {
                                const itemEditionID = state.onboarding.selectedItemIDs.find(itemID => itemID === i.edition_item)
                                if (!itemEditionID) {
                                    // Select it in the UI now
                                    dispatch(selectItem(i.edition_item, true))
                                    // Replace the edition built in item with the installed item that is customized
                                    // but retain the edition ID and image_uri
                                    const editionItem = json[pluralType].find((j: any) => j.id === i.edition_item)
                                    if (editionItem) {
                                        for (const property in editionItem) {
                                            if (property === 'image_uri' || property === 'id' || property === 'components') continue
                                            editionItem[property] = i[property]
                                        }
                                        dispatch(updateItem(editionItem, true))
                                    }
                                }
                            })
                        })
                        .finally(() => setShowLoadingMessage(''))
                }
                else {
                    if (forOnboarding) {
                        // No items found, so go to the next step
                        Result(dispatch(nextOnboardingStep(user)))
                            .finally(() => setShowLoadingMessage(''))
                    }
                    else {
                        setShowLoadingMessage('')
                    }
                }
            })
    }, [dispatch, forOnboarding, user, type, pluralType])

    const onNextHandler = () => {
        if (!user) return

        setShowLoadingMessage('Saving...')

        const editionID = user.edition || `${user.industry}-industry-edition`

        // Send the new items with the proper IDs set
        const clonedItems = state.onboarding.selectedItemIDs.map((id: string) => {
            const i = state.onboarding.items.find(i => i.id === id)
            if (!i) return null

            const newItem = {
                ...i,
                onboarding_contract_id: undefined,
                onboarding_form_id: undefined,
                edition: editionID,
                edition_item: i.id
            }
            delete newItem.id
            return newItem
        }).filter(i => !!i)

        const postData = {
            items: clonedItems,
            edition: editionID,
            forOnboarding,
        }

        apiClient.post(`/onboarding/setup/${pluralType}`, postData)
            .then(resp => resp.data)
            .then(json => {
                if (json.user) localforage.setItem('user', json.user)
                if (forOnboarding) {
                    Result(dispatch(nextOnboardingStep(json.user)))
                        .finally(() => setShowLoadingMessage(''))
                }
                else {
                    // If from a category install, reuse the editionItemIDs to
                    // keep going through all the items in the category. Otherwise,
                    // pass in the newly installed item ids
                    const editionItemIDs = allEditionItemIDs ? allEditionItemIDs : [...state.onboarding.selectedItemIDs]
                    Result(dispatch(nextDefaultInstallStep(editionItemIDs, editionID, initialStep!, pluralType)))
                        .finally(() => setShowLoadingMessage(''))
                }
            })
            .catch(error => {
                dispatch(processError(error))
                setShowLoadingMessage('')
                return Promise.reject()
            })
    }

    const [industryTitle, setIndustryTitle] = useState('your industry')
    useEffect(() => {
        if (!user) return
        localforage.getItem('industries').then((industries: any) => {
            const industry = industries?.find((i: any) => user.industry === i.id)
            if (industry)
                setIndustryTitle(`${industry.pluralized_title}`)
        })
    }, [user])

    const [itemHelperText, setItemHelperText] = useState('')
    useEffect(() => {
        let helperText = ''
        switch (type) {
            case 'product':
                helperText = 'Add-on products can be added to any client booking. '
                break
            case 'subscription':
                helperText = 'The simple way to recurring payments for your business. Select a subscription to add it to your account.'
                break
            case 'package':
                helperText = 'Bundle offerings together as “packages” that your customers can buy. We’ve created a few just for you.'
                break
            case 'class':
                helperText = 'This set of curated classes will help keep you and your clients on a set schedule.'
                break
            case 'reservation':
                helperText = 'Sell overnight and multi-day appointments as reservations to keep your other availability open. Here are some that might be useful.'
                break
            case 'service':
            default:
                helperText = `Here’s a collection of recommended services for ${industryTitle}. Select the ones that you’d like to add to your account and make edits if needed.`
        }
        setItemHelperText(helperText)
    }, [type, industryTitle])

    const body = (
        <>
            {showLoadingMessage && (
                <div className="loadingOverlay">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                        <h1>{showLoadingMessage}</h1>
                    </div>
                </div>
            )}
            <div className="scheduleClient servicesSettings onboarding onboardingItems">
                <div className="header">
                    <h1>{header}</h1>
                    {edition?.allow_basic_plan && (type === 'package' || type === 'subscription' || type === 'product' || type === 'reservation') && (
                        <span className="premiumTag">Premium</span>
                    )}
                    <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                     onClick={() => onNextHandler()}
                                     disabled={!nextEnabled}
                    >
                        Next
                    </PSButtonPrimary>
                </div>

                <div className="separator" />

                <div className="form">
                    <div className="text">
                        {itemHelperText}
                    </div>
                    {state.onboarding.items.map((item: Item) => (
                        <OnboardingItem item={item}
                                        key={item.id}
                                        isChecked={state.onboarding.selectedItemIDs.findIndex((id: string) => id === item.id) > -1}
                                        forOnboarding={forOnboarding}
                                        onToggleItem={(enabled: boolean) => {
                                            dispatch(selectItem(item.id!, enabled))
                                            // Show the premium item warning modal for premium items, abmp referrals, and basic plan users that have not seen this before
                                            if (edition?.allow_basic_plan &&
                                                (item.type === 'package' || item.type === 'subscription' || item.type === 'product' || item.type === 'reservation') &&
                                                (user && !user.plan) &&
                                                enabled &&
                                                sessionStorage.getItem('viewed_premium_upsell') !== '1') {
                                                setShowPremiumWarningModal(true)
                                                sessionStorage.setItem('viewed_premium_upsell', '1')
                                            }
                                        }} />
                    ))}
                </div>
            </div>

            {showPremiumWarningModal && (
                <WarningModal title="Premium feature selection"
                              message="You selected a premium feature that is not included in the ABMP Basic Edition. Premium default items will be hidden until you upgrade to the ABMP Premium Edition."
                              buttonText="Dismiss"
                              onClose={() => setShowPremiumWarningModal(false)}
                              onButtonClick={() => setShowPremiumWarningModal(false)}
                />
            )}
        </>
    )

    if (forOnboarding) {
        return (
            <AppHeader title="Setup" showBackButton middleWidget={null}>
                {body}
            </AppHeader>
        )
    }

    if (!showLoadingMessage && state.onboarding.items.length === 0) {
        return (
            <DefaultsEmptyState title={title} />
        )
    }

    return body
}