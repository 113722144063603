import React from 'react'
import './dashboard.css'
import 'react-vis/dist/style.css'
import {Dashboard} from "../../models/Dashboard";
import {ReactComponent as CalendarSyncIcon} from "../../icons/ps_settings_calendary_sync_nobg.svg";

type Props = {
    dashboard: Dashboard,
    onClick: () => void,
}


export const DashboardAlertBanner = ({dashboard, onClick}: Props) => {
    return (
        <div className="alertGroupWrapper">
            <div className="alertGroup">
                <div className="line">
                    <div className="icon">
                        <CalendarSyncIcon />
                    </div>
                    <div className="title" style={{color: '#E22216'}}>
                        {dashboard.title}
                    </div>
                    <div className="status">{dashboard.status}</div>
                    <div className="view" onClick={onClick}>View</div>
                </div>
            </div>
        </div>
    )
}
