import React, {useEffect, useState} from "react";
import {AppHeader} from "../app/AppHeader";
import {Spinner} from "@zendeskgarden/react-loaders";
import {PSButtonPrimary} from "../app/PSButton";
import {useAppDispatch, usePSUser} from "../../hooks";
import {RootState} from "../../store";
import {useSelector} from "react-redux";
import {Contract} from "../../models/Contract";
import {OnboardingContract} from "./OnboardingContract";
import localforage from "localforage";
import {selectContract, setContracts, setRefreshData, updateContract} from "../../modules/onboarding";
import {apiClient} from "../../modules/apiclient";
import {nextDefaultInstallStep, nextOnboardingStep} from "../../utils/onboarding";
import {processError} from "../../modules/error";
import {Result} from "../../utils";
import {useLocation} from "react-router";
import {ACTION_BUTTON, WarningModal} from "../app/WarningModal";
import {Item} from "../../models/Item";
import {DefaultsEmptyState} from "../settings/DefaultsEmptyState";

type Props = {
    forOnboarding?: boolean;
    allEditionItemIDs?: string[];
    initialStep?: string;
}

export const OnboardingContracts = ({forOnboarding, allEditionItemIDs, initialStep}: Props) => {
    const dispatch = useAppDispatch()
    const state: Pick<RootState, "onboarding"> = useSelector((state: RootState) => {
        return {
            onboarding: state.onboarding,
        }
    })
    const user = usePSUser()

    const location = useLocation()
    const locationState: any = location.state
    const selectedByDefault = locationState?.selectedByDefault ?? false
    const editionItems = locationState?.editionItems ?? []
    forOnboarding = locationState?.forOnboarding ?? forOnboarding ?? true

    const [showLoadingMessage, setShowLoadingMessage] = useState('')
    const [showLinkedContractWarning, setShowLinkedContractWarning] = useState<{contract: Contract, item: Item}>()

    const [nextEnabled, setNextEnabled] = useState(forOnboarding)
    useEffect(() => {
        setNextEnabled(forOnboarding || state.onboarding.selectedContractIDs.length > 0 || (!!initialStep && initialStep !== 'contracts'))
    }, [forOnboarding, state.onboarding.selectedContractIDs])

    const [header, setHeader] = useState('Select Contracts')
    useEffect(() => {
        if (state.onboarding.selectedContractIDs.length === 0)
            setHeader('Select Contracts')
        else {
            setHeader(`Select Contracts (${state.onboarding.selectedContractIDs.length})`)
        }
    }, [state.onboarding.selectedContractIDs])

    useEffect(() => {
        if (state.onboarding.refreshData === false || !forOnboarding) return

        if (selectedByDefault && state.onboarding.selectedContractIDs.length === 0) {
            state.onboarding.contracts.forEach(contract => {
                if (!contract.id) return
                dispatch(selectContract(contract.id, true))
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.onboarding.contracts, state.onboarding.refreshData])

    useEffect(() => {
        return () => {
            dispatch(setRefreshData(true))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!user || state.onboarding.refreshData === false) return
        if (state.onboarding.contracts.length > 0) return

        setShowLoadingMessage('Loading...')

        // Load from the server if no onboarding contracts are saved in state
        const editionID = user.edition ? user.edition : `${user.industry}-industry-edition`
        apiClient.post('/edition/sync', {edition: editionID})
            .then(resp => resp.data)
            .then(json => {
                if (json.contracts && json.contracts.length > 0) {
                    if (forOnboarding) {
                        // Set the contracts from the edition bundle
                        dispatch(setContracts(json.contracts))
                    }

                    // Get the user's current contracts, so we can render them
                    // with the edited fields and select it
                    apiClient.post('/contract/sync')
                        .then(resp => resp.data)
                        .then(contractsJson => {
                            if (!forOnboarding) {
                                const installedDefaultContracts = contractsJson.contracts
                                    .filter((c: any) => !!c.edition_contract)
                                    .map((c: any) => c.edition_contract)
                                const newContractsFromEdition = json.contracts.filter((c: any) => {
                                    return installedDefaultContracts.indexOf(c.id) === -1
                                })
                                dispatch(setContracts(newContractsFromEdition))
                                return
                            }

                            contractsJson.contracts.filter((c: any) => !!c.edition_contract).forEach((c: any) => {
                                const contractEditionID = state.onboarding.selectedContractIDs.find(contractID => contractID === c.edition_contract)
                                if (!contractEditionID) {
                                    // Select it in the UI now
                                    dispatch(selectContract(c.edition_contract, true))
                                    // Replace the edition built in item with the installed item that is customized
                                    // but retain the edition ID and image_uri
                                    const editionContract = json.contracts.find((j: any) => j.id === c.edition_contract)
                                    if (editionContract) {
                                        for (const property in editionContract) {
                                            if (property === 'image_uri' || property === 'id') continue
                                            // @ts-ignore
                                            editionContract[property] = c[property]
                                        }
                                        dispatch(updateContract(editionContract))
                                    }
                                }
                            })
                        })
                        .finally(() => setShowLoadingMessage(''))
                }
                else {
                    if (forOnboarding) {
                        Result(dispatch(nextOnboardingStep(user)))
                            .finally(() => setShowLoadingMessage(''))
                    }
                    else {
                        setShowLoadingMessage('')
                    }
                }
            })
    }, [user, state.onboarding.refreshData, state.onboarding.contracts.length, forOnboarding, dispatch, state.onboarding.selectedContractIDs])

    const [industryTitle, setIndustryTitle] = useState('your industry')
    useEffect(() => {
        if (!user) return
        localforage.getItem('industries').then((industries: any) => {
            const industry = industries?.find((i: any) => user.industry === i.id)
            if (industry)
                setIndustryTitle(`${industry.pluralized_title}`)
        })
    }, [user])

    const onNextHandler = () => {
        if (!user) return

        setShowLoadingMessage('Saving...')

        const editionID = user.edition || `${user.industry}-industry-edition`

        const clonedContracts = state.onboarding.selectedContractIDs.map((id: string) => {
            const c = state.onboarding.contracts.find(c => c.id === id)
            if (!c) return null

            const newContract = {
                ...c,
                edition: editionID,
                edition_contract: c.id,
                is_public: undefined,
                associated_with_service: undefined
            }
            delete newContract.id
            return newContract
        })

        const postData = {
            contracts: clonedContracts,
            edition: editionID,
            forOnboarding,
        }

        apiClient.post(`/onboarding/setup/contracts`, postData)
            .then(resp => resp.data)
            .then(json => {
                if (json.user) localforage.setItem('user', json.user)
                if (forOnboarding) {
                    Result(dispatch(nextOnboardingStep(json.user)))
                        .finally(() => setShowLoadingMessage(''))
                }
                else {
                    const editionItemAndContractIds = allEditionItemIDs ? [ ...allEditionItemIDs ] : []
                    editionItemAndContractIds.push(...state.onboarding.selectedContractIDs)
                    Result(dispatch(nextDefaultInstallStep(editionItemAndContractIds, editionID, initialStep!, 'contracts')))
                        .finally(() => setShowLoadingMessage(''))
                }
            })
            .catch(error => {
                dispatch(processError(error))
                setShowLoadingMessage('')
                return Promise.reject()
            })
    }

    const body = (
        <>
            {showLoadingMessage && (
                <div className="loadingOverlay">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                        <h1>{showLoadingMessage}</h1>
                    </div>
                </div>
            )}
            <div className="scheduleClient servicesSettings onboarding">
                <div className="header">
                    <h1>{header}</h1>
                    <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                     onClick={() => onNextHandler()}
                                     disabled={!nextEnabled}
                    >
                        Next
                    </PSButtonPrimary>
                </div>

                <div className="separator" />

                <div className="form">
                    <div className="text">These contracts are recommended for {industryTitle}. Select & preview these. Add custom contracts later.</div>
                    {state.onboarding.contracts.map((contract: Contract) => (
                        <OnboardingContract contract={contract}
                                            key={contract.id}
                                            isChecked={state.onboarding.selectedContractIDs.findIndex((id: string) => id === contract.id) > -1}
                                            onToggleItem={(enabled: boolean) => {
                                                if (!contract.id) return

                                                const editionItemContract = editionItems[contract.id]
                                                if (editionItemContract && !enabled) {
                                                    setShowLinkedContractWarning({contract: contract, item: editionItemContract})
                                                }
                                                else {
                                                    dispatch(selectContract(contract.id, enabled))
                                                }
                                            }} />
                    ))}
                </div>
            </div>
            {showLinkedContractWarning && (
                <WarningModal title="Linked contract"
                              message={`Are you sure? This contract is linked to an item (${showLinkedContractWarning.item.name}) recently added to your account.`}
                              buttonText="Yes"
                              onButtonClick={(buttonIx) => {
                                  if (buttonIx === ACTION_BUTTON) {
                                      dispatch(selectContract(showLinkedContractWarning.contract.id!, false))
                                  }
                                  setShowLinkedContractWarning(undefined)
                              }}
                              extraButtonText="Cancel"
                              onClose={() => setShowLinkedContractWarning(undefined)}
                />
            )}
        </>
    )

    if (forOnboarding) {
        return (
            <AppHeader title="Setup" showBackButton middleWidget={null}>
                {body}
            </AppHeader>
        )
    }

    if (!showLoadingMessage && state.onboarding.contracts.length === 0) {
        return (
            <DefaultsEmptyState title="Contracts" />
        )
    }

    return body
}
