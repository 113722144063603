import { apiClient, generateCancelToken } from './apiclient'
import debounce from 'lodash.debounce'
import keyBy from 'lodash.keyby'
import axios, { Cancel, CancelToken, CancelTokenSource } from 'axios'
import { processError } from './error'

let cancelToken: CancelTokenSource | null

export type Pagination = {
    hasMore: boolean,
    lastKey: string
}
export type ContactsState = {
    isSending: boolean,
    contacts: any[],
    clients: any[],
    // Client state
    source: any,
    sources: any[],
    channels: any[],
    client: any,
    contact: any,
    fields: any[],
    // Contact search
    searchText: string,
    isSearching: boolean,
    didSearch: boolean,
    searchResults: any[],
    // Pagination
    pagination: Pagination
}

const initialState = {
    isSending: false,
    contacts: [],
    clients: [],
    // Client state
    source: {},
    sources: [],
    channels: [],
    client: {},
    contact: {},
    fields: [],
    // Contact search
    searchText: '',
    isSearching: false,
    didSearch: false,
    searchResults: [],
    // Pagination
    pagination: {
        hasMore: true,
        lastKey: '',
    }
}

export default function contactsReducer(state = initialState, action: any) {
    switch (action.type) {
        case 'contacts/REQUEST':
            return {
                ...state,
                isSending: action.sending,
            }
        case 'contacts/SET_CUSTOM_FIELDS':
            return {
                ...state,
                fields: action.fields
            }
        case 'contacts/LOADED':
            let lastKey
            if (action.contacts.length) {
                lastKey = action.contacts[action.contacts.length-1].sort_key
            }
            const hasMore = action.contacts.length > 0
            return {
                ...state,
                contacts: state.contacts.slice().concat(action.contacts),
                pagination: {
                    lastKey,
                    hasMore,
                }
            }
            case 'clients/LOADED':

                return {
                    ...state,
                    clients: state.clients.slice().concat(action.clients),
                }
        case 'contacts/SET_SEARCHING':
            return {
                ...state,
                searchText: action.query || state.searchText,
                isSearching: action.searching,
            }
        case 'contacts/CLEAR_SEARCH_RESULT':
            return {
                ...state,
                isSearching: false,
                searchText: '',
                didSearch: false,
                searchResults: [],
            }
        case 'contacts/SET_SEARCH_RESULT':
            return {
                ...state,
                didSearch: true,
                searchResults: action.userResults,
            }
        case 'contact/DELETED':
            const contacts = state.contacts
            const clients = state.clients

            const contactIx = contacts.findIndex((contact: any) => contact.id === action.id)
            const clientIx = clients.findIndex((client: any) => client.person === action.id)

            if (contactIx !== -1) contacts.splice(clientIx, 1)
            if (clientIx !== -1) clients.splice(clientIx, 1)

            return {
                ...state,
                contacts,
                clients
            }
        case 'contacts/RESET_CONTACT':
            return {
                ...state,
                client: {},
                contact: {},
            }
        case 'contacts/SET_SOURCES':
            return {
                ...state,
                sources: action.sources
            }
        case 'contacts/SET_CHANNELS':
            return {
                ...state,
                channels: action.channels
            }
        case 'contacts/SET_SOURCE':
            return {
                ...state,
                source: action.source
            }
        case 'contacts/SET_CLIENT':
            return {
                ...state,
                client: action.client
            }
        case 'contacts/SET_CONTACT':
            return {
                ...state,
                contact: action.contact
            }
        case 'contacts/RESET':
            return {
                ...state,
                contacts: [],
                clients: []
            }
        default:
            return state
    }
}

const contactsRequest = (sending: boolean) => {
    return { type: 'contacts/REQUEST', sending }
}

const contactsLoaded = (contacts: any[]) => {
    return { type: 'contacts/LOADED', contacts }
}

const removeContact = (id: string) => {
    return { type: 'contact/DELETED', id }
}

const clientsLoaded = (clients: any[]) => {
    return { type: 'clients/LOADED', clients }
}

const clearContacts = () => {
    return { type: 'contacts/RESET' }
}

export const syncContacts = (page = '', cancelToken?: CancelToken | undefined) => {
    return (dispatch: any) => {
        dispatch(contactsRequest(true))
        return apiClient.post(`/client/sync`, {page: page, show_name: true, limit: 100}, {cancelToken})
            .then(resp => resp.data)
            .then((json) => {
                if (json.contacts)
                    dispatch(contactsLoaded(json.contacts))
                if (json.clients)
                    dispatch(clientsLoaded(json.clients))
            })
            .catch((_: Cancel) => { })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => {
                dispatch(contactsRequest(false))
            })
    }
}

export const deleteContact = (clientId: string, personId: string, blockContactToo: boolean) => {
    return (dispatch: any) => {
        contactsRequest(true)

        return apiClient.post(`/client/${clientId}/delete`, {lock_out: blockContactToo})
            .then(() => dispatch(removeContact(personId)))
            .catch(error => dispatch(processError(error)))
            .finally(() => dispatch(contactsRequest(false)))
        }
}

export const saveContact = (userParams: any, photo?: File) => {
    return (dispatch: any): any => {
        dispatch(contactsRequest(true))

        const url = `/client/${userParams.id ?? ''}`
        const formData = new FormData()

        if (photo) {
            formData.append('image', photo, photo.name)

            Object.keys(userParams).forEach((key: string) => {
                const value = userParams[key]
                if (typeof value === 'object' && value !== null) {
                    if (Array.isArray(value))
                        value.forEach(valueObj => formData.append(key, JSON.stringify(valueObj)))
                    else
                        formData.append(key, JSON.stringify(value))
                }
                else if (value != null)
                    formData.append(key, value)
            })
        }

        return apiClient.post(url.endsWith('/') ? url.slice(0, -1) : url, photo ? formData : userParams)
            .then(resp => resp.data)
            .then(json => {
                if (json.client) dispatch(setClient(json.client))
                if (json.person) dispatch(setContact(json.person))

                // TODO: should just append to contact list, cannot do that easily because it creates bugs with the contact view
                dispatch(clearContacts())
                dispatch(contactsRequest(false))

                return Promise.resolve(json?.person?.id)
            })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
    }
}

export type SyncClientResponse = {
    client: any,
    contact: any,
    sources: any[],
    channels: any[]
}

export const syncClient = (id: string) => {
    return (dispatch: any) => {
        contactsRequest(true)

        return apiClient.post('/client/sync', {sources: true, person: id})
            .then(resp => resp.data)
            .then(json => {
                if (json?.contacts?.length) {
                    const userMap = keyBy(json.contacts)
                    if (json.referer && !userMap[json.referer.id]) {
                        userMap[json.referer.id] = json.referer
                    }
                    dispatch({type: 'entities/UPDATE', name: 'contacts', map: userMap})
                    dispatch({type: 'entities/UPDATE', name: 'users', map: userMap})
                }
                return {
                    client: json?.clients?.length ? json.clients[0] : undefined,
                    contact: json?.contacts?.length ? json.contacts[0] : undefined,
                    sources: json?.sources,
                    channels: Object.values(json?.channels)
                } as SyncClientResponse
            })
            .then(result => {
                if (result.client) {
                    dispatch(setClient(result.client))
                }
                if (result.contact) {
                    dispatch(setContact(result.contact))
                }
                if (result.sources) {
                    dispatch(setSources(result.sources))
                }
                if (result.channels) {
                    dispatch(setChannels(result.channels))
                }
                return result
            })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => contactsRequest(false))
        }
}

export const syncChannels = () => {
    return (dispatch: any) => {
        contactsRequest(true)

        return apiClient.post('/channel/sync')
            .then(resp => resp.data)
            .then(json => {
                if (json?.channels) {
                    dispatch(setChannels(json.channels))
                }
                return json
            })
            .catch(error => {
                dispatch(processError(error))
                return Promise.reject()
            })
            .finally(() => contactsRequest(false))
        }
}

export const fetchCustomFields = (callback?: () => void) => {
    return (dispatch: any): any => {
        const params = {
            is_booking: false,
            show_standard: false,
            owner: true,
        }
        return apiClient.post('/field/sync', params)
            .then(resp => resp.data)
            .then(json => {
                if (json.fields)
                    dispatch(setCustomFields(json.fields))
                if (callback) callback()
            })
    }
}

const setCustomFields = (fields: any) => {
    return { type: 'contacts/SET_CUSTOM_FIELDS', fields }
}

const setSources = (sources: any[]) => {
    return { type: 'contacts/SET_SOURCES', sources }
}

export const setSource = (source: any) => {
    return { type: 'contacts/SET_SOURCE', source }
}

const setClient = (client: any) => {
    return { type: 'contacts/SET_CLIENT', client }
}

const setContact = (contact: any) => {
    return { type: 'contacts/SET_CONTACT', contact }
}

export const clearClientState = () => {
    return {type: 'contacts/RESET_CONTACT'}
}

const setChannels = (channels: any) => {
    return { type: 'contacts/SET_CHANNELS', channels }
}

const setSearching = (searching: boolean, query?: string) => {
    return { type: 'contacts/SET_SEARCHING', searching, query }
}

const setSearchResult = (userResults: any[]) => {
    return { type: 'contacts/SET_SEARCH_RESULT', userResults }
}

export const clearSearch = () => {
    if (cancelToken) {
        performSearchDebounced.cancel()
        cancelToken.cancel('clearSearch')
        cancelToken = null
    }

    return { type: 'contacts/CLEAR_SEARCH_RESULT' }
}

const performSearchDebounced = debounce((dispatch, query) => {
    dispatch(setSearching(true))

    // Cancel the previous query, if is still running
    if (cancelToken) {
        cancelToken.cancel('stop userSearch')
        cancelToken = null
    }

    cancelToken = generateCancelToken()

    const data = {keywords: query, type: 'client'}
    apiClient.post('/user/search', data, {cancelToken: cancelToken.token})
        .then(resp => resp.data)
        .then(json => {
            const indexOfUsers = []
            for (let i = 0; i < json.types.length; i++) {
                if (json.types[i] === 'user') {
                    indexOfUsers.push(i)
                }
            }

            const users: any[] = []
            for (let i = 0; i < indexOfUsers.length; i++) {
                users.push(json.results[indexOfUsers[i]])
            }

            // Update the entities map so if they click the search result, we have
            // the user entity ready to be queried
            const userMap = keyBy(users, 'id')
            dispatch({type: 'entities/UPDATE', name: 'contacts', map: userMap})

            dispatch(setSearchResult(users))
            dispatch(setSearching(false))
        })
        .catch((_: Cancel) => { })
        .catch(error => {
            dispatch(processError(error))
            dispatch(setSearching(false))
            return Promise.reject()
        })
}, 250, {trailing: true})

export const searchContacts = (query: string) => {
    return (dispatch: any) => {
        dispatch(setSearching(true, query))

        performSearchDebounced(dispatch, query)
    }
}
