import React, {useEffect, useState} from "react"
import {AppHeader} from "../app/AppHeader";
import {Spinner} from "@zendeskgarden/react-loaders";
import {PSButtonPrimary} from "../app/PSButton";
import {useAppDispatch, usePSUser} from "../../hooks";
import {Field, Label, Toggle} from "@zendeskgarden/react-forms";
import {Result} from "../../utils";
import {saveUser} from "../../modules/settings";
import {setOnboardingState} from "../../modules/onboarding";
import {push} from "connected-react-router";

export const OnboardingSetupPaymentsContinued = () => {
    const dispatch = useAppDispatch()
    const user = usePSUser()

    const [isSending, setIsSending] = useState(false)
    const [surchargeEnabled, setSurchargeEnabled] = useState(false)
    const [salesTaxEnabled, setSalesTaxEnabled] = useState(false)
    const [gratuityEnabled, setGratuityEnabled] = useState(false)
    const [bnplEnabled, setBnplEnabled] = useState(false)

    useEffect(() => {
        // TODO Load state when page loads if via a back button instead of from user
        if (!user) return
        setSurchargeEnabled(user.allow_surcharge)
        setSalesTaxEnabled(user.allow_salestax)
        setGratuityEnabled(user.allow_gratuity)
        setBnplEnabled(user.allow_bnpl)
    }, [user])

    const saveToggle = (setting: string, enabled: boolean) => {
        if (!user) return

        setIsSending(true)

        const userParams: any = {}
        userParams.id = user.id
        userParams[setting] = enabled

        Result(dispatch(saveUser(userParams)))
            .then(() => setIsSending(false))
    }

    const onNextHandler = () => {
        if (!user) return

        setIsSending(true)

        const userParams: any = {}
        userParams.id = user.id
        userParams.allow_surcharge = surchargeEnabled
        userParams.allow_salestax = salesTaxEnabled
        userParams.allow_gratuity = gratuityEnabled
        userParams.allow_bnpl = bnplEnabled

        // Save user
        Result(dispatch(saveUser(userParams)))
            .then(() => {
                // Send ob_payments_1 state
                Result(dispatch(setOnboardingState('ob_payments_2')))
                    .then(() => setIsSending(false))
            })
    }

    return (
        <AppHeader title="Setup" showBackButton middleWidget={null}>
            {isSending && (
                <div className="loadingOverlay">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                        <h1>Saving...</h1>
                    </div>
                </div>
            )}
            <div className="scheduleClient servicesSettings onboarding onboardingSetupPaymentsContinued">
                <div className="header">
                    <h1>Payments continued</h1>
                    <PSButtonPrimary style={{height: '40px', marginLeft: 'auto'}}
                                     onClick={() => onNextHandler()}
                    >
                        Next
                    </PSButtonPrimary>
                </div>

                <div className="separator" />

                <div className="form">
                    <div className="text">These are specific details for what your clients pay when booking.</div>
                    <div className="serviceCard onboardingCard">
                        <div className="details">
                            <div className="name">Surcharge (clients pay fee)</div>
                            <div className="rate">3% fee added to all payments</div>
                        </div>
                        <div className="edit" />
                        <div className="toggle">
                            <Field>
                                <Toggle checked={surchargeEnabled}
                                        onChange={e => {
                                            saveToggle('allow_surcharge', e.target.checked)
                                            setSurchargeEnabled(e.target.checked)
                                        }}>
                                    <Label>&nbsp;</Label>
                                </Toggle>
                            </Field>
                        </div>
                    </div>
                    <div className="serviceCard onboardingCard">
                        <div className="details">
                            <div className="name">Buy Now Pay Later</div>
                            <div className="rate">Offer 0% interest financing"</div>
                        </div>
                        <div className="edit" />
                        <div className="toggle">
                            <Field>
                                <Toggle checked={bnplEnabled}
                                        onChange={e => {
                                            saveToggle('allow_bnpl', e.target.checked)
                                            setBnplEnabled(e.target.checked)
                                        }}>
                                    <Label>&nbsp;</Label>
                                </Toggle>
                            </Field>
                        </div>
                    </div>
                    <div className="serviceCard onboardingCard">
                        <div className="details">
                            <div className="name">Sales tax</div>
                            <div className="rate">Automatically collect sales tax</div>
                        </div>
                        <div className="edit">
                            <div className={`buttonLink brightBlue ${salesTaxEnabled ? '' : 'disabledLink'}`}
                                 onClick={() => {
                                     if (salesTaxEnabled)
                                         dispatch(push('/onboarding/payment-continued/tax'))
                                 }}
                            >
                                Edit
                            </div>
                        </div>
                        <div className="toggle">
                            <Field>
                                <Toggle checked={salesTaxEnabled}
                                        onChange={e => {
                                            saveToggle('allow_salestax', e.target.checked)
                                            setSalesTaxEnabled(e.target.checked)
                                        }}>
                                    <Label>&nbsp;</Label>
                                </Toggle>
                            </Field>
                        </div>
                    </div>
                    <div className="serviceCard onboardingCard">
                        <div className="details">
                            <div className="name">Gratuity</div>
                            <div className="rate">Allow clients to leave tips</div>
                        </div>
                        <div className="edit">
                            <div className={`buttonLink brightBlue ${gratuityEnabled ? '' : 'disabledLink'}`}
                                 onClick={() => {
                                     if (gratuityEnabled)
                                         dispatch(push('/onboarding/payment-continued/gratuity'))
                                 }}
                            >
                                Edit
                            </div>
                        </div>
                        <div className="toggle">
                            <Field>
                                <Toggle checked={gratuityEnabled}
                                        onChange={e => {
                                            saveToggle('allow_gratuity', e.target.checked)
                                            setGratuityEnabled(e.target.checked)
                                        }}>
                                    <Label>&nbsp;</Label>
                                </Toggle>
                            </Field>
                        </div>
                    </div>
                </div>
            </div>
        </AppHeader>
    )
}
