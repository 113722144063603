import React, {useCallback, useEffect, useState} from "react";
import {Field, Input, Label} from "@zendeskgarden/react-forms";
import debounce from "lodash.debounce";
import {apiClient} from "../../modules/apiclient";
import "./locationautocomplete.css";

type Props = {
    label?: string,
    placeholder?: string,
    currentAddress?: string,
    disabled?: boolean,
    updateAddress: (address: string) => void,
}

export const LocationAutocomplete = ({label, placeholder, currentAddress, disabled, updateAddress}: Props) => {
    const [address, setAddress] = useState('')
    const [selectedAddress, setSelectedAddress] = useState('')
    const [addressOptions, setAddressOptions] = useState([])
    const [completed, setCompleted] = useState(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchAddress = useCallback(debounce((address: string) => {
            setSelectedAddress('')

            if (!address) {
                setAddressOptions([])
                return
            }

            apiClient.post(`/location/autocomplete?q=${address}`)
                .then(resp => resp.data)
                .then((json) => {
                    if (!address) return
                    const addressList = json.predictions.map((p: any) => p.description)
                    setAddressOptions(addressList)
                })
                // @ts-ignore
                .catch(error => window.Rollbar.error('locationAutocomplete error', error))
        }, 250, {trailing: true})
    , [])

    useEffect(() => {
        searchAddress(address)
    }, [address, searchAddress])

    useEffect(() => {
        if (!completed)
            updateAddress(address)
    }, [updateAddress, address, completed])

    const selectAddress = (address: string) => {
        setCompleted(true)
        setSelectedAddress(address)
        updateAddress(address)
        setAddressOptions([])
    }

    return (
        <div className="locationAutoComplete">
            <Field className="field">
                <Label className="label"
                       style={{color: disabled ? '#999999' : ''}}
                >
                    {label || 'Add location'}
                </Label>
                <Input placeholder={placeholder ?? "Address"}
                       disabled={disabled}
                       value={selectedAddress || address || currentAddress}
                       onChange={e => setAddress(e.target.value)} />
            </Field>
            {addressOptions.length > 0 && selectedAddress === '' && (
                <div className="searchResults">
                    {addressOptions.map((address: string) => (
                        <div className="entry"
                             key={address}
                             onClick={() => selectAddress(address)}
                        >
                            {address}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}