import React, {useEffect, useState} from "react";
import {Spinner} from "@zendeskgarden/react-loaders";
import {Field, Input, Label} from "@zendeskgarden/react-forms";
import {PSButtonDanger, PSButtonPrimary} from "../../app/PSButton";
import {Dots} from "@zendeskgarden/react-loaders";
import { VerifyAccountModal } from "./VerifyAccountModal";
import { SuccessModal } from "../SuccessModal";
import { DangerModal } from "../DangerModal"
import { AuthorizeAccountModal } from "./AuthorizeAccountModal";

type Props = {
    source: any,
    isCompany?: boolean,
    disabled?: boolean,
    doVerify?: (firstAmount: number, secondAmount: number) => void,
    doAuthorize?: (accountNumber: string) => void,
    doDelete: () => void
}

export const SourceViewComponent = ({source, isCompany, disabled, doVerify, doAuthorize, doDelete}: Props) => {
    const [isCard, setIsCard] = useState(false)
    const [verificationStep, setVerificationStep] = useState('')

    const [label, setLabel] = useState('')

    const [deleting, setDeleting] = useState(false)
    const [authorizing, setAuthorizing] = useState(false)
    const [verifying, setVerifying] = useState(false)

    const [showVerifyAccountModal, setShowVerifyAccountModal] = useState(false)
    const [showVerificationSuccess, setShowVerificationSuccess] = useState(false)

    const [showAuthorizeAccountModal, setShowAuthorizeAccountModal] = useState(false)
    const [showAuthorizationSuccess, setShowAuthorizationSuccess] = useState(false)

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteModalTitle, setDeleteModalTitle] = useState('')
    const [deleteModalBody, setDeleteModalBody] = useState('')

    useEffect(() => {
        if (!source?.type) return

        const card = source.type === 'credit' || source.type === 'debit' || source.type === 'prepaid'
        setIsCard(card)

        if (card) {
            setLabel((source.card_brand + " - " + source.card_number.slice(source.card_number.length - 4)).replace(/^\w/, c => c.toUpperCase()))
        }
        else {
            setLabel((source.type + " - " + source.account_number.slice(source.account_number.length - 4)).replace(/^\w/, c => c.toUpperCase()))
            if (source.verified_date) {
                setVerificationStep('done')
            }
            else if (source.charge ) {
                setVerificationStep('verify')
            }
            else {
                setVerificationStep('authorize')
            }
        }
    }, [source])

    useEffect(() => {
        if (verificationStep === 'done' && !!verifying)
            setShowVerificationSuccess(true)
    }, [verifying, verificationStep])

    useEffect(() => {
        if (verificationStep === 'verify' && !!authorizing)
            setShowAuthorizationSuccess(true)
    }, [authorizing, verificationStep])

    const onDelete = () => {
        if (isCard) {
            setDeleteModalTitle('Remove card')
            setDeleteModalBody('Are you sure you’d like to remove this card?')
        }
        else {
            setDeleteModalTitle('Remove checking account')
            setDeleteModalBody('Are you sure you’d like to remove this checking account?')
        }

        setShowDeleteModal(true)
    }

    const onAuthorize = () => {
        setAuthorizing(true)
        setShowAuthorizeAccountModal(true)
    }

    const onVerify = () => {
        setVerifying(true)
        setShowVerifyAccountModal(true)
    }

    if (disabled) {
        return (
            <div className="servicesSettings">
                <div className="header">
                    <div style={{textAlign: 'center', padding: '128px 0'}}>
                        <Spinner size="128" color="#314A68" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="servicesSettings">
            {isCard && (
            <div className="header">
                <h1>{source.display_name}</h1>
                <PSButtonDanger style={{height: '40px', marginLeft: 'auto'}}
                                 onClick={() => onDelete()}
                                 disabled={disabled}>
                    {deleting && <Dots />}
                    {!deleting && "Delete card"}
                </PSButtonDanger>
            </div>
            )}
            {!isCard && (
            <div className="header">
                <h1>{source.display_name}</h1>
                <div className="right">
                <PSButtonDanger style={{height: '40px', marginLeft: 'auto'}}
                                    onClick={() => onDelete()}
                                    disabled={disabled}>
                    {deleting && <Dots />}
                    {!deleting && "Delete account"}
                </PSButtonDanger>
                {verificationStep === 'authorize' && isCompany && (
                    <PSButtonPrimary style={{height: '40px', marginLeft: '16px'}}
                                        onClick={() => onAuthorize()}
                                        disabled={disabled}>
                    {authorizing && <Dots />}
                    {!authorizing && "Authorize ACH"}
                    </PSButtonPrimary>
                )}
                {verificationStep === 'verify' && isCompany && (
                    <PSButtonPrimary style={{height: '40px', marginLeft: '16px'}}
                                        onClick={() => onVerify()}
                                        disabled={disabled}>
                    {verifying && <Dots />}
                    {!verifying && "Verify account"}
                    </PSButtonPrimary>
                )}
                </div>
            </div>

            )}
            <div className="form">
                <Field className="field">
                    <Label className="label">Account #</Label>
                    <Input value={label}
                           disabled
                    />
                </Field>
            </div>
            {!!source.bank_name && (
            <div className="form">
                <Field className="field">
                    <Label className="label">Bank</Label>
                    <Input value={source.bank_name}
                           disabled
                    />
                </Field>
            </div>
            )}
            {!source.bank_name && (
            <div className="form">
                <Field className="field">
                    <Label className="label">Name on account</Label>
                    <Input value={source.name}
                           disabled
                    />
                </Field>
            </div>
            )}
            {showVerifyAccountModal && (
                <VerifyAccountModal
                    onClose={() => {setShowVerifyAccountModal(false); setVerifying(false)}}
                    onVerify={(firstAmount: number, secondAmount: number) => {
                        setShowVerifyAccountModal(false)
                        if (doVerify) doVerify(firstAmount, secondAmount)}}
                />
            )}
            {showVerificationSuccess && (
                <SuccessModal
                    title="Your account is now verified"
                    body="You’re ready to begin making payments from this account."
                    buttonText="Done"
                    onAction={() => {setShowVerificationSuccess(false)}}
                    onClose={() => {setShowVerificationSuccess(false)}} />
            )}
            {showAuthorizeAccountModal && (
                <AuthorizeAccountModal
                    source={source}
                    onClose={() =>{
                        setShowAuthorizeAccountModal(false)
                        setAuthorizing(false)}}
                    onAuthorize={(accountNumber: string) => {
                        setShowAuthorizeAccountModal(false)
                        if (doAuthorize) doAuthorize(accountNumber)}}
                />
            )}
            {showAuthorizationSuccess && (
                <SuccessModal
                title="Your account is being authorized"
                body='Two small "VERIFICATION" deposits will be sent to this account in 2-3 days'
                buttonText="Done"
                onAction={() => {setShowAuthorizationSuccess(false)}}
                onClose={() => {setShowAuthorizationSuccess(false)}} />
            )}
            {showDeleteModal && (
                <DangerModal
                title={deleteModalTitle}
                body={deleteModalBody}
                buttonText='Remove'
                disableDismiss={true}
                onAction={() => {
                    setDeleting(true)
                    doDelete()
                    setDeleting(false)
                }}
                onClose={() => {setShowDeleteModal(false)}}
                />
            )}
        </div>
    )
}
