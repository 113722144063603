import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { PSButton, PSButtonPrimary } from "../../../app/PSButton";
import { ReactComponent as LeftArrow } from "../../../../icons/left-arrow.svg";
import { ReactComponent as CheckmarkImage } from "../../../../icons/big_checkmark.svg";
import { SelectOption } from "../../Options";
import { useAppDispatch, usePSUser } from "../../../../hooks";
import { push } from "connected-react-router";
import { discountPlan } from "../../../../modules/settings";
import { Result } from "../../../../utils";
import { Spinner } from "@zendeskgarden/react-loaders";

export const CancelPlanUpsellDiscount = () => {
    const me = usePSUser()
    const history = useHistory()
    const dispatch = useAppDispatch()
    const { reason } = useLocation<{ reason: SelectOption }>().state
    const [subtitle, setSubtitle] = useState('')
    const [showDone, setShowDone] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!reason?.value) return

        switch (reason.value) {
            case 'covid19':
                setSubtitle('Covid-19 has been hard on everyone! We\'re giving Pros impacted by Covid-19 90% off their PocketSuite Premium subscription for 2 months.')
                break;
            case 'more_time':
                setSubtitle('Take your time setting up. Here\'s 2 months of Premium for $1.99/mo.')
                break;
            case 'break':
                setSubtitle('You deserve a break. Here\'s 2 months of Premium for $1.99/mo.')
                break;
            default:
                setSubtitle('We understand and we have your back. Here\'s 2 months of Premium for $1.99/mo.')
                break;
        }
    }, [reason])

    const getDiscount = useCallback(() => {
        if (!me) return

        setIsLoading(true)
        Result(dispatch(discountPlan(me.id, { reason: reason.value })))
            .then(() => {
                setShowDone(true)
                setIsLoading(false)
            })
    }, [me, dispatch, reason.value])

    return (
        <div className="supportSettings">
            <div className="header">
                {!showDone && (
                    <PSButton className="left" onClick={() => history.goBack()}>
                        <LeftArrow />
                    </PSButton>
                )}
                <h1 className="title sm">PocketSuite for $1.99</h1>
                {!showDone && (
                    <div className="right">
                        <PSButton disabled={isLoading}
                                  onClick={() => dispatch(push('confirm', { reason }))}>
                                  Continue cancellation
                        </PSButton>
                        <PSButtonPrimary disabled={isLoading || !reason} onClick={getDiscount}>Get 90% off</PSButtonPrimary>
                    </div>
                )}
            </div>
            <div className="separator" />
            {isLoading && (
                <div style={{textAlign: 'center', padding: '128px 0'}}>
                    <Spinner size="128" color="#314A68" />
                </div>
            )}
            {!isLoading && !showDone && (
                <>
                    <p className="details">{subtitle}</p>
                    <div className="supportCard column">
                        <div className="title">PocketSuite Premium</div>
                        <div className="details">Access PocketSuite Premium for just $1.99/mo for the next 2 months</div>
                    </div>
                    <div className="supportCard column">
                        <div className="title">Keep your data</div>
                        <div className="details">Keep all of your client data and access all of your Premium features</div>
                    </div>
                </>
            )}
            {!isLoading && showDone && (
                <div className="column center margin">
                    <CheckmarkImage/>
                    <h1 className="heading">All done!</h1>
                    <p className="text">You've just unlocked 90% off your current subscription for the next 2 months!</p>
                </div>
            )}
        </div>
    )
}
