import moment from "moment";
import { SelectOption, sessionExpireOptions } from "../components/settings/Options";
import { formatHourMinute } from "../utils";
import { currencyFloat, currencyFormat } from "../utils/numbers";
import { Transaction } from "./Transaction";

export const expirationStrings = sessionExpireOptions.reduce(
    (obj: Record<string, string>, option: SelectOption) => (obj[option.value] = option.label, obj)
, {})

export class Package extends Transaction {
    name: string = ''
    creator: string = '';
    customer: string = '';
    start_date: string = '';
    end_date: string = '';
    paid_date: string = '';
    expired_date?: string;
    location?: string;
    rate: number = 0;
    expires?: number;
    deposit: number = 0;
    available: number = 0;
    duration: number = 0;
    paid_amount?: number = 0;
    remaining_balance?: number = 0;
    last_payment_date: string = ''
    asking_amount?: number = 0;
    discount_instances?: number;
    form: string = '';
    record: string = '';
    contract: string = '';
    capacity: number = 0;
    capacity_type: string = '';
    item?: string;
    instances?: number;
    memo: string = '';
    allow_conference: boolean = false;
    allow_gifting: boolean = false;
    allow_inventory: boolean = false;
    allow_salestax: boolean = false;
    allow_surcharge: boolean = false;
    allow_waitlist: boolean = false;
    cancelled_date?: string;
    created_date?: string;
    sent_date?: string;
    viewed_date?: string;
    captured_date?: string;
    completed_date?: string;
    mark_paid_date?: string;
    seqnum: number = 0;
    number_of_bookings?: number = 0;
    max_payment_count: string = '';
    min_payment_percent: string = '';

    constructor(data: Partial<Package>) {
        super(data)
        Object.assign(this, data)
    }
}

export const displayStatus = (record: Package) => {
    const dateFormat = (date: string) => moment(date).format('MMM Do, yyyy')

    if (record.cancelled_date)
        return `Cancelled ${dateFormat(record.cancelled_date)}`
    if (record.mark_paid_date && record.paymeth)
        return `Marked paid ${dateFormat(record.mark_paid_date)} - ${record.paymeth}`
    if (record.mark_paid_date)
        return `Marked paid ${dateFormat(record.mark_paid_date)}`
    if (record.paid_date)
        return `Paid ${dateFormat(record.paid_date)}`
    if (record.completed_date)
        return `Completed ${dateFormat(record.completed_date)}`
    if (currencyFloat(record.paid_amount) > 0)
        return `Paid ${currencyFormat(record.paid_amount)} (${currencyFormat(record.remaining_balance)} left)`
    return `Sent ${dateFormat(record.created_date!)} - not paid`
}

export const describePackage = (record:Package, remaining: number, omitExpiresIn?: boolean) => {
    let description
    if (record.paid_date) {
        if (record.expired_date)
            description = `exp ${moment(record.expired_date).format('MMM Do, yyyy')}`
        else
            description = 'does not expire'
    }
    else {
        const expires = record.expires ?? ''
        const expiry = expires in expirationStrings ? expirationStrings[expires] : `${expires} days`
        description = !omitExpiresIn ? 'Expires in ' : ''
        description = `${expiry}`
    }

    let capacityString = `${record.capacity} ${record.capacity_type}${record.capacity > 1 ? `s` : ``}`
    if (record.capacity_type === 'duration') {
        capacityString = formatHourMinute(remaining)
    }
    return `${capacityString} · ${description}`
}