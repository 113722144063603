import {Item} from "../models/Item";
import {Category} from "../models/Category";
import {BOOK_HOST} from "./constants";

type WidgetURLProps = {
    user: any,
    category?: Category
    item?: Item
}

export const widgetURL = ({user, category, item}: WidgetURLProps) => {
    const username = user.username || user.key
    const baseURL = `${BOOK_HOST}/book/${username}`
    if (category) {
        const categoryURL = category.name
            .replaceAll('-', '')
            .replaceAll(' ',  '-')
            .toLowerCase()
        return encodeURI(`${baseURL}?category=${categoryURL}`)
    }
    else if (item) {
        const itemURL = item.name.replaceAll('-', '')
            .replaceAll(' ',  '-')
            .toLowerCase()
        return encodeURI(`${baseURL}?category=${itemURL}`)
    }
    return baseURL
}

export const payURL = (user: any) => {
    return encodeURI(`${BOOK_HOST}/cash/${user.key}`)
}

export const downloadURL = () => {
    return encodeURI(`${BOOK_HOST}/download/app`)
}

export const formURL = (form: any) => {
    return encodeURI(`${BOOK_HOST}/form/${form.key}`)
}