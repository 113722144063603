export const SUITELINK_LIMIT = 50

export class PSLink {
    id: string | undefined
    type: string = "custom"
    label: string = ""
    url: string | undefined
    record: string | undefined
    seqnum: number | undefined

    constructor(data: PSLink) {
        Object.assign(this, data)
    }

    toObject(): any {
        const obj: any = {}
        if (this.id)
            obj.id = this.id
        obj.type = this.type
        obj.label = this.label
        obj.url = this.url ?? ""
        obj.record = this.record ?? ""
        return obj
    }

    typeString(): string {
        if ("book" === this.type)
            return "Booking widget"
        if ("chat" === this.type)
            return "Chat widget"
        if ("lead" === this.type)
            return "Lead form"
        if ("cash" === this.type)
            return "Payment widget"
        return "Custom link"
    }
}