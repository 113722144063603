import React from "react";
import {AppHeader} from "../app/AppHeader";
import {CardEdit} from "../settings/payment-methods/CardEdit";

export const GoLiveAddCard = () => {
    return (
        <AppHeader middleWidget={null}
                   showBackButton
                   title="Grow your business">
            <div className="servicesSettings onboarding launchBusiness">
                <CardEdit />
            </div>
        </AppHeader>
    )
}