import merge from 'lodash/merge'

const initialState = {
    contacts: {},
    clients: {},
    categories: {},
    teams: {},
    users: {},
    events: {},
    bookings: {},
    items: {},
    fields: {},
    contracts: {},
    payees: {},
    types: {},
    subscriptions: {},
    signatures: {},
    tasks: {},
    estimates: {},
    gifts: {},
    triggers: {},
    templates: {},
    sources: {},
    invoices: {},
    balances: {},
    payments: {},
    charges: {},
    deposits: {},
}

export default function entitiesReducer(state = initialState, action: any) {
    switch (action.type) {
        case 'entities/UPDATE':
            const updatedState: any = {}
            updatedState[action.name] = action.map
            return merge({}, state, updatedState)
        case 'entities/SET':
            const newState: any = {}
            newState[action.name] = action.map
            return newState
        default:
            return state
    }
}
