import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router";
import { Note } from "../../models/note";
import {
    Field,
    Input,
    Label
} from "@zendeskgarden/react-forms";
import { NoteState, fetchNote, removeNote, saveNote } from "../../modules/notes";
import { RootState } from "../../store";
import { AppHeader } from "../app/AppHeader";
import { PSButtonDanger, PSButtonPrimary } from "../app/PSButton";
import {PSTextarea} from "../app/PSTextarea";
import { SettingsPhotoField } from "../settings/SettingsPhotoField";
import './notes.css'
import { usePhotoUpload } from "../settings/hooks";
import { Spinner } from "@zendeskgarden/react-loaders";
import { Result } from "../../utils";

type NoteTypes = 'user' | 'estimate' | 'booking'

export const EditNote = () => {
    const state: NoteState = useSelector((state: RootState) => state.notes)
    const dispatch = useDispatch()
    const history = useHistory()

    const params = useParams<{id?: string, type?: NoteTypes}>()
    const isAdd = !!params.type
    const note = state.notes.find(note => note.id === params.id)

    const [noteType, setNoteType] = useState(params.type)
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState<string>()
    const [description, setDescription] = useState<string>()
    const [photo, setPhoto] = useState<string | File>()
    const [currentNote, setCurrentNote] = useState<Note | undefined>(note)
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [addPhotoRef, selectPhoto, selectedPhoto] = usePhotoUpload({disabled: state.loading, setPhoto})

    const getPerson = useCallback(() => {
        if (isAdd && noteType === 'user') {
            return params.id
        }
        return note?.person;
    }, [noteType, isAdd, note?.person, params.id])

    const getEstimate = useCallback(() => {
        if (isAdd && noteType === 'estimate') {
            return params.id
        }
        return note?.estimate;
    }, [noteType, isAdd, note?.estimate, params.id])

    const getBooking = useCallback(() => {
        if (isAdd && noteType === 'booking') {
            return params.id
        }
        return note?.booking;
    }, [noteType, isAdd, note?.booking, params.id])

    const getPhoto = useCallback(() => {
        if (!!photo && typeof photo !== 'string')
            return photo
    }, [photo])

    const getTitle = useCallback(() => {
        if (!noteType) return
        return {
            'user': 'Client',
            'estimate': 'Estimate',
            'booking': 'Appointment',
        }[noteType]
    }, [noteType])

    const getNoteType = (note: Note) =>
        note.estimate ? 'estimate' :
        note.booking ? 'booking' :
        'user'

    useEffect(() => {
        if (!isAdd && !note && params.id) {
            Result(dispatch(fetchNote(params.id!)))
                .then((note?: Note) => { setCurrentNote(note) })
        }
    }, [isAdd, note, params.id, dispatch])

    useEffect(() => {
        if (currentNote) {
            setTitle(currentNote.title)
            setDescription(currentNote.note)
            setPhoto(currentNote.image_uri)
            setNoteType(getNoteType(currentNote))
        }
    }, [currentNote])


    useEffect(() => {
        if (isAdd) {
            setIsSaveEnabled(!!title || !!description || !!photo)
        }
        else {
            setIsSaveEnabled(!!title && (
                title !== currentNote?.title || description !== currentNote?.note || photo !== currentNote?.image
            ))
        }
    }, [isAdd, title, description, photo, currentNote])

    let body =
    <div className="notes">
        <div className="header">
            <h1>Note</h1>
            {!isAdd && (
                <PSButtonDanger style={{height: '40px'}}
                    onClick={() => {
                        setLoading(true)
                        Result(dispatch(removeNote(note!.id)))
                            .then(() => history.goBack())
                            .finally(() => setLoading(false))
                    }}
                >
                    Delete
                </PSButtonDanger>
            )}
            <PSButtonPrimary style={{marginLeft: isAdd ? '' : '10px', height: '40px'}} disabled={!isSaveEnabled}
                onClick={() => {
                        setLoading(true)
                        Result(dispatch(saveNote({
                            id: currentNote?.id,
                            title: title,
                            note: description,
                            person: getPerson(),
                            estimate: getEstimate(),
                            booking: getBooking(),
                        }, getPhoto())))
                            .then(() => history.goBack())
                            .finally(() => setLoading(false))
                }}
            >
                Save
            </PSButtonPrimary>
        </div>

        <div className="separator" />

        <Field className="field">
            <Label className="label">Title</Label>
            <Input placeholder="Enter text"
                value={title}
                disabled={state.loading}
                onChange={e => setTitle(e.target.value)} />
        </Field>

        <Field className="field">
            <Label className="label">Description</Label>
            <PSTextarea placeholder="Write a note here"
                        className="input"
                        minRows={8}
                        disabled={state.loading}
                        value={description ?? ''}
                        maxLength={10000}
                        onChange={value => setDescription(value)}/>
        </Field>
        <SettingsPhotoField photo={photo}
            selectPhoto={selectPhoto}
            selectedPhoto={selectedPhoto}
            addPhotoRef={addPhotoRef}
            title={`Add an image`} />
    </div>

    if (state.loading || loading) {
        body = <Spinner size="128" color="#314A68" />
    }
    return (
        <AppHeader showBackButton title={getTitle() ?? ''} middleWidget={null}>
            {body}
        </AppHeader>
    )
}
