import { Field, Input } from "@zendeskgarden/react-forms";
import React, {useCallback, useEffect, useState} from "react";
import { currencyFormat, shiftThirdDecimal } from "../../../utils/numbers";
import { PSButton } from "../../app/PSButton";
import {ReactComponent as NumPadBackspace} from "../../../icons/ps_numpad_backspace.svg";
import {ReactComponent as NumPadCheck} from "../../../icons/ps_numpad_check.svg";

import './charge.css'

type Props = {
    value?: number,
    onComplete: (data: number) => void,
    onInputChanged?: (data: string) => boolean,
}

export const NumberPad = ({value, onComplete, onInputChanged}: Props) => {
    const ZERO = '0.00'
    const [number, setNumber] = useState<string>(ZERO)

    const onChange = useCallback((data: string) => {
        data = Number(data).toString() // remove any leading 0's
        const dotIx = data.indexOf('.')
        if (dotIx === -1) {
            data += '.00'
        }
        else if (dotIx === data.length - 1) {
            data += '00'
        }
        else if (dotIx === data.length - 2) {
            data += '0'
        }
        setNumber(data)
    }, [])

    const onPressed = useCallback((num: string) => {
        return () =>
        {
            if (num.endsWith('✓')) {
                return onComplete(Number(num.slice(0, -1)))
            }
            else if (num.endsWith('<')) {
                num = ZERO
            }

            num = shiftThirdDecimal(num)
            if (onInputChanged) onInputChanged(num)
            else setNumber(num)
        }
    }, [onComplete, onInputChanged])


    useEffect(() => {
        if (value !== undefined) onChange(value.toString())
    }, [onChange, value])

    return (
        <div className="numberPadWrapper">
            <div className="numberPad">
                <Field className='field'>
                    <Input value={currencyFormat(number, true)}
                        onChange={() => {}}
                        onKeyUp={(e) => {
                            if (e.key >= '0' && e.key <= '9') {
                                onPressed(number + e.key)()
                            }
                            else if (e.key === 'Backspace') {
                                onPressed(number + '<')()
                            }
                            else if (e.key === 'Enter') {
                                onPressed(number + '✓')()
                            }
                        }}/>
                </Field>
                <div className="row">
                    <PSButton onClick={onPressed(number + '1')}>1</PSButton>
                    <PSButton onClick={onPressed(number + '2')}>2</PSButton>
                    <PSButton onClick={onPressed(number + '3')}>3</PSButton>
                </div>
                <div className="row">
                    <PSButton onClick={onPressed(number + '4')}>4</PSButton>
                    <PSButton onClick={onPressed(number + '5')}>5</PSButton>
                    <PSButton onClick={onPressed(number + '6')}>6</PSButton>
                </div>
                <div className="row">
                    <PSButton onClick={onPressed(number + '7')}>7</PSButton>
                    <PSButton onClick={onPressed(number + '8')}>8</PSButton>
                    <PSButton onClick={onPressed(number + '9')}>9</PSButton>
                </div>
                <div className="row">
                    <PSButton className="nobg" onClick={onPressed(number + '<')}><NumPadBackspace /></PSButton>
                    <PSButton onClick={onPressed(number + '0')}>0</PSButton>
                    <PSButton className="nobg" onClick={onPressed(number + '✓')}><NumPadCheck /></PSButton>
                </div>
            </div>
        </div>
    )
}