import React, {useEffect, useState, useCallback} from "react";
import {Modal, Close, Footer, FooterItem, Body, Header} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import {CustomFieldEntry} from "./CustomFieldEntry";
import {Field} from "../../models/Field";

type Props = {
    currentAnswers: any,
    fields: Array<Field>,
    onCancel: () => void,
    onDone: (answers: any) => void,
    doneNode?: React.ReactNode,
    onOther?: (answers: any) => void,
    otherNode?: React.ReactNode,
}

export const CustomFieldsModal = ({currentAnswers, fields, onCancel, onDone, doneNode, onOther, otherNode}: Props) => {
    const [requiredFields, setRequiredFields] = useState<Field[]>([])
    useEffect(() => {
        const f = fields.filter(f => f.is_required)
        setRequiredFields(f)
    }, [fields])

    const [doneEnabled, setDoneEnabled] = useState(false)
    const [answers] = useState<any>(currentAnswers || {})
    const checkAnswers = useCallback((answers: any) => {
        // When all required answers have an answer, enable the done button
        let missingAnswer = false
        requiredFields.forEach(field => {
            if (!answers[field.name!]) missingAnswer = true
            if (field.type === 'multiselect')
                if (answers[field.name!]?.length === 0)
                    missingAnswer = true
        })
        setDoneEnabled(!missingAnswer)
    }, [requiredFields])

    useEffect(() => {
        checkAnswers(currentAnswers || {})
    }, [checkAnswers, currentAnswers])

    const onAnswerChange = (field: Field, value: any) => {
        answers[field.name!] = value
        checkAnswers(answers)
    }

    const onDoneHandler = () => {
        onDone({ ...answers })
    }

    const onOtherHandler = () => {
        if (onOther) onOther(answers)
    }

    return (
        <Modal className="modal"
               onClose={() => onCancel()}
        >
            <Header className="header">
                <div className="title">More details</div>
            </Header>
            <Body className="body form">
                {fields.filter(field => field.type !== "image" && field.type !== "document").map((field) => (
                    <CustomFieldEntry field={field}
                                      currentValue={answers[field.name!]}
                                      key={`field-${field.name}`}
                                      onChange={(value) => onAnswerChange(field, value)}
                    />
                ))}
            </Body>
            <Footer className="footer">
            {otherNode && onOther && (
                    <FooterItem>
                        <Button
                                disabled={!doneEnabled}
                                onClick={() => onOtherHandler()}
                        >
                            {otherNode && (
                                otherNode
                            )}
                        </Button>
                    </FooterItem>
                )}
                <FooterItem>
                    <Button isPrimary
                            disabled={!doneEnabled}
                            onClick={() => onDoneHandler()}
                    >
                        {!doneNode && (
                            "Done"
                        )}
                        {doneNode && (
                            doneNode
                        )}
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )
}