import React from "react";
import { Modal, Header, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { Button } from "@zendeskgarden/react-buttons";
import {ReactComponent as AlertIcon} from "../../icons/alert_red_circle.svg";
import {DangerModalButton, PSButtonDanger} from "../app/PSButton";

type Props = {
    title: string | JSX.Element,
    body: React.ReactNode | string,
    buttonText: string,
    extraButtonText?: string,
    disableDismiss?: boolean
    onClose: () => void,
    onAction: (button?: number) => void,
}

export const DangerModal = ({title, body, buttonText, extraButtonText, disableDismiss, onClose, onAction}: Props) => {
    return (
        <Modal onClose={onClose}>
            <Header style={{display: 'flex', color: '#E22216'}}>
                <AlertIcon />
                <span style={{fontWeight: 700, paddingLeft: 8}}>{title}</span>
            </Header>
            <Body>
                {body}
            </Body>
            <Footer>
                {disableDismiss !== true && (<FooterItem>
                    <Button onClick={onClose} isBasic>
                        Dismiss
                    </Button>
                </FooterItem>)}
                {extraButtonText && (
                    <FooterItem>
                        <PSButtonDanger onClick={() => onAction(2)} isPrimary>
                            {extraButtonText}
                        </PSButtonDanger>
                    </FooterItem>
                )}
                <FooterItem>
                    <DangerModalButton onClick={() => onAction(1)} isPrimary>
                        {buttonText}
                    </DangerModalButton>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    )
}